import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import type { Theme } from 'src/theme';


interface ApiProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  paddingTop: 200,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
 
}));

const Api: FC<ApiProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
      <Grid container justify="center" alignItems="flex-end" spacing={4}>
        This is the API page
      </Grid>
      </Container>
    </div>
  );
};

export default Api;
