import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import { manufacturersArray } from 'src/data/manufacturersArray'
import { vendorsArray } from 'src/data/vendorsArray'
import AutoCompleteSearch from 'src/components/AutoCompleteSearch';
import CountrySelector from 'src/components/CountrySelector';
import { useQuery, gql } from '@apollo/client'
import RadioButtons from 'src/components/RadioButtons';
import Alert from '@material-ui/lab/Alert';
import CompanyCard from '../../../components/CompanyCard';
interface HeroProps {
  className?: string;
}

const getManufacturer = gql`
query GET_MANUFACTURER($id: ID!) {
  manufacturer(id: $id) {
    data {
      attributes {
        nameLocation
        address
        address2
        fax
        name
        state
        email
        parent
        website
        zip
        models {
          data {
            id
            attributes {
              modelBrand
              description
            }
          }
        }
      }
    }
  }
}
`

const getVendor = gql`
query GET_VENDOR($id: ID!) {
  vendor(id: $id) {
    data {
      attributes {
        name
        nameLocation
        address
        address2
        fax
        name
        state
        email
        website
        zip
      }
    }
  }
}
`



const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 50,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  container: {
    marginTop: '3rem',
    maxWidth: '600px',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  button: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: theme.palette.secondary.main
  },
  dropDownContainer: {
    marginTop: '3rem',
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: 0,
    right: 150
  },
  title: {
    display: 'block'
  },
  count: {
    textAlign: 'right',
    fontSize: 12,
    color: 'green',
    fontWeight: 'bold',
    marginBottom: '.5rem'
  },
  countContainer: {
    alignSelf: 'end',
  },
  radioContainer: {
    marginBottom: '1rem'
  }
}));


const Hero: FC<HeroProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [country, setCountry] = useState("")
  const [searchType, setSearchType] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [filteredManufacturers, setFilteredManufacturers] = useState([])
  const [filteredVendors, setFilteredVendors] = useState([])
  const [selected, setSelected] = useState('');

  const { loading: manufacturerLoading, error: manufacturerError, data: manufacturerData } = useQuery(getManufacturer, { variables: { id: selected }, skip: (selected == '' || searchType === 'vendor') });
  const { loading: vendorLoading, error: vendorError, data: vendorData } = useQuery(getVendor, { variables: { id: selected }, skip: (selected == '' || searchType === 'manufacturer') });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (vendorError ?? manufacturerError) {
      setError(true)
      setErrorMessage(manufacturerError?.message ?? vendorError?.message)
    }
  }, [vendorError, manufacturerError])



  const handleCountrySelected = (value) => {
    setCountry(value?.label)

  }

  const handleRadioSelected = (value) => {
    setSearchType(value)
  }

  useEffect(() => {
    setSelected('')
  }, [country, searchType])


  useEffect(() => {
    if (country && searchType === 'manufacturer') {
      setFilteredManufacturers(manufacturersArray?.filter(item => item.country === country))
    } else {
      setFilteredVendors(vendorsArray?.filter(item => item.country === country))
    }
  }, [country, searchType])

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container className={classes.container} maxWidth="lg">
        <Grid container item justify="center" alignItems="center" spacing={4}>
          <Typography color="textPrimary" className={classes.title} variant="h1">Medical Device Database	&trade;</Typography>

          <Grid container justify="center" alignItems="center" className={classes.dropDownContainer} spacing={4}>
            <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={Boolean(error)} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
            <Grid xs={2} item className={classes.spinner} >
              {manufacturerLoading || vendorLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom=".7rem"
                >
                  <CircularProgress color="secondary" />
                </Box>) : null}
            </Grid>
            <Grid xs={12} item >
              <Grid xs={12} container item className={classes.radioContainer}>
                <Grid xs={8} item >  <RadioButtons selectedValue={handleRadioSelected} /></Grid>
                <Grid className={classes.countContainer} xs={4} item >    {country && searchType === "manufacturer" ? <Typography className={classes.count} color="textSecondary" variant="h5">{`${filteredManufacturers?.length} manufacturers`}</Typography> : null}
                  {country && searchType === "vendor" ? <Typography color="textSecondary" className={classes.count} variant="h5">{`${filteredVendors?.length} vendors`}</Typography> : null}</Grid>
              </Grid>

              <CountrySelector selected={handleCountrySelected} />
            </Grid>

            <Grid xs={12} item >
              {searchType === 'manufacturer' && country ?
                (
                  <AutoCompleteSearch setSelected={setSelected} searchType="manufacturer" data={filteredManufacturers} />
                ) : null}
              {searchType === 'vendor' && country ?
                (
                  <AutoCompleteSearch setSelected={setSelected} searchType="vendor" data={filteredVendors} />

                ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item >
          {manufacturerData && Object.keys(manufacturerData).length !== 0 ? <CompanyCard data={manufacturerData?.manufacturer.data?.attributes} /> : null}
          {vendorData && Object.keys(vendorData).length !== 0 ? <CompanyCard data={vendorData?.vendor.data?.attributes} /> : null}
        </Grid>
      </Container>
    </div >
  );
};

export default Hero;
