import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      marginTop: '4rem'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    avatar: {
      backgroundColor: red[500]
    }
  })
);

export default function CompanyCard({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            style={{
              color: 'white',
              backgroundColor: `rgb(${Math.floor(Math.random() * 255) +
                1}, ${Math.floor(Math.random() * 255) + 1}, ${Math.floor(
                  Math.random() * 255
                ) + 1})`
            }}
            aria-label="recipe"
            className={classes.avatar}
          >
            {data?.name}
          </Avatar>
        }
        title={data?.name}
      />
      <CardMedia
        className={classes.media}
        image={
          data?.logo ||
          `https://picsum.photos/id/${Math.floor(Math.random() * 500) +
          1}/200/300`
        }
      />
      <CardContent>
        {data?.address ?? <Typography variant="body2" color="textSecondary" component="span">
          {data?.address}
        </Typography>}
        {data?.address2 ??
          <Typography variant="body2" color="textSecondary" component="span">
            {data?.address2}
          </Typography>}
        {data?.city ?? <Typography variant="body2" color="textSecondary" component="span">
          {data?.city}
        </Typography>}
        {data?.state ??
          <Typography variant="body2" color="textSecondary" component="span">
            {data?.state}
          </Typography>}
        {data?.country ?? <Typography variant="body2" color="textSecondary" component="p">
          {data?.country}
        </Typography>}
        {data?.phone ??
          <Typography variant="body2" color="textSecondary" component="p">
            {data?.phone}
          </Typography>}
        {data?.website ?? <Link component="button" variant="body2">
          {data?.website}
        </Link>}

      </CardContent>


      {data.__typename === 'Manufacturer' ? (<><CardActions disableSpacing>
        <Typography variant="body2" color="textSecondary" component="p">
          {`   ${data?.models.data?.length} equipment listed`}
        </Typography>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          disabled={data?.models.data?.length === 0}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {data?.models?.data.map(item => {
              return (
                <Box key={item?.id} padding=".5rem" marginBottom="1rem" borderRadius="5px" border="1px solid lightgray">
                  <Typography component="span">{item?.attributes?.modelBrand}</Typography>
                  <Typography component="span">
                    {item?.attributes?.description}
                  </Typography>
                </Box>
              );
            })}
          </CardContent>
        </Collapse></>) : null}

    </Card>
  );
}
