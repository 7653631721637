import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, Typography } from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  radioGroup: {
    flexDirection: 'row'
  },

}));

export default function RadioButtons({ selectedValue }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('manufacturer');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  selectedValue(value)


  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="manufacturer vendor radio button"
        name="manufacturerVendor"
        value={value}
        className={classes.radioGroup}
        onChange={handleChange}
      >
        <FormControlLabel
          value="manufacturer"
          control={<Radio />}
          checked={value === 'manufacturer'}
          label={<Typography color="textSecondary" variant="subtitle1">Manufacturers (OEM)</Typography>}
        />
        <FormControlLabel checked={value === 'vendor'} value="vendor" control={<Radio />} label={<Typography color="textSecondary" variant="subtitle1">Vendors</Typography>} />
      </RadioGroup>
    </FormControl>
  );
}
