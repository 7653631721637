import React, { Dispatch } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  data: { id: number; name: string }[];
  searchType?: string;
  isDisabled?: boolean;
  setSelected: Dispatch<React.SetStateAction<string>>;
}

const AutoCompleteSearch = (props: Props) => {
  const { data = [], setSelected, searchType, isDisabled } = props;
  const options = data.map(option => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });

  return (
    <Autocomplete
      disabled={isDisabled}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(event, value) =>
        setSelected(JSON.parse(JSON.stringify(value))?.id)
      }
      id={`grouped-${searchType}-search`}
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={option => option.firstLetter}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} label={searchType} variant="outlined" />
      )}
    />
  );
};

export default AutoCompleteSearch;
