/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { countryToFlag } from 'src/utils/helpers';
import { countries } from './countries';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  select: {
    width: '100%'
  }
});

interface CountryType {
  code: string;
  label: string;
}

const CountrySelector = props => {
  const { selected } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-selector"
      className={classes.select}
      options={countries as CountryType[]}
      classes={{
        option: classes.option
      }}
      autoHighlight
      getOptionLabel={option => option.label}
      renderOption={option => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code})
        </React.Fragment>
      )}
      onChange={(e, value) => selected(value)}
      renderInput={params => (
        <TextField
          {...params}
          label="Country"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelector;
