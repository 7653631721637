export const manufacturersArray =  [
  {
    "id": 108649,
    "name": "APC American Power Conversion    ( West Kingston RI USA )",
    "country": "United States"
  },
  {
    "id": 449812,
    "name": "1-800 CONTACTS Inc    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 456705,
    "name": "1800wheelchair.com Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 460726,
    "name": "1E Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457049,
    "name": "20/20 Imaging    ( Garner NC USA )",
    "country": "United States"
  },
  {
    "id": 366874,
    "name": "20/20 Technology Inc    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 441104,
    "name": "210 Innovations LLC    ( Groton CT USA )",
    "country": "United States"
  },
  {
    "id": 108335,
    "name": "21st Century Scientific Inc    ( Coeur d'Alene ID USA )",
    "country": "United States"
  },
  {
    "id": 458225,
    "name": "2B Technologies    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 292055,
    "name": "2D Imaging Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 451383,
    "name": "2XL Corp    ( Clarendon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 451384,
    "name": "3 Point Products Inc    ( Stevensville MD USA )",
    "country": "United States"
  },
  {
    "id": 453609,
    "name": "3 Pty Ltd    ( Wickham  Australia )",
    "country": "Australia"
  },
  {
    "id": 452379,
    "name": "3-D Technical Services    ( Franklin OH USA )",
    "country": "United States"
  },
  {
    "id": 453086,
    "name": "365 Healthcare Ltd    ( Droiwich Spa  England )",
    "country": "UK"
  },
  {
    "id": 457207,
    "name": "3AM Technologies LLC    ( High Ridge MO USA )",
    "country": "United States"
  },
  {
    "id": 455144,
    "name": "3B Scientific Europe Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 398160,
    "name": "3B Scientific GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454775,
    "name": "3B Scientific UK    ( Weston-super-Mare  England )",
    "country": "UK"
  },
  {
    "id": 999731,
    "name": "3Com Corporation    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 455499,
    "name": "3D Imaging & Simulation Corp (3Disc)    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 464808,
    "name": "3D Innovations LLC    ( Greeley CO USA )",
    "country": "United States"
  },
  {
    "id": 455497,
    "name": "3DISC Americas Inc    ( Dulles VA USA )",
    "country": "United States"
  },
  {
    "id": 455498,
    "name": "3DISC Europe ApS    ( Alleroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453338,
    "name": "3dMD LLC    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458553,
    "name": "3DR Laboratories    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 462914,
    "name": "3Dstereo.com Inc    ( Las vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 458579,
    "name": "3E Co    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 456094,
    "name": "3F Medical Systems    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451381,
    "name": "3F Therapeutics Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 456843,
    "name": "3Gen LLC    ( San Juan Capistrano CA USA )",
    "country": "United States"
  },
  {
    "id": 393259,
    "name": "3i France    ( Lavallois-Perret  USA )",
    "country": "United States"
  },
  {
    "id": 150285,
    "name": "3M    (   USA )",
    "country": "United States"
  },
  {
    "id": 267252,
    "name": "3M Asia Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 107801,
    "name": "3M Electronics    ( Sanford NC USA )",
    "country": "United States"
  },
  {
    "id": 397746,
    "name": "3M ESPE Dental Products    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 458640,
    "name": "3M Filtration Inc    ( Meriden CT USA )",
    "country": "United States"
  },
  {
    "id": 104967,
    "name": "3M Health Care    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 151078,
    "name": "3M Health Care Ltd    ( Loughborough  England )",
    "country": "UK"
  },
  {
    "id": 307378,
    "name": "3M Malaysia Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 267254,
    "name": "3M Medica GmbH    ( Neuss  Germany )",
    "country": "Germany"
  },
  {
    "id": 108506,
    "name": "3M Occupational Health & Environmental Safety Div    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 453638,
    "name": "3M Schweiz AGG    ( Rueschlikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 108634,
    "name": "3M Unitek    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 285444,
    "name": "3M Unitek Inter-Unitek GmbH    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 455764,
    "name": "3QBD Israel    ( Arad  Israel )",
    "country": "Israel"
  },
  {
    "id": 407396,
    "name": "3T Medical Systems LLC    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 457834,
    "name": "454 Life Sciences    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 391891,
    "name": "4A Medical Industry Co Ltd    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 456594,
    "name": "4CARE AG    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 377717,
    "name": "4D Neuroimaging    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458149,
    "name": "4MD Medical Solutions    ( Lakwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 451378,
    "name": "4Med Ltd Israel    ( Kfar Saba  Israel )",
    "country": "Israel"
  },
  {
    "id": 451370,
    "name": "4Med Ltd USA    ( Chappaqua NY USA )",
    "country": "United States"
  },
  {
    "id": 458195,
    "name": "4medica    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 457741,
    "name": "4moms    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 462961,
    "name": "4Web Inc    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 454183,
    "name": "5 Star Imaging Inc    ( Odessa FL USA )",
    "country": "United States"
  },
  {
    "id": 453785,
    "name": "7 MED Industrie    ( Hauterive  France )",
    "country": "France"
  },
  {
    "id": 373235,
    "name": "77 Elektronika Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 467178,
    "name": "911 Safety Equipment    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 453049,
    "name": "A & A Sheet Metal Products/Securall Cabinets Inc    ( La Porte IN USA )",
    "country": "United States"
  },
  {
    "id": 138472,
    "name": "A & D Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 105246,
    "name": "A & D Engineering Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 281575,
    "name": "A & D Instruments GmbH    ( Ahrensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 156963,
    "name": "A & D Instruments Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 179812,
    "name": "A & D Medical    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 457068,
    "name": "A & D Weighing    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 108348,
    "name": "A & E Industries Inc    ( Belleville IL USA )",
    "country": "United States"
  },
  {
    "id": 394725,
    "name": "A & E Medical    ( Farmingdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 454858,
    "name": "A & J Medical International Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 337895,
    "name": "A & L Shielding Inc    ( Rome GA USA )",
    "country": "United States"
  },
  {
    "id": 272098,
    "name": "A & M Hearing Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 399270,
    "name": "A & M Henry Pty Ltd    ( Mortdale  Australia )",
    "country": "Australia"
  },
  {
    "id": 284488,
    "name": "A & P Finken Etikettenfabrik    ( Moenchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 282215,
    "name": "A & S Glasvertriebs GmbH    ( Gudensberg  USA )",
    "country": "United States"
  },
  {
    "id": 175382,
    "name": "A & S Tribal Industries    ( Poplar MT USA )",
    "country": "United States"
  },
  {
    "id": 187981,
    "name": "A & T Corp    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 286394,
    "name": "A Berents GmbH & Co KG Mischtechnik    ( Stuhr  Germany )",
    "country": "Germany"
  },
  {
    "id": 279919,
    "name": "A Berkholz & Sohn    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 335817,
    "name": "A Daigger & Co    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 281778,
    "name": "A Hammerer GmbH & Co KG Fabrik Med Bandagen    ( Freilassing  Germany )",
    "country": "Germany"
  },
  {
    "id": 330601,
    "name": "A Henniss    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456478,
    "name": "A Imaging Solutions Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 279921,
    "name": "A Knip & Co Nachf Hartmut Rauchfuss    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 158823,
    "name": "A Kruess Optronic GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 450815,
    "name": "A la Cart Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 106239,
    "name": "A Leventhal & Sons Inc    ( Scranton PA USA )",
    "country": "United States"
  },
  {
    "id": 102489,
    "name": "A M Bickford Inc    ( Wales Center NY USA )",
    "country": "United States"
  },
  {
    "id": 232359,
    "name": "A Menarini Diagnostics    ( Grassina (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452017,
    "name": "A One Surgico    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 451385,
    "name": "A Plus International    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 162959,
    "name": "A Ravona    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 293200,
    "name": "A Riegger Arzt & Spitalbedarf AG    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 338258,
    "name": "A Riklin    ( Ramat Gan  USA )",
    "country": "United States"
  },
  {
    "id": 138474,
    "name": "A Schumann Feinmechanik    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 102458,
    "name": "A Schumann Inc    ( Concord MA USA )",
    "country": "United States"
  },
  {
    "id": 283546,
    "name": "A V Haehnel GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 286567,
    "name": "A V M Schmelter GmbH & Co KG    ( Telgte  Germany )",
    "country": "Germany"
  },
  {
    "id": 284823,
    "name": "A Van Kaick Neu-Isenburg GmbH & Co KG    ( Neu-Isenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 105999,
    "name": "A W Sperry Instruments Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 465028,
    "name": "A-1 Locksmith    ( Carrollton 20 USA )",
    "country": "United States"
  },
  {
    "id": 101028,
    "name": "A-Dec Inc    ( Newberg OR USA )",
    "country": "United States"
  },
  {
    "id": 999741,
    "name": "A-dec Inc.    ( Newberg OR USA )",
    "country": "USA"
  },
  {
    "id": 370787,
    "name": "A-Life Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 102086,
    "name": "A-M Systems Inc    ( Carlsborg WA USA )",
    "country": "United States"
  },
  {
    "id": 377718,
    "name": "A-Med Systems Inc    ( West Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 457021,
    "name": "A-Mi Industry Co    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 101008,
    "name": "A-T Surgical Mfg Co Inc    ( Holyoke MA USA )",
    "country": "United States"
  },
  {
    "id": 300214,
    "name": "A@4 Health Systems    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 328098,
    "name": "A*A DATA    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 457040,
    "name": "A+ Medical Company Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 456510,
    "name": "A1 Scrubs Inc    ( Gretna NE USA )",
    "country": "United States"
  },
  {
    "id": 453951,
    "name": "A2U    ( Wolverhampton  England )",
    "country": "UK"
  },
  {
    "id": 451382,
    "name": "A2Z Uniforms and Embroidery    ( Southaven MS USA )",
    "country": "United States"
  },
  {
    "id": 280650,
    "name": "A3 GmbH    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 391296,
    "name": "AAA Medical    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 143668,
    "name": "AAC X-Ray Service    ( Bethpage NY USA )",
    "country": "United States"
  },
  {
    "id": 452334,
    "name": "AACMED - Swiss Medical Devices    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 431492,
    "name": "Aacurat GmbH    ( Huettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 337905,
    "name": "AADCO Medical Inc    ( Randolph VT USA )",
    "country": "United States"
  },
  {
    "id": 179816,
    "name": "AAF International    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 170122,
    "name": "AAI Engineering Support Inc    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 330768,
    "name": "AalbaDent    ( Fairfield CA USA )",
    "country": "United States"
  },
  {
    "id": 467106,
    "name": "Aalborg Instruments & Controls Inc    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 451228,
    "name": "Aalipayam Medical Engineering Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 450996,
    "name": "Aalto Scientific Ltd    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 371486,
    "name": "AAMSCO Lighting Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 365297,
    "name": "aap Implantate AG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 456790,
    "name": "Aaren Scientific Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 103286,
    "name": "Aaron Medical    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 452919,
    "name": "AART Inc    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 466502,
    "name": "Accriva Diagnostics    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454527,
    "name": "AAT Alber Antriebstechnik GmbH    ( Albstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 157874,
    "name": "AATZ Inc    ( Abiko  Japan )",
    "country": "Japan"
  },
  {
    "id": 452043,
    "name": "AAXIS Pacific    ( Blacktown  Australia )",
    "country": "Australia"
  },
  {
    "id": 238861,
    "name": "AB Biodisk North America Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 409928,
    "name": "AB Germa    ( Kristiansstad  USA )",
    "country": "United States"
  },
  {
    "id": 455002,
    "name": "AB SCIEX (Hong Kong) Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454995,
    "name": "AB SCIEX (Russia)    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 454994,
    "name": "AB SCIEX (United States)    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 454997,
    "name": "AB SCIEX Australia Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 454999,
    "name": "AB SCIEX Germany GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 455000,
    "name": "AB SCIEX Japan Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455001,
    "name": "AB SCIEX Netherlands bv    ( Nieuwerkerk aan den Ijssel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454996,
    "name": "AB SCIEX South Africa Pte Ltd    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454998,
    "name": "AB SCIEX UK Ltd    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 226156,
    "name": "Abatement Technologies Inc    ( Suwanee GA USA )",
    "country": "United States"
  },
  {
    "id": 455073,
    "name": "Abatement Technologies Ltd    ( Fort Erie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458189,
    "name": "Abaxis Europe GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 155940,
    "name": "Abaxis Inc    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 280620,
    "name": "ABB Flaekt Produkte GmbH    ( Butzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 284257,
    "name": "ABB Gebaeudetechnik AG    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 462938,
    "name": "ABB Healthcare    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 104094,
    "name": "Abbeon Cal Inc    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 150992,
    "name": "Abbey Biosystems Ltd    ( Whitland  Wales )",
    "country": "Wales"
  },
  {
    "id": 440769,
    "name": "Abbott Diabetes Care    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 440770,
    "name": "Abbott Diabetes Care (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452509,
    "name": "Abbott Diabetes Germany    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 175331,
    "name": "Abbott Diagnostics    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 152996,
    "name": "Abbott GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 455222,
    "name": "Abbott Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 103243,
    "name": "Abbott Laboratories    ( Abbott Park IL USA )",
    "country": "United States"
  },
  {
    "id": 451672,
    "name": "Abbott Laboratories    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 306942,
    "name": "Abbott Laboratories (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 102166,
    "name": "Abbott Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 174513,
    "name": "Abbott Laboratories Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455223,
    "name": "Abbott Laboratories Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454179,
    "name": "Abbott Medical Optics    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 455172,
    "name": "Abbott Medical Optics (Australia) Pty Ltd    ( Pymble  Australia )",
    "country": "Australia"
  },
  {
    "id": 455162,
    "name": "Abbott Medical Optics (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455164,
    "name": "Abbott Medical Optics (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455165,
    "name": "Abbott Medical Optics (France)    ( Biot  France )",
    "country": "France"
  },
  {
    "id": 455166,
    "name": "Abbott Medical Optics (Germany) GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455167,
    "name": "Abbott Medical Optics (Italy) srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 455168,
    "name": "Abbott Medical Optics (Japan) KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455163,
    "name": "Abbott Medical Optics (Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455169,
    "name": "Abbott Medical Optics (Netherlands) bv    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455173,
    "name": "Abbott Medical Optics (Spain) SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 455170,
    "name": "Abbott Medical Optics (Switzerland) GmbH    ( Pfaffikon SZ  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455171,
    "name": "Abbott Medical Optics (United Kingdom) Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 456769,
    "name": "Abbott Medical Optics Norden AB    ( Upplands Vasby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452682,
    "name": "Abbott Molecular Inc    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 453026,
    "name": "Abbott Nutrition    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 456301,
    "name": "Abbott Nutrition Canada    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 456302,
    "name": "Abbott Nutrition Ireland    ( Dublin 24  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 450992,
    "name": "Abbott Point of Care    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 451670,
    "name": "Abbott Spine Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 451671,
    "name": "Abbott Spine International    ( Bordeaux  France )",
    "country": "France"
  },
  {
    "id": 444079,
    "name": "Abbott Vascular Devices    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 463058,
    "name": "AbbVie    ( North Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 462678,
    "name": "ABC Carpet & Home    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457424,
    "name": "ABC Office    ( Kaysville UT USA )",
    "country": "United States"
  },
  {
    "id": 451207,
    "name": "Abcam Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 451206,
    "name": "Abcam plc    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 185362,
    "name": "abcMED Sp z o o    ( Krakow  Poland )",
    "country": "Poland"
  },
  {
    "id": 102872,
    "name": "ABCO DEALERS    (   USA )",
    "country": "United States"
  },
  {
    "id": 462677,
    "name": "ABCO Fire Protection    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 451741,
    "name": "AbD Serotec Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 454555,
    "name": "ABEL Medical Software Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 286641,
    "name": "Abele Medi-Tec    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 397709,
    "name": "ABELSoft Corp    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 463054,
    "name": "Abena A/S    ( Aabenraa  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 471666,
    "name": "Abena North America    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 441675,
    "name": "ABgene Deutschland    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 441677,
    "name": "ABgene France    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 441004,
    "name": "ABgene Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 441661,
    "name": "ABgene UK    ( Epsom  England )",
    "country": "UK"
  },
  {
    "id": 466238,
    "name": "Ability Dynamics LLC    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 458205,
    "name": "ABILITY Network Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 283790,
    "name": "ABIMED Analysen-Technik GmbH    ( Langenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 408295,
    "name": "ABIOMED bv    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105738,
    "name": "ABIOMED Inc    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 328236,
    "name": "Able Table Co    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 391894,
    "name": "AbleApparel    ( New Hyde Park NY USA )",
    "country": "United States"
  },
  {
    "id": 363244,
    "name": "Ablenet    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 175725,
    "name": "AbleNet Inc    ( Roseville MN USA )",
    "country": "United States"
  },
  {
    "id": 163009,
    "name": "ABM (Applications Bio-Mecaniques)    ( Saint-Etienne Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 462672,
    "name": "ABM Health Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 451365,
    "name": "ABMI SA    ( Echandens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455260,
    "name": "ABnote North America    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 178874,
    "name": "Abodia Lighted Slide Storage Div Elden Associates    ( Charleston WV USA )",
    "country": "United States"
  },
  {
    "id": 106082,
    "name": "ABR Inc (Adam Biomedical Research Inc)    ( Cumberland MD USA )",
    "country": "United States"
  },
  {
    "id": 454556,
    "name": "Abraxas Medical Solutions    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 451874,
    "name": "ABS Medicare Pvt    ( Vadodara  India )",
    "country": "India"
  },
  {
    "id": 454734,
    "name": "Absocold Corp    ( Richmond IN USA )",
    "country": "United States"
  },
  {
    "id": 999494,
    "name": "Absocold Corporation    (   USA )",
    "country": "USA"
  },
  {
    "id": 999984,
    "name": "Absocold Corporation    (   USA )",
    "country": "United States"
  },
  {
    "id": 456949,
    "name": "Absolute Imaging Solutions    ( Oak Ridge NC USA )",
    "country": "United States"
  },
  {
    "id": 454184,
    "name": "Absolute Medical Equipment    ( Garnerville NY USA )",
    "country": "United States"
  },
  {
    "id": 328102,
    "name": "Absolute X-Ray Solutions    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 451368,
    "name": "Absorbest AB    ( Kisa  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 192809,
    "name": "Abu Dhabi Supplies & Commercial Services Est    ( Jebel Ali  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 451229,
    "name": "Abzar Darman Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 457961,
    "name": "Abzar Teb Pouya    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 454633,
    "name": "Acacia    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 453614,
    "name": "Acacia Engineered Products LLC    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 466243,
    "name": "Academic Technologies, Inc.    ( Ridgeland 20 USA )",
    "country": "United States"
  },
  {
    "id": 463051,
    "name": "Acal BFi UK Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 457182,
    "name": "ACANDIS GmbH and Co KG    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 465076,
    "name": "Accel Technology    ( Westlake Village 28 USA )",
    "country": "United States"
  },
  {
    "id": 458557,
    "name": "Accelarad    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 999949,
    "name": "Accelerated Care Plus Corporation    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 418698,
    "name": "Acceleration Therapeutics    ( North Bay Village FL USA )",
    "country": "United States"
  },
  {
    "id": 107197,
    "name": "Acceletronics Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 450844,
    "name": "Accellent    ( Collegeville PA USA )",
    "country": "United States"
  },
  {
    "id": 451891,
    "name": "Accellent Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 458562,
    "name": "Accellion    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 462674,
    "name": "Accelrys Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 322499,
    "name": "Access Battery LLC    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 441248,
    "name": "Access Care    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 451019,
    "name": "Access Genetics LLC    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 451635,
    "name": "Access Mobility Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 451783,
    "name": "Access Point Medical Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 455696,
    "name": "Access Scientific Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458564,
    "name": "Access Technology Integration Inc    ( Wynantskill NY USA )",
    "country": "United States"
  },
  {
    "id": 456346,
    "name": "Access to Recreation Inc    ( Newbury Park CA USA )",
    "country": "United States"
  },
  {
    "id": 458634,
    "name": "AccessClosure Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 999574,
    "name": "Accessible Fitness Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 305701,
    "name": "Accidental First Aid Supplies Pty Ltd    ( Blacktown  Australia )",
    "country": "Australia"
  },
  {
    "id": 451411,
    "name": "Acclarent Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 999620,
    "name": "Accoson Works    ( Harlow Essex USA )",
    "country": "United States"
  },
  {
    "id": 456770,
    "name": "Accratech Inc    ( Necedah WI USA )",
    "country": "United States"
  },
  {
    "id": 450960,
    "name": "Accrington Surgical Ltd    ( Accrington  England )",
    "country": "UK"
  },
  {
    "id": 465145,
    "name": "Accsense, Inc.    ( Chesterland 12 USA )",
    "country": "United States"
  },
  {
    "id": 321451,
    "name": "AccSys Technonogy Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 458577,
    "name": "Accu Medical Waste Systems Inc    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 458204,
    "name": "Accu-Chart Healthcare Systems Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 452814,
    "name": "Accu-line Products Inc    ( Hyannis MA USA )",
    "country": "United States"
  },
  {
    "id": 169575,
    "name": "ACCU-SCOPE Inc    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 457937,
    "name": "Accu-Seal Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 441664,
    "name": "Accu-Stat Diagnostics Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 107005,
    "name": "Accu-Tech Industries    ( Edmond OK USA )",
    "country": "United States"
  },
  {
    "id": 456095,
    "name": "AccuBio Tech Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451636,
    "name": "Accubuilt Inc    ( Lima OH USA )",
    "country": "United States"
  },
  {
    "id": 453774,
    "name": "Accucare Canada    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 358420,
    "name": "Acculab    ( Edgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 442175,
    "name": "Accumetrics    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 153000,
    "name": "Accura Medizintechnik GmbH    ( Karben  Germany )",
    "country": "Germany"
  },
  {
    "id": 176396,
    "name": "Accurate Fire and Safety Equipment    ( Franklin Square NY USA )",
    "country": "United States"
  },
  {
    "id": 170145,
    "name": "Accurate Medical Diagnostics    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 102581,
    "name": "Accurate Surgical & Scientific Instruments Corp    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 454722,
    "name": "Accurate Technology Inc    ( Fletcher NC USA )",
    "country": "United States"
  },
  {
    "id": 454166,
    "name": "Accuray Asia Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454165,
    "name": "Accuray Europe    ( Paris La Defense Cedex  France )",
    "country": "France"
  },
  {
    "id": 373945,
    "name": "Accuray Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 454164,
    "name": "Accuray Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455327,
    "name": "Accuri Cytometers (Europe) Ltd    ( St Ives  England )",
    "country": "UK"
  },
  {
    "id": 452700,
    "name": "Accuri Cytometers Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 455088,
    "name": "Accurion GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451951,
    "name": "Accurion Scientific Instruments    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 451413,
    "name": "Accuro Healthcare Solutions    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457418,
    "name": "Accuro Medical Products LLC    ( Downers Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 337919,
    "name": "AccuSoft Corp    ( Northborough MA USA )",
    "country": "United States"
  },
  {
    "id": 454217,
    "name": "AccuSoft Pegasus    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 226951,
    "name": "AccuSync Medical Research Corp    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 458595,
    "name": "AccuTemp Products Inc    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 439893,
    "name": "Accutome Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 458206,
    "name": "Accutome Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 456741,
    "name": "Accutron Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 454450,
    "name": "AccuVein LLC    ( Cold Spring Harbor NY USA )",
    "country": "United States"
  },
  {
    "id": 306943,
    "name": "Ace Care Co (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 328107,
    "name": "Ace Equipment Management Services    ( Mims FL USA )",
    "country": "United States"
  },
  {
    "id": 454733,
    "name": "Ace Intermed Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 452897,
    "name": "ACE Medical Corp Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457720,
    "name": "Affinition    ( Williamsport PA USA )",
    "country": "United States"
  },
  {
    "id": 108729,
    "name": "Ace Medical Equipment Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 157877,
    "name": "Ace Medicare Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454396,
    "name": "Ace Mobility    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 454695,
    "name": "ACE Surgical SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 108867,
    "name": "ACE Surgical Supply Co    ( Brockton MA USA )",
    "country": "United States"
  },
  {
    "id": 456387,
    "name": "ACell Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 451633,
    "name": "ACells Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 451709,
    "name": "Acer America Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 459159,
    "name": "Aceso Interactive Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 178810,
    "name": "Acetylene Oxygen Co (AOC)    ( Harlingen TX USA )",
    "country": "United States"
  },
  {
    "id": 373301,
    "name": "ACEuropa Lda    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 457734,
    "name": "ACF Technologies Inc    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 401066,
    "name": "Achi Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 277692,
    "name": "Achieve Healthcare Technologies    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 465009,
    "name": "Achievers Corp    ( San Francisco 23 USA )",
    "country": "United States"
  },
  {
    "id": 160751,
    "name": "ACI Medical    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 384773,
    "name": "ACIST (Europe) bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 352947,
    "name": "ACIST Medical Systems Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 101035,
    "name": "Ack Laboratories Inc    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 285766,
    "name": "Ackermann Instrumente GmbH    ( Rietheim-Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 104581,
    "name": "ACL Staticide Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 465151,
    "name": "ACME Business Systems    ( Schenectady P. USA )",
    "country": "United States"
  },
  {
    "id": 456049,
    "name": "ACME Commercial Ways (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 101072,
    "name": "Acme Medical Scale Co    ( San Leandro CA USA )",
    "country": "United States"
  },
  {
    "id": 458162,
    "name": "Acme Monaco    ( New Britain CT USA )",
    "country": "United States"
  },
  {
    "id": 465173,
    "name": "Acme Pacific Repairs, Inc    ( Fremont 13 USA )",
    "country": "United States"
  },
  {
    "id": 458596,
    "name": "Acme Paper & Supply Co Inc    ( Savage MD USA )",
    "country": "United States"
  },
  {
    "id": 451040,
    "name": "Acmevisible Filing Systems Ltd    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 458645,
    "name": "Acmeware Inc    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 454532,
    "name": "ACMI    ( Goodlettsville TN USA )",
    "country": "United States"
  },
  {
    "id": 407211,
    "name": "ACMI Canada Inc    ( Oshawa ON USA )",
    "country": "United States"
  },
  {
    "id": 403304,
    "name": "ACMI Corp    ( Southborough MA USA )",
    "country": "United States"
  },
  {
    "id": 403305,
    "name": "ACMI SA    ( Lisses  USA )",
    "country": "United States"
  },
  {
    "id": 107174,
    "name": "ACOMA MEDICAL IMAGING    (   USA )",
    "country": "United States"
  },
  {
    "id": 152410,
    "name": "Acoma Medical Industry Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 401065,
    "name": "Acor Orthopaedic Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 453742,
    "name": "Acorn Cardiovascular Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 103010,
    "name": "Acorn Engineering Co Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 321007,
    "name": "Acoustair    ( Moerkapelle  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 104980,
    "name": "Acoustic Systems    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 456464,
    "name": "ACP Accelerated Care Plus    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 459292,
    "name": "ACP Inc    ( Cedar Rapids IA USA )",
    "country": "United States"
  },
  {
    "id": 465049,
    "name": "Acquia, Inc.    ( Burlington 25 USA )",
    "country": "United States"
  },
  {
    "id": 104945,
    "name": "Acra-Cut Inc    ( Acton MA USA )",
    "country": "United States"
  },
  {
    "id": 457425,
    "name": "Acroprint Time Recorder Co    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 999578,
    "name": "Acroprint Time Recorder Co.    ( Raleigh NC USA )",
    "country": "USA"
  },
  {
    "id": 442223,
    "name": "Acrorad Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 284140,
    "name": "Acrovyn-Bauprofile GmbH    ( Luedenscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 290073,
    "name": "AcryMed Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 452180,
    "name": "ACS Healthcare Solutions    ( Dearborn MI USA )",
    "country": "United States"
  },
  {
    "id": 465217,
    "name": "ACS MediHealth    ( Alpharetta 19 USA )",
    "country": "United States"
  },
  {
    "id": 455430,
    "name": "ACS MIDAS+    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 450282,
    "name": "ACT Meters Ltd    ( Merseyside  England )",
    "country": "UK"
  },
  {
    "id": 399230,
    "name": "ACT'prime Inc    ( Quebec City PQ USA )",
    "country": "United States"
  },
  {
    "id": 324928,
    "name": "Actamed Ltd    ( Wakefield  England )",
    "country": "UK"
  },
  {
    "id": 452690,
    "name": "Actavis Norway A/S    ( Vennesla  Norway )",
    "country": "Norway"
  },
  {
    "id": 452858,
    "name": "Actavis US    ( Morristown NJ USA )",
    "country": "United States"
  },
  {
    "id": 999746,
    "name": "Acteh    (   USA )",
    "country": "United States"
  },
  {
    "id": 419869,
    "name": "Acteon Group    ( Merignac Cedex  France )",
    "country": "France"
  },
  {
    "id": 450925,
    "name": "Acteon North America    ( Mount Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 451366,
    "name": "ACTherm Inc    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999200,
    "name": "Actidyn    ( 78990 Elancourt  France )",
    "country": "France"
  },
  {
    "id": 457208,
    "name": "ActiGraph    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 236966,
    "name": "Actimed    ( Broadstone  USA )",
    "country": "United States"
  },
  {
    "id": 462774,
    "name": "Action Commercial Services Inc    ( Schenectady NY USA )",
    "country": "United States"
  },
  {
    "id": 101029,
    "name": "Action Products Inc    ( Hagerstown MD USA )",
    "country": "United States"
  },
  {
    "id": 174517,
    "name": "Activa Brand Products Inc    ( Charlottetown PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 456923,
    "name": "Activaero America Inc    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 456922,
    "name": "Activaero GmbH    ( Gemuenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 453046,
    "name": "Activate Ltd    ( Salisbury  England )",
    "country": "UK"
  },
  {
    "id": 458380,
    "name": "ActivaTek Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 457829,
    "name": "Active Ankle Systems Inc    ( Jeffersonville IN USA )",
    "country": "United States"
  },
  {
    "id": 456624,
    "name": "Active Birth Pools Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 233653,
    "name": "Active Corp    ( Castine ME USA )",
    "country": "United States"
  },
  {
    "id": 452394,
    "name": "Active Health Management Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 441249,
    "name": "Active Healthcare Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 457830,
    "name": "Active Innovations    ( Jeffersonville IN USA )",
    "country": "United States"
  },
  {
    "id": 101037,
    "name": "Activeaid Inc    ( Redwood Falls MN USA )",
    "country": "United States"
  },
  {
    "id": 459465,
    "name": "ActiveForever    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 462935,
    "name": "ActivLife Electronics Pty Ltd    ( Essendon  Australia )",
    "country": "Australia"
  },
  {
    "id": 289370,
    "name": "Acuderm Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 451041,
    "name": "Acueity Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 390985,
    "name": "ACUFIRM    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 463072,
    "name": "AcuFocus Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 306176,
    "name": "Acuhealth Pty Ltd    ( Stepney  Australia )",
    "country": "Australia"
  },
  {
    "id": 466425,
    "name": "Acuity Brands Lighting Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 376431,
    "name": "Aculux Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 456050,
    "name": "Acuman Surgical Pvt Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 227403,
    "name": "Acumed LLC    ( Hillsboro OR USA )",
    "country": "United States"
  },
  {
    "id": 454827,
    "name": "Acumed Ltd (UK)    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 454828,
    "name": "Acumed Ltd Iberica    ( El Planto (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 452910,
    "name": "Acuros GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 156019,
    "name": "Acuson    (   USA )",
    "country": "United States"
  },
  {
    "id": 104451,
    "name": "Acuson A Siemens Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 456575,
    "name": "ACUTE Innovations LLC    ( Hillsboro OR USA )",
    "country": "United States"
  },
  {
    "id": 390984,
    "name": "ACUTRONIC Medical Systems AG    ( Hirzel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 184522,
    "name": "Ad-Tech Medical Instrument Corp    ( Racine WI USA )",
    "country": "United States"
  },
  {
    "id": 103199,
    "name": "Ad-Vance Magnetics Inc    ( Rochester IN USA )",
    "country": "United States"
  },
  {
    "id": 103204,
    "name": "ADAC LABS/PHILIPS MEDICAL SYSTEMS    (   USA )",
    "country": "United States"
  },
  {
    "id": 409554,
    "name": "Adaltis Deutschland GmbH    ( Frieburg im Breisgau  USA )",
    "country": "United States"
  },
  {
    "id": 453721,
    "name": "Adaltis Diagnostics Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 453722,
    "name": "Adaltis Healthcare (China) Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 409552,
    "name": "Adaltis Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 409551,
    "name": "Adaltis Italia SpA    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453724,
    "name": "Adam Equipment (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 397980,
    "name": "Adam Equipment Co Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 451255,
    "name": "Adam Equipment Co Ltd    ( Perth  Australia )",
    "country": "Australia"
  },
  {
    "id": 397979,
    "name": "Adam Equipment Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 397981,
    "name": "Adam Equipment SA (Pty) Ltd    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 457041,
    "name": "Adam Imaging Parts Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 452412,
    "name": "ADAM Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 186830,
    "name": "Adam Rouilly Ltd    ( Sittingbourne  England )",
    "country": "UK"
  },
  {
    "id": 441632,
    "name": "Adams Electronics Inc    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 442207,
    "name": "Adams Electronics Intl Ltd    ( Forest Row  England )",
    "country": "UK"
  },
  {
    "id": 384280,
    "name": "Adams Healthcare Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 452812,
    "name": "ADANI    ( Minsk  Belarus )",
    "country": "Belarus"
  },
  {
    "id": 455347,
    "name": "ADANI Ltd    ( Kettering  England )",
    "country": "UK"
  },
  {
    "id": 175017,
    "name": "AdaptAble Designs Inc    ( Camano Island WA USA )",
    "country": "United States"
  },
  {
    "id": 103159,
    "name": "Adaptive Driving Systems SLO Inc    ( San Luis Obispo CA USA )",
    "country": "United States"
  },
  {
    "id": 176046,
    "name": "Adaptive Engineering Lab Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 322787,
    "name": "Adaptive Equipment Systems Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 451516,
    "name": "Adaptivemall.com LLC    ( Dolgeville NY USA )",
    "country": "United States"
  },
  {
    "id": 104103,
    "name": "Adapto Storage Products    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 103093,
    "name": "ADC Hospital Equipment    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 399779,
    "name": "ADC Philippines Inc    ( Pasig City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 451554,
    "name": "ADCO Hearing Products Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 403718,
    "name": "ADCO Medical    ( Bangor ME USA )",
    "country": "United States"
  },
  {
    "id": 101012,
    "name": "Adcoa Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 186383,
    "name": "Adcock Ingram International Div    ( Bryanston  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 105865,
    "name": "Adden Furniture Inc    ( Lowell MA USA )",
    "country": "United States"
  },
  {
    "id": 454505,
    "name": "AdDent Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 457992,
    "name": "ADDER Technology    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 107522,
    "name": "Addto Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 170146,
    "name": "Adel-Lawrence Assoc Inc    ( Aberdeen NJ USA )",
    "country": "United States"
  },
  {
    "id": 292804,
    "name": "Adelberg Laboratories    ( Encino CA USA )",
    "country": "United States"
  },
  {
    "id": 292805,
    "name": "Adelphi Mfg Co Ltd    ( Haywards Heath  England )",
    "country": "UK"
  },
  {
    "id": 170147,
    "name": "Adelsys Inc    ( Cleveland Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 329912,
    "name": "Adenna Inc    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 456577,
    "name": "adeor Medical Technologies GmbH    ( Pullach  Germany )",
    "country": "Germany"
  },
  {
    "id": 107202,
    "name": "Adept-Med International Inc    ( Diamond Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 335237,
    "name": "ADEX Medical Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 255275,
    "name": "Adeza Biomedical Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 455145,
    "name": "ADF BioMedical Sarl    ( Neuville-sur-Oise  France )",
    "country": "France"
  },
  {
    "id": 173958,
    "name": "ADHESIA Laboratoire SAS    ( Flaxlanden  France )",
    "country": "France"
  },
  {
    "id": 456420,
    "name": "Adhezion Biomedical LLC    ( Wyomissing PA USA )",
    "country": "United States"
  },
  {
    "id": 454704,
    "name": "Adhinter SA    ( Cali  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 284578,
    "name": "Adi Hassel Ing -Buero fuer Prozesstechn und    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452402,
    "name": "ADI Time    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 464115,
    "name": "ADIA Medical Equipment Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 390986,
    "name": "Adiatec SA    ( Nantes  France )",
    "country": "France"
  },
  {
    "id": 456745,
    "name": "Adin Dental Implant Systems Ltd    ( Afula  Israel )",
    "country": "Israel"
  },
  {
    "id": 458849,
    "name": "ADInstruments Ltd    ( Dunedin  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 458213,
    "name": "Adirondack Cabling Inc    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 454421,
    "name": "Adivon BioSciences Inc    ( Ithaca NY USA )",
    "country": "United States"
  },
  {
    "id": 102207,
    "name": "Adjustable Fixture Co    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 328110,
    "name": "ADL Data Systems Inc    ( Hawthorne NY USA )",
    "country": "United States"
  },
  {
    "id": 291265,
    "name": "ADM Tronics Unlimited Inc    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 371477,
    "name": "Admar Diagnostic Instruments Inc    ( Bellmore NY USA )",
    "country": "United States"
  },
  {
    "id": 451371,
    "name": "ADMECO AG    ( Hochdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999975,
    "name": "ADMIRAL REFRIGERATORS    (   USA )",
    "country": "United States"
  },
  {
    "id": 286643,
    "name": "Adolf Schweickhardt GmbH & Co    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451369,
    "name": "ADOX SA    ( Ituzaingo  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 144234,
    "name": "Adroit Medical Systems Inc    ( Loudon TN USA )",
    "country": "United States"
  },
  {
    "id": 456816,
    "name": "ADSTEC Corp    ( Funabashi  Japan )",
    "country": "Japan"
  },
  {
    "id": 458207,
    "name": "Adtec Digital    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 439857,
    "name": "Aduromed Corp    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 186642,
    "name": "Adva-Lite Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 439088,
    "name": "ADVAN International Corp    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 455374,
    "name": "ADVAN Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 148139,
    "name": "Advance Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 398890,
    "name": "Advance Galatrek    ( Wrexham  Wales )",
    "country": "Wales"
  },
  {
    "id": 176048,
    "name": "Advance Medequip    ( Sanford FL USA )",
    "country": "United States"
  },
  {
    "id": 171704,
    "name": "Advance Medical Designs Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 452095,
    "name": "Advance Mobility    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 353282,
    "name": "Advanced Air Technologies Inc    ( Corunna MI USA )",
    "country": "United States"
  },
  {
    "id": 378249,
    "name": "Advanced American Biotechnology & Imaging    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 455929,
    "name": "Advanced Analytical Technologies Inc    ( Ames IA USA )",
    "country": "United States"
  },
  {
    "id": 430960,
    "name": "Advanced Answers On Demand Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 470542,
    "name": "Advanced Artificial Eyes    ( Tarzana CA USA )",
    "country": "United States"
  },
  {
    "id": 458212,
    "name": "Advanced Audio Visual Sales Inc    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 108561,
    "name": "Advanced Battery Systems LLC    ( East Moline IL USA )",
    "country": "United States"
  },
  {
    "id": 440004,
    "name": "Advanced Biomaterial Systems Inc    ( Chatham NJ USA )",
    "country": "United States"
  },
  {
    "id": 372917,
    "name": "Advanced Biomedical Devices Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 185835,
    "name": "Advanced Biomedical Electronics Inc    ( Slidell LA USA )",
    "country": "United States"
  },
  {
    "id": 398108,
    "name": "Advanced Bionics Australia    ( Melbourne  USA )",
    "country": "United States"
  },
  {
    "id": 184870,
    "name": "Advanced Bionics Corp    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 398107,
    "name": "Advanced Bionics GmbH    ( Merzig  USA )",
    "country": "United States"
  },
  {
    "id": 398109,
    "name": "Advanced Bionics Latin America    ( Santa Fe de Bogota  USA )",
    "country": "United States"
  },
  {
    "id": 341415,
    "name": "Advanced Bionics Sarl    ( Rixheim  France )",
    "country": "France"
  },
  {
    "id": 398110,
    "name": "Advanced Bionics UK Ltd    ( Great Shelford  England )",
    "country": "UK"
  },
  {
    "id": 153798,
    "name": "Advanced BioScience Laboratories Inc    ( Kensington MD USA )",
    "country": "United States"
  },
  {
    "id": 359434,
    "name": "Advanced Biosensor Inc    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 398982,
    "name": "Advanced Biotechnologies Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 464978,
    "name": "Advanced Business Systems LLC    ( Pass Christian MS USA )",
    "country": "United States"
  },
  {
    "id": 458647,
    "name": "Advanced Cell Diagnostics Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 458067,
    "name": "Advanced ChemTech    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 458648,
    "name": "Advanced Circulatory Systems Inc    ( Roseville MN USA )",
    "country": "United States"
  },
  {
    "id": 458677,
    "name": "Advanced Computer & Network Corp    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 410421,
    "name": "Advanced Containment Systems Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 453333,
    "name": "Advanced Data Systems Corp    ( Paramus NJ USA )",
    "country": "United States"
  },
  {
    "id": 107961,
    "name": "Advanced Electronics Systems Inc    ( Chambersburg PA USA )",
    "country": "United States"
  },
  {
    "id": 458680,
    "name": "Advanced Elevator Solutions Inc    ( Emeryville CA USA )",
    "country": "United States"
  },
  {
    "id": 360637,
    "name": "Advanced Endoscopy Devices Inc    ( Canoga Park CA USA )",
    "country": "United States"
  },
  {
    "id": 459397,
    "name": "Advanced Fitness Group    ( Cottage Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 454839,
    "name": "Advanced Healthcare Ltd    ( Tonbridge  England )",
    "country": "UK"
  },
  {
    "id": 453952,
    "name": "Advanced Healthcare Technology Ltd    ( Sudbury  England )",
    "country": "UK"
  },
  {
    "id": 459160,
    "name": "Advanced Home Care Inc    ( West Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 185648,
    "name": "Advanced Imaging Concepts    ( Moore OK USA )",
    "country": "United States"
  },
  {
    "id": 434169,
    "name": "Advanced Imaging Concepts Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 451433,
    "name": "Advanced Imaging Research Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 407352,
    "name": "Advanced Imaging Technologies LLC    ( Westminster MD USA )",
    "country": "United States"
  },
  {
    "id": 458208,
    "name": "Advanced Informatics    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 458345,
    "name": "Advanced Innovation Solutions Inc (AIS)    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 104370,
    "name": "Advanced Instrument Development Inc    ( Downers Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 330169,
    "name": "Advanced Instrumentations Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 101041,
    "name": "Advanced Instruments Inc    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 393475,
    "name": "Advanced Integrated Medical    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 441268,
    "name": "Advanced Labelworx Inc    ( Oak Ridge TN USA )",
    "country": "United States"
  },
  {
    "id": 172512,
    "name": "Advanced Management for Medical Equipment Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 102693,
    "name": "Advanced Mechanical Technology Inc (AMTI)    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 458215,
    "name": "Advanced Medical Innovations    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 453471,
    "name": "Advanced Medical Optics Australia Pty Ltd    ( Pymble  Australia )",
    "country": "Australia"
  },
  {
    "id": 415324,
    "name": "Advanced Medical Optics GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 415319,
    "name": "Advanced Medical Optics Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 451025,
    "name": "Advanced Medical Optics Spain SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 458293,
    "name": "Advanced Medical Simulations    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 453635,
    "name": "Advanced Medical Solutions Ltd    ( Winsford  England )",
    "country": "UK"
  },
  {
    "id": 454295,
    "name": "Advanced Medical Systems    ( Montgomeryville PA USA )",
    "country": "United States"
  },
  {
    "id": 184306,
    "name": "Advanced Medical Systems (AMS)    ( West Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 106271,
    "name": "Advanced Medical Systems Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 194769,
    "name": "Advanced Meditech International Inc    ( Flushing NY USA )",
    "country": "United States"
  },
  {
    "id": 466452,
    "name": "Advanced Micro Instruments Inc (AMI)    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 458196,
    "name": "Advanced Microscopy Techniques Corp    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 175755,
    "name": "Advanced Mobility Inc    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 174518,
    "name": "Advanced Mobility Systems Corp    ( Kingston ON Canada )",
    "country": "Canada"
  },
  {
    "id": 372489,
    "name": "Advanced Neuromodulation Systems Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 456054,
    "name": "Advanced Opticals    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 464083,
    "name": "Advanced Optisurgical Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 999439,
    "name": "Advanced Optisurgical Inc.    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 458071,
    "name": "Advanced Orthogonal Equipment Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 455033,
    "name": "Advanced Orthopaedic Solutions    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 458210,
    "name": "Advanced Practice Strategies Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 172210,
    "name": "Advanced Products SA    ( Fribourg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458216,
    "name": "Advanced Radiation Therapy LLC    ( Tyngsboro MA USA )",
    "country": "United States"
  },
  {
    "id": 384415,
    "name": "Advanced Renal Technologies Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 458227,
    "name": "Advanced Research Systems Inc    ( Macungie PA USA )",
    "country": "United States"
  },
  {
    "id": 398536,
    "name": "Advanced Respiratory    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 186653,
    "name": "Advanced Sterilization Products    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 307241,
    "name": "Advanced Sterilization Products (Belgium)    ( Dilbeek  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 306945,
    "name": "Advanced Sterilization Products (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450850,
    "name": "Advanced Surfaces    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 474857,
    "name": "Advanced Surgical Concepts (ASC)    ( County Wicklow  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 452767,
    "name": "Advanced Surgical Design & Mfr Ltd    ( St Leonards  Australia )",
    "country": "Australia"
  },
  {
    "id": 458240,
    "name": "Advanced Technologies Group Inc    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 456098,
    "name": "Advanced Technology & Materials Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101071,
    "name": "ADVANCED TECHNOLOGY LABORATORY    (   USA )",
    "country": "United States"
  },
  {
    "id": 103381,
    "name": "Advanced Technology Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 393498,
    "name": "Advanced Test Products (ATP)    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 458570,
    "name": "Advanced Therapy Products Inc    ( Glen Allen VA USA )",
    "country": "United States"
  },
  {
    "id": 330172,
    "name": "Advanced Transducer Services Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 457042,
    "name": "Advanced Ultrasound Electronics    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 465081,
    "name": "Advanced Vapor Technologies    ( Everett 59 USA )",
    "country": "United States"
  },
  {
    "id": 391946,
    "name": "Advanced Vascular Dynamics A Semler Technologies C    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 398165,
    "name": "Advanced Vision Science Inc    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 440697,
    "name": "Advanced Visual Instruments Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 453797,
    "name": "AdvanDx A/S    ( Vedbaek  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453796,
    "name": "AdvanDx Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 454155,
    "name": "AdvanSource Biomaterials Corp    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 451680,
    "name": "Advantage Healthcare    ( Mordialloc  Australia )",
    "country": "Australia"
  },
  {
    "id": 416082,
    "name": "Advantage Laboratories    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 185656,
    "name": "Advantage Medical Cables Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 106083,
    "name": "Advantage Medical Systems Inc    ( Lake Mary FL USA )",
    "country": "United States"
  },
  {
    "id": 458238,
    "name": "Advantage Sterilizer Cleaning    ( Casselberry FL USA )",
    "country": "United States"
  },
  {
    "id": 457696,
    "name": "Advantage Urinal Systems    ( Bountiful UT USA )",
    "country": "United States"
  },
  {
    "id": 452107,
    "name": "AdvantaPure    ( Southampton PA USA )",
    "country": "United States"
  },
  {
    "id": 460735,
    "name": "Advantec MFS Inc    ( Dublin CA USA )",
    "country": "United States"
  },
  {
    "id": 458244,
    "name": "Advantech Co Ltd    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999738,
    "name": "Advantech Co., Ltd.    ( Milpitas CA USA )",
    "country": "USA"
  },
  {
    "id": 451042,
    "name": "Advent Medical    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 456006,
    "name": "Adventa Health Sdn Bhd    ( Kota Bharu  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 455353,
    "name": "Advion BioSciences Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 462540,
    "name": "ADVIZOR Solutions Inc    ( Downers Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 346964,
    "name": "Aearo Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 467012,
    "name": "AED Brands    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 286925,
    "name": "Aeg Aktiengesellschaft Fachbereich Mikrowellen-    ( Ulm  USA )",
    "country": "United States"
  },
  {
    "id": 286927,
    "name": "Aeg Mobile Communication GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 431376,
    "name": "Aegis Scientific    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 377206,
    "name": "AEI Inc    ( Caversham  USA )",
    "country": "United States"
  },
  {
    "id": 450602,
    "name": "AEI Technologies Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 456051,
    "name": "AEMCO ze (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 458685,
    "name": "Aergo Inc    ( Iselin NJ USA )",
    "country": "United States"
  },
  {
    "id": 457818,
    "name": "Aeris Therapeutics    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 108481,
    "name": "Aero All-Gas    ( Hartford CT USA )",
    "country": "United States"
  },
  {
    "id": 108730,
    "name": "Aero Battery Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 175538,
    "name": "Aero Medical Inc    ( West Bend WI USA )",
    "country": "United States"
  },
  {
    "id": 458688,
    "name": "Aero Mfg Co    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 999389,
    "name": "Aerobics Inc - Pacemaster    ( Caldwell NJ USA )",
    "country": "United States"
  },
  {
    "id": 467217,
    "name": "Akorn Pharmaceuticals    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 458701,
    "name": "Aerocom Systems Inc    ( Flowery Branch GA USA )",
    "country": "United States"
  },
  {
    "id": 454238,
    "name": "Aerocrine AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454239,
    "name": "Aerocrine Inc USA    ( New Providence NJ USA )",
    "country": "United States"
  },
  {
    "id": 441167,
    "name": "Aeroflex Inc    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 453493,
    "name": "Aerogen (Ireland) Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 456869,
    "name": "Aerolase    ( Tarrytown NY USA )",
    "country": "United States"
  },
  {
    "id": 451614,
    "name": "Aeromed Inc    ( Amsterdam NY USA )",
    "country": "United States"
  },
  {
    "id": 459094,
    "name": "Aeromed Software LLC    ( Lebanon NH USA )",
    "country": "United States"
  },
  {
    "id": 457995,
    "name": "Aeromedix LLC    ( Jackson WY USA )",
    "country": "United States"
  },
  {
    "id": 101042,
    "name": "Aeros Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 451478,
    "name": "AeroScout Europe    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453725,
    "name": "AeroScout Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 455239,
    "name": "AeroScout Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455238,
    "name": "AeroScout Ltd    ( Rahovot  Israel )",
    "country": "Israel"
  },
  {
    "id": 456969,
    "name": "AeroScout Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457843,
    "name": "Aerotech GmbH    ( Nuremberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457840,
    "name": "Aerotech Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 457844,
    "name": "Aerotech KK    ( Funabashi  Japan )",
    "country": "Japan"
  },
  {
    "id": 457842,
    "name": "Aerotech Ltd    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 162948,
    "name": "Aerotel Medical Systems (1998) Ltd    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 333540,
    "name": "Aerscher Diagnostics Inc    ( Chestertown MD USA )",
    "country": "United States"
  },
  {
    "id": 455441,
    "name": "AES Chemunex GmbH    ( Angelbachtal  Germany )",
    "country": "Germany"
  },
  {
    "id": 455443,
    "name": "AES Chemunex Inc    ( Cranbury NJ USA )",
    "country": "United States"
  },
  {
    "id": 455440,
    "name": "AES Chemunex Italy    ( Noventa (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455444,
    "name": "AES Chemunex Ltd    ( Stansted  England )",
    "country": "UK"
  },
  {
    "id": 455442,
    "name": "AES Chemunex SA    ( Terrassa (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 183435,
    "name": "AES Laboratoire    ( Bruz Cedex  France )",
    "country": "France"
  },
  {
    "id": 397941,
    "name": "Aescudata GmbH    ( Winsen (Luhe)  Germany )",
    "country": "Germany"
  },
  {
    "id": 139214,
    "name": "Aesculap AG & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306946,
    "name": "Aesculap AG/Downs Surgical UK    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 101658,
    "name": "Aesculap Inc    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 186629,
    "name": "Aesculap Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 458218,
    "name": "AESKU Systems GmbH & Co    ( Wendelsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 320791,
    "name": "Aesthetic Lasers Inc    ( Annapolis MD USA )",
    "country": "United States"
  },
  {
    "id": 465070,
    "name": "Aesynt Inc    ( Cranberry Township PA USA )",
    "country": "United States"
  },
  {
    "id": 279586,
    "name": "Aet-GmbH    ( Au/Hallertau  Germany )",
    "country": "Germany"
  },
  {
    "id": 453688,
    "name": "Aethon Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 393187,
    "name": "AETmed SpA    ( Genova GE  USA )",
    "country": "United States"
  },
  {
    "id": 450909,
    "name": "Aetrex Worldwide    ( Teaneck NJ USA )",
    "country": "United States"
  },
  {
    "id": 108855,
    "name": "AEV (American Emergency Vehicles)    ( Jefferson NC USA )",
    "country": "United States"
  },
  {
    "id": 459294,
    "name": "AEVERL MEDICAL LLC    ( Gainesville GA USA )",
    "country": "United States"
  },
  {
    "id": 384301,
    "name": "AF Technology Co Ltd    ( Zhuhai  USA )",
    "country": "United States"
  },
  {
    "id": 108351,
    "name": "Afassco Inc    ( Minden NV USA )",
    "country": "United States"
  },
  {
    "id": 463007,
    "name": "AFC Industries Inc    ( College Point NY USA )",
    "country": "United States"
  },
  {
    "id": 455108,
    "name": "Affymetrix China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 251667,
    "name": "Affymetrix Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 455106,
    "name": "Affymetrix Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455107,
    "name": "Affymetrix Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 390963,
    "name": "Affymetrix UK Ltd    ( High Wycomb  England )",
    "country": "UK"
  },
  {
    "id": 185633,
    "name": "AFI Inc    ( Walpole MA USA )",
    "country": "United States"
  },
  {
    "id": 172254,
    "name": "Afikim Electric mobilizers Ltd    ( Kibbutz Afikim  Israel )",
    "country": "Israel"
  },
  {
    "id": 463008,
    "name": "AFL    ( Duncan SC USA )",
    "country": "United States"
  },
  {
    "id": 357258,
    "name": "Afos Ltd    ( Hessle  England )",
    "country": "UK"
  },
  {
    "id": 103696,
    "name": "AFP Imaging Corp    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 324821,
    "name": "AFP Medical    ( Rugby  England )",
    "country": "UK"
  },
  {
    "id": 455689,
    "name": "Afri Medical Egypt    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 453406,
    "name": "African Oxygen Ltd    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 390988,
    "name": "Afzal Saeed Mfg Corp    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 330159,
    "name": "AGA Biomedica srl    ( Cava de Tirreni (SA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457497,
    "name": "AGA Gas CA    ( Caracas  Venezuela )",
    "country": "Venezuela"
  },
  {
    "id": 282315,
    "name": "Aga Gas GmbH    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 457758,
    "name": "AGA Group International    ( Ambala  India )",
    "country": "India"
  },
  {
    "id": 384319,
    "name": "AGA Medical Corp    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 158074,
    "name": "AGA-Sanitaetsartikel GmbH    ( Loehne  Germany )",
    "country": "Germany"
  },
  {
    "id": 456162,
    "name": "AgaMatrix    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 185659,
    "name": "Agape Instruments Service Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 454557,
    "name": "Agastha Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 454643,
    "name": "Agastha Software Pvt Ltd    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 466613,
    "name": "Agena Bioscience    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 306632,
    "name": "Agencia Matamoros    ( San Pedro Sula  Honduras )",
    "country": "Honduras"
  },
  {
    "id": 152067,
    "name": "Agencinox SA    ( Aillant Sur Tholon  France )",
    "country": "France"
  },
  {
    "id": 454264,
    "name": "AgenCourt Biosciences Corp    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 454586,
    "name": "Agency for Medical Innovationa (AMI)    ( Feldkirch  Austria )",
    "country": "Austria"
  },
  {
    "id": 455015,
    "name": "Agendia bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455016,
    "name": "Agendia Inc USA    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 408238,
    "name": "Agfa de Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 161138,
    "name": "Agfa Deutschland    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 409923,
    "name": "Agfa HealthCare (US)    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 409924,
    "name": "Agfa Healthcare Inc (Canada)    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 193770,
    "name": "Agfa Hong Kong Ltd    ( Wong Chuk Hang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 306947,
    "name": "Agfa Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 376478,
    "name": "Agfa-Gevaert AG/SA    ( Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 376485,
    "name": "Agfa-Gevaert Argentina SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 441333,
    "name": "Agfa-Gevaert bv    ( Rijswijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 376474,
    "name": "Agfa-Gevaert Ges mbH (Austria)    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 376481,
    "name": "Agfa-Gevaert Japan Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 376483,
    "name": "Agfa-Gevaert Ltd (Australia)    ( Scoresby  Australia )",
    "country": "Australia"
  },
  {
    "id": 324525,
    "name": "Agfa-Gevaert Ltd (UK)    ( Brentford  England )",
    "country": "UK"
  },
  {
    "id": 150944,
    "name": "Agfa-Gevaert NV    ( Mortsel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 376477,
    "name": "Agfa-Gevaert SA (France)    ( Ivry-sur-Seine Cedex  France )",
    "country": "France"
  },
  {
    "id": 376480,
    "name": "Agfa-Gevaert SA (Spain)    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 376479,
    "name": "Agfa-Gevaert SpA    ( Cinisello Balsamo (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455435,
    "name": "Agilent Technologies (Schweiz) AG    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452177,
    "name": "Agilent Technologies (Suisse) AG    ( Morges  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455436,
    "name": "Agilent Technologies Australia    ( Forest Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 454534,
    "name": "Agilent Technologies Basil    ( Alphaville Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 376574,
    "name": "Agilent Technologies Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 376598,
    "name": "Agilent Technologies Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 455438,
    "name": "Agilent Technologies Japan    ( Hachioji  Japan )",
    "country": "Japan"
  },
  {
    "id": 455437,
    "name": "Agilent Technologies UK Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 440678,
    "name": "Agility Healthcare Solutions LLC    ( Glen Allen VA USA )",
    "country": "United States"
  },
  {
    "id": 460743,
    "name": "Agility Recovery Solutions    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 458211,
    "name": "Agilysys Corp Services    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 452544,
    "name": "Agofa Lab    ( Ahmedabad  India )",
    "country": "India"
  },
  {
    "id": 107097,
    "name": "Agusta Aerospace Corp    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 140716,
    "name": "Agusta SpA    ( Cascina Costa di Samarte (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459161,
    "name": "AHI of Indiana Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458221,
    "name": "AHI Software Inc    ( Miami Lakes FL USA )",
    "country": "United States"
  },
  {
    "id": 149564,
    "name": "Ahlberg Rehabilitation    ( Fjarhundra  USA )",
    "country": "United States"
  },
  {
    "id": 398010,
    "name": "AHN Biotechnologie GmbH    ( Nordhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 155943,
    "name": "Ahnafield Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 454460,
    "name": "Ahura Scientific Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 458243,
    "name": "AI Medical Devices Inc    ( Williamston MI USA )",
    "country": "United States"
  },
  {
    "id": 441588,
    "name": "AI Scientific Co Inc    ( Saxonburg PA USA )",
    "country": "United States"
  },
  {
    "id": 441591,
    "name": "AI Scientific Pty Ltd    ( Clontarf  Australia )",
    "country": "Australia"
  },
  {
    "id": 452247,
    "name": "Aidapt Bathrooms Ltd    ( St Helens  England )",
    "country": "UK"
  },
  {
    "id": 453464,
    "name": "Aidservice Ltd    ( Wigan  England )",
    "country": "UK"
  },
  {
    "id": 456917,
    "name": "AIM Instrumentation & Technologies    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 292848,
    "name": "AIM Plastics Group Ltd    ( Tunbridge Wells  England )",
    "country": "UK"
  },
  {
    "id": 155790,
    "name": "AIM Software Systems Inc    ( Islandia NY USA )",
    "country": "United States"
  },
  {
    "id": 175473,
    "name": "AIMS Multimedia    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 105455,
    "name": "Ainsworth Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 374971,
    "name": "Aiphone Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 402397,
    "name": "Aiphone Communications (Thailand) Co Ltd    ( Chonburi  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 384358,
    "name": "Aiphone Corp    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 332109,
    "name": "Aiphone Europe NV    ( Antwerpen  USA )",
    "country": "United States"
  },
  {
    "id": 337939,
    "name": "Aiphone Hong Kong Co Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 321298,
    "name": "Aiphone Kommunikation GmbH    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 371696,
    "name": "Aiphone SA    ( Evry Cedex  France )",
    "country": "France"
  },
  {
    "id": 183895,
    "name": "Air & Pump Inc    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 105484,
    "name": "Air Ambulance Systems Div Business Aviation    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 102210,
    "name": "Air Control Inc    ( Henderson NC USA )",
    "country": "United States"
  },
  {
    "id": 460752,
    "name": "Air Control Techniques PC    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 459162,
    "name": "Air Cycle Corp    ( Lisle IL USA )",
    "country": "United States"
  },
  {
    "id": 441587,
    "name": "Air Filters Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 454345,
    "name": "Air Lift CareFore Medical Inc    ( Evergreen CO USA )",
    "country": "United States"
  },
  {
    "id": 106757,
    "name": "Air Lift Unlimited Inc    ( Evergreen CO USA )",
    "country": "United States"
  },
  {
    "id": 107206,
    "name": "Air Link International    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 183088,
    "name": "Air Liquide America Corp    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 454043,
    "name": "Air Liquide America Specialty Gases LLC    ( Plumsteadville PA USA )",
    "country": "United States"
  },
  {
    "id": 177647,
    "name": "Air Liquide Belge    ( Liege  USA )",
    "country": "United States"
  },
  {
    "id": 454044,
    "name": "Air Liquide Healthcare America    ( Plumsteadville PA USA )",
    "country": "United States"
  },
  {
    "id": 459225,
    "name": "Air Liquide Healthcare America    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 330175,
    "name": "Air Liquide Hellas    ( Athinia  USA )",
    "country": "United States"
  },
  {
    "id": 455488,
    "name": "Air Liquide Medical Systems France    ( Antony Cedex  France )",
    "country": "France"
  },
  {
    "id": 466619,
    "name": "Air Master Systems Corp    ( Muskegon MI USA )",
    "country": "United States"
  },
  {
    "id": 338588,
    "name": "Air Med Assist Products Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 108721,
    "name": "Air Methods Corp    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 450818,
    "name": "Air Movement Technologies    ( North Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 392936,
    "name": "Air Pal Patient Transfer Systems    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 102472,
    "name": "Air Products and Chemicals Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 455433,
    "name": "Air Products plc    ( Crewe  England )",
    "country": "UK"
  },
  {
    "id": 453407,
    "name": "Air Products Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 298368,
    "name": "Air Quality Engineering Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 398121,
    "name": "Air Safety Ltd    ( Morecambe  England )",
    "country": "UK"
  },
  {
    "id": 453974,
    "name": "Air Science LLC    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 453975,
    "name": "Air Science UK Ltd    ( Liverpool  England )",
    "country": "UK"
  },
  {
    "id": 105888,
    "name": "Air Techniques Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 103700,
    "name": "Air Techniques International    ( Owings Mills MD USA )",
    "country": "United States"
  },
  {
    "id": 455843,
    "name": "Air Water Safety Service Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 415985,
    "name": "Air-Met Scientific Pty Ltd    ( Nunawading  Australia )",
    "country": "Australia"
  },
  {
    "id": 105492,
    "name": "Air-Shields Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 169574,
    "name": "Air-Tite Products Co Inc    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 101024,
    "name": "Airborne Life Support Systems    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 374052,
    "name": "AirCare Therapy Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 343679,
    "name": "Aircast Europe GmbH    ( Neubeuern  USA )",
    "country": "United States"
  },
  {
    "id": 106426,
    "name": "Aircast Inc    ( Summit NJ USA )",
    "country": "United States"
  },
  {
    "id": 155706,
    "name": "Allan Enterprises    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 358361,
    "name": "Airclean Systems    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 999976,
    "name": "Airco Ohio Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 452461,
    "name": "Aircraft Medical Ltd    ( Edinburgh  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 364514,
    "name": "AIResearch Technology Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 347668,
    "name": "Aireshelta Ltd    ( Langley Mill  England )",
    "country": "UK"
  },
  {
    "id": 451209,
    "name": "Airfiltronix Corp    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 999551,
    "name": "Airflow Systems Inc.    ( Dallas TX USA )",
    "country": "USA"
  },
  {
    "id": 171241,
    "name": "Airgas Inc    ( Radnor PA USA )",
    "country": "United States"
  },
  {
    "id": 473816,
    "name": "AiRISTA LLC    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 452274,
    "name": "Airon Corp    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 305620,
    "name": "Aironic Australia Pty Ltd    ( Sydney  USA )",
    "country": "United States"
  },
  {
    "id": 373337,
    "name": "Aironic Middle East    ( Dubai  USA )",
    "country": "United States"
  },
  {
    "id": 373336,
    "name": "Aironic Singapore    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 382727,
    "name": "AIROX    ( Pau Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 107205,
    "name": "AirSep Corp    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 439637,
    "name": "Airsonett AB    ( Angelholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453718,
    "name": "Airsonett Deutschland GmbH    ( Sigmarszell  Germany )",
    "country": "Germany"
  },
  {
    "id": 439639,
    "name": "Airsonett Inc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 453719,
    "name": "Airsonett UK Ltd    ( Tunbridge Wells  England )",
    "country": "UK"
  },
  {
    "id": 462800,
    "name": "Airstar International    ( Le Chamo-Pres-Froges  France )",
    "country": "France"
  },
  {
    "id": 458217,
    "name": "AirStrip Technologies    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 343685,
    "name": "AirTech    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 335413,
    "name": "Airway    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458128,
    "name": "Airway Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 393110,
    "name": "Airway Management Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 451043,
    "name": "Airway Surgical Appliances Ltd    ( Nepean ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451232,
    "name": "AISoftw@re Medical SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 999503,
    "name": "AIT Industries    ( Bensenville IL USA )",
    "country": "USA"
  },
  {
    "id": 465057,
    "name": "AIT Laboratories Inc    ( Indianapolis 22 USA )",
    "country": "United States"
  },
  {
    "id": 459193,
    "name": "ait-deutschland GmbH    ( Kasendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 463040,
    "name": "ait-deutschland GmbH    ( Kasendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 441011,
    "name": "AIV Inc    ( Harmans MD USA )",
    "country": "United States"
  },
  {
    "id": 450952,
    "name": "AJ Mobility Ltd    ( Hailsham  England )",
    "country": "UK"
  },
  {
    "id": 458556,
    "name": "AJ Monier & Co Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 457571,
    "name": "AJ Morgan Eyewear    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 457789,
    "name": "AJ's Candles Inc    ( Alma GA USA )",
    "country": "United States"
  },
  {
    "id": 460760,
    "name": "AJA International Inc    ( North Scituate MA USA )",
    "country": "United States"
  },
  {
    "id": 398122,
    "name": "AJEX Meditech Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 187303,
    "name": "Ajinomoto Trading Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 394337,
    "name": "AK Specialty Vehicles    ( Harvey IL USA )",
    "country": "United States"
  },
  {
    "id": 453993,
    "name": "AKAS Medical    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 462581,
    "name": "AkeLex    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 337968,
    "name": "Akin Medical Equipment International    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 152538,
    "name": "Akisu Machinery Co Ltd    ( Taichung  USA )",
    "country": "United States"
  },
  {
    "id": 455428,
    "name": "AKKUPlanet GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 152068,
    "name": "Akra Dermojet    ( Pau  France )",
    "country": "France"
  },
  {
    "id": 105868,
    "name": "Akro-Mils    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 102400,
    "name": "Akron Catheter Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 281216,
    "name": "Akrus Medizintechnik GmbH & Co KG    ( Elmshorn  Germany )",
    "country": "Germany"
  },
  {
    "id": 286629,
    "name": "AKS-Aktuelle Krankenpflege Systeme    ( Troisdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 297099,
    "name": "Aksys Ltd    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 333449,
    "name": "Aktina Medical Corp    ( Congers NY USA )",
    "country": "United States"
  },
  {
    "id": 143658,
    "name": "AKW Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 452922,
    "name": "AKW MediCare Ltd    ( Droitwich Spa  England )",
    "country": "UK"
  },
  {
    "id": 199550,
    "name": "Akzo Nobel Faser AG    ( Wuppertal  USA )",
    "country": "United States"
  },
  {
    "id": 306985,
    "name": "Al Amin Medical Instruments    ( Jeddah  Saudi Arabia )",
    "country": "Saudi Arabia"
  },
  {
    "id": 176522,
    "name": "Al Carmel Scientific    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 192810,
    "name": "Al Zahrawi Medical    ( Dubai  USA )",
    "country": "United States"
  },
  {
    "id": 446484,
    "name": "Al-e-Ahmed Surgical Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 175426,
    "name": "Alabama ACEP    ( Montgomery AL USA )",
    "country": "United States"
  },
  {
    "id": 458436,
    "name": "Aladdin Temp-Rite    ( Hendersonville TN USA )",
    "country": "United States"
  },
  {
    "id": 451676,
    "name": "Alamo Tissue Services Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 452625,
    "name": "Alan & Co    ( Verviers  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 382223,
    "name": "Alara Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 435473,
    "name": "ALARIS Medical Canada Ltd    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 340085,
    "name": "ALARIS Medical Systems Australia Pty Ltd    ( Seven Hills  USA )",
    "country": "United States"
  },
  {
    "id": 308442,
    "name": "ALARIS Medical Systems Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 340084,
    "name": "ALARIS Medical Systems Ltd    ( Basingstoke  USA )",
    "country": "United States"
  },
  {
    "id": 401856,
    "name": "Alatech Healthcare Products    ( Eufala AL USA )",
    "country": "United States"
  },
  {
    "id": 455350,
    "name": "Alba Bioscience Ltd    ( Edinburgh  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 455766,
    "name": "ALBAAD Pharma    ( Sde Gat  Israel )",
    "country": "Israel"
  },
  {
    "id": 101010,
    "name": "Albahealth LLC    ( Valdese NC USA )",
    "country": "United States"
  },
  {
    "id": 283839,
    "name": "Albaum Dental-Gipspraeparate    ( Lehrte  Germany )",
    "country": "Germany"
  },
  {
    "id": 454698,
    "name": "ALBE & E-Jasco Magyarorszag Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 241856,
    "name": "Albert Browne International Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 407226,
    "name": "Albert Browne Ltd    ( Leicester  USA )",
    "country": "United States"
  },
  {
    "id": 398113,
    "name": "ALBERT International Inc    ( Gainesville GA USA )",
    "country": "United States"
  },
  {
    "id": 398112,
    "name": "ALBERT Kohlkoerper GmbH & Co KG    ( Hemer  Germany )",
    "country": "Germany"
  },
  {
    "id": 398114,
    "name": "ALBERT Polska Sp z o o    ( Dobczyce  Poland )",
    "country": "Poland"
  },
  {
    "id": 452433,
    "name": "Albert Waeschle Ltd    ( Poole  England )",
    "country": "UK"
  },
  {
    "id": 153002,
    "name": "Albert-Wetzlar GmbH    ( Wetzlar/Garbenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454971,
    "name": "Albrecht Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 364263,
    "name": "Albugam AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 171323,
    "name": "Albyn Medical Ltd    ( Dingwall  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 453633,
    "name": "Albyn Medical SL    ( Cordovilla (Navarra)  Spain )",
    "country": "Spain"
  },
  {
    "id": 347005,
    "name": "ALC    (   USA )",
    "country": "United States"
  },
  {
    "id": 176042,
    "name": "ALC Inc (Action-Lift Chairs)    ( Germantown WI USA )",
    "country": "United States"
  },
  {
    "id": 450857,
    "name": "Alcan Packaging    ( Shelbyville KY USA )",
    "country": "United States"
  },
  {
    "id": 282155,
    "name": "ALCARE Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454248,
    "name": "Alcatel-Lucent (France)    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 453083,
    "name": "Alcatel-Lucent (USA)    ( Murray Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 451044,
    "name": "Alcavis International Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 174091,
    "name": "Alco Electric Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455626,
    "name": "Alco Sales & Service Co    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 392053,
    "name": "Alcohol Countermeasure Systems (AA) Pty Ltd    ( Castle Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 392054,
    "name": "Alcohol Countermeasure Systems (SC)    ( Haninge  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 178811,
    "name": "Alcohol Countermeasure Systems Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 104995,
    "name": "Alcohol Countermeasure Systems Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 451418,
    "name": "Alcon Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 450971,
    "name": "Alcon Eye Care UK Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 456543,
    "name": "Alcon Grieshaber AG    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455487,
    "name": "Alcon Inc    ( Huenenberg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 271237,
    "name": "Alcon Laboratories Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 281722,
    "name": "Alcon Pharma GmbH    ( Freiburg im Breisgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 279059,
    "name": "Alcon SA Laboratoires    ( Rueil-Malmaison Cedex  France )",
    "country": "France"
  },
  {
    "id": 102401,
    "name": "Alconox Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 100001,
    "name": "Alcopro Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 460782,
    "name": "ALCOR Scientific Inc    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 999422,
    "name": "ALCOR Scientific Inc.    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 107024,
    "name": "Alcott Chromatography Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 457954,
    "name": "Alcura UK Ltd    ( Alton  England )",
    "country": "UK"
  },
  {
    "id": 455021,
    "name": "Aldagen Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 108948,
    "name": "Alden Medical LLC    ( West Springfield MA USA )",
    "country": "United States"
  },
  {
    "id": 403719,
    "name": "Alden Surgical    ( Mineola NY USA )",
    "country": "United States"
  },
  {
    "id": 455590,
    "name": "Alere GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 455254,
    "name": "Alere Health LLC    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 455258,
    "name": "Alere Health LLC (Australia)    ( Sinnamon Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 455589,
    "name": "Alere Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 458990,
    "name": "Alere Informatics Solutions    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 417319,
    "name": "Alere Medical Inc    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 455588,
    "name": "Alere North America Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 335453,
    "name": "Alert Care Inc    ( Tilburon CA USA )",
    "country": "United States"
  },
  {
    "id": 454626,
    "name": "ALERT Life Sciences Computing Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 466471,
    "name": "Alert Scientific Inc    ( East Hartford IL USA )",
    "country": "United States"
  },
  {
    "id": 144354,
    "name": "Alex Orthopedic Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 455767,
    "name": "Alexandave Industries Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 284383,
    "name": "Alexander Batteries GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 465012,
    "name": "Alexander Open Systems    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 104096,
    "name": "Alexander Technologies    ( Mason City IA USA )",
    "country": "United States"
  },
  {
    "id": 428631,
    "name": "Alexander Technologies Ltd    ( Peterlee  England )",
    "country": "UK"
  },
  {
    "id": 457468,
    "name": "Alexandra Hock GmbH & Co KG    ( Grosskrotzenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 431619,
    "name": "Alexeter Technologies LLC    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 151462,
    "name": "Alfa Medical Equipment Inc    ( Hempstead NY USA )",
    "country": "United States"
  },
  {
    "id": 393124,
    "name": "Alfa Scientific Designs Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 392249,
    "name": "Alfa Wassermann bv    ( Woerden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392250,
    "name": "Alfa Wassermann Inc    ( West Caldwell NJ USA )",
    "country": "United States"
  },
  {
    "id": 255224,
    "name": "Alfa Wassermann SpA    ( Bologna BO  USA )",
    "country": "United States"
  },
  {
    "id": 285207,
    "name": "Alfred Becht GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 431497,
    "name": "Alfred Kaercher GmbH & Co KG    ( Winnenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 287525,
    "name": "Alfred Kaut GmbH & Co    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 282742,
    "name": "Alfred Mank GmbH    ( Harschbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 455844,
    "name": "Alfresa Pharma Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 457202,
    "name": "ALGA-Bis sc    ( Siedlecka  Poland )",
    "country": "Poland"
  },
  {
    "id": 102505,
    "name": "Algen Scale Corp    ( Islandia NY USA )",
    "country": "United States"
  },
  {
    "id": 101044,
    "name": "Alger Equipment Co Inc    ( Lago Vista TX USA )",
    "country": "United States"
  },
  {
    "id": 393692,
    "name": "Algotec (France)    ( Charenton-le Pont  USA )",
    "country": "United States"
  },
  {
    "id": 393691,
    "name": "Algotec GmbH    ( Wiesbaden  USA )",
    "country": "United States"
  },
  {
    "id": 328118,
    "name": "Algotec Systems Ltd    ( Ra'anana  USA )",
    "country": "United States"
  },
  {
    "id": 341436,
    "name": "Alick's Home Medical Equipment Inc    ( South Bend IN USA )",
    "country": "United States"
  },
  {
    "id": 408152,
    "name": "Alidex Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 398115,
    "name": "Alifax SpA    ( Polverara (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457165,
    "name": "Align Technology BV    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457164,
    "name": "Align Technology Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 457893,
    "name": "Aligned Medical Solutions    ( Billings MT USA )",
    "country": "United States"
  },
  {
    "id": 101068,
    "name": "AliMed Inc    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 999527,
    "name": "Alinco Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 999726,
    "name": "Aline Heat Seal Corp    ( Cerritos CA USA )",
    "country": "USA"
  },
  {
    "id": 473845,
    "name": "AliveCor Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 428140,
    "name": "ALK-Abello AS    ( Horsholm  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 428144,
    "name": "ALK-Abello Inc    ( Round Rock TX USA )",
    "country": "United States"
  },
  {
    "id": 458755,
    "name": "Alkali Scientific    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 104845,
    "name": "Alkco Lighting    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 453446,
    "name": "All Brand Medical Corp    ( Norhtbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 466739,
    "name": "All Day Medical Ltd    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 306948,
    "name": "All Eight Marketing Services Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 455178,
    "name": "All Green Electronics Recycling    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 185665,
    "name": "All Imaging Systems Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 176054,
    "name": "All Pro Exercise Products Inc    ( Longboat Key FL USA )",
    "country": "United States"
  },
  {
    "id": 460590,
    "name": "All Star X-Ray Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 104398,
    "name": "All-Craft Wellman Products Inc    ( Willoughby OH USA )",
    "country": "United States"
  },
  {
    "id": 108462,
    "name": "All-Pro Imaging    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 454965,
    "name": "All-Terrain Medical & Mobility LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 104971,
    "name": "All-Tronics Medical Systems Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 398124,
    "name": "ALL.DIAG    ( Strasbourg Cedex  France )",
    "country": "France"
  },
  {
    "id": 324509,
    "name": "AllBatteries UK Ltd    ( Solihul  England )",
    "country": "UK"
  },
  {
    "id": 398118,
    "name": "allectric GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 371960,
    "name": "Allegiance Healthcare Corp Thermal Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 460801,
    "name": "Allegion plc    ( Dublin  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 458754,
    "name": "Allegro Concepts Pty Ltd    ( Lidcombe  Australia )",
    "country": "Australia"
  },
  {
    "id": 409918,
    "name": "Allegro Industries    ( Garden Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 455610,
    "name": "Allegro Medical    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 441019,
    "name": "Allen Industries Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 107088,
    "name": "Allen Medical Instruments Corp    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 107093,
    "name": "Allen Medical Systems    ( Acton MA USA )",
    "country": "United States"
  },
  {
    "id": 454223,
    "name": "Allen Medical Systems (UK)    ( Ashby de la Zouch  England )",
    "country": "UK"
  },
  {
    "id": 454658,
    "name": "Allen Systems Group (ASG) (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454657,
    "name": "Allen Systems Group (ASG) Australia    ( Canberra  Australia )",
    "country": "Australia"
  },
  {
    "id": 454651,
    "name": "Allen Systems Group (ASG) Denmark    ( Kobenhaven  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454649,
    "name": "Allen Systems Group (ASG) France    ( Paris La Defense Cedex  France )",
    "country": "France"
  },
  {
    "id": 454650,
    "name": "Allen Systems Group (ASG) Germany    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 454652,
    "name": "Allen Systems Group (ASG) Spain    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 454648,
    "name": "Allen Systems Group Inc (ASG) Canada    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454645,
    "name": "Allen Systems Group Inc (ASG) USA    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 452414,
    "name": "Allen Technologies Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 451618,
    "name": "Allen-Vanguard Corp    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453118,
    "name": "Allen-Vanguard Inc    ( Ashburn VA USA )",
    "country": "United States"
  },
  {
    "id": 453119,
    "name": "Allen-Vanguard Ltd    ( Tewkesbury  England )",
    "country": "UK"
  },
  {
    "id": 451661,
    "name": "Allendale Pharmaceuticals Inc    ( Allendale NJ USA )",
    "country": "United States"
  },
  {
    "id": 458246,
    "name": "Allentown Inc    ( Allentown NJ USA )",
    "country": "United States"
  },
  {
    "id": 441578,
    "name": "AllerAir    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455137,
    "name": "Allergan AG    ( Pfaffikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453808,
    "name": "Allergan Australia Pty Ltd    ( Gordon  Australia )",
    "country": "Australia"
  },
  {
    "id": 453812,
    "name": "Allergan France    ( Courbevoie  France )",
    "country": "France"
  },
  {
    "id": 178884,
    "name": "Allergan Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 313849,
    "name": "Allergan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 313425,
    "name": "Allergan Ltd    ( Marlow  England )",
    "country": "UK"
  },
  {
    "id": 453810,
    "name": "Allergan Produtos Farmaceuticos Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 453811,
    "name": "Allergan Services International Limited    ( Tres Cantos (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 183196,
    "name": "Allergy Laboratories Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 464087,
    "name": "Allesee Orthodontic Appliances Inc    ( Mount Pleasant WI USA )",
    "country": "United States"
  },
  {
    "id": 452915,
    "name": "Allez Spine LLC    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 153004,
    "name": "Allgaier Instrumente GmbH    ( Frittlingen/Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456508,
    "name": "allheart    ( Louisiana MO USA )",
    "country": "United States"
  },
  {
    "id": 472766,
    "name": "Alliance Analytical Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 452860,
    "name": "Alliance Boots    ( London  England )",
    "country": "UK"
  },
  {
    "id": 999890,
    "name": "Alliance Business Systems, LLC    ( West Paterson NJ USA )",
    "country": "United States"
  },
  {
    "id": 457953,
    "name": "Alliance Healthcare Ltd    ( Chessington  England )",
    "country": "UK"
  },
  {
    "id": 169947,
    "name": "Alliance Imaging Inc    ( College Park GA USA )",
    "country": "United States"
  },
  {
    "id": 170154,
    "name": "Alliance Imaging Inc    ( Wareham MA USA )",
    "country": "United States"
  },
  {
    "id": 455768,
    "name": "Alliance International Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 458247,
    "name": "Alliance Laundry Systems    ( Ripon WI USA )",
    "country": "United States"
  },
  {
    "id": 171322,
    "name": "Alliance Medical    ( Russellville MO USA )",
    "country": "United States"
  },
  {
    "id": 457442,
    "name": "Alliance Medical Solutions    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 454356,
    "name": "Alliance Oncology    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 458061,
    "name": "Alliance Pharma plc    ( Chippenham  England )",
    "country": "UK"
  },
  {
    "id": 117404,
    "name": "Alliance Pharmaceutical Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456388,
    "name": "Alliant Healthcare Products    ( Richland MI USA )",
    "country": "United States"
  },
  {
    "id": 333450,
    "name": "Allied Biomedical    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 194110,
    "name": "Allied Continental Inc    ( Taichung  USA )",
    "country": "United States"
  },
  {
    "id": 460567,
    "name": "Allied Fire Protection    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 455296,
    "name": "Allied Glove & Safety Corp    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 105171,
    "name": "Allied Healthcare Products Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 460592,
    "name": "Allied Heating and Air Conditioning Co Inc    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 451045,
    "name": "Allied Orthopedics Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 107207,
    "name": "Allied OSI Labs    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 170156,
    "name": "Allied Radiographics Inc    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 461148,
    "name": "Allied Restaurant Services    ( Mansfield OH USA )",
    "country": "United States"
  },
  {
    "id": 999940,
    "name": "Allied Telesis    (   USA )",
    "country": "United States"
  },
  {
    "id": 462541,
    "name": "Allied Vision Technologies Canada Inc    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 462530,
    "name": "Allied Vision Technologies GmbH    ( Stradtroda  Germany )",
    "country": "Germany"
  },
  {
    "id": 226271,
    "name": "Allina Clinical Equipment Services    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 459305,
    "name": "Allison & Assoc Mfg Co Inc    ( North Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 454427,
    "name": "Allison Medical Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 103651,
    "name": "Allman Products Corp    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 454338,
    "name": "AllMed    ( Jefferson City MO USA )",
    "country": "United States"
  },
  {
    "id": 456099,
    "name": "Allmed Medical Products Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455724,
    "name": "Allmedicus Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454558,
    "name": "AllMeds Inc    ( Oak Ridge TN USA )",
    "country": "United States"
  },
  {
    "id": 460593,
    "name": "Allocade Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 457154,
    "name": "Allograft Innovations    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 272068,
    "name": "AlloSource    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 441520,
    "name": "Allsafe Services & Materials Co    ( Fenton MO USA )",
    "country": "United States"
  },
  {
    "id": 451662,
    "name": "Allscripts    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 454328,
    "name": "AllscriptsMisys Healthcare Solutions Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 459166,
    "name": "Allsteel Inc    ( Muscatine IA USA )",
    "country": "United States"
  },
  {
    "id": 179097,
    "name": "Alltech Applied Science (HK) Ltd    ( North Point  USA )",
    "country": "United States"
  },
  {
    "id": 392649,
    "name": "Alltech Assoc Applied Science Ltd    ( Carnforth  England )",
    "country": "UK"
  },
  {
    "id": 392650,
    "name": "Alltech Assoc France    ( Templemars  France )",
    "country": "France"
  },
  {
    "id": 103383,
    "name": "Alltech Assoc Inc    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 179098,
    "name": "Alltech Assoc Inc Belguim    ( Lokeren  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 392655,
    "name": "Alltech Assoc Pty Ltd    ( Baulkham Hills  USA )",
    "country": "United States"
  },
  {
    "id": 286965,
    "name": "Alltech GmbH    ( Rollenburg-Hailfingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 392651,
    "name": "Alltech Italia srl    ( Sedriano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 392647,
    "name": "Alltech Japan    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 473811,
    "name": "AllTech Medical Systems America Inc    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 392652,
    "name": "Alltech Nederland bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392648,
    "name": "Alltech Scientific China Ltd    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 392654,
    "name": "Alltech y Applied Science para Mexico SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 456102,
    "name": "Alltion (Wuzhou) Co Ltd    ( Wuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 223801,
    "name": "ALM Asia/Pacific A Getinge Group Co    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 371297,
    "name": "ALM Hospital Equipment Ltd    ( Burton-on-Trent  USA )",
    "country": "United States"
  },
  {
    "id": 140108,
    "name": "ALM SA    ( Orleans Cedex 2  USA )",
    "country": "United States"
  },
  {
    "id": 444446,
    "name": "ALM SA Div Maquet Cardiopulmonary AG    ( Orleans Cedex 2  USA )",
    "country": "United States"
  },
  {
    "id": 454549,
    "name": "Alma Lasers    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 454548,
    "name": "Alma Lasers Ltd    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 460598,
    "name": "Almar Industries Inc    ( Hazel Park MI USA )",
    "country": "United States"
  },
  {
    "id": 174521,
    "name": "Almedic    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 103701,
    "name": "Almore International Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 452952,
    "name": "ALN Implants Chirurgicaux    ( Ghisonaccia  France )",
    "country": "France"
  },
  {
    "id": 139280,
    "name": "Aloka Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 262423,
    "name": "Aloka Co Ltd (Asia/Pacific)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 150430,
    "name": "Aloka Co Ltd (USA)    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 262424,
    "name": "Aloka Holding Europe AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457020,
    "name": "ALOPS    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 382956,
    "name": "Alosan & Medizell GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 293543,
    "name": "Alowag AG    ( Reinach/Basle 1  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 277907,
    "name": "ALP Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 333452,
    "name": "ALPCO Diagnostics    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 999435,
    "name": "Alpha and Omega Microscopes    ( Darnestown MD USA )",
    "country": "United States"
  },
  {
    "id": 362017,
    "name": "Alpha Communications    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 226268,
    "name": "Alpha Consolidated Imaging    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 467121,
    "name": "Alpha Diagnostic International Inc (ADI)    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 454313,
    "name": "Alpha II    ( Tallahassee FL USA )",
    "country": "United States"
  },
  {
    "id": 106718,
    "name": "Alpha Imaging Inc    ( Willoughby OH USA )",
    "country": "United States"
  },
  {
    "id": 104107,
    "name": "Alpha Industries Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 185670,
    "name": "Alpha Marketing Service    ( Wilton CA USA )",
    "country": "United States"
  },
  {
    "id": 170159,
    "name": "Alpha Medical Equipment of NY Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 415976,
    "name": "Alpha Medical Services    ( Bentley  Australia )",
    "country": "Australia"
  },
  {
    "id": 457485,
    "name": "Alpha Mobility UK    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 458274,
    "name": "Alpha Modalities    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 455643,
    "name": "Alpha Omega Co USA    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 458275,
    "name": "Alpha Omega Co USA Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 394686,
    "name": "Alpha Omega Inc    ( Grapeland TX USA )",
    "country": "United States"
  },
  {
    "id": 179546,
    "name": "Alpha ProTech Inc    ( Richboro PA USA )",
    "country": "United States"
  },
  {
    "id": 271299,
    "name": "Alpha ProTech Ltd (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 279934,
    "name": "Alpha Reha-Rollstuhltechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 451214,
    "name": "Alpha Scientific Electronics    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 451105,
    "name": "Alpha Source Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 458222,
    "name": "Alpha Systems    ( Huntingdon Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 394495,
    "name": "Alpha Technics    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 338144,
    "name": "Alpha Technologies    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 458863,
    "name": "Alpha Tekniko    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 107966,
    "name": "Alpha-Omega Services Inc    ( Bellflower CA USA )",
    "country": "United States"
  },
  {
    "id": 451046,
    "name": "Alpha-Tec Systems Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 398071,
    "name": "alphaleun OHG    ( Leun  Germany )",
    "country": "Germany"
  },
  {
    "id": 391318,
    "name": "alphamed    ( St Ingbert  Germany )",
    "country": "Germany"
  },
  {
    "id": 294222,
    "name": "Alpharma A/S    ( Vennesla  USA )",
    "country": "United States"
  },
  {
    "id": 365288,
    "name": "Alphatec Mfg Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 454748,
    "name": "Alphatec Pacific Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 102841,
    "name": "Alphatek Corp    ( Broadview IL USA )",
    "country": "United States"
  },
  {
    "id": 458248,
    "name": "AlphaView    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 451051,
    "name": "Alpin Surgical Specialties Inc    ( Butler PA USA )",
    "country": "United States"
  },
  {
    "id": 276945,
    "name": "Alpine Biomed    ( Fountain Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 452935,
    "name": "Alpine Biomed ApS    ( Skovlunde  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 452936,
    "name": "Alpine Biomed France    ( Courtabeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 452937,
    "name": "Alpine Biomed GmbH    ( Langenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 348414,
    "name": "Alpine Gloves Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 175435,
    "name": "Alpine Home Medical Service    ( East Setauket NY USA )",
    "country": "United States"
  },
  {
    "id": 185671,
    "name": "Alpine Solutions Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 187039,
    "name": "ALSA Elettromedicali srl    ( Castel Maggiore (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 407227,
    "name": "Alshifa Medical Syringes Mfg Co Ltd    ( Dammam  Saudi Arabia )",
    "country": "Saudi Arabia"
  },
  {
    "id": 393829,
    "name": "Alsius Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 450939,
    "name": "Alta Pacific Technologies Solution Group Inc    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 460908,
    "name": "Altair Instruments Inc    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 999622,
    "name": "Altair Instruments Inc.    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 416143,
    "name": "Altana Pharma AG    ( Konstanz  USA )",
    "country": "United States"
  },
  {
    "id": 452108,
    "name": "AltaPoint Data Systems LLC    ( Midvale UT USA )",
    "country": "United States"
  },
  {
    "id": 458402,
    "name": "Altaravision Inc    ( Apex NC USA )",
    "country": "United States"
  },
  {
    "id": 458143,
    "name": "ALTATEC GmbH    ( Wimsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454224,
    "name": "Alteco Medical AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 462558,
    "name": "Altegra Health Inc    ( Miami Lakes FL USA )",
    "country": "United States"
  },
  {
    "id": 338145,
    "name": "Altek Corp    ( Silver Spring MD USA )",
    "country": "United States"
  },
  {
    "id": 455917,
    "name": "AlterG    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 999627,
    "name": "AlterG    ( Freemont c USA )",
    "country": "United States"
  },
  {
    "id": 459489,
    "name": "AlterG Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 170164,
    "name": "Alternate Source Inc    ( Horseheads NY USA )",
    "country": "United States"
  },
  {
    "id": 458789,
    "name": "Alternative Biomedical Solutions    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 328123,
    "name": "Alternative Computer Products Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 451852,
    "name": "AlternativeElectrodes.com LLC    ( Palm City FL USA )",
    "country": "United States"
  },
  {
    "id": 450574,
    "name": "Altfillisch Assoc Inc    ( West Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 143599,
    "name": "Altimate Medical Inc    ( Morton MN USA )",
    "country": "United States"
  },
  {
    "id": 458279,
    "name": "Alto-Shaam Inc    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 453545,
    "name": "Altomed Ltd    ( Boldon  England )",
    "country": "UK"
  },
  {
    "id": 456128,
    "name": "Alton (Shanghai) Medical Instrumnets Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 290075,
    "name": "Altoona Medical Supply    ( Altoona IA USA )",
    "country": "United States"
  },
  {
    "id": 465093,
    "name": "Altura Communication Solutions LLC    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 162645,
    "name": "Alu-Rehab A/S    ( Klepp stasjon  Norway )",
    "country": "Norway"
  },
  {
    "id": 175770,
    "name": "Alumiramp Inc    ( Quincy MI USA )",
    "country": "United States"
  },
  {
    "id": 459192,
    "name": "ALung Technologies Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 283654,
    "name": "Alup-Kompressoren GmbH    ( Koengen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107210,
    "name": "Aluwax Dental Products Co    ( Allendale MI USA )",
    "country": "United States"
  },
  {
    "id": 153006,
    "name": "Aluweld SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 449818,
    "name": "Alveolus Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 103021,
    "name": "Alvey Washing Equipment Co    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 457157,
    "name": "Always On UPS Systems Inc    ( Kelowna BC Canada )",
    "country": "Canada"
  },
  {
    "id": 101336,
    "name": "ALZA Corp    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 466753,
    "name": "AM Communications Solutions Inc    ( Dover PA USA )",
    "country": "United States"
  },
  {
    "id": 142914,
    "name": "AM Gloves Co Ltd    ( Tainan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 458224,
    "name": "AM Surgical Inc    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 458864,
    "name": "AM Technologies Inc    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 415979,
    "name": "AMA Products    ( Mansfield  Australia )",
    "country": "Australia"
  },
  {
    "id": 466959,
    "name": "AMA Scientific LLC    ( Mount Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 237823,
    "name": "AMADA TECHNOLOGIES INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 283798,
    "name": "Amafilter Membrantechnik GmbH    ( Langenhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 277911,
    "name": "Amajin Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457239,
    "name": "Amann Girrbach GmbH    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 281257,
    "name": "Amann Medizintechnik    ( Emmingen Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999724,
    "name": "Amano    ( Roseland NJ USA )",
    "country": "United States"
  },
  {
    "id": 237795,
    "name": "Amano Corp    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 456573,
    "name": "Amarell GmbH & Co KG    ( Kreuzwertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 106988,
    "name": "Amatech Corp    ( Acton MA USA )",
    "country": "United States"
  },
  {
    "id": 466805,
    "name": "Amazing Interactives Ltd    ( Middlesbrough  England )",
    "country": "UK"
  },
  {
    "id": 454559,
    "name": "AmazingCharts.com Inc    ( North Kingstown RI USA )",
    "country": "United States"
  },
  {
    "id": 452220,
    "name": "Amazon Medical Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 171324,
    "name": "Amba Medical Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 155831,
    "name": "American Catheter Corp    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 273269,
    "name": "Ambassador Medical LLC    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 102475,
    "name": "Ambco Electronics    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 287732,
    "name": "Amber Diagnostics Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 108083,
    "name": "Amber Industries Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 467163,
    "name": "Ambimed Sede    ( Torres Vedras  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 453016,
    "name": "Ambisea Technology Corp Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456732,
    "name": "Ambler Surgical LLC    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 458865,
    "name": "Ambry Genetics    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 306283,
    "name": "Ambu (Deutschland) GmbH    ( Bad Nauheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 439533,
    "name": "Ambu A/S (Finland)    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 439614,
    "name": "Ambu A/S (Sweden)    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 409925,
    "name": "Ambu France Sarl    ( Le Haillan  France )",
    "country": "France"
  },
  {
    "id": 104479,
    "name": "Ambu Inc    ( Glen Burnie MD USA )",
    "country": "United States"
  },
  {
    "id": 150974,
    "name": "Ambu International A/S    ( Ballerup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 409927,
    "name": "Ambu Japan KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 453616,
    "name": "Ambu Ltd    ( St Ives  England )",
    "country": "UK"
  },
  {
    "id": 409926,
    "name": "Ambu Medical Ltd    ( Kowloon  USA )",
    "country": "United States"
  },
  {
    "id": 453615,
    "name": "Ambu SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 454302,
    "name": "Ambu Sleepmate    ( Glen Burnie MD USA )",
    "country": "United States"
  },
  {
    "id": 453617,
    "name": "Ambu srl    ( Agrate Branza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456129,
    "name": "Ambulanc (Shenzhen) Tech Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 356853,
    "name": "Ambulance Network Inc    ( Elizabeth NJ USA )",
    "country": "United States"
  },
  {
    "id": 101045,
    "name": "Ambulatory Monitoring Inc    ( Ardsley NY USA )",
    "country": "United States"
  },
  {
    "id": 174522,
    "name": "Ambutech    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 363475,
    "name": "AMCART Inc    ( Battle Creek MI USA )",
    "country": "United States"
  },
  {
    "id": 456433,
    "name": "AmCells Corp    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 105233,
    "name": "Amcest Corp    ( Roselle NJ USA )",
    "country": "United States"
  },
  {
    "id": 428184,
    "name": "AMCO Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 171336,
    "name": "AMCO Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451820,
    "name": "Amcom Software Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 456614,
    "name": "Amcon Laboratories    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 439641,
    "name": "Amcor Flexibles    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 439646,
    "name": "Amcor Flexibles (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 439647,
    "name": "Amcor Flexibles Albertazzi    ( Soliera (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 439642,
    "name": "Amcor Flexibles do Brasil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 439648,
    "name": "Amcor Flexibles Gent    ( Barsbuettel  Germany )",
    "country": "Germany"
  },
  {
    "id": 439649,
    "name": "Amcor Flexibles Leaderpack    ( Rio de Mouro  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 439650,
    "name": "Amcor Flexibles Lund    ( Varberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 439651,
    "name": "Amcor Flexibles Sligo    ( Sligo  USA )",
    "country": "United States"
  },
  {
    "id": 458209,
    "name": "American College of Cardiolody NCDR    ( Washington DC DC USA )",
    "country": "United States"
  },
  {
    "id": 439644,
    "name": "Amcor Flexibles SPS    ( Coulommiers Cedex  France )",
    "country": "France"
  },
  {
    "id": 439652,
    "name": "Amcor Flexibles Tobepal Logrono    ( Granollers (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 439645,
    "name": "Amcor Flexibles Winterbourne    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 172255,
    "name": "Amcor Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 454710,
    "name": "AMD Dental Mfg Inc    ( Nazareth PA USA )",
    "country": "United States"
  },
  {
    "id": 455331,
    "name": "AMD Lasers LLC    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 455559,
    "name": "AMD Technologies Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 394733,
    "name": "AMD Telemedicine Inc    ( North Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 104976,
    "name": "AMD-Ritmed Inc    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 365214,
    "name": "AMD-Ritmed Inc (Canada)    ( Lachine PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 396610,
    "name": "Amdent AB    ( Nynashamn  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 407392,
    "name": "AMDL Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 457186,
    "name": "AME System Pty Ltd    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 455690,
    "name": "Amecko Medical Industries    ( 10th of Ramadan City  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 457517,
    "name": "Ameco Medical    ( 10th of Ramadan City  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 455769,
    "name": "AMED Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999871,
    "name": "Ameda Breast Feeding Products    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 457070,
    "name": "Ameda Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 457071,
    "name": "Ameda International    ( Vilvoorde  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 458283,
    "name": "Amedica Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 456304,
    "name": "Ameditech    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 281147,
    "name": "Ameg    ( Ebhausen  USA )",
    "country": "United States"
  },
  {
    "id": 458571,
    "name": "Amega Scientific Corp    ( Marlton NJ USA )",
    "country": "United States"
  },
  {
    "id": 455517,
    "name": "Amendia Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 456052,
    "name": "Amer Traders Registered    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 457639,
    "name": "AmerCare Inc    ( North Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 272666,
    "name": "Amercare Ltd    ( Thame  England )",
    "country": "UK"
  },
  {
    "id": 107709,
    "name": "Amerex Instruments Inc    ( Lafayette CA USA )",
    "country": "United States"
  },
  {
    "id": 431556,
    "name": "America Key Boxes    ( Kennedale TX USA )",
    "country": "United States"
  },
  {
    "id": 299645,
    "name": "American 3B Scientific    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 455273,
    "name": "American Alarm and Communications Inc    ( Arlington MA USA )",
    "country": "United States"
  },
  {
    "id": 458226,
    "name": "American Aqua Inc    ( Saline MI USA )",
    "country": "United States"
  },
  {
    "id": 335460,
    "name": "American Associated    ( Fayetteville GA USA )",
    "country": "United States"
  },
  {
    "id": 456498,
    "name": "American Australian Medical Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 183913,
    "name": "American Bantex Corp    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 462796,
    "name": "American Bedding Company    ( Gainersville GA USA )",
    "country": "United States"
  },
  {
    "id": 451054,
    "name": "American Bio Medica Corp    ( Kinderhook NY USA )",
    "country": "United States"
  },
  {
    "id": 106549,
    "name": "American Biomed Instrumentation Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 170167,
    "name": "American Biomedical Electronic Services    ( Terrytown LA USA )",
    "country": "United States"
  },
  {
    "id": 177839,
    "name": "American Biosystems    (   USA )",
    "country": "United States"
  },
  {
    "id": 460649,
    "name": "American BioTech Supply (ABS)    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 456340,
    "name": "American Breast Care    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 108210,
    "name": "American Care Equipment Inc    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 456397,
    "name": "American Dental Accessories Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 454363,
    "name": "American Dental Airsonics    ( Corpus Christi TX USA )",
    "country": "United States"
  },
  {
    "id": 286999,
    "name": "American Dental Supplies GmbH    ( Vaterstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 106660,
    "name": "American Diagnostic Corp    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 372003,
    "name": "American Diagnostica (Australia)    ( Salamander Bay  USA )",
    "country": "United States"
  },
  {
    "id": 372000,
    "name": "American Diagnostica du Canada SEC    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 372001,
    "name": "American Diagnostica GmbH    ( Pfungstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 193766,
    "name": "American Diagnostica Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 372002,
    "name": "American Diagnostica Sarl    ( Neufille-sur-Oise  France )",
    "country": "France"
  },
  {
    "id": 457323,
    "name": "American Donor Services Inc    ( Hastings MN USA )",
    "country": "United States"
  },
  {
    "id": 466719,
    "name": "American Dryer Corp (ADC)    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 458439,
    "name": "American Dynamics    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 456393,
    "name": "American Eagle Instruments Inc    ( Missoula MT USA )",
    "country": "United States"
  },
  {
    "id": 999329,
    "name": "American Electromedics Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 161818,
    "name": "American Eurocopter Corp    ( Grand Prairie TX USA )",
    "country": "United States"
  },
  {
    "id": 105870,
    "name": "American Gas & Chemical Co Ltd    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 339488,
    "name": "American Health and Safety Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 233414,
    "name": "American Health Care Software Enterprises    ( South Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 107651,
    "name": "American Health Products Corp    ( Glendale Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 107866,
    "name": "American Health Systems    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 363705,
    "name": "American Healthcare Inc    ( Scarborough ME USA )",
    "country": "United States"
  },
  {
    "id": 348429,
    "name": "American Healthcare Products Europe    ( Grub am Fors 7  USA )",
    "country": "United States"
  },
  {
    "id": 267231,
    "name": "American Healthcare Products Inc    ( Alhambra CA USA )",
    "country": "United States"
  },
  {
    "id": 451439,
    "name": "American Healthcare Products Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 348427,
    "name": "American Healthcare Products SA de CV    ( Zapopan  USA )",
    "country": "United States"
  },
  {
    "id": 409955,
    "name": "American HealthNet Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 458850,
    "name": "American Heart Assoc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 179412,
    "name": "American Home Patient    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 348686,
    "name": "American Home Patient    ( Leesburg FL USA )",
    "country": "United States"
  },
  {
    "id": 409961,
    "name": "American Homecare Supply    ( West Seneca NY USA )",
    "country": "United States"
  },
  {
    "id": 185677,
    "name": "American Imaging Systems Inc    ( South Plainfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 108352,
    "name": "American Imex    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 367459,
    "name": "American Immuno Tech    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 460652,
    "name": "American Innovation Products    ( Trinity NC USA )",
    "country": "United States"
  },
  {
    "id": 176068,
    "name": "American Innovations Inc    ( Dublin PA USA )",
    "country": "United States"
  },
  {
    "id": 467026,
    "name": "American International Electric Inc    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 367408,
    "name": "American Jet Injector Inc    ( Lansdale PA USA )",
    "country": "United States"
  },
  {
    "id": 108811,
    "name": "American LAbor    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 376819,
    "name": "American LaFrance Corp    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 153726,
    "name": "American Mammographics Inc    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 107551,
    "name": "American Massage Products Inc    ( Silver Creek NY USA )",
    "country": "United States"
  },
  {
    "id": 293743,
    "name": "Aquamed    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462960,
    "name": "American Master Tech Scientific Inc    ( Lodi CA USA )",
    "country": "United States"
  },
  {
    "id": 460653,
    "name": "American Medical Accounting & Consulting    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 105293,
    "name": "American Medical Alert Corp    ( Oceanside NY USA )",
    "country": "United States"
  },
  {
    "id": 460656,
    "name": "American Medical Assoc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 185680,
    "name": "American Medical Design    ( Atascadero CA USA )",
    "country": "United States"
  },
  {
    "id": 176406,
    "name": "American Medical Equipment (AME)    ( Georgetown TX USA )",
    "country": "United States"
  },
  {
    "id": 108403,
    "name": "American Medical Industries    ( Dell Rapids SD USA )",
    "country": "United States"
  },
  {
    "id": 155946,
    "name": "American Medical Instruments Inc    ( Dartmouth MA USA )",
    "country": "United States"
  },
  {
    "id": 439578,
    "name": "American Medical Instruments Inc    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 103704,
    "name": "American Medical Sales Inc    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 334113,
    "name": "American Medical Software    ( Edwardsville IL USA )",
    "country": "United States"
  },
  {
    "id": 154425,
    "name": "American Medical Source    ( Tomball TX USA )",
    "country": "United States"
  },
  {
    "id": 176069,
    "name": "American Medical Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 174523,
    "name": "American Medical Systems Canada Inc    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452131,
    "name": "American Medical Systems France Sarl    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 101005,
    "name": "American Medical Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 452136,
    "name": "American Medical Systems UK Ltd    ( Brentford  England )",
    "country": "UK"
  },
  {
    "id": 441018,
    "name": "American Medical Technologies Inc    ( Corpus Christi TX USA )",
    "country": "United States"
  },
  {
    "id": 460599,
    "name": "American Messaging Services LLC    ( Lewisville TX USA )",
    "country": "United States"
  },
  {
    "id": 460659,
    "name": "American Nurses Credentialing Center (ANCC)    ( Silver Spring MD USA )",
    "country": "United States"
  },
  {
    "id": 107213,
    "name": "American of Martinsville    ( Martinsville VA USA )",
    "country": "United States"
  },
  {
    "id": 466234,
    "name": "American Office    ( - - USA )",
    "country": "United States"
  },
  {
    "id": 102825,
    "name": "American Optical    (   USA )",
    "country": "United States"
  },
  {
    "id": 155789,
    "name": "American Optisurgical Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 103705,
    "name": "American Orthodontics    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 104509,
    "name": "American Orthopaedic    (   USA )",
    "country": "United States"
  },
  {
    "id": 458866,
    "name": "American Panel Corp    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 373558,
    "name": "American Pharmaceutical Partners Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 104751,
    "name": "American Qualex International Inc    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 106125,
    "name": "American Radiographics Inc    ( Taunton MA USA )",
    "country": "United States"
  },
  {
    "id": 158397,
    "name": "American Resource Medical Equipment Inc    ( Paterson NJ USA )",
    "country": "United States"
  },
  {
    "id": 102832,
    "name": "American Scientific Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 272270,
    "name": "American Seating    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 465125,
    "name": "American Security Products Co.    ( Fontana 11 USA )",
    "country": "United States"
  },
  {
    "id": 329834,
    "name": "American Society of Health-Systems Pharmacists    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 103384,
    "name": "American Specialties Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 102940,
    "name": "American Standard Cos Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 338173,
    "name": "American Supply Co Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 272850,
    "name": "American Surgical Sponges    ( Lynn MA USA )",
    "country": "United States"
  },
  {
    "id": 228820,
    "name": "American TeleCare Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 451056,
    "name": "American Time and Signal    ( Dassel MN USA )",
    "country": "United States"
  },
  {
    "id": 101049,
    "name": "American Ultraviolet Co    ( Lebanon IN USA )",
    "country": "United States"
  },
  {
    "id": 321015,
    "name": "American Weights & Measures    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451055,
    "name": "American West Medical Co    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 338175,
    "name": "American X-Ray and Medical Supply Inc    ( Jackson CA USA )",
    "country": "United States"
  },
  {
    "id": 105696,
    "name": "American X-Ray Corp    ( Louisville TN USA )",
    "country": "United States"
  },
  {
    "id": 107927,
    "name": "American X-Ray Service    ( New Hyde Park NY USA )",
    "country": "United States"
  },
  {
    "id": 454737,
    "name": "AmericansourceBergen Technology Group    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 465024,
    "name": "AmeriCom Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 170184,
    "name": "Americomp Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 151463,
    "name": "Americorp Financial Inc    ( Birmingham MI USA )",
    "country": "United States"
  },
  {
    "id": 459164,
    "name": "AmeriGlide Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 465162,
    "name": "AmeriNet    ( Ann Arbor 12 USA )",
    "country": "United States"
  },
  {
    "id": 403306,
    "name": "AmeriSourceBergen Corp    ( Chesterbrook PA USA )",
    "country": "United States"
  },
  {
    "id": 106968,
    "name": "Amerisys    (   USA )",
    "country": "United States"
  },
  {
    "id": 362313,
    "name": "AmeriTek China Ltd    ( Tongxian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 333543,
    "name": "AmeriTek USA    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 466518,
    "name": "Ameritone Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 456534,
    "name": "Ameritus Medical Products    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 453357,
    "name": "Amerityre Corp    ( Boulder City NV USA )",
    "country": "United States"
  },
  {
    "id": 310104,
    "name": "AmeriVap Systems    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 273275,
    "name": "Ameriwater    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 461182,
    "name": "Ameriwood Medical Inc    ( Villa Park IL USA )",
    "country": "United States"
  },
  {
    "id": 999985,
    "name": "Amertek Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 330820,
    "name": "Amerx Health Care Corp    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 185684,
    "name": "Ames Color File    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 330191,
    "name": "Ames Supply Co    ( Lisle IL USA )",
    "country": "United States"
  },
  {
    "id": 150305,
    "name": "Ametek Inc    ( Berwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 999761,
    "name": "AMETEK Test & Calibration Instruments    ( Largo FL USA )",
    "country": "USA"
  },
  {
    "id": 999760,
    "name": "AMETEK, Inc.    ( Berwyn PA USA )",
    "country": "USA"
  },
  {
    "id": 399029,
    "name": "Amex Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 450825,
    "name": "AMF Support Surface Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 103702,
    "name": "AmFab Inc    ( Galesburg MI USA )",
    "country": "United States"
  },
  {
    "id": 401064,
    "name": "Amfit Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 451726,
    "name": "AMG Medical Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455391,
    "name": "Amgen Inc    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 337904,
    "name": "AMI Film Bin    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 186726,
    "name": "AMI Inc    ( Groton CT USA )",
    "country": "United States"
  },
  {
    "id": 361274,
    "name": "AMICAS Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 108517,
    "name": "Amici Inc    ( Spring City PA USA )",
    "country": "United States"
  },
  {
    "id": 226280,
    "name": "Amico Corp (Canada)    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 428434,
    "name": "Amico Corp (US)    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 101050,
    "name": "Amigo Mobility International Inc    ( Bridgeport MI USA )",
    "country": "United States"
  },
  {
    "id": 309611,
    "name": "Amitalia srl    ( Solaro  Italy )",
    "country": "Italy"
  },
  {
    "id": 391322,
    "name": "Amitie Corp    ( Chonrabuk-do  USA )",
    "country": "United States"
  },
  {
    "id": 457350,
    "name": "amixon GmbH    ( Paderborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 458867,
    "name": "AmkaiSolutions LLC    ( Armonk NY USA )",
    "country": "United States"
  },
  {
    "id": 399187,
    "name": "Amluck Inc    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 456053,
    "name": "Ammad Surgical    ( Lahore  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 458251,
    "name": "AmMed Surgical Equipment LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 391806,
    "name": "AMMEX Corp    ( Tukwila WA USA )",
    "country": "United States"
  },
  {
    "id": 460540,
    "name": "Amniox Medical Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 454607,
    "name": "Amnis Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 452794,
    "name": "AmniSure International LLC    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 453472,
    "name": "AMO (Shanghai) Medical Devices Trading Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453479,
    "name": "AMO Asia Ltd (Korea Branch)    ( Seoul  People's Republic of Korea )",
    "country": "People's Korea"
  },
  {
    "id": 453470,
    "name": "AMO Canada Co    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453477,
    "name": "AMO France SAS    ( Biot  France )",
    "country": "France"
  },
  {
    "id": 451185,
    "name": "AMO Groningen    ( Groningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453476,
    "name": "AMO Italy srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 451521,
    "name": "AMO Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453475,
    "name": "AMO Netherlands bv    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453474,
    "name": "AMO Norden AB    ( Upplands Vasby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453478,
    "name": "AMO Switzerland GmbH    ( Pfaffikon SZ  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453473,
    "name": "AMO United Kingdom Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 285563,
    "name": "AMOENA Medizin-Orthopaedie-Technik GmbH & Co KG    ( Raubling  Germany )",
    "country": "Germany"
  },
  {
    "id": 175780,
    "name": "Amoena US    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 349369,
    "name": "Amos Gazit Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 194667,
    "name": "AMP Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 169877,
    "name": "Ampac Products Inc    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 455750,
    "name": "AMPall Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 294178,
    "name": "AMPCO Dental Equipment Inc    ( Sparks NV USA )",
    "country": "United States"
  },
  {
    "id": 370368,
    "name": "Ampcor Technologies Inc    ( Denville NJ USA )",
    "country": "United States"
  },
  {
    "id": 392862,
    "name": "Amphastar Pharmaceuticals Inc    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 460720,
    "name": "AMPI    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 408757,
    "name": "Amplaid Biomedical    ( Assago (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457067,
    "name": "Ample Scientific LLC    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 441250,
    "name": "Amplestuff    ( Bearsville NY USA )",
    "country": "United States"
  },
  {
    "id": 187571,
    "name": "Ampliclinical SpA    ( Assago MI  Italy )",
    "country": "Italy"
  },
  {
    "id": 324125,
    "name": "Amplicon Liveline Ltd    ( Brighton  England )",
    "country": "UK"
  },
  {
    "id": 466239,
    "name": "AMPLITUDE    ( Valence  France )",
    "country": "France"
  },
  {
    "id": 466240,
    "name": "AMPLITUDE GmbH    ( Zotzenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 178837,
    "name": "Amplivox Ltd    ( Kidlington  England )",
    "country": "UK"
  },
  {
    "id": 185367,
    "name": "Ampolmed-Europe Sp z o o    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 356153,
    "name": "Ampronix Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 171242,
    "name": "Amrad/Summit Industries Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451328,
    "name": "Amrad/Summit Industries Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 107092,
    "name": "AMRESCO Inc    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 178844,
    "name": "Amrex Electrotherapy Equipment    ( Carson CA USA )",
    "country": "United States"
  },
  {
    "id": 356053,
    "name": "AMS (Advanced Medical Systems) Ltd    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 455025,
    "name": "AMS Canada    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 466591,
    "name": "AMS Diagnostics LLC    ( Weston FL USA )",
    "country": "United States"
  },
  {
    "id": 101060,
    "name": "Arrow International Inc    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 455026,
    "name": "AMS France SAS    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 455027,
    "name": "AMS UK Ltd    ( Brentford  England )",
    "country": "UK"
  },
  {
    "id": 451192,
    "name": "AMS USA    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 466257,
    "name": "AmScien Instruments    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 330822,
    "name": "Amsino International Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 455725,
    "name": "AMT Engineering Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456535,
    "name": "AMTAI Medical Equipment Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 384287,
    "name": "Amtec Medical Ltd    ( Antrim  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 438827,
    "name": "Amtech Co    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 287219,
    "name": "Amtech GmbH    ( Dossenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 277703,
    "name": "AMTELCO    ( McFarland WI USA )",
    "country": "United States"
  },
  {
    "id": 456533,
    "name": "AmTryke LLC    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 402525,
    "name": "AMTSystems Inc    ( Cheshire CT USA )",
    "country": "United States"
  },
  {
    "id": 108922,
    "name": "Amuneal Mfg Corp    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 453831,
    "name": "Amvex Corp    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 999800,
    "name": "AMX    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 106118,
    "name": "AMX Imaging    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 457050,
    "name": "AMX Solutions Inc    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 457832,
    "name": "Anacapa Technologies Inc    ( San Dimas CA USA )",
    "country": "United States"
  },
  {
    "id": 398498,
    "name": "Anachemia Science    ( Lachine PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 381286,
    "name": "Anacom Med-Tek    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 324658,
    "name": "Anaequip UK    ( Nelson  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 104579,
    "name": "Anaerobe Systems    ( Morgan Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 177270,
    "name": "Anaesthesia Equipment Co    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 463047,
    "name": "Anaesthetic Equipment Supplies    ( Durban  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 173221,
    "name": "Anaheim Custom Extruders Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 177926,
    "name": "Analab Numeric Devices Ltd    ( Manchester  USA )",
    "country": "United States"
  },
  {
    "id": 106561,
    "name": "Analogic Corp    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 457180,
    "name": "Analogic Medical Equipment (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457181,
    "name": "Analogic Ultrasound Group    ( Herlev  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 139844,
    "name": "Analox Instruments Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 296553,
    "name": "Analox Instruments USA    ( Lunenburg MA USA )",
    "country": "United States"
  },
  {
    "id": 102480,
    "name": "Analtech Inc    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 157685,
    "name": "Analysinstrument AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 416511,
    "name": "Analyt-MTC GmbH & Co KG    ( Muellheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 331058,
    "name": "Analytical Control Systems Inc    ( Fishers IN USA )",
    "country": "United States"
  },
  {
    "id": 440740,
    "name": "Analytical India Pvt Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 467018,
    "name": "Analytical Industries Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 402248,
    "name": "Analytical Instruments Ltd    ( Golden Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 102402,
    "name": "Analytical Measurements    ( Hillside NJ USA )",
    "country": "United States"
  },
  {
    "id": 185687,
    "name": "Analytical Wholesalers Inc    ( Conyers GA USA )",
    "country": "United States"
  },
  {
    "id": 183768,
    "name": "Analyticon Instrument Corp    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 464079,
    "name": "Analytik Jena AG    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 280590,
    "name": "Analytix Geraete-Vertriebs-GmbH    ( Burgdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 287761,
    "name": "Anand Medicaids Pvt Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 452548,
    "name": "Ananda Surgicals    ( Trivandrum  India )",
    "country": "India"
  },
  {
    "id": 461145,
    "name": "AnaSpec Ltd    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 174527,
    "name": "Anatech Anatomical Technologies Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 172397,
    "name": "Anatech Ltd    ( Battle Creek MI USA )",
    "country": "United States"
  },
  {
    "id": 349178,
    "name": "Anatomical Concepts (UK) Ltd    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 290078,
    "name": "Anatomical Concepts Inc    ( Poland OH USA )",
    "country": "United States"
  },
  {
    "id": 460731,
    "name": "Anatomics    ( St Kilda  Australia )",
    "country": "Australia"
  },
  {
    "id": 459343,
    "name": "Ancare Corp    ( Bellmore NY USA )",
    "country": "United States"
  },
  {
    "id": 451722,
    "name": "Anchor Medical    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 103248,
    "name": "Anchor Products Co    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 451467,
    "name": "Anco Industrie    ( Vannes  France )",
    "country": "France"
  },
  {
    "id": 454018,
    "name": "ANDA Medical    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103387,
    "name": "Andermac Inc    ( Yuba City CA USA )",
    "country": "United States"
  },
  {
    "id": 266340,
    "name": "Andersen Medical (Hong Kong) Ltd    ( Kwun Tang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101953,
    "name": "Andersen Products Inc    ( Haw River NC USA )",
    "country": "United States"
  },
  {
    "id": 462806,
    "name": "Anderson & Associates    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 356850,
    "name": "Anderson Medical Equipment Co    ( Milford OH USA )",
    "country": "United States"
  },
  {
    "id": 354515,
    "name": "Andocor NV    ( Zoersel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456130,
    "name": "Andon Health Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 105485,
    "name": "Andonian Cryogenics Inc    ( New Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 459345,
    "name": "Andor Technology    ( Belfast  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 460815,
    "name": "Andor Technology USA    ( South Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 290079,
    "name": "Andover Coated Products    ( Salisbury MA USA )",
    "country": "United States"
  },
  {
    "id": 152541,
    "name": "Andreas Hettich GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285865,
    "name": "Andreas Joa GmbH Augen- Mikropinzetten    ( Rottenburg N  Germany )",
    "country": "Germany"
  },
  {
    "id": 330248,
    "name": "Andrew Corp    ( Westchester IL USA )",
    "country": "United States"
  },
  {
    "id": 381779,
    "name": "Andromed Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 428659,
    "name": "Andromed USA Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 392730,
    "name": "ANDROMEDA Medizinische Systeme GmbH    ( Taufkirchen/Potzham  Germany )",
    "country": "Germany"
  },
  {
    "id": 454379,
    "name": "Andromedical SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 439526,
    "name": "Andy Frisque Laser Systems    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 453569,
    "name": "Anes-Med Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 457347,
    "name": "Anesi Parafango    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 104588,
    "name": "Anesthesia Assoc Inc    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 187311,
    "name": "Anesthesia Labels Div Avera PACE Inc    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 106100,
    "name": "Anesthesia Medical Supply Inc    ( Merion Station PA USA )",
    "country": "United States"
  },
  {
    "id": 465047,
    "name": "Automated Logic Corp    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 178077,
    "name": "Anesthesia Plus Inc    ( Elk Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 170189,
    "name": "Anesthetic Vaporizer Services Inc    ( Clarence NY USA )",
    "country": "United States"
  },
  {
    "id": 434012,
    "name": "Anetic Aid Ltd    ( Guiseley  England )",
    "country": "UK"
  },
  {
    "id": 466241,
    "name": "ANETS    ( Concord 10 USA )",
    "country": "United States"
  },
  {
    "id": 999725,
    "name": "Angelantoni Industrie s.p.a.    (   USA )",
    "country": "United States"
  },
  {
    "id": 467324,
    "name": "AngelEye LLC    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 150106,
    "name": "Angelica Corp    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 456131,
    "name": "Angeline Group Ltd    ( Xiamen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 143728,
    "name": "Angelus Medical & Optical Co Inc    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 103663,
    "name": "Angenieux Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 464078,
    "name": "Angiodroid srl    ( San Lazzaro di Savena (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 199733,
    "name": "AngioDynamics Inc    ( Queensbury NY USA )",
    "country": "United States"
  },
  {
    "id": 391339,
    "name": "Angiologica BM srl    ( San Martino Siccomario (PV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 332055,
    "name": "Angiomed GmbH & Co Medizintechnik KG    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 452801,
    "name": "AngioScore Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 452224,
    "name": "Angiotech    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 452225,
    "name": "Angiotech Pharmaceuticals Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456132,
    "name": "Anhui Easyway Medical Supplies Co Ltd    ( Anhui  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456133,
    "name": "Anhui Import and Export Co Ltd    ( Hefei  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456134,
    "name": "Anhui Kangda Medical Products Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456135,
    "name": "Anhui Tiankang Medical Products Co Ltd    ( Tianchang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 332558,
    "name": "ANI Biotech Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 454008,
    "name": "Aniara    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 300656,
    "name": "Anika Therapeutics Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 459238,
    "name": "Animal Care Systems Inc    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 363829,
    "name": "Animas Corp    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 415800,
    "name": "Anita (Denmark)    ( Skaevinge  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 415802,
    "name": "Anita (Finland)    ( Kouvola  Finland )",
    "country": "Finland"
  },
  {
    "id": 415801,
    "name": "Anita (Norway)    ( Voyenenga  Norway )",
    "country": "Norway"
  },
  {
    "id": 415803,
    "name": "Anita (Sweden)    ( Sigtuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 415809,
    "name": "Anita Corsa-Nova AG/SA    ( Sursee  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415799,
    "name": "Anita Dr Helbig GmbH (Austria)    ( Kufstein  Austria )",
    "country": "Austria"
  },
  {
    "id": 280438,
    "name": "Anita Dr Helbig GmbH (Deutschland)    ( Brannenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 415804,
    "name": "Anita Dr Helbig GmbH (France)    ( Schiltigheim Cedex  France )",
    "country": "France"
  },
  {
    "id": 415811,
    "name": "Anita Hungaria Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 406735,
    "name": "Anita International Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 415806,
    "name": "Anita Italia SpA    ( Como (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 415810,
    "name": "Anita Moravia sor    ( Heralec pod Zakovou horou  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 415808,
    "name": "Anita Portugal    ( Lisboa  USA )",
    "country": "United States"
  },
  {
    "id": 453964,
    "name": "Anita Spain SL    ( Sant Quirze del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 415805,
    "name": "Anita UK Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 170190,
    "name": "Anixter Inc    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 456136,
    "name": "Anji Anshen Surgical Dressing Co Ltd    ( Huzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456137,
    "name": "Anji Jixiang Medical Co Ltd    ( Huzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456138,
    "name": "Anji Oasis Medical Products Co Ltd    ( Huzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392731,
    "name": "Anki Medikal Ltd    ( Ankara  USA )",
    "country": "United States"
  },
  {
    "id": 397996,
    "name": "Anmedic AB    ( Vallentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 398001,
    "name": "Anmedic UK    ( Hayling Island  England )",
    "country": "UK"
  },
  {
    "id": 176075,
    "name": "Anna-Dote Inc    ( West Middlesex PA USA )",
    "country": "United States"
  },
  {
    "id": 441053,
    "name": "Anodyne Therapy LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 459259,
    "name": "Anoto Group AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 456139,
    "name": "Anqing Kangmingna Packaging Co Ltd    ( Anging  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 183977,
    "name": "Anritsu Meter Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 408779,
    "name": "Ansell Belgium    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 284580,
    "name": "Ansell Edmont Industrial    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454647,
    "name": "Ansell GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 365784,
    "name": "Ansell Healthcare Products LLC    ( Red Bank NJ USA )",
    "country": "United States"
  },
  {
    "id": 408773,
    "name": "Ansell Ltd    ( Richmond  Australia )",
    "country": "Australia"
  },
  {
    "id": 457469,
    "name": "Ansell Sandel Medical Industries LLC    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 417551,
    "name": "Anson Medical Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 232563,
    "name": "Anspach Cos    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 305740,
    "name": "ANSTO Radiopharmaceuticals and Industrials    ( Lucas Heights  Australia )",
    "country": "Australia"
  },
  {
    "id": 461277,
    "name": "ANT Neuro    ( Enschede  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 393082,
    "name": "Antares Pharma Inc    ( Ewing NJ USA )",
    "country": "United States"
  },
  {
    "id": 455146,
    "name": "Antec (USA) LLC    ( Palm Bay FL USA )",
    "country": "United States"
  },
  {
    "id": 455147,
    "name": "Antec Leyden bv    ( Zoeterwoude  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 162039,
    "name": "Antek Healthware Inc    ( Reistertown MD USA )",
    "country": "United States"
  },
  {
    "id": 469491,
    "name": "Anthem Medical LLC    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 456481,
    "name": "Anthogyr France    ( Sallanches  France )",
    "country": "France"
  },
  {
    "id": 451787,
    "name": "Anthony Products Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 458614,
    "name": "Anthony's Welded Products Inc    ( Pearland TX USA )",
    "country": "United States"
  },
  {
    "id": 344585,
    "name": "Anthos Labtec Instruments GmbH    ( Salzburg  USA )",
    "country": "United States"
  },
  {
    "id": 108918,
    "name": "Anthro Corp    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 173384,
    "name": "Anthros Medical Group    ( Highland KS USA )",
    "country": "United States"
  },
  {
    "id": 464081,
    "name": "Anti Aging Medical Systems    ( Montodat  France )",
    "country": "France"
  },
  {
    "id": 107602,
    "name": "Antibodies Inc    ( Davis CA USA )",
    "country": "United States"
  },
  {
    "id": 376292,
    "name": "Antigen Pharmaceuticals    ( Croyden  England )",
    "country": "UK"
  },
  {
    "id": 475952,
    "name": "Antimicrobial Test Laboratories    ( Round Rock TX USA )",
    "country": "United States"
  },
  {
    "id": 454253,
    "name": "Antisoma plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 454252,
    "name": "Antisoma plc (USA)    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 281586,
    "name": "Antitron Technomedirad    ( Gemuenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 465095,
    "name": "AvePoint, Inc.    ( Jersey City  USA )",
    "country": "United States"
  },
  {
    "id": 286887,
    "name": "Anton Betzler GmbH    ( Tuttlingen/Nendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282861,
    "name": "Anton Franzen GmbH & Co KG    ( Heinsberg Dremmen  USA )",
    "country": "United States"
  },
  {
    "id": 281803,
    "name": "Anton Hipp GmbH    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455699,
    "name": "Anton Paar GmbH    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 453604,
    "name": "Anton Paar OptoTec GmbH    ( Seelze  Germany )",
    "country": "Germany"
  },
  {
    "id": 455700,
    "name": "Anton Paar USA Inc    ( Ashland VA USA )",
    "country": "United States"
  },
  {
    "id": 282004,
    "name": "Anton Ruthmann GmbH & Co KG    ( Gescher/Hochmoor  USA )",
    "country": "United States"
  },
  {
    "id": 451525,
    "name": "Antrin Enterprises Inc    ( Ojai CA USA )",
    "country": "United States"
  },
  {
    "id": 451375,
    "name": "ANTYLLOS Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 307314,
    "name": "Anugerah Saintifik Sdn Bhd    ( Puchong  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 458303,
    "name": "Anulex Technologies Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 455177,
    "name": "Anvita Health Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456140,
    "name": "Anyang Xiangyu Medical Equipment Co Ltd    ( Anyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453855,
    "name": "AnyDoc Software Deutschland (Switzerland) GmbH    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453854,
    "name": "AnyDoc Software Deutschland GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 453848,
    "name": "AnyDoc Software Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 453856,
    "name": "AnyDoc Software Ltd    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 228223,
    "name": "Anzai Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999590,
    "name": "AOC    (   USA )",
    "country": "United States"
  },
  {
    "id": 455375,
    "name": "AOD Software    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 460662,
    "name": "AORN Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 465094,
    "name": "AOS, LLC    ( Fenton 18 USA )",
    "country": "United States"
  },
  {
    "id": 999436,
    "name": "AP Medesthetics, Inc.    ( Wayland MA USA )",
    "country": "United States"
  },
  {
    "id": 164260,
    "name": "Apaco AG fuer Apparatebau    ( Grellingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 184075,
    "name": "Aparatos SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 150996,
    "name": "APC Cardiovascular Ltd    ( Crewe  England )",
    "country": "UK"
  },
  {
    "id": 439588,
    "name": "APC Cardiovascular Ltd (Netherlands)    ( Belfeld  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 104959,
    "name": "APC Medical Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 108549,
    "name": "APEC Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 391338,
    "name": "Apel Co Ltd    ( Kawaguchi  Japan )",
    "country": "Japan"
  },
  {
    "id": 194762,
    "name": "Apelem SA    ( Nimes Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 465225,
    "name": "Aperek, Inc.    ( Raleigh  USA )",
    "country": "United States"
  },
  {
    "id": 457079,
    "name": "Aperio    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 457080,
    "name": "Aperio (Europe)    ( Summertown  England )",
    "country": "UK"
  },
  {
    "id": 457081,
    "name": "Aperio KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 391340,
    "name": "Apex Biotechnology Corp    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 177091,
    "name": "Apex Dynamics Healthcare Products LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 357233,
    "name": "Apex Health Care Mfg Inc    ( Chia Yi  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 106101,
    "name": "Apex Imaging Corp    ( Duarte CA USA )",
    "country": "United States"
  },
  {
    "id": 458223,
    "name": "Apex Innovations LLC    ( Lafayette LA USA )",
    "country": "United States"
  },
  {
    "id": 322488,
    "name": "Apex Medical Corp (Taiwan)    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 427932,
    "name": "Apex-Carex Healthcare    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 460993,
    "name": "Axway    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 320555,
    "name": "Apheresis Technologies Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 454254,
    "name": "API Healthcare Corp    ( Hartford WI USA )",
    "country": "United States"
  },
  {
    "id": 451826,
    "name": "Apiary Medical Inc    ( Valley Center CA USA )",
    "country": "United States"
  },
  {
    "id": 457915,
    "name": "Apipol-Farma Sp z o o    ( Myslenice  Poland )",
    "country": "Poland"
  },
  {
    "id": 456141,
    "name": "APK Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107216,
    "name": "Aplicare Inc    ( Meriden CT USA )",
    "country": "United States"
  },
  {
    "id": 106761,
    "name": "Apogee Communications Group    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 453495,
    "name": "Apogee Medical LLC    ( Youngsville NC USA )",
    "country": "United States"
  },
  {
    "id": 187564,
    "name": "Apollo Corp    ( Somerset WI USA )",
    "country": "United States"
  },
  {
    "id": 999832,
    "name": "Apollo Digital Paging Company    ( North Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 462962,
    "name": "Apollo Endosurgery Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 105714,
    "name": "Apollo Lasers Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 394344,
    "name": "Apollo Telemedicine Inc    ( Falls Church VA USA )",
    "country": "United States"
  },
  {
    "id": 233429,
    "name": "Apothacare    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 303176,
    "name": "Apotheca Marketing Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 349954,
    "name": "Apothecaries Sundries Mfg Co    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 176078,
    "name": "Apothecary Products Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 286138,
    "name": "Apparatebau Stahl GmbH    ( Freudenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 176006,
    "name": "Apparel That Works    ( Chesterton IN USA )",
    "country": "United States"
  },
  {
    "id": 274416,
    "name": "Appasamy Assoc    ( Tamil Nadu  India )",
    "country": "India"
  },
  {
    "id": 455771,
    "name": "Apple BioMedical Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 277846,
    "name": "Apple Computer Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 456320,
    "name": "Apple Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 227897,
    "name": "Apple Medical Corp    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 459107,
    "name": "Appleton Mfg Div    ( Neenah WI USA )",
    "country": "United States"
  },
  {
    "id": 248763,
    "name": "Applicare Medical Imaging bv    ( Zeist  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 178079,
    "name": "Applied Biomedical    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 458316,
    "name": "Applied BioPhysics Inc    ( Troy NY USA )",
    "country": "United States"
  },
  {
    "id": 106731,
    "name": "Applied Biosystems    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 416570,
    "name": "Applied Biosystems (Argentina)    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 416569,
    "name": "Applied Biosystems (Australia)    ( Mulgrave  Australia )",
    "country": "Australia"
  },
  {
    "id": 416571,
    "name": "Applied Biosystems (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 416572,
    "name": "Applied Biosystems (Belgium)    ( Halle  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 416573,
    "name": "Applied Biosystems (Brazil)    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 416574,
    "name": "Applied Biosystems (Canada)    ( Streetville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 416575,
    "name": "Applied Biosystems (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416581,
    "name": "Applied Biosystems (Czech Republic)    ( Praha 6  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 416582,
    "name": "Applied Biosystems (Denmark)    ( Naerum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 416585,
    "name": "Applied Biosystems (Finland)    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 416586,
    "name": "Applied Biosystems (France)    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 416588,
    "name": "Applied Biosystems (Hungary)    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 416589,
    "name": "Applied Biosystems (Italy)    ( Monza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 416590,
    "name": "Applied Biosystems (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 416591,
    "name": "Applied Biosystems (Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 416595,
    "name": "Applied Biosystems (Norway)    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 416596,
    "name": "Applied Biosystems (Poland)    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 416597,
    "name": "Applied Biosystems (Portugal)    ( Porto  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 416598,
    "name": "Applied Biosystems (Russia)    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 416600,
    "name": "Applied Biosystems (South Africa)    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 416601,
    "name": "Applied Biosystems (Spain)    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 416602,
    "name": "Applied Biosystems (Sweden)    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 416603,
    "name": "Applied Biosystems (Switzerland)    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 416604,
    "name": "Applied Biosystems (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 417202,
    "name": "Applied Biosystems (Thailand)    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 416594,
    "name": "Applied Biosystems (The Netherlands)    ( Nieuwerkerk aan den IJssel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416599,
    "name": "Applied Biosystems Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 416593,
    "name": "Applied Biosystems de Mexico S de R L de C V    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 401837,
    "name": "Applied Biosystems GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 416587,
    "name": "Applied Biosystems Hong Kong Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 187626,
    "name": "Applied Biosystems Ltd    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 416592,
    "name": "Applied Biosystems Malaysia Sdn Bhd    ( Petaling Jaya  USA )",
    "country": "United States"
  },
  {
    "id": 104071,
    "name": "Applied Cardiac Systems Inc    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 451039,
    "name": "Applied Cytometry Systems    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 454316,
    "name": "Applied Diabetes Research    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 458015,
    "name": "Applied Energy Systems Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 102169,
    "name": "Applied Fiberoptics    ( Westmont IL USA )",
    "country": "United States"
  },
  {
    "id": 185692,
    "name": "Applied Imaging Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 118147,
    "name": "Applied Laboratories Inc    ( Columbus IN USA )",
    "country": "United States"
  },
  {
    "id": 458516,
    "name": "Applied Logic Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 155637,
    "name": "Applied Medical Resources    ( Rancho Santa Margarita CA USA )",
    "country": "United States"
  },
  {
    "id": 152552,
    "name": "Applied Medical Technology Inc    ( Brecksville OH USA )",
    "country": "United States"
  },
  {
    "id": 108361,
    "name": "Applied Membrane Technology Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 458868,
    "name": "Applied Photophysics Ltd    ( Leatherhead  England )",
    "country": "UK"
  },
  {
    "id": 398070,
    "name": "Applied Quality Services Ltd    ( Southwater  England )",
    "country": "UK"
  },
  {
    "id": 101004,
    "name": "Applied Science Laboratories    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 458004,
    "name": "Applied Scientific Instrumentation    ( Eugene OR USA )",
    "country": "United States"
  },
  {
    "id": 391890,
    "name": "Applied Scintillation Technologies    ( Annapolis MD USA )",
    "country": "United States"
  },
  {
    "id": 391889,
    "name": "Applied Scintillation Technologies Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 455187,
    "name": "Applied Separations Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 458242,
    "name": "Applied Spectral Imaging Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 458229,
    "name": "Applied Statistics & Management Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 431500,
    "name": "Applied Surface Technologies    ( New Providence NJ USA )",
    "country": "United States"
  },
  {
    "id": 403516,
    "name": "Applied Therapeutics Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 331060,
    "name": "Applied Water Engineering Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 306368,
    "name": "Applitec    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 466930,
    "name": "Appointment-Plus    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 454682,
    "name": "Appropriate Technical Resources Inc (ATR)    ( Laurel MD USA )",
    "country": "United States"
  },
  {
    "id": 105872,
    "name": "Approved Medical Systems    ( Riverside CA USA )",
    "country": "United States"
  },
  {
    "id": 232554,
    "name": "Apria Healthcare Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458317,
    "name": "Apricot Designs    ( Covina CA USA )",
    "country": "United States"
  },
  {
    "id": 418164,
    "name": "Aprilaire    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 454560,
    "name": "Aprima Medical Software Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 280396,
    "name": "APS Dental-Handels GmbH    ( Bopfingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459083,
    "name": "APS Imaging & Supply Inc    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 298386,
    "name": "Apscan Pty Ltd    ( Camperdown  Australia )",
    "country": "Australia"
  },
  {
    "id": 462963,
    "name": "Aptus Endosystems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 297230,
    "name": "Aptus Medizintechnik GmbH    ( Schoeneck/Kilianstaedten  Germany )",
    "country": "Germany"
  },
  {
    "id": 431559,
    "name": "APW Wyott Food Service Equipment    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457557,
    "name": "Apyron Technologies    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 456623,
    "name": "AQB Pools Ltd    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 232716,
    "name": "Aqua Butzke-Werke GmbH    ( Ludwigsfelde  USA )",
    "country": "United States"
  },
  {
    "id": 458304,
    "name": "Aqua Creek Products    ( Missoula MT USA )",
    "country": "United States"
  },
  {
    "id": 322855,
    "name": "Aqua Glass Corp    ( Adamsville TN USA )",
    "country": "United States"
  },
  {
    "id": 457905,
    "name": "Aqua Logic Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458235,
    "name": "Aqua Phase    ( Rundle Mall  Australia )",
    "country": "Australia"
  },
  {
    "id": 460745,
    "name": "Aqua Sciences Inc    ( Miami Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 454185,
    "name": "Aqua Solutions Inc    ( Jasper GA USA )",
    "country": "United States"
  },
  {
    "id": 108362,
    "name": "Aqua-Cel Corp    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 456476,
    "name": "Aqua-Eez Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 185370,
    "name": "Aqua-Medica Przeds Prod-Handl Materialow Medycznyc    ( Lodz  Poland )",
    "country": "Poland"
  },
  {
    "id": 285162,
    "name": "Aqua-Quick Wassertechnik    ( Oberwaldbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 453416,
    "name": "AQUA-WHIRL    (   USA )",
    "country": "United States"
  },
  {
    "id": 458869,
    "name": "Aquabilt LLC    ( Canton CT USA )",
    "country": "United States"
  },
  {
    "id": 416088,
    "name": "AQUABLUE Minibus International bv    ( Velddriel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999791,
    "name": "Aquafine Corporation    ( Valencia CA USA )",
    "country": "USA"
  },
  {
    "id": 453126,
    "name": "AquaJoy Bathlifts Ltd    ( Consett  England )",
    "country": "UK"
  },
  {
    "id": 226192,
    "name": "Aquamed Medizinische Geraete GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458824,
    "name": "Aquaneering Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457426,
    "name": "Aquaris    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 183878,
    "name": "Aquarius Bathware LLC    ( Savannah TN USA )",
    "country": "United States"
  },
  {
    "id": 390931,
    "name": "AquaShield USA    ( Grass Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 455210,
    "name": "Aquasure UK Ltd    ( Bury  England )",
    "country": "UK"
  },
  {
    "id": 403470,
    "name": "Aquatec GmbH & Co KG    ( Isny  USA )",
    "country": "United States"
  },
  {
    "id": 186728,
    "name": "Aquatic Access Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 459236,
    "name": "Aquatic Enterprises Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 390938,
    "name": "Aquila Corp    ( Holmen WI USA )",
    "country": "United States"
  },
  {
    "id": 459394,
    "name": "Aquilant Medical    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 344061,
    "name": "Aquintel Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 323459,
    "name": "Aquionics Inc    ( Erlanger KY USA )",
    "country": "United States"
  },
  {
    "id": 108998,
    "name": "AR Custom Medical Products Ltd    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 291936,
    "name": "AR Medicom Inc    ( Lachine PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 376020,
    "name": "AR Medicom Inc (Asia) Ltd    ( Chai Wan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456553,
    "name": "AR Nelson    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458828,
    "name": "Aragona Medical Inc    ( River Vale NJ USA )",
    "country": "United States"
  },
  {
    "id": 456341,
    "name": "Aram Huvis Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 426533,
    "name": "ARAMARK Clinical Technology Services    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 402858,
    "name": "Aramark ServiceMaster Facility Services    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 451048,
    "name": "Aramsco Inc    ( Paulsboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 460700,
    "name": "ARANZ Medical    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 458255,
    "name": "ARBA Retail Systems    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 150214,
    "name": "ARC Distributors    ( Centerville MD USA )",
    "country": "United States"
  },
  {
    "id": 106128,
    "name": "ARC Equipment and Supplies    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 458199,
    "name": "ARC Healthcare Solutions Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 392735,
    "name": "ARC Laser AG    ( Jona  USA )",
    "country": "United States"
  },
  {
    "id": 155710,
    "name": "ARC Laser Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 392734,
    "name": "ARC Laser GmbH    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 108833,
    "name": "ARC Medical Inc    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 458313,
    "name": "ARC Products LLC    ( Des Peres MO USA )",
    "country": "United States"
  },
  {
    "id": 455225,
    "name": "ARCA-MEDICA GmbH    ( Neuenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456300,
    "name": "Arcadia Medical Corp    ( Crown Point IN USA )",
    "country": "United States"
  },
  {
    "id": 454193,
    "name": "Arcadian Medical Inc    ( Monroe NC USA )",
    "country": "United States"
  },
  {
    "id": 452816,
    "name": "ARCFORGE GmbH    ( Isernhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457912,
    "name": "Archer & White Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 280652,
    "name": "Archivtechnik Kunze    ( Calw  Germany )",
    "country": "Germany"
  },
  {
    "id": 320790,
    "name": "ArcMate Mfg Corp    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 398084,
    "name": "Arco Medic Ltd    ( Omer  USA )",
    "country": "United States"
  },
  {
    "id": 453928,
    "name": "Arcoma AB    ( Vaxjo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 458319,
    "name": "Arcoma North America    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 164159,
    "name": "Arcomed AG    ( Regensdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 401777,
    "name": "Arcomed GmbH    ( Giessen  USA )",
    "country": "United States"
  },
  {
    "id": 369583,
    "name": "Axya Medical Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 401778,
    "name": "Arcomed SMD SA    ( Cain  France )",
    "country": "France"
  },
  {
    "id": 324159,
    "name": "Arcomedical Infusion Ltd    ( West Horndon  England )",
    "country": "UK"
  },
  {
    "id": 458675,
    "name": "Arcon Vernova Inc    ( Saline MI USA )",
    "country": "United States"
  },
  {
    "id": 409917,
    "name": "ArcOne    ( Taunton MA USA )",
    "country": "United States"
  },
  {
    "id": 472796,
    "name": "Arcos Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 455452,
    "name": "ArcRoyal Ltd    ( Kells  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 456711,
    "name": "Arctic Heat USA    ( Hillsdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 462936,
    "name": "Arctiko International    ( Esbjerg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451029,
    "name": "Ardent Sound Inc    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 398066,
    "name": "Ardo medical AG    ( Unteraegeri  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 398067,
    "name": "Ardo medical GmbH    ( Almere  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452659,
    "name": "Ardo medical GmbH    ( Oberpfaffenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453703,
    "name": "Ardo medical Ltd    ( Taunton  England )",
    "country": "UK"
  },
  {
    "id": 376332,
    "name": "Ardus Medical Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 459348,
    "name": "Arecont Vision LLC    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 460816,
    "name": "Arecont Vision LLC    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 152406,
    "name": "Aremco    ( Lenham  USA )",
    "country": "United States"
  },
  {
    "id": 106225,
    "name": "Aremco Scientific    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 285188,
    "name": "Arends Elektrogeraete GmbH    ( Offenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 458829,
    "name": "Aretech LLC    ( Ashburn VA USA )",
    "country": "United States"
  },
  {
    "id": 452856,
    "name": "Argavest Inc    ( Woodridge IL USA )",
    "country": "United States"
  },
  {
    "id": 369580,
    "name": "Argentum Medical LLC    ( Willowbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 434444,
    "name": "Argon Electronics    ( Luton  England )",
    "country": "UK"
  },
  {
    "id": 101059,
    "name": "Argon Medical Devices Inc    ( Athens TX USA )",
    "country": "United States"
  },
  {
    "id": 156377,
    "name": "Argos SA    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 441558,
    "name": "Argos Technologies Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 176931,
    "name": "Argus Medical AG    ( Heimberg/Thun  USA )",
    "country": "United States"
  },
  {
    "id": 362831,
    "name": "Argus Technologies    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 453483,
    "name": "Argutus Medical Ltd    ( Dublin 2  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 451599,
    "name": "Aria Medical    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 463010,
    "name": "Ariane Medical Systems Ltd    ( Derby  England )",
    "country": "UK"
  },
  {
    "id": 454309,
    "name": "Aribex Inc    ( Orem UT USA )",
    "country": "United States"
  },
  {
    "id": 452898,
    "name": "Aries srl    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 471711,
    "name": "Ariosa Diagnostics    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 174531,
    "name": "Arista Surgical Supply Co Inc    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 105874,
    "name": "Aristo Import Co Inc    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 418683,
    "name": "Arizant Healthcare Inc Sub Arizant Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 106690,
    "name": "Arizona Instrument LLC    ( Chandler AZ USA )",
    "country": "United States"
  },
  {
    "id": 227458,
    "name": "Arjo Canada Inc    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 418022,
    "name": "Arjo Equipements Hospitaliers SA    ( Roncq Cedex  France )",
    "country": "France"
  },
  {
    "id": 418023,
    "name": "Arjo GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 418031,
    "name": "Arjo Hospital Equipment (Pty) Ltd    ( Bulimba  Australia )",
    "country": "Australia"
  },
  {
    "id": 456989,
    "name": "Axygen Inc    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 418025,
    "name": "Arjo Hospital Equipment NV/SA    ( Wommelgem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 418024,
    "name": "Arjo Hospital Equipment sro    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 178879,
    "name": "Arjo Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 418021,
    "name": "Arjo International AG    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 418027,
    "name": "Arjo Italia SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 233271,
    "name": "Arjo Ltd UK    ( Gloucester  England )",
    "country": "UK"
  },
  {
    "id": 418028,
    "name": "Arjo Nederland bv    ( Tiel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 418029,
    "name": "Arjo Poland Sp z o o    ( Komorniki (k. Poznania)  Poland )",
    "country": "Poland"
  },
  {
    "id": 149981,
    "name": "Arjo Scandinavia AB    ( Eslov  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 418030,
    "name": "Arjo Spain SA    ( Sant Cugat del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 454170,
    "name": "ArjoHuntleigh    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 457556,
    "name": "ArjoHuntleigh (Hong Kong) Ltd    ( Kwai Chung  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457552,
    "name": "ArjoHuntleigh (Shanghai) Medical Trading Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457554,
    "name": "ArjoHuntleigh Africa Pty Ltd    ( Klerksoord  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454176,
    "name": "ArjoHuntleigh Australia Pty Ltd    ( O'Conner  Australia )",
    "country": "Australia"
  },
  {
    "id": 456762,
    "name": "ArjoHuntleigh bv    ( Tiel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454055,
    "name": "ArjoHuntleigh Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457553,
    "name": "ArjoHuntleigh Healthcare India Pve Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 457555,
    "name": "ArjoHuntleigh Latin America Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456763,
    "name": "ArjoHuntleigh Pty Ltd    ( O'Connor  Australia )",
    "country": "Australia"
  },
  {
    "id": 457947,
    "name": "ArjoHuntleigh SAS    ( Roncq Cedex  France )",
    "country": "France"
  },
  {
    "id": 456467,
    "name": "ArjoHuntleigh UK and Ireland    ( Dunstable  England )",
    "country": "UK"
  },
  {
    "id": 162066,
    "name": "ArjoWiggins Appleton plc    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 454823,
    "name": "Arjowiggins Healthcare    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 162045,
    "name": "ArjoWiggins Medical Inc    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 417403,
    "name": "ArjoWiggins SAS    ( Issy-les-Moulineaux  France )",
    "country": "France"
  },
  {
    "id": 456536,
    "name": "Ark Bio-Medical Canada Corp    ( Winsloe PE Canada )",
    "country": "Canada"
  },
  {
    "id": 455005,
    "name": "Arkansas Biomedical Services    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 456907,
    "name": "Arkansas Lions Eye Bank & Laboratory    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 306302,
    "name": "Arkanum    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 458830,
    "name": "Arkeia Software Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 426495,
    "name": "ARKRAY Europe BV    ( Amstelveen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 377486,
    "name": "ARKRAY Inc    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 451697,
    "name": "ARKRAY USA    ( Edina MN USA )",
    "country": "United States"
  },
  {
    "id": 307623,
    "name": "Arlab Ltda    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 157540,
    "name": "Arlanda Medicinska Instrument Co AB    ( Sollentuna  USA )",
    "country": "United States"
  },
  {
    "id": 199556,
    "name": "Arlington Scientific Inc    ( Springville UT USA )",
    "country": "United States"
  },
  {
    "id": 463046,
    "name": "Arma Bleaching Industries Turkey Ltd    ( Mersin  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 398083,
    "name": "Arma-SA Belgium    ( Luxembourg  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 451230,
    "name": "Arman Setad Co Ltd    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 439564,
    "name": "Armedica Mfg Corp    ( Greenwood AR USA )",
    "country": "United States"
  },
  {
    "id": 290080,
    "name": "Armin Poly-Version Inc    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 298551,
    "name": "Armstrong Healthcare Ltd    ( High Wycombe  USA )",
    "country": "United States"
  },
  {
    "id": 459239,
    "name": "Armstrong International    ( Three Rivers MI USA )",
    "country": "United States"
  },
  {
    "id": 102215,
    "name": "Armstrong Medical Industries Inc    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 454086,
    "name": "Armstrong Medical Ltd    ( Coleraine  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 457852,
    "name": "Armstrong Monitoring Corp    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 171160,
    "name": "Arndorfer Inc    ( Greendale WI USA )",
    "country": "United States"
  },
  {
    "id": 454724,
    "name": "Arneson Industries LLC    ( Helena MT USA )",
    "country": "United States"
  },
  {
    "id": 283712,
    "name": "Arno Amarell Praezisions-Thermometer und Araeomete    ( Kreuzwertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 286656,
    "name": "Arno Barthelmes & Co GmbH    ( Zella/Mehlis  Germany )",
    "country": "Germany"
  },
  {
    "id": 456572,
    "name": "Arno Barthelmes Tuning Forks    ( Zella-Mehlis  Germany )",
    "country": "Germany"
  },
  {
    "id": 139472,
    "name": "Arnold & Richter Cine Technik GmbH & Co    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451864,
    "name": "Arnold Dental Supply    ( Lynnwood WA USA )",
    "country": "United States"
  },
  {
    "id": 168440,
    "name": "Arnold Deppeler SA    ( Rolle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 435502,
    "name": "ARNOS Medical bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453903,
    "name": "Arobella Medical LLC    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 452243,
    "name": "AroSurgical Instrument Corp    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 152071,
    "name": "Arplay Medical SAS    ( Izeure  France )",
    "country": "France"
  },
  {
    "id": 457948,
    "name": "ARPwave    ( Apple Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 457866,
    "name": "ARQ Medical Systems    ( Ozone Park NY USA )",
    "country": "United States"
  },
  {
    "id": 460999,
    "name": "Arradiance    ( Sudbury MA USA )",
    "country": "United States"
  },
  {
    "id": 382227,
    "name": "Array Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 395410,
    "name": "Array Corp Europe    ( Roden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 395383,
    "name": "Array Corp USA    ( Hampton NH USA )",
    "country": "United States"
  },
  {
    "id": 465174,
    "name": "Arraya Solutions    ( plymouth Meeting 52 USA )",
    "country": "United States"
  },
  {
    "id": 458832,
    "name": "Arrayit Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 104589,
    "name": "Arrhythmia Research Technology Inc    ( Fitchburg MA USA )",
    "country": "United States"
  },
  {
    "id": 428707,
    "name": "ARRI Inc    ( Blauvelt NY USA )",
    "country": "United States"
  },
  {
    "id": 459356,
    "name": "Arrington Research Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 384429,
    "name": "Arrow Africa (Pty) Ltd    ( Sandton  USA )",
    "country": "United States"
  },
  {
    "id": 322432,
    "name": "Arrow Deutschland GmbH    ( Erding  USA )",
    "country": "United States"
  },
  {
    "id": 384430,
    "name": "Arrow France SA    ( Anglet  USA )",
    "country": "United States"
  },
  {
    "id": 384434,
    "name": "Arrow Hellas AEE    ( Athinia  USA )",
    "country": "United States"
  },
  {
    "id": 384437,
    "name": "Arrow Holland Medical Products bv    ( Weesp  USA )",
    "country": "United States"
  },
  {
    "id": 384438,
    "name": "Arrow Iberia SA    ( Alcobendas (Madrid)  USA )",
    "country": "United States"
  },
  {
    "id": 143739,
    "name": "Arrow Imaging Inc    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 322431,
    "name": "Arrow India    ( Chennai  USA )",
    "country": "United States"
  },
  {
    "id": 384428,
    "name": "Arrow Internacional de Mexico SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 384439,
    "name": "Arrow International CR as    ( Hradec Kralove  USA )",
    "country": "United States"
  },
  {
    "id": 449832,
    "name": "Arrow International Europe    ( Diegem  USA )",
    "country": "United States"
  },
  {
    "id": 384427,
    "name": "Arrow Japan Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 174532,
    "name": "Arrow Medical Products Ltd    ( Oakville ON USA )",
    "country": "United States"
  },
  {
    "id": 458635,
    "name": "Arrow Security Corp    ( Springfield MA USA )",
    "country": "United States"
  },
  {
    "id": 384440,
    "name": "Arrow Slovensko Piest'any sro    ( Piest'any  USA )",
    "country": "United States"
  },
  {
    "id": 415721,
    "name": "Arrow-Tech Inc    ( Rolla ND USA )",
    "country": "United States"
  },
  {
    "id": 453976,
    "name": "Arrowhead Forensics Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 105285,
    "name": "ARS Enterprises    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 277915,
    "name": "Ars Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456751,
    "name": "Ars Medical Instrument Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455554,
    "name": "Arstasis Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 452278,
    "name": "ART Group - Adaptive Equipment Systems Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 458263,
    "name": "Art Robbins Instruments LLC    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 105457,
    "name": "Art Specialty Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 452888,
    "name": "Arta Plast    ( Wayzata MN USA )",
    "country": "United States"
  },
  {
    "id": 452890,
    "name": "Arta Plast AB    ( Tyreso  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 466447,
    "name": "ARTEC Environmental Monitoring    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 458320,
    "name": "Artec Group    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 376340,
    "name": "Artegraft Inc    ( North Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 292012,
    "name": "Artel    ( Westbrook ME USA )",
    "country": "United States"
  },
  {
    "id": 164161,
    "name": "Artema Medical AB    ( Sundbyberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 395008,
    "name": "ArteriA Medical Science Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 455454,
    "name": "Arteriocyte Medical Systems Inc    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 999651,
    "name": "Arteriocyte Medical Systems, Inc    ( Hopkinto MA USA )",
    "country": "United States"
  },
  {
    "id": 337410,
    "name": "Arthrex BvbA    ( Kontich  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 170390,
    "name": "Arthrex Canada    ( Milton ON USA )",
    "country": "United States"
  },
  {
    "id": 185728,
    "name": "Arthrex Ges mbH    ( Wiener Neudorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 153014,
    "name": "Arthrex GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 108697,
    "name": "Arthrex Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 292172,
    "name": "Arthrex Italy    ( Formello RM  USA )",
    "country": "United States"
  },
  {
    "id": 283293,
    "name": "Arthrex Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 330770,
    "name": "Arthrex SAS    ( Lezennes  France )",
    "country": "France"
  },
  {
    "id": 451006,
    "name": "ArthroCare    (   USA )",
    "country": "United States"
  },
  {
    "id": 454985,
    "name": "ArthroCare (Deutschland) AG    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 254283,
    "name": "ArthroCare Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 408310,
    "name": "ArthroCare Europe AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454986,
    "name": "ArthroCare UK Ltd    ( Knaresborough  England )",
    "country": "UK"
  },
  {
    "id": 451262,
    "name": "Arthronet GmbH & Co KG    ( Burscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 451263,
    "name": "Arthronet Medical Inc    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 458833,
    "name": "ArthroPlastics Inc    ( Chagrin Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 451497,
    "name": "Arthrosurface Inc    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 305227,
    "name": "Arthrotek Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 453427,
    "name": "ArthroWave Medical Technologies    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 105875,
    "name": "Arthur Blank & Co Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 454319,
    "name": "Articulate Instruments Ltd    ( Musselburgh  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 458520,
    "name": "Artisan Medical    ( Medford NJ USA )",
    "country": "United States"
  },
  {
    "id": 307165,
    "name": "ArtMED Sp z o o    ( Krakow  Poland )",
    "country": "Poland"
  },
  {
    "id": 999587,
    "name": "Artograph, Inc.    ( Delano MN USA )",
    "country": "USA"
  },
  {
    "id": 103594,
    "name": "Artromick International Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 172390,
    "name": "Artsana SpA    ( Grandate (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 286660,
    "name": "Artur Schikorr Augen- und Mikroaugenpinzetten    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 149146,
    "name": "Artur Steiner GmbH    ( Engen/Hegau  Germany )",
    "country": "Germany"
  },
  {
    "id": 458834,
    "name": "Aruba Networks    ( Sunnyvalle CA USA )",
    "country": "United States"
  },
  {
    "id": 451315,
    "name": "ARUP Laboratories Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 176081,
    "name": "AryLift Inc    ( Shallotte NC USA )",
    "country": "United States"
  },
  {
    "id": 306604,
    "name": "Arzt-und Krankenhausbedarf    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 398077,
    "name": "AS Medizintechnik    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 337020,
    "name": "AS Nogusra    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 458256,
    "name": "AS Software Inc    ( Englewood Cliffs NJ USA )",
    "country": "United States"
  },
  {
    "id": 306312,
    "name": "ASA srl    ( Arcugnano (VI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 177056,
    "name": "Asah Medico A/S    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 138252,
    "name": "Asahi Medical Co Ltd (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 332682,
    "name": "Asahi Medical Co Ltd (US)    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 399307,
    "name": "Asahi Medical GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 174092,
    "name": "Asahi Roentgen Ind Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 279658,
    "name": "Asami Tanaka Dental Enterprises Europa GmbH    ( Friedrichsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 439965,
    "name": "ASAP Endoscopic Products GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458835,
    "name": "Ascend Clinical LLC    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458836,
    "name": "Ascend Software Inc    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 453584,
    "name": "Ascendia MedTech AB    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401068,
    "name": "Ascension    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 449850,
    "name": "Ascension Orthopedics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 455299,
    "name": "Ascent A Stryker Sustainability Solution    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 451598,
    "name": "Ascent Health Solutions    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 282624,
    "name": "Asche AG Arzneimittel Mit Service    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 426724,
    "name": "Asclepion Laser Technologies GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 454799,
    "name": "Ascom (Schweiz) AG    ( Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454802,
    "name": "Ascom (UK) Ltd    ( Streetly  England )",
    "country": "UK"
  },
  {
    "id": 454801,
    "name": "Ascom Hong Kong Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457147,
    "name": "Ascom Patient Systems (US)    ( Lakewood Ranch  USA )",
    "country": "United States"
  },
  {
    "id": 452053,
    "name": "Ascom Sweden AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451473,
    "name": "Ascom Wireless Solutions    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 185372,
    "name": "Ascor SA    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 453926,
    "name": "Ascot Autoclaves Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 458618,
    "name": "Aseptic Enclosures    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 103471,
    "name": "Aseptico Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 285458,
    "name": "ASF Thomas Industries GmbH    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 999833,
    "name": "Ash Technologies Ltd.    ( Co Kildare  USA )",
    "country": "United States"
  },
  {
    "id": 451564,
    "name": "Ashcroft Inc    ( Stratford CT USA )",
    "country": "United States"
  },
  {
    "id": 459001,
    "name": "Ashland Inc    ( Covington KY USA )",
    "country": "United States"
  },
  {
    "id": 363336,
    "name": "ASI Medical Equipment    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 262275,
    "name": "ASI Medical Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 183876,
    "name": "ASI-Modulex    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 455782,
    "name": "Asia Connection Co Ltd    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 398072,
    "name": "Asia Pacific Medical    ( Pymble  USA )",
    "country": "United States"
  },
  {
    "id": 455784,
    "name": "Asiatic Fiber Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456733,
    "name": "ASICO LLC    ( Westmont IL USA )",
    "country": "United States"
  },
  {
    "id": 280420,
    "name": "Asid Bonz und Sohn GmbH    ( Boeblingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287148,
    "name": "Asind GmbH    ( Warstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 192811,
    "name": "Askle Sante    ( Nimes Cedex  France )",
    "country": "France"
  },
  {
    "id": 227317,
    "name": "Asmot srl    ( Torino (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451512,
    "name": "ASO Americas    ( Jaurez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 392151,
    "name": "Aso Corp    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 451511,
    "name": "ASO Europe    ( Nijmegen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 398214,
    "name": "Aso Pharmaceutical Co Ltd    ( Kumamoto  USA )",
    "country": "United States"
  },
  {
    "id": 451513,
    "name": "ASO Philippines    ( Lapu-Lapu City  USA )",
    "country": "United States"
  },
  {
    "id": 305933,
    "name": "ASP Harm Reduction Systems Pty Ltd    ( St Marys  Australia )",
    "country": "Australia"
  },
  {
    "id": 456505,
    "name": "ASP Healthcare Pty Ltd    ( St Marys  Australia )",
    "country": "Australia"
  },
  {
    "id": 399297,
    "name": "ASP Plastics    ( St Marys  Australia )",
    "country": "Australia"
  },
  {
    "id": 187304,
    "name": "Aspect Medical Systems Inc    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 365311,
    "name": "Aspect Medical Systems International bv    ( De Meern  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 402131,
    "name": "Aspel Sa    ( Zabierzow  Poland )",
    "country": "Poland"
  },
  {
    "id": 460670,
    "name": "Aspen Advisors LLC    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 454872,
    "name": "Aspen Medical Europe Ltd    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 398533,
    "name": "Aspen Medical Products    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 378253,
    "name": "Aspen Surgical Products Inc    ( Caledonia MI USA )",
    "country": "United States"
  },
  {
    "id": 451548,
    "name": "Aspyra Inc    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 452283,
    "name": "Aspyra Technologies Inc    ( Rickmansworth  England )",
    "country": "UK"
  },
  {
    "id": 286028,
    "name": "Ass-Etikett Arnold Suessmilch GmbH    ( Schwaebisch Gmuend  Germany )",
    "country": "Germany"
  },
  {
    "id": 454321,
    "name": "ASSA ABLOY AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454318,
    "name": "ASSA ABLOY Canada    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 280251,
    "name": "Assa Med GmbH    ( Bexbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 170748,
    "name": "Assay Designs Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 186611,
    "name": "Assay Technology Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 402253,
    "name": "Asset Management Associates LLC    ( Avon CT USA )",
    "country": "United States"
  },
  {
    "id": 107223,
    "name": "Associated Bag Co    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 336516,
    "name": "Associated Enterprises    ( Kathmandu-3  Nepal )",
    "country": "Nepal"
  },
  {
    "id": 334945,
    "name": "Automated Case Management Systems Inc    ( Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 403735,
    "name": "Associated Health Systems Inc    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452546,
    "name": "Associated Home Textiles (Pvt) Ltd    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 171155,
    "name": "Associated Medical Products and Medical Systems    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 356156,
    "name": "Associated Research Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 171151,
    "name": "Associated X-Ray Imaging Corp    ( Haverhill MA USA )",
    "country": "United States"
  },
  {
    "id": 170197,
    "name": "Associated X-Ray Services Inc    ( South Bend IN USA )",
    "country": "United States"
  },
  {
    "id": 331062,
    "name": "Associates of Cape Cod Inc    ( East Falmouth MA USA )",
    "country": "United States"
  },
  {
    "id": 392646,
    "name": "Associates of Cape Cod International (Ireland) Ltd    ( Ashford  USA )",
    "country": "United States"
  },
  {
    "id": 392645,
    "name": "Associates of Cape Cod International Inc (France)    ( Decines-Charpieu  USA )",
    "country": "United States"
  },
  {
    "id": 392644,
    "name": "Associates of Cape Cod International Inc (UK)    ( Merseyside  England )",
    "country": "UK"
  },
  {
    "id": 152358,
    "name": "Assurance Technologies Inc    ( Bartlett IL USA )",
    "country": "United States"
  },
  {
    "id": 464827,
    "name": "AssureRx Health Inc    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 458388,
    "name": "Astanza Laser Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 470548,
    "name": "Astar Physiotechnology    ( Bielsko-Biala  Poland )",
    "country": "Poland"
  },
  {
    "id": 460667,
    "name": "Astarita Assoc    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 455657,
    "name": "Astec Co Ltd    ( Fukuoka  Japan )",
    "country": "Japan"
  },
  {
    "id": 335416,
    "name": "Astec Microflow Ltd    ( Weston-Super-Mare  USA )",
    "country": "United States"
  },
  {
    "id": 156378,
    "name": "Astell Scientific Ltd    ( Sidcup  England )",
    "country": "UK"
  },
  {
    "id": 450893,
    "name": "Astellas Pharm Inc (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450892,
    "name": "Astellas Pharm US Inc    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 458321,
    "name": "Asteres Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 455338,
    "name": "Asthmatx Inc A Boston Scientific Co    ( Sunnyvale CA USA )",
    "country": "USA"
  },
  {
    "id": 337592,
    "name": "Astoria-Pacific Inc    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 418047,
    "name": "Astra SpA    ( Casalecchio di Reno (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418044,
    "name": "Astra Tech A/S    ( Albertslund  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 418048,
    "name": "Astra Tech AS    ( Lorenskog  Norway )",
    "country": "Norway"
  },
  {
    "id": 418050,
    "name": "Astra Tech bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 359721,
    "name": "Astra Tech France    ( Rueil-Malmaison Cedex  France )",
    "country": "France"
  },
  {
    "id": 418043,
    "name": "Astra Tech Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 283988,
    "name": "Astra Tech GmbH    ( Elz  Germany )",
    "country": "Germany"
  },
  {
    "id": 178225,
    "name": "Astra Tech Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454300,
    "name": "Astra Tech Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 418051,
    "name": "Astra Tech Ltd    ( Stonehouse  England )",
    "country": "UK"
  },
  {
    "id": 418045,
    "name": "Astra Tech Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 418049,
    "name": "Astra Tech SA    ( L'Hospitalet de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 157541,
    "name": "Astra Tech Sverige    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 105001,
    "name": "Astralite Corp    ( Somerset CA USA )",
    "country": "United States"
  },
  {
    "id": 439177,
    "name": "AstraZeneca Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 475965,
    "name": "Astridge International    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 456979,
    "name": "Astro Medical    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 392802,
    "name": "Astro-Med GmbH    ( Rodgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 102589,
    "name": "Astro-Med Inc    ( West Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 392805,
    "name": "Astro-Med Inc (Canada)    ( Brossard PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 323977,
    "name": "Astro-Med Ltd (UK)    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 186154,
    "name": "Astro-Med SNC    ( Trappes  France )",
    "country": "France"
  },
  {
    "id": 392803,
    "name": "Astro-Med srl    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 458190,
    "name": "Astute Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 461002,
    "name": "Astute Technology    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 999222,
    "name": "ASUS Computer    (   USA )",
    "country": "United States"
  },
  {
    "id": 285122,
    "name": "Asys Apotheken Systeme    ( Oberhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 463053,
    "name": "Asys Softwareentwicklung GmbH    ( Oberhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279588,
    "name": "AT & T Deutschland GmbH    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999864,
    "name": "AT&T    (   USA )",
    "country": "United States"
  },
  {
    "id": 338281,
    "name": "Atad Developments & Medical Services Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 163230,
    "name": "ATAGO Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451110,
    "name": "ATAGO INDIA Instruments Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 449798,
    "name": "ATAGO USA Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 402558,
    "name": "Atcor Medical Pty Ltd    ( West Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 107002,
    "name": "ATD-American Co    ( Wyncote PA USA )",
    "country": "United States"
  },
  {
    "id": 458257,
    "name": "Ateb Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 170198,
    "name": "ATEC Industries Ltd    ( Elkridge MD USA )",
    "country": "United States"
  },
  {
    "id": 177652,
    "name": "Atecs SA    ( Waterloo  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 151511,
    "name": "Ateliers Reunis Caddie SA    ( Schiltigheim Cedex  France )",
    "country": "France"
  },
  {
    "id": 187037,
    "name": "Ates Medica Device srl    ( Colognola ai Colli (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455751,
    "name": "Atex Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999645,
    "name": "Athena Beauty Inc.    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 459101,
    "name": "Athena Diagnostics Inc    ( Worcester MA USA )",
    "country": "United States"
  },
  {
    "id": 460701,
    "name": "Athena GTX    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 454535,
    "name": "Athenahealth Inc    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 451753,
    "name": "Athrodax Healthcare International Ltd    ( Drybrook  England )",
    "country": "UK"
  },
  {
    "id": 454329,
    "name": "Atirix Medical Systems    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 456475,
    "name": "Atlanta BioMedical Corp    ( Suwanee GA USA )",
    "country": "United States"
  },
  {
    "id": 451049,
    "name": "Atlanta Dental Supply    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 466280,
    "name": "Atlanta Fixture & Sales Co Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 363902,
    "name": "Atlantic Bio-Medical    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 238477,
    "name": "Atlantic Medco Inc    ( Verona NJ USA )",
    "country": "United States"
  },
  {
    "id": 454702,
    "name": "Atlantic Medical Supply    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 458324,
    "name": "Atlantic Nuclear    ( Rockland MA USA )",
    "country": "United States"
  },
  {
    "id": 452463,
    "name": "Atlantic Purification Systems Ltd    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 101065,
    "name": "Atlantic Ultraviolet Corp    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 457138,
    "name": "Atlantis Worldwide LLC    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 272389,
    "name": "Biotek Medical Imd    ( Ankara  USA )",
    "country": "United States"
  },
  {
    "id": 177857,
    "name": "Atlantis Worldwide Ltd    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 452656,
    "name": "Atlas Copco Compressors LLC    ( Rock Hill SC USA )",
    "country": "United States"
  },
  {
    "id": 456601,
    "name": "Atlas Engineering    ( Hod Hasharon  Israel )",
    "country": "Israel"
  },
  {
    "id": 460818,
    "name": "Atlas Food Systems and Services Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 465154,
    "name": "Atlas International    ( Rancho Cordova 11 USA )",
    "country": "United States"
  },
  {
    "id": 458259,
    "name": "Atlas Medical Software    ( Calabasas CA USA )",
    "country": "United States"
  },
  {
    "id": 454530,
    "name": "Atlas Medical Technologies    ( St Petersberg FL USA )",
    "country": "United States"
  },
  {
    "id": 172256,
    "name": "Atlas Researches Ltd    ( Hod Hasharon  Israel )",
    "country": "Israel"
  },
  {
    "id": 107224,
    "name": "Atlas Sound LP    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 107970,
    "name": "Atlas Specialty Lighting    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 457319,
    "name": "Atlas Spine Inc    ( Jupiter FL USA )",
    "country": "United States"
  },
  {
    "id": 177269,
    "name": "Atlas Surgical Co    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 458249,
    "name": "Atlus Inc    ( Walker MI USA )",
    "country": "United States"
  },
  {
    "id": 439522,
    "name": "ATMOS Medica SL    ( Mataro (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 439532,
    "name": "ATMOS Medical AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439529,
    "name": "ATMOS Medical France    ( Marseille  France )",
    "country": "France"
  },
  {
    "id": 439530,
    "name": "ATMOS MedizinTechnik Asia Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 152463,
    "name": "ATMOS MedizinTechnik GmbH & Co KG    ( Lenzkirch  Germany )",
    "country": "Germany"
  },
  {
    "id": 439531,
    "name": "ATMOS MedizinTechnik Italia srl    ( Trezzano Sul Naviglio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458030,
    "name": "Atmos Tech Industries    ( Ocean NJ USA )",
    "country": "United States"
  },
  {
    "id": 999406,
    "name": "ATMOS, Inc.    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 283088,
    "name": "ATO FORM GmbH    ( Sailauf  Germany )",
    "country": "Germany"
  },
  {
    "id": 149094,
    "name": "Ato Form Produktion A/S    ( Otterup  USA )",
    "country": "United States"
  },
  {
    "id": 154422,
    "name": "Atom Medical Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 172169,
    "name": "Atom Medical International Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 467192,
    "name": "ATOM Medical USA LLC    ( Wexford PA USA )",
    "country": "United States"
  },
  {
    "id": 466474,
    "name": "Atom Scientific Ltd    ( Hyde  England )",
    "country": "UK"
  },
  {
    "id": 104397,
    "name": "Atomergic Chemetals Corp    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 109519,
    "name": "Atomic Energy of Canada Ltd (AECL)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108592,
    "name": "Atomic International    ( Frederick PA USA )",
    "country": "United States"
  },
  {
    "id": 183971,
    "name": "Atos Medical    ( Horby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 108435,
    "name": "Atos Medical Inc    ( West Allis WI USA )",
    "country": "United States"
  },
  {
    "id": 458181,
    "name": "Atossa Genetics Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 442123,
    "name": "AtriCure Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 333546,
    "name": "Atrion Medical Products Inc    ( Arab AL USA )",
    "country": "United States"
  },
  {
    "id": 454471,
    "name": "Atritech Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 107225,
    "name": "Atrium Medical Corp    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 999600,
    "name": "Atrix International Inc.    ( Burnsville MN USA )",
    "country": "USA"
  },
  {
    "id": 466276,
    "name": "ATS Alaska    ( Anchorage AK USA )",
    "country": "United States"
  },
  {
    "id": 104106,
    "name": "ATS Laboratories Inc    ( Bridgeport CT USA )",
    "country": "United States"
  },
  {
    "id": 439401,
    "name": "ATS Medical France Sarl    ( Boulougne-Billancourt  France )",
    "country": "France"
  },
  {
    "id": 384416,
    "name": "ATS Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 402716,
    "name": "AtStaff Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 460702,
    "name": "Attachmate    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 300811,
    "name": "Attachments International Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 458260,
    "name": "Attainia    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 460984,
    "name": "AtTask Inc    ( Lehi UT USA )",
    "country": "United States"
  },
  {
    "id": 452928,
    "name": "Attends Healthcare Products    ( Greenville NC USA )",
    "country": "United States"
  },
  {
    "id": 454372,
    "name": "Attends Healthcare Products    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 454373,
    "name": "Attends Healthcare Products    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 178433,
    "name": "Atto Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 463063,
    "name": "atum Laser GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 153028,
    "name": "Atun Med Geraete & Instrumente    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 451887,
    "name": "ATWILL Corp    ( Yokohama-shi  USA )",
    "country": "United States"
  },
  {
    "id": 101009,
    "name": "Atwood Industries    ( Cardiff by the Sea CA USA )",
    "country": "United States"
  },
  {
    "id": 323716,
    "name": "Atys Medical    ( Soucieu-en-Jarrest  France )",
    "country": "France"
  },
  {
    "id": 456143,
    "name": "Auckland Medical Polymer (Tianjin) Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 460570,
    "name": "AUDAC    ( Hasselt  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 107226,
    "name": "Audex Inc    ( Longview TX USA )",
    "country": "United States"
  },
  {
    "id": 466266,
    "name": "audifon Asia Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 466264,
    "name": "audifon GmbH & Co KG    ( Koelleda  Germany )",
    "country": "Germany"
  },
  {
    "id": 466265,
    "name": "audifon USA Inc    ( Debary FL USA )",
    "country": "United States"
  },
  {
    "id": 298482,
    "name": "Audinsa    ( Guatemala  Guatemala )",
    "country": "Guatemala"
  },
  {
    "id": 174534,
    "name": "Audio Controle Inc    ( Sherbrooke PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 282916,
    "name": "Audio Service GmbH    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 454491,
    "name": "Audio Technologies    ( Gossolengo (PC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 465170,
    "name": "Audio Visual Resources, Inc.    ( Savannah 39 USA )",
    "country": "United States"
  },
  {
    "id": 339500,
    "name": "Audiological Engineering Corp    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 465166,
    "name": "Audiology Systems    ( Schaumburg 50 USA )",
    "country": "United States"
  },
  {
    "id": 450890,
    "name": "Audioscan    ( Dorchester ON Canada )",
    "country": "Canada"
  },
  {
    "id": 312423,
    "name": "Audit Diagnostics    ( Carrigtwohill  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 451462,
    "name": "Audit Diagnostics Benelux    ( Pollare  USA )",
    "country": "United States"
  },
  {
    "id": 451463,
    "name": "Audit Diagnostics China Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453594,
    "name": "Audit Diagnostics Dubai Intelution LLC    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 451464,
    "name": "Audit Diagnostics UK    ( Haywards Heath  England )",
    "country": "UK"
  },
  {
    "id": 451465,
    "name": "Audit Urilab India PVT Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 465183,
    "name": "AudSoft, Inc.    ( Hanover 13 USA )",
    "country": "United States"
  },
  {
    "id": 285812,
    "name": "AUE Metalltechnik Medizintechnik - Anstaltsbedarf    ( Rommelsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 287026,
    "name": "Aug Schwan GmbH & Co    ( Viersen  Germany )",
    "country": "Germany"
  },
  {
    "id": 441132,
    "name": "Augusta Medical Systems LLC    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 455313,
    "name": "Augustine Biomedical & Design    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 455278,
    "name": "Augustine Temperature Management    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 103388,
    "name": "Automated IV Systems Inc    ( Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 458837,
    "name": "Aumed Group Corp    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458838,
    "name": "Aumed Inc    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 107806,
    "name": "Aura Lens Products Inc    ( Sauk Rapids MN USA )",
    "country": "United States"
  },
  {
    "id": 173217,
    "name": "Aura Scientific Ltd    ( Milton Ernest  England )",
    "country": "UK"
  },
  {
    "id": 416459,
    "name": "Aurelia    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 229242,
    "name": "Auric Enterprises Inc    ( Beulah MI USA )",
    "country": "United States"
  },
  {
    "id": 458010,
    "name": "AURION Immuno Gold Reagents & Accessories    ( Wageningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455151,
    "name": "Aurolab    ( Madurai  India )",
    "country": "India"
  },
  {
    "id": 455325,
    "name": "Aurora Biomed Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 466245,
    "name": "Aurora Enterprises    ( Torrance 25 USA )",
    "country": "United States"
  },
  {
    "id": 364305,
    "name": "Aurora Imaging Technology Inc    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 465149,
    "name": "Aurora Storage Products, Inc    ( Aurora 60 USA )",
    "country": "United States"
  },
  {
    "id": 460747,
    "name": "Aurora Systems Group    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 177043,
    "name": "AUS Systems Pty Ltd    ( Allenby Gardens  Australia )",
    "country": "Australia"
  },
  {
    "id": 469481,
    "name": "Aushon BioSystems Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 399308,
    "name": "Austco Communication Systems Pty Ltd    ( Osborne Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 306422,
    "name": "Austco Communications (NZ) Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 399311,
    "name": "Austco Marketing and Service (Asia) Pty Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 399309,
    "name": "Austco Marketing and Service (Canada) Ltd    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 400960,
    "name": "Austco Marketing and Service (Middle East) Pty Ltd    ( Dubai  USA )",
    "country": "United States"
  },
  {
    "id": 399310,
    "name": "Austco Marketing and Service (UK) Pty Ltd    ( Bromsgrove  England )",
    "country": "UK"
  },
  {
    "id": 452389,
    "name": "Austco Marketing and Service (USA) Ltd    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 185709,
    "name": "Austin & Assoc/Telos Medical    ( Fallston MD USA )",
    "country": "United States"
  },
  {
    "id": 454885,
    "name": "Austin Air Systems Ltd    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 466943,
    "name": "Austin Hughes Solutions Inc    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 341644,
    "name": "Austmel Pty Ltd    ( Molendinar  Australia )",
    "country": "Australia"
  },
  {
    "id": 306175,
    "name": "Austofix    ( North Plympton  Australia )",
    "country": "Australia"
  },
  {
    "id": 451792,
    "name": "Austrailian Medical Couches    ( Kensington  Australia )",
    "country": "Australia"
  },
  {
    "id": 305753,
    "name": "Australian Surgical Design & Manufacture Ltd    ( St Leonards  USA )",
    "country": "United States"
  },
  {
    "id": 306200,
    "name": "Austramedex Pty Ltd    ( Ringwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 999739,
    "name": "Auto Identify Data Collect Technology Inc.(aidcti)    (   USA )",
    "country": "United States"
  },
  {
    "id": 171783,
    "name": "Auto Suture    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 453663,
    "name": "Autoadapt UK Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 458811,
    "name": "Autocam Medical    ( Kentwood MI USA )",
    "country": "United States"
  },
  {
    "id": 454773,
    "name": "Autochair Ltd    ( Alfreton  England )",
    "country": "UK"
  },
  {
    "id": 155932,
    "name": "Autoclave Testing Service Inc    ( Pearl River NY USA )",
    "country": "United States"
  },
  {
    "id": 458322,
    "name": "Autodesk Inc    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 455418,
    "name": "AutoGen Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 101080,
    "name": "Autogenics Systems A Stoelting Co    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 455811,
    "name": "AutoGenomics Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 458744,
    "name": "AutoMate Scientific Inc    ( Berkeley CA USA )",
    "country": "United States"
  },
  {
    "id": 287256,
    "name": "Steifensand Buerositzmoebelfabrik GmbH & Co KG    ( Wendelstein  USA )",
    "country": "United States"
  },
  {
    "id": 452813,
    "name": "Automated Medical Products Corp    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 458839,
    "name": "Automated Packaging Systems Inc    ( Streetsboro OH USA )",
    "country": "United States"
  },
  {
    "id": 467226,
    "name": "Automated Systems    ( Cheshire CT USA )",
    "country": "United States"
  },
  {
    "id": 458261,
    "name": "Automatic Data Processing Inc (ADP)    ( Roseland NJ USA )",
    "country": "United States"
  },
  {
    "id": 458342,
    "name": "Automation Products Group (APG) Inc    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 457338,
    "name": "Automation und Messtechnik GmbH    ( Ladenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 450902,
    "name": "AutoMed    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 451710,
    "name": "AutoMed    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 454738,
    "name": "AutoMed Canada    ( Peterborough ON Canada )",
    "country": "Canada"
  },
  {
    "id": 153029,
    "name": "Automess GmbH    ( Ladenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 466992,
    "name": "Autometer Test Equipment    ( Sycamore IL USA )",
    "country": "United States"
  },
  {
    "id": 105227,
    "name": "Autovage    ( Library PA USA )",
    "country": "United States"
  },
  {
    "id": 457963,
    "name": "Ava Pezeshk    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 441544,
    "name": "Avail Medical Products    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 450604,
    "name": "Avail Medical Products Europe Sarl    ( Engling  USA )",
    "country": "United States"
  },
  {
    "id": 453847,
    "name": "Availity LLC    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 460990,
    "name": "Avalanche Technology Corp    ( Tallahassee FL USA )",
    "country": "United States"
  },
  {
    "id": 452818,
    "name": "Avalon Aire Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 457669,
    "name": "Avalon Papers LLC    ( Oshkosh WI USA )",
    "country": "United States"
  },
  {
    "id": 460755,
    "name": "Avancen MOD Corp    ( Mt Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 450913,
    "name": "Avant Immotherapeutics Inc    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 457927,
    "name": "Avantas    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 426631,
    "name": "Avantec Vascular Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 100000,
    "name": "Avanti Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 999838,
    "name": "Avanti Products    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 458330,
    "name": "Avantik Biogroup    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 999779,
    "name": "Avantik Biogroup    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 458327,
    "name": "Avantis Medical Systems    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 999502,
    "name": "Avantis Medical Systems    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 279944,
    "name": "Avantmed GmbH    ( Trier  Germany )",
    "country": "Germany"
  },
  {
    "id": 455601,
    "name": "Avantor Performance Materials bv    ( Deventer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455602,
    "name": "Avantor Performance Materials Deutschland    ( Griesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 455603,
    "name": "Avantor Performance Materials France    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 455600,
    "name": "Avantor Performance Materials Inc    ( Phillipsburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 455605,
    "name": "Avantor Performance Materials Taiwan    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455604,
    "name": "Avantor Performance Materials UK    ( London  England )",
    "country": "UK"
  },
  {
    "id": 458291,
    "name": "AvaSure    ( Belmont MI USA )",
    "country": "United States"
  },
  {
    "id": 458347,
    "name": "Avaya Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 452425,
    "name": "AVAZZIA Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 344828,
    "name": "Avcor Health Care Products Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 452459,
    "name": "Avec Scientific Inc    ( Forestville CA USA )",
    "country": "United States"
  },
  {
    "id": 999414,
    "name": "Avedro, Inc.    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 471582,
    "name": "AveeoMedix LLC    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 451825,
    "name": "Avega    ( El Segundo CA USA )",
    "country": "United States"
  },
  {
    "id": 170200,
    "name": "Aventric Technologies LLC    ( Madison Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 460871,
    "name": "Aventura Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 458354,
    "name": "Avenview Corp    ( Kenmore NY USA )",
    "country": "United States"
  },
  {
    "id": 451533,
    "name": "Avery Biomedical Devices Inc    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 454057,
    "name": "Avery Custom Devices Inc    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 399769,
    "name": "Avery Dennison (Germany)    ( Elbach  USA )",
    "country": "United States"
  },
  {
    "id": 399774,
    "name": "Avery Dennison (Hong Kong) Ltd    ( New Territories  USA )",
    "country": "United States"
  },
  {
    "id": 399775,
    "name": "Avery Dennison (India) Pvt Ltd    ( Mumbai  USA )",
    "country": "United States"
  },
  {
    "id": 399776,
    "name": "Avery Dennison (Japan)    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 399778,
    "name": "Avery Dennison (Malaysia) Sdn Bhd    ( Shah Alam  USA )",
    "country": "United States"
  },
  {
    "id": 399770,
    "name": "Avery Dennison (Scandinavia)    ( Hollviken  USA )",
    "country": "United States"
  },
  {
    "id": 399782,
    "name": "Avery Dennison (South Africa)    ( Roodepoort  USA )",
    "country": "United States"
  },
  {
    "id": 399781,
    "name": "Avery Dennison (Thailand) Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 399768,
    "name": "Avery Dennison (UK)    ( Waterlooville  USA )",
    "country": "United States"
  },
  {
    "id": 292877,
    "name": "Avery Dennison Belgie NV    ( Turnhout  USA )",
    "country": "United States"
  },
  {
    "id": 399773,
    "name": "Avery Dennison China Co Ltd    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 399764,
    "name": "Avery Dennison Corp    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 399767,
    "name": "Avery Dennison Espana SA    ( Sant Just Desvern (Barcelona)  USA )",
    "country": "United States"
  },
  {
    "id": 399766,
    "name": "Avery Dennison Italia SpA    ( Gerenzano VA  USA )",
    "country": "United States"
  },
  {
    "id": 399777,
    "name": "Avery Dennison Korea    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 399771,
    "name": "Avery Dennison Materials Group Australia    ( West Heidelberg  USA )",
    "country": "United States"
  },
  {
    "id": 399765,
    "name": "Avery Dennison Medical    ( Painesville OH USA )",
    "country": "United States"
  },
  {
    "id": 399780,
    "name": "Avery Dennison Singapore (Pte) Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 399751,
    "name": "Avery India Ltd    ( Ballabgarth  India )",
    "country": "India"
  },
  {
    "id": 399756,
    "name": "Avery Malaysia Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 399757,
    "name": "Avery Scales (Pvt) Ltd    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 441101,
    "name": "Avery Weigh-Tronix Inc    ( Fairmont MN USA )",
    "country": "United States"
  },
  {
    "id": 453741,
    "name": "Avery Weigh-Tronix SAS    ( Domont  France )",
    "country": "France"
  },
  {
    "id": 441102,
    "name": "Avery Weigh-Tronix UK    ( Smethwick  England )",
    "country": "UK"
  },
  {
    "id": 336558,
    "name": "AVESCO Marketing Corp    ( Manila  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 458522,
    "name": "AVESTIN Europe GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458521,
    "name": "AVESTIN Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 185712,
    "name": "Avex Electronics    ( Bensalem PA USA )",
    "country": "United States"
  },
  {
    "id": 460756,
    "name": "Avhealio    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 398064,
    "name": "aviCOM GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 363777,
    "name": "Avid Medical Inc    ( Toano VA USA )",
    "country": "United States"
  },
  {
    "id": 458403,
    "name": "Avigilon Corp    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 458123,
    "name": "Avinger    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458344,
    "name": "Avinger Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 459400,
    "name": "Avisoft Bioacoustics    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 284003,
    "name": "Avisomed Produktgruppe der Kastl KG    ( Lindenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 292064,
    "name": "Avistar Communications Corp    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 427800,
    "name": "AViTA Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 170847,
    "name": "Avitar Inc    ( Canton MA USA )",
    "country": "United States"
  },
  {
    "id": 104062,
    "name": "Aviv Biomedical Inc    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 470537,
    "name": "Avizia Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 322381,
    "name": "Avmad    ( Tel Aviv  USA )",
    "country": "United States"
  },
  {
    "id": 999946,
    "name": "Steindorff Berlin    (   USA )",
    "country": "United States"
  },
  {
    "id": 338282,
    "name": "Avnet Medical Equipment Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 453378,
    "name": "Avonwood Developments Ltd    ( Wimborne  England )",
    "country": "UK"
  },
  {
    "id": 185713,
    "name": "Avotec Inc    ( Stuart FL USA )",
    "country": "United States"
  },
  {
    "id": 172257,
    "name": "AVR Communications Ltd    ( Atlit  Israel )",
    "country": "Israel"
  },
  {
    "id": 439881,
    "name": "Avreo Inc (AVREO)    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 330311,
    "name": "AVT Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 459210,
    "name": "AVTEQ Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 456882,
    "name": "Aware Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 409052,
    "name": "Awareness Technology Co LLC    ( Sharjah  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 107721,
    "name": "Awareness Technology Inc    ( Palm City FL USA )",
    "country": "United States"
  },
  {
    "id": 466875,
    "name": "Awareness Technology Inc (Austria)    ( Perchtoldsdorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 454751,
    "name": "Awarepoint Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451448,
    "name": "Awarix Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 185373,
    "name": "Aweste Przedsiebiorstwo Produkcyjno-Handlowe    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 466534,
    "name": "Axcentria Pharmaceuticals LLC    ( Telford PA USA )",
    "country": "United States"
  },
  {
    "id": 454380,
    "name": "Axeda Corp    ( Foxboro MA USA )",
    "country": "United States"
  },
  {
    "id": 452899,
    "name": "AXEL srl    ( Viadana (MN)  Italy )",
    "country": "Italy"
  },
  {
    "id": 376071,
    "name": "Axelgaard Mfg Co Ltd    ( Fallbrook CA USA )",
    "country": "United States"
  },
  {
    "id": 457051,
    "name": "Axess Ultrasound    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 460706,
    "name": "Axia Surgical LLC    ( Bristol CT USA )",
    "country": "United States"
  },
  {
    "id": 456403,
    "name": "Axiobionics    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 473836,
    "name": "Axiohm TXCOM    ( Le Plessis Robinson  France )",
    "country": "France"
  },
  {
    "id": 473837,
    "name": "Axiohm USA    ( Myrtle Beach SC USA )",
    "country": "United States"
  },
  {
    "id": 459211,
    "name": "Axiom EPM    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 334746,
    "name": "AXIOM Internet Commerce    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 452155,
    "name": "Axiom Medical Accounts    ( Flagstaff AZ USA )",
    "country": "United States"
  },
  {
    "id": 103025,
    "name": "Axiom Medical Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 271719,
    "name": "Axis Clinical Software Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 458302,
    "name": "Axis Communications AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 473830,
    "name": "AXIS Medical Group    ( Belleville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 185374,
    "name": "Axis Sp z o o Przedsiebiorstwo Produkcujno-Uslugow    ( Gdansk  Poland )",
    "country": "Poland"
  },
  {
    "id": 460710,
    "name": "Axis Three    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 391117,
    "name": "Axis-Shield ASA    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 391116,
    "name": "Axis-Shield plc    ( Dundee  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 455304,
    "name": "Axis-Shield USA    ( Norton MA USA )",
    "country": "United States"
  },
  {
    "id": 458728,
    "name": "AxoGen Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 452111,
    "name": "Axolotl Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 105353,
    "name": "Axon Systems Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 335504,
    "name": "Axon Tecnologia Medica Srl    ( Munro  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 347664,
    "name": "AXS Medical Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 471717,
    "name": "AXSUN Technologies Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 398250,
    "name": "AxSys Technology Ltd    ( Paisley  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 458974,
    "name": "Symantec Corp    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 999504,
    "name": "AYC LLC    ( Garland TX USA )",
    "country": "USA"
  },
  {
    "id": 454727,
    "name": "aycan Digitalsysteme GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458298,
    "name": "aycan Medical Systems LLC    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 457601,
    "name": "Ayrton Corp    ( Prior Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 465167,
    "name": "Azalea Surgical Products    ( - - USA )",
    "country": "United States"
  },
  {
    "id": 108345,
    "name": "Azek Worldwide Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 471620,
    "name": "AZER Scientific Inc    ( Morgantown PA USA )",
    "country": "United States"
  },
  {
    "id": 461013,
    "name": "Azima DLI    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 185715,
    "name": "Azmed Monitoring Solutions    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 455394,
    "name": "AZReam Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 458394,
    "name": "Aztec Medical Products    ( Williamsburg VA USA )",
    "country": "United States"
  },
  {
    "id": 354559,
    "name": "Azwell Inc    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 469509,
    "name": "Azzur Labs    ( Schnecksville PA USA )",
    "country": "United States"
  },
  {
    "id": 108363,
    "name": "B & B Co Inc    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 252258,
    "name": "B & B Medical Technologies Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 230625,
    "name": "B & B Microscopes Ltd    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 342146,
    "name": "B & H Optical    ( Belleville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 162272,
    "name": "B & H Surgical Instrument Makers    ( London  England )",
    "country": "UK"
  },
  {
    "id": 458840,
    "name": "B & L Cremation Systems Inc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 108621,
    "name": "B & L Engineering    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 284921,
    "name": "B & P Beatmungsprodukte GmbH    ( Neunkirchen-Seelscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 459315,
    "name": "B & W Tek Inc    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 453578,
    "name": "B Braun Aesculap Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452990,
    "name": "B Braun Avitum AG    ( Melsungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306018,
    "name": "B Braun Biotech International Div of B Braun Medic    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 457967,
    "name": "B Braun Dominican Republic Inc    ( Santo Domingo  Dominican Republic )",
    "country": "Dominican Republic"
  },
  {
    "id": 178337,
    "name": "B Braun Medical (France)    ( Boulougne Cedex  France )",
    "country": "France"
  },
  {
    "id": 158071,
    "name": "B Braun Medical Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 171733,
    "name": "B Braun Medical Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 393207,
    "name": "B Braun Medical Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 454126,
    "name": "B Braun Medical Inc (Dominican Republic)    ( Santo Domingo  Dominican Republic )",
    "country": "Dominican Republic"
  },
  {
    "id": 183765,
    "name": "B Braun Medical Industries Sdn Bhd    ( Penang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 150948,
    "name": "B Braun Medical Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 456829,
    "name": "B Braun Medical SA    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 456845,
    "name": "B Braun Medical SA    ( Rubi (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 178339,
    "name": "B Braun Medical SAS    ( Chasseneuil Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 435620,
    "name": "B Braun Medizinelektronik GmbH & Co KG    ( Puchheim  USA )",
    "country": "United States"
  },
  {
    "id": 138485,
    "name": "B Braun Melsungen AG    ( Melsungen  USA )",
    "country": "United States"
  },
  {
    "id": 178094,
    "name": "B Braun Melsungen AG    ( Melsungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 178095,
    "name": "B Braun Melsungen AG    ( Melsungen  USA )",
    "country": "United States"
  },
  {
    "id": 178137,
    "name": "B Braun Melsungen AG    ( Melsungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456842,
    "name": "B Braun Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 101136,
    "name": "Bailey Mfg Co    ( Lodi OH USA )",
    "country": "United States"
  },
  {
    "id": 178136,
    "name": "B Braun Surgical SA    ( Rubi (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 286259,
    "name": "B Braun-Dexon GmbH    ( Spangenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 109166,
    "name": "B F Wehmer Co Inc    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 357276,
    "name": "B Rosenberg Ltd    ( Mildenhall  USA )",
    "country": "United States"
  },
  {
    "id": 170319,
    "name": "B-K Medical A/S (Denmark)    ( Herlev  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 177916,
    "name": "B-K Medical A/S (Norway)    ( Nesbru  Norway )",
    "country": "Norway"
  },
  {
    "id": 408518,
    "name": "B-K Medical Asia    ( Nonthaburi  USA )",
    "country": "United States"
  },
  {
    "id": 306303,
    "name": "B-K Medical GmbH Sub Analogic Corp    ( Quickborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 170214,
    "name": "B-K Medical Systems Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 298538,
    "name": "B-K Medical UK    ( White Waltham  England )",
    "country": "UK"
  },
  {
    "id": 458533,
    "name": "B-Line Medical LLC    ( Washington DC USA )",
    "country": "United States"
  },
  {
    "id": 293749,
    "name": "B-Med AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 104274,
    "name": "B/R Instrument Corp    ( Easton MD USA )",
    "country": "United States"
  },
  {
    "id": 457614,
    "name": "B&D Electromedical    ( Stratford-upon-Avon  England )",
    "country": "UK"
  },
  {
    "id": 462908,
    "name": "B&H Foto & Electronics Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457888,
    "name": "B4 Brands    ( Lisbon IA USA )",
    "country": "United States"
  },
  {
    "id": 455096,
    "name": "Baby Jogger Australia    ( Nunawading  Australia )",
    "country": "Australia"
  },
  {
    "id": 440692,
    "name": "Baby Jogger Co    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 453434,
    "name": "Baby Jogger Co    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 281391,
    "name": "Baby-Comp-Lady-Comp Valley Electronics GmbH    ( Eschenlohe  Germany )",
    "country": "Germany"
  },
  {
    "id": 455785,
    "name": "BabyBelle Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 395013,
    "name": "Bacchus Vascular Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 288521,
    "name": "Bacharach de Mexico S de RL de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 107904,
    "name": "Bacharach Inc    ( New Kensington PA USA )",
    "country": "United States"
  },
  {
    "id": 253491,
    "name": "Bacharach Instruments (Europe)    ( Lemington Spa  USA )",
    "country": "United States"
  },
  {
    "id": 229669,
    "name": "Bacharach Instruments International (Denmark)    ( Christiansfeld  USA )",
    "country": "United States"
  },
  {
    "id": 286452,
    "name": "Bacharach International    ( Rio de Janeiro-RJ  USA )",
    "country": "United States"
  },
  {
    "id": 401072,
    "name": "Bacharach of Canada Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 398210,
    "name": "Bachem AG    ( Bubendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 107230,
    "name": "BackCo Products Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 407160,
    "name": "Bacou-Dalloz (Australia)    ( Braeside  USA )",
    "country": "United States"
  },
  {
    "id": 407154,
    "name": "Bacou-Dalloz (France)    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 407161,
    "name": "Bacou-Dalloz GmbH    ( Luebeck  USA )",
    "country": "United States"
  },
  {
    "id": 407153,
    "name": "Bacou-Dalloz USA    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 456457,
    "name": "Bacterin International Holdings Inc    ( Belgrade MT USA )",
    "country": "United States"
  },
  {
    "id": 104627,
    "name": "Bacti-Lab Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 403473,
    "name": "Badger Electronics    (   USA )",
    "country": "United States"
  },
  {
    "id": 431627,
    "name": "BAE Systems North America    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 397971,
    "name": "Baeramed Instrumente GmbH    ( Schwenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453701,
    "name": "BAG Health Care GmbH    ( Lich  Germany )",
    "country": "Germany"
  },
  {
    "id": 283966,
    "name": "BAG-BiologischesAnalysensystem GmbH    ( Lich  USA )",
    "country": "United States"
  },
  {
    "id": 457637,
    "name": "BagCo Pty Ltd    ( Templestowe Heights  Australia )",
    "country": "Australia"
  },
  {
    "id": 153032,
    "name": "Baier Endoskopie und Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 384282,
    "name": "Bailey Instruments Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 282089,
    "name": "Bailey-Fischer & Porter GmbH    ( Goettingen  USA )",
    "country": "United States"
  },
  {
    "id": 455787,
    "name": "BAILIDA Medical    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 291848,
    "name": "Bailing Health Materials Factory    ( Huangyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456146,
    "name": "Bain Medical Equipment (Guangzhou) Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 461139,
    "name": "Baird Group Inc    ( Fort Atkinson WI USA )",
    "country": "United States"
  },
  {
    "id": 287223,
    "name": "Baisch Byrum Krankenhaus und Objekteinrichtungen    ( Weinstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 456147,
    "name": "Baisheng Medical Equipment Co Ltd    ( Jiangmen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 306250,
    "name": "Baitella AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 102485,
    "name": "Bak Electronics Inc    ( Mount Airy MD USA )",
    "country": "United States"
  },
  {
    "id": 452959,
    "name": "Bakare Beds Ltd    ( Plympton  England )",
    "country": "UK"
  },
  {
    "id": 104065,
    "name": "Baker Co    ( Sanford ME USA )",
    "country": "United States"
  },
  {
    "id": 237792,
    "name": "Baker Norton Asia    ( North Point  USA )",
    "country": "United States"
  },
  {
    "id": 175423,
    "name": "Baker Products Co LLC    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 459401,
    "name": "Bakers Pride    ( Allen TX USA )",
    "country": "United States"
  },
  {
    "id": 999839,
    "name": "Bal-tec    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 458404,
    "name": "Balance Engineering    ( Henrietta NY USA )",
    "country": "United States"
  },
  {
    "id": 458523,
    "name": "Balanceback    ( Kettering OH USA )",
    "country": "United States"
  },
  {
    "id": 458310,
    "name": "Balanced Body Inc    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 999401,
    "name": "Balanced Body Inc.    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 452386,
    "name": "Balas Dental Supplies Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 185720,
    "name": "Balboa Capital Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 452458,
    "name": "Balcan Engineering Ltd    ( Horncastle  England )",
    "country": "UK"
  },
  {
    "id": 333531,
    "name": "Balder USA    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 466956,
    "name": "Baldor Electric Co    ( Fort Smith AR USA )",
    "country": "United States"
  },
  {
    "id": 999882,
    "name": "Baldor Electric Company    ( Fort Smith AR USA )",
    "country": "United States"
  },
  {
    "id": 338630,
    "name": "Baldur Systems Corp    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 285365,
    "name": "Balena AG    ( Buetschwil  USA )",
    "country": "United States"
  },
  {
    "id": 232568,
    "name": "Bally Refrigerated Boxes Inc    ( Morehead City NC USA )",
    "country": "United States"
  },
  {
    "id": 171781,
    "name": "Balt Extrusion    ( Montmorency  France )",
    "country": "France"
  },
  {
    "id": 106738,
    "name": "Baltimore Therapeutic Equipment Co    ( Hanover MD USA )",
    "country": "United States"
  },
  {
    "id": 358827,
    "name": "Balton Ltd    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 103389,
    "name": "Balzers Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 407228,
    "name": "Bambo A/S    ( Aabenraa  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 460597,
    "name": "Bamboo Automated Window Fashions Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 451050,
    "name": "Bandages Plus    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 173102,
    "name": "Bandelin Electronic GmbH & Co KG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 392512,
    "name": "Bang & Olufsen Medicom A/S    ( Struer  USA )",
    "country": "United States"
  },
  {
    "id": 456336,
    "name": "Bangkok Botanica Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 460886,
    "name": "BankMark Inc    ( Hampshire IL USA )",
    "country": "United States"
  },
  {
    "id": 999785,
    "name": "Banner American Products, Inc.    ( Temecula CA USA )",
    "country": "USA"
  },
  {
    "id": 457346,
    "name": "Banner Therapy Products Inc    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 103712,
    "name": "Banyan International Corp    ( Abilene TX USA )",
    "country": "United States"
  },
  {
    "id": 458300,
    "name": "Banyan Medical Systems Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 456148,
    "name": "Baoying County Fukang Medical Appliance Co Ltd    ( Yangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 266958,
    "name": "Bapco    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 103398,
    "name": "Bar-Ray Products Inc    ( Littlestown PA USA )",
    "country": "United States"
  },
  {
    "id": 452632,
    "name": "Bar-Tip Medical    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 177905,
    "name": "Barber Optics Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 320666,
    "name": "Barclay Products Ltd    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 461275,
    "name": "Barclay Water Management Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 455180,
    "name": "Barco Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450772,
    "name": "Barco Ltd    ( Bracknell  England )",
    "country": "UK"
  },
  {
    "id": 450771,
    "name": "Barco NV    ( Kortjijk  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 280849,
    "name": "Barcodat GmbH    ( Dornstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 392478,
    "name": "BarcoView LLC    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 163054,
    "name": "Bard Access Systems    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 188116,
    "name": "Bard Australia Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 300882,
    "name": "Bard Benelux NV    ( Olen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 455458,
    "name": "Bard Biopsy Systems    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 104115,
    "name": "Bard Canada Inc    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 343164,
    "name": "Bard China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 185387,
    "name": "Bard de Espana SA    ( L'Hospitalet de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 439276,
    "name": "Bard Denmark AB    ( Helsingor  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 435644,
    "name": "Bard Electrophysiology    ( Lowell MA USA )",
    "country": "United States"
  },
  {
    "id": 439283,
    "name": "Bard Finland Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 345922,
    "name": "Bard Hong Kong    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 176296,
    "name": "Bard Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 439275,
    "name": "Bard Medica SA (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 439279,
    "name": "Bard Medica SA (Switzerland)    ( Oberrieden/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 274413,
    "name": "Bard Medical    ( Covington GA USA )",
    "country": "United States"
  },
  {
    "id": 101679,
    "name": "Bard MedSystems Div C R Bard Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 343215,
    "name": "Bard Nederland bv    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 439278,
    "name": "Bard Norden AB    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 439277,
    "name": "Bard Norway A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 427969,
    "name": "Bard Peripheral Vascular    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 345917,
    "name": "Bard South Asia (India)    ( Bombay  India )",
    "country": "India"
  },
  {
    "id": 189781,
    "name": "Bard SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 101111,
    "name": "Bard Urological    ( Covington GA USA )",
    "country": "United States"
  },
  {
    "id": 285114,
    "name": "Bareiss Pruefgeraetebau GmbH    ( Oberdischingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306115,
    "name": "Barfell Industries    ( Mordialloc  Australia )",
    "country": "Australia"
  },
  {
    "id": 338286,
    "name": "Bargal Analytical Instruments and Software Ltd    ( Airport City  Israel )",
    "country": "Israel"
  },
  {
    "id": 451079,
    "name": "Barloworld Scientific Ltd    ( Stone  USA )",
    "country": "United States"
  },
  {
    "id": 106340,
    "name": "Barnard & Assoc    ( Seal Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 152721,
    "name": "Barnett International Corp    ( Matthews NC USA )",
    "country": "United States"
  },
  {
    "id": 150339,
    "name": "Barnhardt Mfg Co    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 106642,
    "name": "Barnstead International Corp    ( Dubuque IA USA )",
    "country": "United States"
  },
  {
    "id": 353321,
    "name": "Barotech Inc    ( Valparaiso IN USA )",
    "country": "United States"
  },
  {
    "id": 458407,
    "name": "Barracuda Networks Inc    ( Campbell CA USA )",
    "country": "United States"
  },
  {
    "id": 150123,
    "name": "Barramundi Corp    ( Homosassa Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 999565,
    "name": "Barrett Engineering    ( Burley WA USA )",
    "country": "United States"
  },
  {
    "id": 456718,
    "name": "Barrier Healthcare Ltd    ( Kexby  England )",
    "country": "UK"
  },
  {
    "id": 398409,
    "name": "BarrierMed Glove Co    ( Lake Mary FL USA )",
    "country": "United States"
  },
  {
    "id": 454416,
    "name": "Barrington Healthcare Intl Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 454186,
    "name": "Barrington Medical Imaging LLC    ( Cary IL USA )",
    "country": "United States"
  },
  {
    "id": 453400,
    "name": "BARRX Medical    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 442186,
    "name": "Bartec Technologies Europe    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 441877,
    "name": "Bartec Technologies Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 283548,
    "name": "Bartels & Rieger GmbH & Co Medizin-Technik    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 150442,
    "name": "Bartington Instruments Ltd    ( Witney  England )",
    "country": "UK"
  },
  {
    "id": 454279,
    "name": "Barton Medical Canada    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451752,
    "name": "Barton Medical Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 281591,
    "name": "basan GmbH    ( Kriftel  Germany )",
    "country": "Germany"
  },
  {
    "id": 151337,
    "name": "Baseline-MOCON Inc    ( Lyons CO USA )",
    "country": "United States"
  },
  {
    "id": 107232,
    "name": "Bashaw Medical Inc    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 454395,
    "name": "BASi    ( West Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 152265,
    "name": "Basic American Metal Products    ( Fond du Lac WI USA )",
    "country": "United States"
  },
  {
    "id": 298303,
    "name": "Basic Medical Industries Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 454194,
    "name": "Basic MRI Inc    ( Waterloo IL USA )",
    "country": "United States"
  },
  {
    "id": 282323,
    "name": "Basko Healthcare (Deutschland)    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 309651,
    "name": "Basko Healthcare (The Netherlands)    ( Zaandam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 164262,
    "name": "Basler Eisenmoebelfabrik AG    ( Sissach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 292881,
    "name": "Bassaire Ltd    ( Southampton  England )",
    "country": "UK"
  },
  {
    "id": 452870,
    "name": "Bassett Furniture Industries Inc    ( Bassett VA USA )",
    "country": "United States"
  },
  {
    "id": 458311,
    "name": "BATCA Fitness Systems USA    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 175790,
    "name": "BathEase Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 362372,
    "name": "Batrik Medical Mfg Inc    ( Dorval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 127036,
    "name": "Battelle    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 452457,
    "name": "Batteries Plus LLC    ( Hartland WI USA )",
    "country": "United States"
  },
  {
    "id": 170230,
    "name": "Battery Clinic Inc    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 176089,
    "name": "Battery Outlet Inc    ( Chesapeake VA USA )",
    "country": "United States"
  },
  {
    "id": 108733,
    "name": "Battery Products Inc    ( Hartland WI USA )",
    "country": "United States"
  },
  {
    "id": 372459,
    "name": "Battery Pros Inc    ( Horseshoe Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 105881,
    "name": "Battery Specialties    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 452780,
    "name": "BatteryZONE Inc    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 101108,
    "name": "Battle Creek Equipment Co    ( Battle Creek MI USA )",
    "country": "United States"
  },
  {
    "id": 458220,
    "name": "Baty International    ( Burgess Hill  England )",
    "country": "UK"
  },
  {
    "id": 281203,
    "name": "Bauer und Haeselbarth-Chirurg GmbH    ( Ellerau bei Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 392670,
    "name": "Bauerfeind AG Schweiz    ( Oberrohrdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 392666,
    "name": "Bauerfeind Benelux bv    ( Haarlem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392671,
    "name": "Bauerfeind France Sarl    ( Roissy CDG  France )",
    "country": "France"
  },
  {
    "id": 392669,
    "name": "Bauerfeind Iberica SA    ( Valencia  Spain )",
    "country": "Spain"
  },
  {
    "id": 392667,
    "name": "Bauerfeind Italia    ( Vimercate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 283395,
    "name": "Bauerfeind Orthopaedie GmbH & Co KG    ( Kempen  Germany )",
    "country": "Germany"
  },
  {
    "id": 392668,
    "name": "Bauerfeind UK    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 176091,
    "name": "Bauerfeind USA Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 286615,
    "name": "Bauerhin Elektro Waerme GmbH    ( Trieb/Vogtland  Germany )",
    "country": "Germany"
  },
  {
    "id": 456565,
    "name": "BAUM Retec AG    ( Wiesenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 293693,
    "name": "Baumann-Medical AG    ( Wetzikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 287594,
    "name": "Baumbach Edelstahlverarbeitungsgesellschaft mbH    ( Wurzen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999662,
    "name": "Baumer Ltd.    ( Southington CT USA )",
    "country": "USA"
  },
  {
    "id": 179534,
    "name": "Baumer S/A    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 192703,
    "name": "Baumer S/A    ( Grand Cayman Island  USA )",
    "country": "United States"
  },
  {
    "id": 460764,
    "name": "Baumfolder Corp    ( Sidney OH USA )",
    "country": "United States"
  },
  {
    "id": 441125,
    "name": "Bausch & Lomb    (   USA )",
    "country": "United States"
  },
  {
    "id": 103152,
    "name": "Bausch & Lomb Canada Inc    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453490,
    "name": "Bausch & Lomb GmbH    ( Feldkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 340064,
    "name": "Bausch & Lomb Surgical    ( San Dimas CA USA )",
    "country": "United States"
  },
  {
    "id": 340066,
    "name": "Bausch & Lomb Surgical (Asia Pacific) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 340065,
    "name": "Bausch & Lomb Surgical GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 117911,
    "name": "Bausch & Lomb Vision Care    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 454817,
    "name": "Bausch and Lomb Australia Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 454818,
    "name": "Bausch and Lomb China Inc    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454819,
    "name": "Bausch and Lomb Eyecare (India) Pvt Ltd    ( Gurgaon  India )",
    "country": "India"
  },
  {
    "id": 454821,
    "name": "Bausch and Lomb Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 454822,
    "name": "Bausch and Lomb South Africa Pty Ltd    ( Rivonia  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 320778,
    "name": "Bausch Articulating Papers Inc    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 354509,
    "name": "Bavaria Optics    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 153064,
    "name": "Bavimed Laborgeraetebau GmbH    ( Birkenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 344309,
    "name": "Baxa Canada    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101134,
    "name": "Baxa Corp    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 344308,
    "name": "Baxa Ltd (Denmark)    ( Odense NV  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 256586,
    "name": "Baxa Ltd (UK)    ( Bracknell  England )",
    "country": "UK"
  },
  {
    "id": 460842,
    "name": "Baxano Surgical Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 332060,
    "name": "Baxter bv    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 407213,
    "name": "Baxter Corp Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 153068,
    "name": "Baxter Deutschland GmbH    ( Unterschleissheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457341,
    "name": "Baxter Englewood    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 458765,
    "name": "Bio-Medical Devices International    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 305436,
    "name": "Baxter Healthcare (Asia) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 106389,
    "name": "Baxter Healthcare Corp    ( McGaw Park IL USA )",
    "country": "United States"
  },
  {
    "id": 393243,
    "name": "Baxter Healthcare Corp    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 393245,
    "name": "Baxter Healthcare Corp    ( Round Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 393246,
    "name": "Baxter Healthcare Corp    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 393247,
    "name": "Baxter Healthcare Corp    ( New Providence NJ USA )",
    "country": "United States"
  },
  {
    "id": 393248,
    "name": "Baxter Healthcare Corp    ( Round Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 426357,
    "name": "Baxter Healthcare Corp    ( Round Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 453713,
    "name": "Baxter Healthcare Corp    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 178041,
    "name": "Baxter Healthcare Ltd    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 305486,
    "name": "Baxter International Inc    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 458080,
    "name": "Baxter Medina    ( Medina NY USA )",
    "country": "United States"
  },
  {
    "id": 305435,
    "name": "Baxter SA/Baxter World Trade    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457148,
    "name": "Baxter US    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 105528,
    "name": "Bay Corp    ( Westlake OH USA )",
    "country": "United States"
  },
  {
    "id": 104593,
    "name": "Bay Medical    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 454154,
    "name": "Bay Medical Co Inc    ( Daly City CA USA )",
    "country": "United States"
  },
  {
    "id": 454024,
    "name": "Bay Shore Medical LLC    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 108740,
    "name": "Bay State Anesthesia Inc    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 458082,
    "name": "Bayer (Schweiz) AG Healthcare    ( Zurich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 223180,
    "name": "Bayer Corp    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 223456,
    "name": "Bayer Corp    ( West Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 462958,
    "name": "Bayer HealthCare    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 454041,
    "name": "Bayer HealthCare Diabetes Care    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 452749,
    "name": "Bayer Healthcare LLC    ( Tarrytown NY USA )",
    "country": "United States"
  },
  {
    "id": 470560,
    "name": "Bayer Healthcare LLC    ( Whippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 456905,
    "name": "Bayer HealthCare Pharmaceuticals (Germany)    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 452280,
    "name": "Bayer HealthCare Pharmaceuticals Div-Montville    ( Montville NJ USA )",
    "country": "United States"
  },
  {
    "id": 456904,
    "name": "Bayer Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 452282,
    "name": "Bayer Schering Pharma Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 454927,
    "name": "Baylis Medical (Hong Kong)    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454926,
    "name": "Baylis Medical (United Kingdom)    ( London  England )",
    "country": "UK"
  },
  {
    "id": 341345,
    "name": "Baylis Medical Co Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455396,
    "name": "Baymed Healthcare Ltd    ( East Kilbride  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 143759,
    "name": "Bayside X-Ray Co    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 342973,
    "name": "Baystate Health Systems    ( Springfield MA USA )",
    "country": "United States"
  },
  {
    "id": 397945,
    "name": "Baytex GmbH    ( Menden  USA )",
    "country": "United States"
  },
  {
    "id": 451068,
    "name": "BBC Lighting and Supply    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 450920,
    "name": "BBI Diagnostics    ( West Bridgewater MA USA )",
    "country": "United States"
  },
  {
    "id": 456958,
    "name": "BBI Healthcare Ltd    ( Pencoed  Wales )",
    "country": "Wales"
  },
  {
    "id": 223154,
    "name": "BC Group International Inc    ( St Charles MO USA )",
    "country": "United States"
  },
  {
    "id": 460904,
    "name": "BC Solutions    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 371508,
    "name": "BC Technical Inc    ( West Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 330071,
    "name": "BCD Assoc Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 152353,
    "name": "BCI Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 174535,
    "name": "BCL X-Ray Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 378348,
    "name": "BD    ( Franklin Lakes NJ USA )",
    "country": "United States"
  },
  {
    "id": 457076,
    "name": "BD (Australia)    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 378368,
    "name": "BD (France)    ( Le Pont-de-Claix Cedex  France )",
    "country": "France"
  },
  {
    "id": 457066,
    "name": "BD (Indonesia)    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 457077,
    "name": "BD (Philippines)    ( Taguig City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 378364,
    "name": "BD (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306828,
    "name": "BD Argentina    ( Martinez  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 378366,
    "name": "BD Asia    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 378367,
    "name": "BD Asia Div BD    ( Gurgaon  India )",
    "country": "India"
  },
  {
    "id": 457122,
    "name": "BD Benelux NV    ( Erembodegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 378353,
    "name": "BD Biosciences (CA)    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 451581,
    "name": "BD Biosciences Bioimaging Systems    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 378370,
    "name": "BD Biosciences Div BD    ( Erembodegem/Aalst  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 255182,
    "name": "BD Biosciences PharMingen    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 378352,
    "name": "BD Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 378351,
    "name": "BD Consumer Healthcare    ( Franklin Lakes NJ USA )",
    "country": "United States"
  },
  {
    "id": 392143,
    "name": "BD Diagnostic Systems    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 453421,
    "name": "BD Diagnostics - TriPath    ( Burlington NC USA )",
    "country": "United States"
  },
  {
    "id": 378369,
    "name": "BD GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 372908,
    "name": "BD Medical Systems    ( Sandy UT USA )",
    "country": "United States"
  },
  {
    "id": 378371,
    "name": "BD Medical-Pharmaceutical Systems France    ( Le-Pont-de Claix Cedex  France )",
    "country": "France"
  },
  {
    "id": 378354,
    "name": "BD Ophthalmic Systems    ( Franklin Lakes NJ USA )",
    "country": "United States"
  },
  {
    "id": 378355,
    "name": "BD Sharps Disposal Systems    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 378372,
    "name": "BD UK    ( Cowley  England )",
    "country": "UK"
  },
  {
    "id": 378349,
    "name": "BD Vacutainer Systems    ( Franklin Lakes NJ USA )",
    "country": "United States"
  },
  {
    "id": 357280,
    "name": "BDH Laboratory Supplies    ( Poole  USA )",
    "country": "United States"
  },
  {
    "id": 286251,
    "name": "BDK Luft- und Reinraumtechnik GmbH    ( Sonnenbuehl-Genkingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107695,
    "name": "BDM Medical Inc    ( Saint Anthony ID USA )",
    "country": "United States"
  },
  {
    "id": 151079,
    "name": "BDSL Biological Diagnostic Supplies Ltd    ( Kilmarnock  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 283052,
    "name": "BDV Branchen-Daten-Verarbeitung GmbH    ( Holzwickede  Germany )",
    "country": "Germany"
  },
  {
    "id": 401854,
    "name": "BEAC Biomedical    ( Portalbera (PV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 465030,
    "name": "Beacon Communications LLC    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 999986,
    "name": "Beacon Madeas    (   USA )",
    "country": "United States"
  },
  {
    "id": 185726,
    "name": "Beacon Medical Specialties    ( Granbury TX USA )",
    "country": "United States"
  },
  {
    "id": 454195,
    "name": "Beacon Surgical    ( Churubusco IN USA )",
    "country": "United States"
  },
  {
    "id": 456100,
    "name": "BeaconMedaes    ( Rock Hill SC USA )",
    "country": "United States"
  },
  {
    "id": 464080,
    "name": "Beamed Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 454327,
    "name": "BeamMed Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 454326,
    "name": "BeamMED Shanghai Representative Office    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 187124,
    "name": "Bean Products Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 157745,
    "name": "Bear Medical Systems AG    ( Bilthoven  USA )",
    "country": "United States"
  },
  {
    "id": 101095,
    "name": "Bear Medical Systems Inc    ( Palm Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 175427,
    "name": "BearCom Worldwide Wireless    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 322894,
    "name": "Beatrice M Brantman Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 175692,
    "name": "Beaumont Products Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 451923,
    "name": "Beaver Medical plc    ( Northampton  USA )",
    "country": "United States"
  },
  {
    "id": 455491,
    "name": "Beaver-Visitec International Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 455490,
    "name": "Beaver-Visitec International Ltd    ( Le Pont-de-Claix Cedex  France )",
    "country": "France"
  },
  {
    "id": 401067,
    "name": "Beaverstate Dental Inc    ( Newberg OR USA )",
    "country": "United States"
  },
  {
    "id": 237943,
    "name": "BEC Laboratories    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 458084,
    "name": "Beck's Classic Mfg Inc    ( Brentwood NY USA )",
    "country": "United States"
  },
  {
    "id": 107655,
    "name": "Becker Orthopedic Appliance Co    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 330319,
    "name": "Becker Pumps Corp    ( Cuyahoga Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 451867,
    "name": "Becker-Parkin Dental Supply    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 147818,
    "name": "Beckett Instruments    ( Thundersley  England )",
    "country": "UK"
  },
  {
    "id": 431491,
    "name": "Beckman Coulter (UK) Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 174538,
    "name": "Beckman Coulter Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456761,
    "name": "Beckman Coulter Commercial Enterprise (China) Co L    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 175263,
    "name": "Beckman Coulter France SA    ( Roissy-Charles-de-Gaulle Cedex  France )",
    "country": "France"
  },
  {
    "id": 175266,
    "name": "Beckman Coulter GmbH    ( Krefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 458112,
    "name": "Beckman Coulter HK Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 185066,
    "name": "Beckman Coulter Hong Kong Ltd    ( Quarry Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 340083,
    "name": "Beckman Coulter Inc    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 361269,
    "name": "Beckman Coulter Inc    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 362269,
    "name": "Beckman Coulter Inc    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 431490,
    "name": "Beckman Coulter Inc (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 306950,
    "name": "Beckman Coulter Instruments (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 185065,
    "name": "Beckman Coulter Instruments International S/B    ( Nyon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 175265,
    "name": "Beckman Coulter KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456759,
    "name": "Beckman Coulter Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 178434,
    "name": "Beckman Coulter SpA    ( Cassina de'Pecchi MI  Italy )",
    "country": "Italy"
  },
  {
    "id": 105229,
    "name": "Beckman Instruments Inc Micro-Media Systems Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 460769,
    "name": "Becquerel & Sievert Co Ltd (BqSv)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457078,
    "name": "Becton Dickinson (Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 102236,
    "name": "Bed-Check Corp    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 452176,
    "name": "Bedbar Inc    ( Westhampton MA USA )",
    "country": "United States"
  },
  {
    "id": 104112,
    "name": "Bedcolab Ltd    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 324441,
    "name": "Bedfont Scientific Ltd    ( Rochester  England )",
    "country": "UK"
  },
  {
    "id": 165282,
    "name": "Bedoma AG Kunststoffverarbeitung    ( Waldstatt  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 460684,
    "name": "Bee Robotics    ( Caernarfon  Wales )",
    "country": "Wales"
  },
  {
    "id": 398885,
    "name": "Beekley Corp    ( Bristol CT USA )",
    "country": "United States"
  },
  {
    "id": 466678,
    "name": "Beevers Medical Solutions    ( McMinnville OR USA )",
    "country": "United States"
  },
  {
    "id": 453003,
    "name": "Befour Inc    ( Saukville WI USA )",
    "country": "United States"
  },
  {
    "id": 280488,
    "name": "BEGO Bremer Goldschlaegerei Wilh Herbst GmbH & Co    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 464108,
    "name": "BEGO USA    ( Lincoln RI USA )",
    "country": "United States"
  },
  {
    "id": 107852,
    "name": "Behlman    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 178828,
    "name": "Behnk Elektronik GmbH & Co KG    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 138333,
    "name": "Beiersdorf AG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 279085,
    "name": "Beiersdorf AS    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 232362,
    "name": "Beiersdorf Australia Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 362082,
    "name": "Beiersdorf Inc    ( Wilton CT USA )",
    "country": "United States"
  },
  {
    "id": 456159,
    "name": "Beiging HongRunDa Sci-Tech Development Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456149,
    "name": "Beijing Aeomed Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456150,
    "name": "Beijing Baixiang New Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289692,
    "name": "Beijing Beilin Electronic Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456151,
    "name": "Beijing Beishute Maternity & Child Articles Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457192,
    "name": "Beijing Besnovo S&T Development Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454451,
    "name": "Beijing Choice Electronic Tech Co    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456153,
    "name": "Beijing D & W Medical Equipment Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456154,
    "name": "Beijing Dehaier Medical Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456155,
    "name": "Beijing Fanxing Guangdian Medical Treatment Equipm    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439381,
    "name": "Beijing Fukuda Denshi Medical Instruments Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456156,
    "name": "Beijing Gaoxin Huakang Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456157,
    "name": "Beijing Golden Huahan New Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456158,
    "name": "Beijing HERGOM International Business Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289707,
    "name": "Beijing Instant Oxygen    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289709,
    "name": "Beijing Institute of Opto-Electronic Technology    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 289710,
    "name": "Beijing Jiexiang Medical Electronic Equipment Fact    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456163,
    "name": "Beijing Jinhengwei Technology Development co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456166,
    "name": "Beijing Kelida Medical Equipment Development Co Lt    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456167,
    "name": "Beijing Kinghawk Pharmaceutical Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456168,
    "name": "Beijing Kingst Commercial & Trade Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456169,
    "name": "Beijing Leadman Biochemistry Co Ltd    ( Beiing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456165,
    "name": "Beijing Libeier Bio-Engineering Institute Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456179,
    "name": "Beijing M & B Electronic Instruments Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455555,
    "name": "Beijing MIM Software Corp Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456180,
    "name": "Beijing North Star Yaao SciTech Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456181,
    "name": "Beijing Rongrui-Centry Science & Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455018,
    "name": "Beijing SEDECAL Medical Equipment Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456182,
    "name": "Beijing Shining Sun Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373069,
    "name": "Beijing Shouchang Yamato Electric Scale Co Ltd    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 455367,
    "name": "Beijing Sincoheren Science & Technology Developmen    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289729,
    "name": "Beijing Sitai New Technique Development Corp    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456183,
    "name": "Beijing Steelex Scientific Instruments Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289666,
    "name": "Beijing Steellex Instruments Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456184,
    "name": "Beijing Tigsun Biotinge Science & Technoilogy Co L    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454178,
    "name": "Beijing Toplaser Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416393,
    "name": "Beijing Wandong Medical Equipment Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456185,
    "name": "Beijing Wantai Biological Pharmacy Enterprise Co L    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456186,
    "name": "Beijing Weili New Century Science & Tech Developme    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289733,
    "name": "Beijing Weili Technology & Trade Co    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456196,
    "name": "Beijing Xin He Feng Medical Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 290890,
    "name": "Beijing Zhongke Jian'an Meditechnics Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 462895,
    "name": "Bekins Moving Solutions    ( Arcadia CA USA )",
    "country": "United States"
  },
  {
    "id": 103399,
    "name": "Bel-Art Products Inc    ( Pequannock NJ USA )",
    "country": "United States"
  },
  {
    "id": 451965,
    "name": "Belcher Pharmaceuticals    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 451490,
    "name": "Beldico SA/NV    ( Marche-en-Famenne  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 140149,
    "name": "Belimed AG    ( Ballwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 416387,
    "name": "Belimed bv    ( Beneden-Leeuwen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416384,
    "name": "Belimed d o o    ( Grosuplje  Slovenia )",
    "country": "Slovenia"
  },
  {
    "id": 416383,
    "name": "Belimed GmbH (Austria)    ( Fehring  Austria )",
    "country": "Austria"
  },
  {
    "id": 416209,
    "name": "Belimed GmbH (Deutschland)    ( Muehldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 402798,
    "name": "Belimed Inc    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 416385,
    "name": "Belimed SA    ( Louvain-La-Neuve  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 416386,
    "name": "Belimed SAS    ( Sausheim  France )",
    "country": "France"
  },
  {
    "id": 104121,
    "name": "Beline Products    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 452927,
    "name": "Belkin International Inc    ( Playa Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 999546,
    "name": "Bell + Howell    (   USA )",
    "country": "United States"
  },
  {
    "id": 465067,
    "name": "Bell and Howell, LLC.    ( Wheeling 76 USA )",
    "country": "United States"
  },
  {
    "id": 358805,
    "name": "Bell Biomedical Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 107096,
    "name": "Bell Helicopter Textron Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 185729,
    "name": "Bell Medical    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 283904,
    "name": "Bell Moda GmbH    ( Pirmasens  Germany )",
    "country": "Germany"
  },
  {
    "id": 103097,
    "name": "Bell-Horn    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 459350,
    "name": "Bella Products Inc    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 104191,
    "name": "Bellco Glass Inc    ( Vineland NJ USA )",
    "country": "United States"
  },
  {
    "id": 331072,
    "name": "Bellco SpA    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 294858,
    "name": "Bellingham & Stanley Inc    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 139624,
    "name": "Bellingham & Stanley Ltd    ( Tunbridge Wells  England )",
    "country": "UK"
  },
  {
    "id": 473851,
    "name": "BELMED Inc    ( Red Lion PA USA )",
    "country": "United States"
  },
  {
    "id": 102218,
    "name": "Belmont Equipment    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 104463,
    "name": "Belmont Instrument Corp    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 330322,
    "name": "Belmont Metals Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 281686,
    "name": "Belmont Takara Co Europe GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 342145,
    "name": "Belrose Refracting Equipment Co Inc    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 102172,
    "name": "Beltone Electronics Corp    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 107234,
    "name": "Beltor Mfg Corp    ( Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 471687,
    "name": "bemag Objekteinrichtungen AG    ( Sissach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455381,
    "name": "Bemes Inc    ( Fenton MO USA )",
    "country": "United States"
  },
  {
    "id": 439587,
    "name": "Bemis Asia/Pacific Sdn Bhd    ( Subang Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 354622,
    "name": "Bemis de Mexico SA de CV    ( Guadalupe  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 341771,
    "name": "Bemis Ltd    ( Burnley  England )",
    "country": "UK"
  },
  {
    "id": 101105,
    "name": "Bemis Mfg Co    ( Sheboygan Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 108734,
    "name": "Ben Nye Co Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 999541,
    "name": "Bencher Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 452196,
    "name": "Benchmark Electronics Inc    ( Angleton TX USA )",
    "country": "United States"
  },
  {
    "id": 457035,
    "name": "Benchmark Scientific Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 397851,
    "name": "Benchmark Systems    ( Lynchburg VA USA )",
    "country": "United States"
  },
  {
    "id": 459351,
    "name": "BenchPro    ( Tecate CA USA )",
    "country": "United States"
  },
  {
    "id": 451866,
    "name": "Benco Dental Supply Co    ( Wilkes Barre PA USA )",
    "country": "United States"
  },
  {
    "id": 283203,
    "name": "Bender & Hobein GmbH    ( Ismaning  Germany )",
    "country": "Germany"
  },
  {
    "id": 300262,
    "name": "Bender Medical Products    ( Coatesville PA USA )",
    "country": "United States"
  },
  {
    "id": 999981,
    "name": "BENDINER & SCHLESINGER INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 458292,
    "name": "Benetech Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 174540,
    "name": "Benlan Inc    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454230,
    "name": "Benlioglu Dental Inc    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 105883,
    "name": "Bennett Mfg Co Inc    ( Alden NY USA )",
    "country": "United States"
  },
  {
    "id": 999505,
    "name": "Bennett X-ray Corp.    ( Copiague NY USA )",
    "country": "United States"
  },
  {
    "id": 284999,
    "name": "Benno H Tiaden    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 456525,
    "name": "BenQ Medical Technology Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457340,
    "name": "Bense GmbH    ( Hardegsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453705,
    "name": "Benson Medical Instruments Co    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 455192,
    "name": "Benson Viscometers Ltd    ( West Williamston  Wales )",
    "country": "Wales"
  },
  {
    "id": 184530,
    "name": "Bentec Medical Inc    ( Woodland CA USA )",
    "country": "United States"
  },
  {
    "id": 460596,
    "name": "Bentlon Podiatry    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 152515,
    "name": "Bentronic Gesellschaft fuer Medizin-Elektronik Gmb    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293751,
    "name": "Benz & Cie AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 108194,
    "name": "Berchtold Corp    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 138074,
    "name": "Berchtold GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281303,
    "name": "Berghof GmbH    ( Eningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286408,
    "name": "Berghold & Gamper GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 362149,
    "name": "Bergoz Instrumentation    ( Saint-Genis-Pouilly  France )",
    "country": "France"
  },
  {
    "id": 399752,
    "name": "Berkel (Ireland) Ltd    ( Dublin 12  USA )",
    "country": "United States"
  },
  {
    "id": 399759,
    "name": "Berkel AB    ( Norsborg  USA )",
    "country": "United States"
  },
  {
    "id": 399744,
    "name": "Berkel SA    ( Domont  USA )",
    "country": "United States"
  },
  {
    "id": 106732,
    "name": "Berkeley Medevices Inc    ( Richmond CA USA )",
    "country": "United States"
  },
  {
    "id": 460679,
    "name": "Berkeley Varitronics Systems Inc    ( Metuchen NJ USA )",
    "country": "United States"
  },
  {
    "id": 457928,
    "name": "Berkshire Corp    ( Great Barrington MA USA )",
    "country": "United States"
  },
  {
    "id": 150207,
    "name": "Berlex Laboratories Inc    ( Montville NJ USA )",
    "country": "United States"
  },
  {
    "id": 322350,
    "name": "Berli Jucker Public Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 440839,
    "name": "Berlin Heart AG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 373930,
    "name": "Bernafon AG    ( Berne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 428441,
    "name": "Bernafon Canada Ltd    ( Kitchener ON Canada )",
    "country": "Canada"
  },
  {
    "id": 153132,
    "name": "Bernal Torantriebe GmbH    ( Pfullingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 194676,
    "name": "Bernard Assoc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 151578,
    "name": "Bernell Corp    ( Mishawaka IN USA )",
    "country": "United States"
  },
  {
    "id": 398099,
    "name": "Berner International GmbH    ( Elmshorn  Germany )",
    "country": "Germany"
  },
  {
    "id": 280612,
    "name": "Bernhard Medizintechnik GmbH    ( Burscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 279952,
    "name": "Bernhard Radom Berufskleiderfabrik GmbH    ( Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 415807,
    "name": "Berquitex-Anita BV    ( Weesp  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 418533,
    "name": "Berson Milieutechniek bv    ( Nuenen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999455,
    "name": "Bertec Corporation    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 168438,
    "name": "Bertec Enterprise Corp Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 335245,
    "name": "Bertec Japan Co Ltd    ( Fugisawa  Japan )",
    "country": "Japan"
  },
  {
    "id": 454301,
    "name": "Berthold Detection Systems GmbH    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454062,
    "name": "Berthold Technologies (Uk) Ltd    ( Harpenden  England )",
    "country": "UK"
  },
  {
    "id": 354522,
    "name": "Berthold Technologies GmbH & Co KG    ( Bad Wildbad  Germany )",
    "country": "Germany"
  },
  {
    "id": 105645,
    "name": "Berthold Technologies USA LLC    ( Oak Ridge TN USA )",
    "country": "United States"
  },
  {
    "id": 454521,
    "name": "Bertocchi Srl Elettromedicali    ( Cremona (CR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 162118,
    "name": "Bertonati Hermanos SA    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 452465,
    "name": "BES Better Ergomic Seating    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 455538,
    "name": "BES Industries Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457429,
    "name": "BES Rehab Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 455788,
    "name": "Besmed    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 186676,
    "name": "Bespak plc    ( Kings Lynn  England )",
    "country": "UK"
  },
  {
    "id": 279954,
    "name": "Bess Medizintechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 330210,
    "name": "BEST    ( Fairbanks AK USA )",
    "country": "United States"
  },
  {
    "id": 402507,
    "name": "Best Bath Systems    ( Caldwell ID USA )",
    "country": "United States"
  },
  {
    "id": 999531,
    "name": "Best Beauty    (   USA )",
    "country": "United States"
  },
  {
    "id": 457959,
    "name": "Best Computes    ( Altamonte Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 999722,
    "name": "Best Computes    ( Altamonte Springs FL USA )",
    "country": "USA"
  },
  {
    "id": 458387,
    "name": "Best Glide ASE Inc    ( Robinson TX USA )",
    "country": "United States"
  },
  {
    "id": 105884,
    "name": "Best Inc Sign Systems    ( Montrose CO USA )",
    "country": "United States"
  },
  {
    "id": 306304,
    "name": "Best Manufacturing Europe NV    ( Wommeigem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453575,
    "name": "Best Medical Canada Ltd    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 382402,
    "name": "Best Medical International Inc    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 290084,
    "name": "Best Mfg Co    ( Menlo GA USA )",
    "country": "United States"
  },
  {
    "id": 101092,
    "name": "Best Mfg Group LLC    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 454286,
    "name": "Best nomos    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 186794,
    "name": "Best Orthopedic Products Inc    ( Hickory NC USA )",
    "country": "United States"
  },
  {
    "id": 453574,
    "name": "Best Theratronics Ltd    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455230,
    "name": "Best Vascular Inc    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 455261,
    "name": "Bestcare LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 405947,
    "name": "BestMed LLC    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 330263,
    "name": "Beta Biomed Services Inc    ( Rowlett TX USA )",
    "country": "United States"
  },
  {
    "id": 457053,
    "name": "Beta Medical Inc    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 107860,
    "name": "Beta Medical Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 161993,
    "name": "Beta Technology Inc    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 407229,
    "name": "Betasan Bant Sanayi ve Tic AS    ( Kacaeli  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 458870,
    "name": "Betatek Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460585,
    "name": "Betco Corp    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 323185,
    "name": "Better Hands Glove Products    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 333471,
    "name": "Better Hearing Inc    ( Brookport IL USA )",
    "country": "United States"
  },
  {
    "id": 331073,
    "name": "Better Water Inc    ( Smyrna TN USA )",
    "country": "United States"
  },
  {
    "id": 458752,
    "name": "BetterBuilt    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 997850,
    "name": "BetterBuilt    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 286929,
    "name": "Beurer GmbH & Co    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 290085,
    "name": "Beutlich LP    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 469476,
    "name": "Bevco Precision Mfg Co    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 999928,
    "name": "Beverage Air    (   USA )",
    "country": "United States"
  },
  {
    "id": 169951,
    "name": "Beverly Hills Medical Systems    ( Encino CA USA )",
    "country": "United States"
  },
  {
    "id": 282914,
    "name": "Beycodent Beyer & Co GmbH    ( Herdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 333472,
    "name": "Beyond 21st Century Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 392323,
    "name": "Beyond Barriers    ( Forest Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 457619,
    "name": "Beyond Sight Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 458410,
    "name": "BeyondTrust Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 460680,
    "name": "BF Technologies Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 361280,
    "name": "BFi OPTiCAL SA    ( Evry Cedex  France )",
    "country": "France"
  },
  {
    "id": 291051,
    "name": "BFi OPTiLAS Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 108179,
    "name": "BFW Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 108399,
    "name": "BG Industries Inc    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 399276,
    "name": "BG Systems Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 457787,
    "name": "BH Medwear Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 456629,
    "name": "BH North America    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 339123,
    "name": "BHB Medizintechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 458695,
    "name": "BHdental Ltd    ( Nesher  Israel )",
    "country": "Israel"
  },
  {
    "id": 103653,
    "name": "BHK Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 403713,
    "name": "BHM Medical Inc    ( Magog PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 454634,
    "name": "BHR Pharmaceuticals Ltd    ( Nuneaton  England )",
    "country": "UK"
  },
  {
    "id": 230341,
    "name": "BHT HygieneTechnik GmbH    ( Gersthofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455032,
    "name": "Bi-Coastal Pharmaceutical Corp    ( Red Bank NJ USA )",
    "country": "United States"
  },
  {
    "id": 458277,
    "name": "Bi-Line Systems    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 233458,
    "name": "Bibbero Systems Inc    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 455174,
    "name": "Bibby Scientific Asia    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455157,
    "name": "Bibby Scientific France    ( Nemours Cedex  France )",
    "country": "France"
  },
  {
    "id": 455158,
    "name": "Bibby Scientific Italy    ( Riosso de Cerro Al Lambro (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455155,
    "name": "Bibby Scientific Ltd    ( Stone  England )",
    "country": "UK"
  },
  {
    "id": 455156,
    "name": "Bibby Scientific Middle East    ( Nicosia  Cyprus )",
    "country": "Cyprus"
  },
  {
    "id": 351248,
    "name": "Bicakcilar Tibbi Cihazlar Sanayi Ve Tecaret AS    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 293565,
    "name": "Bico Birchler & Co AG    ( Schaenis  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452456,
    "name": "BiCOM Inc    ( Long Beach NY USA )",
    "country": "United States"
  },
  {
    "id": 309593,
    "name": "Bidoia Gianfranco    ( Peraga di Vigonza (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454797,
    "name": "Biedermann Motech GmbH & Co KG    ( Villingen-Schwenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 463055,
    "name": "Bielefelder Dentalsilicone GmbH & Co KG    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 183978,
    "name": "Bielsan srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 415942,
    "name": "Bien-Air Asia Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 281761,
    "name": "Bien-Air Deutschland GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 415941,
    "name": "Bien-Air Espana SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 415944,
    "name": "Bien-Air France Sarl    ( Pantin  France )",
    "country": "France"
  },
  {
    "id": 415943,
    "name": "Bien-Air Italia srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 415940,
    "name": "Bien-Air Nordic    ( Kobenhaven/Vanlosee  USA )",
    "country": "United States"
  },
  {
    "id": 333641,
    "name": "Bien-Air SA    ( Bienne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453861,
    "name": "Bien-Air Surgery SA    ( Le Noirmont  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 324781,
    "name": "Bien-Air UK Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 371451,
    "name": "Bien-Air USA    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 460682,
    "name": "Big Ass Fan Co    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 452869,
    "name": "BigHand (Australia)    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 451760,
    "name": "BigHand Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451785,
    "name": "BigHand Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451790,
    "name": "BigHand Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 390961,
    "name": "Bignell Surgical Instruments Ltd    ( Arundel  USA )",
    "country": "United States"
  },
  {
    "id": 324560,
    "name": "Bilaurand Laboratories Ltd    ( Carnaby  USA )",
    "country": "United States"
  },
  {
    "id": 454330,
    "name": "Bilt-Rite Orthopedics & Safety    ( Croydon PA USA )",
    "country": "United States"
  },
  {
    "id": 293190,
    "name": "Bimeda AG    ( Bachenbuelach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 108872,
    "name": "Binax Inc    ( Scarborough ME USA )",
    "country": "United States"
  },
  {
    "id": 402897,
    "name": "Binder Asia    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 161115,
    "name": "Binder GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283145,
    "name": "Binz Ambulance und Umwelttechnik GmbH    ( Ilmenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 284046,
    "name": "Binz GmbH & Co KG    ( Lorch  Germany )",
    "country": "Germany"
  },
  {
    "id": 107076,
    "name": "Bio Air Systems    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 451850,
    "name": "Bio Composants Medicaux    ( Tullins  France )",
    "country": "France"
  },
  {
    "id": 160973,
    "name": "Bio Compression Systems Inc    ( Moonachie NJ USA )",
    "country": "United States"
  },
  {
    "id": 177036,
    "name": "Bio Electronics Pty Ltd    ( Niddrie  Australia )",
    "country": "Australia"
  },
  {
    "id": 455752,
    "name": "Bio Focus Co Ltd    ( Uiwang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 284968,
    "name": "BIO KLION GmbH    ( Nideggen  USA )",
    "country": "United States"
  },
  {
    "id": 151334,
    "name": "Bio Med Sciences Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 455753,
    "name": "Bio Medical Technologies Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 179521,
    "name": "Bio Nova International Pty Ltd    ( North Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 174544,
    "name": "Bio Nuclear Diagnostics Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451061,
    "name": "Bio Plas Inc    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 451069,
    "name": "Bio Plas Inc    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 409858,
    "name": "Bio Recherches Innovations    ( Le Luc  France )",
    "country": "France"
  },
  {
    "id": 457507,
    "name": "Bio Skin    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 286621,
    "name": "Bio-Check    ( Trierweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 185739,
    "name": "Bio-Core Inc    ( Kissimmee FL USA )",
    "country": "United States"
  },
  {
    "id": 456760,
    "name": "BIO-DETEK Inc    ( Pawtucket RI USA )",
    "country": "United States"
  },
  {
    "id": 451855,
    "name": "Bio-Diagnostics Ltd    ( Upton-Upon-Severn  England )",
    "country": "UK"
  },
  {
    "id": 101127,
    "name": "Bio-Feedback Systems Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 428391,
    "name": "Bio-Imaging Technologies    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 171772,
    "name": "Bio-Klima-Institut GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 462833,
    "name": "Bio-Logic SAS    ( Claix  France )",
    "country": "France"
  },
  {
    "id": 103098,
    "name": "Bio-Logic Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 104756,
    "name": "Bio-logic Systems Corp    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 104124,
    "name": "Bio-Logics Products Inc    ( West Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 312339,
    "name": "Bio-Lok International Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 460802,
    "name": "Bio-Med Assoc Inc    ( Flemington NJ USA )",
    "country": "United States"
  },
  {
    "id": 104004,
    "name": "Bio-Med Devices Inc    ( Guilford CT USA )",
    "country": "United States"
  },
  {
    "id": 170234,
    "name": "Bio-Med Engineering Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 267647,
    "name": "Bio-Med USA Inc    ( Paterson NJ USA )",
    "country": "United States"
  },
  {
    "id": 335254,
    "name": "Bio-Medic Health Services Inc    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 455633,
    "name": "Bio-Medical Devices Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 107973,
    "name": "Bio-Medical Equipment Service Co    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 108188,
    "name": "Bio-Medical Instrument Co    ( Newbury OH USA )",
    "country": "United States"
  },
  {
    "id": 106420,
    "name": "Bio-Medical Instruments Inc    ( Warren MI USA )",
    "country": "United States"
  },
  {
    "id": 104530,
    "name": "Bio-Medical Products Corp    ( Mendham NJ USA )",
    "country": "United States"
  },
  {
    "id": 452675,
    "name": "Bio-Medical Research GmbH    ( Salem  Germany )",
    "country": "Germany"
  },
  {
    "id": 310269,
    "name": "Bio-Medical Research Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 106763,
    "name": "Bio-Medical Service Corp    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 339022,
    "name": "Bio-Medical Service de Mexico SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 416206,
    "name": "Bio-Optica Milano SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 155737,
    "name": "Bio-Optics Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 175349,
    "name": "Bio-Optronics Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 176094,
    "name": "Bio-Orthotics International Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 455755,
    "name": "Bio-Protech Inc    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 441552,
    "name": "Bio-Rad France    ( Marnes-la-Coquette  France )",
    "country": "France"
  },
  {
    "id": 454946,
    "name": "Bio-Rad Hungary Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 441553,
    "name": "Bio-Rad Laboratories (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 293384,
    "name": "Bio-Rad Laboratories AG    ( Reinach/Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454947,
    "name": "Bio-Rad Laboratories bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454948,
    "name": "Bio-Rad Laboratories Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 284590,
    "name": "Bio-Rad Laboratories GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 150120,
    "name": "Bio-Rad Laboratories Inc    ( Hercules CA USA )",
    "country": "United States"
  },
  {
    "id": 152371,
    "name": "Bio-Rad Laboratories Inc    ( Hercules CA USA )",
    "country": "United States"
  },
  {
    "id": 158745,
    "name": "Bio-Rad Laboratories Ltd    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 353227,
    "name": "Bio-Rad Laboratories Ltd (UK)    ( Hemel Hempstead  England )",
    "country": "UK"
  },
  {
    "id": 300884,
    "name": "Bio-Rad Laboratories Pty Ltd    ( Gladesville  Australia )",
    "country": "Australia"
  },
  {
    "id": 454943,
    "name": "Bio-Rad Laboratories srl    ( Segrate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 262856,
    "name": "Bio-Rad Pacific Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454949,
    "name": "Bio-Rad SA-NV (Belgium)    ( Mechelen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 384289,
    "name": "Bio-Rad Thailand    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 359432,
    "name": "Bio-Safe America Corp    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 451628,
    "name": "Bio-Stat Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 441170,
    "name": "Bio-Tech Microscope Service Inc    ( Gretna LA USA )",
    "country": "United States"
  },
  {
    "id": 467222,
    "name": "Bio-Techne    ( Devens MA USA )",
    "country": "United States"
  },
  {
    "id": 391876,
    "name": "Bio-Tek Instruments GmbH (Deutschland)    ( Bad Friedrichshall  Germany )",
    "country": "Germany"
  },
  {
    "id": 101086,
    "name": "Bio-Tek Instruments Inc    ( Winooski VT USA )",
    "country": "United States"
  },
  {
    "id": 458079,
    "name": "Bio-Tissue Inc    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 102407,
    "name": "Bio/Data Corp    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 455224,
    "name": "Biocytex    ( Marseille  France )",
    "country": "France"
  },
  {
    "id": 232363,
    "name": "Bio/Gene Ltd    ( Kimbolton  England )",
    "country": "UK"
  },
  {
    "id": 458746,
    "name": "BioAcoustics Instruments Inc    ( Hanover MD USA )",
    "country": "United States"
  },
  {
    "id": 104123,
    "name": "Bioanalytical Systems Inc    ( West Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 460544,
    "name": "BioArthro    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 458843,
    "name": "Bioasset Technologies Pvt Ltd    ( Thane  India )",
    "country": "India"
  },
  {
    "id": 467135,
    "name": "BioAutomation    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 457198,
    "name": "BioBDx    ( Westford MA USA )",
    "country": "United States"
  },
  {
    "id": 458020,
    "name": "bioBUBBLE Inc    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 416072,
    "name": "Biocalderoni Taneszboezgyarto es Forgalmazu Kft Ko    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 999884,
    "name": "BIOCAM GmbH    (   USA )",
    "country": "United States"
  },
  {
    "id": 462975,
    "name": "BioCardia Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 453399,
    "name": "Biocare Medical LLC    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 289365,
    "name": "Biocell Laboratories Inc    ( Rancho Dominguez CA USA )",
    "country": "United States"
  },
  {
    "id": 471740,
    "name": "BioCer Entwicklungs-GmbH    ( Bayreuth  Germany )",
    "country": "Germany"
  },
  {
    "id": 455310,
    "name": "BioCheck Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 155083,
    "name": "Biochemical Diagnostics Inc    ( Edgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 416176,
    "name": "Biochemical Systems International srl    ( Arezzo (AR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 279960,
    "name": "BIOCHROM    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 391206,
    "name": "Biochrom Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 456439,
    "name": "Biochrom US    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 226056,
    "name": "Biocientifica SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 305724,
    "name": "Bioclone Australia Pty Ltd    ( Marrickville  Australia )",
    "country": "Australia"
  },
  {
    "id": 471743,
    "name": "Biocoat Inc    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 450507,
    "name": "Biocode Hycel (Belgium)    ( Liege  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 450506,
    "name": "Biocode Hycel (France)    ( Massy Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 458265,
    "name": "BioCold Environmental Inc    ( Fenton MO USA )",
    "country": "United States"
  },
  {
    "id": 460761,
    "name": "BioComp Instruments Inc    ( Fredericton NB Canada )",
    "country": "Canada"
  },
  {
    "id": 108707,
    "name": "BioComp Research Institute    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 401725,
    "name": "Biocompatibles Cardiovascular Inc    ( Farnham  England )",
    "country": "UK"
  },
  {
    "id": 451841,
    "name": "Biocomposites (Shanghai) Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 393196,
    "name": "Biocomposites (USA)    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 357288,
    "name": "Biocomposites Ltd    ( Keele  England )",
    "country": "UK"
  },
  {
    "id": 460843,
    "name": "Biocomputing Platforms Ltd    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 184900,
    "name": "Biocon Medizintechnik GmbH    ( Muenchberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452455,
    "name": "Bioconnect    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457294,
    "name": "Bioconnect Systems    ( Ambler PA USA )",
    "country": "United States"
  },
  {
    "id": 455513,
    "name": "BioControl Medical Israel    ( Yehud  Israel )",
    "country": "Israel"
  },
  {
    "id": 452757,
    "name": "Biocoral France    ( Levallois-Perret  France )",
    "country": "France"
  },
  {
    "id": 290086,
    "name": "BioCore Medical Technologies Inc    ( Elkridge MD USA )",
    "country": "United States"
  },
  {
    "id": 457449,
    "name": "BioCybernetics International    ( La Verne CA USA )",
    "country": "United States"
  },
  {
    "id": 390983,
    "name": "Biomet Merck GmbH    ( Altdorf  USA )",
    "country": "United States"
  },
  {
    "id": 460549,
    "name": "BioD LLC    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 456605,
    "name": "Biodenix Technologies Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 199734,
    "name": "Biodermis Corp    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 108985,
    "name": "Biodex Medical Systems Inc    ( Shirley NY USA )",
    "country": "United States"
  },
  {
    "id": 460853,
    "name": "BioDiscovery Inc    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 460778,
    "name": "Biodose LLC    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 467381,
    "name": "BioDot Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 458041,
    "name": "BioDrain Medical Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 363782,
    "name": "Biodynamics Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 127622,
    "name": "Bioelectric Engineering and Medical Supplies Inc (    ( Elmhurst IL USA )",
    "country": "United States"
  },
  {
    "id": 455805,
    "name": "Bioer Technology Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 255278,
    "name": "Bioetica Inc    ( Northport NY USA )",
    "country": "United States"
  },
  {
    "id": 458563,
    "name": "BioExpress    ( Kaysville UT USA )",
    "country": "United States"
  },
  {
    "id": 398829,
    "name": "Biofeed Ltd    ( Budapest  USA )",
    "country": "United States"
  },
  {
    "id": 407230,
    "name": "Biofeedback Motor Control GmbH    ( Leipzig  Germany )",
    "country": "Germany"
  },
  {
    "id": 392885,
    "name": "BioFilm Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 466935,
    "name": "BioFire Defense    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 458098,
    "name": "BioFire Diagnostics Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 161600,
    "name": "BioFit Engineered Products    ( Waterville OH USA )",
    "country": "United States"
  },
  {
    "id": 451372,
    "name": "BioFocus    ( Uiwang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 155400,
    "name": "BioGenex Laboratories Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 451423,
    "name": "Biogenics Inc    ( Napa CA USA )",
    "country": "United States"
  },
  {
    "id": 416015,
    "name": "Biognosis GmbH    ( Juelich  USA )",
    "country": "United States"
  },
  {
    "id": 452735,
    "name": "Biohit Biotech (Suzhou) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 393238,
    "name": "Biohit Deutschland GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 393234,
    "name": "Biohit Inc    ( Neptune NJ USA )",
    "country": "United States"
  },
  {
    "id": 393236,
    "name": "Biohit Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 289501,
    "name": "Biohit Ltd    ( Torquay  England )",
    "country": "UK"
  },
  {
    "id": 439540,
    "name": "Biohit O O O    ( St Petersburg  Russia )",
    "country": "Russia"
  },
  {
    "id": 162449,
    "name": "Biohit Plc    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 393237,
    "name": "Biohit SA    ( Bonnelles  France )",
    "country": "France"
  },
  {
    "id": 451422,
    "name": "BioHorizons Canada    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 332683,
    "name": "BioHorizons Implant Systems Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 108133,
    "name": "Bioject Inc    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 139999,
    "name": "Biokit SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 108190,
    "name": "Biokit USA Inc    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 456964,
    "name": "Biola Ltd    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 309383,
    "name": "Biolab Ges mbH & Co KG    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 455756,
    "name": "Bioland Co Ltd    ( Cheonan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451374,
    "name": "Bioland Technology    ( North Point  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455732,
    "name": "Bioland Technology Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 230455,
    "name": "BioLase Technology Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 330560,
    "name": "Biolene Srl    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 451070,
    "name": "Biolife LLC    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 304533,
    "name": "BioLight Phototherapy Ltd    ( Jaffa  Israel )",
    "country": "Israel"
  },
  {
    "id": 460861,
    "name": "Biolin Scientific    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 382748,
    "name": "biolitec (M) Sdn Bhd    ( Petaling Jaya  USA )",
    "country": "United States"
  },
  {
    "id": 378489,
    "name": "biolitec AG    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 382747,
    "name": "biolitec Inc    ( East Longmeadow MA USA )",
    "country": "United States"
  },
  {
    "id": 382752,
    "name": "biolitec Ltd    ( Blessington  USA )",
    "country": "United States"
  },
  {
    "id": 108854,
    "name": "Biolog Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 156024,
    "name": "Biological Controls Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 391306,
    "name": "Biological Instrumentation Services Ltd    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 345479,
    "name": "BioLogics Inc    ( Manassas VA USA )",
    "country": "United States"
  },
  {
    "id": 434165,
    "name": "BioLucent Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 227223,
    "name": "BioLume    ( Loos  France )",
    "country": "France"
  },
  {
    "id": 453385,
    "name": "bioLytical Laboratories Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 330268,
    "name": "BioMagnetics    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 284302,
    "name": "BIOMAR Diagnostic Systems GmbH    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 138335,
    "name": "Biomark GmbH    ( Rheinau  Germany )",
    "country": "Germany"
  },
  {
    "id": 108401,
    "name": "Biomark Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 361918,
    "name": "Biomark Ltd    ( Douglas  Great Britain )",
    "country": "UK"
  },
  {
    "id": 450575,
    "name": "Biomarketing Service (M) Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 108737,
    "name": "Biomation    ( Almonte ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457281,
    "name": "Biomatlante    ( Vigneux-de-Bretagne  France )",
    "country": "France"
  },
  {
    "id": 413010,
    "name": "Biomec Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 398086,
    "name": "Biomecanica    ( Jau-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 440225,
    "name": "Biomed Importers Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 287805,
    "name": "Biomed Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 455344,
    "name": "BioMed Laboratories Inc    ( Garland TX USA )",
    "country": "United States"
  },
  {
    "id": 461188,
    "name": "Biomed Simulation Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 366261,
    "name": "Biomed Technologies Inc    ( Lake Hopatcong NJ USA )",
    "country": "United States"
  },
  {
    "id": 455318,
    "name": "Biomedica Medizinprodukte GmbH & Co KG    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 143781,
    "name": "Biomedical Clinical Engineering    ( Arroyo Grande CA USA )",
    "country": "United States"
  },
  {
    "id": 270935,
    "name": "BioMedical Disposal Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 333473,
    "name": "BioMedical Enterprises Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 143761,
    "name": "Biomedical Equipment Service Co Inc (BESCO)    ( Salinas CA USA )",
    "country": "United States"
  },
  {
    "id": 320784,
    "name": "Biomedical International Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 309143,
    "name": "BioMedical Life Systems bv    ( Alkmaar  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 108400,
    "name": "BioMedical Life Systems Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 451071,
    "name": "BioMedical Resources Inc    ( Hatboro PA USA )",
    "country": "United States"
  },
  {
    "id": 458266,
    "name": "BioMedical Solutions Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 460926,
    "name": "Biomedical Synergies Inc    ( Wayzata MN USA )",
    "country": "United States"
  },
  {
    "id": 105010,
    "name": "Biomedical Systems Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 398085,
    "name": "Biomedical Systems SA NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454357,
    "name": "Biomedical Technologies    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 321029,
    "name": "Biomedical Technology Inc    ( Morgantown WV USA )",
    "country": "United States"
  },
  {
    "id": 460891,
    "name": "BioMediCon    ( Moorestown NJ USA )",
    "country": "United States"
  },
  {
    "id": 999883,
    "name": "BioMediCon    ( Moorestown NJ USA )",
    "country": "United States"
  },
  {
    "id": 334468,
    "name": "Biomedics SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 175442,
    "name": "Biomedix Inc    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 384683,
    "name": "BioMedix Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 346080,
    "name": "BiomedRx Inc    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 461377,
    "name": "Biomega Research Products Inc    ( South Plainfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 107239,
    "name": "Biomerica Inc    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 439728,
    "name": "bioMerieux Argentina    ( Capital Federal  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 344486,
    "name": "bioMerieux Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 344503,
    "name": "bioMerieux Benelux bv    ( Zaltbommel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 344487,
    "name": "bioMerieux Benelux SA/NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 344488,
    "name": "bioMerieux Brasil SA    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 344489,
    "name": "bioMerieux Canada Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 344490,
    "name": "bioMerieux Chile SA    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 185868,
    "name": "bioMerieux China Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458066,
    "name": "bioMerieux China Ltd Hong Kong    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373919,
    "name": "bioMerieux Colombia Ltda    ( Santafe de Bogota DC  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 439727,
    "name": "bioMerieux Danmark Aps    ( Herlev  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 285102,
    "name": "bioMerieux Deutschland GmbH    ( Nuertingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456888,
    "name": "bioMerieux Diagnostics (Shanghai) Co Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373925,
    "name": "bioMerieux Diagnostik AS    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 344535,
    "name": "bioMerieux Espana SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 373922,
    "name": "bioMerieux Hellas SA    ( Athinai (Halandri)  Greece )",
    "country": "Greece"
  },
  {
    "id": 108995,
    "name": "bioMerieux Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 373923,
    "name": "bioMerieux India Pvt Ltd    ( Mumbia  India )",
    "country": "India"
  },
  {
    "id": 344491,
    "name": "bioMerieux Italia SpA    ( Bagno a Ripoli (FI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 344500,
    "name": "bioMerieux Japan Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 344501,
    "name": "bioMerieux Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 344502,
    "name": "bioMerieux Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 373917,
    "name": "bioMerieux New Zealand Pvte Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 373918,
    "name": "bioMerieux Norge AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 344504,
    "name": "bioMerieux Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 344505,
    "name": "bioMerieux Portugal Lda    ( Linda-A-Velha  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 373924,
    "name": "bioMerieux Russia    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 140148,
    "name": "BioMerieux SA    ( Craponne  France )",
    "country": "France"
  },
  {
    "id": 344536,
    "name": "bioMerieux Suisse SA    ( Geneve 2  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439720,
    "name": "bioMerieux Suomi Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 439536,
    "name": "bioMerieux Sverige AB    ( Askim  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 439729,
    "name": "bioMerieux Thailand Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 272384,
    "name": "bioMerieux UK Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 463015,
    "name": "Biomerix Corp    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 452929,
    "name": "Biomet 3i    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 454087,
    "name": "Biomet 3i Australia Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 454096,
    "name": "Biomet 3i Belgium NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453090,
    "name": "Biomet 3i Canada Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453093,
    "name": "Biomet 3i Deutschland GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 454091,
    "name": "Biomet 3i do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 453089,
    "name": "Biomet 3i France    ( Lavallois Perret  France )",
    "country": "France"
  },
  {
    "id": 453096,
    "name": "Biomet 3i Iberica SL    ( Cornella de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 457490,
    "name": "Biomet 3i Japan    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 453095,
    "name": "Biomet 3i Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 454090,
    "name": "Biomet 3i Netherlands bv    ( Dordrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453091,
    "name": "Biomet 3i Nordic AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453097,
    "name": "Biomet 3i Schweiz GmbH    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453094,
    "name": "Biomet 3i UK Ltd    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 393250,
    "name": "Biomet A/S    ( Horsens  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453514,
    "name": "Biomet Austria GmbH    ( Thalgau  Austria )",
    "country": "Austria"
  },
  {
    "id": 453513,
    "name": "Biomet Belgium bvba    ( Wilrijk  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452464,
    "name": "Biomet Cementing Technologies AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453509,
    "name": "Biomet CZ sro    ( Praha 4  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 453506,
    "name": "Biomet Deutschland GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 453510,
    "name": "Biomet Finland Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 453512,
    "name": "Biomet France Sarl    ( Valence Cedex 09  France )",
    "country": "France"
  },
  {
    "id": 453508,
    "name": "Biomet Hellas SA    ( Athinai (Moschato)  Greece )",
    "country": "Greece"
  },
  {
    "id": 102173,
    "name": "Biomet Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 453519,
    "name": "Biomet Italia srl    ( S Giuliano Milanese (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 390939,
    "name": "Biomet Merck (The Netherlands)    ( Zwijndrecht  USA )",
    "country": "United States"
  },
  {
    "id": 390977,
    "name": "Biomet Merck Austria GmbH    ( Thalgau  USA )",
    "country": "United States"
  },
  {
    "id": 390980,
    "name": "Biomet Merck Belgium    ( Wilrijk  USA )",
    "country": "United States"
  },
  {
    "id": 390978,
    "name": "Biomet Merck CZ sro    ( Praha 4  USA )",
    "country": "United States"
  },
  {
    "id": 354570,
    "name": "Biomet Merck Deutschland GmbH    ( Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 390979,
    "name": "Biomet Merck Finland Oy    ( Helsinki  USA )",
    "country": "United States"
  },
  {
    "id": 390982,
    "name": "Biomet Merck France    ( Valence Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 390974,
    "name": "Biomet Merck Hellas SA    ( Athinai  USA )",
    "country": "United States"
  },
  {
    "id": 357292,
    "name": "Biomet Merck Ltd    ( Bridgend  USA )",
    "country": "United States"
  },
  {
    "id": 390976,
    "name": "Biomet Merck Norge A/S    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 390981,
    "name": "Biomet Merck Polska    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 390975,
    "name": "Biomet Merck srl    ( Assago MI  USA )",
    "country": "United States"
  },
  {
    "id": 452634,
    "name": "Biomet Microfixation    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 452635,
    "name": "Biomet Microfixation (Europe)    ( Dordrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455354,
    "name": "Biomet Microfixation USA    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 453507,
    "name": "Biomet Netherlands bv    ( Dordrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453515,
    "name": "Biomet Norge A/S    ( Stabekk  Norway )",
    "country": "Norway"
  },
  {
    "id": 453511,
    "name": "Biomet Orthopaedics Switzerland GmbH    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453516,
    "name": "Biomet UK Ltd    ( Bridgend  Wales )",
    "country": "Wales"
  },
  {
    "id": 156970,
    "name": "Biometer International A/S    ( Odense NV  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 153138,
    "name": "Biometra Biomedizinische Analytik GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 471715,
    "name": "Biometric Cables    ( Guindy  India )",
    "country": "India"
  },
  {
    "id": 452852,
    "name": "Biometrics Ltd (UK)    ( Newport  Wales )",
    "country": "Wales"
  },
  {
    "id": 452696,
    "name": "Biometrics Ltd (US)    ( Ladysmith VA USA )",
    "country": "United States"
  },
  {
    "id": 172258,
    "name": "Biometrix Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 462664,
    "name": "BioMicro Systems Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 461190,
    "name": "BioMicroLab    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 226615,
    "name": "Biomidi    ( Toulouse  France )",
    "country": "France"
  },
  {
    "id": 463017,
    "name": "BioMimetic Therapeutics Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 282325,
    "name": "Biomol Feinchemikalien GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 466989,
    "name": "Biomonitor Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 457583,
    "name": "BioMotion Medical Systems LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 188106,
    "name": "Bion Enterprises Ltd    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 454504,
    "name": "Bioness Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 455317,
    "name": "Bionet America Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 108645,
    "name": "BIONET CARDIOCARE    (   USA )",
    "country": "United States"
  },
  {
    "id": 455797,
    "name": "Bionet Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 292075,
    "name": "Bionetics Ltd    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 454243,
    "name": "Bionexus Inc    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 453379,
    "name": "Bionic Medizintechnik GmbH    ( Friedrichsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 451092,
    "name": "BioniCare Medical Technologies Inc    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 456840,
    "name": "Bioniche Teoranta    ( Casla  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 455799,
    "name": "Bionics Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452913,
    "name": "BIONIME (Shenzhen) Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452907,
    "name": "BIONIME Corp    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452914,
    "name": "BIONIME GmbH    ( Berneck  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457456,
    "name": "BIONIME USA Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 466880,
    "name": "BioNiQuest Lab Services Inc    ( Danville CA USA )",
    "country": "United States"
  },
  {
    "id": 178303,
    "name": "Bionix Development Corp    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 108937,
    "name": "Bionostics Inc    ( Devens MA USA )",
    "country": "United States"
  },
  {
    "id": 303561,
    "name": "Biopac Systems Inc    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 457704,
    "name": "BioPacific Diagnostic Corp    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 457703,
    "name": "BioPacific Diagnostics Inc    ( North Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 453018,
    "name": "Biopack Medical    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457500,
    "name": "BioPhoton    ( Saint Alban  France )",
    "country": "France"
  },
  {
    "id": 416532,
    "name": "BioPhysics Assay Laboratory Inc    ( Worcester MA USA )",
    "country": "United States"
  },
  {
    "id": 459118,
    "name": "BIOplastics bv    ( Landgraaf  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451072,
    "name": "Bioplate Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 162113,
    "name": "BioPro Inc    ( Port Huron MI USA )",
    "country": "United States"
  },
  {
    "id": 451164,
    "name": "BioPro International Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 465075,
    "name": "BioPromin LTD    ( Kharkiv  Ukraine )",
    "country": "United Kingdomraine"
  },
  {
    "id": 454679,
    "name": "Biopsy Sciences    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 458200,
    "name": "Bioptechs Inc    ( Butler PA USA )",
    "country": "United States"
  },
  {
    "id": 453567,
    "name": "Bioptics Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 454247,
    "name": "Bioptigen Inc    ( Research Triangle Park NC USA )",
    "country": "United States"
  },
  {
    "id": 455791,
    "name": "Bioptik Technology Inc    ( Jhuan Township  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 458559,
    "name": "BIOQUANT Image Analysis Corp    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 454545,
    "name": "Bioquell Inc    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 288886,
    "name": "Biora AB    ( Malmo  USA )",
    "country": "United States"
  },
  {
    "id": 192812,
    "name": "BIOREM Physiotherapy    ( Agropoli (SA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461193,
    "name": "Biorep Technologies Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 354389,
    "name": "BioResearch Associates Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 458373,
    "name": "Biosafe America Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 330337,
    "name": "BIOSAFE Medical Technologies Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 458378,
    "name": "Biosafe SA    ( Eysins  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 106283,
    "name": "Bioscan Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 370604,
    "name": "Bioscan Inc    ( Washington DC USA )",
    "country": "United States"
  },
  {
    "id": 458164,
    "name": "BioScience GmbH    ( Ransbach-Baumbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 458525,
    "name": "Bioscience International    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 108738,
    "name": "Bioseal Medical Packaging Concepts    ( Placentia CA USA )",
    "country": "United States"
  },
  {
    "id": 101126,
    "name": "Biosearch Medical Products Inc    ( Somerville NJ USA )",
    "country": "United States"
  },
  {
    "id": 337340,
    "name": "Bioselec    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 139328,
    "name": "Bioself SA    ( Geneve/Thonex  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 460571,
    "name": "BioSemi BV    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455678,
    "name": "Biosense Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 401026,
    "name": "Biosense Webster (Europe)    ( Waterloo  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 338303,
    "name": "Biosense Webster (Israel) Ltd    ( Tirat-Ha Carmel  Israel )",
    "country": "Israel"
  },
  {
    "id": 353306,
    "name": "Biosense Webster Inc    ( Diamond Bar CA USA )",
    "country": "United States"
  },
  {
    "id": 452900,
    "name": "BioSensor Industria e Comercio Ltda    ( Americana-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 439093,
    "name": "Biosensors (Malaysia) Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 306518,
    "name": "Biotek Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 438996,
    "name": "Biosensors bv    ( Hillegom  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454808,
    "name": "Biosensors Europe SA    ( Morges  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 303618,
    "name": "Biosensors International Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 418640,
    "name": "Biosensors International USA    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 438997,
    "name": "Biosensors Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 466862,
    "name": "Biosero LLC    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 103099,
    "name": "Biosig Instruments Inc    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 416579,
    "name": "Biosistemi (Croatia)    ( Zagreb  Croatia )",
    "country": "Croatia"
  },
  {
    "id": 175443,
    "name": "Biosite Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 363886,
    "name": "Biosonic srl    ( Calderara (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 106415,
    "name": "Biosonics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 104220,
    "name": "Biosound Esaote Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 412479,
    "name": "BioSource Deutschland GmbH    ( Solingen  USA )",
    "country": "United States"
  },
  {
    "id": 410263,
    "name": "BioSource Europe SA    ( Nivelles  USA )",
    "country": "United States"
  },
  {
    "id": 173227,
    "name": "BioSource International Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 412481,
    "name": "BioSource Netherlands BV    ( Etten/Leur  USA )",
    "country": "United States"
  },
  {
    "id": 451373,
    "name": "Biospace Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 363794,
    "name": "BioSpec Products Inc    ( Bartlesville OK USA )",
    "country": "United States"
  },
  {
    "id": 451634,
    "name": "BioSphere Medical - France    ( Roissy-Charles-de-Gaulle Cedex  France )",
    "country": "France"
  },
  {
    "id": 451630,
    "name": "BioSphere Medical Inc    ( Rockland MA USA )",
    "country": "United States"
  },
  {
    "id": 454498,
    "name": "BioSpherix Ltd    ( Lacona NY USA )",
    "country": "United States"
  },
  {
    "id": 461196,
    "name": "BioStorage Technologies    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 455667,
    "name": "BioStructures LLC    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 105302,
    "name": "Biosynergy Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 419040,
    "name": "Biosys Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 175069,
    "name": "BioSystems SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 459026,
    "name": "Biotage AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 459033,
    "name": "Biotage LLC    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 101124,
    "name": "Biotec Inc    ( Zeeland MI USA )",
    "country": "United States"
  },
  {
    "id": 454368,
    "name": "Biotec Systems Krems GmbH    ( Krems  Austria )",
    "country": "Austria"
  },
  {
    "id": 153140,
    "name": "Biotec-Fischer GmbH    ( Reiskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 450658,
    "name": "BioTech SE Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456618,
    "name": "Biotech Visioncare Neuva Vida    ( Ahmedabad  India )",
    "country": "India"
  },
  {
    "id": 163011,
    "name": "Biotechni    ( La Ciotat  France )",
    "country": "France"
  },
  {
    "id": 407231,
    "name": "BIOTECK srl    ( Arcugnano (VI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 175052,
    "name": "Biotecnica Instruments SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 175350,
    "name": "Biotecx Laboratories Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 455057,
    "name": "BioTek China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455058,
    "name": "BioTek France    ( Colmar Cedex  France )",
    "country": "France"
  },
  {
    "id": 107683,
    "name": "Biotek Inc    ( Crawfordsville IN USA )",
    "country": "United States"
  },
  {
    "id": 455059,
    "name": "BioTek India    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 455060,
    "name": "BioTek Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455061,
    "name": "BioTek Switzerland    ( Luzern  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455062,
    "name": "BioTek UK    ( Potton  England )",
    "country": "UK"
  },
  {
    "id": 392008,
    "name": "Bioteque America Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 357003,
    "name": "Bioteque Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 176407,
    "name": "Biotest    ( Rockaway NJ USA )",
    "country": "United States"
  },
  {
    "id": 407377,
    "name": "Biotest (Schweiz) AG    ( Rupperswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 407379,
    "name": "Biotest (UK) Ltd    ( Solihull  England )",
    "country": "UK"
  },
  {
    "id": 226215,
    "name": "Biotest AG    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 257301,
    "name": "Biotest Hungaria Kft    ( Toeroekbalint  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 455792,
    "name": "Biotest Medical Corp    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 407378,
    "name": "Biotest Nederland    ( Soest  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 407376,
    "name": "Biotest Pharmazeutika GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 406939,
    "name": "Biotest Sarl    ( Buc Cedex  France )",
    "country": "France"
  },
  {
    "id": 407381,
    "name": "Biotest Seralc NV/SA    ( Kortenberg  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 407380,
    "name": "Biotest srl    ( Trezzano sul Naviglio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 471707,
    "name": "bioTheranostics Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 439398,
    "name": "Biotrace Fred Baker Ltd    ( Runcorn  USA )",
    "country": "United States"
  },
  {
    "id": 439225,
    "name": "Biotrace International plc    ( Bridgend  USA )",
    "country": "United States"
  },
  {
    "id": 439400,
    "name": "Biotrace Maltec ApS    ( Karlslunde  USA )",
    "country": "United States"
  },
  {
    "id": 439399,
    "name": "Biotrace SA    ( Villeneuve-La-Garenne Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 366847,
    "name": "Biotrin International    ( Mount Merrion  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 306029,
    "name": "Biotrin International GmbH    ( Sinsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 306030,
    "name": "Biotrin International Sarl    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 407235,
    "name": "Biotron Co Ltd    ( Kangwondo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 143802,
    "name": "BioTronics Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 139503,
    "name": "BIOTRONIK GmbH & Co    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 102698,
    "name": "BIOTRONIK Inc    ( Lake Oswego OR USA )",
    "country": "United States"
  },
  {
    "id": 390960,
    "name": "BIOTRONIK UK Ltd    ( Bicester  England )",
    "country": "UK"
  },
  {
    "id": 467246,
    "name": "Biotype Diagnostic GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 452494,
    "name": "BioVendor GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452496,
    "name": "BioVendor Laboratories Ltd (China)    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452495,
    "name": "BioVendor Laboratories Ltd (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452356,
    "name": "BioVendor Laboratory Medicine Inc    ( Modrice  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 452493,
    "name": "BioVendor LLC    ( Candler NC USA )",
    "country": "United States"
  },
  {
    "id": 458091,
    "name": "Bioventus LLC    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 441028,
    "name": "BioVeris Corp    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 441029,
    "name": "BioVeris Europe Corp    ( Whitney  USA )",
    "country": "United States"
  },
  {
    "id": 397718,
    "name": "Biovest International Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 458653,
    "name": "BioView Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 458652,
    "name": "BioView Ltd    ( Nes Ziona  Israel )",
    "country": "Israel"
  },
  {
    "id": 451477,
    "name": "BioVigilant Systems Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 283147,
    "name": "BioVisioN GmbH & Co KG    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 407209,
    "name": "BioWhittaker Europe SPRL    ( Verviers  USA )",
    "country": "United States"
  },
  {
    "id": 407210,
    "name": "BioWhittaker UK Ltd    ( Wokingham  USA )",
    "country": "United States"
  },
  {
    "id": 456205,
    "name": "Biox Instruments Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 282961,
    "name": "Biozym Diagnostic GmbH    ( Hessisch Oldendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 459119,
    "name": "BIOZYM HOLDING bv    ( Landgraaf  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 155363,
    "name": "Birchwood Laboratories Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 103716,
    "name": "Bird & Cronin Medical Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 101101,
    "name": "Bird Products Corp    ( Palm Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 183335,
    "name": "Bird Products Corp    ( Bilthoven  USA )",
    "country": "United States"
  },
  {
    "id": 279674,
    "name": "Birkenstock Orthopaedie GmbH    ( Vettelschob  Germany )",
    "country": "Germany"
  },
  {
    "id": 458348,
    "name": "Birkova Products    ( Gothenburg NE USA )",
    "country": "United States"
  },
  {
    "id": 101123,
    "name": "Birtcher Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 285904,
    "name": "BIS GmbH    ( St Ingbert  Germany )",
    "country": "Germany"
  },
  {
    "id": 177655,
    "name": "Bisaco NV    ( Kapelle-Op-Den-Bos  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 306395,
    "name": "Bisanz Medizinische Polster GmbH    ( Gau-Algesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 283936,
    "name": "BISCHOFF Analysentechnik und Geraete GmbH    ( Leonberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 332522,
    "name": "BISCHOFF Chromatography    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 333474,
    "name": "BISCO Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 280262,
    "name": "Bisico    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 357590,
    "name": "Bison Bede Ltd    ( Consett  England )",
    "country": "UK"
  },
  {
    "id": 461509,
    "name": "Bison Elevator Service Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 455800,
    "name": "BISON Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 460536,
    "name": "Bistos America    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 455802,
    "name": "BISTOS Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457054,
    "name": "BiTech Medical Corp    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 461198,
    "name": "BitFlow Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 377771,
    "name": "Bitmos GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 458408,
    "name": "Bitplane AG    ( Zurich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458409,
    "name": "Bitplane USA    ( South Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 106284,
    "name": "Bits Ltd    ( Northport NY USA )",
    "country": "United States"
  },
  {
    "id": 280891,
    "name": "Biwece Steinruecke GmbH    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 279445,
    "name": "Bizerba GmbH Co KG    ( Balingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456144,
    "name": "BJ ZHF Panther Medical Equipment Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456560,
    "name": "BK Medical Systems Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 453028,
    "name": "BK Precision Corp    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 455803,
    "name": "BL Tech Co Ltd    ( Chuncheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999562,
    "name": "Black & Decker    ( New Britain CT USA )",
    "country": "United States"
  },
  {
    "id": 453314,
    "name": "Black Box Corp    ( Lawrence PA USA )",
    "country": "United States"
  },
  {
    "id": 999734,
    "name": "Black Box Corp.    ( Lawrence PA USA )",
    "country": "USA"
  },
  {
    "id": 454905,
    "name": "Black Diamond Video    ( Point Richmond CA USA )",
    "country": "United States"
  },
  {
    "id": 272812,
    "name": "BlackBerry Technologies    ( Pottstown PA USA )",
    "country": "United States"
  },
  {
    "id": 999539,
    "name": "Blackenship Amplification    (   USA )",
    "country": "United States"
  },
  {
    "id": 330833,
    "name": "Blackhawk BioSystems Inc    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 459307,
    "name": "Blackmaple Group    ( Weston FL USA )",
    "country": "United States"
  },
  {
    "id": 454350,
    "name": "Blackrock Microsystems    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 332685,
    "name": "Blackstone Medical Inc    ( Springfield MA USA )",
    "country": "United States"
  },
  {
    "id": 408131,
    "name": "Blackstone-Ney Ultrasonics    ( Jamestown NY USA )",
    "country": "United States"
  },
  {
    "id": 451074,
    "name": "Blake Medical Inc    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 153142,
    "name": "Blanco GmbH & Co KG    ( Oberderdingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284102,
    "name": "Blase GmbH & Co KG    ( Luebbecke  Germany )",
    "country": "Germany"
  },
  {
    "id": 150950,
    "name": "Blease Medical Equipment Ltd    ( Chesham  USA )",
    "country": "United States"
  },
  {
    "id": 151383,
    "name": "Bledsoe Brace Systems    ( Grand Prairie TX USA )",
    "country": "United States"
  },
  {
    "id": 392414,
    "name": "Blickman Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 458116,
    "name": "Blink Medical Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 454820,
    "name": "BLJ Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453313,
    "name": "Block Imaging International Inc    ( Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 170250,
    "name": "Block Scientific Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 107518,
    "name": "Blockhouse Co Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 459218,
    "name": "Blodgett Corp    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 184850,
    "name": "Blood Bank Computer Systems    ( Auburn WA USA )",
    "country": "United States"
  },
  {
    "id": 462887,
    "name": "Blood Centers of the Pacific    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 105642,
    "name": "Bloom Assoc Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 105529,
    "name": "Bloomex International Inc    ( Elmwood Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 371733,
    "name": "Blossom Disposable Products    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 452042,
    "name": "Blosson/Mexpo International Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 102849,
    "name": "Blu-Ray    ( Middletown CT USA )",
    "country": "United States"
  },
  {
    "id": 103718,
    "name": "Blue Bell Bio-Medical    ( Van Wert OH USA )",
    "country": "United States"
  },
  {
    "id": 457810,
    "name": "Blue Belt Technologies Inc    ( Puttsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 322889,
    "name": "Blue Chip Medical Products Inc    ( Suffern NY USA )",
    "country": "United States"
  },
  {
    "id": 460896,
    "name": "Blue Coat Systems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 456206,
    "name": "Blue Cross Bio-Medical Beijing Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 163241,
    "name": "Blue Cross Emergency Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 461160,
    "name": "Blue Elm Co LLC    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 455113,
    "name": "Blue Healer    ( Noosa Heads  Australia )",
    "country": "Australia"
  },
  {
    "id": 461154,
    "name": "Blue Jay Consulting LLC    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 461205,
    "name": "Blue Moon Manufacturing Inc    ( Lakeville MN USA )",
    "country": "United States"
  },
  {
    "id": 457055,
    "name": "Blue Ox Medical Technologies    ( Cornelius NC USA )",
    "country": "United States"
  },
  {
    "id": 330343,
    "name": "Blue Ridge Medical Imaging    ( Salem VA USA )",
    "country": "United States"
  },
  {
    "id": 106131,
    "name": "Blue Ridge X-Ray Co Inc    ( Arden NC USA )",
    "country": "United States"
  },
  {
    "id": 330226,
    "name": "Blue Sky Trading Co Inc    ( Ciba City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 107976,
    "name": "Blue Spring Corp    ( Port Lavaca TX USA )",
    "country": "United States"
  },
  {
    "id": 999633,
    "name": "Blue Torch Medical Technologies    ( Ashland MA USA )",
    "country": "United States"
  },
  {
    "id": 461130,
    "name": "Blue Torch Medical Technologies Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 173276,
    "name": "Blue Wave Ultrasonics    ( Davenport IA USA )",
    "country": "United States"
  },
  {
    "id": 418141,
    "name": "Blueair AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 338665,
    "name": "Blueair Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 466410,
    "name": "BlueBin Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 464157,
    "name": "BlueCat Networks    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 450894,
    "name": "Blueendo    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 461143,
    "name": "BlueLight Analytics Inc    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 450988,
    "name": "Bluesky Medical    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 367014,
    "name": "Bluestar Enterprises Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 457056,
    "name": "Bluestone Diagnostics Inc    ( Thornwood NY USA )",
    "country": "United States"
  },
  {
    "id": 285541,
    "name": "Blumberg GmbH & Co KG    ( Ratingen-Lintorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 413233,
    "name": "BM Browne (NI) Ltd    ( Belfast  USA )",
    "country": "United States"
  },
  {
    "id": 455920,
    "name": "BM Polyco Ltd    ( Enfield  England )",
    "country": "UK"
  },
  {
    "id": 451702,
    "name": "BM Sanitas    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455691,
    "name": "BMA for Design & Industry Inc    ( Giza  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 456207,
    "name": "BMC Medical Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458876,
    "name": "BMC Mfg    ( Muskegon MI USA )",
    "country": "United States"
  },
  {
    "id": 453953,
    "name": "BMDiTUTA Healthcare Pty Ltd    ( North Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 454153,
    "name": "BMEYE bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453380,
    "name": "BMEYE Cardiovascular Monitoring    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 452059,
    "name": "BMG Labtech Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 457131,
    "name": "BMG LABTECH Pty Ltd    ( Mornington  Australia )",
    "country": "Australia"
  },
  {
    "id": 285211,
    "name": "BMG Labtechnologies Dr Gurath GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452881,
    "name": "BMI Biomedical International srl    ( Curno (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 999777,
    "name": "BMK MEDICAL    (   USA )",
    "country": "United States"
  },
  {
    "id": 457345,
    "name": "BML Basic Inc    ( Paragould AR USA )",
    "country": "United States"
  },
  {
    "id": 272674,
    "name": "BMM Weston Ltd    ( Faversham  England )",
    "country": "UK"
  },
  {
    "id": 451126,
    "name": "bms Critical Care Ltd    ( Blackburn  England )",
    "country": "UK"
  },
  {
    "id": 238893,
    "name": "BMT Messtechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 454196,
    "name": "BMX Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 461521,
    "name": "BNA Software    ( Arlington VA USA )",
    "country": "United States"
  },
  {
    "id": 299408,
    "name": "BNFL    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 450907,
    "name": "BNI Solutions LLC    ( State College PA USA )",
    "country": "United States"
  },
  {
    "id": 465048,
    "name": "BoardVantage, Inc.    ( Menlo Park 43 USA )",
    "country": "United States"
  },
  {
    "id": 401087,
    "name": "BOC Bangladesh Ltd    ( Dahaka  Bangladesh )",
    "country": "Bangladesh"
  },
  {
    "id": 401088,
    "name": "BOC Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451016,
    "name": "BOC Edwards    ( Crawley  USA )",
    "country": "United States"
  },
  {
    "id": 285571,
    "name": "BOC Gases (Japan)    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 401359,
    "name": "BOC Gases (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 401360,
    "name": "BOC Gases (Southern Africa)    ( Johannesburg  USA )",
    "country": "United States"
  },
  {
    "id": 324734,
    "name": "BOC Gases (UK)    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 232566,
    "name": "BOC Gases (USA)    ( Murray Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 401086,
    "name": "BOC Gases Aruba NV    ( Oranjestad  Aruba )",
    "country": "Aruba"
  },
  {
    "id": 305623,
    "name": "BOC Gases Australia    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 401090,
    "name": "BOC Gases Caribbean NV    ( Curacao  Netherlands Antilles )",
    "country": "Netherlands Antilles"
  },
  {
    "id": 401361,
    "name": "BOC Gases de Venezuela CA    ( Caracas  Venezuela )",
    "country": "Venezuela"
  },
  {
    "id": 401091,
    "name": "BOC Gases Indonesia    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 401092,
    "name": "BOC Gases Ireland Ltd    ( Dublin 12  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 401355,
    "name": "BOC Gases Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 401356,
    "name": "BOC Gases New Zealand Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 401362,
    "name": "BOC Gases Zimbabwe (Pvt) Ltd    ( Harare  Zimbabwe )",
    "country": "Zimbabwe"
  },
  {
    "id": 401358,
    "name": "BOC Gazy Sp z o o    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 401089,
    "name": "BOC India Ltd    ( Calcutta  India )",
    "country": "India"
  },
  {
    "id": 401354,
    "name": "BOC Kenya Ltd    ( Nairobi  Kenya )",
    "country": "Kenya"
  },
  {
    "id": 401357,
    "name": "BOC Pakistan Ltd    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 451502,
    "name": "Boca Medical Products    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 287205,
    "name": "Bochem Instrumente GmbH    ( Weilburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 165341,
    "name": "Bodan-Waagen H U Schneider    ( Altnau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 282333,
    "name": "Bode Chemie GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456360,
    "name": "Boditech Med Inc    ( Chuncheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 102592,
    "name": "Body Care Inc    ( Ball Ground GA USA )",
    "country": "United States"
  },
  {
    "id": 323849,
    "name": "Body Clock Health Care Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 999814,
    "name": "Body Masters Sports Industries    ( Rayne LA USA )",
    "country": "United States"
  },
  {
    "id": 328769,
    "name": "Body Support Systems Inc    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 227951,
    "name": "Body Therapeutics    ( Lake Elsinore CA USA )",
    "country": "United States"
  },
  {
    "id": 451546,
    "name": "Body-Solid Inc    ( Forest Park IL USA )",
    "country": "United States"
  },
  {
    "id": 461522,
    "name": "BodyAlign LLC    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 999450,
    "name": "BODYCRAFT    ( Lewis Center OH USA )",
    "country": "United States"
  },
  {
    "id": 999784,
    "name": "Bodyguard Fitness    ( St-Georges QC Canada )",
    "country": "Canada"
  },
  {
    "id": 107859,
    "name": "Bodyline Comfort Systems    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 451018,
    "name": "BodyMedia Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 232365,
    "name": "Bodystat Ltd    ( Douglas  Great Britain )",
    "country": "UK"
  },
  {
    "id": 415456,
    "name": "Bodystat USA    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 169576,
    "name": "Boeckeler Instruments Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 102409,
    "name": "Boehm Surgical Instrument Corp    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 457917,
    "name": "Boehringer Ingelheim GmbH    ( Ingelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 102227,
    "name": "Boehringer Laboratories Inc    ( Phoenixville PA USA )",
    "country": "United States"
  },
  {
    "id": 453547,
    "name": "Boehringer Wound Systems LLC    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 261930,
    "name": "Boekel Scientific Inc    ( Feasterville PA USA )",
    "country": "United States"
  },
  {
    "id": 172857,
    "name": "Boesl Medizintechnik GmbH    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103401,
    "name": "Bogen Communications Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 305596,
    "name": "Bohle Medical Supplies    ( Brookvale  Australia )",
    "country": "Australia"
  },
  {
    "id": 172259,
    "name": "Bohrer Ltd    ( Migdal Ha'Emek  USA )",
    "country": "United States"
  },
  {
    "id": 452311,
    "name": "Bohus Biotech AB    ( Stromstad  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 407109,
    "name": "Boin Medica Co Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 156971,
    "name": "Bolle    ( Oyonnax Cedex  France )",
    "country": "France"
  },
  {
    "id": 283225,
    "name": "Bollmann-Filter-Gesellschaft mbH    ( Itzehoe  Germany )",
    "country": "Germany"
  },
  {
    "id": 322892,
    "name": "Bolt Systems Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 413009,
    "name": "Bolton Medical Inc    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 457724,
    "name": "Bolton Metal Products Co    ( Bellefonte PA USA )",
    "country": "United States"
  },
  {
    "id": 398100,
    "name": "Bolton Surgical Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 461523,
    "name": "Bomgar Corp    ( Ridgeland MO USA )",
    "country": "United States"
  },
  {
    "id": 451623,
    "name": "BOMImed Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 455034,
    "name": "BOMImed Inc (US)    ( Doylestown PA USA )",
    "country": "United States"
  },
  {
    "id": 331000,
    "name": "Bonacher GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458309,
    "name": "BondTech Corp    ( Somerset KY USA )",
    "country": "United States"
  },
  {
    "id": 451360,
    "name": "Bone Bank Allografts    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 301808,
    "name": "Bonutti Research Inc    ( Effingham IL USA )",
    "country": "United States"
  },
  {
    "id": 455237,
    "name": "Boots Opticians    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 391677,
    "name": "Boots the Chemists    ( Nottingham  USA )",
    "country": "United States"
  },
  {
    "id": 451075,
    "name": "Boracchia + Assoc    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 451621,
    "name": "BOREAL Laboratories    ( St Catherines ON Canada )",
    "country": "Canada"
  },
  {
    "id": 102410,
    "name": "Boren Mfg Co    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 293731,
    "name": "Borer Chemie AG    ( Zuchwil/Solothurn  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999820,
    "name": "BORG WARNER    (   USA )",
    "country": "United States"
  },
  {
    "id": 292896,
    "name": "Borla Industrie SpA    ( Moncalieri TO  Italy )",
    "country": "Italy"
  },
  {
    "id": 462716,
    "name": "Borland Software Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 321024,
    "name": "Borolabs Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 999431,
    "name": "Bortec Biomedical Ltd.    (   USA )",
    "country": "United States"
  },
  {
    "id": 283251,
    "name": "Bosch & Sohn GmbH und Co    ( Jungingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458102,
    "name": "Bosch Healthcare    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 999669,
    "name": "Bosch Security Systems    ( Fairport NY USA )",
    "country": "United States"
  },
  {
    "id": 460713,
    "name": "Bosch Security Systems Inc    ( Fairport NY USA )",
    "country": "United States"
  },
  {
    "id": 306154,
    "name": "Bosco Medical Australia Pty Ltd    ( Wynnum  Australia )",
    "country": "Australia"
  },
  {
    "id": 401746,
    "name": "Boss Instruments Ltd    ( Earlysville VA USA )",
    "country": "United States"
  },
  {
    "id": 285928,
    "name": "Bossewerk Ringelheim GmbH & Co KG    ( Salzgitter  USA )",
    "country": "United States"
  },
  {
    "id": 335353,
    "name": "Bosshard Pty Ltd    ( Richmond  USA )",
    "country": "United States"
  },
  {
    "id": 457440,
    "name": "Boston Brace International    ( Avon MA USA )",
    "country": "United States"
  },
  {
    "id": 330084,
    "name": "Boston Electronics Corp    ( Brookline MA USA )",
    "country": "United States"
  },
  {
    "id": 107062,
    "name": "Boston Medical Corp    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 108739,
    "name": "Boston Medical Products Inc    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 458349,
    "name": "Boston Micromachines Corp    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 451637,
    "name": "Boston Scientific    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431655,
    "name": "Boston Scientific - Cardiovascular Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431656,
    "name": "Boston Scientific - Cardiovascular Group    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 431657,
    "name": "Boston Scientific - Cardiovascular Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431658,
    "name": "Boston Scientific - Cardiovascular Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431659,
    "name": "Boston Scientific - Cardiovascular Group    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 431660,
    "name": "Boston Scientific - Cardiovascular Group    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 431639,
    "name": "Boston Scientific - Endosurgery Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431653,
    "name": "Boston Scientific - Endosurgery Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 431654,
    "name": "Boston Scientific - Endosurgery Group    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 392168,
    "name": "Boston Scientific (Middle East)    ( Beirut  Lebanon )",
    "country": "Lebanon"
  },
  {
    "id": 392185,
    "name": "Boston Scientific (Portugal)    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 392188,
    "name": "Boston Scientific AG    ( Solothurn  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 371294,
    "name": "Boston Scientific Argentina SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 362032,
    "name": "Boston Scientific Asia Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 392183,
    "name": "Boston Scientific bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392178,
    "name": "Boston Scientific Ceska Republika sro    ( Praha 5  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 392172,
    "name": "Boston Scientific Chile Ltda    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 392173,
    "name": "Boston Scientific Colombia Ltda    ( Bogota  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 107135,
    "name": "Boston Scientific Corp    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 392174,
    "name": "Boston Scientific de Mexico SA de CV    ( Benito Juarez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 392171,
    "name": "Boston Scientific do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 392176,
    "name": "Boston Scientific Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 392166,
    "name": "Boston Scientific Hong Kong Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392186,
    "name": "Boston Scientific Iberica SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 342199,
    "name": "Boston Scientific International (France)    ( Nanterre Cedex  France )",
    "country": "France"
  },
  {
    "id": 392165,
    "name": "Boston Scientific International BV (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 391905,
    "name": "Boston Scientific Ireland Ltd    ( Clonmel  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 342198,
    "name": "Boston Scientific Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 392164,
    "name": "Boston Scientific Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 392175,
    "name": "Boston Scientific Ltd (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 392189,
    "name": "Boston Scientific Ltd (UK)    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 392181,
    "name": "Boston Scientific Magyarorszag Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 392180,
    "name": "Boston Scientific Medizintechnik GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454729,
    "name": "Boston Scientific Neuromodulation Corp    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 392187,
    "name": "Boston Scientific Nordic A/B    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 392184,
    "name": "Boston Scientific Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 392167,
    "name": "Boston Scientific Pty Ltd    ( Mascot  Australia )",
    "country": "Australia"
  },
  {
    "id": 392182,
    "name": "Boston Scientific SpA    ( Milan  Italy )",
    "country": "Italy"
  },
  {
    "id": 392170,
    "name": "Boston Scientific Tip Gerecleri Ltd Sti    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 345596,
    "name": "Boston Scientific/Microvasive    (   USA )",
    "country": "United States"
  },
  {
    "id": 458534,
    "name": "Boston Software Systems    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 461126,
    "name": "BOSTONtec    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 455211,
    "name": "Bradford Products LLC    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 457024,
    "name": "Bosungmeditech Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458360,
    "name": "Bottomline Technologies    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 391797,
    "name": "Boule Diagnostics AB    ( Huddinge  USA )",
    "country": "United States"
  },
  {
    "id": 384266,
    "name": "Boule Medical AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 426353,
    "name": "Bound Tree Medical LLC    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 455832,
    "name": "Bountex Knitting Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 359835,
    "name": "Bovie Medical Corp    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 439931,
    "name": "BOWA-electronic GmbH    ( Gomaringen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451498,
    "name": "Bowen Surgical Inc    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 174553,
    "name": "Bowers Medical Supplies Ltd    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456208,
    "name": "Bowin Medical Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107592,
    "name": "BOWMAN Mfg Co Inc    ( Arlington WA USA )",
    "country": "United States"
  },
  {
    "id": 461121,
    "name": "Boyce Scientific Inc    ( Gray Summit MO USA )",
    "country": "United States"
  },
  {
    "id": 105763,
    "name": "Boyd Industries Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 459115,
    "name": "BPCTi    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 352919,
    "name": "BPG Medical Design Ltd    ( Leicester  USA )",
    "country": "United States"
  },
  {
    "id": 107229,
    "name": "BPI (Brain Power Inc)    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 450627,
    "name": "BPI Medical Sales & Service Inc    ( Fife WA USA )",
    "country": "United States"
  },
  {
    "id": 150998,
    "name": "BPR Medical    ( Mansfield  England )",
    "country": "UK"
  },
  {
    "id": 453111,
    "name": "BpTRU Medical Devices    ( Coquitlam BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456540,
    "name": "BpTRU Medical Devices Div MFC Industrial Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 285955,
    "name": "BPV Apo-Plan GmbH    ( Schallstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 458493,
    "name": "BR Surgical LLC    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 280968,
    "name": "Brabender Messtechnik KG    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455100,
    "name": "BRACCO AMT Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455102,
    "name": "BRACCO AMT Inc (UK)    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 455101,
    "name": "BRACCO AMT Inc (USA)    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 415922,
    "name": "Bracco Diagnostics Inc (Canada)    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 250711,
    "name": "Bracco Diagnostics Inc (US)    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 415932,
    "name": "Bracco Far East Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415920,
    "name": "Bracco Imaging bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458636,
    "name": "Bracco Injeneering SA    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415923,
    "name": "Bracco International BV (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 452069,
    "name": "Bracco Oesterreich GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 251720,
    "name": "Bracco SpA    ( San Donato Milanese (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 391343,
    "name": "Bracco Suisse SA    ( Manno  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415921,
    "name": "Bracco UK Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 452068,
    "name": "Bracco-Altana Pharma GmbH    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 415919,
    "name": "Bracco-Byk Gulden GmbH    ( Konstanz  USA )",
    "country": "United States"
  },
  {
    "id": 415930,
    "name": "Bracco-Eisai Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 107977,
    "name": "Braden Shielding Systems    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 356161,
    "name": "Bradley Co    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 105889,
    "name": "Bradley Corp    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 360382,
    "name": "Bradley International Group Europe    ( Zoetermeer  USA )",
    "country": "United States"
  },
  {
    "id": 103402,
    "name": "Brady Corp    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 356887,
    "name": "Braebon Medical Corp    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 330346,
    "name": "Braemar Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 454031,
    "name": "Braemar Inc    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 452321,
    "name": "Braemed Ltd    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 452340,
    "name": "BRAHMS Aktiengesellschaft    ( Hennigsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 453460,
    "name": "BRAHMS Aktiengesellschaft    ( Henningsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 453459,
    "name": "BRAHMS USA Inc    ( Annapolis MD USA )",
    "country": "United States"
  },
  {
    "id": 174191,
    "name": "Braile Biomedica Industria Commercio Representacoe    ( Sao Jose do Rio Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 376666,
    "name": "Brain Power International    ( Rugby  England )",
    "country": "UK"
  },
  {
    "id": 458305,
    "name": "Brain Vision LLC    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 451076,
    "name": "Brainerd LLC of North Carolina    ( Dunn NC USA )",
    "country": "United States"
  },
  {
    "id": 178227,
    "name": "BrainLAB AG    ( Feldkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 178228,
    "name": "BrainLAB Inc    ( Westchester IL USA )",
    "country": "United States"
  },
  {
    "id": 413506,
    "name": "BrainLAB Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 103100,
    "name": "Braintree Scientific Inc    ( Braintree MA USA )",
    "country": "United States"
  },
  {
    "id": 151215,
    "name": "Braintronics bv    ( Almere  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451457,
    "name": "BrainZ Instruments Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 409972,
    "name": "Bralin Mfg Inc    ( La Grange GA USA )",
    "country": "United States"
  },
  {
    "id": 464113,
    "name": "Branan Medical Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 232366,
    "name": "Brand GmbH & Co    ( Wertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 453607,
    "name": "Brand Promotions    ( Rosebery  Australia )",
    "country": "Australia"
  },
  {
    "id": 462662,
    "name": "Brandel Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 282679,
    "name": "Brandes & Diesing    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 158064,
    "name": "Brandon Medical Co Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 101102,
    "name": "Brandt Industries Inc    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 451073,
    "name": "BrandTech Scientific Inc    ( Essex CT USA )",
    "country": "United States"
  },
  {
    "id": 185760,
    "name": "Brandywine Imaging Inc    ( Lincoln University PA USA )",
    "country": "United States"
  },
  {
    "id": 439289,
    "name": "Branson Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 401847,
    "name": "Branson Ultrasonics Asia/Pacific    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439288,
    "name": "Branson Ultrasonics Corp (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 401849,
    "name": "Branson Ultrasonics Corp (Japan)    ( Atsugi  Japan )",
    "country": "Japan"
  },
  {
    "id": 401846,
    "name": "Branson Ultrasonics Corp (Switzerland)    ( Carouge/Geneva  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 102494,
    "name": "Branson Ultrasonics Corp (US)    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 439090,
    "name": "Brasseler Canada Inc    ( Montreal PQ USA )",
    "country": "United States"
  },
  {
    "id": 439089,
    "name": "Brasseler USA    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 286533,
    "name": "Braun GmbH & Co Stuhl & Tischefabrik    ( Bad Rappenau  USA )",
    "country": "United States"
  },
  {
    "id": 102007,
    "name": "Braun Industries Inc    ( Van Wert OH USA )",
    "country": "United States"
  },
  {
    "id": 452140,
    "name": "Braun Inertgas-Systeme GmbH    ( Garching  Germany )",
    "country": "Germany"
  },
  {
    "id": 108140,
    "name": "Braun Northwest Inc    ( Chehalis WA USA )",
    "country": "United States"
  },
  {
    "id": 451078,
    "name": "Bravado Designs    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 174192,
    "name": "Brave Ind Aparelhos Eletro-Medicos Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 459352,
    "name": "Bravolight    ( Lutz FL USA )",
    "country": "United States"
  },
  {
    "id": 451665,
    "name": "Breakaway Imaging LLC    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 102008,
    "name": "Breakaway Inc    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 227224,
    "name": "BREAS Medical AB    ( Moelnlycke  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453723,
    "name": "BREAS Medical bvba    ( Willebroek  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 359616,
    "name": "BREAS Medical GmbH    ( Herrsching  Germany )",
    "country": "Germany"
  },
  {
    "id": 359617,
    "name": "BREAS Medical Ltd    ( Bordon  USA )",
    "country": "United States"
  },
  {
    "id": 359619,
    "name": "BREAS Medical Sarl    ( Saint-Priest  France )",
    "country": "France"
  },
  {
    "id": 401522,
    "name": "BREAS Medical SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 271456,
    "name": "Breasy Medical Equipment Ltd    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 455569,
    "name": "Breathe E-Z Systems Inc    ( Shawnee Mission KS USA )",
    "country": "United States"
  },
  {
    "id": 458747,
    "name": "Breathe Technologies Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 473838,
    "name": "BREC Inc    ( Bryan TX USA )",
    "country": "United States"
  },
  {
    "id": 393158,
    "name": "Breckle GmbH    ( Northeim  Germany )",
    "country": "Germany"
  },
  {
    "id": 101119,
    "name": "Brecon Knitting Mill Inc    ( Talladega AL USA )",
    "country": "United States"
  },
  {
    "id": 451718,
    "name": "Bredal    ( Asker  USA )",
    "country": "United States"
  },
  {
    "id": 286118,
    "name": "Bredent    ( Senden  Germany )",
    "country": "Germany"
  },
  {
    "id": 152748,
    "name": "Breg Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 377721,
    "name": "Bregas AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401036,
    "name": "BREMED Italia srl    ( Todi (PG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455734,
    "name": "Bremed Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 171147,
    "name": "Brennen Medical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 416025,
    "name": "Brenniston First Aid & Workplace Safety    ( Richmond  Australia )",
    "country": "Australia"
  },
  {
    "id": 194766,
    "name": "Brentwood Medical Products Asia/Pacific    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 104119,
    "name": "Bretford Inc    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 361912,
    "name": "Bretford Mfg Ltd    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 157896,
    "name": "Brethren Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 353315,
    "name": "Breton Industries Inc    ( Amsterdam NY USA )",
    "country": "United States"
  },
  {
    "id": 399755,
    "name": "Brevetti van Berkel SpA    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 105383,
    "name": "Brevis Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 331077,
    "name": "Brickell Research Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 451717,
    "name": "Bridge Medical    ( Bangor  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 999733,
    "name": "Bridge Power Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 455427,
    "name": "Bridge to Life Ltd    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 461117,
    "name": "Bridge-Tech Medical Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 458547,
    "name": "BridgeHead Software Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 457287,
    "name": "Bridgepoint Medical Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 458730,
    "name": "BridgeWave Communications Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 104597,
    "name": "Briggs Corp    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 456927,
    "name": "Briggs Healthcare    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 465156,
    "name": "Bright Horizons Family Solutions LLC    ( Watertown 20 USA )",
    "country": "United States"
  },
  {
    "id": 148972,
    "name": "Bright Instrument Co Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 457865,
    "name": "Brightleaf Imaging LLC    ( Murphy NC USA )",
    "country": "United States"
  },
  {
    "id": 465139,
    "name": "Brightwake    ( Kirkby-in-Ashfield  England )",
    "country": "UK"
  },
  {
    "id": 335355,
    "name": "Brimar Distributing    ( McMahons Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 305969,
    "name": "Brinkmann Instruments (Canada) Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103257,
    "name": "Brinkmann Instruments Inc    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 284559,
    "name": "Brinkmann Medical GmbH    ( Muelheim am der Ruhr  USA )",
    "country": "United States"
  },
  {
    "id": 105013,
    "name": "Bristol Babcock Inc    ( Watertown CT USA )",
    "country": "United States"
  },
  {
    "id": 455289,
    "name": "Bristol Maid Hospital Metalcraft Ltd    ( Blandford Forum  England )",
    "country": "UK"
  },
  {
    "id": 108478,
    "name": "Bristol-Myers Squibb Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 399274,
    "name": "Bristol-Myers Squibb Co    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 101116,
    "name": "Bristoline Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 183017,
    "name": "BRIT Systems    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 456552,
    "name": "Brite Inc    ( Holbrook MA USA )",
    "country": "United States"
  },
  {
    "id": 457998,
    "name": "Brite Sources USA    ( Carpentersville IL USA )",
    "country": "United States"
  },
  {
    "id": 306886,
    "name": "Brittan Healthcare Group (Pty) Ltd    ( Isando  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 325031,
    "name": "Broadaker Co Ltd    ( St Samsons  Channel Islands Great Britain )",
    "country": "Channel Islands UK"
  },
  {
    "id": 461228,
    "name": "BroadcastVision Entertainment (BVE)    ( Longmont CO USA )",
    "country": "United States"
  },
  {
    "id": 453021,
    "name": "Broadsound Corp    ( Jupei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 192705,
    "name": "Broadview Instrumentation Services    ( Valley View OH USA )",
    "country": "United States"
  },
  {
    "id": 106400,
    "name": "Broadwest Corp    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 458793,
    "name": "Brocade Communications Systems Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 305978,
    "name": "Brock & Michelsen Instrumenter A/S    ( Birkeroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 322895,
    "name": "Broda Seating    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 161816,
    "name": "Broda Seating (Canada)    ( Kitchener ON Canada )",
    "country": "Canada"
  },
  {
    "id": 330348,
    "name": "Brodersen Instrument Co Inc    ( Valencia PA USA )",
    "country": "United States"
  },
  {
    "id": 289392,
    "name": "Broen A/S    ( Assens  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454964,
    "name": "Bromakin Wheelchairs Ltd    ( Loughborough  England )",
    "country": "UK"
  },
  {
    "id": 279898,
    "name": "Brompton GmbH    ( Berg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458301,
    "name": "Broncus Medical Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 408717,
    "name": "Brookdale    ( Vancouver BC USA )",
    "country": "United States"
  },
  {
    "id": 459308,
    "name": "Brookhaven Instruments Corp    ( Holtsville NY USA )",
    "country": "United States"
  },
  {
    "id": 999399,
    "name": "Brooklands Inc.    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 104117,
    "name": "Brooklyn Thermometer Co Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 458412,
    "name": "Brooks Automation Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 170252,
    "name": "Brooks Medical Systems Inc    ( Lynnwood WA USA )",
    "country": "United States"
  },
  {
    "id": 105890,
    "name": "Brooks Power Systems    ( Mercerville NJ USA )",
    "country": "United States"
  },
  {
    "id": 457521,
    "name": "Brosco International Inc    ( Mesquite TX USA )",
    "country": "United States"
  },
  {
    "id": 473839,
    "name": "Brother International    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 999770,
    "name": "Brother International Corporation    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 107945,
    "name": "Brotherston Homecare Inc    ( Bensalem PA USA )",
    "country": "United States"
  },
  {
    "id": 165563,
    "name": "Brouwer AG    ( Littau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 322896,
    "name": "Brown Engineering Corp    ( Westhampton MA USA )",
    "country": "United States"
  },
  {
    "id": 102495,
    "name": "Brown Medical Industries    ( Spirit Lake IA USA )",
    "country": "United States"
  },
  {
    "id": 339495,
    "name": "Bruce Medical Supply    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 460776,
    "name": "Bruce Technologies Inc    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 465148,
    "name": "Brucken's Inc    ( Evansville 40 USA )",
    "country": "United States"
  },
  {
    "id": 346016,
    "name": "Bruder Healthcare Co    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 153156,
    "name": "Brudermueller GmbH    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 187157,
    "name": "Bruel & Kjaer    (   USA )",
    "country": "United States"
  },
  {
    "id": 474846,
    "name": "Bruel & Kjaer Sound & Vibration Measurement A/S    ( Naerum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 285448,
    "name": "Bruins Instruments    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 289517,
    "name": "Bruker AXS GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 439961,
    "name": "Bruker BioSpin Corp    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 439962,
    "name": "Bruker BioSpin GmbH    ( Rheinstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 458591,
    "name": "Bruker Corp    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 428662,
    "name": "Bruker Daltonics    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 428664,
    "name": "Bruker Daltonics bv    ( Wormer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 332038,
    "name": "Bruker Daltonics GmbH (Switzerland)    ( Faellanden  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391140,
    "name": "Bruker Daltonics Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 428717,
    "name": "Bruker Daltonics Inc (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 391141,
    "name": "Bruker Daltonics Ltd    ( Coventry  England )",
    "country": "UK"
  },
  {
    "id": 174555,
    "name": "Bruker Daltonics Ltd (Canada)    ( East Milton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 428663,
    "name": "Bruker Daltonics Pty Ltd    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 428715,
    "name": "Bruker Daltonics SA (Pty) Ltd    ( Cramerview  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 435456,
    "name": "Bruker Daltonics Scandinavia AB (Denmark)    ( Frederikssund  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 428713,
    "name": "Bruker Daltonics Scandinavia AB (Sweden)    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 428716,
    "name": "Bruker Daltonics Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 428714,
    "name": "Bruker Daltonics SPRL BvBa    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 428660,
    "name": "Bruker Daltonics srl    ( Macerata (MC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 391142,
    "name": "Bruker Daltonik GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 330946,
    "name": "Bruker Daltonique    ( Wissembourg Cedex  France )",
    "country": "France"
  },
  {
    "id": 428661,
    "name": "Bruker Espanola SA    ( San Fernando de Henares  Spain )",
    "country": "Spain"
  },
  {
    "id": 435455,
    "name": "Bruker India Scientific Pvt Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 391138,
    "name": "Bruker Medical GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 153159,
    "name": "Bruker Medizintechnik GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457747,
    "name": "Bruker Nano Analytics    ( Ewing NJ USA )",
    "country": "United States"
  },
  {
    "id": 435461,
    "name": "Bruker Southeast Asia    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 462984,
    "name": "brumaba USA    ( Downingtown PA USA )",
    "country": "United States"
  },
  {
    "id": 306307,
    "name": "Brumaba-Liegen    ( Wolfratshausen  USA )",
    "country": "United States"
  },
  {
    "id": 279781,
    "name": "Brunie-Software GmbH    ( Bad Zwischenahn  Germany )",
    "country": "Germany"
  },
  {
    "id": 999477,
    "name": "C.F. ELECTRONICS, INC.    ( LARAMIE WY USA )",
    "country": "United States"
  },
  {
    "id": 108404,
    "name": "Bruno Independent Living Aids Inc    ( Oconomowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 360372,
    "name": "Bruno Mobility Europe    ( Lutterworth  USA )",
    "country": "United States"
  },
  {
    "id": 104887,
    "name": "Bryan Corp    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 458044,
    "name": "Bryan Medical    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 102135,
    "name": "Bryant Electric    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 101096,
    "name": "Brymill Cryogenic Systems    ( Ellington CT USA )",
    "country": "United States"
  },
  {
    "id": 450504,
    "name": "Brymill Cryogenic Systems (UK) Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 439992,
    "name": "Bryton Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 104428,
    "name": "BSD Medical Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 415972,
    "name": "BSD Technologies International    ( Cooperplains  USA )",
    "country": "United States"
  },
  {
    "id": 397711,
    "name": "BSN Jobst Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 397710,
    "name": "BSN Medical GmbH & Co KG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457470,
    "name": "BSN-JOBST GmbH    ( Emmerich  Germany )",
    "country": "Germany"
  },
  {
    "id": 178250,
    "name": "BT Medical Co Inc    ( Bridgeport PA USA )",
    "country": "United States"
  },
  {
    "id": 442222,
    "name": "BTE Electronics SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 441272,
    "name": "BTE Technologies    ( Hanover MD USA )",
    "country": "United States"
  },
  {
    "id": 457602,
    "name": "BTGases    ( Maidstone  England )",
    "country": "UK"
  },
  {
    "id": 458063,
    "name": "BTI Biotechnology Institute    ( Vitoria-Gasteiz (Alava)  Spain )",
    "country": "Spain"
  },
  {
    "id": 465019,
    "name": "BTL Industries Inc    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 393126,
    "name": "BTL Medical Technologies    ( Praha 6  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 459314,
    "name": "BTS Bioengineering Corp    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 391893,
    "name": "Bu Heung Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 306158,
    "name": "Buchanan Advanced Composites    ( Toowoomba  Australia )",
    "country": "Australia"
  },
  {
    "id": 458794,
    "name": "BUCHI Corp    ( New Castle DE USA )",
    "country": "United States"
  },
  {
    "id": 286672,
    "name": "Buchmann Medical Care & Service GbmH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105181,
    "name": "Buck Scientific Inc    ( East Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 104125,
    "name": "Budget Buddy Co Inc    ( Belton MO USA )",
    "country": "United States"
  },
  {
    "id": 165564,
    "name": "Buechi AG Technische Glasblaeserei    ( Uster  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 283508,
    "name": "Buechi Labortechnik GmbH    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458498,
    "name": "Buehler    ( Lake Bluff IL USA )",
    "country": "United States"
  },
  {
    "id": 286674,
    "name": "Buehler-Instrumente Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 153162,
    "name": "Buettner-Frank GmbH    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 101097,
    "name": "Buffalo Dental Mfg Co Inc    ( Syosset NY USA )",
    "country": "United States"
  },
  {
    "id": 232592,
    "name": "Buffalo Filter    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 453010,
    "name": "Buhl Electric Inc    ( South Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 455379,
    "name": "Buhlmann Laboratories AG    ( Schoenenbuch/Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 153163,
    "name": "Buhr & Fandrey    ( Flintbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 460687,
    "name": "Building Technologies USA    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 320839,
    "name": "Bulb Direct Inc    ( Pittsford NY USA )",
    "country": "United States"
  },
  {
    "id": 155894,
    "name": "Bulbman    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 107980,
    "name": "Bulbtronics Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 441868,
    "name": "Bullard    ( Cynthiana KY USA )",
    "country": "United States"
  },
  {
    "id": 460900,
    "name": "C/Net Solutions    ( Berkeley CA USA )",
    "country": "United States"
  },
  {
    "id": 453480,
    "name": "Bullard Asia Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 453481,
    "name": "Bullard GmbH    ( Remagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458845,
    "name": "Bulldog Bio Inc    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 170254,
    "name": "Bullit Inc    ( Collierville TN USA )",
    "country": "United States"
  },
  {
    "id": 461209,
    "name": "Bungalow Software    ( Blacksburg VA USA )",
    "country": "United States"
  },
  {
    "id": 999666,
    "name": "BUNN Corporation    ( Springfield IL USA )",
    "country": "United States"
  },
  {
    "id": 105342,
    "name": "Bunnell Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 356905,
    "name": "Burdette Medical Systems Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 451995,
    "name": "Burdick Inc    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 462748,
    "name": "Burgess & Assoc Hot Zone USA    ( Clermont FL USA )",
    "country": "United States"
  },
  {
    "id": 102411,
    "name": "Burke Inc    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 999642,
    "name": "Burke Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 451863,
    "name": "Burkhart Dental Supply Co    ( Tacoma WA USA )",
    "country": "United States"
  },
  {
    "id": 108682,
    "name": "Burkhart Roentgen International Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 460820,
    "name": "Burledge USA Inc    ( Winston Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 105531,
    "name": "Burlington Medical Supplies Inc    ( Newport News VA USA )",
    "country": "United States"
  },
  {
    "id": 459357,
    "name": "Burlodge USA Inc    ( Winston Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 450516,
    "name": "Burma Industry Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 457448,
    "name": "Burmeier GmbH & Co KG    ( Lage  Germany )",
    "country": "Germany"
  },
  {
    "id": 999566,
    "name": "Burnett Instrument, Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 418172,
    "name": "BurnFree Products    ( Sandy UT USA )",
    "country": "United States"
  },
  {
    "id": 451077,
    "name": "Burnishine Products    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 155405,
    "name": "Burr Engineering and Development Corp    ( Battle Creek MI USA )",
    "country": "United States"
  },
  {
    "id": 105016,
    "name": "Burrell Scientific    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 101662,
    "name": "Burton Medical Products Corp    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 370348,
    "name": "Burtons Medical Ltd    ( Marden  England )",
    "country": "UK"
  },
  {
    "id": 153164,
    "name": "Bus-Praxissysteme    ( Mengerskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281279,
    "name": "Busch & Co KG    ( Engelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105892,
    "name": "Busch Inc    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 279680,
    "name": "Buse Anlagenbau GmbH    ( Bad Hoenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999987,
    "name": "Bush    (   USA )",
    "country": "United States"
  },
  {
    "id": 454454,
    "name": "Bush Refrigeration    ( Camden NJ USA )",
    "country": "United States"
  },
  {
    "id": 452380,
    "name": "Business by the Sea    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 230522,
    "name": "Business Communications Inc    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 452181,
    "name": "Business Objects    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 101340,
    "name": "Busse Hospital Disposables    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 183944,
    "name": "Butler Gas Products Co    ( McKees Rocks PA USA )",
    "country": "United States"
  },
  {
    "id": 281923,
    "name": "BUW Schmidt GmbH    ( Garbsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458503,
    "name": "Buxton BioMedical    ( East Hanover NJ USA )",
    "country": "United States"
  },
  {
    "id": 107013,
    "name": "Buxton Medical Equipment Corp    ( Lindenhurst NY USA )",
    "country": "United States"
  },
  {
    "id": 455220,
    "name": "BV Medical    ( Barrington IL USA )",
    "country": "United States"
  },
  {
    "id": 323717,
    "name": "BVBA Schlegel SPRL    ( Gistel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456725,
    "name": "BVDA International bv    ( Harrlem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 449867,
    "name": "BVM Medical Ltd    ( Hinckley  England )",
    "country": "UK"
  },
  {
    "id": 462765,
    "name": "BW Technologies    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 461380,
    "name": "BWB Technologies    ( Braintree  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 457455,
    "name": "BWT Hungaria Viztechnikai Kft    ( Nagykanizsa  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 457458,
    "name": "BWT Pharma & Biotech GmbH    ( Vaihingen an der Enz  Germany )",
    "country": "Germany"
  },
  {
    "id": 285995,
    "name": "BWT Wassertechnik GmbH    ( Schriesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 108541,
    "name": "Byers Lab Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 403508,
    "name": "Byk & DiaSorin Diagnostics GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 153165,
    "name": "Byk-Sangtec Diagnostica GmbH & Co KG    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 456005,
    "name": "Byrne Medical Inc    ( Conroe TX USA )",
    "country": "United States"
  },
  {
    "id": 184102,
    "name": "Byron Medical    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 292897,
    "name": "Byron Mediplastics Ltd    ( Hucknall  England )",
    "country": "UK"
  },
  {
    "id": 329968,
    "name": "C & A Scientific Co Inc    ( Manassas VA USA )",
    "country": "United States"
  },
  {
    "id": 454027,
    "name": "C & C Medical Solutions    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 106134,
    "name": "C & C X-Ray & Solution Service Co    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 170255,
    "name": "C & G Technologies Inc    ( Jeffersonville IN USA )",
    "country": "United States"
  },
  {
    "id": 183777,
    "name": "C & K Mfg & Sales Co LLC    ( Westlake OH USA )",
    "country": "United States"
  },
  {
    "id": 454476,
    "name": "C & R Medical Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 292892,
    "name": "C & S Electronics Inc    ( Columbus NE USA )",
    "country": "United States"
  },
  {
    "id": 105019,
    "name": "C & S Research Corp    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 157511,
    "name": "C A Tegner AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 104372,
    "name": "C B Fleet Co Inc    ( Lynchburg VA USA )",
    "country": "United States"
  },
  {
    "id": 286676,
    "name": "C Bruno Bayha GmbH Medizintechnik    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452482,
    "name": "C Change Surgical LLC    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 103426,
    "name": "C D Denison Orthopaedic Appliance Corp    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 353429,
    "name": "C E Bartlett Pty Ltd    ( Wendouree  Australia )",
    "country": "Australia"
  },
  {
    "id": 285345,
    "name": "C Hafner GmbH & Co    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 287760,
    "name": "C J Mehta & Co    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 451083,
    "name": "C L Sturkey Inc    ( Lebanon PA USA )",
    "country": "United States"
  },
  {
    "id": 457465,
    "name": "C Miesen GmbH & Co KG    ( Bonn  Germany )",
    "country": "Germany"
  },
  {
    "id": 439180,
    "name": "C R Bard GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 150057,
    "name": "C R Bard Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 343221,
    "name": "C R Bard Portugal Lda    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 456738,
    "name": "C S Bio Co Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 286040,
    "name": "C Schliessmann Kellerei-Chemie GmbH & Co KG    ( Schwaebisch Hall  Germany )",
    "country": "Germany"
  },
  {
    "id": 465044,
    "name": "C Squared Systems LLC    ( Auburn NH USA )",
    "country": "United States"
  },
  {
    "id": 227939,
    "name": "C Stiefenhofer GmbH    ( Peiting  Germany )",
    "country": "Germany"
  },
  {
    "id": 455319,
    "name": "C-Arm International    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458599,
    "name": "C-Arms International Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 455774,
    "name": "C-Boat Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 453759,
    "name": "C-Boot Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 456987,
    "name": "C-Clear    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 457737,
    "name": "C-Core Medical Inc    ( Lake Monroe FL USA )",
    "country": "United States"
  },
  {
    "id": 457997,
    "name": "C-RAD Imaging AB    ( Froesoen  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 458673,
    "name": "c-Systems Software Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 184256,
    "name": "C-Tec    ( Wigan  England )",
    "country": "UK"
  },
  {
    "id": 457326,
    "name": "C+P Moebelsysteme GmbH & Co KG    ( Breidenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 462750,
    "name": "C2G    ( Moraine OH USA )",
    "country": "United States"
  },
  {
    "id": 464075,
    "name": "C2P Healthcare    ( Toulouse  France )",
    "country": "France"
  },
  {
    "id": 458505,
    "name": "C3 SoftWorks    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 309629,
    "name": "CA-MI snc    ( Pilastro (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 381826,
    "name": "CABE Tecnologia    ( Braganca Paulista-SP  USA )",
    "country": "United States"
  },
  {
    "id": 107243,
    "name": "Cabinets by Dacus    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 415339,
    "name": "Cabizone International Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 403614,
    "name": "Cablon Medical bv    ( Leusden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 102110,
    "name": "Cabot Medical Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 185376,
    "name": "Cabrolab Electronic Zaklad Projektowo-Produkcyjny    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 460902,
    "name": "CACI International Inc    ( Arlington VA USA )",
    "country": "United States"
  },
  {
    "id": 457318,
    "name": "Cactus LLC    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 333986,
    "name": "CACTUS Software    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 457008,
    "name": "CAD Impact Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456600,
    "name": "CADDIE SA    ( Schiltigheim Cedex  France )",
    "country": "France"
  },
  {
    "id": 311760,
    "name": "Caddie Singapore Representative Office    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 458855,
    "name": "Caddy Corp of America    ( Bridgeport NJ USA )",
    "country": "United States"
  },
  {
    "id": 454089,
    "name": "Cadence Inc    ( Staunton VA USA )",
    "country": "United States"
  },
  {
    "id": 454088,
    "name": "Cadence Science    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 457166,
    "name": "Cadent Inc    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 338313,
    "name": "Cadent Ltd    ( Or Yehuda  Israel )",
    "country": "Israel"
  },
  {
    "id": 175468,
    "name": "Cadex Electronics Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456376,
    "name": "Cadi Scientific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 393155,
    "name": "CADItec AG    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 172170,
    "name": "Cadmet    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 441886,
    "name": "Caduceus Systems LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 103629,
    "name": "Cadwell Laboratories Inc    ( Kennewick WA USA )",
    "country": "United States"
  },
  {
    "id": 408243,
    "name": "CAE Cleaning Technologies plc    ( Bedford  USA )",
    "country": "United States"
  },
  {
    "id": 458294,
    "name": "CAE Healthcare USA    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 227225,
    "name": "Caesarea Medical Electronics Ltd    ( Lichtenstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 458127,
    "name": "Caesarea Medical Electronics Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 103722,
    "name": "Caframo Ltd    ( Wiarton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 393152,
    "name": "Cahouet    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 103405,
    "name": "CAIG Laboratories Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 452681,
    "name": "CAIR LGL    ( Civrieux d'Azergues  France )",
    "country": "France"
  },
  {
    "id": 173450,
    "name": "Caire Inc - Biomedical    ( Ball Ground GA USA )",
    "country": "United States"
  },
  {
    "id": 289504,
    "name": "Cal-Glass for Research Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 184354,
    "name": "Cal-Med Diversified Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 457060,
    "name": "Cal-Ray Inc    ( Oshkosh WI USA )",
    "country": "United States"
  },
  {
    "id": 107109,
    "name": "Calbiochem    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451082,
    "name": "Calbiotech Inc    ( Spring Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 452454,
    "name": "Caldera Medical Inc    ( Agoura Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 391301,
    "name": "Calderaro Medical Service    ( Firenza (FI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451080,
    "name": "Caldon Biotech inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 462940,
    "name": "Calero Software    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 143829,
    "name": "Caley & Whitmore Corp    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 457598,
    "name": "Calhoun Vision Inc    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 458929,
    "name": "Caliber Imaging & Diagnostics Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 450510,
    "name": "Calibrate Inc    ( Washington GA USA )",
    "country": "United States"
  },
  {
    "id": 451094,
    "name": "Calico Industries Inc    ( Annapolis Junction MD USA )",
    "country": "United States"
  },
  {
    "id": 460905,
    "name": "California Advanced Labeling Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 452250,
    "name": "California Analytical Instruments Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 357386,
    "name": "California Computer Research Inc    ( Lake Arrowhead CA USA )",
    "country": "United States"
  },
  {
    "id": 462766,
    "name": "California Department of Public Health    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 106135,
    "name": "California Diagnostic Services    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 452949,
    "name": "California Ear Institute    ( East Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 185776,
    "name": "California Instruments Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 330837,
    "name": "California Medical Laboratories Inc    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 233546,
    "name": "California Scientific Software    ( West Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 394707,
    "name": "Caligor/Scientific Supply Co    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 238854,
    "name": "Calimex    ( Antwerpen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 440811,
    "name": "Caliper Life Sciences    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 230541,
    "name": "Callegari SpA    ( Parma (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452874,
    "name": "Calley & Currier    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 101141,
    "name": "Calley & Currier Co    ( Bristol NH USA )",
    "country": "United States"
  },
  {
    "id": 461078,
    "name": "CallingPost Communications    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 282875,
    "name": "Calmar Deutschland GmbH    ( Hemer  Germany )",
    "country": "Germany"
  },
  {
    "id": 458329,
    "name": "Calmar Laser    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 454227,
    "name": "CalMed Ltd    ( Bellshill  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 185778,
    "name": "Calscan Medical Enterprises Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 108744,
    "name": "Caltech Industries Inc    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 392857,
    "name": "Calumet Coach (Brasil) Ltda    ( Sao Paulo SP  USA )",
    "country": "United States"
  },
  {
    "id": 392856,
    "name": "Calumet Coach Co (China)    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 392858,
    "name": "Calumet Coach Co Ltd    ( Camberley  USA )",
    "country": "United States"
  },
  {
    "id": 458185,
    "name": "Calypso Medical Technologies Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 153796,
    "name": "Calypte Biomedical Corp    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 279968,
    "name": "CAMAG (Germany) GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 138247,
    "name": "CAMAG (Switzerland)    ( Muttenz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105349,
    "name": "CAMAG Scientific Inc    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 431613,
    "name": "Camber Corp    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 441462,
    "name": "Cambrex Bio Science Rockland Inc    ( Rockland ME USA )",
    "country": "United States"
  },
  {
    "id": 427844,
    "name": "Cambrex Bio Science Walkersville Inc    ( Walkersville MD USA )",
    "country": "United States"
  },
  {
    "id": 461068,
    "name": "Cambridge Cognition Ltd    ( Bottisham  England )",
    "country": "UK"
  },
  {
    "id": 466273,
    "name": "Cambridge Computer Services Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 451095,
    "name": "Cambridge Diagnostic Products Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 457457,
    "name": "Cardiokinetix Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 460850,
    "name": "Cambridge Electronic Design Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 456348,
    "name": "Cambridge Endoscopic Devices Inc    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 265168,
    "name": "Cambridge Heart Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 151000,
    "name": "Cambridge Medical Equipments Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 458857,
    "name": "Cambridge Scale Works Inc    ( Honey Brook PA USA )",
    "country": "United States"
  },
  {
    "id": 451127,
    "name": "Cambridge Sensors Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 473827,
    "name": "Cambridge Sensors USA LLC    ( Plainfield IL USA )",
    "country": "United States"
  },
  {
    "id": 457750,
    "name": "Cambridge Sheetmetals    ( Cambridge  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 103406,
    "name": "CAMBRO Mfg    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 458510,
    "name": "CAMECA SAS    ( Gennevilliers Cedex  France )",
    "country": "France"
  },
  {
    "id": 456353,
    "name": "Cameron Health    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 101661,
    "name": "Cameron-Miller Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 455184,
    "name": "CamFil AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 392481,
    "name": "CamFil Farr    ( Riverdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 171167,
    "name": "CAMINA srl    ( Egna BZ  USA )",
    "country": "United States"
  },
  {
    "id": 458134,
    "name": "CAMLOG Biotechnologies AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457209,
    "name": "CamNtech Inc    ( Boerne TX USA )",
    "country": "United States"
  },
  {
    "id": 457211,
    "name": "CamNtech Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 101887,
    "name": "CAMP Healthcare    ( Jackson MI USA )",
    "country": "United States"
  },
  {
    "id": 348927,
    "name": "CAMP Healthcare Inc (Canada)    ( Trenton ON USA )",
    "country": "United States"
  },
  {
    "id": 106230,
    "name": "Camp-Ray Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 461064,
    "name": "CampbellRhea    ( Paris TN USA )",
    "country": "United States"
  },
  {
    "id": 458524,
    "name": "Campden Instruments (UK)    ( Loughborough  England )",
    "country": "UK"
  },
  {
    "id": 458526,
    "name": "Campden Instruments (US)    ( Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 139845,
    "name": "Camspec Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 104143,
    "name": "Camtec Cambridge Technologies Inc    ( Cambridge MD USA )",
    "country": "United States"
  },
  {
    "id": 431599,
    "name": "CAN-AM    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 171264,
    "name": "Can-Am Care Corp    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 455191,
    "name": "Can-Am Instruments Ltd    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 377686,
    "name": "Canada Endoscope Corp    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 403767,
    "name": "Canada Micro-Surgical    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451250,
    "name": "Canadian Blood Services    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 171725,
    "name": "Canadian Hospital Specialties Ltd    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103407,
    "name": "Canadian Medical Products Ltd    ( Scarborough ON Canada )",
    "country": "Canada"
  },
  {
    "id": 403467,
    "name": "Canberra Co    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 150126,
    "name": "Canberra Industries Inc    ( Meriden CT USA )",
    "country": "United States"
  },
  {
    "id": 374969,
    "name": "Canberra-Packard Spol s r o    ( Praha 6  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 451096,
    "name": "Cancer Diagnostics Inc    ( Birmingham MI USA )",
    "country": "United States"
  },
  {
    "id": 105939,
    "name": "Candela Corp    ( Wayland MA USA )",
    "country": "United States"
  },
  {
    "id": 366849,
    "name": "Candela Iberica SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 184003,
    "name": "Candela KK (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 170259,
    "name": "CCC Medical Electronics    ( Stilwell KS USA )",
    "country": "United States"
  },
  {
    "id": 366848,
    "name": "Candela Laser Deutschland GmbH    ( Neu-Isenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458331,
    "name": "Candelis Inc    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 455940,
    "name": "Candent Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 293757,
    "name": "Candulor AG    ( Wangen bie Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 285764,
    "name": "Candulor Dental GmbH    ( Rielasingen-Worblingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453361,
    "name": "Candulor USA Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 458268,
    "name": "CANE SpA    ( Rivoli (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451097,
    "name": "Canfield Imaging Systems    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 157138,
    "name": "Canon Europa NV    ( Amstelveen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 157139,
    "name": "Canon Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 104469,
    "name": "Canon Medical Systems    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 362077,
    "name": "Canon Medical Systems    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 999881,
    "name": "Canon U.S.A., Inc.    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 419832,
    "name": "Canopus Australia    ( Malvern East  USA )",
    "country": "United States"
  },
  {
    "id": 419831,
    "name": "Canopus China    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 392808,
    "name": "Canopus Co Ltd    ( Kobe  USA )",
    "country": "United States"
  },
  {
    "id": 419823,
    "name": "Canopus Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 419829,
    "name": "Canopus GmbH    ( Weiterstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 419833,
    "name": "Canopus Italy    ( Modena MO  USA )",
    "country": "United States"
  },
  {
    "id": 419830,
    "name": "Canopus UK    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 456209,
    "name": "Canta Medical Technology Co Ltd    ( Shenyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 472795,
    "name": "Cantel Medical (UK) Ltd    ( Beaconside  England )",
    "country": "UK"
  },
  {
    "id": 401029,
    "name": "Canterbury Scientific Ltd    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 399273,
    "name": "Cantor & Nissel Ltd    ( Brackley  England )",
    "country": "UK"
  },
  {
    "id": 306891,
    "name": "Cantron Electronics    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454399,
    "name": "Canvys    ( LaFox IL USA )",
    "country": "United States"
  },
  {
    "id": 456210,
    "name": "Canwell Medical Co Ltd    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455695,
    "name": "Canwell Medical Ltd    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456992,
    "name": "Canyon State Dental Supply    ( Glendale AZ USA )",
    "country": "United States"
  },
  {
    "id": 450685,
    "name": "CAOS Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 456976,
    "name": "Capatex Ltd    ( New Basford  England )",
    "country": "UK"
  },
  {
    "id": 459318,
    "name": "Cape Communications Corp    ( Cape Girardeau MO USA )",
    "country": "United States"
  },
  {
    "id": 102944,
    "name": "Capintec Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 462934,
    "name": "Capita plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 108753,
    "name": "Capital Biomed    ( Pflugerville TX USA )",
    "country": "United States"
  },
  {
    "id": 457061,
    "name": "Capital Medical Equipment Intl LLC    ( Matawan NJ USA )",
    "country": "United States"
  },
  {
    "id": 456351,
    "name": "Capital Microscope Services    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 453791,
    "name": "Capital X-Ray Inc    ( Tallassee AL USA )",
    "country": "United States"
  },
  {
    "id": 454845,
    "name": "Capitol Scientific Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 452118,
    "name": "CapMed    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 458877,
    "name": "Cappys Custom Cabinets Inc    ( Bogart GA USA )",
    "country": "United States"
  },
  {
    "id": 457569,
    "name": "Capri Optics Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 289400,
    "name": "Circle Medical Products Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 455079,
    "name": "Capsa Solutions    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 463048,
    "name": "CAPSLab SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 456919,
    "name": "Capstone Therapeutics Corp    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 457289,
    "name": "Capsule Tech Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 367255,
    "name": "Capsule Technologie    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 441542,
    "name": "Captiva Software Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456954,
    "name": "Captiva Spine    ( Jupiter FL USA )",
    "country": "United States"
  },
  {
    "id": 416155,
    "name": "Capula Elan Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 460637,
    "name": "Caradigm USA LLC    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 391325,
    "name": "Caradyne Ltd    ( Galway  USA )",
    "country": "United States"
  },
  {
    "id": 458800,
    "name": "CaraFlow LLC    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 153198,
    "name": "Caramant Handelsgesellschaft fuer Glas und Keramik    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 462754,
    "name": "Caraustar Industries Inc    ( Austell GA USA )",
    "country": "United States"
  },
  {
    "id": 185781,
    "name": "Carber Power Technologies    ( Annapolis Junction MD USA )",
    "country": "United States"
  },
  {
    "id": 107732,
    "name": "CarboMedics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 451099,
    "name": "Carbon Medical Technologies Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 152426,
    "name": "Carci Ind e Com de Aparelhos Cirurgicos e Ortopedi    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 441041,
    "name": "Carclo Technical Plastics Ltd    ( Latrobe PA USA )",
    "country": "United States"
  },
  {
    "id": 466770,
    "name": "Card Data Systems    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 352040,
    "name": "Card Guard AG    ( Neuhausen am Rheinfall  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391868,
    "name": "Card Guard Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 172261,
    "name": "Card Guard Scientific Survival Ltd    ( Rehovot  Israel )",
    "country": "Israel"
  },
  {
    "id": 352048,
    "name": "Card Guard South America    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 459495,
    "name": "Card Scanning Solutions Inc    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 282193,
    "name": "Cardex Dental GmbH    ( Klagenfurt  Austria )",
    "country": "Austria"
  },
  {
    "id": 454754,
    "name": "CARDIA Europe    ( Gent  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454753,
    "name": "CARDIA Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 384250,
    "name": "Cardiac Impulse srl    ( Rubano (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451247,
    "name": "Cardiac Science Corp    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 457958,
    "name": "Cardiac Science Corp (Central Europe)    ( Cologne  Germany )",
    "country": "Germany"
  },
  {
    "id": 451278,
    "name": "Cardiac Science Corp China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451279,
    "name": "Cardiac Science Holdings (UK) Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 416487,
    "name": "Cardiac Science International A/S    ( Vaerloese  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 272621,
    "name": "Cardiac Services Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 172518,
    "name": "Cardiacare Ltd    ( Brentwood  England )",
    "country": "UK"
  },
  {
    "id": 328106,
    "name": "CardiacAssist GmbH    ( Morges  USA )",
    "country": "United States"
  },
  {
    "id": 385370,
    "name": "CardiacAssist Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 458878,
    "name": "CardiArc Ltd    ( Canton MI USA )",
    "country": "United States"
  },
  {
    "id": 451896,
    "name": "Cardica Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 310008,
    "name": "Cardima Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 332026,
    "name": "Cardimac GmbH    ( Luedersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 452475,
    "name": "CARDINAL HEALTH ALARIS PRODUCTS    (   USA )",
    "country": "United States"
  },
  {
    "id": 439222,
    "name": "Cardinal Health Belgium SPRL/BVBA    ( Vilvoorde  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 440907,
    "name": "Cardinal Health Canada Inc    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454447,
    "name": "Cardinal Health Co    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 439227,
    "name": "Cardinal Health France SAS    ( Voisons le Bretonneux  France )",
    "country": "France"
  },
  {
    "id": 439228,
    "name": "Cardinal Health GmbH    ( Kleva  Germany )",
    "country": "Germany"
  },
  {
    "id": 304293,
    "name": "Cardinal Health Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 419856,
    "name": "Cardinal Health Inc    ( La Vergne TN USA )",
    "country": "United States"
  },
  {
    "id": 452485,
    "name": "Cardinal Health Inc (Canada)    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 439230,
    "name": "Cardinal Health Italy srl    ( Aprilia (LT)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418181,
    "name": "Cardinal Health Medical Products & Services    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 418176,
    "name": "Cardinal Health Medical Products & Services Group    ( McGaw Park IL USA )",
    "country": "United States"
  },
  {
    "id": 418177,
    "name": "Cardinal Health Medical Products & Services Group    ( McGaw Park IL USA )",
    "country": "United States"
  },
  {
    "id": 418178,
    "name": "Cardinal Health Medical Products & Services Group    ( McGaw Park IL USA )",
    "country": "United States"
  },
  {
    "id": 418179,
    "name": "Cardinal Health Medical Products & Services Group    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 418180,
    "name": "Cardinal Health Medical Products & Services Group    ( Penang  USA )",
    "country": "United States"
  },
  {
    "id": 418182,
    "name": "Cardinal Health Medical Products & Services Group    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 418183,
    "name": "Cardinal Health Medical Products & Services Group    ( McGaw Park IL USA )",
    "country": "United States"
  },
  {
    "id": 439229,
    "name": "Cardinal Health Netherlands bv    ( Zutphen  USA )",
    "country": "United States"
  },
  {
    "id": 452337,
    "name": "Cardinal Health NeuroCare Group    (   USA )",
    "country": "United States"
  },
  {
    "id": 439231,
    "name": "Cardinal Health Spain SL    ( San Fernando de Henares  Spain )",
    "country": "Spain"
  },
  {
    "id": 439232,
    "name": "Cardinal Health Sweden AB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 439233,
    "name": "Cardinal Health Switzerland GmbH    ( Rolle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453404,
    "name": "Cardinal Health UK    ( Reigate  England )",
    "country": "UK"
  },
  {
    "id": 439234,
    "name": "Cardinal Health UK Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 452486,
    "name": "Cardinal Healthcare Australia 200 Pty    ( Seven Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 454448,
    "name": "Cardinal Healthcare Co    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 444101,
    "name": "Cardio Control A/S    ( Tranby  Norway )",
    "country": "Norway"
  },
  {
    "id": 174564,
    "name": "Cardio Medical Products    ( Rockaway NJ USA )",
    "country": "United States"
  },
  {
    "id": 306266,
    "name": "Cardio Safe Deutschland    ( Buerogebaeude S2  Germany )",
    "country": "Germany"
  },
  {
    "id": 102233,
    "name": "Cardio Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 461036,
    "name": "Cardio Theater    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 152427,
    "name": "Cardio-Bras Ind e Com Ltda    ( Brazilia-DF  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 460851,
    "name": "CardioAccess Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 458851,
    "name": "Cardiocom LLC    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 465134,
    "name": "CardioComm Solutions, Inc.    ( Victoria 20 USA )",
    "country": "United States"
  },
  {
    "id": 363229,
    "name": "CardioCommand Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 465083,
    "name": "Cardiocore    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 474844,
    "name": "CardioDx Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 183071,
    "name": "CardioDynamics International Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456892,
    "name": "CardioFocus Inc    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 271356,
    "name": "CardioGenesis Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 176393,
    "name": "Cardiographic Repair Service    ( Wyncote PA USA )",
    "country": "United States"
  },
  {
    "id": 158898,
    "name": "Cardiokinetics Ltd    ( Salford  USA )",
    "country": "United States"
  },
  {
    "id": 163053,
    "name": "Cardioline Inc    ( Sebastopol CA USA )",
    "country": "United States"
  },
  {
    "id": 456921,
    "name": "CARDIOLINE SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457871,
    "name": "CardioLink Corp    ( Levittown NY USA )",
    "country": "United States"
  },
  {
    "id": 174568,
    "name": "CardioMed Supplies Inc    ( Lindsay ON Canada )",
    "country": "Canada"
  },
  {
    "id": 107693,
    "name": "Cardiomedics Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 451487,
    "name": "CardioMEMS Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 402536,
    "name": "Cardionet Inc    ( Conshohocken PA USA )",
    "country": "United States"
  },
  {
    "id": 357301,
    "name": "Cardionetics Ltd    ( Hook  England )",
    "country": "UK"
  },
  {
    "id": 458801,
    "name": "CardioNexus Corp    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 104604,
    "name": "Cardionics Inc    ( Webster TX USA )",
    "country": "United States"
  },
  {
    "id": 352971,
    "name": "Cardionics SA    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 251237,
    "name": "Cardiopulmonary Corp    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 455315,
    "name": "CardioPulmonary Technologies Inc    ( Pewaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 456235,
    "name": "CardioPulse    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 451615,
    "name": "CardioQuickSys LLC    ( Sharonville OH USA )",
    "country": "United States"
  },
  {
    "id": 450623,
    "name": "CardioQuip LLP    ( Bryan TX USA )",
    "country": "United States"
  },
  {
    "id": 458862,
    "name": "CardioSoft    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 431638,
    "name": "Cardiosonix Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 458332,
    "name": "CardioStream LLC    ( Milton GA USA )",
    "country": "United States"
  },
  {
    "id": 370569,
    "name": "Cardiotec Electromedicina    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 107987,
    "name": "Cardiotech Inc (Diagnostic Medical Supplies Inc)    ( Waterford MI USA )",
    "country": "United States"
  },
  {
    "id": 418673,
    "name": "CardioTech International Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 454662,
    "name": "Cardiotronic Inc    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 391870,
    "name": "Cardiovascular Innovations Inc    ( Athens TX USA )",
    "country": "United States"
  },
  {
    "id": 104040,
    "name": "Cardiovascular Instrument Corp    ( Wakefield MA USA )",
    "country": "United States"
  },
  {
    "id": 452998,
    "name": "Cardiovascular Systems Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 462755,
    "name": "Cardno ATC Assoc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 454544,
    "name": "Cardo Medical    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 174569,
    "name": "Cardon Rehabilitation Products (Canada)    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 104149,
    "name": "Cardon Rehabilitation Products Inc    ( Sanborn NY USA )",
    "country": "United States"
  },
  {
    "id": 460901,
    "name": "Cardone EHR Solutions    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 456055,
    "name": "Care & Cure Surgico    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 452863,
    "name": "Care & Independence Systems Ltd    ( Bicester  England )",
    "country": "UK"
  },
  {
    "id": 463020,
    "name": "Care Communications Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 397926,
    "name": "CARE diagnostica GesmbH    ( Moellersdorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 397927,
    "name": "CARE diagnostica Laborreagenzien    ( Voerde  Germany )",
    "country": "Germany"
  },
  {
    "id": 176114,
    "name": "Care Electronics Inc    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 401741,
    "name": "Care Essentials Pty Ltd    ( North Geelong  Australia )",
    "country": "Australia"
  },
  {
    "id": 451002,
    "name": "Care Express Products    ( Cary IL USA )",
    "country": "United States"
  },
  {
    "id": 460899,
    "name": "Care Logistics LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 108359,
    "name": "Care Products Inc    ( Goshen NY USA )",
    "country": "United States"
  },
  {
    "id": 456902,
    "name": "Care Rehab and Orthopaedic Products Inc    ( McLean VA USA )",
    "country": "United States"
  },
  {
    "id": 335162,
    "name": "Care Technologies Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 366252,
    "name": "Care Trak Inc    ( Murphysboro IL USA )",
    "country": "United States"
  },
  {
    "id": 338672,
    "name": "Care Wise Medical Products Corp    ( Morgan Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 456399,
    "name": "Care-ability Healthcare    ( Washington  England )",
    "country": "UK"
  },
  {
    "id": 107989,
    "name": "Care-Tech Laboratories Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 174570,
    "name": "Care-Tek Corp    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 394925,
    "name": "CareCentric Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 453360,
    "name": "Carecour Medical    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 378241,
    "name": "Caredata.com Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 471738,
    "name": "CareDx Inc    ( Brisbane CA USA )",
    "country": "United States"
  },
  {
    "id": 458343,
    "name": "CareEvolve    ( Elmwood Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 333971,
    "name": "Carefacts Information Systems    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 407233,
    "name": "CareFlex Ltd    ( Newton Abbot  England )",
    "country": "UK"
  },
  {
    "id": 460893,
    "name": "CAREFOAM Inc    ( Kitchener ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451103,
    "name": "CareFore Medical Inc    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 455401,
    "name": "CareFusion    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 454428,
    "name": "CareFusion Corp    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 454430,
    "name": "CareFusion Corp    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 454431,
    "name": "CareFusion Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454432,
    "name": "CareFusion Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454433,
    "name": "CareFusion Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 454434,
    "name": "CareFusion Corp    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 454435,
    "name": "CareFusion Corp    ( Leawood KS USA )",
    "country": "United States"
  },
  {
    "id": 455451,
    "name": "CareFusion GmbH    ( Hoechberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455400,
    "name": "CareFusion NeuroCare - Golden    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 333965,
    "name": "Carekeeper Software Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 466705,
    "name": "Carematix Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 173876,
    "name": "Caremed    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 330607,
    "name": "CareMed Corp    ( La Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 393156,
    "name": "CareMed GmbH    ( Dresden  USA )",
    "country": "United States"
  },
  {
    "id": 451093,
    "name": "CareMedical Inc    ( Greenbrier AR USA )",
    "country": "United States"
  },
  {
    "id": 446568,
    "name": "CareSharp    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 451593,
    "name": "Careside Medical LLC    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 455470,
    "name": "Carestream Dental AB Div Carestream Health Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 454866,
    "name": "Carestream Health Australia Pty Ltd    ( East Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 452242,
    "name": "Carestream Health Canada Co    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452323,
    "name": "Carestream Health Dental Systems Group    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 454867,
    "name": "Carestream Health Deutschland GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 464806,
    "name": "CAREstream Medical Ltd    ( Langley BC Canada )",
    "country": "Canada"
  },
  {
    "id": 458552,
    "name": "CareTech Solutions Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 456815,
    "name": "CareTrends    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 453409,
    "name": "CareVoyant Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 393157,
    "name": "Carewell Mediproducts Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 458906,
    "name": "CareWorx    ( Orangeville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453447,
    "name": "Carex Health Brands    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 105896,
    "name": "Cargille Laboratories    ( Cedar Grove NJ USA )",
    "country": "United States"
  },
  {
    "id": 444441,
    "name": "Cari-All Healthcare    ( Battle Creek MI USA )",
    "country": "United States"
  },
  {
    "id": 459358,
    "name": "Cari-All Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 452439,
    "name": "Carico Systems    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 455546,
    "name": "Caridian BCT (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454333,
    "name": "CaridianBCT - Norden    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454332,
    "name": "CaridianBCT Europe    ( Garching  Germany )",
    "country": "Germany"
  },
  {
    "id": 454335,
    "name": "CaridianBCT Europe NV - Spain    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 454334,
    "name": "CaridianBCT Europe NV Italy    ( Calderara di Reno (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453485,
    "name": "CaridianBCT Europe NV/SA    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453484,
    "name": "CaridianBCT Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 453486,
    "name": "CaridianBCT Northern Ireland Ltd    ( Larne  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 438945,
    "name": "Carina VT Ltd    ( Barnard Castle  England )",
    "country": "UK"
  },
  {
    "id": 193495,
    "name": "Carital Ltd    ( Tuusula  Finland )",
    "country": "Finland"
  },
  {
    "id": 280616,
    "name": "Carl Auffarth GmbH & Co KG    ( Butjadingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 172377,
    "name": "Carl Baasel Lasertechnik GmbH    ( Starnberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285486,
    "name": "Carl Friedrich Usbeck KG    ( Radevormwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 280266,
    "name": "Carl Henkel GmbH & Co KG    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 235121,
    "name": "Carl Parker Assoc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 160804,
    "name": "Carl Roth GmbH & Co KG    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 281259,
    "name": "Carl Teufel GmbH & Co    ( Emmingen/Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 138203,
    "name": "Carl Zeiss    ( Oberkochen  Germany )",
    "country": "Germany"
  },
  {
    "id": 104253,
    "name": "Carl Zeiss Canada Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 185061,
    "name": "Carl Zeiss Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 184427,
    "name": "Carl Zeiss Jena GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 305988,
    "name": "Carl Zeiss Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 426732,
    "name": "Carl Zeiss Meditec AG    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 418173,
    "name": "Carl Zeiss Meditec Inc    ( Dublin CA USA )",
    "country": "United States"
  },
  {
    "id": 416153,
    "name": "Carl Zeiss Meditec Ltd    ( Inverkeithing  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 393099,
    "name": "Carl Zeiss MicroImaging Inc    ( Thornwood NY USA )",
    "country": "United States"
  },
  {
    "id": 441534,
    "name": "Carl Zeiss NTS GmbH    ( Oberkochen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452500,
    "name": "CARL ZEISS SMT INC    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 441533,
    "name": "Carl Zeiss SMT Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 452516,
    "name": "Carl Zeiss SMT SAS    ( Le Pecq  France )",
    "country": "France"
  },
  {
    "id": 397827,
    "name": "Carleton Life Support Systems Inc    ( Davenport IA USA )",
    "country": "United States"
  },
  {
    "id": 352432,
    "name": "Carleton Life Support Technologies Ltd    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 458406,
    "name": "Carlisle Foodservice Products    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 193380,
    "name": "Carlo de Giorgi srl    ( Baranzate di Bollate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452383,
    "name": "Carmel Pharma AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452384,
    "name": "Carmel Pharma Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 455735,
    "name": "Carmelton Co Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 104454,
    "name": "Carolina Biological Supply Co    ( Burlington NC USA )",
    "country": "United States"
  },
  {
    "id": 465118,
    "name": "Carolina Business Furniture    ( Archdale NC USA )",
    "country": "United States"
  },
  {
    "id": 332784,
    "name": "Carolina Liquid Chemistries Corp    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 101147,
    "name": "Carolina Medical Inc    ( King NC USA )",
    "country": "United States"
  },
  {
    "id": 457062,
    "name": "Carolina Medical Parts    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 460858,
    "name": "Carolina Rustica    ( Concord NC USA )",
    "country": "United States"
  },
  {
    "id": 101148,
    "name": "Carolon Co    ( Rural Hall NC USA )",
    "country": "United States"
  },
  {
    "id": 452595,
    "name": "Caron Products and Services Inc    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 415720,
    "name": "Carousel Medical Systems Inc    ( Kempton PA USA )",
    "country": "United States"
  },
  {
    "id": 105718,
    "name": "Carr Corp    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 372912,
    "name": "Carrier Commercial Refrigeration Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 372913,
    "name": "Carrier Corp    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 230964,
    "name": "Carrington Healthcare Systems Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 118252,
    "name": "Carrington Laboratories Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 450871,
    "name": "Carroll Healthcare Inc    ( London ON USA )",
    "country": "United States"
  },
  {
    "id": 451376,
    "name": "Carroll Hospital Group    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456570,
    "name": "Carrot Medical    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 108559,
    "name": "Carsen Medical Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 405868,
    "name": "Carson Medical Group    ( Carson City NV USA )",
    "country": "United States"
  },
  {
    "id": 171253,
    "name": "Carstens Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 459361,
    "name": "Cart Mart    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 152079,
    "name": "Carteau Ots    ( Bagnolet  France )",
    "country": "France"
  },
  {
    "id": 461366,
    "name": "Carter-Health LLC    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 103409,
    "name": "Carter-Hoffmann    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 463023,
    "name": "Carticept Medical    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 157897,
    "name": "Carton Optical Industries Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453122,
    "name": "CartsPlus Healthcare Systems Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108692,
    "name": "Cartwright Consulting Co    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 384297,
    "name": "Cartwright Consulting Co (The Netherlands)    ( Oegstgeest  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 399229,
    "name": "carus Group (UK) plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 393127,
    "name": "carus Information Technology AG    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 399228,
    "name": "carus Information Technology Romania SrL    ( Harman/Brasov  Romania )",
    "country": "Romania"
  },
  {
    "id": 455611,
    "name": "CAS America    ( East Greenwich RI USA )",
    "country": "United States"
  },
  {
    "id": 439567,
    "name": "CAS Medical Systems GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 104431,
    "name": "CAS Medical Systems Inc    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 999742,
    "name": "CAS-USA Corp.    ( East Rutherford NJ USA )",
    "country": "United States"
  },
  {
    "id": 152428,
    "name": "Casa do Radiologista Centro Brasileiro Eletro-Medi    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 458915,
    "name": "Casa Futura Technologies    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 184076,
    "name": "Casa Lux SA    ( Naucalpan de Jaurez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 184077,
    "name": "Casa Plarre SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 415410,
    "name": "CASAcare GmbH & Co KG    ( Loehne  USA )",
    "country": "United States"
  },
  {
    "id": 403720,
    "name": "Casad Surgical Supply    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 107387,
    "name": "Cascade Designs Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 999001,
    "name": "Cascade Health and Fitness    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 456627,
    "name": "Cascade Healthcare Products Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 179524,
    "name": "Cascade Orthopedic Supply    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 440094,
    "name": "Case Medical Inc    ( Ridgefield NJ USA )",
    "country": "United States"
  },
  {
    "id": 999830,
    "name": "CASE SYSTEMS    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 335197,
    "name": "Caseworks Furniture Mfg Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 289807,
    "name": "Casix Inc    ( Fuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373554,
    "name": "Casmed UK    ( Epsom  England )",
    "country": "UK"
  },
  {
    "id": 153206,
    "name": "Caspar & Co Labora GmbH    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 170257,
    "name": "Cassling Diagnostic Imaging    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 175475,
    "name": "Cast Products Inc    ( Athens AL USA )",
    "country": "United States"
  },
  {
    "id": 275615,
    "name": "Casters and Parts Inc    ( Conshohocken PA USA )",
    "country": "United States"
  },
  {
    "id": 324542,
    "name": "Castle Group Ltd    ( Scarborough  England )",
    "country": "UK"
  },
  {
    "id": 451945,
    "name": "Catalina Cylinders    ( Garden Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 458900,
    "name": "Catamount Research and Development    ( St Albans VT USA )",
    "country": "United States"
  },
  {
    "id": 286327,
    "name": "Cataract Ingenieurbuero M Zipperer GmbH    ( Staufen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453891,
    "name": "Catch Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 451984,
    "name": "Caterham Surgical Supplies Ltd    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 458201,
    "name": "Caterpillar Inc    ( Peoria IL USA )",
    "country": "United States"
  },
  {
    "id": 453777,
    "name": "Cateye Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 284334,
    "name": "Catgut GmbH    ( Markneukirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 399144,
    "name": "CathEffects LLC    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 451308,
    "name": "Catheter and Disposabels Technology Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 457926,
    "name": "Catheter Connections    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 192815,
    "name": "Cattani SpA    ( Parma (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 309684,
    "name": "Cavex Holland bv    ( Haarlem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451777,
    "name": "Cavex Holland bv    ( Haarlem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 105473,
    "name": "Cavitron    (   USA )",
    "country": "United States"
  },
  {
    "id": 452513,
    "name": "Cayenne Medical Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 428448,
    "name": "CBD Ophthalmic/TOMEY    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 366844,
    "name": "CBG Biotech Ltd    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 179814,
    "name": "CBi Health Care Inc    ( Roanoke TX USA )",
    "country": "United States"
  },
  {
    "id": 454462,
    "name": "CBI Polymers    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 176925,
    "name": "CBM srl    ( Torre de Picenardi (CR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 351276,
    "name": "CBS Scientific    ( Del Mar CA USA )",
    "country": "United States"
  },
  {
    "id": 460771,
    "name": "CBT Supply Inc    ( Hibernia NJ USA )",
    "country": "United States"
  },
  {
    "id": 313428,
    "name": "CCD Laboratoire    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 356163,
    "name": "CCE Canada Commercial Exchange Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458202,
    "name": "CCI Group Inc    ( Longview TX USA )",
    "country": "United States"
  },
  {
    "id": 417396,
    "name": "CCX Medical Imaging Inc    ( Harvey LA USA )",
    "country": "United States"
  },
  {
    "id": 418658,
    "name": "CD Leycom/CardioDynamics bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457329,
    "name": "CD Nelson Inc    ( Lakemoor IL USA )",
    "country": "United States"
  },
  {
    "id": 460854,
    "name": "CDA Systems LLC    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 455255,
    "name": "CDC Software    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458397,
    "name": "CDEX Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 462979,
    "name": "CDI TORQUE Products    ( Kenosha WI USA )",
    "country": "United States"
  },
  {
    "id": 458908,
    "name": "CDL Medical Tech Inc    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 452992,
    "name": "CDM Dental    ( Albany OR USA )",
    "country": "United States"
  },
  {
    "id": 458532,
    "name": "CDR Systems Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 431616,
    "name": "CDS Analytical Inc    ( Oxford PA USA )",
    "country": "United States"
  },
  {
    "id": 464966,
    "name": "CDW    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 104146,
    "name": "CDX Corp    ( Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 106852,
    "name": "CE-Tech    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 284555,
    "name": "CEA (Deutschland) GmbH    ( Muelheim am Ruhr  Germany )",
    "country": "Germany"
  },
  {
    "id": 107983,
    "name": "CEA Instruments Inc    ( Westwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 458911,
    "name": "Ceabis    ( Este (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 354517,
    "name": "Ceatec Medizintechnik    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 152356,
    "name": "Cecil Instruments Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 334296,
    "name": "Cedara Software Corp    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 338674,
    "name": "Cedarberg Industries Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 333750,
    "name": "Cedaron Medical Inc    ( Davis CA USA )",
    "country": "United States"
  },
  {
    "id": 460775,
    "name": "Cedrus Corp    ( San Pedro CA USA )",
    "country": "United States"
  },
  {
    "id": 157681,
    "name": "Cefar Medical AB    ( Lund  USA )",
    "country": "United States"
  },
  {
    "id": 454110,
    "name": "Cefar-Complex Scandinavia AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457924,
    "name": "Cefla Dental Group    ( Imola (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455349,
    "name": "Cefndy Healthcare    ( Rhyl  Wales )",
    "country": "Wales"
  },
  {
    "id": 461220,
    "name": "Cegedim    ( Boulogne Billancourt  France )",
    "country": "France"
  },
  {
    "id": 999583,
    "name": "CEI    ( Gardena CA USA )",
    "country": "USA"
  },
  {
    "id": 458011,
    "name": "Ceimig Ltd    ( Dundee  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 452399,
    "name": "Celayix Inc (Canada)    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452398,
    "name": "Celayix Inc (United States )    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 277920,
    "name": "Celcom Inc    ( Fukuoka  Japan )",
    "country": "Japan"
  },
  {
    "id": 458105,
    "name": "Celera Corp    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 458912,
    "name": "Celerus Diagnostics Inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 454763,
    "name": "Celestron LLC    ( Torrence CA USA )",
    "country": "United States"
  },
  {
    "id": 460777,
    "name": "Cell Biolabs Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451102,
    "name": "Cell Factor Technologies Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 451101,
    "name": "Cell Marque Corp    ( Rocklin CA USA )",
    "country": "United States"
  },
  {
    "id": 365286,
    "name": "Cell Robotics International Inc    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 455845,
    "name": "Cell Science & Technology Institute Inc    ( Sendai  Japan )",
    "country": "Japan"
  },
  {
    "id": 450579,
    "name": "Cell-Max GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 305642,
    "name": "Cellabs Pty Ltd    ( Brookvale  Australia )",
    "country": "Australia"
  },
  {
    "id": 426352,
    "name": "CellaVision AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 467069,
    "name": "CellaVision Canada    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 467067,
    "name": "Cellavision China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 426351,
    "name": "CellaVision Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 467068,
    "name": "CellaVision Japan Kk    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 454298,
    "name": "Celldex Therapeutics Inc    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 458093,
    "name": "CellEra LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 456390,
    "name": "Celleration Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 454473,
    "name": "Cellestis GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 454472,
    "name": "Cellestis Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 454474,
    "name": "Cellestis International Pty Ltd    ( Carnegie  Australia )",
    "country": "Australia"
  },
  {
    "id": 457541,
    "name": "Celling Biosciences LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 461104,
    "name": "Cellix Ltd    ( Dublin 12  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 466818,
    "name": "Cellnovo    ( Swansea  Wales )",
    "country": "Wales"
  },
  {
    "id": 452732,
    "name": "Cellogel Electrophoresis Co srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 139212,
    "name": "Cellpath plc    ( Newtown  Wales )",
    "country": "Wales"
  },
  {
    "id": 462978,
    "name": "CellPoint LLC    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 454686,
    "name": "CellPoint Scientific Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 104242,
    "name": "Cellucap Mfg    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 460974,
    "name": "Cellular Specialties Inc (CSI)    ( Manchester NH USA )",
    "country": "United States"
  },
  {
    "id": 458299,
    "name": "Cellular Technology Ltd    ( Shaker Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 454725,
    "name": "Celon AG    ( Teltow  Germany )",
    "country": "Germany"
  },
  {
    "id": 346965,
    "name": "Celsion Corp    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 440640,
    "name": "Celsis bv    ( Landgraaf  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 232572,
    "name": "Celsis Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 373452,
    "name": "Celsis International plc    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 453458,
    "name": "Celsus Laboratories Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458770,
    "name": "CEM Corp    ( Matthews NC USA )",
    "country": "United States"
  },
  {
    "id": 293236,
    "name": "Cendres & Metaux SA    ( Biel/Bienne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452061,
    "name": "Cenorin LLC    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 452239,
    "name": "Cenorin LLC    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 452344,
    "name": "Censis Technologies Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 186818,
    "name": "Centens TENS USA    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 171310,
    "name": "Center for Prosthetic Restoration Inc    ( Riderwood MD USA )",
    "country": "United States"
  },
  {
    "id": 451862,
    "name": "Center Medical Supply    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 465074,
    "name": "Centers for Age Control    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 458537,
    "name": "CenterVue Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 458536,
    "name": "CenterVue SpA    ( Padova  Italy )",
    "country": "Italy"
  },
  {
    "id": 440095,
    "name": "Centicare Corp    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 105252,
    "name": "Centocor Diagnostics of PA Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 454397,
    "name": "Centocor Ortho Biotech Products Inc    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 183966,
    "name": "Centocor UK Ltd    ( Guildford  USA )",
    "country": "United States"
  },
  {
    "id": 454788,
    "name": "CenTrak Inc    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 454790,
    "name": "CenTrak Inc (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456934,
    "name": "CenTrak Inc (India)    ( Mandaiveli  India )",
    "country": "India"
  },
  {
    "id": 454789,
    "name": "CenTrak Inc (South Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 358343,
    "name": "Central Admixture Pharmacy Services (CAPS)    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 306847,
    "name": "Central de Compras del Extrasistema SA    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 107273,
    "name": "Central Dupage Pedorthics    ( Carol Stream IL USA )",
    "country": "United States"
  },
  {
    "id": 178122,
    "name": "Central Home Care    ( Freeport NY USA )",
    "country": "United States"
  },
  {
    "id": 106137,
    "name": "Central Illinois X-Ray    ( Bloomington IL USA )",
    "country": "United States"
  },
  {
    "id": 458296,
    "name": "Central Logic    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 454600,
    "name": "Central Medical Supplies Ltd    ( Leek  England )",
    "country": "UK"
  },
  {
    "id": 456891,
    "name": "Central New York Eye and Tissue Bank    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 377600,
    "name": "Central Research Laboratories    ( Red Wing MN USA )",
    "country": "United States"
  },
  {
    "id": 458169,
    "name": "Central Specialties Ltd    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 426435,
    "name": "Central Sprinkler Co    ( Lansdale PA USA )",
    "country": "United States"
  },
  {
    "id": 461367,
    "name": "Central Wisconsin Medical Physics Consulting LLC    ( Marshfield WI USA )",
    "country": "United States"
  },
  {
    "id": 471641,
    "name": "Centrex Technologies Inc    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 228487,
    "name": "Centri AB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 107565,
    "name": "Centrix Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 174205,
    "name": "Centro Auditivo Telex S/A    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 179523,
    "name": "Centromed Ltd    ( Ashford  England )",
    "country": "UK"
  },
  {
    "id": 185377,
    "name": "Centrum Mikroskopii    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 466537,
    "name": "Centura X-Ray    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 455074,
    "name": "Centurion Medical Products    ( Howell MI USA )",
    "country": "United States"
  },
  {
    "id": 232368,
    "name": "Centurion Scientific Ltd    ( Stoughton  England )",
    "country": "UK"
  },
  {
    "id": 456451,
    "name": "Centurion Service Group    ( Melrose Park IL USA )",
    "country": "United States"
  },
  {
    "id": 461106,
    "name": "Centurion Technologies    ( Fenton MO USA )",
    "country": "United States"
  },
  {
    "id": 451253,
    "name": "Century Medical Distributors Ltd    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 287396,
    "name": "Century Medical Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455562,
    "name": "Ceodex Inc    ( Mt Pleasant PA USA )",
    "country": "United States"
  },
  {
    "id": 455560,
    "name": "Ceodex SA    ( Lintegen  Luxembourg )",
    "country": "Luxembourg"
  },
  {
    "id": 237819,
    "name": "CEPEM    ( Bauro-SP  USA )",
    "country": "United States"
  },
  {
    "id": 451038,
    "name": "Cepheid    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 352953,
    "name": "Ceracarta    ( Forli (FC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455807,
    "name": "CERAGEM Medisys    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 318336,
    "name": "Ceram Optec Systems    ( Muskva  USA )",
    "country": "United States"
  },
  {
    "id": 107274,
    "name": "CeraMed Dental LLC    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 299828,
    "name": "CeramTec AG    ( Plochingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 299838,
    "name": "CeramTec Medical Products    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 285405,
    "name": "Cerasiv GmbH    ( Plochingen  USA )",
    "country": "United States"
  },
  {
    "id": 279096,
    "name": "Ceraver Osteal    ( Roissy-Charles-de-Gaulle Cedex  France )",
    "country": "France"
  },
  {
    "id": 999470,
    "name": "Circon    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 461109,
    "name": "Cerebros Medical Systems LLC    ( Olyphant PA USA )",
    "country": "United States"
  },
  {
    "id": 458391,
    "name": "Ceretronix ApS    ( Randers  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 450864,
    "name": "Cerner BeyondNow    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 401723,
    "name": "Cerner Canada Ltd    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 463026,
    "name": "Cerner Clairvia    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 104788,
    "name": "Cerner Corp    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 156974,
    "name": "Cerner Corp Pty Ltd    ( Milsons Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 194231,
    "name": "Cerner Deutschland GmbH    ( Idstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 417784,
    "name": "Cerner DHT Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 460671,
    "name": "Cerner Extended Care    ( New Concord OH USA )",
    "country": "United States"
  },
  {
    "id": 161996,
    "name": "Cerner Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 401722,
    "name": "Cerner Malaysia Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 194230,
    "name": "Cerner Middle East FZ-LLC    ( Dubai Internet City  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 401724,
    "name": "Cerner Singapore Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 461111,
    "name": "Certified Air Solutions (CAS) Inc    ( Eagleville PA USA )",
    "country": "United States"
  },
  {
    "id": 185794,
    "name": "Certified Biomedical    ( Wichita Falls TX USA )",
    "country": "United States"
  },
  {
    "id": 150330,
    "name": "Certified Safety Mfg Inc    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 151489,
    "name": "CertoClav Sterilizer GmbH    ( Traun  Austria )",
    "country": "Austria"
  },
  {
    "id": 451437,
    "name": "Certol International LLC    ( Commerce City CO USA )",
    "country": "United States"
  },
  {
    "id": 457368,
    "name": "Cerus    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 460859,
    "name": "CESCO BioProducts    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458038,
    "name": "CET Medical LLC    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 465135,
    "name": "cetoni GmbH    ( xx xx USA )",
    "country": "United States"
  },
  {
    "id": 328760,
    "name": "CF Electronic Inc    ( Laramie WY USA )",
    "country": "United States"
  },
  {
    "id": 451592,
    "name": "CF Group (Commercial Furniture Group)    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 441574,
    "name": "CF Resources    ( Kit Carson CO USA )",
    "country": "United States"
  },
  {
    "id": 450908,
    "name": "CFI Medical Solutions    ( Fenton MI USA )",
    "country": "United States"
  },
  {
    "id": 199754,
    "name": "CFT Inc/Life Mask    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 336997,
    "name": "CGA Strumenti Scientifici SpA    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460800,
    "name": "CH Technologies Inc    ( Westwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 273752,
    "name": "CH-Werfen and Instrumentation Laboratory    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 107316,
    "name": "Chad Therapeutics Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 456211,
    "name": "Chain Medical Products co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457616,
    "name": "Chalgren Enterprises Inc    ( Gilroy CA USA )",
    "country": "United States"
  },
  {
    "id": 454419,
    "name": "Chalice Medical Ltd    ( Worksop  England )",
    "country": "UK"
  },
  {
    "id": 330297,
    "name": "Chamco Inc    ( Cocoa FL USA )",
    "country": "United States"
  },
  {
    "id": 455808,
    "name": "Chammed Co Ltd    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 183896,
    "name": "Champion    ( Princeton IL USA )",
    "country": "United States"
  },
  {
    "id": 392147,
    "name": "Champion America    ( Stony Creek CT USA )",
    "country": "United States"
  },
  {
    "id": 458531,
    "name": "Champion Industries Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 458554,
    "name": "Champion Medical Technologies    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 170835,
    "name": "Circulator Boot Corp    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 176119,
    "name": "Champion Mfg LLC    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 455397,
    "name": "Champion Photochemistry - AMERICAS (Canada)    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455398,
    "name": "Champion Photochemistry - AMERICAS (USA)    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 451779,
    "name": "Champion Sales    ( Ridgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 456058,
    "name": "Chanazhou Hualian Health Dressing Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455833,
    "name": "Chang Gung Medical Technology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456250,
    "name": "Changqing Shanwaishan Science & Technology Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452313,
    "name": "Changshou Zhiye Medical Devices Institute    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291424,
    "name": "Changshu Medical Special Materials Factory    ( Changshu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456212,
    "name": "Changzhou Anker Medical Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456213,
    "name": "Changzhou Hekang Medical Instruments Co Ltd    ( Chengzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456214,
    "name": "Changzhou Hualian Health Dressing Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456217,
    "name": "Changzhou Huichun Medical Instruments Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456218,
    "name": "Changzhou Kangdi Medical Stapler Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456220,
    "name": "Changzhou Kanghui Medical Innovation Co Ltd    ( Chengzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456495,
    "name": "Changzhou Kangida International Trade Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456221,
    "name": "Changzhou Medical Appliances General Factory Co Lt    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456222,
    "name": "Changzhou Nanfang Medical Appliance Factory Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456223,
    "name": "Changzhou Shuangma Medical Devices Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456225,
    "name": "Changzhou Waston Medical Appliance Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456226,
    "name": "Changzhou XieTong Industries Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456641,
    "name": "Changzhou Zhenhua Drying Equipment Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454115,
    "name": "Chaparral Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 456056,
    "name": "Chaplet International (Pvt) Ltd    ( Lahore  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 185798,
    "name": "Char Med Industries Inc    ( Wilmette IL USA )",
    "country": "United States"
  },
  {
    "id": 455834,
    "name": "Charder Electronic Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999586,
    "name": "Charder Medical    ( Dali Dist  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 306310,
    "name": "Charles Austen Pumps Ltd    ( Byfleet  England )",
    "country": "UK"
  },
  {
    "id": 999678,
    "name": "Charles Beseler Company    ( Stroudsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 455219,
    "name": "Charles River Laboratories International Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 456988,
    "name": "CharlyRobot SAS    ( Cruseilles  France )",
    "country": "France"
  },
  {
    "id": 398168,
    "name": "Charm Sciences Inc    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 457007,
    "name": "Charmcare Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458158,
    "name": "Charnstrom    ( Shakopee MN USA )",
    "country": "United States"
  },
  {
    "id": 372151,
    "name": "Charnwood Dynamics Ltd    ( Rothley  England )",
    "country": "UK"
  },
  {
    "id": 363934,
    "name": "Chart Industries Inc    ( Garfield Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 459365,
    "name": "Chart Links LLC    ( South Bend IN USA )",
    "country": "United States"
  },
  {
    "id": 309989,
    "name": "ChartCare Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 455382,
    "name": "Charter Kontron Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 106905,
    "name": "Charter Medical Ltd    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 462784,
    "name": "ChartNet Technologies Inc    ( Yorkville IL USA )",
    "country": "United States"
  },
  {
    "id": 457753,
    "name": "Charts & Carts    ( Kent OH USA )",
    "country": "United States"
  },
  {
    "id": 333751,
    "name": "ChartWare Inc    ( Rohnert Park CA USA )",
    "country": "United States"
  },
  {
    "id": 452466,
    "name": "Chas A Blatchford & Sons Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 330839,
    "name": "Chase Medical Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 103031,
    "name": "Chase Scientific Glass    ( Rockwood TN USA )",
    "country": "United States"
  },
  {
    "id": 451107,
    "name": "Chasma Scientific Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 460975,
    "name": "Chatsworth Products Inc    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 101154,
    "name": "Chattanooga Group    ( Hixson TN USA )",
    "country": "United States"
  },
  {
    "id": 449816,
    "name": "Chattanooga Group International    ( Herentals  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452848,
    "name": "Chattem Inc    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 457524,
    "name": "ChatterVox    ( Indian Creek IL USA )",
    "country": "United States"
  },
  {
    "id": 451128,
    "name": "CHdiagnostics    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 460844,
    "name": "Check In Systems Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 458528,
    "name": "Check Point Software Technologies Ltd    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 454355,
    "name": "Cheetah Medical    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 999868,
    "name": "Chefmate    (   USA )",
    "country": "United States"
  },
  {
    "id": 335098,
    "name": "Chem-Tainer Inc    ( West Babylon NY USA )",
    "country": "United States"
  },
  {
    "id": 108760,
    "name": "Chematics Inc    ( North Webster IN USA )",
    "country": "United States"
  },
  {
    "id": 155391,
    "name": "Chembio Diagnostic Systems Inc    ( Medford NY USA )",
    "country": "United States"
  },
  {
    "id": 363870,
    "name": "ChemDAQ Corp    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 456419,
    "name": "Chemence Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 456422,
    "name": "Chemence Ltd    ( Corby  England )",
    "country": "UK"
  },
  {
    "id": 175333,
    "name": "Chemetrics Inc    ( Calverton VA USA )",
    "country": "United States"
  },
  {
    "id": 101338,
    "name": "Chemetron Medical Div Allied Healthcare Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 458203,
    "name": "Chemglass Life Sciences    ( Vineland NJ USA )",
    "country": "United States"
  },
  {
    "id": 462902,
    "name": "Chemical Exhaust    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 417153,
    "name": "Chemicon Australia Pty Ltd    ( Boronia  USA )",
    "country": "United States"
  },
  {
    "id": 417206,
    "name": "Chemicon Europe Ltd    ( Chandlers Ford  USA )",
    "country": "United States"
  },
  {
    "id": 183756,
    "name": "Chemicon International    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 417204,
    "name": "Chemicon International Ltd    ( Hofheim  USA )",
    "country": "United States"
  },
  {
    "id": 469464,
    "name": "Chemilines Group Ltd    ( Wembley  England )",
    "country": "UK"
  },
  {
    "id": 335673,
    "name": "Chemlab Scientific Products    ( Laindon  England )",
    "country": "UK"
  },
  {
    "id": 460839,
    "name": "ChemoMetec AS    ( Allerod  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451725,
    "name": "Chemopharma GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 451364,
    "name": "Chemopharma Viden spol Sro    ( Vrchlabi  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 462679,
    "name": "Chempac Distributors    ( Englishtown NJ USA )",
    "country": "United States"
  },
  {
    "id": 456228,
    "name": "Chemtron Biotech Inc    ( San Diego  USA )",
    "country": "United States"
  },
  {
    "id": 470533,
    "name": "Chengdu Laoken Technology Co Ltd    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456229,
    "name": "Chengdu Puth Medical Plastics Packaging Co Ltd    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456231,
    "name": "Chengdu Rich Science Industry Co Ltd    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455116,
    "name": "Chengdu XHHC Biotechnology Co Ltd    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455716,
    "name": "Chengdu Xinjin Shifeng Medical Apparatus & Instrum    ( Chengdu City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456232,
    "name": "Chengdu Xinjin Shifeng Medical Apparatus & Instrum    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457702,
    "name": "Chenica Inc    ( Vassar MI USA )",
    "country": "United States"
  },
  {
    "id": 279604,
    "name": "Cherudenta Dentalerzeugnisse    ( Augustdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 462684,
    "name": "Chesapeake Healthcare Forum Inc    ( Charlestown WV USA )",
    "country": "United States"
  },
  {
    "id": 360895,
    "name": "Chesapeake Medical Products Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 456377,
    "name": "Chesham Ellison Healthcare Ltd    ( Mansfield  England )",
    "country": "UK"
  },
  {
    "id": 403721,
    "name": "Cheshire Medical Specialties Inc    ( Cheshire CT USA )",
    "country": "United States"
  },
  {
    "id": 455213,
    "name": "Cheshire Wellness UK    ( Neston  England )",
    "country": "UK"
  },
  {
    "id": 150939,
    "name": "Chest MI Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 105534,
    "name": "Chester Labs    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 452020,
    "name": "Chestnut Medical Technologies Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 105376,
    "name": "Chestnut Ridge Foam Inc    ( Latrobe PA USA )",
    "country": "United States"
  },
  {
    "id": 407237,
    "name": "Chevron Spol sro    ( Pilsen  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 451047,
    "name": "CHF Solutions Inc    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 464091,
    "name": "CHG Hospital Beds    (   Canada )",
    "country": "Canada"
  },
  {
    "id": 459240,
    "name": "Chicago Dryer Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 457063,
    "name": "Chicago Medical Exchange Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 107284,
    "name": "Chicago Trashpacker Corp    ( Hilton Head Island SC USA )",
    "country": "United States"
  },
  {
    "id": 457884,
    "name": "Chicago X-Ray Systems Inc    ( Prospect Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 460998,
    "name": "Chicago-Soft Ltd    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 409856,
    "name": "CHIFA    ( Nowy Tomyl  Poland )",
    "country": "Poland"
  },
  {
    "id": 461001,
    "name": "Children's Heart Clinic    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 462803,
    "name": "Children's Hospital Association    ( Alexandria VA USA )",
    "country": "United States"
  },
  {
    "id": 305788,
    "name": "Children's Medical Ventures    ( Monroeville PA USA )",
    "country": "United States"
  },
  {
    "id": 456234,
    "name": "Chilecom Medical Devices Co Ltd    ( Huizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451839,
    "name": "Chiltern Invadex (NI) Ltd    ( Bangor  USA )",
    "country": "United States"
  },
  {
    "id": 451840,
    "name": "Chiltern Invadex (Scotland) Ltd    ( Glasgow  USA )",
    "country": "United States"
  },
  {
    "id": 352925,
    "name": "Chiltern Invadex Ltd    ( Bicester  England )",
    "country": "UK"
  },
  {
    "id": 456786,
    "name": "China Carnation Endoscope Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 463042,
    "name": "China Daheng Group Inc    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455736,
    "name": "China Effort Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456242,
    "name": "China Healife MEDco Ltd    ( Yangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 290917,
    "name": "China Kejian Corp Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458295,
    "name": "Class 1 Inc    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453699,
    "name": "China Medical Technologies Inc    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 290895,
    "name": "China National Corp of Medical Equipment Industry    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456243,
    "name": "China Qingao Bright Medical Mfg Co Ltd    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456244,
    "name": "China Shanghai Nineluck Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455836,
    "name": "China Surgical Dressings Center Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456642,
    "name": "China-Tianjin Walkman Biomaterial Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 323635,
    "name": "ChinaHawk Enterprises Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 322356,
    "name": "Chinteik Hygiene Products Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 458816,
    "name": "CHIRANA T Injecta    ( Stara Tura  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 193498,
    "name": "CHIRANA-PREMA Trading AS    ( Piest'any  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 451030,
    "name": "Chison Medical Imaging Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456246,
    "name": "Chison Medical Imaging Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 105535,
    "name": "Chiu Technical Corp    ( Kings Park NY USA )",
    "country": "United States"
  },
  {
    "id": 324289,
    "name": "Chloride Power Electronics (UK)    ( London  England )",
    "country": "UK"
  },
  {
    "id": 107278,
    "name": "Cho-Pat Inc    ( Hainesport NJ USA )",
    "country": "United States"
  },
  {
    "id": 431572,
    "name": "Choice 1 Medical Distributors Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 451108,
    "name": "Choice Medical Supplies    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 177851,
    "name": "Choice Medical Systems Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 184309,
    "name": "Choice Systems Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 183927,
    "name": "Cholestech Corp    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 450518,
    "name": "Chongqing Haifu Technology Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456640,
    "name": "Chongqing Jingyu Laser Technology Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452266,
    "name": "Chongqing Jinshan Science & Technology (Group) Co    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456247,
    "name": "Chongqing Jinshan Science and Technology Group Co    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456646,
    "name": "Chongqing Tianhai Medical Equipment Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455316,
    "name": "Chongqing Xinfeng Medical Instruments Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456649,
    "name": "Chongqing Xishan Technology Co Ltd    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291710,
    "name": "Chongquing Medical Equipment Factory    ( Chongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 266206,
    "name": "Choongwae Medical Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454362,
    "name": "Chorus    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 455809,
    "name": "Choyang Medical Industry Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 107711,
    "name": "CHRIS LUTZ MINNTECH CORP    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 392859,
    "name": "Christ AG    ( Aesch  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 286412,
    "name": "Christ GmbH    ( Vaihingen/Enz  Germany )",
    "country": "Germany"
  },
  {
    "id": 287019,
    "name": "Christ GmbH Physikalische Heiltechnik    ( Viernheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 336402,
    "name": "Christ Holland bv    ( Zoeterwoude  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 237944,
    "name": "Cogent Light Technologies Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 336401,
    "name": "Christ Hungaria Kft    ( Budaors  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 392860,
    "name": "Christ Nordic AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 392861,
    "name": "Christ Water Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 329548,
    "name": "Christ Water USA Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 160096,
    "name": "Christian Miesen Fahrzeug- und Karosseriewerk GmbH    ( Bonn 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 462929,
    "name": "Christie Digital Systems USA Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 455305,
    "name": "Christie Medical Holdings Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 451112,
    "name": "Chrom Tech Inc    ( Apple Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 460852,
    "name": "Chromalox Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 999469,
    "name": "Chromamaxx    (   USA )",
    "country": "United States"
  },
  {
    "id": 150917,
    "name": "Chromatography Research Supplies Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 102238,
    "name": "Chrono-log Corp    ( Havertown PA USA )",
    "country": "United States"
  },
  {
    "id": 999825,
    "name": "Chrysler & Koppin Company    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 455812,
    "name": "ChungDo Pharm Co Ltd    ( Chuncheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 466529,
    "name": "Chuntex Electronic Co Ltd (CTX)    ( New Taipei City  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999829,
    "name": "Chuntex Electronic Co. Ltd    ( Taipei County  USA )",
    "country": "United States"
  },
  {
    "id": 456746,
    "name": "Church & Dwight Co Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 143845,
    "name": "Church Biomedical Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 322904,
    "name": "Church Products Co    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 391824,
    "name": "Churchill Medical Ltd    ( Rugby  England )",
    "country": "UK"
  },
  {
    "id": 451113,
    "name": "Churchill Medical Systems Inc    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 456767,
    "name": "CI Healthcare    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 480458,
    "name": "Cianna Medical Inc    ( Aliso Viejo CA USA )",
    "country": "USA"
  },
  {
    "id": 105912,
    "name": "CIBA Vision Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 105303,
    "name": "CIBA Vision Corp    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 282195,
    "name": "CIBA Vision Vertriebs GmbH    ( Grossostheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 455865,
    "name": "Ciber Industrial Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 363247,
    "name": "Cicada Systems LLC    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 285213,
    "name": "Cidelcem GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 179046,
    "name": "CiF Furniture Ltd    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452884,
    "name": "CIMEX Medical Innovations LC    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 101159,
    "name": "Cincinnati Sub-Zero Products Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 101160,
    "name": "Cincinnati Surgical Co Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 453369,
    "name": "Cincom Systems Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458233,
    "name": "Cintas Corp    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 172389,
    "name": "Ciposa Dialysis Sarl    ( Saint-Blaise  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458272,
    "name": "CIR Systems Inc    ( Sparta NJ USA )",
    "country": "United States"
  },
  {
    "id": 999550,
    "name": "CIR Systems, Inc.    ( Sparta NJ USA )",
    "country": "USA"
  },
  {
    "id": 458230,
    "name": "CIRCA Scientific LLC    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 103731,
    "name": "Circadian Systems    ( Valley Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 178882,
    "name": "CircAid Medical Products Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458307,
    "name": "Circle Cardiovascular Imaging Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 381777,
    "name": "Circulatory Technology Inc    ( Oyster Bay NY USA )",
    "country": "United States"
  },
  {
    "id": 458103,
    "name": "CircuLite GmbH    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 335384,
    "name": "Cirius Group Inc    ( Pleasant Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 465088,
    "name": "Cirrus Medical Technologies    ( Delray Beach 23 USA )",
    "country": "United States"
  },
  {
    "id": 280897,
    "name": "CIS Diagnostik GmbH    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 105677,
    "name": "CIS-US Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 439656,
    "name": "CISA Middle East    ( Amman  Jordan )",
    "country": "Jordan"
  },
  {
    "id": 232367,
    "name": "CISA srl    ( Pomezia (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 439663,
    "name": "CisaBrasile Ltda    ( Joinville-SC  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457773,
    "name": "Cisco Systems    ( Feltham  England )",
    "country": "UK"
  },
  {
    "id": 457772,
    "name": "Cisco Systems Canada Co    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451447,
    "name": "Cisco Systems Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 457774,
    "name": "Cisco Systems Norway    ( Lysaker  Norway )",
    "country": "Norway"
  },
  {
    "id": 460856,
    "name": "CISCOR Acquisitions LLC    ( Normon OK USA )",
    "country": "United States"
  },
  {
    "id": 460828,
    "name": "Cissell    ( Ripon WI USA )",
    "country": "United States"
  },
  {
    "id": 439875,
    "name": "Citagenix Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 461083,
    "name": "Citizen Scale Inc    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 455846,
    "name": "Citizen Systems Japan Co Ltd    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 157899,
    "name": "Citizen Watch Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456252,
    "name": "Citotest Labware Mfg Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458529,
    "name": "Citow Cervical Visualizer Co    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 458573,
    "name": "Citrix Systems Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 407238,
    "name": "City Pharmacy    ( Abu Dhabi  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 106711,
    "name": "Civco Medical Solutions    ( Kalona IA USA )",
    "country": "United States"
  },
  {
    "id": 453445,
    "name": "Civco Medical Solutions (Shanghai)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452327,
    "name": "cj enterprises    ( Tarzana CA USA )",
    "country": "United States"
  },
  {
    "id": 457384,
    "name": "CK Dental Industries Co Ltd    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 347243,
    "name": "CK Dental Specialties    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 466816,
    "name": "CLA Medical    ( Blue Ash OH USA )",
    "country": "United States"
  },
  {
    "id": 461368,
    "name": "CLAD Industries LLC    ( Macedon NY USA )",
    "country": "United States"
  },
  {
    "id": 454805,
    "name": "Claflin Medical Equipment Co    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 454417,
    "name": "Clarcon Biologic Chemical Laboratory Inc    ( Roy UT USA )",
    "country": "United States"
  },
  {
    "id": 455447,
    "name": "Clarient Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 452308,
    "name": "Clarion Medical Technologies    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 439951,
    "name": "Clarity    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 999650,
    "name": "Clarity Diagnostics, LLC.    ( Boca Raton FL USA )",
    "country": "USA"
  },
  {
    "id": 453914,
    "name": "Clarity Medical Systems Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 174576,
    "name": "Clark Medical Products Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 104517,
    "name": "Clark Research & Development Inc    ( Folsom LA USA )",
    "country": "United States"
  },
  {
    "id": 272829,
    "name": "Clarke Health Care Inc    ( Oakdale PA USA )",
    "country": "United States"
  },
  {
    "id": 150896,
    "name": "Clarus Medical LLC    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 460834,
    "name": "Clary Business Machines    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 105217,
    "name": "Clary Corp    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 458231,
    "name": "Class Biologically Clean Ltd    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 188895,
    "name": "Class One Orthodonics    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 452686,
    "name": "Claudius Ash    ( Stevenage  England )",
    "country": "UK"
  },
  {
    "id": 101748,
    "name": "Clay Adams Div Becton Dickinson and Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 457363,
    "name": "Claymount Assemblies BV    ( Dinxperlo  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 330663,
    "name": "Claymount North America Corp    ( Elmhurst IL USA )",
    "country": "United States"
  },
  {
    "id": 457009,
    "name": "Claymount(CM) Korea Inc    ( Shihung  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461066,
    "name": "CLC bio    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 458019,
    "name": "Clean Air Products    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 460907,
    "name": "Clean Air Research and Environmental Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 455188,
    "name": "Clean Air Techniek bv    ( Woerden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458031,
    "name": "Clean Air Technology Inc    ( Canton MI USA )",
    "country": "United States"
  },
  {
    "id": 451115,
    "name": "Clean ESD Products Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 458025,
    "name": "Clean Room Environments LLC    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 415982,
    "name": "Clean Room Garments Pty Ltd    ( Glendenning  Australia )",
    "country": "Australia"
  },
  {
    "id": 458032,
    "name": "Clean Rooms International Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 356820,
    "name": "Clean Rooms West Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 460826,
    "name": "CleanZones LLC    ( Jackson NJ USA )",
    "country": "United States"
  },
  {
    "id": 456318,
    "name": "Clear Catheter Systems    ( Bend OR USA )",
    "country": "United States"
  },
  {
    "id": 460827,
    "name": "Clear Spider Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455003,
    "name": "ClearCanvas    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453110,
    "name": "ClearCount Medical Solutions    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 399271,
    "name": "Clearcut Solutions P/L    ( Mt Eliza  USA )",
    "country": "United States"
  },
  {
    "id": 452786,
    "name": "Clearfield Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 451007,
    "name": "Clearform Plastics    ( North Shields  USA )",
    "country": "United States"
  },
  {
    "id": 453781,
    "name": "ClearMedical Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 428609,
    "name": "ClearOne Communications Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 455949,
    "name": "ClearStream Technologies Ltd    ( Enniscorthy  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 324171,
    "name": "ClearView Communications Ltd    ( Chelmsford  England )",
    "country": "UK"
  },
  {
    "id": 460829,
    "name": "ClearWater Tech LLC    ( San Luis Obispo CA USA )",
    "country": "United States"
  },
  {
    "id": 999480,
    "name": "Cleatech    ( Santa Ana CA USA )",
    "country": "USA"
  },
  {
    "id": 163057,
    "name": "Clement Clarke International Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 305625,
    "name": "Clements Medical Equipment Pty Ltd    ( North Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 230095,
    "name": "Cleo of New York    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 454761,
    "name": "Cleveland Medical Devices Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 458506,
    "name": "Cleveland Range LLC    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 460831,
    "name": "Clever Sys Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 455481,
    "name": "CLIAwaived.com    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 330628,
    "name": "Clifton & Co (Pvt) Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 102136,
    "name": "Climet Instruments Co    ( Redlands CA USA )",
    "country": "United States"
  },
  {
    "id": 460836,
    "name": "ClinAxis Outcomes Systems Inc    ( Cedar Park TX USA )",
    "country": "United States"
  },
  {
    "id": 280434,
    "name": "Clinic & Job-Dress Helmut Wagner GmbH    ( Bramsche  Germany )",
    "country": "Germany"
  },
  {
    "id": 439662,
    "name": "Clinical Computer Systems Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 439846,
    "name": "Clinical Computing (Australia)    ( Kalamunda  Australia )",
    "country": "Australia"
  },
  {
    "id": 371212,
    "name": "Clinical Computing (UK)    ( Brentford  England )",
    "country": "UK"
  },
  {
    "id": 331078,
    "name": "Clinical Computing (US)    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 171741,
    "name": "Clinical Controls International    ( Los Osos CA USA )",
    "country": "United States"
  },
  {
    "id": 426496,
    "name": "Clinical Data Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 460847,
    "name": "Clinical Data Management Inc    ( Conifer CO USA )",
    "country": "United States"
  },
  {
    "id": 452373,
    "name": "Clinical Diagnostics Solutions Inc    ( Plantation FL USA )",
    "country": "United States"
  },
  {
    "id": 184103,
    "name": "Clinical Dynamics Corp    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 182842,
    "name": "Clinical Emergencies    ( Lyndhurst  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 106232,
    "name": "Clinical Equipment Service    ( West Monroe LA USA )",
    "country": "United States"
  },
  {
    "id": 185808,
    "name": "Clinical Equipment Services Inc    ( Somerset PA USA )",
    "country": "United States"
  },
  {
    "id": 106433,
    "name": "Clinical Information Systems Inc    ( West Linn OR USA )",
    "country": "United States"
  },
  {
    "id": 202488,
    "name": "Clinical Innovations Inc    ( Murray UT USA )",
    "country": "United States"
  },
  {
    "id": 101164,
    "name": "Clinical Instruments International Inc    ( Southbridge MA USA )",
    "country": "United States"
  },
  {
    "id": 462667,
    "name": "Clinical Laboratory Standards Institute    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 170266,
    "name": "Clinical Measurements Inc    ( Winter Garden FL USA )",
    "country": "United States"
  },
  {
    "id": 461371,
    "name": "Clinical Microsystems Inc    ( Arlington VA USA )",
    "country": "United States"
  },
  {
    "id": 287898,
    "name": "Clinical NetwoRx Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 330637,
    "name": "Clinical Resources Inc    ( Cockeysville MD USA )",
    "country": "United States"
  },
  {
    "id": 169902,
    "name": "Clinical Software Solutions    ( Queen Creek AZ USA )",
    "country": "United States"
  },
  {
    "id": 287714,
    "name": "Clinical Supply Co Ltd    ( Hashima-gun  Japan )",
    "country": "Japan"
  },
  {
    "id": 460863,
    "name": "Clinically Speaking LLC    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 233579,
    "name": "CLINICARE Corp (Canada)    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 391298,
    "name": "Clinico Verwattlungs-GmbH & Co Betellgungs-KG    ( Bad Hersfeld  USA )",
    "country": "United States"
  },
  {
    "id": 108575,
    "name": "CliniComp Intl    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 330841,
    "name": "Clinicon Corp    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 452150,
    "name": "ClinicPro Software    ( Sedona AZ USA )",
    "country": "United States"
  },
  {
    "id": 101749,
    "name": "Clinimed    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 142546,
    "name": "CliniMed Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 151464,
    "name": "Clinimed Medizinische Gerate Ges mbH    ( Pasching  Austria )",
    "country": "Austria"
  },
  {
    "id": 407236,
    "name": "Clinipak Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 451116,
    "name": "Cliniqa Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 454377,
    "name": "Cliniqa Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 306906,
    "name": "Clinisut    ( Port Elizabeth  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454084,
    "name": "ClinixMD    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 283792,
    "name": "Clinomobil Hospitalwerk GmbH    ( Langenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 453114,
    "name": "CLINQA Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 330640,
    "name": "Clinton Electronics Corp    ( Loves Park IL USA )",
    "country": "United States"
  },
  {
    "id": 106556,
    "name": "Clinton Industries Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 415983,
    "name": "Clipper Plastics Pty Ltd    ( Bayswater  Australia )",
    "country": "Australia"
  },
  {
    "id": 185810,
    "name": "Clipper Products    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 457783,
    "name": "ClorDiSys Solutions Inc    ( Lebanon NJ USA )",
    "country": "United States"
  },
  {
    "id": 454064,
    "name": "Cloride/ONEAC    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 232662,
    "name": "Clorox Co    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 292032,
    "name": "Closure Medical Corp    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 147186,
    "name": "Cloud 9 Mason Engineering    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 401743,
    "name": "Cloward Instruments Corp    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 458798,
    "name": "CLR Medicals International Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 461098,
    "name": "CMA Dishmachines    ( Garden Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 462874,
    "name": "CMA Engineering Inc    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 452667,
    "name": "CMA Medico    ( Espergaerde  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458306,
    "name": "CMA Microdialysis AB    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 999708,
    "name": "CMA Microdialysis AB    (   USA )",
    "country": "United States"
  },
  {
    "id": 453602,
    "name": "CME America LLC    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 449868,
    "name": "CME Devices    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 452692,
    "name": "CME McKinley UK Ltd    ( Blackpool  England )",
    "country": "UK"
  },
  {
    "id": 457781,
    "name": "CME Medical UK Ltd    ( Blackpool  England )",
    "country": "UK"
  },
  {
    "id": 454956,
    "name": "CMI    ( White House TN USA )",
    "country": "United States"
  },
  {
    "id": 151343,
    "name": "CMI Inc    ( Owensboro KY USA )",
    "country": "United States"
  },
  {
    "id": 186822,
    "name": "CMO Inc    ( Barberton OH USA )",
    "country": "United States"
  },
  {
    "id": 384324,
    "name": "CMP Batteries    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 453948,
    "name": "CMP Industries LLC    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 462946,
    "name": "CMR Naviscan Corp    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 450903,
    "name": "CMS Dental ApS    ( Kobenhaven S  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 431614,
    "name": "CMS Field Products Group    ( College Station TX USA )",
    "country": "United States"
  },
  {
    "id": 394516,
    "name": "CMS GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451982,
    "name": "CMS Inc    ( Maryland Heights MO USA )",
    "country": "United States"
  },
  {
    "id": 394518,
    "name": "CMS Japan KK    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 348220,
    "name": "CMS Worldwide Corp    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 148483,
    "name": "CMT Inc    ( Hamilton MA USA )",
    "country": "United States"
  },
  {
    "id": 238442,
    "name": "CMT Medical Technologies Inc    ( Valley Stream NY USA )",
    "country": "United States"
  },
  {
    "id": 178834,
    "name": "CMT Medical Technologies Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 106033,
    "name": "CMX Medical Imaging    ( Tukwila WA USA )",
    "country": "United States"
  },
  {
    "id": 392466,
    "name": "CNA Medical    ( Royse City TX USA )",
    "country": "United States"
  },
  {
    "id": 471664,
    "name": "CNB Technology Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 322906,
    "name": "CNH Pillow Inc    ( Abilene TX USA )",
    "country": "United States"
  },
  {
    "id": 170274,
    "name": "CNMC Co Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 104600,
    "name": "CNS Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 402537,
    "name": "CNSystems Medizintechnik GmbH (Austria)    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 439557,
    "name": "CNSystems Medizintechnik GmbH (Germany)    ( Friedberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452406,
    "name": "CoActiv Medical Business Solutions    ( Ridgefield CT USA )",
    "country": "United States"
  },
  {
    "id": 457882,
    "name": "CoaguSense Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 107992,
    "name": "Coast to Coast Medical Inc    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 330642,
    "name": "Coastal Electronics Inc    ( New Bern NC USA )",
    "country": "United States"
  },
  {
    "id": 451117,
    "name": "Coastal Life Systems    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 465082,
    "name": "Coastwide Laboratories    ( Wilsonville 10 USA )",
    "country": "United States"
  },
  {
    "id": 458803,
    "name": "CobbleStone Systems Corp    ( Runnemede NJ USA )",
    "country": "United States"
  },
  {
    "id": 417316,
    "name": "COBE Cardiovascular Australia Pty Ltd    ( South Dandenong  USA )",
    "country": "United States"
  },
  {
    "id": 417317,
    "name": "COBE Cardiovascular Europe    ( Mirandola MO  USA )",
    "country": "United States"
  },
  {
    "id": 108570,
    "name": "COBE Cardiovascular Inc    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 101958,
    "name": "COBE Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459461,
    "name": "Cobius Healthcare Solutions LLC    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 457519,
    "name": "Cobra Medical BV    ( Groningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 306768,
    "name": "Coburger Lehrmittelanstalt    ( Coburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999509,
    "name": "COBY Electronics, Corp.    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 373935,
    "name": "Cochlear (HK) Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 231085,
    "name": "Cochlear AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105226,
    "name": "Cochlear Corp    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 336252,
    "name": "Cochlear Europe Ltd    ( Addlestone  England )",
    "country": "UK"
  },
  {
    "id": 336255,
    "name": "Cochlear GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 139509,
    "name": "Cochlear Ltd    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 455072,
    "name": "Cochlear Nordic AB    ( Moelnlycke  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 399236,
    "name": "Cochlear Technology Centre Europe    ( Mechelen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 309699,
    "name": "Codali Guerbert    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415997,
    "name": "CODAN 11 SA    ( Odivelas  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 453573,
    "name": "CODAN Argus AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415996,
    "name": "CODAN bv    ( Deventer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 298385,
    "name": "CODAN DEHA ApS    ( Horsholm  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 415994,
    "name": "CODAN France Sarl    ( Bischwiller  France )",
    "country": "France"
  },
  {
    "id": 452214,
    "name": "CODAN Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 415993,
    "name": "CODAN Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 415995,
    "name": "CODAN Medical AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 153209,
    "name": "CODAN Medizinische Geraete GmbH    ( Lensahn  Germany )",
    "country": "Germany"
  },
  {
    "id": 415946,
    "name": "CODAN Norge A/S    ( Gjerdrum  Norway )",
    "country": "Norway"
  },
  {
    "id": 416427,
    "name": "CODAN Triplus AB    ( Kungsbacka  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 348942,
    "name": "CODAN US Corp    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 458576,
    "name": "Code Corp    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 175501,
    "name": "Code Three Emergency Vehicles    ( Kelowna BC Canada )",
    "country": "Canada"
  },
  {
    "id": 460869,
    "name": "Code42    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 461107,
    "name": "CodeCorrect Inc    ( Yakima WA USA )",
    "country": "United States"
  },
  {
    "id": 461087,
    "name": "CODEMED Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 101668,
    "name": "Codman & Shurtleff Inc    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 453068,
    "name": "Codman & Shurtleff Neurosciences (Switzerland)    ( Le Locle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451304,
    "name": "CODONICS (Europe/Middle East/Africa)    ( Montrouge  USA )",
    "country": "United States"
  },
  {
    "id": 451303,
    "name": "CODONICS Asia Pacific    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 249982,
    "name": "CODONICS Inc    ( Middleburg Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 451305,
    "name": "CODONICS Ltd KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 373591,
    "name": "CODONICS UK    ( Durham City  USA )",
    "country": "United States"
  },
  {
    "id": 108219,
    "name": "Coeur Inc    ( Lebanon TN USA )",
    "country": "United States"
  },
  {
    "id": 453585,
    "name": "Coger    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 471766,
    "name": "Cohera Medical Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 453595,
    "name": "Coherent (Deutschland) GmbH    ( Dieburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 439876,
    "name": "Coherent Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 453596,
    "name": "Coherent Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 466736,
    "name": "Coherex Medical    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 191919,
    "name": "COHO FURNITURE    (   USA )",
    "country": "United States"
  },
  {
    "id": 465025,
    "name": "COL Management LLC    ( Lafayette LA USA )",
    "country": "United States"
  },
  {
    "id": 451118,
    "name": "Colby Mfg Corp    ( Tullytown PA USA )",
    "country": "United States"
  },
  {
    "id": 103029,
    "name": "Colder Products    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 460877,
    "name": "ColdTech Commercial    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460878,
    "name": "ColdTech Commercial (USA)    ( Findlay OH USA )",
    "country": "United States"
  },
  {
    "id": 466294,
    "name": "Cole Parmer    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 451601,
    "name": "Cole-Parmer Canada    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453521,
    "name": "Cole-Parmer China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455126,
    "name": "Cole-Parmer India    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 102415,
    "name": "Cole-Parmer Instrument Co    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 403503,
    "name": "Cole-Parmer Instrument Co Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 455482,
    "name": "Cole-Taylor Marketing Inc    ( Reseda CA USA )",
    "country": "United States"
  },
  {
    "id": 451551,
    "name": "Colenta Labortechnik GmbH & Co KG    ( Wiener Neustadt  Austria )",
    "country": "Austria"
  },
  {
    "id": 171148,
    "name": "Colgate Oral Pharmaceuticals    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 106486,
    "name": "Colin Medical Instruments    ( Bannockburn IL USA )",
    "country": "United States"
  },
  {
    "id": 451436,
    "name": "CollaGenex Pharmaceuticals    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 233581,
    "name": "Collection Data Systems    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 460889,
    "name": "Collective Medical Technologies Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 454546,
    "name": "College of American Pathologists    ( Northfield IL USA )",
    "country": "United States"
  },
  {
    "id": 456975,
    "name": "College Park Inc    ( Fraser MI USA )",
    "country": "United States"
  },
  {
    "id": 466778,
    "name": "Collier's Medical Equipment    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 174094,
    "name": "Collimax Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452011,
    "name": "COLLINS MEDICAL NSPIRE HEALTH    ( Longmont CO USA )",
    "country": "United States"
  },
  {
    "id": 107286,
    "name": "Collis Curve Inc    ( Brownsville TX USA )",
    "country": "United States"
  },
  {
    "id": 452182,
    "name": "Colombo Sales and Engineering Inc    ( Ferndale MI USA )",
    "country": "United States"
  },
  {
    "id": 107692,
    "name": "Colonial Funding Corp    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 371753,
    "name": "Colonial Medical Assisted Devices    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 227144,
    "name": "Colonial Scientific Ltd (CSL)    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 138223,
    "name": "Coloplast A/S    ( Humlebaek  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 306956,
    "name": "Coloplast A/S    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306909,
    "name": "Coloplast A/S (South Africa)    ( Woodmead  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 105536,
    "name": "Coloplast Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 282352,
    "name": "Coloplast GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 176297,
    "name": "Coloplast Ltd    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 364333,
    "name": "Color Change Corp    ( Streamwood IL USA )",
    "country": "United States"
  },
  {
    "id": 170279,
    "name": "COMET North America Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 454687,
    "name": "ColorSafe IV Lines    ( Guerneville CA USA )",
    "country": "United States"
  },
  {
    "id": 338684,
    "name": "Colortrieve Record Systems Inc    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 408222,
    "name": "Colours in Motion Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 172583,
    "name": "Coltene AG    ( Altstaetten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 336515,
    "name": "Coltene/Whaledent GmbH & KoKG    ( Langenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 108578,
    "name": "Coltene/Whaledent Inc    ( Cuyahoga Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 246117,
    "name": "Coltene/Whaledent Ltd    ( Burgess Hill  England )",
    "country": "UK"
  },
  {
    "id": 262282,
    "name": "Coltene/Whaledent Sarl    ( Saint Ouen Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 107287,
    "name": "Columbia Dentoform Corp    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 461372,
    "name": "Columbia Healthcare Analytics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 170277,
    "name": "Columbia Imaging Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 999519,
    "name": "Columbia Instruments Inc.    ( Trussville AL USA )",
    "country": "United States"
  },
  {
    "id": 108334,
    "name": "Columbia Medical Mfg Corp    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 460931,
    "name": "Columbia Neon Co    ( Columbia TN USA )",
    "country": "United States"
  },
  {
    "id": 441589,
    "name": "Columbus Industries Inc    ( Ashville OH USA )",
    "country": "United States"
  },
  {
    "id": 101171,
    "name": "Columbus Instruments    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 105710,
    "name": "Columbus McKinnon Corp Mobility Products Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 460932,
    "name": "Columbus Medical Equipment    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 322907,
    "name": "Colvin Designs & Distribution    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 452823,
    "name": "Com.Med GmbH    ( Barleben  Germany )",
    "country": "Germany"
  },
  {
    "id": 292921,
    "name": "Comar Inc    ( Buena NJ USA )",
    "country": "United States"
  },
  {
    "id": 440637,
    "name": "Comark Ltd    ( Hitchin  England )",
    "country": "UK"
  },
  {
    "id": 338328,
    "name": "Combact Diagnostic Systems Ltd    ( Herzliya  Israel )",
    "country": "Israel"
  },
  {
    "id": 466777,
    "name": "Combat Medical Systems    ( Fayetteville NC USA )",
    "country": "United States"
  },
  {
    "id": 398222,
    "name": "Combe Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 999702,
    "name": "Combi Wellness    (   USA )",
    "country": "United States"
  },
  {
    "id": 293593,
    "name": "Combo AG    ( Solothurn  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 303201,
    "name": "Comdek Industrial Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 322531,
    "name": "Comdek Industrial Corp (Switzerland)    ( Rikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 152459,
    "name": "Comecer srl    ( Castelbolognese (RA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 293857,
    "name": "Comed    ( Strasbourg  France )",
    "country": "France"
  },
  {
    "id": 139169,
    "name": "COMEG Endoskopie GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 298378,
    "name": "COMEG USA Endoscopy Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 157678,
    "name": "Comento AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 306411,
    "name": "Comepa    ( Bagnolet Cedex  France )",
    "country": "France"
  },
  {
    "id": 335051,
    "name": "Comercial de Especialidades Medicas SA de CV    ( Guadalajara  USA )",
    "country": "United States"
  },
  {
    "id": 416010,
    "name": "Comercializadora Dentadec SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 334654,
    "name": "Comerical MAJESA    ( Saltillo  USA )",
    "country": "United States"
  },
  {
    "id": 439519,
    "name": "Comesa Budapest kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 186833,
    "name": "Comesa Ges mbH    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 439521,
    "name": "Comesa Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 439520,
    "name": "Comesa Spol sro    ( Praha 10  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 149063,
    "name": "COMET AG    ( Flamatt  USA )",
    "country": "United States"
  },
  {
    "id": 450535,
    "name": "Comet China    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 279105,
    "name": "COMET France    ( Wissous  USA )",
    "country": "United States"
  },
  {
    "id": 155374,
    "name": "Comfort Concepts Inc    ( Ridgefield NJ USA )",
    "country": "United States"
  },
  {
    "id": 460939,
    "name": "Comfort Craft Inc    ( Longwood FL USA )",
    "country": "United States"
  },
  {
    "id": 172593,
    "name": "Comfort House    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 456239,
    "name": "Comfort Message Appliance Co Ltd    ( Huangshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455842,
    "name": "Comfort Plus Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456007,
    "name": "Comfort Rubber Glove Industries Sdn Bhd    ( Matang Taiping  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 176127,
    "name": "Comfort Technologies Inc    ( Watchung NJ USA )",
    "country": "United States"
  },
  {
    "id": 322909,
    "name": "Comfort Wedge Inc    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 106722,
    "name": "Comfortex Inc    ( Winona MN USA )",
    "country": "United States"
  },
  {
    "id": 460942,
    "name": "ComfortSoul    ( Baldwin Park CA USA )",
    "country": "United States"
  },
  {
    "id": 456414,
    "name": "Cominox srl    ( Carate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453793,
    "name": "Comis Orthopaedics Ltd    ( Rotherham  England )",
    "country": "UK"
  },
  {
    "id": 461082,
    "name": "Commdex Consulting LLC    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 461073,
    "name": "Commend Inc    ( Mahweh NJ USA )",
    "country": "United States"
  },
  {
    "id": 466412,
    "name": "Commercial Sales & Service Inc (CSS)    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 461173,
    "name": "Commercial Stainless Fabricators Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 107142,
    "name": "Commercial/Medical Electronics Inc    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 461077,
    "name": "Commerical Alchohols    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 106288,
    "name": "Commodities Recovery Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 458334,
    "name": "Common Cents Systems Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 455776,
    "name": "Common Sense    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 330646,
    "name": "Commonwealth X-Ray Inc    ( Nicholasville KY USA )",
    "country": "United States"
  },
  {
    "id": 458527,
    "name": "CommScope Inc    ( Hickory NC USA )",
    "country": "United States"
  },
  {
    "id": 272874,
    "name": "Communications & Power Industries Canada    ( Georgetown ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451246,
    "name": "Communications & Power Industries International In    ( Cham  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 272875,
    "name": "Communications & Power Industries USA    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 461373,
    "name": "Communications Engineering Company    ( Hiawatha IA USA )",
    "country": "United States"
  },
  {
    "id": 412972,
    "name": "Community Blood Center/Community Tissue Services    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 461069,
    "name": "CommVault    ( Oceanport NJ USA )",
    "country": "United States"
  },
  {
    "id": 106440,
    "name": "Comp Pro Med Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 453424,
    "name": "Compactors Inc    ( Hilton Head Island SC USA )",
    "country": "United States"
  },
  {
    "id": 462602,
    "name": "CompAir Inc    ( Middletown CT USA )",
    "country": "United States"
  },
  {
    "id": 337648,
    "name": "Compania Industrial America SA de CV    ( Guadalajara  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 462945,
    "name": "Compania Mexicana de Radiologia    ( El Marques  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 348677,
    "name": "Companion Technologies Corp    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 147189,
    "name": "Companion Walker Ltd    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 232610,
    "name": "COMPASS International Inc    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 457064,
    "name": "Compass Medical Equipment Inc    ( Lake Grove NY USA )",
    "country": "United States"
  },
  {
    "id": 465113,
    "name": "Compass Sign Company    ( Bensalem 15 USA )",
    "country": "United States"
  },
  {
    "id": 461058,
    "name": "Compel Office Furniture    ( Forest Park IL USA )",
    "country": "United States"
  },
  {
    "id": 457472,
    "name": "Competitive Technologies Inc    ( Fairfield CT USA )",
    "country": "United States"
  },
  {
    "id": 373250,
    "name": "Compex SA    ( Ecublens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 428610,
    "name": "Compex Technologies Inc    ( New Brighton MN USA )",
    "country": "United States"
  },
  {
    "id": 457065,
    "name": "Complete Medical Services    ( Sterling Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 185813,
    "name": "Complete System Diagnostics Inc    ( Dixon CA USA )",
    "country": "United States"
  },
  {
    "id": 409893,
    "name": "CompleWare Corp    ( North Liberty IA USA )",
    "country": "United States"
  },
  {
    "id": 458804,
    "name": "Compliance 360 Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 461112,
    "name": "Compliance Concepts Inc    ( Wexford PA USA )",
    "country": "United States"
  },
  {
    "id": 461115,
    "name": "Compliance Consulting Service Inc    ( Angola IN USA )",
    "country": "United States"
  },
  {
    "id": 291714,
    "name": "Component Mfg Works of Nuclear Power Institute of    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 108864,
    "name": "Component Systems Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 465215,
    "name": "Comporium Communications    ( Rock Hill 17 USA )",
    "country": "United States"
  },
  {
    "id": 457644,
    "name": "Composite Resources    ( Rock Hill SC USA )",
    "country": "United States"
  },
  {
    "id": 181818,
    "name": "COMPOSITE ROTO INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 170285,
    "name": "Composites Horizons Inc    ( Covina CA USA )",
    "country": "United States"
  },
  {
    "id": 233610,
    "name": "Comprehensive Technologies International Inc    ( Fairfax VA USA )",
    "country": "United States"
  },
  {
    "id": 376072,
    "name": "Compression Therapy Concepts Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 293414,
    "name": "Comptronix AG    ( Horgen  USA )",
    "country": "United States"
  },
  {
    "id": 233586,
    "name": "Compu-Med Claims Service    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 292730,
    "name": "Compu-TTY Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 454280,
    "name": "CompuCyte Corp    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 457714,
    "name": "CompuGroup Medical Deutschland AG    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 462947,
    "name": "CompuGroup Medical Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 104817,
    "name": "CompuLab Healthcare Systems Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 101172,
    "name": "CompuMed Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 442102,
    "name": "Compumedics Germany GmbH    ( Singen  Germany )",
    "country": "Germany"
  },
  {
    "id": 267325,
    "name": "Compumedics Ltd    ( Abbotsford  Australia )",
    "country": "Australia"
  },
  {
    "id": 442072,
    "name": "Compumedics Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 146905,
    "name": "Compumedics USA Ltd    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 466535,
    "name": "Compuquip Technologies Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 183788,
    "name": "Computational Diagnostics Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 199678,
    "name": "Computer Administration Inc    ( Lindenhurst NY USA )",
    "country": "United States"
  },
  {
    "id": 176133,
    "name": "Computer Applications Unlimited Inc    ( Harrisburg PA USA )",
    "country": "United States"
  },
  {
    "id": 184308,
    "name": "Computer Automated Systems Specialists Inc (CASS)    ( Xenia OH USA )",
    "country": "United States"
  },
  {
    "id": 338997,
    "name": "Computer Modules Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 150978,
    "name": "Computer Resources International A/S    ( Birkeroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 229217,
    "name": "Computer Sciences Corp    ( Falls Church VA USA )",
    "country": "United States"
  },
  {
    "id": 458643,
    "name": "Computer Sports Medicine Inc    ( Stoughton MA USA )",
    "country": "United States"
  },
  {
    "id": 195187,
    "name": "Computer Trust Corp    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 461124,
    "name": "Computer-RX    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 454857,
    "name": "Computerised Information Technology Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 105898,
    "name": "Computerized Imaging Reference Systems Inc    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 458333,
    "name": "Computerized Imaging Reference Systems Inc    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 330656,
    "name": "Computerized Radiation Scanners Inc    ( Vero Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 459061,
    "name": "Computerized Screening Inc (CSI)    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 398387,
    "name": "Computerized Thermal Imaging Inc    ( Ogden UT USA )",
    "country": "United States"
  },
  {
    "id": 176136,
    "name": "Computers Unlimited Inc    ( Billings MT USA )",
    "country": "United States"
  },
  {
    "id": 458922,
    "name": "Computrition    ( West Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 102501,
    "name": "Computype Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 452989,
    "name": "CompView Medical    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 355534,
    "name": "COMSA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 108507,
    "name": "Comtex Inc    ( East Rutherford NJ USA )",
    "country": "United States"
  },
  {
    "id": 161756,
    "name": "Comweld Group Pty Ltd    ( Preston  Australia )",
    "country": "Australia"
  },
  {
    "id": 461129,
    "name": "Con Cell Corp    ( Patterson NY USA )",
    "country": "United States"
  },
  {
    "id": 409558,
    "name": "Conair Corp    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 456890,
    "name": "Concateno    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 399283,
    "name": "Concentric Medical Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 104764,
    "name": "Concept 2 Inc    ( Morrisville VT USA )",
    "country": "United States"
  },
  {
    "id": 427951,
    "name": "Concept Service    ( Wadesboro NC USA )",
    "country": "United States"
  },
  {
    "id": 264023,
    "name": "Conception Technologies LP    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454538,
    "name": "Conceptual MindWorks Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 235997,
    "name": "Conceptus Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 417356,
    "name": "Conceptus Pty Ltd    ( Ultimo  USA )",
    "country": "United States"
  },
  {
    "id": 417355,
    "name": "Conceptus SAS    ( Versailles  France )",
    "country": "France"
  },
  {
    "id": 441128,
    "name": "CONCOA America    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 360424,
    "name": "CONCOA Europa    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 175976,
    "name": "Concorde Battery Corp    ( West Covina CA USA )",
    "country": "United States"
  },
  {
    "id": 457000,
    "name": "Concorde Specialty Gases Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 453853,
    "name": "Concuity    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 293705,
    "name": "Condecta AG    ( Winterthur  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 466306,
    "name": "Condor GmbH Medicaltechnik    ( Salzkotten  Germany )",
    "country": "Germany"
  },
  {
    "id": 293759,
    "name": "Condylator-Service    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 103416,
    "name": "Cone Instruments Inc    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 464977,
    "name": "Conexio Genomics Pty Ltd    ( Fremantle  Australia )",
    "country": "Australia"
  },
  {
    "id": 458390,
    "name": "Conexsys Communications Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 415991,
    "name": "Confident Care Products    ( Ingleburn  Australia )",
    "country": "Australia"
  },
  {
    "id": 433300,
    "name": "Confirma Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 455423,
    "name": "Confluent Surgical Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454325,
    "name": "ConforMIS Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 227949,
    "name": "Conkin Surgical Instruments Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101345,
    "name": "CONMED Corp    ( Utica NY USA )",
    "country": "United States"
  },
  {
    "id": 451569,
    "name": "CONMED Endoscopic Technologies    ( Utica NY USA )",
    "country": "United States"
  },
  {
    "id": 428183,
    "name": "CONMED Integrated Systems    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 455069,
    "name": "CONMED Integrated Systems (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451026,
    "name": "CONMED Linvatec    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 456866,
    "name": "CONMED Linvatec Europe    ( Drogenbos  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 466453,
    "name": "Core Finance Team    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 108139,
    "name": "Conneaut Lake Scientific    ( Hartstown PA USA )",
    "country": "United States"
  },
  {
    "id": 434259,
    "name": "Connect Imaging Inc    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 458632,
    "name": "Connected Technology Solutions    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 392263,
    "name": "Connecticut Bioinstruments    ( Riverdale NY USA )",
    "country": "United States"
  },
  {
    "id": 426269,
    "name": "Connecticut Stamping & Bending Co Inc    ( New Britain CT USA )",
    "country": "United States"
  },
  {
    "id": 454141,
    "name": "Connectorate AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458841,
    "name": "Conner Athletic Products Inc    ( Jefferson IA USA )",
    "country": "United States"
  },
  {
    "id": 454551,
    "name": "Connexin Software Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 454400,
    "name": "Conney Safety    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 451157,
    "name": "Conney Safety Products    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 999332,
    "name": "Connoisseurs    (   USA )",
    "country": "United States"
  },
  {
    "id": 352924,
    "name": "Conqueror Surgical Industries    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 106726,
    "name": "Conrac Inc    ( Baldwin Park CA USA )",
    "country": "United States"
  },
  {
    "id": 453815,
    "name": "Conroe Welding Supplies Inc    ( Conroe TX USA )",
    "country": "United States"
  },
  {
    "id": 454879,
    "name": "Conroy Medical AB    ( Upplands Vasby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 458335,
    "name": "Consensus Medical Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 399772,
    "name": "Consilium Components (Germany)    ( Paderborn  USA )",
    "country": "United States"
  },
  {
    "id": 370868,
    "name": "Consilium Components AB    ( Ulricehamn  USA )",
    "country": "United States"
  },
  {
    "id": 466784,
    "name": "Consolidated Neuro Supply Inc    ( Loveland OH USA )",
    "country": "United States"
  },
  {
    "id": 232719,
    "name": "Consolidated Polymer Technologies Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 469514,
    "name": "Consolidated Sterilizer Systems    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 102416,
    "name": "Consolidated Stills & Sterilizers    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 153794,
    "name": "Consolidated Technologies Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 452678,
    "name": "Consort Medical plc    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 177657,
    "name": "Consort NV    ( Turnhout  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 330671,
    "name": "Construcciones Roentgen Ibericas SA (CRISA)    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 106539,
    "name": "Construction Specialties Inc    ( Lebanon NJ USA )",
    "country": "United States"
  },
  {
    "id": 306089,
    "name": "Consulchem Pty Ltd    ( Scoresby  Australia )",
    "country": "Australia"
  },
  {
    "id": 999448,
    "name": "Consult Diagnostics    (   USA )",
    "country": "United States"
  },
  {
    "id": 155408,
    "name": "Consultants for Communication Technology    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 468425,
    "name": "Consultronix SA    ( Krakow  Poland )",
    "country": "Poland"
  },
  {
    "id": 108750,
    "name": "Consumer Care Products Inc    ( Sussex WI USA )",
    "country": "United States"
  },
  {
    "id": 431502,
    "name": "Container Products Corp    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 457280,
    "name": "Containment Technologies Group Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 999608,
    "name": "Containment Technologies Group Inc.    ( Indianapolis In USA )",
    "country": "United States"
  },
  {
    "id": 999418,
    "name": "Contamination Control Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 292929,
    "name": "Contec Inc    ( Spartanburg SC USA )",
    "country": "United States"
  },
  {
    "id": 454289,
    "name": "Contec Inc    ( Spartanburg SC USA )",
    "country": "United States"
  },
  {
    "id": 365355,
    "name": "Contec Medical bv    ( Waalwijk  USA )",
    "country": "United States"
  },
  {
    "id": 298908,
    "name": "Contec Medical Inc (USA)    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 162297,
    "name": "Contec Medical Ltd (Israel)    ( Ramat Hasharon  Israel )",
    "country": "Israel"
  },
  {
    "id": 457271,
    "name": "Contec Medical Systems    ( Alhambra CA USA )",
    "country": "United States"
  },
  {
    "id": 456203,
    "name": "Contec Medical Systems Co Ltd    ( Qinhuangdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452185,
    "name": "Contec Vision Inc (USA)    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 452186,
    "name": "Contec Vision Ltd (Israel)    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 402611,
    "name": "CONTEMPORARY PRODUCTS    (   USA )",
    "country": "United States"
  },
  {
    "id": 108405,
    "name": "Contemporary Products Inc    ( Middletown CT USA )",
    "country": "United States"
  },
  {
    "id": 410021,
    "name": "Conterra Inc    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 462659,
    "name": "Continental Access A Napco Security Group Company    ( Amityville NY USA )",
    "country": "United States"
  },
  {
    "id": 104609,
    "name": "Continental Electronic Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 457097,
    "name": "Continental Equipment Co    ( Tonganoxie KS USA )",
    "country": "United States"
  },
  {
    "id": 105326,
    "name": "Continental Metal Products Co Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 458392,
    "name": "Continental Refrigerator Co    ( Bensalem PA USA )",
    "country": "United States"
  },
  {
    "id": 458308,
    "name": "Continental Scientific    ( Bensalem PA USA )",
    "country": "United States"
  },
  {
    "id": 999709,
    "name": "Continental Scientific    ( Bensalem PA USA )",
    "country": "USA"
  },
  {
    "id": 458336,
    "name": "Continuum    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 103030,
    "name": "Contour Form Products    ( Greenville PA USA )",
    "country": "United States"
  },
  {
    "id": 451307,
    "name": "Contour Plastics Inc    ( Baldwin WI USA )",
    "country": "United States"
  },
  {
    "id": 418932,
    "name": "ContourMed    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 453987,
    "name": "Control Co    ( Friendswood TX USA )",
    "country": "United States"
  },
  {
    "id": 377698,
    "name": "Control Concepts Inc    ( Putnam CT USA )",
    "country": "United States"
  },
  {
    "id": 267427,
    "name": "Control Optics    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 461532,
    "name": "Control Services Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 465058,
    "name": "Control Technology Inc    ( Sioux Falls  USA )",
    "country": "United States"
  },
  {
    "id": 339002,
    "name": "Control-O-Fax Corp    ( Waterloo IA USA )",
    "country": "United States"
  },
  {
    "id": 108554,
    "name": "Control-X Medical Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 452882,
    "name": "Control-X Medical Ltd    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 107291,
    "name": "Controlled Environment Equipment Corp    ( Gorham ME USA )",
    "country": "United States"
  },
  {
    "id": 107997,
    "name": "Controlled Power Co    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 456819,
    "name": "Contura International A/S    ( Soeborg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 104136,
    "name": "Convaid Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 107292,
    "name": "ConvaQuip Industries Inc    ( Abilene TX USA )",
    "country": "United States"
  },
  {
    "id": 102660,
    "name": "ConvaTec    ( Skillman NJ USA )",
    "country": "United States"
  },
  {
    "id": 457547,
    "name": "ConvaTec (Denmark) ApS    ( Birkeroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 456480,
    "name": "ConvaTec (Dominican Republic)    ( Santo Damingo  Dominican Republic )",
    "country": "Dominican Republic"
  },
  {
    "id": 298085,
    "name": "ConvaTec (European Region)    ( Uxbridge  USA )",
    "country": "United States"
  },
  {
    "id": 298083,
    "name": "ConvaTec (North Pacific Region)    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 306913,
    "name": "ConvaTec (South Africa)    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 284598,
    "name": "ConvaTec Vertriebs GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461012,
    "name": "Convergence Communications    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 343481,
    "name": "Convergent Laser Technologies    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 461008,
    "name": "Convergint Technologies LLC    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 453125,
    "name": "Convertors de Mexico SA de CV    ( Juarez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 474852,
    "name": "ConVida Healthcare & Systems Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 461175,
    "name": "Conviron    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 455854,
    "name": "Conwell Medical Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 298492,
    "name": "Cook (UK) Ltd    ( Letchworth  England )",
    "country": "UK"
  },
  {
    "id": 357734,
    "name": "Cook Biotech Inc    ( West Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 174580,
    "name": "Cook Canada Inc    ( Stouffville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451488,
    "name": "Cook Endoscopy    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 461005,
    "name": "Cook General BioTechnology LLC    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 102243,
    "name": "Cook Inc    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 452265,
    "name": "Cook Medical - Womens Health Div    ( Spencer IN USA )",
    "country": "United States"
  },
  {
    "id": 362064,
    "name": "Cook OB/Gyn    ( Spencer IN USA )",
    "country": "United States"
  },
  {
    "id": 461303,
    "name": "Cook Ross Inc    ( Silver Spring MD USA )",
    "country": "United States"
  },
  {
    "id": 353233,
    "name": "Cook Southeast Asia    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 271585,
    "name": "Cook Surgical    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 104467,
    "name": "Cook Urological Inc    ( Spencer IN USA )",
    "country": "United States"
  },
  {
    "id": 321004,
    "name": "Cook Vascular Inc    ( Vandergrift PA USA )",
    "country": "United States"
  },
  {
    "id": 455540,
    "name": "Cookgas LLC    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 370870,
    "name": "Cool Pair Plus    ( Algonquin IL USA )",
    "country": "United States"
  },
  {
    "id": 455777,
    "name": "Cool Sense Medical Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 465017,
    "name": "Cool-Rite Appliances    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 455312,
    "name": "Cool-View LLC    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 457940,
    "name": "CoolLED Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 457941,
    "name": "CoolLED USA & Canada    ( Yorktown Heights NY USA )",
    "country": "United States"
  },
  {
    "id": 453546,
    "name": "CoolSystems Inc    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 360375,
    "name": "CoolTouch Corp    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 455597,
    "name": "Cooper Bussmann Inc    ( Ellisville MO USA )",
    "country": "United States"
  },
  {
    "id": 150100,
    "name": "Cooper Industries    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 999402,
    "name": "Cooper Laboratories, Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 101865,
    "name": "Cooper LaserSonics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 999582,
    "name": "Cooper US, Inc.    ( Houston TX USA )",
    "country": "USA"
  },
  {
    "id": 392939,
    "name": "Cooper Wiring Devices    ( Peachtree City GA USA )",
    "country": "United States"
  },
  {
    "id": 456532,
    "name": "Cooper-Atkins    ( Middlefield CT USA )",
    "country": "United States"
  },
  {
    "id": 306628,
    "name": "Coopercare Deutschland    ( Dietenhofen  USA )",
    "country": "United States"
  },
  {
    "id": 174663,
    "name": "Coopercare Lastrap Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108594,
    "name": "CooperSurgical Inc    ( Trumbull CT USA )",
    "country": "United States"
  },
  {
    "id": 105480,
    "name": "CooperVision Inc    ( Fairport NY USA )",
    "country": "United States"
  },
  {
    "id": 103091,
    "name": "CooperVision Inc (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461306,
    "name": "Coordinator's Choice    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 453981,
    "name": "Copan Diagnostics Inc    ( Murrieta CA USA )",
    "country": "United States"
  },
  {
    "id": 453982,
    "name": "Copan Italia SpA    ( Brescia  Italy )",
    "country": "Italy"
  },
  {
    "id": 184257,
    "name": "Copharm bv    ( Abcoude  USA )",
    "country": "United States"
  },
  {
    "id": 444450,
    "name": "Copharm Maquet bv    ( Abcoude  USA )",
    "country": "United States"
  },
  {
    "id": 458773,
    "name": "Copystars.com    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 293298,
    "name": "Coramed AG    ( Dietlikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 335113,
    "name": "Cord-Mate Inc    ( Cheshire CT USA )",
    "country": "United States"
  },
  {
    "id": 467335,
    "name": "Cordata Healthcare Innovations LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 307277,
    "name": "Cordis    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 102176,
    "name": "Cordis Corp    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 183027,
    "name": "Cordis Endovascular    ( Warren NJ USA )",
    "country": "United States"
  },
  {
    "id": 151219,
    "name": "Cordis Europa NV    ( Roden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451004,
    "name": "Core Brands    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 458823,
    "name": "CoRE Labs    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 464991,
    "name": "Core Medical Imaging Inc    ( Kenmore WA USA )",
    "country": "United States"
  },
  {
    "id": 461053,
    "name": "Core Power & Environment    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 147194,
    "name": "Core Products International Inc    ( Osceola WI USA )",
    "country": "United States"
  },
  {
    "id": 465034,
    "name": "Core Surgical LLC    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 456253,
    "name": "Core Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455857,
    "name": "Coreleader Biotech Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455813,
    "name": "Corentec Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 460774,
    "name": "Corepoint Health    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 363284,
    "name": "Corflex Inc    ( Manchester NH USA )",
    "country": "United States"
  },
  {
    "id": 364307,
    "name": "Corgenix Inc    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 363659,
    "name": "Corgenix UK Ltd    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 154408,
    "name": "Corin Group plc    ( Cirencester  England )",
    "country": "UK"
  },
  {
    "id": 273682,
    "name": "Corin USA    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 452857,
    "name": "Corium International Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 461045,
    "name": "CorLogix    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 460545,
    "name": "CorMatrix Cardiovascular Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 272398,
    "name": "Cormedica SA    ( Vaulx-Milieu  France )",
    "country": "France"
  },
  {
    "id": 458665,
    "name": "CorMedix Inc    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 334863,
    "name": "Corne SA de CV    ( Garza Garcia  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 402295,
    "name": "Corneal Espana SL    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 402296,
    "name": "Corneal GmbH    ( Hallbergmoos  USA )",
    "country": "United States"
  },
  {
    "id": 279116,
    "name": "Corneal SA    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 178868,
    "name": "Cornell Communications Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 416102,
    "name": "Corning bv    ( Schiphol-Rijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 460748,
    "name": "Corning Cable Systems LLC    ( Hickory NC USA )",
    "country": "United States"
  },
  {
    "id": 348701,
    "name": "Corning Inc    ( Lowell MA USA )",
    "country": "United States"
  },
  {
    "id": 416103,
    "name": "Corning Mexicana SA    ( San Nicolas  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 452775,
    "name": "Corning SAS    ( Avon cedex  France )",
    "country": "France"
  },
  {
    "id": 307278,
    "name": "Corometrics    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 999486,
    "name": "Coronet Lighting    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 199659,
    "name": "CORPAK MedSystems    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 281941,
    "name": "CorpoMed Gesundheitskissen GmbH    ( Geesthacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 453078,
    "name": "Corpomedica SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 461038,
    "name": "Corporate Lamp and Electronic Recycling    ( Kennett Square PA USA )",
    "country": "United States"
  },
  {
    "id": 462651,
    "name": "Corporate Security Service Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 155763,
    "name": "Corso Enterprises Inc    ( Sausalito CA USA )",
    "country": "United States"
  },
  {
    "id": 465100,
    "name": "Cortech Solutions, Inc.    ( Wilmington 14 USA )",
    "country": "United States"
  },
  {
    "id": 464926,
    "name": "CorTechs Labs Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 371527,
    "name": "CorTek Endoscopy Inc    ( Wauconda IL USA )",
    "country": "United States"
  },
  {
    "id": 451686,
    "name": "Cortessis    ( Keratsini Piraeus  Greece )",
    "country": "Greece"
  },
  {
    "id": 280408,
    "name": "Cortex Biophysik GmbH    ( Leipzig  Germany )",
    "country": "Germany"
  },
  {
    "id": 193960,
    "name": "Cortex MMS Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 139383,
    "name": "Cortex Technology ApS    ( Hadsund  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 277927,
    "name": "Cortiton Hearing Aid Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450833,
    "name": "Corus Group    ( London  England )",
    "country": "UK"
  },
  {
    "id": 464809,
    "name": "CorVascular LLC    ( Maple Plain MN USA )",
    "country": "United States"
  },
  {
    "id": 473831,
    "name": "Corwin Health Physics    ( Centralia WA USA )",
    "country": "United States"
  },
  {
    "id": 298494,
    "name": "Cory Brothers Ltd    ( Herts  England )",
    "country": "UK"
  },
  {
    "id": 309735,
    "name": "Corysan SA    ( Viladecans (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 293573,
    "name": "Cosanum AG    ( Schlieren  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459242,
    "name": "Cosco Fire Protection    ( San Juan Capistrano CA USA )",
    "country": "United States"
  },
  {
    "id": 451302,
    "name": "Cosman Medical Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 451340,
    "name": "COSMED (Deutschland) GmbH    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 439655,
    "name": "COSMED China    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 156975,
    "name": "COSMED srl    ( Pavona di Albano (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 416038,
    "name": "COSMED USA Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451156,
    "name": "Cosmedent Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 999802,
    "name": "CosMedic R&D Inc.    ( Rocklin CA USA )",
    "country": "USA"
  },
  {
    "id": 399234,
    "name": "Cosmedico Medizintechnik GmbH    ( Trossingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 439590,
    "name": "Cosmedico Technika Sp z o o    ( Jablonna  USA )",
    "country": "United States"
  },
  {
    "id": 439594,
    "name": "Cosmedico Technique Medicale    ( Saverne  USA )",
    "country": "United States"
  },
  {
    "id": 157905,
    "name": "Cosmo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 296802,
    "name": "Cosmo Laboratory Equipment    ( Ambala Cantt  India )",
    "country": "India"
  },
  {
    "id": 348567,
    "name": "Cosmopolitan Medical Communications    ( Glendale AZ USA )",
    "country": "United States"
  },
  {
    "id": 168439,
    "name": "Cosmos B Schild & Co AG    ( Biel-Bienne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 277928,
    "name": "Cosmotec Co Ltd    ( Koriyama City  Japan )",
    "country": "Japan"
  },
  {
    "id": 330012,
    "name": "CostFlex System Inc    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 225963,
    "name": "Coulbourn Instruments    ( Whitehall PA USA )",
    "country": "United States"
  },
  {
    "id": 458232,
    "name": "Coulmed Products Group LLC    ( Congers NY USA )",
    "country": "United States"
  },
  {
    "id": 471744,
    "name": "Counsyl Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 147168,
    "name": "Country Technology Inc    ( Gays Mills WI USA )",
    "country": "United States"
  },
  {
    "id": 453047,
    "name": "Countrywide Healthcare Supplies Ltd    ( Barnsley  England )",
    "country": "UK"
  },
  {
    "id": 465175,
    "name": "County Restaurant Supply    ( San Carlos 71 USA )",
    "country": "United States"
  },
  {
    "id": 454832,
    "name": "Courage and Khazaka Electronic    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 461007,
    "name": "Courion Corp    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 471745,
    "name": "Courtagen Life Sciences Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 461006,
    "name": "Courtyard Group Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452473,
    "name": "Cousin Bioserv    ( Wervicq-Sud  France )",
    "country": "France"
  },
  {
    "id": 452474,
    "name": "Cousin Biotech    ( Wervicq-Sud  France )",
    "country": "France"
  },
  {
    "id": 451596,
    "name": "Covalence Plastics    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 376873,
    "name": "Covance Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 452215,
    "name": "Covance Research Products Inc    ( Berkeley CA USA )",
    "country": "United States"
  },
  {
    "id": 454420,
    "name": "Covaris Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 452294,
    "name": "Covidien    ( Mansfield MA USA )",
    "country": "United States"
  },
  {
    "id": 452298,
    "name": "Covidien    (   USA )",
    "country": "United States"
  },
  {
    "id": 458718,
    "name": "CPN Power Inc    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 452440,
    "name": "Covidien (Australia)    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 452441,
    "name": "Covidien (Belgium)    ( Mechelen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452295,
    "name": "Covidien (Canada)    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 452451,
    "name": "Covidien (Deutschland)    ( Neustradt/Donau  Germany )",
    "country": "Germany"
  },
  {
    "id": 452443,
    "name": "Covidien (France)    ( Elancourt  France )",
    "country": "France"
  },
  {
    "id": 452444,
    "name": "Covidien (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452450,
    "name": "Covidien (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452442,
    "name": "Covidien (Switzerland)    ( Wallerau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452449,
    "name": "Covidien (United Kingdom)    ( Gosport  England )",
    "country": "UK"
  },
  {
    "id": 452301,
    "name": "Covidien Auto Suture/United States Surgical/Synetu    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 452452,
    "name": "Covidien Mallinckrodt Benelux bv    ( Zaltbommel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452453,
    "name": "Covidien Mallinckrodt France Sarl    ( Courtaboeuf  France )",
    "country": "France"
  },
  {
    "id": 452446,
    "name": "Covidien Mallinckrodt Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452448,
    "name": "Covidien Mallinckrodt Medical GmbH    ( Hennef/Sieg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452445,
    "name": "Covidien Mallinckrodt Medical Sa de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 452304,
    "name": "COVIDIEN MALLINKRODT LEIBEL-FLARSHEIM COMPANY    ( Hazelwood MO USA )",
    "country": "United States"
  },
  {
    "id": 452300,
    "name": "Covidien Nellcor Puritan Bennett    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452303,
    "name": "Covidien Nellcor/Puritan Bennett Div Covidien    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 456871,
    "name": "Covidien Private Ltd (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 458114,
    "name": "Covidien RMS (Respiratory & Monitoring Solutions)    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 455671,
    "name": "Covidien Somanetics    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 456873,
    "name": "Covidien Surgical Solutions Group    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 452299,
    "name": "Covidien Uni-Patch    ( Wabasha MN USA )",
    "country": "United States"
  },
  {
    "id": 452305,
    "name": "Covidien Valley Lab    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 107296,
    "name": "Covoc Corp    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 279974,
    "name": "Covoc Med Techn Vertriebs GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 155744,
    "name": "COW Industries Inc    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 281044,
    "name": "Cowadental Cohen & Co GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 363968,
    "name": "Cowen Medical Technologies Inc    ( Inverness FL USA )",
    "country": "United States"
  },
  {
    "id": 104575,
    "name": "Coy Laboratory Products Inc    ( Grass Lake MI USA )",
    "country": "United States"
  },
  {
    "id": 458001,
    "name": "Cozy Calm LLC    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 454361,
    "name": "CP Health Group Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 173214,
    "name": "CP Instruments Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 184249,
    "name": "CP Medical    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 451744,
    "name": "CP Medical Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 441010,
    "name": "CPAC Imaging    ( Leicester NY USA )",
    "country": "United States"
  },
  {
    "id": 104401,
    "name": "CPAC Inc    ( Leicester NY USA )",
    "country": "United States"
  },
  {
    "id": 418644,
    "name": "CPC of America Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 466927,
    "name": "CPI Communications    ( Wylie TX USA )",
    "country": "United States"
  },
  {
    "id": 153257,
    "name": "CPI GmbH    (   USA )",
    "country": "United States"
  },
  {
    "id": 460825,
    "name": "CPM Healthgrades    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 352928,
    "name": "CPR (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 352927,
    "name": "CPR (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455936,
    "name": "CPR Savers & First Aid Supply LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 458808,
    "name": "CPR Technologies    ( Stevenson Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 339003,
    "name": "CPS Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 455197,
    "name": "CPS Technologies    ( Norton MA USA )",
    "country": "United States"
  },
  {
    "id": 107984,
    "name": "CPSI    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 349198,
    "name": "CPU Medical Management Systems    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 171766,
    "name": "CR Medical Med Techn Vertriebs GmbH    ( Linz  Austria )",
    "country": "Austria"
  },
  {
    "id": 455814,
    "name": "CR Technology    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999788,
    "name": "Craftmatic Adjustable Bed and Lift Care    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 999663,
    "name": "Craftsman    (   USA )",
    "country": "United States"
  },
  {
    "id": 344939,
    "name": "Craig Dental Laboratory Group Inc    ( Poughkeepsie NY USA )",
    "country": "United States"
  },
  {
    "id": 471729,
    "name": "Cramer LLC    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 104975,
    "name": "Cramer Products    ( Gardner KS USA )",
    "country": "United States"
  },
  {
    "id": 460822,
    "name": "Crane Co    ( Bridgeton MA USA )",
    "country": "United States"
  },
  {
    "id": 458995,
    "name": "Craneware Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 360933,
    "name": "Cranial Technologies Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 371479,
    "name": "Crawford Diversified Services Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458774,
    "name": "CRC Assoc    ( Pipersville PA USA )",
    "country": "United States"
  },
  {
    "id": 460883,
    "name": "Creadio    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 322686,
    "name": "Creative Bedding Technologies Inc    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 230129,
    "name": "Creative Biomedics Inc    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 458741,
    "name": "Creative Change Inc    ( Glastonbury CT USA )",
    "country": "United States"
  },
  {
    "id": 155948,
    "name": "Creative Controls Inc    ( Madison Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 999479,
    "name": "Creative Living Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 458726,
    "name": "Creative Office Pavilion    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 103422,
    "name": "Creative Signage Systems Inc    ( College Park MD USA )",
    "country": "United States"
  },
  {
    "id": 465141,
    "name": "Creative Waste Solutions, Inc..    ( Tualatin x USA )",
    "country": "United States"
  },
  {
    "id": 454412,
    "name": "Creche Innovations    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 286178,
    "name": "Credo-Stahlwarenfabrik Gustav Kracht    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 460895,
    "name": "Creehan & Co Corp    ( Canonsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 466283,
    "name": "Creganna-Tactx Medical    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 460823,
    "name": "CreoSalus Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 103423,
    "name": "Cres Cor    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 471748,
    "name": "Crescendo Bioscience Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 287946,
    "name": "Crescendo Systems Corp    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 450568,
    "name": "Crescendo Systems Ltd    ( Hampton  England )",
    "country": "UK"
  },
  {
    "id": 103739,
    "name": "Crescent Corp    ( Port Huron MI USA )",
    "country": "United States"
  },
  {
    "id": 451134,
    "name": "Crest Healthcare Supply    ( Dassel MN USA )",
    "country": "United States"
  },
  {
    "id": 102244,
    "name": "Crest Ultrasonics Corp    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 108145,
    "name": "Crestline Coach Ltd    ( Saskatoon SK Canada )",
    "country": "Canada"
  },
  {
    "id": 176031,
    "name": "Crestline Products Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 453063,
    "name": "Crestpoint Management Ltd    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 292161,
    "name": "Crestron Electronics Inc    ( Rockleigh NJ USA )",
    "country": "United States"
  },
  {
    "id": 462917,
    "name": "Crestron Electronics Inc    ( Rockleigh NJ USA )",
    "country": "United States"
  },
  {
    "id": 339509,
    "name": "Crestwood Communication Aids Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 107782,
    "name": "CRI (Catheter Research Inc)    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 460817,
    "name": "Crisis Prevention Institute    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 155764,
    "name": "Criss Optical Mfg Co Inc    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 457754,
    "name": "Cristia Medical Supply Inc    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 454553,
    "name": "Criterions LLC    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 464144,
    "name": "Critical Alert Systems    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 416408,
    "name": "Critical Assist Group    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 106140,
    "name": "Critical Care Products Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 456833,
    "name": "Critical Diagnostics    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 460814,
    "name": "Critical Response Systems Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 170297,
    "name": "Critical Service Co Inc    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 105174,
    "name": "Criticare Systems Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 292162,
    "name": "CritiCom Inc    ( Lanham MD USA )",
    "country": "United States"
  },
  {
    "id": 360446,
    "name": "Critikon    (   USA )",
    "country": "United States"
  },
  {
    "id": 103105,
    "name": "Croan Engineering Co    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 184130,
    "name": "Crocker Industrial Resources Sdn Bhd    ( Senai  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 453940,
    "name": "Croma GmbH    ( Korneuburg  Austria )",
    "country": "Austria"
  },
  {
    "id": 330537,
    "name": "Cromptons Hospital Textile Products    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 104944,
    "name": "Cropper Medical Inc    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 999111,
    "name": "CROSLEY CORPORATION    (   USA )",
    "country": "United States"
  },
  {
    "id": 458271,
    "name": "Crospon Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 450603,
    "name": "Cross Medical Specialties Inc    ( Pitman NJ USA )",
    "country": "United States"
  },
  {
    "id": 173446,
    "name": "Crosstex International Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 402684,
    "name": "Crosstex International Inc Argentina    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 362127,
    "name": "Crosstex International Inc Europe    ( Lienden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 402687,
    "name": "Crosstex International Inc Japan    ( Suita  Japan )",
    "country": "Japan"
  },
  {
    "id": 999999,
    "name": "Crothall Healthcare    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 453907,
    "name": "Crothall Services Group    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 155765,
    "name": "Crowell Systems    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 312342,
    "name": "Crowley Micrographics Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 458367,
    "name": "Crown Equipment Co    ( New Bremen OH USA )",
    "country": "United States"
  },
  {
    "id": 323822,
    "name": "Crown Graphic Ltd    ( Totnes  England )",
    "country": "UK"
  },
  {
    "id": 454205,
    "name": "Crown Graphic UK    ( Devon  England )",
    "country": "UK"
  },
  {
    "id": 457433,
    "name": "Crown Medical Products Inc    ( Suffern NY USA )",
    "country": "United States"
  },
  {
    "id": 451189,
    "name": "Crown Scientific    ( Moorebank  Australia )",
    "country": "Australia"
  },
  {
    "id": 228391,
    "name": "Crown Software Inc    ( Killeen TX USA )",
    "country": "United States"
  },
  {
    "id": 106439,
    "name": "CRT Medical Systems Inc    ( Novi MI USA )",
    "country": "United States"
  },
  {
    "id": 153259,
    "name": "Cryo Anlagenbau GmbH    ( Wilnsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 453671,
    "name": "Cryo Bio Systems USA    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 455125,
    "name": "Cryo Solutions bv    ( 's-Hertogenbosch  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 465091,
    "name": "Cryo Store Bv    ( Joure  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 107297,
    "name": "Cryo Therapy Inc    ( Monticello MN USA )",
    "country": "United States"
  },
  {
    "id": 409853,
    "name": "CryoBio Systems    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 417433,
    "name": "CryoCath Technologies Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 108752,
    "name": "Cryofab Inc    ( Kenilworth NJ USA )",
    "country": "United States"
  },
  {
    "id": 416020,
    "name": "CryoFlex Poland    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 170298,
    "name": "Cryogenic Equipment & Repair Co Inc    ( Parlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 431506,
    "name": "CryoKinetics    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 455206,
    "name": "CryoLife Europa Ltd (Deutschland)    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455207,
    "name": "CryoLife Europa Ltd (United Kingdom)    ( Guildford  England )",
    "country": "UK"
  },
  {
    "id": 154426,
    "name": "CryoLife Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 177027,
    "name": "CryoLogic Pty Ltd    ( Mulgrave  Australia )",
    "country": "Australia"
  },
  {
    "id": 101192,
    "name": "Cryomed Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 185120,
    "name": "Cryomed Ltd    ( Mansfield Woodhouse  England )",
    "country": "UK"
  },
  {
    "id": 175270,
    "name": "Cryomedical Sciences Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 452437,
    "name": "Cryopak Industries Inc    ( St Leonard PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 457453,
    "name": "Cryopen Inc    ( Corpus Christi TX USA )",
    "country": "United States"
  },
  {
    "id": 458405,
    "name": "Cryosafe    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 461177,
    "name": "Cryospec Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 457199,
    "name": "CryoSports    ( London  England )",
    "country": "UK"
  },
  {
    "id": 335461,
    "name": "CryoSurgery Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 474895,
    "name": "CryoTec    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 459269,
    "name": "CryoXtract    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 107025,
    "name": "Crystal Biotech Inc    ( Northborough MA USA )",
    "country": "United States"
  },
  {
    "id": 466947,
    "name": "Crystal Engineering    ( San Luis Obispo CA USA )",
    "country": "United States"
  },
  {
    "id": 473818,
    "name": "Crystal Photonics GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 461307,
    "name": "CrystaLaser    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 457725,
    "name": "CS Alloys    ( Gastonia NC USA )",
    "country": "United States"
  },
  {
    "id": 283815,
    "name": "CS Chromatographie Service GmbH    ( Langerwehe  Germany )",
    "country": "Germany"
  },
  {
    "id": 456998,
    "name": "CS Medical LLC    ( Creedmoor NC USA )",
    "country": "United States"
  },
  {
    "id": 461119,
    "name": "CS STARS LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 453023,
    "name": "CSA Medical Inc    ( Lutherville MD USA )",
    "country": "United States"
  },
  {
    "id": 455189,
    "name": "CSC Scientific    ( Fairfax VA USA )",
    "country": "United States"
  },
  {
    "id": 415411,
    "name": "CSE Computer fuer Pharmazie und Medizin AG    ( Schlieren  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 281947,
    "name": "CSE Gesellschaft fuer Computer Software und Edv-Be    ( Gefrees  Germany )",
    "country": "Germany"
  },
  {
    "id": 416538,
    "name": "CSE-Servelec Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 459362,
    "name": "CSI-Jewett    ( Fairview PA USA )",
    "country": "United States"
  },
  {
    "id": 460821,
    "name": "CSI-Jewett    ( Fairview PA USA )",
    "country": "United States"
  },
  {
    "id": 455618,
    "name": "CSL Behring    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 456713,
    "name": "CSM Graf GmbH    ( Steinach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 469480,
    "name": "CSMDirect    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 192813,
    "name": "CSO (Costruzione Strumenti Oftalmologici) srl    ( Scandicci (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 174560,
    "name": "CSP Medical    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 162090,
    "name": "CSRS Inc    ( Downey CA USA )",
    "country": "United States"
  },
  {
    "id": 457935,
    "name": "CSS Healthcare Technologies    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 450884,
    "name": "CSZ International GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999942,
    "name": "CT Brand    (   USA )",
    "country": "United States"
  },
  {
    "id": 306774,
    "name": "CT EDV Consulting & Training GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 366513,
    "name": "CT International    ( San Luis Obispo CA USA )",
    "country": "United States"
  },
  {
    "id": 461309,
    "name": "CT-Innovations    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 461186,
    "name": "CT/HX Co LLC    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 452154,
    "name": "CTG Healthcare    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 456792,
    "name": "CTI Electronics Corp    ( Stratford CT USA )",
    "country": "United States"
  },
  {
    "id": 416026,
    "name": "CTL-Laserinstruments    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 454391,
    "name": "CTRL Systems Inc    ( Westminster MD USA )",
    "country": "United States"
  },
  {
    "id": 106775,
    "name": "CTronics Inc    ( Stockton CA USA )",
    "country": "United States"
  },
  {
    "id": 334122,
    "name": "CTS Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 999828,
    "name": "CTX Technology Corp.    ( City of Industry CA USA )",
    "country": "USA"
  },
  {
    "id": 455047,
    "name": "CU Medical Systems Inc    ( Uiwang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 473840,
    "name": "Cuattro Americas    ( Loveland CO USA )",
    "country": "United States"
  },
  {
    "id": 461312,
    "name": "Cubex LLC    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 453043,
    "name": "Cubist Pharmaceutical Inc    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 999524,
    "name": "Cuda Surgical    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 999432,
    "name": "Cuisinart    ( East Windsor NJ USA )",
    "country": "United States"
  },
  {
    "id": 102137,
    "name": "Culligan International Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 107580,
    "name": "Culture Kits Inc    ( Norwich NY USA )",
    "country": "United States"
  },
  {
    "id": 439167,
    "name": "Culture Media & Supplies Inc    ( Oswego IL USA )",
    "country": "United States"
  },
  {
    "id": 452232,
    "name": "Cummins Power Generation Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 466235,
    "name": "Cunningham Woodland, Inc.    ( Hyannis 35 USA )",
    "country": "United States"
  },
  {
    "id": 465147,
    "name": "CuraMedix, LLC    ( Lincoln 40 USA )",
    "country": "United States"
  },
  {
    "id": 458346,
    "name": "Curaspan Health Group    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 105327,
    "name": "Curbell Inc    ( Orchard Park NY USA )",
    "country": "United States"
  },
  {
    "id": 457387,
    "name": "Cure Medical    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 999774,
    "name": "CureLight    (   USA )",
    "country": "United States"
  },
  {
    "id": 454554,
    "name": "CureMD Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457646,
    "name": "Curetis AG    ( Holzgerlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454382,
    "name": "Curexo Technology Corp    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 320071,
    "name": "Curlin Medical LLC    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 376872,
    "name": "Curon Medical Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 105904,
    "name": "Current Technologies Inc    ( Crawfordsville IN USA )",
    "country": "United States"
  },
  {
    "id": 458600,
    "name": "Current Therapeutics Inc    ( Randolph NJ USA )",
    "country": "United States"
  },
  {
    "id": 370619,
    "name": "Currie Medical Specialties    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 104470,
    "name": "Curtin Matheson Scientific Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 470568,
    "name": "Curtis Bay Medical Waste Services    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 461216,
    "name": "Curtis Stout    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 233265,
    "name": "Curtiss-Wright Corp    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 456952,
    "name": "CurveBeam    ( Warrington PA USA )",
    "country": "United States"
  },
  {
    "id": 284600,
    "name": "Custo Med GmbH    ( Ottobrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 435247,
    "name": "Custom Biogenic Systems    ( Romeo MI USA )",
    "country": "United States"
  },
  {
    "id": 429119,
    "name": "Custom Coach International    ( Pawhuska OK USA )",
    "country": "United States"
  },
  {
    "id": 186828,
    "name": "Custom Comfort Inc    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 403722,
    "name": "Custom Healthcare Systems Inc    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 103741,
    "name": "Custom Hospital Products Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 105286,
    "name": "Custom Laboratories    (   USA )",
    "country": "United States"
  },
  {
    "id": 456164,
    "name": "Custom Medical Specialties Inc    ( Pine Level NC USA )",
    "country": "United States"
  },
  {
    "id": 452876,
    "name": "Custom Prosthetic Designs Inc    ( Ashburn VA USA )",
    "country": "United States"
  },
  {
    "id": 330049,
    "name": "Custom Software Systems Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 107046,
    "name": "Custom Ultrasonics Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 457248,
    "name": "CustomChill Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 456841,
    "name": "CUSTOMED    ( Fajardo  Puerto Rico )",
    "country": "Puerto Rico"
  },
  {
    "id": 461314,
    "name": "Customer Expressions Corp    ( Ottawa  Canada )",
    "country": "Canada"
  },
  {
    "id": 452428,
    "name": "Customized Credentialing Services LLC    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 441513,
    "name": "Cutera    ( Brisbane CA USA )",
    "country": "United States"
  },
  {
    "id": 317153,
    "name": "CVC (Cardiovascular Concepts) Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 330742,
    "name": "CVS/Walker Medical Sales Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 106287,
    "name": "CWE Inc    ( Ardmore PA USA )",
    "country": "United States"
  },
  {
    "id": 106578,
    "name": "CWECO Inc    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 187458,
    "name": "CX Medical Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 451772,
    "name": "Cyber Records Inc    ( Lawrence NY USA )",
    "country": "United States"
  },
  {
    "id": 457189,
    "name": "CyberDERM Inc    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 457437,
    "name": "CYBERDYNE Inc    ( Tsukuba  Japan )",
    "country": "Japan"
  },
  {
    "id": 454349,
    "name": "Cyberkinetics Neurotechnology Systems Inc    ( Foxboro MA USA )",
    "country": "United States"
  },
  {
    "id": 457011,
    "name": "CyberMedic Corp    ( Iksan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457592,
    "name": "Cybernet Mfg    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 454953,
    "name": "Cybernet Systems Corp    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 453033,
    "name": "Cybernius Medical Europe GmbH    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 331083,
    "name": "Cybernius Medical Ltd    ( St Albert AB Canada )",
    "country": "Canada"
  },
  {
    "id": 332072,
    "name": "Cyberonics Europe SA    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 135321,
    "name": "Cyberonics Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 473829,
    "name": "CyberPower Systems (USA) Inc    ( Shakopee MN USA )",
    "country": "United States"
  },
  {
    "id": 999522,
    "name": "CyberPower Systems, Inc.    ( Shakopee MN USA )",
    "country": "United States"
  },
  {
    "id": 273741,
    "name": "Cybersonics    (   USA )",
    "country": "United States"
  },
  {
    "id": 457006,
    "name": "Cybertronics Inc    ( New Orleans LA USA )",
    "country": "United States"
  },
  {
    "id": 270041,
    "name": "Cyberware    ( Monterey CA USA )",
    "country": "United States"
  },
  {
    "id": 104042,
    "name": "CYBEX International Inc    ( Medway MA USA )",
    "country": "United States"
  },
  {
    "id": 462689,
    "name": "CyBio AG    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 462688,
    "name": "CyBio US Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 233612,
    "name": "Cybis Medical Systems Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 456499,
    "name": "Cyclomedica Australia    ( Lucas Heights  Australia )",
    "country": "Australia"
  },
  {
    "id": 455112,
    "name": "Cyclomedica UK    ( Dublin  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 458661,
    "name": "Cygnus Inc    ( Petoskey MI USA )",
    "country": "United States"
  },
  {
    "id": 451111,
    "name": "Cyman Therapy Products Inc    ( Chesterfield MI USA )",
    "country": "United States"
  },
  {
    "id": 312369,
    "name": "Cymed Ostomy Co    ( Berkeley CA USA )",
    "country": "United States"
  },
  {
    "id": 455200,
    "name": "Cymru Healthcare    ( Aberdare  Wales )",
    "country": "Wales"
  },
  {
    "id": 450915,
    "name": "Cynacon/Ocusoft Inc    ( Rosenberg TX USA )",
    "country": "United States"
  },
  {
    "id": 179053,
    "name": "Cynosure Inc    ( Westford MA USA )",
    "country": "United States"
  },
  {
    "id": 273869,
    "name": "Cypress Bioscience Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 393150,
    "name": "Cypress Diagnostics    ( Langdorp  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 142921,
    "name": "Cypress Medical Products    ( McHenry IL USA )",
    "country": "United States"
  },
  {
    "id": 458903,
    "name": "CyraCom    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 462610,
    "name": "Cyrus Medical Systems Corp    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 461315,
    "name": "CySolutions Inc    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 461316,
    "name": "Cytolumina Technologies Corp Inc    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 456441,
    "name": "Cytomedix Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 229668,
    "name": "Cytonix Inc    ( Beltsville MD USA )",
    "country": "United States"
  },
  {
    "id": 456757,
    "name": "Cytophil Inc    ( East Troy WI USA )",
    "country": "United States"
  },
  {
    "id": 450984,
    "name": "Cytori Therapeutics Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456968,
    "name": "CytoTherm    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 461200,
    "name": "CytoViva Inc    ( Auburn AL USA )",
    "country": "United States"
  },
  {
    "id": 185603,
    "name": "Cytyc Corp    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 467143,
    "name": "D & L Communication System Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 101322,
    "name": "D E Hokanson Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 152429,
    "name": "D F Vasconcellos S/A    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 150260,
    "name": "D M Davis Inc    ( Teaneck NJ USA )",
    "country": "United States"
  },
  {
    "id": 419842,
    "name": "D-Link (India) Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 419855,
    "name": "D-Link (UK) Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 419834,
    "name": "D-Link Australia Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 419835,
    "name": "D-Link Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 419836,
    "name": "D-Link China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 419837,
    "name": "D-Link Danmark A/S    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 419840,
    "name": "D-Link Deutschland GmbH    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 419838,
    "name": "D-Link Finland    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 419839,
    "name": "D-Link France    ( Guyancourt  France )",
    "country": "France"
  },
  {
    "id": 419841,
    "name": "D-Link Iberia SL    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 419849,
    "name": "D-Link International Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 419843,
    "name": "D-Link Israel    ( Petah-Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 419845,
    "name": "D-Link Japan    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 419844,
    "name": "D-Link Mediterraneo srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452079,
    "name": "D-Link Middle East    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 419846,
    "name": "D-Link Middle East (Egypt)    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 419847,
    "name": "D-Link Norge    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 419848,
    "name": "D-Link Russia    ( Moscow  Russia )",
    "country": "Russia"
  },
  {
    "id": 419850,
    "name": "D-Link South Africa    ( Centurion  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 419851,
    "name": "D-Link South America (Sudamerica)    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 419852,
    "name": "D-Link Sweden    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 419824,
    "name": "D-Link Systems Inc    ( Fountain Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 419853,
    "name": "D-Link Taiwan    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 419854,
    "name": "D-Link Turkey    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 175512,
    "name": "D-Med Inc    ( Highland Park IL USA )",
    "country": "United States"
  },
  {
    "id": 332044,
    "name": "D-Med Systems Ltd    ( Chalgrove  USA )",
    "country": "United States"
  },
  {
    "id": 460575,
    "name": "D-Scope Systems    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 185829,
    "name": "D-Tail Medical Sales    ( Turnersville NJ USA )",
    "country": "United States"
  },
  {
    "id": 339038,
    "name": "D-Vision SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 465146,
    "name": "D.A.I. Scientific Equipment, Inc.    ( Mundelein 25 USA )",
    "country": "United States"
  },
  {
    "id": 374954,
    "name": "da Vinci Dental Studios    ( West Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 453912,
    "name": "Da Vinci Medical Inc    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 455858,
    "name": "Da Yu Enterprise Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 346063,
    "name": "Da-Lite Screen Co Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 107302,
    "name": "Daavlin Inc    ( Bryan OH USA )",
    "country": "United States"
  },
  {
    "id": 174217,
    "name": "Dabi Atlante S/A    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 466905,
    "name": "Dacor Inc    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 458706,
    "name": "Dacor Mfg LLC    ( Columbia IL USA )",
    "country": "United States"
  },
  {
    "id": 329466,
    "name": "Dade Behring Asia Ptd Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 329465,
    "name": "Dade Behring Holding GmbH    ( Liederbach  USA )",
    "country": "United States"
  },
  {
    "id": 329458,
    "name": "Dade Behring Inc    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 426734,
    "name": "Dade Behring Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 426736,
    "name": "Dade Behring SA    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 426735,
    "name": "Dade Behring SAS    ( Paris la Defense  USA )",
    "country": "United States"
  },
  {
    "id": 329459,
    "name": "Dade Microscan Inc    ( West Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 461317,
    "name": "Daedalus Technolgies Inc    ( Oviedo FL USA )",
    "country": "United States"
  },
  {
    "id": 457264,
    "name": "Daekyung Electro Medical Co Ltd    ( Pochun Kun  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452531,
    "name": "Daeshin Enterprise Corp Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455847,
    "name": "DAESIN Enterprise    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455849,
    "name": "Daesung Maref Co Ltd    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 435244,
    "name": "Daewoo Electronic Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 435245,
    "name": "Daewoong International    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456356,
    "name": "Daeyomedi Co Ltd    ( Ansan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 102949,
    "name": "Dagan Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 339007,
    "name": "Dage-MTI Inc    ( Michigan City IN USA )",
    "country": "United States"
  },
  {
    "id": 338338,
    "name": "Dahan Technologies    ( Zuriel  Israel )",
    "country": "Israel"
  },
  {
    "id": 152411,
    "name": "Dai-Ichi Shomei Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 102833,
    "name": "Daig Corp    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 465031,
    "name": "Daihan Scientific    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 287737,
    "name": "Daiichi Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 461232,
    "name": "Daikin McQuay    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452436,
    "name": "Daim Traders    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 462609,
    "name": "Dairei US    ( Esbjerg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 441171,
    "name": "Dairyland Healthcare Solutions    ( Glenwood MN USA )",
    "country": "United States"
  },
  {
    "id": 451749,
    "name": "Daisy Archivierungssysteme GmbH    ( Ladenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 294280,
    "name": "Daiwha Corp Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455928,
    "name": "Daiya Industry Co Ltd    ( Okayama  Japan )",
    "country": "Japan"
  },
  {
    "id": 330228,
    "name": "Dakila Trading Corp    ( Manila  USA )",
    "country": "United States"
  },
  {
    "id": 174626,
    "name": "Dakmed    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 402458,
    "name": "Dako (Australia) Pty Ltd    ( Kingsgrove  Australia )",
    "country": "Australia"
  },
  {
    "id": 457300,
    "name": "Dako (Australia) Pty Ltd    ( Campbellfield  Australia )",
    "country": "Australia"
  },
  {
    "id": 451649,
    "name": "Dako Belguim NV    ( Heverlee  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457303,
    "name": "Dako Belguim NV/SA    ( Heverlee  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 451648,
    "name": "Dako Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457301,
    "name": "Dako Canada Inc    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451647,
    "name": "Dako Czech Republic s r o    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 150255,
    "name": "Dako Denmark A/S    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 457296,
    "name": "Dako Denmark A/S    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451650,
    "name": "Dako Deutschland GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457302,
    "name": "Dako Deutschland GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 402494,
    "name": "Dako Diagnosticos SA    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 457304,
    "name": "Dako Diagnosticos SA    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 451652,
    "name": "Dako France SAS    ( Trappes Cedex  France )",
    "country": "France"
  },
  {
    "id": 457305,
    "name": "Dako France SAS    ( Trappes Cedex  France )",
    "country": "France"
  },
  {
    "id": 451654,
    "name": "Dako Italia SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457306,
    "name": "Dako Italia SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 335465,
    "name": "Dako Japan Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 457297,
    "name": "Dako Japan Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 402499,
    "name": "Dako Norge    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 457307,
    "name": "Dako Norge    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 451644,
    "name": "Dako North America Inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 457295,
    "name": "Dako North America Inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 451646,
    "name": "Dako Osterreich GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 457308,
    "name": "Dako Osterreich GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 331065,
    "name": "Dako Polska Sp z o o    ( Gdynia  Poland )",
    "country": "Poland"
  },
  {
    "id": 457310,
    "name": "Dako Polska Sp z o o    ( Gdynia  Poland )",
    "country": "Poland"
  },
  {
    "id": 451645,
    "name": "Dako Schweiz AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457299,
    "name": "Dako Schweiz AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451653,
    "name": "Dako Sweden AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457309,
    "name": "Dako Sweden AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 309843,
    "name": "Dako UK Ltd    ( Ely  England )",
    "country": "UK"
  },
  {
    "id": 457298,
    "name": "Dako UK Ltd    ( Ely  England )",
    "country": "UK"
  },
  {
    "id": 107023,
    "name": "DAKON CORP    (   USA )",
    "country": "United States"
  },
  {
    "id": 330744,
    "name": "Dalco Electronics    ( Springboro OH USA )",
    "country": "United States"
  },
  {
    "id": 177044,
    "name": "Dalcross Products Pty Ltd    ( Narellan  Australia )",
    "country": "Australia"
  },
  {
    "id": 330685,
    "name": "Dalde RF Shielding    ( Capital Federal  USA )",
    "country": "United States"
  },
  {
    "id": 101113,
    "name": "Dale Medical Products Inc    ( Plainville MA USA )",
    "country": "United States"
  },
  {
    "id": 307279,
    "name": "Dale Medical Products Inc    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 106706,
    "name": "Dale Technology Inc    ( Carson City NV USA )",
    "country": "United States"
  },
  {
    "id": 460930,
    "name": "Daley and Assoc LLC    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 456270,
    "name": "Dalian Goodwood Medical Care Ltd    ( Dalian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456273,
    "name": "Dalian Jincheng Medical Rubbers & Plastics Co Ltd    ( Danyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456271,
    "name": "Dalian Labtek Science & Development Co Ltd    ( Dalian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455421,
    "name": "Dalian Rongbang Medical Healthy Devices Co Ltd    ( Dalian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456272,
    "name": "Dalian Rongbang Medidal Healthy Devices Co Ltd    ( Dalian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 474858,
    "name": "DalimSurgNET Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 409757,
    "name": "DALSA Life Sciences    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108409,
    "name": "Dalton Medical Corp    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 377720,
    "name": "Dalynn Biologicals Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 183786,
    "name": "Dalzell USA Medical Systems    ( Marshall VA USA )",
    "country": "United States"
  },
  {
    "id": 105541,
    "name": "Damas Corp    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 156977,
    "name": "Dameca A/S    ( Rodovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 105271,
    "name": "Damon Corp Damon BioTech Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 104722,
    "name": "Dan Kar Corp    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 444078,
    "name": "DANA Diabecare USA    ( New Orleans LA USA )",
    "country": "United States"
  },
  {
    "id": 176149,
    "name": "Dana Douglas Inc    ( Nepean ON Canada )",
    "country": "Canada"
  },
  {
    "id": 184224,
    "name": "Dana Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 454973,
    "name": "Danaher Tool Group    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 439524,
    "name": "Danatech Medical Systems Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 999605,
    "name": "Danby Products Inc.    ( Findlay OH USA )",
    "country": "United States"
  },
  {
    "id": 454993,
    "name": "Danby Products Ltd    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455321,
    "name": "Dane Technologies Inc    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 150982,
    "name": "Danica Biomedical A/S    ( Rodovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 409526,
    "name": "Daniel L Bowers Co Inc    ( Rochester Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 457523,
    "name": "Daniels Electronics Ltd    ( Victoria BC Canada )",
    "country": "Canada"
  },
  {
    "id": 162274,
    "name": "Daniels Healthcare Ltd    ( Kidlington  England )",
    "country": "UK"
  },
  {
    "id": 441792,
    "name": "Danish Diagnostic Development    ( Horsholm  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458913,
    "name": "Danish Myo Technology A/S    ( Aarhus  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 155770,
    "name": "Danker Laboratories Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 333445,
    "name": "Danlee Medical Products Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 107303,
    "name": "Danmar Products Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 178335,
    "name": "Danmeter A/S    ( Odense C  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 457896,
    "name": "DanMic Global LLC    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 457891,
    "name": "DANNORITZER Medizintechnik GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453925,
    "name": "Danone Nederland bv    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 374951,
    "name": "Dansereau Health Products Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 183790,
    "name": "Dansk Fantom Service    ( Frederikssund  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 331169,
    "name": "Danville Materials    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 456274,
    "name": "Danyang Sunmed Healthcare Corp    ( Danyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 151081,
    "name": "Daray Lighting Ltd    ( Swadlincote  England )",
    "country": "UK"
  },
  {
    "id": 397707,
    "name": "Darby-Spencer-Mead Dental Supply Co    ( Jericho NY USA )",
    "country": "United States"
  },
  {
    "id": 342104,
    "name": "Darco International Inc    ( Huntington WV USA )",
    "country": "United States"
  },
  {
    "id": 162964,
    "name": "Dargatsky Medical and Laboratory Furniture    ( Rehovot  Israel )",
    "country": "Israel"
  },
  {
    "id": 461318,
    "name": "Dartfish    ( Fribourg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174220,
    "name": "Darvas Ind Aparelhos Eletro-Medicos Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 466932,
    "name": "Darwin Chambers Co    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 235114,
    "name": "Dash Medical Gloves Inc    ( Franklin WI USA )",
    "country": "United States"
  },
  {
    "id": 454268,
    "name": "Dash-S Technologies Inc    ( Kigali  Rwanda )",
    "country": "Rwanda"
  },
  {
    "id": 459467,
    "name": "Dashboard Gear LLC    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 440739,
    "name": "Dassnagar Infosystems    ( Kolkata  USA )",
    "country": "United States"
  },
  {
    "id": 233619,
    "name": "Data Distributing LLC    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 458769,
    "name": "Data Duplication Ltd    ( Wendover  England )",
    "country": "UK"
  },
  {
    "id": 465111,
    "name": "Data Equipment, Inc.    ( Pensacola 94 USA )",
    "country": "United States"
  },
  {
    "id": 287959,
    "name": "Data General    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 286007,
    "name": "Data General GmbH    ( Schwalbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 461178,
    "name": "Data Harbor Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 452846,
    "name": "Data Innovations Asia    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452838,
    "name": "Data Innovations Europe    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 330745,
    "name": "Data Innovations Inc    ( South Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 452877,
    "name": "Data Innovations Latin America    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 371502,
    "name": "Data Management Inc    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 461519,
    "name": "Data Ray Corp    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 333728,
    "name": "Data Sciences International    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 177923,
    "name": "Data Southern Enterprises Ltd    ( Fareham  USA )",
    "country": "United States"
  },
  {
    "id": 106365,
    "name": "Data Spectrum Corp    ( Hillsborough NC USA )",
    "country": "United States"
  },
  {
    "id": 334267,
    "name": "Data Strategies Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 449835,
    "name": "Data Transfer Inc    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 461507,
    "name": "Data Unlimited International Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 107304,
    "name": "Data Visible Corp    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 452819,
    "name": "Data Vital GmbH & Co KG    ( Koblenz  Germany )",
    "country": "Germany"
  },
  {
    "id": 431601,
    "name": "Data-Link Assoc Inc    ( North Bellmore NY USA )",
    "country": "United States"
  },
  {
    "id": 459440,
    "name": "Data-Vision Inc    ( Mishawaka IN USA )",
    "country": "United States"
  },
  {
    "id": 461187,
    "name": "Data2Bio    ( Ames IA USA )",
    "country": "United States"
  },
  {
    "id": 106441,
    "name": "Database Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 461518,
    "name": "Database Solutionss Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 459027,
    "name": "DataBay Resources    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 458338,
    "name": "DataCard Corp    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 999988,
    "name": "Datacard Group    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 415355,
    "name": "Datacolor (Benelux)    ( Gent  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415357,
    "name": "Datacolor (China)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415358,
    "name": "Datacolor (France)    ( Montreuil Cedex  France )",
    "country": "France"
  },
  {
    "id": 415359,
    "name": "Datacolor (Germany)    ( Marl  Germany )",
    "country": "Germany"
  },
  {
    "id": 415356,
    "name": "Datacolor (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415360,
    "name": "Datacolor (India)    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 415363,
    "name": "Datacolor (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 415364,
    "name": "Datacolor (United Kingdom)    ( Altrincham  England )",
    "country": "UK"
  },
  {
    "id": 293300,
    "name": "Datacolor AG Industrielle Farbmetrik    ( Dietlikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 413235,
    "name": "Datacolor Corp    ( Lawrenceville NJ USA )",
    "country": "United States"
  },
  {
    "id": 458917,
    "name": "DataFirst Corp    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 452249,
    "name": "Datalogic Scanning    ( Eugene OR USA )",
    "country": "United States"
  },
  {
    "id": 287961,
    "name": "Datalux Corp    ( Winchester VA USA )",
    "country": "United States"
  },
  {
    "id": 363653,
    "name": "Datamax Asia-Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 330747,
    "name": "Datamax Corp    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 363647,
    "name": "Datamax International    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 461179,
    "name": "DATAQ Instruments Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 106514,
    "name": "Datar Inc    ( Long Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 435638,
    "name": "Datascope (Hong Kong)    ( Wan Chai  USA )",
    "country": "United States"
  },
  {
    "id": 242657,
    "name": "Datascope bv    ( Hoevelaken  USA )",
    "country": "United States"
  },
  {
    "id": 101670,
    "name": "Datascope Corp    ( Mahwah NJ USA )",
    "country": "United States"
  },
  {
    "id": 107876,
    "name": "Datascope Corp    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 150080,
    "name": "Datascope Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 153264,
    "name": "Datascope GmbH    ( Bensheim  USA )",
    "country": "United States"
  },
  {
    "id": 450868,
    "name": "Datascope Middle East    ( Cairo  USA )",
    "country": "United States"
  },
  {
    "id": 450867,
    "name": "Datascope Sarl    ( La Ciotat Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 450869,
    "name": "Datascope Sprl/bvba    ( St Stevens-Woluwe  USA )",
    "country": "United States"
  },
  {
    "id": 460618,
    "name": "DataStar Systems Inc    ( Toms River NJ USA )",
    "country": "United States"
  },
  {
    "id": 419826,
    "name": "Datavideo Corp    ( Whittier CA USA )",
    "country": "United States"
  },
  {
    "id": 419825,
    "name": "Datavideo Technologies Corp Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 419827,
    "name": "Datavideo Technologies Europe bv    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452157,
    "name": "Datawatch Corp    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 461506,
    "name": "DataWave Technologies    ( Loveland CO USA )",
    "country": "United States"
  },
  {
    "id": 458928,
    "name": "Dataway    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 459265,
    "name": "Dataworks Development Inc    ( Mountlake Terrace WA USA )",
    "country": "United States"
  },
  {
    "id": 458234,
    "name": "Dataworxs Systems Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452405,
    "name": "DatCard Systems Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 296175,
    "name": "Datex-Engstrom    (   USA )",
    "country": "United States"
  },
  {
    "id": 351302,
    "name": "Datex-Ohmeda (Canada) Inc    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 351977,
    "name": "Datex-Ohmeda (Finland)    ( Helsinki  USA )",
    "country": "United States"
  },
  {
    "id": 354295,
    "name": "Datex-Ohmeda (India) Pvt Ltd    ( New Delhi  USA )",
    "country": "United States"
  },
  {
    "id": 351979,
    "name": "Datex-Ohmeda AB    ( Bromma  USA )",
    "country": "United States"
  },
  {
    "id": 370388,
    "name": "Datex-Ohmeda bv    ( Hoevelaken  USA )",
    "country": "United States"
  },
  {
    "id": 370387,
    "name": "Datex-Ohmeda GmbH    ( Duisburg  USA )",
    "country": "United States"
  },
  {
    "id": 351254,
    "name": "Datex-Ohmeda Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 435639,
    "name": "Datex-Ohmeda Inc (Pulse Oximeter Products)    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 370383,
    "name": "Datex-Ohmeda KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 354403,
    "name": "Datex-Ohmeda Ltd (UK)    ( Hatfield  USA )",
    "country": "United States"
  },
  {
    "id": 408236,
    "name": "Datex-Ohmeda Norway    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 351978,
    "name": "Datex-Ohmeda Pte Ltd (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 370385,
    "name": "Datex-Ohmeda Pty Ltd (Australia)    ( Homebush  USA )",
    "country": "United States"
  },
  {
    "id": 370386,
    "name": "Datex-Ohmeda SAS    ( Limonest  USA )",
    "country": "United States"
  },
  {
    "id": 370389,
    "name": "Datex-Ohmeda SL    ( Madrid  USA )",
    "country": "United States"
  },
  {
    "id": 370384,
    "name": "Datex-Ohmeda SpA    ( Segrate MI  USA )",
    "country": "United States"
  },
  {
    "id": 268175,
    "name": "Datran Corp    ( New Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 183910,
    "name": "Datrend Systems Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 187062,
    "name": "Datrix Inc    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 456980,
    "name": "Datron AG    ( Muehltal  Germany )",
    "country": "Germany"
  },
  {
    "id": 461503,
    "name": "DatStat Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 339026,
    "name": "Datum Filing Systems Inc    ( Emigsville PA USA )",
    "country": "United States"
  },
  {
    "id": 283285,
    "name": "David & Baader DBK GmbH    ( Kandel  Germany )",
    "country": "Germany"
  },
  {
    "id": 101962,
    "name": "David Clark Co Inc    ( Worcester MA USA )",
    "country": "United States"
  },
  {
    "id": 455208,
    "name": "David Hallam Ltd    ( Rotherham  England )",
    "country": "UK"
  },
  {
    "id": 458852,
    "name": "David Kopf Instruments    ( Tujunga CA USA )",
    "country": "United States"
  },
  {
    "id": 102819,
    "name": "David Scott Co    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 173575,
    "name": "Davies Diagnostics Pty Ltd    ( Randburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 417558,
    "name": "Davis Inotek Instruments LLC    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 339028,
    "name": "Davis Lead Aprons Co    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 330750,
    "name": "Davis Medical Electronics Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 357324,
    "name": "Davis Schottlander & Davis Ltd    ( Letchworth  England )",
    "country": "UK"
  },
  {
    "id": 339029,
    "name": "Davis Ultraserv Inc    ( Brentwood CA USA )",
    "country": "United States"
  },
  {
    "id": 451136,
    "name": "Davis-Lobdell Instruments Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 999617,
    "name": "Daviscomms USA, Inc.    ( Scottsdale AZ USA )",
    "country": "USA"
  },
  {
    "id": 330031,
    "name": "DavLong Business Solutions LLC    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 107312,
    "name": "Davlynne International Inc    ( Cudahy WI USA )",
    "country": "United States"
  },
  {
    "id": 101194,
    "name": "Davol Inc    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 153325,
    "name": "DAW Industries Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 441575,
    "name": "Dawg Inc    ( Terryville CT USA )",
    "country": "United States"
  },
  {
    "id": 455692,
    "name": "Dawi Medical    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 391378,
    "name": "DawMed International Ltd    ( Rotherham  England )",
    "country": "UK"
  },
  {
    "id": 104561,
    "name": "Dawning Technologies Inc    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 332700,
    "name": "Daxor Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 151082,
    "name": "Day's Medical Aids Ltd    ( Bridgend  Wales )",
    "country": "Wales"
  },
  {
    "id": 457950,
    "name": "Daylight Medical    ( Middleburg Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 453664,
    "name": "Days Healthcare GmbH & Co KG    ( Bad Oeynhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458368,
    "name": "Dayton    ( Vandalia OH USA )",
    "country": "United States"
  },
  {
    "id": 474917,
    "name": "Dayton Electric Mfg Co    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 287366,
    "name": "Daytona Ltd    ( Colombo-8  Sri Lanka )",
    "country": "Sri Lanka"
  },
  {
    "id": 102247,
    "name": "Dazor Mfg Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 455133,
    "name": "First Call Medical Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 334268,
    "name": "DB Consultants Inc    ( Ottsville PA USA )",
    "country": "United States"
  },
  {
    "id": 371557,
    "name": "DB International Corp    ( Staten Island NY USA )",
    "country": "United States"
  },
  {
    "id": 458369,
    "name": "DB Surgical Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 361065,
    "name": "DBI America    ( Lutz FL USA )",
    "country": "United States"
  },
  {
    "id": 461180,
    "name": "dbMotion Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 151459,
    "name": "DBRS Medical Systems Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 460785,
    "name": "dbTechnologies    ( Crespellano (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 185838,
    "name": "DC Medical    ( Wayland MA USA )",
    "country": "United States"
  },
  {
    "id": 474914,
    "name": "DC Power Products Inc    ( Austell GA USA )",
    "country": "United States"
  },
  {
    "id": 401095,
    "name": "DCH Healthcare Products Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451872,
    "name": "DCI Donor Services    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 457700,
    "name": "DCI Donor Services Tissue Bank    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 451106,
    "name": "DCI International    ( Newberg OR USA )",
    "country": "United States"
  },
  {
    "id": 283419,
    "name": "DCI- Dental-Consulting GmbH    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 107531,
    "name": "DCM Instructional Systems    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 465056,
    "name": "DCMWorks    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 458896,
    "name": "DCS Global Systems Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 999959,
    "name": "de G_tzen    (   USA )",
    "country": "United States"
  },
  {
    "id": 454875,
    "name": "De Gotzen srl    ( Olgiate Olona (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461181,
    "name": "De Novo Software Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 236427,
    "name": "De Soutter Medical Ltd    ( Berkhamsted  England )",
    "country": "UK"
  },
  {
    "id": 999452,
    "name": "De_Longhi America, Inc.    ( Upper Saddle River NJ USA )",
    "country": "United States"
  },
  {
    "id": 126792,
    "name": "De-Tec Inc    ( Baldwinsville NY USA )",
    "country": "United States"
  },
  {
    "id": 458930,
    "name": "Deaf Talk LLC    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 999552,
    "name": "Dean Medical Instruments Inc.    ( Huntington Beach CA USA )",
    "country": "USA"
  },
  {
    "id": 407239,
    "name": "DEAS srl    ( Castel Bolognese (RA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457713,
    "name": "Deb USA Inc    ( Stanley NC USA )",
    "country": "United States"
  },
  {
    "id": 172167,
    "name": "Debiotech SA    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 409970,
    "name": "Decal Corp    ( Tallman NY USA )",
    "country": "United States"
  },
  {
    "id": 108004,
    "name": "Deccaid Services Inc    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 281048,
    "name": "Decision Systems International GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 106036,
    "name": "DECM Corp    ( Zionsville IN USA )",
    "country": "United States"
  },
  {
    "id": 103417,
    "name": "DecoGard Products Div Construction Specialties Inc    ( Muncy PA USA )",
    "country": "United States"
  },
  {
    "id": 324131,
    "name": "Decon Laboratories Ltd    ( Hove  England )",
    "country": "UK"
  },
  {
    "id": 391295,
    "name": "Decos Medical Systems bv    ( Noordwijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999412,
    "name": "Dectro International    ( Qu_bec  Canada )",
    "country": "Canada"
  },
  {
    "id": 455477,
    "name": "Deewan Surgical (Pakistan)    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 455478,
    "name": "Deewan Surgicals (Australia)    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 152081,
    "name": "Defer    ( Limonest  France )",
    "country": "France"
  },
  {
    "id": 435457,
    "name": "Defibtech LLC    ( Guilford CT USA )",
    "country": "United States"
  },
  {
    "id": 461499,
    "name": "Definitive Homecare Solutions    ( Westerville OH USA )",
    "country": "United States"
  },
  {
    "id": 451067,
    "name": "DefinitIVe Medical Technologies Ltd    ( Lod  USA )",
    "country": "United States"
  },
  {
    "id": 458166,
    "name": "Deflecto LLC    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 306075,
    "name": "Defries Industries Pty Ltd    ( Keysborough  Australia )",
    "country": "Australia"
  },
  {
    "id": 153297,
    "name": "DEGO GmbH Medizin-Elektronik    ( Rohrdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 140051,
    "name": "Degussa AG Geschaeftsbereich Dental    ( Frankfurt am Main  USA )",
    "country": "United States"
  },
  {
    "id": 457717,
    "name": "DEHAG Medizin-Technische Produktion    ( Rosdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 464974,
    "name": "Dehumidifier Corp of America    ( Cedarburg WI USA )",
    "country": "United States"
  },
  {
    "id": 155951,
    "name": "DeJarnette Research Systems Inc    ( Towson MD USA )",
    "country": "United States"
  },
  {
    "id": 373853,
    "name": "DEKA Laser    (   Italy )",
    "country": "Italy"
  },
  {
    "id": 373854,
    "name": "Deka Medical/OMI Ltd (UK)    ( Alsager-Stoke-on-Trent  USA )",
    "country": "United States"
  },
  {
    "id": 451020,
    "name": "Del Laboratories Inc    ( Uniondale NY USA )",
    "country": "United States"
  },
  {
    "id": 999533,
    "name": "Del Mar Avionics    ( Irvine CA USA )",
    "country": "USA"
  },
  {
    "id": 417980,
    "name": "Del Mar Reynolds GmbH    ( Feucht  USA )",
    "country": "United States"
  },
  {
    "id": 417954,
    "name": "Del Mar Reynolds Medical Europe    ( Zaventem  USA )",
    "country": "United States"
  },
  {
    "id": 417953,
    "name": "Del Mar Reynolds Medical Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 417979,
    "name": "Del Mar Reynolds Medical Ltd    ( Hertford  USA )",
    "country": "United States"
  },
  {
    "id": 452741,
    "name": "DEL MAR REYNOLDS MEDICAL-SPACELABS INC    ( Hertford  England )",
    "country": "UK"
  },
  {
    "id": 453696,
    "name": "Del Medical Imaging Corp Asia/Pacific    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 376429,
    "name": "Del Medical Systems Group    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 333655,
    "name": "Delacroix-Chevalier    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 289891,
    "name": "Delasco    ( Council Bluffs IA USA )",
    "country": "United States"
  },
  {
    "id": 376492,
    "name": "Delaware Valley Surgical Supply    ( Boothwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 106186,
    "name": "Delaware Valley X-Ray    ( Boothwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 461496,
    "name": "Delcam    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 172264,
    "name": "Deldent Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 376484,
    "name": "Delfi Medical Innovations Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456471,
    "name": "Delfin Technologies Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 440007,
    "name": "Delft Imaging Systems bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451254,
    "name": "Dell Inc    ( Round Rock TX USA )",
    "country": "United States"
  },
  {
    "id": 461498,
    "name": "DelMar Photonics Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 999958,
    "name": "DELMONICO    (   USA )",
    "country": "United States"
  },
  {
    "id": 461183,
    "name": "Deloitte    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 465205,
    "name": "Delong America    ( Montreal 40 USA )",
    "country": "United States"
  },
  {
    "id": 450865,
    "name": "Delphi Medical Systems    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 274322,
    "name": "Delphian Corp    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 333656,
    "name": "Delphic Medical System Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 458337,
    "name": "Delsys Inc    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 372927,
    "name": "Delta Biologicals srL    ( Pomezia (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462998,
    "name": "Delta Biotech Inc    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 459253,
    "name": "Delta Controls Inc    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 450824,
    "name": "Delta Enterprise Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 467267,
    "name": "Delta Health Technologies LLC    ( Altoona PA USA )",
    "country": "United States"
  },
  {
    "id": 457831,
    "name": "Delta Hi-Tech Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 461489,
    "name": "Delta Integration Inc    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 107691,
    "name": "Delta Medical Inc    ( Valparaiso FL USA )",
    "country": "United States"
  },
  {
    "id": 232574,
    "name": "Delta Medical Systems Inc    ( Pewaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 465213,
    "name": "Delta Optical Instruments, Inc    ( North Little Rock 31 USA )",
    "country": "United States"
  },
  {
    "id": 154149,
    "name": "Delta Scientific Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 321062,
    "name": "GDS Technology Inc    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 459398,
    "name": "Delta Surgical Ltd    ( Newcastle-under-Lyme  England )",
    "country": "UK"
  },
  {
    "id": 461486,
    "name": "Delta Systems Inc    ( Shawnee KS USA )",
    "country": "United States"
  },
  {
    "id": 185839,
    "name": "Delta-Med Ltd    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 306403,
    "name": "Deltalab SA    ( Rubi (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 399260,
    "name": "DELTAMED SA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 999690,
    "name": "DeltaTrak Inc    ( Pleasanton CA USA )",
    "country": "USA"
  },
  {
    "id": 107100,
    "name": "Deltec    (   USA )",
    "country": "United States"
  },
  {
    "id": 291973,
    "name": "Deltex Medical Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 324868,
    "name": "Deltex Medical Ltd    ( Chichester  England )",
    "country": "UK"
  },
  {
    "id": 174221,
    "name": "Deltronix Equipamentos Ltda    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 456275,
    "name": "Demax Medical Technology Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 375211,
    "name": "Demetech AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 151114,
    "name": "Den Mat    ( Santa Maria CA USA )",
    "country": "United States"
  },
  {
    "id": 374399,
    "name": "Denbur Inc    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 340621,
    "name": "Dendrite Clinical Systems Ltd    ( Henley-on-Thames  England )",
    "country": "UK"
  },
  {
    "id": 452709,
    "name": "Denka Seiken Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 467063,
    "name": "DenMat Holdings LLC    ( Lompoc CA USA )",
    "country": "United States"
  },
  {
    "id": 999544,
    "name": "Denon Electronics (USA) LLC    ( Mahwah NJ USA )",
    "country": "USA"
  },
  {
    "id": 466663,
    "name": "DeNovix Inc    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 107179,
    "name": "Denovo    ( Baldwin Park CA USA )",
    "country": "United States"
  },
  {
    "id": 450851,
    "name": "Denoyer Geppert International Canada    ( Fort Erie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 450852,
    "name": "Denoyer Geppert International UK    ( Rustington  England )",
    "country": "UK"
  },
  {
    "id": 103265,
    "name": "Denoyer Geppert International USA    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 454958,
    "name": "DenPlus Inc    ( Longueuil PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 151083,
    "name": "Densa Ltd    ( Flint  USA )",
    "country": "United States"
  },
  {
    "id": 454708,
    "name": "Denstar    ( Daegu  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453012,
    "name": "Densway Yuyao Medical Instrument Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 298185,
    "name": "Dent-X Corp USA    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 284013,
    "name": "Dentaform Dentalprodukte GmbH    ( Hamm  Germany )",
    "country": "Germany"
  },
  {
    "id": 374523,
    "name": "Dental Arts Laboratories Inc    ( Peoria IL USA )",
    "country": "United States"
  },
  {
    "id": 105545,
    "name": "Dental Corp of America    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 372177,
    "name": "Dental Disposibles International Inc    ( Marlboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 287634,
    "name": "Dental Festin GmbH & Co KG    ( Zeven  Germany )",
    "country": "Germany"
  },
  {
    "id": 451861,
    "name": "Dental Health Products Inc    ( New Franken WI USA )",
    "country": "United States"
  },
  {
    "id": 450601,
    "name": "Dental Instrument Factory of Shanghai    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 285823,
    "name": "Dental Kunstleder- & Lederservice GmbH    ( Rosdorf  USA )",
    "country": "United States"
  },
  {
    "id": 283559,
    "name": "Dental Liga GmbH & Co KG    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 330953,
    "name": "Dental Repair Service    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 401744,
    "name": "Dental Resources Inc    ( Maple Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 457944,
    "name": "Dental USA Inc    ( McHenry IL USA )",
    "country": "United States"
  },
  {
    "id": 234401,
    "name": "Dental Vision Direct Div Ultrak Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 453822,
    "name": "Dental X srl    ( Dueville (VI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 280928,
    "name": "Dental-Kosmetik GmbH Dresden    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 105200,
    "name": "DeRoyal Industries Inc    ( Powell TN USA )",
    "country": "United States"
  },
  {
    "id": 457083,
    "name": "Dentalaire Products International    ( Fountain Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 999957,
    "name": "Dentalaire ProductsInternational    ( Fountain Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 470547,
    "name": "DentalEye AB    ( Sundbyberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 348906,
    "name": "DentalEZ (UK)    ( Hemel Hempstead  England )",
    "country": "UK"
  },
  {
    "id": 106367,
    "name": "DentalEZ Group    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 457488,
    "name": "Dentamerica Inc    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 999909,
    "name": "DENTAMERICA INC    ( CITY OF INDUSTRY CA USA )",
    "country": "United States"
  },
  {
    "id": 336191,
    "name": "Dentatus AB    ( Spanga  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453965,
    "name": "Dentatus USA Ltd    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 439909,
    "name": "Dentaurum Australia    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 439912,
    "name": "Dentaurum Espana    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 439911,
    "name": "Dentaurum France    ( Marne-la-Vallee Cedex 2  France )",
    "country": "France"
  },
  {
    "id": 187793,
    "name": "Dentaurum Inc    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 439910,
    "name": "Dentaurum Italia    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 153300,
    "name": "Dentaurum J P Winkelstroeter KG    ( Ispringen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458465,
    "name": "DENTECH    ( Farmington Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 999616,
    "name": "Dentech    (   USA )",
    "country": "United States"
  },
  {
    "id": 999922,
    "name": "DenTek Oral Care, Inc.    ( Maryville TN USA )",
    "country": "United States"
  },
  {
    "id": 458241,
    "name": "Denterprise International Inc    ( Ormond Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 105546,
    "name": "Denticator    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 471731,
    "name": "Dentisan Ltd    ( Derby  England )",
    "country": "UK"
  },
  {
    "id": 454351,
    "name": "Dentium USA    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 456750,
    "name": "Dentmate Technology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451689,
    "name": "Denton Vacuum - China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451688,
    "name": "Denton Vacuum - Europe    ( Market Harborough  USA )",
    "country": "United States"
  },
  {
    "id": 102703,
    "name": "Denton Vacuum Inc    ( Moorestown NJ USA )",
    "country": "United States"
  },
  {
    "id": 233626,
    "name": "Dentrix Dental Systems Inc    ( American Fork UT USA )",
    "country": "United States"
  },
  {
    "id": 426309,
    "name": "Dentronix Inc    ( Cuyahoga Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 332156,
    "name": "Dentry Biomedical Inc    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 328241,
    "name": "Dentsply Asia    ( Wong Chuk Hang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 328238,
    "name": "Dentsply Australia    ( Mt Waverly  Australia )",
    "country": "Australia"
  },
  {
    "id": 328231,
    "name": "Dentsply Canada    ( Woodbridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 232575,
    "name": "Dentsply Caulk    ( Milford DE USA )",
    "country": "United States"
  },
  {
    "id": 185359,
    "name": "Dentsply DeTrey (Deutschland)    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 328363,
    "name": "Dentsply DeTrey (France)    ( St-Quentin-en-Yvelines Cedex  France )",
    "country": "France"
  },
  {
    "id": 454528,
    "name": "Dentsply DeTrey Sarl    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 455235,
    "name": "Dentsply GAC International Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 280903,
    "name": "Dentsply GmbH    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 102082,
    "name": "Dentsply International Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 273226,
    "name": "Dentsply International Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 399192,
    "name": "Dentsply Italia srl    ( Cusano Milanino MI  USA )",
    "country": "United States"
  },
  {
    "id": 454514,
    "name": "Dentsply Italia srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 328245,
    "name": "Dentsply Japan KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 453631,
    "name": "Dentsply Maillerfer    ( Ballaigues  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457249,
    "name": "DENTSPLY Raintree Essix Glenroe    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 451432,
    "name": "Dentsply Tulsa Dental    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 328248,
    "name": "Dentsply United Kingdom    ( Addlestone  England )",
    "country": "UK"
  },
  {
    "id": 401851,
    "name": "Dentsply-Ceramco    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 457955,
    "name": "Dentus dental industries    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 101201,
    "name": "Denver Biomedical Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 317142,
    "name": "Denver Instrument Co    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 454862,
    "name": "Denville Scientific Inc    ( Metuchen NJ USA )",
    "country": "United States"
  },
  {
    "id": 177045,
    "name": "Denyers Pty Ltd    ( Moorabbin  Australia )",
    "country": "Australia"
  },
  {
    "id": 157508,
    "name": "Depex bv    ( Houten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452657,
    "name": "Depex BV    ( Tienen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 455579,
    "name": "Depex bv    ( Houten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 459010,
    "name": "Dephinitive Systems Inc    ( Independence OH USA )",
    "country": "United States"
  },
  {
    "id": 461484,
    "name": "Depisteo LLC    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 999512,
    "name": "DEPISTEO LLC,    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 306680,
    "name": "Deposito Medico Dental SA    ( Panama City  Republic of Panama )",
    "country": "Panama"
  },
  {
    "id": 457279,
    "name": "Deppeler SA    ( Rolle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 305461,
    "name": "DePuy    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 361815,
    "name": "DePuy ACE Medical Co    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 293262,
    "name": "DePuy AG    ( Cham  USA )",
    "country": "United States"
  },
  {
    "id": 195066,
    "name": "DePuy International Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 451024,
    "name": "Depuy Ireland Ltd    ( Ringaskiddy  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 450928,
    "name": "DePuy Mitek Inc    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 286518,
    "name": "DePuy Orthopaedie GmbH    ( Sulzbach  USA )",
    "country": "United States"
  },
  {
    "id": 439630,
    "name": "DePuy Spine Inc    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 474894,
    "name": "DePuy Synthes Co    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 474892,
    "name": "DePuy Synthes GmbH (Switzerland)    ( Zuchwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 474890,
    "name": "DePuy Synthes Joint Reconstruction    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 474889,
    "name": "DePuy Synthes Mitek Sports Medicine    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 474888,
    "name": "DePuy Synthes Spine Inc    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 474891,
    "name": "DePuy Synthes UK    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 330954,
    "name": "Derekduck Industries Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 172206,
    "name": "Derma Sciences Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 461482,
    "name": "DermaMed International Inc    ( Lenni PA USA )",
    "country": "United States"
  },
  {
    "id": 999956,
    "name": "DermaMed International Inc    ( Lenni PA USA )",
    "country": "United States"
  },
  {
    "id": 290092,
    "name": "Dermapac Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 349218,
    "name": "Dermascan Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 999424,
    "name": "DermaSweep    ( Rocklin CA USA )",
    "country": "United States"
  },
  {
    "id": 152526,
    "name": "Derungs Licht AG    ( Gossau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 356267,
    "name": "DesAcc Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 184132,
    "name": "Desaga GmbH    ( Wiesloch  Germany )",
    "country": "Germany"
  },
  {
    "id": 451292,
    "name": "Desan Flex SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 373550,
    "name": "DesChutes Medical Products Inc    ( Bend OR USA )",
    "country": "United States"
  },
  {
    "id": 283108,
    "name": "Desdo GmbH    ( Huenfelden  USA )",
    "country": "United States"
  },
  {
    "id": 461194,
    "name": "Desert Biomedical Services    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 320789,
    "name": "Designer Care Co Ltd    ( Neche ND USA )",
    "country": "United States"
  },
  {
    "id": 187011,
    "name": "Designs for Comfort Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 102506,
    "name": "Designs For Vision Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 335366,
    "name": "Designs for Vision Pty Ltd    ( Camperdown  Australia )",
    "country": "Australia"
  },
  {
    "id": 293272,
    "name": "Desinfecta AG    ( Daellikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 466440,
    "name": "DeskMakers Inc    ( Commerce CA USA )",
    "country": "United States"
  },
  {
    "id": 105284,
    "name": "Despatch Industries    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 461480,
    "name": "Destined Commodities Inc    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 281487,
    "name": "Detax GmbH & Co KG    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451137,
    "name": "Detco Industries Inc    ( Conway AR USA )",
    "country": "United States"
  },
  {
    "id": 101206,
    "name": "Detecto Scale Co Div Cardinal Scale Mfg Co    ( Webb City MO USA )",
    "country": "United States"
  },
  {
    "id": 285885,
    "name": "Detensor    ( Roethenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 279982,
    "name": "Detewe Deutsche Telephonwerke Aktiengesellschaft &    ( Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 281050,
    "name": "Detlef Gestigkeit Elektrotechnik    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 279984,
    "name": "Deutsch & Neumann GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 284308,
    "name": "Deutsche Fernsprecher GmbH    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 157944,
    "name": "Deutsche Feuerloescher Bauanstalt-Bensheimer    ( Bensheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 285859,
    "name": "Deutsche Hoval GmbH    ( Rottenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281535,
    "name": "Deutsche Metrohm GmbH & Co    ( Filderstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 452800,
    "name": "Deutsche Nemectron GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 282209,
    "name": "Deutsche Spezialglas AG    ( Gruenenplan  Germany )",
    "country": "Germany"
  },
  {
    "id": 457388,
    "name": "devemed GmbH    ( Neuhausen ob Eck  Germany )",
    "country": "Germany"
  },
  {
    "id": 415989,
    "name": "Device Technologies Australia Pty Ltd    ( Frenchs Forest  Australia )",
    "country": "Australia"
  },
  {
    "id": 101963,
    "name": "DeVilbiss Health Care    (   USA )",
    "country": "United States"
  },
  {
    "id": 456885,
    "name": "DeVilbiss Healthcare GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 453450,
    "name": "DeVilbiss Healthcare Inc    ( Somerset PA USA )",
    "country": "United States"
  },
  {
    "id": 456884,
    "name": "DeVilbiss Healthcare Pty Ltd    ( Castle Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 456883,
    "name": "DeVilbiss Healthcare SAS    ( Tours  France )",
    "country": "France"
  },
  {
    "id": 283776,
    "name": "DeVilbiss Ransburg    ( Langen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451828,
    "name": "Devine Medical Supplies    ( Sante Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 454731,
    "name": "Devon Medical Inc    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 431505,
    "name": "DEW Engineering and Development Ltd    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 194228,
    "name": "DeWallace Technical Sales Inc    ( Worcester MA USA )",
    "country": "United States"
  },
  {
    "id": 287269,
    "name": "Dewe & Co Verbandstoff-Fabrik Dr Wuesthoff & Co KG    ( Wermelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454366,
    "name": "Dewert Motorized Systems    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 184086,
    "name": "Dewimed SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 457514,
    "name": "DEXA Solutions LLC    ( Sun Prairie WI USA )",
    "country": "United States"
  },
  {
    "id": 454834,
    "name": "Dexcom Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 452704,
    "name": "DEXIS LLC    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 999497,
    "name": "Dexon Mfg., Div. of La Calhene, Inc.    ( Rush City MN USA )",
    "country": "United States"
  },
  {
    "id": 104159,
    "name": "Dexta Corp    ( Napa CA USA )",
    "country": "United States"
  },
  {
    "id": 457252,
    "name": "DEYMED Diagnostic    ( Payette ID USA )",
    "country": "United States"
  },
  {
    "id": 457253,
    "name": "DEYMED Diagnostic Europe    ( Hronov  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 455850,
    "name": "DFI Co Ltd    ( Gimhae  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454628,
    "name": "DFine Europe GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454627,
    "name": "DFine Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 187946,
    "name": "DGH Technology Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 175036,
    "name": "DGH-KOI Inc    ( Shermans Dale PA USA )",
    "country": "United States"
  },
  {
    "id": 393600,
    "name": "DH Group Benelux bv    ( Roosendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 468408,
    "name": "Dharma Research Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 999326,
    "name": "DHCL Group    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 177041,
    "name": "DHS Pty Ltd    ( Revestay  Australia )",
    "country": "Australia"
  },
  {
    "id": 454123,
    "name": "DHS Systems LLC    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 284616,
    "name": "Dia-Lab Diagnostika GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 333565,
    "name": "Diabetes Technologies Inc    ( Thomasville GA USA )",
    "country": "United States"
  },
  {
    "id": 465216,
    "name": "Diabetic Supply of Suncoast Inc    ( Dorado  Puerto Rico )",
    "country": "Puerto Rico"
  },
  {
    "id": 461477,
    "name": "Diaco Dental Inc    ( Smyrna GA USA )",
    "country": "United States"
  },
  {
    "id": 431519,
    "name": "Diacor Inc    ( West Valley City UT USA )",
    "country": "United States"
  },
  {
    "id": 346878,
    "name": "Diadent srl    ( Vittorio Veneto TV  USA )",
    "country": "United States"
  },
  {
    "id": 453645,
    "name": "diaDexus    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 457461,
    "name": "Diagast    ( Loos  France )",
    "country": "France"
  },
  {
    "id": 455737,
    "name": "DiagCor Bioscience Inc Ltd    ( Kowloon Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456932,
    "name": "Diagenode SA    ( Liege  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452117,
    "name": "Diagmed Enteral Ltd    ( Thirsk  England )",
    "country": "UK"
  },
  {
    "id": 330853,
    "name": "Diagnostic Chemicals Ltd    ( Charlottetown PE USA )",
    "country": "United States"
  },
  {
    "id": 171331,
    "name": "Diagnostic Chemicals Ltd (USA)    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 376329,
    "name": "Diagnostic Chemicals Ltd de Mexico SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 185844,
    "name": "Diagnostic Equipment Sales Inc    ( Belle Chasse LA USA )",
    "country": "United States"
  },
  {
    "id": 183227,
    "name": "Diagnostic Equipment Service Corp    ( Norfolk MA USA )",
    "country": "United States"
  },
  {
    "id": 450776,
    "name": "Diagnostic Equipment Service Corp    ( Norfolk MA USA )",
    "country": "United States"
  },
  {
    "id": 106147,
    "name": "Diagnostic Equipment Service Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 232369,
    "name": "Diagnostic Grifols SA    ( Parets del Valles (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 454273,
    "name": "Diagnostic Health Group    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 150003,
    "name": "Diagnostic Hybrids Inc    ( Athens OH USA )",
    "country": "United States"
  },
  {
    "id": 454394,
    "name": "Diagnostic Medical Supplies Inc    ( Waterford MI USA )",
    "country": "United States"
  },
  {
    "id": 461531,
    "name": "Diagnostic Monitoring Software Inc    ( Stateline NV USA )",
    "country": "United States"
  },
  {
    "id": 371487,
    "name": "Diagnostic Parts Exchange Inc    ( Quincy FL USA )",
    "country": "United States"
  },
  {
    "id": 183961,
    "name": "Diagnostic Solutions    ( Garrettsville OH USA )",
    "country": "United States"
  },
  {
    "id": 183874,
    "name": "Diagnostic Sonar Ltd    ( Livingston  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 155121,
    "name": "Diagnostic Specialties Inc    ( Metuchen NJ USA )",
    "country": "United States"
  },
  {
    "id": 170320,
    "name": "Diagnostic System Services Inc    ( Pearl River LA USA )",
    "country": "United States"
  },
  {
    "id": 105637,
    "name": "Diagnostic Systems Laboratories Inc    ( Webster TX USA )",
    "country": "United States"
  },
  {
    "id": 104313,
    "name": "Diagnostic Technology Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 344609,
    "name": "Diagnostic Technology Instruments Inc    ( Miami Lakes FL USA )",
    "country": "United States"
  },
  {
    "id": 106781,
    "name": "Diagnostic Ultrasound Corp    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 155645,
    "name": "Diagnostica Stago    ( Asnieres sur Seine  France )",
    "country": "France"
  },
  {
    "id": 374984,
    "name": "Diagnostica Stago Inc    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 453768,
    "name": "Diagnostix Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 104549,
    "name": "Diagnostix Plus Inc    ( Rockville Centre NY USA )",
    "country": "United States"
  },
  {
    "id": 321051,
    "name": "Diagnosys LLC    ( Lowell MA USA )",
    "country": "United States"
  },
  {
    "id": 277803,
    "name": "Diagon Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 286052,
    "name": "Diagramm-Halbach GmbH    ( Schwerte  Germany )",
    "country": "Germany"
  },
  {
    "id": 293286,
    "name": "Diagramma Dietikon AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456485,
    "name": "DIALAB GmbH    ( Wiener Neudorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 460714,
    "name": "Dialog Medical    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 172266,
    "name": "Dialom Diamond Tools Ltd    ( Ramat Gan  Israel )",
    "country": "Israel"
  },
  {
    "id": 458732,
    "name": "Dialysis & Pheresis Technologies Inc    ( Stockton CA USA )",
    "country": "United States"
  },
  {
    "id": 150163,
    "name": "Diamatrix Ltd Inc    ( The Woodlands TX USA )",
    "country": "United States"
  },
  {
    "id": 415886,
    "name": "DiaMed (GB) Ltd    ( Dalkeith  England )",
    "country": "UK"
  },
  {
    "id": 186580,
    "name": "DiaMed (Schweiz) AG    ( Cressier sur Morat  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415896,
    "name": "DiaMed Africa    ( Glenanda  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 415889,
    "name": "DiaMed Argentina SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 415894,
    "name": "DiaMed Australia Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 415885,
    "name": "DiaMed Benelux NV    ( Turnhout  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415893,
    "name": "DiaMed China Ltd    ( North Point  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415884,
    "name": "DiaMed Diagnostica GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 279884,
    "name": "DiaMed Diagnostika Med Produkte GmbH    ( Bensheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 415897,
    "name": "DiaMed Egypt    ( Giza  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 415887,
    "name": "DiaMed Fennica Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 415882,
    "name": "DiaMed France SA    ( Marnes-la-Coquette  France )",
    "country": "France"
  },
  {
    "id": 415891,
    "name": "DiaMed Hellas Ltd    ( Athinai (Moschato)  Greece )",
    "country": "Greece"
  },
  {
    "id": 415900,
    "name": "DiaMed Iberica SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 415895,
    "name": "DiaMed Israel Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 415888,
    "name": "DiaMed Italiana srl    ( Vedano AL Lambro (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 415883,
    "name": "DiaMed Latino America SA    ( Lagoa Santa-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 415898,
    "name": "DiaMed Maghreb sarl    ( Rabat  Morocco )",
    "country": "Morocco"
  },
  {
    "id": 415892,
    "name": "DiaMed SEA Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 415890,
    "name": "DiaMed Venezuela CA    ( Caracas  USA )",
    "country": "United States"
  },
  {
    "id": 415899,
    "name": "DiaMed-Caribbean Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456462,
    "name": "DiaMedical USA Equipment LLC    ( West Bloomfield MI USA )",
    "country": "United States"
  },
  {
    "id": 108894,
    "name": "Diamedix Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 457204,
    "name": "Diamesco    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 184898,
    "name": "Diametrics Medical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 108217,
    "name": "Diamond Computing Co    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 391888,
    "name": "Diamond Diagnostics Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 465144,
    "name": "Diamond Drapery Co., Inc.    ( Garden Grove 74 USA )",
    "country": "United States"
  },
  {
    "id": 366873,
    "name": "Diamondback Fitness    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 441522,
    "name": "Dianon Systems/UroCor Labs    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 312296,
    "name": "DiaPharma Group Inc    ( West Chester OH USA )",
    "country": "United States"
  },
  {
    "id": 104956,
    "name": "Diapulse Corp of America    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 260789,
    "name": "Diasonics Latin America    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 332059,
    "name": "Diasonics Vingmed US do Brazil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 453525,
    "name": "DiaSorin AB    ( Sundbyberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 346802,
    "name": "DiaSorin Inc    ( Stillwater MN USA )",
    "country": "United States"
  },
  {
    "id": 403513,
    "name": "DiaSorin Ltd    ( Dartford  England )",
    "country": "UK"
  },
  {
    "id": 453526,
    "name": "DiaSorin Ltd (Israel)    ( Rosh HaAyin  Israel )",
    "country": "Israel"
  },
  {
    "id": 453524,
    "name": "DiaSorin Ltd China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 403511,
    "name": "DiaSorin Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 403509,
    "name": "DiaSorin Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 403510,
    "name": "DiaSorin SA (France)    ( Antony  France )",
    "country": "France"
  },
  {
    "id": 403512,
    "name": "DiaSorin SA (Spain)    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 403507,
    "name": "DiaSorin SA/NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 363111,
    "name": "DiaSorin srl    ( Saluggia (VC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 354506,
    "name": "Diaswiss SA    ( Nyon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 237822,
    "name": "DiaSys Corp    ( Waterbury CT USA )",
    "country": "United States"
  },
  {
    "id": 454214,
    "name": "DiaSys Diagnostic Systems GmbH    ( Holzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 302212,
    "name": "DiaSys Europe Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 454101,
    "name": "DiaSys Ltd USA    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 152082,
    "name": "Diatecnic    ( Labege  France )",
    "country": "France"
  },
  {
    "id": 227345,
    "name": "Diatek Instruments Inc A Welch Allyn Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 462605,
    "name": "Diatome US    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 277799,
    "name": "DIATRON Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 455078,
    "name": "DIATRON Latinamericano SA    ( Vicente Lopez  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 325042,
    "name": "DIATRON Messtechnik GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 320086,
    "name": "DIATRON Shanghai New Time Trading Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455460,
    "name": "DIATRON USA    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 457382,
    "name": "Diazyme Europe GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 457381,
    "name": "Diazyme Laboratories    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 285050,
    "name": "DIB Elektronik    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 370571,
    "name": "Dibmed Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 417557,
    "name": "Dickies Medical Uniforms    ( Farmers Branch TX USA )",
    "country": "United States"
  },
  {
    "id": 103771,
    "name": "Geriatric Products Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 357519,
    "name": "Dickson    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 452057,
    "name": "Dicom Solutions Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 431925,
    "name": "Diconex SA    ( Quilmes  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 108913,
    "name": "Dictaphone    (   USA )",
    "country": "United States"
  },
  {
    "id": 108920,
    "name": "Dictaphone Corp    ( Stratford CT USA )",
    "country": "United States"
  },
  {
    "id": 330800,
    "name": "Didage Sales Co Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 284618,
    "name": "Didas AG    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 185614,
    "name": "Dideco Asia Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 417258,
    "name": "Dideco Cobe Cardiovasculaire SA    ( Antony Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 417259,
    "name": "Dideco Scandinavia AB    ( Malmo  USA )",
    "country": "United States"
  },
  {
    "id": 157140,
    "name": "Dideco SpA    ( Mirandola MO  USA )",
    "country": "United States"
  },
  {
    "id": 417260,
    "name": "Dideco UK    ( Gloucester  USA )",
    "country": "United States"
  },
  {
    "id": 453100,
    "name": "Dideco-Sorin Group USA    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 282877,
    "name": "Die Wethje GmbH Kunststofftechnik    ( Hengersberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457427,
    "name": "Dieckhoff & Ratschow Praxisdienst GmbH & Co KG    ( Longuich  Germany )",
    "country": "Germany"
  },
  {
    "id": 459486,
    "name": "Diener electronic GmbH & Co KG    ( Ebhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456806,
    "name": "DIERS International GmbH    ( Schlangenbad  Germany )",
    "country": "Germany"
  },
  {
    "id": 456805,
    "name": "DIERS Medical Systems Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 453787,
    "name": "Diesse Diagnostica Senese SpA    ( Monteriggioni (SI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453792,
    "name": "Diesse Diagnostica Senese SpA (China)    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453786,
    "name": "Diesse Inc    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 176205,
    "name": "Diestco Mfg Co    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 282232,
    "name": "Dieter Mruk Import-Export GmbH    ( Rheda-Wiedenbrnck  Germany )",
    "country": "Germany"
  },
  {
    "id": 160124,
    "name": "Dieter Oertel Medizin-Technik    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 283096,
    "name": "Dieter Poertner Medizinische Geraete - Ultraschall    ( Hundsangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284052,
    "name": "Dieterle & Marquardt GmbH & Co    ( Lorch  Germany )",
    "country": "Germany"
  },
  {
    "id": 322360,
    "name": "Diethelm & Co Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 457335,
    "name": "DIETZ GmbH Reha-Producte    ( Karlsbad-Ittersbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 157953,
    "name": "Dietz Reha-Producte    ( Karlsbad (Ittersbach)  Germany )",
    "country": "Germany"
  },
  {
    "id": 105250,
    "name": "Difco Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 456449,
    "name": "Diffuplast srl    ( Olgiate Olona (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 118022,
    "name": "Digene Corp    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 469513,
    "name": "Digi International Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 454804,
    "name": "Digi-Key Corp    ( Thief River Falls MN USA )",
    "country": "United States"
  },
  {
    "id": 458926,
    "name": "Digi-Trax Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 999787,
    "name": "Digi-Trax Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 237790,
    "name": "Digicare Biomedical Technology Inc    ( Boynton Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 164605,
    "name": "Digicare Tecnologia Biomedica    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 454561,
    "name": "digiChart Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 465214,
    "name": "Digilab Inc    ( Marlborough 10 USA )",
    "country": "United States"
  },
  {
    "id": 455860,
    "name": "DIGIMAX INNOVATIVE PRODUCTS LTD    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455127,
    "name": "Digimeter Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 455861,
    "name": "digiO2 International Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 461413,
    "name": "DigiPath Inc    ( Henderson NV USA )",
    "country": "United States"
  },
  {
    "id": 328751,
    "name": "Digirad Corp    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 441492,
    "name": "Digirad Imaging Solutions    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 104814,
    "name": "Digisonics Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 458250,
    "name": "Digital Accessories Corp (DAC)    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 459363,
    "name": "Digital Healthcare Inc    ( Wake Forest NC USA )",
    "country": "United States"
  },
  {
    "id": 458935,
    "name": "Digital Innovation Inc    ( Forest Hill MD USA )",
    "country": "United States"
  },
  {
    "id": 462931,
    "name": "Digital Projection    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 461191,
    "name": "Digital Roads Inc    ( Wheat Ridge CO USA )",
    "country": "United States"
  },
  {
    "id": 452037,
    "name": "Digital Security Controls    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461530,
    "name": "Digital Technology LLC    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 413112,
    "name": "Digital Voice Inc    ( Joplin MO USA )",
    "country": "United States"
  },
  {
    "id": 457802,
    "name": "DigitalDev    ( Curico  Chile )",
    "country": "Chile"
  },
  {
    "id": 461411,
    "name": "DigitalPersona Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 293418,
    "name": "Digitana AG    ( Horgen  USA )",
    "country": "United States"
  },
  {
    "id": 175527,
    "name": "Digitcare Corp    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 356259,
    "name": "Digitec Medical Service Corp    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 455268,
    "name": "Digiterm    ( Gyoengyoes  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 178298,
    "name": "Digitimer Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 185856,
    "name": "DigiVision Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 461529,
    "name": "Dignity Inc    ( Maryland Heights TN USA )",
    "country": "United States"
  },
  {
    "id": 454000,
    "name": "Dignity Resource Council Inc    ( Brooksville FL USA )",
    "country": "United States"
  },
  {
    "id": 458784,
    "name": "Dijkstra Vereenigde bv    ( Lelystad  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454699,
    "name": "Dik Drug Co    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 155411,
    "name": "Diller Medical Inc    ( Bluffton OH USA )",
    "country": "United States"
  },
  {
    "id": 185852,
    "name": "Dillon Medical Oxygen Supply    ( Dillon MT USA )",
    "country": "United States"
  },
  {
    "id": 441585,
    "name": "Dilon Technologies    ( Newport News VA USA )",
    "country": "United States"
  },
  {
    "id": 179164,
    "name": "Dima Italia    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455328,
    "name": "DIMA SL    ( Calatayud (Zaragoza)  Spain )",
    "country": "Spain"
  },
  {
    "id": 103113,
    "name": "Dimco-Gray Co    ( Centerville OH USA )",
    "country": "United States"
  },
  {
    "id": 305802,
    "name": "Dimeca SA de CV    ( San Salvador  USA )",
    "country": "United States"
  },
  {
    "id": 150969,
    "name": "Dimeda Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457012,
    "name": "DIMEDI Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461410,
    "name": "Dimensional Insight Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 456991,
    "name": "Dimensional Photonics International Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 426239,
    "name": "Diomed Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 183095,
    "name": "Diomed Ltd    ( Cambridge  USA )",
    "country": "United States"
  },
  {
    "id": 179096,
    "name": "Dionex (UK) Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 102597,
    "name": "Dionex Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 283135,
    "name": "Dionex GmbH    ( Idstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 375990,
    "name": "Dionex Softron GmbH    ( Germering  Germany )",
    "country": "Germany"
  },
  {
    "id": 449817,
    "name": "Diopsys Inc    ( Pine Brook NJ USA )",
    "country": "United States"
  },
  {
    "id": 155776,
    "name": "Dioptics Medical Products    ( San Luis Obispo CA USA )",
    "country": "United States"
  },
  {
    "id": 279504,
    "name": "Dipl -Ing (Fh) F Grassinger GmbH & Co    ( Ammerndorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 284466,
    "name": "Dipl -Ing Arnold Weber Roentgen - Strahlenschutz    ( Moers  Germany )",
    "country": "Germany"
  },
  {
    "id": 286609,
    "name": "Dipl -Ing Juergen Pfaender GmbH    ( Trebbin  Germany )",
    "country": "Germany"
  },
  {
    "id": 293204,
    "name": "Dipl Ing Rolf Herzog Rehabilitationstechnik    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 172966,
    "name": "Dipl-Ing Ernest Spirig    ( Rapperswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 137861,
    "name": "Dipl-Ing W Bender GmbH & Co KG    ( Gruenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 171771,
    "name": "DIPRO med Handels GmbH    ( Weigelsdorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 461409,
    "name": "Direct Electron LP    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 175530,
    "name": "Direct Safety Co    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 999920,
    "name": "Direct Supply    (   USA )",
    "country": "United States"
  },
  {
    "id": 458370,
    "name": "Direct Supply Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 458672,
    "name": "Directory Solutions Inc    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 461320,
    "name": "DirecTV    ( El Segundo CA USA )",
    "country": "United States"
  },
  {
    "id": 335771,
    "name": "Direx de Mexico    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 335770,
    "name": "Direx do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 335776,
    "name": "Direx Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 335778,
    "name": "Direx Medical Systems (China)    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 156982,
    "name": "Direx Medical Systems Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 392289,
    "name": "Direx Systems Corp    ( Canton MA USA )",
    "country": "United States"
  },
  {
    "id": 361283,
    "name": "Direx Systems GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 335774,
    "name": "Direx Systems Ltd    ( Keighley  USA )",
    "country": "United States"
  },
  {
    "id": 335773,
    "name": "Direx Systems SA (France)    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 335775,
    "name": "Direx Systems srl    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458664,
    "name": "Dirinco AG    ( Bern 25  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105449,
    "name": "Diros Technology Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 999967,
    "name": "DIRT DEVIL    (   USA )",
    "country": "United States"
  },
  {
    "id": 456276,
    "name": "DIRUI Industrial Co Ltd    ( Chang Chun  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451139,
    "name": "Disaster Management Systems Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 461165,
    "name": "Disaster Medical Technologies LLC    ( Auburn AL USA )",
    "country": "United States"
  },
  {
    "id": 461263,
    "name": "Disaster Response Solutions Inc    ( Milford OH USA )",
    "country": "United States"
  },
  {
    "id": 454424,
    "name": "Disc Motion Technologies    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 331087,
    "name": "Discount Medical Supply Inc    ( North Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 452413,
    "name": "Discovery Hospital    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 457638,
    "name": "Discovery Medical Inc    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 454512,
    "name": "Discus Dental    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 416030,
    "name": "Disetronic Medical Systems (Denmark)    ( Hillerod  USA )",
    "country": "United States"
  },
  {
    "id": 416031,
    "name": "Disetronic Medical Systems (Finland)    ( Vantaa  USA )",
    "country": "United States"
  },
  {
    "id": 416032,
    "name": "Disetronic Medical Systems (Norway)    ( Holmstrand  USA )",
    "country": "United States"
  },
  {
    "id": 330143,
    "name": "Disetronic Medical Systems AB    ( Nacka Strand  USA )",
    "country": "United States"
  },
  {
    "id": 168475,
    "name": "Disetronic Medical Systems AG    ( Burgdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 392737,
    "name": "Disetronic Medical Systems bv    ( Vianen  USA )",
    "country": "United States"
  },
  {
    "id": 392742,
    "name": "Disetronic Medical Systems GmbH (Austria)    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 392739,
    "name": "Disetronic Medical Systems GmbH (Germany)    ( Sulzbach  USA )",
    "country": "United States"
  },
  {
    "id": 150157,
    "name": "Disetronic Medical Systems Inc    ( Fishers IN USA )",
    "country": "United States"
  },
  {
    "id": 392736,
    "name": "Disetronic Medical Systems Ltd    ( Stareton Near Kenilworth  USA )",
    "country": "United States"
  },
  {
    "id": 392738,
    "name": "Disetronic Medical Systems SAS    ( Emerainville  USA )",
    "country": "United States"
  },
  {
    "id": 392741,
    "name": "Disetronic Medical Systems srl    ( Verona VR  USA )",
    "country": "United States"
  },
  {
    "id": 451466,
    "name": "Disetronic Sterile Products    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 458768,
    "name": "Diskology    ( Lincoln CA USA )",
    "country": "United States"
  },
  {
    "id": 456854,
    "name": "Dispocare Ltd    ( Cardiff  Wales )",
    "country": "Wales"
  },
  {
    "id": 227514,
    "name": "Dispomed Witt oHG    ( Gelnhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 162965,
    "name": "Dispomedic Ltd    ( Dimona  USA )",
    "country": "United States"
  },
  {
    "id": 282364,
    "name": "Dispomedica GmbH Herstellung und Vertrieb    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999447,
    "name": "Disposmed Ltd.    ( Joliet  Canada )",
    "country": "Canada"
  },
  {
    "id": 451776,
    "name": "Distribution Systems International    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 453833,
    "name": "Distrimed    ( Cuers  France )",
    "country": "France"
  },
  {
    "id": 330786,
    "name": "DITEC Inc    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 172848,
    "name": "Diter-Elektroniikka Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 450542,
    "name": "Diter-Elektroniikka Oy    ( Newcastle Upon Tyne  England )",
    "country": "UK"
  },
  {
    "id": 451138,
    "name": "DiVal    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 451140,
    "name": "Diversified Biotech    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 451833,
    "name": "Diversified Designs Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 104633,
    "name": "Diversified Diagnostic Products    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 106295,
    "name": "Diversified Equipment Co    ( Lorton VA USA )",
    "country": "United States"
  },
  {
    "id": 170315,
    "name": "Diversified Instrument Services Inc    ( Evansville IN USA )",
    "country": "United States"
  },
  {
    "id": 441561,
    "name": "Diversified Medical NCE    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 465143,
    "name": "Diversified Pump & Compressor    ( Hampton 10 USA )",
    "country": "United States"
  },
  {
    "id": 293545,
    "name": "Divina of Switzerland    ( Rheineck  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454198,
    "name": "Dixie Medical Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 283405,
    "name": "Dixie Union Verpackungen GmbH    ( Kempten  Germany )",
    "country": "Germany"
  },
  {
    "id": 384281,
    "name": "Dixons Surgical Instruments Ltd    ( Wickford  England )",
    "country": "UK"
  },
  {
    "id": 454029,
    "name": "Dixtal Medical Inc    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 174223,
    "name": "Dixtal Tecnologia Industria e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 377726,
    "name": "dj Orthopedics LLC    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 454836,
    "name": "DJO Asia-Pacific Ltd    ( Shatin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454835,
    "name": "DJO Australia    ( Normanhurst  Australia )",
    "country": "Australia"
  },
  {
    "id": 455670,
    "name": "DJO Benelux    ( Herentals  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453540,
    "name": "DJO Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453366,
    "name": "DJO Deutschland GmbH    ( Neubeuern  Germany )",
    "country": "Germany"
  },
  {
    "id": 453539,
    "name": "DJO France    ( Mouguerre  France )",
    "country": "France"
  },
  {
    "id": 452204,
    "name": "DJO LLC    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 453538,
    "name": "DJO UK    ( Guildford  England )",
    "country": "UK"
  },
  {
    "id": 457317,
    "name": "DJO UK    ( Guildford  England )",
    "country": "UK"
  },
  {
    "id": 458433,
    "name": "DJProducts Inc    ( Little Falls MN USA )",
    "country": "United States"
  },
  {
    "id": 280930,
    "name": "DKI GmbH    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 453661,
    "name": "DKL GmbH    ( Rosdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 455134,
    "name": "DKSH Australia Pty Ltd    ( Hallam  Australia )",
    "country": "Australia"
  },
  {
    "id": 455135,
    "name": "DKSH New Zealand Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 309435,
    "name": "Dlouhy Ges mbH    ( Tulln  Austria )",
    "country": "Austria"
  },
  {
    "id": 453823,
    "name": "DLS Medical    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 461247,
    "name": "DM & A-Webb    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 456803,
    "name": "Dometic Scandinavia AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455432,
    "name": "DM Software    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 175245,
    "name": "DM Systems Inc    ( Evanston IL USA )",
    "country": "United States"
  },
  {
    "id": 157961,
    "name": "DMB-Apparatebau GmbH    ( Woerrstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 440146,
    "name": "DME Rental    ( Salem OR USA )",
    "country": "United States"
  },
  {
    "id": 451388,
    "name": "DMetrix Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 282366,
    "name": "DMG Chemisch-Pharmazeutische Fabrik GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 152431,
    "name": "DMG Equipamentos Medicos Ltda    ( Duque de Caxias-RJ  USA )",
    "country": "United States"
  },
  {
    "id": 460966,
    "name": "DMI Office Furniture    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 451681,
    "name": "DML    ( Rotselaar  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 460967,
    "name": "DMR Sign Systems    ( Randolph NJ USA )",
    "country": "United States"
  },
  {
    "id": 397110,
    "name": "DMS Health Technologies    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 431324,
    "name": "DMS Imaging    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 454080,
    "name": "DMS Topline Medical    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 292905,
    "name": "DMS-Diagnostic Medical Systems    ( Perols  France )",
    "country": "France"
  },
  {
    "id": 365267,
    "name": "DMV Medizintechnik GmbH    ( Hennigsdorf  USA )",
    "country": "United States"
  },
  {
    "id": 461156,
    "name": "DMX Music    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 441166,
    "name": "DMX Works Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 356261,
    "name": "DNTLworks Equipment Corp    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 250044,
    "name": "DOCS/Acme Express Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 454562,
    "name": "Doctations Inc    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 144459,
    "name": "Doctor's Equipment Service Co    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 408328,
    "name": "Doctor's Research Group Inc    ( Southbury CT USA )",
    "country": "United States"
  },
  {
    "id": 464984,
    "name": "Doctors Depot    ( Jupiter FL USA )",
    "country": "United States"
  },
  {
    "id": 466768,
    "name": "Document Capture Technologies Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 454563,
    "name": "Document Storage Systems Inc    ( Juno Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 407359,
    "name": "DocuSys Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458314,
    "name": "DocuTemp Wireless LLC    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 460716,
    "name": "DocuWare Corp    ( New Windsor NY USA )",
    "country": "United States"
  },
  {
    "id": 282252,
    "name": "Doering GmbH    ( Leverkusen  USA )",
    "country": "United States"
  },
  {
    "id": 104056,
    "name": "Dolan-Jenner Industries Inc    ( Boxborough MA USA )",
    "country": "United States"
  },
  {
    "id": 451770,
    "name": "Dolbey Systems Inc    ( Concord OH USA )",
    "country": "United States"
  },
  {
    "id": 466311,
    "name": "Dolbey-Jamison Inc    ( Pottstown PA USA )",
    "country": "United States"
  },
  {
    "id": 401328,
    "name": "Dolomite AB    ( Anderstorp  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 999475,
    "name": "Dolores Speech Products    ( Wellesley MA USA )",
    "country": "USA"
  },
  {
    "id": 402555,
    "name": "Dolphin Medical Inc    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 144460,
    "name": "Dolphin X-Ray Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 458478,
    "name": "Domain Surgical    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 157639,
    "name": "Domedica Pty Ltd    ( Lane Cove  USA )",
    "country": "United States"
  },
  {
    "id": 453761,
    "name": "Dometic International AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 442213,
    "name": "Dometic Medical Systems    ( Abidjan  USA )",
    "country": "United States"
  },
  {
    "id": 439930,
    "name": "Dometic Sarl    ( Hosingen  Luxembourg )",
    "country": "Luxembourg"
  },
  {
    "id": 442210,
    "name": "Dometic Sarl Austria    ( Strasswalchen  USA )",
    "country": "United States"
  },
  {
    "id": 442212,
    "name": "Dometic Sarl Deutschland    ( Siegen  Germany )",
    "country": "Germany"
  },
  {
    "id": 442211,
    "name": "Dometic Sarl France    ( Plailly  France )",
    "country": "France"
  },
  {
    "id": 452134,
    "name": "Dominion Biologicals    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 393604,
    "name": "Domnick Hunter AB    ( Molndal  USA )",
    "country": "United States"
  },
  {
    "id": 348481,
    "name": "Domnick Hunter Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 393590,
    "name": "Domnick Hunter China    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 393601,
    "name": "Domnick Hunter France SA    ( Villefranche sur Saone Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 283684,
    "name": "Domnick Hunter GmbH    ( Willich/Muenchheide II  USA )",
    "country": "United States"
  },
  {
    "id": 393595,
    "name": "Domnick Hunter Group Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 393603,
    "name": "Domnick Hunter Hiross SpA    ( San Angelo di Piove PD  USA )",
    "country": "United States"
  },
  {
    "id": 393602,
    "name": "Domnick Hunter Iberica    ( Montornes del Valles  USA )",
    "country": "United States"
  },
  {
    "id": 332215,
    "name": "Domnick Hunter Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 393592,
    "name": "Domnick Hunter India Pvt Ltd    ( New Delhi  USA )",
    "country": "United States"
  },
  {
    "id": 332213,
    "name": "Domnick Hunter Ltd    ( Birtley  USA )",
    "country": "United States"
  },
  {
    "id": 393591,
    "name": "Domnick Hunter Nihon    ( Kobe-shi  USA )",
    "country": "United States"
  },
  {
    "id": 393597,
    "name": "Domnick Hunter Pty Ltd    ( Boronia  USA )",
    "country": "United States"
  },
  {
    "id": 393593,
    "name": "Domnick Hunter RL (Malaysia) Sdn Bhd    ( Petaling Jaya  USA )",
    "country": "United States"
  },
  {
    "id": 393596,
    "name": "Domnick Hunter RL (Thailand) Co Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 393599,
    "name": "Domnick Hunter Skandinavien A/S    ( Slangerup  USA )",
    "country": "United States"
  },
  {
    "id": 441607,
    "name": "Don Whitley Scientific ltd    ( Shipley  England )",
    "country": "UK"
  },
  {
    "id": 467210,
    "name": "Donau Trading Sp z o o    ( Warsaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 999410,
    "name": "Donegan Optical Company, Inc.    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 294311,
    "name": "Dong Jin Medical Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456283,
    "name": "Dong-e E-jiao E-hua Medical Equipment Co Ltd    ( Dong-e  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456284,
    "name": "Dongguan Kewei Medical Instruments Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455851,
    "name": "DONGJIN MEDICAL Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455852,
    "name": "Dongmun Co Ltd    ( Goyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 307036,
    "name": "Donmed Pharmaceuticals (Pty) Ltd    ( Gardenview  USA )",
    "country": "United States"
  },
  {
    "id": 456796,
    "name": "Donor Network of Arizona    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 451142,
    "name": "Donovan Industries    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 415435,
    "name": "Doosan America Corp    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 294286,
    "name": "Doosan Corp    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 415436,
    "name": "Doosan Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 451713,
    "name": "Doove Medical    ( Zevenhuizen  USA )",
    "country": "United States"
  },
  {
    "id": 451716,
    "name": "Doove Venocare bv    ( Zevenhuizen  USA )",
    "country": "United States"
  },
  {
    "id": 182639,
    "name": "Doppler Sonics    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 454127,
    "name": "Doran Scales Inc    ( Batavia IL USA )",
    "country": "United States"
  },
  {
    "id": 454033,
    "name": "DORC International bv    ( Zuidland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103986,
    "name": "Dorex Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 453045,
    "name": "Dormed Medizinische Systeme GmbH    ( Holzwickede  Germany )",
    "country": "Germany"
  },
  {
    "id": 174589,
    "name": "Dormer Laboratories Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461184,
    "name": "Dormont    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 348944,
    "name": "Dornier MedTech America Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 188437,
    "name": "Dornier MedTech Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 466287,
    "name": "Dr Harold Katz LLC    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 402650,
    "name": "Dornier MedTech Espana SL    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 453956,
    "name": "Dornier MedTech Europe GmbH (Russia)    ( Moscow  Russia )",
    "country": "Russia"
  },
  {
    "id": 402652,
    "name": "Dornier MedTech France Sarl    ( Sillingly  France )",
    "country": "France"
  },
  {
    "id": 139512,
    "name": "Dornier MedTech GmbH    ( Wessling  Germany )",
    "country": "Germany"
  },
  {
    "id": 402649,
    "name": "Dornier MedTech Italia srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 287768,
    "name": "Dornier MedTech Japan    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 451762,
    "name": "Dornoch Medical Systems    ( Riverside MO USA )",
    "country": "United States"
  },
  {
    "id": 329868,
    "name": "Dos Ni Roha PT    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 157967,
    "name": "Dosatec GmbH    ( Gilching  Germany )",
    "country": "Germany"
  },
  {
    "id": 199710,
    "name": "Dosimeter    ( Rolla ND USA )",
    "country": "United States"
  },
  {
    "id": 394351,
    "name": "DOTmed.com Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 455409,
    "name": "Dotolo Research Corp    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 105970,
    "name": "Dotronix Inc    ( New Brighton MN USA )",
    "country": "United States"
  },
  {
    "id": 454322,
    "name": "Dotronix Technology Inc    ( New Brighton MN USA )",
    "country": "United States"
  },
  {
    "id": 435341,
    "name": "Double Black Imaging    ( Westminster CO USA )",
    "country": "United States"
  },
  {
    "id": 456286,
    "name": "Double-Dove Group Co Ltd    ( Huangyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 176106,
    "name": "Douglas Battery Mfg Co    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 335367,
    "name": "Douglas Bean Australia Pty Ltd    ( Wantirna South  Australia )",
    "country": "Australia"
  },
  {
    "id": 435621,
    "name": "Dow Corning Corp    ( Hemlock MI USA )",
    "country": "United States"
  },
  {
    "id": 287666,
    "name": "Dow Denmark A/S    ( Nakskov  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 286011,
    "name": "Dow Deutschland Inc    ( Schwalbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 457604,
    "name": "Dow Kokam    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 463038,
    "name": "DoWell Dental Products Inc    ( Rancho Cucamong CA USA )",
    "country": "United States"
  },
  {
    "id": 370463,
    "name": "DOXS Technology Systems Inc    ( Downingtown PA USA )",
    "country": "United States"
  },
  {
    "id": 178406,
    "name": "DP Medical Systems Ltd    ( Chessington  England )",
    "country": "UK"
  },
  {
    "id": 451028,
    "name": "DPC Instrument Systems Div    ( Flanders NJ USA )",
    "country": "United States"
  },
  {
    "id": 457150,
    "name": "DPE Medical Ltd    ( Shoeva  Israel )",
    "country": "Israel"
  },
  {
    "id": 460780,
    "name": "DPSS Lasers Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 450924,
    "name": "DQE Inc    ( Fishers IN USA )",
    "country": "United States"
  },
  {
    "id": 286126,
    "name": "Dr Arabin GmbH & Co KG    ( Witten  Germany )",
    "country": "Germany"
  },
  {
    "id": 287481,
    "name": "Dr Ausbuettel & Co GmbH    ( Witten-Annen  Germany )",
    "country": "Germany"
  },
  {
    "id": 263811,
    "name": "Dr Berthold G Schlag Wissenschaftliche Messinstrum    ( Bergisch Gladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 282068,
    "name": "Dr Boehme & Schoeps Dental GmbH    ( Goslar  Germany )",
    "country": "Germany"
  },
  {
    "id": 153150,
    "name": "Dr Bosbach GmbH    ( Kurten  Germany )",
    "country": "Germany"
  },
  {
    "id": 281052,
    "name": "Dr Bruno Lange GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 235431,
    "name": "Dr Bruno Lange GmbH & Co KG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 309270,
    "name": "Dr Carl Reissigl GesmbH & Co KG    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 458078,
    "name": "Dr Fenning BioMed GmbH    ( Kirchzarten  Germany )",
    "country": "Germany"
  },
  {
    "id": 280363,
    "name": "Dr Fenyves & Gut Deutschland GmbH    ( Hechingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 171770,
    "name": "Dr Gernot Schuhfried GmbH    ( Moedling  Austria )",
    "country": "Austria"
  },
  {
    "id": 282787,
    "name": "Dr Goos-Suprema GmbH Herstellung Von    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 279994,
    "name": "Dr H Stamm GmbH Chemische Fabrik    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 285056,
    "name": "Dr Heindl Tresor GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293643,
    "name": "Dr Hermann Keck & Partner    ( Therwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 280552,
    "name": "Dr Hochwahr GmbH Elektromedizinische Geraete    ( Bretten  USA )",
    "country": "United States"
  },
  {
    "id": 285387,
    "name": "Dr Hoenle AG    ( Graefelfing bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280494,
    "name": "Dr Hugo Schramm GmbH & Co KG    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306295,
    "name": "Dr J Haensler GmbH    ( Iffezheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 283139,
    "name": "Dr J Haensler GmbH Medizintechnik O3    ( Iffezheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 283565,
    "name": "Dr Jean Bausch KG    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 283112,
    "name": "Dr Keddo Biochemische Produkte    ( Huerth-Gleuel  Germany )",
    "country": "Germany"
  },
  {
    "id": 152525,
    "name": "Dr Mach GmbH & Co    ( Ebersberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456332,
    "name": "Dr Mueller Pharma Sro    ( Hradec Kralove  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 439660,
    "name": "Dr Notes    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 283279,
    "name": "Dr Nuesken Chemie GmbH    ( Kamen  Germany )",
    "country": "Germany"
  },
  {
    "id": 426341,
    "name": "Dr Osypka GmbH    ( Rheinfelden/Herten  Germany )",
    "country": "Germany"
  },
  {
    "id": 284871,
    "name": "Dr Paul Koch GmbH    ( Frickenhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284904,
    "name": "Dr Rowedder Biomed Geraete    ( Neumuenster  USA )",
    "country": "United States"
  },
  {
    "id": 454705,
    "name": "Dr Sabharwals Group    ( Haryana  India )",
    "country": "India"
  },
  {
    "id": 284402,
    "name": "Dr Schumacher GmbH    ( Melsungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 187974,
    "name": "DR Systems Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 309403,
    "name": "Dr Techn Ing Stefan Sztatecsny GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 285490,
    "name": "Dr Walser Dental GmbH    ( Radolfzell  Germany )",
    "country": "Germany"
  },
  {
    "id": 306622,
    "name": "Dr Weigert (GmbH & Co) Chemische Fabrik    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 279439,
    "name": "Dr Winkler GmbH & Co KG    ( Ainring/Mitterfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 286072,
    "name": "Dr Wolfgang Kernchen GmbH    ( Seelze/Letter  USA )",
    "country": "United States"
  },
  {
    "id": 285939,
    "name": "Dr-Ing Dipl-Oekonom C Meyer-Kobbe    ( Sarstedt/Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 454508,
    "name": "Draeger Colombia LLC    ( Bogata DC  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 371686,
    "name": "Draeger Krankanhaus-Informationssystems GmbH    ( Meerbusch  USA )",
    "country": "United States"
  },
  {
    "id": 306071,
    "name": "Draeger Medical Australia Pty Ltd    ( Notting Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 453935,
    "name": "Draeger Medical Canada Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 374044,
    "name": "Draeger Medical Deutschland GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 136968,
    "name": "Draeger Medical Hispania SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 371341,
    "name": "Draeger Medical Inc    ( Telford PA USA )",
    "country": "United States"
  },
  {
    "id": 453115,
    "name": "Draeger Medical Netherlands bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 157747,
    "name": "Draeger Medical UK Ltd    ( Hemel Hempstead  England )",
    "country": "UK"
  },
  {
    "id": 139322,
    "name": "Draeger Safety AG & Co KGaA    (   USA )",
    "country": "United States"
  },
  {
    "id": 431620,
    "name": "Draeger Safety AG & Co KGaA    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 431633,
    "name": "Draeger Safety Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 307040,
    "name": "Draeger South Africa Pty Ltd    ( Bromhof  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 309775,
    "name": "Draeger Southeast Asia Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 354511,
    "name": "Draeger Southeast Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 456287,
    "name": "Dragon Laboratory Instruments Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453080,
    "name": "DragonMobility Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 185388,
    "name": "Drajmed Zaklad Wyrobow Ortopedyczno-Rehabilitacyjn    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 174224,
    "name": "Drake Eletronica e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 103996,
    "name": "Dranetz-BMI    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 460968,
    "name": "Draper Inc    ( Spiceland IN USA )",
    "country": "United States"
  },
  {
    "id": 353325,
    "name": "Drass Galeazzi Underwater Technology srl    ( Livorno (LI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 103431,
    "name": "Dravon Medical Inc    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 144397,
    "name": "DRE Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 285559,
    "name": "Dre-Dr Riss Ellipsometerbau GmbH    ( Ratzeburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456357,
    "name": "Dream STS Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999980,
    "name": "DREMEL    (   USA )",
    "country": "United States"
  },
  {
    "id": 279996,
    "name": "Drendel & Zweiling Diamant GmbH    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 160781,
    "name": "Dreve Otoplastik GmbH    ( Unna  Germany )",
    "country": "Germany"
  },
  {
    "id": 286957,
    "name": "Dreve-Dentamid GmbH    ( Unna  Germany )",
    "country": "Germany"
  },
  {
    "id": 402709,
    "name": "Drew Scientific Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 353374,
    "name": "Drew Scientific Ltd    ( Barrow in Furness  England )",
    "country": "UK"
  },
  {
    "id": 457436,
    "name": "Drew Shoe Corp USA    ( Lancaster OH USA )",
    "country": "United States"
  },
  {
    "id": 461010,
    "name": "DrFirst Corp    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 330797,
    "name": "DRG Alternatives Inc    ( Hinsdale IL USA )",
    "country": "United States"
  },
  {
    "id": 284310,
    "name": "DRG Instruments GmbH    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 105906,
    "name": "DRG International Inc    ( Mountainside NJ USA )",
    "country": "United States"
  },
  {
    "id": 456880,
    "name": "DRG International Inc (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 358003,
    "name": "DRG MedTek Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 451559,
    "name": "DRG TechSystems A/O    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 392880,
    "name": "DRG-CR    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 392840,
    "name": "DRG-Elstim    ( Zagreb  Croatia )",
    "country": "Croatia"
  },
  {
    "id": 455855,
    "name": "DRGEM Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 279630,
    "name": "Driesen & Kern GmbH    ( Bad Bramstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 440987,
    "name": "Drive Medical Design and Mfg    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 453618,
    "name": "Drive Medical Ltd    ( Elland  England )",
    "country": "UK"
  },
  {
    "id": 108552,
    "name": "Drive-Master Co Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 461261,
    "name": "DriveABLE Assessment Centres Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 108973,
    "name": "Driving Aids Development Corp    ( Vienna VA USA )",
    "country": "United States"
  },
  {
    "id": 999451,
    "name": "DrQuickLook    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 451597,
    "name": "DRS Engineered Air Systems Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 466869,
    "name": "Drucker Diagnostics    ( Port Matilda PA USA )",
    "country": "United States"
  },
  {
    "id": 285061,
    "name": "Druckhaus Andreas Abraham GmbH & Co KG    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 102419,
    "name": "Drummond Scientific Co    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 461407,
    "name": "DS Corporation    ( Cross  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 462980,
    "name": "DS Medica Srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453879,
    "name": "DS Medical    ( Fareham  England )",
    "country": "UK"
  },
  {
    "id": 332065,
    "name": "DS Medigroup SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 471610,
    "name": "DSC Optical Services Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 108556,
    "name": "Envision Imaging Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 451819,
    "name": "DSHI Systems Inc    ( Rockledge FL USA )",
    "country": "United States"
  },
  {
    "id": 452943,
    "name": "DSP Diagnostik Systempartner GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 457744,
    "name": "DST Diagnostische Systeme & Technologien GmbH    ( Schwerin  Germany )",
    "country": "Germany"
  },
  {
    "id": 459442,
    "name": "DSX Access Systems Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 458733,
    "name": "DT Research Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 455862,
    "name": "DTC-Healthkare Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 162967,
    "name": "Du-Kedem Projects Ltd    ( Moshav Gan Haim  USA )",
    "country": "United States"
  },
  {
    "id": 309617,
    "name": "Dual-Sanitaly SpA    ( Moncalieri (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 322385,
    "name": "Dubal Medical Furniture    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 279998,
    "name": "Dublosan Gerhard Schmitz Medizinische Schutzhuelle    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 460970,
    "name": "Dubois Chemicals    ( Sharonville OH USA )",
    "country": "United States"
  },
  {
    "id": 285821,
    "name": "Ducera Dental-Gesellschaft mbH    ( Rosbach vor der Hoehe  Germany )",
    "country": "Germany"
  },
  {
    "id": 457933,
    "name": "Duchesnay Inc    ( Blainville PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 182712,
    "name": "Duckworth & Kent Ltd    ( Baldock  England )",
    "country": "UK"
  },
  {
    "id": 187430,
    "name": "Duckworth & Kent USA Ltd    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 162276,
    "name": "Dudley Controls Ltd    ( Milton Keynes  USA )",
    "country": "United States"
  },
  {
    "id": 284404,
    "name": "Duecker Medizintechnik    ( Melsungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283310,
    "name": "Duerasol Ursula Esch    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 280295,
    "name": "Duerr-Dental GmbH & Co KG    ( Bietigheim-Bissingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 157968,
    "name": "Duerr-Technik GmbH & Co KG    ( Bietigheim-Bissingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999485,
    "name": "Duff-Norton    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 157969,
    "name": "Dufner Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 403709,
    "name": "Dufort & Lavigne Ltee    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 451582,
    "name": "Dukal Corp    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 342297,
    "name": "Dukane Communication Systems (Canada)    ( Waterloo ON USA )",
    "country": "United States"
  },
  {
    "id": 103036,
    "name": "Dukane Communications Systems US    ( Warrenville IL USA )",
    "country": "United States"
  },
  {
    "id": 173439,
    "name": "Dukane Corp    ( St Charles IL USA )",
    "country": "United States"
  },
  {
    "id": 330801,
    "name": "Duke Diagnostic Resale    ( Syosset NY USA )",
    "country": "United States"
  },
  {
    "id": 416524,
    "name": "Duke Scientific Corp    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 177658,
    "name": "Dumont Instruments et Co SA    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457816,
    "name": "Dune Medical Devices    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 372004,
    "name": "Dunlee    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 282658,
    "name": "Dunlopillo Division der Dunlop GmbH    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 279558,
    "name": "Dunn Labortechnik GmbH    ( Asbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 401798,
    "name": "DunnAmics Inc    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 399286,
    "name": "Duoject Medical Systems Inc    ( Bromont PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 452205,
    "name": "DuoProSS Meditech Corp    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 458339,
    "name": "Duos Technologies Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 101222,
    "name": "Dupaco    (   USA )",
    "country": "United States"
  },
  {
    "id": 104946,
    "name": "Dupaco Inc    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 458081,
    "name": "DuPhaMed BV    ( Alphen aan den Rijn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999223,
    "name": "DuPont    (   USA )",
    "country": "United States"
  },
  {
    "id": 999896,
    "name": "Duquesne    (   USA )",
    "country": "United States"
  },
  {
    "id": 471665,
    "name": "Dura-Comfort Tables    ( Stevensville MT USA )",
    "country": "United States"
  },
  {
    "id": 176207,
    "name": "Dura-Kold Corp    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 103597,
    "name": "Durable Corp    ( Norwalk OH USA )",
    "country": "United States"
  },
  {
    "id": 999804,
    "name": "Durabrand    (   USA )",
    "country": "United States"
  },
  {
    "id": 999714,
    "name": "Durabuilt Medical Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 106407,
    "name": "Duracell    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 175874,
    "name": "Duralife Inc    ( South Williamsport PA USA )",
    "country": "United States"
  },
  {
    "id": 174593,
    "name": "Duraline Medical Products (Canada)    ( Biggar SK USA )",
    "country": "United States"
  },
  {
    "id": 155412,
    "name": "Duraline Medical Products Inc    ( Leipsic OH USA )",
    "country": "United States"
  },
  {
    "id": 452532,
    "name": "Durbin plc    ( South Harrow  England )",
    "country": "UK"
  },
  {
    "id": 178853,
    "name": "Durfold SleepChair Corp    ( Jackson MS USA )",
    "country": "United States"
  },
  {
    "id": 104987,
    "name": "Duro-Med Industries Inc    ( Jesup GA USA )",
    "country": "United States"
  },
  {
    "id": 364605,
    "name": "DUSA Pharmaceuticals Inc (Canada)    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 332782,
    "name": "DUSA Pharmaceuticals Inc (USA)    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 151223,
    "name": "Dutch Ophthalmic Research Center International bv    ( Zuidland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 155783,
    "name": "Dutch Ophthalmic USA    ( Exeter NH USA )",
    "country": "United States"
  },
  {
    "id": 471587,
    "name": "DUX Dental    ( Oxnard CA USA )",
    "country": "United States"
  },
  {
    "id": 999500,
    "name": "Dux Dental    ( Oxnard CA USA )",
    "country": "United States"
  },
  {
    "id": 458446,
    "name": "DVS Sciences Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 460949,
    "name": "dvsAnalytics Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 161543,
    "name": "DVT Dental AB    ( Orebro  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 392331,
    "name": "DW Auto & Home Mobility Specialties    ( Columbia MO USA )",
    "country": "United States"
  },
  {
    "id": 453679,
    "name": "DWA GmbH & Co KG    ( Ubstadt-Weiher  Germany )",
    "country": "Germany"
  },
  {
    "id": 186834,
    "name": "DWL Elektronische Systeme GmbH    ( Singen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107767,
    "name": "Dwyer Instruments Inc    ( Michigan City IN USA )",
    "country": "United States"
  },
  {
    "id": 426232,
    "name": "Dwyer Instruments Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 426233,
    "name": "Dwyer Instruments Pty Ltd    ( Unanderra  Australia )",
    "country": "Australia"
  },
  {
    "id": 455614,
    "name": "Dwyer Kitchen Showrooms    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 107313,
    "name": "Dwyer Precision Products Inc    ( Jacksonville Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 454304,
    "name": "DxS Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 999336,
    "name": "Dyaco Internatioanl Inc.    (   Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 292964,
    "name": "Dycem Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 452316,
    "name": "Dymalon Inc    ( Timonium MD USA )",
    "country": "United States"
  },
  {
    "id": 108677,
    "name": "Dymax Corp    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 451143,
    "name": "Dymedix Corp    ( Shoreview MN USA )",
    "country": "United States"
  },
  {
    "id": 458350,
    "name": "Dymedso Inc    ( Boisbriand PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999559,
    "name": "Dymo    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 473841,
    "name": "DYMO Corp    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 175354,
    "name": "Dyn-A-Med Products    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 999797,
    "name": "Dyna Dental Engineering b.v.    (   The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 456608,
    "name": "Dyna Systems    ( Perkasie PA USA )",
    "country": "United States"
  },
  {
    "id": 428706,
    "name": "Dyna-Flex International    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 454042,
    "name": "Dynacon Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 177918,
    "name": "Dynal A/S    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 102864,
    "name": "Dynalab Corp    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 461253,
    "name": "DYNALABS    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 454464,
    "name": "Dynamac Living Inc    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 394938,
    "name": "Dynamic Air Inc    ( Orange Beach AL USA )",
    "country": "United States"
  },
  {
    "id": 176209,
    "name": "Dynamic Energy Systems Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 441265,
    "name": "Dynamic Health Systems    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 226275,
    "name": "Dynamic Imaging    ( Allendale NJ USA )",
    "country": "United States"
  },
  {
    "id": 176186,
    "name": "Dynamic Imaging Ltd    ( Livingston  USA )",
    "country": "United States"
  },
  {
    "id": 456599,
    "name": "Dynamic Living Inc    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 461150,
    "name": "Dynamic Solutions Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 358418,
    "name": "Dynamic Surgery Inc    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 107613,
    "name": "Dynamic Systems Inc    ( Leicester NC USA )",
    "country": "United States"
  },
  {
    "id": 306383,
    "name": "Dynamis Medizintechnik AG    ( Dornach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999841,
    "name": "Dynapar    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 106730,
    "name": "Dynarex Corp    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 457116,
    "name": "Dynasil Products    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 409929,
    "name": "Dynasty (Xiamen) Plastic    ( Xiamen  USA )",
    "country": "United States"
  },
  {
    "id": 457533,
    "name": "DynaTech 2000 Inc    ( Manotick ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101226,
    "name": "Dynatech Nevada Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 457099,
    "name": "Dynatech-CBET    ( Leesburg FL USA )",
    "country": "United States"
  },
  {
    "id": 339008,
    "name": "Dynatek Dalta Scientific Instruments    ( Galena MO USA )",
    "country": "United States"
  },
  {
    "id": 105329,
    "name": "Dynatronics    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 999713,
    "name": "Dynavision International LLC    ( West Chester OH USA )",
    "country": "USA"
  },
  {
    "id": 363249,
    "name": "Dynavit & Co KG    ( Kaiserslautern  Germany )",
    "country": "Germany"
  },
  {
    "id": 384360,
    "name": "DynaVox Mayer-Johnson    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 999677,
    "name": "DynaVox Mayer-Johnson    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 102810,
    "name": "DynaWave Corp    ( Geneva IL USA )",
    "country": "United States"
  },
  {
    "id": 451191,
    "name": "Dyne Trading Co    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 306171,
    "name": "Dynek Pty Ltd    ( Hendon  Australia )",
    "country": "Australia"
  },
  {
    "id": 999514,
    "name": "Dynex Products    ( Richfield MN USA )",
    "country": "United States"
  },
  {
    "id": 273788,
    "name": "Dynex Technologies Inc    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 102811,
    "name": "Dyonics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 306354,
    "name": "E & L Computer Systeme    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 367472,
    "name": "E & M Engineering    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 101660,
    "name": "E A Beck & Co    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 185602,
    "name": "E Benson Hood Laboratories    ( Pembroke MA USA )",
    "country": "United States"
  },
  {
    "id": 325029,
    "name": "E Biegler GmbH    ( Mauerbach  Austria )",
    "country": "Austria"
  },
  {
    "id": 299832,
    "name": "E C Moore Co Inc    ( Dearborn MI USA )",
    "country": "United States"
  },
  {
    "id": 455410,
    "name": "E Comfort Health Studio    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 104851,
    "name": "E F Bavis & Assoc Inc    ( Maineville OH USA )",
    "country": "United States"
  },
  {
    "id": 108015,
    "name": "E Fougera & Co    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 283658,
    "name": "E Hahnenkratt GmbH    ( Koenigsbach-Stein  Germany )",
    "country": "Germany"
  },
  {
    "id": 174414,
    "name": "E J Krieger & Cia Ltda/Permution    ( Curitiba-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 286689,
    "name": "E Link & Co GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105350,
    "name": "E M Adams Co Inc    ( Fort Pierce FL USA )",
    "country": "United States"
  },
  {
    "id": 391294,
    "name": "E Renggli AG    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 152433,
    "name": "E Tamussino & Cia Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 281882,
    "name": "E Wild Verbandklammern    ( Fuerth  Germany )",
    "country": "Germany"
  },
  {
    "id": 176121,
    "name": "E-A-R Specialty Composites    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 455977,
    "name": "E-Care Technology Co Ltd    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455185,
    "name": "E-Chrom Tech Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455380,
    "name": "E-COM Technologies Ltd    ( Zhuhai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458978,
    "name": "e-Image Data Corp    ( Hartford WI USA )",
    "country": "United States"
  },
  {
    "id": 455864,
    "name": "e-life International Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452119,
    "name": "e-MDs    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 104165,
    "name": "E-Med Corp    ( Maineville OH USA )",
    "country": "United States"
  },
  {
    "id": 402700,
    "name": "e-med innovations Europe    ( Ecuisses  USA )",
    "country": "United States"
  },
  {
    "id": 399143,
    "name": "e-med innovations Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 460937,
    "name": "e-PharmPro LLC    ( Brookhaven PA USA )",
    "country": "United States"
  },
  {
    "id": 184885,
    "name": "E-Stat Plastics    ( Middlesex NJ USA )",
    "country": "United States"
  },
  {
    "id": 458858,
    "name": "E-Z Systems Inc    ( Palmer PA USA )",
    "country": "United States"
  },
  {
    "id": 392465,
    "name": "E-Z-EM Canada Inc    ( Anjou PQ USA )",
    "country": "United States"
  },
  {
    "id": 102756,
    "name": "E-Z-EM Inc    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 184517,
    "name": "E-Z-EM Ltd    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 458945,
    "name": "EA Fischione Instruments Inc    ( Export PA USA )",
    "country": "United States"
  },
  {
    "id": 348950,
    "name": "EAC Corp    ( Teterboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 170536,
    "name": "EAD Systems Corp    ( Quincy MA USA )",
    "country": "United States"
  },
  {
    "id": 460959,
    "name": "Eagle Consulting Services LLC    ( Sandy Spring MD USA )",
    "country": "United States"
  },
  {
    "id": 330965,
    "name": "Eagle Endoscopic Inc    ( St Augustine FL USA )",
    "country": "United States"
  },
  {
    "id": 459364,
    "name": "Eagle Group    ( Clayton DE USA )",
    "country": "United States"
  },
  {
    "id": 460864,
    "name": "Eagle Group    ( Clayton DE USA )",
    "country": "United States"
  },
  {
    "id": 322929,
    "name": "Eagle Health Supplies Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 452158,
    "name": "Eagle Innovations    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 155785,
    "name": "Eagle Laboratories    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 431514,
    "name": "Eagle Mfg Co    ( Wellsburg WV USA )",
    "country": "United States"
  },
  {
    "id": 179055,
    "name": "Eagle MHC    ( Clayton DE USA )",
    "country": "United States"
  },
  {
    "id": 999758,
    "name": "Eagle Microscope Service & Bulb Company LLC    (   USA )",
    "country": "United States"
  },
  {
    "id": 451926,
    "name": "Eagle Parts and Products    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 460961,
    "name": "Eagle Pneumatic Inc    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 176212,
    "name": "Eagle Sportschairs    ( Snellville GA USA )",
    "country": "United States"
  },
  {
    "id": 330807,
    "name": "Eagle Technology Inc    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 456604,
    "name": "Eagle Vision Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 152621,
    "name": "EagleVision Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 431507,
    "name": "EAI Corp    ( Abingdon MD USA )",
    "country": "United States"
  },
  {
    "id": 458047,
    "name": "EAO AG    ( Olten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461021,
    "name": "EAP Expert Inc    ( Georgetown ON Canada )",
    "country": "Canada"
  },
  {
    "id": 283706,
    "name": "Ear-Technic GmbH    ( Kreuztal  Germany )",
    "country": "Germany"
  },
  {
    "id": 456434,
    "name": "EarlySense    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 457546,
    "name": "Earthlite Massage Tables    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 324791,
    "name": "Easiaids Ltd    ( Gosport  USA )",
    "country": "United States"
  },
  {
    "id": 182830,
    "name": "East Asian Dental Corp Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 457100,
    "name": "East Coast Medical Systems    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 450917,
    "name": "East Coast Surgical Instruments Inc    ( Elizabethtown PA USA )",
    "country": "United States"
  },
  {
    "id": 457001,
    "name": "East Health Care Co Ltd    ( Jiangyin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 147184,
    "name": "East Penn Mfg Co Inc    ( Lyon Station PA USA )",
    "country": "United States"
  },
  {
    "id": 330647,
    "name": "Eastek Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 106297,
    "name": "Easter Services Inc    ( Matherville IL USA )",
    "country": "United States"
  },
  {
    "id": 457101,
    "name": "Eastern Diagnostic Imaging Inc    ( Taunton MA USA )",
    "country": "United States"
  },
  {
    "id": 152413,
    "name": "Eastern Medical Apparatus Works Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 108931,
    "name": "Eastern Rail Systems Inc    ( Bristol PA USA )",
    "country": "United States"
  },
  {
    "id": 287727,
    "name": "Eastern Surgical Co    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 292970,
    "name": "Eastman Chemical International AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 273728,
    "name": "Eastman Kodak Co    ( New Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 452245,
    "name": "EASTMAN KODAK-CARESTREAM HEALTH ONEX    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 461029,
    "name": "Eastman Park Micrographics (EPM) Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 286038,
    "name": "Easy Innovative Software    ( Schwaebisch Gmuend  Germany )",
    "country": "Germany"
  },
  {
    "id": 335709,
    "name": "Easy Medical Equipment    ( Seven Hills  USA )",
    "country": "United States"
  },
  {
    "id": 456547,
    "name": "Easy Wheel Services & Trading    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455868,
    "name": "EASYTEM Co Ltd    ( Siheung  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 177701,
    "name": "eatide Industrial Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 450901,
    "name": "Eaton Corp    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 149574,
    "name": "Eaton Medicals Corp    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 108610,
    "name": "EatonCare Telemetry Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 451875,
    "name": "Eatonform Inc    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 461040,
    "name": "eAudit Solutions Inc    ( Grapvine TX USA )",
    "country": "United States"
  },
  {
    "id": 107143,
    "name": "EB Enterprises    (   USA )",
    "country": "United States"
  },
  {
    "id": 461055,
    "name": "EB Medical Inc    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 354767,
    "name": "EB Neuro SpA    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 103268,
    "name": "Eberbach Corp    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 280808,
    "name": "Eberh Wohlfahrt Schuhfabrik    ( Dettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286691,
    "name": "Ebert Medizintechnik    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 394348,
    "name": "EBI LP    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 456511,
    "name": "eBioscience Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 336080,
    "name": "Ebos Group Ltd    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 467375,
    "name": "EBP Supply Solutions    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 461057,
    "name": "eBridge Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 174595,
    "name": "EC Walkers Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 465033,
    "name": "ec2 Software Solutions    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 174225,
    "name": "Ecafix Industria e Comercio Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 454565,
    "name": "eCast corp    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 237793,
    "name": "ECEP Ltd    ( Lagos  Nigeria )",
    "country": "Nigeria"
  },
  {
    "id": 453017,
    "name": "Echo-Son SA    ( Pulawy  Poland )",
    "country": "Poland"
  },
  {
    "id": 232576,
    "name": "EchoCath Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458355,
    "name": "EchoMRI LLC    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 453743,
    "name": "Echosens SA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 454767,
    "name": "Echoserve    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 465133,
    "name": "EchoStor Technologies    ( Hopkinton 45 USA )",
    "country": "United States"
  },
  {
    "id": 171239,
    "name": "ECI Medical Technologies Inc    ( Bridgewater NS USA )",
    "country": "United States"
  },
  {
    "id": 279494,
    "name": "Eckard Zeisberg Hno-Technik Neurootometrie    ( Alvesse  Germany )",
    "country": "Germany"
  },
  {
    "id": 281872,
    "name": "Eckard-Design Technik GmbH    ( Fulda  Germany )",
    "country": "Germany"
  },
  {
    "id": 104016,
    "name": "Eckel Industries Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 156138,
    "name": "Eckel Industries of Canada Ltd    ( Morrisburg ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453740,
    "name": "Eckel Industries UK    ( Bangshot  England )",
    "country": "UK"
  },
  {
    "id": 452075,
    "name": "Eckert & Ziegler BEBIG GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 128448,
    "name": "EclecTech LLC    ( Laveen AZ USA )",
    "country": "United States"
  },
  {
    "id": 458678,
    "name": "Ecleris    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 453841,
    "name": "eClinicalWorks    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 459395,
    "name": "Eclipse Acoustic Solutions Inc    ( Belle Plaine MN USA )",
    "country": "United States"
  },
  {
    "id": 459063,
    "name": "Eclipse Aesthetics LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 454079,
    "name": "Eclipse Medical Imaging Inc    ( Vacaville CA USA )",
    "country": "United States"
  },
  {
    "id": 170358,
    "name": "Eclipse Systems Inc    ( Durham CT USA )",
    "country": "United States"
  },
  {
    "id": 312348,
    "name": "Eclipsys Corp    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 453022,
    "name": "ECM    ( Angouleme  France )",
    "country": "France"
  },
  {
    "id": 456782,
    "name": "ECM Europe BV    ( Gemert  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 465105,
    "name": "ECMO Advantage Corp    ( Murfreesboro TN USA )",
    "country": "United States"
  },
  {
    "id": 466516,
    "name": "ECO Medics AG    ( Duernten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 192818,
    "name": "Ecodent Dental Equipment GmbH    ( Ilsede  Germany )",
    "country": "Germany"
  },
  {
    "id": 312330,
    "name": "Ecolab Professional Products Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 457520,
    "name": "Ecom Merchandies    ( Forest Hills NY USA )",
    "country": "United States"
  },
  {
    "id": 174226,
    "name": "Ecomed Comercio e Industria Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 359901,
    "name": "Ecomedis Medizintechnik & Sensorik GmbH    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 454638,
    "name": "Econo-Med    ( Sutton  England )",
    "country": "UK"
  },
  {
    "id": 296803,
    "name": "Economic Holland bv    ( Badhoevedorp  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455870,
    "name": "ECORAY Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 416154,
    "name": "Ecovital Comercio e Servicos Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 999840,
    "name": "EcoWater Systems LLC    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 456503,
    "name": "ECRI Institute    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 461067,
    "name": "ECRS    ( Boone NC USA )",
    "country": "United States"
  },
  {
    "id": 178851,
    "name": "ECS Nuclear    ( Sunland CA USA )",
    "country": "United States"
  },
  {
    "id": 173877,
    "name": "Ectron Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 458395,
    "name": "Eczacibasi-Monrol Nukleer Urunler San ve Tic AS    ( Kocaeli  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 168724,
    "name": "EDAK AG    ( Dachsen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 363192,
    "name": "EDAK Inc    ( Medford NY USA )",
    "country": "United States"
  },
  {
    "id": 454093,
    "name": "Edan Instruments Inc    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 463039,
    "name": "Edan USA    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 417393,
    "name": "EDAP SA    ( Vaulx-en-Velin  France )",
    "country": "France"
  },
  {
    "id": 384317,
    "name": "EDAP Technomed (M) Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 384314,
    "name": "EDAP Technomed Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 384316,
    "name": "EDAP Technomed srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 458187,
    "name": "EDAP TMS GmbH    ( Flensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458188,
    "name": "EDAP TMS Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461088,
    "name": "EDCi    ( Appleton WI USA )",
    "country": "United States"
  },
  {
    "id": 453297,
    "name": "Edda Technology Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 453534,
    "name": "Edda Technology Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999460,
    "name": "Edemco Dryers, Inc    ( Peyton CO USA )",
    "country": "United States"
  },
  {
    "id": 999464,
    "name": "Edemco Dryers, Inc    ( Peyton CO USA )",
    "country": "United States"
  },
  {
    "id": 168487,
    "name": "Edenta AG Dentalprodukte    ( Au/St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451144,
    "name": "Edge Biologicals Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 108902,
    "name": "Edge Medical    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 455301,
    "name": "EDGE Medical Devices Inc    ( Mount Holly NC USA )",
    "country": "United States"
  },
  {
    "id": 455300,
    "name": "EDGE Medical Devices Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 999929,
    "name": "Edge System Corporation    ( Signal Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 458982,
    "name": "Edge Systems LLC    ( Signal Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 104166,
    "name": "Edgeco Hospital Products    ( Little Ferry NJ USA )",
    "country": "United States"
  },
  {
    "id": 999604,
    "name": "EdgeStar Products    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 431592,
    "name": "Edgewood Chemical Biological Center    ( Aberdeen Proving Grounds MD USA )",
    "country": "United States"
  },
  {
    "id": 457285,
    "name": "EDIC    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 461093,
    "name": "Edifecs Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 357531,
    "name": "Edison Telecom Ltd    ( Aldershot  England )",
    "country": "UK"
  },
  {
    "id": 999602,
    "name": "Edlund Company Inc.    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 451808,
    "name": "Edmund Buehler GmbH    ( Hechingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 102516,
    "name": "Edmund Industrial Optics    ( Barrington NJ USA )",
    "country": "United States"
  },
  {
    "id": 999532,
    "name": "Edmund Scientific    ( Tonawanda NY USA )",
    "country": "USA"
  },
  {
    "id": 103434,
    "name": "EDRO Corp    ( East Berlin CT USA )",
    "country": "United States"
  },
  {
    "id": 102956,
    "name": "Edroy Products Co Inc    ( Nyack NY USA )",
    "country": "United States"
  },
  {
    "id": 431512,
    "name": "Edsal Mfg Co Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 157675,
    "name": "Edsbyns Tryckeri AB    ( Edsbyn  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 460948,
    "name": "Edstrom Industries Inc    ( Waterford WI USA )",
    "country": "United States"
  },
  {
    "id": 160100,
    "name": "Eduard Munz & Co Ergonomische Arbeitsplaetze    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 460620,
    "name": "Education Management Solutions Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 462924,
    "name": "Education Science International    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 460945,
    "name": "EduTracker    ( Galt CA USA )",
    "country": "United States"
  },
  {
    "id": 999564,
    "name": "Edvotek Inc.    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 284312,
    "name": "Edwards Hochvakuum GmbH    ( Marburg  USA )",
    "country": "United States"
  },
  {
    "id": 455420,
    "name": "Edwards Lifesciences (Asia) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 415798,
    "name": "Edwards Lifesciences (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 394197,
    "name": "Edwards Lifesciences Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 374501,
    "name": "Edwards Lifesciences Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 415795,
    "name": "Edwards Lifesciences India Pvt Ltd    ( Mumbai  USA )",
    "country": "United States"
  },
  {
    "id": 413003,
    "name": "Edwards Lifesciences Ltd    ( Newbury  USA )",
    "country": "United States"
  },
  {
    "id": 415797,
    "name": "Edwards Lifesciences Macchi Ltd    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 279646,
    "name": "Euro-Lab Labortechnik KG    ( Bad Feilnbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 415796,
    "name": "Edwards Lifesciences SA    ( Nyon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451132,
    "name": "Edwards Medical Supply    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 457595,
    "name": "EEG Info    ( Woodland Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 460943,
    "name": "EF Johnson Technologies Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 177016,
    "name": "EFER    ( La Ciotat Cedex  France )",
    "country": "France"
  },
  {
    "id": 306775,
    "name": "eff & eff Systemberatung GmbH    ( Wuerselen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293310,
    "name": "Effidata AG    ( Effretikon  USA )",
    "country": "United States"
  },
  {
    "id": 452526,
    "name": "EFI Electronics (Spain)    ( Cornella de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 106902,
    "name": "EFI Electronics Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 999941,
    "name": "EFOS    ( Williamsville  USA )",
    "country": "United States"
  },
  {
    "id": 431542,
    "name": "EfstonScience    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455697,
    "name": "EG Medical Systems    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 465066,
    "name": "Egemin Automation Inc.    ( Holland 11 USA )",
    "country": "United States"
  },
  {
    "id": 457632,
    "name": "eGeneral Medical Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 282070,
    "name": "Egertina Walter Carl Egert    ( Goslar  Germany )",
    "country": "Germany"
  },
  {
    "id": 157973,
    "name": "Egger und Co    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 455698,
    "name": "Egifix Medical Co    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 457965,
    "name": "EGNER Dental-Manufaktur    ( Schemmerhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281854,
    "name": "Egon Faulhaber Pinzetten    ( Frittlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285670,
    "name": "Egona Egon Boersch GmbH & Co KG    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 455701,
    "name": "Egy Chem for Lab Technology    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 455702,
    "name": "Egyptian Co For Biotechnology    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 459079,
    "name": "eHealth Data Solutions    ( Yellow Springs OH USA )",
    "country": "United States"
  },
  {
    "id": 458980,
    "name": "eHealth Technologies    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 170866,
    "name": "EHOB Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 157974,
    "name": "Ehret GmbH & Co KG    ( Emmendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452120,
    "name": "EHS    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 454566,
    "name": "EHS Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 293557,
    "name": "EHS Schaffhausen AG    ( Neuhausen am Rheinfall  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 157676,
    "name": "Eica AB    ( Halmstad  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 441020,
    "name": "eicd.com by YakiTECHNOLOGIES    ( Hyattsville MD USA )",
    "country": "United States"
  },
  {
    "id": 458654,
    "name": "EiCOM    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 458655,
    "name": "EiCOM USA    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 102517,
    "name": "Eigen Medical    ( Grass Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 458957,
    "name": "Eight Medical Corp    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 157913,
    "name": "Eiken Chemical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 465171,
    "name": "Eiki International, Inc.    ( Rancho Santa Margarita 30 USA )",
    "country": "United States"
  },
  {
    "id": 999837,
    "name": "Eiki International, Inc.    ( Rancho Santa Margarita CA USA )",
    "country": "United States"
  },
  {
    "id": 338362,
    "name": "Einat Medical Agencies    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 281864,
    "name": "Eisenhut Instrumente GmbH    ( Frittlingen/Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458757,
    "name": "Eisys Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 435440,
    "name": "Eizo Nanao Corp    ( Hakusan  Japan )",
    "country": "Japan"
  },
  {
    "id": 435443,
    "name": "Eizo Nanao Technologies Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 435435,
    "name": "Eizo Nordic AB    ( Upplands Vasby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 105656,
    "name": "EK Industries Inc    ( Joliet IL USA )",
    "country": "United States"
  },
  {
    "id": 453675,
    "name": "Ekahau (Finland)    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 453676,
    "name": "Ekahau Asia Pacific    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452346,
    "name": "Ekahau Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 306385,
    "name": "ekamed - Erika Kayser    ( Wunstorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 466815,
    "name": "EKF Life Sciences    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 322061,
    "name": "EKF-diagnostic GmbH    ( Barleben/Magdeburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 174476,
    "name": "EKO Instruments Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457261,
    "name": "EKO Instruments Europe bv    ( Den Haag  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457259,
    "name": "EKO Instruments USA Inc    ( San Jose  USA )",
    "country": "United States"
  },
  {
    "id": 451120,
    "name": "eko systems inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 451188,
    "name": "EKOS Corp    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 450729,
    "name": "Ekotek Global Inc    ( Lively ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458690,
    "name": "Eksigent    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458792,
    "name": "Ekso Bionics    ( Richmond CA USA )",
    "country": "United States"
  },
  {
    "id": 306278,
    "name": "EKU Elektronik GmbH    ( Leiningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 426730,
    "name": "El En SpA Group    ( Calenzano (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 417257,
    "name": "ELA Medical (Portugal)    ( Camaxide Lisboa  USA )",
    "country": "United States"
  },
  {
    "id": 417251,
    "name": "ELA Medical Benelux SA    ( Lasne  USA )",
    "country": "United States"
  },
  {
    "id": 417254,
    "name": "ELA Medical bv    ( Oudenhoorn  USA )",
    "country": "United States"
  },
  {
    "id": 417249,
    "name": "ELA Medical GmbH    ( Muenchen  USA )",
    "country": "United States"
  },
  {
    "id": 103749,
    "name": "ELA Medical Inc    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 417256,
    "name": "ELA Medical Izasa    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 287786,
    "name": "ELA Medical Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 417255,
    "name": "ELA Medical Nordic    ( Rodovre  USA )",
    "country": "United States"
  },
  {
    "id": 139264,
    "name": "ELA Medical SA    ( Le Plessis Robinson Cedex  France )",
    "country": "France"
  },
  {
    "id": 417253,
    "name": "ELA Medical SA (Switzerland)    ( Lausanne 30  USA )",
    "country": "United States"
  },
  {
    "id": 417250,
    "name": "ELA Medical srl    ( Agrate Brianza MI  USA )",
    "country": "United States"
  },
  {
    "id": 417252,
    "name": "ELA Medical UK Ltd    ( St Albans  USA )",
    "country": "United States"
  },
  {
    "id": 138304,
    "name": "Elan Corp plc    ( Dublin 2  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 287495,
    "name": "Elcat GmbH    ( Wolfratshausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456906,
    "name": "Elchrom Scientific AG    ( Cham  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 183928,
    "name": "Elcomatic Ltd    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 999933,
    "name": "Elcomed    (   USA )",
    "country": "United States"
  },
  {
    "id": 102422,
    "name": "Elconap Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 322467,
    "name": "Eldan Elctronic Instruments Co Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 466515,
    "name": "Elecom Systems Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 183464,
    "name": "Electone Inc    ( Longwood FL USA )",
    "country": "United States"
  },
  {
    "id": 161815,
    "name": "Electra-Tec Inc    ( Otsego MI USA )",
    "country": "United States"
  },
  {
    "id": 144473,
    "name": "ElectraMed Corp    ( Flint MI USA )",
    "country": "United States"
  },
  {
    "id": 450891,
    "name": "Electric Medical Records    ( Fayetteville AR USA )",
    "country": "United States"
  },
  {
    "id": 272449,
    "name": "Electric Mobility Canada    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 105694,
    "name": "Electric Mobility Corp    ( Sewell NJ USA )",
    "country": "United States"
  },
  {
    "id": 162277,
    "name": "Electric Mobility Euro Ltd    ( Ilminster  England )",
    "country": "UK"
  },
  {
    "id": 435445,
    "name": "Electrical Geodesics Inc    ( Eugene OR USA )",
    "country": "United States"
  },
  {
    "id": 460940,
    "name": "Electro Kinetic Technologies    ( Germantown WI USA )",
    "country": "United States"
  },
  {
    "id": 457377,
    "name": "Electro Medical Equipment    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 162378,
    "name": "Electro Medical Inc    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 336441,
    "name": "Electro Parts SA    ( San Salvador  USA )",
    "country": "United States"
  },
  {
    "id": 999698,
    "name": "Electro Powerpacs Co    ( Cambridge MA USA )",
    "country": "USA"
  },
  {
    "id": 331223,
    "name": "Electro Standards Laboratories    ( Cranston RI USA )",
    "country": "United States"
  },
  {
    "id": 106998,
    "name": "Electro Therapeutics Devices Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 105034,
    "name": "Electro-Cap International Inc    ( Eaton OH USA )",
    "country": "United States"
  },
  {
    "id": 458485,
    "name": "Electro-Diagnostic Imaging Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458161,
    "name": "Electro-Matic Products Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 101245,
    "name": "Electro-Med Health Industries    ( North Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 457985,
    "name": "Electro-Medical Equipment Co Ltd    ( Richmond Hill  Canada )",
    "country": "Canada"
  },
  {
    "id": 174641,
    "name": "Electro-Medical Instrument Co    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 401745,
    "name": "Electro-Optical Science Inc    ( Irvington NY USA )",
    "country": "United States"
  },
  {
    "id": 102252,
    "name": "Electro-Surgical Instrument Co    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 185879,
    "name": "Electro-Tech Systems Inc    ( Glenside PA USA )",
    "country": "United States"
  },
  {
    "id": 460788,
    "name": "ElectroForce Systems Group    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 183329,
    "name": "Electrohome Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 187416,
    "name": "ElectroLab Medic SA CV    ( San Salvador  El Salvador )",
    "country": "El Salvador"
  },
  {
    "id": 451939,
    "name": "Electrolux Laundry Systems Denmark A/S    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458530,
    "name": "Electrolux Major Appliances    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 102253,
    "name": "Electromagnetic Industries Div Square D Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 999609,
    "name": "ELECTROMATIC Equipment Co., Inc.    ( Cedarhurst NY USA )",
    "country": "USA"
  },
  {
    "id": 453655,
    "name": "Electromed Inc    ( New Prague MN USA )",
    "country": "United States"
  },
  {
    "id": 309145,
    "name": "Electromedical Products International (Europe) Ltd    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 147161,
    "name": "Electromedical Products International Inc    ( Mineral Wells TX USA )",
    "country": "United States"
  },
  {
    "id": 230811,
    "name": "Electromedicarin SA    ( Parets del Valles (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 155693,
    "name": "Electromedics Medizintechnik GmbH    ( Alzenau  USA )",
    "country": "United States"
  },
  {
    "id": 458487,
    "name": "Electromek Diagnostic Systems    ( Troy IL USA )",
    "country": "United States"
  },
  {
    "id": 125202,
    "name": "Electron Microscopy Sciences    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 457121,
    "name": "Electronic Control Concepts    ( Saugerties NY USA )",
    "country": "United States"
  },
  {
    "id": 401977,
    "name": "Electronic Data Systems Corp    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 416119,
    "name": "Electronic Data Systems Corp (Australia)    ( Millers Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 416121,
    "name": "Electronic Data Systems Corp (Brazil)    ( Sao Bernardo do Campo_SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 416120,
    "name": "Electronic Data Systems Corp (Canada)    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 416116,
    "name": "Electronic Data Systems Corp (Hong Kong)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416117,
    "name": "Electronic Data Systems Corp (New Zealand)    ( Wellington  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 416118,
    "name": "Electronic Data Systems Corp (United Kingdom)    ( Uxbridge  England )",
    "country": "UK"
  },
  {
    "id": 144344,
    "name": "Electronic Development Labs Inc    ( Danville VA USA )",
    "country": "United States"
  },
  {
    "id": 184310,
    "name": "Electronic Healthcare Systems    ( Freedom PA USA )",
    "country": "United States"
  },
  {
    "id": 362360,
    "name": "Electronic Imaging Materials Inc    ( Keene NH USA )",
    "country": "United States"
  },
  {
    "id": 176216,
    "name": "Electronic Mobility Controls    ( Baton Rouge LA USA )",
    "country": "United States"
  },
  {
    "id": 459040,
    "name": "Electronic Registry Systems Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 177022,
    "name": "Electronic Safety Products    ( Windsor  USA )",
    "country": "United States"
  },
  {
    "id": 226127,
    "name": "Electronic Services Nouvag USA    ( Lake Hughes CA USA )",
    "country": "United States"
  },
  {
    "id": 464994,
    "name": "Electronic Waveform Lab (EWL) Inc    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 459163,
    "name": "Electronics For Industry    ( South Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 460793,
    "name": "Electronics USA    ( Victorville CA USA )",
    "country": "United States"
  },
  {
    "id": 106933,
    "name": "Electropedic Products    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 292632,
    "name": "ELECTROTEK    ( Shipley  England )",
    "country": "UK"
  },
  {
    "id": 439290,
    "name": "Electrovaya    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 282683,
    "name": "Elefant-Chemie Breuhan GmbH & Co Vertriebs KG    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 299735,
    "name": "Elekta AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455282,
    "name": "Elekta IMPAC Software    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 455285,
    "name": "Elekta IMPAC Software Pty Ltd    ( Moonee Ponds  Australia )",
    "country": "Australia"
  },
  {
    "id": 418693,
    "name": "Elekta Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 138071,
    "name": "Elekta Instrument AB    ( Stockholm  USA )",
    "country": "United States"
  },
  {
    "id": 230128,
    "name": "Elekta Instruments Asia Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 309782,
    "name": "Elekta Oncology Systems Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 270575,
    "name": "Elekta Surgical Instruments    ( Andover  USA )",
    "country": "United States"
  },
  {
    "id": 455280,
    "name": "Elekta/CMS GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455281,
    "name": "Elekta/CMS Inc    ( Maryland Heights MO USA )",
    "country": "United States"
  },
  {
    "id": 457284,
    "name": "Elekta/IMPAC Medical Systems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 358443,
    "name": "Elektra GmbH & Co KG    ( Enger  USA )",
    "country": "United States"
  },
  {
    "id": 175542,
    "name": "Elektro Assemblies Inc    ( Red Wing MN USA )",
    "country": "United States"
  },
  {
    "id": 457336,
    "name": "Elektro-Medical Gharieni GmbH    ( Moers  Germany )",
    "country": "Germany"
  },
  {
    "id": 179063,
    "name": "Elektrotherapie Geraete GmbH (ETH)    ( Lauffen  Germany )",
    "country": "Germany"
  },
  {
    "id": 185390,
    "name": "Elektrox    ( Bydgoszcz  USA )",
    "country": "United States"
  },
  {
    "id": 999688,
    "name": "Elema-Schonander    (   USA )",
    "country": "United States"
  },
  {
    "id": 458400,
    "name": "ELEMENTAR Americas Inc    ( Mt Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 458399,
    "name": "ELEMENTAR Analysensysteme GmbH    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 999496,
    "name": "Elephant Manufacturing Co., Ltd.    ( Jiangsu  China )",
    "country": "China"
  },
  {
    "id": 174227,
    "name": "Eletro-Medica Brasileira Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 174228,
    "name": "Eletromedicina Berger Industria e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 152527,
    "name": "Elettronica Pagani srl    ( Paderno Dugnano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456753,
    "name": "Elettronica Valseriana srl    ( Casnigo (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454596,
    "name": "ELGA LabWater Global Operations    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 362601,
    "name": "ELGEMS Ltd Advanced Technology    ( Tirat Carmel  Israel )",
    "country": "Israel"
  },
  {
    "id": 102960,
    "name": "Elgin Exercise Equipment Corp    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 455473,
    "name": "Elgin Medical UK Ltd    ( Loanhead  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 107641,
    "name": "Eli Lilly & Co    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 356446,
    "name": "Eliav Medical Imaging Systems Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 276651,
    "name": "Elico Ltd    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 999474,
    "name": "Elite    (   USA )",
    "country": "United States"
  },
  {
    "id": 460798,
    "name": "Elite Chiropractic Tables    ( Hagersville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456288,
    "name": "Elite Medical (Nanjing) Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392046,
    "name": "Elite Surgical Supplies Pty Ltd    ( Pretoria  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 466911,
    "name": "ELITech Benelux    ( Spankeren/Dieren  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 465231,
    "name": "ELITech Biomedical Systems    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 452947,
    "name": "ELItech Group    ( Puteaux  France )",
    "country": "France"
  },
  {
    "id": 456896,
    "name": "ELITech Italy    ( Trezzano sul Naviglio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456895,
    "name": "ELITech Latin America    ( Vitoria-ES  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 456894,
    "name": "ELITech North America    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 456898,
    "name": "ELITech Serbia    ( Beograd  Serbia )",
    "country": "Serbia"
  },
  {
    "id": 456899,
    "name": "ELITech Switzerland    ( Estavayer le Lac  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456897,
    "name": "ELITech UK Ltd    ( Berkhamsted  England )",
    "country": "UK"
  },
  {
    "id": 452774,
    "name": "Elixir Research LLC    ( Vashon WA USA )",
    "country": "United States"
  },
  {
    "id": 382225,
    "name": "Elk Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 279560,
    "name": "Elky Gesellschaft fuer Technische Entwicklungen mb    ( Aschaffenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456564,
    "name": "ELLA-CS sro    ( Hradec Kralove 6  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 399022,
    "name": "Ella-Legros SA    ( Tauxigny  France )",
    "country": "France"
  },
  {
    "id": 451133,
    "name": "Ellard Instrumentation Ltd    ( Monroe WA USA )",
    "country": "United States"
  },
  {
    "id": 168489,
    "name": "Ellema AG    ( Fahrweid  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451655,
    "name": "Ellex Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 248769,
    "name": "Ellex Medical Lasers Ltd    ( Adelaide  Australia )",
    "country": "Australia"
  },
  {
    "id": 106787,
    "name": "Elliott Bay Laboratory Services Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 458763,
    "name": "Elliott Data Systems Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 458012,
    "name": "Ellipse Technologies Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 453944,
    "name": "Elliquence LLC    ( Baldwin NY USA )",
    "country": "United States"
  },
  {
    "id": 105177,
    "name": "Ellis & Watts International    ( Batavia OH USA )",
    "country": "United States"
  },
  {
    "id": 459323,
    "name": "Ellis Corp    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 105399,
    "name": "Ellman International Inc    ( Oceanside NY USA )",
    "country": "United States"
  },
  {
    "id": 156986,
    "name": "Elltec Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 185916,
    "name": "ELM International Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 158097,
    "name": "ELMA GmbH & Co KG    ( Singen  Germany )",
    "country": "Germany"
  },
  {
    "id": 365759,
    "name": "Elmed Elektronik Ve Medikal San Ve Tic AS    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 169609,
    "name": "Elmed GmbH    ( Elmed  Germany )",
    "country": "Germany"
  },
  {
    "id": 101348,
    "name": "Elmed Inc    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 456059,
    "name": "Elmed Instruments (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 365760,
    "name": "ELMED USA    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 284062,
    "name": "Elmex Forschung Wybert GmbH    ( Loerrach  Germany )",
    "country": "Germany"
  },
  {
    "id": 186593,
    "name": "Euro-Med Div CooperSurgical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 409550,
    "name": "ELMSTech Inc    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 999899,
    "name": "ELO Touch Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 453294,
    "name": "Elo TouchSystems    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 158098,
    "name": "Elomed GmbH    ( Straubenhardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 466928,
    "name": "Elos AB    ( Lidkoping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 462985,
    "name": "Elpas Inc    ( Westford MA USA )",
    "country": "United States"
  },
  {
    "id": 416027,
    "name": "Elpol Sp z o o OTU Teson    ( Pulawy  Poland )",
    "country": "Poland"
  },
  {
    "id": 182863,
    "name": "ELPRO srl    ( Moncalieri (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459324,
    "name": "ELPRO-BUCHS AG    ( Buchs SG  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457337,
    "name": "Elquest Corp    ( Tomisato  Japan )",
    "country": "Japan"
  },
  {
    "id": 168490,
    "name": "Elro-Werke AG    ( Bremgarten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458735,
    "name": "ELS Products Corp    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 999782,
    "name": "Elscint    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 460848,
    "name": "Elsevier CPM Resource Center    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 458962,
    "name": "Elsevier Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 455778,
    "name": "ElsMed Medical Systems ltd    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 185391,
    "name": "Elterma Lubuskie Zaklady Termotechniczne    ( Swiebodzin  Poland )",
    "country": "Poland"
  },
  {
    "id": 152542,
    "name": "Eltex of Sweden Ltd    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 148413,
    "name": "Elvex Corp    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 451685,
    "name": "Elyos    ( Mouvaux  France )",
    "country": "France"
  },
  {
    "id": 157707,
    "name": "Elyria Plastic Products Inc    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 103516,
    "name": "EM Science    (   USA )",
    "country": "United States"
  },
  {
    "id": 432584,
    "name": "Emageon Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 458720,
    "name": "Emageon Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 185392,
    "name": "Emalia Olkuska Fabryka Naczyn Emaliowanych SA    ( Olkusz  Poland )",
    "country": "Poland"
  },
  {
    "id": 451765,
    "name": "Embeem Inc    ( Milltown NJ USA )",
    "country": "United States"
  },
  {
    "id": 452864,
    "name": "Embla (Canada)    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452866,
    "name": "Embla (Deutschland)    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452865,
    "name": "Embla (Netherlands)    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452862,
    "name": "Embla (USA)    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 370894,
    "name": "Embo-Optics LLC    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 174257,
    "name": "Embraeme Ind Imp de Equips Medicos Ltda    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 293553,
    "name": "Embru-Werke Mantel & Cie    ( Rueti  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451155,
    "name": "Embryotech Laboratories Inc    ( Haverhill MA USA )",
    "country": "United States"
  },
  {
    "id": 453886,
    "name": "EMC Corp    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 454218,
    "name": "EMC Corp    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 279349,
    "name": "EMC GmbH Erzeugnisse fuer Medizin & Chirurgie    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451125,
    "name": "EMcision Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 358009,
    "name": "Emco Ltda    ( Medellin  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 287725,
    "name": "Emco Meditek Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 417951,
    "name": "EMD Chemicals Inc    ( Gibbstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 454564,
    "name": "eMD Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 457480,
    "name": "EMD Millipore Chemicals    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 457481,
    "name": "EMD Millipore Corp    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 393535,
    "name": "emd technologies    ( St Eustache PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 393559,
    "name": "emd Technologies Europe SA    ( Fontenay-sous-Bois Cedex  France )",
    "country": "France"
  },
  {
    "id": 454393,
    "name": "Emdeon Business Services LLC    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 451583,
    "name": "Emdeon Corp    ( Elwood Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 154401,
    "name": "EME (Electro Medical Equipment) Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 439173,
    "name": "EME Medical LLC    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 446566,
    "name": "eMed Nordic Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 453408,
    "name": "Emerald International Health Products Inc    ( North Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 460804,
    "name": "eMerge Health Solutions LTD    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 375511,
    "name": "Emergency Caller Products    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 402559,
    "name": "Emergency Filtration Products Inc    ( Henderson NV USA )",
    "country": "United States"
  },
  {
    "id": 232529,
    "name": "Emergency Medical Innovations    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 106298,
    "name": "Emergency Medical International Inc    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 177944,
    "name": "Emergency Medical Products Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 175547,
    "name": "Emergency One Inc    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 461528,
    "name": "Emergency Preparedness Systems Inc    ( Elkhart IL USA )",
    "country": "United States"
  },
  {
    "id": 459325,
    "name": "Emergency Vehicle Group Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 451520,
    "name": "Emergent Respiratory Products Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 454798,
    "name": "Emergin Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 453442,
    "name": "Emergis Inc (Canada)    ( Longueuil PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453443,
    "name": "Emergis Inc (US)    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 263076,
    "name": "Emergisoft Corp    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 150117,
    "name": "Emerson Electric Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 453000,
    "name": "Emerson Network Power    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 451534,
    "name": "Emerson Network Power/Control Concepts Corp    ( Binghamton NY USA )",
    "country": "United States"
  },
  {
    "id": 999493,
    "name": "Emerson Radio Corp    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 451756,
    "name": "Emfit Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 440171,
    "name": "Emfit Ltd    ( Vaajakoski  Finland )",
    "country": "Finland"
  },
  {
    "id": 466900,
    "name": "EMG Technology Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 460805,
    "name": "EMI Imaging    ( Stuart VA USA )",
    "country": "United States"
  },
  {
    "id": 439403,
    "name": "EmiD    ( Doesburg  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 281351,
    "name": "Emil Kraenzlein AG Buerstenfabrik    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281281,
    "name": "Emil Lange Zahnbohrerfabrik    ( Engelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458796,
    "name": "emka TECHNOLOGIES SA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 470528,
    "name": "Emmi Solutions LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 287334,
    "name": "Emo Optik Arthur Seibert Inh Werner Michaeli    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 453456,
    "name": "Empi Direct    ( Shoreview MN USA )",
    "country": "United States"
  },
  {
    "id": 457316,
    "name": "Empi Direct    ( Shoreview MN USA )",
    "country": "United States"
  },
  {
    "id": 103620,
    "name": "Empi Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 106069,
    "name": "Empire RGS Inc    ( Island Park NY USA )",
    "country": "United States"
  },
  {
    "id": 450829,
    "name": "Empiric Systems LLC    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 403485,
    "name": "Employ + Ability Inc    ( Braintree MA USA )",
    "country": "United States"
  },
  {
    "id": 461018,
    "name": "Emporos Systems Corp    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 175515,
    "name": "EMS Assoc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 320991,
    "name": "EMS Electrimedical Systems SA    ( Nyon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 171231,
    "name": "EMS Electro Medical Systems SA    (   USA )",
    "country": "United States"
  },
  {
    "id": 460958,
    "name": "EMS Helpers LLC    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 410422,
    "name": "EMS Innovations Inc    ( Pasadena MD USA )",
    "country": "United States"
  },
  {
    "id": 156984,
    "name": "EMS Medical GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 195236,
    "name": "EMS Medical Group Ltd    ( Gloucester  England )",
    "country": "UK"
  },
  {
    "id": 457922,
    "name": "EMS Mobil Sistemler ve Hastane Malzemeteri Sanayi    (   USA )",
    "country": "United States"
  },
  {
    "id": 451807,
    "name": "EMS Physio Ltd    ( Wantage  England )",
    "country": "UK"
  },
  {
    "id": 462690,
    "name": "EMS Technology Solutions LLC    ( Austell GA USA )",
    "country": "United States"
  },
  {
    "id": 331227,
    "name": "EMSAR (Equipment Management Service & Repair)    ( Wilmington OH USA )",
    "country": "United States"
  },
  {
    "id": 460956,
    "name": "emsCharts    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 452790,
    "name": "Emse Corp    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 108759,
    "name": "EMT Medical Co Inc    ( Poulsbo WA USA )",
    "country": "United States"
  },
  {
    "id": 185393,
    "name": "Emtel    ( Zabrze  Poland )",
    "country": "Poland"
  },
  {
    "id": 460953,
    "name": "EnableComp Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 377758,
    "name": "Encision Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 454567,
    "name": "Encite Inc    ( Johnstown PA USA )",
    "country": "United States"
  },
  {
    "id": 106940,
    "name": "Encompas Unlimited Inc    ( Tallevast FL USA )",
    "country": "United States"
  },
  {
    "id": 394708,
    "name": "Encompass Group LLC    (   USA )",
    "country": "United States"
  },
  {
    "id": 457976,
    "name": "Encompass Group LLC    ( McDonough GA USA )",
    "country": "United States"
  },
  {
    "id": 441562,
    "name": "Encompass Medical    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 452024,
    "name": "Encompass Therapeutic Support Systems    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 103436,
    "name": "Encon Safety Products    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 305213,
    "name": "Encore Medical Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 446567,
    "name": "Encore Medical Systems    ( Longwood FL USA )",
    "country": "United States"
  },
  {
    "id": 999902,
    "name": "Encore Medical Technologies Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 458921,
    "name": "Encore Path Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 454569,
    "name": "EncounterPRO Healthcare Resources Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 455190,
    "name": "Endecotts Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 371597,
    "name": "Endius Inc    ( Plainville MA USA )",
    "country": "United States"
  },
  {
    "id": 160785,
    "name": "Endo Optiks Inc    ( Little Silver NJ USA )",
    "country": "United States"
  },
  {
    "id": 454299,
    "name": "Endo Pharmaceuticals    ( Chadds Ford PA USA )",
    "country": "United States"
  },
  {
    "id": 435467,
    "name": "ENDO PLUS (UK) Ltd    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 435466,
    "name": "ENDO PLUS France Sarl    ( Courbevoie  France )",
    "country": "France"
  },
  {
    "id": 435465,
    "name": "ENDO PLUS SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 435469,
    "name": "ENDO PLUS srl    ( Agrate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 230371,
    "name": "ENDO Surgical Concepts    ( Greenwich CT USA )",
    "country": "United States"
  },
  {
    "id": 333483,
    "name": "Endo Technic Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 158099,
    "name": "Endo-Technik Wolfgang Griesat GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452085,
    "name": "EndoArt SA    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 176130,
    "name": "Endocare Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 454470,
    "name": "EndoChoice Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 451153,
    "name": "Endoco Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 458856,
    "name": "EndoControl    ( Grenoble  France )",
    "country": "France"
  },
  {
    "id": 457819,
    "name": "EndoGastric Solutions    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 391462,
    "name": "Endologix Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 306285,
    "name": "Endomed Endoskopie und Video GmbH    ( Alsbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 174260,
    "name": "Endomed Ind Com Repres Imp Exp Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 458142,
    "name": "Endoplus    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 458750,
    "name": "Endorphin Corp    ( Pinellas Park FL USA )",
    "country": "United States"
  },
  {
    "id": 999907,
    "name": "Endorphin Corp    ( Pinellas Park FL USA )",
    "country": "United States"
  },
  {
    "id": 291543,
    "name": "Endoscope Ltd Co of Shenyang University Endoscope    ( Shenyang  USA )",
    "country": "United States"
  },
  {
    "id": 454200,
    "name": "Endoscopy Replacement Parts Inc    ( Newberry FL USA )",
    "country": "United States"
  },
  {
    "id": 457102,
    "name": "Endoscopy Specialists Inc    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 142931,
    "name": "Endoscopy Support Services Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 456406,
    "name": "Endosense SA    ( Meyrin  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453771,
    "name": "EndoSoft    ( Schenectady NY USA )",
    "country": "United States"
  },
  {
    "id": 453780,
    "name": "EndoSoft bv    ( Alphen aan den Rijn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453779,
    "name": "EndoSoft Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 453782,
    "name": "EndoSoft Solutions Pvt Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 365303,
    "name": "Endotec Inc    ( South Orange NJ USA )",
    "country": "United States"
  },
  {
    "id": 257675,
    "name": "EndoTex Interventional Systems Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 460934,
    "name": "Endothelix Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 393123,
    "name": "Endure Medical Inc    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 364321,
    "name": "Enduro Wheelchair Co    ( East Hartford CT USA )",
    "country": "United States"
  },
  {
    "id": 457030,
    "name": "Energist (Denmark)    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 457032,
    "name": "Energist (Germany)    ( Stettfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 457033,
    "name": "Energist Ltd    ( Swansea  Wales )",
    "country": "Wales"
  },
  {
    "id": 457031,
    "name": "Energist NA Inc    ( Nyack NY USA )",
    "country": "United States"
  },
  {
    "id": 452062,
    "name": "Energizer Battery Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 148416,
    "name": "Energy Beam Sciences Inc    ( East Granby CT USA )",
    "country": "United States"
  },
  {
    "id": 149015,
    "name": "Energy Plus Scientific Co    ( Harrisburg PA USA )",
    "country": "United States"
  },
  {
    "id": 999966,
    "name": "ENERGY STAR    (   USA )",
    "country": "United States"
  },
  {
    "id": 451536,
    "name": "Enersol Technologies Inc    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 460935,
    "name": "EnerSys Inc    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 233277,
    "name": "ENG Scientific Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 281161,
    "name": "Engelbrecht Medizin- und Labortechnik GmbH    ( Edermuende  Germany )",
    "country": "Germany"
  },
  {
    "id": 103269,
    "name": "Engelhard-CLAL    ( Iselin NJ USA )",
    "country": "United States"
  },
  {
    "id": 174261,
    "name": "Engesp Industria de Equips Hospitalares Ltda    ( Curitiba-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 462644,
    "name": "Enghouse Interactive    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 366510,
    "name": "Engineered Medical Systems    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 144480,
    "name": "Engineered Systems & Designs Inc    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 291715,
    "name": "Engineering Department of Wanfu Electrotechnical F    ( Chengdu  USA )",
    "country": "United States"
  },
  {
    "id": 458799,
    "name": "Engineering Solutions Inc    ( Tukwila WA USA )",
    "country": "United States"
  },
  {
    "id": 999392,
    "name": "Engle Dental Systems    ( Forest Grove OR USA )",
    "country": "United States"
  },
  {
    "id": 104982,
    "name": "Engler Engineering Corp    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 466706,
    "name": "Enhanced Medical Services (EMS)    ( Brentwood MO USA )",
    "country": "United States"
  },
  {
    "id": 456764,
    "name": "Enhanced Vision    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 999836,
    "name": "Enhanced Vision    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 456765,
    "name": "Enhanced Vision Europe    ( Iikeston  England )",
    "country": "UK"
  },
  {
    "id": 466600,
    "name": "Enhancement Medical    ( Wauwatosa WI USA )",
    "country": "United States"
  },
  {
    "id": 415967,
    "name": "Enika Trading Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 108969,
    "name": "Enmet Corp    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 103114,
    "name": "ENOCHS Examining Room Furniture    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 399220,
    "name": "Enodis Corp    ( New Port Richey FL USA )",
    "country": "United States"
  },
  {
    "id": 391886,
    "name": "Enodis plc    ( Langley  England )",
    "country": "UK"
  },
  {
    "id": 458356,
    "name": "Enova Illumination    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 999772,
    "name": "Enova Medical Technologies Inc    ( St. Paul MN USA )",
    "country": "USA"
  },
  {
    "id": 458097,
    "name": "Enovate Medical    ( Murfreesboro TN USA )",
    "country": "United States"
  },
  {
    "id": 451832,
    "name": "EnovateIT    ( Ferndale MI USA )",
    "country": "United States"
  },
  {
    "id": 450895,
    "name": "Enpath Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 295028,
    "name": "Enraf-Nonius bv    ( Rotterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 393256,
    "name": "Enraf-Nonius I Portugal Lda    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 336628,
    "name": "Enraf-Nonius Iberica SA    ( Mostoles (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 303456,
    "name": "Enraf-Nonius Medical Equipment Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 333675,
    "name": "Enraf-Nonius NV/SA    ( Aartselaar  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 333676,
    "name": "Enraf-Nonius SA    ( Sevran Cedex  France )",
    "country": "France"
  },
  {
    "id": 441646,
    "name": "ENSIL International Corp (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 441560,
    "name": "ENSIL International Corp USA    ( Lewiston NY USA )",
    "country": "United States"
  },
  {
    "id": 106157,
    "name": "ENTECH    ( Chandler AZ USA )",
    "country": "United States"
  },
  {
    "id": 458393,
    "name": "Entellus Medical    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 455704,
    "name": "Enteplin Egypt    ( 10th of Ramadan City  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 453864,
    "name": "Enterix Inc (Australia)    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 453863,
    "name": "Enterix Inc (USA)    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 176852,
    "name": "Entermed bv    ( Houten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416079,
    "name": "Entermed International Ltd    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 457847,
    "name": "EnteroMedics Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 356865,
    "name": "Enterprise Healthcare Systems Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 451824,
    "name": "Enterprise Performance Systems Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 465016,
    "name": "Enterprise Systems    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 373541,
    "name": "Enthermics Medical Systems    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 451152,
    "name": "Entific Medical Systems AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 157537,
    "name": "Entomed AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 151086,
    "name": "Envair Ltd    ( Rossendale  England )",
    "country": "UK"
  },
  {
    "id": 162170,
    "name": "ENVIRCO    ( Sanford NC USA )",
    "country": "United States"
  },
  {
    "id": 440153,
    "name": "ENVIRCO Asia    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 431511,
    "name": "EnviroFoam Technologies Inc    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 431631,
    "name": "Environics USA Inc    ( Abingdon MD USA )",
    "country": "United States"
  },
  {
    "id": 101246,
    "name": "Environmental Growth Chambers    ( Chagrin Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 455123,
    "name": "Environmental Sensors Co    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 333485,
    "name": "Environmental Test Systems Inc    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 361271,
    "name": "EnviroPack Development Corp    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 458488,
    "name": "Envisioneering Medical Technologies    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 456966,
    "name": "Envisionier Medical Technologies Inc    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 352904,
    "name": "Envitec Wismar GmbH    ( Wismar  Germany )",
    "country": "Germany"
  },
  {
    "id": 457643,
    "name": "Envoy Medical Corp    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 177028,
    "name": "Enware Australia Pty Ltd    ( Caringbah  Australia )",
    "country": "Australia"
  },
  {
    "id": 108617,
    "name": "Enzo Life Sciences Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 292042,
    "name": "Eon Systems Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 455922,
    "name": "EOS Imaging (France)    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 455923,
    "name": "EOS Imaging Canada    ( Saint-Hubert PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455925,
    "name": "EOS Imaging GmbH    ( Griesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 455924,
    "name": "EOS Imaging Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 458312,
    "name": "Eoscene    ( Renton WA USA )",
    "country": "United States"
  },
  {
    "id": 460944,
    "name": "EPI Mobile Health Solutions (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 104162,
    "name": "Epic Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 458719,
    "name": "Epic Laser Services Inc    ( Tukwila WA USA )",
    "country": "United States"
  },
  {
    "id": 169895,
    "name": "Epic Systems Corp    ( Verona WI USA )",
    "country": "United States"
  },
  {
    "id": 458104,
    "name": "Epigenomics AG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 460946,
    "name": "Epigentek Group Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 321065,
    "name": "Epimed International Inc    ( Johnstown NY USA )",
    "country": "United States"
  },
  {
    "id": 458934,
    "name": "Epiphan Systems Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451299,
    "name": "Epiphany Cardiography Products LLC    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 339106,
    "name": "EPIX Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 464988,
    "name": "ePlus Inc    ( Herndon VA USA )",
    "country": "United States"
  },
  {
    "id": 461027,
    "name": "EPM Equipment LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 435641,
    "name": "EPMedSystems Europe Ltd    ( Bexleyheath  USA )",
    "country": "United States"
  },
  {
    "id": 435643,
    "name": "EPMedSystems France Sarl    ( Aix en Provence Cedex 3  USA )",
    "country": "United States"
  },
  {
    "id": 334743,
    "name": "EPMedSystems Inc    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 453991,
    "name": "Epocal Inc (Canada)    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453992,
    "name": "Epocal Inc (USA)    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 460951,
    "name": "Epoch Health Solutions LLC    ( Saratoga Springs NY USA )",
    "country": "United States"
  },
  {
    "id": 460808,
    "name": "EPOWERdoc Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 174871,
    "name": "Eppendorf AG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 307282,
    "name": "Eppendorf AG    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306017,
    "name": "Eppendorf AG (Malaysia)    ( Subang Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 455472,
    "name": "Eppendorf France SARL    ( Le Pecq  France )",
    "country": "France"
  },
  {
    "id": 455474,
    "name": "Eppendorf Middle East FZLLC    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 108882,
    "name": "Eppendorf North America Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 455475,
    "name": "Eppendorf South Pacific Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 305994,
    "name": "Eppendorf srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457255,
    "name": "Eppendorf UK Ltd    ( Stevenage  England )",
    "country": "UK"
  },
  {
    "id": 455249,
    "name": "Eppendorf Vertrieb Deutschland GmbH    ( Wesseling-Berzdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 454506,
    "name": "EPS Bio Technology Corp    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 102962,
    "name": "EPS Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 464957,
    "name": "EPS Texas Storage Solutions Inc    ( Wylie TX USA )",
    "country": "United States"
  },
  {
    "id": 460807,
    "name": "Epsilon Technology Corp    ( Jackson WY USA )",
    "country": "United States"
  },
  {
    "id": 292985,
    "name": "Epsom Glass Industries Ltd    ( Epsom  England )",
    "country": "UK"
  },
  {
    "id": 458377,
    "name": "Epson America Inc    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 999623,
    "name": "Epson America, Inc.    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 185888,
    "name": "EQ2 Inc    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 271240,
    "name": "EQual Diagnostics    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 325692,
    "name": "Equalization Technologies Inc    ( Cypress TX USA )",
    "country": "United States"
  },
  {
    "id": 460806,
    "name": "Equalizer Exercise Machines    ( Red Deer AB Canada )",
    "country": "Canada"
  },
  {
    "id": 999755,
    "name": "Equalizer Exercise Machines    (   Canada )",
    "country": "Canada"
  },
  {
    "id": 466421,
    "name": "Equation Consulting Inc    ( Layton UT USA )",
    "country": "United States"
  },
  {
    "id": 108418,
    "name": "Equi-Tron Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459189,
    "name": "Equicare Health    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 105038,
    "name": "Equilibrated Bio Systems Inc    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 460962,
    "name": "Equip for Independence Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 464099,
    "name": "EquipaCare    ( Volta Redonda (RJ)  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 287758,
    "name": "Equipment de Chandigarh    ( Chandigarh  India )",
    "country": "India"
  },
  {
    "id": 403693,
    "name": "Equipment Medical KCI Sarl    ( Chilly-Mazarin  France )",
    "country": "France"
  },
  {
    "id": 402261,
    "name": "Equipment Resurrection    ( Pacifica CA USA )",
    "country": "United States"
  },
  {
    "id": 155415,
    "name": "Equipment Shop    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 391309,
    "name": "Equipos Medicos Vizcarra SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 108496,
    "name": "Equipto    ( Tatamy PA USA )",
    "country": "United States"
  },
  {
    "id": 465160,
    "name": "Equiptocare Ltd    ( Ebbw Vale  Wales )",
    "country": "Wales"
  },
  {
    "id": 453641,
    "name": "Erba Diagnositcs Asia Pacific    ( Mumbia  India )",
    "country": "India"
  },
  {
    "id": 408735,
    "name": "Erba Diagnostics Mannheim GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 451266,
    "name": "ERBE Benelux bv    ( Werkendam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451268,
    "name": "ERBE Benelux bv ba    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 336211,
    "name": "ERBE Elektromedicina d o o    ( Ljubljana/Sentvid  Slovenia )",
    "country": "Slovenia"
  },
  {
    "id": 336202,
    "name": "ERBE Elektromedizin Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 156988,
    "name": "ERBE Elektromedizin GmbH    ( Tuebingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451269,
    "name": "ERBE Elektromedizin GmbH    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 452739,
    "name": "ERBE Elektromedizin GmbH (Serbia)    ( Novi Sad  Serbia )",
    "country": "Serbia"
  },
  {
    "id": 452740,
    "name": "ERBE Elektromedizin GmbH (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 336199,
    "name": "ERBE Medical S a r l    ( Limonest  France )",
    "country": "France"
  },
  {
    "id": 451265,
    "name": "ERBE Medical UK Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 458173,
    "name": "ERBE Nederland bv    ( Werkendam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451271,
    "name": "ERBE OMSZ es Informacios KFT    ( Budapest  USA )",
    "country": "United States"
  },
  {
    "id": 272402,
    "name": "ERBE Polska sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 451264,
    "name": "ERBE Shanghai    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458172,
    "name": "ERBE Shanghai Medical Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451267,
    "name": "ERBE Swiss AG    ( Winterthur  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 108422,
    "name": "ERBE USA Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 279477,
    "name": "ERC GmbH    ( Riemerling  Germany )",
    "country": "Germany"
  },
  {
    "id": 286705,
    "name": "Erchinger Chirurgische Instrumente    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 452697,
    "name": "Erchonia Medical Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 284233,
    "name": "Erdal-Rex GmbH Werner & Mertz Gruppe    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 185394,
    "name": "ERG Przedsiebiorstwo Tworzyw Sztucznych    ( Klobuck  Poland )",
    "country": "Poland"
  },
  {
    "id": 332665,
    "name": "Ergo Air Inc    ( Pine Bush NY USA )",
    "country": "United States"
  },
  {
    "id": 453390,
    "name": "ERGO-FIT GmbH & Co KG    ( Pirmasens  Germany )",
    "country": "Germany"
  },
  {
    "id": 453799,
    "name": "Ergo-Tec GmbH    ( Wilhelmsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 108500,
    "name": "Ergodyne Corp    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 453708,
    "name": "Ergolet A/S    ( Korsoer  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 280317,
    "name": "Ergoline GmbH    ( Bitz  Germany )",
    "country": "Germany"
  },
  {
    "id": 108762,
    "name": "ErgoMed Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 283459,
    "name": "Ergometer & Medizintechnik Axel Bayer    ( Kirchheim Teck  USA )",
    "country": "United States"
  },
  {
    "id": 458975,
    "name": "Ergonurse    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 457416,
    "name": "Ergophone GmbH    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 460870,
    "name": "Ergotech Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 356449,
    "name": "Ergotron Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 454378,
    "name": "ERGOtug    ( Shakopee MN USA )",
    "country": "United States"
  },
  {
    "id": 158237,
    "name": "Erhardt & Leimer GmbH    ( Stadtbergen  Germany )",
    "country": "Germany"
  },
  {
    "id": 150943,
    "name": "Erich Jaeger GmbH & Co    ( Hoechberg  USA )",
    "country": "United States"
  },
  {
    "id": 470545,
    "name": "Erickson Labs Northwest    ( Kirkland WA USA )",
    "country": "United States"
  },
  {
    "id": 226956,
    "name": "Ericsson Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 222500,
    "name": "Ericsson Telecommunicatie bv    ( Ruen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103753,
    "name": "Erie Medical    ( Pleasant Prairie WI USA )",
    "country": "United States"
  },
  {
    "id": 103438,
    "name": "Erie Scientific Co    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 454389,
    "name": "Eriem Surgical Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 279764,
    "name": "ERKA Kallmeyer Medizintechnik GmbH & Co KG    ( Bad Toelz  Germany )",
    "country": "Germany"
  },
  {
    "id": 285337,
    "name": "Erkodent Erich Kopp GmbH    ( Pfalzgrafenweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 363450,
    "name": "Erlab DFS SA    ( Val-de-Revil Cedex  France )",
    "country": "France"
  },
  {
    "id": 416086,
    "name": "Erlab DFS SA Vertretungsbuero Deutschland    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 402857,
    "name": "Erlab Inc    ( Rowley MA USA )",
    "country": "United States"
  },
  {
    "id": 366535,
    "name": "Erlab Industries Asia    ( Johor Bahru  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 177061,
    "name": "Erlau AG    ( Aalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283823,
    "name": "Erler-Zimmer GmbH & Co Kg    ( Lauf  Germany )",
    "country": "Germany"
  },
  {
    "id": 277935,
    "name": "ERMA INC    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 286188,
    "name": "Ernst Dinger GmbH & Co KG Press- und Stanzwerk    ( Solingen  USA )",
    "country": "United States"
  },
  {
    "id": 284545,
    "name": "Ernst Haage Apparatebau GmbH & Co KG    ( Muelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 282072,
    "name": "Ernst Hinrichs GmbH    ( Goslar  Germany )",
    "country": "Germany"
  },
  {
    "id": 286190,
    "name": "Ernst Krauskopf    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285349,
    "name": "Ernst Vetter GmbH Dentalfabrik    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 285718,
    "name": "Ernst Wagner GmbH & Co KG    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287542,
    "name": "Ernst Worring GmbH    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 283930,
    "name": "Ero-Etikett GmbH    ( Lenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452685,
    "name": "EROTHITAN Titanim Implants Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 283073,
    "name": "EROTHITAN Titanimplantate AG    ( Suhl  Germany )",
    "country": "Germany"
  },
  {
    "id": 331230,
    "name": "ERS Inc Bio-Medical Service    ( Lutz FL USA )",
    "country": "United States"
  },
  {
    "id": 174263,
    "name": "Erwin Guth Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 284906,
    "name": "Erwin Kowsky KG    ( Neumuenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 280011,
    "name": "Erwin Schmidt Medizinischer Fachhandel Verkauf & R    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 104803,
    "name": "ES Industries    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 457282,
    "name": "ESA    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 179094,
    "name": "ESA Analytical Ltd    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 102707,
    "name": "ESA Biosciences Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 284867,
    "name": "Esaote Biomedica Deutschland GmbH    ( Neufahrn bei Muechen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455988,
    "name": "Esaote Canada    ( Georgetown ON Canada )",
    "country": "Canada"
  },
  {
    "id": 371773,
    "name": "Esaote China Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 393186,
    "name": "Esaote Espana SA    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 451950,
    "name": "Esaote Europe bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 393183,
    "name": "Esaote Latinamerica SA    ( Capital Federal  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 238463,
    "name": "Esaote North America Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 344891,
    "name": "Esaote Sarl    ( Saint-Germain-en-Laye Cedex  France )",
    "country": "France"
  },
  {
    "id": 371771,
    "name": "Esaote SpA    ( Genova (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 186838,
    "name": "Esaote SpA (Firenze)    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 371776,
    "name": "Esaote SpA (Russia)    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 174598,
    "name": "Esbe Scientific Industries Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456893,
    "name": "ESCAD Medical GmbH    ( Heiligenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 467055,
    "name": "Escali Corp    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 999411,
    "name": "Escali Corp.    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 454255,
    "name": "Escalon Digital Solutions    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 272322,
    "name": "Escalon Medical Corp    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 426450,
    "name": "Escalon Vascular Access Inc    ( New Berlin WI USA )",
    "country": "United States"
  },
  {
    "id": 460965,
    "name": "eScapes Network LLC    ( Monroe MI USA )",
    "country": "United States"
  },
  {
    "id": 158239,
    "name": "Eschenbach Optik GmbH & Co    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 155813,
    "name": "Eschenbach Optik of America Inc    ( Ridgefield CT USA )",
    "country": "United States"
  },
  {
    "id": 157749,
    "name": "Eschmann Equipment    ( Lancing  England )",
    "country": "UK"
  },
  {
    "id": 152065,
    "name": "Eschweiler GmbH & Co KG    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 456402,
    "name": "Esco GB Ltd    ( Downton  England )",
    "country": "UK"
  },
  {
    "id": 451967,
    "name": "ESCO Medicon Medical Systems    ( Silverwater  Australia )",
    "country": "Australia"
  },
  {
    "id": 397774,
    "name": "Esco Micro Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455119,
    "name": "Escort Data Loggers Inc    ( Bucanan VA USA )",
    "country": "United States"
  },
  {
    "id": 455120,
    "name": "Escort Data Logging Systems Ltd    ( Penrose  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 462694,
    "name": "eScreen Inc    ( Overland Park  USA )",
    "country": "United States"
  },
  {
    "id": 331231,
    "name": "ESECO Speedmaster    ( Cushing OK USA )",
    "country": "United States"
  },
  {
    "id": 459295,
    "name": "ESI    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 281958,
    "name": "Eska Implants GmbH & Co    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 173104,
    "name": "ESMA Inc    ( South Holland IL USA )",
    "country": "United States"
  },
  {
    "id": 170352,
    "name": "ESMO Inc    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 471742,
    "name": "Esoterix Laboratory Services Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 296424,
    "name": "Espe America    (   USA )",
    "country": "United States"
  },
  {
    "id": 232370,
    "name": "ESPE GmbH & Co KG    ( Seefeld/Obb  Germany )",
    "country": "Germany"
  },
  {
    "id": 456852,
    "name": "Espiner Medical Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 464960,
    "name": "Esri    ( Redlands CA USA )",
    "country": "United States"
  },
  {
    "id": 293637,
    "name": "Esro AG    ( Kilchberg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 184486,
    "name": "Essential Dental Systems Inc    ( South Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 176220,
    "name": "Essential Medical Supply Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 107319,
    "name": "Essex Cryogenics of Missouri Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 452997,
    "name": "Essex Electronics inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 138092,
    "name": "Essilor International    ( Charenton-le-Pont  France )",
    "country": "France"
  },
  {
    "id": 280459,
    "name": "Essilor-Optik GmbH    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 338374,
    "name": "Estar Technologies Ltd    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 452873,
    "name": "ESTECH Europe    ( Lage Zwaluwe  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 433748,
    "name": "ESTECH USA    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 359983,
    "name": "Estill Medical Technologies Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 456389,
    "name": "eSwallow USA LLC    ( Guntersville AL USA )",
    "country": "United States"
  },
  {
    "id": 286160,
    "name": "ESWE Analysentechnik Gera GmbH    ( Gera  Germany )",
    "country": "Germany"
  },
  {
    "id": 450489,
    "name": "eSys Medical Systems Inc    ( Montreal PQ USA )",
    "country": "United States"
  },
  {
    "id": 416216,
    "name": "et medical devices SpA    ( Vignate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 339107,
    "name": "ET Medical Diagnostic Co    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 149221,
    "name": "ETAC AB    ( Anderstorp  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452002,
    "name": "ETC BioMedical Systems Group    ( Southampton PA USA )",
    "country": "United States"
  },
  {
    "id": 452007,
    "name": "ETC Egypt    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 452005,
    "name": "ETC Europe    ( Cobham  England )",
    "country": "UK"
  },
  {
    "id": 452008,
    "name": "ETC Japan    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 173263,
    "name": "ETC Middle East    ( Abu Dhabi  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 452009,
    "name": "ETC Poland    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 173257,
    "name": "ETC Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 431725,
    "name": "ETC Technology Malaysia Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 452006,
    "name": "ETC Turkey    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 101252,
    "name": "ETC/Environmental Tectonics Corp    ( Southampton PA USA )",
    "country": "United States"
  },
  {
    "id": 999224,
    "name": "Etekcity    ( Anaheim CA usa )",
    "country": "usa"
  },
  {
    "id": 330859,
    "name": "ETEX Corp    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 374053,
    "name": "Global Medical Imports Inc    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 101250,
    "name": "Ethicare Products    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 363929,
    "name": "Ethicon Endo-Surgery (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 363931,
    "name": "Ethicon Endo-Surgery (Mexico)    ( Ciudad Juarez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 363930,
    "name": "Ethicon Endo-Surgery (Scotland)    ( Livingston  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 307283,
    "name": "Ethicon Endo-Surgery (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 160783,
    "name": "Ethicon Endo-Surgery GmbH & Co KG    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 149225,
    "name": "Ethicon Endo-Surgery Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 394731,
    "name": "Ethicon Endo-Surgery Inc Urology Div    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 101251,
    "name": "ETHICON JOHNSON & JOHNSON    ( Somerville NJ USA )",
    "country": "United States"
  },
  {
    "id": 176953,
    "name": "Ethicon Ltd    ( Edinburgh  USA )",
    "country": "United States"
  },
  {
    "id": 451397,
    "name": "Ethicon Sarl    ( Spreitenbach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 102103,
    "name": "Ethox International    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 454813,
    "name": "ETI Dental    ( Bnei Brak  Israel )",
    "country": "Israel"
  },
  {
    "id": 286086,
    "name": "ETI Process Technique International GmbH    ( Sehnde Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 452817,
    "name": "Etiam    ( Rennes  France )",
    "country": "France"
  },
  {
    "id": 462954,
    "name": "Etiam Corp    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 282127,
    "name": "Etifix Etikettiersysteme GmbH    ( Grafenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 292990,
    "name": "Etigam bv    ( Apeldoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 285407,
    "name": "Etiket Schiller GmbH    ( Pluederhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281477,
    "name": "Etikettenfabrik Erhard Kuechler GmbH    ( Esslingen-Berkheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 152466,
    "name": "Etoile snc di A Lazzari & Co    ( Mediglia (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 279093,
    "name": "ETS Bruneau    ( Bretigny sur Orge  France )",
    "country": "France"
  },
  {
    "id": 309711,
    "name": "ETS L F Laets SA    ( Gembloux  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 403515,
    "name": "ETS-Lindgren    ( Cedar Park TX USA )",
    "country": "United States"
  },
  {
    "id": 403518,
    "name": "ETS-Lindgren Asia-Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 428557,
    "name": "ETS-Lindgren China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 428558,
    "name": "ETS-Lindgren France    ( Stains  France )",
    "country": "France"
  },
  {
    "id": 250457,
    "name": "ETS-Lindgren Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 403517,
    "name": "ETS-Lindgren United Kingdom    ( Stevenage  England )",
    "country": "UK"
  },
  {
    "id": 407219,
    "name": "ETS-Lindgren-Euroshield Oy    ( Eura  Finland )",
    "country": "Finland"
  },
  {
    "id": 455779,
    "name": "ETView Ltd    ( Misgav  Israel )",
    "country": "Israel"
  },
  {
    "id": 361067,
    "name": "Etymonic Design Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 460971,
    "name": "Etymotic Research Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 105373,
    "name": "Euclid Spiral Paper Tube Corp    ( Apple Creek OH USA )",
    "country": "United States"
  },
  {
    "id": 280311,
    "name": "Euler-Arbeitssicherheit    ( Bischofsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 158242,
    "name": "Eumatron GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 172391,
    "name": "Eureduc    ( Coignieres  France )",
    "country": "France"
  },
  {
    "id": 105040,
    "name": "Eureka X-Ray Tube Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 466990,
    "name": "Euro Diagnostica AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453523,
    "name": "Euro Energy Resources Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 370371,
    "name": "Euro Service srl    ( Genova (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 465153,
    "name": "Euro Worldent Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 453606,
    "name": "Eurocamina srl    ( Salorno (BZ)  Italy )",
    "country": "Italy"
  },
  {
    "id": 152464,
    "name": "Eurocolumbus srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 382486,
    "name": "EuroCrystal    ( Luxembourg  Luxembourg )",
    "country": "Luxembourg"
  },
  {
    "id": 458938,
    "name": "Eurodib    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 324463,
    "name": "EuroEnergy Resources Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 453437,
    "name": "Eurofins MWG Operon    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453431,
    "name": "Eurofins MWG Operon AG    ( Ebersberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 453438,
    "name": "Eurofins MWG Operon France    ( Roissy CDG  France )",
    "country": "France"
  },
  {
    "id": 453432,
    "name": "Eurofins MWG Operon India    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 453439,
    "name": "Eurofins MWG Operon UK    ( London  England )",
    "country": "UK"
  },
  {
    "id": 453430,
    "name": "Eurofins MWG Operon USA    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 417463,
    "name": "EUROIMMUN (Southeast Asia) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 282181,
    "name": "EUROIMMUN AG    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 417461,
    "name": "EUROIMMUN Beijing    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 417464,
    "name": "EUROIMMUN for Medical Laboratory Diagnostics LLC    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 417405,
    "name": "EUROIMMUN Italia srl    ( Padova (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 417404,
    "name": "EUROIMMUN Medical Diagnostics Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 417459,
    "name": "EUROIMMUN Middle East Medical Laboratory Diagnosti    ( Beirut  Lebanon )",
    "country": "Lebanon"
  },
  {
    "id": 417456,
    "name": "EUROIMMUN Polska Sp z o o    ( Wroclaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 417458,
    "name": "EUROIMMUN South Africa Pty Ltd    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 417457,
    "name": "EUROIMMUN UK Ltd    ( Pontypool  Wales )",
    "country": "Wales"
  },
  {
    "id": 455706,
    "name": "Euromed For Medical Industries    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 456060,
    "name": "Euromed Industries    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 452822,
    "name": "Euromed Networks AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 277858,
    "name": "Euromedical Industries Sdn Bhd    ( Sungai Petani  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 187979,
    "name": "Euromex Microscopes bv    ( Arnhem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452901,
    "name": "Euromi SA    ( Verviers  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 324624,
    "name": "European Instruments    ( Headington  England )",
    "country": "UK"
  },
  {
    "id": 451682,
    "name": "European Sleep Research Institute    ( Leuven  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457132,
    "name": "EURORAD    ( Chennevieres-sur-Marne  France )",
    "country": "France"
  },
  {
    "id": 279171,
    "name": "Euros    ( La Ciotat Cedex  France )",
    "country": "France"
  },
  {
    "id": 183328,
    "name": "Eurospital SpA    ( Trieste (TS)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452661,
    "name": "Eurosurgical Ltd    ( Dublin 14  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 457848,
    "name": "Eurotape BV    ( Soest  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 171342,
    "name": "Eurotherm Chessell Recorders    ( Leesburg VA USA )",
    "country": "United States"
  },
  {
    "id": 454891,
    "name": "Eurovema    ( Gislaved  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 282382,
    "name": "Eurowater Wasseraufbereitung GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999930,
    "name": "Euthanex Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 456755,
    "name": "EV Aesthetics    ( Casnigo (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456754,
    "name": "EV Medicals    ( Casnigo (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452255,
    "name": "EV Rider    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 418261,
    "name": "ev3 Europe SAS    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 416486,
    "name": "ev3 Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 451610,
    "name": "ev3 Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 452065,
    "name": "ev3 Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 419877,
    "name": "EVAC-5    ( Three Rivers PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 107016,
    "name": "EVAC+CHAIR Corp    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 457791,
    "name": "Evacryl Dental Supply Co    ( London  England )",
    "country": "UK"
  },
  {
    "id": 460981,
    "name": "Evacucorp Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 455772,
    "name": "Evacusled Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457981,
    "name": "Evans (Instruments) Ltd    ( Thetford  England )",
    "country": "UK"
  },
  {
    "id": 451130,
    "name": "Evans National Leasing Inc    ( Hamburg NY USA )",
    "country": "United States"
  },
  {
    "id": 450540,
    "name": "EVE America Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 455892,
    "name": "Evenflo Co Inc    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 391299,
    "name": "Evengoal International Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 403318,
    "name": "eVent Medical Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 403413,
    "name": "eVent Medical Ltd (US)    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 175553,
    "name": "Event Medical Services Inc    ( Los Alamitos CA USA )",
    "country": "United States"
  },
  {
    "id": 460563,
    "name": "Ever Prosperous Instrument Inc (EPII)    ( New Taipei City  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 158244,
    "name": "Ever-med GmbH    ( Muechen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458681,
    "name": "Everbridge Inc    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 451562,
    "name": "Everdixie USA EMS Supply Co    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 105304,
    "name": "Eveready Battery Co Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 999403,
    "name": "Everest & Jennings    (   USA )",
    "country": "United States"
  },
  {
    "id": 103619,
    "name": "Everest Interscience Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 458939,
    "name": "Everest Refrigeration    ( Compton CA USA )",
    "country": "United States"
  },
  {
    "id": 999453,
    "name": "EverFocus    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 155075,
    "name": "Evergreen Scientific    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 441251,
    "name": "EverMed    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 306387,
    "name": "Everyway Medical Instruments Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 393111,
    "name": "Evit Labs Inc    ( McClellan CA USA )",
    "country": "United States"
  },
  {
    "id": 451973,
    "name": "EVO Medical Solutions    ( Adel IA USA )",
    "country": "United States"
  },
  {
    "id": 457749,
    "name": "Evocare Australia Pty Ltd    ( Stafford  Australia )",
    "country": "Australia"
  },
  {
    "id": 462964,
    "name": "Evologics America    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 459046,
    "name": "Evolution Medical Products Inc    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 465120,
    "name": "Evolution1 Inc    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 458095,
    "name": "Evolutis    ( Briennon  France )",
    "country": "France"
  },
  {
    "id": 460985,
    "name": "Evolve Technologies Corp    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 441164,
    "name": "Evolved Digital Systems Canada    ( Laval PQ USA )",
    "country": "United States"
  },
  {
    "id": 441160,
    "name": "Evolved Digital Systems Inc    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 452837,
    "name": "Evonik Degussa Corp    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 452835,
    "name": "Evonik Degussa GmbH    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452836,
    "name": "Evonik Industries AG    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 309669,
    "name": "Granulab International bv    ( Amersfoort  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 464970,
    "name": "Evoqua Water Technologies LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 457352,
    "name": "Ewac Medical    ( Langedijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 285772,
    "name": "Ewald Bacher Medizintechnik GmbH    ( Rietheim-Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454131,
    "name": "eWellness LLC    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 158252,
    "name": "Ewem-Janus Inh Waltraud Janus    ( Landau  Germany )",
    "country": "Germany"
  },
  {
    "id": 452980,
    "name": "EWha Biomedics Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454240,
    "name": "Ex-Pack Technology PTE Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 391308,
    "name": "EXACON SCIENTIFIC A/S    ( Roskilde  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458357,
    "name": "Exacq Technologies Inc    ( Fishers IN USA )",
    "country": "United States"
  },
  {
    "id": 471750,
    "name": "Exact Sciences Corp    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 454284,
    "name": "Exactech Asia    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454281,
    "name": "Exactech Canada    ( Mount Hope ON Canada )",
    "country": "Canada"
  },
  {
    "id": 194108,
    "name": "Exactech Inc    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 454283,
    "name": "Exactech Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454282,
    "name": "Exactech United Kingdom    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 285007,
    "name": "Exakt Advanced Technologies GmbH    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 458807,
    "name": "Exakt Technologies Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 456293,
    "name": "Exalenz Bioscience Inc    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 456294,
    "name": "Exalenz Bioscience Ltd    ( Modi'in  Israel )",
    "country": "Israel"
  },
  {
    "id": 462681,
    "name": "ExamSoft Worldwide    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 458353,
    "name": "Exan Group    ( Coquitlam BC Canada )",
    "country": "Canada"
  },
  {
    "id": 466332,
    "name": "Excalibur Lab Specialists Inc    ( Minden NV USA )",
    "country": "United States"
  },
  {
    "id": 455930,
    "name": "Excel Engineering Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451150,
    "name": "Excel Gloves and Safety Supplies Inc    ( Fife WA USA )",
    "country": "United States"
  },
  {
    "id": 459096,
    "name": "Excel Medical Corp    ( Bruce WI USA )",
    "country": "United States"
  },
  {
    "id": 454095,
    "name": "Excel Medical Electronincs Inc    ( Juniper FL USA )",
    "country": "United States"
  },
  {
    "id": 185896,
    "name": "Excel Medical Systems    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 304378,
    "name": "EXCEL TECHNOLOGY INC.    (   USA )",
    "country": "United States"
  },
  {
    "id": 455314,
    "name": "Excel-Tech Ltd A Natus Medical Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 233654,
    "name": "Excelcare Inc    ( Ligonier PA USA )",
    "country": "United States"
  },
  {
    "id": 467097,
    "name": "Excell Medical Supplies & Repair    ( Davison MI USA )",
    "country": "United States"
  },
  {
    "id": 102021,
    "name": "Excellance Inc    ( Madison AL USA )",
    "country": "United States"
  },
  {
    "id": 187022,
    "name": "Excelsior Medical Corp    ( Neptune NJ USA )",
    "country": "United States"
  },
  {
    "id": 458942,
    "name": "Exchange Cart Accessories Inc    ( Freeburg IL USA )",
    "country": "United States"
  },
  {
    "id": 465053,
    "name": "Exclusively RN''s, LLC    ( Colorado Springs 66 USA )",
    "country": "United States"
  },
  {
    "id": 466625,
    "name": "ExCor Technologies LLC    ( Dana Point CA USA )",
    "country": "United States"
  },
  {
    "id": 455581,
    "name": "Excorp Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 466650,
    "name": "Executone Systems Co of LA Inc    ( Metairie LA USA )",
    "country": "United States"
  },
  {
    "id": 152348,
    "name": "Exel International Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 465121,
    "name": "Exelis    ( McLean 16 USA )",
    "country": "United States"
  },
  {
    "id": 460978,
    "name": "Exemplo Medical LLC    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 103754,
    "name": "Exercycle    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 107875,
    "name": "Exergen Corp    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 184433,
    "name": "Exeter Industrial Services    (   USA )",
    "country": "United States"
  },
  {
    "id": 230543,
    "name": "Exide Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 452121,
    "name": "Exide Technologies    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 456831,
    "name": "Eximp Holland    ( Apeldoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452409,
    "name": "Exit-Writer Parker Hill Assoc Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 452410,
    "name": "ExitCare LLC    ( Lake Elmo MN USA )",
    "country": "United States"
  },
  {
    "id": 459291,
    "name": "EXL    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 162470,
    "name": "Exocell Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 452840,
    "name": "Exocomm Technology Group    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 465014,
    "name": "EXP Pharmaceutical Services Corp    ( Freemont CA USA )",
    "country": "United States"
  },
  {
    "id": 457036,
    "name": "Expanding Orthopedics Inc (Israel)    ( Or Akiva  Israel )",
    "country": "Israel"
  },
  {
    "id": 457037,
    "name": "Expanding Orthopedics Inc (United States)    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 105173,
    "name": "Expeditor Systems Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 458954,
    "name": "Experia Health    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 331240,
    "name": "Experior Corp    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 455623,
    "name": "Expert Medical Systems Inc    ( Bergheim TX USA )",
    "country": "United States"
  },
  {
    "id": 158255,
    "name": "Explorent Chirurgische Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452256,
    "name": "Express Diagnostics Int'l Inc    ( Blue Earth MN USA )",
    "country": "United States"
  },
  {
    "id": 454023,
    "name": "Express Systems & Parts Network Inc    ( Aurora OH USA )",
    "country": "United States"
  },
  {
    "id": 458155,
    "name": "Express Time Systems    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 999695,
    "name": "Expresso Fitness    (   USA )",
    "country": "United States"
  },
  {
    "id": 283348,
    "name": "Expresso-Deutschland Transportgeraete GmbH    ( Kassel  Germany )",
    "country": "Germany"
  },
  {
    "id": 103182,
    "name": "Extech Instruments Corp    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454796,
    "name": "EXTENSION Inc    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 450914,
    "name": "Extrel CMS    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 451607,
    "name": "Extreme Integration    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 457482,
    "name": "Extremity Medical LLC    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 459016,
    "name": "Extron USA West    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 465080,
    "name": "EYE DESIGNS, LLC.    ( Collegeville 22 USA )",
    "country": "United States"
  },
  {
    "id": 358358,
    "name": "Eye Dynamics Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 464979,
    "name": "Eye in the Sky LLC    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 170370,
    "name": "Eyedot Computer Inc    ( Neptune NJ USA )",
    "country": "United States"
  },
  {
    "id": 409762,
    "name": "EyeKon Medical    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 451851,
    "name": "Eyelogic Systems Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 459086,
    "name": "Eyemaginations Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 469493,
    "name": "Eyemart Express LLC    ( Farmers Branch TX USA )",
    "country": "United States"
  },
  {
    "id": 465165,
    "name": "EyeMD EMR Healthcare Systems, Inc.    ( Bonita Springs 27 USA )",
    "country": "United States"
  },
  {
    "id": 465092,
    "name": "Eyeol UK Ltd    ( Dunstable  England )",
    "country": "UK"
  },
  {
    "id": 321476,
    "name": "Eyequip    ( Ponte Vedra FL USA )",
    "country": "United States"
  },
  {
    "id": 393054,
    "name": "EyeSupply USA Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 175424,
    "name": "EyeSys Technology A Premier Laser Systems Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 458458,
    "name": "EyeSys Vision Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 376041,
    "name": "EyeTech LLC    ( Oakdale MN USA )",
    "country": "United States"
  },
  {
    "id": 452252,
    "name": "Eyewear House    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 456696,
    "name": "EYEZONE    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 354740,
    "name": "EYMASA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 999850,
    "name": "Guger Technologies OG    (   USA )",
    "country": "United States"
  },
  {
    "id": 108905,
    "name": "EZ Lock Inc    ( Baton Rouge LA USA )",
    "country": "United States"
  },
  {
    "id": 428454,
    "name": "EZ Way Inc    ( Clarinda IA USA )",
    "country": "United States"
  },
  {
    "id": 453088,
    "name": "EZ Way Inc    ( Clarinda IA USA )",
    "country": "United States"
  },
  {
    "id": 454121,
    "name": "EZ-ACCESS    ( Algona WA USA )",
    "country": "United States"
  },
  {
    "id": 460884,
    "name": "eZono AG    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 467008,
    "name": "eZono USA    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 185799,
    "name": "F & L Medical Products    ( Vandergrift PA USA )",
    "country": "United States"
  },
  {
    "id": 453075,
    "name": "F & M Control    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 453076,
    "name": "F & M Control SL    ( Vitoria (Alava)  Spain )",
    "country": "Spain"
  },
  {
    "id": 457272,
    "name": "F Ad Mueller Soehne AB    ( Staffanstorp  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 287374,
    "name": "F Ad Mueller Soehne Institut Zur Herstellung    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 384279,
    "name": "F Brumaba    ( Wolfratshausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 156993,
    "name": "F Hoffman-La Roche AG    (   USA )",
    "country": "United States"
  },
  {
    "id": 306280,
    "name": "F Stephan GmbH Medizintechnik    ( Gackenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 428586,
    "name": "F Stephan Middle East Office    ( Amman  Jordan )",
    "country": "Jordan"
  },
  {
    "id": 285672,
    "name": "F W Kraemer Orthopaedie Service GmbH    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 460881,
    "name": "F.A. O'Toole Office Systems Inc    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 458972,
    "name": "F5 Networks Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 348449,
    "name": "Faaborg Rehab Technic DK    ( Faaborg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 174293,
    "name": "Fabbe-Primar Industrial Ltda    ( Sao Pualo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 398219,
    "name": "Fabrica de Instrumentos y Equipos SA de CV    ( Naucalpan  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 108264,
    "name": "Fabrication Enterprises Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 150881,
    "name": "Fabricut Contract    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 342076,
    "name": "Fabriorto Lda    ( Porto  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 999397,
    "name": "Fabrique Par    (   USA )",
    "country": "United States"
  },
  {
    "id": 408733,
    "name": "Facet Technologies LLC    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 408734,
    "name": "Facet Technologies Ltd    ( Northampton  England )",
    "country": "UK"
  },
  {
    "id": 462595,
    "name": "FACT Canada    ( Quesnel BC Canada )",
    "country": "Canada"
  },
  {
    "id": 291705,
    "name": "Factory of Electronic Instrument Shanghai Universi    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 331247,
    "name": "Facts Services Inc    ( Coral Gables FL USA )",
    "country": "United States"
  },
  {
    "id": 416083,
    "name": "Facultatieve Technologies Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 162115,
    "name": "Fagerdala Industri AB    ( Gustavsberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451588,
    "name": "Fagerman Technologies Inc    ( Somerville AL USA )",
    "country": "United States"
  },
  {
    "id": 284363,
    "name": "Fahrenberger Orga-Service GmbH    ( Meckenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457532,
    "name": "Fairbanks Scales    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 999955,
    "name": "Fairbanks Scales    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 336042,
    "name": "Fairbright Enterprises Inc    ( Makati  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 391307,
    "name": "Fairfield Imaging Ltd    ( Tunbridge Wells  England )",
    "country": "UK"
  },
  {
    "id": 460841,
    "name": "Fairfield Laundry Machinery Corp    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 306054,
    "name": "Fairmont Medical Products Pty Ltd    ( Bayswater  Australia )",
    "country": "Australia"
  },
  {
    "id": 458882,
    "name": "FairWarning Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 107253,
    "name": "Fairway King Co    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 451151,
    "name": "Faith Medical Inc    ( Steedman MO USA )",
    "country": "United States"
  },
  {
    "id": 162647,
    "name": "Falck A/S    ( Risor  Norway )",
    "country": "Norway"
  },
  {
    "id": 101263,
    "name": "Falcon Rehabilitation Products Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 452341,
    "name": "Falcon Surgical Co Pvt Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 371752,
    "name": "Fall-Care Medical Systems    ( McAlester OK USA )",
    "country": "United States"
  },
  {
    "id": 105555,
    "name": "Falls Welding and Fabricating Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 358438,
    "name": "Famed Lodz Sa    ( Lodz  Poland )",
    "country": "Poland"
  },
  {
    "id": 185397,
    "name": "Famed-Zywiec Ltd    ( Zywiec  Poland )",
    "country": "Poland"
  },
  {
    "id": 456289,
    "name": "Famidoc Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 185398,
    "name": "Famor SA Zaklady Urzadzen Okretowych    ( Bydgoszcz  Poland )",
    "country": "Poland"
  },
  {
    "id": 332074,
    "name": "Famos bv    ( Venlo  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 393273,
    "name": "Famos Medizintechnik-Vertriebs GmbH    ( Straelen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103756,
    "name": "Fancee Free    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 174321,
    "name": "Fanem Fabr de Apars Nac de Eletromedicina Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 451514,
    "name": "Fanlight Corp Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 413232,
    "name": "Fannin Healthcare    ( Dublin 18  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 303660,
    "name": "Far East Medical Equipment    ( Mandaluyong City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 295731,
    "name": "Farafan Engineering Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 365756,
    "name": "Faratech Co    ( Giza  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 321054,
    "name": "Farber Specialty Vehicles    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 292996,
    "name": "Farcon srl    ( Varese (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 138327,
    "name": "Faretec Inc    ( Painesville OH USA )",
    "country": "United States"
  },
  {
    "id": 454889,
    "name": "Farla Medical Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 182785,
    "name": "Faro SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 108192,
    "name": "Faro Technologies Inc    ( Lake Mary FL USA )",
    "country": "United States"
  },
  {
    "id": 306253,
    "name": "Faromed GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 451578,
    "name": "Farrand Optical Components & Instruments    ( Valhalla NY USA )",
    "country": "United States"
  },
  {
    "id": 339190,
    "name": "FASD Methods Co Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 283528,
    "name": "Fashy GmbH    ( Korntal-Muenchingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 329707,
    "name": "Fast Medical    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 322937,
    "name": "Fastrack Healthcare Systems Inc    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 466662,
    "name": "Fathom Technologies    ( Georgetown TX USA )",
    "country": "United States"
  },
  {
    "id": 283314,
    "name": "Favodent Karl Huber GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 456588,
    "name": "Faxitron Bioptics Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 250057,
    "name": "Faxitron X-Ray Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 333670,
    "name": "Fazzini Czech    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 333672,
    "name": "Fazzini Jordan    ( Amman  USA )",
    "country": "United States"
  },
  {
    "id": 332068,
    "name": "Fazzini srl    ( Vimodrone (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 237888,
    "name": "FBI Fred Berninger Importe    ( Taufkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 322387,
    "name": "FC Commodities    ( Yavne  USA )",
    "country": "United States"
  },
  {
    "id": 283085,
    "name": "FCI    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 456079,
    "name": "FCI Ophthalmics    ( Marshfield Hills MA USA )",
    "country": "United States"
  },
  {
    "id": 280337,
    "name": "FD Fleischhauer Datentraeger    ( Herne  Germany )",
    "country": "Germany"
  },
  {
    "id": 305463,
    "name": "Feather Safety Razor Co    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 286032,
    "name": "Fechner Solar GmbH    ( Schwaebisch Hall  Germany )",
    "country": "Germany"
  },
  {
    "id": 453918,
    "name": "Fedegari Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 309507,
    "name": "Fedegari Autoclavi SpA    ( Albuzzano (PV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 450883,
    "name": "Fedegari Autoklaven AG    ( Bedano  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453917,
    "name": "Fedegari Technologies Inc    ( Dublin PA USA )",
    "country": "United States"
  },
  {
    "id": 451590,
    "name": "Federal Covers and Textiles Inc    ( Kenly NC USA )",
    "country": "United States"
  },
  {
    "id": 458454,
    "name": "Federal Industries    ( Belleville WI USA )",
    "country": "United States"
  },
  {
    "id": 460845,
    "name": "FedEx Corp    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 283512,
    "name": "Fedrofuss Holger G Weber    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 163634,
    "name": "FeFarma Tip Malzemeleri San Ve Tic AS    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 452507,
    "name": "Fehling Instruments GmbH & Co KG    ( Karlstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 283339,
    "name": "Fehling Medical AG    ( Karlstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 320682,
    "name": "Fehling Surgical Instruments    ( Acworth GA USA )",
    "country": "United States"
  },
  {
    "id": 345059,
    "name": "FEI Co    ( Hillsboro OR USA )",
    "country": "United States"
  },
  {
    "id": 455901,
    "name": "FEI Deutschland GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 429378,
    "name": "FEI Electron Optics bv    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455902,
    "name": "FEI France    ( Lyon Cedex 03  France )",
    "country": "France"
  },
  {
    "id": 455904,
    "name": "FEI Italia srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455903,
    "name": "FEI UK Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 453383,
    "name": "Feig Electronic GmbH    ( Weilburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 449820,
    "name": "Fein-Elast Umspinnwerk GmbH    ( Zeulenroda  Germany )",
    "country": "Germany"
  },
  {
    "id": 281925,
    "name": "Feinfocus Roetgen-Systeme GmbH    ( Garbsen  USA )",
    "country": "United States"
  },
  {
    "id": 285957,
    "name": "Felas Lasers GmbH    ( Scharbeutz  Germany )",
    "country": "Germany"
  },
  {
    "id": 151488,
    "name": "Felcon Reinraum- und Prozesstechnische Analgen    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 184089,
    "name": "Felhmex SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 456546,
    "name": "Felix Storch Inc    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 306242,
    "name": "Felton Grimwade & Bickford Pty Ltd    ( Oakleigh South  Australia )",
    "country": "Australia"
  },
  {
    "id": 456584,
    "name": "Felton Grimwade & Bosisto's Pty Ltd    ( Oakleigh South  Australia )",
    "country": "Australia"
  },
  {
    "id": 149217,
    "name": "Femcare Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 458122,
    "name": "Femcare-Nikomed Ltd    ( Romsey  England )",
    "country": "UK"
  },
  {
    "id": 457914,
    "name": "FEMI-LAB sro    ( Poprad  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 458489,
    "name": "Femtolasers Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 291088,
    "name": "Fenglei Armament Factory    ( Anshun  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 466801,
    "name": "Fentex Medical GmbH    ( Neuhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452143,
    "name": "Fenwal Inc    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 353501,
    "name": "Fern Enterprises (Pvt) Ltd    ( Colombo-7  USA )",
    "country": "United States"
  },
  {
    "id": 162377,
    "name": "Ferndale Laboratories Inc    ( Ferndale MI USA )",
    "country": "United States"
  },
  {
    "id": 402206,
    "name": "Ferno (Schweiz) AG    ( Littau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174085,
    "name": "Ferno (UK) Ltd    ( Cleckheaton  England )",
    "country": "UK"
  },
  {
    "id": 306159,
    "name": "Ferno Australia Pty Ltd    ( Brendale  Australia )",
    "country": "Australia"
  },
  {
    "id": 370948,
    "name": "Ferno Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 402103,
    "name": "Ferno France    ( Amberieu-En-Bugey  France )",
    "country": "France"
  },
  {
    "id": 174084,
    "name": "Ferno Japan Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 402203,
    "name": "Ferno Mexico    ( Guaymas  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 402207,
    "name": "Ferno Middle East    ( Abu Dhabi  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 402202,
    "name": "Ferno Norden A/S    ( Horten  Norway )",
    "country": "Norway"
  },
  {
    "id": 402204,
    "name": "Ferno Transportgeraete GmbH    ( Troisdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 402205,
    "name": "Ferno Washington Italia srl    ( Prato (PO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 150095,
    "name": "Ferno-Washington Inc    ( Wilmington OH USA )",
    "country": "United States"
  },
  {
    "id": 403771,
    "name": "Ferrania SpA    ( Cairo Montenotte (SV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451066,
    "name": "Ferrania UK Ltd    ( Bracknell  England )",
    "country": "UK"
  },
  {
    "id": 417298,
    "name": "Ferraris CardioRespiratory (Germany)    ( Feucht  USA )",
    "country": "United States"
  },
  {
    "id": 417297,
    "name": "Ferraris Respiratory    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 450543,
    "name": "Ferraris Respiratory (Hong Kong)    ( North Point  USA )",
    "country": "United States"
  },
  {
    "id": 416476,
    "name": "Ferraris Respiratory Europe Ltd    ( Hertford  USA )",
    "country": "United States"
  },
  {
    "id": 455091,
    "name": "Ferring Pharmaceuticals    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 118219,
    "name": "Ferris Mfg Corp    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 387457,
    "name": "Ferrosan A/S    ( Soeborg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 107642,
    "name": "Fertile Hemispheres    ( Potomac MD USA )",
    "country": "United States"
  },
  {
    "id": 463005,
    "name": "FertiliTech Inc    ( Rockland MA USA )",
    "country": "United States"
  },
  {
    "id": 452795,
    "name": "Fertility Technology Resources Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 409852,
    "name": "Fertipro    ( Beernem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 451161,
    "name": "Feta Med Inc    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 280015,
    "name": "Feuerstein GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 312531,
    "name": "FFDM Pneumat    ( Bourges Cedex  France )",
    "country": "France"
  },
  {
    "id": 331091,
    "name": "FFF Enterprises Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 999594,
    "name": "FG Industries    (   USA )",
    "country": "United States"
  },
  {
    "id": 306388,
    "name": "FH Furtwangen & Rad Universitaetsklinik    ( Furtwangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451200,
    "name": "FHC Inc    ( Bowdoin ME USA )",
    "country": "United States"
  },
  {
    "id": 457256,
    "name": "FIAB SpA    ( Vicchio (FI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455186,
    "name": "FIAlab Instruments Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 458490,
    "name": "Fianium Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 453678,
    "name": "FiatLUX Imaging Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 460866,
    "name": "Fiber-Seal Systems    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 460867,
    "name": "FiberCell Systems Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 454081,
    "name": "Fiberlinks Textiles Inc    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 459019,
    "name": "Fiberlock Technologies    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 466798,
    "name": "Fiberoptic Medical Products Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 330083,
    "name": "Fiberoptic Systems Inc    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 462967,
    "name": "Fiberoptics Technology Inc    ( Pomfret CT USA )",
    "country": "United States"
  },
  {
    "id": 431625,
    "name": "Fibertek Inc    ( Herndon VA USA )",
    "country": "United States"
  },
  {
    "id": 371516,
    "name": "Fidia SpA    ( Abano Terme (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451258,
    "name": "Fiegert Endotech Brazil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 418692,
    "name": "First Biomedical Inc    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 158260,
    "name": "Fiegert-Endotech Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451257,
    "name": "Fiegert-Endotech USA    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 452699,
    "name": "Fifth Dimension Information Management    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 331248,
    "name": "File Master    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 465230,
    "name": "FileMaker, Inc.    ( Santa Clara 52 USA )",
    "country": "United States"
  },
  {
    "id": 339191,
    "name": "Filing Systems Inc/FSI Label Co    ( Roseville MI USA )",
    "country": "United States"
  },
  {
    "id": 467285,
    "name": "Fillmaster Systems LLC    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 458941,
    "name": "Filson Water LLC    ( Carlisle PA USA )",
    "country": "United States"
  },
  {
    "id": 402501,
    "name": "Filtertek BV    ( Newcastle West  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 402502,
    "name": "Filtertek do Brasil    ( Guarulhos-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 402503,
    "name": "Filtertek GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 188047,
    "name": "Filtertek Inc    ( Hebron IL USA )",
    "country": "United States"
  },
  {
    "id": 292999,
    "name": "Filtertek SA    ( Plailly  France )",
    "country": "France"
  },
  {
    "id": 999673,
    "name": "Filtrine Manufacturing Company    ( Keene NH USA )",
    "country": "USA"
  },
  {
    "id": 458456,
    "name": "Filtrine Mfg Co    ( Keene NH USA )",
    "country": "United States"
  },
  {
    "id": 403604,
    "name": "Fim Medical    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 162457,
    "name": "Fimet Oy    ( Monninkylae  Finland )",
    "country": "Finland"
  },
  {
    "id": 460860,
    "name": "Final Air Balance Co Inc    ( Auburn CA USA )",
    "country": "United States"
  },
  {
    "id": 426920,
    "name": "Finapres Medical Systems bv (FMS)    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 108639,
    "name": "Fine Science Tools (USA) Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 152351,
    "name": "Fine Science Tools Inc    ( North Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 152818,
    "name": "Fine Surgical Instrument Inc    ( Hempstead NY USA )",
    "country": "United States"
  },
  {
    "id": 456295,
    "name": "Finess Healthcare Group Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 450846,
    "name": "Finetec Co Ltd    ( Fuchu-shi  USA )",
    "country": "United States"
  },
  {
    "id": 452966,
    "name": "Finishing Touches Ltd    ( Haywards Heath  England )",
    "country": "UK"
  },
  {
    "id": 284416,
    "name": "Fink & Walter GmbH    ( Merchweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 475933,
    "name": "Fink Engineering PTY Ltd    ( Warana  Australia )",
    "country": "Australia"
  },
  {
    "id": 439545,
    "name": "Finland Biohit Co Ltd    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 999737,
    "name": "Finnex    (   USA )",
    "country": "United States"
  },
  {
    "id": 282387,
    "name": "Finnjark GmbH & Co KG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 369472,
    "name": "Finnzymes Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 452354,
    "name": "Finsbury Orthopaedics Ltd    ( Leatherhead  England )",
    "country": "UK"
  },
  {
    "id": 279640,
    "name": "Finzler Schrock & Kimmel GmbH    ( Bad Ems  Germany )",
    "country": "Germany"
  },
  {
    "id": 306413,
    "name": "Fiopharma srl    ( Milano MI  Italy )",
    "country": "Italy"
  },
  {
    "id": 454017,
    "name": "Fire Fighting Equipment Co    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 455276,
    "name": "Fire-Lite Alarms Inc    ( Northford CT USA )",
    "country": "United States"
  },
  {
    "id": 175568,
    "name": "Fireboy-Xintex Safety Systems    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 455937,
    "name": "Firehouse Medical Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 453011,
    "name": "FireKing Security Group    ( New Albany IN USA )",
    "country": "United States"
  },
  {
    "id": 152091,
    "name": "Firlabo    ( Meyzieu  France )",
    "country": "France"
  },
  {
    "id": 329476,
    "name": "First Aid Bandage Co    ( New London CT USA )",
    "country": "United States"
  },
  {
    "id": 451803,
    "name": "First Aid Only    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 455274,
    "name": "First Alert Inc    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 453998,
    "name": "First Call Parts    ( Salem VA USA )",
    "country": "United States"
  },
  {
    "id": 455786,
    "name": "First Care Products Ltd    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 195332,
    "name": "First DataBank    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 460833,
    "name": "First Degree Fitness    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 999796,
    "name": "First Degree Fitness NA, LLC    ( Springfield MI USA )",
    "country": "United States"
  },
  {
    "id": 161817,
    "name": "First Healthcare Products    ( Sanborn NY USA )",
    "country": "United States"
  },
  {
    "id": 459091,
    "name": "First Line Technology HQ    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 467007,
    "name": "First Medica    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 455624,
    "name": "First Medical International Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 457459,
    "name": "First Medical Source    ( Laguna Niguel CA USA )",
    "country": "United States"
  },
  {
    "id": 460857,
    "name": "First Office    ( Huntingburg IN USA )",
    "country": "United States"
  },
  {
    "id": 466849,
    "name": "First Place Fitness Equipment    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 108265,
    "name": "First Quality Enterprises Inc    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 455326,
    "name": "First Source Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 452820,
    "name": "First Water Ltd    ( Marlborough  England )",
    "country": "UK"
  },
  {
    "id": 453087,
    "name": "Firstar Healthcare Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101269,
    "name": "Fischer Imaging Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 103039,
    "name": "Fischer Industries Inc    ( Geneva IL USA )",
    "country": "United States"
  },
  {
    "id": 453505,
    "name": "Fischer Medical Technologies Inc    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 286424,
    "name": "Fischer Software GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 361958,
    "name": "Fischer Technical Co    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 284377,
    "name": "Fischer Technology GmbH    ( Meckenheim Bonn  USA )",
    "country": "United States"
  },
  {
    "id": 401929,
    "name": "Fischer-Zoth Diagnosesysteme GmbH    ( Germering  Germany )",
    "country": "Germany"
  },
  {
    "id": 460855,
    "name": "Fiserv Corp    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 330986,
    "name": "Fish Medical    ( Lake in the Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 175281,
    "name": "Fisher & Paykel Healthcare France/Benelux    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 255300,
    "name": "Fisher & Paykel Healthcare Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 151622,
    "name": "Fisher & Paykel Healthcare Ltd (New Zealand)    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 184866,
    "name": "Fisher & Paykel Healthcare Ltd (UK)    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 148229,
    "name": "Fisher & Paykel Healthcare Pty Ltd    ( Ringwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 456977,
    "name": "Fisher Biotec Pty Ltd    ( Wembley  Australia )",
    "country": "Australia"
  },
  {
    "id": 454520,
    "name": "Fisher Consulting    ( Clarkston MI USA )",
    "country": "United States"
  },
  {
    "id": 455547,
    "name": "Fisher Diagnostics    ( Middletown VA USA )",
    "country": "United States"
  },
  {
    "id": 184190,
    "name": "Fisher Hamilton LLC    ( Two Rivers WI USA )",
    "country": "United States"
  },
  {
    "id": 375242,
    "name": "Fisher Healthcare    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 367056,
    "name": "Fisher Imaging Corp-Hong Kong    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 103117,
    "name": "Fisher Scientific (USA)    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 129383,
    "name": "Fisher Scientific Co (Canada)    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455549,
    "name": "Fisher Scientific GmbH    ( Schwerte  Germany )",
    "country": "Germany"
  },
  {
    "id": 456862,
    "name": "Fisher Scientific Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 289494,
    "name": "Fisher Scientific UK    ( Loughborough  England )",
    "country": "UK"
  },
  {
    "id": 101497,
    "name": "Fiske Assoc    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 458889,
    "name": "FitLinxx    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 999637,
    "name": "Fitness Master Inc.    ( Farmers Branch TX USA )",
    "country": "United States"
  },
  {
    "id": 174599,
    "name": "Fitter International Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 457522,
    "name": "Fitzco Inc    ( Spring Park MN USA )",
    "country": "United States"
  },
  {
    "id": 450994,
    "name": "Fitzgerald Industries International Inc    ( Concord MA USA )",
    "country": "United States"
  },
  {
    "id": 431609,
    "name": "Five Star Marketing Inc    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 392332,
    "name": "Five Star Surgical Inc    ( New Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 460849,
    "name": "Fixtur World Inc    ( Cookeville TN USA )",
    "country": "United States"
  },
  {
    "id": 456622,
    "name": "FJORDBLINK MEDICAL P/S    ( Randers  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 107609,
    "name": "FJW Optical Systems Inc    ( Palatine IL USA )",
    "country": "United States"
  },
  {
    "id": 431582,
    "name": "FKI Security Group    ( New Albany IN USA )",
    "country": "United States"
  },
  {
    "id": 322938,
    "name": "FLA Orthopedics Inc    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 154409,
    "name": "Flaem Nuova SpA    ( San Martino Della Battaglia  Italy )",
    "country": "Italy"
  },
  {
    "id": 323699,
    "name": "Flaga Group hf    ( Reykjavik  Iceland )",
    "country": "Iceland"
  },
  {
    "id": 309774,
    "name": "Flaghouse Inc    ( Hasbrouck Heights NJ USA )",
    "country": "United States"
  },
  {
    "id": 455031,
    "name": "Flagship Mobility Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 469466,
    "name": "Flanders Corp    ( Washington NC USA )",
    "country": "United States"
  },
  {
    "id": 469456,
    "name": "Flanders Scientific Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 458894,
    "name": "Flanery Medical LLC    ( Cordova TN USA )",
    "country": "United States"
  },
  {
    "id": 293001,
    "name": "Flash Laboratories SA    ( Echternach  Luxembourg )",
    "country": "Luxembourg"
  },
  {
    "id": 293343,
    "name": "Flawa AG    ( Flawil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999965,
    "name": "Fleet Power    (   USA )",
    "country": "United States"
  },
  {
    "id": 460846,
    "name": "Fleetmatics Ltd    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 280017,
    "name": "Fleischhacker GmbH & Co KG    ( Schwerte  Germany )",
    "country": "Germany"
  },
  {
    "id": 280019,
    "name": "Flemming und Klingbeil GmbH Hoergeraete-Akustik    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 107256,
    "name": "Flents Products Co Inc    ( St Charles MI USA )",
    "country": "United States"
  },
  {
    "id": 104793,
    "name": "Fletcher-Flora Computer Products Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 108266,
    "name": "Flex-A-Bed Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 108267,
    "name": "Flex-Aid    ( Oakland Park FL USA )",
    "country": "United States"
  },
  {
    "id": 456555,
    "name": "Flex-i-Trans BV    ( Velddriel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 107257,
    "name": "Flex-Lite Inc    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 459024,
    "name": "Flexcell International Corp    ( Hillsborough NC USA )",
    "country": "United States"
  },
  {
    "id": 103442,
    "name": "Flexi-Wall Systems    ( Liberty SC USA )",
    "country": "United States"
  },
  {
    "id": 306135,
    "name": "Flexibeds Australia Pty Ltd    ( Dingley  USA )",
    "country": "United States"
  },
  {
    "id": 457667,
    "name": "Flexicare Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 272692,
    "name": "Flexicare Medical Ltd    ( Mountain Ash  Wales )",
    "country": "Wales"
  },
  {
    "id": 104179,
    "name": "Flexisplint Inc    ( Hemet CA USA )",
    "country": "United States"
  },
  {
    "id": 332708,
    "name": "FlexSite Diagnostics Inc    ( Palm City FL USA )",
    "country": "United States"
  },
  {
    "id": 417641,
    "name": "FLEXSTENT MED Corp    ( Changzhou  USA )",
    "country": "United States"
  },
  {
    "id": 409735,
    "name": "Flexstim.Com Inc    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 452824,
    "name": "Fliegel Data GmbH    ( Hoexter  Germany )",
    "country": "Germany"
  },
  {
    "id": 455790,
    "name": "Flight Medical Innovations Ltd    ( Lod  Israel )",
    "country": "Israel"
  },
  {
    "id": 175574,
    "name": "FLIR Systems Inc    ( Wilsonville OR USA )",
    "country": "United States"
  },
  {
    "id": 451831,
    "name": "Flo Healthcare    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 374046,
    "name": "FloCare    ( Fort Bragg CA USA )",
    "country": "United States"
  },
  {
    "id": 331092,
    "name": "Florida Infusion Services Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 453079,
    "name": "Florida Infusions/Nations Drug Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 456838,
    "name": "Florida Lions Eye Bank    ( Miama FL USA )",
    "country": "United States"
  },
  {
    "id": 457477,
    "name": "Florida Mfg Corp    ( Daytona Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 101271,
    "name": "Florida Mfg Corp/FlaManCo International    ( Daytona Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 455333,
    "name": "Florida Service Plus Inc    ( Apopka FL USA )",
    "country": "United States"
  },
  {
    "id": 305566,
    "name": "Florida Software Systems    ( Palmetto FL USA )",
    "country": "United States"
  },
  {
    "id": 108518,
    "name": "Florlift of New Jersey Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 273724,
    "name": "Flotec Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 999471,
    "name": "Flow Dental    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 398211,
    "name": "Flow Management Technologies Inc    ( Saratoga Springs NY USA )",
    "country": "United States"
  },
  {
    "id": 105463,
    "name": "Flow Technology Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 454747,
    "name": "FlowCardia Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 308104,
    "name": "Flowforms (Wiltshire) Ltd    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 460835,
    "name": "Flownamics    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 465140,
    "name": "Flowserve Corporation    ( Irving 52 USA )",
    "country": "United States"
  },
  {
    "id": 176849,
    "name": "Flowtronics Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 107258,
    "name": "Flud Dental Laboratory    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 293456,
    "name": "Flueckiger & Huguenin SA    ( La Chaux-de-Fonds  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 104184,
    "name": "Fluid Metering Inc (FMI)    ( Syosset NY USA )",
    "country": "United States"
  },
  {
    "id": 460838,
    "name": "Fluid Solutions Inc    ( Maumelle AR USA )",
    "country": "United States"
  },
  {
    "id": 458448,
    "name": "Fluidigm    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 324341,
    "name": "Fluke (UK) Ltd    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 336418,
    "name": "Fluke Belgium NV    ( Sint Pieters-Leeuw  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 178848,
    "name": "Fluke Biomedical Corp    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 451244,
    "name": "Fluke Biomedical Corp Radiation Management Service    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 431338,
    "name": "Fluke Corp    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 336478,
    "name": "Fluke Corp Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 336439,
    "name": "Fluke Danmark A/S    ( Kobenhaven K  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 336448,
    "name": "Fluke Deutschland GmbH    ( Kassel  Germany )",
    "country": "Germany"
  },
  {
    "id": 363660,
    "name": "Fluke do Brazil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 336422,
    "name": "Fluke Electronics Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 336446,
    "name": "Fluke Finland Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 336447,
    "name": "Fluke France SA    ( Roissy-Charles-de-Guulle Cedex  France )",
    "country": "France"
  },
  {
    "id": 336574,
    "name": "Fluke Iberica SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 336426,
    "name": "Fluke International Corp (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 336475,
    "name": "Fluke Italia srl    ( Brugherio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 336482,
    "name": "Fluke Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 336500,
    "name": "Fluke Malaysia Representative Office    ( Kuala Lumpur  USA )",
    "country": "United States"
  },
  {
    "id": 402461,
    "name": "Fluke Nederland bv    ( Son  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 464114,
    "name": "Fluke Networks    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 336554,
    "name": "Fluke Norge A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 336569,
    "name": "Fluke Southeast Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 407163,
    "name": "Fluke Sverige AB    ( Upplands Vaesby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 336578,
    "name": "Fluke Switzerland AG    ( Wallisellen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 336414,
    "name": "Fluke Vertriebs Ges mbH    ( Brunn am Gebirge  Austria )",
    "country": "Austria"
  },
  {
    "id": 469433,
    "name": "Fluoron GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 149333,
    "name": "Fluoroscan Imaging Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 999921,
    "name": "Fluovac    (   USA )",
    "country": "United States"
  },
  {
    "id": 284352,
    "name": "Flux-Geraete GmbH    ( Maulbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 233585,
    "name": "FMT Medizintechnik GmbH    ( Bamberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 322689,
    "name": "FNC Medical Corp    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 157918,
    "name": "Focal Corp    ( Kashiwa  Japan )",
    "country": "Japan"
  },
  {
    "id": 338671,
    "name": "Focus Diagnostics Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 455358,
    "name": "Focus Medical    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 322944,
    "name": "Focus Medical Mfg LLC    ( Bay City MI USA )",
    "country": "United States"
  },
  {
    "id": 452435,
    "name": "Focus Surgery Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 457518,
    "name": "Focus Treatment Solutions    ( Middletown NY USA )",
    "country": "United States"
  },
  {
    "id": 362130,
    "name": "Focused Technology    ( Ridgecrest CA USA )",
    "country": "United States"
  },
  {
    "id": 283578,
    "name": "Foerster & Trester GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 285908,
    "name": "Foerster & Trester GmbH Indikationsprothetik    ( Saarbruecken  Germany )",
    "country": "Germany"
  },
  {
    "id": 102105,
    "name": "Fogg System Co Inc    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 230818,
    "name": "Folabo srl    ( Buccinasco (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 293006,
    "name": "Folioplast    ( Sarcelles Cedex  France )",
    "country": "France"
  },
  {
    "id": 104178,
    "name": "Follett Corp    ( Easton PA USA )",
    "country": "United States"
  },
  {
    "id": 162648,
    "name": "Follo Industrier A/S    ( As  Norway )",
    "country": "Norway"
  },
  {
    "id": 457885,
    "name": "FONA Dental SRO    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 104177,
    "name": "FONAR Corp    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 282983,
    "name": "Fondermann GmbH    ( Hilden  Germany )",
    "country": "Germany"
  },
  {
    "id": 451818,
    "name": "FONEMED    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 458948,
    "name": "Food Equipment International Inc    ( Spokane Valley WA USA )",
    "country": "United States"
  },
  {
    "id": 426712,
    "name": "Food Handler Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 460824,
    "name": "Food Warming Equipment Co Inc    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 459367,
    "name": "Food Warming Equipment Company Inc    ( Portland TN USA )",
    "country": "United States"
  },
  {
    "id": 107259,
    "name": "Foot Management Inc    ( Pittsville MD USA )",
    "country": "United States"
  },
  {
    "id": 306503,
    "name": "Foot Science International Ltd    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 458780,
    "name": "Footlink Pty Ltd    ( Moorabbin  Australia )",
    "country": "Australia"
  },
  {
    "id": 455866,
    "name": "For Care Enterprise Co Ltd    ( Kaohsiung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 280021,
    "name": "For Life Produktions- und Vertriebsgesellschaft    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 105924,
    "name": "Forbo Flooring Inc    ( Hazleton PA USA )",
    "country": "United States"
  },
  {
    "id": 340852,
    "name": "Forbo-Giubiasco SA    ( Giubiasco  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174600,
    "name": "Force 3 Medical Inc    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 460873,
    "name": "Force Technology    ( Brondby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 465097,
    "name": "Ford & Ulrich Inc    ( North Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 453770,
    "name": "Fore Tech Medical    ( Williamstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 101969,
    "name": "Foregger Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 457104,
    "name": "Foremost Equipment Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 306608,
    "name": "Foremost Glove Industrial Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455867,
    "name": "Foremount Enterprise Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 107772,
    "name": "Forensic Technologies Inc    ( Woodbury MN USA )",
    "country": "United States"
  },
  {
    "id": 457742,
    "name": "Foreseeson Custom Displays Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 454047,
    "name": "Foreseeson USA    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 452648,
    "name": "Foresight Imaging    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 394477,
    "name": "Forest Medical LLC    ( East Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 294176,
    "name": "Forest Medical Products Inc    ( Hillsboro OR USA )",
    "country": "United States"
  },
  {
    "id": 457014,
    "name": "Foretek Microsystem    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452545,
    "name": "Forever Young Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 453621,
    "name": "ForHealth Technologies Inc    ( Daytona Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 458379,
    "name": "Forklift Systems    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 344846,
    "name": "Forlab SA    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 293835,
    "name": "Forma AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 335300,
    "name": "FORMAC Products    ( Kew  Australia )",
    "country": "Australia"
  },
  {
    "id": 459056,
    "name": "Formax    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 267516,
    "name": "Formec SA de CV    ( Monterray  USA )",
    "country": "United States"
  },
  {
    "id": 102963,
    "name": "Formedica Ltd    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 459227,
    "name": "FormFast    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 105524,
    "name": "Formica Corp    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458491,
    "name": "Formulatrix Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 456331,
    "name": "Forrest Health Center    ( Los Gatos CA USA )",
    "country": "United States"
  },
  {
    "id": 377601,
    "name": "Forschungszentrum Karlsruhe GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 465079,
    "name": "Fort Defiance Industries Inc    ( Loudon TN USA )",
    "country": "United States"
  },
  {
    "id": 458629,
    "name": "Forte Automation Systems Inc    ( Machesney Park IL USA )",
    "country": "United States"
  },
  {
    "id": 460787,
    "name": "Forte Research Systems Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 451159,
    "name": "ForTec Medical Inc    ( Streetsboro OH USA )",
    "country": "United States"
  },
  {
    "id": 452527,
    "name": "Forth Medical Ltd    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 460539,
    "name": "Forticell Bioscience Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 104841,
    "name": "Fortrad Eye Instruments    ( Mendham NJ USA )",
    "country": "United States"
  },
  {
    "id": 453837,
    "name": "Fortress Diagnostics Ltd    ( Antrim  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 177266,
    "name": "Fortune Medical Instrument Corp    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 458683,
    "name": "Forward Advantage Inc    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 456296,
    "name": "Foshan Dongfang Medical Equipment Manufactory Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456297,
    "name": "Foshan Nanhai Baihe Medical Technology Co Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455723,
    "name": "Foshan Nanhai Plus Medical Co Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451158,
    "name": "Fray Products Corp    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 456590,
    "name": "Foshan Nanhai Xinhuafeng Medical Equipment Co Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455726,
    "name": "Foshan SOFT Medical Treatment Health Care Products    ( Foshan City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 317154,
    "name": "Foss NIR Systems    ( Laurel MD USA )",
    "country": "United States"
  },
  {
    "id": 999610,
    "name": "Foster + Freeman USA Inc.    ( Sterling VA USA )",
    "country": "USA"
  },
  {
    "id": 103763,
    "name": "Foster Mfg Corp    ( Racine WI USA )",
    "country": "United States"
  },
  {
    "id": 287546,
    "name": "Foster Refrigerator GmbH    ( Schutterwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 103162,
    "name": "Fostoria Industries Inc    ( Fostoria OH USA )",
    "country": "United States"
  },
  {
    "id": 280025,
    "name": "Fotochemische Werke GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 102686,
    "name": "Fotodyne Inc    ( Hartland WI USA )",
    "country": "United States"
  },
  {
    "id": 459366,
    "name": "FotoFinder Systems Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 460888,
    "name": "FotoFinder Systems Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 367428,
    "name": "Fotona d d    ( Ljubljana  Slovenia )",
    "country": "Slovenia"
  },
  {
    "id": 306391,
    "name": "Fotona Germany    ( Gruibingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 471710,
    "name": "Foundation Medicine Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 441459,
    "name": "Four Rivers Software Systems    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 458897,
    "name": "Four Winds Interactive LLC    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 151019,
    "name": "Foures SA    ( Gradignan Cedex  France )",
    "country": "France"
  },
  {
    "id": 417339,
    "name": "Fournitures Hospitalieres Industrie    ( Quimper  France )",
    "country": "France"
  },
  {
    "id": 449844,
    "name": "Fox Hollow Technologies    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 103448,
    "name": "Fox Medical Co Inc    ( Groton CT USA )",
    "country": "United States"
  },
  {
    "id": 458904,
    "name": "Fox Medical Equipment Inc    ( Pennsauken NJ USA )",
    "country": "United States"
  },
  {
    "id": 461017,
    "name": "FP Mailing Solutions    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 104176,
    "name": "FR Chemicals Inc    ( Mount Vernon NY USA )",
    "country": "United States"
  },
  {
    "id": 416074,
    "name": "France 3B Scientific    ( Bartenheim  France )",
    "country": "France"
  },
  {
    "id": 279175,
    "name": "Francemed    ( Bagneux  USA )",
    "country": "United States"
  },
  {
    "id": 185911,
    "name": "Franek Technologies Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 174793,
    "name": "Franel Optical Supply    ( Apopka FL USA )",
    "country": "United States"
  },
  {
    "id": 285138,
    "name": "Frank Audiodata    ( Oberhausen/Rheinhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107972,
    "name": "Frank Barker Assoc Inc    ( Towaco NJ USA )",
    "country": "United States"
  },
  {
    "id": 322533,
    "name": "Frank Mobility Systems Inc    ( Oakdale PA USA )",
    "country": "United States"
  },
  {
    "id": 101697,
    "name": "Frank Scholz X-Ray Corp    ( Brockton MA USA )",
    "country": "United States"
  },
  {
    "id": 286711,
    "name": "Frank Simmank    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103898,
    "name": "Frank Stubbs Co Inc    ( Oxnard CA USA )",
    "country": "United States"
  },
  {
    "id": 328057,
    "name": "Franke Finland Oy    ( Naarajarvi  Finland )",
    "country": "Finland"
  },
  {
    "id": 455741,
    "name": "Frankenman International Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451160,
    "name": "Franklin Dental Supply Inc    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 999518,
    "name": "Franklin Industries L.L.C.    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 144505,
    "name": "Franklin X-Ray Corp    ( Fox River Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 176597,
    "name": "Frantztech Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 441264,
    "name": "Franz Design Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 281501,
    "name": "Franz Kalff GmbH    ( Euskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 230342,
    "name": "Franz Mueller GmbH & Co KG    ( Engelskirchen  USA )",
    "country": "United States"
  },
  {
    "id": 286581,
    "name": "Frasaco Franz Sachs & Co GmbH    ( Tettnang  Germany )",
    "country": "Germany"
  },
  {
    "id": 108149,
    "name": "Frazer Inc    ( Bellaire TX USA )",
    "country": "United States"
  },
  {
    "id": 168491,
    "name": "Frebag AG    ( Langnau aA  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 397944,
    "name": "Fred Baker Scientific    ( Runcorn  England )",
    "country": "UK"
  },
  {
    "id": 999762,
    "name": "Fred V. Fowler Company, Inc.    ( Auburndale MA USA )",
    "country": "USA"
  },
  {
    "id": 162969,
    "name": "Freed Electric Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 458318,
    "name": "Freedom Concepts Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 107727,
    "name": "Freedom Designs Inc    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 106160,
    "name": "Freedom Imaging Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 453364,
    "name": "Freedom Innovations Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 439576,
    "name": "Freedom Medical    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 457620,
    "name": "Freedom Scientific    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 457621,
    "name": "Freedom Scientific GmbH    ( Taegerwilen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457395,
    "name": "Freehand 2010 Ltd    ( Guildford  England )",
    "country": "UK"
  },
  {
    "id": 103767,
    "name": "Freeman Mfg Co    ( Sturgis MI USA )",
    "country": "United States"
  },
  {
    "id": 458323,
    "name": "FreeMotion Fitness    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 999712,
    "name": "FreeMotion Fitness    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 459077,
    "name": "Freeslate Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 332788,
    "name": "French Pocket Emergency Airway    ( New Orleans LA USA )",
    "country": "United States"
  },
  {
    "id": 283155,
    "name": "Frenzel GmbH    ( Obersulm-Suelzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 139461,
    "name": "Fresenius AG    ( Bad Homburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454912,
    "name": "Fresenius HemoCare Austria GmbH    (   USA )",
    "country": "United States"
  },
  {
    "id": 454941,
    "name": "Fresenius Kabi (Schweiz) AG    ( Stans  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454942,
    "name": "Fresenius Kabi (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 454917,
    "name": "Fresenius Kabi AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454931,
    "name": "Fresenius Kabi Ab    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 452660,
    "name": "Fresenius Kabi AG    ( Bad Homburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454944,
    "name": "Fresenius Kabi Argentina SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 454913,
    "name": "Fresenius Kabi Asia Pacific Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454916,
    "name": "Fresenius Kabi Australia Pty Ltd    ( Pymble  Australia )",
    "country": "Australia"
  },
  {
    "id": 454911,
    "name": "Fresenius Kabi Austria GmbH    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 454930,
    "name": "Fresenius Kabi Denmark    ( Kobenhaven S  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454940,
    "name": "Fresenius Kabi Espana SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 454910,
    "name": "Fresenius Kabi France SAS    ( Sevres Cedex  France )",
    "country": "France"
  },
  {
    "id": 454932,
    "name": "Fresenius Kabi Hellas SA    ( Athens  Greece )",
    "country": "Greece"
  },
  {
    "id": 454933,
    "name": "Fresenius Kabi Hungary Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 454934,
    "name": "Fresenius Kabi Ireland    ( Balbriggan  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 454935,
    "name": "Fresenius Kabi Italia srl    ( Isola della Scala (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452701,
    "name": "Fresenius Kabi Ltd    ( Runcorn  England )",
    "country": "UK"
  },
  {
    "id": 454945,
    "name": "Fresenius Kabi Mexico SA de CV    ( Zapopan  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 454936,
    "name": "Fresenius Kabi Nederland bv    ( Zeist  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454937,
    "name": "Fresenius Kabi Norge A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 454928,
    "name": "Fresenius Kabi NV    ( Schelle  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454938,
    "name": "Fresenius Kabi Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 454939,
    "name": "Fresenius Kabi Romania    ( Brasov  Romania )",
    "country": "Romania"
  },
  {
    "id": 454915,
    "name": "Fresenius Kabi South Africa (Pty) Ltd    ( Midrand  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454929,
    "name": "Fresenius Kabi sro    ( Praha 4  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 457343,
    "name": "Fresenius Kabi USA    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 457548,
    "name": "Fresenius Medical Care Australia Pty Ltd    ( Milsons Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 346048,
    "name": "Fresenius Medical Care Canada Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454270,
    "name": "Fresenius Medical Care Co KGaA    ( Bad Homburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 312187,
    "name": "Fresenius Medical Care North America    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 298142,
    "name": "Fresenius Vial SA    ( Brezins  France )",
    "country": "France"
  },
  {
    "id": 456316,
    "name": "Freud-Carson    ( Flanders NJ USA )",
    "country": "United States"
  },
  {
    "id": 292902,
    "name": "Freudenberg Vliesstoffe KG    ( Weinheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 382930,
    "name": "Friadent GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458450,
    "name": "FridgeFreeze Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 999674,
    "name": "FridgeFreeze, Inc.    ( San Diego CA USA )",
    "country": "USA"
  },
  {
    "id": 279741,
    "name": "Friedr Nierhaus GmbH    ( Bad Salzuflen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103781,
    "name": "Friedrich & Dimmock Inc    ( Millville NJ USA )",
    "country": "United States"
  },
  {
    "id": 153003,
    "name": "Friedrich Albrecht GmbH    ( Berlin 36  USA )",
    "country": "United States"
  },
  {
    "id": 153151,
    "name": "Friedrich Bosch GmbH & Co KG    ( Bisingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451869,
    "name": "Friedrich Daniels GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285776,
    "name": "Friedrich Haag Chirurgische Instrumente    ( Rietheim-Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 280550,
    "name": "Friedrich Wilhelm Kunath GmbH -Kunath Medizinische    ( Bretnig/Hauswalde  Germany )",
    "country": "Germany"
  },
  {
    "id": 286196,
    "name": "Friedrich Witte GmbH    ( Solingen  USA )",
    "country": "United States"
  },
  {
    "id": 106216,
    "name": "Friese X-Ray Services    ( Guthrie OK USA )",
    "country": "United States"
  },
  {
    "id": 466473,
    "name": "Frigidaire Commercial    ( Martinez GA USA )",
    "country": "United States"
  },
  {
    "id": 339660,
    "name": "Frigidaire Commercial Products Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 293767,
    "name": "Fritac Medizintechnik AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 283129,
    "name": "Fritsch GmbH    ( Idar-Oberstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 282225,
    "name": "Fritz Drux    ( Gummersbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 286715,
    "name": "Fritz Holweg Inh Joerg Holweg Augen- Mikro- und    ( Rietheim-Weilheim  USA )",
    "country": "United States"
  },
  {
    "id": 287271,
    "name": "Fritz Jaeger GmbH & Co KG    ( Wermelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280498,
    "name": "Fritz Koehn Handels GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284528,
    "name": "Fritz Osk Michallik GmbH & Co    ( Muehlacker  Germany )",
    "country": "Germany"
  },
  {
    "id": 285243,
    "name": "Fritz Rode GmbH    ( Osnabrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 457362,
    "name": "Fritz Stephan GmbH    ( Gackenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 284543,
    "name": "Fritz Werner    ( Muehlheim/Stetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 456924,
    "name": "FRIWO Geraetebau GmbH    ( Ostbevern  Germany )",
    "country": "Germany"
  },
  {
    "id": 453123,
    "name": "Front Row    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 185913,
    "name": "Frontier Biomedical    ( Midland TX USA )",
    "country": "United States"
  },
  {
    "id": 151087,
    "name": "Frontier Medical Products    ( Blackwood  Wales )",
    "country": "Wales"
  },
  {
    "id": 335212,
    "name": "Frontier Medical Products Inc    ( Port Washington WI USA )",
    "country": "United States"
  },
  {
    "id": 461022,
    "name": "FrontRange Solutions USA Inc    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 999964,
    "name": "FROSTMAN    (   USA )",
    "country": "United States"
  },
  {
    "id": 101276,
    "name": "Frye Electronics Inc    ( Tigard OR USA )",
    "country": "United States"
  },
  {
    "id": 281468,
    "name": "Fryka-Kaeltetechnik GmbH    ( Esslingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458168,
    "name": "FS Industries    ( Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 332174,
    "name": "FSI North America    ( Sheffield Lake OH USA )",
    "country": "United States"
  },
  {
    "id": 338061,
    "name": "FTM Mauritius Ltd    ( Pailles  Mauritius )",
    "country": "Mauritius"
  },
  {
    "id": 452064,
    "name": "FTS Systems Inc    ( Stone Ridge NY USA )",
    "country": "United States"
  },
  {
    "id": 455883,
    "name": "Fu Shun Hsing Technology Co Ltd    ( Tainan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 174096,
    "name": "Fu-Undo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455728,
    "name": "Fudakang Industrial Co Ltd    ( Dongguan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 284915,
    "name": "Fuhrmann Verbandstoffe GmbH    ( Much  Germany )",
    "country": "Germany"
  },
  {
    "id": 455742,
    "name": "Fuji Dynamics Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 174048,
    "name": "Fuji Koki Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 287844,
    "name": "Fuji Latex Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 199543,
    "name": "Fuji Medical Systems Co Ltd (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 199544,
    "name": "Fuji Photo Film (Europa) GmbH    ( Duesseldorf 11  Germany )",
    "country": "Germany"
  },
  {
    "id": 162280,
    "name": "Fuji Photo Film Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 358505,
    "name": "Fuji Respiratory Care Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 456812,
    "name": "Fuji Respironics Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 139628,
    "name": "Fuji Systems Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 290905,
    "name": "Fujian Dongfang Millimeter Wave    ( Fuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456877,
    "name": "FUJIFILM (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 453687,
    "name": "FUJIFILM Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453689,
    "name": "FUJIFILM Europe GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 105698,
    "name": "FujiFilm Medical Systems USA Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 458180,
    "name": "FUJIFILM SonoSite Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 287428,
    "name": "Fujinon (Europe) GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 102260,
    "name": "Fujinon Inc    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 452307,
    "name": "Fujirebio Diagnosstics (Italy)    ( Cigliano (VC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452211,
    "name": "Fujirebio Diagnostics Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 287857,
    "name": "Fujirebio Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 287866,
    "name": "Fujita Medical Instruments Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 174097,
    "name": "Fujitec Ltd    ( Fujisawa  Japan )",
    "country": "Japan"
  },
  {
    "id": 233667,
    "name": "Fujitsu Computer Products of America Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 287868,
    "name": "Fujitsu Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 138380,
    "name": "Fukuda Denshi Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 264073,
    "name": "Fukuda Denshi Europe    ( Fehraltorf  USA )",
    "country": "United States"
  },
  {
    "id": 324748,
    "name": "Fukuda Denshi UK    ( Old Woking  England )",
    "country": "UK"
  },
  {
    "id": 105044,
    "name": "Fukuda Denshi USA Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 139505,
    "name": "Fukuda M-E Kogyo Co Ltd    ( Nagareyama  Japan )",
    "country": "Japan"
  },
  {
    "id": 152415,
    "name": "Fukuda Sangyo Co Ltd    ( Nagareyama  Japan )",
    "country": "Japan"
  },
  {
    "id": 157922,
    "name": "Fukugawa Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456562,
    "name": "Fulcrum Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 467207,
    "name": "Full Support Healthcare    ( Wellingborough  England )",
    "country": "UK"
  },
  {
    "id": 428422,
    "name": "Full Vision Inc    ( Newton KS USA )",
    "country": "United States"
  },
  {
    "id": 102600,
    "name": "Fuller Ultra-Violet Corp    ( Frankfort IL USA )",
    "country": "United States"
  },
  {
    "id": 455731,
    "name": "Fullstar Non-Woven Products Co Ltd    ( Wuhan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453966,
    "name": "Fumetec Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 238898,
    "name": "Funeralia GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 168492,
    "name": "Furrer Roentgentechnik    ( Sempach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174796,
    "name": "Fused Kontacts Inc    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 458052,
    "name": "Fusion Fluid Equipment LLC    ( Whitehall MI USA )",
    "country": "United States"
  },
  {
    "id": 465104,
    "name": "Fusion Interiors    ( Allen 81 USA )",
    "country": "United States"
  },
  {
    "id": 464082,
    "name": "Fusion Mesotherapy    ( Vilafont  Spain )",
    "country": "Spain"
  },
  {
    "id": 106789,
    "name": "Futrex/Zelcore    ( Hagerstown MD USA )",
    "country": "United States"
  },
  {
    "id": 459082,
    "name": "Futura Mobility    ( Fort Washington PA USA )",
    "country": "United States"
  },
  {
    "id": 462718,
    "name": "Future Diagnostics bv    ( Wijchen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 185914,
    "name": "Future Health Concepts    ( Sanford FL USA )",
    "country": "United States"
  },
  {
    "id": 450626,
    "name": "Future Medical Laser Services INc    ( Novelty OH USA )",
    "country": "United States"
  },
  {
    "id": 362228,
    "name": "Future Medical Systems France    ( Saint Jeannet  USA )",
    "country": "United States"
  },
  {
    "id": 322346,
    "name": "Future Medical Systems Inc    ( Glen Burnie MD USA )",
    "country": "United States"
  },
  {
    "id": 407052,
    "name": "Future Medical Systems International    ( Geneve/Meyrin  USA )",
    "country": "United States"
  },
  {
    "id": 458548,
    "name": "Future Mobility Healthcare Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 105786,
    "name": "Futuremed America Inc    ( Granada Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 402723,
    "name": "FutureNet Technologies Corp    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 290907,
    "name": "Fuzhou Mei-sheng Medical Equipment Co Ltd    ( Fuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 393198,
    "name": "Fuzhou Nipro Co Ltd    ( Fuzhou  USA )",
    "country": "United States"
  },
  {
    "id": 322365,
    "name": "FW Company Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 287440,
    "name": "G & G Medizinische Pflege- und Therapiegeraete Gmb    ( Wilnsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 454353,
    "name": "G & J Electronics Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 176231,
    "name": "G & M Industries Inc    ( Collinsville IL USA )",
    "country": "United States"
  },
  {
    "id": 293871,
    "name": "G & M Objekteinrichtungen GmbH    ( Bielefeld  USA )",
    "country": "United States"
  },
  {
    "id": 293769,
    "name": "G & P Electronics AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452508,
    "name": "G & S Smirthwaite Ltd    ( Newton Abbot  England )",
    "country": "UK"
  },
  {
    "id": 287582,
    "name": "G A Storz Inh Dipl -Ing (Fh) Ulrich Binder Medizin    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105749,
    "name": "G E Miller Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 144509,
    "name": "G E Walker Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 398302,
    "name": "G H Zeal Medical Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 107325,
    "name": "G Hartzell & Son    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 282393,
    "name": "G Heinemann Medizintechnik GmbH    ( Kaltenkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284877,
    "name": "G Hipp & Sohn    ( Neuhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287111,
    "name": "G Hornberger GmbH    ( Waldfischbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 451751,
    "name": "G Kisker GbR    ( Steinfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 407178,
    "name": "G L Bowron    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 322893,
    "name": "G L Bowron & Co Ltd    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 192834,
    "name": "G Rodenstock Instrumente GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 227243,
    "name": "G Suchatzki    ( Rennerod  Germany )",
    "country": "Germany"
  },
  {
    "id": 303716,
    "name": "G Surgiwear Ltd    ( Shajahanpur  India )",
    "country": "India"
  },
  {
    "id": 452758,
    "name": "G Weinmann AG    ( Neuenhof  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458628,
    "name": "g.tec medical engineering GmbH    ( Schiedlberg  Austria )",
    "country": "Austria"
  },
  {
    "id": 458451,
    "name": "G2 Automated Technologies LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 458252,
    "name": "GA Braun Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 461147,
    "name": "Gabbert Acoustical    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 286719,
    "name": "Gabele Pinzetten    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 192820,
    "name": "Gabler Medical (UK) Ltd    ( Colchester  England )",
    "country": "UK"
  },
  {
    "id": 453557,
    "name": "Gabmed GmbH    ( Nettetal  Germany )",
    "country": "Germany"
  },
  {
    "id": 286430,
    "name": "Gabriel Medical GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 398028,
    "name": "Gabriele Fritz Medizintechnik    ( Hausen  USA )",
    "country": "United States"
  },
  {
    "id": 184524,
    "name": "GAC International Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 157534,
    "name": "GAC-Hospital Supply AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 152367,
    "name": "Gaeltec Ltd    ( Isle of Skye  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 283580,
    "name": "Gaetano Di Napoli    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 999835,
    "name": "Gagne, Inc.    ( Johnson City NY USA )",
    "country": "United States"
  },
  {
    "id": 461026,
    "name": "GAIA Software LLC    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 469528,
    "name": "GAKO International GmbH    ( Munich  Germany )",
    "country": "Germany"
  },
  {
    "id": 172277,
    "name": "Galai Production Ltd    ( Migdal Ha'Emek  USA )",
    "country": "United States"
  },
  {
    "id": 999513,
    "name": "Galanz Group    ( Foshan City  China )",
    "country": "China"
  },
  {
    "id": 454694,
    "name": "Galaxy Medical Equipments & Instruments Co Ltd    ( Zhuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 108016,
    "name": "Galaxy Medical Mfg Co    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 453362,
    "name": "Galaxy Salon Furniture & Equipment    ( City of Commerce CA USA )",
    "country": "United States"
  },
  {
    "id": 295042,
    "name": "GaleMed Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 460997,
    "name": "Galen Healthcare Solutions Inc    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 367423,
    "name": "Galil Medical    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 370898,
    "name": "Galil Medical (Israel)    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 454175,
    "name": "Galil Medical UK Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 199547,
    "name": "Galileo Industrie Ottiche SpA    ( Marghera (VE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 176265,
    "name": "Galix Biomedical Instrumentation Inc    ( Miami Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 175710,
    "name": "Galls Inc    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 435531,
    "name": "Galmed    ( Bydgoszcz  Poland )",
    "country": "Poland"
  },
  {
    "id": 409891,
    "name": "Galt Medical Corp    ( Garland TX USA )",
    "country": "United States"
  },
  {
    "id": 176851,
    "name": "Galtrade srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 458910,
    "name": "Galvatron Inc    ( Maitland FL USA )",
    "country": "United States"
  },
  {
    "id": 105926,
    "name": "Galveston Medical Mfg    ( Santa Fe TX USA )",
    "country": "United States"
  },
  {
    "id": 103450,
    "name": "Gam Industries Inc    ( Jesup GA USA )",
    "country": "United States"
  },
  {
    "id": 185299,
    "name": "Gama as    ( Ceske Budejovice  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 174737,
    "name": "GAMA Electronics Inc    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 452902,
    "name": "Gama Sanitas Technologies srl    ( Gravina di Catania (CT)  Italy )",
    "country": "Italy"
  },
  {
    "id": 102023,
    "name": "Garden State Rescue Products    ( Shrewsbury NJ USA )",
    "country": "United States"
  },
  {
    "id": 462628,
    "name": "Gamatronic Electronic Industries Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 139296,
    "name": "Gambro AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 374112,
    "name": "Gambro BCT Europe NV    ( Zaventem  USA )",
    "country": "United States"
  },
  {
    "id": 374111,
    "name": "Gambro BCT Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 374113,
    "name": "Gambro BCT Ltd    ( Quedgeley  USA )",
    "country": "United States"
  },
  {
    "id": 374116,
    "name": "Gambro BCT Zwegniederlassung de Gambro GmbH    ( Martinsried  USA )",
    "country": "United States"
  },
  {
    "id": 384288,
    "name": "Gambro Dasco SpA    ( Parma  Italy )",
    "country": "Italy"
  },
  {
    "id": 455619,
    "name": "Gambro Dasco SpA    ( Medolla (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454376,
    "name": "Gambro Hospal GmbH    ( Martinsreid  Germany )",
    "country": "Germany"
  },
  {
    "id": 418672,
    "name": "Gambro Hospal Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 376394,
    "name": "Gambro Inc (Canada)    ( St Leonard PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 307284,
    "name": "Gambro Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 287972,
    "name": "Gambro KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 191335,
    "name": "Gambro Ltd    ( Sidcup  USA )",
    "country": "United States"
  },
  {
    "id": 462944,
    "name": "Gambro Lundia AB    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 455683,
    "name": "Gambro Medical Sales (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 305714,
    "name": "Gambro Pty Ltd    ( Baulkham Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 372119,
    "name": "Gambro Renal Products    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 454374,
    "name": "Gambro/Hospal Schweiz AG    ( Kilchberg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 468427,
    "name": "Game Bike Fitness    ( Forney TX USA )",
    "country": "United States"
  },
  {
    "id": 286721,
    "name": "Gamed Medizintechnik    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451935,
    "name": "Gamewell-FCI Inc    ( Northford CT USA )",
    "country": "United States"
  },
  {
    "id": 331262,
    "name": "Gamma Endoscopy    ( Grants Pass OR USA )",
    "country": "United States"
  },
  {
    "id": 370899,
    "name": "Gamma Medica Inc    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 455360,
    "name": "Gamma Medica Inc    ( Sherbrooke PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 185940,
    "name": "Gamma Medical Equipment Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 460972,
    "name": "Gamma Scientific    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 236428,
    "name": "Gamma Technical Corp    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 283868,
    "name": "Gamma-Service Produktbestrahlung GmbH    ( Radeberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458492,
    "name": "GammaSys Solutions Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 162372,
    "name": "Gammex rmi    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 161986,
    "name": "Gammex RMI GmbH    ( Giessen-Allendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 182920,
    "name": "Gammex RMI Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 459120,
    "name": "Gander LLC    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 306621,
    "name": "Gandus Saldatrici snc di G Guglielmini & Co    ( Cornaredo (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 158409,
    "name": "Ganshorn Medizin Electronic GmbH    ( Niederlauer  Germany )",
    "country": "Germany"
  },
  {
    "id": 285233,
    "name": "GAP Etikettierer & Drucksysteme GmbH    ( Oldenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 162970,
    "name": "Gapim Ltd    ( Tel Hashomer  Israel )",
    "country": "Israel"
  },
  {
    "id": 279481,
    "name": "Garantol Products Unternehmensbereich    ( Greven  Germany )",
    "country": "Germany"
  },
  {
    "id": 105047,
    "name": "Garaventa Accessibility    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 459326,
    "name": "Gardall Safe Corp    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 461000,
    "name": "Garden & Assoc Inc    ( St Louis MN USA )",
    "country": "United States"
  },
  {
    "id": 341040,
    "name": "Gardner Denver Inc    ( Quincy IL USA )",
    "country": "United States"
  },
  {
    "id": 463049,
    "name": "Gardner Denver Thomas GmbH    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 459098,
    "name": "Garland Commercial Ranges Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459100,
    "name": "Garrett Callahan Company    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 454113,
    "name": "Gas Control Equipment    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 402504,
    "name": "Gast Hong Kong    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 323985,
    "name": "Gast Mfg Ltd Unit IDEX Corp    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 106736,
    "name": "Gast Mfg Unit IDEX Corp    ( Benton Harbor MI USA )",
    "country": "United States"
  },
  {
    "id": 279760,
    "name": "Gastrock-Stoecke GmbH    ( Bad Sooden-Allendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 185400,
    "name": "Gastromasz Osrodek Bad-Rozw Urzadzen Clodn Gastron    ( Bydgoszcz  Poland )",
    "country": "Poland"
  },
  {
    "id": 450523,
    "name": "Gatan France    ( Evry Cedex  France )",
    "country": "France"
  },
  {
    "id": 450520,
    "name": "Gatan GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 289513,
    "name": "Gatan Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 450521,
    "name": "Gatan Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450522,
    "name": "Gatan UK    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 456501,
    "name": "GATE Rehab Development    ( Smalandsstenar  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454571,
    "name": "Gateway Electronic Medical Management Systems    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 452217,
    "name": "Gateway Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 455355,
    "name": "Gateway Medical Inc    ( Mooresville NC USA )",
    "country": "United States"
  },
  {
    "id": 317358,
    "name": "Gaum Inc    ( Robbinsville NJ USA )",
    "country": "United States"
  },
  {
    "id": 104186,
    "name": "Gaumard Scientific Co Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 280412,
    "name": "Gaus GmbH    ( Bovenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 451290,
    "name": "Gauthier Biomedical Inc    ( Grafton WI USA )",
    "country": "United States"
  },
  {
    "id": 409560,
    "name": "Gayatri House    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 461003,
    "name": "Gaylord Bros    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 376585,
    "name": "Gaylord Chemical Corp    ( Slidell LA USA )",
    "country": "United States"
  },
  {
    "id": 101281,
    "name": "Gaymar Industries Inc    ( Orchard Park NY USA )",
    "country": "United States"
  },
  {
    "id": 108495,
    "name": "GBC Scientific Equipment Inc    ( Hampshire IL USA )",
    "country": "United States"
  },
  {
    "id": 139347,
    "name": "GBC Scientific Equipment Pty Ltd    ( Braeside  Australia )",
    "country": "Australia"
  },
  {
    "id": 176264,
    "name": "GBI International Corp    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 454960,
    "name": "GBL Wheelchair Services Ltd    ( Hanworth  England )",
    "country": "UK"
  },
  {
    "id": 306392,
    "name": "gbo Geraetebau Odenwald AG    ( Grasellenbach  USA )",
    "country": "United States"
  },
  {
    "id": 440166,
    "name": "gbo Medizintechnik AG    ( Rimbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 408240,
    "name": "GBS Computer Solutions    ( Youngstown OH USA )",
    "country": "United States"
  },
  {
    "id": 285304,
    "name": "GBS Vertriebs-GmbH    ( Lupburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455376,
    "name": "GBUK Healthcare    ( Selby  England )",
    "country": "UK"
  },
  {
    "id": 151384,
    "name": "GC America    ( Alsip IL USA )",
    "country": "United States"
  },
  {
    "id": 454709,
    "name": "GCA America    ( Alsip IL USA )",
    "country": "United States"
  },
  {
    "id": 919911,
    "name": "GCA/PRESCISION SCIENTIFIC    (   USA )",
    "country": "United States"
  },
  {
    "id": 407260,
    "name": "GCL Trading Inc    ( Quezon City  USA )",
    "country": "United States"
  },
  {
    "id": 455729,
    "name": "GCMEDICA Enterprise Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 102812,
    "name": "GCX Corp    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 466821,
    "name": "GE Analytical Instruments    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 999993,
    "name": "GE Appliance    (   USA )",
    "country": "United States"
  },
  {
    "id": 458637,
    "name": "GE Appliances    ( Fairfield CT USA )",
    "country": "United States"
  },
  {
    "id": 440767,
    "name": "GE Healthcare    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 454147,
    "name": "GE Healthcare    ( Wessling  Germany )",
    "country": "Germany"
  },
  {
    "id": 401905,
    "name": "GE Healthcare (Hong Kong)    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452805,
    "name": "GE Healthcare AS Oslo    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 300443,
    "name": "GE Healthcare Asia (Japan)    ( Hino  Japan )",
    "country": "Japan"
  },
  {
    "id": 441444,
    "name": "GE Healthcare Australia    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 451408,
    "name": "GE Healthcare Bio-Sciences AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451410,
    "name": "GE Healthcare Bio-Sciences KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451011,
    "name": "GE Healthcare Bio-Sciences UK    ( Little Chalfont  England )",
    "country": "UK"
  },
  {
    "id": 451012,
    "name": "GE Healthcare Bio-Sciences USA    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 451728,
    "name": "GE Healthcare Buchler GmbH & Co KG    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 456809,
    "name": "GE Healthcare BVBA    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452809,
    "name": "GE Healthcare Clinical Systems Devices (Netherland    ( Hoevelaken  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452807,
    "name": "GE Healthcare Clinical Systems Devices (UK)    ( Hatfield  England )",
    "country": "UK"
  },
  {
    "id": 454443,
    "name": "GE Healthcare Clinical Systems Devices (USA)    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 458096,
    "name": "GE Healthcare Clinical Systems SLU    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 171319,
    "name": "GE Healthcare Europe    ( Velizy Cedex  France )",
    "country": "France"
  },
  {
    "id": 451409,
    "name": "GE Healthcare Europe GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452810,
    "name": "GE Healthcare Information Technologies AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455413,
    "name": "GE Healthcare IT    ( Dornstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 399259,
    "name": "GE Healthcare Italia SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457004,
    "name": "GE Healthcare Japan Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457005,
    "name": "GE Healthcare Life Sciences    ( Little Chalfont  England )",
    "country": "UK"
  },
  {
    "id": 372110,
    "name": "GE Healthcare OEC Medical Systems    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 340559,
    "name": "GE Healthcare South Africa    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 401910,
    "name": "GE Healthcare Spain SA    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 453503,
    "name": "GE Healthcare Surgery    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 393584,
    "name": "GE Healthcare Technologies    ( Jupiter FL USA )",
    "country": "United States"
  },
  {
    "id": 440964,
    "name": "GE Healthcare Technologies    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 441470,
    "name": "GE Healthcare Technologies    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 441471,
    "name": "GE Healthcare Technologies    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 441472,
    "name": "GE Healthcare Technologies    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 441270,
    "name": "GE Healthcare Technologies (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452811,
    "name": "GE Healthcare Technologies Clinical Systems (Finla    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 440972,
    "name": "GE Healthcare Technologies GmbH    ( Dornstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 450874,
    "name": "GE Healthcare Technologies IT (Finland)    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 441445,
    "name": "GE Healthcare Technologies New Zealand    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 401906,
    "name": "GE Healthcare UK    ( Little Chalfont  England )",
    "country": "UK"
  },
  {
    "id": 439946,
    "name": "GE Healthcare USA    ( Wauwatosa WI USA )",
    "country": "United States"
  },
  {
    "id": 451988,
    "name": "GE HealthcareTechnologies Surgery Navigaition    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 456807,
    "name": "GE HomeCare Systems    ( Herrsching  Germany )",
    "country": "Germany"
  },
  {
    "id": 418119,
    "name": "GE Infrastructure Sensing    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 450904,
    "name": "GE Inspection Technologies LP    ( Lewistown PA USA )",
    "country": "United States"
  },
  {
    "id": 458884,
    "name": "GE Kaye Instruments    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 454453,
    "name": "GE Osmonics    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 455414,
    "name": "GE Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 441787,
    "name": "GE Security Industrial    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 454369,
    "name": "GE Sensing & Inspection Technologies    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 406407,
    "name": "GE Ultraschall Deutschland GmbH & Co KG    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 428606,
    "name": "GE Water Technologies    ( Trevose PA USA )",
    "country": "United States"
  },
  {
    "id": 183063,
    "name": "GE Yokogawa Medical Systems Ltd    ( Hino  Japan )",
    "country": "Japan"
  },
  {
    "id": 459289,
    "name": "GEA Niro Soavi    ( Parma PR  Italy )",
    "country": "Italy"
  },
  {
    "id": 361938,
    "name": "Gebauer Co    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 453702,
    "name": "Geberit Balena AG    ( Buetschwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 348430,
    "name": "Gebr Becker GmbH & Co    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 283906,
    "name": "Gebr Brasseler GmbH & Co KG    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 284271,
    "name": "Gebr Buddeberg GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 287266,
    "name": "Gebr Buexenstein KG    ( Werdohl  Germany )",
    "country": "Germany"
  },
  {
    "id": 283910,
    "name": "Gebr Koch Lederwaren und Schuerzenfabrik    ( Lemgo  USA )",
    "country": "United States"
  },
  {
    "id": 280268,
    "name": "Gebr Liebisch Fabrik fuer Laborapparate    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 285399,
    "name": "Gebr Seuster GmbH & Co KG Etiketten    ( Plettenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 153131,
    "name": "Gebrueder Bense Laborbau GmbH    ( Hardegsen 5/Gladebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 159755,
    "name": "Gebrueder Martin GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161005,
    "name": "Gebrueder Thonet GmbH    ( Frankenberg (Eder)  Germany )",
    "country": "Germany"
  },
  {
    "id": 399762,
    "name": "GEC Avery Zimbabwe (Pvt) Ltd    ( Harare  USA )",
    "country": "United States"
  },
  {
    "id": 347627,
    "name": "Geddis Inc    ( Dunedin FL USA )",
    "country": "United States"
  },
  {
    "id": 999437,
    "name": "Geek Squad    (   USA )",
    "country": "United States"
  },
  {
    "id": 199746,
    "name": "Geen Healthcare Inc    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 106790,
    "name": "Geerpres Inc    ( Muskegon MI USA )",
    "country": "United States"
  },
  {
    "id": 255868,
    "name": "Geers Hoerakustik GmbH & Co KG    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 999621,
    "name": "Gefen, LLC    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 280243,
    "name": "GEFO Folienbetrieb GmbH    ( Gera  Germany )",
    "country": "Germany"
  },
  {
    "id": 349199,
    "name": "Geiger Medical Technologies Inc    ( Council Bluffs IA USA )",
    "country": "United States"
  },
  {
    "id": 281515,
    "name": "Geimuplast Kunststofftechnik GmbH & Co KG    ( Farchant  Germany )",
    "country": "Germany"
  },
  {
    "id": 293302,
    "name": "Geissmann Papier AG    ( Dottikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 403463,
    "name": "Geister Inc    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 178240,
    "name": "Geister Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279868,
    "name": "Geka-Brush GmbH    ( Bechhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282969,
    "name": "Gela GmbH Gehoer- Laerm- und Arbeitsschutz    ( Heusenstamm  Germany )",
    "country": "Germany"
  },
  {
    "id": 417199,
    "name": "Gelflex (Israel) Contact Lenses Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 306192,
    "name": "Gelflex Laboratories    ( Osborne Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 417198,
    "name": "Gelflex USA    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 452559,
    "name": "Gelita Medical bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 280339,
    "name": "Gellrich GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 102429,
    "name": "Gem Scientific Inc Div Gem Refrigerator Co    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 426492,
    "name": "Gem Sprinkler    ( Oak Creek WI USA )",
    "country": "United States"
  },
  {
    "id": 457904,
    "name": "GEM Srl    ( Viareggio (LU)  Italy )",
    "country": "Italy"
  },
  {
    "id": 331095,
    "name": "GEM Water Systems International LLC    ( Buena Park CA USA )",
    "country": "United States"
  },
  {
    "id": 456538,
    "name": "Gem-Med Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 456537,
    "name": "Gem-Med SL    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 402706,
    "name": "Gemco Medical Inc    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 455019,
    "name": "GEMMS USA    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 455884,
    "name": "Gemmy Industrial Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 416198,
    "name": "GemTech Medical    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 178135,
    "name": "Gen Trak Inc    ( Liberty NC USA )",
    "country": "United States"
  },
  {
    "id": 999908,
    "name": "Gen-Med    (   USA )",
    "country": "United States"
  },
  {
    "id": 457952,
    "name": "Gen-Probe GTI Diagnostics Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 107603,
    "name": "Gen-Probe Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454700,
    "name": "Gen-Probe Life Sciences Ltd    ( Livingston  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 468410,
    "name": "GenCell Biosystems Ltd    ( Limerick  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 467368,
    "name": "GenCell USA LLC    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 328368,
    "name": "Gendex Dental System    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 454515,
    "name": "Gendex Dental Systems    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 101284,
    "name": "Gendron Inc    ( Bryan OH USA )",
    "country": "United States"
  },
  {
    "id": 461004,
    "name": "GenDx    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 306010,
    "name": "Gene Co Ltd    ( Chai Wan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 471735,
    "name": "GeneDX    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 471703,
    "name": "Genelex Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 357212,
    "name": "Genemax Medical Products Industry Corp    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 352423,
    "name": "Genentech Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 457183,
    "name": "GENEQ Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999510,
    "name": "Generac Power Systems    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 467311,
    "name": "Generac Power Systems Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 457105,
    "name": "General Anesthetic Services Inc    ( South Park PA USA )",
    "country": "United States"
  },
  {
    "id": 174870,
    "name": "General Automation Inc    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 102431,
    "name": "General Bandages Inc    ( Morton Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 106041,
    "name": "General Biomedical Service Inc    ( Kenner LA USA )",
    "country": "United States"
  },
  {
    "id": 108989,
    "name": "General Cardiac Technology    ( Los Gatos CA USA )",
    "country": "United States"
  },
  {
    "id": 454877,
    "name": "General Data Co Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 107265,
    "name": "General Dental Products Inc    ( Ely NV USA )",
    "country": "United States"
  },
  {
    "id": 104868,
    "name": "General Devices    ( Ridgefield NJ USA )",
    "country": "United States"
  },
  {
    "id": 431630,
    "name": "General Dynamics Canada    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103223,
    "name": "General Econopak Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 431419,
    "name": "General Electric Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 401909,
    "name": "General Electric GmbH (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 455885,
    "name": "General Life Biotechnology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 104005,
    "name": "General Medical Co    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 454678,
    "name": "General Medical Devices (Belgium)    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454676,
    "name": "General Medical Devices (USA)    ( Gig Harbor WA USA )",
    "country": "United States"
  },
  {
    "id": 177062,
    "name": "General Medical Merate SpA    ( Seriate (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455739,
    "name": "General Meditech Inc    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 307077,
    "name": "General Optical Co (Cape) (Pty) Ltd    ( Johannesburg  USA )",
    "country": "United States"
  },
  {
    "id": 101286,
    "name": "General Physiotherapy Inc    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 103064,
    "name": "General Scanning Inc Recorder Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 366845,
    "name": "General Scientific Corp    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 105929,
    "name": "General Scientific Safety Equipment Co    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 999906,
    "name": "General Signal    (   USA )",
    "country": "United States"
  },
  {
    "id": 464953,
    "name": "General Tools & Instruments    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 458253,
    "name": "General Water Technologies Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 402034,
    "name": "Generation II (NZ) Ltd    ( Hamilton East  USA )",
    "country": "United States"
  },
  {
    "id": 362031,
    "name": "Generation II Orthotics Inc    ( Richmond BC USA )",
    "country": "United States"
  },
  {
    "id": 184006,
    "name": "Generation II USA Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 342148,
    "name": "Generra Medical Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 198984,
    "name": "Genesee BioMedical Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 999878,
    "name": "Genesis Air, Inc.    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 176236,
    "name": "Genesis Bio-Pharmaceuticals Inc    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 456995,
    "name": "Genesis Biosystems    ( Lewisville TX USA )",
    "country": "United States"
  },
  {
    "id": 455066,
    "name": "Genesis BPS    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 455067,
    "name": "Genesis BPS International Sarl    ( Nyon 2  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458694,
    "name": "Genesis Digital Imaging Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 428443,
    "name": "Genesis Durable Medical Equipment    ( Waycross GA USA )",
    "country": "United States"
  },
  {
    "id": 446569,
    "name": "Genesis Magnet Services LLC    ( Huntley IL USA )",
    "country": "United States"
  },
  {
    "id": 454518,
    "name": "Genesis Medical Imaging Inc    ( Huntley IL USA )",
    "country": "United States"
  },
  {
    "id": 452193,
    "name": "Genesis Medical Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 288431,
    "name": "Genesys    ( Methuen MA USA )",
    "country": "United States"
  },
  {
    "id": 457023,
    "name": "Genesys Spine    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 454601,
    "name": "GenesysMD    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458358,
    "name": "Genetec Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 471622,
    "name": "Genetic Testing Institute    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 455594,
    "name": "Genetix Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 377424,
    "name": "Genetix Ltd    ( New Milton  England )",
    "country": "UK"
  },
  {
    "id": 320906,
    "name": "Geneva Dental Inc    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 459050,
    "name": "Geneva Scientific LLC    ( Fontana WI USA )",
    "country": "United States"
  },
  {
    "id": 459266,
    "name": "Genevac Inc    ( Stone Ridge NY USA )",
    "country": "United States"
  },
  {
    "id": 455871,
    "name": "Genexel-Medical Instrument Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457979,
    "name": "Genial Genetics    ( Chester  England )",
    "country": "UK"
  },
  {
    "id": 366514,
    "name": "GENICON    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 441995,
    "name": "GeniCon Europe Ltd    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 451576,
    "name": "GeniCon Latin America    ( Pereira  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 451577,
    "name": "GeniCon Pacific Rim    ( Crescent  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 441870,
    "name": "GeniCon UK    ( Chiswick  England )",
    "country": "UK"
  },
  {
    "id": 457404,
    "name": "Genii Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 158410,
    "name": "Genitron Instruments GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 455550,
    "name": "GenMark Diagnostics Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 454511,
    "name": "GENOMA 1005 Ltd    ( Bogota  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 455467,
    "name": "Genomic Health Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458813,
    "name": "Genomic Solutions Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 471702,
    "name": "Genomind    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 459095,
    "name": "Genoptix Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 456358,
    "name": "GENORAY Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457608,
    "name": "Genpak LLC    ( Glens Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 458572,
    "name": "Genstar Technologies Co Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 999795,
    "name": "Genstar Technologies Company Inc.    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 999794,
    "name": "Gentec (Shanghai) Corporation    ( Shanghai  China )",
    "country": "China"
  },
  {
    "id": 105712,
    "name": "Gentec Electro-Optics Inc    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 272826,
    "name": "Gentell Inc    ( Trevose PA USA )",
    "country": "United States"
  },
  {
    "id": 462630,
    "name": "Gentera LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 441565,
    "name": "Gentex Corp    ( Simpson PA USA )",
    "country": "United States"
  },
  {
    "id": 451169,
    "name": "Gentra Systems Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 103041,
    "name": "Gentran    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 465164,
    "name": "Genus Technologies, LLC    ( Minneapolis 66 USA )",
    "country": "United States"
  },
  {
    "id": 451957,
    "name": "GenWay Biotech Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 348921,
    "name": "Genx International/IVFonline LLC    ( Guilford CT USA )",
    "country": "United States"
  },
  {
    "id": 391887,
    "name": "Genzyme Biosurgery    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 392556,
    "name": "Genzyme Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 321027,
    "name": "Genzyme Diagnostics    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 334722,
    "name": "Genzyme Diagnostics UK    ( West Malling  England )",
    "country": "UK"
  },
  {
    "id": 392565,
    "name": "Genzyme do Brazil Ltd    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 354568,
    "name": "Genzyme GmbH    ( Nue-Isenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 392559,
    "name": "Genzyme Israel Ltd    ( Kfar Saba  Israel )",
    "country": "Israel"
  },
  {
    "id": 392560,
    "name": "Genzyme Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 392561,
    "name": "Genzyme Ltd    ( Malling  England )",
    "country": "UK"
  },
  {
    "id": 392568,
    "name": "Genzyme Netherlands    ( Naarden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392550,
    "name": "Genzyme SA    ( Cergy-Pontoise Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 392562,
    "name": "Genzyme SL    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 392558,
    "name": "Genzyme srl    ( Modena (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 392569,
    "name": "Genzyme Sweden    ( Kobenhaven  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 392546,
    "name": "Genzyme Virotech GmbH    ( Ruesselsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 101583,
    "name": "Geo Otis Co    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 451441,
    "name": "GEO TEC Inc    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 416391,
    "name": "Geodesic MediTech Inc    ( Rancho Santa Fe CA USA )",
    "country": "United States"
  },
  {
    "id": 456578,
    "name": "Geomed Medizin-Technik GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 441569,
    "name": "Geomet Technologies, LLC    ( Germantown MD USA )",
    "country": "United States"
  },
  {
    "id": 102432,
    "name": "Geometric Data Corp A SmithKline Beckman Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 460923,
    "name": "GeoMetrix Data Systems Inc    ( Victoria BC Canada )",
    "country": "Canada"
  },
  {
    "id": 455886,
    "name": "Geon Corp    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 285704,
    "name": "Georg Alber GmbH & Co KG    ( Renquishausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 158422,
    "name": "Georg Grimm GmbH    ( Nusplingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452554,
    "name": "Georg Kramer Ges mbH & Co KG    ( Pasching  Austria )",
    "country": "Austria"
  },
  {
    "id": 284537,
    "name": "Georg Leibinger    ( Muehlheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 282395,
    "name": "Georg Luehrs GmbH & Co KG    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 285959,
    "name": "Georg Schick Dental-GmbH    ( Schemmerhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287001,
    "name": "Georg Schmerler GmbH & Co KG    ( Veitsbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 285246,
    "name": "Georg Strick & Soehne    ( Osnabrueck  USA )",
    "country": "United States"
  },
  {
    "id": 101122,
    "name": "George C Bishop Co    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 101287,
    "name": "George Glove Co Inc    ( Midland Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 460924,
    "name": "George K Moss Co Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 169581,
    "name": "George King Bio-Medical Inc    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 999834,
    "name": "George Percy McGown    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 107489,
    "name": "George Taub Products & Fusion Co Inc    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 108906,
    "name": "George Tiemann Co    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 144519,
    "name": "Georgia BioMedical Inc    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 453059,
    "name": "Georgia Eye Bank    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 356458,
    "name": "Geotest-Marvin Test Systems Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 462949,
    "name": "Gepe-Geimuplast GmbH    ( Farchant  Germany )",
    "country": "Germany"
  },
  {
    "id": 177700,
    "name": "Gepin SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 177662,
    "name": "Gera-Filtest SA/NV    ( Nivelles  USA )",
    "country": "United States"
  },
  {
    "id": 282006,
    "name": "Geraberger Thermometerwerk GmbH    ( Geschwenda  Germany )",
    "country": "Germany"
  },
  {
    "id": 999861,
    "name": "Gerald Industries Incorporated    ( Gerald MO USA )",
    "country": "United States"
  },
  {
    "id": 451723,
    "name": "Gerald Simonds Healthcare Ltd    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 262893,
    "name": "Gerard Industries Pty Ltd    ( Bowden  USA )",
    "country": "United States"
  },
  {
    "id": 456813,
    "name": "Geratherm Medical AG    ( Geschwenda  Germany )",
    "country": "Germany"
  },
  {
    "id": 187025,
    "name": "Gerber Chair Mates Inc    ( Johnstown PA USA )",
    "country": "United States"
  },
  {
    "id": 456603,
    "name": "Gerber Condylator Service    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 334306,
    "name": "Gerber Instruments AG    ( Effretikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105436,
    "name": "Gerber Products Co    ( Fremont MI USA )",
    "country": "United States"
  },
  {
    "id": 106851,
    "name": "Gereonics Inc    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 280608,
    "name": "Gerhard Biro Medizinische Apparate    ( Burladingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160798,
    "name": "Gerhard Menzel    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 285264,
    "name": "Gerhard Nolden KG Dentalfabrik und Metallgiesserei    ( Osterode  Germany )",
    "country": "Germany"
  },
  {
    "id": 328058,
    "name": "Gerhardt Optik und Feinmechanik GmbH    ( Naumburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 103633,
    "name": "Geri-Care Products    ( Moonachie NJ USA )",
    "country": "United States"
  },
  {
    "id": 374054,
    "name": "Geritrex Corp    ( Mount Vernon NY USA )",
    "country": "United States"
  },
  {
    "id": 279723,
    "name": "Gerking GmbH    ( Bad Oldesloe  Germany )",
    "country": "Germany"
  },
  {
    "id": 337734,
    "name": "Germaine Laboratories Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 457103,
    "name": "German Electronics Co    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 283759,
    "name": "Germania Geraetebau- und Vertriebs-GmbH    ( Landau-Pfalz  Germany )",
    "country": "Germany"
  },
  {
    "id": 102467,
    "name": "Germfree Laboratories Inc    ( Ormond Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 174608,
    "name": "Germiphene Corp    ( Brantford ON Canada )",
    "country": "Canada"
  },
  {
    "id": 158411,
    "name": "Gerstel GmbH    ( Muelheim am Ruhr 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 376089,
    "name": "Gestion Techno-Medic Inc    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 454480,
    "name": "GestureTek    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 283431,
    "name": "Gesundheitszentrum Nord P Green GmbH    ( Kiel  USA )",
    "country": "United States"
  },
  {
    "id": 377774,
    "name": "GETC Asia Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 382201,
    "name": "getemed Medizin und Informationtechnik AG    ( Teltow  Germany )",
    "country": "Germany"
  },
  {
    "id": 139388,
    "name": "Getinge AB    ( Getinge  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 193768,
    "name": "Getinge Australia Pty Ltd    ( Bulimba  Australia )",
    "country": "Australia"
  },
  {
    "id": 334386,
    "name": "Getinge Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455075,
    "name": "Getinge Lancer SAS    ( Tournefeuille  France )",
    "country": "France"
  },
  {
    "id": 439205,
    "name": "Getinge Surgical Systems Italia SpA    ( Cardano al Campo VA  USA )",
    "country": "United States"
  },
  {
    "id": 439186,
    "name": "Getinge Surgical Systems UK    ( Sutton-in-Ashfield  England )",
    "country": "UK"
  },
  {
    "id": 417559,
    "name": "Getinge USA Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 286382,
    "name": "Getinge van Dijk Medizintechnik GmbH    ( Rastatt  Germany )",
    "country": "Germany"
  },
  {
    "id": 107593,
    "name": "Gettig Technologies Inc    ( Spring Mills PA USA )",
    "country": "United States"
  },
  {
    "id": 452411,
    "name": "GetWellNetwork Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 271359,
    "name": "Getz Brothers Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 138332,
    "name": "Geuder GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 398104,
    "name": "Geva Elettronica    ( Almese (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 441994,
    "name": "GF Health Products Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 283433,
    "name": "GFA Medizintechnik GmbH    ( Kiel  USA )",
    "country": "United States"
  },
  {
    "id": 460925,
    "name": "GFI Software    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 280600,
    "name": "GFL Gesellschaft fuer Labortechnik mbH    ( Burgwedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 199566,
    "name": "GFMD Ltd    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 451346,
    "name": "GFS Chemicals Inc    ( Powell OH USA )",
    "country": "United States"
  },
  {
    "id": 282823,
    "name": "GFW mbH    ( Heidenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 273852,
    "name": "GG & B Co    ( Wichita Falls TX USA )",
    "country": "United States"
  },
  {
    "id": 461031,
    "name": "GGB Medical Management Services Inc    ( Chatworth CA USA )",
    "country": "United States"
  },
  {
    "id": 455707,
    "name": "Ghatwary Medical Supply    ( Alexandria  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 457800,
    "name": "GHC group LLC    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 416464,
    "name": "Ghia Surgiblades Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 457013,
    "name": "GHIWELL Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456020,
    "name": "gi Dynamics Inc    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 339203,
    "name": "GI Supply Inc    ( Camp Hill PA USA )",
    "country": "United States"
  },
  {
    "id": 331096,
    "name": "GIA Medical    ( River Hills WI USA )",
    "country": "United States"
  },
  {
    "id": 187952,
    "name": "Giacomo Bertocchi Co    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 151077,
    "name": "Giacomo Bertocchi SNC    ( Cremona CR  USA )",
    "country": "United States"
  },
  {
    "id": 108269,
    "name": "Giant Lift Equipment Mfg Co Inc    ( North Hampton NH USA )",
    "country": "United States"
  },
  {
    "id": 455872,
    "name": "GIANT STAR    ( Yangju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999919,
    "name": "Gibson    (   USA )",
    "country": "United States"
  },
  {
    "id": 170951,
    "name": "Gibson Laboratories    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 184263,
    "name": "Gierth GmbH    ( Riesa  Germany )",
    "country": "Germany"
  },
  {
    "id": 451555,
    "name": "Gieser Lab    ( Colingdale PA USA )",
    "country": "United States"
  },
  {
    "id": 452861,
    "name": "Gift of Hope Organ & Tissue Donor Network    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 291066,
    "name": "Gifyt SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 439640,
    "name": "Gigahertz-Optik GmbH    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 439643,
    "name": "Gigahertz-Optik Inc    ( Newburyport MA USA )",
    "country": "United States"
  },
  {
    "id": 174377,
    "name": "Gigante Recem Nascido ICR Ltda    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 248695,
    "name": "GII Orthotics Europe NV    ( Brussel  USA )",
    "country": "United States"
  },
  {
    "id": 174100,
    "name": "Giken Kogyo Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 384298,
    "name": "Gil Medical Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 176853,
    "name": "Gilardoni SpA    ( Mandello del Lario (LC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 356462,
    "name": "Gilbert H Cosnett and Assoc    ( Haddonfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 199679,
    "name": "Gilbert Johnson and Assoc    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 451986,
    "name": "Giles Scientific Inc    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 451170,
    "name": "Gill Podiatry and Equipment Co    ( Strongsville OH USA )",
    "country": "United States"
  },
  {
    "id": 330862,
    "name": "Gillen Industries    ( The Villages FL USA )",
    "country": "United States"
  },
  {
    "id": 306813,
    "name": "Gillespie's Hire & Sales Service    ( Artarmon  Australia )",
    "country": "Australia"
  },
  {
    "id": 150956,
    "name": "Gillett & Sibert Ltd    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 102108,
    "name": "Gilmore Liquid Air Co    ( South El Monte CA USA )",
    "country": "United States"
  },
  {
    "id": 458153,
    "name": "Gilmore-Kramer Co    ( Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 459327,
    "name": "GILRAS LLC    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 178866,
    "name": "Gilson Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 329953,
    "name": "Gilson International bv    ( Den Haag  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 175025,
    "name": "Gilson SAS    ( Villiers-le-Bel  France )",
    "country": "France"
  },
  {
    "id": 345887,
    "name": "GIMA SpA    ( Gessate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 158415,
    "name": "GIMMI GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306412,
    "name": "Ginevri srl    ( Albano Laziale (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 105389,
    "name": "Gingi-Pak    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 456333,
    "name": "Ginova UK Ltd    ( Maidstone  England )",
    "country": "UK"
  },
  {
    "id": 332039,
    "name": "Gio de Vita E C srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 283514,
    "name": "GIRARDELLI Dental-Medizinische Produkte    ( Schemmerhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285353,
    "name": "Girrbach Dental GmbH    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 459053,
    "name": "Girton Mfg Co Inc    ( Millville PA USA )",
    "country": "United States"
  },
  {
    "id": 103774,
    "name": "Gish Biomedical Inc    ( Rancho Santa Margarita CA USA )",
    "country": "United States"
  },
  {
    "id": 408296,
    "name": "Given France SAS    ( Maisons-Laffitte  France )",
    "country": "France"
  },
  {
    "id": 463021,
    "name": "Ideomed    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 457610,
    "name": "Given Imaging France SAS    ( Maisons-Laffitte  France )",
    "country": "France"
  },
  {
    "id": 401835,
    "name": "Given Imaging GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 416197,
    "name": "Given Imaging Iberica    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 391192,
    "name": "Given Imaging Inc    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 391191,
    "name": "Given Imaging Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 391193,
    "name": "Given Imaging Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 285215,
    "name": "Gizeh-Werk Dispoform GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282049,
    "name": "GKE-mbH    ( Waldems  Germany )",
    "country": "Germany"
  },
  {
    "id": 137097,
    "name": "GKR Industries Inc    ( Crestwood IL USA )",
    "country": "United States"
  },
  {
    "id": 999783,
    "name": "Glacial    (   USA )",
    "country": "United States"
  },
  {
    "id": 187027,
    "name": "Glacier Cross Inc    ( Kalispell MT USA )",
    "country": "United States"
  },
  {
    "id": 457672,
    "name": "Glamox ASA    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 102758,
    "name": "Glas-Col LLC    ( Terre Haute IN USA )",
    "country": "United States"
  },
  {
    "id": 281984,
    "name": "Glaser & Co GmbH    ( Germering  Germany )",
    "country": "Germany"
  },
  {
    "id": 458062,
    "name": "Glasir Medical    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 293645,
    "name": "Glasmechanik AG    ( Therwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 463006,
    "name": "Glaukos Corp    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 397888,
    "name": "GlaxoSmithKline Inc (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458315,
    "name": "GlaxoSmithKline Inc (United Kingdom)    ( Brentford  England )",
    "country": "UK"
  },
  {
    "id": 394965,
    "name": "GlaxoSmithKline Inc (United States)    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 453207,
    "name": "Glen Dimplex Professional Appliances    ( Merseyside  England )",
    "country": "UK"
  },
  {
    "id": 459069,
    "name": "Glen Mills Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 187028,
    "name": "Glenn Medical Systems Inc    ( Canton OH USA )",
    "country": "United States"
  },
  {
    "id": 337735,
    "name": "Glenroe Technologies    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 454630,
    "name": "Glenview Health Systems    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 454572,
    "name": "Glenwood Systems LLC    ( Waterbury CT USA )",
    "country": "United States"
  },
  {
    "id": 332712,
    "name": "Glidewell Laboratories    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 461039,
    "name": "Global Care Quest Inc    ( El Segundo CA USA )",
    "country": "United States"
  },
  {
    "id": 461043,
    "name": "Global Compliance Services Ltd    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 464101,
    "name": "Global Components Medical Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 331019,
    "name": "Global Concepts Inc    ( Rochester MI USA )",
    "country": "United States"
  },
  {
    "id": 462706,
    "name": "Global Cooling Inc    ( Athens OH USA )",
    "country": "United States"
  },
  {
    "id": 450562,
    "name": "Global Device Management LLC    ( Duluth MN USA )",
    "country": "United States"
  },
  {
    "id": 441461,
    "name": "Global Dosimetry Solutions Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 458919,
    "name": "Global Equipment Co Inc    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 458710,
    "name": "Global Focus Marketing & Distribution Ltd    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 999696,
    "name": "Global Focus Marketing & Distribution Ltd.    (   USA )",
    "country": "United States"
  },
  {
    "id": 187029,
    "name": "Global Healthcare    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 458364,
    "name": "Global Healthcare Exchange LLC    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 462676,
    "name": "Global Healthcare Products Inc    ( Quezon City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 455625,
    "name": "Global Inventory Management LLC    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 402018,
    "name": "Global Med Technologies Inc    ( El Dorado Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 455431,
    "name": "Global Medical Imaging Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 431351,
    "name": "Global Medical Instrumentation Inc    ( Ramsey MN USA )",
    "country": "United States"
  },
  {
    "id": 171722,
    "name": "Global Medical Products Inc (Canada)    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108675,
    "name": "Global Power Systems    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 394987,
    "name": "Global Products Corp Orthopaedics Div    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 453892,
    "name": "Global Protection    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 452702,
    "name": "Global Protection Corp    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 441572,
    "name": "Global Protection LLC    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 471678,
    "name": "Global Risk Services    ( Warrenville IL USA )",
    "country": "United States"
  },
  {
    "id": 462909,
    "name": "Global Services Group LLC    ( Williamsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 475973,
    "name": "Global Specialties    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 457107,
    "name": "Global Star Medical    ( Texarkana AR USA )",
    "country": "United States"
  },
  {
    "id": 224626,
    "name": "Global Surgical Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 466225,
    "name": "Global Technical Systems, Inc.    ( Jacksonville 22 USA )",
    "country": "United States"
  },
  {
    "id": 455939,
    "name": "Global Technologies    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 459104,
    "name": "Global Upholstery Co Inc    ( Marlton NJ USA )",
    "country": "United States"
  },
  {
    "id": 461047,
    "name": "Global USA    ( Marlton NJ USA )",
    "country": "United States"
  },
  {
    "id": 452163,
    "name": "Global Work Systems Inc    ( Colchester VT USA )",
    "country": "United States"
  },
  {
    "id": 179086,
    "name": "GlobalCare Medical    ( Boynton Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 371669,
    "name": "GlobalMed Inc    ( Trenton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 169602,
    "name": "Globe Scientific Inc    ( Paramus NJ USA )",
    "country": "United States"
  },
  {
    "id": 460721,
    "name": "GlobeStar Systems Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453711,
    "name": "GlobTek Inc    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 285676,
    "name": "Globus Karl Kremendahl Orthopaedische Erzeugnisse    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 458417,
    "name": "Globus Medical Inc    ( Audubon PA USA )",
    "country": "United States"
  },
  {
    "id": 306766,
    "name": "Gloria Maglieria Elastica srl    ( Menaggio (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451703,
    "name": "Gloriamed    ( Menaggio  USA )",
    "country": "United States"
  },
  {
    "id": 455887,
    "name": "Glorious Union Meditech Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 461054,
    "name": "Glory Global Solutions    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 454573,
    "name": "gloStream Inc    ( Bloomfield Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 347527,
    "name": "Glottal Enterprises Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 282649,
    "name": "Glove-Box Ganuk GmbH    ( Hammersbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 471695,
    "name": "Gloves By Web    ( Beulah MI USA )",
    "country": "United States"
  },
  {
    "id": 453429,
    "name": "Gloves Online Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 459493,
    "name": "Glow Research    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 457190,
    "name": "Glow Technology    ( Loudon TN USA )",
    "country": "United States"
  },
  {
    "id": 453099,
    "name": "GlucoTec Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 282087,
    "name": "Gluecksband Roth GmbH & Co    ( Goeppingen-Ursenwang  Germany )",
    "country": "Germany"
  },
  {
    "id": 458574,
    "name": "Glytec LLC    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 177196,
    "name": "GM Instruments Ltd    ( Kilwinning  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 454574,
    "name": "gMed Inc    ( Weston FL USA )",
    "country": "United States"
  },
  {
    "id": 457761,
    "name": "GMI Inc    ( Ramsey MN USA )",
    "country": "United States"
  },
  {
    "id": 108901,
    "name": "GMP Labeling Inc    ( Granite Bay CA USA )",
    "country": "United States"
  },
  {
    "id": 364494,
    "name": "GMP Mfg Co Inc    ( Bridgeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 458076,
    "name": "GMP SA    ( Renens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 471698,
    "name": "GMSbiotech Inc    ( Inverness IL USA )",
    "country": "United States"
  },
  {
    "id": 283689,
    "name": "GN Danavox GmbH    ( Krefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 391083,
    "name": "GN Otometrics A/S    ( Taastrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 391084,
    "name": "GN Otometrics GmbH & Co KG    ( Neckartenzlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 426355,
    "name": "GN Otometrics North America Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 163334,
    "name": "GN Resound AS    ( Ballerup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 456938,
    "name": "GN ReSound GmbH    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 378242,
    "name": "GN ReSound North America    ( Bloomington MN USA )",
    "country": "United States"
  },
  {
    "id": 174378,
    "name": "Gnatus Equipamentos Medico-Odontologicos Ltda    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 451171,
    "name": "GNR Health Systems Inc    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 455525,
    "name": "GNS Assoc Pty Ltd    ( Malvern  Australia )",
    "country": "Australia"
  },
  {
    "id": 156990,
    "name": "GO Medical Industries Pty Ltd    ( Subiaco  Australia )",
    "country": "Australia"
  },
  {
    "id": 457106,
    "name": "GO-Med    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 158417,
    "name": "Goessner GmbH & Co KG    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 458539,
    "name": "Gold Standard Diagnostics    ( Davis CA USA )",
    "country": "United States"
  },
  {
    "id": 456598,
    "name": "Gold Violin    ( Jessup PA USA )",
    "country": "United States"
  },
  {
    "id": 451172,
    "name": "Golda Inc    ( Beachwood OH USA )",
    "country": "United States"
  },
  {
    "id": 462955,
    "name": "Goldburs USA    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 451842,
    "name": "Golden Hour Medical    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 176243,
    "name": "Golden Ratio Woodworks    ( Emigrant MT USA )",
    "country": "United States"
  },
  {
    "id": 459068,
    "name": "Golden Sab Inc    ( North Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 108169,
    "name": "Golden Technologies Inc    ( Old Forge PA USA )",
    "country": "United States"
  },
  {
    "id": 455740,
    "name": "Goldsite Diagnostics Inc    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999472,
    "name": "Goldsmith & Revere    ( Englewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 999201,
    "name": "Goldstar Distributors    ( Richardson TX USA )",
    "country": "USA"
  },
  {
    "id": 286315,
    "name": "Goldstein & Lewin Technology GmbH    ( Stahnsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 426654,
    "name": "Goldway US Inc    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 284394,
    "name": "Golf Apparatebau Industrielackierungen OHG    ( Melle  Germany )",
    "country": "Germany"
  },
  {
    "id": 331269,
    "name": "GoMac Inc    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 156991,
    "name": "Gonotec GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 461061,
    "name": "Good Technology    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 102262,
    "name": "Good-Lite Co    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 184428,
    "name": "Goodman Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 456724,
    "name": "Goodrum International    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 392335,
    "name": "Goodtime Medical    ( Wright City MO USA )",
    "country": "United States"
  },
  {
    "id": 289395,
    "name": "Goodwin Biotechnology Inc    ( Plantation FL USA )",
    "country": "United States"
  },
  {
    "id": 340382,
    "name": "Goodwin Mfg Inc    ( High Point NC USA )",
    "country": "United States"
  },
  {
    "id": 331024,
    "name": "Goodyear X-ray Co    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 457579,
    "name": "Google Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 281551,
    "name": "Gopas Computersysteme Floeha GmbH    ( Floeha  Germany )",
    "country": "Germany"
  },
  {
    "id": 471697,
    "name": "GoPath Laboratories LLC    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 465106,
    "name": "Gopher Medical, Inc.    ( Watertown 72 USA )",
    "country": "United States"
  },
  {
    "id": 331025,
    "name": "Gordhandas Desai Pvt Ltd    ( Baroda  India )",
    "country": "India"
  },
  {
    "id": 458540,
    "name": "Gordinier Electronics Inc    ( Roseville MI USA )",
    "country": "United States"
  },
  {
    "id": 999701,
    "name": "Gordinier Electronics, Inc.    ( Roseville MI USA )",
    "country": "USA"
  },
  {
    "id": 455269,
    "name": "Gordon Ellis & Co    ( Derby  England )",
    "country": "UK"
  },
  {
    "id": 104734,
    "name": "Gordon Instruments Inc    ( Orchard Park NY USA )",
    "country": "United States"
  },
  {
    "id": 273232,
    "name": "Gordon N Stowe and Assoc Inc    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 457722,
    "name": "Gordon Systems Inc    ( DeWitt NY USA )",
    "country": "United States"
  },
  {
    "id": 452198,
    "name": "Gordon Visual Solutions    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 306443,
    "name": "Gordon's Limited    ( Auckland  USA )",
    "country": "United States"
  },
  {
    "id": 282399,
    "name": "Gormesa GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 306757,
    "name": "Gothaplast Verbandpflasterfabrik GmbH    ( Gotha  Germany )",
    "country": "Germany"
  },
  {
    "id": 107270,
    "name": "Gottfried Medical Inc    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 286729,
    "name": "Gotthard Enslin Augen-Mikro-Instrumente    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 172279,
    "name": "Gottlieb Transportation Systems    ( Bnei Brak  Israel )",
    "country": "Israel"
  },
  {
    "id": 461063,
    "name": "Gould & Lamb    ( Bradentown FL USA )",
    "country": "United States"
  },
  {
    "id": 178233,
    "name": "Gould Instrument Systems UK    ( Ilford  England )",
    "country": "UK"
  },
  {
    "id": 174611,
    "name": "Gow Trainer Ltd    ( Barrie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 105453,
    "name": "GOW-MAC Instrument Co    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 391544,
    "name": "GOW-MAC Instrument Co    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 179245,
    "name": "GOW-MAC Instrument Co Ireland    ( Shannon  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 163045,
    "name": "Gowllands Ltd    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 453423,
    "name": "GP Instruments    ( Hamilton Square NJ USA )",
    "country": "United States"
  },
  {
    "id": 455395,
    "name": "GP Supplies Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 457881,
    "name": "GPC Medical Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 455647,
    "name": "GPK Inc    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 452315,
    "name": "Grace Davison Discovery Sciences    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 453832,
    "name": "Grace Davison Discovery Sciences    ( Rowville  Australia )",
    "country": "Australia"
  },
  {
    "id": 455138,
    "name": "Grace Davison Discovery Sciences - France    ( Templemars  France )",
    "country": "France"
  },
  {
    "id": 459036,
    "name": "Grace Medical Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 402615,
    "name": "Grace Vydac    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 382754,
    "name": "GRAETZ Strahlungsmesstechnik GmbH    ( Altena  Germany )",
    "country": "Germany"
  },
  {
    "id": 457706,
    "name": "Graftys    ( Aix en Provence Cedex 3  France )",
    "country": "France"
  },
  {
    "id": 102590,
    "name": "Graham Medical Products    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 452101,
    "name": "GraLab    ( Centerville OH USA )",
    "country": "United States"
  },
  {
    "id": 280839,
    "name": "Gramm Technik Gramm GmbH & Co Kg    ( Tiefenbronn/Muehlhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 152597,
    "name": "Grams Medical    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 106714,
    "name": "Granberg International    ( Vallejo CA USA )",
    "country": "United States"
  },
  {
    "id": 176556,
    "name": "Grand Medical Products    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 277941,
    "name": "Grand Seiko Co Ltd    ( Hiroshima  Japan )",
    "country": "Japan"
  },
  {
    "id": 106302,
    "name": "Grand X-Ray Supplies Co    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 455743,
    "name": "Grandway Healthcare Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 102433,
    "name": "Grant Healing Environment    ( Vanderbilt MI USA )",
    "country": "United States"
  },
  {
    "id": 324039,
    "name": "Grant Instruments (Cambridge) Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 129387,
    "name": "Graphic Controls Canada Ltd    ( Ganaoque ON Canada )",
    "country": "Canada"
  },
  {
    "id": 102109,
    "name": "Graphic Controls LLC    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 457678,
    "name": "Graphic Controls Ltd    ( Totnes  England )",
    "country": "UK"
  },
  {
    "id": 106791,
    "name": "Graphic Systems Inc    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 140045,
    "name": "Graphtec Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 102263,
    "name": "Grason-Stadler    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 283912,
    "name": "Grass GmbH    ( Bad Kreuznach  Germany )",
    "country": "Germany"
  },
  {
    "id": 999769,
    "name": "Grass Technologies    ( West Warwick RI USA )",
    "country": "USA"
  },
  {
    "id": 454438,
    "name": "Grass Valley (Australia)    ( Notting Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 454439,
    "name": "Grass Valley (UK) Ltd    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 454440,
    "name": "Grass Valley France SA    ( Boulogne-Billancourt  France )",
    "country": "France"
  },
  {
    "id": 454442,
    "name": "Grass Valley USA    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 371767,
    "name": "Grass-Telefactor    ( West Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 392804,
    "name": "Grass-Telefactor (Netherlands)    ( Zwolle  USA )",
    "country": "United States"
  },
  {
    "id": 168496,
    "name": "Grauba AG Basle    ( Muenchenstein  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457445,
    "name": "Gravity Defyer    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 451176,
    "name": "Grayco Systems Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 457709,
    "name": "Great Basin Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 454167,
    "name": "Great Bear Healthcare Ltd    ( Cardiff  Wales )",
    "country": "Wales"
  },
  {
    "id": 151373,
    "name": "Great Lakes Filter    ( Hillsdale MI USA )",
    "country": "United States"
  },
  {
    "id": 458842,
    "name": "Great Lakes Health Information Exchange    ( East Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 426209,
    "name": "Great Lakes Orthodontics Ltd    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 467191,
    "name": "Great Openings    ( Ludington MI USA )",
    "country": "United States"
  },
  {
    "id": 108191,
    "name": "Great Plains Ballistics Inc    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 453417,
    "name": "Greatbatch Inc    ( Clarence NY USA )",
    "country": "United States"
  },
  {
    "id": 452903,
    "name": "Greatcare International Trade Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452991,
    "name": "Green Dental Laboratory    ( Heber Springs AR USA )",
    "country": "United States"
  },
  {
    "id": 464993,
    "name": "Green Light Courier Inc    ( Pennellville NY USA )",
    "country": "United States"
  },
  {
    "id": 277942,
    "name": "Green Medix Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 298729,
    "name": "Green Tek    ( Edgerton WI USA )",
    "country": "United States"
  },
  {
    "id": 293024,
    "name": "Greene Tweed & Co Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 175717,
    "name": "GreenField Medical Sourcing Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 455417,
    "name": "Greenstone LLC    ( Peapack NJ USA )",
    "country": "United States"
  },
  {
    "id": 102178,
    "name": "Greenwald Surgical Co Inc    ( Lake Station IN USA )",
    "country": "United States"
  },
  {
    "id": 454148,
    "name": "Greenway Medical Technologies Inc    ( Carrollton GA USA )",
    "country": "United States"
  },
  {
    "id": 107271,
    "name": "Greenwich Instrument Co Inc    ( Greenwich CT USA )",
    "country": "United States"
  },
  {
    "id": 370467,
    "name": "Greer Laboratories Inc    ( Lenoir NC USA )",
    "country": "United States"
  },
  {
    "id": 171163,
    "name": "Greggersen Gasetechnik GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 461070,
    "name": "Gregory Poole Equipment Co    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 415334,
    "name": "Greiner Bio-One bv    ( Alphen aan den Rijn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 415336,
    "name": "Greiner Bio-One Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 397870,
    "name": "Greiner Bio-One GmbH (Austria)    ( Kremsmuenster  Austria )",
    "country": "Austria"
  },
  {
    "id": 281799,
    "name": "Greiner Bio-One GmbH (Deutschland)    ( Frickenhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 397869,
    "name": "Greiner Bio-One Inc    ( Monroe NC USA )",
    "country": "United States"
  },
  {
    "id": 415335,
    "name": "Greiner Bio-One Ltd    ( Stonehouse  England )",
    "country": "UK"
  },
  {
    "id": 415338,
    "name": "Greiner Bio-One NV    ( Wemmel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415333,
    "name": "Greiner Bio-One Sarl    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 283914,
    "name": "Greiner-Glasinstrumente GmbH    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 415337,
    "name": "Greiner-Muenyagtechnika Kft    ( Mosonmagyarovar  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 107272,
    "name": "Gresham Driving Aids Inc    ( Wixom MI USA )",
    "country": "United States"
  },
  {
    "id": 321020,
    "name": "GretagMacbeth LLC    ( New Windsor NY USA )",
    "country": "United States"
  },
  {
    "id": 308506,
    "name": "Gridline AB    ( Spanga  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 287324,
    "name": "Griep-West Behindertentechnik    ( Westerstede  Germany )",
    "country": "Germany"
  },
  {
    "id": 293559,
    "name": "Grieshaber & Co AG    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 158421,
    "name": "Grieshaber GmbH    ( Muellheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454387,
    "name": "Griffen Medical Products    ( Bridgeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 333663,
    "name": "Griffes Co Ltd    ( Limassol  Cyprus )",
    "country": "Cyprus"
  },
  {
    "id": 999612,
    "name": "Griffin Laboratories    ( Temecula CA USA )",
    "country": "USA"
  },
  {
    "id": 322963,
    "name": "Griffin Medical Products Inc    ( Bridgeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 183886,
    "name": "Griffith Rubber Mills    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 431336,
    "name": "Grifols USA Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 374369,
    "name": "Grifols-Quest Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 107017,
    "name": "Grimm Scientific Industries    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 103776,
    "name": "Grobet USA    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 227258,
    "name": "Grobet USA    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 282947,
    "name": "Grom Analytik & HPLC-GmbH    ( Herrenberg  USA )",
    "country": "United States"
  },
  {
    "id": 456900,
    "name": "Groman Inc    ( Margate FL USA )",
    "country": "United States"
  },
  {
    "id": 999729,
    "name": "Grommes Precision    ( Gurnee IL USA )",
    "country": "USA"
  },
  {
    "id": 283748,
    "name": "Gronert Ultra X-Laborgeraete GmbH & Co Kg    ( Lage  Germany )",
    "country": "Germany"
  },
  {
    "id": 459105,
    "name": "Ground Support Equipment (US) Ltd    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 279178,
    "name": "Groupe Lepine    ( Genay  France )",
    "country": "France"
  },
  {
    "id": 452109,
    "name": "Groupone Healthsource    ( Jefferson City MO USA )",
    "country": "United States"
  },
  {
    "id": 431330,
    "name": "Growth Curves Ab Oy Ltd    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 439586,
    "name": "Growth Curves USA    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 307073,
    "name": "GRS Medical (Pty) Ltd    ( Johahnesburg  USA )",
    "country": "United States"
  },
  {
    "id": 407162,
    "name": "Gruenberg Oven Co    ( White Deer PA USA )",
    "country": "United States"
  },
  {
    "id": 456928,
    "name": "Gruendler GmbH    ( Freudenstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284433,
    "name": "Grunella-Service Enzian-Werk GmbH & Co KG    ( Metzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 416534,
    "name": "Gruppo Urciuoli    ( Campania (AV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 331276,
    "name": "Gryphon Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 161008,
    "name": "GS Elektromedizinische Geraete G Stemple GmbH    ( Kaufering  Germany )",
    "country": "Germany"
  },
  {
    "id": 453422,
    "name": "Guided Therapeutics Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 372430,
    "name": "GS Medical Packaging Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461072,
    "name": "GS Security Systems    ( Tewksbury MA USA )",
    "country": "United States"
  },
  {
    "id": 453522,
    "name": "GS Yuasa International Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 158430,
    "name": "GSG Mess- und Analysengeraete Vertriebsgesellschaf    ( Bruchsal  Germany )",
    "country": "Germany"
  },
  {
    "id": 170871,
    "name": "GSI Medical Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459328,
    "name": "GSL Solutions Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 452647,
    "name": "gSource LLC    ( Emerson NJ USA )",
    "country": "United States"
  },
  {
    "id": 466422,
    "name": "GT Products LLC    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 106657,
    "name": "GTE Electrical Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 454034,
    "name": "GTI Diagnostics    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 457187,
    "name": "GTI Graphic Technology Inc    ( Newburgh NY USA )",
    "country": "United States"
  },
  {
    "id": 384436,
    "name": "GTR Labs Inc    ( Gassaway WV USA )",
    "country": "United States"
  },
  {
    "id": 456199,
    "name": "Guangdong Biolight Co Ltd    ( Zhuhai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451355,
    "name": "Guangdong Institute of Ultrasonic Instruments    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455770,
    "name": "Guangdong Kaiyang Medical Technology Co Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455773,
    "name": "Guangdong Yuehua Medical Instrument Factory Co Ltd    ( Shantou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456200,
    "name": "Guangzhou Bio Medical Instruments Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456940,
    "name": "Guangzhou Bioseal Biotech    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455775,
    "name": "Guangzhou Clean Medical Products Mfg Corp    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456648,
    "name": "Guangzhou Fenghua BioEngineering Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455780,
    "name": "Guangzhou Huaxi Medical Science Technology Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454642,
    "name": "Guard RFID Solutions Inc    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 461074,
    "name": "Guardian CSC    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 322964,
    "name": "Guardian Electronics Inc    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 428182,
    "name": "Guardian Laboratories    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 154411,
    "name": "Guardian Medical Products Ltd    ( Derby  USA )",
    "country": "United States"
  },
  {
    "id": 459004,
    "name": "GuardianOR Inc    ( Davidson NC USA )",
    "country": "United States"
  },
  {
    "id": 101297,
    "name": "Gudebrod Inc    ( Pottstown PA USA )",
    "country": "United States"
  },
  {
    "id": 153141,
    "name": "Guenter Blach Roentgentechnik-Elektromedizin    ( Assamstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 282158,
    "name": "Guenter Calate Geriatrische Versorgungs- und    ( Grevenbroich  Germany )",
    "country": "Germany"
  },
  {
    "id": 285217,
    "name": "Guenter Schaefer Kunststofftechnik GmbH    ( Ortenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 160101,
    "name": "Guenter Schuster Medizinische Systeme GmbH    ( Forchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 287592,
    "name": "Guenter Stoffel Insto Qualitaetsinstrumente    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286917,
    "name": "Guenther Heim Geraetebau GmbH    ( Uelzen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452687,
    "name": "Guerbet (France)    ( Villepinte  France )",
    "country": "France"
  },
  {
    "id": 454231,
    "name": "Guerbet Laboratories Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 454232,
    "name": "Guerbet LLC    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 158431,
    "name": "Guerra Lanpade Speciali    ( Carmagnola (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 139482,
    "name": "Guest Medical Ltd    ( Larkfield  England )",
    "country": "UK"
  },
  {
    "id": 309753,
    "name": "Guido Rayos X SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 428450,
    "name": "Guilford Pharmaceuticals Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 291083,
    "name": "Guilin Kangxing Laser Instrument Factory    ( Guilin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291658,
    "name": "Guilin Kangxing Laser Instrument Factory    ( Guilin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455781,
    "name": "Guilin Woodpecker Medical Instrument Co Ltd    ( Guilin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 293290,
    "name": "Gujer Meuli & Co    ( Dielsdorf  USA )",
    "country": "United States"
  },
  {
    "id": 178846,
    "name": "Gulden Ophthalmics    ( Elkins Park PA USA )",
    "country": "United States"
  },
  {
    "id": 440719,
    "name": "Guldmann AB    ( Alingsas  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 440721,
    "name": "Guldmann GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 440728,
    "name": "Guldmann Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 440723,
    "name": "Guldmann Sarl    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 440720,
    "name": "Guldmann UK    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 398977,
    "name": "Gulf Coast Hyperbarics Inc    ( Lynn Haven FL USA )",
    "country": "United States"
  },
  {
    "id": 456690,
    "name": "Gulf Fiberoptics Inc    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 999484,
    "name": "Gulf Medical    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 322965,
    "name": "Gulf South Medical Supply Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457098,
    "name": "Gulfstream Anesthesia Service Inc    ( Jupiter FL USA )",
    "country": "United States"
  },
  {
    "id": 322510,
    "name": "Gulmay Ltd    ( ByFleet  England )",
    "country": "UK"
  },
  {
    "id": 285936,
    "name": "Gummiwarenfabrik Tex GmbH    ( Sarstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 309607,
    "name": "Gummo Italia srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 107355,
    "name": "Gunnell Inc    ( Millington MI USA )",
    "country": "United States"
  },
  {
    "id": 451174,
    "name": "Gunther Weiss Scientific Glass Blowing Co    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 455783,
    "name": "Guoteng Science and Technology Development Co Ltd    ( Zhuhai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 282078,
    "name": "Gustav Blickle GmbH    ( Goeppingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279522,
    "name": "Gustav Bruns Maschinenbau und Foerderanlagen GmbH    ( Apen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280686,
    "name": "Gustav Dehler GmbH    ( Coburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 279815,
    "name": "Gustav Ehinger GmbH & Co    ( Balingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282922,
    "name": "Gustav Ernstmeier GmbH & Co KG    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 282933,
    "name": "Gustav Feix    ( Hermannsburg  USA )",
    "country": "United States"
  },
  {
    "id": 287273,
    "name": "Gustav Fleischer Jr Holzwarenfabrik    ( Wermelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280049,
    "name": "Gustav Kunze Jr GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 286733,
    "name": "Gustav Mueller GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285190,
    "name": "Gustav Pirazzi & Co Chirurgisches Nahtmaterial    ( Offenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 286200,
    "name": "Gustav Weck    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 361259,
    "name": "Guth Laboratories Inc    ( Harrisburg PA USA )",
    "country": "United States"
  },
  {
    "id": 158432,
    "name": "Gutmann Medizinelektronik    ( Eurasburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 278291,
    "name": "Gutmann Medizinelektronik    ( Eurasburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452706,
    "name": "Guyson International Ltd    ( Skipton  England )",
    "country": "UK"
  },
  {
    "id": 458359,
    "name": "GVI Medical Devices    ( Twinsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 293026,
    "name": "GVS    ( Zola Predosa (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 151487,
    "name": "GVS-NY    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 280724,
    "name": "GWH Offizin Gepraegte Form GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 322691,
    "name": "GWR Medical Inc    ( Chadds Ford PA USA )",
    "country": "United States"
  },
  {
    "id": 471761,
    "name": "Gym Source    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 431397,
    "name": "Gym Tech Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 394679,
    "name": "GymnaUniphy NV    ( Bilzen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456432,
    "name": "GYN Disposables Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 101300,
    "name": "GYNE-TECH INSTRUMENT CORP    (   USA )",
    "country": "United States"
  },
  {
    "id": 347573,
    "name": "Gynecare    (   USA )",
    "country": "United States"
  },
  {
    "id": 409854,
    "name": "Gynetics    ( Achel  USA )",
    "country": "United States"
  },
  {
    "id": 402761,
    "name": "Gynex Corp    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 461081,
    "name": "GyroTools LLC    ( Winterthur  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 402910,
    "name": "Gyrus ACMI    ( Bartlett TN USA )",
    "country": "United States"
  },
  {
    "id": 453065,
    "name": "Gyrus ACMI Australasia Pty Ltd    ( Port Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 397725,
    "name": "Gyrus ACMI Cyromedics    ( Southborough MA USA )",
    "country": "United States"
  },
  {
    "id": 451297,
    "name": "Gyrus Australasia Pty Ltd    ( Port Melbourne  USA )",
    "country": "United States"
  },
  {
    "id": 453066,
    "name": "Gyrus Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 362066,
    "name": "Gyrus Medical Ltd Div Gyrus Group plc    ( Cardiff  USA )",
    "country": "United States"
  },
  {
    "id": 306778,
    "name": "GZDS GmbH    ( Warendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 458113,
    "name": "H & H Medical Corp    ( Ordinary VA USA )",
    "country": "United States"
  },
  {
    "id": 461084,
    "name": "H & O Equipments Inc    ( Mt Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 158485,
    "name": "H & P Labortechnik AG    ( Oberschleissheim  USA )",
    "country": "United States"
  },
  {
    "id": 459058,
    "name": "H & R Healthcare    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 454022,
    "name": "H & S Medical    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 455572,
    "name": "H & W    ( Glabbeck  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457108,
    "name": "H + H System Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 393598,
    "name": "H B Domnick Hunter Equipamentos Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 287142,
    "name": "H Brinkhaus GmbH & Co KG    ( Warendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 345679,
    "name": "H Dam Kaergaard A/S    ( Farum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 153299,
    "name": "H Dennhoefer KG    ( Koeln 50  Germany )",
    "country": "Germany"
  },
  {
    "id": 323762,
    "name": "H Fereday & Sons Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 168493,
    "name": "H Giovanna SA    ( Clarens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 370416,
    "name": "H L Bouton Co    ( Wareham MA USA )",
    "country": "United States"
  },
  {
    "id": 105570,
    "name": "H L Lyons Co    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 174382,
    "name": "H Ozawa Industria e Comercio de Equip Cientificos    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 104408,
    "name": "H R Simon & Co Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 174383,
    "name": "H Strattner & Cia Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 266341,
    "name": "H W Andersen Products Ltd    ( Clacton-on-Sea  England )",
    "country": "UK"
  },
  {
    "id": 101851,
    "name": "H Weniger Inc    ( Brisbane CA USA )",
    "country": "United States"
  },
  {
    "id": 457160,
    "name": "H Wilson Co    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 457344,
    "name": "H-e-a-t Spa Kur Therapy Development Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 103460,
    "name": "H-P Products Inc    ( Louisville OH USA )",
    "country": "United States"
  },
  {
    "id": 285040,
    "name": "h/p/cosmos sports & medical gmbh    ( Nussdorf-Traunstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 456643,
    "name": "H&J Medical Devices Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452994,
    "name": "H&O Dental Laboratory    ( Manchester NH USA )",
    "country": "United States"
  },
  {
    "id": 999615,
    "name": "H&R Healthcare    ( Lakewood NJ USA )",
    "country": "USA"
  },
  {
    "id": 455873,
    "name": "H3 System Co Ltd    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 150028,
    "name": "Haag-Streit AG    ( Koeniz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 384292,
    "name": "Haag-Streit Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 454782,
    "name": "Haag-Streit Deutschland GmbH    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 105651,
    "name": "Haag-Streit Group USA    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 460629,
    "name": "Haag-Streit Surgical GmbH    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 293725,
    "name": "HAARI AG    ( Bachenbuelach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 158434,
    "name": "Haas & Marx GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 306619,
    "name": "Haberbosch GmbH    ( Schemmerhofen-Assmannshardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 467154,
    "name": "Habitat Ecologico    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 104444,
    "name": "Hach Co    ( Loveland CO USA )",
    "country": "United States"
  },
  {
    "id": 175294,
    "name": "Hach Europe    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 441526,
    "name": "Hach Ultra Analytics    ( Grants Pass OR USA )",
    "country": "United States"
  },
  {
    "id": 441527,
    "name": "Hach Ultra Analytics GmbH    ( Lich  Germany )",
    "country": "Germany"
  },
  {
    "id": 441528,
    "name": "Hach Ultra Analytics SAS    ( Trappes  France )",
    "country": "France"
  },
  {
    "id": 441557,
    "name": "Hach Ultra Analytics Switzerland    ( Vesenez  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 441530,
    "name": "Hach Ultra Analytics UK Ltd    ( Chesterfield  England )",
    "country": "UK"
  },
  {
    "id": 185960,
    "name": "Hackensack Dental Equipment    ( Fair Lawn NJ USA )",
    "country": "United States"
  },
  {
    "id": 102965,
    "name": "Hacker Instruments & Industries Inc    ( Winnsboro SC USA )",
    "country": "United States"
  },
  {
    "id": 287009,
    "name": "Haco Rollen- und Raederfabrik GmbH & Co KG    ( Velbert  Germany )",
    "country": "Germany"
  },
  {
    "id": 452712,
    "name": "Hadeco Inc    ( Kawasaki  Japan )",
    "country": "Japan"
  },
  {
    "id": 282695,
    "name": "Hadewe Hainhoelzer Dental-Werkstatt GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 416522,
    "name": "Hadfield Sign Co    ( Bankstown  Australia )",
    "country": "Australia"
  },
  {
    "id": 286434,
    "name": "Haeberle GmbH & Co KG    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 170901,
    "name": "Haemachem Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 253275,
    "name": "Haemacure Corp Canada    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 370350,
    "name": "Haemacure Corp USA    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 202156,
    "name": "HaeMedic AB    ( Munka Ljungby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 415933,
    "name": "HaeMedic USA    ( Wayzata MN USA )",
    "country": "United States"
  },
  {
    "id": 450581,
    "name": "Haemo-Lab Corp    ( Coral Gables FL USA )",
    "country": "United States"
  },
  {
    "id": 450580,
    "name": "Haemoclub International srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 194764,
    "name": "Haemonetics (Hong Kong) Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 183766,
    "name": "Haemonetics (UK) Ltd    ( Bothwell  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 399020,
    "name": "Haemonetics Asia    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 399012,
    "name": "Haemonetics Austria Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 399015,
    "name": "Haemonetics bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 102265,
    "name": "Haemonetics Corp    ( Braintree MA USA )",
    "country": "United States"
  },
  {
    "id": 399016,
    "name": "Haemonetics CZ spol s r o    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 399017,
    "name": "Haemonetics France Sarl    ( Plaisir  France )",
    "country": "France"
  },
  {
    "id": 399019,
    "name": "Haemonetics Germany GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 399013,
    "name": "Haemonetics Italia srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 183755,
    "name": "Haemonetics Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 183754,
    "name": "Haemonetics SA    ( Signy 2  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 399014,
    "name": "Haemonetics SA-NV    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 399018,
    "name": "Haemonetics Scandinavia AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 399021,
    "name": "Haemonetics Shanghai    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 102797,
    "name": "Haemoscope Corp    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 140870,
    "name": "Haemotronic Italy SpA    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459108,
    "name": "Haenel Gmbh & Co KG    ( Bad Friedrichshall  Germany )",
    "country": "Germany"
  },
  {
    "id": 459109,
    "name": "Haenel Storage Systems    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 452744,
    "name": "Haeng Lim Seo Won Acu Needle Co    (   Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 279805,
    "name": "Hafner Chirurgische Instrumente    ( Balgheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 285574,
    "name": "Hagas Sitzsysteme GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 286438,
    "name": "Hager & Elsaesser GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 281069,
    "name": "Hager & Meisinger GmbH    ( Neuss  Germany )",
    "country": "Germany"
  },
  {
    "id": 451954,
    "name": "Hager & Werken (Asia) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 280972,
    "name": "Hager & Werken GmbH & Co KG    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 334302,
    "name": "Hager Dental Vertrieb GmbH    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451953,
    "name": "Hager Polonia Sp z o o    ( Wroclaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 451952,
    "name": "Hager Worldwide Inc    ( Odessa FL USA )",
    "country": "United States"
  },
  {
    "id": 281020,
    "name": "Hages-Aufzuege GmbH & Co KG    ( Dueren  Germany )",
    "country": "Germany"
  },
  {
    "id": 185401,
    "name": "Hagmed    ( Rawa Mazowiecka  Poland )",
    "country": "Poland"
  },
  {
    "id": 394364,
    "name": "HAH Medical    ( Lone Tree CO USA )",
    "country": "United States"
  },
  {
    "id": 467313,
    "name": "Hahn-Kolb GmbH & Co    ( Ludwigsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 331099,
    "name": "Haidylena for Advanced Medical Industries    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 455708,
    "name": "Haidylena for Advanced Medical Industries    ( Giza  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 912399,
    "name": "Haier    (   USA )",
    "country": "United States"
  },
  {
    "id": 455798,
    "name": "Haier Medical and Laboratory Products Co Ltd    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 282269,
    "name": "Hailo-Werk Rudolf Loh GmbH & Co KG    ( Haiger  Germany )",
    "country": "Germany"
  },
  {
    "id": 454143,
    "name": "Hain Lifescience GmbH    ( Nehren  Germany )",
    "country": "Germany"
  },
  {
    "id": 455801,
    "name": "Haining Yikang Textile Co Ltd    ( Haining  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455804,
    "name": "Haiyan Kangyuan Medical Instrument Co Ltd    ( Haiyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456644,
    "name": "Haiying Enterprise Group Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 277943,
    "name": "Hakasui Trading Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 999434,
    "name": "Hako-Med Holdings, Inc    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 455931,
    "name": "Hakujuji Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 101301,
    "name": "Hal-Hen Co Inc    ( Garden City Park NY USA )",
    "country": "United States"
  },
  {
    "id": 462590,
    "name": "Hal-Stack    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 999954,
    "name": "Halco Instruments Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 461086,
    "name": "Haldeman-Homme Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 170409,
    "name": "Hale Imaging Systems Inc    ( Orient OH USA )",
    "country": "United States"
  },
  {
    "id": 106164,
    "name": "Hale X-Ray Co Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 459102,
    "name": "Halfpenny Technologies Inc    ( Blue Bell PA USA )",
    "country": "United States"
  },
  {
    "id": 459074,
    "name": "Halifax BioMedical Inc    ( Mabou NS Canada )",
    "country": "Canada"
  },
  {
    "id": 187431,
    "name": "Halkey-Roberts Corp    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 175727,
    "name": "Hall Medical Equipment Supply/Ambulance Service    ( Bakersfield CA USA )",
    "country": "United States"
  },
  {
    "id": 453455,
    "name": "Hallcrest    ( Connah's Quay  Wales )",
    "country": "Wales"
  },
  {
    "id": 108908,
    "name": "Hallcrest Inc    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 460876,
    "name": "Halliday Medical Marketing Assoc Inc    ( Walpole MA USA )",
    "country": "United States"
  },
  {
    "id": 105320,
    "name": "Hallmark Refining Corp    ( Mount Vernon WA USA )",
    "country": "United States"
  },
  {
    "id": 458575,
    "name": "Hallowell EMC    ( Pittsfield ME USA )",
    "country": "United States"
  },
  {
    "id": 999935,
    "name": "Hallowell EMC    ( Pittsfield MA USA )",
    "country": "United States"
  },
  {
    "id": 453397,
    "name": "Halo Innovations Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 460795,
    "name": "Halogen Software Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457920,
    "name": "Halotech DNA SL    ( Tres Cantos  Spain )",
    "country": "Spain"
  },
  {
    "id": 457617,
    "name": "Halscion Inc    ( Suwanee GA USA )",
    "country": "United States"
  },
  {
    "id": 464086,
    "name": "Halt Medical Inc    ( Brentwood CA USA )",
    "country": "United States"
  },
  {
    "id": 468402,
    "name": "Halyard Health    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 108769,
    "name": "Hamamatsu Corp    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 192850,
    "name": "Hamamatsu Photonics KK    ( Hamamatsu  Japan )",
    "country": "Japan"
  },
  {
    "id": 150443,
    "name": "HAMEG GmbH    ( Mainhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 398926,
    "name": "HAMEG Iberica SL    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 144549,
    "name": "HAMEG Inc    ( East Meadow NY USA )",
    "country": "United States"
  },
  {
    "id": 398927,
    "name": "HAMEG Instruments Ltd    ( Kowloon  USA )",
    "country": "United States"
  },
  {
    "id": 398924,
    "name": "HAMEG Ltd    ( Buckden  England )",
    "country": "UK"
  },
  {
    "id": 398925,
    "name": "HAMEG Sarl    ( Meudon La Foret Cedex  France )",
    "country": "France"
  },
  {
    "id": 994399,
    "name": "HAMILTON    (   USA )",
    "country": "United States"
  },
  {
    "id": 102141,
    "name": "Hamilton Bell Co Inc    ( Montvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 453402,
    "name": "Hamilton Bonaduz AG    ( Bonaduz GR  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 394343,
    "name": "Hamilton Buhl Electronics    ( South Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 103780,
    "name": "Hamilton Caster & Mfg Co    ( Hamilton OH USA )",
    "country": "United States"
  },
  {
    "id": 102436,
    "name": "Hamilton Co    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 138228,
    "name": "Hamilton Medical AG    ( Bonaduz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418184,
    "name": "Hamilton Medical Asia Pacific    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 464090,
    "name": "Hamilton Medical Furniture    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 305923,
    "name": "Hamilton Medical Furniture Corp    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 105689,
    "name": "Hamilton Medical Inc    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 461096,
    "name": "Hamilton Robotics Inc    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 108679,
    "name": "Hamilton Scientific Inc    ( De Pere WI USA )",
    "country": "United States"
  },
  {
    "id": 193506,
    "name": "Hamilton Thorne Biosciences Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 454477,
    "name": "Hammacher Schlemmer & Co Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457645,
    "name": "Hammarplast Medical AB    ( Lidkoeping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 426413,
    "name": "Hammer Medical Supply    ( Newton IA USA )",
    "country": "United States"
  },
  {
    "id": 371524,
    "name": "Hammond Mfg Co Inc    ( Cheektowaga NY USA )",
    "country": "United States"
  },
  {
    "id": 107322,
    "name": "HAMO USA Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 102437,
    "name": "Hampden Engineering Corp    ( East Longmeadow MA USA )",
    "country": "United States"
  },
  {
    "id": 458916,
    "name": "Impulse Training Systems    ( Newnan GA USA )",
    "country": "United States"
  },
  {
    "id": 456918,
    "name": "Hampshire Controls Corp    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 454109,
    "name": "Hampton Medical Devices    ( Valley Park MO USA )",
    "country": "United States"
  },
  {
    "id": 435506,
    "name": "Hanau Med GmbH    ( Hanau  USA )",
    "country": "United States"
  },
  {
    "id": 231308,
    "name": "Hand Biomechanics Lab Inc    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 331279,
    "name": "Hand Held Products    ( Skaneateles Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 452145,
    "name": "Hand Held Products Asia/Pacific    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452146,
    "name": "Hand Held Products Brazil Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 452054,
    "name": "Hand Held Products Europe bv    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452147,
    "name": "Hand Held Products France    ( Chaville  France )",
    "country": "France"
  },
  {
    "id": 452148,
    "name": "Hand Held Products Germany    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 452149,
    "name": "Hand Held Products Spain    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 452055,
    "name": "Hand Held Products UK    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 416481,
    "name": "Hand Innovations    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 453391,
    "name": "Hand-Pro AS    ( Honefoss  Norway )",
    "country": "Norway"
  },
  {
    "id": 455874,
    "name": "Handform Plastic Products Co Ltd    (   People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392333,
    "name": "Handi-Lift Inc    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 392740,
    "name": "Handi-Lift Residential Inc    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 151039,
    "name": "Handi-Move NV    ( Ninove  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 103782,
    "name": "Handi-Ramp Inc    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 108513,
    "name": "Handicaps Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 466611,
    "name": "Handicare    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 162658,
    "name": "HandiCare ASA    ( Moss  Norway )",
    "country": "Norway"
  },
  {
    "id": 457194,
    "name": "Handicare Bathroom Safety BV    ( Pijnacker  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457193,
    "name": "Handicare GmbH    ( Germering  Germany )",
    "country": "Germany"
  },
  {
    "id": 453120,
    "name": "Handicare Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457195,
    "name": "Handicare UK    ( Kingswinford  England )",
    "country": "UK"
  },
  {
    "id": 107324,
    "name": "Handler Mfg Co Inc    ( Westfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 456910,
    "name": "HandTrucks.com    ( Middletown OH USA )",
    "country": "United States"
  },
  {
    "id": 455053,
    "name": "Handy Healthcare Co    ( Machester  England )",
    "country": "UK"
  },
  {
    "id": 453859,
    "name": "HandyLab Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 335618,
    "name": "Hanford Bay Assoc Ltd    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 175364,
    "name": "Hanger Orthopedic Group Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 467002,
    "name": "Hangzhou Clongene Biotech Co Ltd    ( Future Technology City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291827,
    "name": "Hangzhou Dianda Disinfection Equipment Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456650,
    "name": "Hangzhou FengHua Medical Device Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455806,
    "name": "Hangzhou Garden Corp    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455810,
    "name": "Hangzhou Hawk Optical Electronic Instruments Co Lt    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455830,
    "name": "Hangzhou Hua'an Medical & Health Instruments Co Lt    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 407222,
    "name": "Hans-Juergen Moje    ( Petersburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 291837,
    "name": "Hangzhou Jinlin Medical Appliances Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455831,
    "name": "Hangzhou Kangji Medical Instruments Co    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456688,
    "name": "Hangzhou Longx Technology Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455839,
    "name": "Hangzhou Optcla Medical Instrument Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455840,
    "name": "Hangzhou Sejoy Electronics & Instruments Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455848,
    "name": "Hangzhou Shanyou Medical Equipment Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455853,
    "name": "Hangzhou Shuyou Electric Science Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455856,
    "name": "Hangzhou Tonglu Acme Endoscope Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455859,
    "name": "Hangzhou Tonglu Shikonghou Medicl Instrument Co Lt    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456661,
    "name": "Hangzhou Xi'anda Antimicrobial Technology Institut    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455863,
    "name": "Hangzhou Zhenqi Sanitary Products Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999391,
    "name": "Hangzhou Zongrun    (   USA )",
    "country": "United States"
  },
  {
    "id": 457015,
    "name": "Hanil-TM Co Ltd    ( Hanam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 162971,
    "name": "Hanita Lenses    ( Kibbutz Hanita  Israel )",
    "country": "Israel"
  },
  {
    "id": 103458,
    "name": "Hankison International    ( Canonsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 456359,
    "name": "Hanlab Corp    ( Paju City  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456381,
    "name": "HANMED Co Ltd    ( Gimhae  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 357456,
    "name": "Hann's On Software    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 108928,
    "name": "Hanna Instruments Inc    ( Woonsocket RI USA )",
    "country": "United States"
  },
  {
    "id": 999625,
    "name": "HANNING ELEKTRO-WERKE GmbH & Co. KG    (   Germany )",
    "country": "Germany"
  },
  {
    "id": 178468,
    "name": "Hanover Direct Inc    ( Weehawken NJ USA )",
    "country": "United States"
  },
  {
    "id": 105451,
    "name": "Hanovia Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 323875,
    "name": "Hanovia Ltd    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 456355,
    "name": "Hans Biomed Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 285778,
    "name": "Hans Dieter Zepf    ( Rietheim-Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 293771,
    "name": "Hans Gonzenbach    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 160131,
    "name": "Hans H Penner    ( March-Buchheim  USA )",
    "country": "United States"
  },
  {
    "id": 332067,
    "name": "Hans Hermann GmbH    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287133,
    "name": "Hans Kloss GmbH    ( Waltrop  Germany )",
    "country": "Germany"
  },
  {
    "id": 283803,
    "name": "Hans Landgraf Laborgeraete    ( Langenhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 346049,
    "name": "HANS O MAHN & Co    ( Hamburg-Stapelfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 355725,
    "name": "Hans Pausch Rontgengeratebau GmbH &Co    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281805,
    "name": "Hans Rudolf Chirurg Instrumente    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103334,
    "name": "Hans Rudolph Inc    ( Shawnee KS USA )",
    "country": "United States"
  },
  {
    "id": 281807,
    "name": "Hans Sattler Chirurgische Instrumente    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280784,
    "name": "Hans Seid    ( Denkingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284330,
    "name": "Hans Waelischmiller GmbH    ( Markdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 281782,
    "name": "Hans Wipperfuerth    ( Freilassing  Germany )",
    "country": "Germany"
  },
  {
    "id": 280560,
    "name": "Hans-H Hasbargen GmbH    ( Bruchsal  Germany )",
    "country": "Germany"
  },
  {
    "id": 287596,
    "name": "Hans-Peter Gruss Prophylaxe-Dienst    ( Wuelfrath  Germany )",
    "country": "Germany"
  },
  {
    "id": 285692,
    "name": "Hans-Peter Kleinmaier    ( Remshalden Grunbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 461097,
    "name": "Hansatech Instruments Ltd    ( King's Lynn  England )",
    "country": "UK"
  },
  {
    "id": 158435,
    "name": "Hansaton Akustik GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 158436,
    "name": "Hanse-Medizintechnik    ( Ratekau  Germany )",
    "country": "Germany"
  },
  {
    "id": 453127,
    "name": "Hansen Medical Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 293144,
    "name": "Hanshin Medical Co Ltd    ( Incheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451363,
    "name": "Hanshin Medical Co Ltd    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 277944,
    "name": "Hanshin Technical Laboratory Ltd    ( Nishinomiya  Japan )",
    "country": "Japan"
  },
  {
    "id": 175359,
    "name": "Hanson Lab Furniture    ( Newbury Park CA USA )",
    "country": "United States"
  },
  {
    "id": 101303,
    "name": "Hapad Inc    ( Bethel Park PA USA )",
    "country": "United States"
  },
  {
    "id": 283042,
    "name": "Happersberger Otopront GmbH    ( Hohenstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 453819,
    "name": "Happersberger Otopront GmbH    ( Hohenstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 364341,
    "name": "Happy Ag    ( Gossau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452276,
    "name": "HAPRO Inc    ( East Meadow NY USA )",
    "country": "United States"
  },
  {
    "id": 287550,
    "name": "Harald Fischer Chirurgische Instrumente    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 455869,
    "name": "Harbin Quanke Medi-Technical Development Co Ltd    ( Harbin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999606,
    "name": "Harbor Freight Tools    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 105936,
    "name": "Harbor Linen    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 441785,
    "name": "Harbor Medical Inc    ( Phoenix MD USA )",
    "country": "United States"
  },
  {
    "id": 339503,
    "name": "Harc Mercantile Ltd    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 101273,
    "name": "HARD Mfg Co Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 453467,
    "name": "Hardall International Ltd    ( Dunstable  England )",
    "country": "UK"
  },
  {
    "id": 107102,
    "name": "Hardwood Products Co LLC    ( Guilford ME USA )",
    "country": "United States"
  },
  {
    "id": 261734,
    "name": "Hardy Diagnostics    ( Santa Maria CA USA )",
    "country": "United States"
  },
  {
    "id": 185961,
    "name": "Hardy Mfg Co Inc    ( Decatur GA USA )",
    "country": "United States"
  },
  {
    "id": 108890,
    "name": "Harmac Medical Products Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 187046,
    "name": "Harman Corp    ( Rochester Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 345481,
    "name": "Harmar Auto Lift    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 450789,
    "name": "Harmony Brands LLC    ( Melvindale MI USA )",
    "country": "United States"
  },
  {
    "id": 454884,
    "name": "Harod Enterprises Inc    ( Martinez GA USA )",
    "country": "United States"
  },
  {
    "id": 459469,
    "name": "Harrick Plasma    ( Ithaca NY USA )",
    "country": "United States"
  },
  {
    "id": 101972,
    "name": "Harris Calorific    ( Gainesville GA USA )",
    "country": "United States"
  },
  {
    "id": 378243,
    "name": "Harris Calorific srl    ( Pianoro (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462891,
    "name": "Harris Healthcare Solutions    ( Falls Church VA USA )",
    "country": "United States"
  },
  {
    "id": 107323,
    "name": "Harris Industries Inc    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 455161,
    "name": "Harris Medical Resources    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 461028,
    "name": "Harrison Research Laboratories Inc    ( Union NJ USA )",
    "country": "United States"
  },
  {
    "id": 103719,
    "name": "Harry J Bosworth Co    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 151226,
    "name": "Harstra Instrumenten    ( Zeewolde  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458986,
    "name": "Harta Instruments    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 456008,
    "name": "Hartalega    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 174381,
    "name": "Hartmann Industria e Comercio    ( Sao Goncalo-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 281387,
    "name": "Hartmann Verpackung GmbH    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 397717,
    "name": "Hartmann-Conco Inc    ( Rock Hill SC USA )",
    "country": "United States"
  },
  {
    "id": 160125,
    "name": "Hartmut Oertel    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107680,
    "name": "Hartwell Medical Corp    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 391207,
    "name": "Harvard Apparatus Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 177424,
    "name": "Harvard Clinical Technology    (   USA )",
    "country": "United States"
  },
  {
    "id": 455514,
    "name": "Harvest Healthcare    ( Rotherham  England )",
    "country": "UK"
  },
  {
    "id": 245177,
    "name": "Harvest Technologies Corp    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 392259,
    "name": "Harvest Technologies GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 466254,
    "name": "Harvey Instruments Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 102761,
    "name": "Harvy Surgical Supply Corp    ( Flushing NY USA )",
    "country": "United States"
  },
  {
    "id": 457366,
    "name": "Haselmeier GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 999727,
    "name": "Haskris Co.    ( Elk Grove Village IL USA )",
    "country": "USA"
  },
  {
    "id": 293887,
    "name": "Haslauer GmbH    ( Ainring/Mitterfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 999691,
    "name": "Hasomed    (   USA )",
    "country": "United States"
  },
  {
    "id": 307085,
    "name": "HASS Southern Africa Pty Ltd    ( Queenswood  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 467171,
    "name": "Hassoc Medical Inc    ( High Point NC USA )",
    "country": "United States"
  },
  {
    "id": 176244,
    "name": "Hatch Corp    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 465220,
    "name": "HatchMed    ( Seattle 11 USA )",
    "country": "United States"
  },
  {
    "id": 459090,
    "name": "Hatco Corp    ( Milwaukee MI USA )",
    "country": "United States"
  },
  {
    "id": 466404,
    "name": "Hatteras Instruments    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 101438,
    "name": "Hausmann Industries Inc    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 293609,
    "name": "Hausmann Laboratorien AG    ( St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 293611,
    "name": "Hausmann Spitalbedarf AG    ( St Gallen  USA )",
    "country": "United States"
  },
  {
    "id": 290093,
    "name": "Hausser Scientific    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 456408,
    "name": "Hausted Patient Handling Systems LLC    ( Montgomery AL USA )",
    "country": "United States"
  },
  {
    "id": 283289,
    "name": "Haux-Life-Support GmbH    ( Karlsbad  Germany )",
    "country": "Germany"
  },
  {
    "id": 177664,
    "name": "Hav NV    ( Boechout  USA )",
    "country": "United States"
  },
  {
    "id": 108941,
    "name": "Havel's Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 335836,
    "name": "Hawaii Chemical & Scientific    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 452123,
    "name": "Hawaii Medical LLC    ( Pembroke MA USA )",
    "country": "United States"
  },
  {
    "id": 408728,
    "name": "Hawkepaks.com Inc    ( Clovis CA USA )",
    "country": "United States"
  },
  {
    "id": 150957,
    "name": "Hawksley & Sons Ltd    ( Lancing  England )",
    "country": "UK"
  },
  {
    "id": 285172,
    "name": "hawo GmbH    ( Obrigheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 104225,
    "name": "Haworth Calgary    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 450889,
    "name": "Haworth Inc    ( Holland MI USA )",
    "country": "United States"
  },
  {
    "id": 451175,
    "name": "Haws Corp    ( Sparks NV USA )",
    "country": "United States"
  },
  {
    "id": 156028,
    "name": "Hayashi Denki Co Ltd (HADECO)    ( Kawasaki-shi  USA )",
    "country": "United States"
  },
  {
    "id": 291070,
    "name": "Hayashidera Medinol Co Ltd    ( Kanazawa City  Japan )",
    "country": "Japan"
  },
  {
    "id": 163636,
    "name": "Hayat Tibbi Aletler San ve Tic AS    ( Corum  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 440005,
    "name": "Haycomp Pty Ltd    ( Bayswater  Australia )",
    "country": "Australia"
  },
  {
    "id": 457886,
    "name": "Hayden Medical Inc    ( Santa Clarita CA USA )",
    "country": "United States"
  },
  {
    "id": 426896,
    "name": "Homier Distributing Co Inc    ( Huntington IN USA )",
    "country": "United States"
  },
  {
    "id": 460792,
    "name": "Hayes Management Consulting    ( Newton Center MA USA )",
    "country": "United States"
  },
  {
    "id": 331293,
    "name": "Hayes Medical Inc    ( El Dorado Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 459329,
    "name": "Hayman Safe Co Inc    ( Oviedo FL USA )",
    "country": "United States"
  },
  {
    "id": 104565,
    "name": "Haynes Radiation Ltd Inc    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 281171,
    "name": "Haynl-Elektronik    ( Schonebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 358486,
    "name": "Hazmat Medical Assoc    ( New Lenox IL USA )",
    "country": "United States"
  },
  {
    "id": 459140,
    "name": "HB Communications Inc    ( North Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 152435,
    "name": "HB Hospitalar Industria e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 459460,
    "name": "HBG Henneberg-Sander GmbH    ( Giessen-Luetzellinden  Germany )",
    "country": "Germany"
  },
  {
    "id": 286825,
    "name": "HBH Medizintechnik GmbH    ( Tuttlingen/Nendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455956,
    "name": "HBI Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461020,
    "name": "HBR Healthcare    ( Liberty IN USA )",
    "country": "United States"
  },
  {
    "id": 461016,
    "name": "hc1.com    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 346069,
    "name": "HCS (Health Care Software Inc)    ( Farmingdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 457266,
    "name": "HD Smith    ( Springfield IL USA )",
    "country": "United States"
  },
  {
    "id": 101313,
    "name": "HDC Corp    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 999816,
    "name": "HDC Maquinolas, LLC    ( Simpsonville KY USA )",
    "country": "USA"
  },
  {
    "id": 450986,
    "name": "HDI Railing Systems]    ( Columbia PA USA )",
    "country": "United States"
  },
  {
    "id": 364495,
    "name": "HDS Specialty Vehicles    ( Lakeville MN USA )",
    "country": "United States"
  },
  {
    "id": 459287,
    "name": "HDT Global    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 416388,
    "name": "HEAD prone inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 339233,
    "name": "HeadWater Systems Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 452552,
    "name": "Heal Force Bio-Meditech Holdings Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454871,
    "name": "Healing HealthCare Systems    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 456715,
    "name": "HealioHealth    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 455888,
    "name": "Health & Life Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 392336,
    "name": "Health Aid Co Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 460986,
    "name": "Health Care Compliance Strategies Inc    ( Jericho NY USA )",
    "country": "United States"
  },
  {
    "id": 125953,
    "name": "Health Care Exports Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 105938,
    "name": "Health Care Information LLC    ( Loveland OH USA )",
    "country": "United States"
  },
  {
    "id": 331042,
    "name": "Health Care Instruments    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 102966,
    "name": "Health Care Logistics Inc    ( Circleville OH USA )",
    "country": "United States"
  },
  {
    "id": 999710,
    "name": "Health Care Logistics Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 440006,
    "name": "Health Care Mfg Inc (HCMI)    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 458371,
    "name": "Health Care Systems Inc (HCS)    ( Montgomery AL USA )",
    "country": "United States"
  },
  {
    "id": 233691,
    "name": "Health Data Services Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 452804,
    "name": "Health Directions Inc    ( Levittown PA USA )",
    "country": "United States"
  },
  {
    "id": 106793,
    "name": "Health Edco    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 469527,
    "name": "Health Engineering Systems    ( Norman OK USA )",
    "country": "United States"
  },
  {
    "id": 103973,
    "name": "Health Enterprises    ( North Attleboro MA USA )",
    "country": "United States"
  },
  {
    "id": 460973,
    "name": "Health Environment Art Services    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 361929,
    "name": "Health First    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 402560,
    "name": "Health Frontier    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 339239,
    "name": "Health Gear Inc    ( Unionville CT USA )",
    "country": "United States"
  },
  {
    "id": 306528,
    "name": "Health Hero Network Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 233421,
    "name": "Health Highway Inc    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 460969,
    "name": "Health Information Assoc Inc    ( Pawleys Island SC USA )",
    "country": "United States"
  },
  {
    "id": 233695,
    "name": "Health It Advisors Inc    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460963,
    "name": "Health Language    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 399294,
    "name": "Health Medical Products Inc    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 469501,
    "name": "Health Net Connect Inc    ( Wixom MI USA )",
    "country": "United States"
  },
  {
    "id": 104906,
    "name": "Health O Meter Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 454271,
    "name": "Health o meter Professional Scale Corp    ( Alsip IL USA )",
    "country": "United States"
  },
  {
    "id": 466428,
    "name": "Health One Technology Solutions    ( Dalton GA USA )",
    "country": "United States"
  },
  {
    "id": 104376,
    "name": "Health Physics Northwest Inc    ( Tigard OR USA )",
    "country": "United States"
  },
  {
    "id": 456469,
    "name": "Health Products For You    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 453619,
    "name": "Health Robotics    ( Williamstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 455306,
    "name": "Health Robotics srl    ( Bolzano  Italy )",
    "country": "Italy"
  },
  {
    "id": 103784,
    "name": "Health Science Products Inc    ( Hueytown AL USA )",
    "country": "United States"
  },
  {
    "id": 428585,
    "name": "Health Star    ( Moultrie GA USA )",
    "country": "United States"
  },
  {
    "id": 466259,
    "name": "Health System Services    ( Wheatfield NY USA )",
    "country": "United States"
  },
  {
    "id": 330034,
    "name": "Health Systems Resources Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 396899,
    "name": "Health Watch Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 392883,
    "name": "Health-Mor    ( Strongsville OH USA )",
    "country": "United States"
  },
  {
    "id": 465218,
    "name": "Health.edu    ( Lubbock 36 USA )",
    "country": "United States"
  },
  {
    "id": 460723,
    "name": "Healthagen    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 460955,
    "name": "Healthcall LLC    ( Crown Point IN USA )",
    "country": "United States"
  },
  {
    "id": 232673,
    "name": "Healthcare Automation    ( Cranston RI USA )",
    "country": "United States"
  },
  {
    "id": 321491,
    "name": "Healthcare Biomedical Services    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 461076,
    "name": "Healthcare Cable Systems Inc    ( Henrico VA USA )",
    "country": "United States"
  },
  {
    "id": 462645,
    "name": "Healthcare Communication Solutions Inc    ( Sinking Spring PA USA )",
    "country": "United States"
  },
  {
    "id": 333995,
    "name": "Healthcare Data Exchange Corp    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 333813,
    "name": "HealthCare Data Inc    ( Morgantown IN USA )",
    "country": "United States"
  },
  {
    "id": 465184,
    "name": "Healthcare Design Solutions Inc    ( San Ramon 24 USA )",
    "country": "United States"
  },
  {
    "id": 460952,
    "name": "Healthcare Finanical Management Assoc    ( Westchester IL USA )",
    "country": "United States"
  },
  {
    "id": 999735,
    "name": "Healthcare International    ( Langley WA USA )",
    "country": "USA"
  },
  {
    "id": 457970,
    "name": "HealthCare International Inc    ( Langley WA USA )",
    "country": "United States"
  },
  {
    "id": 233737,
    "name": "Healthcare Management Systems Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 464995,
    "name": "Healthcare Resource Group Inc    ( Spokane Valley WA USA )",
    "country": "United States"
  },
  {
    "id": 451311,
    "name": "Healthcare Sales and Service Ltd    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 450827,
    "name": "Healthcare Service and Supply    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 458923,
    "name": "Healthcare Storage Solutions Corp    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 452887,
    "name": "Healthcare Technologies Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 459274,
    "name": "Healthcare Technology Corp    ( Fairport NY USA )",
    "country": "United States"
  },
  {
    "id": 458749,
    "name": "HealthcareSource    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 331284,
    "name": "HealthCast Inc    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 451027,
    "name": "HealthCheck Systems Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 322975,
    "name": "HealthCraft Products Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 462759,
    "name": "HealthDox    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 101882,
    "name": "Healthdyne Technologies Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 395161,
    "name": "HealtheTech Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 102468,
    "name": "HealthFirst Corp    ( Mountlake Terrace WA USA )",
    "country": "United States"
  },
  {
    "id": 454053,
    "name": "HealthFrontier Inc    ( Branchburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 454537,
    "name": "Healthland    ( Glennwood MN USA )",
    "country": "United States"
  },
  {
    "id": 460947,
    "name": "Healthline Information Systems Inc    ( Davidson NC USA )",
    "country": "United States"
  },
  {
    "id": 357603,
    "name": "Healthline Products Ltd    ( Bedford  England )",
    "country": "UK"
  },
  {
    "id": 331294,
    "name": "HealthLine Systems Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 264191,
    "name": "HealthLink    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 101312,
    "name": "Healthmark Industries Co Inc    ( Fraser MI USA )",
    "country": "United States"
  },
  {
    "id": 465001,
    "name": "Healthmark Services    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 334272,
    "name": "Healthpac Computer Systems Inc    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 453425,
    "name": "HealthPort    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 453426,
    "name": "HealthPort    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 453491,
    "name": "Healthpower Technology Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999911,
    "name": "HealthSonics    ( Algonquin IL USA )",
    "country": "United States"
  },
  {
    "id": 456305,
    "name": "HealthSTATS International Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 458415,
    "name": "HealthStream Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 454575,
    "name": "HealthTec Software Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 441460,
    "name": "HealthTronics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 372946,
    "name": "HEALTHvision Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 441047,
    "name": "HealthWare Corp    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 454752,
    "name": "Healthware Systems    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 199553,
    "name": "HealthWay    ( Pulaski NY USA )",
    "country": "United States"
  },
  {
    "id": 306758,
    "name": "Healthy Health Care Products Co    ( Cairo  USA )",
    "country": "United States"
  },
  {
    "id": 457349,
    "name": "Healthy You    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 108474,
    "name": "Hearing Components Inc    ( Oakdale MN USA )",
    "country": "United States"
  },
  {
    "id": 454894,
    "name": "Hearing Health Care Centers Inc    ( Plymouth WI USA )",
    "country": "United States"
  },
  {
    "id": 375290,
    "name": "Hearing Help Express Inc    ( DeKalb IL USA )",
    "country": "United States"
  },
  {
    "id": 358514,
    "name": "Hearit LLC    ( Oro Valley AZ USA )",
    "country": "United States"
  },
  {
    "id": 225927,
    "name": "Heart Alert Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 467374,
    "name": "Heart Force Medical Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 458630,
    "name": "Heart Imaging Technologies LLC    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 457839,
    "name": "Heart Medical Europe BV    ( Best  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 105561,
    "name": "Heart Rate Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 107752,
    "name": "Heart Technology Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 460936,
    "name": "Heartbase Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 343453,
    "name": "Heartlab Inc    ( Westerly RI USA )",
    "country": "United States"
  },
  {
    "id": 453008,
    "name": "Heartland Lions Eye Bank    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 457862,
    "name": "Heartland Medical Equipment    ( Marshall MO USA )",
    "country": "United States"
  },
  {
    "id": 451173,
    "name": "Heartland Medical Sales    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 999939,
    "name": "Heartscape Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 412550,
    "name": "HeartSine Technologies Inc    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 412551,
    "name": "HeartSine Technologies Ltd    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 455620,
    "name": "HeartSync Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 460917,
    "name": "HeartVista    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 455064,
    "name": "HeartWare International Inc (Australia)    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 455065,
    "name": "HeartWare International Inc (United States)    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 357213,
    "name": "Heartway Medical Products Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999530,
    "name": "Heatcraft Refrigeration Products LLC    ( Stone Mountain GA USA )",
    "country": "USA"
  },
  {
    "id": 451205,
    "name": "Heathrow Scientific LLC    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 171315,
    "name": "Heatmax Inc    ( Dalton GA USA )",
    "country": "United States"
  },
  {
    "id": 458663,
    "name": "Heavenly Wellness Products Co    ( Venice CA USA )",
    "country": "United States"
  },
  {
    "id": 284486,
    "name": "Heba-Otoplastik Labortechnik & Einrichtungs GmbH &    ( Moemlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455875,
    "name": "Hebei Xinle Sci & Tech Co Ltd    ( Shijiazhuang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456544,
    "name": "HEBUmedical GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 439765,
    "name": "Hecht-France    ( Igny  France )",
    "country": "France"
  },
  {
    "id": 416097,
    "name": "Heckel Medizintechnik GmbH    ( Esslingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285863,
    "name": "Heckmann Chisurgietechnik    ( Rottenburg  USA )",
    "country": "United States"
  },
  {
    "id": 457719,
    "name": "HECTEC GmbH    ( Landshut  Germany )",
    "country": "Germany"
  },
  {
    "id": 285154,
    "name": "Hedent GmbH    ( Oberursel  Germany )",
    "country": "Germany"
  },
  {
    "id": 439579,
    "name": "Hedley Technologies Inc (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 439580,
    "name": "Hedley Technologies Inc (USA)    ( Grand Junction CO USA )",
    "country": "United States"
  },
  {
    "id": 164436,
    "name": "Heesen Medica bv    ( Boxtel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 168575,
    "name": "Hegner Geru AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 293404,
    "name": "Hegra AG    ( Hedingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 431349,
    "name": "HEI Advanced Medical Operations    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 456947,
    "name": "HEI Medical Device High Performance Mfg    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 450526,
    "name": "Heico Switzerland    ( Steinach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 398859,
    "name": "Heidelberg Engineering GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 398886,
    "name": "Heidelberg Engineering Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 283388,
    "name": "Heidolph Instruments GmbH & Co KG    ( Schwabach  Germany )",
    "country": "Germany"
  },
  {
    "id": 348556,
    "name": "Heidolph Instruments LLC    ( Cinnaminson NJ USA )",
    "country": "United States"
  },
  {
    "id": 222502,
    "name": "Heildolph Elektro GmbH & Co KG    ( Kelheim/Donau  USA )",
    "country": "United States"
  },
  {
    "id": 150447,
    "name": "Heine Optotechnik GmbH & Co KG    ( Herrsching  Germany )",
    "country": "Germany"
  },
  {
    "id": 142911,
    "name": "Heine USA Ltd    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 283390,
    "name": "Heinemann & Gregori GmbH Mess- und Regeltechnik    ( Kelkheim Fischbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 152521,
    "name": "Heinen & Loewenstein GmbH    ( Bad Ems  Germany )",
    "country": "Germany"
  },
  {
    "id": 279520,
    "name": "Heinlein Plastik-Technik GmbH    ( Ansbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 282421,
    "name": "Heinrich AD Berkemann GmbH & Co    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 153007,
    "name": "Heinrich Amelung GmbH Medizinische Laborgeraete    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 283753,
    "name": "Heinrich Caroli eK    ( Lahr  Germany )",
    "country": "Germany"
  },
  {
    "id": 283060,
    "name": "Heinrich Vorndamme OHG    ( Horn-Bad Meinberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285953,
    "name": "Heinrich Walch GmbH & Co KG    ( Halver  Germany )",
    "country": "Germany"
  },
  {
    "id": 281615,
    "name": "Heinrich Zeiss Zweigniederlassung    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 281617,
    "name": "Heinz Diefenbach GmbH Elektromedizin    ( Frankfurt  USA )",
    "country": "United States"
  },
  {
    "id": 282423,
    "name": "Heinz Herenz Medizinalbedarf GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281313,
    "name": "Heinz Kettler    ( Ense/Parsit  Germany )",
    "country": "Germany"
  },
  {
    "id": 280999,
    "name": "Heinz Kurz GmbH Medizintechnik    ( Dusslingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281543,
    "name": "Heinz Schiebler Norddeutsche Gummistrumpffabrik    ( Flensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283159,
    "name": "Heinz Schultz Chirurgische Instrumente    ( Immendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454310,
    "name": "Heipha Dr Mueller GmbH    ( Eppelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 157925,
    "name": "Heiwa Electronic Industrial Co Ltd    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 284117,
    "name": "HEK GmbH    ( Luebeck  USA )",
    "country": "United States"
  },
  {
    "id": 460912,
    "name": "HEKA Instruments Inc    ( Bellmore NY USA )",
    "country": "United States"
  },
  {
    "id": 293031,
    "name": "Helapet Ltd    ( Haughton Regis  England )",
    "country": "UK"
  },
  {
    "id": 284856,
    "name": "Helbig-Medizintechnik Vertreibs GmbH    ( Neudenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 426242,
    "name": "Helena BioSciences Europe (France)    ( Saint Leu La Foret  USA )",
    "country": "United States"
  },
  {
    "id": 426243,
    "name": "Helena BioSciences Europe (Italy)    ( Assago MI  USA )",
    "country": "United States"
  },
  {
    "id": 272142,
    "name": "Helena BioSciences Europe (UK)    ( Gateshead  England )",
    "country": "UK"
  },
  {
    "id": 344837,
    "name": "Helena Laboratories (Australia) Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 102709,
    "name": "Helena Laboratories Corp    ( Beaumont TX USA )",
    "country": "United States"
  },
  {
    "id": 107121,
    "name": "Helena Plastics    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 384477,
    "name": "Helena Point of Care    ( Beaumont TX USA )",
    "country": "United States"
  },
  {
    "id": 356852,
    "name": "Helget Gas Products Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 357605,
    "name": "Helica Instruments Ltd    ( Edinburgh  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 458759,
    "name": "Helicon    ( Moscow  Russia )",
    "country": "Russia"
  },
  {
    "id": 456974,
    "name": "Helicor Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 454266,
    "name": "Helioscopie Medical Implants    ( Vienne Cedex  France )",
    "country": "France"
  },
  {
    "id": 339513,
    "name": "Helix Medical Inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 101315,
    "name": "Heller Laboratories    ( Sparks NV USA )",
    "country": "United States"
  },
  {
    "id": 174949,
    "name": "Hellma Cells Inc    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 175262,
    "name": "Hellma GmbH & Co    ( Muellheim/Baden  Germany )",
    "country": "Germany"
  },
  {
    "id": 107717,
    "name": "HELMER    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 280378,
    "name": "Helmut Eich Glasblaeserei    ( Bonn  Germany )",
    "country": "Germany"
  },
  {
    "id": 158487,
    "name": "Helmut Hund GmbH    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 283932,
    "name": "Helmut Roeck GmbH    ( Lenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286094,
    "name": "Helmut Zepf Medizintechnik GmbH    ( Seitingen-Oberflacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 452003,
    "name": "Help Me Heal    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 461533,
    "name": "HelpDesk Technology International Corp    ( Crestview FL USA )",
    "country": "United States"
  },
  {
    "id": 107327,
    "name": "Helvoet Pharma USA    ( Pennsauken NJ USA )",
    "country": "United States"
  },
  {
    "id": 457597,
    "name": "Hema-Quebec    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 340380,
    "name": "Hemagen Diagnosticos    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 236644,
    "name": "Hemagen Diagnostics Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 401434,
    "name": "HemaMetrics Inc    ( Kaysville UT USA )",
    "country": "United States"
  },
  {
    "id": 450577,
    "name": "HemaTechnologies (Scotland)    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 450578,
    "name": "HemaTechnologies (USA)    ( Lebanon NJ USA )",
    "country": "United States"
  },
  {
    "id": 102968,
    "name": "HEMCO Corp    ( Independence MO USA )",
    "country": "United States"
  },
  {
    "id": 426430,
    "name": "HemCon Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 408160,
    "name": "Hemedex Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 455351,
    "name": "Hemo bioscience    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 168577,
    "name": "HEMO-Werkzeuge Heinz Mosig    ( Kriens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 405873,
    "name": "HemoCleanse Inc    ( Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 139465,
    "name": "HemoCue AB    ( Angelholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450536,
    "name": "HemoCue AG    ( Wetzikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452755,
    "name": "HemoCue Australia Pty Ltd    ( Tumbi Umbi  Australia )",
    "country": "Australia"
  },
  {
    "id": 393225,
    "name": "HemoCue Danmark A/S    ( Vedbaek  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 452754,
    "name": "HemoCue France    ( Meaux  France )",
    "country": "France"
  },
  {
    "id": 393230,
    "name": "HemoCue GmbH    ( Grossostheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 107838,
    "name": "HemoCue Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 324662,
    "name": "HemoCue Ltd    ( Dronfield  England )",
    "country": "UK"
  },
  {
    "id": 393231,
    "name": "HemoCue Nederland bv    ( Waalre  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 393232,
    "name": "HemoCue Norge    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 393229,
    "name": "HemoCue Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 393233,
    "name": "HemoCue Poland    ( Katowice  Poland )",
    "country": "Poland"
  },
  {
    "id": 393228,
    "name": "HemoCue South Africa    ( Bryanston  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 450537,
    "name": "HemoCue srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 279186,
    "name": "Hemodia SA    ( Labege Cedex  France )",
    "country": "France"
  },
  {
    "id": 338464,
    "name": "HemoDynamics Systems Ltd    ( Upper Yokne'am  USA )",
    "country": "United States"
  },
  {
    "id": 223675,
    "name": "Hemopharm    ( Gardanne  USA )",
    "country": "United States"
  },
  {
    "id": 450745,
    "name": "HemoSense Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 455330,
    "name": "HemoSonics LLC    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 199563,
    "name": "Hemostatix Medical Devices    (   USA )",
    "country": "United States"
  },
  {
    "id": 454814,
    "name": "Hemostatix Medical Technologies LLC    ( Bartlett TN USA )",
    "country": "United States"
  },
  {
    "id": 455076,
    "name": "Hemotion Sda    ( Oeiras  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 455876,
    "name": "Henan Huirui Biomedical Electronics Co Ltd    ( Zhengzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456789,
    "name": "Henan Medical    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 171210,
    "name": "Henke-Sass Wolf GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281071,
    "name": "Henkel Hygiene GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 183047,
    "name": "Henley HealthCare Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 104891,
    "name": "Henley International Inc Div Maxxim Medical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 232371,
    "name": "Henleys Medical Supplies Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 457573,
    "name": "Henry Jullien    ( Lons-Le-Saunier Cedex  France )",
    "country": "France"
  },
  {
    "id": 410529,
    "name": "Henry Schein (UK)    ( Gillingham  England )",
    "country": "UK"
  },
  {
    "id": 410528,
    "name": "Henry Schein Arcona Inc    ( Niagara On The Lake ON Canada )",
    "country": "Canada"
  },
  {
    "id": 283518,
    "name": "Henry Schein Dentina GmbH    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 410535,
    "name": "Henry Schein Espana SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 410531,
    "name": "Henry Schein France SA    ( Alfortville Cedex  France )",
    "country": "France"
  },
  {
    "id": 410532,
    "name": "Henry Schein Hospithera SA/NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 107647,
    "name": "Henry Schein Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 410530,
    "name": "Henry Schein Killiner McCourt    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 454290,
    "name": "Henry Schein Laboratory Services    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 410534,
    "name": "Henry Schein Regional (Australia)    ( Rosebery  Australia )",
    "country": "Australia"
  },
  {
    "id": 410536,
    "name": "Henry Schein Regional (New Zealand)    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 410533,
    "name": "Henry Schein Repraesentanz Oesterreich    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 410537,
    "name": "Henry Schein-Van den Braak BV    ( Almere  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103922,
    "name": "Henry Troemner LLC    ( Thorofare NJ USA )",
    "country": "United States"
  },
  {
    "id": 284911,
    "name": "Hentschke & Sawatzki    ( Neumuenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 459330,
    "name": "HEPACART Inc    ( Shawnee KS USA )",
    "country": "United States"
  },
  {
    "id": 101532,
    "name": "Her-Mar Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 394705,
    "name": "Heraeus Inc    ( Chalfont PA USA )",
    "country": "United States"
  },
  {
    "id": 347673,
    "name": "Heraeus Kulzer GmbH & Co KG    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 272836,
    "name": "Heraeus Kulzer Inc    ( Armonk NY USA )",
    "country": "United States"
  },
  {
    "id": 254159,
    "name": "Heraeus Medical GmbH    ( Wehrheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 293655,
    "name": "Herag AG    ( Uetikon amSee  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 466998,
    "name": "Herb PHARM    ( Williams OR USA )",
    "country": "United States"
  },
  {
    "id": 456311,
    "name": "Herbert (Thailand) Co Ltd    ( Khon Kaen  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 459418,
    "name": "Herbert Kannegiesser GmbH    ( Vlotho  Germany )",
    "country": "Germany"
  },
  {
    "id": 282427,
    "name": "Herbert Rehn Maschinenhohlglas GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 287424,
    "name": "Herbert Seus GmbH & Co    ( Laage  Germany )",
    "country": "Germany"
  },
  {
    "id": 287056,
    "name": "Herbert Waldmann GmbH & Co    ( Villingen-Schwenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281720,
    "name": "Herco Wassertechnik GmbH    ( Freiberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 103042,
    "name": "Herculite Products Inc    ( Emigsville PA USA )",
    "country": "United States"
  },
  {
    "id": 456639,
    "name": "Heritage Labs    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 459243,
    "name": "Heritage MedCall Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 457371,
    "name": "Heritage Medical Products    ( West Plains MO USA )",
    "country": "United States"
  },
  {
    "id": 426420,
    "name": "Herley Farmingdale    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 450606,
    "name": "HERMA GmbH    ( Filderstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 394365,
    "name": "Herman Miller Inc    ( Zeeland MI USA )",
    "country": "United States"
  },
  {
    "id": 455531,
    "name": "Hermann Bock    ( Verl  Germany )",
    "country": "Germany"
  },
  {
    "id": 306299,
    "name": "Hermann Butsch GmbH    ( Weilheim/Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286751,
    "name": "Hermann Dausch Medizintechnik GmbH    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286753,
    "name": "Hermann Mueller    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 108276,
    "name": "Hermell Products Inc    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 441517,
    "name": "Hermes Medical Solutions AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452694,
    "name": "Hermes Medical Solutions Canada    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 441555,
    "name": "Hermes Medical Solutions Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 463059,
    "name": "Hermes Medical Solutions Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 999912,
    "name": "INFINIUM MEDICAL INC    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 452695,
    "name": "Hermes Medical Solutions Pty Ltd    ( Wollongong  Australia )",
    "country": "Australia"
  },
  {
    "id": 183745,
    "name": "Hermle Labortechnik GmbH    ( Wehingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 322979,
    "name": "Hernia Institute of Lyon    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 177031,
    "name": "Hero Medical Pty Ltd    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 287410,
    "name": "Herolab GmbH    ( Wiesloch  Germany )",
    "country": "Germany"
  },
  {
    "id": 281179,
    "name": "Herrmann Apparatebau GmbH    ( Kleinwallstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 280066,
    "name": "Herst-und Vetriebsges    ( Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 459410,
    "name": "Herzan LLC    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 280794,
    "name": "Herzer Einrichtungsgesellschaft mbH    ( Dessau  Germany )",
    "country": "Germany"
  },
  {
    "id": 279624,
    "name": "Herzlieb GmbH Kleeberg & Meyer    ( Bad Berneck  Germany )",
    "country": "Germany"
  },
  {
    "id": 459110,
    "name": "Herzog Surgical Inc    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 999931,
    "name": "Heska    (   USA )",
    "country": "United States"
  },
  {
    "id": 465043,
    "name": "HESS Inc    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 284255,
    "name": "Hess Medizintechnik GmbH    ( Puchheim bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 168578,
    "name": "Hess-Duebendorf Fabrik fuer Spitalapparate    ( Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 147150,
    "name": "Hessler Forms and Labels    ( Elkins Park PA USA )",
    "country": "United States"
  },
  {
    "id": 156483,
    "name": "Hestia Pharma GmbH    ( Mannheim  USA )",
    "country": "United States"
  },
  {
    "id": 286613,
    "name": "Hetek Baumann Hebetechnik GmbH    ( Treffurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284751,
    "name": "Hettgen GmbH Medizin- und Gastechnik    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 334299,
    "name": "Hettich AG    ( Baech  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452658,
    "name": "Hettich Asia Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457656,
    "name": "Hettich Benelux BV    ( Geldermalsen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452492,
    "name": "Hettich Instruments LP    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 452491,
    "name": "Hettich Labinstrument AB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452488,
    "name": "Hettich Labinstrument ApS    ( Hillerod  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 452490,
    "name": "Hettich Sarl    ( Sarreguemines  France )",
    "country": "France"
  },
  {
    "id": 455287,
    "name": "Heuser Apparatebau GmbH    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 378109,
    "name": "Hevea Medical Sdn Bhd    ( Pontian  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 409605,
    "name": "HEWI (UK) Ltd    ( Gillingham  England )",
    "country": "UK"
  },
  {
    "id": 409606,
    "name": "HEWI France    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 279554,
    "name": "HEWI Heinrich Wilke GmbH    ( Bad Arolsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 409607,
    "name": "HEWI Italia Com srl-GMBH    ( Salurn (BZ)  Italy )",
    "country": "Italy"
  },
  {
    "id": 106305,
    "name": "Hewitt Dental Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 150090,
    "name": "Hewlett-Packard Co    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 183073,
    "name": "Hex Laboratory Systems    ( Encinitas CA USA )",
    "country": "United States"
  },
  {
    "id": 390962,
    "name": "Hexacath    ( Rueil-Malmaison  France )",
    "country": "France"
  },
  {
    "id": 293374,
    "name": "Hexagon AG    ( Glattbrugg  USA )",
    "country": "United States"
  },
  {
    "id": 152523,
    "name": "Heyer Anesthesia GmbH & Co KG    ( Bad Ems  Germany )",
    "country": "Germany"
  },
  {
    "id": 306846,
    "name": "Heyn y Cia Ltda Juan y Gerardo    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 455877,
    "name": "Heze Juxinyuan Food Co Ltd    ( Caoxian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 158445,
    "name": "HF Medizin-Elektronik GmbH    ( Landau  Germany )",
    "country": "Germany"
  },
  {
    "id": 108992,
    "name": "HGM INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 286758,
    "name": "HGR Instrumente GmbH    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 282437,
    "name": "Ing Harald Warncke GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 284011,
    "name": "HGV-Verbandstoffe GmbH Bleicherei - Faerberei    ( Lindhorst  Germany )",
    "country": "Germany"
  },
  {
    "id": 408710,
    "name": "HHP Asia/Pacific    ( Wanchai  USA )",
    "country": "United States"
  },
  {
    "id": 408715,
    "name": "HHP Brazil Ltda    ( Rio de Janeiro-RJ  USA )",
    "country": "United States"
  },
  {
    "id": 408713,
    "name": "HHP EMEA    ( Eindhoven  USA )",
    "country": "United States"
  },
  {
    "id": 408712,
    "name": "HHP France    ( Chaville  USA )",
    "country": "United States"
  },
  {
    "id": 408714,
    "name": "HHP Germany    ( Jungingen  USA )",
    "country": "United States"
  },
  {
    "id": 408709,
    "name": "HHP Ltd    ( Warrington  USA )",
    "country": "United States"
  },
  {
    "id": 408716,
    "name": "HHP Spain    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 459331,
    "name": "HHW Technologies Inc    ( Redlands CA USA )",
    "country": "United States"
  },
  {
    "id": 144561,
    "name": "Hi Tech International Group Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 237820,
    "name": "Hi-Pro SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 248698,
    "name": "Hi-TEC Medical GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456350,
    "name": "Hi-Tech Pharmacal    ( Amityville NY USA )",
    "country": "United States"
  },
  {
    "id": 185970,
    "name": "Hi-Tech X-Ray Inc    ( New Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 458844,
    "name": "Hickman-Kenyon Systems Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 409562,
    "name": "Hicks Thermometer (India) Ltd    ( Aligarh  India )",
    "country": "India"
  },
  {
    "id": 458381,
    "name": "HID Global Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 463002,
    "name": "HID Global Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 105562,
    "name": "Higgins Supply Co Inc    ( McGraw NY USA )",
    "country": "United States"
  },
  {
    "id": 467333,
    "name": "High Performance Technologies (HPT) Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 999718,
    "name": "High Power Technology Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 462793,
    "name": "High Q Laser GmbH    ( Rankwell  Austria )",
    "country": "Austria"
  },
  {
    "id": 402263,
    "name": "High Technology Inc    ( Walpole MA USA )",
    "country": "United States"
  },
  {
    "id": 290889,
    "name": "High-Technology Co Institute of Electronics    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 162937,
    "name": "Highland International    ( Poynton  USA )",
    "country": "United States"
  },
  {
    "id": 107329,
    "name": "Highland Laboratories Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 459419,
    "name": "Highland Medical Equipment    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 452883,
    "name": "Highland Metals Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 279243,
    "name": "Highnice Development Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458254,
    "name": "Hightech American Industrial Laboratories Inc (HAI    (   USA )",
    "country": "United States"
  },
  {
    "id": 285381,
    "name": "Hiko Medical Communication GmbH    ( Pirmasens  Germany )",
    "country": "Germany"
  },
  {
    "id": 172280,
    "name": "HIL Research Ltd    ( Tel Aviv  USA )",
    "country": "United States"
  },
  {
    "id": 456061,
    "name": "Hilbro (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 192822,
    "name": "Hilco USA    ( Plainville MA USA )",
    "country": "United States"
  },
  {
    "id": 280068,
    "name": "Hildebrandt & Bartsch GmbH & Co KG    ( Ahrensfelde/ OT Blumberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 463052,
    "name": "Hilding Anders Switzerland AG    ( Schaenis  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 284253,
    "name": "Hilgenberg GmbH    ( Malsfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 101317,
    "name": "Hill Laboratories Co    ( Frazer PA USA )",
    "country": "United States"
  },
  {
    "id": 339679,
    "name": "Hill-Rom Air-Shields A Hill-Rom Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 455055,
    "name": "Hill-Rom Asia    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 456835,
    "name": "Hill-Rom Australia    ( Auburn  Australia )",
    "country": "Australia"
  },
  {
    "id": 107911,
    "name": "Hill-Rom Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101318,
    "name": "Hill-Rom Co Inc    ( Batesville IN USA )",
    "country": "United States"
  },
  {
    "id": 354512,
    "name": "Hill-Rom GmbH    ( Witten  Germany )",
    "country": "Germany"
  },
  {
    "id": 174029,
    "name": "Hill-Rom Industries SA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 374360,
    "name": "HomMed LLC    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 455450,
    "name": "Hill-Rom Ltd    ( Ashby de la Zouch  England )",
    "country": "UK"
  },
  {
    "id": 467300,
    "name": "Hill-Rom Respiratory Care    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 455150,
    "name": "Hills Healthcare    ( Perth  Australia )",
    "country": "Australia"
  },
  {
    "id": 155927,
    "name": "Hillusa Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 466267,
    "name": "HIMSA    ( Copenhagen O  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 466270,
    "name": "HIMSA Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 456748,
    "name": "Hip Impact Protection Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 455524,
    "name": "Hipotronics Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 157926,
    "name": "Hirayama Mfg Corp    ( Kasukabe  Japan )",
    "country": "Japan"
  },
  {
    "id": 459420,
    "name": "HireRight Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 455958,
    "name": "Hironic Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456063,
    "name": "Hirose Corp    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 157928,
    "name": "Hirose Electric Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 416229,
    "name": "Hirschmann Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 175685,
    "name": "Hirschmann Laborgeraete GmbH & Co KG    ( Eberstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 176922,
    "name": "Hirtz & Co Hospitalwerk Koeln    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 283563,
    "name": "Hirtz & Co Hospitalwerk Koeln    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 172281,
    "name": "Hisense Ltd    ( Rishon LeZion  Israel )",
    "country": "Israel"
  },
  {
    "id": 370370,
    "name": "Histo-Line Laboratories srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 157686,
    "name": "Histolab Products AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457710,
    "name": "Hitachi Aloka America    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 458056,
    "name": "Hitachi Aloka Medical Ltd    ( Mitaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 457908,
    "name": "Hitachi Appliances Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 310262,
    "name": "Hitachi Chemical Co Ltd    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 459111,
    "name": "Hitachi Data Systems Corp    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 285794,
    "name": "Hitachi Denshi (Europa) GmbH    ( Rodgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 103462,
    "name": "Hitachi Denshi America Ltd    ( Woodbury NY USA )",
    "country": "United States"
  },
  {
    "id": 457216,
    "name": "Hitachi High Technologies America Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 409074,
    "name": "Hitachi High-Technologies America Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 409075,
    "name": "Hitachi High-Technologies Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 409777,
    "name": "Hitachi High-Technologies Europe (UK)    ( London  England )",
    "country": "UK"
  },
  {
    "id": 409776,
    "name": "Hitachi High-Technologies Europe GmbH    ( Krefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 456766,
    "name": "Hitachi High-Technologies Europe Paris Branch    ( Velizy Cedex  France )",
    "country": "France"
  },
  {
    "id": 409778,
    "name": "Hitachi High-Technologies France Sarl    ( Velizy  France )",
    "country": "France"
  },
  {
    "id": 266962,
    "name": "Hitachi Koki Co Ltd Life-Science Instruments Div    ( Hitachinaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 453352,
    "name": "Hitachi Kokusai Electric America Ltd    ( Woodbury NY USA )",
    "country": "United States"
  },
  {
    "id": 455029,
    "name": "Hitachi Kokusai Electric Europe GmbH    ( Erkrath  Germany )",
    "country": "Germany"
  },
  {
    "id": 395034,
    "name": "Hitachi Kokusai Electric Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455030,
    "name": "Hitachi Kokusai Electric UK Ltd    ( Waltham Cross  England )",
    "country": "UK"
  },
  {
    "id": 138226,
    "name": "Hitachi Medical Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 107620,
    "name": "Hitachi Medical Corp of America    ( Tarrytown NY USA )",
    "country": "United States"
  },
  {
    "id": 107689,
    "name": "Hitachi Medical Systems America Inc    ( Twinsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 415945,
    "name": "Hitachi Medical Systems Europe Holdings AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 393172,
    "name": "Hitachi Medical Systems GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 176251,
    "name": "Hitec Group International Inc    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 466858,
    "name": "Hitech Instruments Inc    ( Pennsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 455889,
    "name": "Hivox Biotek Inc    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 450207,
    "name": "HK Surgical    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 999476,
    "name": "HLD Healthy Life Devices Ltd    (   Finland )",
    "country": "Finland"
  },
  {
    "id": 439949,
    "name": "HLD Systems    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 452286,
    "name": "HLTH Corp    ( Elmwood Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 331051,
    "name": "HMB Health Care Products Inc    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 397701,
    "name": "HMC Electronics    ( Canton MA USA )",
    "country": "United States"
  },
  {
    "id": 459245,
    "name": "HME Wireless Inc    ( Suwanee GA USA )",
    "country": "United States"
  },
  {
    "id": 463067,
    "name": "HMK Bilcon A/S    ( Gistrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 287658,
    "name": "HMK Industri A/S    ( Hjoerring  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 306529,
    "name": "HMS Heinkel-Medizin-Systeme/Ein    ( Blaubeuren-Gerhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 360434,
    "name": "HMT High Medical Technologies AG    ( Lengwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455960,
    "name": "HnT MEDICAL Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 195239,
    "name": "HNU-Nordion Ltd Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 452803,
    "name": "Hoana Medical Inc    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 272908,
    "name": "Hobart Food Equipment Group    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 155696,
    "name": "Hobbs Medical Inc    ( Stafford Springs CT USA )",
    "country": "United States"
  },
  {
    "id": 450905,
    "name": "Hobert Corp    ( Troy OH USA )",
    "country": "United States"
  },
  {
    "id": 338396,
    "name": "Hochmal Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 451347,
    "name": "Hocoma AG    ( Volketswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453923,
    "name": "Hocoma Inc    ( Rockland MA USA )",
    "country": "United States"
  },
  {
    "id": 458422,
    "name": "Hoefer Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 280342,
    "name": "Hoegg-Lift Handelsgesellschaft mbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 283536,
    "name": "Hoermann Audiphon GmbH    ( Koelleda  Germany )",
    "country": "Germany"
  },
  {
    "id": 282628,
    "name": "Hofer Nachrichtentechnik GmbH & Co KG    ( Hamburg-Schenefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 460915,
    "name": "Hoffman Engineered Systems    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 451940,
    "name": "Hoffman/New Yorker Inc    ( Bloomfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 286048,
    "name": "Hoffrichter GmbH    ( Schwerin  Germany )",
    "country": "Germany"
  },
  {
    "id": 337767,
    "name": "Hofmann DMT GmbH    ( Erlangen  USA )",
    "country": "United States"
  },
  {
    "id": 456085,
    "name": "HOGGAN Health Industries    ( West Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 277959,
    "name": "Hogy Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 285341,
    "name": "Hohenloher Papptellerfabrik GmbH    ( Pfedelbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 458580,
    "name": "HOIST Fitness Systems    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 999717,
    "name": "Hoist Fitness Systems    ( Poway CA USA )",
    "country": "USA"
  },
  {
    "id": 335208,
    "name": "Hold-It Inc    ( Port Huron MI USA )",
    "country": "United States"
  },
  {
    "id": 331302,
    "name": "Holden Battery Services    ( Bristol VA USA )",
    "country": "United States"
  },
  {
    "id": 174614,
    "name": "Holl Meditronics Inc    ( Bolton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457776,
    "name": "Hollinger Diagnostics Inc    ( Cary IL USA )",
    "country": "United States"
  },
  {
    "id": 186008,
    "name": "Hollister Danmark    ( Fredensborg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 101442,
    "name": "Hollister Inc    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 455469,
    "name": "Hollister Inc    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 286961,
    "name": "Hollister Inc Niederlassung Deutschland    ( Unterfoehring  Germany )",
    "country": "Germany"
  },
  {
    "id": 232355,
    "name": "Hollister Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 174615,
    "name": "Hollister Ltd (Canada)    ( Aurora ON Canada )",
    "country": "Canada"
  },
  {
    "id": 322821,
    "name": "Hollister Ltd (Ireland)    ( Dublin  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 451956,
    "name": "Hollister Switzerland    ( Dietkon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 370620,
    "name": "Hollister-Stier Laboratories LLC    (   USA )",
    "country": "United States"
  },
  {
    "id": 999697,
    "name": "Hollywog, LLC    ( Chattanooga PA USA )",
    "country": "USA"
  },
  {
    "id": 185974,
    "name": "Hollywood Film Co    ( Sun Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 176253,
    "name": "Holmes Products Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 453504,
    "name": "Hologic Asia Pacific    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 156155,
    "name": "Hologic Europe NV    ( Vilvoorde  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457251,
    "name": "Hologic Gen-Probe Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 106487,
    "name": "Hologic Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 459332,
    "name": "Holt Anatomical    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 457584,
    "name": "Holt Dental Supply Inc    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 461268,
    "name": "Holtain Ltd    ( Crymych  England )",
    "country": "UK"
  },
  {
    "id": 285674,
    "name": "Holthaus Medical GmbH & Co KG    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 282179,
    "name": "Holthaus Meditex GmbH    ( Grossroehrsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 286380,
    "name": "Homann-Medical GmbH & Co KG    ( Stolzenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 451782,
    "name": "Home Access Health Corp    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 177841,
    "name": "Home Care Concepts Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 307673,
    "name": "Home Care Technology Co Ltd    ( Tainan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 105395,
    "name": "Home Diagnostics Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 108202,
    "name": "Home Medical Services Inc    ( Parsons KS USA )",
    "country": "United States"
  },
  {
    "id": 453693,
    "name": "Home Telehealth Ltd    ( Cardiff  Wales )",
    "country": "Wales"
  },
  {
    "id": 371746,
    "name": "Home Test Medical    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 174616,
    "name": "Home-Aid Products    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 455890,
    "name": "Homecare Enterprise Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 108373,
    "name": "Homecare Products Inc    ( Auburn WA USA )",
    "country": "United States"
  },
  {
    "id": 377687,
    "name": "Homecare Supply    ( Bethpage NY USA )",
    "country": "United States"
  },
  {
    "id": 452102,
    "name": "Homecraft Rolyan    ( Sutton-in-Ashfield  England )",
    "country": "UK"
  },
  {
    "id": 107330,
    "name": "Homedics Inc    ( Commerce Township MI USA )",
    "country": "United States"
  },
  {
    "id": 452779,
    "name": "Homemate Houseware Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 277963,
    "name": "Homer Ion Laboratory Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453958,
    "name": "Homewood Health Care    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 282431,
    "name": "Homoth Medizinelektronik GmbH & Co Kg    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 187949,
    "name": "Honda Electronics Co Ltd    ( Toyohashi  Japan )",
    "country": "Japan"
  },
  {
    "id": 451639,
    "name": "Honeywell Analytics Distribution Inc    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 460732,
    "name": "Honeywell Building Solutions    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 162413,
    "name": "Honeywell Environmental Air Control Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 453972,
    "name": "Honeywell HLS    ( Alleur  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452258,
    "name": "Honeywell HomeMed LLC    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 150874,
    "name": "Honeywell International Inc    ( Morristown NJ USA )",
    "country": "United States"
  },
  {
    "id": 454001,
    "name": "Honeywell Life Safety AS    ( Drammen  Norway )",
    "country": "Norway"
  },
  {
    "id": 453968,
    "name": "Honeywell Life Safety Austria GmbH    ( Vienna  Austria )",
    "country": "Austria"
  },
  {
    "id": 453970,
    "name": "Honeywell Life Safety Iberia SL    ( Badalona (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 466813,
    "name": "Honeywell Safety Products    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 466898,
    "name": "Honeywell Safety Products (Asia)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 466899,
    "name": "Honeywell Safety Products (Australia/New Zealand)    ( Dingley  Australia )",
    "country": "Australia"
  },
  {
    "id": 466894,
    "name": "Honeywell Safety Products Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 455557,
    "name": "Honeywell Scanning & Mobility (Asia Pacific)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455564,
    "name": "Honeywell Scanning & Mobility (Brazil)    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 455568,
    "name": "Honeywell Scanning & Mobility (France)    ( Roissy CDG Cedex  France )",
    "country": "France"
  },
  {
    "id": 455570,
    "name": "Honeywell Scanning & Mobility (Germany)    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 455565,
    "name": "Honeywell Scanning & Mobility (Netherlands)    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455567,
    "name": "Honeywell Scanning & Mobility (Spain)    ( Coslada (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 455566,
    "name": "Honeywell Scanning & Mobility (UK)    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 455558,
    "name": "Honeywell Scanning & Mobility (USA)    ( Skaneateles Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 450906,
    "name": "Honeywell Security & Custom Electronics    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 223712,
    "name": "Honeywell Sensing and Control    ( Freeport IL USA )",
    "country": "United States"
  },
  {
    "id": 455966,
    "name": "Hong An Medical Appliance Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457367,
    "name": "Hongda Medical International Co Ltd    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 132408,
    "name": "Honyee Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 416392,
    "name": "Hood Thermo-Pad Canada Ltd    ( Summerland BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454402,
    "name": "HOOGI GmbH    ( Ransbach-Baumbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 324701,
    "name": "Hook & Tucker Instruments Ltd    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 332824,
    "name": "Hope X-Ray Products (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 305458,
    "name": "Hope X-Ray Products Inc    ( Warminster PA USA )",
    "country": "United States"
  },
  {
    "id": 332827,
    "name": "Hope X-Ray Products Inc (South Africa)    ( Pretoria  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 364854,
    "name": "Hope X-Ray Products Inc (Switzerland)    ( Lully  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 285650,
    "name": "Hopf Pietaetsartikel GmbH    ( Reilingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279992,
    "name": "Hopf Ringleb & Co GmbH & Cie    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 451415,
    "name": "Hopkins Educational Resources    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 331106,
    "name": "Hopkins Imaging Systems    ( Denton TX USA )",
    "country": "United States"
  },
  {
    "id": 451204,
    "name": "Hopkins Medical Products    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 226617,
    "name": "Horcher GmbH    ( Nidderau  Germany )",
    "country": "Germany"
  },
  {
    "id": 450546,
    "name": "Horcher Lifting Systems Ltd    ( Durham  England )",
    "country": "UK"
  },
  {
    "id": 450547,
    "name": "Horcher LiftingSystems Inc    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 450549,
    "name": "HORIBA ABX SA    ( Montpellier Cedex 4  France )",
    "country": "France"
  },
  {
    "id": 450557,
    "name": "HORIBA ABX USA    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 451766,
    "name": "Horiba Jobin Yvon Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451391,
    "name": "HORIBA Jobin Yvon GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451393,
    "name": "HORIBA Jobin Yvon Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 451396,
    "name": "HORIBA Jobin Yvon Ltd    ( Stanmore  England )",
    "country": "UK"
  },
  {
    "id": 451392,
    "name": "HORIBA Jobin Yvon SA    ( Longjumeau Cedex  France )",
    "country": "France"
  },
  {
    "id": 451394,
    "name": "HORIBA Jobin Yvon SAS (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451395,
    "name": "HORIBA Jobin Yvon srl    ( Opera (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 138253,
    "name": "HORIBA Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 454385,
    "name": "HORIBA Scientific    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 185977,
    "name": "Horizon Medical Services Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458949,
    "name": "Horizon Software International LLC    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 458762,
    "name": "Horizons International Corp    ( Barreal De Heredia  Costa Rica )",
    "country": "Costa Rica"
  },
  {
    "id": 455663,
    "name": "Horn Imaging GmbH    ( Aalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462649,
    "name": "Horseley Dental Supplies Pty Ltd    ( South Granville  Australia )",
    "country": "Australia"
  },
  {
    "id": 102027,
    "name": "Horton Emergency Vehicles Co    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 460976,
    "name": "Horton/Jones Electric    ( Wilton Manors FL USA )",
    "country": "United States"
  },
  {
    "id": 185978,
    "name": "Horyzon Equipment Technologies    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 108021,
    "name": "Hos-Pillow Corp    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 288462,
    "name": "Hoshina Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 356469,
    "name": "Hoshizaki America Inc    ( Peachtree City GA USA )",
    "country": "United States"
  },
  {
    "id": 172388,
    "name": "Hoskins Medical Equipment Ltd    ( Birmingham  USA )",
    "country": "United States"
  },
  {
    "id": 177024,
    "name": "Hoslab Systems Pty Ltd    ( Gladesville  Australia )",
    "country": "Australia"
  },
  {
    "id": 285118,
    "name": "Hosmed Medizintechnik GmbH    ( Oberhaching  Germany )",
    "country": "Germany"
  },
  {
    "id": 103786,
    "name": "Hosmer Dorrance Corp    ( Campbell CA USA )",
    "country": "United States"
  },
  {
    "id": 233354,
    "name": "Hosokawa Alpine Aktiengesellschaft    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 331103,
    "name": "Hospal AG    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 285590,
    "name": "Hospal Medizintechnik GmbH    ( Planegg-Martinsreid  USA )",
    "country": "United States"
  },
  {
    "id": 185980,
    "name": "Hospeq Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 279612,
    "name": "Hospi-Mobil Medizinische Rettungstechnik GmbH    ( Aurich  Germany )",
    "country": "Germany"
  },
  {
    "id": 440681,
    "name": "Hospira Healthcare Corp    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 440861,
    "name": "Hospira Healthcare Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 440680,
    "name": "Hospira Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 458764,
    "name": "Hospital Assoc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 331107,
    "name": "Hospital Biomedical Services    ( Escalon CA USA )",
    "country": "United States"
  },
  {
    "id": 331172,
    "name": "Hospital Communication Systems Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 185982,
    "name": "Hospital Equipment Engineering Services Inc    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 402230,
    "name": "Hospital Equipment Recycled 4-U    ( Salisbury NC USA )",
    "country": "United States"
  },
  {
    "id": 151228,
    "name": "Hospital Equipment Services bv    ( Kampen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103276,
    "name": "Hospital Marketing Services    ( Naugatuck CT USA )",
    "country": "United States"
  },
  {
    "id": 171249,
    "name": "Hospital Shared Service    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 102798,
    "name": "Hospital Specialty Co    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 455622,
    "name": "Hospital Surgical Medical    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 363248,
    "name": "Hospital Systems Inc    ( Pittsburg CA USA )",
    "country": "United States"
  },
  {
    "id": 456635,
    "name": "Hospital Therapy Products Inc    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 158482,
    "name": "Hospitec GmbH    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 462922,
    "name": "Hospiteknik Healthcare    ( Haryana  India )",
    "country": "India"
  },
  {
    "id": 451089,
    "name": "Hospitex Cairo-Egypt    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 451091,
    "name": "Hospitex China    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 342180,
    "name": "Hospitex Diagnostics    ( Sesto Fiorentino (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451090,
    "name": "Hospitex Diagnostics LP    ( League City TX USA )",
    "country": "United States"
  },
  {
    "id": 451086,
    "name": "Hospitex Diagnostics Moscow    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 451088,
    "name": "Hospitex France    ( Banon  France )",
    "country": "France"
  },
  {
    "id": 451087,
    "name": "Hospitex Romania    ( Cluj  Romania )",
    "country": "Romania"
  },
  {
    "id": 451491,
    "name": "Hosuk Co Ltd    ( Chungchoeng  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999968,
    "name": "HOT POINT    (   USA )",
    "country": "United States"
  },
  {
    "id": 465087,
    "name": "Hotel & Restaurant Supply    ( Jackson MS USA )",
    "country": "United States"
  },
  {
    "id": 101946,
    "name": "Hotpack    ( Warminster PA USA )",
    "country": "United States"
  },
  {
    "id": 455709,
    "name": "House Medical Group SAE    ( Giza  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 356470,
    "name": "House of Batteries    ( Fountain Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 457795,
    "name": "House of Canes    ( Grants Pass OR USA )",
    "country": "United States"
  },
  {
    "id": 450583,
    "name": "House of Supply Co    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 455551,
    "name": "Houston Medical New Zealand    ( Hamilton  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 176257,
    "name": "Hoveround Corp    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 409785,
    "name": "HoverTech International    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 451309,
    "name": "Howard Industries Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 184654,
    "name": "Howard Instruments Inc    ( Tuscaloosa AL USA )",
    "country": "United States"
  },
  {
    "id": 454323,
    "name": "Howard Medical    ( Ellisville MS USA )",
    "country": "United States"
  },
  {
    "id": 103125,
    "name": "Howard Medical Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 148492,
    "name": "Howard Wright Ltd    ( New Plymouth  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 182556,
    "name": "Howorth Airtech Ltd    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 344580,
    "name": "Howorth USA    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 451455,
    "name": "Hoya ConBio    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 451456,
    "name": "Hoya ConBio France    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 277964,
    "name": "Hoya Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 439092,
    "name": "Hoya Healthcare Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 382636,
    "name": "Hoya Lens Australia Pty Ltd    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 401773,
    "name": "Hoya Lens Belgium NV    ( Kapellen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 382626,
    "name": "Hoya Lens Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 401772,
    "name": "Hoya Lens Denmark    ( Taastrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 382623,
    "name": "Hoya Lens Deutschland GmbH    ( Monchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 382631,
    "name": "Hoya Lens Finland Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 382634,
    "name": "Hoya Lens France SA    ( Emerainville Cedex 2  France )",
    "country": "France"
  },
  {
    "id": 382652,
    "name": "Hoya Lens Guangzhou Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 382648,
    "name": "Hoya Lens Hong Kong Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401774,
    "name": "Hoya Lens Hungary Rt    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 382630,
    "name": "Hoya Lens Iberia SA    ( Coslada (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 382627,
    "name": "Hoya Lens Italia SpA    ( Garbognate Milanese (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 382649,
    "name": "Hoya Lens Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 401776,
    "name": "Hoya Lens Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 382629,
    "name": "Hoya Lens Nederland bv    ( Uithoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 401775,
    "name": "Hoya Lens of America Inc    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 382654,
    "name": "Hoya Lens Philippines Inc    ( Makati City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 382632,
    "name": "Hoya Lens Poland Sp z o o    ( Piaseczno  Poland )",
    "country": "Poland"
  },
  {
    "id": 382660,
    "name": "Hoya Lens Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 382641,
    "name": "Hoya Lens Taiwan Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 139479,
    "name": "Hoya Lens Thailand Ltd    ( Patumthani  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 382526,
    "name": "Hoya Lens UK Ltd    ( Wrexham  England )",
    "country": "UK"
  },
  {
    "id": 382633,
    "name": "Hoya-Optikslip AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 174046,
    "name": "Hoyer GmbH    ( Butzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 103463,
    "name": "Hoyt Corp    ( Westport MA USA )",
    "country": "United States"
  },
  {
    "id": 460989,
    "name": "HP Autonomy    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 458087,
    "name": "HPC Healthline UK Ltd    ( Morden  England )",
    "country": "UK"
  },
  {
    "id": 170418,
    "name": "HPI International Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 455114,
    "name": "HPMS Inc    ( Windham NH USA )",
    "country": "United States"
  },
  {
    "id": 451843,
    "name": "HR Medical    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 451035,
    "name": "HRJ Surgical    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 103787,
    "name": "HRL Inc    ( Margate FL USA )",
    "country": "United States"
  },
  {
    "id": 460977,
    "name": "HRsmart Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 459165,
    "name": "HS Medical Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 455891,
    "name": "Hsiner Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 285926,
    "name": "HSM Pressen GmbH Co KG    ( Salem  Germany )",
    "country": "Germany"
  },
  {
    "id": 456321,
    "name": "HTC America    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 456322,
    "name": "HTC Corp    ( Taoyuan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454405,
    "name": "HTL-STREFA Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 454406,
    "name": "HTL-STREFA SA    ( Ozorkow  Poland )",
    "country": "Poland"
  },
  {
    "id": 462661,
    "name": "HTS Inc    ( Emeryville CA USA )",
    "country": "United States"
  },
  {
    "id": 283843,
    "name": "Hu-Friedy Mfg Co Inc    ( Leimen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458002,
    "name": "Hu-Friedy Mfg Co LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 373453,
    "name": "Hu-Friedy/Mader Medical    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 291176,
    "name": "Hua Zhong University of Science and Technology    ( Wuchang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455673,
    "name": "HuaiYin Medical Insturments Co Ltd    ( Huain  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455879,
    "name": "Huanuo Life-saving Equipment Co Ltd    ( Zhuozhuo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452636,
    "name": "Hubbell Canada LP    ( Pickering ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461024,
    "name": "Hubbell Electric Heater Co    ( Stratford CT USA )",
    "country": "United States"
  },
  {
    "id": 102143,
    "name": "Hubbell Inc (Delaware)    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 455961,
    "name": "HuBDIC Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455880,
    "name": "Hubei ExcelMed Non-Woven Products Co Ltd    ( Xiantao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455881,
    "name": "Hubei Haixin Protective Products Co Ltd    ( Wuhan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455882,
    "name": "Hubei Huanfu Plastic Products Co Ltd    ( Hanchuan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 431606,
    "name": "Hubert Co LLC    ( Harrison OH USA )",
    "country": "United States"
  },
  {
    "id": 106421,
    "name": "Hubert Zepf Inc    ( Bayport NY USA )",
    "country": "United States"
  },
  {
    "id": 999653,
    "name": "Huck Spaulding Enterprises, Inc.    ( Voorheesville NY USA )",
    "country": "United States"
  },
  {
    "id": 309701,
    "name": "Huckert's International    ( Nivelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 226300,
    "name": "Huco Vision SA    ( Saint-Blaise  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452058,
    "name": "Hudson Digital Systems Inc    ( West Paterson NJ USA )",
    "country": "United States"
  },
  {
    "id": 999823,
    "name": "Hudson Fitness Equipment    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 150112,
    "name": "Hudson Industries Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 102439,
    "name": "Hudson Photographic Industries Inc    ( Irvington NY USA )",
    "country": "United States"
  },
  {
    "id": 451658,
    "name": "Hudson RCI /Ruesch    ( Kernen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458543,
    "name": "Hudson Robotics Inc    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 285128,
    "name": "Huelsebeck GmbH Praxis Einrichtungen    ( Oberhausen  USA )",
    "country": "United States"
  },
  {
    "id": 162412,
    "name": "Huestis Medical    ( Bristol RI USA )",
    "country": "United States"
  },
  {
    "id": 227230,
    "name": "Huettinger Electronic GmbH & Co KG    ( Freiburg  USA )",
    "country": "United States"
  },
  {
    "id": 462866,
    "name": "Hug-U-Vac Surgical Positioning Systems Inc    ( Salem OR USA )",
    "country": "United States"
  },
  {
    "id": 347666,
    "name": "Hughes Notduschen GmbH    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 347676,
    "name": "Hughes Safety Showers    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 174629,
    "name": "Hugo Sachs Elektronik    ( March-Hugstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 286034,
    "name": "HUK Starke GmbH    ( Schwaebisch Hall  Germany )",
    "country": "Germany"
  },
  {
    "id": 151229,
    "name": "Huka Developments bv    ( Oldenzaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 358219,
    "name": "Huliot Plastics Industries    ( Upper Galilee  Israel )",
    "country": "Israel"
  },
  {
    "id": 456965,
    "name": "HUM GmbH    ( Luenen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452476,
    "name": "Humagen Fertility Diagnostics Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 457225,
    "name": "Human BioSciences Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 406589,
    "name": "Human Care AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 462937,
    "name": "Human Care Australia Pty Ltd    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 462959,
    "name": "Human Care USA    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 461035,
    "name": "Human Circuit Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 455293,
    "name": "HUMAN gesellschat fuer Biochemica und Diagnostica    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 455445,
    "name": "human med AG    ( Schwerin  Germany )",
    "country": "Germany"
  },
  {
    "id": 455446,
    "name": "human med inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 415955,
    "name": "Human Meditek Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459422,
    "name": "Human Touch    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 453765,
    "name": "HumanCare Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 101330,
    "name": "Humane Restraint Co Inc    ( Waunakee WI USA )",
    "country": "United States"
  },
  {
    "id": 108023,
    "name": "Humanicare International Inc    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 459006,
    "name": "Humanscale Healthcare    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 999845,
    "name": "HumanWare    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 306294,
    "name": "HUMARES GmbH    ( Bruchsal-Untergrombach  Germany )",
    "country": "Germany"
  },
  {
    "id": 457155,
    "name": "Humeca    ( Enschede  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 330563,
    "name": "Humera Sankyo Corp    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 458055,
    "name": "Hummingbird Medical    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 104203,
    "name": "Humphrey Instruments    (   USA )",
    "country": "United States"
  },
  {
    "id": 466224,
    "name": "Hunt Optics and Imaging Inc    ( Pittsburgh 49 USA )",
    "country": "United States"
  },
  {
    "id": 465182,
    "name": "Hunter Douglas    ( 1 1 USA )",
    "country": "United States"
  },
  {
    "id": 344510,
    "name": "Hunter Fan Co    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 455908,
    "name": "Huntex Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 461105,
    "name": "Huntington Business Systems Inc    ( Holbrook NY USA )",
    "country": "United States"
  },
  {
    "id": 303562,
    "name": "Huntleigh Akron    ( Ipswich  USA )",
    "country": "United States"
  },
  {
    "id": 333648,
    "name": "Huntleigh Diagnostics Ltd    ( Cardiff  Wales )",
    "country": "Wales"
  },
  {
    "id": 102764,
    "name": "Huntleigh Healthcare LLC    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 272792,
    "name": "Huntleigh Healthcare Ltd    ( Luton  England )",
    "country": "UK"
  },
  {
    "id": 202258,
    "name": "Huntleigh Healthcare Pty Ltd    ( Wangara  USA )",
    "country": "United States"
  },
  {
    "id": 306525,
    "name": "Huntleigh Kinetics    ( Liverpool  USA )",
    "country": "United States"
  },
  {
    "id": 187984,
    "name": "Huntleigh Nesbit Evans Ltd    ( Wednesbury  USA )",
    "country": "United States"
  },
  {
    "id": 331110,
    "name": "Huntron Inc    ( Mill Creek WA USA )",
    "country": "United States"
  },
  {
    "id": 999792,
    "name": "Huntron Inc.    ( Mill Creek WA USA )",
    "country": "USA"
  },
  {
    "id": 459424,
    "name": "Huot Instruments LLC    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 999521,
    "name": "Huot Instruments, LLC    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 280696,
    "name": "Hupfer Metallwerke GmbH & Co    ( Coesfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 454509,
    "name": "Hurricane Medical    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 467187,
    "name": "Hussmann Corp    ( Bridgeton MO USA )",
    "country": "United States"
  },
  {
    "id": 999699,
    "name": "Hussmann Corporation    ( Bridgeton MO USA )",
    "country": "USA"
  },
  {
    "id": 458029,
    "name": "Hutchins & Hutchins    ( Waynesboro VA USA )",
    "country": "United States"
  },
  {
    "id": 455129,
    "name": "Hutchinson Technology France SAS    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 401949,
    "name": "Hutchinson Technology Inc    ( Hutchinson MN USA )",
    "country": "United States"
  },
  {
    "id": 455128,
    "name": "Hutchinson Technology Inc    ( Arnhem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999393,
    "name": "HUVITZ Co. Ltd.    (   South Korea )",
    "country": "South Korea"
  },
  {
    "id": 158488,
    "name": "Huwo-Hydrotherapie GmbH    ( Umkirch  Germany )",
    "country": "Germany"
  },
  {
    "id": 285343,
    "name": "HWK Hagsfelder Werkstaetten Karlsruhe GmbH    ( Pfinztal  Germany )",
    "country": "Germany"
  },
  {
    "id": 284237,
    "name": "HWS Hans W Schmidt GmbH & Co KG Laboratoriumstechn    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 230976,
    "name": "Hy-Med Healthcare Ltd    ( North Fordingbridge  USA )",
    "country": "United States"
  },
  {
    "id": 223695,
    "name": "Hy-Tape International    ( Patterson NY USA )",
    "country": "United States"
  },
  {
    "id": 457544,
    "name": "Hyaltech    ( Livingston  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 458713,
    "name": "Hybex Innovations    ( Anjou PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999528,
    "name": "I-Inc.    ( Irvine CA USA )",
    "country": "USA"
  },
  {
    "id": 455744,
    "name": "HybriBio Ltd    ( Central Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 465180,
    "name": "HyBridge Solutions, Inc.    ( Atlanta 33 USA )",
    "country": "United States"
  },
  {
    "id": 283018,
    "name": "Hyco-Vakuumtechnik GmbH    ( Hochstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 233571,
    "name": "Hycor Biomedical Europe    ( Kassel  Germany )",
    "country": "Germany"
  },
  {
    "id": 107128,
    "name": "Hycor Biomedical Inc    ( Garden Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 281623,
    "name": "Hydas Fabrik fuer Medizinalbedarf GmbH & Co    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 397908,
    "name": "Hydas Inc    ( Hershey PA USA )",
    "country": "United States"
  },
  {
    "id": 106402,
    "name": "Hydra-Fitness Industries    (   USA )",
    "country": "United States"
  },
  {
    "id": 453836,
    "name": "Hydrate Inc    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 458707,
    "name": "Hydro Service and Supplies    ( Research Triangle Park NC USA )",
    "country": "United States"
  },
  {
    "id": 101333,
    "name": "Hydro-Med Products Inc    ( Rancho Dominguez CA USA )",
    "country": "United States"
  },
  {
    "id": 458545,
    "name": "Hydro-Pac Inc    ( Fairview PA USA )",
    "country": "United States"
  },
  {
    "id": 399280,
    "name": "HydroCision Inc    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 999754,
    "name": "Hydrofarm, Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 282700,
    "name": "Hydromed GmbH Wassermatratzen und Wasserbetten    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 284573,
    "name": "Hydrosun Medizintechnik GmbH    ( Muellheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458705,
    "name": "HydroWorx International Inc    ( Middletown PA USA )",
    "country": "United States"
  },
  {
    "id": 453048,
    "name": "Hydrox Labs    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 293474,
    "name": "Hyga-Wattefabrik W E Gilomen & Cie    ( Lengnau/Biel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 151021,
    "name": "Hygeco    ( Garges-les-Gonesse Cedex  France )",
    "country": "France"
  },
  {
    "id": 464100,
    "name": "Hygeia Medical Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 307292,
    "name": "Hygeian Medical Supplies (Pte) Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 466746,
    "name": "HYGIA Health Services Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 280670,
    "name": "Hygiene- und Berufskleidung GmbH    ( Chemnitz  Germany )",
    "country": "Germany"
  },
  {
    "id": 459290,
    "name": "Hyland Software    ( Westlake OH USA )",
    "country": "United States"
  },
  {
    "id": 457907,
    "name": "Hylands Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 455077,
    "name": "Hymed Health Care Products Ltd    ( Fordingbridge  England )",
    "country": "UK"
  },
  {
    "id": 457027,
    "name": "HYMN Mc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 105264,
    "name": "HYNSON WESTCOTT DUNNING    (   USA )",
    "country": "United States"
  },
  {
    "id": 267527,
    "name": "Hyokwang Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457153,
    "name": "Hypertension Diagnostics    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 453871,
    "name": "HYPHEN BioMedical SAS    ( Neuville-sur-Oise  France )",
    "country": "France"
  },
  {
    "id": 392820,
    "name": "Hypoguard Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 461099,
    "name": "Hypoxygen    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 462915,
    "name": "Hyster-Yale Materials Handling Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 352813,
    "name": "Hytech Hyperbaric Technology    ( Raamsdonksveer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451313,
    "name": "HyTest Ltd    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 455965,
    "name": "Hyun Medics    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455963,
    "name": "Hyundae Meditech    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999728,
    "name": "Hyundai IT Corp    ( San Jose CA USA )",
    "country": "USA"
  },
  {
    "id": 455962,
    "name": "Hyup Sung Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 101355,
    "name": "i    (   USA )",
    "country": "United States"
  },
  {
    "id": 281265,
    "name": "I C Lercher GmbH Instrumente Kaltlichttechnik    ( Emmingen-Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 176260,
    "name": "I-Flow Corp    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 464093,
    "name": "i-Medical Ophthalmic International GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 357013,
    "name": "I-Ming Sanitary Materials Co Ltd    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 472797,
    "name": "i-Optics    ( The Hague  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 105946,
    "name": "I-Rep Inc    ( Lake Elsinore CA USA )",
    "country": "United States"
  },
  {
    "id": 455982,
    "name": "i-SENS Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456064,
    "name": "IAA Surgical Instruments co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 455290,
    "name": "IAC America Inc    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 455292,
    "name": "IAC GmbH    ( Niederkruechten  Germany )",
    "country": "Germany"
  },
  {
    "id": 402376,
    "name": "IAC Nordik A/S    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 455291,
    "name": "IAC UK Ltd    ( Winchester  England )",
    "country": "UK"
  },
  {
    "id": 377433,
    "name": "IAI Corp    ( Shimizu  Japan )",
    "country": "Japan"
  },
  {
    "id": 377437,
    "name": "IAI Industrieroboter GmbH    ( Schwalbach am Taunus  Germany )",
    "country": "Germany"
  },
  {
    "id": 451057,
    "name": "iapyx Medical    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 458372,
    "name": "Iatric Systems Inc    ( Boxford MA USA )",
    "country": "United States"
  },
  {
    "id": 191618,
    "name": "Iatron Laboratories Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 397829,
    "name": "IBA Dosimetry    ( Louvain-la-Neuve  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454742,
    "name": "IBA Dosimetry (Germany)    ( Schwarzenbruck  Germany )",
    "country": "Germany"
  },
  {
    "id": 454743,
    "name": "IBA Dosimetry AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454342,
    "name": "IBA Dosimetry America    ( Bartlett TN USA )",
    "country": "United States"
  },
  {
    "id": 415342,
    "name": "IBA Health (Asia) Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 415340,
    "name": "IBA Health (Europe) Ltd    ( Brierly Hill  USA )",
    "country": "United States"
  },
  {
    "id": 415325,
    "name": "IBA Health Ltd    ( Sydney  USA )",
    "country": "United States"
  },
  {
    "id": 415341,
    "name": "IBA Health Ltd (New Zealand)    ( Wellington  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 459313,
    "name": "IBA Molecular    ( Dulles VA USA )",
    "country": "United States"
  },
  {
    "id": 140868,
    "name": "IBF (Industria Brasileira de Filmes) Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 108773,
    "name": "IBF Corp    ( Garfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 454152,
    "name": "IBG Immucor Ltd    ( Shoreham-by-Sea  England )",
    "country": "UK"
  },
  {
    "id": 149338,
    "name": "IBG Systems Ltd    ( Shoreham-by-Sea  USA )",
    "country": "United States"
  },
  {
    "id": 454159,
    "name": "IBI Scientific    ( Peosta IA USA )",
    "country": "United States"
  },
  {
    "id": 462764,
    "name": "ibidi GmbH    ( Martinsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 282550,
    "name": "IBL GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 392048,
    "name": "IBL Innovative Berlin Laser AG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 463064,
    "name": "IBL International Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 104442,
    "name": "IBM Corp    ( Armonk NY USA )",
    "country": "United States"
  },
  {
    "id": 156996,
    "name": "IBM Japan Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 461336,
    "name": "IBP Medical GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 108063,
    "name": "IC Medical Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 461202,
    "name": "IC Realtime LLC    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 409664,
    "name": "iCAD Inc    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 454570,
    "name": "iCAD Inc Europe    ( Marcq-en-Baroeul  France )",
    "country": "France"
  },
  {
    "id": 459087,
    "name": "Icare Finland Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 264370,
    "name": "ICE Communications Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 455228,
    "name": "Ice-O-Matic    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 466251,
    "name": "Iceberg Enterprises    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 467129,
    "name": "IceCure Medical Inc    ( Collierville TN USA )",
    "country": "United States"
  },
  {
    "id": 451684,
    "name": "ICF    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 461327,
    "name": "Ichor Diagnostic Medicine Inc    ( Covington LA USA )",
    "country": "United States"
  },
  {
    "id": 144592,
    "name": "ICL (Industrial & Commercial Leasing Co)    ( Pennington NJ USA )",
    "country": "United States"
  },
  {
    "id": 284365,
    "name": "ICN Biomedicals GmbH    ( Meckenheim  USA )",
    "country": "United States"
  },
  {
    "id": 105526,
    "name": "ICN Micromedic Systems Inc Sub ICN Biomedical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 462756,
    "name": "ICNet International Ltd    ( Stroud  England )",
    "country": "UK"
  },
  {
    "id": 999672,
    "name": "ICO Rally    ( Palo Alto PA USA )",
    "country": "United States"
  },
  {
    "id": 459246,
    "name": "Icom America Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 459248,
    "name": "Icom Inc    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 371296,
    "name": "ICON Health and Fitness Inc    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 331312,
    "name": "Icon Medical Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 457873,
    "name": "Iconix Video Inc    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 461131,
    "name": "iContracts Inc    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 297513,
    "name": "Icor Instruments AB    ( Bromma  USA )",
    "country": "United States"
  },
  {
    "id": 451183,
    "name": "ICP Medical    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 451180,
    "name": "iCRco Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 192824,
    "name": "ICS International    ( Dubai  USA )",
    "country": "United States"
  },
  {
    "id": 439094,
    "name": "ICT AG    ( Magden/Basel  USA )",
    "country": "United States"
  },
  {
    "id": 364337,
    "name": "ICT Corp    ( Whippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 298381,
    "name": "ICT GmbH    ( Bad Homburg  USA )",
    "country": "United States"
  },
  {
    "id": 439095,
    "name": "ICT-Inter.Net    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 105658,
    "name": "ICU Medical Inc    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 459136,
    "name": "ICUSA.com Inc    ( Medford OR USA )",
    "country": "United States"
  },
  {
    "id": 463034,
    "name": "ICWUSA.com Inc    ( Medford OR USA )",
    "country": "United States"
  },
  {
    "id": 384276,
    "name": "ID Gesellschaft fuer Information und Dokumentation    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 415458,
    "name": "ID Labs (Australia)    ( Taree  Australia )",
    "country": "Australia"
  },
  {
    "id": 344847,
    "name": "ID Labs Inc (Canada)    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 415457,
    "name": "ID Labs Inc (United Kingdom)    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 459368,
    "name": "ID Solutions    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 460892,
    "name": "ID Solutions USA    ( Hillside NJ USA )",
    "country": "United States"
  },
  {
    "id": 456454,
    "name": "Idaho Lions Eye Bank    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 352914,
    "name": "Idaho Technology Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 458033,
    "name": "IDB Holland bv    ( Bearle-Nassau  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451280,
    "name": "IDC Europe bv    ( `s-Hertogenbosch  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 137859,
    "name": "IDC Medical - LifeStand    ( Les Echets  France )",
    "country": "France"
  },
  {
    "id": 184243,
    "name": "Idea LLC    ( Green OH USA )",
    "country": "United States"
  },
  {
    "id": 286766,
    "name": "Idea-Instrumentefabrik Berchtold & Co GmbH    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 465168,
    "name": "IDeACOM Healthcare Communications of Florida    ( Tampa 39 USA )",
    "country": "United States"
  },
  {
    "id": 296897,
    "name": "Ideal Medical Products    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 183466,
    "name": "Ideal Medical Products Inc    ( Broseley MO USA )",
    "country": "United States"
  },
  {
    "id": 440145,
    "name": "Ideal Medical Source Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 249790,
    "name": "Ideatrics Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 458382,
    "name": "IDenticard Systems    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 459135,
    "name": "Identification Systems Group    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 105947,
    "name": "Identita Designers Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 103126,
    "name": "Idesco Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 452948,
    "name": "Idetec Medical Imagining    ( Nimes  France )",
    "country": "France"
  },
  {
    "id": 456820,
    "name": "IDEV Technologies Inc    ( Webster TX USA )",
    "country": "United States"
  },
  {
    "id": 459386,
    "name": "IDEX Health and Science SA    ( Glattbrugg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999463,
    "name": "IDEXX Laboratories, Inc    ( Westbrook ME USA )",
    "country": "United States"
  },
  {
    "id": 466775,
    "name": "Idiag Ag    ( Fehraltorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453747,
    "name": "IDS Inc (Immunodiagnostic Systems Inc)    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 357618,
    "name": "IDS Ltd    ( Boldon  England )",
    "country": "UK"
  },
  {
    "id": 453749,
    "name": "IDS Nordic A/S    ( Herlev  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 455044,
    "name": "IDS SA    ( Liege  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 105828,
    "name": "IDX Systems Corp    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 306275,
    "name": "IEM GmbH    ( Stolberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 104883,
    "name": "IET Labs Inc    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 152093,
    "name": "Iffa-Credo    ( L'Arbresle Cedex  France )",
    "country": "France"
  },
  {
    "id": 293777,
    "name": "IG Instrumenten-Gesellschaft AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 357640,
    "name": "IGEA srl    ( Carpi (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460681,
    "name": "IgeaCare Solutions Inc    ( North York ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457288,
    "name": "Igefa Handelsgesellschaft mbH & Co KG    ( Ahrensfelde  Germany )",
    "country": "Germany"
  },
  {
    "id": 999331,
    "name": "Igloo Products Corporation    (   USA )",
    "country": "United States"
  },
  {
    "id": 177200,
    "name": "IGP (UK) Ltd    ( Chelmsford  England )",
    "country": "UK"
  },
  {
    "id": 461199,
    "name": "iGrok Inc    ( Sewickley PA USA )",
    "country": "United States"
  },
  {
    "id": 463065,
    "name": "IGZ Instruments AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457879,
    "name": "IHC Hytech    ( Raamsdonksveer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 462758,
    "name": "IHS Global Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 457536,
    "name": "IITC Life Science Inc    ( Woodland Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 999634,
    "name": "iiyama International B.V.    (   The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 286329,
    "name": "IKA-Werke GmbH & Co KG    ( Staufen  Germany )",
    "country": "Germany"
  },
  {
    "id": 155356,
    "name": "IKA-Works Inc    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 452052,
    "name": "IKARIA Inc    ( Hampton NJ USA )",
    "country": "United States"
  },
  {
    "id": 999973,
    "name": "IKEGAMI    (   USA )",
    "country": "United States"
  },
  {
    "id": 458281,
    "name": "Ikegami Electronics (USA) Inc    ( Maywood NJ USA )",
    "country": "United States"
  },
  {
    "id": 455951,
    "name": "Ikegami Tsushin Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157934,
    "name": "Ikemoto Scientific Technology Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 458699,
    "name": "Ikonisys Inc    ( New Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 455234,
    "name": "IL Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 108774,
    "name": "IL Med Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 461195,
    "name": "iLabequipment    ( South Orange NJ USA )",
    "country": "United States"
  },
  {
    "id": 174387,
    "name": "Ilacon Ltd    ( Tonbridge  England )",
    "country": "UK"
  },
  {
    "id": 358519,
    "name": "Ilex Israel    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 307525,
    "name": "Ilex South Africa Ltd    ( Rivonia  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 999886,
    "name": "ILFORD PHOTO    ( Mobberley, Knutsford Cheshire England )",
    "country": "UK"
  },
  {
    "id": 457671,
    "name": "iLine Microsystems    ( San Sebastian-Donostia  Spain )",
    "country": "Spain"
  },
  {
    "id": 453354,
    "name": "Illinois Eye-Bank Chicago    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 455815,
    "name": "Illumina Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 227231,
    "name": "ILO Electronic GmbH    ( Quickborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 104035,
    "name": "IM Inc    ( West Caldwell NJ USA )",
    "country": "United States"
  },
  {
    "id": 453392,
    "name": "IM Medical Ltd    ( Hawthorn  Australia )",
    "country": "Australia"
  },
  {
    "id": 416390,
    "name": "IM Systems    ( Arnold MD USA )",
    "country": "United States"
  },
  {
    "id": 999457,
    "name": "iM3 Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 106471,
    "name": "Ima-Tek Inc    ( Thorofare NJ USA )",
    "country": "United States"
  },
  {
    "id": 227232,
    "name": "IMACO GmbH    ( Luedersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 457973,
    "name": "ImaCor Inc    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 999665,
    "name": "ImaCor Inc.    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 108024,
    "name": "Image Analysis Inc    ( Columbia KY USA )",
    "country": "United States"
  },
  {
    "id": 454503,
    "name": "Image Diagnost International GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 170423,
    "name": "Image Diagnostics Inc    ( Sterling MA USA )",
    "country": "United States"
  },
  {
    "id": 461384,
    "name": "Image Engineering Labs LLC    ( Basking Ridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 458631,
    "name": "IMAGE Information Systems Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 108775,
    "name": "Image Perspectives    ( Carson City NV USA )",
    "country": "United States"
  },
  {
    "id": 452375,
    "name": "Image Stream Medical    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 169963,
    "name": "Image Systems Group    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 462710,
    "name": "ImageFIRST    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 292045,
    "name": "Images-on-Call    ( Flower Mound TX USA )",
    "country": "United States"
  },
  {
    "id": 457899,
    "name": "ImageTek Labels    ( Springfield VT USA )",
    "country": "United States"
  },
  {
    "id": 459249,
    "name": "ImageTrend Inc    ( Lakeville MN USA )",
    "country": "United States"
  },
  {
    "id": 455236,
    "name": "ImageWorks Corp    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 465150,
    "name": "Imagin Systems Corp    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 170425,
    "name": "Imaging Affiliates    ( Rural Hall NC USA )",
    "country": "United States"
  },
  {
    "id": 457117,
    "name": "Imaging Associates Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 461227,
    "name": "Imaging Biometrics LLC    ( Elm Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 106306,
    "name": "Imaging Concepts Inc    ( Henrico VA USA )",
    "country": "United States"
  },
  {
    "id": 331314,
    "name": "Imaging Diagnostic Systems Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 143941,
    "name": "Imaging Diagnostics Inc    ( Hendersonville TN USA )",
    "country": "United States"
  },
  {
    "id": 450876,
    "name": "Imaging Dynamics Asia/Pacific    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 370913,
    "name": "Imaging Dynamics Co Ltd    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 271247,
    "name": "Imaging Sciences International Inc    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 185996,
    "name": "Imaging Systems International    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 185997,
    "name": "Imaging Systems Services Ltd    ( Meredith NH USA )",
    "country": "United States"
  },
  {
    "id": 455500,
    "name": "Imaging3 Inc    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 458282,
    "name": "ImagingPlanet    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 456747,
    "name": "Imalux Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 351469,
    "name": "ImaRx Therapeutics Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 293045,
    "name": "Imasonic SA    ( Voray-sur-l'Ognon  France )",
    "country": "France"
  },
  {
    "id": 402797,
    "name": "Imaxeon Pty Ltd    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 415999,
    "name": "Imbros Pty Ltd    ( Moonah  Australia )",
    "country": "Australia"
  },
  {
    "id": 451523,
    "name": "Imcarmed GmbH    ( Saalfeld/Saale  USA )",
    "country": "United States"
  },
  {
    "id": 451730,
    "name": "IMCARMED GmbH    ( Luedersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 372414,
    "name": "IMCO Technologies Corp    ( Pewaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 401045,
    "name": "IMCO Technologies International GmbH    ( Taegerwilen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999656,
    "name": "Kart-a-Bag, Manufacturing Inc.    ( Joliet IL USA )",
    "country": "United States"
  },
  {
    "id": 281533,
    "name": "IMCOR GmbH    ( Filderstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 354505,
    "name": "IMD SpA (International Medical Devices)    ( Scanzorosciate (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 426340,
    "name": "iMDsoft bv    ( Leiden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452916,
    "name": "iMDsoft Germany    ( Krefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 401958,
    "name": "iMDsoft Inc USA    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 401957,
    "name": "iMDsoft Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 451836,
    "name": "iMed.com    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 370914,
    "name": "IMEDCO AG    ( Haegendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 331320,
    "name": "IMEDCO America Ltd    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 339345,
    "name": "IMEDCO Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 456374,
    "name": "IMEDICOM Co Ltd    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 108282,
    "name": "Imex Healthcare Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 338406,
    "name": "IMEXCO General Ltd    ( Ramat Hasharon  Israel )",
    "country": "Israel"
  },
  {
    "id": 132323,
    "name": "IMI Co Ltd    ( Koshigaya-shi  USA )",
    "country": "United States"
  },
  {
    "id": 348473,
    "name": "IMI United Kingdom    ( Gastard Corsham  England )",
    "country": "UK"
  },
  {
    "id": 108536,
    "name": "IMI-Cornelius Inc    ( Osseo MN USA )",
    "country": "United States"
  },
  {
    "id": 453669,
    "name": "IMIX ADR Asia    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453670,
    "name": "IMIX ADR GmbH    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 453668,
    "name": "IMIX ADR USA    ( Sterling VA USA )",
    "country": "United States"
  },
  {
    "id": 365204,
    "name": "Imix Oy/Ab    ( Tampere  Finland )",
    "country": "Finland"
  },
  {
    "id": 455664,
    "name": "Immediate Response Technology    ( Glenn Dale MD USA )",
    "country": "United States"
  },
  {
    "id": 409739,
    "name": "Immersion Medical    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 465004,
    "name": "immixGroup, Inc.    ( McLean 84 USA )",
    "country": "United States"
  },
  {
    "id": 452133,
    "name": "Immucor Benelux    ( Heppignies  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 341683,
    "name": "Immucor GmbH    ( Roedermark  Germany )",
    "country": "Germany"
  },
  {
    "id": 104855,
    "name": "Immucor Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 341684,
    "name": "Immucor Italia    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456483,
    "name": "Immucor KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456482,
    "name": "Immucor Medizinische Diagnostik GmbH    ( Roedermark  Germany )",
    "country": "Germany"
  },
  {
    "id": 341681,
    "name": "Immucor Portugal    ( Alfragide  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 341682,
    "name": "Immucor SL    ( Cerdanyola (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 452135,
    "name": "Immucor-Kainas Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 279890,
    "name": "Immundiagnostik AG    ( Bensheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 456364,
    "name": "ImmuneMed Inc    ( Chuncheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456551,
    "name": "ImmuneTech Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 454128,
    "name": "Immuno Concepts NA Ltd    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 416406,
    "name": "Immuno Diagnostics    ( St Peters  Australia )",
    "country": "Australia"
  },
  {
    "id": 307293,
    "name": "Immuno GmbH    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 108484,
    "name": "Immuno-Mycologics Inc    ( Norman OK USA )",
    "country": "United States"
  },
  {
    "id": 416372,
    "name": "ImmunoDiagnostics Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 453748,
    "name": "Immunodiagnostsic Systems (IDS) EURL    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 283352,
    "name": "Immunolab GmbH    ( Kassel  Germany )",
    "country": "Germany"
  },
  {
    "id": 330869,
    "name": "Immunostics Inc    ( Ocean NJ USA )",
    "country": "United States"
  },
  {
    "id": 451835,
    "name": "Immunotech SAS    ( Marseille Cedex 9  France )",
    "country": "France"
  },
  {
    "id": 155116,
    "name": "ImmunoVision Inc    ( Springdale AR USA )",
    "country": "United States"
  },
  {
    "id": 458686,
    "name": "Imorgon    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 413507,
    "name": "IMPAC Global Systems UK Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 162001,
    "name": "IMPAC Medical Systems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 101885,
    "name": "Impact Instrumentation Inc    ( West Caldwell NJ USA )",
    "country": "United States"
  },
  {
    "id": 451660,
    "name": "Impakt USA    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 457699,
    "name": "ImpediMed    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 375034,
    "name": "Impella Cardiosystems AG    ( Aachen  USA )",
    "country": "United States"
  },
  {
    "id": 462740,
    "name": "Imperial Commercial Cooking Equipment    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 101973,
    "name": "Imperial Fastener Co Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 104006,
    "name": "Imperial Surgical Ltd    ( Dorval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 462730,
    "name": "Imperva Inc    ( Redwood Shores CA USA )",
    "country": "United States"
  },
  {
    "id": 453092,
    "name": "Impladend Dental Implants LLC    ( Thessaloniki  Greece )",
    "country": "Greece"
  },
  {
    "id": 147029,
    "name": "Impladent Ltd    ( Holliswood NY USA )",
    "country": "United States"
  },
  {
    "id": 454510,
    "name": "Implant Direct LLC    ( Calabassa Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 393254,
    "name": "Implant Innovations Canada Inc    ( St-Laurent PQ USA )",
    "country": "United States"
  },
  {
    "id": 393263,
    "name": "Implant Innovations de Mexico    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 393255,
    "name": "Implant Innovations Deutschland GmbH    ( Karlsruhe  USA )",
    "country": "United States"
  },
  {
    "id": 393258,
    "name": "Implant Innovations Europe    ( Kobenhaven K  USA )",
    "country": "United States"
  },
  {
    "id": 393264,
    "name": "Implant Innovations Iberica SL    ( Sant Just Desvern (Barcelona)  USA )",
    "country": "United States"
  },
  {
    "id": 393260,
    "name": "Implant Innovations Impladend    ( Thessaloniki  USA )",
    "country": "United States"
  },
  {
    "id": 330870,
    "name": "Implant Innovations Inc    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 393262,
    "name": "Implant Innovations Japan    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 394360,
    "name": "Implant Innovations Switzerland    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 393261,
    "name": "Implant Innovations UK Ltd    ( Maidenhead  USA )",
    "country": "United States"
  },
  {
    "id": 381788,
    "name": "Implant Sciences Corp    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 457804,
    "name": "implantcast GmbH    ( Buxtehude  Germany )",
    "country": "Germany"
  },
  {
    "id": 290530,
    "name": "Implantech Assoc Inc    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 279218,
    "name": "Implants Industrie    ( La Fouillouse  France )",
    "country": "France"
  },
  {
    "id": 458445,
    "name": "Implen GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453085,
    "name": "Impliant    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 174384,
    "name": "Impol Instrumental e Implantes Ltda    ( Diadema-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 460631,
    "name": "Imprivata Inc    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 456417,
    "name": "Impromediform GmbH    ( Luedenscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 462726,
    "name": "Improvision    ( Coventry  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 457126,
    "name": "Impulse Dynamics (USA) Inc    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 457127,
    "name": "Impulse Dynamics Germany GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 463003,
    "name": "Impulse Monitoring Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 454533,
    "name": "IMRIS    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 195078,
    "name": "IMS Internazionale Medico Scientifico srl    ( Pontecchio Marconi (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 466969,
    "name": "IMSTAR SA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 453051,
    "name": "IMTEC Canada    ( City of Vaughn ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452986,
    "name": "IMTEC Corp    ( Ardmore OK USA )",
    "country": "United States"
  },
  {
    "id": 453053,
    "name": "IMTEC Europe GmbH    ( Oberursel  Germany )",
    "country": "Germany"
  },
  {
    "id": 453054,
    "name": "IMTEC Latin America    ( Cuernavaca  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 453052,
    "name": "IMTEC United Kingdom    ( Crewe  England )",
    "country": "UK"
  },
  {
    "id": 453888,
    "name": "imtmedical AG    ( Buchs  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453889,
    "name": "imtmedical Corp    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 233757,
    "name": "IMTS Healthcare Inc    ( Diamond Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 412973,
    "name": "IN COMMAND    ( Cypress TX USA )",
    "country": "United States"
  },
  {
    "id": 459449,
    "name": "In Motion Technology Inc    ( New Westminster BC Canada )",
    "country": "Canada"
  },
  {
    "id": 453630,
    "name": "In Vitro Technologies    ( Noble Park North  Australia )",
    "country": "Australia"
  },
  {
    "id": 464111,
    "name": "In2Bones SAS    ( Ecully  France )",
    "country": "France"
  },
  {
    "id": 455909,
    "name": "IN4 Technology Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 450531,
    "name": "INAMED Aesthetics (UK)    ( Wokingham  USA )",
    "country": "United States"
  },
  {
    "id": 450533,
    "name": "INAMED Aesthetics GmbH (Germany)    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 409962,
    "name": "INAMED Aesthetics North America    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 450528,
    "name": "INAMED Aesthetics Pty Ltd    ( Chullora  USA )",
    "country": "United States"
  },
  {
    "id": 450532,
    "name": "INAMED Aesthetics Spain SA    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 450529,
    "name": "INAMED Brazil    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 450530,
    "name": "INAMED France    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 455004,
    "name": "Inami & Co (China) Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 157936,
    "name": "Inami & Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 293412,
    "name": "Inauen Stuhl- und Tischfabrik AG    ( Herisau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453488,
    "name": "InaVein    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 409969,
    "name": "InBios International Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 472770,
    "name": "InBody Co Ltd    ( Cerritos CA USA )",
    "country": "United States"
  },
  {
    "id": 151230,
    "name": "Inca Techniek bv    ( Bergambacht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 106593,
    "name": "InCare Medical Products Div Hollister Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 465010,
    "name": "Incentive Services, Inc.    ( Edina 76 USA )",
    "country": "United States"
  },
  {
    "id": 408129,
    "name": "Inceptio Medical Technologies LC    ( Farmington UT USA )",
    "country": "United States"
  },
  {
    "id": 452646,
    "name": "Incisive Surgical Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 108377,
    "name": "Inclinator Co of America    ( Harrisburg PA USA )",
    "country": "United States"
  },
  {
    "id": 461460,
    "name": "InContact Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 458920,
    "name": "InControl Medical LLC    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 322695,
    "name": "Incutech Inc    ( Kernersville NC USA )",
    "country": "United States"
  },
  {
    "id": 456365,
    "name": "INCYTO Co Ltd    ( Chonan-si  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 450823,
    "name": "IND Diagnostics Inc    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 462545,
    "name": "InDemand Interpreting    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 339261,
    "name": "Independence Cryogenic Engineering    ( Little Egg Harbor NJ USA )",
    "country": "United States"
  },
  {
    "id": 371659,
    "name": "Independence Medical    ( Twinsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 372510,
    "name": "Independence Technology LLC    ( Binghamton NY USA )",
    "country": "United States"
  },
  {
    "id": 454463,
    "name": "Independent Living Aids LLC    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 458447,
    "name": "Independent Medical Assoc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 441162,
    "name": "Independent Solutions Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 458236,
    "name": "InDevR Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 428451,
    "name": "Indevus Pharmaceuticals Inc    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 296807,
    "name": "India Medico Instruments    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 457052,
    "name": "Indiana Lions Eye & Tissue Transplant Bank    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 355694,
    "name": "Indigo Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 451838,
    "name": "Inditherm Medical Products plc    ( Rotherham  England )",
    "country": "UK"
  },
  {
    "id": 174386,
    "name": "Indrel (Ind de Refrigeracao Londrinense Ltda)    ( Londrina-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 375295,
    "name": "Indus Medicare Ltd    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 356607,
    "name": "Indus-Tool    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 174389,
    "name": "Industria Reunidas Rhos Ltda (Vicol do Brasil)    ( Duque de Caxias-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 103791,
    "name": "Industrial & Biomedical Sensors Corp    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 102440,
    "name": "Industrial Acoustics Co    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 235118,
    "name": "Industrial Acoustics Co GmbH    ( Niederkruechten  Germany )",
    "country": "Germany"
  },
  {
    "id": 235119,
    "name": "Industrial Acoustics Co Ltd    ( Winchester  England )",
    "country": "UK"
  },
  {
    "id": 450921,
    "name": "Industrial Computing Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 184091,
    "name": "Industrial Electromedica de Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 461330,
    "name": "Industrial Engineering Machine LLC    ( Monterey CA USA )",
    "country": "United States"
  },
  {
    "id": 462720,
    "name": "Industrial Freezer Sales    ( Agoura Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 184093,
    "name": "Industrial Medica Electronica SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 462767,
    "name": "Industrial Steam Cleaning Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 337925,
    "name": "Industrias Cobramex SA de CV    ( Cuautlancingo  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 184069,
    "name": "Industrias Garsam SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 393251,
    "name": "Industrias Quirurgicas de Levante SL    ( Fuente del Jarro (Valencia)  USA )",
    "country": "United States"
  },
  {
    "id": 309631,
    "name": "Industrie Guido Malvestio SpA    ( Villanova (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461192,
    "name": "Industry Sign and Graphics    ( Auburn WA USA )",
    "country": "United States"
  },
  {
    "id": 105565,
    "name": "Infab Corp    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 108228,
    "name": "Infant Advantage Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458549,
    "name": "Infection Prevention Technologies LLC    ( Auburn Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 292972,
    "name": "Inficon    ( East Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 431632,
    "name": "INFICON    ( East Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 162921,
    "name": "InfiMed Europe    ( Leusden  USA )",
    "country": "United States"
  },
  {
    "id": 157709,
    "name": "InfiMed Inc    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 460683,
    "name": "INFINISOURCE    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 458714,
    "name": "Infinite Software Solutions Inc    ( Staten Island NY USA )",
    "country": "United States"
  },
  {
    "id": 457814,
    "name": "Infinite Therapeutics    ( Kingston NH USA )",
    "country": "United States"
  },
  {
    "id": 451789,
    "name": "INFINITT Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456739,
    "name": "INFINITT Europe GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 451566,
    "name": "INFINITT North America Inc    ( Philipsburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 456416,
    "name": "Infinium Medical Inc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 461457,
    "name": "Inflexxion Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 391186,
    "name": "Influent Medical LLC    ( Concord NH USA )",
    "country": "United States"
  },
  {
    "id": 459112,
    "name": "Infoblox Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 467098,
    "name": "InFocusTech    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 461447,
    "name": "InfoEd Global    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 416376,
    "name": "Infolab Inc    ( Lyon MS USA )",
    "country": "United States"
  },
  {
    "id": 451830,
    "name": "InfoLogix Inc    ( Hatboro PA USA )",
    "country": "United States"
  },
  {
    "id": 454072,
    "name": "Infomed SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 336429,
    "name": "Infomedia    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 358208,
    "name": "InfoMedics    ( Reading MA USA )",
    "country": "United States"
  },
  {
    "id": 331327,
    "name": "InfoPartners Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 455986,
    "name": "Infopia Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451706,
    "name": "Infor Global Solutions (Australia) Pty Ltd    ( Pennant Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 451705,
    "name": "Infor Global Solutions Canada    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 451708,
    "name": "Infor Global Solutions UK    ( Studley  England )",
    "country": "UK"
  },
  {
    "id": 451704,
    "name": "Infor Global Solutions US    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 452124,
    "name": "Infor-Med Corp    ( Woodland Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 292255,
    "name": "InfoRad Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 363791,
    "name": "Information Data Management    ( Rosemont IL USA )",
    "country": "United States"
  },
  {
    "id": 168728,
    "name": "Infors AG    ( Bottmingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 415905,
    "name": "Infors Asia    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453929,
    "name": "Infors Canada    ( Anjou PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 281189,
    "name": "Infors GmbH    ( Einsbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 415904,
    "name": "Infors Italia srl    ( Doetinchem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 415903,
    "name": "Infors Sarl    ( Massy Cedex  France )",
    "country": "France"
  },
  {
    "id": 415902,
    "name": "Infors UK    ( Reigate  England )",
    "country": "UK"
  },
  {
    "id": 384343,
    "name": "InfoSys Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 461440,
    "name": "InfoTouch Corp    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 458709,
    "name": "Infotronic Datatech    ( Geesteren  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 227233,
    "name": "Infotronic Medical/Industrial Research and Develop    ( Tubbergen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458846,
    "name": "InfoTronics Inc    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 470571,
    "name": "Infoware Inc    ( Madison MS USA )",
    "country": "United States"
  },
  {
    "id": 398221,
    "name": "INFRA SA de CV    ( Naucalpan de Juarez  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 287180,
    "name": "Inframed Medical GmbH    ( Wehrheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457625,
    "name": "InfraRed Imaging Systems Inc    ( Marysville OH USA )",
    "country": "United States"
  },
  {
    "id": 457171,
    "name": "Infraredx Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 466879,
    "name": "InfraScan Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 455985,
    "name": "Infunix Technology Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 306377,
    "name": "Infus Medical (Thailand) Co Ltd    ( Samutprakarn  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 374371,
    "name": "Infusion Dynamics Inc    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 457110,
    "name": "InfuSystem    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 457111,
    "name": "InfuSystem Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 283666,
    "name": "Ing M Ohlenschlaeger Medizintechnik    ( Koenigstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 455596,
    "name": "Ingenieurbuero Rainer Beuth    ( Zwoenitz  Germany )",
    "country": "Germany"
  },
  {
    "id": 461255,
    "name": "Ingenious Med    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 157683,
    "name": "Ingenjoersfirman Bjoern Bergdahl AB    ( Billdal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453949,
    "name": "INGfertility    ( Valleyford WA USA )",
    "country": "United States"
  },
  {
    "id": 466244,
    "name": "Inglett & Stubbs LLC    ( Mableton 52 USA )",
    "country": "United States"
  },
  {
    "id": 170749,
    "name": "Ingmar Medical    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 286339,
    "name": "Ingold GmbH    ( Steinbach Ts  USA )",
    "country": "United States"
  },
  {
    "id": 453680,
    "name": "InHealth Technologies    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 456611,
    "name": "Inion Inc    ( Weston FL USA )",
    "country": "United States"
  },
  {
    "id": 456609,
    "name": "Inion Oy    ( Tampere  Finland )",
    "country": "Finland"
  },
  {
    "id": 452352,
    "name": "Initiate Systems Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 999764,
    "name": "Injector Support and Service, LLC    ( Orlando FL USA )",
    "country": "USA"
  },
  {
    "id": 331135,
    "name": "Inland Assoc Inc    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 271588,
    "name": "Inlet Medical Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 461387,
    "name": "InMage Systems Inc    ( Hoschton GA USA )",
    "country": "United States"
  },
  {
    "id": 108777,
    "name": "Inmark Corp    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 178338,
    "name": "Inmed Ltda    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 459121,
    "name": "InMon Corp    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 458977,
    "name": "Innara Health    ( Shawnee KS USA )",
    "country": "United States"
  },
  {
    "id": 462729,
    "name": "Inner Space Systems Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 454543,
    "name": "InnerCool Therapies    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 462727,
    "name": "Innerface Architectural Signage Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 187951,
    "name": "Innerspace Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 401729,
    "name": "InnerSpace/Datel Corp    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 263303,
    "name": "InnerVision MRI Ltd    ( Keighley  England )",
    "country": "UK"
  },
  {
    "id": 453870,
    "name": "InnerWireless Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 162650,
    "name": "Innherred Produkter A/S    ( Levanger  Norway )",
    "country": "Norway"
  },
  {
    "id": 255978,
    "name": "Innocept Medizintechnik AG    ( Gladbeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 455983,
    "name": "Innochips Technology Co Ltd    ( Ansan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 397931,
    "name": "Innogenetics Diagnostica y Terapeutica SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 397930,
    "name": "Innogenetics GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 397928,
    "name": "Innogenetics Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 163961,
    "name": "Innogenetics NV    ( Gent  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 397932,
    "name": "Innogenetics Sarl    ( Les Ulis  France )",
    "country": "France"
  },
  {
    "id": 397929,
    "name": "Innogenetics srl    ( Pomezia (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 330829,
    "name": "Innokas Medical Oy    ( Kempele  USA )",
    "country": "United States"
  },
  {
    "id": 453600,
    "name": "InnoMed    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 450605,
    "name": "Innomed Inc    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 150026,
    "name": "Innomed Medical Inc    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 270898,
    "name": "InnoMed Technologies Inc    ( Coconut Creek FL USA )",
    "country": "United States"
  },
  {
    "id": 405964,
    "name": "Innominata dba GenBio    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 455821,
    "name": "INNOPSYS    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 455820,
    "name": "INNOPSYS SA    ( Carbonne  France )",
    "country": "France"
  },
  {
    "id": 458027,
    "name": "Innotech Products Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 174638,
    "name": "Innotech Rehabilitation Products Inc    ( Orillia ON Canada )",
    "country": "Canada"
  },
  {
    "id": 417214,
    "name": "Innova LifeSciences (Asia Pacific)    ( Double Day  USA )",
    "country": "United States"
  },
  {
    "id": 417244,
    "name": "Innova LifeSciences (Europe)    ( Pershore  USA )",
    "country": "United States"
  },
  {
    "id": 328582,
    "name": "Innova LifeSciences Corp    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 458740,
    "name": "INNOVA Medical Ophthalmics    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459122,
    "name": "Innova Systems Inc    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 435602,
    "name": "Innovacion Medica Especializada    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 451727,
    "name": "Innovacon Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 466540,
    "name": "Innovasis    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 453069,
    "name": "InnovaSystems International LLC    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456707,
    "name": "Innovatech Medical Resources LP    ( Midlothian TX USA )",
    "country": "United States"
  },
  {
    "id": 455104,
    "name": "Innovatek Medical Inc    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 459123,
    "name": "Innovation Assoc    ( Johnson City NY USA )",
    "country": "United States"
  },
  {
    "id": 462724,
    "name": "Innovation Data Processing    ( Little Falls NJ USA )",
    "country": "United States"
  },
  {
    "id": 452603,
    "name": "Innovation Product Achievement    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 184008,
    "name": "Innovation Sports    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 454103,
    "name": "Innovatis AG    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 454105,
    "name": "Innovatis AG (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 454104,
    "name": "Innovatis Inc    ( Malvern  USA )",
    "country": "United States"
  },
  {
    "id": 450599,
    "name": "Innovative Chemical Corp    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 466268,
    "name": "Innovative Communications Systems LLC    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 365569,
    "name": "Innovative Control Systems-Accutech    ( Franklin WI USA )",
    "country": "United States"
  },
  {
    "id": 454903,
    "name": "Innovative Endoscopy Components LLC    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 456312,
    "name": "Innovative Gloves Co Ltd    ( Songkhla  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 174830,
    "name": "Innovative Imaging Inc    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 464992,
    "name": "Innovative Imaging Services Inc    ( Benton AR USA )",
    "country": "United States"
  },
  {
    "id": 453900,
    "name": "Innovative Implant Technology    ( Aventura FL USA )",
    "country": "United States"
  },
  {
    "id": 250661,
    "name": "Innovative Managed Care Systems Ltd    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 459194,
    "name": "Innovative Med Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 458535,
    "name": "Innovative Medical Products    ( Plainville CT USA )",
    "country": "United States"
  },
  {
    "id": 107733,
    "name": "Innovative Medical Products Inc (IMP)    ( Plainville CT USA )",
    "country": "United States"
  },
  {
    "id": 106908,
    "name": "Innovative Medical Systems Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 332722,
    "name": "Innovative Medical Technologies Inc    ( Leawood KS USA )",
    "country": "United States"
  },
  {
    "id": 454317,
    "name": "Innovative Neurotronics    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 458582,
    "name": "Innovative Neurotronics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 466635,
    "name": "Innovative Orthopedic Technologies LLC (IOT)    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 187164,
    "name": "Innovative Products Inc    ( Grand Forks ND USA )",
    "country": "United States"
  },
  {
    "id": 108379,
    "name": "Innovative Products Unlimited    ( Niles MI USA )",
    "country": "United States"
  },
  {
    "id": 458581,
    "name": "Innovative Sports Training Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 473843,
    "name": "Innovative Sterilization Technologies LLC    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 458924,
    "name": "Innovative Technology Inc    ( Amesbury MA USA )",
    "country": "United States"
  },
  {
    "id": 463032,
    "name": "Innovative Therapies Inc    ( Pompano Beach FL USA )",
    "country": "USA"
  },
  {
    "id": 458175,
    "name": "Innoventor Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 999756,
    "name": "Innoventor Inc.    ( St. Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 282439,
    "name": "Innovint Einrichtungs GmbH    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 454667,
    "name": "Innovision A/S    ( Odense S  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454666,
    "name": "Innovision GmbH    ( Kaltenkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454665,
    "name": "Innovision Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 185415,
    "name": "INNOW    ( Katowice  Poland )",
    "country": "Poland"
  },
  {
    "id": 398491,
    "name": "INO Therapeutics Inc    ( Clinton NJ USA )",
    "country": "United States"
  },
  {
    "id": 451037,
    "name": "Inogen Inc    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 458969,
    "name": "inomed Medizintechnik GmbH    ( Emmendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 466546,
    "name": "InoNet Computer GmbH    ( Taufkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279224,
    "name": "INOTEB SA    ( Levallois-Perret  USA )",
    "country": "United States"
  },
  {
    "id": 452742,
    "name": "Inotech Biosystems International Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 232709,
    "name": "INOVA Diagnostics Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 232708,
    "name": "Inova Labs Inc.    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 462894,
    "name": "Inova Solutions    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 462543,
    "name": "Inovalon Inc    ( Bowie MD USA )",
    "country": "United States"
  },
  {
    "id": 451406,
    "name": "Inovel LLC    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 382836,
    "name": "Inovise Medical Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 461213,
    "name": "Inovonics Wireless Corp    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 463043,
    "name": "Inpeco SA    ( Lugano  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452230,
    "name": "Inpeco SpA    ( Segrate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 356472,
    "name": "InPro Corp    ( Muskego WI USA )",
    "country": "United States"
  },
  {
    "id": 460642,
    "name": "InQuicker LLC    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 455563,
    "name": "Inrad Inc    ( Kentwood MI USA )",
    "country": "United States"
  },
  {
    "id": 458517,
    "name": "Inscopix    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 465029,
    "name": "Insight    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 199680,
    "name": "Insight Healthcare Information Systems    ( Encino CA USA )",
    "country": "United States"
  },
  {
    "id": 458551,
    "name": "Insight Imaging    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 178862,
    "name": "Insight Instruments Inc    ( Stuart FL USA )",
    "country": "United States"
  },
  {
    "id": 458006,
    "name": "Insight Opticians Ltd    ( Borehamwood  England )",
    "country": "UK"
  },
  {
    "id": 457565,
    "name": "Insight Pharmaceuticals LLC    ( Langhorne PA USA )",
    "country": "United States"
  },
  {
    "id": 446564,
    "name": "InSightec Inc    ( Tirat Carmel  Israel )",
    "country": "Israel"
  },
  {
    "id": 999516,
    "name": "Insignia    ( Richfield MN USA )",
    "country": "United States"
  },
  {
    "id": 999567,
    "name": "Insinger Machine Company    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 434258,
    "name": "InSiteOne Inc    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 321864,
    "name": "InSitu Technologies Inc    ( Inver Grove Heights MN USA )",
    "country": "United States"
  },
  {
    "id": 455105,
    "name": "InSound Medical Inc    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 333835,
    "name": "Inspec Tech    ( Valley Head AL USA )",
    "country": "United States"
  },
  {
    "id": 456512,
    "name": "Inspiration Healthcare Ltd    ( Earl Shilton  England )",
    "country": "UK"
  },
  {
    "id": 458662,
    "name": "InspiredLiving.com    ( Dundee OR USA )",
    "country": "United States"
  },
  {
    "id": 464810,
    "name": "InspireMD    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 155929,
    "name": "Insta-Just Seating    ( Brigham City UT USA )",
    "country": "United States"
  },
  {
    "id": 416374,
    "name": "Instant Diagnostics Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 343450,
    "name": "INSTEC-Sorvall Beijing Service Station    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458756,
    "name": "Instech Laboratories Inc    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 280938,
    "name": "Institut fur Lueft- und Kaeltetechnik Gemeinnuetzi    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 281759,
    "name": "Institut Straumann AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461011,
    "name": "Institute for Health Metrics and Evaluation    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 464094,
    "name": "Institute of HeartMath    ( Boulder Creek CO USA )",
    "country": "United States"
  },
  {
    "id": 291716,
    "name": "Institute of Optics & Electronics    ( Chengdu  USA )",
    "country": "United States"
  },
  {
    "id": 453967,
    "name": "Instituto em Diagnostico Molecular Theranostica Lt    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 469520,
    "name": "Instradent AG    ( Basel  Germany )",
    "country": "Germany"
  },
  {
    "id": 469519,
    "name": "Instradent USA Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 243356,
    "name": "Instramed    ( Porta Alegre-RS  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457745,
    "name": "Instratek Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 102814,
    "name": "Instromedix    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458874,
    "name": "Instron    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 459515,
    "name": "Instron TGT    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 274957,
    "name": "Instru-Med Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 277749,
    "name": "Instrucom Comercio de Produtos Cientificos Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 454139,
    "name": "Instrumatics Inc    ( Muskego WI USA )",
    "country": "United States"
  },
  {
    "id": 104852,
    "name": "InstruMed Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 144624,
    "name": "Instrumed International Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 144625,
    "name": "Instrument Service Laboratories    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 356610,
    "name": "Instrument Specialists Inc    ( Boerne TX USA )",
    "country": "United States"
  },
  {
    "id": 452645,
    "name": "Instrumentarium    ( Terrebonne PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 354734,
    "name": "Instrumentarium Corp ITKA    ( Lahti  USA )",
    "country": "United States"
  },
  {
    "id": 182844,
    "name": "Instrumentarium Dental Finland    ( Tuusula  Finland )",
    "country": "Finland"
  },
  {
    "id": 400967,
    "name": "Instrumentarium Dental France Sarl    ( Bonneuil sur Marne Cedex  France )",
    "country": "France"
  },
  {
    "id": 359986,
    "name": "Instrumentarium Dental GmbH    ( Schutterwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 107685,
    "name": "Instrumentarium Dental Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 400968,
    "name": "Instrumentarium Dental srl Italia    ( Desio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 397727,
    "name": "Instrumentarium Imaging    (   USA )",
    "country": "United States"
  },
  {
    "id": 103793,
    "name": "Instrumentation Industries Inc    ( Bethel Park PA USA )",
    "country": "United States"
  },
  {
    "id": 398971,
    "name": "Instrumentation Laboratory (Belgium) NV/SA    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 398965,
    "name": "Instrumentation Laboratory (Hong Kong)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 398964,
    "name": "Instrumentation Laboratory (Japan)    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 398966,
    "name": "Instrumentation Laboratory (Lietuva) BI    ( Kaunas  Lithuania )",
    "country": "Lithuania"
  },
  {
    "id": 398968,
    "name": "Instrumentation Laboratory (Netherlands) bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 398969,
    "name": "Instrumentation Laboratory (UK) Ltd    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 101922,
    "name": "Instrumentation Laboratory Co    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 398972,
    "name": "Instrumentation Laboratory Czech    ( Praha 10  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 398963,
    "name": "Instrumentation Laboratory Diagnostics SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 398970,
    "name": "Instrumentation Laboratory Ges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 283451,
    "name": "Instrumentation Laboratory GmbH    ( Kichheim bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 398967,
    "name": "Instrumentation Laboratory Poland    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 398973,
    "name": "Instrumentation Laboratory SA    ( Paris Cedex 12  France )",
    "country": "France"
  },
  {
    "id": 156052,
    "name": "Instrumentation Laboratory SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461272,
    "name": "Instrumented Sensor Technology Inc    ( Okemos MI USA )",
    "country": "United States"
  },
  {
    "id": 174090,
    "name": "Instrumentos Cientificos CG Ltd    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 101974,
    "name": "Instrutek Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 456542,
    "name": "INStuhl und Tisch GmbH    ( Herisau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458700,
    "name": "InstyMeds Corp    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 454492,
    "name": "Insulet Corp    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 450860,
    "name": "Intact Medical Corp    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 457815,
    "name": "Intact Vascular    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 282105,
    "name": "Intas GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455659,
    "name": "Intavent Direct    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 451679,
    "name": "Intavent Orthofix Ltd    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 233756,
    "name": "INTECK Corp    ( Papillion NE USA )",
    "country": "United States"
  },
  {
    "id": 452277,
    "name": "Intego Systems Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 272823,
    "name": "INTEGRA Biosciences AG    ( Zizers  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 345673,
    "name": "INTEGRA Biosciences GmbH    ( Fernwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 345674,
    "name": "INTEGRA Biosciences Sarl    ( Cergy-Pontoise Cedex  France )",
    "country": "France"
  },
  {
    "id": 451948,
    "name": "Integra Biotechnical LLC    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 150315,
    "name": "Integra LifeSciences    ( Plainsboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 462877,
    "name": "Integra LifeSciences Services    ( Saint Priest  France )",
    "country": "France"
  },
  {
    "id": 456822,
    "name": "Integra LS (Benelux) NV    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415793,
    "name": "Integra Medical Imaging (Aust) Pty Ltd    ( Mawson Lakes  USA )",
    "country": "United States"
  },
  {
    "id": 456823,
    "name": "Integra Miltex    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 416068,
    "name": "Integra NeuroSciences Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 456821,
    "name": "Integra NeuroSciences Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 418188,
    "name": "Integra NeuroSupplies Inc    ( Plainsboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 451529,
    "name": "Integra Radionics    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 451530,
    "name": "Integra Radionics Europe NV    ( Gent  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 455919,
    "name": "Integra Spine    ( Medina OH USA )",
    "country": "United States"
  },
  {
    "id": 455411,
    "name": "Integral Process    ( Conflans Sainte Honorine  France )",
    "country": "France"
  },
  {
    "id": 457790,
    "name": "Integral Systems Inc    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 332801,
    "name": "Integrated Biomedical Technology    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 462719,
    "name": "Integrated Dispensing Solutions (IDS) Inc    ( Agoura Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 462818,
    "name": "Integrated Genetics    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 441688,
    "name": "Integrated Healthware LLC    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 461108,
    "name": "Integrated Informatics    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 453677,
    "name": "Integrated Laboratory Automated Solutions Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 474851,
    "name": "Integrated Listening Systems    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 373547,
    "name": "Integrated Measurement Systems Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 450573,
    "name": "Integrated Medical Devices Inc    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 232587,
    "name": "Integrated Medical Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 299868,
    "name": "Integrated Medical Systems Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 457918,
    "name": "Integrated Medical Systems Inc    ( Signal Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 399295,
    "name": "Integrated Medical Systems Pty Ltd    ( South Brisbane  Australia )",
    "country": "Australia"
  },
  {
    "id": 373258,
    "name": "Integrated Modular Systems Inc    ( Havertown PA USA )",
    "country": "United States"
  },
  {
    "id": 473826,
    "name": "Integrated Oncology    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 149328,
    "name": "Integrated Orbital Implants Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 371732,
    "name": "Integrated Recovery Products    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 337289,
    "name": "Integrated Sciences Pty Ltd    ( Chatswood  Australia )",
    "country": "Australia"
  },
  {
    "id": 323004,
    "name": "Integrated Software Inc    ( Palm Bay FL USA )",
    "country": "United States"
  },
  {
    "id": 177769,
    "name": "Integrated Surgical Systems    ( Davis CA USA )",
    "country": "United States"
  },
  {
    "id": 407393,
    "name": "Integrated Surgical Systems SA    ( Bron  France )",
    "country": "France"
  },
  {
    "id": 464976,
    "name": "Integrated Systems and Services Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 458775,
    "name": "Integrated Voice Solutions    ( Wakefield MA USA )",
    "country": "United States"
  },
  {
    "id": 453839,
    "name": "InteGreat Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 454576,
    "name": "Integritas Inc    ( Monterey CA USA )",
    "country": "United States"
  },
  {
    "id": 458476,
    "name": "IntegriTech LLC    ( Reynoldsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 457118,
    "name": "Integrity BioMedical Services LLC    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 461222,
    "name": "Integrity Custom Concepts    ( Mishawaka IN USA )",
    "country": "United States"
  },
  {
    "id": 458198,
    "name": "Integrity Medical Devices Inc    ( Hammonton NJ USA )",
    "country": "United States"
  },
  {
    "id": 461526,
    "name": "Integrity Medical Solutions    ( Punta Gorda FL USA )",
    "country": "United States"
  },
  {
    "id": 108778,
    "name": "Integrity Medical Systems Inc    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 453597,
    "name": "Intel Corp    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 457728,
    "name": "Intel-GE Care Innovations LLC    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 472785,
    "name": "IntelaMetrix Inc    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 331335,
    "name": "Intelect Network Technologies    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 439572,
    "name": "Intelerad Medical Systems Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453939,
    "name": "InteliFUSE Inc    ( New Orleans LA USA )",
    "country": "United States"
  },
  {
    "id": 461294,
    "name": "Intellibot Robotics Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 451817,
    "name": "IntelliCare    ( South Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 458374,
    "name": "Intellicure Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 453828,
    "name": "IntelliCyt Corp    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 451934,
    "name": "IntelliDOT Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 402846,
    "name": "Intelligent Actuator Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 283255,
    "name": "Kas Haiss Kom Ges    ( Jungingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458766,
    "name": "Intelligent Computer Solutions Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 401964,
    "name": "Intelligent Hearing Systems    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 453623,
    "name": "Intelligent Hospital Systems    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 458396,
    "name": "Intelligent Imaging Innovations Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 455583,
    "name": "Intelligent InSites Inc    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 456048,
    "name": "Intelligent Medical Innovations    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 458914,
    "name": "Intelligent Medical Objects Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 459452,
    "name": "Intelligent Orthopaedics Ltd    ( Stoke-on-Trent  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 465022,
    "name": "Intelligent Power Solutions    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 461241,
    "name": "Intelligent Recording Ltd    ( Worksop  England )",
    "country": "UK"
  },
  {
    "id": 464807,
    "name": "Intelligent Weighing Technology    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 460928,
    "name": "Intellimed International Corp    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 461418,
    "name": "Intellinetics Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 452557,
    "name": "IntelliNetx    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 416540,
    "name": "IntelliRAD Solutions Pty Ltd    ( East Hawthorn  Australia )",
    "country": "Australia"
  },
  {
    "id": 452429,
    "name": "IntelliSoft Group Inc    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 457898,
    "name": "IntelliTech International Inc    ( Hudson MA USA )",
    "country": "United States"
  },
  {
    "id": 340383,
    "name": "Intensa, Inc.    ( High Point NC USA )",
    "country": "United States"
  },
  {
    "id": 168727,
    "name": "Intensiv SA    ( Montagnola  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459113,
    "name": "Intensive Therapeutics Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 473805,
    "name": "Inteplast Group Ltd    ( Livingston NJ USA )",
    "country": "United States"
  },
  {
    "id": 174640,
    "name": "Inter Medico    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 144626,
    "name": "Inter-Mountain Biomedical Services Inc    ( Kalispell MT USA )",
    "country": "United States"
  },
  {
    "id": 293781,
    "name": "Inter-Unitek AG    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 150984,
    "name": "Interacoustics A/S    ( Assens  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453826,
    "name": "Interacoustics Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 361066,
    "name": "Interacoustics USA    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 462722,
    "name": "InterAct    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 457161,
    "name": "Interacter Inc    ( Meriden CT USA )",
    "country": "United States"
  },
  {
    "id": 233774,
    "name": "Interactive Computer Products Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 458944,
    "name": "Interactive Fitness    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 457585,
    "name": "Interactive Metronome    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 451081,
    "name": "Interactive Motion Technologies Inc    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 458376,
    "name": "Interactive Solutions Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 457591,
    "name": "Interactive Touchscreen Solutions Inc    ( Crofton MD USA )",
    "country": "United States"
  },
  {
    "id": 458776,
    "name": "Interbit Data    ( Natick ME USA )",
    "country": "United States"
  },
  {
    "id": 382759,
    "name": "Interbros GmbH    ( Todtnau/Schwarzwald  USA )",
    "country": "United States"
  },
  {
    "id": 107710,
    "name": "Intercall Systems Inc    ( Garden City Park NY USA )",
    "country": "United States"
  },
  {
    "id": 286633,
    "name": "InterCo Ges mbH    ( Eitorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 466505,
    "name": "INTERCOM Inc    ( Coppell TX USA )",
    "country": "United States"
  },
  {
    "id": 280818,
    "name": "Intercom Systems GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 287352,
    "name": "Intercomfort Mobilmoebel    ( Wickede-Ruhr  USA )",
    "country": "United States"
  },
  {
    "id": 452826,
    "name": "InterComponentWare AG    ( Walldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 356613,
    "name": "Intercon 1    ( Baxter MN USA )",
    "country": "United States"
  },
  {
    "id": 458558,
    "name": "Intereum Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 265156,
    "name": "Interface Assoc    ( Laguna Niguel CA USA )",
    "country": "United States"
  },
  {
    "id": 461432,
    "name": "Interface People LP    ( Lewisville TX USA )",
    "country": "United States"
  },
  {
    "id": 103468,
    "name": "InterfaceFLOR Commercial USA    ( La Grange GA USA )",
    "country": "United States"
  },
  {
    "id": 466679,
    "name": "iNTERFACEWARE Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 286450,
    "name": "Interflex Datensysteme GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 462762,
    "name": "InterFocus Ltd    ( Linton  England )",
    "country": "UK"
  },
  {
    "id": 183934,
    "name": "Interkardio Pvt Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 455392,
    "name": "Interlace Medical    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 105616,
    "name": "Intermagnetics General Corp    ( Latham NY USA )",
    "country": "United States"
  },
  {
    "id": 346082,
    "name": "Intermec Technologies Corp    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 331338,
    "name": "InterMed Biomedical Services Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 174394,
    "name": "Intermed Equipamento Medico Hospitalar Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 309749,
    "name": "Intermed Europa SL    ( Lerida  Spain )",
    "country": "Spain"
  },
  {
    "id": 454622,
    "name": "Intermed Gas Products    ( Pampano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 288487,
    "name": "Intermed Japan Inc    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 194767,
    "name": "InterMed Labor & MedTech GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 457119,
    "name": "InterMed Nuclear Medicine Services Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 267250,
    "name": "INTERMED VIDEO TECHNOLOGIES    (   USA )",
    "country": "United States"
  },
  {
    "id": 150915,
    "name": "InterMed Video Technologies Inc    ( Newtown CT USA )",
    "country": "United States"
  },
  {
    "id": 999874,
    "name": "INTERmedic    (   USA )",
    "country": "United States"
  },
  {
    "id": 281327,
    "name": "Intermedica Medizin-Technik Thueringen GmbH    ( Erfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 102569,
    "name": "InterMetro Industries Corp    ( Wilkes Barre PA USA )",
    "country": "United States"
  },
  {
    "id": 454653,
    "name": "InterMetro Industries Corp    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 454654,
    "name": "InterMetro Industries Corp    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 454655,
    "name": "InterMetro Industries Corp    ( Coatesville PA USA )",
    "country": "United States"
  },
  {
    "id": 457875,
    "name": "International Biologics LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 456597,
    "name": "International Biomedical AG SA Ltd    ( Uetendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 150048,
    "name": "International Biomedical Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 104595,
    "name": "International Biomedics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 456558,
    "name": "International Biophysics Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 999490,
    "name": "International Business Solutions, Inc    ( Collierville TN USA )",
    "country": "United States"
  },
  {
    "id": 331143,
    "name": "International Clinics Ltda    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 462913,
    "name": "International Controls Systems Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 186014,
    "name": "International Equipment Trading Ltd    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 450927,
    "name": "International Health Resources    ( Grass Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 101370,
    "name": "International Hospital Products Inc    ( Little Silver NJ USA )",
    "country": "United States"
  },
  {
    "id": 186015,
    "name": "International Imaging Systems Inc    ( Saline MI USA )",
    "country": "United States"
  },
  {
    "id": 334730,
    "name": "International Immuno-Diagnostics    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 107169,
    "name": "International Industries Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 359903,
    "name": "International Isotopes Inc    ( Idaho Falls ID USA )",
    "country": "United States"
  },
  {
    "id": 324169,
    "name": "International Lamps Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 464961,
    "name": "International Light Technologies (ILT) Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 101373,
    "name": "International Medical Electronics Ltd    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 453909,
    "name": "International Medical Equipment    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 455628,
    "name": "International Medical Equipment & Service Inc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 227260,
    "name": "International Medical Group bv    ( Grave  USA )",
    "country": "United States"
  },
  {
    "id": 105901,
    "name": "International Medical Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 104377,
    "name": "International Medical Industries Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 171321,
    "name": "International Medical Intelligence (IMI) Co Ltd    ( Saitama  USA )",
    "country": "United States"
  },
  {
    "id": 283479,
    "name": "International Medical Products Deutschland GmbH    ( Kleve  Germany )",
    "country": "Germany"
  },
  {
    "id": 104648,
    "name": "International Medication Systems Ltd    ( South El Monte CA USA )",
    "country": "United States"
  },
  {
    "id": 456955,
    "name": "International Medsurg Connection Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 232376,
    "name": "International pbi SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 156484,
    "name": "International Products and Services (IPS) srl    ( San Donato Milanese (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452113,
    "name": "International Reporting Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 409921,
    "name": "International Safety Instruments    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 108970,
    "name": "International Sensor Technology    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 106045,
    "name": "International Shared Services (ISS)    ( Langhorne PA USA )",
    "country": "United States"
  },
  {
    "id": 458154,
    "name": "International Sight Restoration    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 102442,
    "name": "International Technidyne Corp    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 170439,
    "name": "International X-Ray Brokers    ( Brockton MA USA )",
    "country": "United States"
  },
  {
    "id": 461037,
    "name": "Internet Payment Exchange Inc    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 394749,
    "name": "Internexsys Corp    ( Walpole MA USA )",
    "country": "United States"
  },
  {
    "id": 452349,
    "name": "InterPage LP    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 104943,
    "name": "Interpore Cross International    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 462763,
    "name": "Interpreting and Consulting Services Inc    ( Benicia CA USA )",
    "country": "United States"
  },
  {
    "id": 282971,
    "name": "Interquad Computer GmbH    ( Heusenstamm  USA )",
    "country": "United States"
  },
  {
    "id": 306760,
    "name": "Intersan SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 108030,
    "name": "Interscan Corp    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 184063,
    "name": "InterSciences Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457561,
    "name": "Intersect ENT Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 186018,
    "name": "Interson Corp Medical Instruments    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 461189,
    "name": "Interspec Industries Ltd    ( Rosemont  Canada )",
    "country": "Canada"
  },
  {
    "id": 409919,
    "name": "Interspiro Inc    ( Pleasant Prairie WI USA )",
    "country": "United States"
  },
  {
    "id": 108382,
    "name": "Interstate Batteries    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 338410,
    "name": "Intersun Ltd    ( Ramat Hasharon  Israel )",
    "country": "Israel"
  },
  {
    "id": 452714,
    "name": "Intersurgical (Philippines) Inc    ( Metro Manila  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 370995,
    "name": "Intersurgical bv    ( Uden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 285934,
    "name": "Intersurgical GmbH    ( Sankt Augustin  Germany )",
    "country": "Germany"
  },
  {
    "id": 178129,
    "name": "Intersurgical Inc    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 452715,
    "name": "Intersurgical Inc    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 199063,
    "name": "Intersurgical Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 356763,
    "name": "Intersurgical Portugal Lda    ( Sintra  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 452716,
    "name": "Intersurgical RSA    ( Sandton  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 391486,
    "name": "Intersurgical SA    ( Mostoles (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 342044,
    "name": "Intersurgical Sarl    ( Fontenay-Sous-Bois Cedex  France )",
    "country": "France"
  },
  {
    "id": 449853,
    "name": "Intersurgical SRO    ( Praha 4  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 201632,
    "name": "Intersurgical UAB    ( Pabrade  Lithuania )",
    "country": "Lithuania"
  },
  {
    "id": 439948,
    "name": "Intersurgical ZAO    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 459014,
    "name": "InterSystems Corp    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 999489,
    "name": "Intertek    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 471590,
    "name": "Intertrade Imports    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 464959,
    "name": "InterValve Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 106452,
    "name": "InterVascular Inc    ( Mahwah NJ USA )",
    "country": "United States"
  },
  {
    "id": 391892,
    "name": "InterVascular SA    ( La Ciotat Cedex  France )",
    "country": "France"
  },
  {
    "id": 456448,
    "name": "Interventional Spine Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 458384,
    "name": "Intervid Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 233779,
    "name": "InterVoice-Brite Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 293579,
    "name": "Interzeag AG Medical Technology    ( Schlieren  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457780,
    "name": "Intio Inc    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 454578,
    "name": "Intivia Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 454812,
    "name": "InTouch Health    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 107596,
    "name": "Intoximeters    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 397729,
    "name": "Intoximeters UK Ltd    ( Totnes  England )",
    "country": "UK"
  },
  {
    "id": 285644,
    "name": "Intra Special Catheters GmbH    ( Rehlingen-Siersburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 108497,
    "name": "Intra-Sonix    (   USA )",
    "country": "United States"
  },
  {
    "id": 267032,
    "name": "Intracel Corp    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 458597,
    "name": "Intracellular Imaging Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 416200,
    "name": "IntraLase Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 382728,
    "name": "IntraLuminal Therapeutics Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 417429,
    "name": "IntraMedical Imaging LLC    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 452960,
    "name": "Intraop Medical Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 456160,
    "name": "Intraplant Medizinische Produkte GmbH    ( Moedling  Austria )",
    "country": "Austria"
  },
  {
    "id": 456392,
    "name": "IntraPump Infusion Systems    ( Grapevine TX USA )",
    "country": "United States"
  },
  {
    "id": 373200,
    "name": "IntraTech Dental Products Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 292268,
    "name": "Intrepid Videoconference Systems    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 457493,
    "name": "Intricon Corp    ( Arden Hills MN USA )",
    "country": "United States"
  },
  {
    "id": 457492,
    "name": "IntriCon Datrix Corp    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 471650,
    "name": "Intrinsic Therapeutics Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 454201,
    "name": "IntrinsiQ LLC    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454213,
    "name": "IntroMedic Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455793,
    "name": "Intromedix Advanced Medical Technologies Ltd    ( Netanya  Israel )",
    "country": "Israel"
  },
  {
    "id": 286066,
    "name": "Klaus Thoma Medizintechnik    ( Seefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 458932,
    "name": "Intronix Technologies Corp    ( Bolton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 106972,
    "name": "Introtek International Inc    ( Edgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 463018,
    "name": "IntuBrite    ( Vista CA USA )",
    "country": "USA"
  },
  {
    "id": 454577,
    "name": "Intuitive Medical Software LLC    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 377602,
    "name": "Intuitive Surgical Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 408707,
    "name": "Intuitive Surgical Inc (France)    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 462761,
    "name": "INUVIO Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 452236,
    "name": "Invacare A/S    ( Broendby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454882,
    "name": "Invacare Aquatec GmbH    ( Isny  Germany )",
    "country": "Germany"
  },
  {
    "id": 454841,
    "name": "Invacare Australia Pty Ltd    ( North Rocks  Australia )",
    "country": "Australia"
  },
  {
    "id": 329225,
    "name": "Invacare Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 348663,
    "name": "Invacare Continuing Care Group    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 101976,
    "name": "Invacare Corp    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 345629,
    "name": "Invacare Deutschland GmbH    ( Bad Oeynhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454888,
    "name": "Invacare Dolomite AB    ( Dio  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 322826,
    "name": "Invacare International    ( Fondeltes  France )",
    "country": "France"
  },
  {
    "id": 454880,
    "name": "Invacare International Sarl    ( Gland  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 330026,
    "name": "Invacare Ltd    ( Bridgend  Wales )",
    "country": "Wales"
  },
  {
    "id": 342073,
    "name": "Invacare New Zealand    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 454881,
    "name": "Invacare Portugal LL    ( Leca Do Balio  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 391303,
    "name": "Invacare Rea AB    ( Dio  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 394345,
    "name": "Invacare Supply Group    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 371914,
    "name": "Invacare Top End    ( Pinellas Park FL USA )",
    "country": "United States"
  },
  {
    "id": 237889,
    "name": "INVAP SE    ( San Carlos de Bariloche  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 454151,
    "name": "INVATIS Bioanalytical Instruments AG    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 466684,
    "name": "invendo medical Inc    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 416141,
    "name": "Invensys Building Systems Inc    ( Loves Park IL USA )",
    "country": "United States"
  },
  {
    "id": 458651,
    "name": "Inventive Medical Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 453898,
    "name": "Inverness Medical Innovations Australia Pty Ltd    ( Sinnamon Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 384682,
    "name": "Inverness Medical Innovations Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 451440,
    "name": "Inverness Medical Professional Diagnostics    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 453393,
    "name": "Inverness Medical-BioStar Inc    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 452909,
    "name": "Invictus Scientific Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 104207,
    "name": "Invincible Office Furniture    ( Manitowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 451768,
    "name": "Inviro Medical    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 467213,
    "name": "Invitech Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 451612,
    "name": "Invitrogen - Europe    ( Breda  USA )",
    "country": "United States"
  },
  {
    "id": 394747,
    "name": "Invitrogen Corp    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 308478,
    "name": "Invivo Corp    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 455528,
    "name": "Invivo Germany GmbH    ( Schwerin  Germany )",
    "country": "Germany"
  },
  {
    "id": 455541,
    "name": "Invivo International    ( Best  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 186581,
    "name": "Invivo Research Inc (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 450737,
    "name": "Invivo Research Inc (Switzerland)    ( Ermatingen  USA )",
    "country": "United States"
  },
  {
    "id": 384296,
    "name": "Invivo Research UK Ltd    ( Halifax  USA )",
    "country": "United States"
  },
  {
    "id": 330021,
    "name": "Invotec International Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457705,
    "name": "Invuity    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 456366,
    "name": "Inwoo Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459125,
    "name": "IoA Healthcare Furniture    ( Thomasville NC USA )",
    "country": "United States"
  },
  {
    "id": 435462,
    "name": "IOCH RAN Bruker Ltd    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 459470,
    "name": "IOD Inc    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 457360,
    "name": "IOGEAR    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 999538,
    "name": "IOGEAR    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 384278,
    "name": "Ioltech Laboratoires    ( La Rochelle Cedex 9  France )",
    "country": "France"
  },
  {
    "id": 107072,
    "name": "IOMED Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 151041,
    "name": "Ion Beam Applications SA    ( Louvain-la-Neuve  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456951,
    "name": "Ion Genius Inc    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 174631,
    "name": "Ion-Trace Inc    ( Stouffville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461251,
    "name": "IonField Systems Inc    ( Moorestown NJ USA )",
    "country": "United States"
  },
  {
    "id": 453565,
    "name": "IonFusion Surgical    ( Lakeside CA USA )",
    "country": "United States"
  },
  {
    "id": 451276,
    "name": "Ionics EMS Inc    ( Laguna  USA )",
    "country": "United States"
  },
  {
    "id": 459178,
    "name": "IonOptix LLC    ( Milton MA USA )",
    "country": "United States"
  },
  {
    "id": 285510,
    "name": "Ionpure Technologies GmbH    ( Ransbach Baumbach  USA )",
    "country": "United States"
  },
  {
    "id": 333576,
    "name": "IOP Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 458952,
    "name": "IOPI Medical LLC    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 999747,
    "name": "IOPI Medical LLC.    ( Redmond WA USA )",
    "country": "USA"
  },
  {
    "id": 331150,
    "name": "IOtech Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 458598,
    "name": "IPA    ( Suwanee GA USA )",
    "country": "United States"
  },
  {
    "id": 403717,
    "name": "IPAS USA    ( Chapel Hill NC USA )",
    "country": "United States"
  },
  {
    "id": 458085,
    "name": "IPC Inc    ( Pembina ND USA )",
    "country": "United States"
  },
  {
    "id": 377118,
    "name": "IPG Hospital Beds    ( Tel Aviv  USA )",
    "country": "United States"
  },
  {
    "id": 461426,
    "name": "IPG Photonics Corp    ( Oxford MA USA )",
    "country": "United States"
  },
  {
    "id": 454787,
    "name": "IPRO Austria    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 454784,
    "name": "IPRO France    ( Ingwiller  France )",
    "country": "France"
  },
  {
    "id": 454781,
    "name": "IPRO GmbH    ( Leonberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454786,
    "name": "IPRO Hungry    ( Szada  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 454785,
    "name": "IPRO Netherlands    ( Vaals  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454783,
    "name": "IPRO UK    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 454070,
    "name": "IPSOGEN Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 454069,
    "name": "IPSOGEN SA    ( Marseille Cedex 09  France )",
    "country": "France"
  },
  {
    "id": 459129,
    "name": "Ipswitch File Transfer    ( Lexington MA USA )",
    "country": "United States"
  },
  {
    "id": 418636,
    "name": "IQ TeQ International    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 461270,
    "name": "IQAir AG    ( Goldach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461256,
    "name": "IQAir North America    ( La Mirada CA USA )",
    "country": "United States"
  },
  {
    "id": 451773,
    "name": "IQMax Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 452644,
    "name": "iQuire LLC    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 453885,
    "name": "IRadimed Corp    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 451231,
    "name": "Iran Needle Co    ( Tehran  USA )",
    "country": "United States"
  },
  {
    "id": 461244,
    "name": "iRCODER.COM    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 270613,
    "name": "IRIDEX Corp    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 456445,
    "name": "IRIS Diagnostics    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 457990,
    "name": "IRIS Diagnostics France SA    ( Roissy Charles de Gaulle  France )",
    "country": "France"
  },
  {
    "id": 462819,
    "name": "Iris ID Systems Inc    ( Cranbury NJ USA )",
    "country": "United States"
  },
  {
    "id": 312096,
    "name": "IRIS Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 277160,
    "name": "Iris Medical Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 312346,
    "name": "IRIS Sample Processing    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 393138,
    "name": "Irlandox Laboratorios Quimica Analitica Ltda    ( Porto  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 284637,
    "name": "Irmtraud Schienagel Apparate fuer Forschung und    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 402313,
    "name": "IRN    ( Los Alamitos CA USA )",
    "country": "United States"
  },
  {
    "id": 409767,
    "name": "Iron Duck    ( Chicopee MA USA )",
    "country": "United States"
  },
  {
    "id": 462703,
    "name": "Iron Mountain Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 453979,
    "name": "Iroquois Hoods    ( Muskegon MI USA )",
    "country": "United States"
  },
  {
    "id": 233781,
    "name": "IRP Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 177051,
    "name": "Irradia AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455407,
    "name": "IRRIMAX Corp    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 452643,
    "name": "IRSG    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 169606,
    "name": "Irvine Scientific    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 356920,
    "name": "IS2 Medical Systems    ( Ottawa ON USA )",
    "country": "United States"
  },
  {
    "id": 452392,
    "name": "Isabel Healthcare Inc    ( Falls Church VA USA )",
    "country": "United States"
  },
  {
    "id": 454579,
    "name": "iSalus healthcare    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 458716,
    "name": "ISCAN Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 467316,
    "name": "iSchemaView Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 456901,
    "name": "iScience Interventional    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 103277,
    "name": "Isco Inc    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 179093,
    "name": "Isco Inc Europe    ( Hengoed  USA )",
    "country": "United States"
  },
  {
    "id": 461049,
    "name": "iScope Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 371742,
    "name": "iScreen LLC    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 458565,
    "name": "ISEC Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 293306,
    "name": "Isele & Co    ( Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 289706,
    "name": "ISEN Electronics Co Ltd    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 454422,
    "name": "Isensix Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457321,
    "name": "ISG Intermed Service GmbH & Co KG    ( Geesthacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 462889,
    "name": "iSimulate USA    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 454482,
    "name": "iSirona LLC    ( Panama City FL USA )",
    "country": "United States"
  },
  {
    "id": 403703,
    "name": "ISIS Medical Ltd    ( Wigan  USA )",
    "country": "United States"
  },
  {
    "id": 403704,
    "name": "ISIS Medical Ltd (Germany)    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 454012,
    "name": "ISIS Medical Solutions LLC    ( Tabernacle NJ USA )",
    "country": "United States"
  },
  {
    "id": 465086,
    "name": "Iskra Medical D o o    ( Ljublljana  Slovenia )",
    "country": "Slovenia"
  },
  {
    "id": 361930,
    "name": "Island Corp    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454768,
    "name": "iSOFT (NZ) Ltd    ( Wellington  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 446570,
    "name": "iSOFT Deutschland GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454766,
    "name": "iSOFT Group Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 440107,
    "name": "iSOFT Group plc    ( Banbury  England )",
    "country": "UK"
  },
  {
    "id": 446571,
    "name": "iSOFT Netherlands bv    ( Leiden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 446572,
    "name": "iSOFT Switzerland GmbH    ( Schwerzenbach  USA )",
    "country": "United States"
  },
  {
    "id": 466772,
    "name": "Isokinetics Inc    ( De Queen AR USA )",
    "country": "United States"
  },
  {
    "id": 457025,
    "name": "ISOL Technology Inc    ( Gwangju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461273,
    "name": "Isolated Power Panel Services LLC    ( Lewisville TX USA )",
    "country": "United States"
  },
  {
    "id": 457760,
    "name": "Isolite Systems    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 383561,
    "name": "Isolux LLC    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 457088,
    "name": "ISOMED Inc    ( Kirkland WA USA )",
    "country": "United States"
  },
  {
    "id": 457096,
    "name": "iSonea Ltd (Australia)    ( Armadale  Australia )",
    "country": "Australia"
  },
  {
    "id": 457094,
    "name": "iSonea Ltd (United States)    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 999520,
    "name": "iSonic Inc.    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 168820,
    "name": "Isoplast AG    ( Brugg  USA )",
    "country": "United States"
  },
  {
    "id": 362050,
    "name": "Isopure Corp    ( Simpsonville KY USA )",
    "country": "United States"
  },
  {
    "id": 458615,
    "name": "IsoRay Medical Inc    ( Richland WA USA )",
    "country": "United States"
  },
  {
    "id": 458731,
    "name": "Isotech Design Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999844,
    "name": "IsoTech Design Inc.    ( Montreal  Canada )",
    "country": "Canada"
  },
  {
    "id": 442206,
    "name": "IsoTis International    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 442205,
    "name": "IsoTis OrthoBiologics USA    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 139385,
    "name": "Isotopen-Technik Dr Sauerwein GmbH    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 416485,
    "name": "Isotron Nederland bv    ( Ede  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 162980,
    "name": "Israflow Ltd    ( Herzliya  Israel )",
    "country": "Israel"
  },
  {
    "id": 458715,
    "name": "ISS Inc    ( Champaign IL USA )",
    "country": "United States"
  },
  {
    "id": 463031,
    "name": "ISTO Technologies Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 452821,
    "name": "ISU UBCare Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 329972,
    "name": "ITA-MED Co    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 441564,
    "name": "ITA-MED Russia    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 279232,
    "name": "ITAC    ( Toulon  USA )",
    "country": "United States"
  },
  {
    "id": 306397,
    "name": "Italray srl    ( Scandicci (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 439183,
    "name": "Itamar Medical Inc    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 453610,
    "name": "Itamar Medical Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 401520,
    "name": "ITC Europe    ( Rodano MI  Italy )",
    "country": "Italy"
  },
  {
    "id": 454771,
    "name": "ITD Corp    ( Prospect CT USA )",
    "country": "United States"
  },
  {
    "id": 454770,
    "name": "ITD GmbH    ( Unterhaching  Germany )",
    "country": "Germany"
  },
  {
    "id": 281229,
    "name": "ITE Intertechnik Elze GmbH & Co KG    ( Elze  Germany )",
    "country": "Germany"
  },
  {
    "id": 455981,
    "name": "ITI Technology Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 156029,
    "name": "ITO Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 439527,
    "name": "ITO Co Ltd (Hanoi)    ( Hanoi  Vietnam )",
    "country": "Vietnam"
  },
  {
    "id": 439525,
    "name": "ITO Co Ltd (Shanghai)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 173130,
    "name": "ITO JV International (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 281666,
    "name": "ITS Compagnie Internationale de Services de    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 457467,
    "name": "ITS GmbH    ( Lassnitzhoehe/Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 465051,
    "name": "ITS Partners    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 452789,
    "name": "ITT Corp    ( White Plains NY USA )",
    "country": "USA"
  },
  {
    "id": 431612,
    "name": "ITT Industries    ( Herndon VA USA )",
    "country": "United States"
  },
  {
    "id": 451085,
    "name": "ITW Dymon    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 401930,
    "name": "ITX Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 462697,
    "name": "IUL SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 463030,
    "name": "iUltrasonic    ( South Orange NJ USA )",
    "country": "United States"
  },
  {
    "id": 403385,
    "name": "IV House Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 171150,
    "name": "IV League Medical    ( Oxnard CA USA )",
    "country": "United States"
  },
  {
    "id": 271717,
    "name": "IV Technologies Inc    ( Upperville VA USA )",
    "country": "United States"
  },
  {
    "id": 452104,
    "name": "IV Technologies Inc    ( Upperville VA USA )",
    "country": "United States"
  },
  {
    "id": 101379,
    "name": "Ivac Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 457932,
    "name": "Ivancic i sinovi doo    ( Belgrade  Serbia )",
    "country": "Serbia"
  },
  {
    "id": 458135,
    "name": "Ivantis Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 394312,
    "name": "IVAX Diagnostics Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 104206,
    "name": "Ivek Corp    ( North Springfield VT USA )",
    "country": "United States"
  },
  {
    "id": 457925,
    "name": "Ivera Medical Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 168744,
    "name": "IVF Hartmann AG    ( Neuhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999462,
    "name": "Ivivi Technologies Inc    ( Montvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 102444,
    "name": "IVM (In Vivo Metric)    ( Healdsburg CA USA )",
    "country": "United States"
  },
  {
    "id": 457697,
    "name": "Ivoclar Vivadent (Shanghai) Trading Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452031,
    "name": "Ivoclar Vivadent AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 333016,
    "name": "Ivoclar Vivadent AG    ( Schaan  Liechtenstein )",
    "country": "Liechtenstein"
  },
  {
    "id": 397753,
    "name": "Ivoclar Vivadent GmbH    ( Ellwangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 397756,
    "name": "Ivoclar Vivadent Inc    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 397755,
    "name": "Ivoclar Vivadent Inc (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452030,
    "name": "Ivoclar Vivadent Ltd    ( Enderby  England )",
    "country": "UK"
  },
  {
    "id": 457698,
    "name": "Ivoclar Vivadent Ltda    ( Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 397752,
    "name": "Ivoclar Vivadent Nordic AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 397754,
    "name": "Ivoclar Vivadent Pty Ltd    ( Noble Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 452032,
    "name": "Ivoclar Vivadent SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 452034,
    "name": "Ivoclar Vivadent SAS    ( Saint-Jorioz  France )",
    "country": "France"
  },
  {
    "id": 452033,
    "name": "Ivoclar Vivadent Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 452035,
    "name": "Ivoclar Vivadent srl & C sas    ( Naturno (BZ)  Italy )",
    "country": "Italy"
  },
  {
    "id": 105952,
    "name": "Ivy Biomedical Systems Inc    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 457542,
    "name": "iWalk Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 105065,
    "name": "Iwatsu Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 453904,
    "name": "iWorx Systems Inc    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 466794,
    "name": "Ix Medical Inc    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 453905,
    "name": "Izasa SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 331352,
    "name": "IZI Medical Products Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 459066,
    "name": "izzy+    ( Spring Lake MI USA )",
    "country": "United States"
  },
  {
    "id": 105007,
    "name": "J & H Berge Inc    ( South Plainfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 102837,
    "name": "J & J Engineering Inc    ( Poulsbo WA USA )",
    "country": "United States"
  },
  {
    "id": 144640,
    "name": "J & R Enterprises    ( Murray KY USA )",
    "country": "United States"
  },
  {
    "id": 106047,
    "name": "J & S Medical Assoc Inc    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 398103,
    "name": "J A Caruana Ltd    ( Marsascala  USA )",
    "country": "United States"
  },
  {
    "id": 286993,
    "name": "J C Baumann Technische Reha-Hilfen    ( Vaihingen an der Enz  Germany )",
    "country": "Germany"
  },
  {
    "id": 306305,
    "name": "J C Binzer Papierfabrik GmbH & Co KG    ( Hatzfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 171244,
    "name": "J D Honigberg International Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 280782,
    "name": "J Foerster Etikettendruck    ( Denkendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 186027,
    "name": "J Gravengaard Corp    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 101244,
    "name": "J H Emerson Co    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 157664,
    "name": "J H Orsing AB    ( Raa-Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 154142,
    "name": "J L Shepherd and Assoc    ( San Fernando CA USA )",
    "country": "United States"
  },
  {
    "id": 457391,
    "name": "J Lange Strohmatratzen und Verpackungen    ( Willebadessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 186029,
    "name": "J M Baragano Biomedical PM & Consulting Inc    ( San Juan PR USA )",
    "country": "United States"
  },
  {
    "id": 178477,
    "name": "J M Sidebottom Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 407175,
    "name": "J Morita Brasil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 288539,
    "name": "J Morita Corp    ( Suita  Japan )",
    "country": "Japan"
  },
  {
    "id": 321046,
    "name": "J Morita Corp (Thailand)    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 322778,
    "name": "J Morita Europe GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 407176,
    "name": "J Morita Middle East    ( Alexandria  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 182834,
    "name": "J Morita USA Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 370454,
    "name": "J P Selecta SA    ( Abrera (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 293054,
    "name": "J R Maruani    ( Gonesse  France )",
    "country": "France"
  },
  {
    "id": 451656,
    "name": "J Sterling Industries Ltd    ( Woodbridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 357389,
    "name": "J-KEM Scientific Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 451196,
    "name": "J-Pac LLC    ( Somersworth NH USA )",
    "country": "United States"
  },
  {
    "id": 999780,
    "name": "J. K. Hoppl Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 108310,
    "name": "JAC Healthcare Products    ( Vernon CA USA )",
    "country": "United States"
  },
  {
    "id": 457757,
    "name": "JAC Medical Products Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 458119,
    "name": "Jac-cell Medic    ( Mont Royal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999624,
    "name": "Jac-cell Medic    ( Qu_bec  Canada )",
    "country": "Canada"
  },
  {
    "id": 161982,
    "name": "JACE Systems    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 452944,
    "name": "Jackson Safety Inc    ( Fenton MO USA )",
    "country": "United States"
  },
  {
    "id": 465008,
    "name": "Jackson WWS Inc    ( Barbourville KY USA )",
    "country": "United States"
  },
  {
    "id": 454951,
    "name": "JACO Inc    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 186032,
    "name": "Jaco Medical Equipment Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 454974,
    "name": "Jacobs Chucks Mfg Co    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 461279,
    "name": "Jacobs Ladder LLC    ( North Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 401023,
    "name": "Jacomex SA de CV    ( Tijuana  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 103474,
    "name": "Jacuzzi Whirlpool Bath    (   USA )",
    "country": "United States"
  },
  {
    "id": 162072,
    "name": "Jaeger & Sohn GmbH    ( Muehlacker  USA )",
    "country": "United States"
  },
  {
    "id": 183332,
    "name": "Jaeger Industrial Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 347663,
    "name": "Jaeger USA    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 465212,
    "name": "Jaenchen Medical Resources    ( Long Lake 25 USA )",
    "country": "United States"
  },
  {
    "id": 158495,
    "name": "Jahn GmbH    ( Leimen  Germany )",
    "country": "Germany"
  },
  {
    "id": 177666,
    "name": "Kodak Ltd    ( Hemel Hempstead  USA )",
    "country": "United States"
  },
  {
    "id": 461235,
    "name": "JAI Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 335243,
    "name": "Jaisons International Inc    ( Trumbull CT USA )",
    "country": "United States"
  },
  {
    "id": 107353,
    "name": "Jaken Medical Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 158573,
    "name": "Jakoubek Instrumente    ( Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 367441,
    "name": "Jakoubek Medizintechnik GmbH    ( Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 463071,
    "name": "Jamar Health Products Inc    ( Greendale WI USA )",
    "country": "United States"
  },
  {
    "id": 458918,
    "name": "Jambeyang Research LLC    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 105254,
    "name": "James Alexander Corp    ( Blairstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 176272,
    "name": "James Consolidated Inc    ( Walnut Creek CA USA )",
    "country": "United States"
  },
  {
    "id": 348594,
    "name": "James Leckey Design Ltd    ( Dunmurry  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 455092,
    "name": "James Spencer & Co Ltd    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 103472,
    "name": "Jamestown Metal Products    ( Jamestown NY USA )",
    "country": "United States"
  },
  {
    "id": 461290,
    "name": "Jamesway Incubator Co Ltd    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 999953,
    "name": "Jan L Company, Inc    ( Mt Holly NJ USA )",
    "country": "United States"
  },
  {
    "id": 106725,
    "name": "Jan L Inc    ( Mount Holly NJ USA )",
    "country": "United States"
  },
  {
    "id": 461425,
    "name": "Janis Research Co    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 148788,
    "name": "Janke & Kunkel GmbH    ( Staufen  Germany )",
    "country": "Germany"
  },
  {
    "id": 106376,
    "name": "Jannx Medical Systems Inc    ( Kirkwood MO USA )",
    "country": "United States"
  },
  {
    "id": 454621,
    "name": "Jansen Medical Supply LLC    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 465108,
    "name": "Janssen Diagnostics LLC    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 126753,
    "name": "Janssen Pharmaceutica    ( Titusville NJ USA )",
    "country": "United States"
  },
  {
    "id": 416377,
    "name": "Jantek Engineering Inc    ( Paso Robles CA USA )",
    "country": "United States"
  },
  {
    "id": 399188,
    "name": "Japan Lifeline Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454526,
    "name": "Japan Materials Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 187058,
    "name": "Japan MDM Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455953,
    "name": "Japan Stent Technology Co Ltd    ( Okayama  Japan )",
    "country": "Japan"
  },
  {
    "id": 182810,
    "name": "Japlar/Schauer    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 450976,
    "name": "Jarden Corp    ( Rye NY USA )",
    "country": "United States"
  },
  {
    "id": 453386,
    "name": "Jardine Enterprises    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 149103,
    "name": "Jardon Eye Prosthetics Inc    ( Southfield MI USA )",
    "country": "United States"
  },
  {
    "id": 417213,
    "name": "Jari Electrode Supply    ( Gilroy CA USA )",
    "country": "United States"
  },
  {
    "id": 103281,
    "name": "JARIT Surgical Instruments    ( Hawthorne NY USA )",
    "country": "United States"
  },
  {
    "id": 461427,
    "name": "Jarvik Heart Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 418521,
    "name": "JASCO (UK) Ltd    ( Great Dunmow  England )",
    "country": "UK"
  },
  {
    "id": 418525,
    "name": "JASCO Analitica Spain SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 108779,
    "name": "JASCO Battery Specialists Inc    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 418524,
    "name": "JASCO Benelux bv    ( de Meern  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 137956,
    "name": "JASCO Corp    ( Hachioji  Japan )",
    "country": "Japan"
  },
  {
    "id": 418523,
    "name": "JASCO Eastern Europe Inf    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 152763,
    "name": "JASCO Europe srl    ( Cremella (LC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418522,
    "name": "JASCO France Sarl    ( Bouguenals  France )",
    "country": "France"
  },
  {
    "id": 102714,
    "name": "JASCO Inc    ( Easton MD USA )",
    "country": "United States"
  },
  {
    "id": 185935,
    "name": "Kodak-Pathe    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 175324,
    "name": "JASCO International Co Ltd    ( Hachioji-shi  Japan )",
    "country": "Japan"
  },
  {
    "id": 158496,
    "name": "JASCO Labor-und Datentechnik GmbH Deutschland    ( Gross-Umstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 456440,
    "name": "Jay Bird Investments Inc    ( Layton UT USA )",
    "country": "United States"
  },
  {
    "id": 308106,
    "name": "Jay International    ( Bromley  England )",
    "country": "UK"
  },
  {
    "id": 144646,
    "name": "Jay Medical Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 459462,
    "name": "Jaytec Glass Ltd    ( Hastings  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 455406,
    "name": "JAZ Innovations    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 461133,
    "name": "JB Developers Inc    ( Folsom CA USA )",
    "country": "United States"
  },
  {
    "id": 461428,
    "name": "JBT Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 465228,
    "name": "JC White    ( - - USA )",
    "country": "United States"
  },
  {
    "id": 288538,
    "name": "JCB Industry Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 186035,
    "name": "JCF Engineering Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 452093,
    "name": "JCM Seating Solutions    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 331358,
    "name": "JD Imaging Corp    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 356617,
    "name": "JDI Solutions Inc    ( Brevard NC USA )",
    "country": "United States"
  },
  {
    "id": 458761,
    "name": "JDS Uniphase Corp    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 461429,
    "name": "Jean Jurek Assoc Inc    ( Clarence NY USA )",
    "country": "United States"
  },
  {
    "id": 282447,
    "name": "Jebsen & Jessen GmbH & Co KG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 103283,
    "name": "Jedmed Instrument Co    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 106237,
    "name": "Jefferson X-Ray Services Inc    ( Kenner LA USA )",
    "country": "United States"
  },
  {
    "id": 293208,
    "name": "Jeger AG Apparatebau    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455910,
    "name": "Jei Daniel (JD) Biotech    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 175806,
    "name": "JEI Inc    ( Cameron Park CA USA )",
    "country": "United States"
  },
  {
    "id": 456028,
    "name": "Jeil Medical Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459132,
    "name": "Jeio Tech Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456029,
    "name": "Jejoong Medical Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 170449,
    "name": "Jelight Co Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 461431,
    "name": "JEM Tech Group    ( Clinton Township MI USA )",
    "country": "United States"
  },
  {
    "id": 458285,
    "name": "Jenco International Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 999798,
    "name": "Jenco International, Inc    ( Portland OR USA )",
    "country": "USA"
  },
  {
    "id": 454984,
    "name": "Jencons    ( East Grinstead  England )",
    "country": "UK"
  },
  {
    "id": 454983,
    "name": "Jencons Scientific LLC    ( Bridgeville PA USA )",
    "country": "United States"
  },
  {
    "id": 456931,
    "name": "Jenny Dose Tracking System LLC    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 457175,
    "name": "JENOPTIK Laser GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 457177,
    "name": "JENOPTIK Laser Technologies USA Corp    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 415931,
    "name": "Jensen GmbH    ( Metzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 332309,
    "name": "Jensen Industries Inc    ( North Haven CT USA )",
    "country": "United States"
  },
  {
    "id": 186037,
    "name": "Jensen Tools    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 360014,
    "name": "Jenway (Middle East) Ltd    ( Nicosia  Cyprus )",
    "country": "Cyprus"
  },
  {
    "id": 178309,
    "name": "Jenway Ltd    ( Dunmow  England )",
    "country": "UK"
  },
  {
    "id": 452950,
    "name": "Jenx Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 333604,
    "name": "JEOL (Australasia) Pty Ltd    ( Frenchs Forest  Australia )",
    "country": "Australia"
  },
  {
    "id": 418630,
    "name": "JEOL (Egypt)    ( Dokki (Cairo)  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 185788,
    "name": "JEOL (Europe) BV    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 335755,
    "name": "JEOL (Europe) bv    ( Nieuw-Vennep  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 418512,
    "name": "JEOL (Europe) SA Czech Republic    ( Praha 2  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 335764,
    "name": "JEOL (Europe) SA France    ( Croissy-sur-Seine  France )",
    "country": "France"
  },
  {
    "id": 418631,
    "name": "JEOL (Europe) SA Hungary    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 418633,
    "name": "JEOL (Europe) SA Poland    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 418511,
    "name": "JEOL (Germany) GmbH    ( Eching bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 418632,
    "name": "JEOL (Italia) SpA    ( Pieve Emanuele (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 170490,
    "name": "JEOL (Malaysia) Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 331128,
    "name": "JEOL (Skandinaviska) AB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 331056,
    "name": "JEOL (UK) Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 418634,
    "name": "JEOL Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 418629,
    "name": "JEOL Beijing    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 332090,
    "name": "JEOL de Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 418635,
    "name": "JEOL Korea Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 140223,
    "name": "JEOL Ltd    ( Tachikawa  Japan )",
    "country": "Japan"
  },
  {
    "id": 105627,
    "name": "JEOL USA Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 398102,
    "name": "Jepson Bolton & Co Ltd    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 175807,
    "name": "Jerome An Ossur Company    ( Paulsboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 107734,
    "name": "Jeron Electronic Systems Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 186038,
    "name": "Jet Medical Electronics    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 103475,
    "name": "Jeter Systems Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 108386,
    "name": "Jetta Corp    ( Edmond OK USA )",
    "country": "United States"
  },
  {
    "id": 999895,
    "name": "Jewett Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 330151,
    "name": "JH Enraf-Nonius (M) Sdn Bhd    ( Kuala Lumpar  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 454703,
    "name": "Jhordan Medical Supply LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 451687,
    "name": "Ji Hua Medical Apparatus & Instrument Co Ltd Guang    ( GuangZhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455895,
    "name": "Jiangsu Cureguard Glove Co Ltd    ( Suqian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455896,
    "name": "Jiangsu Folee Medical Equipment Co Ltd    ( Zhenjiang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455897,
    "name": "Jiangsu Haian Medigauze Co Ltd    ( Haian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 391379,
    "name": "Jiangsu Intco Medical Equipment & Supply Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455912,
    "name": "Jiangsu Kanghua Medical Equipment Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455913,
    "name": "Jiangsu Kangjian Medical Apparatus Co Ltd    ( Jiangyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455675,
    "name": "Jiangsu Kangjin Medical Instrument Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454691,
    "name": "Jiangsu Keling Medical Appliances Co Ltd    ( Yangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 372424,
    "name": "Jiangsu Likang Group Corp    ( Wuijang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455915,
    "name": "Jiangsu Province JianErKang Medical Dressing Co Lt    ( Jintan City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455916,
    "name": "Jiangsu Qianjing Medical Equipment Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455918,
    "name": "Jiangsu Reak Healthy Articles Co Ltd    ( Yangzhong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456645,
    "name": "Jiangsu Rich Life Science Instrument Co Ltd    ( Xuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456654,
    "name": "Jiangsu Shenli Medical Production Co Ltd    ( Changzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455672,
    "name": "Jiangsu Suyun Medical Material Co Ltd    ( Lianyungang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455927,
    "name": "Jiangsu Yongfa Medical Equipment Co Ltd    ( Zhangjiagang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455932,
    "name": "Jiangsu Yuyue Medical Equipment Inc    ( Danyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455933,
    "name": "Jiangsu Zhengji Instruments Co Ltd    ( Jintan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455934,
    "name": "Jiangxi Vista Enterprise Co Ltd    ( Nanchang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456655,
    "name": "Jiangxi Yikang Medical Instrument Group Co Ltd    ( Nanchang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455935,
    "name": "Jiaxing Tianhe Pharmaceutical Co Ltd    ( Jiaxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 103476,
    "name": "Jiffy Mixer Co Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 392523,
    "name": "Jinan Sysmex Medical Electronics Co Ltd    ( Jinan City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291853,
    "name": "Jinhua Electric Medical Instrument Factory    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456668,
    "name": "Jinhua KeDi Strumental Equipment Co Ltd    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456669,
    "name": "Jinhua YIDI Medical Appliance Co Ltd    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456656,
    "name": "Jining Jianda Medical Apparatus & Instrument Techn    ( Jining  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456030,
    "name": "JinSung Medi Co Ltd    ( Incheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 290939,
    "name": "Jintong (Guangzhou) Medical & Healthcare Products    ( Gaungzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455941,
    "name": "Jiujiang HuaDa Medical Dressing Co Ltd    ( Gongqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 267355,
    "name": "Jiunn Sheng Enterprises    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 175016,
    "name": "JJ & A Instruments LLC    ( Duvall WA USA )",
    "country": "United States"
  },
  {
    "id": 457364,
    "name": "JK Products    ( Jonesboro AR USA )",
    "country": "United States"
  },
  {
    "id": 457311,
    "name": "JK-International    ( Windhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 361831,
    "name": "JLJ Medical Devices International LLC    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 141248,
    "name": "JM Gloves Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457412,
    "name": "JM Keckler Medical Co Inc    ( Oakdale CA USA )",
    "country": "United States"
  },
  {
    "id": 455270,
    "name": "JM Science Inc    ( Grand Island NY USA )",
    "country": "United States"
  },
  {
    "id": 458958,
    "name": "JMI Barcodes    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 333843,
    "name": "JMJ Technologies Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 465035,
    "name": "JMMedSource LLC    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 277979,
    "name": "JMS Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 369458,
    "name": "JMS Dalian Medical Supply Co Ltd    ( Dalian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 331179,
    "name": "JMS North America Corp    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 457909,
    "name": "JMS Plastics Supply Inc    ( Neptune NJ USA )",
    "country": "United States"
  },
  {
    "id": 150958,
    "name": "JMW Systems Ltd    ( Loanhead  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 345616,
    "name": "Jobri LLC    ( Konawa OK USA )",
    "country": "United States"
  },
  {
    "id": 281241,
    "name": "Jobst GmbH    ( Emmerich  Germany )",
    "country": "Germany"
  },
  {
    "id": 176274,
    "name": "Jodee Inc    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 457178,
    "name": "Jodi-vac LLC    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 158497,
    "name": "Joerg & Sohn GmbH    ( Ahorn-Schorkendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 101388,
    "name": "Joerns Healthcare Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 309240,
    "name": "Johann Mieth GmbH    ( Radebeul  Germany )",
    "country": "Germany"
  },
  {
    "id": 279354,
    "name": "Johann Stermanns GmbH & Co KG    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161012,
    "name": "Johann Stiegelmeyer GmbH & Co KG    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 284073,
    "name": "Johannes Lieder Laboratorium fuer Mikroskopie    ( Ludwigsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285971,
    "name": "Johannes Meist    ( Feuchtwangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284166,
    "name": "Johannes Weithas Dental-Kunststoffe-Zaehne    ( Luetjenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 176951,
    "name": "Johmedical AB    ( Ursviken  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 102854,
    "name": "JOHN CHATILLON AND SONS    (   USA )",
    "country": "United States"
  },
  {
    "id": 456080,
    "name": "John Fowler Ocular Lenses Pvt Ltd    ( Aurangabad  India )",
    "country": "India"
  },
  {
    "id": 103801,
    "name": "John J Kelley Assoc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 465046,
    "name": "John Mezzalingua Associates LLC    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 108572,
    "name": "John O Butler Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 161560,
    "name": "John Sjodings AB    ( Kista  USA )",
    "country": "United States"
  },
  {
    "id": 454780,
    "name": "John Weiss & Sons Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 287608,
    "name": "Johnsen GmbH Hno-Einrichtungen und Service    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 307295,
    "name": "Johnson & Johnson Asia Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 103796,
    "name": "Johnson & Johnson Consumer Products Co    ( Skillman NJ USA )",
    "country": "United States"
  },
  {
    "id": 281073,
    "name": "Johnson & Johnson GmbH    ( Neuss  Germany )",
    "country": "Germany"
  },
  {
    "id": 108482,
    "name": "Johnson & Johnson Hemisferica SA    ( Caguas PR USA )",
    "country": "United States"
  },
  {
    "id": 226226,
    "name": "Johnson & Johnson Medical (UK)    ( Blackburn  USA )",
    "country": "United States"
  },
  {
    "id": 187978,
    "name": "Johnson & Johnson Medical GmbH    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 279242,
    "name": "Johnson & Johnson Medical Inc (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 108593,
    "name": "Johnson & Johnson Medical Products Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454660,
    "name": "Johnson & Johnson Vision Care Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 458237,
    "name": "Johnson Controls    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 397872,
    "name": "Johnson Global Medical Products Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 397113,
    "name": "Johnson MarCraft Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 293783,
    "name": "Johnson Matthey & Brandenberger AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 150169,
    "name": "Johnson Scale Co    ( Pine Brook NJ USA )",
    "country": "United States"
  },
  {
    "id": 455242,
    "name": "Johnson-Promident    ( Valley Cottage NY USA )",
    "country": "United States"
  },
  {
    "id": 150059,
    "name": "JOHNSON&JOHNSON CORP    (   USA )",
    "country": "United States"
  },
  {
    "id": 458940,
    "name": "joimax GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 458946,
    "name": "joimax Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 456031,
    "name": "Join Enterprise Ltd    ( Yongin  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455914,
    "name": "Joinsoon Electronics Mfg Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 450873,
    "name": "Joint Commission Resources    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 105429,
    "name": "Joint Medical Products Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 272698,
    "name": "Joint Replacement Instrumentation Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 454840,
    "name": "Jointmedica Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 457441,
    "name": "Joints in Motion    ( Pewaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 156132,
    "name": "Jokoh Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451964,
    "name": "Jolife AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457817,
    "name": "JOLINE GmbH & Co KG    ( Hechingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456413,
    "name": "Joma Kunststofftechnik GmbH & Co KG    ( Brunn am Gebirge  Austria )",
    "country": "Austria"
  },
  {
    "id": 453806,
    "name": "Jomar Healthcare Pty Ltd    ( Mordialloc  Australia )",
    "country": "Australia"
  },
  {
    "id": 402224,
    "name": "JoncoMed Consulting Inc    ( Bronson FL USA )",
    "country": "United States"
  },
  {
    "id": 175576,
    "name": "Jones and Co    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 101391,
    "name": "Jones Medical Instrument Co    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 107652,
    "name": "Jones X-Ray Inc    ( Grand Prairie TX USA )",
    "country": "United States"
  },
  {
    "id": 105953,
    "name": "Jones-Zylon Co    ( West Lafayette OH USA )",
    "country": "United States"
  },
  {
    "id": 401742,
    "name": "Jong Ching Research and Develop Co Ltd    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 185063,
    "name": "Jong Sang Techno Co Ltd    ( Inchon  USA )",
    "country": "United States"
  },
  {
    "id": 309595,
    "name": "Jonplast Medical Products    ( Peregallo di Lesmo (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 103797,
    "name": "Jordan Paper Box Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 999847,
    "name": "Jordan Scientific, LLC    (   USA )",
    "country": "United States"
  },
  {
    "id": 999846,
    "name": "Jordon Commercial Refrigerator Co    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 999932,
    "name": "Jorgensen Laboratories, Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 153031,
    "name": "Josef Bacher GmbH    ( Rietheim-Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 286098,
    "name": "Josef Betzler Chirurgische Instrumente    ( Seitingen-Oberflacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 286770,
    "name": "Josef Heiss Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282177,
    "name": "Josef Hock Schaumstoff und Textil GmbH    ( Grosskrotzenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 174646,
    "name": "Josef Kratz GmbH    ( Bad Honnef  Germany )",
    "country": "Germany"
  },
  {
    "id": 281075,
    "name": "Josef Schreyeck    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 454972,
    "name": "Joseph Albrecht Bohrfutterfabrik GmbH & Co    ( Wenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 168854,
    "name": "Jota AG    ( Ruethi  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 104529,
    "name": "Jouan Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 306239,
    "name": "Joyce Healthcare (Australia)    ( Perth  USA )",
    "country": "United States"
  },
  {
    "id": 344610,
    "name": "JP International    ( Mt Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 331371,
    "name": "JPI America Inc    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 456455,
    "name": "JPI Healthcare Solutions Inc    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 324295,
    "name": "JPM Products Ltd    ( Ware  England )",
    "country": "UK"
  },
  {
    "id": 277755,
    "name": "JPW Material Hospitalar    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 271440,
    "name": "JRH Biosciences    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 457386,
    "name": "JRI Orthopaedics Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 144653,
    "name": "JRM Medical Services Inc    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 457786,
    "name": "JRS Medical Inc    ( Cordova TN USA )",
    "country": "United States"
  },
  {
    "id": 144654,
    "name": "JRT Assoc    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 107011,
    "name": "JS Biomedical    (   USA )",
    "country": "United States"
  },
  {
    "id": 107340,
    "name": "JS Dental Mfg Inc    ( Ridgefield CT USA )",
    "country": "United States"
  },
  {
    "id": 158250,
    "name": "JS Evro-Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456062,
    "name": "JSW Surgical co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 999575,
    "name": "JT Packard    ( Verona WI USA )",
    "country": "United States"
  },
  {
    "id": 451724,
    "name": "JTech Communications Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 362003,
    "name": "JTECH Medical Industries Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 459142,
    "name": "Jubilant DraxImage Inc    ( Kirkland PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 458034,
    "name": "Jublilant DraxImage Inc    ( Kirkland PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 456082,
    "name": "Judd Medical Ltd    ( Bromsgrove  England )",
    "country": "UK"
  },
  {
    "id": 179522,
    "name": "Judex Datasystemer A/S    ( Aalborg SV  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 471776,
    "name": "Judice Services Inc    ( Broussard LA USA )",
    "country": "United States"
  },
  {
    "id": 451248,
    "name": "Julabo East USA Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 286772,
    "name": "Julius Wirth-Inh Josef Mueller GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459143,
    "name": "JumpUSA    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 455050,
    "name": "JUN-AIR Deutschland GmbH    ( Ahrensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455046,
    "name": "JUN-AIR International A/S    ( Norresundby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 455049,
    "name": "JUN-AIR USA Inc    ( Benton Harbor MI USA )",
    "country": "United States"
  },
  {
    "id": 285993,
    "name": "Junghans Uhren GmbH    ( Schramberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459144,
    "name": "Jungheinrich AG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 280414,
    "name": "Jungkeit Meredos GmbH    ( Bovenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 157059,
    "name": "Jungwon Precision Industries Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459145,
    "name": "Juniper Networks Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 175577,
    "name": "Junkin Safety Appliance Co Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 456722,
    "name": "Just Dental Supplies Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 457322,
    "name": "Just Health Shops    ( Ashton MD USA )",
    "country": "United States"
  },
  {
    "id": 466539,
    "name": "Just Medical Inc (JMI)    ( Watkinsville GA USA )",
    "country": "United States"
  },
  {
    "id": 458111,
    "name": "Just Mfg    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 106536,
    "name": "Just Mfg Co    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 103798,
    "name": "Justman Brush Co    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 464968,
    "name": "JustRight Surgical    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 431515,
    "name": "Justrite Mfg Co LLC    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 312183,
    "name": "JUZO    ( Cuyahoga Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 457383,
    "name": "JV FreBor LLC    ( Borisov  Belarus )",
    "country": "Belarus"
  },
  {
    "id": 171146,
    "name": "JVC Professional Products Co    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 456032,
    "name": "JVM Co Ltd    ( Daegu  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458121,
    "name": "JW Medical Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 306780,
    "name": "K & L Konzepte GmbH    ( Witten  USA )",
    "country": "United States"
  },
  {
    "id": 280086,
    "name": "K & R Rahabilitations- und Elektrotechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 453629,
    "name": "K A Rasmussen    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 370471,
    "name": "K BowMed Inc    ( North Wilkesboro NC USA )",
    "country": "United States"
  },
  {
    "id": 175385,
    "name": "K Medical Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 150985,
    "name": "K R Stalror A/S    ( Hadsten  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 152442,
    "name": "K Takaoka Ind e Com Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 286776,
    "name": "K Voegele Medizin-Technik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103284,
    "name": "K-10 Inc    ( Mission TX USA )",
    "country": "United States"
  },
  {
    "id": 280274,
    "name": "K-H Dewert GmbH    ( Bielefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 455980,
    "name": "K-jump Health Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 459197,
    "name": "La Boit Inc    ( Gahanna OH USA )",
    "country": "United States"
  },
  {
    "id": 999848,
    "name": "K-Systems (KIVEX Biotec Inc)    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 459146,
    "name": "K2 Medical Systems    ( Plymouth  England )",
    "country": "UK"
  },
  {
    "id": 460537,
    "name": "K2M Inc    ( Leesburg VA USA )",
    "country": "United States"
  },
  {
    "id": 456721,
    "name": "KAB Dental Inc    ( Sterling Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 372794,
    "name": "Kabushiki Kaisha Helena Kenkyujyo    ( Saitama  Japan )",
    "country": "Japan"
  },
  {
    "id": 999857,
    "name": "Kaga Electronics Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 186043,
    "name": "KahnTact USA Inc    ( Hillside IL USA )",
    "country": "United States"
  },
  {
    "id": 452084,
    "name": "Kai Industries Co Ltd    ( Seki  Japan )",
    "country": "Japan"
  },
  {
    "id": 455942,
    "name": "Kaifat Medical Science & Technical (Ningbo) Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 280918,
    "name": "Kaiser Naturfellprodukte Medizinische Fellartikel    ( Dreikirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455978,
    "name": "Kaiser Technology Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 158498,
    "name": "Kaiser-Elektromedizin    ( Bretten  Germany )",
    "country": "Germany"
  },
  {
    "id": 459151,
    "name": "Kaivac Inc    ( Hamilton OH USA )",
    "country": "United States"
  },
  {
    "id": 175809,
    "name": "KalMed Dental Products Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 455515,
    "name": "Kamiya Biomedical Co    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 157951,
    "name": "Kamiya Tsusan Kaisha Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 284025,
    "name": "Kampmann Verwaltung    ( Lingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107344,
    "name": "Kane Enterprises Inc    ( Brandon SD USA )",
    "country": "United States"
  },
  {
    "id": 357017,
    "name": "Kanegafuchi Chemical Industry Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 277981,
    "name": "Kaneka Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 174104,
    "name": "Kanematsu Medical Systems Corp    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 291856,
    "name": "Kangjia Chememedical Equipment Factory    ( Yuhuan County  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 157528,
    "name": "KanMed AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 375095,
    "name": "Kanner Corp    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 277984,
    "name": "Kansai Trading Corp    ( Higashiosaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 456797,
    "name": "Kansas Eye Bank & Cornea Research Center    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 455979,
    "name": "Kao Chen Enterprise Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 322390,
    "name": "Kapel Kal Ltd    ( Yavne  Israel )",
    "country": "Israel"
  },
  {
    "id": 150405,
    "name": "Kapitex Healthcare Ltd    ( Wetherby  England )",
    "country": "UK"
  },
  {
    "id": 407328,
    "name": "KAPP Chirurgische Instrumente GmbH    ( Duerbheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 451168,
    "name": "Kapp Surgical Instruments Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 104670,
    "name": "Kappa Medical    ( Prescott AZ USA )",
    "country": "United States"
  },
  {
    "id": 178243,
    "name": "KAPPA opto-electronics GmbH    ( Gleichen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280344,
    "name": "Kappes Systeme GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 391201,
    "name": "Kappler Canada    ( Brantford ON USA )",
    "country": "United States"
  },
  {
    "id": 347665,
    "name": "Kappler Europe    ( Mansfield  England )",
    "country": "UK"
  },
  {
    "id": 381778,
    "name": "Kappler Inc    ( Guntersville AL USA )",
    "country": "United States"
  },
  {
    "id": 462992,
    "name": "Kappler Med+Org GmbH    ( Pfalzgrafenweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 339393,
    "name": "Kardex    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 462835,
    "name": "Kardex AG    ( Zurich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462834,
    "name": "Kardex Remstar LLC    ( Westbrook ME USA )",
    "country": "United States"
  },
  {
    "id": 464117,
    "name": "Kardiosis Ltd    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 455231,
    "name": "Kardium Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 323010,
    "name": "Kare Products Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 106462,
    "name": "Kareco International Inc    ( Dunellen NJ USA )",
    "country": "United States"
  },
  {
    "id": 456009,
    "name": "Karex Industries Sdn Bhd    ( Pontian  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 454260,
    "name": "Karishma Software Ltd    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 153034,
    "name": "Karl Baisch GmbH    ( Plattling  Germany )",
    "country": "Germany"
  },
  {
    "id": 454919,
    "name": "Karl Beese (GmbH & Co KG )    ( Barbuettel  Germany )",
    "country": "Germany"
  },
  {
    "id": 282459,
    "name": "Karl Berg Dentalwachsfabrik    ( Engen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286778,
    "name": "Karl Bollmann GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281517,
    "name": "Karl Gramm GmbH Co KG    ( Remseck  Germany )",
    "country": "Germany"
  },
  {
    "id": 158429,
    "name": "Karl Grumbach GmbH & Co KG    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 286205,
    "name": "Karl Hammacher GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 439764,
    "name": "Karl Hecht Assistent GmbH    ( Altnau TG  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439766,
    "name": "Karl Hecht Ges mbH    ( Fritzens  Austria )",
    "country": "Austria"
  },
  {
    "id": 286247,
    "name": "Karl Hecht KG    ( Sondheim vor der Rhoen  Germany )",
    "country": "Germany"
  },
  {
    "id": 155888,
    "name": "Karl Kaps GmbH & Co KG    ( Asslar/Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 286780,
    "name": "Karl Klappenecker Fabrik fuer Chirurgie-Instrument    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284865,
    "name": "Karl Moeser Nachf    ( Neuenrade  Germany )",
    "country": "Germany"
  },
  {
    "id": 306627,
    "name": "Karl Otto Braun KG    ( Wolfstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 286366,
    "name": "Karl Otto Knauf (GmbH & Co KG)    ( Stockelsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 149144,
    "name": "Karl Reichle & Co    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451479,
    "name": "Karl Schumacher Dental Instrument Co Inc    ( Southampton PA USA )",
    "country": "United States"
  },
  {
    "id": 279356,
    "name": "Karl Stahlberg GmbH & Co KG    ( Aachen  USA )",
    "country": "United States"
  },
  {
    "id": 293785,
    "name": "Karl Steiner AG Laborbau    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454499,
    "name": "KARL STORZ Endoscopy Australia Pty Ltd    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 366542,
    "name": "Karl Storz Endoscopy Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454501,
    "name": "KARL STORZ Endoscopy Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454500,
    "name": "KARL STORZ Endoscopy South Africa Pty Ltd    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 102159,
    "name": "Karl Storz Endoscopy-America Inc    ( El Segundo CA USA )",
    "country": "United States"
  },
  {
    "id": 152361,
    "name": "Karl Storz GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 289363,
    "name": "Karl Storz Lithotripsy-America Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 282461,
    "name": "Karl-Heinz Hinze Optoengineering GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 280964,
    "name": "Karl-Heinz Schlichtmann Hamburgische Med-Technik    ( Drochtersen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285961,
    "name": "Karl-Heinz Thiele GmbH    ( Hamburg-Schenefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 283318,
    "name": "Karlsruher Glastechnisches Werk Schieder GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 452038,
    "name": "Karma Mobility Ltd    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 323011,
    "name": "Karman Healthcare Inc    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 178453,
    "name": "Karomed    ( Chard  England )",
    "country": "UK"
  },
  {
    "id": 466788,
    "name": "Karos Health Inc    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 285985,
    "name": "Karosserie- und Fahrzeug-Bau Ambulanz-Mobile GmbH    ( Schoenebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 293424,
    "name": "Karr Dental AG    ( Thalwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 168855,
    "name": "Kasag-Flueckiger AG    ( Langnau iE  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 287713,
    "name": "Kashmir Surgical Works    ( Ambala Cantt  India )",
    "country": "India"
  },
  {
    "id": 465127,
    "name": "Kaspersky Lab    ( Woburn 50 USA )",
    "country": "United States"
  },
  {
    "id": 182592,
    "name": "Kata-Electronics Oy    ( Ylamylly  Finland )",
    "country": "Finland"
  },
  {
    "id": 105966,
    "name": "Katecho    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 101394,
    "name": "Katena Products Inc    ( Denville NJ USA )",
    "country": "United States"
  },
  {
    "id": 459180,
    "name": "KaTom Restaurant Supply Inc    ( Kodak TN USA )",
    "country": "United States"
  },
  {
    "id": 157950,
    "name": "Katoman Seisakusho Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 192851,
    "name": "Katowickie Zaklady Sprzetu Ortopedycznego    ( Katowice  USA )",
    "country": "United States"
  },
  {
    "id": 460633,
    "name": "Kaufman Hall    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 462999,
    "name": "Kaung Ta Beautician Equipment Supply Co    ( Tainan City  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451238,
    "name": "Kavandish System Engineering Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 185180,
    "name": "KaVo Dental Asia-Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 106638,
    "name": "KaVo Dental Corp    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 280253,
    "name": "KaVo Dental GmbH    ( Biberach/Riss  Germany )",
    "country": "Germany"
  },
  {
    "id": 460980,
    "name": "KaVo Dental Ltd    ( Amersham  England )",
    "country": "UK"
  },
  {
    "id": 454513,
    "name": "KaVo Italia srl    ( Genova (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461433,
    "name": "Kawai America Corp    ( Rancho Dominguez CA USA )",
    "country": "United States"
  },
  {
    "id": 278004,
    "name": "Kawamoto Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 288603,
    "name": "Kawanishi Iryo Denki Seisaku-sho    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454741,
    "name": "Kawasumi Laboratories (Thailand) Co Ltd    ( Pathum Thani  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 155360,
    "name": "Kawasumi Laboratories America Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 157958,
    "name": "Kawasumi Laboratories Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 158807,
    "name": "KaWeCo GmbH    ( Ditzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452633,
    "name": "Kay & Co    ( Mumbia  India )",
    "country": "India"
  },
  {
    "id": 102445,
    "name": "Kay Elemetrics Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 458984,
    "name": "Kay Medical Concepts Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 103800,
    "name": "Kay-See Dental Mfg Co    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 307296,
    "name": "Kaye Products    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 187313,
    "name": "Kaye Products Inc    ( Hillsborough NC USA )",
    "country": "United States"
  },
  {
    "id": 450987,
    "name": "KayPENTAX    ( Montvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 459223,
    "name": "KayserBetten GmbH & Co KG    ( Bad Fallingbostel  Germany )",
    "country": "Germany"
  },
  {
    "id": 101399,
    "name": "Kaz Inc    ( Southborough MA USA )",
    "country": "United States"
  },
  {
    "id": 459250,
    "name": "Kb Port    ( Allison Park PA USA )",
    "country": "United States"
  },
  {
    "id": 149021,
    "name": "KBD Products    ( Crescent Springs KY USA )",
    "country": "United States"
  },
  {
    "id": 262510,
    "name": "KBI Pharmacia & Upjohn Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 152297,
    "name": "KC Pharmaceuticals    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 185724,
    "name": "KCI Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 403701,
    "name": "KCI Clinic Spain SL    ( Boadilla del Monte (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 403699,
    "name": "KCI Medical AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450781,
    "name": "KCI Medical Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 335791,
    "name": "KCI Medical Australia Pty Ltd    ( Lane Cove West  Australia )",
    "country": "Australia"
  },
  {
    "id": 450783,
    "name": "KCI Medical Belgium BvBa    ( Peer  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 450780,
    "name": "KCI Medical bv    ( Houten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 403697,
    "name": "KCI Medical Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 403700,
    "name": "KCI Medical Ltd (Ireland)    ( Dublin 12  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 170196,
    "name": "KCI Medical Ltd UK    ( Kidlington  England )",
    "country": "UK"
  },
  {
    "id": 403702,
    "name": "KCI Medical South Africa (Pty) Ltd    ( Midrand  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 450782,
    "name": "KCI Medical srl    ( Assago (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 403695,
    "name": "KCI Mediscus GmbH (Switzerland)    ( Rumlang  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 176259,
    "name": "KCI Medizinprodukte GmbH    ( Weisbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 279600,
    "name": "KD Kleindienst Waescherei und Desinfektionstechnik    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452906,
    "name": "KD Medical GmbH Hospital Products    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 452620,
    "name": "KD Scientific Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 465152,
    "name": "KDF US Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 108477,
    "name": "Keane Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 451701,
    "name": "Keaney Medical    ( Dublin 24  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 459154,
    "name": "Keating of Chicago Inc    ( McCook IL USA )",
    "country": "United States"
  },
  {
    "id": 456418,
    "name": "Keaton Medical Inc    ( Pasadena MD USA )",
    "country": "United States"
  },
  {
    "id": 457778,
    "name": "Kedrion Biopharma Inc    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 108387,
    "name": "KEE Medical Services    ( Mechanicsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 101905,
    "name": "Keeler Instruments Inc    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 147817,
    "name": "Keeler Ltd    ( Windsor  England )",
    "country": "UK"
  },
  {
    "id": 450792,
    "name": "Keelers Medical Supply Inc    ( Yakima WA USA )",
    "country": "United States"
  },
  {
    "id": 195329,
    "name": "Keen Compressed Gas    ( New Castle DE USA )",
    "country": "United States"
  },
  {
    "id": 457434,
    "name": "Keen Healthcare    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 105066,
    "name": "Kees Goebel Medical    ( Hamilton OH USA )",
    "country": "United States"
  },
  {
    "id": 466617,
    "name": "Kees Inc    ( Elkhart Lake WI USA )",
    "country": "United States"
  },
  {
    "id": 280072,
    "name": "KEG Sonderabfall-Entsorgungsgesellschaft mbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 457234,
    "name": "KEI Medical Imaging Services LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 174657,
    "name": "Keir Surgical Ltd    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 157952,
    "name": "Keisei Medical Industrial Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 459181,
    "name": "Keiser Corp    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 453731,
    "name": "Keith Exim    ( Delhi  India )",
    "country": "India"
  },
  {
    "id": 272613,
    "name": "Keithley Instruments bv    ( Gorinchem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 150134,
    "name": "Keithley Instruments Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 416380,
    "name": "Keitzer Mfg USA    ( Rolla MO USA )",
    "country": "United States"
  },
  {
    "id": 190728,
    "name": "Kelim Medical System    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 103044,
    "name": "Kelkom Systems    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 104217,
    "name": "Kelleher Medical Inc    ( Powhatan VA USA )",
    "country": "United States"
  },
  {
    "id": 107347,
    "name": "Kells Medical Inc    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 102973,
    "name": "Kelvinator Scientific    (   USA )",
    "country": "United States"
  },
  {
    "id": 104876,
    "name": "Kem Medical Products Corp    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 150986,
    "name": "KEN Maskinfabrik A/S    ( Broby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 391291,
    "name": "Ken-A-Vision (Europe)    ( Den Haag  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452652,
    "name": "Ken-A-Vision International GmbH    ( Bruhl  Germany )",
    "country": "Germany"
  },
  {
    "id": 107039,
    "name": "Ken-A-Vision Mfg Co Inc    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 306367,
    "name": "Kencap Ltd    ( Yehud  Israel )",
    "country": "Israel"
  },
  {
    "id": 354574,
    "name": "Kendall Camp Orthpaedic Ltd    ( Basingstoke  USA )",
    "country": "United States"
  },
  {
    "id": 371861,
    "name": "Kendall Healthcare Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 371862,
    "name": "Kendall Healthcare Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 450982,
    "name": "Kendra American Airless    ( Reynoldsburg OH USA )",
    "country": "United States"
  },
  {
    "id": 271648,
    "name": "KENDRO LABORATORY PRODUCTS    (   USA )",
    "country": "United States"
  },
  {
    "id": 324179,
    "name": "Kenex (Electro-Medical) Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 459155,
    "name": "Kenexa Corp    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 999998,
    "name": "Kenmore    (   USA )",
    "country": "United States"
  },
  {
    "id": 150444,
    "name": "Kensey Nash Corp    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 452253,
    "name": "Kensey Nash Corp    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 402854,
    "name": "Kensey Nash Europe GmbH    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 344603,
    "name": "Kent Elastomer Products Inc    ( Kent OH USA )",
    "country": "United States"
  },
  {
    "id": 374402,
    "name": "Kent Laboratories    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 458711,
    "name": "Kent Scientific Corp    ( Torrington CT USA )",
    "country": "United States"
  },
  {
    "id": 106451,
    "name": "Kentec Medical Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 107831,
    "name": "Kentek Corp    ( Pittsfield NH USA )",
    "country": "United States"
  },
  {
    "id": 458090,
    "name": "Kentenich GmbH    ( Bornheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 332730,
    "name": "Kentron Health Care Inc    ( Springfield TN USA )",
    "country": "United States"
  },
  {
    "id": 281207,
    "name": "Kentzler-Kaschner Dental GmbH    ( Ellwangen/Jagst  Germany )",
    "country": "Germany"
  },
  {
    "id": 999983,
    "name": "KENWOOD    (   USA )",
    "country": "United States"
  },
  {
    "id": 184901,
    "name": "Kenzmedico Co Ltd    ( Honjo  Japan )",
    "country": "Japan"
  },
  {
    "id": 336013,
    "name": "Keomed Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 452827,
    "name": "Keosys Medical Imaging    ( Saint-Herbain Cedex  France )",
    "country": "France"
  },
  {
    "id": 285545,
    "name": "Keramag Keramische Werke AG    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282995,
    "name": "Kerapid Krueger und Schuette KG    ( Hildesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 450934,
    "name": "Kerberos Proximal Solutions Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 466490,
    "name": "Kerma Medical Products Inc    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 282277,
    "name": "Kerma Verbandstoff GmbH    ( Hainichen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279443,
    "name": "Kern & Sohn GmbH    ( Balingen-Frommern  Germany )",
    "country": "Germany"
  },
  {
    "id": 416378,
    "name": "Kern Surgical Supply    ( Bakersfield CA USA )",
    "country": "United States"
  },
  {
    "id": 293665,
    "name": "Kern-Etiketten AG    ( Urdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 104218,
    "name": "Kernco Instruments Co Inc    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 106684,
    "name": "Kerr Corp    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 283320,
    "name": "Kerr GmbH    ( Karlsruhe  USA )",
    "country": "United States"
  },
  {
    "id": 416065,
    "name": "KerrHawe SA    ( Bioggio  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 399265,
    "name": "Kerry Equipment    ( Perth  Australia )",
    "country": "Australia"
  },
  {
    "id": 193494,
    "name": "Kerry Ultrasonics Ltd    ( Skipton  USA )",
    "country": "United States"
  },
  {
    "id": 465176,
    "name": "Kesle Systems Inc.    ( West Newton 25 USA )",
    "country": "United States"
  },
  {
    "id": 474849,
    "name": "Kestrel Ophthalmics Ltd    ( Broadstone  England )",
    "country": "UK"
  },
  {
    "id": 281389,
    "name": "Kettenbach GmbH & Co KG    ( Eschenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281736,
    "name": "Ketterer & Liebherr GmbH & Co KG    ( Freiburg  USA )",
    "country": "United States"
  },
  {
    "id": 999506,
    "name": "Keurig, Inc.    ( Reading MA USA )",
    "country": "United States"
  },
  {
    "id": 103478,
    "name": "Kewaunee Scientific Corp    ( Statesville NC USA )",
    "country": "United States"
  },
  {
    "id": 459156,
    "name": "KEY Functional Assessments Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 469451,
    "name": "Key Instruments    ( Trevose PA USA )",
    "country": "United States"
  },
  {
    "id": 305690,
    "name": "Key Pharmaceuticals Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 462969,
    "name": "Key Surgical Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 454838,
    "name": "Keydex    ( Caracas  Venezuela )",
    "country": "Venezuela"
  },
  {
    "id": 458286,
    "name": "Keyence Corp of America    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 338423,
    "name": "Keygun & Mouller Ltd    ( Hertzlia  USA )",
    "country": "United States"
  },
  {
    "id": 140230,
    "name": "KeyMed Ltd    ( Southend-on-Sea  England )",
    "country": "UK"
  },
  {
    "id": 174954,
    "name": "KeyMedical Software Inc    ( Lebanon IN USA )",
    "country": "United States"
  },
  {
    "id": 459157,
    "name": "Keys Fitness    ( Hughes Springs TX USA )",
    "country": "United States"
  },
  {
    "id": 999706,
    "name": "Keys Fitness    ( Hughes Springs TX USA )",
    "country": "USA"
  },
  {
    "id": 431576,
    "name": "Keysan    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 457798,
    "name": "Keystone Dental Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 175813,
    "name": "Keystone Helicopter Corp    ( Coatesville PA USA )",
    "country": "United States"
  },
  {
    "id": 108681,
    "name": "Keystone Industries    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 102546,
    "name": "Keystone View    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 107371,
    "name": "Keystone X-Ray Inc    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 376088,
    "name": "Keywest Technology Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 279614,
    "name": "KFB Extramobile GmbH    ( Meuspath  Germany )",
    "country": "Germany"
  },
  {
    "id": 282926,
    "name": "KFM Regelungstechnik GmbH    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 461423,
    "name": "Kforce Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 184656,
    "name": "KHL Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 373457,
    "name": "KI    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 431621,
    "name": "KI4U Inc    ( Gonzales TX USA )",
    "country": "United States"
  },
  {
    "id": 451544,
    "name": "KIA Med Trading Co    ( Tehran  USA )",
    "country": "United States"
  },
  {
    "id": 293248,
    "name": "Kibernetik AG    ( Buchs  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457394,
    "name": "Kibion AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 999534,
    "name": "Kids Fit International Youth Fitness    ( Huger SC USA )",
    "country": "USA"
  },
  {
    "id": 467367,
    "name": "Kidzpace Interactive Inc    ( Collingwood ON Canada )",
    "country": "Canada"
  },
  {
    "id": 343418,
    "name": "Kieser Training AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 176277,
    "name": "Kik Technology Inc    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 103285,
    "name": "Kilgore International Inc    ( Coldwater MI USA )",
    "country": "United States"
  },
  {
    "id": 171328,
    "name": "Kimal plc    ( Uxbridge  England )",
    "country": "UK"
  },
  {
    "id": 408720,
    "name": "Kimberly-Clark (UK)    ( West Mailing  England )",
    "country": "UK"
  },
  {
    "id": 408725,
    "name": "Kimberly-Clark Australia Pty Ltd    ( Milsons Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 408722,
    "name": "Kimberly-Clark Belgium    ( Zaventem  USA )",
    "country": "United States"
  },
  {
    "id": 150069,
    "name": "Kimberly-Clark Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 408723,
    "name": "Kimberly-Clark France    ( Saint-Cloud  France )",
    "country": "France"
  },
  {
    "id": 284567,
    "name": "Kimberly-Clark GmbH    ( Koblenz-Rheinhafen  Germany )",
    "country": "Germany"
  },
  {
    "id": 397736,
    "name": "Kimberly-Clark Health Care    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 408724,
    "name": "Kimberly-Clark Japan    ( Yokohama  USA )",
    "country": "United States"
  },
  {
    "id": 408726,
    "name": "Kimberly-Clark Malaysia    ( Petaling Jaya  USA )",
    "country": "United States"
  },
  {
    "id": 429371,
    "name": "Kimberly-Clark Safety Div    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 408727,
    "name": "Kimberly-Clark Singapore    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 375298,
    "name": "Kimberly-Clark/Ballard Medical    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 150332,
    "name": "Kimble/Kontes    ( Vineland NJ USA )",
    "country": "United States"
  },
  {
    "id": 467241,
    "name": "LadyCare Lifetime LLC    ( Oceanport NJ USA )",
    "country": "United States"
  },
  {
    "id": 280843,
    "name": "Kimetec GmbH Medizintechnik    ( Ditzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 163651,
    "name": "Kimetsan-Kimya Maden    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 152992,
    "name": "Kinamed Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 108035,
    "name": "Kincheloe Ltd    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 152363,
    "name": "Kindermann & Co GmbH    ( Ochsenfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 293484,
    "name": "Kinematica AG    ( Luzern  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454347,
    "name": "KineMedics Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456948,
    "name": "Kinetec SA    ( Charleville-Mezieres Cedex  France )",
    "country": "France"
  },
  {
    "id": 126898,
    "name": "Kinetic Biomedical Services Inc    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 102816,
    "name": "Kinetic Concepts Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 148485,
    "name": "Kinetic Instruments Inc    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 459406,
    "name": "Kinetic Systems Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 273722,
    "name": "Kinetikos Medical Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 331377,
    "name": "King Equipment Services Inc    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 453074,
    "name": "King Pharmaceuticals Inc    ( Bristol TN USA )",
    "country": "United States"
  },
  {
    "id": 101406,
    "name": "King Systems Corp    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 106048,
    "name": "King's Medical Group    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 107372,
    "name": "Kingsley Mfg Co    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 307297,
    "name": "Kingston Medical Supplies (Pte) Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450803,
    "name": "Kingswood Laboratories Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 174105,
    "name": "Kinki Roentgen Industrial Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 470552,
    "name": "Kinsman Enterprises Inc    ( West Frankfort IL USA )",
    "country": "United States"
  },
  {
    "id": 459042,
    "name": "KinTek Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 457993,
    "name": "Kintz Plastics Inc    ( Howes Cave NY USA )",
    "country": "United States"
  },
  {
    "id": 459152,
    "name": "KIOSK Information Systems    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 107373,
    "name": "Kiper Enterprises Inc    ( Oswego NY USA )",
    "country": "United States"
  },
  {
    "id": 266257,
    "name": "Kipp & Zonen bv    ( Delft  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 463029,
    "name": "Kips Bay Medical    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 104652,
    "name": "Kirby Lester Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 306289,
    "name": "Kirchner & Wilhelm GmbH & Co KG    ( Asperg  Germany )",
    "country": "Germany"
  },
  {
    "id": 330999,
    "name": "Kirlin Co    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 455426,
    "name": "Kirton Healthcare Group Ltd    ( Haverhill  England )",
    "country": "UK"
  },
  {
    "id": 106804,
    "name": "Kirwan Surgical Products Inc    ( Marshfield MA USA )",
    "country": "United States"
  },
  {
    "id": 455921,
    "name": "Kisco International    ( Saint-Priest  France )",
    "country": "France"
  },
  {
    "id": 103483,
    "name": "Kistler Instrument Corp    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 293061,
    "name": "Kistler Instrumente AG    ( Winterthur  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455303,
    "name": "Kitalpha Med Ltd    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453938,
    "name": "Kivi srl    ( Vigliano d'Asti (AT)  Italy )",
    "country": "Italy"
  },
  {
    "id": 392675,
    "name": "Kjaerulff A/S    ( Otterup  USA )",
    "country": "United States"
  },
  {
    "id": 286368,
    "name": "KKT - Porkka GmbH    ( Stockelsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 463041,
    "name": "KKT chillers Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 467282,
    "name": "Klarity Medical Products USA    ( Newark OH USA )",
    "country": "United States"
  },
  {
    "id": 286209,
    "name": "Klaus Demtroeder GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284275,
    "name": "Klaus Ziemer GmbH    ( Langerwehe  Germany )",
    "country": "Germany"
  },
  {
    "id": 454595,
    "name": "KLC Services Inc    ( Hillard OH USA )",
    "country": "United States"
  },
  {
    "id": 174395,
    "name": "KLD-Biosistemas Equipamentos Eletronicos Ltda    ( Amparo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 103287,
    "name": "Kleen Test Products    ( Port Washington WI USA )",
    "country": "United States"
  },
  {
    "id": 372173,
    "name": "Kleenair Group    ( Christchurch  USA )",
    "country": "United States"
  },
  {
    "id": 281797,
    "name": "Klein Vakuumtechnik    ( Niederfischbach  USA )",
    "country": "United States"
  },
  {
    "id": 285169,
    "name": "Kleinbub GmbH Metall und Kunststoffverarbeitung    ( Oberwolfach  Germany )",
    "country": "Germany"
  },
  {
    "id": 281951,
    "name": "Kleinfeld GmbH & Co Labortechnik    ( Gehrden  Germany )",
    "country": "Germany"
  },
  {
    "id": 293210,
    "name": "Kleingluehlampen AG    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 287471,
    "name": "Kleusberg GmbH & Co KG    ( Wissen  Germany )",
    "country": "Germany"
  },
  {
    "id": 408162,
    "name": "KLIMAmed Technology Medizingeraete GmbH    ( Kreuzlingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457432,
    "name": "Klimed Klimatechnik und Medizintechnik GmbH    ( Heusenstamm-Rembruecken  Germany )",
    "country": "Germany"
  },
  {
    "id": 317122,
    "name": "Klimed Technology Medizintechnik GmbH    ( Heusenstamm/Rembrucken  Germany )",
    "country": "Germany"
  },
  {
    "id": 398101,
    "name": "Klinckmann Dental Medical    ( Taunusstein/Neuhof  USA )",
    "country": "United States"
  },
  {
    "id": 467000,
    "name": "Klinger Medical    ( College Point NY USA )",
    "country": "United States"
  },
  {
    "id": 281907,
    "name": "KLN Ultraschall GmbH    ( Heppenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 252779,
    "name": "KLS Martin LP    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457230,
    "name": "KM Healthcare Corp    ( Guri  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456033,
    "name": "KMG    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458508,
    "name": "KMI Surgical    ( Downingtown PA USA )",
    "country": "United States"
  },
  {
    "id": 999581,
    "name": "KMI, Kolster Methods, INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 415426,
    "name": "Kneipp (Schweiz) GmbH    ( Wallisellen  USA )",
    "country": "United States"
  },
  {
    "id": 415423,
    "name": "Kneipp Austria Naturheilmittel GmbH    ( Linz  USA )",
    "country": "United States"
  },
  {
    "id": 415421,
    "name": "Kneipp Corp of America    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 415422,
    "name": "Kneipp de Argentina SA    ( Capital Federal  USA )",
    "country": "United States"
  },
  {
    "id": 415427,
    "name": "Kneipp Italia srl    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 415424,
    "name": "Kneipp Nederland bv    ( Montfoort  USA )",
    "country": "United States"
  },
  {
    "id": 287610,
    "name": "Kneipp Werke Leusser & Oberhaeusser GmbH Co KG    ( Wuerzburg  USA )",
    "country": "United States"
  },
  {
    "id": 395416,
    "name": "KNF Corp    ( Tamaqua PA USA )",
    "country": "United States"
  },
  {
    "id": 280093,
    "name": "Knick - Elektronische Messgeraete GmbH & Co    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 101535,
    "name": "Knight's Dividers & Supplies    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 150075,
    "name": "Knit-Rite Inc    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 298080,
    "name": "Knoblich Licht GmbH    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 461422,
    "name": "Knome Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 334000,
    "name": "Knorr Associates Inc    ( Butler NJ USA )",
    "country": "United States"
  },
  {
    "id": 459168,
    "name": "Knu Healthcare    ( Ferdinand IN USA )",
    "country": "United States"
  },
  {
    "id": 306614,
    "name": "Knuerr AG    ( Muenchsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 320696,
    "name": "Knurr Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 459427,
    "name": "Koala Kare Products    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 305953,
    "name": "Kobayashi Medical Div    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 230343,
    "name": "Kobayashi Shoji KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 286454,
    "name": "Kodak Aktiengesellschaft    ( Stuttgart  USA )",
    "country": "United States"
  },
  {
    "id": 104224,
    "name": "Kodak Canada Inc    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 441869,
    "name": "Kodak Dental Systems    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 227142,
    "name": "Kodak Japan Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 158814,
    "name": "Kodra Apparatebau KG Walter Fischer GmbH & Co    ( Stuttgart/Degerloch  Germany )",
    "country": "Germany"
  },
  {
    "id": 439168,
    "name": "Koettermann AG    ( Gousau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439178,
    "name": "Koettermann Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 439176,
    "name": "Koettermann bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 439175,
    "name": "Koettermann bvba    ( Antwerpen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 158815,
    "name": "Koettermann GmbH & Co KG    ( Uetze/Haenigsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 439174,
    "name": "Koettermann Ltd    ( Bourne End  England )",
    "country": "UK"
  },
  {
    "id": 439172,
    "name": "Koettermann Sarl    ( Saint Quentin Fallavier  France )",
    "country": "France"
  },
  {
    "id": 439171,
    "name": "Koettermann Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 439179,
    "name": "Koettermann Systemlabor SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 457603,
    "name": "Koettermann Systemlabor SAS    ( Vaulx Milieu  France )",
    "country": "France"
  },
  {
    "id": 460616,
    "name": "Kofax Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 381827,
    "name": "Kohken Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 467005,
    "name": "Kohler Co    ( Kohler WI USA )",
    "country": "United States"
  },
  {
    "id": 284883,
    "name": "Kohler Medizintechnik GmbH & Co KG    ( Stockach  Germany )",
    "country": "Germany"
  },
  {
    "id": 280400,
    "name": "Kohlgrueber GmbH Raeder-Rollen-Reifen    ( Borgholzhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 152417,
    "name": "Koken Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 459261,
    "name": "Kokopelli Technologies LLC    ( Santa Fe NM USA )",
    "country": "United States"
  },
  {
    "id": 232358,
    "name": "Kokusan Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450584,
    "name": "KOL Biomedical Instruments    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 459054,
    "name": "Kolar Systems International    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 286959,
    "name": "Kolb Schilderfabrik    ( Unnau  Germany )",
    "country": "Germany"
  },
  {
    "id": 394685,
    "name": "Kold-Draft    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 431329,
    "name": "Koldcare    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 999408,
    "name": "Koldwave    ( Westfield MA USA )",
    "country": "United States"
  },
  {
    "id": 174397,
    "name": "Kolplast Comercial Industrial Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 454990,
    "name": "Komal Health Care Pvt Ltd    ( Thane  India )",
    "country": "India"
  },
  {
    "id": 267251,
    "name": "Komes International Co    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 416095,
    "name": "Komet Medical    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 157955,
    "name": "Konan Medical Inc    ( Nishinomiya  Japan )",
    "country": "Japan"
  },
  {
    "id": 464105,
    "name": "Konan Medical USA Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 174398,
    "name": "Konex Industria e Comercio de Metais Ltda    ( Ipiranga-SP  USA )",
    "country": "United States"
  },
  {
    "id": 455745,
    "name": "Kong Fai Non-Woven Articles (HK) Co Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401484,
    "name": "Kongsak X-Ray Medical Industry Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 458287,
    "name": "Konica Minolta Business Solutions USA Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 441088,
    "name": "Konica Minolta Graphic Imaging USA    ( Glen Cove NY USA )",
    "country": "United States"
  },
  {
    "id": 441055,
    "name": "Konica Minolta Holding Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 441090,
    "name": "Konica Minolta Medical & Graphic Imaging Europe Gm    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 441054,
    "name": "Konica Minolta Medical Imaging Inc    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 451937,
    "name": "Konica Minolta Sensing America Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 451938,
    "name": "Konica Minolta Sensing Europe bv    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451936,
    "name": "Konica Minolta Sensing Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 101412,
    "name": "Konigsberg Instruments Inc    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 474853,
    "name": "Koning Corp    ( West Henrietta NY USA )",
    "country": "United States"
  },
  {
    "id": 285975,
    "name": "Konrad Berg Passteilfabrikation Orthopaedie-Techni    ( Schorndorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 102547,
    "name": "Kontes Glass Co    ( Vineland NJ USA )",
    "country": "United States"
  },
  {
    "id": 174878,
    "name": "Kontur Kontact Lens Co Inc    ( Hercules CA USA )",
    "country": "United States"
  },
  {
    "id": 458054,
    "name": "KooJoo Trading Co    ( Gimpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453155,
    "name": "Kopp Development Inc    ( Jensen Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 454177,
    "name": "Kopp Development Inc (Spain)    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 999553,
    "name": "Kopycake    ( Torrance CA USA )",
    "country": "USA"
  },
  {
    "id": 458847,
    "name": "Korchek Technologies    ( Trumbull CT USA )",
    "country": "United States"
  },
  {
    "id": 466937,
    "name": "KORDEN    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 278115,
    "name": "Korea Miura Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 108389,
    "name": "Koregon Enterprises Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 285082,
    "name": "Korn Medizin-Technik    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 150433,
    "name": "Koros Surgical Instruments    ( Moorpark CA USA )",
    "country": "United States"
  },
  {
    "id": 183075,
    "name": "Koroseal Wall Protection Systems    ( Fairlawn OH USA )",
    "country": "United States"
  },
  {
    "id": 373220,
    "name": "KORR Medical Technologies Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 287058,
    "name": "Kortemeyer GmbH Vitrinen & Infosysteme    ( Vlotho  Germany )",
    "country": "Germany"
  },
  {
    "id": 185292,
    "name": "Koryo Eyetech Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 288658,
    "name": "Kosan Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454019,
    "name": "Kosmic Medical Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 168969,
    "name": "Kosmo Koch & Co    ( Lugano  USA )",
    "country": "United States"
  },
  {
    "id": 456021,
    "name": "Kossan Latex Industries (M) Sdn Bhd    ( Klang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 283916,
    "name": "Kotzolt-Leuchten L & G Kotzolt GmbH & Co KG    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 453044,
    "name": "Kou Hing Hong Scientific Supplies Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 466455,
    "name": "Kova Laboratories    ( Margate FL USA )",
    "country": "United States"
  },
  {
    "id": 466298,
    "name": "Kovarus Inc    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 454267,
    "name": "Koven Technology Canada Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 106311,
    "name": "Koven Technology Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 149005,
    "name": "Kowa Health Care Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 149004,
    "name": "Kowa Optimed Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 407313,
    "name": "KPI Cos    ( Riverside CA USA )",
    "country": "United States"
  },
  {
    "id": 335874,
    "name": "Krackeler Scientific Inc    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 280260,
    "name": "Kraemer & Grebe GmbH & Co KG    ( Biedenkopf-Wallau  Germany )",
    "country": "Germany"
  },
  {
    "id": 306782,
    "name": "Kraemer & Kroell GmbH    ( Muelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 456514,
    "name": "Kraemer AG    ( Bassersdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 284773,
    "name": "Kraemer Einrichtungen fuer Krankenhaus und Altenhe    ( Muenzenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 293226,
    "name": "Kraemer Metallwarenfabrik AG    ( Bassersdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174052,
    "name": "Krainer Medtechnik HGmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 286456,
    "name": "Kraiss & Friz Autogenwerk-Sauerstoffwerk    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 461340,
    "name": "Kramer Electronics Ltd    ( Clinton NJ USA )",
    "country": "United States"
  },
  {
    "id": 461009,
    "name": "Kramer Technologies Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 454188,
    "name": "Krames    ( Yardley PA USA )",
    "country": "United States"
  },
  {
    "id": 282941,
    "name": "Krankenhausdrucke-Verlag Wanne-Eickel GmbH    ( Herne  Germany )",
    "country": "Germany"
  },
  {
    "id": 279358,
    "name": "Krantz TKT GmbH    ( Bergisch Gladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 451860,
    "name": "Krasity's Medical & Surgical Supply Inc    ( Dearborn MI USA )",
    "country": "United States"
  },
  {
    "id": 458398,
    "name": "Kratos Defense & Security Solutions Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 279550,
    "name": "Krause Medizin Technik Service GmbH    ( Arnum  Germany )",
    "country": "Germany"
  },
  {
    "id": 183964,
    "name": "Krauth Medical KG GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 102782,
    "name": "Krebs Instruments    ( Little Ferry NJ USA )",
    "country": "United States"
  },
  {
    "id": 336754,
    "name": "Kreg Information Systems    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 459131,
    "name": "Kreg Therapeutics Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 283090,
    "name": "Kress GmbH    ( Hoesbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 151492,
    "name": "Kretztechnik AG    ( Zipf  USA )",
    "country": "United States"
  },
  {
    "id": 398849,
    "name": "Kretztechnik France    ( Ittlenheim  USA )",
    "country": "United States"
  },
  {
    "id": 158961,
    "name": "Kretztechnik GmbH    ( Marl  USA )",
    "country": "United States"
  },
  {
    "id": 235120,
    "name": "Kreuzer GmbH & Co oHG    ( Puchheim bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 440824,
    "name": "Krieger Medical Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 293145,
    "name": "Kroeber Medizintechnik GmbH    ( Dieblich  Germany )",
    "country": "Germany"
  },
  {
    "id": 999571,
    "name": "Krohn-Hite Co.    ( Brockton MA USA )",
    "country": "USA"
  },
  {
    "id": 338606,
    "name": "Kronner Medical Mfg    ( Roseburg OR USA )",
    "country": "United States"
  },
  {
    "id": 336755,
    "name": "Kronos Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 385382,
    "name": "Kronus Inc    ( Star ID USA )",
    "country": "United States"
  },
  {
    "id": 304541,
    "name": "Krown Mfg Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 282471,
    "name": "Kruess GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459085,
    "name": "Krug Inc    ( Kitchener ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453840,
    "name": "Kryptiq Corp    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 452010,
    "name": "KS CNC - Lasertechnik e K    ( Kolbingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283504,
    "name": "KS Instrumente Karl Schad    ( Kolbingen  USA )",
    "country": "United States"
  },
  {
    "id": 158824,
    "name": "KSG Sterilisatoren GmbH    ( Olching  Germany )",
    "country": "Germany"
  },
  {
    "id": 284980,
    "name": "KSN-Wassertechnik    ( Nistertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 456034,
    "name": "KTMED Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453568,
    "name": "Kub Technologies Inc    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 158831,
    "name": "Kuepper-Primax GmbH    ( Troisdorf-Spich  Germany )",
    "country": "Germany"
  },
  {
    "id": 342074,
    "name": "Kueschall Design AG    ( Allschwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 353395,
    "name": "Kugel Medizintechnik Vertriebs GmbH    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 184064,
    "name": "Kuhlman Technologies Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 281012,
    "name": "Kuhn Medizintechnik    ( Duerbheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 224629,
    "name": "Kuk-Je Medical Co Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 459195,
    "name": "KUKA Laboratories GmbH    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 373065,
    "name": "Kumyong Yamato Scale Co Ltd    ( Inchon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 309119,
    "name": "Kundt & Co AG    ( Elgg  USA )",
    "country": "United States"
  },
  {
    "id": 281915,
    "name": "Kundt GmbH & Co    ( Gaienhofen  USA )",
    "country": "United States"
  },
  {
    "id": 148518,
    "name": "Kung Long Batteries    (   USA )",
    "country": "United States"
  },
  {
    "id": 467240,
    "name": "LadyCare Lifetime Ltd    ( Brislington  England )",
    "country": "UK"
  },
  {
    "id": 456657,
    "name": "Kunming Hiwings Medical Device Technology Develop    ( Kunming  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455944,
    "name": "Kunshan Deyi Plastic Co Ltd    ( Kunshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455947,
    "name": "Kunshan Master Grade Electronic Technology Co Ltd    ( Kunshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 285272,
    "name": "Kup-Kurtz & Puchner GmbH & Co    ( Ostfildern  Germany )",
    "country": "Germany"
  },
  {
    "id": 174106,
    "name": "Kuraray Medical Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 463027,
    "name": "Kuros Biosurgery AG    ( Zurich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 286460,
    "name": "Kurt Bauer & Co GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 282263,
    "name": "Kurt Goericke Inh Eva Schuler-Busbach Papierwaren    ( Hagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287021,
    "name": "Kurt Noor & Co Fabrik fuer Berufskleidung    ( Viernheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 286784,
    "name": "Kurt Semrau GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161119,
    "name": "Kurt Zeipel Medizintechnik/Entwicklung    ( Bovenden/Lenglern  Germany )",
    "country": "Germany"
  },
  {
    "id": 450545,
    "name": "Kurz Medical Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 282299,
    "name": "Kusch & Co Sitzmoebelwerke GmbH & Co KG    ( Hallenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452087,
    "name": "Kuschall AG    ( Witterswel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 431357,
    "name": "Kushies Baby    ( Stoney Creek ON Canada )",
    "country": "Canada"
  },
  {
    "id": 170903,
    "name": "Kustomer Kinetics Inc    ( Arcadia CA USA )",
    "country": "United States"
  },
  {
    "id": 174656,
    "name": "KVB Mfg    ( Smiths Falls ON USA )",
    "country": "United States"
  },
  {
    "id": 457991,
    "name": "KVMs.com    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 174401,
    "name": "KW Industria Nacional de Technologia Eletronica Lt    ( Amparo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 441012,
    "name": "KW-Med Inc    ( Antioch IL USA )",
    "country": "United States"
  },
  {
    "id": 459133,
    "name": "Kwalu    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 185422,
    "name": "Kwapisz Pompy Infuzyjne    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 453753,
    "name": "KYMCO Healthcare Ltd    ( Pontyclun  Wales )",
    "country": "Wales"
  },
  {
    "id": 178579,
    "name": "Kyocera Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 174108,
    "name": "Kyoto Kagaku Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 307298,
    "name": "Kyowa Hakko Industry (s) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 173123,
    "name": "Kyowa Hakko Kogyo Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 157960,
    "name": "Kyowa Medex Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 152418,
    "name": "Kyowa Optical Co Ltd    ( Sagamihara  Japan )",
    "country": "Japan"
  },
  {
    "id": 393191,
    "name": "Kyphon Europe    ( Zaventem  USA )",
    "country": "United States"
  },
  {
    "id": 392882,
    "name": "Kyphon Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 286356,
    "name": "L & C Arnold Stendal GmbH    ( Stendal  Germany )",
    "country": "Germany"
  },
  {
    "id": 401726,
    "name": "L & M Equipment    ( Stafford  Australia )",
    "country": "Australia"
  },
  {
    "id": 999793,
    "name": "L & R Manufacturing Company    ( Kearny NJ USA )",
    "country": "USA"
  },
  {
    "id": 103289,
    "name": "L & R Mfg Co    ( Kearny NJ USA )",
    "country": "United States"
  },
  {
    "id": 454856,
    "name": "L E West Ltd    ( Barking  England )",
    "country": "UK"
  },
  {
    "id": 140164,
    "name": "L M Ericsson    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 449831,
    "name": "L Vad Technology Inc    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 416164,
    "name": "L Vermeiren Ges mbH    ( Linz  Austria )",
    "country": "Austria"
  },
  {
    "id": 456306,
    "name": "L-Tac Medicare Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 152368,
    "name": "L'accessorio Nucleare srl (L'ACN)    ( Cerro Maggiore (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 171732,
    "name": "L'Air Liquide SA    ( Paris Cedex 07  France )",
    "country": "France"
  },
  {
    "id": 458989,
    "name": "L&H Sign Company Inc    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 151018,
    "name": "La Diffusion Technique Francaise    ( Saint-Etienne Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 187389,
    "name": "La Pointique International Ltd    ( Tukwila WA USA )",
    "country": "United States"
  },
  {
    "id": 459167,
    "name": "La-Z-Boy Contract Furniture    ( Ferdinand IN USA )",
    "country": "United States"
  },
  {
    "id": 463024,
    "name": "Lab Armor LLC    (   USA )",
    "country": "United States"
  },
  {
    "id": 103486,
    "name": "Lab Fabricators Co    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 438943,
    "name": "Lab Furnishings (UK) Ltd    ( Haverhill  USA )",
    "country": "United States"
  },
  {
    "id": 458170,
    "name": "Lab Inventory Systems Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 108832,
    "name": "LAB Medical Supply    ( Latrobe PA USA )",
    "country": "United States"
  },
  {
    "id": 105150,
    "name": "Lab Products Inc    ( Seaford DE USA )",
    "country": "United States"
  },
  {
    "id": 455651,
    "name": "Lab Research Products    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 102718,
    "name": "Lab Safety Supply Inc    ( Janesville WI USA )",
    "country": "United States"
  },
  {
    "id": 456808,
    "name": "Lab Service sas    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 151749,
    "name": "Lab Vision (UK) Ltd    ( Rancom  England )",
    "country": "UK"
  },
  {
    "id": 232486,
    "name": "Lab Vision Corp    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 102551,
    "name": "Lab-Line Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 185923,
    "name": "Lab-Marc Inc    ( Spokane WA USA )",
    "country": "United States"
  },
  {
    "id": 184258,
    "name": "Labcaire Systems Ltd    ( Clevedon  England )",
    "country": "UK"
  },
  {
    "id": 170457,
    "name": "Labcare Services    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 162927,
    "name": "Labco Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 451943,
    "name": "Labcold Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 230456,
    "name": "Labcon North America    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 102550,
    "name": "Labconco Corp    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 455687,
    "name": "Labcor Laboratorios Ltda    ( Belo Horizonte-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 999576,
    "name": "LabCraft    (   USA )",
    "country": "United States"
  },
  {
    "id": 456985,
    "name": "LABDen Modern International Corp    ( Dongguan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101413,
    "name": "LaBerne Mfg Co Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 284447,
    "name": "Labex Scientific Equipment GmbH    ( Michelstadt  USA )",
    "country": "United States"
  },
  {
    "id": 140719,
    "name": "Labhospy    ( Tegucigalpa MDC  Honduras )",
    "country": "Honduras"
  },
  {
    "id": 401662,
    "name": "LAbiTec LAbor Biomedical Technologies GmbH    ( Ahrensberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454846,
    "name": "LabIVF Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 458783,
    "name": "LabIVF Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 459117,
    "name": "LabLogic Systems Inc    ( Brandon FL USA )",
    "country": "United States"
  },
  {
    "id": 393076,
    "name": "Labnet International Inc    ( Woodbridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 377952,
    "name": "Labnet International UK    ( Windsor  England )",
    "country": "UK"
  },
  {
    "id": 455950,
    "name": "Labnovation Technologies Inc    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454809,
    "name": "Labo America Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 457550,
    "name": "Labo Electrofrance    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 311389,
    "name": "Labo-Medica Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452796,
    "name": "Labocast LLC    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 999404,
    "name": "LaboGene Inc.    ( Cerritos CA USA )",
    "country": "United States"
  },
  {
    "id": 458288,
    "name": "Labomed Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 999511,
    "name": "Labomed, Inc.    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 441271,
    "name": "LabOne Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 456625,
    "name": "Labor and Birth Tub Co LLC    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 151016,
    "name": "Laboratoires ANIOS    ( Lille-Hellemmes  France )",
    "country": "France"
  },
  {
    "id": 345915,
    "name": "Laboratoires Bard SA    ( Voisins-le-Bretonneux  France )",
    "country": "France"
  },
  {
    "id": 455665,
    "name": "Laboratoires FILORGA    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 172376,
    "name": "Laboratoires Perouse Implant    ( Ivry le Temple  France )",
    "country": "France"
  },
  {
    "id": 393139,
    "name": "Laboratories Randox    ( Roissy  France )",
    "country": "France"
  },
  {
    "id": 453060,
    "name": "Laboratorio Elettrofisico Engineering Srl    ( Nerviano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 393142,
    "name": "Laboratorios Randox SL    ( L'Hospitalet de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 402297,
    "name": "Laboratorium Corneal Polska    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 182922,
    "name": "Laboratory & Scientific Equipment Co (Pty) Ltd    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 232585,
    "name": "Laboratory Corp of America    ( Burlington NC USA )",
    "country": "United States"
  },
  {
    "id": 179095,
    "name": "Laboratory Equipment (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 106805,
    "name": "Laboratory Equipment Technology Ltd    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 374959,
    "name": "Laboratory Furniture Innovators    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 466902,
    "name": "Laboratory Optical Service Inc    ( Abingdon VA USA )",
    "country": "United States"
  },
  {
    "id": 458016,
    "name": "Laboratory Precision Ltd    ( Daventry  England )",
    "country": "UK"
  },
  {
    "id": 451380,
    "name": "Laboratory Supply Co (LABSCO) Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 107021,
    "name": "Laboratory Technologies Inc    ( Maple Park IL USA )",
    "country": "United States"
  },
  {
    "id": 463069,
    "name": "Laborbau Systeme Hemling GmbH & Co KG    ( Ahaus  Germany )",
    "country": "Germany"
  },
  {
    "id": 161081,
    "name": "Laborgeraete Vetter GmbH    ( Wiesloch  Germany )",
    "country": "Germany"
  },
  {
    "id": 456801,
    "name": "Laborie International China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 150585,
    "name": "Laborie Medical Technologies Corp    ( Williston VT USA )",
    "country": "United States"
  },
  {
    "id": 152790,
    "name": "Laborie Medical Technologies Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 347309,
    "name": "Laborie Medical Technologies NV    ( Sint-Niklaas  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 306347,
    "name": "Labotect Labor-Technik-Goettingen GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 377403,
    "name": "Labotix Automation Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 458167,
    "name": "LABRepCo    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 999822,
    "name": "LABREPCO    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 461302,
    "name": "LabRetriever EMR Solutions    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 467339,
    "name": "Labsystems Diagnostics Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 105145,
    "name": "Labthermics Technologies Inc    ( Champaign IL USA )",
    "country": "United States"
  },
  {
    "id": 169897,
    "name": "Labtronics Inc    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459158,
    "name": "LABVANTAGE Solutions Inc    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 104653,
    "name": "Lac-Mac Ltd    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457903,
    "name": "Laccure AB    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 186047,
    "name": "Lace Elettronica srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 370839,
    "name": "Lacey Mfg Co Inc    ( Bridgeport CT USA )",
    "country": "United States"
  },
  {
    "id": 174880,
    "name": "Lacrimedics Inc    ( Eastsound WA USA )",
    "country": "United States"
  },
  {
    "id": 106090,
    "name": "Lact-Aid International Inc    ( Athens TN USA )",
    "country": "United States"
  },
  {
    "id": 307674,
    "name": "Ladd Research    ( Williston VT USA )",
    "country": "United States"
  },
  {
    "id": 176280,
    "name": "Ladies First Inc    ( Salem OR USA )",
    "country": "United States"
  },
  {
    "id": 346958,
    "name": "Laerdal Benelux NV    ( Valkenswaard  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454014,
    "name": "Laerdal China Ltd    ( Northern Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 346963,
    "name": "Laerdal Danmark    ( Kobenhaven S  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 346957,
    "name": "Laerdal Espana SL    ( San Sebastian de los Reyes  Spain )",
    "country": "Spain"
  },
  {
    "id": 434166,
    "name": "Laerdal Hospiline Sdn Bhd    ( Shah Alam  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 346956,
    "name": "Laerdal Italia srl    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454016,
    "name": "Laerdal Medical (Suzhou) Co Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 139627,
    "name": "Laerdal Medical A/S    ( Stavanger  Norway )",
    "country": "Norway"
  },
  {
    "id": 346961,
    "name": "Laerdal Medical AB    ( Saltsjo-Boo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 273233,
    "name": "Laerdal Medical Canada Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101417,
    "name": "Laerdal Medical Corp    ( Wappingers Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 179058,
    "name": "Laerdal Medical Far East    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 346955,
    "name": "Laerdal Medical France SA    ( Limonest Cedex  France )",
    "country": "France"
  },
  {
    "id": 346954,
    "name": "Laerdal Medical GmbH & Co    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 374309,
    "name": "Laerdal Medical Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454015,
    "name": "Laerdal Medical Korea LLC (LMK)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 272140,
    "name": "Laerdal Medical Ltd    ( Orpington  England )",
    "country": "UK"
  },
  {
    "id": 346962,
    "name": "Laerdal Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 346959,
    "name": "Laerdal Pty Ltd    ( Oakleigh  Australia )",
    "country": "Australia"
  },
  {
    "id": 102276,
    "name": "Lafayette Instrument Co Inc    ( Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 158835,
    "name": "Laflow Reinraumtechnik GmbH & Co    ( Blaubeuren  Germany )",
    "country": "Germany"
  },
  {
    "id": 279235,
    "name": "Lafon-GBM    ( Fertans  France )",
    "country": "France"
  },
  {
    "id": 456010,
    "name": "Lagis Enterprise Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 366523,
    "name": "Laglove (M) Sdn Bhd    ( Kajang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 462876,
    "name": "Lagniappe Pharmacy Services (LPS)    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 464112,
    "name": "Laird Technologies Inc    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 459206,
    "name": "Laitek Inc    ( Homewood IL USA )",
    "country": "United States"
  },
  {
    "id": 416251,
    "name": "LAK Enterprises Inc    ( Millbrae CA USA )",
    "country": "United States"
  },
  {
    "id": 467127,
    "name": "Lake Court Medical Supplies    ( Roseville MI USA )",
    "country": "United States"
  },
  {
    "id": 466999,
    "name": "Lake Forest Anatomicals    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 398284,
    "name": "Lake Region Mfg Inc    ( Chaska MN USA )",
    "country": "United States"
  },
  {
    "id": 175816,
    "name": "Lakeland Industries Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 452129,
    "name": "Lakes Health Systems Inc    ( Gate City VA USA )",
    "country": "United States"
  },
  {
    "id": 401791,
    "name": "Lakeshore Technologies Inc    ( Bridgeport NY USA )",
    "country": "United States"
  },
  {
    "id": 103808,
    "name": "Lakeside Mfg Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 171018,
    "name": "Lambda Photometrics Ltd    ( Harpenden  USA )",
    "country": "United States"
  },
  {
    "id": 393130,
    "name": "Lambda Physik AG    ( Goettingen  USA )",
    "country": "United States"
  },
  {
    "id": 374123,
    "name": "LaserSight Technologies Inc (Belgium)    ( Aartselaar  USA )",
    "country": "United States"
  },
  {
    "id": 393131,
    "name": "Lambda Physik Japan Co Ltd    ( Yokohama  USA )",
    "country": "United States"
  },
  {
    "id": 174881,
    "name": "Lambda Physik USA Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 291930,
    "name": "LaMed Inc    ( Shelby Township MI USA )",
    "country": "United States"
  },
  {
    "id": 280768,
    "name": "LAmed Vertriebs GmbH fuer Med-Tech Produkte    ( Oberhaching bei Muechen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306021,
    "name": "Lamedid    ( Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 101418,
    "name": "Lamico Inc    ( Oshkosh WI USA )",
    "country": "United States"
  },
  {
    "id": 434224,
    "name": "Lamidey Noury Medical    ( Verrieres-le-Buisson  France )",
    "country": "France"
  },
  {
    "id": 102447,
    "name": "Laminar Flow Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 105956,
    "name": "Laminex Inc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 309680,
    "name": "Lamit Laboratorium en Medische Installatie Technie    ( Amersfoort  USA )",
    "country": "United States"
  },
  {
    "id": 451434,
    "name": "Lammers Medical Technology GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 354745,
    "name": "LaMont Medical (Australia) Pty Ltd    ( Homsby Heights  USA )",
    "country": "United States"
  },
  {
    "id": 295585,
    "name": "LaMont Medical Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 226330,
    "name": "LaMotte Chemical Products Co    ( Chestertown MD USA )",
    "country": "United States"
  },
  {
    "id": 185927,
    "name": "Lamp Technology Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 286313,
    "name": "Lampe GmbH    ( Stadtoldendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 107891,
    "name": "Lampire Biological Laboratories Inc    ( Pipersville PA USA )",
    "country": "United States"
  },
  {
    "id": 293795,
    "name": "Lamprecht AG    ( Regensdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391355,
    "name": "Lancer Holland bv    ( Wamel  USA )",
    "country": "United States"
  },
  {
    "id": 138079,
    "name": "Lancer Industrie    ( Tournefeuille  France )",
    "country": "France"
  },
  {
    "id": 185928,
    "name": "Lancer Medical Services Inc    ( Azusa CA USA )",
    "country": "United States"
  },
  {
    "id": 184864,
    "name": "Lancer Orthodontics Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 391354,
    "name": "Lancer UK Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 107921,
    "name": "Lancer USA Inc    ( Lake Mary FL USA )",
    "country": "United States"
  },
  {
    "id": 175817,
    "name": "Lancet Technology Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 455523,
    "name": "Land of Nod    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 288579,
    "name": "Landacorp Inc    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 298987,
    "name": "Landanger    ( Chaumont  France )",
    "country": "France"
  },
  {
    "id": 107375,
    "name": "Landau Uniforms    ( Olive Branch MS USA )",
    "country": "United States"
  },
  {
    "id": 108545,
    "name": "Landauer Inc    ( Glenwood IL USA )",
    "country": "United States"
  },
  {
    "id": 108957,
    "name": "Landice Inc    ( Randolph NJ USA )",
    "country": "United States"
  },
  {
    "id": 453034,
    "name": "Landwind International Medical Science Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 459134,
    "name": "Lane Telecommunications Inc    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 108923,
    "name": "Lanel Inc    ( Floral Park NY USA )",
    "country": "United States"
  },
  {
    "id": 282199,
    "name": "Lang & Co Verbandstoffe    ( Groebenzell  Germany )",
    "country": "Germany"
  },
  {
    "id": 232382,
    "name": "Lang Dental Mfg Co    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 157531,
    "name": "Langenas Eltekniska AB    ( Moelnlycke  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 101420,
    "name": "Langer Inc    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 459044,
    "name": "Language Access Network    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 459208,
    "name": "Language Service Assoc    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 307143,
    "name": "Lanoy Medical    ( Halfway House  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 464956,
    "name": "Lansing Sanitary Supply (LSS) Inc    ( Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 457657,
    "name": "Lansinoh Laboratories Inc    ( Alexandria VA USA )",
    "country": "United States"
  },
  {
    "id": 453071,
    "name": "Lantheus Medical Imaging    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 458434,
    "name": "Lantronix Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 455518,
    "name": "Lanx    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 455519,
    "name": "Lanx srl    ( Medolla (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 284158,
    "name": "LAP GmbH Laser Applikationen    ( Lueneburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 298369,
    "name": "LAP of America    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 336372,
    "name": "Lapp Austria GmbH    ( Linz  Austria )",
    "country": "Austria"
  },
  {
    "id": 455630,
    "name": "Laproman LLC    ( Dearborn MI USA )",
    "country": "United States"
  },
  {
    "id": 452642,
    "name": "LapSurgical Systems LLC    ( Roswell NM USA )",
    "country": "United States"
  },
  {
    "id": 105740,
    "name": "Lares Research    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 103170,
    "name": "Larkotex Co    ( Texarkana TX USA )",
    "country": "United States"
  },
  {
    "id": 279244,
    "name": "Lars    ( Arc sur Tille  France )",
    "country": "France"
  },
  {
    "id": 451506,
    "name": "Larsen & Toubro LLC    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 450828,
    "name": "Larsen & Toubro Ltd    ( Mumbia  USA )",
    "country": "United States"
  },
  {
    "id": 451507,
    "name": "Larsen & Toubro Ltd (China)    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 459293,
    "name": "Larson Davis    ( Depew NY USA )",
    "country": "United States"
  },
  {
    "id": 374143,
    "name": "Laryngeal Mask Co Ltd    ( Jersey  Great Britain )",
    "country": "UK"
  },
  {
    "id": 399189,
    "name": "LAS Laborapparate AG    ( Schoenengrund  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461208,
    "name": "LaSalle Solutions    ( Rosemont IL USA )",
    "country": "United States"
  },
  {
    "id": 103810,
    "name": "Lasco Diamond Products Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 458024,
    "name": "Lasco Services    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 104022,
    "name": "Lase Inc Div EnviroSurgical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458604,
    "name": "Laser Components USA Inc    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 270039,
    "name": "Laser Design Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 395428,
    "name": "Laser East    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 106387,
    "name": "Laser Engineering    (   USA )",
    "country": "United States"
  },
  {
    "id": 458290,
    "name": "Laser Engineering    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 106806,
    "name": "Laser Institute of America    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 331391,
    "name": "Laser Labs Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 361985,
    "name": "Laser Lines Ltd    ( Banbury  England )",
    "country": "UK"
  },
  {
    "id": 458684,
    "name": "Laser Operations LLC    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 192825,
    "name": "Laser Optik Systeme GmbH & Co KG    ( Mainz  USA )",
    "country": "United States"
  },
  {
    "id": 107810,
    "name": "Laser Peripherals LLC    ( Golden Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 455629,
    "name": "Laser Scientific    ( Round Rock TX USA )",
    "country": "United States"
  },
  {
    "id": 295272,
    "name": "Laser Solutions Inc    ( Basking Ridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 291072,
    "name": "Laser Technic Application Research Institute of Gu    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 474837,
    "name": "Laser Ventures Inc    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 306181,
    "name": "Laserex Technologies Pty Ltd    ( Unley  Australia )",
    "country": "Australia"
  },
  {
    "id": 320971,
    "name": "Lasering srl    ( Modena (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 185929,
    "name": "LaserMax Services Inc    ( Acworth GA USA )",
    "country": "United States"
  },
  {
    "id": 456035,
    "name": "LASEROPTEK Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 105685,
    "name": "Laserscope    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 179170,
    "name": "LaserSight Technologies Inc    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 158839,
    "name": "LaserVision GmbH    ( Fuerth  Germany )",
    "country": "Germany"
  },
  {
    "id": 453500,
    "name": "LASERVISION USA    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 416012,
    "name": "Laseuropa Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 999556,
    "name": "Lasko Products Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 178244,
    "name": "Lasotronic AG    ( Platting  Germany )",
    "country": "Germany"
  },
  {
    "id": 277806,
    "name": "Lasram Lezer Kft    ( Szentendre  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 451421,
    "name": "Laswell Medical    ( Milton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 463057,
    "name": "LAT Labor- und Analysen-Technik GmbH    ( Garbsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 158842,
    "name": "Latek    ( Eppelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 102976,
    "name": "Latest Products Corp    ( Syosset NY USA )",
    "country": "United States"
  },
  {
    "id": 456022,
    "name": "Latex Partners Berhad    ( Kamunting  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 460885,
    "name": "Latham & Phillips Ophthalmic    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 459369,
    "name": "Latham and Phillips Ophthalmic    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 999549,
    "name": "Lathem Time    ( Atlanta GA USA )",
    "country": "USA"
  },
  {
    "id": 306644,
    "name": "Latin American Hospital Supply Corp    ( Tegucigalpa MDC  Honduras )",
    "country": "Honduras"
  },
  {
    "id": 334108,
    "name": "Lattice Inc    ( Wheaton IL USA )",
    "country": "United States"
  },
  {
    "id": 416510,
    "name": "Lau-Verlag GmbH    ( Reinbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 158844,
    "name": "Lauda Dr R Wobser GmbH & Co KG    ( Lauda-Koenigshofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 336396,
    "name": "Lauer Membran Wassertechnik GmbH    ( Wittlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456602,
    "name": "LAUER-FISCHER Niederlassung Stuttgart    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 454418,
    "name": "Launch Diagnostics Ltd    ( Longfield  England )",
    "country": "UK"
  },
  {
    "id": 456415,
    "name": "Launch Electrical Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 464116,
    "name": "Laurimed LLC    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 403495,
    "name": "Laurus Systems Inc    ( Ellicott City MD USA )",
    "country": "United States"
  },
  {
    "id": 459209,
    "name": "Lavante Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 287354,
    "name": "Lavatherm W K Waerme und Kaeltepackungen    ( Ingolstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 460890,
    "name": "LaVision Inc    ( Ypsilanti MI USA )",
    "country": "United States"
  },
  {
    "id": 307149,
    "name": "Lawo Africa    ( Noordhoek  USA )",
    "country": "United States"
  },
  {
    "id": 286156,
    "name": "Lawson Mardon Singen GmbH    ( Singen  USA )",
    "country": "United States"
  },
  {
    "id": 184491,
    "name": "Lawson Software    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 184494,
    "name": "Lawson Software (Canada)    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 184493,
    "name": "Lawson Software (UK)    ( Bracknell  England )",
    "country": "UK"
  },
  {
    "id": 158914,
    "name": "Lawton GmbH & Co KG Medizintechnik    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 330095,
    "name": "Lazuli Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 457566,
    "name": "LBI Eyewear    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 402765,
    "name": "LC Medical Inc    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 464972,
    "name": "LCH Maintenance Services    ( Redondo Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 999426,
    "name": "LCL Beauty    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 323186,
    "name": "LCM Distribution Ltd    ( Calgary AB USA )",
    "country": "United States"
  },
  {
    "id": 457666,
    "name": "LDI Corp    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 458280,
    "name": "LDP LLC    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 462970,
    "name": "LDR USA    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 393153,
    "name": "LDT Europe GmbH    ( Schriesheim  USA )",
    "country": "United States"
  },
  {
    "id": 272030,
    "name": "Le Groupe Uniplus Medical Inc    ( St-Nicolas PQ USA )",
    "country": "United States"
  },
  {
    "id": 172294,
    "name": "Lipschitz Laboratory Appliances Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 453061,
    "name": "LE USA Walker Scientific Inc    ( Lake Orion MI USA )",
    "country": "United States"
  },
  {
    "id": 306251,
    "name": "LEA Medizintechnik GmbH    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107377,
    "name": "Lead Enterprises Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 105362,
    "name": "Lead-Lok Inc    ( Sandpoint ID USA )",
    "country": "United States"
  },
  {
    "id": 102028,
    "name": "Leader Industries Inc    ( South El Monte CA USA )",
    "country": "United States"
  },
  {
    "id": 102603,
    "name": "Leader Instruments Corp    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 441008,
    "name": "LearnCom    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 452369,
    "name": "Learning Communications LLC    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 331393,
    "name": "Leasing Consultants Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 305741,
    "name": "Leatherite Pty Ltd    ( Berkeley Vale  USA )",
    "country": "United States"
  },
  {
    "id": 105194,
    "name": "Lebanon Corp    ( Lebanon IN USA )",
    "country": "United States"
  },
  {
    "id": 164160,
    "name": "LEC Refrigeration plc    ( Bognor Regis  USA )",
    "country": "United States"
  },
  {
    "id": 459059,
    "name": "Lecat's Ventriloscope LLC    ( Tallmadge OH USA )",
    "country": "United States"
  },
  {
    "id": 455131,
    "name": "Lechnologies Research Inc    ( Sussex WI USA )",
    "country": "United States"
  },
  {
    "id": 283453,
    "name": "Leco Instrumente GmbH    ( Moenchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 356621,
    "name": "LeCroy Corp    ( Chestnut Ridge NY USA )",
    "country": "United States"
  },
  {
    "id": 465036,
    "name": "Lectrosonics Inc    ( Rio Rancho NM USA )",
    "country": "United States"
  },
  {
    "id": 453775,
    "name": "LED Dental Inc    ( White Rock BC Canada )",
    "country": "Canada"
  },
  {
    "id": 303962,
    "name": "Lee Hung Technical Co    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 108246,
    "name": "Lee Laboratories    ( Grayson GA USA )",
    "country": "United States"
  },
  {
    "id": 331181,
    "name": "Lee Medical International Inc    ( Harahan LA USA )",
    "country": "United States"
  },
  {
    "id": 108247,
    "name": "Lee Pharmaceuticals    ( South El Monte CA USA )",
    "country": "United States"
  },
  {
    "id": 104231,
    "name": "Leebaw Mfg Co Inc    ( Canfield OH USA )",
    "country": "United States"
  },
  {
    "id": 324584,
    "name": "Leec Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 460779,
    "name": "Legacy Data Access LLC    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 459139,
    "name": "Legacy Furniture Group Inc    ( Conover NC USA )",
    "country": "United States"
  },
  {
    "id": 457038,
    "name": "Legacy Medical Imaging    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 452400,
    "name": "Legiant    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 152095,
    "name": "Legrand    ( Limoges Cedex  France )",
    "country": "France"
  },
  {
    "id": 309777,
    "name": "Legrand Asia Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 449814,
    "name": "Legrand North America    ( West Hartford CT USA )",
    "country": "United States"
  },
  {
    "id": 453077,
    "name": "Leica Biosystems Melbourne Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 138009,
    "name": "Leica Microsystems (Schweiz) AG    ( Heerbrugg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 178273,
    "name": "Leica Microsystems (Switzerland) AG    ( Heerbrugg SG  USA )",
    "country": "United States"
  },
  {
    "id": 305989,
    "name": "Leica Microsystems (UK) Ltd    ( Newcastle upon Tyne  England )",
    "country": "UK"
  },
  {
    "id": 458125,
    "name": "Leica Microsystems (UK) Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 195307,
    "name": "Leica Microsystems Canada Inc    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 363806,
    "name": "Leica Microsystems Imaging Solutions Ltd    ( Cambridge  USA )",
    "country": "United States"
  },
  {
    "id": 107938,
    "name": "Leica Microsystems Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 195240,
    "name": "Leica Microsystems Nussloch GmbH    ( Nussloch bei Heidelberg  USA )",
    "country": "United States"
  },
  {
    "id": 158955,
    "name": "Leica Microsystems Vertrieb GmbH    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 172161,
    "name": "Leica Mikrosysteme Handelsges mbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 460583,
    "name": "Leidos Health    ( Westfield IN USA )",
    "country": "United States"
  },
  {
    "id": 281397,
    "name": "Leipold & Doehle GmbH    ( Eschwege  Germany )",
    "country": "Germany"
  },
  {
    "id": 106469,
    "name": "LEISEGANG MEDICAL INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 466948,
    "name": "Leistungselektronik JENA GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 108171,
    "name": "Leisure-Lift    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 459262,
    "name": "Leja    ( Nieuw-Vennep  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 285124,
    "name": "Leleux Vertriebs GmbH    ( Oberhausen  USA )",
    "country": "United States"
  },
  {
    "id": 393084,
    "name": "LeMaitre Vascular GmbH    ( Sulzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 393083,
    "name": "LeMaitre Vascular Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 452540,
    "name": "LeMaitre Vascular KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 393085,
    "name": "LeMaitre Vascular Sarl    ( Neuilly-en-Thelle  USA )",
    "country": "United States"
  },
  {
    "id": 458739,
    "name": "Lemer Pax    ( Carquefou  France )",
    "country": "France"
  },
  {
    "id": 999644,
    "name": "Lemi Group    (   USA )",
    "country": "United States"
  },
  {
    "id": 227236,
    "name": "LEMKE Medical TV GmbH    ( Groebenzell  USA )",
    "country": "United States"
  },
  {
    "id": 284649,
    "name": "Lemo Elektronik GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999396,
    "name": "Lemona Electronics    (   USA )",
    "country": "United States"
  },
  {
    "id": 458902,
    "name": "LeMond    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 393151,
    "name": "Len srl    ( Chiavari (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459296,
    "name": "Lencore Acoustics Corp    ( Woodbury NY USA )",
    "country": "United States"
  },
  {
    "id": 473842,
    "name": "Lenderking Caging Products    ( Millersville MD USA )",
    "country": "United States"
  },
  {
    "id": 461434,
    "name": "Lenel Systems International Inc    ( Pittsford NY USA )",
    "country": "United States"
  },
  {
    "id": 325069,
    "name": "Lenjoy Medical Engineering Inc    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 459501,
    "name": "Lennox International Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 451942,
    "name": "Lenovo (United States) Inc    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 451812,
    "name": "Lenox-MacLaren Surgical Corp    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 458603,
    "name": "LENSAR Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 462957,
    "name": "Leoch Battery Corp    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 158838,
    "name": "Leonhard Lang GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 283020,
    "name": "Leonhard Wiedemann Medizin und Technik    ( Hof  Germany )",
    "country": "Germany"
  },
  {
    "id": 455952,
    "name": "Lepu Medical Technology (Beijing) Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454078,
    "name": "Lescarden Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 459311,
    "name": "Lesro Industries Inc    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 155775,
    "name": "Lester A Dine Inc Corp    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 108884,
    "name": "Lester Electrical    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 283102,
    "name": "Letzner Pharmawasseraufbereitung GmbH    ( Hueckeswagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293376,
    "name": "Leuenberger Medizintechnik AG    ( Wallisellen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 403606,
    "name": "Lev-El Ltd    ( Ariel  USA )",
    "country": "United States"
  },
  {
    "id": 106355,
    "name": "Level 1 Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 194083,
    "name": "Leventon SA    ( Sant Esteve de Sesrovires  Spain )",
    "country": "Spain"
  },
  {
    "id": 284277,
    "name": "Lever Sutter GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 345604,
    "name": "Levi Medica srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 459443,
    "name": "Levi Ray & Shoup Inc    ( Springfield IL USA )",
    "country": "United States"
  },
  {
    "id": 175872,
    "name": "Levine Health Products Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 102147,
    "name": "Leviton Mfg Co Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 367312,
    "name": "Leviton Mfg of Canada Ltd    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 418657,
    "name": "Levitronix GmbH    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418645,
    "name": "Levitronix LLC    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 160259,
    "name": "LEVO AG    ( Wohlen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 328757,
    "name": "LEVO USA Inc    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 285116,
    "name": "Lewicki Microelectronic GmbH    ( Oberdischingen bei Ulm  USA )",
    "country": "United States"
  },
  {
    "id": 185705,
    "name": "Lewin Medical Supply    ( Riverhead NY USA )",
    "country": "United States"
  },
  {
    "id": 461437,
    "name": "Lewis Testing Services Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 452310,
    "name": "LexaMed Ltd    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 288586,
    "name": "Lexi-Comp Inc    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 172869,
    "name": "Lexicor Medical Technology Inc    ( Augusta GA USA )",
    "country": "United States"
  },
  {
    "id": 103365,
    "name": "Lexington Instruments Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 450572,
    "name": "Lexion Medical    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 461525,
    "name": "Lexitek Inc    ( Wellesley MA USA )",
    "country": "United States"
  },
  {
    "id": 999671,
    "name": "Lexmark    (   USA )",
    "country": "United States"
  },
  {
    "id": 233799,
    "name": "Lexmark International Inc    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 106810,
    "name": "Leybold Vacuum USA Inc    ( Export PA USA )",
    "country": "United States"
  },
  {
    "id": 457039,
    "name": "LFC Capital Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451443,
    "name": "LFC Equipment Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 450838,
    "name": "LG CNS Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456352,
    "name": "LG Electronics    ( Englewood Cliffs NJ USA )",
    "country": "United States"
  },
  {
    "id": 456620,
    "name": "LGM International Inc    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 441795,
    "name": "Lhasa OMS Inc    ( Weymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 458981,
    "name": "LI-COR Inc    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 461358,
    "name": "Liaison Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 304239,
    "name": "Liarre srl    ( Casalfiumanese (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455967,
    "name": "Libatape Pharmaceutical Co Ltd    ( Kumamoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 417546,
    "name": "Liberating Technologies Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 324481,
    "name": "Liberator Ltd    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 102552,
    "name": "Liberty Industries Inc    ( East Berlin CT USA )",
    "country": "United States"
  },
  {
    "id": 441130,
    "name": "Liberty Medical Specialties    ( Winterville NC USA )",
    "country": "United States"
  },
  {
    "id": 465027,
    "name": "Liberty Safe and Security Products, Inc.    ( Payson 11 USA )",
    "country": "United States"
  },
  {
    "id": 459445,
    "name": "Liberty Software Inc    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 158956,
    "name": "Licefa Kunststofftechnik GmbH & Co KG    ( Bad Salzuflen  Germany )",
    "country": "Germany"
  },
  {
    "id": 402799,
    "name": "Licht & Optik GmbH    ( Pullach/Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287507,
    "name": "Lichte Systemwand GmbH    ( Beckum  Germany )",
    "country": "Germany"
  },
  {
    "id": 452021,
    "name": "Liconic AG    ( Mauren  Liechtenstein )",
    "country": "Liechtenstein"
  },
  {
    "id": 452018,
    "name": "Liconic US Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 395004,
    "name": "LiDCO Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 152629,
    "name": "Liebel-Flarsheim Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 458053,
    "name": "Liebel-Flarsheim Co LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 168971,
    "name": "Lieberherr AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 267671,
    "name": "Liebert Corp    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 440112,
    "name": "Life Alaska Donor Services    ( Anchorage AK USA )",
    "country": "United States"
  },
  {
    "id": 298161,
    "name": "Life Care Ltd    ( Tiberias  Israel )",
    "country": "Israel"
  },
  {
    "id": 107380,
    "name": "LIFE Corp    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 105879,
    "name": "Life Fitness    ( Schiller Park IL USA )",
    "country": "United States"
  },
  {
    "id": 335138,
    "name": "Life Fitness Asia Pacific Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 335137,
    "name": "Life Fitness Atlantic bv    ( Barendrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392725,
    "name": "Life Fitness do Brazil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 366531,
    "name": "Life Fitness Europe GmbH    ( Unterschleissheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 417196,
    "name": "Life Fitness Iberia    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 392726,
    "name": "Life Fitness Italia srl    ( Affi (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 372187,
    "name": "Life Fitness Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 372186,
    "name": "Life Fitness UK Ltd    ( Ely  England )",
    "country": "UK"
  },
  {
    "id": 392727,
    "name": "Life Fitness Vertriebs GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 459023,
    "name": "Life Instrument Corp    ( Braintree MA USA )",
    "country": "United States"
  },
  {
    "id": 407312,
    "name": "Life Measurement Inc    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 185937,
    "name": "Life Medical Equipment    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 232623,
    "name": "Life Medical Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 452703,
    "name": "Life Partners Europe    ( Bagnolet  France )",
    "country": "France"
  },
  {
    "id": 454324,
    "name": "Life Point Inc    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 456708,
    "name": "Life Recovery Systems HD LLC    ( Kinnelon NJ USA )",
    "country": "United States"
  },
  {
    "id": 452226,
    "name": "Life Safety Distribution AG    ( Uster  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 410026,
    "name": "Life Safety Systems    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 999684,
    "name": "Life Sciences Inc.    ( Greenwich CT USA )",
    "country": "USA"
  },
  {
    "id": 104929,
    "name": "Life Sensing Instrument Co Inc    ( Tullahoma TN USA )",
    "country": "United States"
  },
  {
    "id": 150960,
    "name": "Life Support Engineering Ltd    ( Storrington  England )",
    "country": "UK"
  },
  {
    "id": 371520,
    "name": "Life Systems Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 455911,
    "name": "Life Systems International    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 453902,
    "name": "Life Technologies Corp    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 175823,
    "name": "Life-Assist Inc    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 102834,
    "name": "Life-Tech Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 178342,
    "name": "Life's Resources    ( Addison MI USA )",
    "country": "United States"
  },
  {
    "id": 186582,
    "name": "Lifecare Asia-Pacific Sales & Service Center    ( Kowloon  USA )",
    "country": "United States"
  },
  {
    "id": 150959,
    "name": "Lifecare Hospital Supplies Ltd    ( Market Harborough  England )",
    "country": "UK"
  },
  {
    "id": 461439,
    "name": "LifeCare Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 108039,
    "name": "Lifecare International Inc    ( Whittier CA USA )",
    "country": "United States"
  },
  {
    "id": 455710,
    "name": "LifeCare Surgical    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 371677,
    "name": "LifeCare Technologies Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 317195,
    "name": "LifeCell Corp    ( Branchburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 441030,
    "name": "Lifeclinic International    ( Burtonsville MD USA )",
    "country": "United States"
  },
  {
    "id": 418536,
    "name": "Lifecore Biomedical AB    ( Nacka  USA )",
    "country": "United States"
  },
  {
    "id": 418535,
    "name": "Lifecore Biomedical GmbH    ( Alfter  USA )",
    "country": "United States"
  },
  {
    "id": 108164,
    "name": "Lifecore Biomedical Inc    ( Chaska MN USA )",
    "country": "United States"
  },
  {
    "id": 418534,
    "name": "Lifecore Biomedical SpA    ( Veronan VR  USA )",
    "country": "United States"
  },
  {
    "id": 453405,
    "name": "LifeGas    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 104233,
    "name": "Lifeguard Medical    ( Plainfield IN USA )",
    "country": "United States"
  },
  {
    "id": 453883,
    "name": "Lifehealthcare Distribution Pty Ltd    ( Artarmon  Australia )",
    "country": "Australia"
  },
  {
    "id": 458464,
    "name": "lifeIMAGE    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 108152,
    "name": "Lifeline Emergency Vehicles    ( Sumner IA USA )",
    "country": "United States"
  },
  {
    "id": 456516,
    "name": "Lifeline First Aid    ( Wilsonville OR USA )",
    "country": "United States"
  },
  {
    "id": 105812,
    "name": "Lifeline Instruments Inc    ( Redmond OR USA )",
    "country": "United States"
  },
  {
    "id": 176287,
    "name": "Lifeline International    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 474864,
    "name": "LifeLine Mobile Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 459067,
    "name": "LifeLine Software Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 104229,
    "name": "Lifeline Systems    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 426429,
    "name": "LifeLines Ltd    ( North Stockbridge  England )",
    "country": "UK"
  },
  {
    "id": 426428,
    "name": "LifeLines Neurodiagnostic Systems Inc    ( Troy IL USA )",
    "country": "United States"
  },
  {
    "id": 431317,
    "name": "LifeLink Foundation Tissue Bank    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 354242,
    "name": "LifeLoc Technologies Inc    ( Wheat Ridge CO USA )",
    "country": "United States"
  },
  {
    "id": 101676,
    "name": "Lifemed of California    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 372305,
    "name": "LifeNet    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 456518,
    "name": "LifeOnKey Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 456772,
    "name": "LifePoint Inc    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 461349,
    "name": "Lifepoint Informatics    ( Ridgewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 107682,
    "name": "LifePort Inc    ( Woodland WA USA )",
    "country": "United States"
  },
  {
    "id": 457845,
    "name": "LifeSaving Medical Solutions LLC    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 107747,
    "name": "Lifesaving Resources Inc    ( Harrisville NH USA )",
    "country": "United States"
  },
  {
    "id": 459076,
    "name": "Lifesaving Systems Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 307301,
    "name": "Lifescan (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 271363,
    "name": "LifeScan China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 271361,
    "name": "LifeScan France    ( Issy-les-Moulineaux Cedex 9  France )",
    "country": "France"
  },
  {
    "id": 104078,
    "name": "LifeScan Inc    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 452629,
    "name": "LifeScience PLUS    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 457825,
    "name": "LifeScience Plus Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 455598,
    "name": "LifeSecure    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 349929,
    "name": "Lifesign    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 458420,
    "name": "LifeSize Communications Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 457002,
    "name": "LifeSolutionsPlus Inc    ( Willow Street PA USA )",
    "country": "United States"
  },
  {
    "id": 458578,
    "name": "Lifespan Healthcare LLC    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 323460,
    "name": "LifeSuit America    ( Dresher PA USA )",
    "country": "United States"
  },
  {
    "id": 457586,
    "name": "LifeSupply    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452223,
    "name": "LifeSync Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 458708,
    "name": "LifeTec Inc    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 455283,
    "name": "LifeWatch AG    ( Neuhausen am Rheinfall  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391866,
    "name": "LifeWatch Inc    ( Rosemont IL USA )",
    "country": "United States"
  },
  {
    "id": 453418,
    "name": "LifeWear Technologies Inc    ( Oakland Park FL USA )",
    "country": "United States"
  },
  {
    "id": 322699,
    "name": "Lift Aid    ( Wixom MI USA )",
    "country": "United States"
  },
  {
    "id": 458593,
    "name": "Lift Free Products Inc    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 461246,
    "name": "Lift Products Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 454278,
    "name": "LiftCare Bed Co    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 394475,
    "name": "Liquid Crystal Resources LLC    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 453586,
    "name": "Liftech Systems Ltd    ( Totton  England )",
    "country": "UK"
  },
  {
    "id": 459448,
    "name": "LiftSeat Corp    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 325072,
    "name": "Liftsmith Ltd    ( Middletown KY USA )",
    "country": "United States"
  },
  {
    "id": 183968,
    "name": "Light Age Inc    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 357242,
    "name": "Light Progress    ( Anghiari (AR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 107742,
    "name": "Light Technology Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 457089,
    "name": "Light-Tech Inc    ( Sebring FL USA )",
    "country": "United States"
  },
  {
    "id": 457811,
    "name": "Lighthouse for the Blind    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 457936,
    "name": "Lighthouse Imaging Corp    ( Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 459458,
    "name": "Lighthouse Worldwide Solutions    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 390933,
    "name": "LightLab Imaging LLC    ( Westford MA USA )",
    "country": "United States"
  },
  {
    "id": 458605,
    "name": "LightMed Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454861,
    "name": "Lightning Bolt Solutions    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 457176,
    "name": "Lightning Enterprises    ( Limington ME USA )",
    "country": "United States"
  },
  {
    "id": 171269,
    "name": "Lightolier    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 458466,
    "name": "Lightools Research    ( Encinitas CA USA )",
    "country": "United States"
  },
  {
    "id": 186454,
    "name": "Lightpath Optical Co Ltd    ( Axminster  England )",
    "country": "UK"
  },
  {
    "id": 459484,
    "name": "LightSquared    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 462899,
    "name": "LIGHTWAVE Technologies    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 462900,
    "name": "Lightwave Technologies LLC    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 462582,
    "name": "Lightyear MultiMedia Studios    ( Leesburg VA USA )",
    "country": "United States"
  },
  {
    "id": 461351,
    "name": "Lightyear Technology Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 151058,
    "name": "Ligtvoet Products bv    ( Helmond  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 281323,
    "name": "Likamed Rudolf Lipp GmbH    ( Eppingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 237799,
    "name": "Liko (UK) Ltd    ( Stonehouse  England )",
    "country": "UK"
  },
  {
    "id": 187152,
    "name": "Liko AB    ( Lulea  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 398931,
    "name": "Liko Care AG    ( Schoenenbuch  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 398928,
    "name": "Liko France    ( Lay-Saint-Christophe  France )",
    "country": "France"
  },
  {
    "id": 398929,
    "name": "Liko GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 358830,
    "name": "Liko Inc    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 456925,
    "name": "LIKVOR AB    ( Umea  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 456442,
    "name": "Lima Corporate    ( Udine (UD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459045,
    "name": "Limbs & Things Inc    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 307162,
    "name": "Limbtech    ( Florida  USA )",
    "country": "United States"
  },
  {
    "id": 158957,
    "name": "Limmer Laser GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 456701,
    "name": "Lin-Zhi International    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 454455,
    "name": "LiNA Medical ApS    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 999888,
    "name": "Linak    (   USA )",
    "country": "United States"
  },
  {
    "id": 452976,
    "name": "Linc Facility Services    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 455279,
    "name": "Linc Health LLC    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 454150,
    "name": "LINC Medical Systems LLC    ( Loddington  England )",
    "country": "UK"
  },
  {
    "id": 457496,
    "name": "Linde Aruba    ( Oranjestad  Aruba )",
    "country": "Aruba"
  },
  {
    "id": 457494,
    "name": "Linde Bangladesh Ltd    ( Dhaka  Bangladesh )",
    "country": "Bangladesh"
  },
  {
    "id": 158958,
    "name": "Linde Gas AG    ( Munich  Germany )",
    "country": "Germany"
  },
  {
    "id": 441524,
    "name": "Linde Gas LLC    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 457495,
    "name": "Linde Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 377775,
    "name": "Linde Medical Sensors AG    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 454249,
    "name": "Linde North America LLC    ( Murray Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 457498,
    "name": "Linde Pakistan Ltd    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 155958,
    "name": "LINE Imaging Systems    ( Oyster Bay NY USA )",
    "country": "United States"
  },
  {
    "id": 106812,
    "name": "Linear Medical Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 106983,
    "name": "Linear Tonometers Inc    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 452988,
    "name": "Linemaster Switch Corp    ( Woodstock CT USA )",
    "country": "United States"
  },
  {
    "id": 455324,
    "name": "Linet Americas    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 451291,
    "name": "Linet France SAS    ( Chambray-les-Tours  France )",
    "country": "France"
  },
  {
    "id": 452854,
    "name": "Linet Italia    ( Brescia  Italy )",
    "country": "Italy"
  },
  {
    "id": 266205,
    "name": "Linet spol s r o    ( Slany  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 452760,
    "name": "Linet Sweden AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452761,
    "name": "Linet UK    ( Portchester  England )",
    "country": "UK"
  },
  {
    "id": 458182,
    "name": "Lingraphica    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 291426,
    "name": "Linhai Medical Products Factory    ( Linhai  USA )",
    "country": "United States"
  },
  {
    "id": 151237,
    "name": "Linido bv    ( Pijnacker  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 474878,
    "name": "Liniform    ( Barberton OH USA )",
    "country": "United States"
  },
  {
    "id": 174893,
    "name": "Link Ergonomics Corp    ( Joplin MO USA )",
    "country": "United States"
  },
  {
    "id": 319588,
    "name": "LINK Medical Computing Inc    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 363804,
    "name": "Link Orthopaedics    ( Rockaway NJ USA )",
    "country": "United States"
  },
  {
    "id": 283016,
    "name": "Linn High Therm GmbH    ( Eschenfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 108040,
    "name": "Linscan UltraSound    ( Rolla MO USA )",
    "country": "United States"
  },
  {
    "id": 105069,
    "name": "Linseis Inc    ( Robbinsville NJ USA )",
    "country": "United States"
  },
  {
    "id": 321872,
    "name": "Linsen Ltd    ( Central Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 364325,
    "name": "LINTECH    ( Rochelle Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 324572,
    "name": "Linton Instrumentation    ( Palgrave Diss  England )",
    "country": "UK"
  },
  {
    "id": 108670,
    "name": "Linvatec Corp Div Bristol-Myer    (   USA )",
    "country": "United States"
  },
  {
    "id": 454040,
    "name": "Linvatec Europe    ( Drogenbos  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454039,
    "name": "Linvatec Korea Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453382,
    "name": "Lion Eye Bank of Wisconsin    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 458583,
    "name": "Lions Eye Bank for Long Island    ( Valley Stream NY USA )",
    "country": "United States"
  },
  {
    "id": 455068,
    "name": "Lions Eye Bank of Oregon    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 457405,
    "name": "Lions Eye Bank of Texas    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 456915,
    "name": "Lions Eye Bank of West Central Ohio    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 456957,
    "name": "Lions Eye Institute for Transplant and Research In    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 458385,
    "name": "Lions Medical Eye Bank of Eastern Virginia    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 458753,
    "name": "Lions VisionGift    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 461352,
    "name": "LionShare Marketing Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 102621,
    "name": "Lionville Systems Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 298162,
    "name": "LIP (Equipment & Services) Ltd    ( Shipley  England )",
    "country": "UK"
  },
  {
    "id": 456997,
    "name": "Lipose Corp    ( Maitland FL USA )",
    "country": "United States"
  },
  {
    "id": 460740,
    "name": "Lippincott Williams & Wilkins    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 461354,
    "name": "Liquidware Labs Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 305704,
    "name": "Lir Australia Pty Ltd    ( North Parramatta  Australia )",
    "country": "Australia"
  },
  {
    "id": 177054,
    "name": "LISA Laser Products OHG    ( Katlenburg-Lindau  Germany )",
    "country": "Germany"
  },
  {
    "id": 455229,
    "name": "LISA Laser SEA    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 455226,
    "name": "LISA Laser USA    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 139463,
    "name": "Lista AG    ( Erlen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105958,
    "name": "Lista International Corp    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 392886,
    "name": "LISTEM Corp    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 148815,
    "name": "Liston Scientific Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 454878,
    "name": "LIT Surgical    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 434005,
    "name": "Lite-On (USA) International Inc    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 434004,
    "name": "Lite-On IT Corp (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 175878,
    "name": "Litechnica Ltd    ( Heston  England )",
    "country": "UK"
  },
  {
    "id": 461356,
    "name": "LiteCure LLC    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 104235,
    "name": "Litho/Color Inc    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 452514,
    "name": "Lithonia Lighting    ( Conyers GA USA )",
    "country": "United States"
  },
  {
    "id": 456710,
    "name": "litos GmbH    ( Ahrensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 150124,
    "name": "Little Rapids Corp    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 457590,
    "name": "LiveData Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 461203,
    "name": "LIVENGOOD    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 459260,
    "name": "Livescribe Inc    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 450551,
    "name": "Living Data Technology Corp    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 458901,
    "name": "Living Systems Instrumentation    ( St Albans VT USA )",
    "country": "United States"
  },
  {
    "id": 999635,
    "name": "Livingston Products Inc.    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 356000,
    "name": "Livingston Technologies    ( Cedar Knolls NJ USA )",
    "country": "United States"
  },
  {
    "id": 305586,
    "name": "Livingstone International Pty Ltd    ( Rosebery  Australia )",
    "country": "Australia"
  },
  {
    "id": 103293,
    "name": "Lixi Inc    ( Huntley IL USA )",
    "country": "United States"
  },
  {
    "id": 462723,
    "name": "LKB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 102554,
    "name": "LKB Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 101677,
    "name": "LKC Technologies Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 416250,
    "name": "LL Medico    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 293076,
    "name": "Lloyd Instruments Ltd    ( Fareham  England )",
    "country": "UK"
  },
  {
    "id": 108454,
    "name": "Lloyd Table Co    ( Lisbon IA USA )",
    "country": "United States"
  },
  {
    "id": 375832,
    "name": "LM Air Technology Inc    ( Rahway NJ USA )",
    "country": "United States"
  },
  {
    "id": 455093,
    "name": "LM Instruments Oy    ( Parainen  Finland )",
    "country": "Finland"
  },
  {
    "id": 362954,
    "name": "LMA North America Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451286,
    "name": "LMA PacMed Pty Ltd    ( Richmond  Australia )",
    "country": "Australia"
  },
  {
    "id": 293077,
    "name": "LMMP    ( Whitehaven  USA )",
    "country": "United States"
  },
  {
    "id": 170466,
    "name": "LMS Medical Inc    ( Arlington MA USA )",
    "country": "United States"
  },
  {
    "id": 409051,
    "name": "LMS Medical Systems (USA) Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 370475,
    "name": "LMS Medical Systems Ltd    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 103806,
    "name": "LND Inc    ( Oceanside NY USA )",
    "country": "United States"
  },
  {
    "id": 291931,
    "name": "Lobana Laboratories    ( Park Rapids MN USA )",
    "country": "United States"
  },
  {
    "id": 174894,
    "name": "Lobob Laboratories Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 453988,
    "name": "LOC Scientific Inc    ( Buford GA USA )",
    "country": "United States"
  },
  {
    "id": 461169,
    "name": "Loc8tor Ltd    ( Borehamwood  England )",
    "country": "UK"
  },
  {
    "id": 456986,
    "name": "Locamed Ltd    ( Farnham  England )",
    "country": "UK"
  },
  {
    "id": 459039,
    "name": "Lochinvar LLC    ( Lebanon TN USA )",
    "country": "United States"
  },
  {
    "id": 456539,
    "name": "Locker Brand    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 287082,
    "name": "Lockweiler Plastic Werke GmbH    ( Wadern-Lockweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 461359,
    "name": "Lockwood Mfg Co    ( Livonia MS USA )",
    "country": "United States"
  },
  {
    "id": 151060,
    "name": "Lode bv    ( Groningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 238438,
    "name": "Lode Medical Technology Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 452408,
    "name": "LodgeNet Entertainment Corp    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 401621,
    "name": "Lodox Systems (Pty) Ltd    ( Sandton  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 416409,
    "name": "Lodox Systems North America LLC    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 282453,
    "name": "Loening Hospitex GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 284745,
    "name": "Loewinger GmbH    ( Muennerstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 280516,
    "name": "Loftex-Firchow GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461162,
    "name": "Loftware Inc    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 461166,
    "name": "Logi-D    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453529,
    "name": "Logibec Group Informatique Ltd    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 457564,
    "name": "Logic Product Group    ( New York City NY USA )",
    "country": "United States"
  },
  {
    "id": 452391,
    "name": "Logical Images Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 466312,
    "name": "Logicalis    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 288589,
    "name": "LOGICARE Corp    ( Eau Claire WI USA )",
    "country": "United States"
  },
  {
    "id": 305748,
    "name": "Logikal Health Products Pty Ltd    ( Morisset  Australia )",
    "country": "Australia"
  },
  {
    "id": 453410,
    "name": "Logiquip LLC    ( Galesburg MI USA )",
    "country": "United States"
  },
  {
    "id": 459258,
    "name": "Logitech    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 224630,
    "name": "Logos Research Ltd    ( Aharnes  USA )",
    "country": "United States"
  },
  {
    "id": 466232,
    "name": "Logos Systems Int'l    ( Scotts Valley 17 USA )",
    "country": "United States"
  },
  {
    "id": 455121,
    "name": "LogTag Recorders    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 455645,
    "name": "Lohmann & Rauscher GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 441169,
    "name": "Lohmann & Rauscher Inc    ( Topeka KS USA )",
    "country": "United States"
  },
  {
    "id": 139345,
    "name": "Lohmann & Rauscher International GmbH & Co KG    ( Neuwied  Germany )",
    "country": "Germany"
  },
  {
    "id": 364502,
    "name": "LOIS Ltd    ( McLean VA USA )",
    "country": "United States"
  },
  {
    "id": 456458,
    "name": "Lojer Oy Vammala    ( Sastamala  Finland )",
    "country": "Finland"
  },
  {
    "id": 107382,
    "name": "Loktite Inc    ( Timonium MD USA )",
    "country": "United States"
  },
  {
    "id": 457833,
    "name": "Loma Vista Medical    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 384325,
    "name": "Lomax Mobility Ltd    ( Dundee  USA )",
    "country": "United States"
  },
  {
    "id": 455149,
    "name": "Lombard Medical Technologies Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 451837,
    "name": "Lombard Medical Technologies plc    ( Didcot  England )",
    "country": "UK"
  },
  {
    "id": 266833,
    "name": "Lombart Instrument    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 371522,
    "name": "LOMO America Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 440838,
    "name": "LOMO PLC    ( St Petersburg  Russia )",
    "country": "Russia"
  },
  {
    "id": 272837,
    "name": "London Scientific Ltd    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453073,
    "name": "Lone Oak Medical Technologies    ( Doylestown PA USA )",
    "country": "United States"
  },
  {
    "id": 454478,
    "name": "Lone Oak Medical Technologies    ( Doylestown PA USA )",
    "country": "United States"
  },
  {
    "id": 105800,
    "name": "Lone Star Medical Products Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 461375,
    "name": "Lone Star Shredding & Document Storage    ( Mercedes TX USA )",
    "country": "United States"
  },
  {
    "id": 107383,
    "name": "Long Island Scientific Inc    ( East Setauket NY USA )",
    "country": "United States"
  },
  {
    "id": 461376,
    "name": "Long Range Systems LLC (LRS)    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 999778,
    "name": "Long Range Systems, Inc.    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 454181,
    "name": "Longevity LLC    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 455954,
    "name": "Longfian Scitech Co Ltd    ( Baoding  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 382460,
    "name": "Longo Inc    ( Ramsey NJ USA )",
    "country": "United States"
  },
  {
    "id": 450265,
    "name": "Longport Inc    ( Glens Mills PA USA )",
    "country": "United States"
  },
  {
    "id": 456011,
    "name": "Lonjyfan Enterprise Corp    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452189,
    "name": "Lonza Group Ltd    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452188,
    "name": "Lonza Rockland Inc    ( Rockland ME USA )",
    "country": "United States"
  },
  {
    "id": 151238,
    "name": "Lopital Nederland bv    ( Oisterwijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 439925,
    "name": "Lopital Nederland bv    ( Oisterwijk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 151449,
    "name": "Lorad Breast Imaging    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 431600,
    "name": "Lorentz Design    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 460921,
    "name": "Loricca Inc    ( Brandon FL USA )",
    "country": "United States"
  },
  {
    "id": 357659,
    "name": "Lorne Laboratories Ltd    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 283962,
    "name": "Loser & Co GmbH    ( Leverkusen  USA )",
    "country": "United States"
  },
  {
    "id": 451147,
    "name": "Lost Mountain Tissue Bank    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 283884,
    "name": "Lothar Doberschuetz Medizintechnik    ( Leipzig  USA )",
    "country": "United States"
  },
  {
    "id": 293903,
    "name": "Lothar Lafloer GmbH & Co    ( Velbert  Germany )",
    "country": "Germany"
  },
  {
    "id": 283506,
    "name": "Lothar Scharras Fabrikation Chirurgischer    ( Kolbingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160959,
    "name": "Lothar Schmidtke    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 102980,
    "name": "Lotus Health Care Products    ( Naugatuck CT USA )",
    "country": "United States"
  },
  {
    "id": 103772,
    "name": "Louis M Gerson Co Inc    ( Middleboro MA USA )",
    "country": "United States"
  },
  {
    "id": 283461,
    "name": "Louis Steitz Secura GmbH & Co KG Schuhfabriken    ( Kirchheimbolanden  Germany )",
    "country": "Germany"
  },
  {
    "id": 452396,
    "name": "Louisville Apl Diagnostics Inc    ( Seabrook TX USA )",
    "country": "United States"
  },
  {
    "id": 464398,
    "name": "Louroe Electronics    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 999628,
    "name": "Louroe Electronics    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 426234,
    "name": "Love Controls    ( Michigan City IN USA )",
    "country": "United States"
  },
  {
    "id": 307303,
    "name": "Lozon (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 461383,
    "name": "LPA Medical Inc    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 999417,
    "name": "LPA MEDICAL inc.    ( Quebec  USA )",
    "country": "United States"
  },
  {
    "id": 459212,
    "name": "LPIT Solutions    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 462577,
    "name": "LPKF Laser & Electronics North America    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 182857,
    "name": "LRC Hospital Products Malaysia Sdn Bhd    ( Kulim  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 458151,
    "name": "LSA Design    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 456631,
    "name": "LSI International    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 439657,
    "name": "LSI Solutions    ( Victor NY USA )",
    "country": "United States"
  },
  {
    "id": 170906,
    "name": "LSL Industries Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 454580,
    "name": "LSS Data Systems    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 461513,
    "name": "LSS Life Safety Services LLC    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 458077,
    "name": "LTB Lasertechnik Berlin GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 227235,
    "name": "LTE Scientific Ltd    ( Oldham  England )",
    "country": "UK"
  },
  {
    "id": 455199,
    "name": "LTF Labortechnik GmbH & Co KG    ( Wasserburg am Bodensee  Germany )",
    "country": "Germany"
  },
  {
    "id": 286464,
    "name": "LTG Lufttechnische GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 451989,
    "name": "LTL Medical LLC    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 456012,
    "name": "LU MARK ENTERPRISES INC    ( Taoyuan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 409564,
    "name": "Luba Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 176307,
    "name": "Lubidet USA Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 108471,
    "name": "Lucas Grayson Stadler Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 333494,
    "name": "Lucas Medical Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 335213,
    "name": "Lucent Technologies    ( Murray Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 456768,
    "name": "Lucero Medical LLC    ( Richfield OH USA )",
    "country": "United States"
  },
  {
    "id": 457599,
    "name": "Lucina Care    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 158960,
    "name": "Lucius & Baer Vertriebs GmbH    ( Geretsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 454073,
    "name": "Lucomed Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 454074,
    "name": "Lucomed SpA    ( Carpi (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455955,
    "name": "Luda Plastic Product Co Ltd    ( Dongguan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453117,
    "name": "Ludl Electronic Products Ltd    ( Hawthorne NY USA )",
    "country": "United States"
  },
  {
    "id": 103493,
    "name": "Ludlow Composites Corp    ( Fremont OH USA )",
    "country": "United States"
  },
  {
    "id": 103815,
    "name": "Ludlum Measurements Inc    ( Sweetwater TX USA )",
    "country": "United States"
  },
  {
    "id": 153043,
    "name": "Ludwig Bau Sondermaschinebau    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282051,
    "name": "Ludwig Becker Elektronische Informationssysteme    ( Gleiszellen  Germany )",
    "country": "Germany"
  },
  {
    "id": 168488,
    "name": "Ludwig Elkuch AG    ( Bendern  Liechtenstein )",
    "country": "Liechtenstein"
  },
  {
    "id": 279360,
    "name": "Ludwig Mohren KG    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287162,
    "name": "Ludwig Poppe GmbH & Co    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 280330,
    "name": "Lueck GmbH    ( Bocholt  Germany )",
    "country": "Germany"
  },
  {
    "id": 466333,
    "name": "Luigs & Neumann Feinmechanik & Elektrotechnik GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455457,
    "name": "Luitpold Pharmaceuticals Inc    ( Shirley NY USA )",
    "country": "United States"
  },
  {
    "id": 287560,
    "name": "Luma GmbH    ( Dueren  Germany )",
    "country": "Germany"
  },
  {
    "id": 140802,
    "name": "Lumac bv    ( Landgraaf  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 271716,
    "name": "Lumalier/Commercial Lighting Design Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 267697,
    "name": "Lumatec GmbH    ( Deisenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 376140,
    "name": "Lumedx Corp    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 455424,
    "name": "Lumen Biomedical    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 999419,
    "name": "Lumen Dynamics Group Inc.    ( Mississauga  USA )",
    "country": "United States"
  },
  {
    "id": 461170,
    "name": "Lumencor Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 401947,
    "name": "LuMend Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 401399,
    "name": "Lumenis (France) Sarl    ( Orsay  France )",
    "country": "France"
  },
  {
    "id": 401400,
    "name": "Lumenis (Germany) GmbH    ( Dreieich-Dreieichenhain  Germany )",
    "country": "Germany"
  },
  {
    "id": 401404,
    "name": "Lumenis (Holland) bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 401401,
    "name": "Lumenis (Hong Kong) Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401402,
    "name": "Lumenis (Italy) srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 101450,
    "name": "Magnatone Hearing Aid Corp    ( Casselberry FL USA )",
    "country": "United States"
  },
  {
    "id": 401405,
    "name": "Lumenis (Sweden) AB    ( Kristianstad  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401490,
    "name": "Lumenis (UK) Ltd    ( Elstree  England )",
    "country": "UK"
  },
  {
    "id": 417245,
    "name": "Lumenis BV (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392912,
    "name": "Lumenis Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 401403,
    "name": "Lumenis Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 399183,
    "name": "Lumenis Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 417246,
    "name": "Lumenis Schaffhausen    ( Schaffhausen  USA )",
    "country": "United States"
  },
  {
    "id": 397738,
    "name": "Lumeon / McKesson Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 999632,
    "name": "Luminaire    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 457929,
    "name": "Luminance Integrated Technologies    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 107386,
    "name": "Luminaud Inc    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 452090,
    "name": "Luminetx Corp    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 452719,
    "name": "Luminex Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 451781,
    "name": "Lumiport LLC    ( Pleasant Grove UT USA )",
    "country": "United States"
  },
  {
    "id": 185009,
    "name": "Lumitex Inc    ( Strongsville OH USA )",
    "country": "United States"
  },
  {
    "id": 460790,
    "name": "Luna Innovations Inc    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 455216,
    "name": "Luna Spas Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 104623,
    "name": "Lunaire Ltd    ( White Deer PA USA )",
    "country": "United States"
  },
  {
    "id": 451015,
    "name": "Lunapads International    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 183462,
    "name": "Lunar Asia/Pacific    ( West Pennant Hills  USA )",
    "country": "United States"
  },
  {
    "id": 156153,
    "name": "Lunar Europe NV    ( Bruxelles  USA )",
    "country": "United States"
  },
  {
    "id": 238458,
    "name": "Lunar GmbH (Deutschland)    ( Koeln  USA )",
    "country": "United States"
  },
  {
    "id": 291933,
    "name": "Lunax Corp    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 415964,
    "name": "Luneau SA    ( Chartres Cedex  France )",
    "country": "France"
  },
  {
    "id": 280624,
    "name": "Lurgi Bamag GmbH    ( Butzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 325078,
    "name": "LuSal Enterprises Inc    ( Aledo TX USA )",
    "country": "United States"
  },
  {
    "id": 456036,
    "name": "Lutronic Corp    ( Goyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 287296,
    "name": "Lutz GmbH & Co KG    ( Wertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 183443,
    "name": "Luwa AG    ( Uster  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418122,
    "name": "Luwa USA Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 284518,
    "name": "Lux GmbH Murnau    ( Murnau  Germany )",
    "country": "Germany"
  },
  {
    "id": 107870,
    "name": "Luxar    (   USA )",
    "country": "United States"
  },
  {
    "id": 309729,
    "name": "Luxilon Industries Co NV    ( Antwerpen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 162652,
    "name": "Luxo ASA    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 101433,
    "name": "Luxo Corp    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 104237,
    "name": "Luxo Lamp Ltd    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 103295,
    "name": "Luxor    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 105072,
    "name": "Luxtec    ( West Boylston MA USA )",
    "country": "United States"
  },
  {
    "id": 187059,
    "name": "Luxtec European    ( Merstham Redhill  England )",
    "country": "UK"
  },
  {
    "id": 452975,
    "name": "Luxtel LLC    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 999327,
    "name": "Luxvision    (   USA )",
    "country": "United States"
  },
  {
    "id": 451816,
    "name": "LVM Systems Inc    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 999328,
    "name": "LW Measurements, LLC    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 392873,
    "name": "LW Scientific Inc    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 450978,
    "name": "LXU Healthcare    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 456990,
    "name": "LycoDent GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 452736,
    "name": "Lympha Press USA    ( Manalapan NJ USA )",
    "country": "United States"
  },
  {
    "id": 106173,
    "name": "Lynn Medical Co    ( Wixom MI USA )",
    "country": "United States"
  },
  {
    "id": 227237,
    "name": "Lynton Lasers Ltd    ( Cheadle  England )",
    "country": "UK"
  },
  {
    "id": 459431,
    "name": "LYNX Product Group    ( Wilson NY USA )",
    "country": "United States"
  },
  {
    "id": 460565,
    "name": "Lyon Software    ( Sylvania OH USA )",
    "country": "United States"
  },
  {
    "id": 266925,
    "name": "Lyon Workspace Products    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 101431,
    "name": "Lyons Medical    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 280101,
    "name": "Lysoform Dr Hans Rosemann GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 332733,
    "name": "LySonix Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 284958,
    "name": "M & C Schiffer GmbH    ( Neustadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 341117,
    "name": "M & I Medical Sales Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 282850,
    "name": "M & M Elektronik Kohn GmbH    ( Heimsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457529,
    "name": "M & S Technologies Inc    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 467385,
    "name": "M A Corp Japan    ( Noda City  Japan )",
    "country": "Japan"
  },
  {
    "id": 281224,
    "name": "M A Limmer Mali-Kurzzeituhren    ( Elsenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 281927,
    "name": "M Braun GmbH    ( Garching  USA )",
    "country": "United States"
  },
  {
    "id": 415434,
    "name": "M Braun Inc    ( Stratham NH USA )",
    "country": "United States"
  },
  {
    "id": 452141,
    "name": "M Braun Inertgas Systems (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455039,
    "name": "M Braun UK Ltd    ( Mansfield  England )",
    "country": "UK"
  },
  {
    "id": 106692,
    "name": "M C Johnson Co Inc    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 286786,
    "name": "M Cannova Chirurg Instrumente    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107397,
    "name": "M D McCauley Co Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 458616,
    "name": "M Dialysis AB    ( Johanneshov  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 458617,
    "name": "M Dialysis Inc    ( North Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 999719,
    "name": "M Dialysis Inc.    ( North Chelmsford MA USA )",
    "country": "USA"
  },
  {
    "id": 286026,
    "name": "M G Kurth GmbH    ( Schwarzenbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 461171,
    "name": "M Hanson & Co Inc    ( Fort Launderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 280105,
    "name": "M Pech Vitalzentren GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 453931,
    "name": "M W Mooney & Co Inc    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 346535,
    "name": "M-E Hearing Systems Pty Ltd    ( Parkside  USA )",
    "country": "United States"
  },
  {
    "id": 108227,
    "name": "M-PACT Worldwide LLC    ( Eudora KS USA )",
    "country": "United States"
  },
  {
    "id": 278095,
    "name": "M-S Surgical Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453874,
    "name": "m-u-t America Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 456744,
    "name": "M*Modal IP LLC    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 999569,
    "name": "M&S Technologies, Inc.    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 336760,
    "name": "M2 Information Systems    ( Edmonds WA USA )",
    "country": "United States"
  },
  {
    "id": 465222,
    "name": "M2S, Inc.    ( West Lebanon 12 USA )",
    "country": "United States"
  },
  {
    "id": 456065,
    "name": "MA Arain & Brothers (Ptv) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 457369,
    "name": "MA Suchatzki    ( Rennerod  Germany )",
    "country": "Germany"
  },
  {
    "id": 453908,
    "name": "mab Solutions    ( Dettenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454251,
    "name": "Mabis DMI Healthcare    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 176309,
    "name": "Mabis Healthcare Inc    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 331403,
    "name": "MAC Medical Inc    ( Millstadt IL USA )",
    "country": "United States"
  },
  {
    "id": 108512,
    "name": "MAC's Lift Gate Inc    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 335882,
    "name": "Macalaster Bicknell Co of NJ Inc    ( Millville NJ USA )",
    "country": "United States"
  },
  {
    "id": 362026,
    "name": "Macan Engineering and Mfg Co    ( Milton DE USA )",
    "country": "United States"
  },
  {
    "id": 999670,
    "name": "Magnavox    (   USA )",
    "country": "United States"
  },
  {
    "id": 108128,
    "name": "MacBick Sales and Service    ( Burlington NJ USA )",
    "country": "United States"
  },
  {
    "id": 108614,
    "name": "MacDee Inc    ( Chelsea MI USA )",
    "country": "United States"
  },
  {
    "id": 458606,
    "name": "Mach7 Technologies    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 187126,
    "name": "Macherey-Nagel GmbH & Co KG    ( Dueren  Germany )",
    "country": "Germany"
  },
  {
    "id": 157962,
    "name": "Machida Endoscope Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 458758,
    "name": "Mack Information Systems Inc    ( Wyncote PA USA )",
    "country": "United States"
  },
  {
    "id": 401931,
    "name": "Mack Medizintechnik GmbH    ( Pfaffenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 402130,
    "name": "MACK Technologies    ( Westford MA USA )",
    "country": "United States"
  },
  {
    "id": 182655,
    "name": "Macken Instruments Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 999972,
    "name": "MACKIE    (   USA )",
    "country": "United States"
  },
  {
    "id": 466304,
    "name": "Mackin Medical    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 459171,
    "name": "MacKinney Systems Inc    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 457545,
    "name": "Mackworth Products    ( Bridgend  Wales )",
    "country": "Wales"
  },
  {
    "id": 454830,
    "name": "MacMed Healthcare    ( Mudgeeraba  Australia )",
    "country": "Australia"
  },
  {
    "id": 451195,
    "name": "MacoPharm SAS    ( Tourcoing Cedex  France )",
    "country": "France"
  },
  {
    "id": 467065,
    "name": "Macopharma USA    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 305606,
    "name": "MacQuarie Medical Systems Pty Ltd    ( Leichhardt  Australia )",
    "country": "Australia"
  },
  {
    "id": 459080,
    "name": "Macro Helix Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 457853,
    "name": "Macron Dynamics Inc    ( Croydon PA USA )",
    "country": "United States"
  },
  {
    "id": 174404,
    "name": "Macrotec Industria Comercio de Equipamentos Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457044,
    "name": "Macrovision Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 466651,
    "name": "MacuLogix Inc    ( Hummelstown PA USA )",
    "country": "United States"
  },
  {
    "id": 466233,
    "name": "Mad City Labs Inc    ( Madison 25 USA )",
    "country": "United States"
  },
  {
    "id": 333759,
    "name": "Mad Scientist Software    ( Lehi UT USA )",
    "country": "United States"
  },
  {
    "id": 101448,
    "name": "Mada Medical Products Inc    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 101875,
    "name": "Maddak Inc    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 287773,
    "name": "Madegom Ltda    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 455048,
    "name": "Madrimed Medizintechnik    ( Buermoos  Austria )",
    "country": "Austria"
  },
  {
    "id": 139621,
    "name": "Madsen Electronics (Denmark)    ( Taastrup  USA )",
    "country": "United States"
  },
  {
    "id": 174109,
    "name": "Maeda & Co Ltd    ( Kanazawa  Japan )",
    "country": "Japan"
  },
  {
    "id": 284656,
    "name": "Maedycos    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 140591,
    "name": "Maerzhaeuser Wetzlar GmbH & Co KG    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 999700,
    "name": "Mag Innovision    (   USA )",
    "country": "United States"
  },
  {
    "id": 467048,
    "name": "Magaw Medical    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 454269,
    "name": "Magellan Biosciences Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 399272,
    "name": "Magic Mobility    ( Rowville  Australia )",
    "country": "Australia"
  },
  {
    "id": 325081,
    "name": "Magister Corp    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 401031,
    "name": "Magmedix    ( Fitchburg MA USA )",
    "country": "United States"
  },
  {
    "id": 177038,
    "name": "Magnacare Pty Ltd    ( Medindie Gardens  Australia )",
    "country": "Australia"
  },
  {
    "id": 186060,
    "name": "Magnacoustics Inc    ( Atlantic Beach NY USA )",
    "country": "United States"
  },
  {
    "id": 456939,
    "name": "Magnamed Tecnologia Medica S/A    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 454182,
    "name": "MagnaServ Inc    ( Stuart FL USA )",
    "country": "United States"
  },
  {
    "id": 461385,
    "name": "Magnatag Visible Systems    ( Macedon NY USA )",
    "country": "United States"
  },
  {
    "id": 452627,
    "name": "Magnatek Enterprises    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 298389,
    "name": "Magne-Tec Enterprises Inc    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 146107,
    "name": "Magnet Resources Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 452708,
    "name": "Magnetic Elektromotoren AG    ( Liestal  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 398626,
    "name": "Magnetic Products International    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 455632,
    "name": "Magnetic Resonance Technologies Inc    ( Willoughby OH USA )",
    "country": "United States"
  },
  {
    "id": 170485,
    "name": "Magnetic Shield Corp    ( Bensenville IL USA )",
    "country": "United States"
  },
  {
    "id": 458620,
    "name": "Magnettech Analysenmesstechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 440768,
    "name": "MagneVu Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 466585,
    "name": "Magnifiers & More LLC    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 457623,
    "name": "MagniSight Inc    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 439966,
    "name": "Magnivision Inc    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 458875,
    "name": "Magnum Fitness Systems    ( South Milwaukee WI USA )",
    "country": "USA"
  },
  {
    "id": 999589,
    "name": "Magnum Fitness Systems    ( South Milwaukee WI USA )",
    "country": "USA"
  },
  {
    "id": 187407,
    "name": "Magnum Medical Inc    ( Chandler AZ USA )",
    "country": "United States"
  },
  {
    "id": 288520,
    "name": "Magstim Co US LLC    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 183266,
    "name": "Maguire Enterprises    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 999849,
    "name": "MagVenture    (   Denmark )",
    "country": "Denmark"
  },
  {
    "id": 459088,
    "name": "MagVenture A/S    ( Farum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 343007,
    "name": "Magview Systems    ( Burtonsville MD USA )",
    "country": "United States"
  },
  {
    "id": 286788,
    "name": "MAHE Barthelmes Medizintechnik GmbH    ( Emmingen  USA )",
    "country": "United States"
  },
  {
    "id": 298390,
    "name": "MAHE International Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 452733,
    "name": "mahe medical gmbh    ( Emmingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 340682,
    "name": "MAHE Medical Technology Co Ltd    ( Pathumthani  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 236957,
    "name": "Maico Diagnostic GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 348943,
    "name": "Maico Diagnostics    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 155421,
    "name": "Mailhawk Mfg Co    ( Warm Springs GA USA )",
    "country": "United States"
  },
  {
    "id": 170063,
    "name": "Maillerfer Instruments SA    ( Ballaigues  USA )",
    "country": "United States"
  },
  {
    "id": 291976,
    "name": "Main Line International Inc    ( Bogart GA USA )",
    "country": "United States"
  },
  {
    "id": 465065,
    "name": "Mainline Medical, Inc.    ( Norcross 32 USA )",
    "country": "United States"
  },
  {
    "id": 453652,
    "name": "Mainline Technologies    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 466853,
    "name": "Mainspring Healthcare Solutions    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 459172,
    "name": "Maintenance Connection Inc    ( Davis CA USA )",
    "country": "United States"
  },
  {
    "id": 461452,
    "name": "Maintenance Value Plan    ( Warrenville IL USA )",
    "country": "United States"
  },
  {
    "id": 455789,
    "name": "Major Biosystem Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 101451,
    "name": "Major Lab Mfg Co    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 382567,
    "name": "Major Medical Inc    ( Delson PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 461389,
    "name": "MAK-SYSTEM International Group    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 454009,
    "name": "MAKO Surgical Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 999339,
    "name": "Mako Technologies    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 298443,
    "name": "Makol    ( Barreal de Heredia  Costa Rica )",
    "country": "Costa Rica"
  },
  {
    "id": 392111,
    "name": "Makosh GmbH    ( Bonn  USA )",
    "country": "United States"
  },
  {
    "id": 451976,
    "name": "Makro Medical (Pty) Ltd    ( Kensington  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 293661,
    "name": "Mecosana AG    ( Unterengstringen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 382651,
    "name": "Malaysian Hoya Lens Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 325082,
    "name": "Malaytex USA    ( American Canyon CA USA )",
    "country": "United States"
  },
  {
    "id": 151006,
    "name": "Malem Medical Ltd    ( Lowdham  England )",
    "country": "UK"
  },
  {
    "id": 331410,
    "name": "Malin Endoscopy    ( Nolensville TN USA )",
    "country": "United States"
  },
  {
    "id": 382730,
    "name": "Mallard Medical Inc    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 282185,
    "name": "Mallinckrodt Baker Deutschland    ( Griesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 382756,
    "name": "Mallinckrodt Benelux bv    ( Zaltbommel  USA )",
    "country": "United States"
  },
  {
    "id": 397815,
    "name": "Mallinckrodt France Sarl    ( Courtaboeuf  USA )",
    "country": "United States"
  },
  {
    "id": 458035,
    "name": "Mallinckrodt Hong Kong Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 225956,
    "name": "Mallinckrodt Inc    ( Hazelwood MO USA )",
    "country": "United States"
  },
  {
    "id": 177391,
    "name": "Mallinckrodt Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 458037,
    "name": "Mallinckrodt Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 307304,
    "name": "Mallinckrodt Medical Asia Pacific Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 177389,
    "name": "Mallinckrodt Medical GmbH    ( Hennef/Sieg  USA )",
    "country": "United States"
  },
  {
    "id": 377603,
    "name": "Mallinckrodt Medical Sa de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 458023,
    "name": "Mallinckrodt Pharmaceuticals    ( Hazelwood MO USA )",
    "country": "United States"
  },
  {
    "id": 177047,
    "name": "Malmet (Australia) Pty Ltd    ( Leeton  Australia )",
    "country": "Australia"
  },
  {
    "id": 458194,
    "name": "Malosa Medical    ( Elland  England )",
    "country": "UK"
  },
  {
    "id": 457837,
    "name": "Malozza Distribution Ltd    ( Whitstable  England )",
    "country": "UK"
  },
  {
    "id": 352839,
    "name": "Malta Chemetron srl    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 286211,
    "name": "Malteser-Stahlwarenfabrik Willi Bals GmbH & Co KG    ( Solingen  USA )",
    "country": "United States"
  },
  {
    "id": 454228,
    "name": "Malvern Instruments China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 282949,
    "name": "Malvern Instruments GmbH    ( Herrenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454226,
    "name": "Malvern Instruments Inc    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 454225,
    "name": "Malvern Instruments Ltd    ( Malvern  England )",
    "country": "UK"
  },
  {
    "id": 459173,
    "name": "Mama Systems Inc    ( Oconomowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 303199,
    "name": "Mammendorfer Institut fuer Physik und Medizin (MIP    ( Mammendorf/Muenchen  USA )",
    "country": "United States"
  },
  {
    "id": 455288,
    "name": "Mammendorfer Institut fuer Physik und Medizin GmbH    ( Mammendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 146431,
    "name": "Mammography Reporting System Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 456961,
    "name": "Mammotome    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 462549,
    "name": "Managed Healthcare Information Services Inc (MHIS)    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 455636,
    "name": "Managed Medical Imaging LLC    ( Davie FL USA )",
    "country": "United States"
  },
  {
    "id": 155960,
    "name": "Manan Medical Products Inc    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 162982,
    "name": "Manara Technical Glass    ( Galil Elion  Israel )",
    "country": "Israel"
  },
  {
    "id": 466521,
    "name": "Manchac Technologies LLC    ( Alexandria LA USA )",
    "country": "United States"
  },
  {
    "id": 335737,
    "name": "Mandarin Opto-Medic Co Pty Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 174670,
    "name": "Mandel Scientific Co Ltd    ( Guelph ON Canada )",
    "country": "Canada"
  },
  {
    "id": 286792,
    "name": "Manfred Hilzinger Chirurgische Instrumente    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286522,
    "name": "Manfred Rink GmbH    ( Sulzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 232356,
    "name": "Manfred Sauer GmbH    ( Lobbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 453957,
    "name": "Manfred Sauer UK Ltd    ( Northampton  England )",
    "country": "UK"
  },
  {
    "id": 281195,
    "name": "Manfred Scharna GmbH    ( Eislingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 151093,
    "name": "Mangar International    ( Presteigne  Wales )",
    "country": "Wales"
  },
  {
    "id": 288774,
    "name": "MANI Inc    ( Utsunomiya  Japan )",
    "country": "Japan"
  },
  {
    "id": 459126,
    "name": "Manitowoc Foodservice    ( New Port Richey FL USA )",
    "country": "United States"
  },
  {
    "id": 103495,
    "name": "Manitowoc Ice Inc    ( Manitowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 280835,
    "name": "Mannesmann Stahlflaschen GmbH    ( Dinslaken  Germany )",
    "country": "Germany"
  },
  {
    "id": 287485,
    "name": "Manometerwerk Richard Matthes GmbH    ( Wittgensdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 174671,
    "name": "Manrex Ltd    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 454979,
    "name": "Mansfield Cryogenics Ltd    ( Mansfield  England )",
    "country": "UK"
  },
  {
    "id": 174672,
    "name": "Mansfield Medical    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 242523,
    "name": "Manta Medical Systems (Pty) Ltd    ( Bryanston  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 452472,
    "name": "Mantis Surgical Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 184097,
    "name": "Manufacturas Govel SAde CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 184096,
    "name": "Manufacturera de Aparatos Cientificos SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 170922,
    "name": "Manufacturing and Research Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 465042,
    "name": "Manuh Solutions    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 242663,
    "name": "MAP Medizintechnik fuer Arzt und Patient GmbH & Co    ( Martinsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 287637,
    "name": "Mapa GmbH    ( Zeven  Germany )",
    "country": "Germany"
  },
  {
    "id": 431548,
    "name": "Maple Craft USA    ( Albion PA USA )",
    "country": "United States"
  },
  {
    "id": 458219,
    "name": "Maple Leaf Wheelchair Mfg Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 462553,
    "name": "Maplewood Software    ( Spokane WA USA )",
    "country": "United States"
  },
  {
    "id": 293378,
    "name": "Mapo AG    ( Glattbrugg/Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 439188,
    "name": "Maquet (Shanghai) International Trading Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456818,
    "name": "MAQUET (Suzhou) Co Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439204,
    "name": "Maquet Belgium NV    ( Ternat  USA )",
    "country": "United States"
  },
  {
    "id": 444451,
    "name": "Maquet Cardiopulmonary (Demark)    ( Lyngby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 444452,
    "name": "Maquet Cardiopulmonary (Italy)    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 444443,
    "name": "Maquet Cardiopulmonary AG    ( Hirrlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 444449,
    "name": "Maquet Cardiopulmonary AG (Norway)    ( Bergen  USA )",
    "country": "United States"
  },
  {
    "id": 453766,
    "name": "MAQUET Cardiovascular LLC    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 439169,
    "name": "Maquet Critical Care AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 305482,
    "name": "Maquet GmbH & Co KG    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454539,
    "name": "MAQUET GmbH & Co KG    ( Rastatt  Germany )",
    "country": "Germany"
  },
  {
    "id": 460522,
    "name": "MAQUET Hong Kong Ltd    ( Kwai Chung  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 336117,
    "name": "Maquet Inc    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 444448,
    "name": "Maquet Ltd    ( Sunderland  England )",
    "country": "UK"
  },
  {
    "id": 456817,
    "name": "MAQUET Medical Systems USA    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 444453,
    "name": "Maquet Medizentechnik Vertrieb und Service GmbH    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 453560,
    "name": "MAQUET Netherlands bv    ( Hilversum  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 444445,
    "name": "Maquet Nordic AB    ( Solna  USA )",
    "country": "United States"
  },
  {
    "id": 453561,
    "name": "MAQUET SA    ( Orleans Cedex 2  France )",
    "country": "France"
  },
  {
    "id": 444444,
    "name": "Maquet-Dynamed Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 439184,
    "name": "Maquet-Getinge KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 444447,
    "name": "Maquet-Jostra Spain    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 199732,
    "name": "Mar Cor Purification    ( Skippack PA USA )",
    "country": "United States"
  },
  {
    "id": 452993,
    "name": "Mar-Med Co    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 455641,
    "name": "Mar-Med Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 452828,
    "name": "Marabu EDV-Beratung und Service    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 325083,
    "name": "Maramed Orthopedic Systems    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 999579,
    "name": "Marantz America, LLC.    ( Mahwah NJ USA )",
    "country": "USA"
  },
  {
    "id": 104267,
    "name": "Marathon Equipment Co    ( Vernon AL USA )",
    "country": "United States"
  },
  {
    "id": 441261,
    "name": "MarathonNorco Aerospace Inc    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 284314,
    "name": "Marburger Stempel-Erzeugung Ing (Grad) Kroul & Hlo    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 106314,
    "name": "Marburger Surgical Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 174403,
    "name": "Marc Mil Industria e Comercio de Art Hospitalares    ( Osasco-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 999467,
    "name": "Marc Pro    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 108542,
    "name": "MarCal Medical Inc    ( Millersville MD USA )",
    "country": "United States"
  },
  {
    "id": 107898,
    "name": "MarCap Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 185426,
    "name": "Marcel Sp z o o    ( Zielonka  Poland )",
    "country": "Poland"
  },
  {
    "id": 103133,
    "name": "March & Green    ( Syracuse IN USA )",
    "country": "United States"
  },
  {
    "id": 293909,
    "name": "March Mfg Inc    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 461210,
    "name": "Marchbanks Measurement Systems Ltd    ( Lymington  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 138331,
    "name": "Marcher Enterprises Ltd    ( Hereford  USA )",
    "country": "United States"
  },
  {
    "id": 108200,
    "name": "Marco Ophthalmic Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 107363,
    "name": "Marco Products Co    ( San Fernando CA USA )",
    "country": "United States"
  },
  {
    "id": 374059,
    "name": "Marcon Group Inc    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 280694,
    "name": "Marcus Sommer Somso-Modelle    ( Coburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456507,
    "name": "Marcus Uniforms    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 451483,
    "name": "Marian Monticello    ( Monticello IN USA )",
    "country": "United States"
  },
  {
    "id": 455634,
    "name": "Marietta Vision    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 107110,
    "name": "Maril Products Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 402757,
    "name": "Marina Medical Instruments Inc    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 331414,
    "name": "Marinco Speciality Wiring Devices    ( Glendale WI USA )",
    "country": "United States"
  },
  {
    "id": 104368,
    "name": "Marine Dynamics Corp    ( Murrieta CA USA )",
    "country": "United States"
  },
  {
    "id": 354747,
    "name": "Mark Force Pty Ltd    ( Bayswater  USA )",
    "country": "United States"
  },
  {
    "id": 325084,
    "name": "Mark of Fitness Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 363303,
    "name": "MarkCare Medical Systems Ltd    ( Grayshott  USA )",
    "country": "United States"
  },
  {
    "id": 103823,
    "name": "Markel Corp    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 103824,
    "name": "Markell Shoe Co Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 999982,
    "name": "MARKET FORGE    (   USA )",
    "country": "United States"
  },
  {
    "id": 459051,
    "name": "Market Forge Industries Inc    ( Everett MA USA )",
    "country": "United States"
  },
  {
    "id": 384299,
    "name": "Marketing & Industrie NV    ( Geel  USA )",
    "country": "United States"
  },
  {
    "id": 104928,
    "name": "Marketing International Inc    ( Topeka KS USA )",
    "country": "United States"
  },
  {
    "id": 454038,
    "name": "Marketlab Inc    ( Caledonia MI USA )",
    "country": "United States"
  },
  {
    "id": 460630,
    "name": "MarketWare Systems LLC    ( Midvale UT USA )",
    "country": "United States"
  },
  {
    "id": 452743,
    "name": "Marknew Products LLC    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 348756,
    "name": "Markson LabSales Inc    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 281081,
    "name": "Markus Medizintechnik    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 284474,
    "name": "Markus Tueren GmbH    ( Montabaur  USA )",
    "country": "United States"
  },
  {
    "id": 104251,
    "name": "Marlen Mfg & Development Co    ( Bedford OH USA )",
    "country": "United States"
  },
  {
    "id": 458171,
    "name": "Marlin Steel Wire Products    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 105960,
    "name": "Marlo Mfg Co    ( Boonton NJ USA )",
    "country": "United States"
  },
  {
    "id": 285760,
    "name": "Maro-Medical GmbH    ( Rheinstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 280424,
    "name": "Marob Hygienetechnik Margot Kenngott    ( Boertlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 301519,
    "name": "Marpac Inc    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 175619,
    "name": "Marque Inc    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 457075,
    "name": "Marquis Medical LLC    ( Denham Springs LA USA )",
    "country": "United States"
  },
  {
    "id": 451235,
    "name": "Mars Medical Products Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451275,
    "name": "Mars Medical Products Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451203,
    "name": "Mars Medical Systems Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 999458,
    "name": "Mars Scales    ( Ontario  Canada )",
    "country": "Canada"
  },
  {
    "id": 323748,
    "name": "Marsden Weighing Machine Group Ltd    ( Henley-on-Thames  England )",
    "country": "UK"
  },
  {
    "id": 438942,
    "name": "Marshall Products Ltd    ( Bath  England )",
    "country": "UK"
  },
  {
    "id": 474915,
    "name": "Marshall-Browning International Corp    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 458383,
    "name": "MarShield    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451991,
    "name": "Marson Medical Inc    ( Paramus NJ USA )",
    "country": "United States"
  },
  {
    "id": 457716,
    "name": "Mart Microbiology bv    ( Drachten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 186066,
    "name": "Martab Medical    ( Allendale NJ USA )",
    "country": "United States"
  },
  {
    "id": 312180,
    "name": "Martech Medical Inc    ( Harleysville PA USA )",
    "country": "United States"
  },
  {
    "id": 453805,
    "name": "Martel Instruments Ltd    ( Stanley  England )",
    "country": "UK"
  },
  {
    "id": 453807,
    "name": "Martel Instruments Ltd USA    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 281157,
    "name": "Martens-Medizinisch-Pharmazeutische Software GmbH    ( Eckental  Germany )",
    "country": "Germany"
  },
  {
    "id": 458682,
    "name": "Martikan    ( Dolna Marikova 7  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 146115,
    "name": "Martin Medical Equipment Servicing Co    ( North Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 456563,
    "name": "Martinson-Nicholls Inc    ( Willoughby OH USA )",
    "country": "United States"
  },
  {
    "id": 174110,
    "name": "Marubun Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 184903,
    "name": "Maruman Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 288757,
    "name": "Marumitsu Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999649,
    "name": "Marus Dental    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 371978,
    "name": "Marus Dental International    ( Newberg OR USA )",
    "country": "United States"
  },
  {
    "id": 431597,
    "name": "Marvel Group Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 451226,
    "name": "Marvel Scientific    ( Greenville MI USA )",
    "country": "United States"
  },
  {
    "id": 359996,
    "name": "Maryland Business Forms    ( Glyndin MD USA )",
    "country": "United States"
  },
  {
    "id": 102560,
    "name": "Maryland Plastics Inc    ( Federalsburg MD USA )",
    "country": "United States"
  },
  {
    "id": 293679,
    "name": "maSana AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 287463,
    "name": "Maschinenbau Harnisch & Rieth Gmbh & Co    ( Winterbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 407232,
    "name": "Mascia Brunelli SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 184525,
    "name": "Masel Enterprises    ( Bristol PA USA )",
    "country": "United States"
  },
  {
    "id": 457163,
    "name": "Masimo (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 371567,
    "name": "Masimo Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 457162,
    "name": "Masimo International Sarl    ( Neuchatel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458351,
    "name": "Masimo Sweden AB    ( Danderyd  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 107874,
    "name": "Mason Medical Products    ( New Hyde Park NY USA )",
    "country": "United States"
  },
  {
    "id": 466277,
    "name": "Mason Tayler Medical Products Corp    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 455938,
    "name": "MASS Group Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 106816,
    "name": "Mass Medical Equipment Co Inc    ( Wakefield MA USA )",
    "country": "United States"
  },
  {
    "id": 457327,
    "name": "MASS Medical Storage    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 462670,
    "name": "Massachusetts Rehabilitation Commission (MRC)    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 457342,
    "name": "Massage Warehouse    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 285136,
    "name": "Massunda Dieter Weissbach Herstellung    ( Murnau  Germany )",
    "country": "Germany"
  },
  {
    "id": 392501,
    "name": "MAST Diagnostic (France)    ( Amiens  France )",
    "country": "France"
  },
  {
    "id": 285658,
    "name": "MAST Diagnostica Laboratoriums-Praeparate GmbH    ( Reinfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 157002,
    "name": "MAST Group Ltd    ( Merseyside  England )",
    "country": "UK"
  },
  {
    "id": 999588,
    "name": "MASTECH    (   USA )",
    "country": "United States"
  },
  {
    "id": 176915,
    "name": "Mastel Precision Surgical Instruments Inc    ( Rapid City SD USA )",
    "country": "United States"
  },
  {
    "id": 999915,
    "name": "Master Appliance Corp    ( Racine WI USA )",
    "country": "United States"
  },
  {
    "id": 397749,
    "name": "Master Plan Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 458960,
    "name": "Master-Bilt    ( New Albany MS USA )",
    "country": "United States"
  },
  {
    "id": 416014,
    "name": "Master-Tool Co    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 171222,
    "name": "MasterCare Patient Equipment Inc    ( Columbus NE USA )",
    "country": "United States"
  },
  {
    "id": 458738,
    "name": "MasterControl Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 465059,
    "name": "MasterControl Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 473828,
    "name": "Masterguard Chloride Austria Energieversorgungs-sy    ( Salzburg  Austria )",
    "country": "Austria"
  },
  {
    "id": 150139,
    "name": "Mastex Industries Inc    ( Petersburg VA USA )",
    "country": "United States"
  },
  {
    "id": 195242,
    "name": "Matachana SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 458925,
    "name": "Matakina International Ltd    ( Wellington  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 344625,
    "name": "Matarrita Medica SA    ( Heredia  USA )",
    "country": "United States"
  },
  {
    "id": 339700,
    "name": "Materialise (USA)    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 453978,
    "name": "Materialise China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415948,
    "name": "Materialise France    ( Romans-sur-Isere  France )",
    "country": "France"
  },
  {
    "id": 415953,
    "name": "Materialise Germany    ( Oberpfaffenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 415952,
    "name": "Materialise Japan    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 415951,
    "name": "Materialise Malaysia    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 427868,
    "name": "Materialise Medical    ( Glen Burnie MD USA )",
    "country": "United States"
  },
  {
    "id": 407051,
    "name": "Materialise NV    ( Leuven  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 415950,
    "name": "Materialise UK    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 453977,
    "name": "Materialise Ukraine    ( Kiev  Ukraine )",
    "country": "United Kingdomraine"
  },
  {
    "id": 451453,
    "name": "Materials Management Microsystems    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 460564,
    "name": "Math Resolutions LLC    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 431366,
    "name": "Matheson Tri-Gas Inc    ( Montgomeryville PA USA )",
    "country": "United States"
  },
  {
    "id": 282854,
    "name": "Mathias Lueck Spezialdruckerei    ( Heinsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454489,
    "name": "Mathys Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 454410,
    "name": "Mathys Ltd Bettlach    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 185600,
    "name": "Mathys Medical Ltd    ( Bettlach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454486,
    "name": "Mathys Orthopaedics Belux NV-SA    ( Leuven  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454488,
    "name": "Mathys Orthopaedics bv    ( Huis ter Heide  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454411,
    "name": "Mathys Orthopaedics Ltd    ( Alton  England )",
    "country": "UK"
  },
  {
    "id": 454409,
    "name": "Mathys Orthopaedics Pty Ltd    ( Castle Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 454487,
    "name": "Mathys Orthopaedics SAS    ( Gerzat  France )",
    "country": "France"
  },
  {
    "id": 454484,
    "name": "Mathys Orthopaedie GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 454485,
    "name": "Mathys Orthopaedie GmbH (Austria)    ( Schwechat  Austria )",
    "country": "Austria"
  },
  {
    "id": 999599,
    "name": "Matica Americas    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 187297,
    "name": "Matifas SEHP    ( Amiens Cedex  France )",
    "country": "France"
  },
  {
    "id": 344493,
    "name": "Matlock Endoscopic Repairs Sales & Service Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 456632,
    "name": "MatOrtho Ltd    ( Leatherhead  England )",
    "country": "UK"
  },
  {
    "id": 274376,
    "name": "Matria Healthcare Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 243349,
    "name": "Matritech    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 370456,
    "name": "Matritech GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458584,
    "name": "Matrix Fitness    ( Cottage Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 461468,
    "name": "Matrix Science Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 458401,
    "name": "Matrix Systems Inc    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 169864,
    "name": "Matrix Technologies Corp    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 376489,
    "name": "Matrix Technologies Corp    ( Wilmslow  USA )",
    "country": "United States"
  },
  {
    "id": 376491,
    "name": "Matrix Technologies Corp    ( Pfaffikon  USA )",
    "country": "United States"
  },
  {
    "id": 376490,
    "name": "Matrix Technologies Corp Deutschland    ( Wehrheim  USA )",
    "country": "United States"
  },
  {
    "id": 278100,
    "name": "Matsunaga Seisakusho Co Ltd    ( Yorogun  Japan )",
    "country": "Japan"
  },
  {
    "id": 174111,
    "name": "Matsushita Communication Industrial Co Ltd    ( Yokohama  USA )",
    "country": "United States"
  },
  {
    "id": 286891,
    "name": "Mattes Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 467054,
    "name": "Matthews Specialty Vehicles Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 399034,
    "name": "Mattioli Engineering Corp    ( McLean VA USA )",
    "country": "United States"
  },
  {
    "id": 177055,
    "name": "Mattioli Engineering Italia SPA    ( Calenzano (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456277,
    "name": "Mauna Kea Technologies Inc    ( Newtown PA USA )",
    "country": "United States"
  },
  {
    "id": 283693,
    "name": "Maurenbrecher    ( Krefeld  USA )",
    "country": "United States"
  },
  {
    "id": 287103,
    "name": "Mauser Waldeck AG Einrichtungen    ( Waldeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 151411,
    "name": "Maven Medical Mfg Inc    ( Indian Rocks Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 462534,
    "name": "Maverick Office Solutions    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 999815,
    "name": "Mavidon    ( Lake Worth FL USA )",
    "country": "USA"
  },
  {
    "id": 457334,
    "name": "Mavidon Corp    ( Lake Worth FL USA )",
    "country": "United States"
  },
  {
    "id": 452086,
    "name": "MAVIG GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 106624,
    "name": "Mavig of America    ( Torrington CT USA )",
    "country": "United States"
  },
  {
    "id": 286803,
    "name": "Max Hauser Sueddeutsche Chirurgie-Mechanik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 464955,
    "name": "Max Medical Solutions Inc    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 293416,
    "name": "Max Staeubli AG    ( Horgen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462537,
    "name": "Max-Secure Systems Inc    ( Williamsville NY USA )",
    "country": "United States"
  },
  {
    "id": 104413,
    "name": "Maxant    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 451608,
    "name": "Maxi-Aids Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 999876,
    "name": "Maxi-Myst    (   USA )",
    "country": "United States"
  },
  {
    "id": 439926,
    "name": "Maximus Medical Products Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 457201,
    "name": "MAXimus sc    ( Wroclaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 175847,
    "name": "Maxon America Paging Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 362036,
    "name": "Maxtec Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 454415,
    "name": "Maxter Catheters    ( Marseille  France )",
    "country": "France"
  },
  {
    "id": 458743,
    "name": "Maxtown Medical Corp    ( Westerville OH USA )",
    "country": "United States"
  },
  {
    "id": 467190,
    "name": "MAXXESS Systems Inc    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 365268,
    "name": "Maxxim Medical Europe bv    ( Oss  USA )",
    "country": "United States"
  },
  {
    "id": 281022,
    "name": "May & Spies Papierverarbeitungswerk GmbH & Co KG    ( Dueren  Germany )",
    "country": "Germany"
  },
  {
    "id": 187438,
    "name": "May Corp    ( Grygla MN USA )",
    "country": "United States"
  },
  {
    "id": 415968,
    "name": "Mayall Australia Pty Ltd    ( Richmond  USA )",
    "country": "United States"
  },
  {
    "id": 227238,
    "name": "Mayfair Medical Supplies Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455746,
    "name": "Mayfair Medical Supplies Ltd    ( Jordon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 170496,
    "name": "Mayfield Medical Services Inc    ( Bethalto IL USA )",
    "country": "United States"
  },
  {
    "id": 431604,
    "name": "Mayline Group    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 444085,
    "name": "Mayo Healthcare Pty Ltd    ( Rosebery  Australia )",
    "country": "Australia"
  },
  {
    "id": 102556,
    "name": "Mayon Plastics Inc    ( Hopkins MN USA )",
    "country": "United States"
  },
  {
    "id": 999428,
    "name": "Maytag    (   USA )",
    "country": "United States"
  },
  {
    "id": 332680,
    "name": "Maytex Corp    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 457809,
    "name": "MAZOR Robotics GmbH    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 457808,
    "name": "MAZOR Robotics Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 457807,
    "name": "MAZOR Robotics Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 458289,
    "name": "MBF Bioscience    ( Williston VT USA )",
    "country": "United States"
  },
  {
    "id": 457320,
    "name": "MBH International AS    ( Alleroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 106813,
    "name": "MBI X-Ray and Medical Supply (MBI Inc)    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 459288,
    "name": "MBM Corp    ( North Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 298508,
    "name": "MBO Supplies (UK) Ltd    ( Borehamwood  USA )",
    "country": "United States"
  },
  {
    "id": 199586,
    "name": "MC Healthcare Products Inc    ( Beamsville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 279496,
    "name": "MC Medizingeraete GmbH    ( Alzenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 461023,
    "name": "MC Strategies Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 306232,
    "name": "MCA Medical Products Pty Ltd    ( Osborne Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 459175,
    "name": "McAfee Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 451062,
    "name": "McArthur Medical Sales    ( Rockton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456689,
    "name": "McAuley Medical Inc    ( Concord NH USA )",
    "country": "United States"
  },
  {
    "id": 175687,
    "name": "MCC GmbH    ( Karlsruhe  USA )",
    "country": "United States"
  },
  {
    "id": 102561,
    "name": "McCarty's Sacro Ease LLC    ( Coeur d'Alene ID USA )",
    "country": "United States"
  },
  {
    "id": 105324,
    "name": "McClure Industries Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 459230,
    "name": "McConnell Orthopedic Mfg    ( Greenville TX USA )",
    "country": "United States"
  },
  {
    "id": 454257,
    "name": "McCormick Scientific    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 102030,
    "name": "McCoy Miller    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 323939,
    "name": "McCrea Engineering Co Ltd    ( Houghton Regis  England )",
    "country": "UK"
  },
  {
    "id": 307194,
    "name": "Medhold Medical Ltd    ( Westville  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 460647,
    "name": "McCreadie Group Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 466595,
    "name": "McCrone Microscopes & Accessories    ( Westmont IL USA )",
    "country": "United States"
  },
  {
    "id": 176926,
    "name": "McCue plc    ( Winchester  England )",
    "country": "UK"
  },
  {
    "id": 467327,
    "name": "MCD Medical Computers Deutschland GmbH    ( Moenchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 404241,
    "name": "McDonough Medical Products Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 306201,
    "name": "McFarlane Medical Equipment Holding Pty Ltd    ( Surrey Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 460581,
    "name": "MCG    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 454925,
    "name": "McGan Technology LLC    ( Blackstone MA USA )",
    "country": "United States"
  },
  {
    "id": 232591,
    "name": "McHugh Group    ( Garden City NY USA )",
    "country": "United States"
  },
  {
    "id": 306086,
    "name": "MCI Australia Pty Ltd    ( St Kilda  Australia )",
    "country": "Australia"
  },
  {
    "id": 103819,
    "name": "MCI Optonix    ( Washington NJ USA )",
    "country": "United States"
  },
  {
    "id": 428420,
    "name": "McKelor Technologies Ltd    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 108393,
    "name": "McKenzie Repair Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 101459,
    "name": "McKeon Products Inc    ( Warren MI USA )",
    "country": "United States"
  },
  {
    "id": 397741,
    "name": "McKesson Automation Inc    ( Cranberry Township PA USA )",
    "country": "United States"
  },
  {
    "id": 410524,
    "name": "McKesson Medical Imaging Group    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 466248,
    "name": "McKesson Medical Imaging Group    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 397740,
    "name": "McKesson Medical-Surgical    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 397739,
    "name": "McKesson Provider Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 458177,
    "name": "McKesson Technology Solutions    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 408916,
    "name": "McKinley Medical (UK) Ltd    ( Blackpool  USA )",
    "country": "United States"
  },
  {
    "id": 288679,
    "name": "McKinley Medical LLLP    ( Wheat Ridge CO USA )",
    "country": "United States"
  },
  {
    "id": 467243,
    "name": "McMaster-Carr    ( Robbinsville NJ USA )",
    "country": "United States"
  },
  {
    "id": 108044,
    "name": "McMillan Co    ( Georgetown TX USA )",
    "country": "United States"
  },
  {
    "id": 450585,
    "name": "MCMmedsys AG    ( Kirchberg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453147,
    "name": "MCN Healthcare Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 335636,
    "name": "MCP France SA    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 175369,
    "name": "MCP Iberia SA    ( Caslada (Madrid)  USA )",
    "country": "United States"
  },
  {
    "id": 335810,
    "name": "MCP Italia srl    ( Buccinasco MI  USA )",
    "country": "United States"
  },
  {
    "id": 321081,
    "name": "MCP Metalspecialties Inc    ( Fairfield CT USA )",
    "country": "United States"
  },
  {
    "id": 415824,
    "name": "MCP Technology    ( Minsk  USA )",
    "country": "United States"
  },
  {
    "id": 454721,
    "name": "McRoberts Security Technologies    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 159781,
    "name": "MCS AG    ( Eltville am Rhein  Germany )",
    "country": "Germany"
  },
  {
    "id": 415914,
    "name": "MCS Labordatensysteme GmbH & Co KG    ( Kornwestheim 1  USA )",
    "country": "United States"
  },
  {
    "id": 342087,
    "name": "MCS Medical (France)    ( Viry  France )",
    "country": "France"
  },
  {
    "id": 453873,
    "name": "MCS Medical Compression Systems (DBN) Ltd    ( Or-Akiva  Israel )",
    "country": "Israel"
  },
  {
    "id": 227904,
    "name": "MCS Medical Sarl    ( Carouge/Geneve  USA )",
    "country": "United States"
  },
  {
    "id": 105494,
    "name": "MCT Medical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 455063,
    "name": "Mcube Technology Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 450641,
    "name": "MD Biomedics Inc    ( Morrow OH USA )",
    "country": "United States"
  },
  {
    "id": 146164,
    "name": "MD International Co    ( Anthem AZ USA )",
    "country": "United States"
  },
  {
    "id": 125960,
    "name": "MD International Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456996,
    "name": "MD Resource Corp    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 454584,
    "name": "MD Tablet LLC    ( Matawan NJ USA )",
    "country": "United States"
  },
  {
    "id": 457197,
    "name": "mecoTec GmbH    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 452626,
    "name": "MD Technologies Inc    ( Galena IL USA )",
    "country": "United States"
  },
  {
    "id": 415429,
    "name": "MDC    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 415437,
    "name": "MDC    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 186075,
    "name": "MDC Molecular Data Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 463044,
    "name": "MDF Instruments Medifriend Inc    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451289,
    "name": "MDG Medical Inc    ( Beechwood OH USA )",
    "country": "United States"
  },
  {
    "id": 453528,
    "name": "MDI Achieve    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 454339,
    "name": "MDI Achieve    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 459207,
    "name": "MDI Solutions    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 441523,
    "name": "MDI Technologies Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 108054,
    "name": "MDI/Modular Devices Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 454581,
    "name": "MDLand.com    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 451775,
    "name": "MDMI Technologies Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452127,
    "name": "MDOffice    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 454631,
    "name": "MDS Analytical Technologies    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 454636,
    "name": "MDS Analytical Technologies (GB) Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 454635,
    "name": "MDS Analytical Technologies GmbH    ( Ismaning  Germany )",
    "country": "Germany"
  },
  {
    "id": 321017,
    "name": "MDS Nordion    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 351565,
    "name": "MDS Nordion (Hong Kong)    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 417230,
    "name": "MDS Nordion (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 351564,
    "name": "MDS Nordion SA    ( Fleurus  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 375988,
    "name": "MDServe Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 172295,
    "name": "MDT Industrial Diamond Tools (1990) Ltd    ( Afula  Israel )",
    "country": "Israel"
  },
  {
    "id": 457640,
    "name": "MDT J Zych A Budyn Spj    ( Krakow  Poland )",
    "country": "Poland"
  },
  {
    "id": 238455,
    "name": "MDT USA    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 999812,
    "name": "Meadows Instrumentation, Inc    ( Bristol WI USA )",
    "country": "United States"
  },
  {
    "id": 293912,
    "name": "Measom Freer Ltd    ( Wigston  England )",
    "country": "UK"
  },
  {
    "id": 451565,
    "name": "Measurement Computing corp    ( Norton MA USA )",
    "country": "United States"
  },
  {
    "id": 372025,
    "name": "Measurement Specialties    ( Hampton VA USA )",
    "country": "United States"
  },
  {
    "id": 452105,
    "name": "Measurement Specialties Inc    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 282484,
    "name": "Meba GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456013,
    "name": "MEC Addheat Co Ltd    ( Tainan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 186711,
    "name": "MEC-Inov    ( Levis PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455040,
    "name": "Mecalab-M Braun AG    ( Wittenbach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453116,
    "name": "MECALL srl    ( Lissone (MB)  Italy )",
    "country": "Italy"
  },
  {
    "id": 293396,
    "name": "Mecaplex AG    ( Grenchen  USA )",
    "country": "United States"
  },
  {
    "id": 272614,
    "name": "Mecaserto    ( Saint Thibault des Vignes  USA )",
    "country": "United States"
  },
  {
    "id": 185429,
    "name": "Mechanika Precyzyjna Wytwarzanie art z tworz szt    ( Lomianki k/Warszawy  Poland )",
    "country": "Poland"
  },
  {
    "id": 453961,
    "name": "Mecmesin (Shanghai) Pte Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453960,
    "name": "Mecmesin Asia Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 453959,
    "name": "Mecmesin Corp    ( Sterling VA USA )",
    "country": "United States"
  },
  {
    "id": 324850,
    "name": "Mecmesin Ltd    ( Horsham  England )",
    "country": "UK"
  },
  {
    "id": 102246,
    "name": "MECTA Corp    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 453566,
    "name": "Mectra Laboratories Inc    ( Bloomfield IN USA )",
    "country": "United States"
  },
  {
    "id": 457232,
    "name": "Mectron spa    ( Carasco (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 285339,
    "name": "Med & Org GmbH    ( Pfalzgrafenweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 293799,
    "name": "MED AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 450586,
    "name": "MED Alliance Group Inc    ( St Charles IL USA )",
    "country": "United States"
  },
  {
    "id": 106818,
    "name": "Med Assoc Inc    ( St Albans VT USA )",
    "country": "United States"
  },
  {
    "id": 457151,
    "name": "Med Care Mfg    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 457093,
    "name": "MED iMAGE Equipment Solutions    ( New Lenox IL USA )",
    "country": "United States"
  },
  {
    "id": 102818,
    "name": "Med Labs Inc    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 151418,
    "name": "Med Link Medical Inc    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 457938,
    "name": "MED Medical Products GmbH    ( Karlstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 259205,
    "name": "Med Net GmbH    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 457870,
    "name": "Med One Capital    ( Sandy UT USA )",
    "country": "United States"
  },
  {
    "id": 461400,
    "name": "Med Stainless Steel    ( West Harrison IN USA )",
    "country": "United States"
  },
  {
    "id": 105384,
    "name": "Med Systems    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 103827,
    "name": "Med Tech Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 450784,
    "name": "Med X Change Inc    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 458745,
    "name": "Med-Acoustics    ( Stone Mountain GA USA )",
    "country": "United States"
  },
  {
    "id": 457889,
    "name": "Med-Chem Surgical Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 455503,
    "name": "Med-Con USA Inc    ( Longwood FL USA )",
    "country": "United States"
  },
  {
    "id": 417354,
    "name": "Med-Dispense LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 451212,
    "name": "Med-Dyne    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 101466,
    "name": "Med-E-Jet D    ( Olmsted Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 371513,
    "name": "Med-e-quip Locators Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 338608,
    "name": "Med-Edge Inc    ( Clemmons NC USA )",
    "country": "United States"
  },
  {
    "id": 296250,
    "name": "MED-EL Corp    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 286323,
    "name": "MED-EL Deutschland GmbH    ( Starnberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 274372,
    "name": "MED-EL GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 299796,
    "name": "MED-EL GmbH    ( Innsbruck  USA )",
    "country": "United States"
  },
  {
    "id": 390970,
    "name": "MED-EL GmbH Sucursal Espana    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 391476,
    "name": "MED-EL Hellas    ( Thessaloniki  USA )",
    "country": "United States"
  },
  {
    "id": 390971,
    "name": "MED-EL Hong Kong    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 391477,
    "name": "MED-EL Latino America Srl    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 390969,
    "name": "MED-EL Medical Electronics    ( Muntinlupa City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 390972,
    "name": "MED-EL Middle East FZE    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 390968,
    "name": "MED-EL Tokyo    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453625,
    "name": "MED-EL UK Ltd    ( Barnsley  England )",
    "country": "UK"
  },
  {
    "id": 106052,
    "name": "Med-Electronics Inc    ( College Park MD USA )",
    "country": "United States"
  },
  {
    "id": 170498,
    "name": "Med-Equip International    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 233468,
    "name": "Med-Fit Systems Inc    ( Fallbrook CA USA )",
    "country": "United States"
  },
  {
    "id": 457611,
    "name": "Med-Hot Thermal Imaging Inc    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 462544,
    "name": "MedHOK Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 306459,
    "name": "Med-I-Pant (NZ) Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 378244,
    "name": "Med-I-Pant (UK) Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 465131,
    "name": "Med-Lab    ( Miami 92 USA )",
    "country": "United States"
  },
  {
    "id": 188446,
    "name": "Med-Lift & Mobility Inc    ( Calhoun City MS USA )",
    "country": "United States"
  },
  {
    "id": 459191,
    "name": "Med-Mizer Inc    ( Batesville IN USA )",
    "country": "United States"
  },
  {
    "id": 105793,
    "name": "Med-Ortho Design & Mfg Ltd    ( Concord ON USA )",
    "country": "United States"
  },
  {
    "id": 459275,
    "name": "Med-Pat Inc    ( Shrewsbury NJ USA )",
    "country": "United States"
  },
  {
    "id": 342105,
    "name": "Med-Pro Corp    ( Woodside NY USA )",
    "country": "United States"
  },
  {
    "id": 464084,
    "name": "MED-SONICS Corp    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 155273,
    "name": "Med-Stor    ( St Clair Shores MI USA )",
    "country": "United States"
  },
  {
    "id": 441563,
    "name": "MED-TEC (Shanghai)    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 106920,
    "name": "MED-TEC Inc    ( Orange City IA USA )",
    "country": "United States"
  },
  {
    "id": 288777,
    "name": "Med-Tech Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 106176,
    "name": "Med-X-Ray Co Inc    ( East Orange NJ USA )",
    "country": "United States"
  },
  {
    "id": 342425,
    "name": "Med-XS Solutions    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 431343,
    "name": "Med1Online    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 455957,
    "name": "MEDA Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 282486,
    "name": "medac Gesellschaft fuer klinische Spezialpraeparat    (   USA )",
    "country": "United States"
  },
  {
    "id": 459254,
    "name": "Medacist Solutions Group    ( Cheshire CT USA )",
    "country": "United States"
  },
  {
    "id": 454364,
    "name": "MedAct Software Solutions    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 453389,
    "name": "Medacta International SA    ( Castel San Pietro  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462966,
    "name": "Medacta USA Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 455130,
    "name": "MEDACX Ltd    ( Hayling Island  England )",
    "country": "UK"
  },
  {
    "id": 455204,
    "name": "Medafor Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 455205,
    "name": "Medafor International GmbH    ( Marktbreit  Germany )",
    "country": "Germany"
  },
  {
    "id": 331429,
    "name": "MEDai Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 150961,
    "name": "Medaid Health Products    ( Felmersham  England )",
    "country": "UK"
  },
  {
    "id": 439908,
    "name": "Medair AB    ( Hudiksvall  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450919,
    "name": "MedAir Inc    ( East Bridgewater MA USA )",
    "country": "United States"
  },
  {
    "id": 286979,
    "name": "MEDAP Medizinische Apparate GmbH    ( Usingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461044,
    "name": "MedApproved LLC    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 454583,
    "name": "MedAppz LLC    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 428449,
    "name": "Medaris Medical Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 378079,
    "name": "MedArt (Deutschland) GmbH    ( Stettfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 453580,
    "name": "MedArt A/S    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 186471,
    "name": "MedArt Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 454849,
    "name": "Medartis AG    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462968,
    "name": "Medartis Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 453850,
    "name": "MedAssets    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 370951,
    "name": "MedAssets Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 151061,
    "name": "Medasto bv    ( Leiden  USA )",
    "country": "United States"
  },
  {
    "id": 415344,
    "name": "Medasys Belgium    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 362142,
    "name": "Medasys Japan K K    ( Inagi  Japan )",
    "country": "Japan"
  },
  {
    "id": 202262,
    "name": "Medasys SA    ( Gif-sur-Yvette Cedex  France )",
    "country": "France"
  },
  {
    "id": 415343,
    "name": "Medasys Suisse    ( Geneve 26  USA )",
    "country": "United States"
  },
  {
    "id": 177667,
    "name": "Medatec SPRL    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 185918,
    "name": "Medlas Medical Technologies    ( Bonn  USA )",
    "country": "United States"
  },
  {
    "id": 452829,
    "name": "Medavis GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 457278,
    "name": "MEDAX GmbH & Co KG    ( Neumuenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 159802,
    "name": "Medax Nagel GmbH    ( Rendsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454585,
    "name": "MedAZ.Net LLC    ( Princeton Junction NJ USA )",
    "country": "United States"
  },
  {
    "id": 458748,
    "name": "Medcalm Corp    ( Chappaqua NY USA )",
    "country": "United States"
  },
  {
    "id": 461402,
    "name": "Medcare Mfg Inc    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 370363,
    "name": "Medcare Products    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 999951,
    "name": "MEDCARE PRODUCTS    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 343764,
    "name": "Medcare Systems    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 108785,
    "name": "Medcare Technologies    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 455959,
    "name": "Medcie Industry Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454130,
    "name": "MedClean Technologies Inc    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 352946,
    "name": "Medco Electronic Systems Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 458541,
    "name": "MEDCO Equipment Inc    ( Houlton WI USA )",
    "country": "United States"
  },
  {
    "id": 459256,
    "name": "MEDCO Equipment Inc    ( Houlton WI USA )",
    "country": "United States"
  },
  {
    "id": 374035,
    "name": "Medco Instruments Inc    ( Hickory Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 179246,
    "name": "Medco Ltd    ( Krakow  USA )",
    "country": "United States"
  },
  {
    "id": 999481,
    "name": "Medco Manufacturing    ( Spring Tx USA )",
    "country": "USA"
  },
  {
    "id": 331431,
    "name": "Medco Service Inc    ( Fayetteville NC USA )",
    "country": "United States"
  },
  {
    "id": 455109,
    "name": "Medco Sports Medicine    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 450935,
    "name": "Medco Supplies    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 451859,
    "name": "Medco Supply Co Inc    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 452830,
    "name": "MedCom GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 104948,
    "name": "Medcom Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 186630,
    "name": "Medcom Information Systems Inc    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 312177,
    "name": "MedComp (Medical Components Inc)    ( Harleysville PA USA )",
    "country": "United States"
  },
  {
    "id": 461475,
    "name": "MedComp Alliance LLC    ( East Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 461172,
    "name": "Medcomp Software Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 453858,
    "name": "MedcomSoft Corp    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458094,
    "name": "Medcon Biolab Technologies Inc    ( Grafton MA USA )",
    "country": "United States"
  },
  {
    "id": 454587,
    "name": "MedConnect Inc    ( Montgomery AL USA )",
    "country": "United States"
  },
  {
    "id": 306530,
    "name": "MedControl Electronics GmbH    ( Teltow/Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 459190,
    "name": "Medcor Inc    ( Lexington NC USA )",
    "country": "United States"
  },
  {
    "id": 103298,
    "name": "Medcorp    (   USA )",
    "country": "United States"
  },
  {
    "id": 156405,
    "name": "Medcorp International    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 162093,
    "name": "Medcraft Industries Inc    ( Darien CT USA )",
    "country": "United States"
  },
  {
    "id": 439185,
    "name": "MedDetect Inc    ( Rochester IL USA )",
    "country": "United States"
  },
  {
    "id": 101465,
    "name": "MedDev Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 456967,
    "name": "Meddev USA    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 281251,
    "name": "Mede Technik Chirurgie- und DentalInstrumente    ( Emmingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 185431,
    "name": "Medea Corp    ( Gliwice  USA )",
    "country": "United States"
  },
  {
    "id": 426346,
    "name": "Medea Corp    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 461494,
    "name": "MedeAnalytics Inc    ( Emeryville CA USA )",
    "country": "United States"
  },
  {
    "id": 291305,
    "name": "Medec Benelux nv    ( Aalst  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 331433,
    "name": "MEDecision Inc    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 151224,
    "name": "Medeco bv    ( Oud-Beijerland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 402217,
    "name": "Medeco Inc    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 305602,
    "name": "Medefield Pty Ltd    ( Mt Kuring-Gai  USA )",
    "country": "United States"
  },
  {
    "id": 453067,
    "name": "Medefil Inc    ( Glendale Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 409727,
    "name": "Medegen Medical Products    ( Gallaway TN USA )",
    "country": "United States"
  },
  {
    "id": 452199,
    "name": "Medegen MMS Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 157004,
    "name": "Medel Italiana SpA    ( San Polo di Torrile (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 282488,
    "name": "Medel Medizintechnik GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 156407,
    "name": "Medela AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451799,
    "name": "Medela Benelux bv    ( `s-Hertogenbosch  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451801,
    "name": "Medela Espana    ( Badalona (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 451795,
    "name": "Medela France Sarl    ( Etampes  France )",
    "country": "France"
  },
  {
    "id": 103640,
    "name": "Medela Inc    ( McHenry IL USA )",
    "country": "United States"
  },
  {
    "id": 451794,
    "name": "Medela Inc Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451798,
    "name": "Medela Italia srl    ( Sala Bolognese (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451797,
    "name": "Medela KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451800,
    "name": "Medela Medical AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 306267,
    "name": "Medela Medizintechnik GmbH & Co Handles KG    ( Eching  Germany )",
    "country": "Germany"
  },
  {
    "id": 451802,
    "name": "Medela OOO    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 451796,
    "name": "Medela UK Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 106315,
    "name": "Medelco (Medical Electronics Co Inc)    ( Boynton Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 186085,
    "name": "Medelex Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 170500,
    "name": "Medequip Biomedical    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 457861,
    "name": "MedEquip Biomedical Inc    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 356632,
    "name": "Medequip Engineering Service Inc    ( Central Point OR USA )",
    "country": "United States"
  },
  {
    "id": 458622,
    "name": "Mederi Therapeutics Inc    ( Greenwich CT USA )",
    "country": "United States"
  },
  {
    "id": 284664,
    "name": "medesign I C GmbH    ( Dietramszell  Germany )",
    "country": "Germany"
  },
  {
    "id": 453937,
    "name": "medesign LLC    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 158751,
    "name": "MEDesign Ltd    ( Merseyside  England )",
    "country": "UK"
  },
  {
    "id": 309990,
    "name": "Medeus (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 451482,
    "name": "MedEvolve Inc    ( Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 452040,
    "name": "Medex    ( Saint Priest  France )",
    "country": "France"
  },
  {
    "id": 455747,
    "name": "Medex Orthopaedic and Medical Supplies    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107401,
    "name": "MedExchange USA    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 293398,
    "name": "Medexim AG    ( Fehraltorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 464074,
    "name": "Medexim spol sro    ( Piestany  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 199717,
    "name": "Medexpert    ( Folsom PA USA )",
    "country": "United States"
  },
  {
    "id": 455099,
    "name": "Medexpert Ktf    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 154413,
    "name": "Medexpo International srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456446,
    "name": "MedFact Engineering GmbH    ( Loerrach  Germany )",
    "country": "Germany"
  },
  {
    "id": 106820,
    "name": "Medfilms Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 108046,
    "name": "Medgyn Products Inc    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 441732,
    "name": "MEDHOST Inc    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 357638,
    "name": "medi Austria GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 279852,
    "name": "medi Bayreuth    ( Bayreuth  Germany )",
    "country": "Germany"
  },
  {
    "id": 357645,
    "name": "medi Bayreuth Espana SL    ( Hospitalet de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 357641,
    "name": "medi Bayreuth Lda    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 102284,
    "name": "Medi Cal Instruments Inc    ( Lewis Center OH USA )",
    "country": "United States"
  },
  {
    "id": 357642,
    "name": "medi Danmark ApS    ( Vallensbaeck  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 357639,
    "name": "medi France    ( Tremblay-en-France Cedex  France )",
    "country": "France"
  },
  {
    "id": 101683,
    "name": "Medi Inc    ( Randolph MA USA )",
    "country": "United States"
  },
  {
    "id": 357643,
    "name": "medi Italia srl    ( Casalecchio di Reno (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453624,
    "name": "medi Middle East    ( Abu Dhabi  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 357646,
    "name": "medi Nederland bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453420,
    "name": "Medi Notes Corp    ( West Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 453622,
    "name": "medi Polska Sp z o o    ( Gliwice  Poland )",
    "country": "Poland"
  },
  {
    "id": 999936,
    "name": "Medi Products    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 357644,
    "name": "medi UK Ltd    ( Hereford  USA )",
    "country": "United States"
  },
  {
    "id": 453620,
    "name": "medi UK Ltd    ( Hereford  England )",
    "country": "UK"
  },
  {
    "id": 108278,
    "name": "Medi USA LP    ( Whitsett NC USA )",
    "country": "United States"
  },
  {
    "id": 321739,
    "name": "Medi-Aids Sdn Bhd    ( Petaling Jaya  USA )",
    "country": "United States"
  },
  {
    "id": 103500,
    "name": "Medi-Crush Co    ( Flowood MS USA )",
    "country": "United States"
  },
  {
    "id": 104736,
    "name": "Medi-Dose Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 403491,
    "name": "Medi-Dyne Healthcare Products Inc    ( Colleyville TX USA )",
    "country": "United States"
  },
  {
    "id": 170952,
    "name": "Medi-Flex Hospital Products    ( Leawood KS USA )",
    "country": "United States"
  },
  {
    "id": 170501,
    "name": "Medi-Globe Corp    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 416052,
    "name": "Medi-Globe GmbH    ( Achenmuehle  Germany )",
    "country": "Germany"
  },
  {
    "id": 410115,
    "name": "Medi-inn Ltd    ( Cameron ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456994,
    "name": "Medi-Khan Inc    ( West Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 306695,
    "name": "Medi-Lab SA    ( Panama City  Republic of Panama )",
    "country": "Panama"
  },
  {
    "id": 105077,
    "name": "Medi-Mold Enterprises Inc    ( Tolland CT USA )",
    "country": "United States"
  },
  {
    "id": 106316,
    "name": "Medi-Nuclear Corp    ( New Hudson MI USA )",
    "country": "United States"
  },
  {
    "id": 453531,
    "name": "Medi-plinth Ltd    ( Ipswich  England )",
    "country": "UK"
  },
  {
    "id": 338456,
    "name": "Medi-Pro Medical Supplies Ltd    ( Yahud  USA )",
    "country": "United States"
  },
  {
    "id": 232665,
    "name": "Medi-Products    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 458141,
    "name": "Medi-Products Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 223757,
    "name": "Medi-Stim AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 426714,
    "name": "Medi-Stim Deutschland GmbH    ( Deisenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 426713,
    "name": "Medi-Stim USA Inc    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 101494,
    "name": "Medi-Tech International Corp    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 329974,
    "name": "Medi-Tech International Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 107956,
    "name": "Medi/Nuclear Corp Inc    ( Baldwin Park CA USA )",
    "country": "United States"
  },
  {
    "id": 289509,
    "name": "Media Cybernetics Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 356878,
    "name": "Media Highway    ( Berwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 451322,
    "name": "Mediace (Russia)    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 452831,
    "name": "Mediaform Informationssysteme GmbH    ( Reinbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 286899,
    "name": "Mediagnost GmbH    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451541,
    "name": "Mediaid GmbH    ( Grevenbroich  USA )",
    "country": "United States"
  },
  {
    "id": 417308,
    "name": "Mediaid Inc    ( Cerritos CA USA )",
    "country": "United States"
  },
  {
    "id": 455271,
    "name": "Mediaid Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452593,
    "name": "Mediana Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456349,
    "name": "Mediana Technologies Corp    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 382784,
    "name": "MEDIAPHOT Inc    ( North Wales PA USA )",
    "country": "United States"
  },
  {
    "id": 105830,
    "name": "Mediatech Inc    ( Manassas VA USA )",
    "country": "United States"
  },
  {
    "id": 450595,
    "name": "Medibo NV    ( Hamont-Achel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 226297,
    "name": "Medibol-Technics bv    ( Valkenswaard  USA )",
    "country": "United States"
  },
  {
    "id": 306507,
    "name": "Medic Corp Ltd    ( Lower Hutt  USA )",
    "country": "United States"
  },
  {
    "id": 187740,
    "name": "Medic ID's International Inc    ( Tarzana CA USA )",
    "country": "United States"
  },
  {
    "id": 456788,
    "name": "Medic Vision Imaging Solutions Ltd    ( Tirat Carmel  Israel )",
    "country": "Israel"
  },
  {
    "id": 455711,
    "name": "MEDIC-Egypt for Medical Clothes    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 105300,
    "name": "Medica Corp    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 456963,
    "name": "Medica Europe bv    ( Oss  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 230345,
    "name": "Medica Medizintechnik GmbH    ( Hochdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 339430,
    "name": "Medica Siller    ( San Luis Potosi  USA )",
    "country": "United States"
  },
  {
    "id": 267745,
    "name": "Medica srl    ( Medolla (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 192826,
    "name": "Medical & Pharmaceutical Services    ( Jeddah  Saudi Arabia )",
    "country": "Saudi Arabia"
  },
  {
    "id": 105962,
    "name": "Medical Accessories Inc    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 107393,
    "name": "Medical Action Industries Inc    ( Brentwood NY USA )",
    "country": "United States"
  },
  {
    "id": 455637,
    "name": "Medical Advantages Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 408525,
    "name": "Medical Air Solutions LLC    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 455275,
    "name": "Medical Air Systems Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 148887,
    "name": "Medical Air Technology Inc    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 306513,
    "name": "Medical Alarms Security Services (NZ) Ltd    ( Tauranga  USA )",
    "country": "United States"
  },
  {
    "id": 462538,
    "name": "Medical Asset Management Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 455495,
    "name": "Medical Australia Ltd    ( North Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 336793,
    "name": "Medical Automation Systems Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 106823,
    "name": "Medical Cables Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 410148,
    "name": "Medical Carbon Research Institute LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 102563,
    "name": "Medical Chemical Corp    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 104256,
    "name": "Medical Coaches Inc    ( Oneonta NY USA )",
    "country": "United States"
  },
  {
    "id": 416252,
    "name": "Medical Comfort Specialists    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 403613,
    "name": "Medical Compression Systems Ltd    ( Or-Akiva  USA )",
    "country": "United States"
  },
  {
    "id": 403610,
    "name": "Medical Compression Systems Ltd (US)    ( North Haledon NJ USA )",
    "country": "United States"
  },
  {
    "id": 331185,
    "name": "Medical Computing Assoc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 105909,
    "name": "Medical Concepts Development Inc    ( Woodbury MN USA )",
    "country": "United States"
  },
  {
    "id": 146160,
    "name": "Medical Data Information Services    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 336799,
    "name": "Medical Data Processing Inc    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 262868,
    "name": "Medical Data Systems    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 295094,
    "name": "Medical Design Systems    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 461508,
    "name": "Medical Designs LLC    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 172516,
    "name": "Medical Development Department of Tecmachine    ( Andrezieux-Boutheon Cedex  France )",
    "country": "France"
  },
  {
    "id": 306245,
    "name": "Medical Developments International Ltd    ( Springvale  Australia )",
    "country": "Australia"
  },
  {
    "id": 465061,
    "name": "Medical Device Depot    ( Ellicott City 32 USA )",
    "country": "United States"
  },
  {
    "id": 371926,
    "name": "Medical Device Resource Corp    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 108611,
    "name": "Medical Device Technologies Inc    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 456066,
    "name": "Medical Devices (Ptv) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 102983,
    "name": "Medical Devices Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 101476,
    "name": "Medical Devices International    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 172552,
    "name": "Medical Devices International Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 364510,
    "name": "Medical Devices International Inc    ( Fayetteville GA USA )",
    "country": "United States"
  },
  {
    "id": 416253,
    "name": "Medical Diagnostic Services Inc    ( Ellicott City MD USA )",
    "country": "United States"
  },
  {
    "id": 199681,
    "name": "Medical Display Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 331001,
    "name": "Medical Disposables Mfg    ( Mosta  Malta )",
    "country": "Malta"
  },
  {
    "id": 325782,
    "name": "Medical Distributors (Pty) Ltd    ( Halfway House  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 462638,
    "name": "Medical Dynamics of SC Inc    ( Leesville SC USA )",
    "country": "United States"
  },
  {
    "id": 450816,
    "name": "Medical Education Technologies Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 342106,
    "name": "Medical Electronic Devices & Instrumentation    ( Benicia CA USA )",
    "country": "United States"
  },
  {
    "id": 307057,
    "name": "Medical Electronic Distributors Pty Ltd    ( Bellville  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454136,
    "name": "Medical Electronic Systems Asia Pacific - Hong Kon    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452677,
    "name": "Medical Electronic Systems Europe    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 452671,
    "name": "Medical Electronic Systems LLC    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 452679,
    "name": "Medical Electronic Systems Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 106825,
    "name": "Medical Energy Inc    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 457563,
    "name": "Medical Equipment & Gases Australia Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 287709,
    "name": "Medical Equipment Co    ( Lucknow  India )",
    "country": "India"
  },
  {
    "id": 455638,
    "name": "Medical Equipment Dynamics Inc    ( New Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 106079,
    "name": "Medical Equipment Exchange Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 259210,
    "name": "Medical Equipment International (France)    ( Marne la VallTe Cedex  France )",
    "country": "France"
  },
  {
    "id": 331448,
    "name": "Medical Equipment Repair Assoc    ( Columbus WI USA )",
    "country": "United States"
  },
  {
    "id": 146167,
    "name": "Medical Equipment Resale Inc    ( Novi MI USA )",
    "country": "United States"
  },
  {
    "id": 415978,
    "name": "Medical Equipment Services Pty Ltd    ( Keilor Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 471723,
    "name": "Medical Equipment Source LLC    ( Mars PA USA )",
    "country": "United States"
  },
  {
    "id": 146168,
    "name": "Medical Equipment Specialists Inc (GA)    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 162424,
    "name": "Medical Equipment Specialists Inc (MA)    ( Northborough MA USA )",
    "country": "United States"
  },
  {
    "id": 455250,
    "name": "Medical Equipment US    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 451272,
    "name": "Medical Export srl    ( Fizzonasco di Pieve Emanuele  Italy )",
    "country": "Italy"
  },
  {
    "id": 455037,
    "name": "Medical Eye Bank of Maryland    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 177849,
    "name": "Medical Fittings Div Precison Medical Inc    ( Northampton PA USA )",
    "country": "United States"
  },
  {
    "id": 283463,
    "name": "Medical GmbH Medizintechnik    ( Kirchlengern  Germany )",
    "country": "Germany"
  },
  {
    "id": 104049,
    "name": "Medical Graphics Corp    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 356262,
    "name": "Medical High Tech GmbH    ( Bad Krozingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451345,
    "name": "Medical House Products Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 106702,
    "name": "Medical ID Systems Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 465177,
    "name": "Medical Illumination Inc    ( San Fernando 54 USA )",
    "country": "United States"
  },
  {
    "id": 107071,
    "name": "Medical Illumination International    ( San Fernando CA USA )",
    "country": "United States"
  },
  {
    "id": 458951,
    "name": "Medical Image Enhancement Technologies Inc (MIET)    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 462920,
    "name": "Medical Imaging Applications LLC    ( Coralville IA USA )",
    "country": "United States"
  },
  {
    "id": 339727,
    "name": "Medical Imaging Consultants Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 186096,
    "name": "Medical Imaging International Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 186097,
    "name": "Medical Imaging Resources Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 455043,
    "name": "Medical Imaging Solutions    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 186098,
    "name": "Medical Imaging Solutions Inc    ( Jefferson LA USA )",
    "country": "United States"
  },
  {
    "id": 399226,
    "name": "Medical Imaging Systems Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 372273,
    "name": "Medical Imaging Technologies    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 364576,
    "name": "Medical Imaging/IMCO Inc    ( Hatboro PA USA )",
    "country": "United States"
  },
  {
    "id": 337805,
    "name": "Medical Index GmbH    ( Bad Rappenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 362396,
    "name": "Medical Indicators Inc    ( Pennington NJ USA )",
    "country": "United States"
  },
  {
    "id": 108396,
    "name": "Medical Industries American Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 356634,
    "name": "Medical Industries Australia Pty Ltd    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 454588,
    "name": "Medical Informatics Engineering    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 103505,
    "name": "Medical Information Technology Inc (MEDITECH)    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 454728,
    "name": "Medical Information Technology SA (Pty) Ltd (MEDIT    ( Halfway House  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 376087,
    "name": "Medical Innovations International Inc    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 324355,
    "name": "Medical Innovations Ltd    ( Bishop's Stortford  England )",
    "country": "UK"
  },
  {
    "id": 146195,
    "name": "Medical Instrument Services Inc    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 146196,
    "name": "Medical Instrumentation Brokers Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 107399,
    "name": "Medical Instrumentation Repair Inc (MIRI)    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 172853,
    "name": "Medical Instruments Corp    ( Solothurn  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 291100,
    "name": "Medical Instruments Factory of Baoding    ( Baoding  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107573,
    "name": "Medical Integrated Services Inc    ( Cuyahoga Falls OH USA )",
    "country": "United States"
  },
  {
    "id": 453178,
    "name": "Medical Intelligence Medizintechnik GmbH    ( Schwabmuenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 353514,
    "name": "Medical International    ( Delhi  India )",
    "country": "India"
  },
  {
    "id": 339731,
    "name": "Medical International Exchange Inc    ( St Charles MO USA )",
    "country": "United States"
  },
  {
    "id": 187741,
    "name": "Medical International Research    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 175434,
    "name": "Medical Knowledge Systems (MKS) Inc    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 155963,
    "name": "Medical Line Warehouse    (   USA )",
    "country": "United States"
  },
  {
    "id": 306234,
    "name": "Medical Magnetics (Aust) Pty Ltd    ( Wanneroo  USA )",
    "country": "United States"
  },
  {
    "id": 431325,
    "name": "Medical Manager MacHealth    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 409103,
    "name": "Medical Manager PNC Inc    ( Elmwood Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 300134,
    "name": "Medical Manager Research and Development Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 453005,
    "name": "Medical Measurement Systems bv    ( Enschede  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453004,
    "name": "Medical Measurement Systems USA Inc    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 103829,
    "name": "Medical Measurements Inc    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 105676,
    "name": "Medical Monitoring Systems Inc    ( Teaneck NJ USA )",
    "country": "United States"
  },
  {
    "id": 392264,
    "name": "Medical Monofilament Mfg LLC    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 384350,
    "name": "Medical Optics    ( Tamarac FL USA )",
    "country": "United States"
  },
  {
    "id": 169582,
    "name": "Medical Packaging Corp    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 105745,
    "name": "Medical Packaging Inc    ( Ringoes NJ USA )",
    "country": "United States"
  },
  {
    "id": 461095,
    "name": "Medical Physics Consultants    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 174677,
    "name": "Medical Plastic Devices Inc    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 392108,
    "name": "Medical Positioning Inc    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 459002,
    "name": "Medical Predictive Science Corp (MPSC)    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 462817,
    "name": "Medical Products Direct    ( Del City OK USA )",
    "country": "United States"
  },
  {
    "id": 151437,
    "name": "Medical Recovery Co Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 331725,
    "name": "Medical Repair & Supply    ( Metairie LA USA )",
    "country": "United States"
  },
  {
    "id": 298377,
    "name": "Medical Repair Center Inc    ( Chula Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 170513,
    "name": "Medical Resources    ( Lewis Center OH USA )",
    "country": "United States"
  },
  {
    "id": 457647,
    "name": "Medical Resources Group Ltd    ( Morton IL USA )",
    "country": "United States"
  },
  {
    "id": 310121,
    "name": "Medical Resources Management Inc    ( Glendale CA USA )",
    "country": "United States"
  },
  {
    "id": 292795,
    "name": "Medical Rubber AB    ( Horby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 288783,
    "name": "Medical Science Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 107878,
    "name": "Medical Science Products Inc    ( Canal Fulton OH USA )",
    "country": "United States"
  },
  {
    "id": 151409,
    "name": "Medical Scientific Inc    ( Taunton MA USA )",
    "country": "United States"
  },
  {
    "id": 178329,
    "name": "Medical Services of America Inc    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 462686,
    "name": "Medical Simulation Corporation    ( Denver  USA )",
    "country": "United States"
  },
  {
    "id": 103504,
    "name": "Medical Sky Hook Co    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 186622,
    "name": "Medical SNI Ltd    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 460579,
    "name": "Medical Software Solutions GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 452512,
    "name": "Medical Solutions Inc    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 409702,
    "name": "Medical Specialists Co/IMX    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 305630,
    "name": "Medical Specialties Australia Pty Ltd    ( Willoughby  Australia )",
    "country": "Australia"
  },
  {
    "id": 106319,
    "name": "Medical Specialties Distributors LLC    ( Stoughton MA USA )",
    "country": "United States"
  },
  {
    "id": 105081,
    "name": "Medical Specialties Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 462691,
    "name": "Medical Staffing Solutions    ( Rice Lake WI USA )",
    "country": "United States"
  },
  {
    "id": 455201,
    "name": "Medical Supplies & Equipment Co    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 457883,
    "name": "Medical Supply Co Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 456039,
    "name": "Medical Supply Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455631,
    "name": "Medical Support Products Inc    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 186115,
    "name": "Medical Systems Inc    ( Paola KS USA )",
    "country": "United States"
  },
  {
    "id": 454764,
    "name": "Medical Technique Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 375286,
    "name": "Medical Techniques Inc    ( North Salt Lake UT USA )",
    "country": "United States"
  },
  {
    "id": 455176,
    "name": "Medical Technology Industries    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 105728,
    "name": "Medical Technology Products Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 104878,
    "name": "Nidek Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 186119,
    "name": "Medical Tronik Ltd    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 186120,
    "name": "Medical Video Systems Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 151106,
    "name": "Medical Wire & Equipment Co Ltd    ( Corsham  England )",
    "country": "UK"
  },
  {
    "id": 154174,
    "name": "Medical-Latex (DUA) Sdn Bhd    ( Senai  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 372417,
    "name": "MedicalCV Inc    ( Inver Grove Heights MN USA )",
    "country": "United States"
  },
  {
    "id": 103501,
    "name": "MedicAlert Foundation International    ( Turlock CA USA )",
    "country": "United States"
  },
  {
    "id": 466702,
    "name": "Medicaleshop    ( New Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 453681,
    "name": "Medicalex-Francemed    ( Bagneux  France )",
    "country": "France"
  },
  {
    "id": 107633,
    "name": "Medicalibration Inc    ( Ripon CA USA )",
    "country": "United States"
  },
  {
    "id": 281833,
    "name": "Medicamat SA    ( Malakoff  France )",
    "country": "France"
  },
  {
    "id": 395037,
    "name": "Medicana Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 440016,
    "name": "Medicana USA    ( Danville CA USA )",
    "country": "United States"
  },
  {
    "id": 106829,
    "name": "Medicanix Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 159806,
    "name": "Medicap Medizintechnik GmbH    ( Ulrichstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 457392,
    "name": "MediCapture    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 460909,
    "name": "Medicare Colgate Ltd    ( Cullompton  England )",
    "country": "UK"
  },
  {
    "id": 325785,
    "name": "Medicare Hospital Equipment cc    ( Stanton  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 457380,
    "name": "MediCath    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 458120,
    "name": "Medicel AG    ( Wolfhalden  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 329553,
    "name": "MEDIchair Ltd    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 451691,
    "name": "Medicina Ltd    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 415430,
    "name": "Medicine Devices GmbH & Co KG    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 460577,
    "name": "Medicity    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 401560,
    "name": "MediClick    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 401111,
    "name": "Medico Partner    ( Schoenbrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 454202,
    "name": "Medico SpA    ( Rubano (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 227239,
    "name": "Medico-Technique SA    ( Neuchatel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 332062,
    "name": "Medico's Hirata Inc    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 456556,
    "name": "Medicom Asia Ltd    ( Chai Wan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451240,
    "name": "Medicom Corp    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 376011,
    "name": "Medicom Healthcare bv    ( Woudenberg  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 331216,
    "name": "Medicom USA    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 160810,
    "name": "Medicomp Elektronische und Computertechnische Syst    ( Garching bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 104421,
    "name": "Medicomp Inc    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 137906,
    "name": "Medicon eG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 150527,
    "name": "Medicon Hellas SA    ( Athinai (Gerakas)  Greece )",
    "country": "Greece"
  },
  {
    "id": 417542,
    "name": "Medicon Inc    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 457043,
    "name": "MEDIconet Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 146914,
    "name": "MEDiCooL Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 279826,
    "name": "OFA Bahmberg Otto Frankhaenel & Sohn GmbH    ( Bamberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283143,
    "name": "Medicoplast Klaus Loritz GmbH    ( Illingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 193382,
    "name": "Medicor AG    ( Cham  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 277820,
    "name": "Medicor Elektronika Rt    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 347077,
    "name": "Medicor Mutotechnika Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 150025,
    "name": "Medicor Rontgen Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 140228,
    "name": "Medicor Trading Co Ltd    ( Budapest 62  USA )",
    "country": "United States"
  },
  {
    "id": 390934,
    "name": "Medicore Inc    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 272827,
    "name": "Medicorp Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 293861,
    "name": "Medicorp Pole Technologique de Brabois    ( Villers les Nancy  USA )",
    "country": "United States"
  },
  {
    "id": 279733,
    "name": "Medicosys GmbH    ( Reichenwalde  Germany )",
    "country": "Germany"
  },
  {
    "id": 179076,
    "name": "Medicotest A/S (Denmark)    ( Olstykke  USA )",
    "country": "United States"
  },
  {
    "id": 401074,
    "name": "Medicotest Espana SL    ( Madrid  USA )",
    "country": "United States"
  },
  {
    "id": 401078,
    "name": "Medicotest GmbH    ( Andernach  USA )",
    "country": "United States"
  },
  {
    "id": 401077,
    "name": "Medicotest Sarl    ( Suresnes  USA )",
    "country": "United States"
  },
  {
    "id": 401075,
    "name": "Medicotest Sdn Bhd    ( Penang  USA )",
    "country": "United States"
  },
  {
    "id": 401076,
    "name": "Medicotest srl    ( Vignate MI  USA )",
    "country": "United States"
  },
  {
    "id": 401073,
    "name": "Medicotest UK Ltd    ( St Ives  USA )",
    "country": "United States"
  },
  {
    "id": 453700,
    "name": "Medicraft Hill-Rom Australia Pty Ltd    ( Marrickville  Australia )",
    "country": "Australia"
  },
  {
    "id": 415969,
    "name": "Medicraft Pty Ltd    ( Marrickville  USA )",
    "country": "United States"
  },
  {
    "id": 457324,
    "name": "Medicrea International    ( Neyron  France )",
    "country": "France"
  },
  {
    "id": 457901,
    "name": "Medicsight plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 391908,
    "name": "Medicus    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 457626,
    "name": "Medicus Health    ( Kentwood MI USA )",
    "country": "United States"
  },
  {
    "id": 459257,
    "name": "MEDICUS Surgical    ( Danville CA USA )",
    "country": "United States"
  },
  {
    "id": 333854,
    "name": "MedicWare Inc    ( Irwindale CA USA )",
    "country": "United States"
  },
  {
    "id": 224631,
    "name": "Medidas Co Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 456637,
    "name": "medidenta.com    ( Woodside NY USA )",
    "country": "United States"
  },
  {
    "id": 281537,
    "name": "medifa hesse GmbH & Co KG    ( Finnentrop  Germany )",
    "country": "Germany"
  },
  {
    "id": 356939,
    "name": "Mediface Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457276,
    "name": "Medifit Systems    ( Maarn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 185140,
    "name": "Medifix    ( Oak Park MI USA )",
    "country": "United States"
  },
  {
    "id": 408221,
    "name": "MediFix Inc    ( Morton Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 161990,
    "name": "Mediflex    ( Islandia NY USA )",
    "country": "United States"
  },
  {
    "id": 456839,
    "name": "Mediflex Surgical Products    ( Islandia NY USA )",
    "country": "United States"
  },
  {
    "id": 325089,
    "name": "Mediflow Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 399275,
    "name": "MediFriend Medical Products Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 170518,
    "name": "Medigas Service & Testing Co Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 456040,
    "name": "Medigate Inc    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 462795,
    "name": "Medigen Biotechnology Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 322403,
    "name": "Medigloves Ltd    ( Pathumtani  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 148786,
    "name": "Medigroup Inc Div Janin Group Inc    ( Oswego IL USA )",
    "country": "United States"
  },
  {
    "id": 455817,
    "name": "Medigus Ltd    ( Omer  Israel )",
    "country": "Israel"
  },
  {
    "id": 452802,
    "name": "Medihale Ltd    ( Savion  Israel )",
    "country": "Israel"
  },
  {
    "id": 456014,
    "name": "MEDIHIGHTEC MEDICAL Co Ltd    ( Keelung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 357501,
    "name": "Medikmark Inc    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 434189,
    "name": "Medikro GmbH    ( Wesel  Germany )",
    "country": "Germany"
  },
  {
    "id": 162450,
    "name": "Medikro Oy    ( Kuopio  Finland )",
    "country": "Finland"
  },
  {
    "id": 402488,
    "name": "Medikro USA    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 306356,
    "name": "Medilab GmbH & Co    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 226618,
    "name": "Mediland Enterprise Corp    ( Taoyuan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 293366,
    "name": "Medilas AG    ( Geroldswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 280249,
    "name": "Mediline    ( Betzenweiler  Germany )",
    "country": "Germany"
  },
  {
    "id": 292907,
    "name": "Medilink    ( Mauguio  France )",
    "country": "France"
  },
  {
    "id": 451823,
    "name": "MediLogistics Inc    ( Sugar Land TX USA )",
    "country": "United States"
  },
  {
    "id": 284358,
    "name": "Medima GmbH    ( Maulburg  USA )",
    "country": "United States"
  },
  {
    "id": 440113,
    "name": "Medima Ltd    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 152713,
    "name": "MedImage Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 451461,
    "name": "MediMatic srl    ( Genova (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452505,
    "name": "Medimexico SA de RL de CV    ( Tijuana  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 457130,
    "name": "MediMizer    ( Encinitas CA USA )",
    "country": "United States"
  },
  {
    "id": 229069,
    "name": "Medimop Medical Projects Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 439166,
    "name": "Medimpex United Inc    ( Bensalem PA USA )",
    "country": "United States"
  },
  {
    "id": 293214,
    "name": "Medimprax GmbH    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 467390,
    "name": "MEDIN as    ( Nove Mesto na Morave  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 101489,
    "name": "Medin Corp    ( Passaic NJ USA )",
    "country": "United States"
  },
  {
    "id": 293450,
    "name": "Medinale AG    ( Agno  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 356637,
    "name": "MediNet Management Services Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 333850,
    "name": "MedInformatix Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 451712,
    "name": "Medinord    ( Locate di Triulzi (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 391154,
    "name": "MEDINORM Medizintechnik GmbH    ( Spiesen-Elversberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456887,
    "name": "medInt Holdings LLC    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 415960,
    "name": "Medinus Co Ltd    ( Yongin  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 467073,
    "name": "Medinvents NV    ( Hasselt  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 455703,
    "name": "Medion Grifols Diagnostics AG    ( Dudingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105277,
    "name": "Medionics International Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 338467,
    "name": "Medionics International Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 286114,
    "name": "Medipan Diagnostica Entwicklungs- und Vertriebs Gm    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 462987,
    "name": "Medipan GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 456456,
    "name": "Mediphacos Ltda    ( Minas Gerais-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 456726,
    "name": "Medipharchem Nederland bv    ( Wormerveer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 226619,
    "name": "Mediplus Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 101490,
    "name": "Medipoint Inc    ( Mineola NY USA )",
    "country": "United States"
  },
  {
    "id": 377768,
    "name": "Mediport Kardiotechnik GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 151024,
    "name": "Mediprema SA    ( Tauxigny  France )",
    "country": "France"
  },
  {
    "id": 106056,
    "name": "MEDIQ    ( Pennsauken NJ USA )",
    "country": "United States"
  },
  {
    "id": 460578,
    "name": "MediQuant Inc    ( Brecksville OH USA )",
    "country": "United States"
  },
  {
    "id": 450769,
    "name": "Medique Products    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 457139,
    "name": "Mediquip Parts Plus Inc    ( High Ridge MO USA )",
    "country": "United States"
  },
  {
    "id": 272600,
    "name": "Mediquipe Scandinavia AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 460954,
    "name": "MediRegs    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 104266,
    "name": "Medirex Systems Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456044,
    "name": "Medirite Australia Pty Ltd    ( Kings Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 287831,
    "name": "Mediroll Medico-technical Ltd    ( Debrecen  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 457738,
    "name": "MediRox AB    ( Nykoping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 459047,
    "name": "Medis Medical Imaging Systems Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 172842,
    "name": "Medis Medizinische Messtechnik GmbH    ( Ilmenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 186650,
    "name": "Medis srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 172296,
    "name": "Medis-El Ltd    ( Lod  Israel )",
    "country": "Israel"
  },
  {
    "id": 373298,
    "name": "Medisafe America LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 157006,
    "name": "Medisafe UK Ltd    ( Bishop's Stortford  England )",
    "country": "UK"
  },
  {
    "id": 152543,
    "name": "Medisan    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 281788,
    "name": "Medisan-Reha GmbH Medizinische    ( Freilassing  Germany )",
    "country": "Germany"
  },
  {
    "id": 416609,
    "name": "Medisana (France)    ( Ruaudin  USA )",
    "country": "United States"
  },
  {
    "id": 416614,
    "name": "Medisana (Japan)    ( Yokohama  USA )",
    "country": "United States"
  },
  {
    "id": 416613,
    "name": "Medisana (Korea)    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 392780,
    "name": "Medisana AG    ( Hilden  Germany )",
    "country": "Germany"
  },
  {
    "id": 416607,
    "name": "Medisana Benelux NV    ( Kerkrade  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416612,
    "name": "Medisana Far East Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416608,
    "name": "Medisana Healthcare SL    ( Terrassa (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 416611,
    "name": "Medisana Healthcare UK Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 416605,
    "name": "Medisana Hellas    ( Athinia  Greece )",
    "country": "Greece"
  },
  {
    "id": 416610,
    "name": "Medisana Poland    ( Torun  USA )",
    "country": "United States"
  },
  {
    "id": 359491,
    "name": "Medisana USA Inc    ( Glenwillow OH USA )",
    "country": "United States"
  },
  {
    "id": 306624,
    "name": "Mediseam Oy    ( Hollola  Finland )",
    "country": "Finland"
  },
  {
    "id": 263081,
    "name": "MediServe Information Systems    ( Chandler AZ USA )",
    "country": "United States"
  },
  {
    "id": 451274,
    "name": "Medisim Ltd    ( Neve Ilan  Israel )",
    "country": "Israel"
  },
  {
    "id": 455818,
    "name": "Medisim USA    ( Holly Springs NC USA )",
    "country": "United States"
  },
  {
    "id": 457984,
    "name": "MEDISISS    ( Redmond OR USA )",
    "country": "United States"
  },
  {
    "id": 293915,
    "name": "Medisize bv    ( Hillegom  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454077,
    "name": "Medisize CZ    ( Trhove Sviny  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 454076,
    "name": "Medisize Finland    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 438876,
    "name": "Medisize Ireland Ltd    ( Letterkenny  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 186785,
    "name": "Mediso Ltd    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 450596,
    "name": "MediSoft China Ltd    ( Hong Kong  USA )",
    "country": "United States"
  },
  {
    "id": 177668,
    "name": "MediSoft SA    ( Sorinnes  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 273742,
    "name": "MediSolution Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460927,
    "name": "Medisolv Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 155965,
    "name": "Medison America Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 146952,
    "name": "Medison Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 440705,
    "name": "Medison Do Brasil Ltda    ( San Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 451323,
    "name": "Medison Europe (The Netherlands)    ( Hoofddorp  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 354554,
    "name": "Medison Japan Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 440706,
    "name": "Medison Medical Systems India Pvt Ltd    ( New Dehli  India )",
    "country": "India"
  },
  {
    "id": 157526,
    "name": "Medisp Produtos Hospitalares SA (Sweden)    ( Borlange  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 151746,
    "name": "Medispec Ltd    ( Germantown MD USA )",
    "country": "United States"
  },
  {
    "id": 171726,
    "name": "Medispec Ltd    ( Germantown MD USA )",
    "country": "United States"
  },
  {
    "id": 451109,
    "name": "Medispec Medical Services (MMS) GmbH    ( Taunusstein/Hahn  USA )",
    "country": "United States"
  },
  {
    "id": 439574,
    "name": "Medispec Medical Services GmbH    ( Taunusstein/Hahn  USA )",
    "country": "United States"
  },
  {
    "id": 456067,
    "name": "Medisporex (Ptv) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 282715,
    "name": "Medistar Praxiscomputer GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 457228,
    "name": "Medistim ASA    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 457231,
    "name": "MediStim Norge AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 451871,
    "name": "MediSURG Research & Management Corp    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 454589,
    "name": "MediSYS Inc    ( Gardendale AL USA )",
    "country": "United States"
  },
  {
    "id": 159804,
    "name": "MediSyst    ( Rheinstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 159810,
    "name": "MediSyst Medical & Research Diagnostic Computer Sy    ( Linden 2  Germany )",
    "country": "Germany"
  },
  {
    "id": 106639,
    "name": "Medisystems Corp    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 452125,
    "name": "Meditab Software Inc    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 159809,
    "name": "Medite GmbH    ( Burgdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 454051,
    "name": "Medite GmbH (Austria)    ( Salzburg  Austria )",
    "country": "Austria"
  },
  {
    "id": 454050,
    "name": "Medite Inc    ( Winter Garden FL USA )",
    "country": "United States"
  },
  {
    "id": 454052,
    "name": "Medite Srl    ( Peschiera del garda (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 272603,
    "name": "Meditec Corporation sro    ( Praha 4  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 451683,
    "name": "Meditech    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 102984,
    "name": "Meditech - Medical Information Technology Inc    ( Westwood MA USA )",
    "country": "United States"
  },
  {
    "id": 455733,
    "name": "Meditech (Asia) Co Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454688,
    "name": "MediTech India    ( Mumbia  India )",
    "country": "India"
  },
  {
    "id": 454629,
    "name": "Meditech International Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 277797,
    "name": "Meditech Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 282234,
    "name": "Meditech Roentgensysteme GmbH    ( Guetersloh  Germany )",
    "country": "Germany"
  },
  {
    "id": 307198,
    "name": "Meditech SA - Medical Information Technology SA (P    ( Halfway House  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 358325,
    "name": "Meditech Systems Ltd    ( Shaftesbury  England )",
    "country": "UK"
  },
  {
    "id": 283116,
    "name": "Meditechnik GmbH & Co KG Bade- und Hilfssysteme    ( Huettenberg/Rechtenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 371342,
    "name": "MeditechUSA    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 377205,
    "name": "Mediteck Ltd    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 457752,
    "name": "Meditek-hemco    ( Cape Town  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 442103,
    "name": "Meditel Ingenieria Medica    ( Zaragoza  Spain )",
    "country": "Spain"
  },
  {
    "id": 293801,
    "name": "Meditex Hinsen AG    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 162985,
    "name": "Meditom    ( Tel Aviv  USA )",
    "country": "United States"
  },
  {
    "id": 455819,
    "name": "MediTouch Ltd    ( Netanya  Israel )",
    "country": "Israel"
  },
  {
    "id": 172297,
    "name": "Meditrac Medical Equipment Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 459474,
    "name": "MediTract    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 293667,
    "name": "Meditrade AG    ( Uster  USA )",
    "country": "United States"
  },
  {
    "id": 285285,
    "name": "MediTrade Medical Trading GmbH    ( Oyten  Germany )",
    "country": "Germany"
  },
  {
    "id": 283417,
    "name": "Meditrade Roesner-Mautby GmbH & Co    ( Kiefersfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 174407,
    "name": "Meditron Eletromedicina Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 451493,
    "name": "Medivance Europe    ( Seeheim Jugenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 451492,
    "name": "Medivance Inc    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 452144,
    "name": "Medivator Reprocessing Systems    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 457003,
    "name": "Medivators    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 457058,
    "name": "Medivators Asia/Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457059,
    "name": "Medivators bv    ( Heerlen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457057,
    "name": "Medivators Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 308445,
    "name": "MEDIVATORS-CANTEL MEDCAL CORP    (   USA )",
    "country": "United States"
  },
  {
    "id": 372201,
    "name": "Medivent Ltd    ( Lucan  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 332918,
    "name": "MediVision Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 338477,
    "name": "MediVision Medical Imaging Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 184899,
    "name": "Mediware Information Systems Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 106682,
    "name": "Mediwatch    ( Rugby CV21 1QN USA )",
    "country": "United States"
  },
  {
    "id": 454777,
    "name": "Mediwatch plc    ( Rugby  England )",
    "country": "UK"
  },
  {
    "id": 454774,
    "name": "Mediwatch USA    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 367362,
    "name": "Mediwest Denmark ApS    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 367392,
    "name": "Mediwest Norway A/S    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 452106,
    "name": "Mediwest Norway A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 391818,
    "name": "Mediwest Scandinavia AB    ( Vastra Frolunda  USA )",
    "country": "United States"
  },
  {
    "id": 273259,
    "name": "Mediworld Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 471637,
    "name": "Medix Biochemica    ( Kauniainen  Finland )",
    "country": "Finland"
  },
  {
    "id": 155632,
    "name": "Medix Choice    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 151495,
    "name": "Medix GmbH    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 157008,
    "name": "Medix Industrial y Comercial SA    ( El Talar  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 458625,
    "name": "Medix Specialty Vehicles Inc    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 184098,
    "name": "Medizer SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 173416,
    "name": "Medizin Elektronik Lueneburg KG    ( Lueneburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286583,
    "name": "Medizin Technische Werke Thale GmbH    ( Thale  Germany )",
    "country": "Germany"
  },
  {
    "id": 287620,
    "name": "Medizinisches Einrichtungs-Zentrum    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286599,
    "name": "Medizinmoebel GmbH Torgelow    ( Torgelow  Germany )",
    "country": "Germany"
  },
  {
    "id": 282887,
    "name": "Medizintechnik Bergstrasse GmbH Extrakorporale Sys    ( Heppenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 280875,
    "name": "Medizintechnik Dipl -Ing Heise Vertriebs-GmbH    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 458667,
    "name": "Medizintechnik Gulden eK    ( Modauta  Germany )",
    "country": "Germany"
  },
  {
    "id": 279568,
    "name": "Medizintechnik-Fachhandel Gisela Ewe    ( Aschersleben  Germany )",
    "country": "Germany"
  },
  {
    "id": 338611,
    "name": "Medjet Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 460874,
    "name": "MedKeeper    ( Westminster CO USA )",
    "country": "United States"
  },
  {
    "id": 452430,
    "name": "Medkinetics LLC    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 999572,
    "name": "Medkofysio (Finland)    (   USA )",
    "country": "United States"
  },
  {
    "id": 306293,
    "name": "Medlab Medizinische Diagnosegeraete GmbH    ( Stutensee  Germany )",
    "country": "Germany"
  },
  {
    "id": 140162,
    "name": "MedLab Products Sp zo o    ( Raszyn  Poland )",
    "country": "Poland"
  },
  {
    "id": 103625,
    "name": "Medline Industries Inc    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 417395,
    "name": "Medlink Imaging Inc    ( Teterboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 454590,
    "name": "MedLink International Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 452833,
    "name": "Medlinq Softwaresyteme GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 403378,
    "name": "MEDLOG Systems    ( Crystal Bay NV USA )",
    "country": "United States"
  },
  {
    "id": 399105,
    "name": "MedLogic Global Corp    ( Plymouth  England )",
    "country": "UK"
  },
  {
    "id": 461495,
    "name": "MedManagement LLC    ( Vestavia Hills AL USA )",
    "country": "United States"
  },
  {
    "id": 465013,
    "name": "MedMarc Co    ( Euless TX USA )",
    "country": "United States"
  },
  {
    "id": 451356,
    "name": "MedMark Technologies LLC    ( Perkasie PA USA )",
    "country": "United States"
  },
  {
    "id": 107820,
    "name": "MEDmetric Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 444440,
    "name": "MedMira (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 444439,
    "name": "MedMira Inc    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 454237,
    "name": "Medmont International Pty Ltd    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 398217,
    "name": "Mednet Healthcare Technologies Inc    ( Ewing NJ USA )",
    "country": "United States"
  },
  {
    "id": 332737,
    "name": "MedNet Locator Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 454591,
    "name": "MedNet System    ( Webster MA USA )",
    "country": "United States"
  },
  {
    "id": 464998,
    "name": "MedNetwoRx    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 267695,
    "name": "Mednosbro    ( Mellingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 463016,
    "name": "MEDNOVO Medical Software Solutions GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 453535,
    "name": "Mednovus Inc    ( Leucadia CA USA )",
    "country": "United States"
  },
  {
    "id": 105085,
    "name": "Medo USA Inc    ( Hanover Park IL USA )",
    "country": "United States"
  },
  {
    "id": 266960,
    "name": "Medoc Ltd    ( Ramat Yishai  Israel )",
    "country": "Israel"
  },
  {
    "id": 461217,
    "name": "MedOne Healthcare    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 456736,
    "name": "MedOne Surgical Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 457767,
    "name": "Medonica (Africa)    ( Canakry  Guinea )",
    "country": "Guinea"
  },
  {
    "id": 457768,
    "name": "Medonica (Central Asia & Caucasus)    ( Almaty  Kazakhstan )",
    "country": "Kazakhstan"
  },
  {
    "id": 457769,
    "name": "Medonica (Eastern Europe)    ( Kiev  Ukraine )",
    "country": "United Kingdomraine"
  },
  {
    "id": 457764,
    "name": "Medonica (Korea)    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457765,
    "name": "Medonica (Oceania)    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 457120,
    "name": "Medonica Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457770,
    "name": "Medonica Ltd (Russia)    ( Saint-Petersburg  Russia )",
    "country": "Russia"
  },
  {
    "id": 283809,
    "name": "Medos AG    ( Langenselbold  USA )",
    "country": "United States"
  },
  {
    "id": 415992,
    "name": "Medos Co Pty Ltd    ( Mount Waverley  USA )",
    "country": "United States"
  },
  {
    "id": 464965,
    "name": "Medos Healthcare Solutions    ( Chelsea AL USA )",
    "country": "United States"
  },
  {
    "id": 454172,
    "name": "MEDOS Medizintechnik AG    ( Stolberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 104270,
    "name": "Medovations Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 292047,
    "name": "Medpacs Displays Inc    ( Hartland WI USA )",
    "country": "United States"
  },
  {
    "id": 457229,
    "name": "MedPark Co    ( Kimhae  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454593,
    "name": "MedPlexus Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 288629,
    "name": "MedPlus Inc    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 456101,
    "name": "Medpro Corp Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 332340,
    "name": "MEDPRO GmbH    ( Luedersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 450636,
    "name": "MEDPRO Midwest Group    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 175848,
    "name": "MedProtect Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 453384,
    "name": "MedQuip Inc    ( Blufferton SC USA )",
    "country": "United States"
  },
  {
    "id": 413111,
    "name": "MedQuist Inc    ( Mount Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 372150,
    "name": "Medrad (Egypt)    ( Cairo  USA )",
    "country": "United States"
  },
  {
    "id": 342080,
    "name": "Medrad Do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 152536,
    "name": "Medrad Europe bv    ( Beek  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 342077,
    "name": "Medrad France Sarl    ( Rungis Cedex  France )",
    "country": "France"
  },
  {
    "id": 105381,
    "name": "Medrad Inc    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 184519,
    "name": "Medrad Inc (Asia)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452078,
    "name": "Medrad Inc (Middle East and Africa)    ( Nicosia  Cyprus )",
    "country": "Cyprus"
  },
  {
    "id": 454122,
    "name": "Medrad Interventional/Possis    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 342079,
    "name": "Medrad Italia srl    ( Cava Manara (PV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 342078,
    "name": "Medrad Medizinische Systeme GmbH    ( Volkach  Germany )",
    "country": "Germany"
  },
  {
    "id": 372149,
    "name": "Medrad Mexicana S de RL de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 457140,
    "name": "MEDRAD Multi Vendor Service    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 452115,
    "name": "Medrad Sweden    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 342082,
    "name": "Medrad UK Ltd    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 106242,
    "name": "Medrecon Inc    ( Garwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 452623,
    "name": "Medrecon Inc    ( Garwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 185740,
    "name": "MedResource INc    ( Piedmont AL USA )",
    "country": "United States"
  },
  {
    "id": 325094,
    "name": "MedRite International Inc    ( Fairfield CT USA )",
    "country": "United States"
  },
  {
    "id": 107358,
    "name": "Medro Systems Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 455666,
    "name": "MedRx    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 999904,
    "name": "MedRx Inc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 173702,
    "name": "Medsed    ( Trekker  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 459070,
    "name": "MEDSEEK    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 282500,
    "name": "Medset Medizintechnik GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457868,
    "name": "MedShape Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 459276,
    "name": "MedShape Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 459057,
    "name": "MedSim Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 277769,
    "name": "Medson Rep e Imp de Equip Biomedicos Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 458144,
    "name": "MedSonics US Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 457407,
    "name": "MedSource International LLC    ( Mound MN USA )",
    "country": "United States"
  },
  {
    "id": 441040,
    "name": "MedSource LLC    ( Bloomington IL USA )",
    "country": "United States"
  },
  {
    "id": 331737,
    "name": "MedSource Southwest    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 462981,
    "name": "MedSpeed LLC    ( Elmhurst IL USA )",
    "country": "United States"
  },
  {
    "id": 456993,
    "name": "Medspira LLC    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 193937,
    "name": "Medstan Inc    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 429374,
    "name": "Medstat    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 312345,
    "name": "MedStent Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454680,
    "name": "Medstrat    ( Downers Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 458848,
    "name": "Medstreaming    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 458788,
    "name": "Medstrom Healthcare    ( Castle Donington  England )",
    "country": "UK"
  },
  {
    "id": 403669,
    "name": "Medsun Inc    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 454592,
    "name": "MedSym Inc    ( Tuscaloosa AL USA )",
    "country": "United States"
  },
  {
    "id": 455022,
    "name": "Medsys sa    ( Gembloux  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 306615,
    "name": "Medsystem GmbH    ( Bad Ems  Germany )",
    "country": "Germany"
  },
  {
    "id": 102065,
    "name": "Medtec Ambulance Corp    ( Goshen IN USA )",
    "country": "United States"
  },
  {
    "id": 281361,
    "name": "Medtec GmbH    ( Fuerth  Germany )",
    "country": "Germany"
  },
  {
    "id": 458184,
    "name": "Medtec Medical Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 137437,
    "name": "MedTech Biomedical Services    ( Sylvania OH USA )",
    "country": "United States"
  },
  {
    "id": 450502,
    "name": "Medtech Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 176902,
    "name": "Medtech International Inc    ( Altamonte Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 373914,
    "name": "Medtech Products Ltd    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 457975,
    "name": "Medtech SAS    ( Castelnau Le Lez  France )",
    "country": "France"
  },
  {
    "id": 457974,
    "name": "Medtech Surgical Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 456016,
    "name": "Medtecs International Corp Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 416407,
    "name": "Medtek Pty Ltd    ( Townsville  Australia )",
    "country": "Australia"
  },
  {
    "id": 395020,
    "name": "Medteq Innovations Pty Ltd    ( Springwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 471716,
    "name": "MedTest DX    ( Canton MI USA )",
    "country": "United States"
  },
  {
    "id": 453644,
    "name": "Medtox Diagnostics    ( Burlingron NC USA )",
    "country": "United States"
  },
  {
    "id": 454697,
    "name": "MEDTOX Scientific Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 458722,
    "name": "MedTrack VNG Inc    ( Henderson NV USA )",
    "country": "United States"
  },
  {
    "id": 454852,
    "name": "Medtrica Solutions    ( Langley BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454851,
    "name": "Medtrica Solutions (US)    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 331187,
    "name": "Medtrol Inc    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 285910,
    "name": "Medtron AG    ( Saarbruecken  Germany )",
    "country": "Germany"
  },
  {
    "id": 455507,
    "name": "Medtronic (Schweiz) AG    ( Muenchenbuchsee  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 354575,
    "name": "Medtronic A/S    ( Koebenhavn S  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453730,
    "name": "Medtronic AB    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 462943,
    "name": "Medtronic Advanced Energy    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 225960,
    "name": "Medtronic Australasia Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 360365,
    "name": "Medtronic AVE Div Medtronic Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 372314,
    "name": "Medtronic Cardiac Surgical Products    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 457971,
    "name": "Medtronic Coronary    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 453733,
    "name": "Medtronic Diabetes (Benelux)    ( Heerlen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453735,
    "name": "Medtronic Diabetes Europe SA    ( Tolochenaz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453737,
    "name": "Medtronic Diabetes of Canada    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453736,
    "name": "Medtronic Diabetes USA    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 440781,
    "name": "Medtronic Emergency Response Systems    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 440800,
    "name": "Medtronic Emergency Response Systems (Asia Pacific    ( Christchurch 1  USA )",
    "country": "United States"
  },
  {
    "id": 440799,
    "name": "Medtronic Emergency Response Systems (Austria)    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 440798,
    "name": "Medtronic Emergency Response Systems (China)    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 440797,
    "name": "Medtronic Emergency Response Systems (Czech Republ    ( Praha 6  USA )",
    "country": "United States"
  },
  {
    "id": 440796,
    "name": "Medtronic Emergency Response Systems (France)    ( Boulogne-Billancourt Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 440787,
    "name": "Medtronic Emergency Response Systems (Germany)    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 440795,
    "name": "Medtronic Emergency Response Systems (Hungary)    ( Budapest  USA )",
    "country": "United States"
  },
  {
    "id": 440794,
    "name": "Medtronic Emergency Response Systems (Italy)    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 440793,
    "name": "Medtronic Emergency Response Systems (Mediterranea    ( Beirut  USA )",
    "country": "United States"
  },
  {
    "id": 440792,
    "name": "Medtronic Emergency Response Systems (Netherlands)    ( Kerkrade  USA )",
    "country": "United States"
  },
  {
    "id": 440791,
    "name": "Medtronic Emergency Response Systems (Poland)    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 440789,
    "name": "Medtronic Emergency Response Systems (Scandinavia)    ( Jarfalla  USA )",
    "country": "United States"
  },
  {
    "id": 440790,
    "name": "Medtronic Emergency Response Systems (Spain)    ( Alcobendas (Madrid)  USA )",
    "country": "United States"
  },
  {
    "id": 440786,
    "name": "Medtronic Emergency Response Systems (UK)    ( Watford  USA )",
    "country": "United States"
  },
  {
    "id": 440788,
    "name": "Medtronic Emergency Response Systems Europe    ( Tolochenaz  USA )",
    "country": "United States"
  },
  {
    "id": 451932,
    "name": "Medtronic ENT    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 364331,
    "name": "Medtronic EP Systems    ( Mounds View MN USA )",
    "country": "United States"
  },
  {
    "id": 176893,
    "name": "Medtronic Europe Sarl    ( Tolochenaz  USA )",
    "country": "United States"
  },
  {
    "id": 453738,
    "name": "Medtronic Finland Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 151025,
    "name": "Medtronic France SAS    ( Boulogne-Billancourt Cedex  France )",
    "country": "France"
  },
  {
    "id": 281092,
    "name": "Medtronic GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 453734,
    "name": "Medtronic GmbH    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 101809,
    "name": "Medtronic Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 105368,
    "name": "Medtronic Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 377488,
    "name": "Medtronic Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 398065,
    "name": "Medtronic Inc    ( Mounds View MN USA )",
    "country": "United States"
  },
  {
    "id": 426208,
    "name": "Medtronic Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 440784,
    "name": "Medtronic Inc    ( Ft Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 242655,
    "name": "Medtronic International Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 453715,
    "name": "Medtronic International Trading Sarl    ( Tolochenaz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455575,
    "name": "Medtronic Italy SpA    ( Sesto San Giovanni (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 176894,
    "name": "Medtronic Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455052,
    "name": "Medtronic Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453732,
    "name": "Medtronic Ltd    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 455576,
    "name": "Medtronic Mediterranean SAL    ( Beirut  Lebanon )",
    "country": "Lebanon"
  },
  {
    "id": 399035,
    "name": "Medtronic MiniMed    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 399046,
    "name": "Medtronic MiniMed (Benelux)    ( Almere  USA )",
    "country": "United States"
  },
  {
    "id": 399044,
    "name": "Medtronic MiniMed (Canada)    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 399048,
    "name": "Medtronic MiniMed (UK)    ( Ashtead  USA )",
    "country": "United States"
  },
  {
    "id": 399045,
    "name": "Medtronic MiniMed Europe SA    ( Bruxelles  USA )",
    "country": "United States"
  },
  {
    "id": 399040,
    "name": "Medtronic MiniMed GmbH    ( Mainz  USA )",
    "country": "United States"
  },
  {
    "id": 399047,
    "name": "Medtronic MiniMed Nordic AB (Denmark)    ( Glostrup  USA )",
    "country": "United States"
  },
  {
    "id": 399049,
    "name": "Medtronic MiniMed Nordic AB (Finland)    ( Vantaa  USA )",
    "country": "United States"
  },
  {
    "id": 399041,
    "name": "Medtronic MiniMed Nordic AB (Sweden)    ( Lund  USA )",
    "country": "United States"
  },
  {
    "id": 399051,
    "name": "Medtronic MiniMed Pty Ltd    ( West Chatswood  USA )",
    "country": "United States"
  },
  {
    "id": 399039,
    "name": "Medtronic MiniMed SA    ( Levallois-Perret  USA )",
    "country": "United States"
  },
  {
    "id": 399052,
    "name": "Medtronic Navigation Inc    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 455577,
    "name": "Medtronic Nederland    ( Heerlen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 394710,
    "name": "Medtronic Neurosurgery    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 105086,
    "name": "Medtronic of Canada Ltd    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 440845,
    "name": "Medtronic Paceart    ( Mounds View MN USA )",
    "country": "United States"
  },
  {
    "id": 359988,
    "name": "Medtronic Perfusion Systems    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 455578,
    "name": "Medtronic Poland Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 372300,
    "name": "Medtronic Sofamor Danek    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 354523,
    "name": "Medtronic Synectics Asia Ltd    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 440785,
    "name": "Medtronic Vascular    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 372181,
    "name": "Medtronic Xomed Surgical Products Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 452173,
    "name": "Medtuity Inc    ( Westerville OH USA )",
    "country": "United States"
  },
  {
    "id": 455738,
    "name": "Medu-Scientific Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 466653,
    "name": "Medusa Medical Technologies Inc    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 172387,
    "name": "Medutek GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459277,
    "name": "MedVantage Inc    ( Marywood NJ USA )",
    "country": "United States"
  },
  {
    "id": 460580,
    "name": "Medved Products Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 462801,
    "name": "MedVision Inc    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 334281,
    "name": "Medware    ( New Smyrna Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 333444,
    "name": "Medwave Inc    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 459141,
    "name": "Medwaves Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 325097,
    "name": "MedWay Corp    ( Amherst OH USA )",
    "country": "United States"
  },
  {
    "id": 250069,
    "name": "Medweb Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 999525,
    "name": "Medweigh    ( Linwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 455363,
    "name": "Medwork Medical Products and Services GmbH    ( Hoechstadt/Aisch  Germany )",
    "country": "Germany"
  },
  {
    "id": 162425,
    "name": "Medworks Instruments    ( Johnson City TN USA )",
    "country": "United States"
  },
  {
    "id": 458099,
    "name": "MedWurx    ( Lewis Center OH USA )",
    "country": "United States"
  },
  {
    "id": 460879,
    "name": "MedX Health    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 105518,
    "name": "MEDX Inc    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 107673,
    "name": "MedX of Gainesville    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 454117,
    "name": "MedXL Inc    ( Pointe Claire ON Canada )",
    "country": "Canada"
  },
  {
    "id": 306784,
    "name": "Medys GmbH    ( Wuelfrath  Germany )",
    "country": "Germany"
  },
  {
    "id": 356704,
    "name": "Meena Medical Equipment Inc    ( Colleyville TX USA )",
    "country": "United States"
  },
  {
    "id": 103507,
    "name": "Meese Orbitron Dunn Co    ( Saddle Brook NJ USA )",
    "country": "United States"
  },
  {
    "id": 462948,
    "name": "MEG International Services Ltd (MISL)    ( Coquitlam BC Canada )",
    "country": "Canada"
  },
  {
    "id": 152460,
    "name": "Mega Electronics Ltd    ( Kuopio  Finland )",
    "country": "Finland"
  },
  {
    "id": 439814,
    "name": "Mega Medical Co Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 285478,
    "name": "Megadenta GmbH    ( Limeshain  Germany )",
    "country": "Germany"
  },
  {
    "id": 283995,
    "name": "megadental GmbH    ( Buedingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 184531,
    "name": "MegaDyne Medical Products Inc    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 171243,
    "name": "Megamed AG    ( Cham  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 334282,
    "name": "Megas Corp    ( Tallahassee FL USA )",
    "country": "United States"
  },
  {
    "id": 416044,
    "name": "Megger Bahrain    ( Manama  Bahrain )",
    "country": "Bahrain"
  },
  {
    "id": 416043,
    "name": "Megger France    ( Le Raincy  France )",
    "country": "France"
  },
  {
    "id": 416042,
    "name": "Megger Ltd    ( Scarborough ON Canada )",
    "country": "Canada"
  },
  {
    "id": 416039,
    "name": "Megger Mexico    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 416041,
    "name": "Megger UK    ( Dover  England )",
    "country": "UK"
  },
  {
    "id": 409529,
    "name": "Megger US    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 157010,
    "name": "Mego AfeK Lympha Press    ( Kibbutz Afek  Israel )",
    "country": "Israel"
  },
  {
    "id": 159964,
    "name": "Megro GmbH & Co KG    ( Wesel  Germany )",
    "country": "Germany"
  },
  {
    "id": 339763,
    "name": "MEI Clovette    ( Sterling Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 431456,
    "name": "MEI Development Corp    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 293328,
    "name": "Meier-Zosso AG    ( Faellanden  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452832,
    "name": "Meierhofer AG    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 105845,
    "name": "Meiji Techno America    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 139466,
    "name": "Meiji Techno Co Ltd    ( Iruma  Japan )",
    "country": "Japan"
  },
  {
    "id": 392807,
    "name": "Meiji Techno UK Ltd    ( Axbridge  England )",
    "country": "UK"
  },
  {
    "id": 159991,
    "name": "Meiko Maschinenbau GmbH & Co    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283876,
    "name": "Meinhardt Ultraschalltechnik    ( Leipzig  Germany )",
    "country": "Germany"
  },
  {
    "id": 456042,
    "name": "MEINNTECH Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 159996,
    "name": "Meintrup-Labortechnik    ( Laehden-Holte  Germany )",
    "country": "Germany"
  },
  {
    "id": 148971,
    "name": "Meisei Electric Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 322494,
    "name": "Meisei Electrical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454507,
    "name": "Meisinger USA LLC    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 286466,
    "name": "Meissner & Wurst GmbH & Co    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 306298,
    "name": "Meisterhand Instrumente GmbH    ( Weilheim/Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455964,
    "name": "Meizhou Cornley Hi-Tech Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 237816,
    "name": "MEK & Assoc    ( Snellville GA USA )",
    "country": "United States"
  },
  {
    "id": 452533,
    "name": "Mekics Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454732,
    "name": "Mekos Laboratories ApS    ( Hillerod  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 999952,
    "name": "Mel Sobel Microscopes Ltd    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 458039,
    "name": "MELA Sciences Inc    ( Irvington NY USA )",
    "country": "United States"
  },
  {
    "id": 999767,
    "name": "MELA Sciences, Inc.    ( Irvington NY USA )",
    "country": "USA"
  },
  {
    "id": 293920,
    "name": "Melab GmbH    ( Leonberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 391387,
    "name": "Melag oHG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 281827,
    "name": "Melanie Safety-Hygiene GmbH    ( Friedland  Germany )",
    "country": "Germany"
  },
  {
    "id": 999924,
    "name": "Melco Engineering    (   USA )",
    "country": "United States"
  },
  {
    "id": 176395,
    "name": "Mellen Air Mfg Inc    ( Signal Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 279750,
    "name": "Mem-O-Matic GmbH    ( Bad Segeberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 375556,
    "name": "Membrana GmbH    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 286003,
    "name": "Memmert GmbH und Co KG    ( Schwabach  Germany )",
    "country": "Germany"
  },
  {
    "id": 102567,
    "name": "Menda    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 246586,
    "name": "Mendez Medical Products Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 177067,
    "name": "Menfis bioMedica srl    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 108977,
    "name": "Menicon America Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 454525,
    "name": "Menicon Holdings Europe    ( Clichy Cedex  France )",
    "country": "France"
  },
  {
    "id": 451123,
    "name": "Merge Healthcare Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 454524,
    "name": "Menicon Mandarin Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 454522,
    "name": "Menicon Pty Ltd    ( Parkside  Australia )",
    "country": "Australia"
  },
  {
    "id": 454523,
    "name": "Menicon UK Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 101498,
    "name": "Mennen Medical Corp    ( Feasterville-Trevose PA USA )",
    "country": "United States"
  },
  {
    "id": 171234,
    "name": "Mennen Medical Ltd    ( Rehovot  Israel )",
    "country": "Israel"
  },
  {
    "id": 457562,
    "name": "Menon Enterprises    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 157667,
    "name": "Menox AB    ( Goteborg  USA )",
    "country": "United States"
  },
  {
    "id": 459127,
    "name": "Mentice Inc    ( Evanston IL USA )",
    "country": "United States"
  },
  {
    "id": 362478,
    "name": "Mentor Benelux bv    ( Leiden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 202160,
    "name": "Mentor Corp    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 185617,
    "name": "Mentor Deutschland GmbH    ( Hallbergmoos  Germany )",
    "country": "Germany"
  },
  {
    "id": 408729,
    "name": "Mentor Medical Italia srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 362477,
    "name": "Mentor Medical Systems Australia Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 185619,
    "name": "Mentor Medical Systems Canada Inc    ( Oshawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 359861,
    "name": "Mentor Medical Systems France SA    ( Montigny-le-Bretonneux  France )",
    "country": "France"
  },
  {
    "id": 362479,
    "name": "Mentor Medical Systems Iberica SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 359862,
    "name": "Mentor Medical Systems Ltd UK    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 464973,
    "name": "Mentra Labs    ( Miami Lakes FL USA )",
    "country": "United States"
  },
  {
    "id": 452208,
    "name": "Mentzer Electronic GmbH    ( Woelfersheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457073,
    "name": "MEPS Inc    ( Gallatin TN USA )",
    "country": "United States"
  },
  {
    "id": 456314,
    "name": "Mercator Medical (Thailand) Ltd    ( Songkhla  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 451213,
    "name": "Mercedes Medical Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 459073,
    "name": "Mercer    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 460819,
    "name": "Merchandising Frontiers Inc    ( Winterset IA USA )",
    "country": "United States"
  },
  {
    "id": 457615,
    "name": "Mercian Surgical Supply Co Ltd    ( Bromsgrove  England )",
    "country": "UK"
  },
  {
    "id": 173445,
    "name": "Merck & Co Inc    ( Whitehouse Station NJ USA )",
    "country": "United States"
  },
  {
    "id": 329882,
    "name": "Merck Diagnostics    ( Jakarta Timur  USA )",
    "country": "United States"
  },
  {
    "id": 455582,
    "name": "Merck Frosst Canada Ltd    ( Kirkland PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 139268,
    "name": "Merck KGaA    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 454541,
    "name": "Merck Serono SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451791,
    "name": "Mercury Computer Systems Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 102449,
    "name": "Mercury Medical    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 452751,
    "name": "Merete Medical GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 454005,
    "name": "Merete Medical Inc    ( Rye NY USA )",
    "country": "United States"
  },
  {
    "id": 454006,
    "name": "Merete Medical Polska sp z o o    ( Wroclaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 333586,
    "name": "Meretek Diagnostics Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 451124,
    "name": "Merge eMed    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 418485,
    "name": "Merge eMed (Europe)    ( Nuenen  USA )",
    "country": "United States"
  },
  {
    "id": 454004,
    "name": "Merge Healthcare (Europe)    ( Nuenen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 456929,
    "name": "Merge Healthcare Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458060,
    "name": "Merge Healthcare China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454003,
    "name": "Merge Healthcare OEM Solutions    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 141597,
    "name": "Meriam Instrument    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 399278,
    "name": "Meridian Bioscience Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458051,
    "name": "Meridian Medical Systems LLC    ( Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 300799,
    "name": "Meridian Medical Technologies Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 392934,
    "name": "Meridian Medical Technologies Ltd    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 458998,
    "name": "Meridian Technique Ltd    ( Southampton  England )",
    "country": "UK"
  },
  {
    "id": 454597,
    "name": "meridianEMR Inc    ( Livingston NJ USA )",
    "country": "United States"
  },
  {
    "id": 186140,
    "name": "Merit Industries    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 458193,
    "name": "Merit Ltd    ( Blair Atholl  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 457968,
    "name": "Merit Medical EMEA    ( Maastricht-Airport  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 273281,
    "name": "Merit Medical Inc    ( Mesquite TX USA )",
    "country": "United States"
  },
  {
    "id": 108483,
    "name": "Merit Medical Systems Inc    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 459128,
    "name": "Meritech Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 458772,
    "name": "Meritline.com    ( Rowland Heights CA USA )",
    "country": "United States"
  },
  {
    "id": 190554,
    "name": "Merits Health Products Inc    ( Cape Coral FL USA )",
    "country": "United States"
  },
  {
    "id": 152547,
    "name": "Merivaara Corp    ( Lahti  Finland )",
    "country": "Finland"
  },
  {
    "id": 332056,
    "name": "Merk Electronic    ( Bad Salzuflen  USA )",
    "country": "United States"
  },
  {
    "id": 452363,
    "name": "Merlin Diagnostika GmbH    ( Bornheim-Hersel  Germany )",
    "country": "Germany"
  },
  {
    "id": 331740,
    "name": "Merlin Engineering Works Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 452893,
    "name": "Merlin Medical Ltd    ( Rhynmey  Wales )",
    "country": "Wales"
  },
  {
    "id": 454100,
    "name": "Merlyn Medical    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 459403,
    "name": "Merriam-Webster Inc    ( Springfield MA USA )",
    "country": "United States"
  },
  {
    "id": 458467,
    "name": "Merrithew Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 176881,
    "name": "Merry Walker Corp    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 428597,
    "name": "Merry X-Ray Cleveland    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 346967,
    "name": "Merry X-Ray Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 377882,
    "name": "Merry X-Ray Houston    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 348924,
    "name": "Merry X-Ray Virginia Beach    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 186141,
    "name": "Merss Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 466319,
    "name": "MERU    ( Epsom  England )",
    "country": "UK"
  },
  {
    "id": 459130,
    "name": "Meru Networks    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 281633,
    "name": "Merz & Co GmbH & Co    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 456092,
    "name": "Merz Aesthetics    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 384305,
    "name": "Merz Dental    ( Luetjenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 107405,
    "name": "MES Inc    ( Seguin TX USA )",
    "country": "United States"
  },
  {
    "id": 450923,
    "name": "MES Inc    ( Denton TX USA )",
    "country": "United States"
  },
  {
    "id": 176882,
    "name": "MESA Inc (Medical External Support Appliances Inc)    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 161819,
    "name": "Mesa Laboratories Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 279876,
    "name": "Mesa Medizintechnik GmbH    ( Benediktbeuern  Germany )",
    "country": "Germany"
  },
  {
    "id": 467269,
    "name": "Mesa Monitoring Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 374368,
    "name": "MeshTel Intelite Inc    ( Genoa NV USA )",
    "country": "United States"
  },
  {
    "id": 459062,
    "name": "Meso Scale Discovery    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 460872,
    "name": "MessageNet Systems    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 283695,
    "name": "Messer Griesheim GmbH    ( Krefeld  USA )",
    "country": "United States"
  },
  {
    "id": 293274,
    "name": "MESSER Schweisstechnik AG    ( Daellikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 357204,
    "name": "Mesure Technology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 460542,
    "name": "Mesynthes Ltd    ( Lower Hutt  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 452390,
    "name": "MeSys Medizinische Systeme    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 999763,
    "name": "Met One Instruments    (   USA )",
    "country": "United States"
  },
  {
    "id": 456375,
    "name": "Meta Biomed Co Ltd    ( Chungbuk  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 462886,
    "name": "Meta Dynamic Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 336847,
    "name": "Meta Health Technology Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 456771,
    "name": "Meta Imaging Solutions    ( Columbia MO USA )",
    "country": "United States"
  },
  {
    "id": 228398,
    "name": "Meta Pharmacy Systems    ( Floral Park NY USA )",
    "country": "United States"
  },
  {
    "id": 458893,
    "name": "Metafile Information Systems Inc    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 354571,
    "name": "Metafix (UK) Ltd    ( Northants  England )",
    "country": "UK"
  },
  {
    "id": 370349,
    "name": "Metafix Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 293924,
    "name": "Metal Cutting Corp    ( Cedar Grove NJ USA )",
    "country": "United States"
  },
  {
    "id": 431327,
    "name": "Metal-Fab Inc    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 103833,
    "name": "Metalcraft Inc    ( Mason City IA USA )",
    "country": "United States"
  },
  {
    "id": 431311,
    "name": "Metallica Mfg    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 285260,
    "name": "Metallspritztechnik Schrage GmbH    ( Osterholz-Scharmbeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 395297,
    "name": "Metals Recovery Services LLC    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 176850,
    "name": "Metaltronica srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 458477,
    "name": "Metasoft Systems Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 309272,
    "name": "Metasys Medizintechnik GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 458907,
    "name": "MetaSystems GmbH    ( Altlussheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458909,
    "name": "MetaSystems Group Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 287483,
    "name": "Metec Medizintechnik GmbH    ( Wahlstedt  USA )",
    "country": "United States"
  },
  {
    "id": 185452,
    "name": "Metech S C Produkcja Sprezetu Medycznego    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 283668,
    "name": "Metek Koeber & Michalski OHG    ( Koenigswartha  USA )",
    "country": "United States"
  },
  {
    "id": 306611,
    "name": "Meteka GmbH    ( Judenburg  Austria )",
    "country": "Austria"
  },
  {
    "id": 152685,
    "name": "Metertech Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999430,
    "name": "Methapharm, Inc.    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 306613,
    "name": "Methatec Ges fuer Praxisbedarf GmbH & Co KG    ( Neu-Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 457594,
    "name": "Metran Co Ltd    ( Kawaguchi  Japan )",
    "country": "Japan"
  },
  {
    "id": 455160,
    "name": "Metrasens Ltd (UK)    ( Malvern  England )",
    "country": "UK"
  },
  {
    "id": 455159,
    "name": "Metrasens Ltd (US)    ( Lemont IL USA )",
    "country": "United States"
  },
  {
    "id": 172393,
    "name": "Metrax GmbH    ( Rottweil  Germany )",
    "country": "Germany"
  },
  {
    "id": 128364,
    "name": "Metrex    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 400945,
    "name": "Metrika Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 329715,
    "name": "Metro Global Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 242774,
    "name": "Metro International Corp (The Netherlands)    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 179057,
    "name": "Metro International Corporation (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 331188,
    "name": "Metro Medical Supply Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 183923,
    "name": "Metro-Med Inc    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 162535,
    "name": "Metro-Medical Industries Inc    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 289533,
    "name": "METROHM Ltd    ( Herisau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453762,
    "name": "Metrohm USA    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 171714,
    "name": "Metrolab    ( Bernal  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 276652,
    "name": "Metrolab do Brasil    ( Piracicaba-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 182820,
    "name": "Metrolab Instruments SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456017,
    "name": "MetroMed Healthcare Co Ltd    ( Taoyuan Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 403409,
    "name": "Metron (Deutschland) GmbH    ( Aschaffenburg  USA )",
    "country": "United States"
  },
  {
    "id": 163505,
    "name": "Metron AS    ( Trondheim  USA )",
    "country": "United States"
  },
  {
    "id": 372146,
    "name": "Metron France    ( Evry  USA )",
    "country": "United States"
  },
  {
    "id": 184653,
    "name": "Metron Medical Australia Pty Ltd    ( Carrum Downs  Australia )",
    "country": "Australia"
  },
  {
    "id": 289927,
    "name": "Metron US Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 160003,
    "name": "Metronik Elektro-Med Geraete    ( Koeln  USA )",
    "country": "United States"
  },
  {
    "id": 454180,
    "name": "Metropolis International    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 461226,
    "name": "Metropolitan Mechanical Contractors Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 370482,
    "name": "Metropolitan Medical Inc    ( Winchester VA USA )",
    "country": "United States"
  },
  {
    "id": 417321,
    "name": "Metropolitan Medical Services of NC Inc    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 999445,
    "name": "Metropolitan Vacuum Cleaner Company, Inc.    ( Oakland NJ USA )",
    "country": "United States"
  },
  {
    "id": 393222,
    "name": "Metropolitan Wire (Canada) Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453672,
    "name": "Metrum CryoFlex    ( Lomianki n/Warsaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 175880,
    "name": "Mettag Products Inc    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 101504,
    "name": "Mettler Electronics Corp    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 137964,
    "name": "Mettler Toledo GmbH    ( Greifensee  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 162102,
    "name": "Mettler Toledo Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 457978,
    "name": "Mevion Medical Systems Inc    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 454690,
    "name": "Mevis Medical Solutions Inc    ( Pewaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 454689,
    "name": "Mevis Medical Solutions Ltd    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 276643,
    "name": "Mexpo International Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 452841,
    "name": "Mexys SA    ( Mons  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 160088,
    "name": "Meyer & Kersting Medizintechnik und Laborbedarf    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 292298,
    "name": "Meyer Instruments Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 160050,
    "name": "Meyer-Haake Medical Innovations    ( Ober-Moerlen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306534,
    "name": "Meyer-Wagenfeld    ( Espelkamp  Germany )",
    "country": "Germany"
  },
  {
    "id": 140049,
    "name": "Meyra Wilhelm Meyer GmbH & Co KG    ( Kalletal-Kalldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 457275,
    "name": "MEYRA-ORTOPEDIA Vertriebsgesellschaft mbH    ( Vlotho  Germany )",
    "country": "Germany"
  },
  {
    "id": 155936,
    "name": "Meza Medical Equipment    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 159807,
    "name": "MFE (Medical Fitness Equipment)    ( Maarn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 106870,
    "name": "MFI Medical Equipment Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 151092,
    "name": "MG Electric (Colchester) Ltd    ( Colchester  England )",
    "country": "UK"
  },
  {
    "id": 455402,
    "name": "MGB Endoskopische Gerate GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 282074,
    "name": "MGB Glastechnik GmbH    ( Goslar  USA )",
    "country": "United States"
  },
  {
    "id": 457372,
    "name": "MGC Diagnostics Corp    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 321057,
    "name": "MGE UPS Systems Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 324221,
    "name": "MGI International Ltd    ( Manchester  USA )",
    "country": "United States"
  },
  {
    "id": 450916,
    "name": "MGI Pharma Inc    ( Bloomington MN USA )",
    "country": "United States"
  },
  {
    "id": 435564,
    "name": "MGlas AG    ( Muennerstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 106356,
    "name": "MGM Instruments Inc    ( Hamden CT USA )",
    "country": "United States"
  },
  {
    "id": 451591,
    "name": "MGP Instruments    ( Smyrna GA USA )",
    "country": "United States"
  },
  {
    "id": 333587,
    "name": "MGS Research Inc    ( Madison CT USA )",
    "country": "United States"
  },
  {
    "id": 280122,
    "name": "MGW Medizinisch Glastechnische Werkstaette    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 457665,
    "name": "MHC Medical Products    ( Fairfield OH USA )",
    "country": "United States"
  },
  {
    "id": 460623,
    "name": "MHC Software Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 458457,
    "name": "MHS Inc    ( Fairfield CT USA )",
    "country": "United States"
  },
  {
    "id": 456658,
    "name": "Mianyang Lide Electronic Technology Co Ltd    ( Mianyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456251,
    "name": "MiaraMar    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 104719,
    "name": "Michael J Woldow & Co Inc    ( Claremont NH USA )",
    "country": "United States"
  },
  {
    "id": 162926,
    "name": "MichClone Assoc Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 454542,
    "name": "Michigan Eye-Bank    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 101505,
    "name": "Michigan Instruments Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 453613,
    "name": "Michigan Orthopaedic Products    ( Grandville MI USA )",
    "country": "United States"
  },
  {
    "id": 107123,
    "name": "Michigan X-Ray Co    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 309455,
    "name": "Michtner Geraete Optik Vertriebs GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 227402,
    "name": "Mick Radio-Nuclear Instruments Inc    ( Mount Vernon NY USA )",
    "country": "United States"
  },
  {
    "id": 461233,
    "name": "Mickle Laboratory Engineering Co Ltd    ( Guildford  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 439501,
    "name": "MICON Medizintechnik GmbH    ( Quickborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 371364,
    "name": "Micor Inc    ( Allison Park PA USA )",
    "country": "United States"
  },
  {
    "id": 458626,
    "name": "MicoVas Technologies Inc    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 340428,
    "name": "Micra Instruments    ( Slapton  England )",
    "country": "UK"
  },
  {
    "id": 157061,
    "name": "Micrel Medical Devices SA    ( Athinia (Gerakas)  Greece )",
    "country": "Greece"
  },
  {
    "id": 106625,
    "name": "Micrins Microsurgical Instruments Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 108789,
    "name": "Micro Audiometrics Corp    ( Murphy NC USA )",
    "country": "United States"
  },
  {
    "id": 391460,
    "name": "Micro Automation Technology SA    ( Wavre  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 106244,
    "name": "Micro Diagnostics Imaging    ( Hamlin PA USA )",
    "country": "United States"
  },
  {
    "id": 178456,
    "name": "Micro Direct Inc    ( Lewiston ME USA )",
    "country": "United States"
  },
  {
    "id": 462715,
    "name": "Micro Focus    ( Newbury  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 464981,
    "name": "Micro Image Technologies    ( Manasquan NJ USA )",
    "country": "United States"
  },
  {
    "id": 354211,
    "name": "Micro Infusion Ltd    ( Ramat Gan  Israel )",
    "country": "Israel"
  },
  {
    "id": 462925,
    "name": "Micro Measures & Intruments    ( Haryana  India )",
    "country": "India"
  },
  {
    "id": 141468,
    "name": "Micro Medical Corp    ( Kawasaki  USA )",
    "country": "United States"
  },
  {
    "id": 148884,
    "name": "Micro Medical Ltd    ( Rochester  USA )",
    "country": "United States"
  },
  {
    "id": 454436,
    "name": "Micro Medical Ltd    ( Chatham  England )",
    "country": "UK"
  },
  {
    "id": 285156,
    "name": "Micro Mega AG Deutschland    ( Oberursel  Germany )",
    "country": "Germany"
  },
  {
    "id": 460809,
    "name": "Micro Photonics Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 312370,
    "name": "Micro Scientific Industries Inc    ( Rolling Meadows IL USA )",
    "country": "United States"
  },
  {
    "id": 146304,
    "name": "Micro Supply Co Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 460882,
    "name": "Micro Technology Services Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 160093,
    "name": "Micro-Bio-Tec-Brand    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 460910,
    "name": "Micro-Clean Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 460591,
    "name": "Micro-Dyn Medical Systems Inc    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 452619,
    "name": "Micro-Lite    ( Three Rivers MA USA )",
    "country": "United States"
  },
  {
    "id": 184290,
    "name": "Micro-Mega SA    ( Besancon Cedex  France )",
    "country": "France"
  },
  {
    "id": 457263,
    "name": "Micro-Tech (Nanjing) Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 440735,
    "name": "Micro-Tech Enterprises    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 457262,
    "name": "Micro-Tech Europe    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 452257,
    "name": "Micro-Tech Hearing Instruments    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 286468,
    "name": "Micro-Technic GmbH    ( Stuttgart  USA )",
    "country": "United States"
  },
  {
    "id": 372904,
    "name": "MicroAire Europe    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 101079,
    "name": "MicroAire Surgical Instruments    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 999944,
    "name": "MicroAire Surgical Instruments LLC    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 105811,
    "name": "MicroBioLogics Inc    ( St Cloud MN USA )",
    "country": "United States"
  },
  {
    "id": 441633,
    "name": "Microbiology International    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 391439,
    "name": "Microbiotech/se AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 292126,
    "name": "Microbix Biosystems Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456398,
    "name": "Microbrush International    ( Grafton WI USA )",
    "country": "United States"
  },
  {
    "id": 453916,
    "name": "Microcopy Dental    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 451845,
    "name": "Microcuff GmbH    ( Weinheim  USA )",
    "country": "United States"
  },
  {
    "id": 462550,
    "name": "Microdea Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103052,
    "name": "Microelectrodes Inc    ( Bedford NH USA )",
    "country": "United States"
  },
  {
    "id": 106429,
    "name": "Microfit Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 298409,
    "name": "Microflex Medical Corp    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 175362,
    "name": "Microfluidics    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 459229,
    "name": "Microframe Corp    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 456502,
    "name": "Microgen Bioproducts Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 450795,
    "name": "Microgenics Australia    ( Lidcombe  Australia )",
    "country": "Australia"
  },
  {
    "id": 108126,
    "name": "Microgenics Corp    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 450796,
    "name": "Microgenics Espana    ( Sant Cugat Del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 450794,
    "name": "Microgenics GmbH    ( Passau  Germany )",
    "country": "Germany"
  },
  {
    "id": 450797,
    "name": "Microgenics Ltd    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 459220,
    "name": "MicroGraphic Solutions    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 398833,
    "name": "Microlife Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456781,
    "name": "Microlife Medical Home Solutions Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 365351,
    "name": "Microlife USA Inc    ( Dunedin FL USA )",
    "country": "United States"
  },
  {
    "id": 174921,
    "name": "Microline PENTAX Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 456799,
    "name": "Microline Surgical Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 227934,
    "name": "MICROM International GmbH    ( Walldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 459264,
    "name": "Microm UK Ltd    ( Bicester  England )",
    "country": "UK"
  },
  {
    "id": 283177,
    "name": "Microma Martin Alber Chirurgische Instrumente    ( Irndorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 348542,
    "name": "Micromar Industria e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 451332,
    "name": "Micromed Electronics Ltd    ( Woking  England )",
    "country": "UK"
  },
  {
    "id": 280350,
    "name": "Micromed GmbH    ( Herne  Germany )",
    "country": "Germany"
  },
  {
    "id": 451330,
    "name": "Micromed Medizin-Electronic GmbH    ( Graefelfing  Germany )",
    "country": "Germany"
  },
  {
    "id": 184265,
    "name": "Micromed srl    ( Mogliano Veneto (TV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 417325,
    "name": "MicroMed Technology Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 104898,
    "name": "Micromedical Devices Inc (MDI)    (   USA )",
    "country": "United States"
  },
  {
    "id": 182864,
    "name": "Micromedical Industries Ltd    ( Labrador  USA )",
    "country": "United States"
  },
  {
    "id": 107409,
    "name": "Micromedical Technologies Inc    ( Chatham IL USA )",
    "country": "United States"
  },
  {
    "id": 107410,
    "name": "Micromedics Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 152446,
    "name": "Micronal SA    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 456430,
    "name": "Micronclean    ( Skegness  England )",
    "country": "UK"
  },
  {
    "id": 458297,
    "name": "Micronix Systems Inc    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 999821,
    "name": "Micronta    (   USA )",
    "country": "United States"
  },
  {
    "id": 401955,
    "name": "MicroPace Pty Ltd    ( Canterbury  Australia )",
    "country": "Australia"
  },
  {
    "id": 464102,
    "name": "MicroPort Orthopedics BV    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458048,
    "name": "Microprecision Electronics SA    ( Vouvry  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459309,
    "name": "MICROS Systems Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 451714,
    "name": "MicroScan bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 186162,
    "name": "Microscope & Microtome Service Co    ( Mesquite TX USA )",
    "country": "United States"
  },
  {
    "id": 454112,
    "name": "Microsoft    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 361064,
    "name": "Microsound Products    ( Fairfield CA USA )",
    "country": "United States"
  },
  {
    "id": 225962,
    "name": "MicroStrain Inc    ( Williston VT USA )",
    "country": "United States"
  },
  {
    "id": 459234,
    "name": "MicroStrategy Inc    ( Tysons Corner VA USA )",
    "country": "United States"
  },
  {
    "id": 451361,
    "name": "Microsulis Medical Ltd    ( Denmead  England )",
    "country": "UK"
  },
  {
    "id": 103835,
    "name": "MicroSurgical Technology Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 187009,
    "name": "Microtech Medical Systems    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 107411,
    "name": "Microtek Medical Inc    ( Columbus MS USA )",
    "country": "United States"
  },
  {
    "id": 473852,
    "name": "Microtek Northwest Inc    ( Gig Harbor WA USA )",
    "country": "United States"
  },
  {
    "id": 457820,
    "name": "MicroVention Europe SARL    ( Saint-Germain-en-Laye  France )",
    "country": "France"
  },
  {
    "id": 450487,
    "name": "MicroVention France    ( Saint-Germain-en-Laye  France )",
    "country": "France"
  },
  {
    "id": 450486,
    "name": "MicroVention GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 449808,
    "name": "MicroVention Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 450485,
    "name": "MicroVention UK Ltd    ( North Shields  England )",
    "country": "UK"
  },
  {
    "id": 451715,
    "name": "MicroVision Medical Inc    ( Wallingford PA USA )",
    "country": "United States"
  },
  {
    "id": 324896,
    "name": "Microvitec Display Ltd    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 456568,
    "name": "Microvitec Ltd    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 457994,
    "name": "Microwave Research & Applications Inc    ( Carol Stream IL USA )",
    "country": "United States"
  },
  {
    "id": 151395,
    "name": "Microzone Corp    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 449809,
    "name": "Micrus Endovascular Corp    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 450484,
    "name": "Micrus Endovascular Corp (Switzerland)    ( Renens SA  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451920,
    "name": "Micrus Endovascular Corp (UK)    ( Bishop's Stortford  England )",
    "country": "UK"
  },
  {
    "id": 999563,
    "name": "Mid Atlantic Diagnostics, Inc.    ( Mt. Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 458727,
    "name": "Mid Central Medical    ( St Vincent MN USA )",
    "country": "United States"
  },
  {
    "id": 395332,
    "name": "MID Labs Inc    ( San Leandro CA USA )",
    "country": "United States"
  },
  {
    "id": 307811,
    "name": "Mid-America Medical Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 186164,
    "name": "Mid-America Sterilizer    ( Olathe KS USA )",
    "country": "United States"
  },
  {
    "id": 466730,
    "name": "Mid-America Transplant Services    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458810,
    "name": "Mid-AtlantiCare    ( Riva MD USA )",
    "country": "United States"
  },
  {
    "id": 146339,
    "name": "Mid-Georgia X-Ray Supply Inc    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 458183,
    "name": "Mid-South Eye Bank for Sight Restoration Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 431567,
    "name": "Mid-Way Cabinet    ( Lodi CA USA )",
    "country": "United States"
  },
  {
    "id": 233838,
    "name": "MIDAS+    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 460911,
    "name": "MidAtlantic Sign & Site Services LLC    ( Waterford Works NJ USA )",
    "country": "United States"
  },
  {
    "id": 453556,
    "name": "Midbrook Inc    ( Jackson MI USA )",
    "country": "United States"
  },
  {
    "id": 999680,
    "name": "Middle Atlantic Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 408774,
    "name": "Midi Inc    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 105298,
    "name": "Midland Mfg Co Inc    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 103304,
    "name": "Midmark Corp    ( Versailles OH USA )",
    "country": "United States"
  },
  {
    "id": 416480,
    "name": "Midmark Diagnostics Group    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 456709,
    "name": "MIDSCI    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 456394,
    "name": "Midway Dental Supply Inc    ( Lakeville IN USA )",
    "country": "United States"
  },
  {
    "id": 457142,
    "name": "Midwest Biomedical Resources Inc    ( Mokena IL USA )",
    "country": "United States"
  },
  {
    "id": 428561,
    "name": "Midwest Biomedical Service Network    ( Oakwood IL USA )",
    "country": "United States"
  },
  {
    "id": 170530,
    "name": "Midwest Computer Support    ( Sylvania OH USA )",
    "country": "United States"
  },
  {
    "id": 104309,
    "name": "Midwest Dental Products Corp    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 186171,
    "name": "Midwest Imaging Inc    ( St Charles MO USA )",
    "country": "United States"
  },
  {
    "id": 292301,
    "name": "Midwest Information Systems Inc    ( Villa Park IL USA )",
    "country": "United States"
  },
  {
    "id": 401030,
    "name": "Midwest Laser Products    ( Frankfort IL USA )",
    "country": "United States"
  },
  {
    "id": 186173,
    "name": "Midwest Medical Equipment Inc    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 450589,
    "name": "Midwest Medical Supply Co (MMS)    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 999543,
    "name": "Midwest Pacific    (   USA )",
    "country": "United States"
  },
  {
    "id": 144438,
    "name": "Midwest Products & Engineering Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 170532,
    "name": "Midwest Scientific    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 465077,
    "name": "Midwest Special Instruments Corp.    ( Burnsville 41 USA )",
    "country": "United States"
  },
  {
    "id": 103179,
    "name": "Midwest X-Ray Equipment Co    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 194105,
    "name": "MIE America Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 233906,
    "name": "MIE GmbH    ( Seth  Germany )",
    "country": "Germany"
  },
  {
    "id": 390987,
    "name": "MIE Medical Research Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 138076,
    "name": "Miele & Cie GmbH & Co    ( Guetersloh  Germany )",
    "country": "Germany"
  },
  {
    "id": 107676,
    "name": "Miele Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 332646,
    "name": "Miele Ltd    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 173403,
    "name": "Miesse Nursing Home Supply    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 456380,
    "name": "MIGA Medical Co Ltd    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453589,
    "name": "Miga Solutions    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 457258,
    "name": "Migali Scientific    ( Camden NJ USA )",
    "country": "United States"
  },
  {
    "id": 283326,
    "name": "Mihm-Vogt GmbH & Co DentalGeraetebau    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 456096,
    "name": "Mika Medical Co    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 174113,
    "name": "Mikasa X-Ray Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 458072,
    "name": "Mikrogen GmbH    ( Neuried  Germany )",
    "country": "Germany"
  },
  {
    "id": 272309,
    "name": "Mikromeditron Kft    ( Esztergom  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 108052,
    "name": "Mikron Digital Imaging Inc    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 465068,
    "name": "MikRon, Inc    ( x x USA )",
    "country": "United States"
  },
  {
    "id": 286963,
    "name": "Mikrona Dentaltechnik Vetriebs-GmbH    ( Potsdam  Germany )",
    "country": "Germany"
  },
  {
    "id": 391118,
    "name": "Mikrona Technologie AG    ( Spreitenbach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452621,
    "name": "Mil's SAS    ( Genas Cedex  France )",
    "country": "France"
  },
  {
    "id": 179834,
    "name": "Milcare bv    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 104262,
    "name": "Mile High Equipment Co/Ice-O-Matic    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 456571,
    "name": "Milenia Biotec GmbH    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451260,
    "name": "Miles Medical Supply Co    ( Niles OH USA )",
    "country": "United States"
  },
  {
    "id": 103165,
    "name": "Miles Scientific Div. Miles Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 407371,
    "name": "Milestone China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 407372,
    "name": "Milestone General KK    ( Kawasaki  Japan )",
    "country": "Japan"
  },
  {
    "id": 300260,
    "name": "Milestone Inc    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 407370,
    "name": "Milestone Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 450847,
    "name": "Milestone Medical Ltd Hong Kong    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 450845,
    "name": "Milestone Medical USA    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 362065,
    "name": "Milestone Scientific    ( Livingston NJ USA )",
    "country": "United States"
  },
  {
    "id": 235126,
    "name": "Milestone srl    ( Sorisole (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 463011,
    "name": "Milestone Systems USA    ( Beavertown OR USA )",
    "country": "United States"
  },
  {
    "id": 407182,
    "name": "Milex Norge (Norway)    ( Haugesund  USA )",
    "country": "United States"
  },
  {
    "id": 102987,
    "name": "Milex Products Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 104003,
    "name": "Milex Products Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 456926,
    "name": "Milian USA    ( Gahanna OH USA )",
    "country": "United States"
  },
  {
    "id": 101541,
    "name": "Millar Instruments Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 467126,
    "name": "Millennium Dental Technologies    ( Cerritos CA USA )",
    "country": "United States"
  },
  {
    "id": 471701,
    "name": "Millennium Health LLC    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457200,
    "name": "Millennium ICE Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 362819,
    "name": "Millennium Medical Products Inc    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 458325,
    "name": "Millennium Surgical Corp    ( Narberth PA USA )",
    "country": "United States"
  },
  {
    "id": 108155,
    "name": "Miller Coach Co Inc    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 461046,
    "name": "Miller Electric Mfg Co    ( Appleton WI USA )",
    "country": "United States"
  },
  {
    "id": 363956,
    "name": "Miller Graphic Pty Ltd    ( Clifton Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 457989,
    "name": "Miller Medical    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 103307,
    "name": "Miller-Stephenson Chemical Co    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 999545,
    "name": "Millers Falls Tool Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 452275,
    "name": "Millipore (UK) Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 453112,
    "name": "Millipore Bioscience Research Reagents    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 453113,
    "name": "Millipore Bioscience UK    ( Dundee  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 178086,
    "name": "Millipore China Ltd    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 150093,
    "name": "Millipore Corp    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 178087,
    "name": "Millipore European Headquarters    ( Saint Quentin en Yvelines  France )",
    "country": "France"
  },
  {
    "id": 281381,
    "name": "Millipore GmbH    ( Schwalbach am Taunus  Germany )",
    "country": "Germany"
  },
  {
    "id": 160822,
    "name": "Millipore SAS    ( Molsheim Cedex  France )",
    "country": "France"
  },
  {
    "id": 459221,
    "name": "Millrock Technology Inc    ( Kingston NY USA )",
    "country": "United States"
  },
  {
    "id": 175335,
    "name": "Milner-Fenwick Inc    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 452971,
    "name": "Miltenyi Biotec GmbH    ( Bergisch Gladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 452967,
    "name": "Miltenyi Biotec Inc    ( Auburn CA USA )",
    "country": "United States"
  },
  {
    "id": 101684,
    "name": "Miltex Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 464987,
    "name": "Milton CAT    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 451966,
    "name": "Milton Roy Europe    ( Pont-Saint-Pierre  France )",
    "country": "France"
  },
  {
    "id": 150225,
    "name": "Milton Roy USA    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 454749,
    "name": "Milverton Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999491,
    "name": "Milwaukee Electric Tool Corporation    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 103053,
    "name": "Milwaukee Mattress & Furniture Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 455277,
    "name": "MIM Medical Ltd    ( Hyde  England )",
    "country": "UK"
  },
  {
    "id": 460551,
    "name": "MiMedx    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 460594,
    "name": "Mimic Technologies Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 464110,
    "name": "mimijumi LLC    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 457921,
    "name": "Mimos    ( Playa D'Aro (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 461174,
    "name": "Mimosa Acoustics Inc    ( Champaign IL USA )",
    "country": "United States"
  },
  {
    "id": 451013,
    "name": "MIMvista Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 157062,
    "name": "Minato Medical Science Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 456879,
    "name": "Mindray (UK) Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 455416,
    "name": "Mindray Medical International Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454750,
    "name": "Mindray North America A Mindray Global Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 455434,
    "name": "Mindware Technologies Ltd    ( Gahanna OH USA )",
    "country": "United States"
  },
  {
    "id": 185998,
    "name": "Mindways Software Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 178246,
    "name": "Minhorst GmbH & Co    ( Meudt  USA )",
    "country": "United States"
  },
  {
    "id": 454320,
    "name": "Mini Crosser A/S    ( Snejbjerg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 108562,
    "name": "Mini Mitter Co Inc    ( Bend OR USA )",
    "country": "United States"
  },
  {
    "id": 101513,
    "name": "Minimax Corp    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 162198,
    "name": "Mining & Chemical Products Ltd    ( Wellingborough  USA )",
    "country": "United States"
  },
  {
    "id": 162991,
    "name": "Miniplast Ein-Shemer    ( Menashe  Israel )",
    "country": "Israel"
  },
  {
    "id": 455823,
    "name": "Miniplast Ein-Shemer ACS Ltd    ( Post Heffer  Israel )",
    "country": "Israel"
  },
  {
    "id": 287182,
    "name": "Minitrac GmbH Elektrofahrzeuge    ( Wehrheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 455095,
    "name": "Minivator Deutschland UG    ( Minden  Germany )",
    "country": "Germany"
  },
  {
    "id": 360032,
    "name": "Minnesota Bramstedt Surgical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 452867,
    "name": "Minnesota Lions Eye Bank    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 186178,
    "name": "Minnesota Med Spec    ( Golden Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 266047,
    "name": "Minnesota Med-Equip    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 102988,
    "name": "Minnesota Orthopedic Services    (   USA )",
    "country": "United States"
  },
  {
    "id": 465090,
    "name": "Minnesota Thermal Science LLC    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 104024,
    "name": "Minnesota Wire & Cable Co    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 999703,
    "name": "Minnetronix    ( St. Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 454098,
    "name": "Minntech Asia/Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 454099,
    "name": "Minntech bv    ( Heerlen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457152,
    "name": "Minogue Medical Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 102892,
    "name": "Minor Rubber Co Inc    ( Bloomfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 450793,
    "name": "Minrad Europe    ( Lille  USA )",
    "country": "United States"
  },
  {
    "id": 330934,
    "name": "Minrad International Inc    ( Orchard Park NY USA )",
    "country": "United States"
  },
  {
    "id": 475959,
    "name": "Mint Medical GmbH    ( Dossenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458992,
    "name": "Mintie Corp    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 108791,
    "name": "Minto Research & Development Inc    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 223707,
    "name": "Minuteman International Inc    ( Pingree Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 184236,
    "name": "Minuteman UPS/Para Systems Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 416526,
    "name": "Minwa (Australia) Pty Ltd    ( Hallam  Australia )",
    "country": "Australia"
  },
  {
    "id": 103109,
    "name": "MinXray Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 409770,
    "name": "MIP (Canada)    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 391909,
    "name": "MIP (US)    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 452046,
    "name": "MIPM Mammendorfer Institut fuer Physik und Medizin    ( Mammendorf/Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459232,
    "name": "MIQS    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 235117,
    "name": "MIRA Europe    ( Rotterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 101481,
    "name": "MIRA Inc    ( Uxbridge MA USA )",
    "country": "United States"
  },
  {
    "id": 450990,
    "name": "Mirabel Medical Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 450991,
    "name": "Mirabel Medical Ltd    ( Migdal Ha'Emek  USA )",
    "country": "United States"
  },
  {
    "id": 463009,
    "name": "Miraca Holdings Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456647,
    "name": "Miracle Laser Co Ltd    ( Wuhan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 377931,
    "name": "Miracle-Ear Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 346542,
    "name": "Miracle-Ear-OIR SA    ( Panama  Republic of Panama )",
    "country": "Panama"
  },
  {
    "id": 458624,
    "name": "Mirada Medical UK    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 324449,
    "name": "Mirage Dental Products    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 293428,
    "name": "Mirap AG    ( Jona  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457191,
    "name": "Miravex Ltd    ( Dublin 2  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 357684,
    "name": "Miriam Healthcare Ltd    ( Wirral  USA )",
    "country": "United States"
  },
  {
    "id": 462994,
    "name": "Mirion Technologies (RADOS) Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 459231,
    "name": "Mirion Technologies Inc    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 287357,
    "name": "miro Verbandstoffe GmbH    ( Wiehl-Drabenderhoehe  Germany )",
    "country": "Germany"
  },
  {
    "id": 346074,
    "name": "Mirolin Industries Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457185,
    "name": "MIS Inc    ( Villa Park IL USA )",
    "country": "United States"
  },
  {
    "id": 170577,
    "name": "Misawa Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 320786,
    "name": "MISC Inc    ( Toms River NJ USA )",
    "country": "United States"
  },
  {
    "id": 459233,
    "name": "MISCO Refractometer    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 184247,
    "name": "Misonix Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 170074,
    "name": "Mission Pharmacal Co    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 186179,
    "name": "Mission X-Ray/Uniline    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 174681,
    "name": "Mississauga Medical Supply Ltd    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 408522,
    "name": "Misys Healthcare Systems    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 394711,
    "name": "Misys Healthcare Systems plc    ( Burgess Hill  USA )",
    "country": "United States"
  },
  {
    "id": 104241,
    "name": "MIT Poly-Cart Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 106051,
    "name": "MIT Service Inc    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 456953,
    "name": "Mitaka USA    ( Park City UT USA )",
    "country": "United States"
  },
  {
    "id": 174114,
    "name": "Mitaya Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 135535,
    "name": "Mitek Surgical Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458481,
    "name": "Mitel Networks Corp    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451700,
    "name": "Mitem    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 431498,
    "name": "MITI Mfg Co Inc    ( Grand Junction CO USA )",
    "country": "United States"
  },
  {
    "id": 456950,
    "name": "Mitochon Systems Inc    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 187980,
    "name": "Mitsubishi Chemical Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 107057,
    "name": "Mitsubishi Digital Electronics America Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 157965,
    "name": "Mitsubishi Electric Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455650,
    "name": "Mitsubishi Heavy Industries Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 385386,
    "name": "Mitutoyo America Corp    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 174116,
    "name": "Miwa Electric Medical Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 455824,
    "name": "Mizra Medical Ltd    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 364439,
    "name": "Mizuho America Inc    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 288805,
    "name": "Mizuho Ikakogyo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454262,
    "name": "Mizuho OSI    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 451888,
    "name": "Mizuho USA Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 105089,
    "name": "Mizzy Inc    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 460595,
    "name": "MJM International Corp    ( Edinburg TX USA )",
    "country": "United States"
  },
  {
    "id": 187060,
    "name": "MJS Healthcare Ltd    ( Barton  England )",
    "country": "UK"
  },
  {
    "id": 105241,
    "name": "MK Battery    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 403772,
    "name": "MK Battery International Ltd    ( Kettering  England )",
    "country": "UK"
  },
  {
    "id": 286207,
    "name": "MK Jucheim GmbH & Co    ( Fulda  Germany )",
    "country": "Germany"
  },
  {
    "id": 186182,
    "name": "MKH Electronics Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 284670,
    "name": "MKS Instruments Deutschland GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 102575,
    "name": "MKS Instruments Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 286164,
    "name": "MKW Therapiesysteme    ( Sinzheim/Baden-Baden  Germany )",
    "country": "Germany"
  },
  {
    "id": 999950,
    "name": "MLH ENTERPRISES INC    ( Scaradale NY USA )",
    "country": "United States"
  },
  {
    "id": 283958,
    "name": "MLS Mikrowellen-Labor Systeme GmbH    ( Leutkirch im Allgaeu  Germany )",
    "country": "Germany"
  },
  {
    "id": 458931,
    "name": "MM Hayes Co Inc    ( Albany NY USA )",
    "country": "United States"
  },
  {
    "id": 371033,
    "name": "MMAR Medical Group Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 458956,
    "name": "MMF Systems Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 431335,
    "name": "MMI Medication Carts Inc    ( El Segundo CA USA )",
    "country": "United States"
  },
  {
    "id": 458674,
    "name": "MMI Systems of Mississippi    ( Crystal Springs MS USA )",
    "country": "United States"
  },
  {
    "id": 409849,
    "name": "MMM Medcenter Einrichtungen GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160098,
    "name": "MMM Muenchener Medizin Mechanik GmbH    ( Planegg  Germany )",
    "country": "Germany"
  },
  {
    "id": 466228,
    "name": "MMS - A Medical Supply Company    ( 11 1 USA )",
    "country": "United States"
  },
  {
    "id": 283888,
    "name": "MMS Deutschland GmbH    ( Bottrop  Germany )",
    "country": "Germany"
  },
  {
    "id": 330778,
    "name": "MMS Medical Measurement Systems bv    ( Enschede  USA )",
    "country": "United States"
  },
  {
    "id": 452469,
    "name": "MMS Medicor Medical Supplies GmbH    ( Kerpen  Germany )",
    "country": "Germany"
  },
  {
    "id": 440382,
    "name": "MMS Sales Corp    ( Noble OK USA )",
    "country": "United States"
  },
  {
    "id": 367378,
    "name": "MMS USA Inc    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 461335,
    "name": "MO BIO Laboratories Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 285654,
    "name": "MO-Tronic Med -Optischer Apparatebau GmbH    ( Reinbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 457859,
    "name": "Moberg Research Inc    ( Ambler PA USA )",
    "country": "United States"
  },
  {
    "id": 456855,
    "name": "Mobilcenter Zawatzky GmbH    ( Meckesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 452076,
    "name": "Mobile Air Applied Science Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 454233,
    "name": "Mobile Aspects Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 459336,
    "name": "Mobile Heartbeat Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 127055,
    "name": "Mobile Instrument Service    ( Bellefontaine OH USA )",
    "country": "United States"
  },
  {
    "id": 285474,
    "name": "Mobile Lithotripter Systeme    ( Quickborn  USA )",
    "country": "United States"
  },
  {
    "id": 455655,
    "name": "Mobile Medical Maintenance    ( Leo IN USA )",
    "country": "United States"
  },
  {
    "id": 416257,
    "name": "Mobile Medical Vehicles Inc    ( Jasper TX USA )",
    "country": "United States"
  },
  {
    "id": 186183,
    "name": "Mobile Radiology Inc    ( New Port Richey FL USA )",
    "country": "United States"
  },
  {
    "id": 459502,
    "name": "Mobile-Shop Co    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 102576,
    "name": "Mobile-Tronics Co Inc    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 459235,
    "name": "MobileIron    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 457141,
    "name": "MobileScan Imaging    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 363818,
    "name": "Mobili G Inc    ( Carmel CA USA )",
    "country": "United States"
  },
  {
    "id": 458550,
    "name": "Mobility Aids Sales & Services    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 330118,
    "name": "Mobility Research / LiteGait    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 458197,
    "name": "Mobility Shop    ( Newton Abbot  England )",
    "country": "UK"
  },
  {
    "id": 325118,
    "name": "Mobility Transfer Systems Inc    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 459222,
    "name": "Mobility Unlimited Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 452251,
    "name": "MobilityWorks    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 287564,
    "name": "Mobimed GmbH    ( Wuppertal  USA )",
    "country": "United States"
  },
  {
    "id": 457803,
    "name": "Mobisante Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 471682,
    "name": "Mobius Imaging LLC    ( Shirley MA USA )",
    "country": "United States"
  },
  {
    "id": 459237,
    "name": "Mobius Medical Systems LP    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 160099,
    "name": "MOC-Danner GmbH    ( Ammerbuch  Germany )",
    "country": "Germany"
  },
  {
    "id": 152419,
    "name": "Mochida Pharmaceutical Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 274415,
    "name": "Mocom srl    ( Castel Maggiore (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462841,
    "name": "MOD Laundry    ( Ashtabula OH USA )",
    "country": "United States"
  },
  {
    "id": 431509,
    "name": "Modec Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 107414,
    "name": "Modern Biomedical & Imaging Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 354115,
    "name": "Modern Medical Inc    ( Lewis Center OH USA )",
    "country": "United States"
  },
  {
    "id": 178922,
    "name": "Modern Medical Systems Co    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 105967,
    "name": "Modern Plastics Corp    ( Wilkes Barre PA USA )",
    "country": "United States"
  },
  {
    "id": 105810,
    "name": "Modern Way Immobilizers Inc    ( Clifton TN USA )",
    "country": "United States"
  },
  {
    "id": 280758,
    "name": "modie-trans GmbH    ( Dautphetal  Germany )",
    "country": "Germany"
  },
  {
    "id": 459247,
    "name": "ModuForm Inc    ( Fitchburg MA USA )",
    "country": "United States"
  },
  {
    "id": 309527,
    "name": "Modul Diagram srl    ( Castelbolognese (RA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 331395,
    "name": "Modul USA    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 284476,
    "name": "Modul-Technik GmbH    ( Montabaur  Germany )",
    "country": "Germany"
  },
  {
    "id": 174683,
    "name": "Modular & Custom Cabinets Ltd    ( Maple ON Canada )",
    "country": "Canada"
  },
  {
    "id": 471733,
    "name": "Modular Pharmacy Solutions    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 333447,
    "name": "Modular Services Co    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 103210,
    "name": "Modulation Optics Inc    ( Glen Cove NY USA )",
    "country": "United States"
  },
  {
    "id": 163477,
    "name": "Modulex A/S    ( Billund  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 102577,
    "name": "Modulus Data Systems Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 459337,
    "name": "Modus Medical Devices Inc    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 152926,
    "name": "Moelab    ( Hilden  Germany )",
    "country": "Germany"
  },
  {
    "id": 454452,
    "name": "moeLab GmbH    ( Langenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 155384,
    "name": "Moeller-Wedel GmbH    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 451983,
    "name": "Moelnlycke Health Care GmbH    ( Erkrath-Unterfeldhaus  Germany )",
    "country": "Germany"
  },
  {
    "id": 431608,
    "name": "Moffat Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 459298,
    "name": "Mogul Wireless LP    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 336415,
    "name": "Mohammed Fakhroo & Bros    ( Manama  Bahrain )",
    "country": "Bahrain"
  },
  {
    "id": 451225,
    "name": "Mohawk Hospital Equipment    ( Utica NY USA )",
    "country": "United States"
  },
  {
    "id": 102894,
    "name": "Molded Fiber Glass Tray Co    ( Linesville PA USA )",
    "country": "United States"
  },
  {
    "id": 409913,
    "name": "Moldex (Australia)    ( Seven Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 409915,
    "name": "Moldex (Mexico)    ( Juriquilla  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 409914,
    "name": "Moldex (New Zealand)    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 453015,
    "name": "Moldex Inc    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 409907,
    "name": "Moldex-Metric (France)    ( Bourg-les-Valence  France )",
    "country": "France"
  },
  {
    "id": 409909,
    "name": "Moldex-Metric (Italy)    ( Mariano Comense (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 409910,
    "name": "Moldex-Metric (Spain)    ( Sant Vicene dels Horts  Spain )",
    "country": "Spain"
  },
  {
    "id": 409912,
    "name": "Moldex-Metric (Sweden)    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 409908,
    "name": "Moldex-Metric (The Netherlands)    ( Barendrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 409911,
    "name": "Moldex-Metric (UK)    ( Bilborough  England )",
    "country": "UK"
  },
  {
    "id": 409906,
    "name": "Moldex-Metric AG & Co KG    ( Walddorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 409897,
    "name": "Moldex-Metric Inc    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 441516,
    "name": "Molecular BioProducts Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 293174,
    "name": "Molecular Design MDL AG    ( Allschwil 2  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455298,
    "name": "Molecular Devices (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 276639,
    "name": "Molecular Devices Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 363109,
    "name": "Molecular Devices GmbH    ( Ismaning bie Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 276658,
    "name": "Molecular Devices Ltd    ( Wokingham  England )",
    "country": "UK"
  },
  {
    "id": 412985,
    "name": "Molecular Machines & Industries AG    ( Glattbrugg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462687,
    "name": "MoleSafe USA    ( Millburn NJ USA )",
    "country": "United States"
  },
  {
    "id": 453441,
    "name": "Molex Inc    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 454189,
    "name": "Molift AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454190,
    "name": "Molift AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 454191,
    "name": "Molift Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 454192,
    "name": "Molift Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 280126,
    "name": "Molnar Institut fuer Angewandte Chromatographie    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 451572,
    "name": "Molnlycke Health Care AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 343477,
    "name": "Molnlycke Health Care LLC    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 999753,
    "name": "MOM365, INC.    (   USA )",
    "country": "United States"
  },
  {
    "id": 325121,
    "name": "Momentum Medical    ( Idaho Falls ID USA )",
    "country": "United States"
  },
  {
    "id": 103171,
    "name": "Monaghan Medical Corp    ( Plattsburgh NY USA )",
    "country": "United States"
  },
  {
    "id": 999818,
    "name": "Monarch Instrument    ( Amherst NH USA )",
    "country": "USA"
  },
  {
    "id": 455295,
    "name": "Monarch Labs    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 455640,
    "name": "Monarch Medical    ( Staten Island NY USA )",
    "country": "United States"
  },
  {
    "id": 101519,
    "name": "Monarch Molding Inc    ( Council Grove KS USA )",
    "country": "United States"
  },
  {
    "id": 157666,
    "name": "Monark Exercise AB    ( Vansbro  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457797,
    "name": "Monet Medical Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 366541,
    "name": "Moneypenny Ltd    ( Chai Wan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456633,
    "name": "Monica Healthcare Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 462557,
    "name": "Monico Inc    ( Spring TX USA )",
    "country": "United States"
  },
  {
    "id": 106938,
    "name": "Monitor Instruments Inc    ( Hillsborough NC USA )",
    "country": "United States"
  },
  {
    "id": 459353,
    "name": "Monitored Rehab Systems    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 332739,
    "name": "Monobind Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 471704,
    "name": "Monogram Biosciences Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 102290,
    "name": "Monroe Electronics Inc    ( Lyndonville NY USA )",
    "country": "United States"
  },
  {
    "id": 184099,
    "name": "Monroy Lemus Arturo    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 466328,
    "name": "Monstersteel.com    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 416258,
    "name": "Montage Medical Inc    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 309212,
    "name": "Montavit Pharmazeutische Fabrik Ges mbH    ( Absam  Austria )",
    "country": "Austria"
  },
  {
    "id": 459283,
    "name": "Monterey Medical Solutions Inc    ( Seaside CA USA )",
    "country": "United States"
  },
  {
    "id": 458590,
    "name": "Monteris Medical Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 457880,
    "name": "Monticello Drug Co    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457856,
    "name": "Moog Animatics    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 453674,
    "name": "Moog Medical Devices Group Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 455898,
    "name": "Moor Instruments    ( Devon  England )",
    "country": "UK"
  },
  {
    "id": 455899,
    "name": "Moor Instruments Inc    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 460622,
    "name": "Moore Industries-International Inc    ( North Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 459408,
    "name": "MR confon GmbH    ( Magdeburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451858,
    "name": "Moore Medical LLC    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 458145,
    "name": "Mopec Europe    ( Newhaven  Great Britain )",
    "country": "UK"
  },
  {
    "id": 300135,
    "name": "MOPEC Inc    ( Oak Park MI USA )",
    "country": "United States"
  },
  {
    "id": 286470,
    "name": "Morcher GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 330893,
    "name": "More Diagnostics    ( Los Osos CA USA )",
    "country": "United States"
  },
  {
    "id": 461474,
    "name": "MoreDirect Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 459251,
    "name": "Morefield Communications    ( Camp Hill PA USA )",
    "country": "United States"
  },
  {
    "id": 452640,
    "name": "Moretti SpA    ( Cavriglia (AR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 344511,
    "name": "MORFAM Inc    ( Mishawaka IN USA )",
    "country": "United States"
  },
  {
    "id": 339980,
    "name": "Morgan Buildings and Spas Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457413,
    "name": "Morgan Innovation and Technology Ltd    ( Petersfield  England )",
    "country": "UK"
  },
  {
    "id": 356942,
    "name": "Morgan MEDesign Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 232527,
    "name": "Morgan Scientific Inc    ( Haverhill MA USA )",
    "country": "United States"
  },
  {
    "id": 416403,
    "name": "Moria    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 232647,
    "name": "Moria France    ( Antony  France )",
    "country": "France"
  },
  {
    "id": 407255,
    "name": "Moria Inc    ( Doylestown PA USA )",
    "country": "United States"
  },
  {
    "id": 416405,
    "name": "Moria Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 407259,
    "name": "Moria Shanghai    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 174119,
    "name": "Moriyama X-Ray Equipment Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 178849,
    "name": "Mormac Tube Guard Co    ( North Loup NE USA )",
    "country": "United States"
  },
  {
    "id": 465229,
    "name": "Morrell Instrument Company    ( Melville 50 USA )",
    "country": "United States"
  },
  {
    "id": 459349,
    "name": "Morris & Dickson Co LLC    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 471658,
    "name": "Morris Innovative Inc    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 454745,
    "name": "Morrisey Assocs    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 101522,
    "name": "Morrison Medical    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 283730,
    "name": "Morsa Wachswarenfabrik Sallinger GmbH    ( Krumbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 331756,
    "name": "Morse Medical Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 175628,
    "name": "MorTan Inc    ( Missoula MT USA )",
    "country": "United States"
  },
  {
    "id": 457473,
    "name": "Mortara Dolby UK Ltd    ( Stirling  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 455387,
    "name": "Mortara Instrument Australia    ( Baulkham Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 426610,
    "name": "Mortara Instrument bv    ( de Bilt  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 281445,
    "name": "Mortara Instrument GmbH    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 104961,
    "name": "Mortara Instrument Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 157063,
    "name": "Mortara Rangoni Europe srl    ( Casalecchio di Reno (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 402493,
    "name": "Mortech Mfg Co    ( Azusa CA USA )",
    "country": "United States"
  },
  {
    "id": 452641,
    "name": "Morton Medikal Ltd    ( Yenisehir-Izmir  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 107416,
    "name": "Mortuary Lift Co    ( Cedar Rapids IA USA )",
    "country": "United States"
  },
  {
    "id": 286973,
    "name": "Moser Elektrogeraete GmbH    ( Unterkirnach  Germany )",
    "country": "Germany"
  },
  {
    "id": 106321,
    "name": "Mosley Medical Products Inc    ( Flagstaff AZ USA )",
    "country": "United States"
  },
  {
    "id": 293939,
    "name": "Moss srl    ( Reggio Emilia  Italy )",
    "country": "Italy"
  },
  {
    "id": 171354,
    "name": "Moss Tubes Inc    ( West Sand Lake NY USA )",
    "country": "United States"
  },
  {
    "id": 454730,
    "name": "MOTEK Medical bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458326,
    "name": "Motic North America    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 108055,
    "name": "Motion Analysis Corp    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 457525,
    "name": "Motion Computing Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 453651,
    "name": "Motion Concepts Canada    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103603,
    "name": "Motion Control Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 459347,
    "name": "Motion Fitness LLC    ( Palatine IL USA )",
    "country": "United States"
  },
  {
    "id": 345052,
    "name": "Motion Lab Systems Inc    ( Baton Rouge LA USA )",
    "country": "United States"
  },
  {
    "id": 331324,
    "name": "Motloid/Yates & Bird    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 377438,
    "name": "Motoman Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 377439,
    "name": "Motoman Robotics Europe AB    ( Torsas  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454893,
    "name": "Motorika Medical (Israel) Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 454892,
    "name": "Motorika Medical (USA) Ltd    ( Trussville AL USA )",
    "country": "United States"
  },
  {
    "id": 999866,
    "name": "Motorola    (   USA )",
    "country": "United States"
  },
  {
    "id": 452137,
    "name": "Motorola Enterprise Mobility    ( Holtsville NY USA )",
    "country": "United States"
  },
  {
    "id": 184279,
    "name": "Motorola Inc    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 456327,
    "name": "Motorola Mobility Inc    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 460757,
    "name": "Motorola Solutions Inc    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 999851,
    "name": "Mott Manufacturing    ( Brantford Ont Canada )",
    "country": "Canada"
  },
  {
    "id": 469465,
    "name": "Mott Manufacturing Ltd    ( Brantford ON Canada )",
    "country": "Canada"
  },
  {
    "id": 162997,
    "name": "Motyknit Ltd    ( Holon  Israel )",
    "country": "Israel"
  },
  {
    "id": 357179,
    "name": "Mouldex Co Ltd    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 176884,
    "name": "Mountain Aire Medical Supply Inc    ( Grand Junction CO USA )",
    "country": "United States"
  },
  {
    "id": 456172,
    "name": "Mountain Integrated Medical Devices Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 104010,
    "name": "Mountain Medical Equipment Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459297,
    "name": "Mountain Medical Technologies Inc    ( Village of Palmetto Bay FL USA )",
    "country": "United States"
  },
  {
    "id": 999593,
    "name": "Mountain Precision Mfg    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 450590,
    "name": "Mountain States Medical    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 455608,
    "name": "Mountainside Medical Equipment Inc    ( Marcy NY USA )",
    "country": "United States"
  },
  {
    "id": 334287,
    "name": "Mountainside Software    ( Weyers Cave VA USA )",
    "country": "United States"
  },
  {
    "id": 454403,
    "name": "Mountway Ltd    ( Tredegar  Wales )",
    "country": "Wales"
  },
  {
    "id": 457860,
    "name": "Mouser Electronics    ( Mansfield TX USA )",
    "country": "United States"
  },
  {
    "id": 456400,
    "name": "Movement2 Ltd    ( East Goscote  England )",
    "country": "UK"
  },
  {
    "id": 466299,
    "name": "Movi Medical LLC    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 409706,
    "name": "Movingpeople.net Canada Inc    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 455198,
    "name": "MP Biomedicals    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 452073,
    "name": "MP Biomedicals Europe    ( Illkirch  France )",
    "country": "France"
  },
  {
    "id": 428711,
    "name": "MP Biomedicals LLC    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 373290,
    "name": "MPACS LLC    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 408924,
    "name": "MPD Medical Systems Inc    ( Prairie Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 291939,
    "name": "MPM Medical Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 279566,
    "name": "MPV GmbH    ( Aschau  Germany )",
    "country": "Germany"
  },
  {
    "id": 454025,
    "name": "MPV Truma    ( Putzbrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 451535,
    "name": "MPX Sales and Service LLC    ( Saline MI USA )",
    "country": "United States"
  },
  {
    "id": 461056,
    "name": "Mr C Refrigeration Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 462561,
    "name": "MTS Services    ( Londonderry NH USA )",
    "country": "United States"
  },
  {
    "id": 170538,
    "name": "MR Resources Inc    ( Fitchburg MA USA )",
    "country": "United States"
  },
  {
    "id": 281291,
    "name": "MR-Etikettiertechnik GmbH & Co KG    ( Enger  Germany )",
    "country": "Germany"
  },
  {
    "id": 461240,
    "name": "MRA Inc    ( Washington PA USA )",
    "country": "United States"
  },
  {
    "id": 464990,
    "name": "MRC-Holland    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 459338,
    "name": "MRESnet Inc    ( Port Arthur TX USA )",
    "country": "United States"
  },
  {
    "id": 457593,
    "name": "MRI CinemaVision LLC    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 456313,
    "name": "MRI Co Ltd    ( Sriracha  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 186192,
    "name": "MRI Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 450736,
    "name": "MRI Devices (Europe) GmbH    ( Wuersburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 408750,
    "name": "MRI Devices Daum GmbH    ( Schwerin  Germany )",
    "country": "Germany"
  },
  {
    "id": 458821,
    "name": "MRI Interventions Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 459393,
    "name": "MRI Med    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 457515,
    "name": "MRIaudio Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 455366,
    "name": "MRIEquip.com    ( Nisswa MN USA )",
    "country": "United States"
  },
  {
    "id": 417323,
    "name": "MRP Group Inc    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 285740,
    "name": "MRT Murken-Reha-Technik Ing Buero Murken GmbH & Co    ( Rheda Wiedenbrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 348417,
    "name": "MRV Communications Inc    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 456714,
    "name": "Ms Cooling    ( Gray LA USA )",
    "country": "United States"
  },
  {
    "id": 456723,
    "name": "MS Enterprises    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 418560,
    "name": "MSA (Africa) Pty Ltd    ( Gauteng  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 418571,
    "name": "MSA (Australia) Pty Ltd    ( Wentworthville  Australia )",
    "country": "Australia"
  },
  {
    "id": 418546,
    "name": "MSA (Belgium) NV    ( Lier  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 156043,
    "name": "MSA (Britain) Ltd    ( Bellshill  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 301941,
    "name": "MSA (Canada) Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 418554,
    "name": "MSA (Espanola) SAU    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 418549,
    "name": "MSA (France)    ( Saint-Ouen-l'Aumone  France )",
    "country": "France"
  },
  {
    "id": 418567,
    "name": "MSA (India) Ltd    ( Calcutta  India )",
    "country": "India"
  },
  {
    "id": 418551,
    "name": "MSA (Italiana) SpA    ( Rozzano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418568,
    "name": "MSA (Japan) Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 418570,
    "name": "MSA (Middle East)    ( Abu Dhabi  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 159421,
    "name": "MSA (Mine Safety Appliances Co)    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 418558,
    "name": "MSA (Schweiz)    ( Oberglatt  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418569,
    "name": "MSA (Southeast Asia) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 418561,
    "name": "MSA (Zimbabwe)    ( Harare  Zimbabwe )",
    "country": "Zimbabwe"
  },
  {
    "id": 418545,
    "name": "MSA Auer (Austria) Vertriebs GmbH    ( Tulin  Austria )",
    "country": "Austria"
  },
  {
    "id": 418547,
    "name": "MSA Auer (Bulgaria)    ( Sofia  Bulgaria )",
    "country": "Bulgaria"
  },
  {
    "id": 418548,
    "name": "MSA Auer (Czech Republic) o z    ( Praha 4  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 418550,
    "name": "MSA Auer (Hungaria) Biztonsagtechnika Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 418559,
    "name": "MSA Auer (Kiev)    ( Kyiv  Ukraine )",
    "country": "United Kingdomraine"
  },
  {
    "id": 418553,
    "name": "MSA Auer (Polska) Sp z o o    ( Raszyn  Poland )",
    "country": "Poland"
  },
  {
    "id": 418556,
    "name": "MSA Auer (Russia)    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 418555,
    "name": "MSA Auer (Slovakia) o z    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 418544,
    "name": "MSA Auer GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 418562,
    "name": "MSA de (Argentina) SA    ( Don Torcuato  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 255601,
    "name": "MSA de (Mexico) SA de CV    ( El Marques  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 418564,
    "name": "MSA de Chile Ltda    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 418565,
    "name": "MSA del (Peru) SA    ( Lima 3  Peru )",
    "country": "Peru"
  },
  {
    "id": 418563,
    "name": "MSA do (Brasil) Ltda    ( Diadema-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 102184,
    "name": "MSA International    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 418552,
    "name": "MSA Nederland bv    ( Hoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 418557,
    "name": "MSA Nordic (Sweden)    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 183908,
    "name": "MSB Ltd    ( Marlborough  USA )",
    "country": "United States"
  },
  {
    "id": 458888,
    "name": "MSDSonline    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 364306,
    "name": "MSI Inc    ( Brandon FL USA )",
    "country": "United States"
  },
  {
    "id": 451014,
    "name": "MSI Precision Instruments    ( Phoenixville PA USA )",
    "country": "United States"
  },
  {
    "id": 283054,
    "name": "MSP Schmeiser GmbH    ( Horb  Germany )",
    "country": "Germany"
  },
  {
    "id": 460648,
    "name": "MSR West    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 328761,
    "name": "MSS Ltd    ( Hay River NT Canada )",
    "country": "Canada"
  },
  {
    "id": 284879,
    "name": "MST Albert Martin Spritzen-Technik Veterinaer-    ( Neuhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 232253,
    "name": "MSV GmbH Medical Systems    ( Kandel  Germany )",
    "country": "Germany"
  },
  {
    "id": 280642,
    "name": "MSW    ( Buettelborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 456734,
    "name": "MT MEDI Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 282754,
    "name": "MTA Strassmann GmbH    ( Hattersheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 104658,
    "name": "MTC Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 358221,
    "name": "MTC Industries & Research Carmiel Ltd    ( Carmiel  Israel )",
    "country": "Israel"
  },
  {
    "id": 101524,
    "name": "MTD Inc    ( Blairstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 284095,
    "name": "MTG Imiella Medizintechnik    ( Lugau  Germany )",
    "country": "Germany"
  },
  {
    "id": 184282,
    "name": "MTI Dental Products    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 458790,
    "name": "MTI Diagnostics    ( Idstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 104850,
    "name": "MTI INC    (   USA )",
    "country": "United States"
  },
  {
    "id": 456549,
    "name": "MTI Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 457642,
    "name": "MTI Medical Technology Industries Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 449822,
    "name": "MTI Medizin-Technische Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 107175,
    "name": "MTM Health Products Ltd    ( Burlington ON USA )",
    "country": "United States"
  },
  {
    "id": 287386,
    "name": "MTM Multitechmed GmbH    ( Huenfelden-Dauborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 284852,
    "name": "MTN Medizintechnik Neubrandenburg GmbH    ( Neubrandenburg  USA )",
    "country": "United States"
  },
  {
    "id": 415963,
    "name": "MTP Medical Technical Promotion GmbH    ( Neuhausen ob Eck  Germany )",
    "country": "Germany"
  },
  {
    "id": 374362,
    "name": "MTRE Advanced Technologies Ltd    ( Yavne  Israel )",
    "country": "Israel"
  },
  {
    "id": 462859,
    "name": "MTS Integratrak North America    ( River Edge NJ USA )",
    "country": "United States"
  },
  {
    "id": 457359,
    "name": "MTS Maschinenbau AG    ( Bilten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459341,
    "name": "MTS Medical UG    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 459407,
    "name": "MTS Medication Technologies    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 999640,
    "name": "MTS Medication Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 286044,
    "name": "MTS Medizin-Technische Systeme Schweinfurt GmbH    ( Schweinfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 306398,
    "name": "MTS Medizintechnische    ( Marl  Germany )",
    "country": "Germany"
  },
  {
    "id": 452773,
    "name": "MTS Systems Corp    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 417468,
    "name": "MTS-Delft USA    ( Aurora OH USA )",
    "country": "United States"
  },
  {
    "id": 459219,
    "name": "mTuitive    ( Centerville MA USA )",
    "country": "United States"
  },
  {
    "id": 280442,
    "name": "MTW Cardio Concept Medizintechnik H Wilhelm GmbH    ( Braunfels  Germany )",
    "country": "Germany"
  },
  {
    "id": 458966,
    "name": "MTX Lab Systems Inc    ( Vienna VA USA )",
    "country": "United States"
  },
  {
    "id": 174409,
    "name": "Mucambo S/A    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 306620,
    "name": "Mueholos-Werk Alfred Mueller GmbH & Co    ( Niefern  Germany )",
    "country": "Germany"
  },
  {
    "id": 293476,
    "name": "Mueller & Leutwyler AG    ( Lenzburg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 283048,
    "name": "Mueller & Mueller-Joh GmbH & Co    ( Holzminden  Germany )",
    "country": "Germany"
  },
  {
    "id": 186733,
    "name": "Mueller and Sebastiani Elektronik GmbH    ( Ottbrunn bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 227935,
    "name": "Mueller Instrumente & Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 176885,
    "name": "Mueller Sports Medicine Inc    ( Prairie du Sac WI USA )",
    "country": "United States"
  },
  {
    "id": 284016,
    "name": "Mueller-Dental GmbH    ( Zwerbruecken  Germany )",
    "country": "Germany"
  },
  {
    "id": 456202,
    "name": "Mueller-Omicron Dental GmbH & Co KG    ( Lindlar/Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 286553,
    "name": "Muenchener Apparatebau fuer Elektronische Geraete    ( Taufkirchen  USA )",
    "country": "United States"
  },
  {
    "id": 282955,
    "name": "Muetek Analytic GmbH    ( Herrsching  Germany )",
    "country": "Germany"
  },
  {
    "id": 285724,
    "name": "Muetex Richard Mueller GmbH    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 184176,
    "name": "Mui Scientific Div H & A Mui Enterprises Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 107797,
    "name": "Mulholland Positioning Systems Inc    ( Burley ID USA )",
    "country": "United States"
  },
  {
    "id": 333745,
    "name": "Multi BioSensors Inc    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 177854,
    "name": "Multi Imager Service LLC    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 457214,
    "name": "Multi Imaging Systems Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 458589,
    "name": "Multi Radiance Medical    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 999748,
    "name": "Multi Radiance Medical    ( Solon OH USA )",
    "country": "USA"
  },
  {
    "id": 462919,
    "name": "Multi-Comp Inc    ( Owings Mills MD USA )",
    "country": "United States"
  },
  {
    "id": 455232,
    "name": "Multi-Med Inc    ( West Swanzey NH USA )",
    "country": "United States"
  },
  {
    "id": 456105,
    "name": "Multi-way Medical Co Ltd    ( Cixi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999409,
    "name": "Multiaqua, Inc.    ( Easley SC USA )",
    "country": "United States"
  },
  {
    "id": 462840,
    "name": "Multicard US    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 281201,
    "name": "Multicolor GmbH & Co Druck- und Selbstklebetechnik    ( Ellerau  USA )",
    "country": "United States"
  },
  {
    "id": 106447,
    "name": "Multidata Computer Systems    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 363273,
    "name": "Multidata Medical Physics India Pvt Ltd    ( Baroda  India )",
    "country": "India"
  },
  {
    "id": 391538,
    "name": "Multidata Systems Deutschland GmbH    ( Koblenz  Germany )",
    "country": "Germany"
  },
  {
    "id": 148891,
    "name": "Multidata Systems International Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 186586,
    "name": "Multidata Systems Netherlands bv    ( Soest  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 391539,
    "name": "Multidata Systems UK Ltd    ( Southampton  England )",
    "country": "UK"
  },
  {
    "id": 305713,
    "name": "Multigate Medical Products Pty Ltd    ( Yennora  Australia )",
    "country": "Australia"
  },
  {
    "id": 106505,
    "name": "Multigon Industries Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 332031,
    "name": "Multimage srl    ( Cavaria (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457806,
    "name": "Multiplex Stimulator Ltd    ( Blaine WA USA )",
    "country": "United States"
  },
  {
    "id": 302195,
    "name": "Multisorb Technologies Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 415432,
    "name": "Multisorb Technologies Inc    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 324267,
    "name": "Multitone Electronics plc    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 294199,
    "name": "Multivac    ( Wolfertschwenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 174120,
    "name": "Munatech Co Ltd    ( Saitama  Japan )",
    "country": "Japan"
  },
  {
    "id": 283992,
    "name": "Mundipharma GmbH    ( Limburg an der Lahn  Germany )",
    "country": "Germany"
  },
  {
    "id": 453084,
    "name": "Munire Furniture    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 294200,
    "name": "Munktell Filter AB    ( Falun  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 152420,
    "name": "Muranaka Medical Instruments Co Ltd    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 999638,
    "name": "Murdock Laboratories, Inc.    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 352993,
    "name": "Murphy Furniture Pty Ltd    ( Chipping Norton  Australia )",
    "country": "Australia"
  },
  {
    "id": 416483,
    "name": "Musculoskeletal Transplant Foundation Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 451719,
    "name": "Mushrif Medical    ( Ruwi Muscat  Oman )",
    "country": "Oman"
  },
  {
    "id": 282717,
    "name": "Muss Dental GmbH    ( Wennigsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306271,
    "name": "MUT GmbH    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 267694,
    "name": "Muto Pure Chemicals Co    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 454826,
    "name": "Muzamal Industries Sdn Bhd    ( Cheras  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 293338,
    "name": "MVB Handels AG    ( Aeugstertal  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454978,
    "name": "MVE Biological Systems Div Chart Industries Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 456638,
    "name": "mVisum Inc    ( Camden NJ USA )",
    "country": "United States"
  },
  {
    "id": 467003,
    "name": "MVP Group Corp    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 457694,
    "name": "MVZ Labor PD Dr Volkmann und Kollegen GbR    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 175554,
    "name": "MWG Biotech (Switzerland)    ( Muechenstein  USA )",
    "country": "United States"
  },
  {
    "id": 265822,
    "name": "MWG Biotech (UK) Ltd    ( Milton Keynes  USA )",
    "country": "United States"
  },
  {
    "id": 186103,
    "name": "MWG Biotech A/S    ( Risskov  USA )",
    "country": "United States"
  },
  {
    "id": 418230,
    "name": "MWG Biotech AG    ( Ebersberg  USA )",
    "country": "United States"
  },
  {
    "id": 186361,
    "name": "MWG Biotech France SA    ( Courtaboeuf Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 418231,
    "name": "MWG Biotech Inc    ( High Point NC USA )",
    "country": "United States"
  },
  {
    "id": 233764,
    "name": "MWG Biotech Ltd    ( Cork  USA )",
    "country": "United States"
  },
  {
    "id": 451256,
    "name": "MWG Biotech Pvt Ltd    ( Bangalore  USA )",
    "country": "United States"
  },
  {
    "id": 186134,
    "name": "MWG Biotech srl    ( Firenze FL  USA )",
    "country": "United States"
  },
  {
    "id": 456596,
    "name": "MWV Hemer    ( Hemer  Germany )",
    "country": "Germany"
  },
  {
    "id": 104380,
    "name": "MXE (Medical X-Ray Enterprises) Inc    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 453501,
    "name": "My Weigh Digital Scales    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 199561,
    "name": "Myco Instrumentation Source Inc    ( Renton WA USA )",
    "country": "United States"
  },
  {
    "id": 378083,
    "name": "Myco Medical Supplies Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 454125,
    "name": "Myconostica Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 453415,
    "name": "Mydent International    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 374522,
    "name": "MyDent International Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 461471,
    "name": "MYE Inc    ( Stevenson Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 291974,
    "name": "Myelotec    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 403561,
    "name": "Myers Power Products Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 426530,
    "name": "Myers/CPI    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 161958,
    "name": "Mylan Laboratories Inc    ( Canonsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 106061,
    "name": "Mylin Medical Systems Inc    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 333438,
    "name": "Mylon-Tech Health Technologies Inc    ( Ottawa ON USA )",
    "country": "United States"
  },
  {
    "id": 453412,
    "name": "Myomo Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 458064,
    "name": "Myoscience    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 346070,
    "name": "Myotronics-Noromed Inc    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 454465,
    "name": "Myrenne GmbH    ( Roetgen  Germany )",
    "country": "Germany"
  },
  {
    "id": 104725,
    "name": "Myrenne Instruments Inc    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 471699,
    "name": "Myriad Genetics Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 101527,
    "name": "Myron L Co    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 460781,
    "name": "mySmartSimulations Inc    ( Saratoga Springs NY USA )",
    "country": "United States"
  },
  {
    "id": 282835,
    "name": "Mytron Bio und Solartechnik GmbH    ( Heilbad Heiligenstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 151095,
    "name": "N H Eastwood & Son Ltd    ( Enfield  USA )",
    "country": "United States"
  },
  {
    "id": 460538,
    "name": "N K Precision Machining    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 999707,
    "name": "N S Electronic Service Inc.    ( Atlanta GA USA )",
    "country": "USA"
  },
  {
    "id": 331765,
    "name": "N-K Products Co    ( Lake Elsinore CA USA )",
    "country": "United States"
  },
  {
    "id": 450817,
    "name": "NA Ware    ( Marble Falls TX USA )",
    "country": "United States"
  },
  {
    "id": 397759,
    "name": "Nabi    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 277977,
    "name": "NAC Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 278138,
    "name": "NAC Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 176886,
    "name": "Nada Concepts Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 158351,
    "name": "Nagano Keiki Seisakusho Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 183791,
    "name": "Nagase & Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 170404,
    "name": "Nagase Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 155887,
    "name": "Nagashima Medical Instruments Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999879,
    "name": "Nagata Scale Co.    ( Yung Kang Tainan China )",
    "country": "China"
  },
  {
    "id": 367416,
    "name": "Nagor Ltd    ( Cumbernauld  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 288490,
    "name": "Naigai Sizai Co    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 382777,
    "name": "NAIS Wellnesslife GmbH    ( Duesseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 140080,
    "name": "Nakaden Overseas Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157990,
    "name": "Nakagawa Seikodo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157991,
    "name": "Nakamura Medical Industry Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 199548,
    "name": "Nakanish Optical Products Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 184288,
    "name": "Nakanishi Inc    ( Kanuma  Japan )",
    "country": "Japan"
  },
  {
    "id": 467196,
    "name": "nal von minden GmbH    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 460762,
    "name": "Nalco    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 462768,
    "name": "NALCO Cal Water    ( Placentia CA USA )",
    "country": "United States"
  },
  {
    "id": 103310,
    "name": "Nalge Nunc International    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 469496,
    "name": "Namrol    ( Sitges (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 108508,
    "name": "NAMSA    ( Northwood OH USA )",
    "country": "United States"
  },
  {
    "id": 456201,
    "name": "Nanchang Biotech A & C Biotechnical Industry Inc    ( Nanchang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 459391,
    "name": "Nanion Technologies GmbH    ( Munich  Germany )",
    "country": "Germany"
  },
  {
    "id": 459392,
    "name": "Nanion Technologies Inc    ( North Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 454918,
    "name": "Naniwa Rubber Co Ltd    ( Yamatotakada  Japan )",
    "country": "Japan"
  },
  {
    "id": 457210,
    "name": "Nanjing Perlong Medical Equiptment Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456659,
    "name": "Nanjing Perlove Medical Equipment Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 441532,
    "name": "Nano Technology Systems    ( Thornwood NY USA )",
    "country": "United States"
  },
  {
    "id": 456863,
    "name": "NanoDrop Products    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 452718,
    "name": "NanoDrop Technologies    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 457727,
    "name": "NanoEnTek    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457726,
    "name": "NanoEnTek USA    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 282107,
    "name": "Nanofilm Technologie GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451603,
    "name": "Nanogen    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 451604,
    "name": "Nanogen    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 151112,
    "name": "Nanometrics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 456097,
    "name": "NANOOMTECH Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459342,
    "name": "Nanosight Ltd    ( Amesbury  England )",
    "country": "UK"
  },
  {
    "id": 457538,
    "name": "Nanosonics    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 999657,
    "name": "Nanosonics Ltd    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 452925,
    "name": "Nanosphere Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 457486,
    "name": "NanoString Europe Ltd    ( Banstead  England )",
    "country": "UK"
  },
  {
    "id": 457431,
    "name": "NanoString Technologies Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 458968,
    "name": "NanoTemper Technologies GmbH    ( Munich  Germany )",
    "country": "Germany"
  },
  {
    "id": 456574,
    "name": "Nanotherapeutics Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 469467,
    "name": "NantHealth    ( Culver City CA USA )",
    "country": "United States"
  },
  {
    "id": 455972,
    "name": "Nantong Flexitex Co Ltd    ( Nantong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455973,
    "name": "Nantong Jangsung Gauze Co Ltd    ( Nantong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416525,
    "name": "Naol Australia    ( Taren Point  Australia )",
    "country": "Australia"
  },
  {
    "id": 462656,
    "name": "Napco Security Technologies Inc    ( Amityville NY USA )",
    "country": "United States"
  },
  {
    "id": 186198,
    "name": "Naptech Test Equipment Inc    ( Lower Lake CA USA )",
    "country": "United States"
  },
  {
    "id": 278143,
    "name": "Nara Seiki Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 177267,
    "name": "Narang Enterprises    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 174410,
    "name": "Narcosul Aparelhos Cientificos Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 102294,
    "name": "Narda Microwave East    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 457581,
    "name": "Narishige International USA Inc    ( East Meadow NY USA )",
    "country": "United States"
  },
  {
    "id": 103841,
    "name": "NASCO    ( Fort Atkinson WI USA )",
    "country": "United States"
  },
  {
    "id": 177032,
    "name": "Nascor Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 186199,
    "name": "Nashville Medical Electronics    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 328673,
    "name": "Nasiff Assoc Inc    ( Central Square NY USA )",
    "country": "United States"
  },
  {
    "id": 199815,
    "name": "Nasorcap Medical Inc    ( West Mifflin PA USA )",
    "country": "United States"
  },
  {
    "id": 171164,
    "name": "Nassetti Ettore SpA    ( Trezzano sul Naviglio MI  USA )",
    "country": "United States"
  },
  {
    "id": 455209,
    "name": "Natare Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 471712,
    "name": "Natera Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 102151,
    "name": "National Biological Corp    ( Beachwood OH USA )",
    "country": "United States"
  },
  {
    "id": 459334,
    "name": "National Computer Corp Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 305624,
    "name": "National Diagnostic Products Pty Ltd    ( St Leonards  Australia )",
    "country": "Australia"
  },
  {
    "id": 105654,
    "name": "National Diagnostics    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 457247,
    "name": "National Discount X-ray Supply    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 167625,
    "name": "NATIONAL DISPLAY SYSTEMS    (   USA )",
    "country": "United States"
  },
  {
    "id": 999721,
    "name": "National Electronics    (   USA )",
    "country": "United States"
  },
  {
    "id": 146926,
    "name": "National Hospital Specialties    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 356711,
    "name": "National Imaging Resources    ( Willoughby OH USA )",
    "country": "United States"
  },
  {
    "id": 459335,
    "name": "National Instrument Service Corp    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 195070,
    "name": "National Instruments    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 160119,
    "name": "National Lab GmbH    ( Moelln  Germany )",
    "country": "Germany"
  },
  {
    "id": 455717,
    "name": "National Medical Industry SAE    ( 10th of Ramadan City  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 104281,
    "name": "National Medical Products    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 367403,
    "name": "National Medical Products Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 364493,
    "name": "National Micro Rentals    ( Monroe Township NJ USA )",
    "country": "United States"
  },
  {
    "id": 182625,
    "name": "National Microscope Exchange Inc    ( Carnation WA USA )",
    "country": "United States"
  },
  {
    "id": 465119,
    "name": "National Office Furniture A Unit of Kimball Intern    ( Jasper 12 USA )",
    "country": "United States"
  },
  {
    "id": 459333,
    "name": "National Office Services Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 455668,
    "name": "National Ophthalmics Corp    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 471586,
    "name": "National Optical Instruments Inc    ( Schertz TX USA )",
    "country": "United States"
  },
  {
    "id": 999877,
    "name": "National Optronics Inc.    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 176888,
    "name": "National Power Chair Inc    ( Mound MN USA )",
    "country": "United States"
  },
  {
    "id": 999654,
    "name": "National Presto Industries, Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459215,
    "name": "National Research Corp    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 178302,
    "name": "National Safety Technologies Inc    ( Paoli PA USA )",
    "country": "United States"
  },
  {
    "id": 451494,
    "name": "National Scientific Co    ( Rockwood TN USA )",
    "country": "United States"
  },
  {
    "id": 366846,
    "name": "National Starch and Chemical Co    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 457576,
    "name": "National Technology Services Inc    ( Voorhees NJ USA )",
    "country": "United States"
  },
  {
    "id": 451501,
    "name": "National Ultrasound    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 175899,
    "name": "National Van Builders Inc    ( Attleboro MA USA )",
    "country": "United States"
  },
  {
    "id": 466642,
    "name": "Nationwide Laboratory Services    ( Ft Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 459213,
    "name": "Nationwide Mobile Kitchens    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 467141,
    "name": "Nationwide Power Solutions Inc    ( Henderson NV USA )",
    "country": "United States"
  },
  {
    "id": 455118,
    "name": "Natura Health & Well-Being Ltd    ( Wellington  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 440689,
    "name": "Natural Care Labs Inc    ( Greenbrier TN USA )",
    "country": "United States"
  },
  {
    "id": 401946,
    "name": "Natus (UK) Ltd    ( Chalgrove  USA )",
    "country": "United States"
  },
  {
    "id": 456653,
    "name": "Natus Europe GmbH    ( Planegg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457943,
    "name": "Natus France    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 401932,
    "name": "Natus Japan KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 108193,
    "name": "Natus Medical Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 458814,
    "name": "Natus Neurology Inc    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 458815,
    "name": "Natus Neurology Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 431395,
    "name": "Nautilus Health and Fitness Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 431396,
    "name": "Nautilus Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458588,
    "name": "Nautilus Medical    ( Barrington IL USA )",
    "country": "United States"
  },
  {
    "id": 458950,
    "name": "NAVEX Global    ( Lake Oswego OR USA )",
    "country": "United States"
  },
  {
    "id": 459028,
    "name": "Navicure Inc    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 459204,
    "name": "Navigant Consulting Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 453763,
    "name": "Navilyst Medical    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 441724,
    "name": "Naviscan PET Systems Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 461470,
    "name": "Navix Diagnostix Inc    ( Taunton MA USA )",
    "country": "United States"
  },
  {
    "id": 457916,
    "name": "Neo Healar    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 454855,
    "name": "Navratna Industries    ( Faridabad  India )",
    "country": "India"
  },
  {
    "id": 105678,
    "name": "NBS Medical Inc    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 348505,
    "name": "NBS Technologies Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 155967,
    "name": "NC Systems Inc (Nuclear Cardiology Systems Inc)    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 453187,
    "name": "NCD Corp    ( Eastlake OH USA )",
    "country": "United States"
  },
  {
    "id": 453435,
    "name": "NCE Battery    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 454599,
    "name": "NCG Medical Systems Inc    ( Altamonte Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 453714,
    "name": "nContact Surgical Inc    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 459518,
    "name": "NCR Corp    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 378096,
    "name": "ndd Medical Technologies    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 378095,
    "name": "ndd Medizintechnik AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 426210,
    "name": "NDI    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108319,
    "name": "NDL Products Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 107417,
    "name": "NDR Products    ( Merlin OR USA )",
    "country": "United States"
  },
  {
    "id": 340005,
    "name": "NDS Products Inc    ( Pasadena TX USA )",
    "country": "United States"
  },
  {
    "id": 452717,
    "name": "NDS Surgical Imaging    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 452788,
    "name": "NDS Surgical Imaging (Asia/Pacific)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452787,
    "name": "NDS Surgical Imaging (Europe)    ( Zevenhuizen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453717,
    "name": "Neater Solutions Ltd    ( Buxton  England )",
    "country": "UK"
  },
  {
    "id": 453851,
    "name": "Nebo Systems Inc    ( Oakbrook Terrace IL USA )",
    "country": "United States"
  },
  {
    "id": 271242,
    "name": "NEC America Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 174122,
    "name": "NEC Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 150179,
    "name": "NEC Corp of America Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 999872,
    "name": "NEC Display Solutions of America    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 267418,
    "name": "NEC Medical Systems Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 183463,
    "name": "Nederburgh bv    ( Bunschoten-Spakenburgh  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 227261,
    "name": "NEEN HealthCare    ( Dereham  England )",
    "country": "UK"
  },
  {
    "id": 104272,
    "name": "Negafile Systems    ( Furlong PA USA )",
    "country": "United States"
  },
  {
    "id": 324001,
    "name": "Negretti Automation Ltd    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 426660,
    "name": "Neich Medical    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 157992,
    "name": "Neitz Instruments Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451620,
    "name": "Nektar Therapeutics    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 340007,
    "name": "Nelco    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 328172,
    "name": "Nelipak Ireland    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 294206,
    "name": "Nelipak Nederland    ( Venray  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 294208,
    "name": "Nelipak UK    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 104468,
    "name": "Nellcor Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 273853,
    "name": "Nellcor Puritan Bennett (Melville) Ltd    ( Kanata ON USA )",
    "country": "United States"
  },
  {
    "id": 475950,
    "name": "Nelson Laboratories Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 108221,
    "name": "Nemco Medical Inc    ( Hicksville OH USA )",
    "country": "United States"
  },
  {
    "id": 138326,
    "name": "Nemectron GmbH    ( Karlsruhe  USA )",
    "country": "United States"
  },
  {
    "id": 174123,
    "name": "Nemoto Kyorindo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 370961,
    "name": "Nemoto Medical US Inc    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 104280,
    "name": "Nemschoff Inc    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 333504,
    "name": "Neo Imagery Technologies Inc    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 456421,
    "name": "Neo Medical Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 441790,
    "name": "Neo-Contemporary Co    ( Stoughton MA USA )",
    "country": "United States"
  },
  {
    "id": 461245,
    "name": "Neocase    ( Clemmons NC USA )",
    "country": "United States"
  },
  {
    "id": 464811,
    "name": "NeoChord Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 192828,
    "name": "Neodent SA    ( Thessaloniki  Greece )",
    "country": "Greece"
  },
  {
    "id": 308507,
    "name": "Neogen Corp    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 272718,
    "name": "Neoligaments Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 457333,
    "name": "Neoligaments Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 454582,
    "name": "NeoMatrix    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 283605,
    "name": "Neomed GmbH    ( Korschenbroich  Germany )",
    "country": "Germany"
  },
  {
    "id": 457735,
    "name": "Neomed Inc    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 287834,
    "name": "Neomed Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 454061,
    "name": "NeoMedix Corp    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 184140,
    "name": "Neomedix Systems Pty Ltd    ( Belrose  Australia )",
    "country": "Australia"
  },
  {
    "id": 457269,
    "name": "Neomend Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 333593,
    "name": "Neometrics Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 329719,
    "name": "Neopharm Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 459312,
    "name": "Neopost USA Inc    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 186204,
    "name": "Neoprobe Corp    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 458668,
    "name": "Neoss Ltd    ( Harrogate  England )",
    "country": "UK"
  },
  {
    "id": 439971,
    "name": "NeoSurg Technologies Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 456308,
    "name": "Neotec Medical Industries Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 108212,
    "name": "Neotech Products Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 453469,
    "name": "Neoteric Technology Ltd    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 146928,
    "name": "Neoterik Health Technologies Inc    ( Woodsboro MD USA )",
    "country": "United States"
  },
  {
    "id": 394316,
    "name": "Neotonus Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 457634,
    "name": "NeoTract Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 454063,
    "name": "Neovasc Inc    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 418663,
    "name": "Neoventa Medical AB    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452285,
    "name": "Neoventa Medical Inc    ( Reading MA USA )",
    "country": "United States"
  },
  {
    "id": 454740,
    "name": "NeoVista Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 460548,
    "name": "Nephros Inc    ( River Edge NJ USA )",
    "country": "United States"
  },
  {
    "id": 458936,
    "name": "NEPS LLC    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 400981,
    "name": "Neptec    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 400979,
    "name": "Neptec International    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 302719,
    "name": "NERL Diagnostics    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 466783,
    "name": "NEScientific Inc    ( Waterbury CT USA )",
    "country": "United States"
  },
  {
    "id": 358223,
    "name": "NESS Neuromuscular Electrical Stimulation Systems    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 309214,
    "name": "Nessler Medizintechnik GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 357012,
    "name": "Nestle Clinical Nutrition    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 325131,
    "name": "Nestor Rehab Products    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 458927,
    "name": "Net Health Systems Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 434190,
    "name": "net-RIS.com Inc    ( Welland ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460546,
    "name": "NetApp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 457348,
    "name": "Netblue Co Ltd    ( Daegu  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 469521,
    "name": "NetBrain Technologies Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 461365,
    "name": "NetDimensions    ( Wan Chai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 106026,
    "name": "Netech Corp    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 459228,
    "name": "NetLearning    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 452151,
    "name": "Netsmart Technologies    ( Great River NY USA )",
    "country": "United States"
  },
  {
    "id": 363217,
    "name": "Netward Software Inc    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 465181,
    "name": "Network Billing Systems, LLC (DBA Fusion)    ( New York 42 USA )",
    "country": "United States"
  },
  {
    "id": 465055,
    "name": "Network Hardware Resale, LLC.    ( Santa Barbara 65 USA )",
    "country": "United States"
  },
  {
    "id": 349684,
    "name": "Network Medical Products Ltd    ( Ripon  England )",
    "country": "UK"
  },
  {
    "id": 460789,
    "name": "Network Technologies Inc    ( Aurora OH USA )",
    "country": "United States"
  },
  {
    "id": 286108,
    "name": "Netzsch Geraetebau GmbH    ( Selb  Germany )",
    "country": "Germany"
  },
  {
    "id": 451285,
    "name": "Neue Magnetodyn GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287115,
    "name": "Neue Ornapress GmbH    ( Waldshut-Tiengen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453946,
    "name": "Neuisys LLC    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 286388,
    "name": "Neukum-Elektronik GmbH    ( Straubenhardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 186205,
    "name": "Neuman & Assoc    ( Elizabethtown KY USA )",
    "country": "United States"
  },
  {
    "id": 325132,
    "name": "NeuMed Inc    ( West Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 460811,
    "name": "NeuMeDx Inc    ( Bristol PA USA )",
    "country": "United States"
  },
  {
    "id": 999768,
    "name": "Neuralynx    (   USA )",
    "country": "United States"
  },
  {
    "id": 458723,
    "name": "Neuralynx Inc    ( Bozeman MT USA )",
    "country": "United States"
  },
  {
    "id": 451968,
    "name": "NEURELEC    ( Vallauris Cedex  France )",
    "country": "France"
  },
  {
    "id": 451969,
    "name": "NEURELEC GmbH    ( Saarbruecken  Germany )",
    "country": "Germany"
  },
  {
    "id": 452759,
    "name": "NEURELEC Middle East    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 451970,
    "name": "NEURELEC UK Ltd    ( Manchester  USA )",
    "country": "United States"
  },
  {
    "id": 474893,
    "name": "Neuro Alert Monitoring Services    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 416256,
    "name": "Neuro Care    ( Salem OR USA )",
    "country": "United States"
  },
  {
    "id": 102994,
    "name": "Neuro Diagnostics Inc Div Luther Medical Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 452999,
    "name": "Neuro Fitness LLC    ( Snoqualmie WA USA )",
    "country": "United States"
  },
  {
    "id": 454656,
    "name": "Neuro Kinetics Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 462921,
    "name": "Neuro Logics Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 452388,
    "name": "Neuro Resource Group Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 105216,
    "name": "Neuro Technology Inc    ( Kerrville TX USA )",
    "country": "United States"
  },
  {
    "id": 466242,
    "name": "Neuro-tec    ( Kennesaw 97 USA )",
    "country": "United States"
  },
  {
    "id": 106716,
    "name": "NeuroCom International Inc    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 302714,
    "name": "NeuroControl Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 226955,
    "name": "NeuroDyne Medical Corp    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 462976,
    "name": "NeuroGym Technologies Inc    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451663,
    "name": "NeuroLogica Corp    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 458474,
    "name": "NeuroMetrix Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 999499,
    "name": "NeuroMetrix, Inc.    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 459278,
    "name": "NeuroMetrx Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454244,
    "name": "Neuronetics Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 463036,
    "name": "NeuroPace Inc    ( Mountain Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 108364,
    "name": "Neuropedic    ( West Hazleton PA USA )",
    "country": "United States"
  },
  {
    "id": 234813,
    "name": "NeuroPhysics Corp    ( Shirley MA USA )",
    "country": "United States"
  },
  {
    "id": 456941,
    "name": "Neuroptics Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 187063,
    "name": "Neurotec Pesquisa e Desenvolvimento em Biomedicina    ( Itajuba-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 458671,
    "name": "NeuroTek Corp    ( Wheat Ridge CO USA )",
    "country": "United States"
  },
  {
    "id": 453376,
    "name": "NeuroTherm Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 453377,
    "name": "NeuroTherm NL    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453375,
    "name": "NeuroTherm UK    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 331193,
    "name": "Neurotron Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 398979,
    "name": "NeuroVasx Inc    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 458586,
    "name": "NeuroVirtual    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 454924,
    "name": "Neurovision Medical Products Inc    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 457996,
    "name": "Neurowave Medical Technologies    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 456423,
    "name": "Neusoft America Inc    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 457951,
    "name": "Neusoft Corp    ( Shenyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439569,
    "name": "NEUsoft Digital Medical Systems Co Ltd    ( Shenyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456425,
    "name": "Neusoft Europe AG    ( Appenzell  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 409872,
    "name": "Neusoft Medical Systems Co Ltd    ( Shenyang  USA )",
    "country": "United States"
  },
  {
    "id": 456908,
    "name": "Neusoft Medical Systems USA    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 280820,
    "name": "Neutech GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 101553,
    "name": "Neutron Products Inc    ( Dickerson MD USA )",
    "country": "United States"
  },
  {
    "id": 378261,
    "name": "Neutronics Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 460812,
    "name": "Neutronix Quintel    ( Morgan Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 457034,
    "name": "NeuWave Medical    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 170071,
    "name": "Neuweiler AG    ( Waldstatt  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 340011,
    "name": "Nev's Ink Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 429375,
    "name": "Nevada X-Ray Inc    ( Paterson NJ USA )",
    "country": "United States"
  },
  {
    "id": 453019,
    "name": "New Brunswick Scientific Benelux BV    ( Nijmegen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103311,
    "name": "New Brunswick Scientific Co Inc    ( Enfield CT USA )",
    "country": "United States"
  },
  {
    "id": 452933,
    "name": "New Brunswick Scientific GmbH    ( Nuertingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453020,
    "name": "New Brunswick Scientific UK Ltd    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 441252,
    "name": "New Care Therapies Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 412549,
    "name": "New Deantronics Europe (Ltd)    ( South Woodchester  USA )",
    "country": "United States"
  },
  {
    "id": 402856,
    "name": "New Deantronics Ltd (USA)    ( Walnut Creek CA USA )",
    "country": "United States"
  },
  {
    "id": 402681,
    "name": "New Deantronics Taiwan Ltd    ( Taipei Hsien  USA )",
    "country": "United States"
  },
  {
    "id": 457421,
    "name": "New England Compounding Center (NECC)    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 462804,
    "name": "New England Controls Inc    ( Mansfield MA USA )",
    "country": "United States"
  },
  {
    "id": 461060,
    "name": "New England Laboratory Casework Co Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 320787,
    "name": "New England Medical Corp    ( Walden NY USA )",
    "country": "United States"
  },
  {
    "id": 462807,
    "name": "New England Medical Transcription Inc    ( Woolwich ME USA )",
    "country": "United States"
  },
  {
    "id": 331783,
    "name": "New England Ophthalmic    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 452184,
    "name": "New England Ophthalmic Instrument Service Co LLC    ( Deep River CT USA )",
    "country": "United States"
  },
  {
    "id": 187450,
    "name": "New Horizons Diagnostics Corp    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 465123,
    "name": "New Horizons Worldwide, Inc.    ( West Conshohocken 10 USA )",
    "country": "United States"
  },
  {
    "id": 106185,
    "name": "New Jersey X-Ray Corp    ( North Arlington NJ USA )",
    "country": "United States"
  },
  {
    "id": 450930,
    "name": "New Laser Science Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 454375,
    "name": "New Life Systems Inc    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 307393,
    "name": "New Medica (Pty) Ltd    ( Alberton  USA )",
    "country": "United States"
  },
  {
    "id": 452895,
    "name": "New Medical Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456677,
    "name": "New Precise (Ningbo) Instrument Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 325133,
    "name": "New Solutions    ( Riverside CA USA )",
    "country": "United States"
  },
  {
    "id": 384286,
    "name": "New Splint plc    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 184304,
    "name": "New Star Lasers Inc    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 175085,
    "name": "New Starlab snc    ( Castel San Giovanni PC  Italy )",
    "country": "Italy"
  },
  {
    "id": 460786,
    "name": "New Tech Computer Systems    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 459299,
    "name": "New Wave Research    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 391936,
    "name": "New Wave Software Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 456706,
    "name": "New Wave Surgical Corp    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 441577,
    "name": "New World Assoc Inc    ( Fredericksburg VA USA )",
    "country": "United States"
  },
  {
    "id": 290863,
    "name": "New World Medical Inc    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 469425,
    "name": "New York Microscope Co Inc    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 462858,
    "name": "New-Tech    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 457796,
    "name": "Newage Testing Instruments Inc    ( Feasterville PA USA )",
    "country": "United States"
  },
  {
    "id": 451459,
    "name": "Newark Dental-Pemco    ( Springfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 465112,
    "name": "NewBold Corp    ( Rocky Mount VA USA )",
    "country": "United States"
  },
  {
    "id": 999898,
    "name": "NewBold Corporation    (   USA )",
    "country": "United States"
  },
  {
    "id": 307394,
    "name": "Newco Medical    ( Durban  USA )",
    "country": "United States"
  },
  {
    "id": 451754,
    "name": "Newdeal    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 456795,
    "name": "Newegg.com    ( Whittier CA USA )",
    "country": "United States"
  },
  {
    "id": 999427,
    "name": "Newline Mii Wintime International, Inc.    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 456468,
    "name": "Newman Medical    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 452650,
    "name": "Newmarket Laboratories    ( Newmarket  England )",
    "country": "UK"
  },
  {
    "id": 453829,
    "name": "Newmatic Medical    ( Caledonia MI USA )",
    "country": "United States"
  },
  {
    "id": 457824,
    "name": "Newmedic Systems    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 460810,
    "name": "NewMedical Technology Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 440905,
    "name": "Newport Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 453037,
    "name": "Newport Electronics GmbH    ( Deckenpfronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 102609,
    "name": "Newport Electronics Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 453036,
    "name": "Newport Electronics Inc (Canada)    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453038,
    "name": "Newport Electronics Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 453039,
    "name": "Newport Electronics Spol sro    ( Karvina  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 187402,
    "name": "Newport Medical Instruments Europe    ( Barton-upon-Humber  England )",
    "country": "UK"
  },
  {
    "id": 105093,
    "name": "Newport Medical Instruments Inc    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 466263,
    "name": "NewSound Technology Co Ltd    ( Xiamen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455974,
    "name": "NewTech Medical Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456550,
    "name": "Newwave Medical LLC    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 455757,
    "name": "Nex Medical Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451613,
    "name": "Nexa Orthopedics    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 312335,
    "name": "nexAir Medical Technologies    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 399133,
    "name": "Nexan Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 458971,
    "name": "Nexcelom Bioscience LLC    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 459370,
    "name": "Nexel Industries Inc    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 453994,
    "name": "Nexeon MedSystems Inc    ( Charleston WV USA )",
    "country": "United States"
  },
  {
    "id": 441154,
    "name": "NexGen Ergonomics Inc    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 458820,
    "name": "Nexstim Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 459279,
    "name": "Next Advance Inc    ( Averill Park NY USA )",
    "country": "United States"
  },
  {
    "id": 170923,
    "name": "Next Generation Co    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 460784,
    "name": "NexTalk Inc    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 454594,
    "name": "Nextech Systems Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 460783,
    "name": "NextEngine Inc    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 466661,
    "name": "NextGate Solutions Inc    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 403486,
    "name": "NextGen Healthcare Information Systems Inc    ( Horsham PA USA )",
    "country": "United States"
  },
  {
    "id": 362281,
    "name": "Nextron Medical Technologies    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 452842,
    "name": "NEXUS AG    ( Villingen-Schwenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456450,
    "name": "Nexus Dx    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457670,
    "name": "Nexus Medical LLC    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 454171,
    "name": "NEXUS/Medos AG    ( Langenselbold  Germany )",
    "country": "Germany"
  },
  {
    "id": 293216,
    "name": "Neyco Dental AG    ( Basle  USA )",
    "country": "United States"
  },
  {
    "id": 450684,
    "name": "NHS Logistics    ( Maidstone  England )",
    "country": "UK"
  },
  {
    "id": 457874,
    "name": "NI Medical USA    ( Streetsboro OH USA )",
    "country": "United States"
  },
  {
    "id": 377759,
    "name": "NICA Power Battery Corp    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 186208,
    "name": "Nical Imaging    ( Hastings on Hudson NY USA )",
    "country": "United States"
  },
  {
    "id": 271774,
    "name": "Nical SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460832,
    "name": "NICE Systems Inc    ( Paramus NJ USA )",
    "country": "United States"
  },
  {
    "id": 452210,
    "name": "Nice-Pak International Ltd    ( Flint  Wales )",
    "country": "Wales"
  },
  {
    "id": 232596,
    "name": "Niche Medical Inc    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 306348,
    "name": "Nichimen Europe plc    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 455969,
    "name": "Nichirei Biosciences Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 171391,
    "name": "Nicholls and Clarke Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 305759,
    "name": "Nichrominox    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 454669,
    "name": "NICO Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 160120,
    "name": "Nicolai Apparatebau GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 179817,
    "name": "Nicolet Biomedical Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 185598,
    "name": "Nicolet Biomedical Inc (Asia/Pacific)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 366534,
    "name": "Nicolet Biomedical Japan Inc    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 185599,
    "name": "Nicolet Biomedical Ltd    ( Warwick  USA )",
    "country": "United States"
  },
  {
    "id": 359490,
    "name": "Nicolet Vascular    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 460739,
    "name": "Nicomac Systems Inc    ( Tenafly NJ USA )",
    "country": "United States"
  },
  {
    "id": 382714,
    "name": "Nicore Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 460730,
    "name": "NICVIEW    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 455712,
    "name": "NID Medical Co    ( Alexandria  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 175521,
    "name": "NIDEK Co Ltd    ( Gamagori  Japan )",
    "country": "Japan"
  },
  {
    "id": 152421,
    "name": "NIDEK Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 105861,
    "name": "Nidek Medical Products Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 175496,
    "name": "Nidek SA    ( Creteil Cedex  France )",
    "country": "France"
  },
  {
    "id": 467392,
    "name": "NIDHI Meditech Systems    ( Ahmedabad  India )",
    "country": "India"
  },
  {
    "id": 455694,
    "name": "Nigale Biomedical Inc of Sichuan Province Corp    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 174686,
    "name": "Nighthawk Mfg Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 453867,
    "name": "NightHawk Radiology Services LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 452098,
    "name": "Nightingale Care Beds Ltd    ( Wrexham  England )",
    "country": "UK"
  },
  {
    "id": 465136,
    "name": "Nightingale Chairs    ( Mississauga 23 USA )",
    "country": "United States"
  },
  {
    "id": 458463,
    "name": "Nightingale Informatix    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 452270,
    "name": "Nightingale Informatix Corp    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 292058,
    "name": "Nightingale Sales Inc    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 452269,
    "name": "Nightingale VantageMed Corp    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 416081,
    "name": "Nihon 3B Scientific    ( Niigata  Japan )",
    "country": "Japan"
  },
  {
    "id": 285895,
    "name": "Nihon Bruker Daltonics KK    ( Ibaraki  Japan )",
    "country": "Japan"
  },
  {
    "id": 289257,
    "name": "Nihon Cochlear Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 130211,
    "name": "NIHON KOHDEN AMERICA    (   USA )",
    "country": "United States"
  },
  {
    "id": 103211,
    "name": "Nihon Kohden America Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 154416,
    "name": "Nihon Kohden Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 226303,
    "name": "Nihon Kohden Europe GmbH    ( Rosbach vor der Hoehe  Germany )",
    "country": "Germany"
  },
  {
    "id": 176868,
    "name": "Nihon Light Inc    ( Kobe  Japan )",
    "country": "Japan"
  },
  {
    "id": 228028,
    "name": "Nihon Mediphysics Co Ltd    ( Nishinomiya City  USA )",
    "country": "United States"
  },
  {
    "id": 367133,
    "name": "Nihon Medrad KK    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 276656,
    "name": "Nihon Molecular Devices    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 152422,
    "name": "Nihon Seimitsu Sokki Co Ltd (JPI)    ( Gumma  Japan )",
    "country": "Japan"
  },
  {
    "id": 174479,
    "name": "Nihon SiberHegner KK    ( Minato-ku  Japan )",
    "country": "Japan"
  },
  {
    "id": 455182,
    "name": "Nihon Waters KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451273,
    "name": "Nik Rahnama Kar Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 150938,
    "name": "Nikkiso Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454759,
    "name": "NIKKISO Europe GmbH    ( Langenhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454757,
    "name": "NIKKISO-Therm Co Ltd    ( Musashino  Japan )",
    "country": "Japan"
  },
  {
    "id": 440729,
    "name": "Nikkiso/YSI Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 285708,
    "name": "Niklaus Straub Chirurgische Instrumenten Fabrik    ( Renquishausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 193933,
    "name": "Niko Surgical Ltd    ( Stonehouse  England )",
    "country": "UK"
  },
  {
    "id": 107065,
    "name": "Nikomed USA Inc    ( Huntingdon Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 394359,
    "name": "Nikon AG    ( Egg/ZH  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 394356,
    "name": "Nikon Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 155691,
    "name": "Nikon Corp    ( Kawasaki  Japan )",
    "country": "Japan"
  },
  {
    "id": 394357,
    "name": "Nikon France SA    ( Champigny-sur-Marne Cedex  France )",
    "country": "France"
  },
  {
    "id": 160121,
    "name": "Nikon GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 332097,
    "name": "On-Line Consultant Software    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 155692,
    "name": "Nikon Instruments Europe bv    ( Amstelveen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103312,
    "name": "Nikon Instruments Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 394358,
    "name": "Nikon Instruments SpA    ( Calenzano (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 394355,
    "name": "Nikon Malaysia Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 394354,
    "name": "Nikon Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 305990,
    "name": "Nikon UK Ltd    ( Kingston upon Thames  England )",
    "country": "UK"
  },
  {
    "id": 155119,
    "name": "Niland Co    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 358484,
    "name": "Nilfisk-Advance America Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 360628,
    "name": "Nilfisk-Advance Canada Co    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 358485,
    "name": "Nilfisk-Advance Nordic A/S    ( Broendby  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 161167,
    "name": "NIM srl    ( Verona (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451239,
    "name": "Nima Pouyesh Teb Ltd Co    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 460736,
    "name": "Nimbic Systems Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 451868,
    "name": "Nimgbo Haishu Qualik Opatoelectronics Corp    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 464092,
    "name": "NinePoint Medical    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 455994,
    "name": "Ningbo Cibei Medical Treatment Appliance Co Ltd    ( Cixi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291430,
    "name": "Ningbo David Medical Device Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456002,
    "name": "Ningbo ETDZ Huixing Trade Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456003,
    "name": "Ningbo Fuchun Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456103,
    "name": "Ningbo Hecai Medical Equipment Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456670,
    "name": "Ningbo Kaipo Electron Instrument and Meter Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456104,
    "name": "Ningbo Lecount Daily Commodities Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456106,
    "name": "Ningbo Shengheng Optics & Electronics Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456672,
    "name": "Ningbo Tianyi Medical Appliances Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455677,
    "name": "Ningbo Xingaoyi Magnetism Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291855,
    "name": "Ningbo YaTai Medical Instrument Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415965,
    "name": "Ningbo Yongxin Optical Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999580,
    "name": "Nintendo    (   USA )",
    "country": "United States"
  },
  {
    "id": 179166,
    "name": "Nippon Dionex KK    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 450519,
    "name": "Nippon Gatan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157994,
    "name": "Nippon Infrared Industry Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157674,
    "name": "Nippon Kayaku Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157995,
    "name": "Nippon Microscope Works Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455970,
    "name": "Nippon Sigmax Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457029,
    "name": "Nippon Thermo Fisher KK    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 322425,
    "name": "Nipro (Thailand) Corp Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 108799,
    "name": "Optimed Inc    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 393200,
    "name": "Nipro Asia Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 393202,
    "name": "Nipro Asia Pte Ltd (Dubai)    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 393201,
    "name": "Nipro Asia Pte Ltd (India)    ( Nungambakkam Chennai  India )",
    "country": "India"
  },
  {
    "id": 150872,
    "name": "Nipro Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 456015,
    "name": "Nipro Diagnostics Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 393199,
    "name": "Nipro Europe NV    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 393205,
    "name": "Nipro Europe NV (Holland)    ( Haarlem  USA )",
    "country": "United States"
  },
  {
    "id": 393204,
    "name": "Nipro Europe NV (Milano)    ( Assago MI  USA )",
    "country": "United States"
  },
  {
    "id": 393203,
    "name": "Nipro Europe NV (Spain)    ( San Fernando de Henares  USA )",
    "country": "United States"
  },
  {
    "id": 332740,
    "name": "Nipro Medical Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 393206,
    "name": "Nipro Medical Corp (Argentina)    ( Capital Federal  USA )",
    "country": "United States"
  },
  {
    "id": 393210,
    "name": "Nipro Medical Corp (Chile)    ( Santiago  USA )",
    "country": "United States"
  },
  {
    "id": 393209,
    "name": "Nipro Medical Ltda    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 393212,
    "name": "Nipro Medical Panama SA    ( Panama City  Republic of Panama )",
    "country": "Panama"
  },
  {
    "id": 453798,
    "name": "Nipro Trading (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 393211,
    "name": "Nipromedica de Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 451622,
    "name": "NIR Diagnostics Inc    ( Campbellville ON USA )",
    "country": "United States"
  },
  {
    "id": 459475,
    "name": "NIR Therapeutic    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 149006,
    "name": "Nirad Ltd    ( Antrim  USA )",
    "country": "United States"
  },
  {
    "id": 462880,
    "name": "NiSCA Corp    ( Minamikoma-Gun  Japan )",
    "country": "Japan"
  },
  {
    "id": 177053,
    "name": "Nisco Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 174126,
    "name": "Nishi Nihon MC Co Ltd    ( Fukuoka  Japan )",
    "country": "Japan"
  },
  {
    "id": 452553,
    "name": "Nison Instrument (Shanghai) Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 391314,
    "name": "Nissan Medical Industries (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 391313,
    "name": "Nissan Medical Industries Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 455975,
    "name": "Nissei Japan Precision Instruments Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 289325,
    "name": "Nissho Sangyo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 226302,
    "name": "Nissin Dental Products    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 289326,
    "name": "Nissin Medical Industries Co Ltd    ( Kitanagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 465130,
    "name": "Nitinol Devices & Components Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 101554,
    "name": "Nitrofit    (   USA )",
    "country": "United States"
  },
  {
    "id": 372419,
    "name": "Niva Medical    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 108058,
    "name": "NK Medical Products Inc    ( Clarence NY USA )",
    "country": "United States"
  },
  {
    "id": 452896,
    "name": "NMI (New Medical Imaging)    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 376085,
    "name": "NMT Medical Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 451182,
    "name": "No Rinse Laboratories LLC    ( Springboro OH USA )",
    "country": "United States"
  },
  {
    "id": 106985,
    "name": "NOA Medical Industries Inc    ( Washington MO USA )",
    "country": "United States"
  },
  {
    "id": 287330,
    "name": "Noba Verbandmittel Danz GmbH und Co KG    ( Wetter  Germany )",
    "country": "Germany"
  },
  {
    "id": 273227,
    "name": "Nobel Biocare AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 273228,
    "name": "Nobel Biocare Canada Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 273290,
    "name": "Nobel Biocare USA Inc    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 281275,
    "name": "Nobis Labordiagnostica GmbH    ( Endingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 329641,
    "name": "Noble Pine Products Co    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 459280,
    "name": "Noel Industries Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 453769,
    "name": "NoemaLife SpA    ( Bologna (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 174928,
    "name": "NoIR Medical Technologies    ( South Lyon MI USA )",
    "country": "United States"
  },
  {
    "id": 461274,
    "name": "Noise Barriers LLC    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 474847,
    "name": "NoiseMeters Inc    ( Berkley MI USA )",
    "country": "United States"
  },
  {
    "id": 456324,
    "name": "Nokia    ( Nokia Group  Finland )",
    "country": "Finland"
  },
  {
    "id": 999819,
    "name": "Nokia    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 461313,
    "name": "Noldus Information Technology Inc    ( Leesburg VA USA )",
    "country": "United States"
  },
  {
    "id": 282943,
    "name": "Noll Hygiene Systeme    ( Herne  Germany )",
    "country": "Germany"
  },
  {
    "id": 456613,
    "name": "Nomax Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 237702,
    "name": "NOMOS Radiation Oncology    ( Cranberry Township PA USA )",
    "country": "United States"
  },
  {
    "id": 106951,
    "name": "Non-Invasive Monitoring Systems Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 106455,
    "name": "Nonin Medical Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 453884,
    "name": "Noninvasive Medical Technologies Inc    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 306297,
    "name": "Nopa instruments    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454343,
    "name": "Nopac Healthcare Services Ltd    ( Stapleford Abbotts  England )",
    "country": "UK"
  },
  {
    "id": 410521,
    "name": "Nor E First Response Inc    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 102898,
    "name": "Nor-Lake Scientific    ( Hudson WI USA )",
    "country": "United States"
  },
  {
    "id": 106503,
    "name": "Noram Solutions (Canada)    ( Burlington ON USA )",
    "country": "United States"
  },
  {
    "id": 105933,
    "name": "Noram Solutions (US)    ( Lewiston NY USA )",
    "country": "United States"
  },
  {
    "id": 454718,
    "name": "Norav Medical GmbH    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 456452,
    "name": "Norav Medical Inc    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 227936,
    "name": "Norav Medical Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 193769,
    "name": "Noraxon Oy (Deutschland)    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 185337,
    "name": "Noraxon USA Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 999963,
    "name": "NORCOLD    (   USA )",
    "country": "United States"
  },
  {
    "id": 363477,
    "name": "Norcom Inc    ( Griffin GA USA )",
    "country": "United States"
  },
  {
    "id": 103850,
    "name": "Nordent Mfg Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 343479,
    "name": "Nordic Capital Svenska AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452951,
    "name": "Nordic Care Services Ltd    ( Canterbury  England )",
    "country": "UK"
  },
  {
    "id": 458133,
    "name": "Nordic Medcom AB    ( Boras  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 999613,
    "name": "Nordic Track    ( Logan UT USA )",
    "country": "USA"
  },
  {
    "id": 458649,
    "name": "NordicNeuroLab    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 285578,
    "name": "Nordiska Import    ( Gummersbach  USA )",
    "country": "United States"
  },
  {
    "id": 172859,
    "name": "Nordjysk Dentalfabrik    ( Aalborg SV  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 462533,
    "name": "Nordson MARCH    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 280682,
    "name": "Nordstern-Glaswarenfabrik GmbH & Co KG    ( Clausthal-Zellerfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 104772,
    "name": "Norfolk Medical Products Inc    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 456634,
    "name": "Norgenix    ( Spartanburg SC USA )",
    "country": "United States"
  },
  {
    "id": 455346,
    "name": "Norgesplastor A/S    ( Vennesla  Norway )",
    "country": "Norway"
  },
  {
    "id": 257410,
    "name": "Norian Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 460550,
    "name": "Norix Group Inc    ( West Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 460733,
    "name": "Norkan Inc    ( Warren MI USA )",
    "country": "United States"
  },
  {
    "id": 458781,
    "name": "Norman Noble Inc    ( Highland Heights OH USA )",
    "country": "United States"
  },
  {
    "id": 453772,
    "name": "NormaTec Industries LP    ( Newton Center MA USA )",
    "country": "United States"
  },
  {
    "id": 416094,
    "name": "Normau Beschlaege und Ausstattungs GmbH    ( Renchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 177268,
    "name": "Normax - Fabrica de Vidros Cientificos Ltda    ( Marinha Grande Codex  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 162653,
    "name": "Normeca A/S    ( Loerenskog  Norway )",
    "country": "Norway"
  },
  {
    "id": 321497,
    "name": "Normeca Asia    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454331,
    "name": "NORMED Medizin-Technik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 177029,
    "name": "Norseld Pty Ltd    ( Adelaide  Australia )",
    "country": "Australia"
  },
  {
    "id": 101557,
    "name": "Nortech Laboratories Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 301104,
    "name": "Nortech Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 451475,
    "name": "Nortel    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 150907,
    "name": "North American Caseline Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 170547,
    "name": "North American Imaging Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 462861,
    "name": "North American Instruments    ( Marshfield WI USA )",
    "country": "United States"
  },
  {
    "id": 331793,
    "name": "North American Magnetics Corp    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457650,
    "name": "North American Medical Corp    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 462982,
    "name": "North American Partners in Anesthesia (NAPA)    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 392597,
    "name": "North American Rescue Products Inc    ( Greer SC USA )",
    "country": "United States"
  },
  {
    "id": 186210,
    "name": "North American Scientific Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 999918,
    "name": "North American Scientific Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 464967,
    "name": "North Central Instruments    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 107915,
    "name": "North Coast Medi Tek Inc    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 106245,
    "name": "North Coast Medical Equipment Inc    ( Berea OH USA )",
    "country": "United States"
  },
  {
    "id": 106745,
    "name": "North Coast Medical Inc    ( Gilroy CA USA )",
    "country": "United States"
  },
  {
    "id": 458585,
    "name": "North Coast Outfitters Ltd    ( Riverhead NY USA )",
    "country": "United States"
  },
  {
    "id": 452387,
    "name": "North Eos Industries Inc    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 193790,
    "name": "North Safety Products    ( Cranston RI USA )",
    "country": "United States"
  },
  {
    "id": 354553,
    "name": "North Safety Products    ( Heywood  England )",
    "country": "UK"
  },
  {
    "id": 455086,
    "name": "North Safety Products (Australia/New Zealand)    ( Moonee Ponds  Australia )",
    "country": "Australia"
  },
  {
    "id": 455080,
    "name": "North Safety Products (Canada)    ( Anjou PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455083,
    "name": "North Safety Products (Deutschland)    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455087,
    "name": "North Safety Products (Far East)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455085,
    "name": "North Safety Products (France)    ( Pernes-les-Fontaines  France )",
    "country": "France"
  },
  {
    "id": 455084,
    "name": "North Safety Products (Scandinavia)    ( Jonkoping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455081,
    "name": "North Safety Products (The Netherlands)    ( Middleburg  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455082,
    "name": "North Safety Products (United Kingdom)    ( Heywood  England )",
    "country": "UK"
  },
  {
    "id": 454598,
    "name": "North Star Health Products LLC    ( North Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 331194,
    "name": "North Star Systems Inc    ( Ashland MO USA )",
    "country": "United States"
  },
  {
    "id": 183929,
    "name": "Northeast Dental Care    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 186211,
    "name": "Northeast Electronics Inc    ( Derry NH USA )",
    "country": "United States"
  },
  {
    "id": 462757,
    "name": "Northeast Interior Systems    ( Liverpool NY USA )",
    "country": "United States"
  },
  {
    "id": 107650,
    "name": "Northeast Laboratory Services    ( Winslow ME USA )",
    "country": "United States"
  },
  {
    "id": 458569,
    "name": "Northeast Medical Systems Corp    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 365812,
    "name": "NorthEast Monitoring Inc    ( Maynard MA USA )",
    "country": "United States"
  },
  {
    "id": 451631,
    "name": "Northeast Pennsylvania Lions Eye Bank    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 465211,
    "name": "Northeast Petroleum Technologies Inc.    ( Schenectady 29 USA )",
    "country": "United States"
  },
  {
    "id": 466681,
    "name": "Northeast Protection Partners Inc (NEPPS)    ( Parkesburg PA USA )",
    "country": "United States"
  },
  {
    "id": 106063,
    "name": "Northeast Scientific Co    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 305955,
    "name": "Northeastern Sonics    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 458729,
    "name": "Northeastern Technologies Group    ( Glen Cove NY USA )",
    "country": "United States"
  },
  {
    "id": 461128,
    "name": "Northern Air Corp    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 462905,
    "name": "Northern Lights Technology Videoconference (NLTVC)    (   USA )",
    "country": "United States"
  },
  {
    "id": 330050,
    "name": "Northern Software Inc    ( Ironwood MI USA )",
    "country": "United States"
  },
  {
    "id": 106840,
    "name": "Northern Technologies Inc    ( Liberty Lake WA USA )",
    "country": "United States"
  },
  {
    "id": 465103,
    "name": "Northfield Medical Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 102295,
    "name": "Northgate Technologies Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 410414,
    "name": "Northrop Grumman Information Technology    ( McLean VA USA )",
    "country": "United States"
  },
  {
    "id": 312333,
    "name": "Northstar Dental Group    ( Appleton WI USA )",
    "country": "United States"
  },
  {
    "id": 452528,
    "name": "NorthStar Orthopaedics    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 455522,
    "name": "Northwest Health and Safety Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 441568,
    "name": "Northwest Medical Physics Equipment (NMPE)    ( Lynnwood WA USA )",
    "country": "United States"
  },
  {
    "id": 457631,
    "name": "Northwest Professional Hearing    ( Countryside IL USA )",
    "country": "United States"
  },
  {
    "id": 458650,
    "name": "Northwestern Emergency Vehicles Inc    ( Jefferson NC USA )",
    "country": "United States"
  },
  {
    "id": 999693,
    "name": "Northwestern System Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 456068,
    "name": "Norweste Surgical Industries    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 454163,
    "name": "Norwex Enviro Products Inc    ( Dauphin MB Canada )",
    "country": "Canada"
  },
  {
    "id": 281101,
    "name": "Nosbuesch-Verbandstoffe Inh Doris Balling    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 464958,
    "name": "Not Applicable    ( Not Applicable No USA )",
    "country": "United States"
  },
  {
    "id": 453363,
    "name": "Noteworthy Medical Systems Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 435459,
    "name": "Notifier    ( Northford CT USA )",
    "country": "United States"
  },
  {
    "id": 461276,
    "name": "NotifyMD    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 335105,
    "name": "Notoco LLC    ( Ferndale CA USA )",
    "country": "United States"
  },
  {
    "id": 171393,
    "name": "Nottingham Rehab Supplies    ( Ashby de la Zouch  England )",
    "country": "UK"
  },
  {
    "id": 170072,
    "name": "Nouvag AG    ( Goldach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455573,
    "name": "NOUVAG USA Inc    ( Lake Hughes CA USA )",
    "country": "United States"
  },
  {
    "id": 999740,
    "name": "Nouveau Contour USA    ( Orlando FL USA )",
    "country": "USA"
  },
  {
    "id": 451538,
    "name": "Nova Analytics Corp    ( Gold River CA USA )",
    "country": "United States"
  },
  {
    "id": 323709,
    "name": "Nova Biomedical (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 323708,
    "name": "Nova Biomedical Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 102723,
    "name": "Nova Biomedical Corp    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 323710,
    "name": "Nova Biomedical France    ( Les Ulis-Courtaboeuf  France )",
    "country": "France"
  },
  {
    "id": 285869,
    "name": "Nova Biomedical GmbH    ( Roedermark  Germany )",
    "country": "Germany"
  },
  {
    "id": 451419,
    "name": "Nova Biomedical India    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 323711,
    "name": "Nova Biomedical KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 441531,
    "name": "Nova Biomedical UK    ( Runcorn  England )",
    "country": "UK"
  },
  {
    "id": 453694,
    "name": "Nova Century Scientific Inc    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103847,
    "name": "Nova Health Systems Inc    ( Utica NY USA )",
    "country": "United States"
  },
  {
    "id": 307218,
    "name": "Nova Medical (Pty) Ltd    ( Randburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 186829,
    "name": "Nova Ortho-Med Inc    ( Carson CA USA )",
    "country": "United States"
  },
  {
    "id": 134875,
    "name": "Nova Technologies Inc    ( Julesburg CO USA )",
    "country": "United States"
  },
  {
    "id": 159366,
    "name": "Nova Therapeutic Systems    ( South Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 331795,
    "name": "Nova West Technology    ( Los Gatos CA USA )",
    "country": "United States"
  },
  {
    "id": 452338,
    "name": "Novacare GmbH    ( Bad Duerkheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 160260,
    "name": "Novacor    ( Rueil-Malmaison Cedex  France )",
    "country": "France"
  },
  {
    "id": 452481,
    "name": "Novadaq Technologies Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457331,
    "name": "NOVAFON Elektomedizinische Geraete GmbH    ( Weinstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 286472,
    "name": "Novafon GmbH    ( Fellbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 451854,
    "name": "Novagen    ( Gibbstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 451929,
    "name": "Novagen Ltd    ( Prudhoe  England )",
    "country": "UK"
  },
  {
    "id": 999863,
    "name": "Novalis Medical Inc.    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 455364,
    "name": "Novalung GmbH    ( Talheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 149025,
    "name": "NovaMed Corp    ( Trumbull CT USA )",
    "country": "United States"
  },
  {
    "id": 398386,
    "name": "Novamed LLC    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 456436,
    "name": "Novamed Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 439905,
    "name": "Novamedica SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 358810,
    "name": "Novamedical Products GmbH    ( Langenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 391150,
    "name": "NovaMedical Systems Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 186680,
    "name": "Novamedix Services Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 102836,
    "name": "Novametrix Medical Systems Inc    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 435616,
    "name": "Novar AG    ( Neuss  Germany )",
    "country": "Germany"
  },
  {
    "id": 453969,
    "name": "Novar France SA    ( Saint-Quentin-Fallavier Cedex  France )",
    "country": "France"
  },
  {
    "id": 453971,
    "name": "Novar Nederland bv    ( Raamsdonksveer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458459,
    "name": "Novarad Corp    ( American Fork UT USA )",
    "country": "United States"
  },
  {
    "id": 376073,
    "name": "NOVARE Surgical Systems Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 348684,
    "name": "Novartis Consumer Health    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 297516,
    "name": "Novartis Medical Nutrition    ( Fremont MI USA )",
    "country": "United States"
  },
  {
    "id": 455574,
    "name": "Novartis Pharmaceuticals Ltd    ( Chamberley  England )",
    "country": "UK"
  },
  {
    "id": 455020,
    "name": "Novasys Medical Inc    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 453999,
    "name": "NovaTec Immundiagnostica GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 330896,
    "name": "Novatech SA    ( La Ciotat Cedex  France )",
    "country": "France"
  },
  {
    "id": 457428,
    "name": "Novatek International Pty Ltd    ( Bundall  Australia )",
    "country": "Australia"
  },
  {
    "id": 108983,
    "name": "Novatek Medical Inc    ( Greenwich CT USA )",
    "country": "United States"
  },
  {
    "id": 462749,
    "name": "NovaTek Software Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 285308,
    "name": "Novatex GmbH    ( Pattensen  Germany )",
    "country": "Germany"
  },
  {
    "id": 428439,
    "name": "Noveko    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 284676,
    "name": "novel gmbh    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 399212,
    "name": "Novex Pharma Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 158856,
    "name": "Novo Nordisk A/S    ( Bagsvaerd  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451880,
    "name": "ORTHOsoft Inc    ( Montreal  USA )",
    "country": "United States"
  },
  {
    "id": 107818,
    "name": "Novo Nordisk Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 289334,
    "name": "Novo Nordisk Pharma Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 406411,
    "name": "Novobrush GmbH    ( Weidenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 466589,
    "name": "Novocol Pharmaceutical of Canada Inc    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 293232,
    "name": "Novoglas Labortechnik    ( Berne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 293462,
    "name": "Novomag SA    ( Lausanne  USA )",
    "country": "United States"
  },
  {
    "id": 456719,
    "name": "NovoMedicus    ( Nokomis FL USA )",
    "country": "United States"
  },
  {
    "id": 460727,
    "name": "NovoPath Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 431312,
    "name": "NovoSci    ( Conroe TX USA )",
    "country": "United States"
  },
  {
    "id": 186964,
    "name": "Novoste Corp    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 283520,
    "name": "Novotech GmbH    (   USA )",
    "country": "United States"
  },
  {
    "id": 462828,
    "name": "Novus Informatics Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 450911,
    "name": "Novus Medical Imaging Inc    ( Cromwell CT USA )",
    "country": "United States"
  },
  {
    "id": 460584,
    "name": "Novus Surgical Spine    ( Parker CO USA )",
    "country": "United States"
  },
  {
    "id": 451377,
    "name": "Novymed International BV    ( `s-Hertogenbosch  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 460725,
    "name": "NOW Solutions Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 454711,
    "name": "Nowak Dental Supplies Inc    ( Carriere MS USA )",
    "country": "United States"
  },
  {
    "id": 457846,
    "name": "Nowe Klimaty Krakow    (   Poland )",
    "country": "Poland"
  },
  {
    "id": 999495,
    "name": "Nox Medical    (   Iceland )",
    "country": "Iceland"
  },
  {
    "id": 463037,
    "name": "Noxilizer Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 462791,
    "name": "NPE Systems Inc    ( Pembroke Pines FL USA )",
    "country": "United States"
  },
  {
    "id": 458003,
    "name": "nPoint Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 393100,
    "name": "NPS Corp    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 451886,
    "name": "NQ Environmental Inc - France    ( Bouchemaine  France )",
    "country": "France"
  },
  {
    "id": 333446,
    "name": "NQ Environmental Inc - USA    ( Rocky Hill CT USA )",
    "country": "United States"
  },
  {
    "id": 457872,
    "name": "NQ Industries Inc    ( Rocky Hill CT USA )",
    "country": "United States"
  },
  {
    "id": 999989,
    "name": "NQ Industries Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 454869,
    "name": "NRC Picker    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 457414,
    "name": "NRS Healthcare    ( Ashby  England )",
    "country": "UK"
  },
  {
    "id": 454536,
    "name": "NRT-Nordisk Rotgen Teknik A/S    ( Hasselager  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 348592,
    "name": "NS Industria de Aparelisos Medicos Ltd    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 306374,
    "name": "NS Surgical Co    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 105579,
    "name": "NSG Precision Cells Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 416024,
    "name": "NSI Dental Pty Ltd    ( Hornsby  Australia )",
    "country": "Australia"
  },
  {
    "id": 468395,
    "name": "NSI Health Systems LLC    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 457205,
    "name": "NSK Dental LLC    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 284989,
    "name": "NSP Nukleare Sicherheits-Produkte GmbH    ( Nordendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 452016,
    "name": "nSpire Health GmbH    ( Oberthulba  Germany )",
    "country": "Germany"
  },
  {
    "id": 452015,
    "name": "nSpire Health Ltd    ( Hertford  England )",
    "country": "UK"
  },
  {
    "id": 369594,
    "name": "Ntero Surgical Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 456379,
    "name": "NTL Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 170552,
    "name": "NTS Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 101564,
    "name": "Nu Hope Laboratories Inc    ( Pacoima CA USA )",
    "country": "United States"
  },
  {
    "id": 456018,
    "name": "nu-beca & maxcellent co    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 330897,
    "name": "Nu-Sound    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 102724,
    "name": "NuAire Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 451217,
    "name": "Nuance Communications Duetschland GmbH    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451220,
    "name": "Nuance Communications Espana    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 451218,
    "name": "Nuance Communications France    ( Boulogne Billancourt  France )",
    "country": "France"
  },
  {
    "id": 451215,
    "name": "Nuance Communications Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 451216,
    "name": "Nuance Communications International bvba    ( Merelbeke  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 451221,
    "name": "Nuance Communications Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451219,
    "name": "Nuance Communications srl Italy    ( Agrate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451222,
    "name": "Nuance Communications United Kingdom Ltd    ( Woking  England )",
    "country": "UK"
  },
  {
    "id": 452892,
    "name": "NuCel Labs    ( Idaho Falls ID USA )",
    "country": "United States"
  },
  {
    "id": 999891,
    "name": "Nuclear Associates    ( Carle Place NY USA )",
    "country": "United States"
  },
  {
    "id": 173215,
    "name": "Nuclear Diagnostics Ltd    ( Gravesend  England )",
    "country": "UK"
  },
  {
    "id": 349518,
    "name": "Nuclear Fields (Australia) Pty Ltd    ( St Marys  Australia )",
    "country": "Australia"
  },
  {
    "id": 349345,
    "name": "Nuclear Fields bv    ( Boxmeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 340027,
    "name": "Nuclear Fields USA Corp    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 104718,
    "name": "Nuclear Measurements Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 417548,
    "name": "Nuclear Pharmacy Services    ( Woodland Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 183767,
    "name": "Nuclemed NV    ( Roeselare  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454496,
    "name": "Nucletron AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 454493,
    "name": "Nucletron Asia Pacific Ltd (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454494,
    "name": "Nucletron Asia Pacific Ltd (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306941,
    "name": "Nucletron bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454308,
    "name": "Nucletron Canada Inc    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 106839,
    "name": "Nucletron Corp    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 186598,
    "name": "Nucletron Far East (HK) Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 361437,
    "name": "Nucletron India Pvt Ltd    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 361439,
    "name": "Nucletron Poland Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 391160,
    "name": "Nucletron Pty Ltd    ( Newtown  Australia )",
    "country": "Australia"
  },
  {
    "id": 361441,
    "name": "Nucletron SA    ( San Fernando de Henares  Spain )",
    "country": "Spain"
  },
  {
    "id": 361435,
    "name": "Nucletron Sarl    ( Matne la Valle  France )",
    "country": "France"
  },
  {
    "id": 440816,
    "name": "Nucletron Scandinavia AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 361442,
    "name": "Nucletron UK Ltd    ( Chester  USA )",
    "country": "United States"
  },
  {
    "id": 454307,
    "name": "Nucletron UK Ltd    ( Chester  England )",
    "country": "UK"
  },
  {
    "id": 361440,
    "name": "Nucletron-Oldelft    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 361438,
    "name": "Nuclital srl    ( Monza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454495,
    "name": "nuclital srl    ( Monza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 284443,
    "name": "Nudischer Medcompany    ( Messstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 450550,
    "name": "Nuell Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 356719,
    "name": "Nuesoft    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 458973,
    "name": "NuGEN Technologies Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 454457,
    "name": "Nukepills.com    ( Mooresville NC USA )",
    "country": "United States"
  },
  {
    "id": 104947,
    "name": "NuLab Supplies    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 186221,
    "name": "Numa Inc    ( Amherst NH USA )",
    "country": "United States"
  },
  {
    "id": 108797,
    "name": "NuMED Inc    ( Hopkinton NY USA )",
    "country": "United States"
  },
  {
    "id": 135433,
    "name": "Numed Inc    ( Denton TX USA )",
    "country": "United States"
  },
  {
    "id": 403760,
    "name": "NuMedics Inc    ( Tigard OR USA )",
    "country": "United States"
  },
  {
    "id": 464106,
    "name": "Numedis Inc    ( Isanti MN USA )",
    "country": "United States"
  },
  {
    "id": 458101,
    "name": "Numia Medical Technology LLC    ( Newport VT USA )",
    "country": "United States"
  },
  {
    "id": 102613,
    "name": "Numonics Corp    ( Montgomeryville PA USA )",
    "country": "United States"
  },
  {
    "id": 466313,
    "name": "Numotion    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 160123,
    "name": "Nunc GmbH    ( Wiesbaden  USA )",
    "country": "United States"
  },
  {
    "id": 471630,
    "name": "Nuo Therapeutics Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 452541,
    "name": "Nuova Blandino SpA    ( Mappano TO  Italy )",
    "country": "Italy"
  },
  {
    "id": 460950,
    "name": "NuPhysicia Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 999807,
    "name": "Nurad Medical Solutions LLC    ( Brea CA US )",
    "country": "US"
  },
  {
    "id": 435617,
    "name": "NUROMED Ltd    ( Wigan  England )",
    "country": "UK"
  },
  {
    "id": 433347,
    "name": "Nurse Assist Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 440374,
    "name": "Nurse Assist Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 459426,
    "name": "Nursenav Oncology    ( North Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 450834,
    "name": "NuSil Technology    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 321008,
    "name": "NuStep Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 459150,
    "name": "Nutech    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 467208,
    "name": "Nutek Disposables Inc    ( McElhattan PA USA )",
    "country": "United States"
  },
  {
    "id": 459205,
    "name": "Nutek Orthopaedics    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 102614,
    "name": "Nutheme Illustrated Safety Co    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 273292,
    "name": "NutraMax    ( Gloucester MA USA )",
    "country": "United States"
  },
  {
    "id": 455537,
    "name": "Nutricia Australia Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 300154,
    "name": "Nutricia Clinical Care    ( Trowbridge  England )",
    "country": "UK"
  },
  {
    "id": 455532,
    "name": "Nutricia GmbH    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455536,
    "name": "Nutricia Netherland bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455530,
    "name": "Nutricia North America-Canada    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455529,
    "name": "Nutricia North America-US    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 455535,
    "name": "Nutriciai Advanced Medical Nutrition    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 455533,
    "name": "Nutriciai Norge As    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 455534,
    "name": "Nutriciai sro    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 170553,
    "name": "Nutronics Imaging Inc    ( Woodbury NY USA )",
    "country": "United States"
  },
  {
    "id": 385367,
    "name": "NuVasive Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456848,
    "name": "Nuve Inc    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 318339,
    "name": "Nuve Sanayi Malzemeleri Imalat Ve Ticaret AS    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 999852,
    "name": "NuVision    (   USA )",
    "country": "United States"
  },
  {
    "id": 428382,
    "name": "Nuvo Inc    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 454250,
    "name": "Nuvon Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 999492,
    "name": "NuWave, LLC    ( Libertyville IL USA )",
    "country": "USA"
  },
  {
    "id": 366840,
    "name": "NV Endoscopie    ( Gent/Drongen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 462790,
    "name": "NVT Systems Pty Ltd    ( Torrensville  Australia )",
    "country": "Australia"
  },
  {
    "id": 226191,
    "name": "NWL-Laser Technologies GmbH    ( Ottensoos  Germany )",
    "country": "Germany"
  },
  {
    "id": 451296,
    "name": "NWT Infotech Services Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 464963,
    "name": "NXC Imaging    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 451481,
    "name": "NxStage Medical Inc    ( Lawrence MA USA )",
    "country": "United States"
  },
  {
    "id": 458976,
    "name": "Nycom Inc    ( Monroeville PA USA )",
    "country": "United States"
  },
  {
    "id": 307308,
    "name": "Nycomed Asia Pacific Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 108366,
    "name": "Nytone Medical Products    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 455336,
    "name": "Nyx Technik Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 392656,
    "name": "NZ Alltech Inc    ( Auckland 10  USA )",
    "country": "United States"
  },
  {
    "id": 177072,
    "name": "NZ Mfg LLC    ( Stow OH USA )",
    "country": "United States"
  },
  {
    "id": 305229,
    "name": "NZ Software Corp Ltd d/b/a Houston Medical    ( Hamilton  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 457794,
    "name": "NZOZ ONKOMED Agnieszka Wojcik    ( Warsaw  Poland )",
    "country": "Poland"
  },
  {
    "id": 293805,
    "name": "O W Heinze & Co    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 102196,
    "name": "O-Two Medical Technologies Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 465005,
    "name": "O.C. Tanner    ( Salt Lake City 19 USA )",
    "country": "United States"
  },
  {
    "id": 459346,
    "name": "O.R. Surgical Co Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 417702,
    "name": "O'Brien Dental Lab Inc    ( Corvallis OR USA )",
    "country": "United States"
  },
  {
    "id": 431503,
    "name": "O'Dell Engineering Ltd    ( Puslinch ON Canada )",
    "country": "Canada"
  },
  {
    "id": 107435,
    "name": "O'Ryan Industries Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 460960,
    "name": "OAISYS    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 439858,
    "name": "Oakfield Instruments Inc (USA)    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 324642,
    "name": "Oakfield Instruments Ltd    ( Eynsham  USA )",
    "country": "United States"
  },
  {
    "id": 177073,
    "name": "Oakworks Inc    ( New Freedom PA USA )",
    "country": "United States"
  },
  {
    "id": 455218,
    "name": "Oasis Lifestyle LLC    ( Plymouth IN USA )",
    "country": "United States"
  },
  {
    "id": 174931,
    "name": "Oasis Medical    ( Glendora CA USA )",
    "country": "United States"
  },
  {
    "id": 454388,
    "name": "OASYS Healthcare    ( Uxbridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 293218,
    "name": "OBA AG    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458176,
    "name": "Obalon Therapeutics    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 138492,
    "name": "Oberlikon Leybold Vacuum AG    ( Hanau 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 177075,
    "name": "Oberon Co    ( New Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 306480,
    "name": "Obex Medical Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 460979,
    "name": "Objective Medical Systems    ( Houma LA USA )",
    "country": "United States"
  },
  {
    "id": 107890,
    "name": "Oblique Filing Systems    ( Irmo SC USA )",
    "country": "United States"
  },
  {
    "id": 293492,
    "name": "Obrist Interior AG    ( Lucern 11  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 397795,
    "name": "Obtura/Spartan Canada    ( Burlington ON USA )",
    "country": "United States"
  },
  {
    "id": 397112,
    "name": "Obtura/Spartan USA    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 104286,
    "name": "Obus Forme Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456127,
    "name": "ObvieLine    ( Dardilly  France )",
    "country": "France"
  },
  {
    "id": 431510,
    "name": "Occidental Chemical Corp    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 175905,
    "name": "OccuNomix International Inc    ( Port Jefferson Station NY USA )",
    "country": "United States"
  },
  {
    "id": 451994,
    "name": "Occupational Health Dynamics    ( Pelham AL USA )",
    "country": "United States"
  },
  {
    "id": 466799,
    "name": "Occupational Health Systems Inc    ( Alamo CA USA )",
    "country": "United States"
  },
  {
    "id": 461218,
    "name": "Occupational Performance Corp    ( Salina KS USA )",
    "country": "United States"
  },
  {
    "id": 392782,
    "name": "Ocean Optics bv    ( Duiven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 377119,
    "name": "Ocean Optics Inc    ( Dunedin FL USA )",
    "country": "United States"
  },
  {
    "id": 391185,
    "name": "Oceanic Medical Products Inc    ( Atchison KS USA )",
    "country": "United States"
  },
  {
    "id": 456554,
    "name": "Ocelco Inc    ( Brainerd MN USA )",
    "country": "United States"
  },
  {
    "id": 305469,
    "name": "Ocenco Inc    ( Pleasant Prairie WI USA )",
    "country": "United States"
  },
  {
    "id": 102298,
    "name": "OCG Technology Inc    ( New Rochelle NY USA )",
    "country": "United States"
  },
  {
    "id": 338585,
    "name": "OCO Biomedical Inc    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 452618,
    "name": "OCSI/Neuroflex    ( Pinellas Park FL USA )",
    "country": "United States"
  },
  {
    "id": 458470,
    "name": "Octane Fitness    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 999614,
    "name": "Octane Fitness    ( Brooklyn Park MN USA )",
    "country": "United States"
  },
  {
    "id": 452765,
    "name": "Octapharma AG    ( Lachen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452766,
    "name": "Octapharma USA Inc    ( Hoboken NJ USA )",
    "country": "United States"
  },
  {
    "id": 457782,
    "name": "OCTAX Microscience GmbH    ( Bruckberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 330899,
    "name": "Octostop Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 162671,
    "name": "Ocu-Ease Optical Products Inc    ( Pinole CA USA )",
    "country": "United States"
  },
  {
    "id": 174933,
    "name": "Ocular Instruments Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 470544,
    "name": "Ocular Prosthetics Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 400962,
    "name": "Ocular Sciences Canada Inc    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 456407,
    "name": "Ocular Systems Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 457460,
    "name": "Oculentis GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 174687,
    "name": "Oculo-Plastik Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 392723,
    "name": "Oculus Inc    ( Arlington WA USA )",
    "country": "United States"
  },
  {
    "id": 456824,
    "name": "Oculus Innovative Sciences Inc    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 287340,
    "name": "Oculus Optikgeraete GmbH    ( Wetzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 286885,
    "name": "Ocumed Micro Instrumente    ( Tuttlingen/Moehringen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454413,
    "name": "Ocutech Inc    ( Chapel Hill NC USA )",
    "country": "United States"
  },
  {
    "id": 402141,
    "name": "Odin Medical Technologies Europe bvba    ( Gent  USA )",
    "country": "United States"
  },
  {
    "id": 402132,
    "name": "Odin Medical Technologies Inc    ( Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 402135,
    "name": "Odin Medical Technologies Ltd    ( Yokne'am Elit  USA )",
    "country": "United States"
  },
  {
    "id": 391324,
    "name": "Odon Life Technology    ( Marly Le Roi  France )",
    "country": "France"
  },
  {
    "id": 453428,
    "name": "ODS Medical    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 463061,
    "name": "Odstock Medical Ltd    ( Salisbury  England )",
    "country": "UK"
  },
  {
    "id": 175906,
    "name": "Odyssey Automotive Specialty    ( Wharton NJ USA )",
    "country": "United States"
  },
  {
    "id": 295341,
    "name": "Odyssey Medical Inc    ( Bartlett TN USA )",
    "country": "United States"
  },
  {
    "id": 392004,
    "name": "OEC Australia    ( Brookvale  USA )",
    "country": "United States"
  },
  {
    "id": 457857,
    "name": "OEM Dynamics    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 461025,
    "name": "OEM Medical Solutions LLC    ( Towson MD USA )",
    "country": "United States"
  },
  {
    "id": 465072,
    "name": "OENT Instruments, Inc    ( Tulsa 90 USA )",
    "country": "United States"
  },
  {
    "id": 454285,
    "name": "Oerlikon Leybold Vacuum USA Inc    ( Export PA USA )",
    "country": "United States"
  },
  {
    "id": 452799,
    "name": "Oertel Medizintechnik    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 185179,
    "name": "Oertli Instrumente AG    ( Berneck  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 464077,
    "name": "OESS Corp    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 452537,
    "name": "OFFCARR srl    ( Villa del Conte (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462744,
    "name": "Office Concepts    ( Egg Harbor NJ USA )",
    "country": "United States"
  },
  {
    "id": 460554,
    "name": "Office Depot Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 309583,
    "name": "Officine Sordina SpA    ( Saonara (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 465038,
    "name": "OFS Brands    ( Huntingburg IN USA )",
    "country": "United States"
  },
  {
    "id": 157997,
    "name": "OG Giken Co Ltd    ( Okayama  Japan )",
    "country": "Japan"
  },
  {
    "id": 185459,
    "name": "Ogarit PPHU Export-Import    ( Lomianki k/Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 456933,
    "name": "Ogenix    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 457574,
    "name": "OGI Eyewear    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 104447,
    "name": "Oglaend Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 103313,
    "name": "Ohaus Corp    ( Pine Brook NJ USA )",
    "country": "United States"
  },
  {
    "id": 174128,
    "name": "Ohbayashi Mfg Co Ltd    ( Kawaguchi  Japan )",
    "country": "Japan"
  },
  {
    "id": 464969,
    "name": "Ohio Medical Physics Consulting (OMPC) LLC    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 452041,
    "name": "OHIO MEDICAL PRODUCTS    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 184432,
    "name": "Ohio Willow Wood Co    ( Mount Sterling OH USA )",
    "country": "United States"
  },
  {
    "id": 457648,
    "name": "OHK Medical Devices Inc    ( Grandville MI USA )",
    "country": "United States"
  },
  {
    "id": 459359,
    "name": "OHK Medical Devices Inc    ( Grandville MI USA )",
    "country": "United States"
  },
  {
    "id": 186223,
    "name": "Ohlin Sales Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 347601,
    "name": "Ohmeda Medical    ( Laurel MD USA )",
    "country": "United States"
  },
  {
    "id": 101569,
    "name": "Ohmic Instruments Co    ( Easton MD USA )",
    "country": "United States"
  },
  {
    "id": 157998,
    "name": "Ohmic Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999554,
    "name": "Ohmite Mfg. Co    ( Arlington Heights IL USA )",
    "country": "USA"
  },
  {
    "id": 287185,
    "name": "Ohropax GmbH    ( Wehrheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 287566,
    "name": "Ohrsana GmbH    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 285529,
    "name": "Ohst Medizintechnik AG    ( Rathenow  Germany )",
    "country": "Germany"
  },
  {
    "id": 157999,
    "name": "Ohto Corp    ( Funabashi  Japan )",
    "country": "Japan"
  },
  {
    "id": 452044,
    "name": "OI Analytical    ( College Station TX USA )",
    "country": "United States"
  },
  {
    "id": 440902,
    "name": "OJ Medtech Inc    ( Bay Shore NY USA )",
    "country": "United States"
  },
  {
    "id": 456108,
    "name": "OK Biotech Co Ltd    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 294230,
    "name": "OK International Corp    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 457048,
    "name": "OK Medical    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 325140,
    "name": "OK-1 Mfg Co    ( Altus OK USA )",
    "country": "United States"
  },
  {
    "id": 262835,
    "name": "Okamoto Industries Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 174129,
    "name": "Okamoto Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 183040,
    "name": "Okamoto USA Inc    ( Sandusky OH USA )",
    "country": "United States"
  },
  {
    "id": 455644,
    "name": "Oki Data Americas Inc    ( Mount Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 457513,
    "name": "Oklahoma Lions Service Foundation    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 461041,
    "name": "Okolab srl    ( Ottaviano  Italy )",
    "country": "Italy"
  },
  {
    "id": 453587,
    "name": "Old Dominion Eye Foundation    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 467105,
    "name": "Oldham Americas    ( The Woodlands TX USA )",
    "country": "United States"
  },
  {
    "id": 467104,
    "name": "Oldham Gas    ( Arras Cedex  France )",
    "country": "France"
  },
  {
    "id": 457606,
    "name": "Olerup GmbH    ( Vienna  Austria )",
    "country": "Austria"
  },
  {
    "id": 457607,
    "name": "Olerup Inc    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 174412,
    "name": "Olidef CZ Industria e Comercio Aparelhos Hospitala    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 458646,
    "name": "Olis Inc    ( Bogart GA USA )",
    "country": "United States"
  },
  {
    "id": 457762,
    "name": "Olive Medical    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 457763,
    "name": "Olive Medical (United Kingdom)    ( Great Finborough  England )",
    "country": "UK"
  },
  {
    "id": 454291,
    "name": "Oliver-Tolas Health Care Packaging Inc    ( Feasterville PA USA )",
    "country": "United States"
  },
  {
    "id": 281635,
    "name": "Olivetti GmbH    ( Frankfurt  USA )",
    "country": "United States"
  },
  {
    "id": 281447,
    "name": "Ollmann Audiometer KG    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285710,
    "name": "Olren Instrumente Erich Leibinger    ( Renquishausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 401033,
    "name": "Olsen Q2 Medical    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 101573,
    "name": "Olympic Medical Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 456424,
    "name": "Olympus (China) Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107564,
    "name": "Olympus America Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 185157,
    "name": "Olympus America Inc    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 276750,
    "name": "Olympus America Inc    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 364575,
    "name": "Olympus America Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 455339,
    "name": "Olympus Australia Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 276351,
    "name": "Olympus Beijing    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454346,
    "name": "Olympus Canada Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 426499,
    "name": "Olympus Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 426501,
    "name": "Olympus Diagnostic Systems Group Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 282514,
    "name": "Olympus Diagnostica GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456426,
    "name": "Olympus Europa Holding GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457980,
    "name": "Olympus KeyMed Ltd    ( Southend-on-Sea  England )",
    "country": "UK"
  },
  {
    "id": 452745,
    "name": "Olympus Medical Systems Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451962,
    "name": "Olympus Medical Systems Europa GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 107896,
    "name": "Olympus NDT Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 276169,
    "name": "Olympus Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 451166,
    "name": "Olympus Surgical & Industrial America Inc    ( Center Valley PA USA )",
    "country": "United States"
  },
  {
    "id": 458115,
    "name": "Olympus Surgical Technologies Europe    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 444106,
    "name": "Olympus UK Ltd    ( Southend-on-Sea  England )",
    "country": "UK"
  },
  {
    "id": 151208,
    "name": "Olympus Winter & IBE GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455654,
    "name": "OMED of Nevada LLC    ( Reno CA USA )",
    "country": "United States"
  },
  {
    "id": 102727,
    "name": "Omega Engineering Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 455070,
    "name": "Omega Engineering Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 456728,
    "name": "Omega Healthcare    ( London  England )",
    "country": "UK"
  },
  {
    "id": 439396,
    "name": "Omega Laboratories    ( Mogadore OH USA )",
    "country": "United States"
  },
  {
    "id": 193376,
    "name": "Omega Laser Systems Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 265343,
    "name": "Omega Medical & Scientific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 106843,
    "name": "Omega Medical Electronics Ltd    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 362063,
    "name": "Omega Medical Imaging Inc    ( Sanford FL USA )",
    "country": "United States"
  },
  {
    "id": 452617,
    "name": "Omega Medical Products Corp    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 107813,
    "name": "Omega Optical Inc    ( Brattleboro VT USA )",
    "country": "United States"
  },
  {
    "id": 174689,
    "name": "Omega Posture Systems Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 339481,
    "name": "OmegaDyne Inc    ( Sunbury OH USA )",
    "country": "United States"
  },
  {
    "id": 435619,
    "name": "OmegaSoft Medical    ( Aalter  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 409786,
    "name": "OMG plc    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 461059,
    "name": "Omicron-Laserage Laserprodukte Gmbh    ( Rodgau-Dudenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 354565,
    "name": "Omiderm Ltd    ( Yavne  Israel )",
    "country": "Israel"
  },
  {
    "id": 232332,
    "name": "OMJ Pharmaceuticals Inc    ( San German PR USA )",
    "country": "United States"
  },
  {
    "id": 451485,
    "name": "Omni Corp    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 175368,
    "name": "Omni International Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 450941,
    "name": "Omni Life Science Inc    ( Raynham MA USA )",
    "country": "United States"
  },
  {
    "id": 453890,
    "name": "Omni Medical Supply Inc    ( Walled Lake MI USA )",
    "country": "United States"
  },
  {
    "id": 228401,
    "name": "Omni Technologies Inc    ( Greendale IN USA )",
    "country": "United States"
  },
  {
    "id": 107428,
    "name": "Omni Therm Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 160128,
    "name": "Omni-Pac Ekco GmbH    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 107182,
    "name": "Omni-Tract Surgical    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 456630,
    "name": "Omnicell Inc (Dubai)    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 267223,
    "name": "Omnicell Technologies Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 457587,
    "name": "Omniderm Pharma Canada Inc    ( Vandreuil-Dorion PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455323,
    "name": "OmniGuide    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 102615,
    "name": "Omnimed Inc    ( Moorestown NJ USA )",
    "country": "United States"
  },
  {
    "id": 356913,
    "name": "OmniSYS Inc    ( Greenville TX USA )",
    "country": "United States"
  },
  {
    "id": 459339,
    "name": "Omniwatch Technologies    ( Sunbury PA USA )",
    "country": "United States"
  },
  {
    "id": 463056,
    "name": "Omnyx LLC    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 454744,
    "name": "OMRIX biopharmaceuticals Ltd    ( Kiryat Ono  Israel )",
    "country": "Israel"
  },
  {
    "id": 370362,
    "name": "OMRIX biopharmaceuticals SA    ( Rhode St Genese  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 177500,
    "name": "OMRON (China) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 185196,
    "name": "OMRON Asia Pacific Pte Ltd (India)    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 267674,
    "name": "OMRON Asia Pacific Pte Ltd (Vietnam)    ( Ho Chi Minh City  Vietnam )",
    "country": "Vietnam"
  },
  {
    "id": 458046,
    "name": "OMRON Electronics LLC    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 416239,
    "name": "OMRON Healthcare Canada    ( Bannockburn IL USA )",
    "country": "United States"
  },
  {
    "id": 149479,
    "name": "OMRON Healthcare Co Ltd    ( Muko  Japan )",
    "country": "Japan"
  },
  {
    "id": 177498,
    "name": "OMRON Healthcare Europe bv    ( Hoofddorp  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 450835,
    "name": "OMRON Healthcare France    ( Rosny-sous-Bois Cedex  France )",
    "country": "France"
  },
  {
    "id": 107853,
    "name": "OMRON Healthcare Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 456583,
    "name": "OMRON Healthcare India PVT LTD    ( Gurgaon  India )",
    "country": "India"
  },
  {
    "id": 332087,
    "name": "OMRON Healthcare Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 384291,
    "name": "OMRON Healthcare UK Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 284286,
    "name": "OMRON Medizintechnik Handelsgesellschaft GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 192829,
    "name": "OMS SpA    ( Caselle di Selvazzano (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460941,
    "name": "Omtool    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 454383,
    "name": "On-X Life Technologies Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 461071,
    "name": "ONC Solutions Inc    ( Acton MA USA )",
    "country": "United States"
  },
  {
    "id": 451815,
    "name": "OnCal Healthcare Communications    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 999765,
    "name": "OnCare    ( Minneapolis MN USA )",
    "country": "USA"
  },
  {
    "id": 459282,
    "name": "Oncology Data Systems Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 185638,
    "name": "Oncology Services International    ( Montebello NY USA )",
    "country": "United States"
  },
  {
    "id": 461079,
    "name": "Oncology Solutions    ( Decatur GA USA )",
    "country": "United States"
  },
  {
    "id": 450764,
    "name": "Oncura (France)    ( Pantin  France )",
    "country": "France"
  },
  {
    "id": 450766,
    "name": "Oncura (Italy)    ( Saluggia (VC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 435342,
    "name": "Oncura (UK)    ( Amersham  England )",
    "country": "UK"
  },
  {
    "id": 435248,
    "name": "Oncura (USA)    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 450765,
    "name": "Oncura GmbH    ( Castrop-Rauxel  Germany )",
    "country": "Germany"
  },
  {
    "id": 450798,
    "name": "Oncura Netherlands    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 450799,
    "name": "Oncura Portugal    ( Carcavelos  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 450800,
    "name": "Oncura Spain    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 283106,
    "name": "Ondal Industrietechnik GmbH    ( Huenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 452539,
    "name": "OnDemandSoft Co Ltd    ( Daegu  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451731,
    "name": "Ondine Biopharma Corp    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 107847,
    "name": "One Lambda Inc    ( Canoga Park CA USA )",
    "country": "United States"
  },
  {
    "id": 461444,
    "name": "One Touch Design Inc    ( Ledgewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 106841,
    "name": "ONEAC Corp    ( Libertyville IL USA )",
    "country": "United States"
  },
  {
    "id": 461278,
    "name": "OneMedNet Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 458880,
    "name": "OneSource Document Management Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 325158,
    "name": "Ongoing Care Solutions Inc    ( Pinellas Park FL USA )",
    "country": "United States"
  },
  {
    "id": 999790,
    "name": "Ongpin Enterprises Co., Ltd.    (   USA )",
    "country": "United States"
  },
  {
    "id": 391287,
    "name": "ONI Medical Systems Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 461032,
    "name": "Online Computer Library Center Inc (OCLC)    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 175879,
    "name": "OnLine Power Inc    ( City of Commerce CA USA )",
    "country": "United States"
  },
  {
    "id": 452731,
    "name": "Onset Medical Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 401961,
    "name": "OnSite Gas Systems Inc    ( Newington CT USA )",
    "country": "United States"
  },
  {
    "id": 460672,
    "name": "OnSite Sterilization LLC    ( Pottstown PA USA )",
    "country": "United States"
  },
  {
    "id": 999591,
    "name": "Onyx Healthcare Inc.    ( Brea CA Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 360009,
    "name": "ONYX Medical Inc    ( Novato CA USA )",
    "country": "United States"
  },
  {
    "id": 175631,
    "name": "Op-D-Op Inc    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 459064,
    "name": "Open Software Solutions    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 461085,
    "name": "OpenSpan Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 460635,
    "name": "OpenText Corp    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460983,
    "name": "Operativ    ( Kirkland WA USA )",
    "country": "United States"
  },
  {
    "id": 283221,
    "name": "Ophardt Product GmbH    ( Issum  Germany )",
    "country": "Germany"
  },
  {
    "id": 233281,
    "name": "Ophex Lasersysteme GmbH & Co KG    ( Bonn  Germany )",
    "country": "Germany"
  },
  {
    "id": 108472,
    "name": "Ophir Optronics Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 138198,
    "name": "Ophir Optronics Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 452931,
    "name": "Ophir-Spiricon Inc    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 999759,
    "name": "Out-Front    ( Mesa AZ USA )",
    "country": "USA"
  },
  {
    "id": 454048,
    "name": "OPHIT Co Ltd    ( Suwon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454049,
    "name": "OPHIT Co Ltd - Europe    ( Bad Homburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 178484,
    "name": "OPHTEC bv    ( Groningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 294587,
    "name": "OPHTEC USA Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 107430,
    "name": "Ophthalmic Imaging Systems    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 377482,
    "name": "Ophthalmic Innovations International    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 458328,
    "name": "Ophthalmic Instrument Co Inc    ( Soughton MA USA )",
    "country": "United States"
  },
  {
    "id": 452797,
    "name": "Ophthalmic Technologies Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453558,
    "name": "OPKO Health Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 471747,
    "name": "OPKO Lab    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 391828,
    "name": "Opocrin SpA    ( Corlo di Formgine (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 339124,
    "name": "Oppici SA    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 454274,
    "name": "OPS Medical LLC    ( Pasadena MD USA )",
    "country": "United States"
  },
  {
    "id": 259211,
    "name": "OPSM Pty Ltd    ( Auburn  Australia )",
    "country": "Australia"
  },
  {
    "id": 160129,
    "name": "OPTAC Audiologie    ( Roedermark  Germany )",
    "country": "Germany"
  },
  {
    "id": 462779,
    "name": "Optec International FZE    ( Sharjah  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 298490,
    "name": "Optec SpA    ( Parabiago (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 282842,
    "name": "Optek Clean Air Systeme GmbH    ( Heilbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 462928,
    "name": "optek-Danulat Inc    ( Germantown WI USA )",
    "country": "United States"
  },
  {
    "id": 457629,
    "name": "Optelec UK    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 457628,
    "name": "Optelec US    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 999478,
    "name": "OPTI Kinetics    ( Ashland VA USA )",
    "country": "United States"
  },
  {
    "id": 452653,
    "name": "OPTI Medical Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 458191,
    "name": "OPTI Medical Systems GmbH    ( Neu-Anspach  Germany )",
    "country": "Germany"
  },
  {
    "id": 475960,
    "name": "OPTI-UPS Corp    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 285744,
    "name": "Optia-Mangel    ( Rheda-Wiedenbrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 462713,
    "name": "Optical Associates Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 106359,
    "name": "Optical Electronics Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 461089,
    "name": "Optical Imaging Ltd    ( Rehotvot  Israel )",
    "country": "Israel"
  },
  {
    "id": 449846,
    "name": "Optical Services Co    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 346540,
    "name": "Opticas Place Vendome    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 451985,
    "name": "Opticon Medical    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 458352,
    "name": "Optics Inc    ( Brunswick OH USA )",
    "country": "United States"
  },
  {
    "id": 358330,
    "name": "Optiglass Ltd    ( Hainault  England )",
    "country": "UK"
  },
  {
    "id": 107431,
    "name": "Optikem International Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 185774,
    "name": "Optikon 2000 SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 474854,
    "name": "Optilia Instruments AB    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 359572,
    "name": "Optim Inc    ( Sturbridge MA USA )",
    "country": "United States"
  },
  {
    "id": 454715,
    "name": "Optima Dubai Inc    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 363781,
    "name": "Optima Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 460641,
    "name": "Optima Products Inc    ( Long Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 416158,
    "name": "Optima USA Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 455607,
    "name": "Optimal Health Network    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 453492,
    "name": "OptiMed Medizinische Instrumente GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 332744,
    "name": "OptiMed Technologies Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 457531,
    "name": "OptiMedica Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 455587,
    "name": "Optimum Medical Solutions Ltd    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 294234,
    "name": "Optinova Ab    ( Godby  Finland )",
    "country": "Finland"
  },
  {
    "id": 451809,
    "name": "Optiscan Pty Ltd    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 431516,
    "name": "Optivus Technology Inc    ( Loma Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 453031,
    "name": "Opto Circuits India Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 461100,
    "name": "Opto Engine LLC    ( Midvale UT USA )",
    "country": "United States"
  },
  {
    "id": 291048,
    "name": "Opto Science Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 461123,
    "name": "Optoacoustics Ltd    ( Moshav Mazor  Israel )",
    "country": "Israel"
  },
  {
    "id": 162418,
    "name": "Optometrics LLC    ( Ayer MA USA )",
    "country": "United States"
  },
  {
    "id": 452538,
    "name": "Optomic Espana SA    ( Colmenar Viejo (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 461103,
    "name": "OptOR Systems    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 413114,
    "name": "Optos North America    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 413113,
    "name": "Optos plc    ( Dunfermline  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 461110,
    "name": "Optous    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 378091,
    "name": "Optovent AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453555,
    "name": "Optovue Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 458460,
    "name": "Optovue Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 321063,
    "name": "OPTP    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 454221,
    "name": "Optronics    ( Goleta CA USA )",
    "country": "United States"
  },
  {
    "id": 451814,
    "name": "Optum    ( Golden Valley MN USA )",
    "country": "United States"
  },
  {
    "id": 458612,
    "name": "OptumInsight    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 400937,
    "name": "Opus Healthcare Solutions Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 170829,
    "name": "Opus Medication Systems    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 401486,
    "name": "OpusDent Ltd    ( Netanya  USA )",
    "country": "United States"
  },
  {
    "id": 401353,
    "name": "OpusDent USA Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 394347,
    "name": "OpWare LLC    ( Le Roy NY USA )",
    "country": "United States"
  },
  {
    "id": 238052,
    "name": "OR Solutions Inc    ( Chantilly VA USA )",
    "country": "United States"
  },
  {
    "id": 456171,
    "name": "OR Specific Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 455320,
    "name": "OR Support Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 108800,
    "name": "OR-D Systems Inc    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 451338,
    "name": "Oracle Australia    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 451327,
    "name": "Oracle Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 451326,
    "name": "Oracle Benelux    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 450886,
    "name": "Oracle Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451336,
    "name": "Oracle Danmark    ( Ballerup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451333,
    "name": "Oracle Iberica    ( Las Rozas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 451337,
    "name": "Oracle Italia srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 451335,
    "name": "Oracle Middle East    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 451334,
    "name": "Oracle Svenska AB    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451325,
    "name": "Oracle UK    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 441887,
    "name": "Oracle US    ( Redwood Shores CA USA )",
    "country": "United States"
  },
  {
    "id": 283522,
    "name": "Oralia Dentalprodukte GmbH    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 453814,
    "name": "ORALIA medical GmbH    ( Konstanz  Germany )",
    "country": "Germany"
  },
  {
    "id": 177077,
    "name": "Orange-Sol Medical Products    ( Gilbert AZ USA )",
    "country": "United States"
  },
  {
    "id": 456935,
    "name": "Orascoptic UK    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 429373,
    "name": "OraSure Technologies Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 457707,
    "name": "Oraya Theraputics Inc    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 104896,
    "name": "Orbeco Analytical Systems Inc    ( Farmingdale NY USA )",
    "country": "United States"
  },
  {
    "id": 279756,
    "name": "Orben Ingenieurbuero    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 457478,
    "name": "ORBIS    ( Oconomowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 273288,
    "name": "Orbis/Menasha Material Handling Corp    ( Oconomowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 459360,
    "name": "Orbital Therapy LLC    ( Bedford MD USA )",
    "country": "United States"
  },
  {
    "id": 334020,
    "name": "Orchard Software Corp    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 457475,
    "name": "Orchid Chelsea    ( Chelsea MI USA )",
    "country": "United States"
  },
  {
    "id": 163027,
    "name": "Ordisi SA    ( L'Hospitalet de Llobregat  Spain )",
    "country": "Spain"
  },
  {
    "id": 461113,
    "name": "Oregon Environmental Systems    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 435421,
    "name": "Orex Computed Radiography Inc    ( Auburndale MA USA )",
    "country": "United States"
  },
  {
    "id": 435422,
    "name": "Orex Computed Radiography Ltd    ( Yokne'am Elit  USA )",
    "country": "United States"
  },
  {
    "id": 178448,
    "name": "Orfit Industries NV    ( Wijnegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 458983,
    "name": "ORFLO Technologies    ( Hailey ID USA )",
    "country": "United States"
  },
  {
    "id": 306786,
    "name": "Orga Systems GmbH    ( Paderborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 415347,
    "name": "Orga-Service France    ( Courcouronnes  France )",
    "country": "France"
  },
  {
    "id": 415346,
    "name": "Orga-Service Nederland    ( Asperen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 415345,
    "name": "Orga-Service Oestrerreich    ( Micheldorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 454220,
    "name": "Organ Recovery Systems (Belgium)    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454219,
    "name": "Organ Recovery Systems (USA)    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 185347,
    "name": "Organogenesis Inc    ( Canton MA USA )",
    "country": "United States"
  },
  {
    "id": 104285,
    "name": "Organomation Assoc Inc    ( Berlin MA USA )",
    "country": "United States"
  },
  {
    "id": 101258,
    "name": "Organon Teknika Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 451955,
    "name": "Orgaterm Produktions- und Warenvertriebsgesellscha    ( Luedinghausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 322473,
    "name": "Orgenics Ltd    ( Yavne  Israel )",
    "country": "Israel"
  },
  {
    "id": 457962,
    "name": "Orgentec Diagnostika GmbH    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 163653,
    "name": "Orhan Boz Tibbi Malzeme ve Sanayi AS    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 471660,
    "name": "Oricare Inc    ( Quakertown PA USA )",
    "country": "United States"
  },
  {
    "id": 456660,
    "name": "ORICH Medical Equipment (Tianjin) Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 353340,
    "name": "Oridion Medical Inc    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 416419,
    "name": "Oridion Medical KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 334303,
    "name": "Oridion Medical Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 158000,
    "name": "Oriental Instruments Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451881,
    "name": "ORTHOsoft Inc (France)    ( Baumes les Dames  USA )",
    "country": "United States"
  },
  {
    "id": 394330,
    "name": "OriGen Biomedical Europe    ( Helsingborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 394319,
    "name": "OriGen Biomedical Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 454612,
    "name": "Origin Healthcare Solutions    ( Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 108511,
    "name": "Originator Corp    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 456587,
    "name": "ORIGIO A/S    ( Malov  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458785,
    "name": "Origio A/S    ( Malov  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458778,
    "name": "ORIGIO MidAtlantic Devices Inc    ( Mt Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 451805,
    "name": "Orimex srl    ( Torchiara (SA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 337484,
    "name": "Oriola As (Estonia)    ( Harjumaa  Estonia )",
    "country": "Estonia"
  },
  {
    "id": 358100,
    "name": "Oriola Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 138473,
    "name": "Orion Diagnostica    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 174130,
    "name": "Orion Electric Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 454206,
    "name": "Orion Health Canada    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 454207,
    "name": "Orion Health New Zealand    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 454208,
    "name": "Orion Health United Kingdom    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 452351,
    "name": "Orion Health United States    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 462812,
    "name": "Orion Lasers Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 370364,
    "name": "Orion Meditech Systems (P) Ltd    ( Chandigarh  India )",
    "country": "India"
  },
  {
    "id": 284925,
    "name": "Oriplast Gebr Krayer GmbH    ( Neunkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 106465,
    "name": "Ormco Corp    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 293478,
    "name": "Ormed AG    ( Aesch  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 226699,
    "name": "ORMED GmbH    ( Freiburg im Breisgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 333685,
    "name": "Ormed Information Systems Ltd    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 106970,
    "name": "Ormed Mfg Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 226217,
    "name": "ORMED Orthopedic Medical Products Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 336883,
    "name": "ORMED Sarl    ( Lyon  USA )",
    "country": "United States"
  },
  {
    "id": 454306,
    "name": "ORMESA Srl    ( Foligno (PG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 467122,
    "name": "Oroboros Instruments Corp    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 282802,
    "name": "Orpegen Pharma Ges fuer Biotechnologische    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459319,
    "name": "Orth Align Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 461114,
    "name": "Ortheon Medical    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 108368,
    "name": "Ortho Active Appliances Ltd    ( Coquitlam BC Canada )",
    "country": "Canada"
  },
  {
    "id": 433622,
    "name": "Ortho Biotech Products LP    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 107432,
    "name": "Ortho Care Inc    ( Raytown MO USA )",
    "country": "United States"
  },
  {
    "id": 296805,
    "name": "Ortho Care Products Pvt Ltd    ( Keezhillam  India )",
    "country": "India"
  },
  {
    "id": 257298,
    "name": "Ortho Development Corp    ( Draper UT USA )",
    "country": "United States"
  },
  {
    "id": 103316,
    "name": "Ortho Diagnostic Systems Inc A Johnson & Johnson C    (   USA )",
    "country": "United States"
  },
  {
    "id": 453637,
    "name": "Ortho Europe Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 103853,
    "name": "Ortho Med Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 453921,
    "name": "Ortho Solutions Ltd    ( Maldon  England )",
    "country": "UK"
  },
  {
    "id": 237629,
    "name": "Ortho Source    ( North Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 289353,
    "name": "Ortho-Clinical Diagnostic Systems KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 172107,
    "name": "Ortho-Clinical Diagnostics (England)    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 453865,
    "name": "Ortho-Clinical Diagnostics (Hong Kong)    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 307310,
    "name": "Ortho-Clinical Diagnostics (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 284789,
    "name": "Ortho-Clinical Diagnostics GmbH    ( Neckargemuend  Germany )",
    "country": "Germany"
  },
  {
    "id": 105249,
    "name": "Ortho-Clinical Diagnostics Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 346053,
    "name": "Ortho-Craft Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 102616,
    "name": "Ortho-Kinetics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 171309,
    "name": "Ortho-McNeil Pharmaceutical Inc    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 237630,
    "name": "Ortho-Organizers Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 280137,
    "name": "Ortho-Ped Dittmer Inh Klaus Dittmer    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 398169,
    "name": "Orthoband Barnhart Industries Inc    ( Imperial MO USA )",
    "country": "United States"
  },
  {
    "id": 456447,
    "name": "Orthocon Inc    ( Irvington NY USA )",
    "country": "United States"
  },
  {
    "id": 472791,
    "name": "OrthoCor Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 453532,
    "name": "Orthocrat Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 453457,
    "name": "Orthodocs Ltd    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 456956,
    "name": "Orthodynamics GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 452723,
    "name": "Orthofab Inc    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 416400,
    "name": "Orthofix AG    ( Steinhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453673,
    "name": "Orthofix do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 416399,
    "name": "Orthofix GmbH    ( Valley  Germany )",
    "country": "Germany"
  },
  {
    "id": 234206,
    "name": "Orthofix Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 258271,
    "name": "Orthofix International NV    ( Curacao  USA )",
    "country": "United States"
  },
  {
    "id": 162153,
    "name": "Orthofix Ltd    ( Henley-on-Thames  USA )",
    "country": "United States"
  },
  {
    "id": 416398,
    "name": "Orthofix SA    ( Gentilly  France )",
    "country": "France"
  },
  {
    "id": 178399,
    "name": "Orthofix srl    ( Bussolengo (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451400,
    "name": "OrthoHelix Surgical Design Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 184255,
    "name": "OrthoLogic Corp    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 452470,
    "name": "Orthomed SA    ( St Jeannet  France )",
    "country": "France"
  },
  {
    "id": 325151,
    "name": "Orthomerica Products Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 409549,
    "name": "Orthometrix Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 403663,
    "name": "OrthoMotion Inc    ( Pickering ON USA )",
    "country": "United States"
  },
  {
    "id": 453692,
    "name": "Orthopaedic Appliances Pty Ltd    ( Brunswick  Australia )",
    "country": "Australia"
  },
  {
    "id": 280944,
    "name": "Orthopaedie & Rehatechnik Dresden GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 454685,
    "name": "OrthoPediatrics Corp    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 457823,
    "name": "Orthopedic Alliance LLC    ( Murrieta CA USA )",
    "country": "United States"
  },
  {
    "id": 101580,
    "name": "Orthopedic Systems Inc    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 331871,
    "name": "OrthoPrep Ltd    ( Stroud  England )",
    "country": "UK"
  },
  {
    "id": 453628,
    "name": "OrthoPro LLC    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 403665,
    "name": "OrthoRehab An Otto Bock Co Group    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454670,
    "name": "OrthoScan Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 457499,
    "name": "OrthoSensor    ( Dania Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 407216,
    "name": "Orthosonics Ltd    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 407220,
    "name": "Orthosonics Ltd US    ( Chatham NJ USA )",
    "country": "United States"
  },
  {
    "id": 305946,
    "name": "Orthotic Consultants Pty Ltd    ( Fitzroy  Australia )",
    "country": "Australia"
  },
  {
    "id": 306270,
    "name": "Orthotronic Medizintechnik GmbH    ( Wedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 455240,
    "name": "Orthovita    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 157665,
    "name": "Ortivus AB    ( Danderyd  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 398020,
    "name": "ORTMANN GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 152535,
    "name": "Ortogamma srl    ( Vinovo (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 226332,
    "name": "Osada Electric Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 305789,
    "name": "Osada Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 108157,
    "name": "Osage Industries Inc    ( Linn MO USA )",
    "country": "United States"
  },
  {
    "id": 460996,
    "name": "Osaki Massage Chair    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 453857,
    "name": "Osborn Medical Corp    ( Utica MN USA )",
    "country": "United States"
  },
  {
    "id": 461116,
    "name": "Osborn Visual Solutions LLC    ( Dultuh GA USA )",
    "country": "United States"
  },
  {
    "id": 451287,
    "name": "Oscimed SA    ( La Chaux-de-Fonds  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 371766,
    "name": "Oscor Inc    ( Palm Harbor FL USA )",
    "country": "United States"
  },
  {
    "id": 454384,
    "name": "Oshkosh Specialty Vehicles    ( Calumet Ciry IL USA )",
    "country": "United States"
  },
  {
    "id": 460541,
    "name": "Osiris Therapeutics Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 284396,
    "name": "Oskar Heinze Jun GmbH & Co KG    ( Mellenbach-Glasbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 286819,
    "name": "Oskar Keller Inh Gerhard Keller    ( Tuttlingen  USA )",
    "country": "United States"
  },
  {
    "id": 323798,
    "name": "Oskar Lapp Ltd    ( Greenford  England )",
    "country": "UK"
  },
  {
    "id": 285252,
    "name": "OSMA Aufzuege Albert Schenk GmbH & Co KG    ( Osnabrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 433382,
    "name": "Osmetech Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 453727,
    "name": "Osmetech Molecular Diagnostics    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 441551,
    "name": "Osmetech plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 101581,
    "name": "Osmonics Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 285254,
    "name": "OSNA-Punpen    ( Osnabrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 458068,
    "name": "Osprey Medical Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 162109,
    "name": "Osram GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 162106,
    "name": "OSRAM SYLVANIA    (   USA )",
    "country": "United States"
  },
  {
    "id": 459389,
    "name": "Osseon Therapeutics Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 458696,
    "name": "Osseous Technologies of America    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 458670,
    "name": "Osstell AB    ( Gothenburg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452932,
    "name": "Ossur Americas    ( Foothill Ranch CA USA )",
    "country": "United States"
  },
  {
    "id": 455448,
    "name": "Ossur Canada    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452241,
    "name": "Ossur Custom Bracing    ( Alisa Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 416022,
    "name": "Ossur Europe bv    ( Son en Breugel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 164162,
    "name": "Ossur hf    ( Reykjavik  Iceland )",
    "country": "Iceland"
  },
  {
    "id": 332841,
    "name": "Ossur North America    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 456606,
    "name": "Osteogenics Biomedical Inc    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 455456,
    "name": "Osteohealth    ( Shirley NY USA )",
    "country": "United States"
  },
  {
    "id": 155281,
    "name": "OsteoMed Corp    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 372971,
    "name": "Osteometer MediTech Inc    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 186175,
    "name": "OsteoMetrics Inc    ( Decatur GA USA )",
    "country": "United States"
  },
  {
    "id": 456717,
    "name": "Osteopore International    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 456125,
    "name": "Osteosys Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 303112,
    "name": "Osteotech Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 260191,
    "name": "Ostex International Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 458040,
    "name": "Ostial Corp    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 452962,
    "name": "Osypka Medical GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 454663,
    "name": "Osypka Medical GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 452963,
    "name": "Osypka Medical Inc    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 454664,
    "name": "Osypka Medical Inc    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 461138,
    "name": "OTech Group LLC    ( Jackson WI USA )",
    "country": "United States"
  },
  {
    "id": 360426,
    "name": "Othy Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 139629,
    "name": "Oticon A/S    ( Smorum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 454337,
    "name": "Oticon Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103524,
    "name": "Oticon Inc    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 354306,
    "name": "Oticon Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 455462,
    "name": "Otis Bed Mfg    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 460572,
    "name": "Otis Elevator Co    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 465137,
    "name": "OTiS Medical, Inc.    ( Great Falls P. USA )",
    "country": "United States"
  },
  {
    "id": 459300,
    "name": "OtisMed Corp    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 103958,
    "name": "OTO-Med Inc    ( Lake Havasu City AZ USA )",
    "country": "United States"
  },
  {
    "id": 190718,
    "name": "Otodynamics Ltd UK    ( Hatfield  England )",
    "country": "UK"
  },
  {
    "id": 453790,
    "name": "Otologics Belgium Sprl    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453789,
    "name": "Otologics GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 453784,
    "name": "Otologics LLC    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 457911,
    "name": "Otomed Inc    ( Lake Havasu City AZ USA )",
    "country": "United States"
  },
  {
    "id": 457836,
    "name": "OTOSAN    ( Forli  Italy )",
    "country": "Italy"
  },
  {
    "id": 457374,
    "name": "OtoSim Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458609,
    "name": "Ototronix Diagnostics    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 453563,
    "name": "Otovation LLC    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 152403,
    "name": "Otsuka Electronics Co Ltd    ( Hirakata  Japan )",
    "country": "Japan"
  },
  {
    "id": 160143,
    "name": "Otsuka Pharmaceutical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999407,
    "name": "OttLite Technology    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 107952,
    "name": "Otto Bock HealthCare    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 455227,
    "name": "Otto Bock Healthcare Canada Ltd    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 150936,
    "name": "Otto Bock HealthCare GmbH    ( Duderstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284316,
    "name": "Otto E Kobe Fabrik fuer Labor- und    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 473847,
    "name": "Otto Environmental    ( Greenfield WI USA )",
    "country": "United States"
  },
  {
    "id": 284541,
    "name": "Otto Leibinger GmbH    ( Muehlheim an der Donau  Germany )",
    "country": "Germany"
  },
  {
    "id": 284897,
    "name": "Otto Richter KG    ( Neumarkt  USA )",
    "country": "United States"
  },
  {
    "id": 286221,
    "name": "Otto Ruettgers GmbH & Co KG    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285440,
    "name": "Otto Schill GmbH & Co KG    ( Fellbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 280604,
    "name": "Otto Thaemert Textil- und Kunststoff GmbH & Co KG    ( Burgwedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 282620,
    "name": "Oury Guye et Fils SA    ( Nogent  France )",
    "country": "France"
  },
  {
    "id": 457149,
    "name": "Out-Front    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 461092,
    "name": "Out-Front    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 170169,
    "name": "Outils Rubis SA    ( Stabio  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 356732,
    "name": "Overhead Camera Systems Inc    ( South Hutchinson KS USA )",
    "country": "United States"
  },
  {
    "id": 103854,
    "name": "Overly Door Co    ( Greensburg PA USA )",
    "country": "United States"
  },
  {
    "id": 272722,
    "name": "Overseas Medical Supplies (UK) Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 453121,
    "name": "Oversize Medical Rental & Sales    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 391121,
    "name": "Oversize Medical Rentals and Sales    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 456473,
    "name": "Ovesco Endoscopy AG    ( Tuebingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459453,
    "name": "Ovid Technologies Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457464,
    "name": "Ovivo USA LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 363727,
    "name": "Owen Kane Holdings Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 348468,
    "name": "Owen Mumford GmbH    ( Grossostheim-Ringheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 330716,
    "name": "Owen Mumford Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 139480,
    "name": "Owen Mumford Ltd    ( Woodstock  England )",
    "country": "UK"
  },
  {
    "id": 348463,
    "name": "Owen Mumford Ltd (France)    ( Vernon Cedex  France )",
    "country": "France"
  },
  {
    "id": 105198,
    "name": "Owens & Minor    ( Mechanicsville VA USA )",
    "country": "United States"
  },
  {
    "id": 106087,
    "name": "Owens Scientific Inc    ( Katy TX USA )",
    "country": "United States"
  },
  {
    "id": 340371,
    "name": "Owl Separation Systems    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 461142,
    "name": "Oxcyon Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 458985,
    "name": "Oxford Immunotec Inc    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 465132,
    "name": "Oxford Instruments    ( x x USA )",
    "country": "United States"
  },
  {
    "id": 415412,
    "name": "Oxford Instruments bv    ( Gorinchem  USA )",
    "country": "United States"
  },
  {
    "id": 287388,
    "name": "Oxford Instruments GmbH    ( Wiesbaden  USA )",
    "country": "United States"
  },
  {
    "id": 415419,
    "name": "Oxford Instruments KK    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 415414,
    "name": "Oxford Instruments Marketing Ltd    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 154924,
    "name": "Oxford Instruments Medical    ( Hawthorne NY USA )",
    "country": "United States"
  },
  {
    "id": 151096,
    "name": "Oxford Instruments Medical Limited    ( Old Woking  USA )",
    "country": "United States"
  },
  {
    "id": 415413,
    "name": "Oxford Instruments NV    ( Schelle  USA )",
    "country": "United States"
  },
  {
    "id": 415418,
    "name": "Oxford Instruments Overseas Marketing Ltd    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 139642,
    "name": "Oxford Instruments plc    (   USA )",
    "country": "United States"
  },
  {
    "id": 365308,
    "name": "Oxford Instruments Private Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 415417,
    "name": "Oxford Instruments SA    ( San Sebastian de los Reyes  USA )",
    "country": "United States"
  },
  {
    "id": 415415,
    "name": "Oxford Instruments Sarl    ( Saclay Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 415416,
    "name": "Oxford Instruments SpA    ( San Giuliano Milanese MI  USA )",
    "country": "United States"
  },
  {
    "id": 227194,
    "name": "Oxford Optronix Ltd    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 466307,
    "name": "Oxford Science Inc    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 459301,
    "name": "Oxid Corp    ( Farmington Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 152447,
    "name": "Oxigel Materiais Hospitalares Industria Comercio L    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 307614,
    "name": "Oxigeno Medicinal Domiciliario    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 457268,
    "name": "Oximed    ( Santiago 1  Chile )",
    "country": "Chile"
  },
  {
    "id": 464103,
    "name": "Oximeter Plus Inc    ( Roslyn NY USA )",
    "country": "United States"
  },
  {
    "id": 155443,
    "name": "OxLife Inc    ( Hendersonville NC USA )",
    "country": "United States"
  },
  {
    "id": 336180,
    "name": "Oxoid GmbH    ( Wesel  Germany )",
    "country": "Germany"
  },
  {
    "id": 106914,
    "name": "Oxoid Inc    ( Nepean ON Canada )",
    "country": "Canada"
  },
  {
    "id": 331004,
    "name": "Oxoid Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 457636,
    "name": "Oxus Inc    ( Rochester Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 454829,
    "name": "Oxycare GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 450625,
    "name": "OxygenPlus Medical Systems Inc    ( Moneta VA USA )",
    "country": "United States"
  },
  {
    "id": 373234,
    "name": "Oxyheal Health Group    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 187944,
    "name": "Oxylitre Ltd    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 284686,
    "name": "Oxyparat Allihn & Co    ( Martinsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 454102,
    "name": "Oxyrase Inc    ( Mansfield OH USA )",
    "country": "United States"
  },
  {
    "id": 415956,
    "name": "Oxytech Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 227219,
    "name": "Oy Gehac Ltd    ( Karjaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 162451,
    "name": "Oy Medira AB    ( Kangasala  Finland )",
    "country": "Finland"
  },
  {
    "id": 365227,
    "name": "Oyster Bay Pump Works Inc    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 108698,
    "name": "Ozark Biomedical LLC    ( Beebe AR USA )",
    "country": "United States"
  },
  {
    "id": 417460,
    "name": "Ozmen Tibbi Laboratuar Teshisleri AS    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 282765,
    "name": "Ozo-Zours GmbH Tigges-Bandagen    ( Hattingen Ruhr  Germany )",
    "country": "Germany"
  },
  {
    "id": 293881,
    "name": "Ozonium Ingenieur Hans Neumayer    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458725,
    "name": "Oztech Systems Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 337310,
    "name": "Ozyme    ( St-Quentin-en-Yvelines Cedex  France )",
    "country": "France"
  },
  {
    "id": 287776,
    "name": "P & L Electronica SA    ( Santiago  USA )",
    "country": "United States"
  },
  {
    "id": 384275,
    "name": "P & P Materialfluss-Systeme GmbH    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 185386,
    "name": "P C Werth Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 283609,
    "name": "P J Dahlhausen & Co GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 186248,
    "name": "P J Hilton & Assoc    ( San Dimas CA USA )",
    "country": "United States"
  },
  {
    "id": 139356,
    "name": "P K Morgan Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 280774,
    "name": "P Lonsberg GmbH Textilwerk    ( Delbrueck  Germany )",
    "country": "Germany"
  },
  {
    "id": 285991,
    "name": "P S Kunststofffwerke AG    ( Schoeppenstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 393594,
    "name": "P T Domnick Hunter Indonesia    ( Jakarta  USA )",
    "country": "United States"
  },
  {
    "id": 452672,
    "name": "P3 Medical Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 455676,
    "name": "PAA Laboratories GmbH    ( Pasching  Austria )",
    "country": "Austria"
  },
  {
    "id": 454886,
    "name": "Pac Dent International Inc    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 441260,
    "name": "PAC LP    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 461238,
    "name": "PAC LP    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 103061,
    "name": "Pac-Kit Safety Equipment Co    ( South Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 441326,
    "name": "PAC-Walter Herzog GmbH    ( Lauda-Koenigshofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451993,
    "name": "Pace Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 106506,
    "name": "Pace Tech Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 102904,
    "name": "Pace Transducer Co    ( Tarzana CA USA )",
    "country": "United States"
  },
  {
    "id": 348664,
    "name": "Pacer Treadmill    (   USA )",
    "country": "United States"
  },
  {
    "id": 226320,
    "name": "Pacesetter Inc A St Jude Medical Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 459504,
    "name": "Pacific Auxiliary Fire Alarm Co    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 460758,
    "name": "Pacific BioMaterial Management Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 460768,
    "name": "Pacific BioMaterial Management Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 342107,
    "name": "Pacific Biomedical    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 452655,
    "name": "Pacific Bioscience Laboratories Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 458987,
    "name": "Pacific Biosciences    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 185608,
    "name": "Pacific Coast Laboratories    ( San Leandro CA USA )",
    "country": "United States"
  },
  {
    "id": 407138,
    "name": "Pacific Consolidated Industries LLP    ( Riverside CA USA )",
    "country": "United States"
  },
  {
    "id": 999842,
    "name": "Pacific Dental Specialties    ( QLD  Australia )",
    "country": "Australia"
  },
  {
    "id": 452669,
    "name": "Pacific Device de Mexico SA DE CV    ( Tijuana  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 458026,
    "name": "Pacific Enviornmental Tech Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 454874,
    "name": "Pacific Medical LLC    ( San Juan Capistrano CA USA )",
    "country": "United States"
  },
  {
    "id": 106247,
    "name": "Pacific Medical Systems Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 454211,
    "name": "Pacific Medico Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 466741,
    "name": "Pacific Mothers Support Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 103933,
    "name": "Pacific Research Laboratories    ( Vashon WA USA )",
    "country": "United States"
  },
  {
    "id": 465221,
    "name": "Pacific Rim Mechanical    ( San Diego 76 USA )",
    "country": "United States"
  },
  {
    "id": 175915,
    "name": "Pacific Safety Products Inc    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459226,
    "name": "Pacific Science Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 464980,
    "name": "Pacific Security Integrations    ( Aiea HI USA )",
    "country": "United States"
  },
  {
    "id": 452608,
    "name": "Pacific Surgical    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 306484,
    "name": "Pacific Surgical Supplies Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 395196,
    "name": "Pacific Tech Products of Ontario    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 393335,
    "name": "Pacifica USA    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 227240,
    "name": "Pacioni & C snc    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 294239,
    "name": "Packaging Aids Corp    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 320706,
    "name": "Packworld USA    ( Nazareth PA USA )",
    "country": "United States"
  },
  {
    "id": 458514,
    "name": "PACSGEAR Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 458515,
    "name": "PACSHealth LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 102618,
    "name": "Padgett Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 151242,
    "name": "Paes Nederland bv    ( Zoeterwoude  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 323820,
    "name": "PAG Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 104289,
    "name": "PageCom LLC    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 293250,
    "name": "Pago AG    ( Grabs  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 279433,
    "name": "Pago Etikettiersysteme GmbH    ( Aichtal  Germany )",
    "country": "Germany"
  },
  {
    "id": 458818,
    "name": "Paieon Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 152880,
    "name": "PAJUNK GmbH    ( Geisingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451001,
    "name": "Pajunk Medical Systems    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 105437,
    "name": "Pak-Rapid Inc    ( Conshohocken PA USA )",
    "country": "United States"
  },
  {
    "id": 352905,
    "name": "Pakistan Surgical Industries (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 462712,
    "name": "PAL Health Technologies    ( Pekin IL USA )",
    "country": "United States"
  },
  {
    "id": 452089,
    "name": "Paladin Biomedical Corp    ( Wilmot NH USA )",
    "country": "United States"
  },
  {
    "id": 451249,
    "name": "Palatin Technologies    ( Cranbury NJ USA )",
    "country": "United States"
  },
  {
    "id": 454036,
    "name": "Palbam Class Ltd    ( Ein Harod Ihud  Israel )",
    "country": "Israel"
  },
  {
    "id": 106847,
    "name": "Palco Labs Inc    ( Scotts Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 416148,
    "name": "Palex Medical SA    ( Sant Cugat del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 307312,
    "name": "Pall Filtration Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 461015,
    "name": "Pall ForteBio Corp    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 280909,
    "name": "Pall GmbH    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 171162,
    "name": "Pall Medical (UK)    ( Portsmouth  England )",
    "country": "UK"
  },
  {
    "id": 102304,
    "name": "Pall Medical Corp    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 451707,
    "name": "Palm Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 108173,
    "name": "Palmer Industries Inc    ( Endicott NY USA )",
    "country": "United States"
  },
  {
    "id": 192343,
    "name": "Palmero Health Care    ( Stratford CT USA )",
    "country": "United States"
  },
  {
    "id": 459414,
    "name": "Palo Alto Networks    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 226269,
    "name": "Palomar Medical Technologies Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 319223,
    "name": "Palumbo Orthopedics    ( Vienna VA USA )",
    "country": "United States"
  },
  {
    "id": 272167,
    "name": "Pan Servco Surgical Ltd    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 455661,
    "name": "Pan-America Hyperbarics Inc    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 455662,
    "name": "Pan-America Hyperbarics Inc (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 152901,
    "name": "Pan-Pacific Enterprises Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 106012,
    "name": "Panamax Inc    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 457220,
    "name": "Panasonic Biomedical Sales Europe bv (The Netherla    (   USA )",
    "country": "United States"
  },
  {
    "id": 457219,
    "name": "Panasonic Biomedical Sales Europe bv (United Kingd    (   USA )",
    "country": "United States"
  },
  {
    "id": 453690,
    "name": "Panasonic Corp    ( Kadoma  Japan )",
    "country": "Japan"
  },
  {
    "id": 457218,
    "name": "Panasonic Corp North America    ( Secaucus NJ USA )",
    "country": "United States"
  },
  {
    "id": 453691,
    "name": "Panasonic Corp of America    ( Secaucus NJ USA )",
    "country": "United States"
  },
  {
    "id": 104417,
    "name": "Panasonic Vision Systems    ( Secaucus NJ USA )",
    "country": "United States"
  },
  {
    "id": 398202,
    "name": "PanBio Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 306153,
    "name": "Panbio Pty Ltd    ( Windsor  USA )",
    "country": "United States"
  },
  {
    "id": 325165,
    "name": "Panex Corp    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 293494,
    "name": "PanGas Healthcare    ( Dagmersellen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451449,
    "name": "PanGo Networks    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 277771,
    "name": "Panmedica Negocios Hospitalares Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 358510,
    "name": "Panoramic Corp    ( Fort Wayne IN USA )",
    "country": "United States"
  },
  {
    "id": 363265,
    "name": "Pantak Ltd    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 335651,
    "name": "Pantech Instruments Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 108597,
    "name": "Pantex    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 354600,
    "name": "Pantheon Research Inc    ( Venice CA USA )",
    "country": "United States"
  },
  {
    "id": 175919,
    "name": "Panther Pacific Inc    ( Enterprise AL USA )",
    "country": "United States"
  },
  {
    "id": 103318,
    "name": "Paper Pak    ( San Dimas CA USA )",
    "country": "United States"
  },
  {
    "id": 427809,
    "name": "Par Excellence Systems Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 392679,
    "name": "Par Scientific A/S    ( Odense  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 335890,
    "name": "Para Scientific Co    ( Fairless Hills PA USA )",
    "country": "United States"
  },
  {
    "id": 458179,
    "name": "Para Systems Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 453720,
    "name": "Paracor Medical Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 284893,
    "name": "Paradies GmbH Gebr Kremers    ( Neukirchen-Vluyn  Germany )",
    "country": "Germany"
  },
  {
    "id": 184655,
    "name": "Paradigm Medical Industries Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 402524,
    "name": "Paradigm Medical Industries Inc Diagnostic Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 453686,
    "name": "Paradigm Spine GmbH    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453685,
    "name": "Paradigm Spine LLC    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 441635,
    "name": "Paradise Plastics    ( Plant City FL USA )",
    "country": "United States"
  },
  {
    "id": 462971,
    "name": "Paragon 28    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 462669,
    "name": "Paragon Group UK Ltd    ( Sunderland  England )",
    "country": "UK"
  },
  {
    "id": 452289,
    "name": "Paragon Medical Inc    ( Pierceton IN USA )",
    "country": "United States"
  },
  {
    "id": 175693,
    "name": "Paragon Service    ( Saline MI USA )",
    "country": "United States"
  },
  {
    "id": 177058,
    "name": "Paraid Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 451998,
    "name": "Paraid Medical Systems    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 458988,
    "name": "Parallabs Ltd    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 339970,
    "name": "Parallax Technology Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 278308,
    "name": "Parama-Tech Co Ltd    ( Fukuoka  Japan )",
    "country": "Japan"
  },
  {
    "id": 280746,
    "name": "Paramed    ( Dasing  Germany )",
    "country": "Germany"
  },
  {
    "id": 999423,
    "name": "ParaMed Exercise Equipment, Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 458075,
    "name": "Paramed Latin America    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 458069,
    "name": "Paramed Medical Systems Inc    ( Morton Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 458070,
    "name": "Paramed srl    ( Genova (GE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 158002,
    "name": "Paramount Bed Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 397850,
    "name": "Paramount Fitness (UK)    ( Coggeshall  England )",
    "country": "UK"
  },
  {
    "id": 108804,
    "name": "Paramount Fitness Corp    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 306371,
    "name": "Paramount Surgimed Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 374343,
    "name": "Paraproducts Inc    ( South Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 451584,
    "name": "Parata Systems    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 454469,
    "name": "Paraytec Ltd    ( York  England )",
    "country": "UK"
  },
  {
    "id": 466291,
    "name": "Parcus Medical LLC    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 284227,
    "name": "Pardes Spezialstruempfe GmbH & Co KG    ( Maintal  Germany )",
    "country": "Germany"
  },
  {
    "id": 439972,
    "name": "PARE Surgical Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 329725,
    "name": "Parema    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 163937,
    "name": "PARI GmbH    ( Starnberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 324795,
    "name": "PARI Medical Ltd    ( West Byfleet  England )",
    "country": "UK"
  },
  {
    "id": 163945,
    "name": "PARI Respiratory Equipment Inc    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 999945,
    "name": "PARI Respiratory Equipment, Inc    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 451032,
    "name": "Paris Biotech    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 459371,
    "name": "Park Bioservices LLC    ( Groveland MA USA )",
    "country": "United States"
  },
  {
    "id": 452534,
    "name": "Park House Healthcare Ltd    ( Birkenshaw  England )",
    "country": "UK"
  },
  {
    "id": 103643,
    "name": "Park Surgical Co Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 101589,
    "name": "Parkell Inc    ( Edgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 391304,
    "name": "Parker Bath Ltd    ( New Milton  USA )",
    "country": "United States"
  },
  {
    "id": 454132,
    "name": "Parker Domnick Hunter Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 378262,
    "name": "Parker Hannifin Corp    ( Haverhill MA USA )",
    "country": "United States"
  },
  {
    "id": 457855,
    "name": "Parker Hannifin Corp    ( Rohnert Park CA USA )",
    "country": "United States"
  },
  {
    "id": 453757,
    "name": "Parker Hannifin Ltd    ( Birtley  England )",
    "country": "UK"
  },
  {
    "id": 354556,
    "name": "Parker Healthcare Pty Ltd    ( Mitcham  Australia )",
    "country": "Australia"
  },
  {
    "id": 101590,
    "name": "Parker Laboratories Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 370447,
    "name": "Parker Medical Assoc    ( Highlands Ranch CO USA )",
    "country": "United States"
  },
  {
    "id": 106189,
    "name": "Parker X-Ray    ( East Hartford CT USA )",
    "country": "United States"
  },
  {
    "id": 102998,
    "name": "Parks Medical Electronics Inc    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 285306,
    "name": "Paro Handelshaus fuer Papier Buerobedarf Tapeten G    ( Pasewalk  Germany )",
    "country": "Germany"
  },
  {
    "id": 459317,
    "name": "Parr Instrument Co    ( Moline IL USA )",
    "country": "United States"
  },
  {
    "id": 451234,
    "name": "Pars Azma Co    ( Tehran  USA )",
    "country": "United States"
  },
  {
    "id": 105331,
    "name": "Parsons ADL Inc    ( Tottenham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 335099,
    "name": "Parsons Mfg Corp    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 453955,
    "name": "Partec Afrique Centrale SARL    ( Douala  Cameroon )",
    "country": "Cameroon"
  },
  {
    "id": 452983,
    "name": "Partec GmbH    ( Muenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 455893,
    "name": "Partec India    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 453057,
    "name": "Partec Italia srl    ( Carate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453954,
    "name": "Partec Japan Inc    ( Tsuchiura  Japan )",
    "country": "Japan"
  },
  {
    "id": 453058,
    "name": "Partec Nederland bv    ( Heerhugowaard  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457785,
    "name": "Partec Nordic AB    ( Nassjo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452984,
    "name": "Partec North America Inc    ( Swedesboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 453055,
    "name": "Partec Sarl    ( Sainte-Genevieve-des-Bois  France )",
    "country": "France"
  },
  {
    "id": 457784,
    "name": "Partec South Asia    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 453056,
    "name": "Partec UK ltd    ( Canterbury  England )",
    "country": "UK"
  },
  {
    "id": 461280,
    "name": "Particle Sizing Systems    ( Port Richey FL USA )",
    "country": "United States"
  },
  {
    "id": 457123,
    "name": "Parts Consulting Group Inc    ( Warren OH USA )",
    "country": "United States"
  },
  {
    "id": 330995,
    "name": "Parts Port Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 417703,
    "name": "PartsSource    ( Aurora OH USA )",
    "country": "United States"
  },
  {
    "id": 409971,
    "name": "Parvo Medics Inc    ( Sandy UT USA )",
    "country": "United States"
  },
  {
    "id": 118479,
    "name": "Pascal Co    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 105489,
    "name": "Pasco Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 185463,
    "name": "PASO SA Pabianickie Zaklady Srodkow Opatrunkowych    ( Pabianice  Poland )",
    "country": "Poland"
  },
  {
    "id": 102152,
    "name": "Pass & Seymour/Legrand    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 456069,
    "name": "Passau Impex    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 452169,
    "name": "Passport Health Communications    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 150912,
    "name": "Passy-Muir Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 179531,
    "name": "Pat Grimes Inc    ( Fredericksburg VA USA )",
    "country": "United States"
  },
  {
    "id": 455760,
    "name": "Paten Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458449,
    "name": "Pathfinder Technologies    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 363895,
    "name": "Pathlogix Corp    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 474898,
    "name": "PathoFinder BV    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 187308,
    "name": "Pathology Computer Systems    ( Madison IN USA )",
    "country": "United States"
  },
  {
    "id": 459316,
    "name": "Pathology Devices Inc    ( Westminster MD USA )",
    "country": "United States"
  },
  {
    "id": 462665,
    "name": "Pathology Innovations LLC    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 454483,
    "name": "Pathway Medical Technologies Inc    ( Kirkland WA USA )",
    "country": "United States"
  },
  {
    "id": 184880,
    "name": "Patient Care Technologies Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 441730,
    "name": "Patient Care Technology Systems LLC    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 461284,
    "name": "Patient Comfort Systems    ( Newark CA USA )",
    "country": "United States"
  },
  {
    "id": 462907,
    "name": "Patient Education Programs LLC    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 466639,
    "name": "Patient Engagement Advisors (PEA)    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 999809,
    "name": "Patient Technology    (   USA )",
    "country": "United States"
  },
  {
    "id": 453210,
    "name": "PatientKeeper Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 459216,
    "name": "PatientLink Enterprises Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 455302,
    "name": "PatientSafe Solutions Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 462805,
    "name": "PatientTrak    ( Batavia IL USA )",
    "country": "United States"
  },
  {
    "id": 170562,
    "name": "Patterson Dental    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 461325,
    "name": "Patterson EagleSoft    ( Effingham IL USA )",
    "country": "United States"
  },
  {
    "id": 454360,
    "name": "Patterson Medical    ( Warrenville IL USA )",
    "country": "United States"
  },
  {
    "id": 460759,
    "name": "Patterson Scientific    ( Bend OR USA )",
    "country": "United States"
  },
  {
    "id": 367453,
    "name": "Patton Surgical Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 462663,
    "name": "Patton's Medical    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 280367,
    "name": "Paul Boettger Ohg    ( Bodenmais  Germany )",
    "country": "Germany"
  },
  {
    "id": 282831,
    "name": "Paul Hartmann AG    ( Heidenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 453927,
    "name": "PAUL HARTMANN Pty Ltd    ( Homebush  Australia )",
    "country": "Australia"
  },
  {
    "id": 415425,
    "name": "Paul Hartmann SA    ( Chatenois  France )",
    "country": "France"
  },
  {
    "id": 279705,
    "name": "Paul Marienfeld GmbH & Co KG    ( Lauda-Koenigshofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282080,
    "name": "Paul Mitter GmbH & Co KG    ( Goeppingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280144,
    "name": "Paul Raebiger Pharmazeutische Apparate    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 293314,
    "name": "Paul Rickenbacher Med Lehr- und    ( Einsiedeln  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 284379,
    "name": "Paul Schmidt Aufzuege    ( Medebach  Germany )",
    "country": "Germany"
  },
  {
    "id": 284324,
    "name": "Paul Schoendorf Metallwaren GmbH    ( March-Buchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 366855,
    "name": "Pauldrach Medical GmbH    ( Garbsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 467184,
    "name": "Paulson Mfg Corp    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 355813,
    "name": "Pausch LLC    ( Tinton Falls NJ USA )",
    "country": "United States"
  },
  {
    "id": 450591,
    "name": "Paw Bioscience Products Inc    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 461125,
    "name": "Pawlak Enterprise Inc    ( East Schodack NY USA )",
    "country": "United States"
  },
  {
    "id": 103526,
    "name": "Pawling Corp    ( Wassaic NY USA )",
    "country": "United States"
  },
  {
    "id": 408699,
    "name": "Paxar Benelux bvba    ( Gent  USA )",
    "country": "United States"
  },
  {
    "id": 403682,
    "name": "Paxar Canada Inc    ( Pickering ON USA )",
    "country": "United States"
  },
  {
    "id": 359341,
    "name": "Paxar Corp    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 370412,
    "name": "Paxar Corp Pty Ltd    ( Lidcombe  USA )",
    "country": "United States"
  },
  {
    "id": 451178,
    "name": "Paxar de Colombia SA    ( Medellin  USA )",
    "country": "United States"
  },
  {
    "id": 370403,
    "name": "Paxar de Mexico SA de CV    ( Cd de Mexico  USA )",
    "country": "United States"
  },
  {
    "id": 403684,
    "name": "Paxar Deutschland GmbH    ( Loehne  USA )",
    "country": "United States"
  },
  {
    "id": 370404,
    "name": "Paxar do Brasil Ltda    ( Blumenau-SC  USA )",
    "country": "United States"
  },
  {
    "id": 370411,
    "name": "Paxar Far East Ltd    ( Kowloon  USA )",
    "country": "United States"
  },
  {
    "id": 403683,
    "name": "Paxar France SA    ( Fontenay sous Bois Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 408698,
    "name": "Paxar Iberia SA    ( Sant Quirze del Valles  USA )",
    "country": "United States"
  },
  {
    "id": 408697,
    "name": "Paxar Italia srl    ( Carpi Mo  USA )",
    "country": "United States"
  },
  {
    "id": 408701,
    "name": "Paxar Korea Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 370409,
    "name": "Paxar Polska Sp z o o    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 403685,
    "name": "Paxar Singapore Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 370410,
    "name": "Paxar Turkey    ( Kagithane/Istanubl  USA )",
    "country": "United States"
  },
  {
    "id": 408692,
    "name": "Paxar UK Ltd    ( Watford  USA )",
    "country": "United States"
  },
  {
    "id": 453368,
    "name": "Payerpath    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 102623,
    "name": "Payton Scientific Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 454568,
    "name": "PBF Online    ( Johnstown PA USA )",
    "country": "United States"
  },
  {
    "id": 466461,
    "name": "PC Connection Inc    ( Merrimack NH USA )",
    "country": "United States"
  },
  {
    "id": 459202,
    "name": "PCAA USA    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 458724,
    "name": "PCB Piezotronics Inc    ( Depew NY USA )",
    "country": "United States"
  },
  {
    "id": 457273,
    "name": "PCE Fitness    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 108810,
    "name": "PCI Medical Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 273777,
    "name": "PCI Medical Inc    ( Deep River CT USA )",
    "country": "United States"
  },
  {
    "id": 277887,
    "name": "PCK Electronic Industry and Trade Co Ltd    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 462588,
    "name": "PCO-TECH Inc    ( Romulus MI USA )",
    "country": "United States"
  },
  {
    "id": 108371,
    "name": "PCP-Champion    ( Ripley OH USA )",
    "country": "United States"
  },
  {
    "id": 458117,
    "name": "PDC Healthcare    ( Velencia CA USA )",
    "country": "United States"
  },
  {
    "id": 458118,
    "name": "PDC Healthcare (Belgium)    ( Nivelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453371,
    "name": "PDG Product Design Group Canada    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 453370,
    "name": "PDG Product Design Group USA    ( Blaine WA USA )",
    "country": "United States"
  },
  {
    "id": 108653,
    "name": "PDI Communication Systems    ( Springboro OH USA )",
    "country": "United States"
  },
  {
    "id": 452201,
    "name": "PDI Healthcare    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 999429,
    "name": "PDM Healthcare    (   USA )",
    "country": "United States"
  },
  {
    "id": 452174,
    "name": "PDM Productive Data Management    ( Rowland Heights CA USA )",
    "country": "United States"
  },
  {
    "id": 460677,
    "name": "PDQ Precision Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 334109,
    "name": "PDX Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 369622,
    "name": "PDZ Europa Ltd    ( Sandbach  USA )",
    "country": "United States"
  },
  {
    "id": 283494,
    "name": "PEA GmbH    ( Neuwied  Germany )",
    "country": "Germany"
  },
  {
    "id": 105101,
    "name": "Peace Medical Inc    ( Orange NJ USA )",
    "country": "United States"
  },
  {
    "id": 472782,
    "name": "Peach State Ambulance Inc    ( Tyrone GA USA )",
    "country": "United States"
  },
  {
    "id": 453834,
    "name": "Peacocks Medical Group Ltd    ( Newcastle upon Tynes  England )",
    "country": "UK"
  },
  {
    "id": 999853,
    "name": "Peak Scientific    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 459021,
    "name": "Peak Scientific Instruments Ltd    ( Inchinnan  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 453943,
    "name": "Peak Surgical Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 461344,
    "name": "Peake Software Labs Inc    ( Sparks MD USA )",
    "country": "United States"
  },
  {
    "id": 461176,
    "name": "Pearce Stainless    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 358052,
    "name": "Pearsalls Ltd    ( Taunton  England )",
    "country": "UK"
  },
  {
    "id": 320887,
    "name": "Pearson Dental Supply Co    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 458899,
    "name": "Pearson Medical Technologies    ( Alexandria LA USA )",
    "country": "United States"
  },
  {
    "id": 450866,
    "name": "Pearson NCS    ( Bloomington MN USA )",
    "country": "United States"
  },
  {
    "id": 459428,
    "name": "Pearson plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 293347,
    "name": "Pebe Datentechnik AG    ( Frauenfeld  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455479,
    "name": "PedAlign Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457112,
    "name": "Pedia Pals    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 458704,
    "name": "Pediavision    ( Lake Mary FL USA )",
    "country": "United States"
  },
  {
    "id": 102728,
    "name": "Pedicraft Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 199762,
    "name": "PediFix Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 104076,
    "name": "Pedigo Products Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 286227,
    "name": "Pedras Manicure-Artikel GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284688,
    "name": "Pedrazzini-Dental    ( Putzbrunn bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 153890,
    "name": "Peerless Industries Inc    ( Melrose Park IL USA )",
    "country": "United States"
  },
  {
    "id": 458475,
    "name": "peerVUE    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 454481,
    "name": "Pega Medical Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 107150,
    "name": "Pegasus Airwave Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 375639,
    "name": "Pegasus Egerton Ltd    ( Waterlooville  USA )",
    "country": "United States"
  },
  {
    "id": 400939,
    "name": "Pegasus GmbH    ( Kiel  USA )",
    "country": "United States"
  },
  {
    "id": 174700,
    "name": "Pegasus Industrial Specialties Inc    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458644,
    "name": "Pegasus Medical Concepts Inc    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 172180,
    "name": "Pegasus Research Corp    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 451208,
    "name": "Pegasus Scientific Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 284148,
    "name": "Peha Paul Hochkoepper GmbH & Co KG    ( Luedenscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 177063,
    "name": "Pehamed GmbH    ( Sulzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 459176,
    "name": "Pehratek Products    ( Chaska MN USA )",
    "country": "United States"
  },
  {
    "id": 452522,
    "name": "PEL Supply Co    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 459372,
    "name": "Pelco    ( Clovis CA USA )",
    "country": "United States"
  },
  {
    "id": 358422,
    "name": "Pelican Healthcare Ltd    ( Cardiff  Wales )",
    "country": "Wales"
  },
  {
    "id": 103527,
    "name": "Pellerin Milnor Corp    ( Kenner LA USA )",
    "country": "United States"
  },
  {
    "id": 999598,
    "name": "Pelonis Appliance Inc.    ( Grand Prairie TX USA )",
    "country": "United States"
  },
  {
    "id": 466942,
    "name": "Pelouze Scale    ( Bridgeview IL USA )",
    "country": "United States"
  },
  {
    "id": 408279,
    "name": "Pelstar LLC    ( Bridgeview IL USA )",
    "country": "United States"
  },
  {
    "id": 175636,
    "name": "Pelton Shepherd Industries    ( Stockton CA USA )",
    "country": "United States"
  },
  {
    "id": 185464,
    "name": "PEM Pracownia Elektroniki Medycznej    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 199715,
    "name": "Pemco Medical    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 102906,
    "name": "Penco Products Inc    ( Skippack PA USA )",
    "country": "United States"
  },
  {
    "id": 105265,
    "name": "Peninsula Laboratories Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 451484,
    "name": "Penlon America    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 139281,
    "name": "Penlon Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 186239,
    "name": "Penn Biomedical Support Inc    ( Blandon PA USA )",
    "country": "United States"
  },
  {
    "id": 454068,
    "name": "Penn Diagnostics    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 410024,
    "name": "PennCare Inc    ( Niles OH USA )",
    "country": "United States"
  },
  {
    "id": 106015,
    "name": "Penndas Laboratory Inc    ( Telford PA USA )",
    "country": "United States"
  },
  {
    "id": 441497,
    "name": "Penner Patient Care Inc    ( Aurora NE USA )",
    "country": "United States"
  },
  {
    "id": 272728,
    "name": "Pennine Healthcare Ltd    ( Derby  England )",
    "country": "UK"
  },
  {
    "id": 332115,
    "name": "Pennsylvania Medical Imaging Inc    ( Chalfont PA USA )",
    "country": "United States"
  },
  {
    "id": 999681,
    "name": "Pennsylvania Scale Company    (   USA )",
    "country": "United States"
  },
  {
    "id": 330067,
    "name": "PenRad Technologies Inc    ( Buffalo MN USA )",
    "country": "United States"
  },
  {
    "id": 361263,
    "name": "Pensi Rescue Oy    ( Sastamala  Finland )",
    "country": "Finland"
  },
  {
    "id": 458827,
    "name": "Pentair Aquatic Habitats    ( Apopka FL USA )",
    "country": "United States"
  },
  {
    "id": 441159,
    "name": "Pentair Water Treatment    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 462652,
    "name": "Pentapack NV    ( Kinrooi  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453941,
    "name": "Pentapharm GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451693,
    "name": "Pentapharm Ltd    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 282522,
    "name": "PENTAX Europe GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456798,
    "name": "PENTAX Europe GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 417313,
    "name": "PENTAX France SA    ( Argenteuil Cedex  France )",
    "country": "France"
  },
  {
    "id": 102999,
    "name": "PENTAX Medical Co    ( Montvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 417222,
    "name": "Pentax of America Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 174458,
    "name": "PENTAX UK Ltd    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 409772,
    "name": "Pentron Clinical Technologies LLC    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 452859,
    "name": "Penumbra Inc    ( Alameda CA USA )",
    "country": "United States"
  },
  {
    "id": 442002,
    "name": "PeopleSoft (Schweiz) AG    ( Volketswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454795,
    "name": "PEQLAB Biotechnologie GmbH (Austria)    ( Polling  Austria )",
    "country": "Austria"
  },
  {
    "id": 454792,
    "name": "PEQLAB Biotechnologie GmbH (Deutschland)    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454793,
    "name": "PEQLAB LLC    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 454794,
    "name": "PEQLAB Ltd    ( Sarisbury Green  England )",
    "country": "UK"
  },
  {
    "id": 431602,
    "name": "Per Madsen Design    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 340154,
    "name": "Per-Se Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 451617,
    "name": "Per-Se Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 453213,
    "name": "Perceptive Software    ( Shawnee KS USA )",
    "country": "United States"
  },
  {
    "id": 459177,
    "name": "Perceptual Testing Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457267,
    "name": "Percival Scientific Inc    ( Perry IA USA )",
    "country": "United States"
  },
  {
    "id": 325125,
    "name": "Percussionaire Belgium    ( Bruxelles  USA )",
    "country": "United States"
  },
  {
    "id": 108895,
    "name": "Percussionaire Corp    ( Sandpoint ID USA )",
    "country": "United States"
  },
  {
    "id": 458797,
    "name": "PercuVision LLC    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 455332,
    "name": "Peregrine Surgical Ltd    ( New Britain PA USA )",
    "country": "United States"
  },
  {
    "id": 320785,
    "name": "Perfecseal Inc/Medi-Plus    ( Oshkosh WI USA )",
    "country": "United States"
  },
  {
    "id": 294243,
    "name": "Perfecseal Ltd    ( Londonderry  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 457864,
    "name": "Perfect Imaging LLC    ( Beachwood OH USA )",
    "country": "United States"
  },
  {
    "id": 461319,
    "name": "Perfect Serve Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 342177,
    "name": "Perfectone Mold Co    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 416418,
    "name": "Perfector Scientific Inc    ( Atascadero CA USA )",
    "country": "United States"
  },
  {
    "id": 283534,
    "name": "Perfekta-Maschinenbau Foerdertechnik GmbH    ( Korschenbroich  Germany )",
    "country": "Germany"
  },
  {
    "id": 465023,
    "name": "Perficient Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 462642,
    "name": "Performance Design LLC    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 455378,
    "name": "Performance Enterprises    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452343,
    "name": "Performance Health Products Ltd    ( Pyle  Wales )",
    "country": "Wales"
  },
  {
    "id": 466271,
    "name": "Performance Interiors    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 460741,
    "name": "Performance Logic Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 371982,
    "name": "Performance Medical Devices    ( Richmond BC USA )",
    "country": "United States"
  },
  {
    "id": 183081,
    "name": "Performance Medical Group    ( Lafayette LA USA )",
    "country": "United States"
  },
  {
    "id": 452763,
    "name": "Performance Pharmacy Systems Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 458627,
    "name": "Performance Water Products    ( Buena Park CA USA )",
    "country": "United States"
  },
  {
    "id": 391881,
    "name": "Pergut Suministros Medicos & Embalajes SL    ( San Sebastion de Los Reyes  Spain )",
    "country": "Spain"
  },
  {
    "id": 455265,
    "name": "PeriGen Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 455266,
    "name": "PeriGen Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 148790,
    "name": "Perimed AB    ( Jarfalla  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401012,
    "name": "Perimed China Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401013,
    "name": "Perimed France Sarl    ( Craponne  France )",
    "country": "France"
  },
  {
    "id": 108831,
    "name": "Perimed Inc    ( Ardmore PA USA )",
    "country": "United States"
  },
  {
    "id": 401011,
    "name": "Perimed Italia srl    ( Cuggiono (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 324761,
    "name": "Perimed UK Ltd    ( Bury St Edmunds  England )",
    "country": "UK"
  },
  {
    "id": 467062,
    "name": "PeriOptix    ( Lompoc CA USA )",
    "country": "United States"
  },
  {
    "id": 108068,
    "name": "Peripheral Visions Inc    ( Auburn WA USA )",
    "country": "United States"
  },
  {
    "id": 457659,
    "name": "PerkinElmer (Australia)    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 457660,
    "name": "PerkinElmer (Canada)    ( Woodbridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457673,
    "name": "PerkinElmer (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457664,
    "name": "PerkinElmer (Denmark)    ( Skovlunde  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 457663,
    "name": "PerkinElmer (Finland)    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 457684,
    "name": "PerkinElmer (France)    ( Courtaboeuf Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 457662,
    "name": "PerkinElmer (Germany)    ( Rodgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 457674,
    "name": "PerkinElmer (Hong Kong) Ltd    ( Kwun Tong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457677,
    "name": "PerkinElmer (India)    ( Thane  India )",
    "country": "India"
  },
  {
    "id": 457685,
    "name": "PerkinElmer (Ireland)    ( Dublin 12  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 457680,
    "name": "PerkinElmer (Malaysia)    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 457687,
    "name": "PerkinElmer (Norway)    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 457681,
    "name": "PerkinElmer (Philippines)    ( Muntinlupa City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 457688,
    "name": "PerkinElmer (Poland)    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 457682,
    "name": "PerkinElmer (South Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457689,
    "name": "PerkinElmer (Spain)    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 457690,
    "name": "PerkinElmer (Sweden)    ( Sverige  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457691,
    "name": "PerkinElmer (Switzerland)    ( Schwerzenbach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457675,
    "name": "PerkinElmer (Taiwan) Corp    ( Kaohsiung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457683,
    "name": "PerkinElmer (Thailand)    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 457686,
    "name": "PerkinElmer (The Netherlands)    ( Groningen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457661,
    "name": "PerkinElmer (United Kingdom)    ( Beaconsfield  England )",
    "country": "UK"
  },
  {
    "id": 175323,
    "name": "PerkinElmer Asia    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 371981,
    "name": "PerkinElmer Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 457676,
    "name": "PerkinElmer Instruments (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457679,
    "name": "PerkinElmer Japan Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 416105,
    "name": "PerkinElmer Life and Analytical Sciences    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 401836,
    "name": "PerkinElmer Life and Analytical Sciences (Belgium)    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 403466,
    "name": "PerkinElmer Life and Analytical Sciences (Canada)    ( Woodbridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 372024,
    "name": "PerkinElmer Life and Analytical Sciences (Finland)    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 418478,
    "name": "PerkinElmer Life and Analytical Sciences (Germany)    ( Rodgau/Juegesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 439505,
    "name": "Perkinelmer Life and Analytical Sciences (Italy)    ( Monza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 401834,
    "name": "PerkinElmer Life and Analytical Sciences Ltd    ( Beaconsfield  England )",
    "country": "UK"
  },
  {
    "id": 409737,
    "name": "PerkinElmer Optoelectronics    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 401841,
    "name": "PerkinElmer Wallac GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 465186,
    "name": "Perkins Biomedical Services, LLC    ( Hooksett P. USA )",
    "country": "United States"
  },
  {
    "id": 146584,
    "name": "Perkins Electronics    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 458568,
    "name": "Perkins Healthcare Technologies    ( Richardson TX USA )",
    "country": "United States"
  },
  {
    "id": 108451,
    "name": "Perma Pure LLC    ( Toms River NJ USA )",
    "country": "United States"
  },
  {
    "id": 102625,
    "name": "Perma-Type Co Inc    ( Plainville CT USA )",
    "country": "United States"
  },
  {
    "id": 174948,
    "name": "Permark Inc    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 108250,
    "name": "Permatron Corp    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 452348,
    "name": "PerMedics Inc    ( San Bernardino CA USA )",
    "country": "United States"
  },
  {
    "id": 140229,
    "name": "Permobil AB    ( Timra  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 342341,
    "name": "Permobil bv    ( Kerkrade  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 108069,
    "name": "Permobil Inc    ( Lebanon TN USA )",
    "country": "United States"
  },
  {
    "id": 461321,
    "name": "Pero-System Messgeraete GmbH    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 452630,
    "name": "Peros    ( Lyons  France )",
    "country": "France"
  },
  {
    "id": 452164,
    "name": "Perot Systems Corp    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 454671,
    "name": "Perouse Medical    ( Ivry-le-Temple  France )",
    "country": "France"
  },
  {
    "id": 286482,
    "name": "Perpedes Fuss-Stuetzen GmbH    ( Lenningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451017,
    "name": "Perrigo Co    ( Allegan MI USA )",
    "country": "United States"
  },
  {
    "id": 107822,
    "name": "Perry Baromedical Corp    ( Riviera Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 458469,
    "name": "Perry Dynamics Inc    ( Decatur IL USA )",
    "country": "United States"
  },
  {
    "id": 451241,
    "name": "Persia Therapeutics Ltd    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 457045,
    "name": "Person Medical Co    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454311,
    "name": "Persona Medical    ( Casselberry FL USA )",
    "country": "United States"
  },
  {
    "id": 182827,
    "name": "Personal Communication Systems Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 466551,
    "name": "Personal Fitness Interiors    ( South Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 466327,
    "name": "Personal Med    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 441105,
    "name": "Personal Safety Corp    ( Cedar Rapids IA USA )",
    "country": "United States"
  },
  {
    "id": 108467,
    "name": "Personna Medical    ( Verona VA USA )",
    "country": "United States"
  },
  {
    "id": 461322,
    "name": "Perspective Enterprises    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 450501,
    "name": "Persys Technology Ltd    ( Lod  Israel )",
    "country": "Israel"
  },
  {
    "id": 458567,
    "name": "Persyst    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 416000,
    "name": "Perth Surgical Supply Co Pty Ltd    ( Perth  USA )",
    "country": "United States"
  },
  {
    "id": 462612,
    "name": "Pervasive Software Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 163658,
    "name": "Petas Profesyonel Elektronik San ve Tic AS    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 293653,
    "name": "Peter Auer AG    ( Truttikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 281919,
    "name": "Peter Auer GmbH    ( Gailingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 151450,
    "name": "Prosthetic Orthotic Center Ltd    ( La Crosse WI USA )",
    "country": "United States"
  },
  {
    "id": 284690,
    "name": "Peter Barthel & Wilhelm Schmidthals GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457739,
    "name": "Peter Brehm GmbH    ( Weisendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 457385,
    "name": "Peter Greven Physioderm GmbH    ( Euskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282244,
    "name": "Peter H Cronemeyer GmbH    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 281858,
    "name": "Peter Hoehnle    ( Frittlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285688,
    "name": "Peter M Boersch    ( Remscheid-Luettringhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279447,
    "name": "Peter Mueller GmbH    ( Salzgitter  Germany )",
    "country": "Germany"
  },
  {
    "id": 283784,
    "name": "Peter Schulze Kunststofftechnik GmbH    ( Langenargen  Germany )",
    "country": "Germany"
  },
  {
    "id": 363909,
    "name": "Petermann GmbH    ( Dombuehl  Germany )",
    "country": "Germany"
  },
  {
    "id": 354736,
    "name": "Peters Asia co Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 151027,
    "name": "Peters Laboratoire    ( Bobigny Cedex  France )",
    "country": "France"
  },
  {
    "id": 466527,
    "name": "Peters Surgical    ( Bobigny Cedex  France )",
    "country": "France"
  },
  {
    "id": 453272,
    "name": "PETNET Solutions Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 102626,
    "name": "Petra Mfg Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 285203,
    "name": "Petri & Lehr GmbH und Co KG    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 460749,
    "name": "Petrone Assoc LLC    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 457378,
    "name": "Pettibon System Inc    ( Chehalis WA USA )",
    "country": "United States"
  },
  {
    "id": 225852,
    "name": "Pevco Systems International Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 294245,
    "name": "PEXCO Inc    ( Northborough MA USA )",
    "country": "United States"
  },
  {
    "id": 150004,
    "name": "Pfeiffer Glass    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 293508,
    "name": "Pfeiffer Mollis AG    ( Mollis  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 103860,
    "name": "Pfingst & Co Inc    ( South Plainfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 455362,
    "name": "Pfizer Consumer Healthcare    ( Madison NJ USA )",
    "country": "United States"
  },
  {
    "id": 133964,
    "name": "Pfizer Inc    ( New York NY USA )",
    "country": "USA"
  },
  {
    "id": 160134,
    "name": "pfm AG    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 453754,
    "name": "PG Drive Technology    ( Christchurch  England )",
    "country": "UK"
  },
  {
    "id": 455175,
    "name": "PG LifeLink    ( Erlanger KY USA )",
    "country": "United States"
  },
  {
    "id": 462653,
    "name": "PGC Scientifics Corp    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 452839,
    "name": "PGP    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456303,
    "name": "PH Medisavers Ltd    ( Morton  England )",
    "country": "UK"
  },
  {
    "id": 455639,
    "name": "Phaco Repair    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 451545,
    "name": "Phadia AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452262,
    "name": "Phadia US Inc    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 416373,
    "name": "Phamatech Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 453809,
    "name": "Pharm Allergan GmbH    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 296797,
    "name": "Pharma & Health BVBA    ( Roeselare  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 282275,
    "name": "Pharma Test Apparatebau GmbH    ( Hainburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457931,
    "name": "Pharma Vinci A/S    ( Frederiksvaerk  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 456339,
    "name": "Pharmacare Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 450820,
    "name": "Pharmaceutical Assoc Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 101595,
    "name": "Pharmaceutical Innovations Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 107136,
    "name": "Pharmacia Diagnostics (US) Inc    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 230967,
    "name": "Pharmacy Computer Services Inc    ( Grants Pass OR USA )",
    "country": "United States"
  },
  {
    "id": 459137,
    "name": "Pharmacy OneSource    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 462634,
    "name": "Pharmacy Providers of Oklahoma Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 452911,
    "name": "Pharmalucence Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 455718,
    "name": "Pharmaplast    ( Alexandria  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 272232,
    "name": "PharmaSoft AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 280946,
    "name": "Pharmatec-Pharma-Maschinen GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 281937,
    "name": "Pharmatechnik GmbH    ( Starnberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 461033,
    "name": "PharmEcology Services    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 280578,
    "name": "Pharno-Wedropharm GmbH    ( Buchholz  USA )",
    "country": "United States"
  },
  {
    "id": 293464,
    "name": "Pharnova SA    ( Lausanne  USA )",
    "country": "United States"
  },
  {
    "id": 455463,
    "name": "PHASEIN AB    ( Danderyd  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455464,
    "name": "PHASEIN ASIA    ( Nanshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101598,
    "name": "Phelan Mfg Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 456580,
    "name": "PHENIX Research Products    ( Candler NC USA )",
    "country": "United States"
  },
  {
    "id": 150030,
    "name": "Philadelphia Cervical Collar Co    ( Thorofare NJ USA )",
    "country": "United States"
  },
  {
    "id": 158809,
    "name": "Philipp Kirsch GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 415453,
    "name": "Philips Argentina SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 456830,
    "name": "Philips Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 456438,
    "name": "Philips Burton    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 456825,
    "name": "Philips Color Kinetics    ( Burlington MS USA )",
    "country": "United States"
  },
  {
    "id": 456827,
    "name": "Philips Day-Brite Canada    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456834,
    "name": "Philips do Brasil    ( Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 187950,
    "name": "Philips Electronics Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 186113,
    "name": "Philips Electronics UK Ltd    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 456435,
    "name": "Philips Emergin Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 160136,
    "name": "Philips GmbH    ( Kassel  USA )",
    "country": "United States"
  },
  {
    "id": 453414,
    "name": "Philips Healthcare    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 453548,
    "name": "Philips Healthcare    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 456828,
    "name": "Philips Healthcare    ( Suresnes Cedex  France )",
    "country": "France"
  },
  {
    "id": 453554,
    "name": "Philips Healthcare (Italy)    ( Monza MI  Italy )",
    "country": "Italy"
  },
  {
    "id": 453552,
    "name": "Philips Healthcare Australiasia    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 453550,
    "name": "Philips Healthcare Canada    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453553,
    "name": "Philips Healthcare Hong Kong    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452290,
    "name": "Philips Healthcare Informatics    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 453549,
    "name": "Philips Healthcare Nederland    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453551,
    "name": "Philips Healthcare North America    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 454222,
    "name": "Philips Lifeline Systems    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 106591,
    "name": "Philips Lighting Co    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 456826,
    "name": "Philips Lumileds Lighting Co    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 397917,
    "name": "Philips Medical Systems    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 398048,
    "name": "Philips Medical Systems (Asia Pacific)    ( Taikoo Shing  USA )",
    "country": "United States"
  },
  {
    "id": 398047,
    "name": "Philips Medical Systems (Europe)    ( Boeblingen  USA )",
    "country": "United States"
  },
  {
    "id": 188101,
    "name": "Philips Medical Systems Asia    ( Wanchai  USA )",
    "country": "United States"
  },
  {
    "id": 415449,
    "name": "Philips Medical Systems Australasia    ( North Ryde  USA )",
    "country": "United States"
  },
  {
    "id": 415446,
    "name": "Philips Medical Systems Belgium/Luxembourg    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 397918,
    "name": "Philips Medical Systems Canada    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 460527,
    "name": "Philips Medical Systems Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 415450,
    "name": "Philips Medical Systems International bv    ( Best  USA )",
    "country": "United States"
  },
  {
    "id": 456484,
    "name": "Philips Medical Systems Ltda    ( Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 102120,
    "name": "Philips Medical Systems North America    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 415447,
    "name": "Philips Medical Systems UK Ltd    ( Reigate  England )",
    "country": "UK"
  },
  {
    "id": 155383,
    "name": "Philips Medical Systems/ATL SpA    ( Monza MI  USA )",
    "country": "United States"
  },
  {
    "id": 160137,
    "name": "Philips Medizin Systeme GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 415444,
    "name": "Philips Medizinische Systeme Austria    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 457260,
    "name": "Philips Oral Healthcare    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 454161,
    "name": "Philips Respironics    ( Murrysville PA USA )",
    "country": "United States"
  },
  {
    "id": 458089,
    "name": "Philips Spa Healthcare    ( Monza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 330998,
    "name": "Philips Speech Processing    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 415448,
    "name": "Philips Systemes Medicaux France    ( Suresnes Cedex  France )",
    "country": "France"
  },
  {
    "id": 453764,
    "name": "Phillips Safety Products    ( Middlesex NJ USA )",
    "country": "United States"
  },
  {
    "id": 456124,
    "name": "Philosys    ( Gunsan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 101986,
    "name": "Phipps & Bird Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 435246,
    "name": "phiTec International Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 457877,
    "name": "Phoenix AirMid Biomedical Corp    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 450804,
    "name": "Phoenix Bio-Tech Corp    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 408324,
    "name": "Phoenix Biomedical Products Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458021,
    "name": "Phoenix Controls    ( Acton MA USA )",
    "country": "United States"
  },
  {
    "id": 187151,
    "name": "Phoenix Cosmetics Laboratories Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 185662,
    "name": "Phoenix Data Systems Inc    ( Southfield MI USA )",
    "country": "United States"
  },
  {
    "id": 146598,
    "name": "Phoenix Equipment Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 461337,
    "name": "Phoenix HealthCare Services Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 460650,
    "name": "Phoenix Kiosk Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 459037,
    "name": "Phoenix LTC    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 465085,
    "name": "Phoenix Medical Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 358057,
    "name": "Phoenix Medical Ltd    ( Preston  England )",
    "country": "UK"
  },
  {
    "id": 108975,
    "name": "Phoenix Medical Service Inc    ( Roseville MN USA )",
    "country": "United States"
  },
  {
    "id": 346929,
    "name": "Phoenix Medical Systems Pft Ltd    ( Chennai  India )",
    "country": "India"
  },
  {
    "id": 459038,
    "name": "Phoenix Ortho    ( Rockwall TX USA )",
    "country": "United States"
  },
  {
    "id": 460763,
    "name": "Phoenix Research Labs    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 467383,
    "name": "Phoenix Surgical Instruments Ltd    ( Hoddesdon  England )",
    "country": "UK"
  },
  {
    "id": 466645,
    "name": "Phoenix Technical Services Inc    ( Pearl MS USA )",
    "country": "United States"
  },
  {
    "id": 371576,
    "name": "Pholatec Ltd    ( Schlieren/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 373934,
    "name": "Phonak AG    ( Staefa  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 281521,
    "name": "Phonak GmbH    ( Fellbach-Oeffingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 330907,
    "name": "Phonak Inc USA    ( Warrenville IL USA )",
    "country": "United States"
  },
  {
    "id": 457578,
    "name": "Phone Labs Technology Co Inc    ( Bridgeport CT USA )",
    "country": "United States"
  },
  {
    "id": 152775,
    "name": "Phone-TTY Inc    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 332121,
    "name": "Phonetics Inc    ( Aston PA USA )",
    "country": "United States"
  },
  {
    "id": 454162,
    "name": "PhoneTree    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 101439,
    "name": "Phonic Ear Inc    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 340829,
    "name": "Phonic Ear Ltd    ( Mississauga ON USA )",
    "country": "United States"
  },
  {
    "id": 307417,
    "name": "Phormpak SA    ( Glenashley  USA )",
    "country": "United States"
  },
  {
    "id": 462624,
    "name": "Photo Diagnostic Systems    ( Boxboro MA USA )",
    "country": "United States"
  },
  {
    "id": 102627,
    "name": "Photo Research Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 457184,
    "name": "Photodyne Technologies    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 455399,
    "name": "Photologic UK Ltd    ( Elstree  England )",
    "country": "UK"
  },
  {
    "id": 403706,
    "name": "PhotoMed GmbH (Denmark)    ( Koge  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 403710,
    "name": "PhotoMed GmbH (Germany)    ( Seefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 401112,
    "name": "PhotoMedex    ( Montgomeryville PA USA )",
    "country": "United States"
  },
  {
    "id": 450931,
    "name": "Photometrics    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 362704,
    "name": "Photon Technology International (Canada) Inc    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 202280,
    "name": "Photon Technology International Inc    ( Birmingham NJ USA )",
    "country": "United States"
  },
  {
    "id": 458428,
    "name": "Photon2 Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 453465,
    "name": "Photonic Instruments Inc    ( St Charles IL USA )",
    "country": "United States"
  },
  {
    "id": 999639,
    "name": "PhotoTherm    ( Trenton NJ USA )",
    "country": "USA"
  },
  {
    "id": 460665,
    "name": "Photron USA Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457143,
    "name": "PHS Technologies Group LLC    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 453029,
    "name": "PHS West Inc    ( Hanover MN USA )",
    "country": "United States"
  },
  {
    "id": 285395,
    "name": "PHW-Plauener Holzwerkstaetten GmbH    ( Plauen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280760,
    "name": "Phyroplan Trenker GmbH & Co KG    ( Dautphetal  Germany )",
    "country": "Germany"
  },
  {
    "id": 160139,
    "name": "Physia GmbH    ( Neu/Isenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458795,
    "name": "Physical Sciences Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 105418,
    "name": "Physician Engineered Products (PEP) Inc    ( Fryeburg ME USA )",
    "country": "United States"
  },
  {
    "id": 457863,
    "name": "Physician's Resource for Medical Equipment Inc    ( Moutaintop PA USA )",
    "country": "United States"
  },
  {
    "id": 146599,
    "name": "Physicians Resource Network    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 105661,
    "name": "Physicians' Record Co    ( Berwyn IL USA )",
    "country": "United States"
  },
  {
    "id": 458005,
    "name": "Physik Instrumente (PI) GmbH & Co KG    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 452688,
    "name": "Physio Boerse    ( Wittlich  Germany )",
    "country": "Germany"
  },
  {
    "id": 452576,
    "name": "Physio-Control (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 456774,
    "name": "Physio-Control (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 452577,
    "name": "Physio-Control (Czech Republic)    ( Praha 6  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 452578,
    "name": "Physio-Control (France)    ( Boulogne/Billancourt Cedex  France )",
    "country": "France"
  },
  {
    "id": 456775,
    "name": "Physio-Control (France)    ( Boulogne-Billancourt Cedex  France )",
    "country": "France"
  },
  {
    "id": 452585,
    "name": "Physio-Control (Germany)    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 452579,
    "name": "Physio-Control (Hungary)    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 452580,
    "name": "Physio-Control (Italy)    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452581,
    "name": "Physio-Control (Middle East)    ( Beirut  Lebanon )",
    "country": "Lebanon"
  },
  {
    "id": 452582,
    "name": "Physio-Control (Netherlands)    ( Kerkrade  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452583,
    "name": "Physio-Control (Poland)    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 452586,
    "name": "Physio-Control (Scandinavia)    ( Jarfalla  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452584,
    "name": "Physio-Control (Spain)    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 456776,
    "name": "Physio-Control (Spain)    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 456777,
    "name": "Physio-Control (Sweden)    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 452587,
    "name": "Physio-Control (Switzerland)    ( Tolochenaz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452588,
    "name": "Physio-Control (UK)    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 456779,
    "name": "Physio-Control (UK)    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 452589,
    "name": "Physio-Control Corp (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452575,
    "name": "Physio-Control Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 456773,
    "name": "Physio-Control Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 456778,
    "name": "Physio-Control Inc (Canada)    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 415962,
    "name": "PhysIOL SA    ( Liege  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 186244,
    "name": "Physiologic Reps Inc (PRI)    ( Sun Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 285969,
    "name": "Physiomed Electromedizin AG    ( Schnaittach-Laipersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 384273,
    "name": "Physiomed Moskva    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 107367,
    "name": "Physitemp Instruments Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 337098,
    "name": "Physmark Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 325193,
    "name": "PI Professional Therapy Products    ( Athens TN USA )",
    "country": "United States"
  },
  {
    "id": 458641,
    "name": "PICC ACCESS LLC    ( Rome GA USA )",
    "country": "United States"
  },
  {
    "id": 366333,
    "name": "Picis Inc    ( Wakefield MA USA )",
    "country": "United States"
  },
  {
    "id": 455425,
    "name": "Picis International Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450280,
    "name": "Picis Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 366336,
    "name": "Picis SA (Spain)    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 101602,
    "name": "Picker International Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 451295,
    "name": "PickPoint    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 461136,
    "name": "Picometrics Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 461134,
    "name": "Picometrics Technologies SAS    ( Labege  France )",
    "country": "France"
  },
  {
    "id": 233880,
    "name": "PicturePhone Direct Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 450981,
    "name": "PID Analyzers LLC UK    ( Frodsham  England )",
    "country": "UK"
  },
  {
    "id": 450980,
    "name": "PID Analyzers LLC USA    ( Pembroke MA USA )",
    "country": "United States"
  },
  {
    "id": 410009,
    "name": "PIE Medical International Inc    ( Buford GA USA )",
    "country": "United States"
  },
  {
    "id": 292062,
    "name": "Piedmont Medical Inc    ( Dobson NC USA )",
    "country": "United States"
  },
  {
    "id": 105371,
    "name": "Pierce Biotechnology    ( Rockford IL USA )",
    "country": "United States"
  },
  {
    "id": 457233,
    "name": "Piezosurgery Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 156408,
    "name": "Pigeon Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 315012,
    "name": "Pihsiang Machinery Mfg Co Ltd    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 287845,
    "name": "Pikosystem Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 293619,
    "name": "Pilger Medizin-Elektronik AG    ( Zofingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458160,
    "name": "Pilgrim Medical Equipment Co    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 439535,
    "name": "Pilkington Special Glass Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 398192,
    "name": "Pilkington Special Glass Ltd    ( St Asaph  USA )",
    "country": "United States"
  },
  {
    "id": 458639,
    "name": "Pillar Orthopedic Design LLC    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 454772,
    "name": "Pilling Weck Asia Pilling Weck Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450727,
    "name": "Pilling Weck Canada LP    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 241855,
    "name": "Pilot Blankenfelde Medizinisch eletronische Geraet    ( Blankenfelde  Germany )",
    "country": "Germany"
  },
  {
    "id": 459179,
    "name": "Pimentel Communications Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 340102,
    "name": "Pinestar Technology Inc    ( Greenville PA USA )",
    "country": "United States"
  },
  {
    "id": 356995,
    "name": "Pinnacle Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 462870,
    "name": "Pinnacle Marketing Group    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 450517,
    "name": "Pinnacle Medical Supply    ( Rowlett TX USA )",
    "country": "United States"
  },
  {
    "id": 999805,
    "name": "Pinnacle Medsource    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 309935,
    "name": "Pinnacle Technology Group    ( Ottawa Lake MI USA )",
    "country": "United States"
  },
  {
    "id": 458427,
    "name": "Pinpoint Ltd    ( Leven  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 356743,
    "name": "Pioneer Biomedical    ( Leawood KS USA )",
    "country": "United States"
  },
  {
    "id": 365291,
    "name": "Pioneer Laboratories Inc    ( Marquette MI USA )",
    "country": "United States"
  },
  {
    "id": 170567,
    "name": "Pioneer Medical Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 101687,
    "name": "Pioneer Medical Systems Inc    ( Melrose MA USA )",
    "country": "United States"
  },
  {
    "id": 431610,
    "name": "Piper Products Inc    ( Wausau WI USA )",
    "country": "United States"
  },
  {
    "id": 457972,
    "name": "Pishtaz Teb Diagnostics    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 287847,
    "name": "Piston Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 272726,
    "name": "PitBros Ltd    ( Ilford  England )",
    "country": "UK"
  },
  {
    "id": 282891,
    "name": "Pitney Bowes Warenauszeichnungssysteme    ( Heppenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 466253,
    "name": "Pitts Little Corp    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 463019,
    "name": "Pivot Interiors    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 462619,
    "name": "Pivotal Health Solutions    ( Watertown SD USA )",
    "country": "United States"
  },
  {
    "id": 461149,
    "name": "PixeLINK    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 309659,
    "name": "PK Kloek Lijsten    ( Wijchen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 337101,
    "name": "PKC Corp    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 366851,
    "name": "PL Medical Co LLC    ( New Britain CT USA )",
    "country": "United States"
  },
  {
    "id": 397119,
    "name": "Plade Ltd    ( Glasgow  USA )",
    "country": "United States"
  },
  {
    "id": 332131,
    "name": "Planar Display Solutions    (   USA )",
    "country": "United States"
  },
  {
    "id": 233882,
    "name": "Planar Systems Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 428429,
    "name": "Planar Systems Inc (Finland)    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 409754,
    "name": "Planar Systems NV    ( Hever  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 186788,
    "name": "Plandent    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 148995,
    "name": "Planer Products Ltd    ( Sunbury-on-Thames  England )",
    "country": "UK"
  },
  {
    "id": 462616,
    "name": "Planetree    ( Derby CT USA )",
    "country": "United States"
  },
  {
    "id": 400983,
    "name": "Planilux Geraetebau Felix Schulte GmbH & Co KG    ( Warstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 177900,
    "name": "Planmeca Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 162459,
    "name": "Planmeca Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 344885,
    "name": "Planmed Germany    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 266302,
    "name": "Planmed Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 344886,
    "name": "Planmed Italy    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 149072,
    "name": "Planmed Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 344887,
    "name": "Planmed UK    ( Potters Bar  USA )",
    "country": "United States"
  },
  {
    "id": 467376,
    "name": "PlaqueTec Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 103063,
    "name": "Plas-Labs Inc    ( Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 107442,
    "name": "Plas-Pak Industries Inc    ( Norwich CT USA )",
    "country": "United States"
  },
  {
    "id": 162036,
    "name": "Plasco Inc    ( Columbus MS USA )",
    "country": "United States"
  },
  {
    "id": 458022,
    "name": "Plascore Inc    ( Zeeland MI USA )",
    "country": "United States"
  },
  {
    "id": 459483,
    "name": "Plasma Etch Inc    ( Carson City NV USA )",
    "country": "United States"
  },
  {
    "id": 455485,
    "name": "Plasma Surgical AB    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455484,
    "name": "Plasma Surgical Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 455483,
    "name": "Plasma Surgical Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 455486,
    "name": "Plasma Surgical SARL    ( Paris La Defense Cedex  France )",
    "country": "France"
  },
  {
    "id": 459491,
    "name": "PLASMAtech Inc    ( Erlanger KY USA )",
    "country": "United States"
  },
  {
    "id": 294255,
    "name": "Plasti Medical SpA    ( Villamarzana (RO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 335427,
    "name": "Plasti-Products Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 461159,
    "name": "Plastic Concepts Inc    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 138242,
    "name": "Plastic Extruders    ( Wickford  England )",
    "country": "UK"
  },
  {
    "id": 102451,
    "name": "Plastic Mfr Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 293500,
    "name": "Plastifil SA    ( Mendrisio  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 101604,
    "name": "Plastodent Inc    ( Bronx NY USA )",
    "country": "United States"
  },
  {
    "id": 441161,
    "name": "Platinum Medical    ( Hebron KY USA )",
    "country": "United States"
  },
  {
    "id": 464109,
    "name": "Playtex Baby    ( New Providence NJ USA )",
    "country": "United States"
  },
  {
    "id": 171226,
    "name": "Playtex Products Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 106088,
    "name": "Plaza Medical Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 188912,
    "name": "PLC Medical Systems Inc    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 362067,
    "name": "Pleasure Latex Products Sdn Bhd    ( Jeram  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 453852,
    "name": "Plexis Healthcare Systems    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 458566,
    "name": "Plexon Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 999869,
    "name": "Plextor Americas    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 373073,
    "name": "PLH Products Inc    ( Buena Park CA USA )",
    "country": "United States"
  },
  {
    "id": 360630,
    "name": "PLI Professional Lamps Inc    ( Farmingdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 282848,
    "name": "Ploetz-Werk G Timmermann Kunststoffverarbeitung    ( Heilbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 426270,
    "name": "Plumb E-Z    ( New Britain CT USA )",
    "country": "United States"
  },
  {
    "id": 435470,
    "name": "PLUS Endoprothetik bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 435463,
    "name": "PLUS Endoprothetik Gesellschaft mbH    ( Moedling  Austria )",
    "country": "Austria"
  },
  {
    "id": 435464,
    "name": "PLUS Endoprothetik GmbH    ( Marl  Germany )",
    "country": "Germany"
  },
  {
    "id": 435468,
    "name": "PLUS Endoprothetik Hellas SA    ( Illioupoli  Greece )",
    "country": "Greece"
  },
  {
    "id": 333596,
    "name": "PLUS Orthopedics    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 435250,
    "name": "PLUS Orthopedics AG    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461323,
    "name": "Plus Technologies LLC    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 106196,
    "name": "Reese X-Ray Co Inc    ( North Augusta SC USA )",
    "country": "United States"
  },
  {
    "id": 435251,
    "name": "PLUS-FOSUN Orthopedic Products Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 462601,
    "name": "Plusoptix Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 452523,
    "name": "Pluss Organisation Ltd    ( Exeter  England )",
    "country": "UK"
  },
  {
    "id": 108802,
    "name": "PM Gloves Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 462797,
    "name": "PMC2    ( Pfafftown NC USA )",
    "country": "United States"
  },
  {
    "id": 107190,
    "name": "PML Microbiologicals Inc    ( Wilsonville OR USA )",
    "country": "United States"
  },
  {
    "id": 323947,
    "name": "PMS Instruments Ltd    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 104294,
    "name": "PMT Corp    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 289402,
    "name": "PMT Corp    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 453468,
    "name": "PneuLogix    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 439932,
    "name": "Pneumex Inc    ( Sandpoint ID USA )",
    "country": "United States"
  },
  {
    "id": 455259,
    "name": "Pnn Medical A/S    ( Kvistgaard  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 455263,
    "name": "Pnn Medical Deutschland    ( Harrislee  Germany )",
    "country": "Germany"
  },
  {
    "id": 455264,
    "name": "Pnn Medical USA    ( Chesapeake VA USA )",
    "country": "United States"
  },
  {
    "id": 460737,
    "name": "PNY Technologies Inc    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 185465,
    "name": "POCH Polskie Odczynniki Chemiczne SA    ( Gliwice  Poland )",
    "country": "Poland"
  },
  {
    "id": 462597,
    "name": "Pocket Nurse    ( Monaca PA USA )",
    "country": "United States"
  },
  {
    "id": 455329,
    "name": "PocketSonics Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 999979,
    "name": "PODIATHERM    (   USA )",
    "country": "United States"
  },
  {
    "id": 107746,
    "name": "Pohlmann Rescue Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 467149,
    "name": "Point A Technologies Inc    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 454602,
    "name": "Point and Click Solutions Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 453419,
    "name": "Point Blank Solutions Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 325899,
    "name": "Point of Care Diagnostics Australia Pty Ltd    ( Artarmon  Australia )",
    "country": "Australia"
  },
  {
    "id": 399205,
    "name": "Point of Care Diagnostics New Zealand Ltd    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 288720,
    "name": "Point-of-View Systems Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 456814,
    "name": "PointCare Technologies Inc    ( Marlborough MA USA )",
    "country": "United States"
  },
  {
    "id": 462592,
    "name": "PointClickCare    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 155939,
    "name": "Pointe Scientific Inc    ( Canton MI USA )",
    "country": "United States"
  },
  {
    "id": 457091,
    "name": "PointNix Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461287,
    "name": "PointOne Systems LLC    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 282169,
    "name": "Polar Electro GmbH Deutschland    ( Buettelborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 199711,
    "name": "Polar Electro Inc    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 456712,
    "name": "Polar Products Inc    ( Stow OH USA )",
    "country": "United States"
  },
  {
    "id": 102452,
    "name": "Polar Ware Co    ( Kiel WI USA )",
    "country": "United States"
  },
  {
    "id": 459031,
    "name": "Polaris Industries Inc    ( Medina MN USA )",
    "country": "United States"
  },
  {
    "id": 466530,
    "name": "Polaris Medical Imaging    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 103066,
    "name": "Polaroid Corp    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 305582,
    "name": "Polartechnics Ltd    ( Erskineville  Australia )",
    "country": "Australia"
  },
  {
    "id": 185469,
    "name": "Poldent Sp z o o Polsko-Szwedzkie Joint-Venture    ( Warszawa  USA )",
    "country": "United States"
  },
  {
    "id": 272833,
    "name": "Polestar Labs Inc    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 184647,
    "name": "Polhemus    ( Colchester VT USA )",
    "country": "United States"
  },
  {
    "id": 458479,
    "name": "PolicyStat LLC    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 286158,
    "name": "Polirapid Germany    ( Singen  Germany )",
    "country": "Germany"
  },
  {
    "id": 277783,
    "name": "Politec Importao e Comercio Ltda    ( Barueri-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 185472,
    "name": "Poll Ltd Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 172307,
    "name": "Pollak International Ltd    ( Rosh HaAyin  Israel )",
    "country": "Israel"
  },
  {
    "id": 171742,
    "name": "Pollenex A Rival Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 365755,
    "name": "Polon-Poznan SA    ( Poznan  Poland )",
    "country": "Poland"
  },
  {
    "id": 416415,
    "name": "Poly Com S de RL de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 285648,
    "name": "Poly Diagnost GmbH    ( Pfaffenhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 186450,
    "name": "Poly Implant Prostheses    ( La Seyne-sur-Mer  France )",
    "country": "France"
  },
  {
    "id": 457526,
    "name": "Poly Medicure Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 279626,
    "name": "Poly-Optik GmbH    ( Bad Blankenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 169865,
    "name": "Poly-Pipets Inc    ( Englewood Cliffs NJ USA )",
    "country": "United States"
  },
  {
    "id": 454390,
    "name": "Poly-Version Inc    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 399296,
    "name": "Polycane Australia Pty Ltd    ( Ashmore  Australia )",
    "country": "Australia"
  },
  {
    "id": 345574,
    "name": "PolyChrome Medical    ( Brooklyn Center MN USA )",
    "country": "United States"
  },
  {
    "id": 416410,
    "name": "Polycom (France) Sarl    ( Paris La Defense Cedex  France )",
    "country": "France"
  },
  {
    "id": 408311,
    "name": "Polycom (Germany) GmbH    ( Hallbergmoos  Germany )",
    "country": "Germany"
  },
  {
    "id": 407022,
    "name": "Polycom (United Kingdom) Ltd    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 453374,
    "name": "Polycom Canada Ltd    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 408316,
    "name": "Polycom Global Pty Ltd    ( North Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 407021,
    "name": "Polycom Hong Kong Ltd    ( Wan Chai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 292392,
    "name": "Polycom Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 416416,
    "name": "Polycom Inc    ( Lima 27  Peru )",
    "country": "Peru"
  },
  {
    "id": 408320,
    "name": "Polycom Inc (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 408319,
    "name": "Polycom Inc (Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 416412,
    "name": "Polycom Inc Buenos Aires    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 416414,
    "name": "Polycom Inc Santiago    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 416413,
    "name": "Polycom Inc Sao Paulo    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 416411,
    "name": "Polycom Israel Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 408318,
    "name": "Polycom KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 408315,
    "name": "Polycom Solutions Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 467181,
    "name": "PolyConversions Inc    ( Rantoul IL USA )",
    "country": "United States"
  },
  {
    "id": 185986,
    "name": "Polyflon Co    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 285784,
    "name": "Polyform Kunststofftechnik GmbH & Co KG    ( Rinteln  Germany )",
    "country": "Germany"
  },
  {
    "id": 155370,
    "name": "Polygenex International Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 403726,
    "name": "Polymed Chirurgical Inc    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 170171,
    "name": "Polymed Medical Center    ( Glattbrugg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 362823,
    "name": "POLYMEDCO INCORPORTED    ( Cortlandt Manor NY USA )",
    "country": "United States"
  },
  {
    "id": 108291,
    "name": "Polymer Dynamics Inc    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 148493,
    "name": "Polymer Technology (USA) Corp    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 404197,
    "name": "Polymer Technology Systems Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 285748,
    "name": "Polymer-Synthese-Werk GmbH    ( Rheinberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 227344,
    "name": "Polymers Reconstructive A/S    ( Farum  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 233883,
    "name": "Polyoptimum Inc    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 293436,
    "name": "Polypol AG    ( Kilchberg/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456581,
    "name": "Polyscience    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 339661,
    "name": "Polysciences Europe GmbH    ( Eppelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 103614,
    "name": "Polysciences Inc    ( Warrington PA USA )",
    "country": "United States"
  },
  {
    "id": 408314,
    "name": "Polyspan bv    ( Hoofddorp  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 408312,
    "name": "Polyspan SARL    ( Levallois-Perret Cedex  France )",
    "country": "France"
  },
  {
    "id": 258186,
    "name": "Polytec PI Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 280812,
    "name": "POLYTECH-SILIMED Europe GmbH    ( Dieburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 461291,
    "name": "PolyVision Corp    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 418480,
    "name": "Pomona Electronics    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 457964,
    "name": "Pooyandegan Rah Saadat Co Ltd    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 175374,
    "name": "Pope Scientific Inc    ( Saukville WI USA )",
    "country": "United States"
  },
  {
    "id": 282029,
    "name": "Poppe GmbH & Co KG    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 101606,
    "name": "Popper & Sons Inc    ( New Hyde Park NY USA )",
    "country": "United States"
  },
  {
    "id": 161616,
    "name": "Porex Surgical Inc    ( Newnan GA USA )",
    "country": "United States"
  },
  {
    "id": 101607,
    "name": "Pori Assoc Inc    ( Pacifica CA USA )",
    "country": "United States"
  },
  {
    "id": 462862,
    "name": "Portable Medical Devices Inc    ( N Ft Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 156010,
    "name": "Portable Power System    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 377696,
    "name": "PortaFab Corp    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 462578,
    "name": "Portal Inc    ( Grafton WI USA )",
    "country": "United States"
  },
  {
    "id": 263814,
    "name": "Portanje Elektronika bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 105273,
    "name": "Porter Instrument Co Inc    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 358066,
    "name": "Porter Nash Medical    ( London  England )",
    "country": "UK"
  },
  {
    "id": 305735,
    "name": "Portland Square Pty Ltd    ( Kogarah  Australia )",
    "country": "Australia"
  },
  {
    "id": 101610,
    "name": "Porto-Lift Corp    ( Higgins Lake MI USA )",
    "country": "United States"
  },
  {
    "id": 170079,
    "name": "Pos-T-Vac Inc    ( Dodge City KS USA )",
    "country": "United States"
  },
  {
    "id": 101611,
    "name": "Posey Co    ( Arcadia CA USA )",
    "country": "United States"
  },
  {
    "id": 454810,
    "name": "PositiveID    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 106020,
    "name": "Positron Corp    ( Westmont IL USA )",
    "country": "United States"
  },
  {
    "id": 185006,
    "name": "Possis Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 454303,
    "name": "Possum Ltd    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 407272,
    "name": "Post Glover LifeLink Inc    ( Erlanger KY USA )",
    "country": "United States"
  },
  {
    "id": 149012,
    "name": "Post Medical Inc    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 452499,
    "name": "Post Surgical Mfg Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 458083,
    "name": "Post Surgical Rehab    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 102633,
    "name": "Postcraft Co    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 451767,
    "name": "Poulten and Graf Ltd    ( Barking  USA )",
    "country": "United States"
  },
  {
    "id": 464975,
    "name": "Powell's Service Inc    ( St Louis MI USA )",
    "country": "United States"
  },
  {
    "id": 301755,
    "name": "Power Electric Co Ltd    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 468426,
    "name": "Power Lift & Conner Athletics Products Inc    ( Jefferson IA USA )",
    "country": "United States"
  },
  {
    "id": 416618,
    "name": "Power Medical Interventions Inc    ( Langhorne PA USA )",
    "country": "United States"
  },
  {
    "id": 451619,
    "name": "Power Mobilizers    ( Cerritos CA USA )",
    "country": "United States"
  },
  {
    "id": 461391,
    "name": "Power Plate North America Inc    ( Carol Stream IL USA )",
    "country": "United States"
  },
  {
    "id": 999685,
    "name": "Power Plate North America, Inc.    ( Irvine CA USA )",
    "country": "USA"
  },
  {
    "id": 160142,
    "name": "Power Push Werner Bischoff GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 332138,
    "name": "Power Quality Inc    ( Yuma AZ USA )",
    "country": "United States"
  },
  {
    "id": 458468,
    "name": "Power Systems Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 175637,
    "name": "Power-Sonic Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 999456,
    "name": "Power-Sonic Corporation    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 146604,
    "name": "Powercom    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 457934,
    "name": "Powerex Inc    ( Harrison OH USA )",
    "country": "United States"
  },
  {
    "id": 186254,
    "name": "Powerline Battery Specialist Co    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 332141,
    "name": "PowerMed Corp    ( Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 461305,
    "name": "PowerTech Group Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 332142,
    "name": "Powertron    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 999676,
    "name": "Powertronix Corporation    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 362825,
    "name": "Powervar Ltd    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 170637,
    "name": "POWVAR    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 999716,
    "name": "PPM Technology Ltd.    ( LL55 2BD  USA )",
    "country": "United States"
  },
  {
    "id": 170638,
    "name": "PPX Imaging Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 461137,
    "name": "Practical Data Solutions    ( Southbury CT USA )",
    "country": "United States"
  },
  {
    "id": 461345,
    "name": "Practical Systems Inc    ( Odessa FL USA )",
    "country": "United States"
  },
  {
    "id": 462741,
    "name": "Practice Greenhealth    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 451602,
    "name": "Practice Partner    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 454365,
    "name": "PracticeHwy.com.Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 461414,
    "name": "PracticeLink Ltd    ( Hinton WV USA )",
    "country": "United States"
  },
  {
    "id": 462942,
    "name": "PracticeMatch Services LLC    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 419864,
    "name": "PracticeOne    ( Canoga Park CA USA )",
    "country": "United States"
  },
  {
    "id": 435501,
    "name": "PracticeWorks AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 435499,
    "name": "PracticeWorks Pty Ltd    ( Nerang  USA )",
    "country": "United States"
  },
  {
    "id": 392433,
    "name": "PracticeWorks Systems LLC A Carestream Health Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 435500,
    "name": "PracticeWorks UK    ( Stevenage  USA )",
    "country": "United States"
  },
  {
    "id": 450900,
    "name": "PracticeXpert Inc    ( Calabasas CA USA )",
    "country": "United States"
  },
  {
    "id": 451786,
    "name": "Practicon    ( Greenville NC USA )",
    "country": "United States"
  },
  {
    "id": 362115,
    "name": "Praecitronic Dresden GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 283613,
    "name": "Praemeta GmbH & Co KG    ( Troisdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 459182,
    "name": "Prairie Seating Corp    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 458592,
    "name": "Prairie Technologies Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 325208,
    "name": "Prairie View Industries Inc    ( Fairbury NE USA )",
    "country": "United States"
  },
  {
    "id": 185478,
    "name": "Prama Fabryka Maszyn Pralniczych    ( Kielce  Poland )",
    "country": "Poland"
  },
  {
    "id": 183918,
    "name": "Praxair Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 451884,
    "name": "PRAXIM medivision - Deutschland    ( Dusseldorf  USA )",
    "country": "United States"
  },
  {
    "id": 451882,
    "name": "PRAXIM medivision - France    ( La Tronche  France )",
    "country": "France"
  },
  {
    "id": 451885,
    "name": "PRAXIM medivision - Switzerland    ( Berne 25  USA )",
    "country": "United States"
  },
  {
    "id": 451883,
    "name": "PRAXIM medivision - USA    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 285144,
    "name": "Praxis Team GmbH    ( Oberndorf Hochmoessingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306276,
    "name": "Praxisdienst Dieckhoff & Ratschow GmbH & Co KG    ( Schweich  Germany )",
    "country": "Germany"
  },
  {
    "id": 283003,
    "name": "Praxocomp Computer- und Software-Vertrieb    ( Hilgertshausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160144,
    "name": "PRC Krochmann GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 452995,
    "name": "Preat Corp    ( Santa Ynez CA USA )",
    "country": "United States"
  },
  {
    "id": 410016,
    "name": "Precept Medical Products Inc    ( Arden NC USA )",
    "country": "United States"
  },
  {
    "id": 452427,
    "name": "PreCheck Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 392047,
    "name": "Precimed SA    ( Orvin  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 354244,
    "name": "Precisa Instruments AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 170639,
    "name": "Precise Biomedical Inc    ( Parma OH USA )",
    "country": "United States"
  },
  {
    "id": 466335,
    "name": "Precise Instrument Co    ( Hanson MA USA )",
    "country": "United States"
  },
  {
    "id": 101614,
    "name": "Precise Optics/PME Inc    ( Bay Shore NY USA )",
    "country": "United States"
  },
  {
    "id": 332544,
    "name": "Precise Power Corp    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 177083,
    "name": "Precision Air Products Co    ( Hopkins MN USA )",
    "country": "United States"
  },
  {
    "id": 105103,
    "name": "Precision BioLogic Inc    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 186256,
    "name": "Precision Biomedical Services Inc    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 450638,
    "name": "Precision Calibration Services (PCS)    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 178877,
    "name": "Precision Charts Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 101615,
    "name": "Precision Dynamics Corp    ( San Fernando CA USA )",
    "country": "United States"
  },
  {
    "id": 399263,
    "name": "Precision Electronic Products    ( Adelaide  USA )",
    "country": "United States"
  },
  {
    "id": 462536,
    "name": "Precision Endoscopic Technologies LLC    ( Sturbridge MA USA )",
    "country": "United States"
  },
  {
    "id": 351877,
    "name": "Precision Endoscopy of America Inc    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 459383,
    "name": "Precision Fitness Equipment    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 330567,
    "name": "Precision Instruments House    ( Ambala  India )",
    "country": "India"
  },
  {
    "id": 332144,
    "name": "Precision Laser Specialist    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 441046,
    "name": "Precision Lifts    ( Morristown TN USA )",
    "country": "United States"
  },
  {
    "id": 226284,
    "name": "Precision Machinery Systems Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 106490,
    "name": "Precision Medical Inc    ( Northampton PA USA )",
    "country": "United States"
  },
  {
    "id": 456107,
    "name": "Precision Medical Plastics Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 402708,
    "name": "Precision Micro Products of America Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 169370,
    "name": "Precision Optics Corp    ( Gardner MA USA )",
    "country": "United States"
  },
  {
    "id": 370963,
    "name": "Precision Software Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 471592,
    "name": "Precision Solutions Inc    ( Quakertown PA USA )",
    "country": "United States"
  },
  {
    "id": 183759,
    "name": "Precision Surgical Inc    ( Ambler PA USA )",
    "country": "United States"
  },
  {
    "id": 442219,
    "name": "Precision System Science Co Ltd    ( Matsudo  Japan )",
    "country": "Japan"
  },
  {
    "id": 442220,
    "name": "Precision System Science Europe GmbH    ( Worrstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 461207,
    "name": "Precision Systems and Instrumentation LLC    ( Fairfax Station VA USA )",
    "country": "United States"
  },
  {
    "id": 101934,
    "name": "Precision Systems Inc    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 174953,
    "name": "Precision Therapeutics Inc    ( Greenwood Village CO USA )",
    "country": "United States"
  },
  {
    "id": 320751,
    "name": "Precision Therapy Asia    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999978,
    "name": "PRECISION VISION    ( La Salle IL USA )",
    "country": "United States"
  },
  {
    "id": 459200,
    "name": "Precision X-Ray Inc    ( North Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 169285,
    "name": "Precoptic Co    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 361814,
    "name": "Precor Asia-Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 361813,
    "name": "Precor Europe    ( Neuried  Germany )",
    "country": "Germany"
  },
  {
    "id": 355171,
    "name": "Precor Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 361812,
    "name": "Precor Products Ltd-UK    ( Finchampstead  England )",
    "country": "UK"
  },
  {
    "id": 458610,
    "name": "Precyse Solutions LLC    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 461164,
    "name": "Preferred Medical Marketing Corp    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 179084,
    "name": "Preferred Products    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 161169,
    "name": "Prein & Partners    ( Lisses  USA )",
    "country": "United States"
  },
  {
    "id": 335895,
    "name": "Preiser Scientific Inc    ( St Albans WV USA )",
    "country": "United States"
  },
  {
    "id": 186681,
    "name": "Preissler Medizintechnik GmbH    ( Kaufbeuren  Germany )",
    "country": "Germany"
  },
  {
    "id": 452422,
    "name": "PreMD Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 177924,
    "name": "Premed A/S    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 452908,
    "name": "Premedical srl    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461212,
    "name": "PREMIER Biosoft International    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 105444,
    "name": "Premier Dental Products Co    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 458045,
    "name": "Premier Farnell UK Ltd    ( Leeds  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 186286,
    "name": "Premier Imaging Inc    ( Channelview TX USA )",
    "country": "United States"
  },
  {
    "id": 105239,
    "name": "Premier Medical Products Co    ( Plymouth Meeting PA USA )",
    "country": "United States"
  },
  {
    "id": 307420,
    "name": "Premier Technologies    ( Randburg  USA )",
    "country": "United States"
  },
  {
    "id": 999749,
    "name": "Premiere Brand    (   USA )",
    "country": "United States"
  },
  {
    "id": 151285,
    "name": "Premis Revalidatie en Verpleegartikelen    ( Woudenberg  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451822,
    "name": "Premise Corp    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 324363,
    "name": "Premise Ltd    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 460600,
    "name": "Premium Air Systems Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 465209,
    "name": "Premium Waters Inc    ( Minneapolis 21 USA )",
    "country": "United States"
  },
  {
    "id": 108892,
    "name": "Prentke-Romich Co    ( Wooster OH USA )",
    "country": "United States"
  },
  {
    "id": 103868,
    "name": "Presco Co Inc    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 255154,
    "name": "Presco Services    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 106066,
    "name": "Prescott's Inc    ( Monument CO USA )",
    "country": "United States"
  },
  {
    "id": 452616,
    "name": "Preservation Solutions Inc    ( Elkhorn WI USA )",
    "country": "United States"
  },
  {
    "id": 460666,
    "name": "Presidio Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 458853,
    "name": "Press Ganey Assoc Inc    ( South Bend IN USA )",
    "country": "United States"
  },
  {
    "id": 150016,
    "name": "Pressalit A/S    ( Ry  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 457312,
    "name": "Pressalit Care    ( Ry  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 460601,
    "name": "Presstek Inc    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 459183,
    "name": "Pressure BioSciences Inc    ( South Easton MA USA )",
    "country": "United States"
  },
  {
    "id": 454746,
    "name": "Pressure Products    ( San Pedro CA USA )",
    "country": "United States"
  },
  {
    "id": 461224,
    "name": "Prestan Products    ( Mayfield Village OH USA )",
    "country": "United States"
  },
  {
    "id": 458086,
    "name": "Prestige Ameritech    ( North Richland Hills TX USA )",
    "country": "United States"
  },
  {
    "id": 108256,
    "name": "Prestige Medical Corp    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 151098,
    "name": "Prestige Medical Ltd    ( Blackburn  England )",
    "country": "UK"
  },
  {
    "id": 392110,
    "name": "Prevent Products Inc    ( Mendota Heights MN USA )",
    "country": "United States"
  },
  {
    "id": 454860,
    "name": "PreXion Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 459184,
    "name": "Prezio Health    ( Madison Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 458883,
    "name": "PricewaterhouseCoopers LLP    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 285488,
    "name": "Prico GmbH    ( Radevormwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 358811,
    "name": "Pride Mobility Products Corp    ( Exeter PA USA )",
    "country": "United States"
  },
  {
    "id": 283892,
    "name": "Prigan Physiotherapeutische Geraete Herstellung    ( Leipzig  Germany )",
    "country": "Germany"
  },
  {
    "id": 153719,
    "name": "Prim SA    ( Mostoles (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 324219,
    "name": "Prima Medical Systems Ltd    ( Stroud  England )",
    "country": "UK"
  },
  {
    "id": 364498,
    "name": "PrimaGard    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 461406,
    "name": "Primaris    ( Columbia MO USA )",
    "country": "United States"
  },
  {
    "id": 467260,
    "name": "Primary Care Diagnostics LLC    ( McKenzie TN USA )",
    "country": "United States"
  },
  {
    "id": 108071,
    "name": "Primary Medical Products Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 454604,
    "name": "Prime Clinical Systems Inc    ( Pasadena CA USA )",
    "country": "United States"
  },
  {
    "id": 108809,
    "name": "Prime Engineering Inc    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 456123,
    "name": "Prime Medical Inc    ( Hwaseong  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456070,
    "name": "Prime Star Instruments    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 282281,
    "name": "Primed Halberstadt Medizintechnik GmbH    ( Halberstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 452063,
    "name": "priMED Medical Products Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 338619,
    "name": "PrimeLine Medical Products Inc    ( Edmonton AB USA )",
    "country": "United States"
  },
  {
    "id": 272305,
    "name": "PrimeSource Surgical and Healthcare    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 226333,
    "name": "Primetech Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 459138,
    "name": "Primex Wireless    ( Lake Geneva WI USA )",
    "country": "United States"
  },
  {
    "id": 458513,
    "name": "Primordial Design Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 108527,
    "name": "PRIMUS Corp    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 285833,
    "name": "Primus Gold Edelmetall Vertrieb GmbH    ( Rosenheim  USA )",
    "country": "United States"
  },
  {
    "id": 457471,
    "name": "Primus Medical LLC    ( Boardman OH USA )",
    "country": "United States"
  },
  {
    "id": 153891,
    "name": "Primus Sterilizer Co Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 321490,
    "name": "Princeton BioMeditech Corp    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 202210,
    "name": "Princeton Instruments Inc    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 150893,
    "name": "Princeton Technology Corp    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 461231,
    "name": "Printek Inc    ( Benton Harbor MI USA )",
    "country": "United States"
  },
  {
    "id": 999948,
    "name": "PRINTREX    (   USA )",
    "country": "United States"
  },
  {
    "id": 332033,
    "name": "Priocal AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453936,
    "name": "Prior Scientific GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 184484,
    "name": "Prior Scientific Inc    ( Rockland MA USA )",
    "country": "United States"
  },
  {
    "id": 183375,
    "name": "Prior Scientific Instruments Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 192831,
    "name": "PriorClave Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 325215,
    "name": "Priorities Inc    ( Wellesley MA USA )",
    "country": "United States"
  },
  {
    "id": 461062,
    "name": "Priority Consult LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 466497,
    "name": "Priority Medical Inc    ( Greenbrier TN USA )",
    "country": "United States"
  },
  {
    "id": 460604,
    "name": "Prism Clinical Imaging    ( Elm Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 454111,
    "name": "Prism Medical Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454762,
    "name": "Prism Technologies Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291949,
    "name": "Priva Inc    ( Champlain NY USA )",
    "country": "United States"
  },
  {
    "id": 459355,
    "name": "Privatel Inc    ( Spring Lake Heights NJ USA )",
    "country": "United States"
  },
  {
    "id": 452287,
    "name": "Privates Institut fur Immunologie und Molekulargen    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 267116,
    "name": "Prizm Medical Inc    ( Oakwood GA USA )",
    "country": "United States"
  },
  {
    "id": 452879,
    "name": "PRN Vascular    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 455140,
    "name": "Pro Med Instruments GmbH    ( Freiburg im Breisgau  Germany )",
    "country": "Germany"
  },
  {
    "id": 455141,
    "name": "Pro Med Instruments Inc    ( Cape Coral FL USA )",
    "country": "United States"
  },
  {
    "id": 455471,
    "name": "Pro Medicus Ltd    ( Richmond  Australia )",
    "country": "Australia"
  },
  {
    "id": 108257,
    "name": "Pro Orthopedic Devices Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 174585,
    "name": "PRO Scientific Inc    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 455656,
    "name": "Pro Scope Systems LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 456334,
    "name": "Pro Trainer's Choice    ( Kingston WA USA )",
    "country": "United States"
  },
  {
    "id": 431520,
    "name": "Pro-Am Safety Inc    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 325216,
    "name": "Pro-Bed Medical Technologies Inc    ( Abbotsford BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452670,
    "name": "Pro-Care Ltd    ( Selby  England )",
    "country": "UK"
  },
  {
    "id": 186269,
    "name": "Pro-Comm Inc    ( Brick NJ USA )",
    "country": "United States"
  },
  {
    "id": 151454,
    "name": "Pro-Custom srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 362930,
    "name": "Pro-Dex Micro Motors    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 152556,
    "name": "Pro-Lab Diagnostics    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 454138,
    "name": "Pro-Lab Inc    ( Richmond Hill ON Canada )",
    "country": "Canada"
  },
  {
    "id": 306094,
    "name": "Pro-Med Australia Pty Ltd    ( Cheltenham  USA )",
    "country": "United States"
  },
  {
    "id": 286827,
    "name": "PRO-MED Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459174,
    "name": "Pro-Med Products Inc    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 283762,
    "name": "Pro-Med Systems GmbH    ( Landausheim  USA )",
    "country": "United States"
  },
  {
    "id": 459149,
    "name": "Pro-Medical Innovations Inc    ( Villa Park IL USA )",
    "country": "United States"
  },
  {
    "id": 107446,
    "name": "Pro-Optics Inc    ( Palatine IL USA )",
    "country": "United States"
  },
  {
    "id": 358435,
    "name": "Pro-Tech Services Inc    ( Mukilteo WA USA )",
    "country": "United States"
  },
  {
    "id": 170211,
    "name": "Pro-Western Plastics Ltd    ( St Albert AB Canada )",
    "country": "Canada"
  },
  {
    "id": 461237,
    "name": "Pro2Med Inc    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 453025,
    "name": "PROACT Medical Ltd    ( Corby  England )",
    "country": "UK"
  },
  {
    "id": 280893,
    "name": "Proactiv Reha-Technik GmbH    ( Dotternhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451319,
    "name": "Problem Solving Concepts    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 285516,
    "name": "Probst & Class GmbH & Co KG    ( Rastatt  Germany )",
    "country": "Germany"
  },
  {
    "id": 461243,
    "name": "ProCare Water Treatment Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 282723,
    "name": "Procedia GmbH    ( Hannover  Germany )",
    "country": "Germany"
  },
  {
    "id": 279465,
    "name": "Procedo-Stocker GmbH    ( Allershausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 173882,
    "name": "Procedure Products    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 305967,
    "name": "Process Construction    ( Garsnas  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 466316,
    "name": "Process Control Solutions    ( Shrewsbury MA USA )",
    "country": "United States"
  },
  {
    "id": 461310,
    "name": "Process Software    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 163003,
    "name": "Procom Swiss Israel Ltd    ( Barkan  Israel )",
    "country": "Israel"
  },
  {
    "id": 461404,
    "name": "PROCON Products    ( Smyma TN USA )",
    "country": "United States"
  },
  {
    "id": 183891,
    "name": "Procter & Gamble Health Care    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 287242,
    "name": "Procter & Gamble Pharmaceuticals Germany GmbH    ( Weiterstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284825,
    "name": "Prodene Wilden GmbH    ( Roedermark/Ober-Roden  Germany )",
    "country": "Germany"
  },
  {
    "id": 139402,
    "name": "Prodimed    ( Neuilly-en-Thelle  France )",
    "country": "France"
  },
  {
    "id": 455621,
    "name": "Prodol Meditec SA    ( Guecho (Vizcaya)  Spain )",
    "country": "Spain"
  },
  {
    "id": 293360,
    "name": "Prodonta SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 345941,
    "name": "Productos Bard de Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 401583,
    "name": "Productos Roche Interamericana SA (Prisa)    ( Guatemala City  Guatemala )",
    "country": "Guatemala"
  },
  {
    "id": 401584,
    "name": "Productos Roche Ltda (Chile)    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 401605,
    "name": "Productos Roche QFSA    ( Lima  Peru )",
    "country": "Peru"
  },
  {
    "id": 401586,
    "name": "Productos Roche SA (Colombia)    ( Bogota  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 401600,
    "name": "Productos Roche SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 452615,
    "name": "Products for Medicine    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 332155,
    "name": "Products Group International Inc    ( Lyons CO USA )",
    "country": "United States"
  },
  {
    "id": 102637,
    "name": "Products International Co    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 466260,
    "name": "Products Unlimited Inc    ( Justin TX USA )",
    "country": "United States"
  },
  {
    "id": 170910,
    "name": "Produits Dentaires SA    ( Vevey 1  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458771,
    "name": "ProDuplicator.com    ( Diamond Bar CA USA )",
    "country": "United States"
  },
  {
    "id": 401582,
    "name": "Produtos Roche Quimicos e Farmaceuticos SA    ( Sao Paulo SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 461249,
    "name": "Professional Advanced Compression Therapy (ProAct)    ( Williamstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 103327,
    "name": "Professional Disposables International    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 384283,
    "name": "Professional Disposables International Europe    ( Flint  USA )",
    "country": "United States"
  },
  {
    "id": 456071,
    "name": "Professional Hospital Furnishers    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 378102,
    "name": "Professional Hospital Supply Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 453739,
    "name": "Professional Industries Ltd    ( Massapequa NY USA )",
    "country": "United States"
  },
  {
    "id": 999596,
    "name": "Professional Medical Imports    ( Marlboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 451495,
    "name": "Professional Medical Inc    ( New Lenox IL USA )",
    "country": "United States"
  },
  {
    "id": 103328,
    "name": "Professional Products Co    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 370443,
    "name": "Professional Products Inc    ( DeFuniak Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 102305,
    "name": "Profex Medical Products    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 459201,
    "name": "Proficient Surgical    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 416100,
    "name": "Profile Respiratory Systems Ltd    ( Bognor Regis  England )",
    "country": "UK"
  },
  {
    "id": 286358,
    "name": "Profimed Dentalvertriebs- und Prophylaxe GmbH    ( Stephanskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999824,
    "name": "Proform    (   USA )",
    "country": "United States"
  },
  {
    "id": 457535,
    "name": "PROFOX Assoc Inc    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 452591,
    "name": "Progen Biotechnik GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452592,
    "name": "Progen Biotechnique Sarl    ( Montigny le Bretonneux  France )",
    "country": "France"
  },
  {
    "id": 332157,
    "name": "Progeny Inc (A Midmark Co)    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 272310,
    "name": "Progetti srl    ( Trofarello (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454639,
    "name": "Prognosis Health Information Systems    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 334006,
    "name": "Programming Resources Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 391435,
    "name": "Progressive Dynamics Medical Inc    ( Marshall MI USA )",
    "country": "United States"
  },
  {
    "id": 178719,
    "name": "Progressive Medical Corp    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 455465,
    "name": "Progressive Medical Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 186276,
    "name": "Progressive Medical International    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 332158,
    "name": "Progressive Microtechnology Inc    ( St Augustine FL USA )",
    "country": "United States"
  },
  {
    "id": 282537,
    "name": "Proimex GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 414126,
    "name": "PROINSA    ( Navajoa  USA )",
    "country": "United States"
  },
  {
    "id": 103870,
    "name": "Project Hear    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 462932,
    "name": "Projector Discount Store    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 461258,
    "name": "Prolancer Pty Ltd    ( Kingsgrove  Australia )",
    "country": "Australia"
  },
  {
    "id": 473833,
    "name": "Prologics LLC    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 155093,
    "name": "Promar Industries Inc    ( Bridgeport CT USA )",
    "country": "United States"
  },
  {
    "id": 999337,
    "name": "Promate Electronic Co.    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 108590,
    "name": "Promatek Industries Ltd    ( Montreal PQ USA )",
    "country": "United States"
  },
  {
    "id": 452227,
    "name": "Promax/Robinair/TIF    ( Owatonna MN USA )",
    "country": "United States"
  },
  {
    "id": 999715,
    "name": "ProMaxima    (   USA )",
    "country": "United States"
  },
  {
    "id": 452979,
    "name": "Promed Ltd    ( Somersham  England )",
    "country": "UK"
  },
  {
    "id": 457158,
    "name": "Promedica Inc    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 284696,
    "name": "Promedico Computer fuer die Medizin GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284290,
    "name": "Promedisoft Software Systeme fuer das    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457286,
    "name": "Promedon    ( Cordoba  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 118174,
    "name": "Promega Corp    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 460520,
    "name": "Promethean LifeSciences Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 473824,
    "name": "Prometheus Laboratories Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 178038,
    "name": "Promex    ( Sandton  USA )",
    "country": "United States"
  },
  {
    "id": 287314,
    "name": "Promochem GmbH    ( Wesel  Germany )",
    "country": "Germany"
  },
  {
    "id": 288724,
    "name": "ProModel Corp    ( Orem UT USA )",
    "country": "United States"
  },
  {
    "id": 152101,
    "name": "Promotal    ( Ernee  France )",
    "country": "France"
  },
  {
    "id": 151922,
    "name": "Pronefro-Produtos Nefrologicos SA    ( Maia  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 451211,
    "name": "Pronk Technologies Inc    ( Sun Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 370965,
    "name": "Pronosco A/S    ( Vedbaek  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 461296,
    "name": "Pronto Repairs Inc    ( Tappan NY USA )",
    "country": "United States"
  },
  {
    "id": 465050,
    "name": "Propeople    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 467272,
    "name": "ProPep Surgical    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 999483,
    "name": "ProPep Surgical    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 455248,
    "name": "ProPhotonix Inc    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 460709,
    "name": "ProPhysics Innovations Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 101621,
    "name": "Propper Mfg Co Inc    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 451784,
    "name": "ProRhythm Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 375022,
    "name": "PROSEC Protection Systems Inc    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 452912,
    "name": "ProSolv CardioVascular    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 456122,
    "name": "Prosonic Co Ltd    ( Gyeongju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 102910,
    "name": "Prospect Park Press    ( West Chesterfield NH USA )",
    "country": "United States"
  },
  {
    "id": 999910,
    "name": "ProstaLund Operations AB    ( Sweden  USA )",
    "country": "United States"
  },
  {
    "id": 452130,
    "name": "Prosthetic Laboratories of Rochester    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 397728,
    "name": "Prosurg Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 453583,
    "name": "Prosurgics Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 453581,
    "name": "Prosurgics Ltd    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 453582,
    "name": "Prosurgics Ltd Germany    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 457419,
    "name": "Protac A/S    ( Aarhus C  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 439577,
    "name": "PROTEC Asia Co Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 160145,
    "name": "PROTEC Medizintechnik GmbH & Co KG    ( Oberstenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 453875,
    "name": "PROTEC Shanghai Office    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458819,
    "name": "Protech International Inc    ( Boerne TX USA )",
    "country": "United States"
  },
  {
    "id": 452013,
    "name": "PROTECH Leaded Eyewear Inc    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 456582,
    "name": "ProTech Professional Products Inc    ( Boynton Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 184212,
    "name": "Protectair Inc    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 459263,
    "name": "Protein Technologies Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 456515,
    "name": "ProteinSimple    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 409110,
    "name": "Protek T & M    ( Englewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 282819,
    "name": "Proteor Handicap Technologie    ( Saint-Appollinaire  France )",
    "country": "France"
  },
  {
    "id": 230483,
    "name": "Proteus Hyperbaric Systems Inc    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 170911,
    "name": "Protex Dental AG    ( Berne 17  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462554,
    "name": "Protex Ltd    ( Paola  Malta )",
    "country": "Malta"
  },
  {
    "id": 475974,
    "name": "Protexer Corp    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 461264,
    "name": "Protochips Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 106680,
    "name": "Protocol Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459025,
    "name": "ProtoKinetics    ( Havertown PA USA )",
    "country": "United States"
  },
  {
    "id": 457977,
    "name": "ProTom International Inc    ( Flower Mound TX USA )",
    "country": "United States"
  },
  {
    "id": 999732,
    "name": "PROTON Corp.    ( Rowland Height CA USA )",
    "country": "USA"
  },
  {
    "id": 457095,
    "name": "Proton Services Inc    ( Sayreville NJ USA )",
    "country": "United States"
  },
  {
    "id": 451197,
    "name": "Provalis Diagnostics (USA) Ltd    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 403650,
    "name": "Provalis Diagnostics Ltd    ( Deeside  England )",
    "country": "UK"
  },
  {
    "id": 451198,
    "name": "Provalis Diagnostics Ltd    ( Flintshire  USA )",
    "country": "United States"
  },
  {
    "id": 451350,
    "name": "ProVation Medical Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 457558,
    "name": "Providence Global Medical    ( Holladay UT USA )",
    "country": "United States"
  },
  {
    "id": 999679,
    "name": "Providence Global Medical    ( Holladay UT USA )",
    "country": "United States"
  },
  {
    "id": 431319,
    "name": "Providence Health Care and BC Ear Bank    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 459505,
    "name": "Provider Consulting Solutions Inc    ( Clifton Park NY USA )",
    "country": "United States"
  },
  {
    "id": 417553,
    "name": "Provider Pharmacy Services    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 392783,
    "name": "Provis SA    ( La Ricamarie  France )",
    "country": "France"
  },
  {
    "id": 160146,
    "name": "Provita GmbH Medizintechnik    ( Wermelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451758,
    "name": "Provox Technologies    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 392724,
    "name": "Prowess Inc    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 455552,
    "name": "ProXeon    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 465204,
    "name": "Proximity Systems    ( Tomball 11 USA )",
    "country": "United States"
  },
  {
    "id": 340115,
    "name": "Proxtronics Inc    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 452776,
    "name": "Proxy Biomedical Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 333954,
    "name": "Proxymed Inc    ( Plantation FL USA )",
    "country": "United States"
  },
  {
    "id": 285316,
    "name": "Pruefbau Dr -Ing H Duerner GmbH    ( Peissenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 280152,
    "name": "Pruefer & Co Reha-Technik    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 160147,
    "name": "Pruefgeraete-Werk Medingen GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 467145,
    "name": "Pruitt Communications Inc    ( Dothan AL USA )",
    "country": "United States"
  },
  {
    "id": 418133,
    "name": "Pryamed Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 382740,
    "name": "Prym Medical Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 152742,
    "name": "Pryon Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 101622,
    "name": "Pryor Products    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 186798,
    "name": "PS Analytical Ltd    ( Orpington  England )",
    "country": "UK"
  },
  {
    "id": 451763,
    "name": "PSC Inc    ( Eugene OR USA )",
    "country": "United States"
  },
  {
    "id": 160148,
    "name": "PSI Gruenewald GmbH & Co KG    ( Laudenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 453608,
    "name": "Psi Health Solutions    ( Pacific Grove CA USA )",
    "country": "United States"
  },
  {
    "id": 465124,
    "name": "PSI LLC    ( Minneapolis 23 USA )",
    "country": "United States"
  },
  {
    "id": 473844,
    "name": "PSI-TRONIX.COM    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 105392,
    "name": "Psoralite Corp    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 274334,
    "name": "PSS (Physician Sales & Service)    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 442221,
    "name": "PSS Bio Instruments Inc    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 186281,
    "name": "PSS Imaging Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 363407,
    "name": "PSS/World Medical Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 271569,
    "name": "Psyche Systems Corp    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 462863,
    "name": "Psychological Assessment Resources (PAR) Inc    ( Lutz FL USA )",
    "country": "United States"
  },
  {
    "id": 187572,
    "name": "PT Bersaudara    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 329864,
    "name": "PT Dian Graha Elektrika    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 306015,
    "name": "PT Enseval Putera Megatrading    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 354748,
    "name": "PT Lestari Dini Tunggul    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 457489,
    "name": "PT Linde Indonesia    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 401595,
    "name": "PT Roche Indonesia    ( Jakarta  Indonesia )",
    "country": "Indonesia"
  },
  {
    "id": 284350,
    "name": "PTA Pharma-Techn -Apparatebau GmbH    ( Mauern  Germany )",
    "country": "Germany"
  },
  {
    "id": 237969,
    "name": "PTC Electronics    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 341420,
    "name": "PTI Inc (UK)    ( Ford  England )",
    "country": "UK"
  },
  {
    "id": 467270,
    "name": "PTS Diagnostics    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 451959,
    "name": "PTW-Asia Pacific Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453824,
    "name": "PTW-Beijing Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416566,
    "name": "PTW-France    ( La Ville-du-Bois  France )",
    "country": "France"
  },
  {
    "id": 160149,
    "name": "PTW-Freiburg GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451960,
    "name": "PTW-Latin America    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 266973,
    "name": "PTW-New York    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 453825,
    "name": "PTW-UK Ltd    ( Grantham  England )",
    "country": "UK"
  },
  {
    "id": 243134,
    "name": "Puget Sound Blood Center    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 292779,
    "name": "Puget Sound Surgical Inc    ( Tacoma WA USA )",
    "country": "United States"
  },
  {
    "id": 281772,
    "name": "Pulch & Lorenz GmbH    ( March/Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 104678,
    "name": "Pulizzi Engineering Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 108644,
    "name": "Pulmonary Data Service Inc    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 104938,
    "name": "Pulmonetic Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 357517,
    "name": "Pulmonox Medical Inc    ( Tofield AB USA )",
    "country": "United States"
  },
  {
    "id": 293729,
    "name": "Pulpadent Dental Products    ( Zollikon/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 101624,
    "name": "Pulpdent Corp    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 466285,
    "name": "Pulsar Vascular    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 333735,
    "name": "Pulse Biomedical Inc    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 146668,
    "name": "Pulse Medical Inc    ( Davie FL USA )",
    "country": "United States"
  },
  {
    "id": 177094,
    "name": "Pulse Metric Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 457188,
    "name": "Pulse Scientific Inc    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 394757,
    "name": "Pulse Systems Inc    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 455405,
    "name": "PulseCare Medical LLC    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 455948,
    "name": "Pulsecath bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453869,
    "name": "PULSION Medical Inc    ( Powell OH USA )",
    "country": "United States"
  },
  {
    "id": 183909,
    "name": "PULSION Medical Systems GmbH & Co KG    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 416539,
    "name": "pulson.med    ( Hundsangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457668,
    "name": "Pulsonic Medical Technology    ( Schlieren/Zuric  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 102733,
    "name": "Pure Aire Corp    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 457136,
    "name": "Pure Aqua Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 461034,
    "name": "Pure Process Technology    ( Nashau NH USA )",
    "country": "United States"
  },
  {
    "id": 459029,
    "name": "Pure Processing LLC    ( Wheaton IL USA )",
    "country": "United States"
  },
  {
    "id": 457137,
    "name": "Pure Solutions Water Treatment Co    ( Mansfield TX USA )",
    "country": "United States"
  },
  {
    "id": 457358,
    "name": "Pure Spa Direct    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 348534,
    "name": "Pure Water Solutions    ( Ridgeland MS USA )",
    "country": "United States"
  },
  {
    "id": 457134,
    "name": "Pure Water Systems Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 426358,
    "name": "Pure-Logic Industries    ( Gilbert AZ USA )",
    "country": "United States"
  },
  {
    "id": 459271,
    "name": "Pureflow Inc    ( Graham NC USA )",
    "country": "United States"
  },
  {
    "id": 175370,
    "name": "PurFybr Inc    ( Munster IN USA )",
    "country": "United States"
  },
  {
    "id": 451640,
    "name": "PuriCore    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 451641,
    "name": "PuriCore/Sterilox Endoscopy UK    ( Beaconside  England )",
    "country": "UK"
  },
  {
    "id": 392812,
    "name": "Purified microEnvironments    ( Ormond Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 101913,
    "name": "Puritan Bennett Corp    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 440810,
    "name": "Puritan Medical Products Co LLC    ( Guilford ME USA )",
    "country": "United States"
  },
  {
    "id": 466693,
    "name": "Purite Ltd    ( Thame  England )",
    "country": "UK"
  },
  {
    "id": 329241,
    "name": "Purkinje Canada    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 329233,
    "name": "Purkinje Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 283467,
    "name": "PVB Medizintechnik GmbH    ( Kirchseeon  USA )",
    "country": "United States"
  },
  {
    "id": 452549,
    "name": "PVG International    ( Ambala Cantt  India )",
    "country": "India"
  },
  {
    "id": 441038,
    "name": "PVT LabSystems LLC    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 168581,
    "name": "Reeves EMS LLC    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 441037,
    "name": "PVT Probenverteiltechnik GmbH    ( Waiblingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999508,
    "name": "Pyle Audio Inc.    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 410022,
    "name": "Pyng Medical Corp    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454296,
    "name": "PyraMED Health Systems    ( Berwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 452953,
    "name": "Pyramed Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 460617,
    "name": "Pyramid Analytics    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 170646,
    "name": "Pyramid Medical Inc    ( Los Alamitos CA USA )",
    "country": "United States"
  },
  {
    "id": 458157,
    "name": "Pyramid Time Systems    ( Meriden CT USA )",
    "country": "United States"
  },
  {
    "id": 999585,
    "name": "Pyromation, Inc.    ( Fort Wayne IN USA )",
    "country": "USA"
  },
  {
    "id": 170928,
    "name": "Pyxis Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 185482,
    "name": "PZL Swidnik SA    ( Swidnik  Poland )",
    "country": "Poland"
  },
  {
    "id": 185484,
    "name": "PZSO Poznanskie Zaklady Sprzetu Ortopedycznego    ( Poznan  Poland )",
    "country": "Poland"
  },
  {
    "id": 460619,
    "name": "Q Solutions Inc    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 451804,
    "name": "Q-Core Ltd    ( Ramat Gan  USA )",
    "country": "United States"
  },
  {
    "id": 451925,
    "name": "Q-Core Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 460675,
    "name": "Q-Matic Corp    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 439661,
    "name": "Q-Med Corp Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 377838,
    "name": "Q-Vis Ltd    ( Nedlands  Australia )",
    "country": "Australia"
  },
  {
    "id": 155427,
    "name": "Q'Straint    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 373448,
    "name": "Q'Straint Australia    ( Rocklea  Australia )",
    "country": "Australia"
  },
  {
    "id": 373294,
    "name": "Q'Straint Canada    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 373295,
    "name": "Q'Straint Europe    ( Whitstable  England )",
    "country": "UK"
  },
  {
    "id": 999529,
    "name": "Q3 Innovations    ( Independence IA USA )",
    "country": "USA"
  },
  {
    "id": 455111,
    "name": "QA Supplies LLC    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 324722,
    "name": "Qados Ltd    ( Sandhurst  England )",
    "country": "UK"
  },
  {
    "id": 456930,
    "name": "QAL Medical LLC    ( Marinette WI USA )",
    "country": "United States"
  },
  {
    "id": 456073,
    "name": "Qayum & Elahisons (Ptv) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 452764,
    "name": "QBC Diagnostics Inc    ( Port Matilda PA USA )",
    "country": "United States"
  },
  {
    "id": 459459,
    "name": "QC Storage LLC    ( Jennings LA USA )",
    "country": "United States"
  },
  {
    "id": 400964,
    "name": "QC Technology Inc    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 391184,
    "name": "QCare International LLC    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 170647,
    "name": "QDR (Quality Dental Repair)    ( Freehold NJ USA )",
    "country": "United States"
  },
  {
    "id": 399182,
    "name": "QED (Quality Enabling Devices Ltd)    ( Gosport  England )",
    "country": "UK"
  },
  {
    "id": 458126,
    "name": "QED Medical    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 320674,
    "name": "QFC Industries Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 459147,
    "name": "Qfix    ( Avondale PA USA )",
    "country": "United States"
  },
  {
    "id": 458881,
    "name": "QGenda Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 460530,
    "name": "QHR Technologies Inc    ( Kelowna BC Canada )",
    "country": "Canada"
  },
  {
    "id": 408327,
    "name": "QI Medical Inc    ( Nevada City CA USA )",
    "country": "United States"
  },
  {
    "id": 409654,
    "name": "QIAGEN AG    ( Hombrechtikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 377441,
    "name": "QIAGEN GmbH    ( Hilden  Germany )",
    "country": "Germany"
  },
  {
    "id": 377442,
    "name": "QIAGEN Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 409641,
    "name": "QIAGEN Inc (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 409645,
    "name": "QIAGEN KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 409640,
    "name": "QIAGEN Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 377440,
    "name": "QIAGEN NV    ( Venlo  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 409652,
    "name": "QIAGEN Pty Ltd    ( Doncaster  Australia )",
    "country": "Australia"
  },
  {
    "id": 409642,
    "name": "QIAGEN SA    ( Courtaboeuf Cedex  France )",
    "country": "France"
  },
  {
    "id": 409653,
    "name": "QIAGEN SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 232377,
    "name": "Qiao-Wei Medical Instruments Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458430,
    "name": "QImaging    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 455115,
    "name": "Qindao Electric Appliance Co Ltd    ( Jimo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455822,
    "name": "Qingdao Double Butterfly Group Co Ltd    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291627,
    "name": "Qingdao Hyperbaric Oxygen Cabin Factory    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291634,
    "name": "Qingdao Latex Co    ( Qingdao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291639,
    "name": "Qingdao Pingdu High-Pressure Oxygen Cabin Equipmen    ( Pingdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451580,
    "name": "Qioptiq Imaging Solutions    ( Fairport NY USA )",
    "country": "United States"
  },
  {
    "id": 456847,
    "name": "Qioptiq Photonics GmbH & Co KG    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459507,
    "name": "Qlicksmart Pty Ltd    ( Brisbane  Australia )",
    "country": "Australia"
  },
  {
    "id": 333312,
    "name": "QLT Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 451354,
    "name": "QLT USA Inc    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 460674,
    "name": "Qmatic Group    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457732,
    "name": "Qmatic United States    ( Duluth MN USA )",
    "country": "United States"
  },
  {
    "id": 104829,
    "name": "QMed Inc/Interactive Heart Management Corp    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 175371,
    "name": "Qorpak    ( Bridgeville PA USA )",
    "country": "United States"
  },
  {
    "id": 322334,
    "name": "QR srl    ( Verona (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 358809,
    "name": "QRS Diagnostic LLC    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 455215,
    "name": "QS Supplies Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 398944,
    "name": "QS/1 Canada    ( Halifax NS USA )",
    "country": "United States"
  },
  {
    "id": 348688,
    "name": "QS/1 Data Systems Inc    ( Spartanburg SC USA )",
    "country": "United States"
  },
  {
    "id": 460621,
    "name": "QSA Global Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 457743,
    "name": "Qsonica LLC    ( Newtown CT USA )",
    "country": "United States"
  },
  {
    "id": 233897,
    "name": "Quadax Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 274378,
    "name": "Quadmed Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 377688,
    "name": "QuadraMed Corp    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 456443,
    "name": "Quadratech Ltd    ( Epsom  England )",
    "country": "UK"
  },
  {
    "id": 332163,
    "name": "QuadTech Inc    ( Maynard MA USA )",
    "country": "United States"
  },
  {
    "id": 460712,
    "name": "Quail Digital North America    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 999947,
    "name": "QUALA    (   USA )",
    "country": "United States"
  },
  {
    "id": 391359,
    "name": "Qualigen Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 456173,
    "name": "Qualimed Mfg Corp    ( Xiantao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452610,
    "name": "Qualiteam srl    ( Chiaverano (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 450849,
    "name": "Qualitest Pharmaceuticals Inc    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 103650,
    "name": "Qualitone    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 133025,
    "name": "Qualitron Inc    ( Holland OH USA )",
    "country": "United States"
  },
  {
    "id": 454842,
    "name": "Quality Electrodynamics    ( Mayfair Village OH USA )",
    "country": "United States"
  },
  {
    "id": 458270,
    "name": "Quality Medical South    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 146673,
    "name": "Quality Monitor Systems Inc    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 174963,
    "name": "Quality Systems Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 460624,
    "name": "QualityMetric Inc    ( Lincoln RI USA )",
    "country": "United States"
  },
  {
    "id": 460703,
    "name": "Qualstar Corp    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 460704,
    "name": "Qualys Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 459255,
    "name": "Quansys Biosciences    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 455142,
    "name": "Quanta System SpA    ( Solbiate Olona (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452614,
    "name": "Quanta Technologies LLC    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 457172,
    "name": "Quantel Derma GmbH    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 299823,
    "name": "Quantel Group    ( Les Ulis Cedex  France )",
    "country": "France"
  },
  {
    "id": 450814,
    "name": "Quantel Medical (Asia/Pacific)    ( Chiangmai  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 450813,
    "name": "Quantel Medical Inc    ( Bozeman MT USA )",
    "country": "United States"
  },
  {
    "id": 383544,
    "name": "Quantel Medical SA    ( Cournon d'Auvergne  France )",
    "country": "France"
  },
  {
    "id": 999325,
    "name": "QUANTIFOIL Instruments GmbH    ( Jena  Germany )",
    "country": "Germany"
  },
  {
    "id": 466326,
    "name": "QuantiMed Solutions    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 155104,
    "name": "Quantimetrix Corp    ( Redondo Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 106855,
    "name": "Quantronix Corp    ( East Setauket NY USA )",
    "country": "United States"
  },
  {
    "id": 458967,
    "name": "Quantros    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 340117,
    "name": "Quantum Data Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 999723,
    "name": "Quantum Fitness    (   USA )",
    "country": "United States"
  },
  {
    "id": 458453,
    "name": "Quantum Fitness Corp    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 460610,
    "name": "Quantum Medical    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 384274,
    "name": "Quantum Medical Imaging LLC    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 467395,
    "name": "Quantum Northwest Inc    ( Liberty Lake WA USA )",
    "country": "United States"
  },
  {
    "id": 332166,
    "name": "Quantum Products    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 460607,
    "name": "Quantum Storage Systems    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 454981,
    "name": "Quantum Technology Corp    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454982,
    "name": "Quantum Technology Corp (United States)    ( Blaine WA USA )",
    "country": "United States"
  },
  {
    "id": 999592,
    "name": "Quartet Technology, Inc.    ( Dracut MA USA )",
    "country": "USA"
  },
  {
    "id": 306252,
    "name": "Quartett Immunodiagnostika und    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 103331,
    "name": "Quartz Scientific Inc    ( Fairport Harbor OH USA )",
    "country": "United States"
  },
  {
    "id": 287123,
    "name": "Quarz-Elektro-Bauelemente GmbH    ( Walldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 281451,
    "name": "Quarzlampenfabrik Dr Mueller GmbH & Co KG    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 999526,
    "name": "Quasar    (   USA )",
    "country": "United States"
  },
  {
    "id": 457651,
    "name": "Quasar Bio-Tech Inc dba Silver Bay LLC    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 999862,
    "name": "Queen Beauty Industrial Co. Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 151938,
    "name": "Quelab Laboratories Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 451699,
    "name": "Quermed    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 453401,
    "name": "Quest 88 Ltd    ( Shifnal  England )",
    "country": "UK"
  },
  {
    "id": 450592,
    "name": "Quest Biomedical    ( Solihull  England )",
    "country": "UK"
  },
  {
    "id": 185156,
    "name": "Quest Diagnostics Inc    ( Madison NJ USA )",
    "country": "United States"
  },
  {
    "id": 106858,
    "name": "Quest International Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 103537,
    "name": "Quest Medical Inc    ( Allen TX USA )",
    "country": "United States"
  },
  {
    "id": 451131,
    "name": "Quest Pharmatech Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 367307,
    "name": "Quest Technologies Inc    ( Oconomowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 450624,
    "name": "Questra    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 363116,
    "name": "QuestStar Medical Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 260174,
    "name": "Queue Systems International    ( Driebergen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 460606,
    "name": "QueueVision Inc    ( Coral Gables FL USA )",
    "country": "United States"
  },
  {
    "id": 233898,
    "name": "Quick Notes Inc    ( Cooper City FL USA )",
    "country": "United States"
  },
  {
    "id": 456074,
    "name": "Quick Silver Instruments    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 454140,
    "name": "Quickels Systems AB    ( Nacka  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 107631,
    "name": "Quickie Designs    ( Fresno CA USA )",
    "country": "United States"
  },
  {
    "id": 453990,
    "name": "QuickMedical    ( Issaquah WA USA )",
    "country": "United States"
  },
  {
    "id": 452609,
    "name": "Quicksmart    ( Brisbane  Australia )",
    "country": "Australia"
  },
  {
    "id": 106011,
    "name": "Quidel Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 442495,
    "name": "Quidel Corp Australia    ( Mt Gravatt East  Australia )",
    "country": "Australia"
  },
  {
    "id": 442494,
    "name": "Quidel Deutschland GmbH    ( Marburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 175928,
    "name": "Quigley Motor Co Inc    ( Manchester PA USA )",
    "country": "United States"
  },
  {
    "id": 428452,
    "name": "Quimica FarmacEutica Bayer SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 460605,
    "name": "Quincy Compressor    ( Quincy IL USA )",
    "country": "United States"
  },
  {
    "id": 332216,
    "name": "Quincy Lab Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 103067,
    "name": "Quincy Specialties Co    ( Quincy IL USA )",
    "country": "United States"
  },
  {
    "id": 226282,
    "name": "Quinn Healthcare Products    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 999454,
    "name": "Quintet AC    (   USA )",
    "country": "United States"
  },
  {
    "id": 101891,
    "name": "Quinton Instruments Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 101627,
    "name": "QuinTron Instrument Co    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 460626,
    "name": "Quip Laboratories    ( Wilmington DE USA )",
    "country": "United States"
  },
  {
    "id": 454720,
    "name": "Quotient Diagnostics Ltd    ( Walton-on-Themes  England )",
    "country": "UK"
  },
  {
    "id": 441044,
    "name": "Quovadx Inc    ( Greenwood Village CO USA )",
    "country": "United States"
  },
  {
    "id": 152218,
    "name": "R & D Batteries Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 146677,
    "name": "R & D Imaging    ( Evansville IN USA )",
    "country": "United States"
  },
  {
    "id": 338622,
    "name": "R & D Medical Products Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 108624,
    "name": "R & D Systems Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 146678,
    "name": "R & F Imaging Systems Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 451949,
    "name": "R & R Therapeutics P/L    ( Leederville  Australia )",
    "country": "Australia"
  },
  {
    "id": 186295,
    "name": "R A Bell & Assoc    ( Encinitas CA USA )",
    "country": "United States"
  },
  {
    "id": 101268,
    "name": "R A Fischer Co    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 102588,
    "name": "R B Annis Co Inc    ( Greencastle IN USA )",
    "country": "United States"
  },
  {
    "id": 104122,
    "name": "R D Bussard & Son Inc    ( Albany OR USA )",
    "country": "United States"
  },
  {
    "id": 281317,
    "name": "R F Koch GmbH & Co    ( Eppelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 107196,
    "name": "R G Abernethy Inc    ( Creston NC USA )",
    "country": "United States"
  },
  {
    "id": 104120,
    "name": "R H Burton Co    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 458702,
    "name": "R Healthcare    ( London  England )",
    "country": "UK"
  },
  {
    "id": 102114,
    "name": "R J Lindquist Co    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 324991,
    "name": "R L Dolby & Co Ltd    ( Stirling  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 454114,
    "name": "R L Dolby & Co Ltd    ( Sterling  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 283379,
    "name": "R Leipold GmbH & Co KG    ( Kaufungen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287487,
    "name": "R Lubig Fachfirma fuer Medizinische Polsterungen    ( Wittstock  Germany )",
    "country": "Germany"
  },
  {
    "id": 403764,
    "name": "R Medical Supply    ( Hillsborough NC USA )",
    "country": "United States"
  },
  {
    "id": 102897,
    "name": "R Murphy Co Inc    ( Ayer MA USA )",
    "country": "United States"
  },
  {
    "id": 415966,
    "name": "R Poon Medical Products Co Ltd    ( Foshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439964,
    "name": "R R Donnelley    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 454466,
    "name": "R R Mechatronics The Netherlands    ( Hoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416146,
    "name": "R Shal (Mishal) Ltd    ( Rishon LeZion  Israel )",
    "country": "Israel"
  },
  {
    "id": 152409,
    "name": "R Taylor & Son    ( Walsall  England )",
    "country": "UK"
  },
  {
    "id": 175280,
    "name": "R W Cameron and Co    ( Wallkill NY USA )",
    "country": "United States"
  },
  {
    "id": 280734,
    "name": "R-Biopharm GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 397750,
    "name": "R-Biopharm Inc    ( Marshall MI USA )",
    "country": "United States"
  },
  {
    "id": 397751,
    "name": "R-Biopharm Ltd    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 371153,
    "name": "R-TAS Systems Inc    ( Sudbury MA USA )",
    "country": "United States"
  },
  {
    "id": 174131,
    "name": "R-Tec Corp    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 332171,
    "name": "R-Wave of Florida Inc    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 465117,
    "name": "R.A.M.M., Inc.,    ( W. Conshohocken 15 USA )",
    "country": "United States"
  },
  {
    "id": 340120,
    "name": "R2 Technology Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 456177,
    "name": "r4 Vascular Inc    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 270604,
    "name": "R82 A/S    ( Gedved  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 417217,
    "name": "R82 GmbH    ( Ingelheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 417218,
    "name": "R82 NL bv    ( Heerenveen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 416471,
    "name": "R82 UK Ltd    ( Halesowen  England )",
    "country": "UK"
  },
  {
    "id": 459463,
    "name": "Ra Medical Systems Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 999601,
    "name": "Ra Medical Systems, Inc.    ( CArlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 280158,
    "name": "Ra-Lo Vertriebsgesellschaft fuer Massage- und    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 459020,
    "name": "RA-Lock Security Solutions Inc    ( Midlothian TX USA )",
    "country": "United States"
  },
  {
    "id": 286229,
    "name": "Rabbasol-Chemie GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 306605,
    "name": "Racal Arbietssicherheit GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 287166,
    "name": "Raczek Analysentechnik GmbH    ( Garbsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 298174,
    "name": "Rad Elec Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 341555,
    "name": "Rad Source Technologies Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 453872,
    "name": "RadarFind Corp    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 103874,
    "name": "Radcal Corp    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 241968,
    "name": "Radcliffe Rehabilitation Services    ( Brackley  England )",
    "country": "UK"
  },
  {
    "id": 106070,
    "name": "Radco Imaging Technologies Inc    ( Midland TX USA )",
    "country": "United States"
  },
  {
    "id": 459030,
    "name": "Radcode Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 408264,
    "name": "Radeco Inc    ( Taftville CT USA )",
    "country": "United States"
  },
  {
    "id": 157520,
    "name": "Radi Medical Systems AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 449815,
    "name": "Radianse Inc    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 456109,
    "name": "Radiant Innovation Inc    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 415454,
    "name": "Radiant Medical Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 105583,
    "name": "Radiation Concepts Inc    ( Davie FL USA )",
    "country": "United States"
  },
  {
    "id": 103875,
    "name": "Radiation Monitoring Devices Inc    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 459488,
    "name": "Radiation Oncology Resources    ( Goshen IN USA )",
    "country": "United States"
  },
  {
    "id": 366837,
    "name": "Radiation Oncology Systems LP    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 105584,
    "name": "Radiation Products Design Inc    ( Albertville MN USA )",
    "country": "United States"
  },
  {
    "id": 459482,
    "name": "Radiation Shielding Inc    ( Pinson AL USA )",
    "country": "United States"
  },
  {
    "id": 287721,
    "name": "Radical Instruments    ( Ambala Cantt  India )",
    "country": "India"
  },
  {
    "id": 398905,
    "name": "Radim Argentina SA    ( Capital Federal  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 382584,
    "name": "Radim Iberica SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 454803,
    "name": "Radim Latin America Diagnosticos    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 266263,
    "name": "Radim SA    ( Angleur/Liege  USA )",
    "country": "United States"
  },
  {
    "id": 248767,
    "name": "Radim SpA    ( Pomezia (RM)  Italy )",
    "country": "Italy"
  },
  {
    "id": 382585,
    "name": "Radim SpA Niederlassung Deutschland    ( Sulzbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 457145,
    "name": "Radimetrics Inc (UK/Europe)    ( East Kilbride  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 457144,
    "name": "Radimetrics Inc (US/Canada)    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 407310,
    "name": "RADIN Group GmbH    ( Fuerth  USA )",
    "country": "United States"
  },
  {
    "id": 452066,
    "name": "RADinfo SYSTEMS    ( Dulles VA USA )",
    "country": "United States"
  },
  {
    "id": 186299,
    "name": "Radiographic Equipment Group LLC    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 186300,
    "name": "Radiographic Tech Inc    ( Fargo ND USA )",
    "country": "United States"
  },
  {
    "id": 170655,
    "name": "Radiological Imaging Services    ( Hamburg PA USA )",
    "country": "United States"
  },
  {
    "id": 459480,
    "name": "Radiological Imaging Technology    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 136830,
    "name": "Radiological Specialists Inc    ( Van Nuys CA USA )",
    "country": "United States"
  },
  {
    "id": 170667,
    "name": "Radiology Imaging    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 457222,
    "name": "Radiology OneSource    ( Nolensville TN USA )",
    "country": "United States"
  },
  {
    "id": 107541,
    "name": "Radiology Support Devices Inc    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 139324,
    "name": "Radiometer A/S    ( Bronshoj  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 101928,
    "name": "Radiometer America Inc    ( Westlake OH USA )",
    "country": "United States"
  },
  {
    "id": 451454,
    "name": "Radiometer Canada    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 287436,
    "name": "Radiometer GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 324833,
    "name": "Radiometer Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 256369,
    "name": "Radiometer Pacific Pty Ltd (Australia)    ( Mount Waverley  Australia )",
    "country": "Australia"
  },
  {
    "id": 232663,
    "name": "Radiometer Pacific Pty Ltd (New Zealand)    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 192833,
    "name": "Radiometer Trading KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 459479,
    "name": "Radion Inc    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 999630,
    "name": "RadioShack    (   USA )",
    "country": "United States"
  },
  {
    "id": 238053,
    "name": "Radius International LP    ( Grays Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 452605,
    "name": "Radleys Discovery Technologies    ( Saffron Walden  England )",
    "country": "UK"
  },
  {
    "id": 440375,
    "name": "Radlink    ( Redondo Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 467030,
    "name": "RadmediX LLC    ( West Chester OH USA )",
    "country": "United States"
  },
  {
    "id": 384264,
    "name": "Radnik Co    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 459148,
    "name": "Radnoti LLC    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 460608,
    "name": "Radnoti LLC    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 332179,
    "name": "Radon Medical Imaging Corp    ( Gaffney SC USA )",
    "country": "United States"
  },
  {
    "id": 320862,
    "name": "RADOS Technology Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 451664,
    "name": "RHK Technology    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 186306,
    "name": "RadScan Medical Equipment Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 465089,
    "name": "Radva Corp    ( Radforda VA USA )",
    "country": "United States"
  },
  {
    "id": 452000,
    "name": "RADVISION (Asia/Pacific)    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451997,
    "name": "RADVISION Inc    ( Fair Lawn NJ USA )",
    "country": "United States"
  },
  {
    "id": 451996,
    "name": "RADVISION Ltd (Israel)    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 451999,
    "name": "RADVISION Ltd (United Kingdom)    ( Uxbridge  England )",
    "country": "UK"
  },
  {
    "id": 454673,
    "name": "RADWAG USA LLC    ( North Miami Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 454674,
    "name": "RADWAG WAAGEN GmbH    ( Hilden  Germany )",
    "country": "Germany"
  },
  {
    "id": 185485,
    "name": "Radwag Zaklad Mechaniki Precyzyjnej    ( Radom  Poland )",
    "country": "Poland"
  },
  {
    "id": 402902,
    "name": "RADX Europe SA    ( Fleurier  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 410015,
    "name": "RAE Systems    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 399050,
    "name": "RAHN Medtronic MiniMed AG    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 272825,
    "name": "RaiChem    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 161103,
    "name": "Raimund Wenzler GmbH    ( Balgheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454305,
    "name": "Rainbow Mobility    ( Ness  England )",
    "country": "UK"
  },
  {
    "id": 451732,
    "name": "Rainbow Scientific Inc    ( Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 459043,
    "name": "RainDance Technologies Inc    ( Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 287584,
    "name": "Rainer Kaupp Chirurgische Instrumente Mikro-Nadelh    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 305478,
    "name": "Rainin Instrument LLC    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 233900,
    "name": "Raintree Systems Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 325225,
    "name": "Raising Canes LLC    ( Centerville MA USA )",
    "country": "United States"
  },
  {
    "id": 456075,
    "name": "Raiz & Saijad Surgical (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 451733,
    "name": "Rajala Rehab Products    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 462927,
    "name": "Rajas Enterprises    ( Haryana  India )",
    "country": "India"
  },
  {
    "id": 290005,
    "name": "RAL Tecnica para el Laboratorio SA    ( Sant Joan Despi (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 284089,
    "name": "Rala GmbH & Co    ( Ludwigshafen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454381,
    "name": "Raleigh America    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 286831,
    "name": "Ralf Bacher    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 402511,
    "name": "RAM    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 455143,
    "name": "Ram Medical Inc    ( Wayne NJ USA )",
    "country": "United States"
  },
  {
    "id": 999901,
    "name": "Ram Products Inc.    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 152530,
    "name": "RAM srl    ( Camin (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 105445,
    "name": "Ramco Laboratories Inc    ( Stafford TX USA )",
    "country": "United States"
  },
  {
    "id": 273859,
    "name": "Rampmaster    ( Weston ON Canada )",
    "country": "Canada"
  },
  {
    "id": 466468,
    "name": "Ramsay Medical Inc    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 306096,
    "name": "Ramsey Coote Instruments Co Pty Ltd    ( Oakleigh South  Australia )",
    "country": "Australia"
  },
  {
    "id": 250071,
    "name": "RamSoft Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 233902,
    "name": "Ranac Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 452849,
    "name": "Ranbaxy Pharmaceuticals Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461185,
    "name": "Ranbaxy US    ( Princeton  USA )",
    "country": "United States"
  },
  {
    "id": 452503,
    "name": "Rand srl    ( Medolla (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 107582,
    "name": "Rand-Scot Inc    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 464089,
    "name": "Randolph Austin Co    ( Manchacha TX USA )",
    "country": "United States"
  },
  {
    "id": 999641,
    "name": "Randolph Austin Company    ( Manchoco TX USA )",
    "country": "United States"
  },
  {
    "id": 417209,
    "name": "Randox de Puerto Rico    ( San Juan PR USA )",
    "country": "United States"
  },
  {
    "id": 393141,
    "name": "Randox Laboratories (Germany)    ( Krefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 393144,
    "name": "Randox Laboratories (Kyrgyzstan)    ( Bishkek  Kyrgyzstan )",
    "country": "Kyrgyzstan"
  },
  {
    "id": 417208,
    "name": "Randox Laboratories (Poland)    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 393137,
    "name": "Randox Laboratories Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 393136,
    "name": "Randox Laboratories Inc    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 457072,
    "name": "Randox Laboratories India Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 358073,
    "name": "Randox Laboratories Ltd    ( Crumlin  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 393143,
    "name": "Randox Laboratories Ltd (Hungary)    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 393145,
    "name": "Randox SRO (Czech Republic)    ( Praha 3  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 393140,
    "name": "Randox SRO (Slovakia)    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 457085,
    "name": "Randox Toxicology    ( Crumlin  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 466908,
    "name": "Rane Bathing Systems    ( Sparta TN USA )",
    "country": "United States"
  },
  {
    "id": 101630,
    "name": "Ranfac Corp    ( Avon MA USA )",
    "country": "United States"
  },
  {
    "id": 282191,
    "name": "Ranft-Terrazzo Rudolf Ranft GmbH    ( Grosshesselohe  Germany )",
    "country": "Germany"
  },
  {
    "id": 108260,
    "name": "Ranger All Season Corp    ( George IA USA )",
    "country": "United States"
  },
  {
    "id": 439201,
    "name": "Ranger Security Detectors Inc    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 455926,
    "name": "Ranier Technology Ltd    ( Teversham  England )",
    "country": "UK"
  },
  {
    "id": 461090,
    "name": "Ranir    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 332180,
    "name": "Rankin Biomedical Corp    ( Holly MI USA )",
    "country": "United States"
  },
  {
    "id": 453662,
    "name": "Ransohoff    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 453387,
    "name": "Rapid Sample Processing Ltd    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 375119,
    "name": "Rapid-Aid Corp    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 174710,
    "name": "Rapid-Aid Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460705,
    "name": "Rapid7    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 456121,
    "name": "RapiGEN Inc    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459015,
    "name": "Rapiscan Systems    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 184083,
    "name": "Rapitech UK Ltd    ( Skipton  USA )",
    "country": "United States"
  },
  {
    "id": 286835,
    "name": "Rapp & Wick Fabrikation Chirurgischer Instrumente    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 378110,
    "name": "Rapp Informatik Systeme GmbH    ( Ulm/Danou  Germany )",
    "country": "Germany"
  },
  {
    "id": 286911,
    "name": "Rasche & Uhlhorn Vertriebs-GmbH    ( Twistringen  Germany )",
    "country": "Germany"
  },
  {
    "id": 344923,
    "name": "Rasna Imaging Systems    ( Firenze (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 416003,
    "name": "Ratek Instruments Pty Ltd    ( Knox City  Australia )",
    "country": "Australia"
  },
  {
    "id": 282543,
    "name": "Rathaus-Apotheke    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282844,
    "name": "Rathgeber-Bioform-GmbH    ( Heilbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 280907,
    "name": "Ratiolab GmbH    ( Dreieich  Germany )",
    "country": "Germany"
  },
  {
    "id": 460532,
    "name": "Rational AG    ( Landsberg am Lech  Germany )",
    "country": "Germany"
  },
  {
    "id": 371727,
    "name": "Raul F Valera    ( Lima  Peru )",
    "country": "Peru"
  },
  {
    "id": 373492,
    "name": "Rauland-Borg (Canada) Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103070,
    "name": "Rauland-Borg Corp    ( Mount Prospect IL USA )",
    "country": "United States"
  },
  {
    "id": 457655,
    "name": "RAUMEDIC AG    ( Helmbrechts  Germany )",
    "country": "Germany"
  },
  {
    "id": 467251,
    "name": "RAUMEDIC Inc    ( Leesburg VA USA )",
    "country": "United States"
  },
  {
    "id": 306603,
    "name": "Rauscher Consumer Products GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 325875,
    "name": "Rautoclave cc    ( Mulbarton  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 149325,
    "name": "Raven Biological Laboratories    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 457910,
    "name": "Ray Foster Dental Equipment    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 454859,
    "name": "Ray-Tek Services Inc    ( Plaistow NH USA )",
    "country": "United States"
  },
  {
    "id": 467322,
    "name": "Rayence Co Ltd    ( Hwaseong  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 467323,
    "name": "Rayence Inc    ( Fort Lee NJ USA )",
    "country": "United States"
  },
  {
    "id": 291950,
    "name": "Raymar Medical Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 457351,
    "name": "Raymed Imaging AG    ( Krauchthal  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 202157,
    "name": "Raymond A Lamb    ( London  England )",
    "country": "UK"
  },
  {
    "id": 293320,
    "name": "Raymor Mediz Apparate    ( Ermatingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 272727,
    "name": "Rayner Intraocular Lenses Ltd    ( Hove  England )",
    "country": "UK"
  },
  {
    "id": 106255,
    "name": "Raynostix Group LLC    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 339268,
    "name": "Rayos X de Guadalajara SA de CV    ( Guadalajara  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 174716,
    "name": "Rayovac Corp    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 456444,
    "name": "RaySearch Laboratories AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 456174,
    "name": "Raysen Healthcare Products Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999854,
    "name": "Raytek Corporation    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 286390,
    "name": "Raytest Isotopenmessgeraete GmbH    ( Straubenhardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 456175,
    "name": "Rayto Life and Analytical Sciences Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 459017,
    "name": "Raz Design Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 101632,
    "name": "Razel Scientific Instruments Inc    ( St Albans VT USA )",
    "country": "United States"
  },
  {
    "id": 281107,
    "name": "RB Hospitalbedarf GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 409700,
    "name": "RB Medical Engineering Ltd    ( Ross-on-Wye  England )",
    "country": "UK"
  },
  {
    "id": 465128,
    "name": "RBC Technologies LLC.    ( Philadelphia 86 USA )",
    "country": "United States"
  },
  {
    "id": 456860,
    "name": "RC Imaging    ( Hilton NY USA )",
    "country": "United States"
  },
  {
    "id": 452197,
    "name": "RC Medical Inc    ( Tolland CT USA )",
    "country": "United States"
  },
  {
    "id": 455612,
    "name": "RC Smith Co    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 999894,
    "name": "RCA    (   USA )",
    "country": "United States"
  },
  {
    "id": 460627,
    "name": "Rcadia Medical Imaging Ltd    ( Wellesley MA USA )",
    "country": "United States"
  },
  {
    "id": 283662,
    "name": "RCN Medizin- und Rehatechnik GmbH    ( Sargenroth  Germany )",
    "country": "Germany"
  },
  {
    "id": 457430,
    "name": "RCP-Technik GmbH & Co KG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 305664,
    "name": "RCT Enterprises Pty Ltd    ( Newport  USA )",
    "country": "United States"
  },
  {
    "id": 333534,
    "name": "RD Equipment Inc    ( West Barnstable MA USA )",
    "country": "United States"
  },
  {
    "id": 324738,
    "name": "RDG Medical    ( Croydon  USA )",
    "country": "United States"
  },
  {
    "id": 450943,
    "name": "RDI    ( Concord MA USA )",
    "country": "United States"
  },
  {
    "id": 465101,
    "name": "RDM Industrial Products Inc    ( Milpitas  USA )",
    "country": "United States"
  },
  {
    "id": 363964,
    "name": "REA Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 354588,
    "name": "Reach Global Industries Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 459477,
    "name": "REACH Health    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 431489,
    "name": "Reade Advanced Materials    ( Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 453024,
    "name": "Reade International Corp    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 459421,
    "name": "ReadyPoint Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 325235,
    "name": "REAL Design    ( Dolgeville NY USA )",
    "country": "United States"
  },
  {
    "id": 999570,
    "name": "Realist Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 286267,
    "name": "Reaplan-Reanimationsplatten    ( Speyer  Germany )",
    "country": "Germany"
  },
  {
    "id": 416160,
    "name": "Reatime srl    ( Varedo (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458623,
    "name": "Rebellion Photonics    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 281016,
    "name": "Rebstock Instruments GmbH    ( Duerbheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 460628,
    "name": "Recall Corp    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 397719,
    "name": "Reckitt Benckiser plc    ( Slough  England )",
    "country": "UK"
  },
  {
    "id": 376045,
    "name": "Reckitt Benckiser Professional    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 285385,
    "name": "Reco-Medizintechnik Wolfgang Rentsch    ( Pirna  Germany )",
    "country": "Germany"
  },
  {
    "id": 286311,
    "name": "Recon GmbH Medizintechnik    ( Stadthagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462933,
    "name": "Recordex USA Inc    ( Long Island NY USA )",
    "country": "United States"
  },
  {
    "id": 466427,
    "name": "Records Imaging Systems Inc    ( North Little Rock AR USA )",
    "country": "United States"
  },
  {
    "id": 409691,
    "name": "RecoverCare    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 364492,
    "name": "Red Ball Oxygen Co Inc    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 460707,
    "name": "Red Hat Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 125328,
    "name": "Red Lion Medical Safety Inc    ( Newark DE USA )",
    "country": "United States"
  },
  {
    "id": 465206,
    "name": "Red River Computer Co Inc    ( Claremont 21 USA )",
    "country": "United States"
  },
  {
    "id": 459373,
    "name": "Red Shirt Imaging LLC    ( Decatur GA USA )",
    "country": "United States"
  },
  {
    "id": 459429,
    "name": "Red Thread    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 141414,
    "name": "RED-WING    (   USA )",
    "country": "United States"
  },
  {
    "id": 456396,
    "name": "Redax srl    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 465109,
    "name": "Redcord AS    ( Staubo  Norway )",
    "country": "Norway"
  },
  {
    "id": 343001,
    "name": "Redfield Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 453728,
    "name": "Redfield Corp    ( Rochelle Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 458511,
    "name": "Redfield Corp    ( Rochelle Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 450989,
    "name": "Redi-Tech Medical Products Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 106631,
    "name": "Redman Power Chair LLC Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 344901,
    "name": "RedMed Co    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 461355,
    "name": "ReDoc Software    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 467070,
    "name": "RedPath Integrated Pathology Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 460638,
    "name": "REDpoint International Medical Innovations    ( Vancouver  USA )",
    "country": "United States"
  },
  {
    "id": 460718,
    "name": "RedRick Technologies Inc    ( Mt Brydges ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456742,
    "name": "RedSense Medical AB    ( Halmstad  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 456743,
    "name": "RedSense Medical Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 459464,
    "name": "RedTech Inc    ( Mooresville NC USA )",
    "country": "United States"
  },
  {
    "id": 459498,
    "name": "Redwood Software Inc    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 457086,
    "name": "Redwood Toxicology Laboratory Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 454987,
    "name": "Redyns Medical LLC    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 999752,
    "name": "Reebok    (   USA )",
    "country": "United States"
  },
  {
    "id": 101633,
    "name": "Reedco Research    ( Geneva NY USA )",
    "country": "United States"
  },
  {
    "id": 281809,
    "name": "Rees GmbH    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 233564,
    "name": "Rees Scientific Corp    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 337778,
    "name": "Reference Diagnostics Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 457748,
    "name": "Refine USA Inc    ( Jacksonville Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 456978,
    "name": "REFLEX Analytical Corp    ( Ridgewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 460640,
    "name": "Refmacal Labs LLC    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 457069,
    "name": "Refractec Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 460722,
    "name": "Regal Beloit Corp    ( Beloit WI USA )",
    "country": "United States"
  },
  {
    "id": 453598,
    "name": "Regal Lager Inc    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 329913,
    "name": "RegalTex International Inc    ( Avenel NJ USA )",
    "country": "United States"
  },
  {
    "id": 280354,
    "name": "Regasol GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 453543,
    "name": "ReGear Life Sciences Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 178876,
    "name": "Regency Product International    ( Vernon CA USA )",
    "country": "United States"
  },
  {
    "id": 435458,
    "name": "Regeneration Technologies Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 451570,
    "name": "Regeneration Technologies Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 453950,
    "name": "Regenesis Biomedical Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 999416,
    "name": "RegenLab    (   Stitzerland )",
    "country": "Stitzerland"
  },
  {
    "id": 398945,
    "name": "Regent Medical (Austria)    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 398951,
    "name": "Regent Medical (Pacific Asia)    ( Hong Kong  USA )",
    "country": "United States"
  },
  {
    "id": 457452,
    "name": "Reger Medizintechnik GmbH    ( Villingendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 160169,
    "name": "rego Gollwitzer GmbH & Co KG    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457277,
    "name": "rego X-Ray GmbH    ( Augsburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 280986,
    "name": "Reha Media GmbH    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285318,
    "name": "Reha Sued Fahrzeugtechnik    ( Peissenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281866,
    "name": "Reha-Lift Gerd Ecklmeier GmbH    ( Fritzlar  Germany )",
    "country": "Germany"
  },
  {
    "id": 160179,
    "name": "Reha-Medi Gesellschaft fuer Biomedizinische Techni    ( Ammelshain  Germany )",
    "country": "Germany"
  },
  {
    "id": 185487,
    "name": "Reha-San-Repty Sp z o o    ( Tarnowskie Gory  USA )",
    "country": "United States"
  },
  {
    "id": 456083,
    "name": "Reha-Stim    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 280526,
    "name": "Reha-Team Bremen Medizintechnik GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286269,
    "name": "Reha-Trend GmbH Medizintechnik    ( Speyer  Germany )",
    "country": "Germany"
  },
  {
    "id": 471669,
    "name": "Rehab Dimensions    ( Fitchburg MA USA )",
    "country": "United States"
  },
  {
    "id": 232645,
    "name": "Rehab Direct    ( Valley City ND USA )",
    "country": "United States"
  },
  {
    "id": 108263,
    "name": "Rehab Plus Safety    ( Wolfforth TX USA )",
    "country": "United States"
  },
  {
    "id": 325236,
    "name": "Rehab Seating Systems Inc    ( Brookline MA USA )",
    "country": "United States"
  },
  {
    "id": 373249,
    "name": "Rehabilicare (UK) Ltd    ( Henley-on-Thames  England )",
    "country": "UK"
  },
  {
    "id": 384271,
    "name": "Rehabilicare (UK) Ltd    ( Henley-on-Thames  England )",
    "country": "UK"
  },
  {
    "id": 455284,
    "name": "Rehabilitation Centre for Children    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 471768,
    "name": "Rehabilitation Manufacturing Services Ltd    ( Sittingbourne  England )",
    "country": "UK"
  },
  {
    "id": 287221,
    "name": "rehability GmbH    ( Weinheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 453706,
    "name": "RehabMart LLC    ( Winterville GA USA )",
    "country": "United States"
  },
  {
    "id": 452889,
    "name": "RehaMed International    ( Homestead FL USA )",
    "country": "United States"
  },
  {
    "id": 363836,
    "name": "RehaNorm Bingen GmbH    ( Bingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287420,
    "name": "Rehatec    ( Schonau/Odenwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 463060,
    "name": "REHAVISTA GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455496,
    "name": "Reich Consulting Services Ltd    ( Plantation FL USA )",
    "country": "United States"
  },
  {
    "id": 160180,
    "name": "Reichelt Chemietechnik GmbH & Co    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 174417,
    "name": "Reichenbach Instrumentos e Fibra Otica Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 428386,
    "name": "Reichert Analytical Instruments    ( Depew NY USA )",
    "country": "United States"
  },
  {
    "id": 428385,
    "name": "Reichert Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 456545,
    "name": "Reichert Microscope Services    ( Depew NY USA )",
    "country": "United States"
  },
  {
    "id": 162917,
    "name": "Reichert Ophthalmic Instruments    ( Depew NY USA )",
    "country": "United States"
  },
  {
    "id": 105232,
    "name": "Reichert-Jung Inc Fiber Optics Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 280469,
    "name": "Reiher GmbH    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 999801,
    "name": "Reimers Electra Steam, Inc    ( Clear Brook VA USA )",
    "country": "United States"
  },
  {
    "id": 230518,
    "name": "Reimers Systems Inc    ( Lorton VA USA )",
    "country": "United States"
  },
  {
    "id": 356947,
    "name": "Reina Imaging    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 279471,
    "name": "Reinecker Reha-Technik GmbH    ( Alsbach-Haehnlein  Germany )",
    "country": "Germany"
  },
  {
    "id": 439546,
    "name": "Reinecker Reha-Technik Netherlands bv    ( Geldermalsen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 284342,
    "name": "Reinhold Erbstoesser Sicherheit-Umweltschutz    ( Marktheidenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 287174,
    "name": "Reinhold Narr Chirurgie-Instrumente    ( Wehingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287586,
    "name": "Reinhold Tontarra Chirurgische Instrumente    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456453,
    "name": "Reison Medical AB    ( Hallstahammar  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 279644,
    "name": "Reitel Feinwerktechnik GmbH    ( Bad Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456309,
    "name": "REKA Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 281503,
    "name": "Rekamed GmbH    ( Euskirchen  USA )",
    "country": "United States"
  },
  {
    "id": 160181,
    "name": "Relatec Medizin- und Reinraumtechnik GmbH    ( Ofterdingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 106009,
    "name": "Relay Specialties Inc    ( Oakland NJ USA )",
    "country": "United States"
  },
  {
    "id": 453367,
    "name": "RelayHealth    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 465096,
    "name": "Relcore Software, Inc.    ( Los Angeles PO USA )",
    "country": "United States"
  },
  {
    "id": 175644,
    "name": "Reliable Emergency Vehicles Inc    ( Comanche TX USA )",
    "country": "United States"
  },
  {
    "id": 459466,
    "name": "Reliable Scientific Inc    ( Nesbit MS USA )",
    "country": "United States"
  },
  {
    "id": 999855,
    "name": "Reliable Scientific, Inc.    ( Nesbit MS USA )",
    "country": "United States"
  },
  {
    "id": 999736,
    "name": "ReliaMed    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 106713,
    "name": "Reliance Medical Products Inc    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 237632,
    "name": "Reliance Orthodontic Products Inc    ( Itasca IL USA )",
    "country": "United States"
  },
  {
    "id": 999926,
    "name": "Reliant Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 999433,
    "name": "Relion    (   USA )",
    "country": "United States"
  },
  {
    "id": 999584,
    "name": "Relisys    (   USA )",
    "country": "United States"
  },
  {
    "id": 452228,
    "name": "REM Systems Inc    ( Paoli PA USA )",
    "country": "United States"
  },
  {
    "id": 287342,
    "name": "Rema GmbH Reha-Rollstuhl-Technik    ( Weyhe  USA )",
    "country": "United States"
  },
  {
    "id": 160182,
    "name": "REMA Medizintechnik GmbH    ( Duerbheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 285920,
    "name": "Remed GmbH Handel und Vertr Med Technik    ( Sailauf  Germany )",
    "country": "Germany"
  },
  {
    "id": 108073,
    "name": "ReMedPar Inc    ( Goodlettsville TN USA )",
    "country": "United States"
  },
  {
    "id": 105237,
    "name": "Remel Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 146706,
    "name": "Remesta Medical Corp    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 453750,
    "name": "Remi Laboratory Instruments    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 450790,
    "name": "Remington Medical    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 321284,
    "name": "Remington Medical Equipment Ltd    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451761,
    "name": "Remon Medical Technologies Ltd    ( Caesaria Industrial Park  Israel )",
    "country": "Israel"
  },
  {
    "id": 456057,
    "name": "Remote Diagnostic Technologies Ltd    ( Farleigh Walloop  England )",
    "country": "UK"
  },
  {
    "id": 454706,
    "name": "Remote Medical International    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 458679,
    "name": "Remote Meeting Technologies LLC    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 459468,
    "name": "Remspec Corp    ( Charlton MA USA )",
    "country": "United States"
  },
  {
    "id": 179167,
    "name": "Rendix Ltd    ( Qiryat Tivon  Israel )",
    "country": "Israel"
  },
  {
    "id": 452216,
    "name": "Reneuvo Inc    ( East Greenwich RI USA )",
    "country": "United States"
  },
  {
    "id": 283014,
    "name": "Renfert GmbH    ( Hilzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 374049,
    "name": "Renfert USA Inc    ( St Charles IL USA )",
    "country": "United States"
  },
  {
    "id": 456593,
    "name": "Renishaw plc    ( Wotton-under Edge  England )",
    "country": "UK"
  },
  {
    "id": 105382,
    "name": "Rennco Inc    ( Homer MI USA )",
    "country": "United States"
  },
  {
    "id": 456176,
    "name": "Rennington (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453881,
    "name": "RENOLIT Nederland bv    ( Enkhuizen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 456120,
    "name": "RENOSEM Co Ltd    ( Bucheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 460894,
    "name": "Renovo Solutions    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 466747,
    "name": "ReNu Medical Inc    ( Everett WA USA )",
    "country": "United States"
  },
  {
    "id": 465161,
    "name": "Reos Medical    ( Wells 16 USA )",
    "country": "United States"
  },
  {
    "id": 106197,
    "name": "Replacement Parts Industries Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 347230,
    "name": "Repro-Med Systems Inc/Gyneco Inc    ( Chester NY USA )",
    "country": "United States"
  },
  {
    "id": 453648,
    "name": "Reprocessing Products Corp    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 460897,
    "name": "Republic Services Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 460898,
    "name": "RES Software    ( Radnor PA USA )",
    "country": "United States"
  },
  {
    "id": 288753,
    "name": "RES-Q Healthcare Systems    ( Calabasas CA USA )",
    "country": "United States"
  },
  {
    "id": 459481,
    "name": "Rescue 42 Inc    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 186315,
    "name": "Rescue Alert    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 175933,
    "name": "Rescue Concepts Inc    ( Dayton TX USA )",
    "country": "United States"
  },
  {
    "id": 458386,
    "name": "Rescue Essentials    ( Salida CO USA )",
    "country": "United States"
  },
  {
    "id": 460645,
    "name": "Research Dataware LLC    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 456323,
    "name": "Research In Motion Ltd    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108065,
    "name": "Research Instruments International    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 149000,
    "name": "Research Instruments Ltd    ( Falmouth  England )",
    "country": "UK"
  },
  {
    "id": 458594,
    "name": "Research Instruments Ltd    ( Falmouth  England )",
    "country": "UK"
  },
  {
    "id": 431626,
    "name": "Research International Inc    ( Monroe WA USA )",
    "country": "United States"
  },
  {
    "id": 458787,
    "name": "Research Precision Instruments Co Inc    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 103541,
    "name": "Research Products Corp    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 106921,
    "name": "Research Products International Corp    ( Mount Prospect IL USA )",
    "country": "United States"
  },
  {
    "id": 397104,
    "name": "Resist-A-Band Div of Donovan Industries Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 297271,
    "name": "ResMed (Asia/Pacific) Ltd    ( Bella Vista  Australia )",
    "country": "Australia"
  },
  {
    "id": 345621,
    "name": "ResMed (UK) Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 177147,
    "name": "ResMed Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 417364,
    "name": "ResMed Finland Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 382224,
    "name": "ResMed GmbH & Co KG    ( Moenchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 417363,
    "name": "ResMed Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 417360,
    "name": "ResMed Malaysia Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 454203,
    "name": "Roche Molecular Systems Inc    ( Branchburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 417362,
    "name": "ResMed NZ Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 345622,
    "name": "ResMed SA    ( Saint-Priest  France )",
    "country": "France"
  },
  {
    "id": 451281,
    "name": "ResMed Schweiz AG    ( Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 345623,
    "name": "ResMed Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 417365,
    "name": "ResMed Spain SL    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 417361,
    "name": "ResMed Sweden AB    ( Trollhattan  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 340131,
    "name": "Resonance Technology Inc    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 451977,
    "name": "Resonant Medical Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 285094,
    "name": "Resorba Wundversorgung GmbH & Co KG    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459049,
    "name": "Resource Software International Ltd    ( Oshawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 174719,
    "name": "Respan Products Inc    ( Erin ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452964,
    "name": "RespCare Inc    ( Coconut Creek FL USA )",
    "country": "United States"
  },
  {
    "id": 108880,
    "name": "Respiratory Equipment and Mfg Co    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 460654,
    "name": "Respiratory Research Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 999573,
    "name": "Respiratory Science Industries LTD (RSI)    (   USA )",
    "country": "United States"
  },
  {
    "id": 409902,
    "name": "Respiratory Systems Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 466573,
    "name": "Respiratory Technology Corp (ResTech)    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 409922,
    "name": "Respirex International Ltd    ( Redhill  England )",
    "country": "UK"
  },
  {
    "id": 347587,
    "name": "Respironics Asia/Pacific    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 332046,
    "name": "Respironics Deutschland GmbH    ( Herrsching  Germany )",
    "country": "Germany"
  },
  {
    "id": 101639,
    "name": "Respironics Inc    ( Murrysville PA USA )",
    "country": "United States"
  },
  {
    "id": 321323,
    "name": "Respironics International Inc    ( Carquefou Cedex  France )",
    "country": "France"
  },
  {
    "id": 451589,
    "name": "Respironics Novametrix LLC    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 452521,
    "name": "Respironics UK Ltd    ( Chichester  England )",
    "country": "UK"
  },
  {
    "id": 458812,
    "name": "RespirTech    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 999557,
    "name": "RespirTech    ( St. Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 999459,
    "name": "Respond Systems, Inc.    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 460660,
    "name": "Response Care Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 431508,
    "name": "Response Equipment Co    ( Abingdon MD USA )",
    "country": "United States"
  },
  {
    "id": 451666,
    "name": "Response Medical Equipment Ltd    ( Chipping Campden  England )",
    "country": "UK"
  },
  {
    "id": 452613,
    "name": "Responsive Respiratory Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 458389,
    "name": "Restoration Robotics Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 178843,
    "name": "Restorative Care of America Inc (RCAI)    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 396709,
    "name": "Restorative Medical Inc    ( Brandenburg KY USA )",
    "country": "United States"
  },
  {
    "id": 452934,
    "name": "Restorative Oral Health    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 455154,
    "name": "Restorative Therapies Inc (Australia)    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 455153,
    "name": "Restorative Therapies Inc (USA)    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 999916,
    "name": "Restorative Therapies, Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 391911,
    "name": "Restored Sight Projects Ltd    ( Monmouth  Wales )",
    "country": "Wales"
  },
  {
    "id": 460686,
    "name": "RestorixHealth    ( Tarrytown NY USA )",
    "country": "United States"
  },
  {
    "id": 460663,
    "name": "Retail Management Solutions    ( Lacey WA USA )",
    "country": "United States"
  },
  {
    "id": 330915,
    "name": "Retractable Technologies Inc    ( Little Elm TX USA )",
    "country": "United States"
  },
  {
    "id": 459374,
    "name": "RetroFit LLC    ( Harrison NJ USA )",
    "country": "United States"
  },
  {
    "id": 999631,
    "name": "RetroFit, LLC    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 171765,
    "name": "Retronic Instruments    ( Berndorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 454229,
    "name": "Retsch Technology GmbH    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 454407,
    "name": "Return To Fitness LLC    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 454807,
    "name": "Reva Industries Ltd    ( Kingston Upon Hull  England )",
    "country": "UK"
  },
  {
    "id": 151286,
    "name": "Revab bv    ( Silvolde  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 460685,
    "name": "Revcord    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 320755,
    "name": "Revelation LLC    ( Bridgeport CT USA )",
    "country": "United States"
  },
  {
    "id": 340196,
    "name": "Revelation Medical Inc    ( Bozeman MT USA )",
    "country": "United States"
  },
  {
    "id": 460691,
    "name": "Revenue Advantage Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 460693,
    "name": "Revenue Cycle Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 399279,
    "name": "Revivant Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 460694,
    "name": "Revolabs Inc    ( Sudbury MA USA )",
    "country": "United States"
  },
  {
    "id": 454672,
    "name": "Revolutionary Science    ( Shafer MN USA )",
    "country": "United States"
  },
  {
    "id": 454137,
    "name": "Revolutions Medical Corp    ( Mount Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 427791,
    "name": "Rex Medical LP    ( Conshohocken PA USA )",
    "country": "United States"
  },
  {
    "id": 285373,
    "name": "Rex-Gummiwarenfabrik    ( Pfungstadt  USA )",
    "country": "United States"
  },
  {
    "id": 456110,
    "name": "REXMED Industries Co Ltd    ( Kaohsiung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 332193,
    "name": "Rexon Components Inc    ( Beachwood OH USA )",
    "country": "United States"
  },
  {
    "id": 452981,
    "name": "Rexon TLD Systems Inc    ( Beachwood OH USA )",
    "country": "United States"
  },
  {
    "id": 457630,
    "name": "Rexton    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 462893,
    "name": "Rexxam Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457966,
    "name": "Reyhan Teb Medical and Laboratory Mfg    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 458150,
    "name": "Reytek Corp    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 160184,
    "name": "Rezila Aerztemoebel GmbH    ( Lage  Germany )",
    "country": "Germany"
  },
  {
    "id": 458424,
    "name": "RF IDeas Inc    ( Rolling Meadows IL USA )",
    "country": "United States"
  },
  {
    "id": 460533,
    "name": "RF Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 453109,
    "name": "RF Surgical Systems Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 175357,
    "name": "RF Technologies Inc    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 458806,
    "name": "RFA Medical Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 286840,
    "name": "RFQ Medizintechnik GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 298163,
    "name": "RFSU AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 306410,
    "name": "RGB Medical Devices SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 452612,
    "name": "RGF Environmental    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 466444,
    "name": "RGI Medical Manufacturing Inc    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 456111,
    "name": "Rheamed Biotechnology Co    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456076,
    "name": "Rhein Enterprises (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 108074,
    "name": "Rhein Medical    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 282667,
    "name": "Rheindorff GmbH    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 454468,
    "name": "RheoMeditech Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451734,
    "name": "RHINO Pediatric Orthopedic Designs Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 376066,
    "name": "RhinoMetrics A/S    ( Lynge  USA )",
    "country": "United States"
  },
  {
    "id": 455403,
    "name": "RhinoSystems Inc    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 460697,
    "name": "RHK Technology Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 451947,
    "name": "Rhytec Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 464962,
    "name": "RI LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 306416,
    "name": "Ri Mos srl    ( Mirandola (MO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454681,
    "name": "RianCorp Pty Ltd    ( Marleston  Australia )",
    "country": "Australia"
  },
  {
    "id": 287763,
    "name": "Ribbel International Ltd    ( Sonepat  India )",
    "country": "India"
  },
  {
    "id": 285891,
    "name": "riblu-Medizintechnik GmbH    ( Ruescheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 453009,
    "name": "Ricca Chemical Co    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 171595,
    "name": "Rice Lake Weighing Systems    ( Rice Lake WI USA )",
    "country": "United States"
  },
  {
    "id": 101645,
    "name": "Rich-Mar Corp    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 287467,
    "name": "Richard Braun    ( Winterberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286486,
    "name": "Richard Geromiller GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 284318,
    "name": "Richard Kaphingst GmbH    ( Lahntal/Gossfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 286846,
    "name": "Richard Martin Medizintechnik GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280881,
    "name": "Richard Mueller GmbH    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 283697,
    "name": "Richard Scherpe Grafische Betriebe GmbH & Co KG    ( Dueren  Germany )",
    "country": "Germany"
  },
  {
    "id": 282021,
    "name": "Richard Schilder GmbH & Co KG    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456851,
    "name": "Richard Schilder Sani-Med GmbH & Co KG    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 284126,
    "name": "Richard Schulz-Dusenschoen    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 415984,
    "name": "Richard Thomson Pty Ltd    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 366839,
    "name": "Richard Wolf Austria Ges MbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 454739,
    "name": "Richard Wolf Belgium NV    ( Gent-Drongen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 366842,
    "name": "Richard Wolf France Sarl    ( Reims  France )",
    "country": "France"
  },
  {
    "id": 161114,
    "name": "Richard Wolf GmbH    ( Knittlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454502,
    "name": "Richard Wolf India Private Ltd    ( Gurgaon  India )",
    "country": "India"
  },
  {
    "id": 102126,
    "name": "Richard Wolf Medical Instruments Corp    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 366841,
    "name": "Richard Wolf UK Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 347778,
    "name": "Richard-Allan Scientific    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 185340,
    "name": "Richard-James Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 155982,
    "name": "Richards Medical Equipment Inc    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 146713,
    "name": "Richardson Electronics Ltd    ( LaFox IL USA )",
    "country": "United States"
  },
  {
    "id": 454540,
    "name": "Richardson Healthcare Ltd    ( Borehamwood  England )",
    "country": "UK"
  },
  {
    "id": 169878,
    "name": "Richmond Dental    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 226285,
    "name": "Richmond Products Inc    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 284708,
    "name": "Richter GmbH & Co KG    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279856,
    "name": "Richter Rehabilitation GmbH    ( Bayreuth  Germany )",
    "country": "Germany"
  },
  {
    "id": 101989,
    "name": "Rico Suction Labs Inc    ( Burlington NC USA )",
    "country": "United States"
  },
  {
    "id": 459034,
    "name": "Ricoh Americas Corp    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 459032,
    "name": "Ricoh Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 108425,
    "name": "Ricon Corp    ( Panorama City CA USA )",
    "country": "United States"
  },
  {
    "id": 162655,
    "name": "Riddervold & Co A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 461415,
    "name": "Ridgid Tool Company    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 285726,
    "name": "Rieber GmbH & Co KG    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 394817,
    "name": "Riebesam GmbH & Co KG    ( Genthin  Germany )",
    "country": "Germany"
  },
  {
    "id": 282213,
    "name": "Rieco Druck- und Papierverarbeitungs-GmbH & Co    ( Gruenstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 279485,
    "name": "Rieco Orgaform Altenburg GmbH    ( Altenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281263,
    "name": "Riede Opthalmic-and Microsurgical Instruments    ( Emmingen/Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456612,
    "name": "RIEMSER Arzneimittel AG    ( Kleinostheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 456615,
    "name": "RIEMSER Inc    ( Research Triangle Park NC USA )",
    "country": "United States"
  },
  {
    "id": 463070,
    "name": "Riester USA LLC    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 321064,
    "name": "Rifton Equipment    ( Rifton NY USA )",
    "country": "United States"
  },
  {
    "id": 459252,
    "name": "Rig Expert Ukraine Ltd    ( Kyiv  Ukraine )",
    "country": "United Kingdomraine"
  },
  {
    "id": 458712,
    "name": "Rigaku Americas    ( The Woodlands TX USA )",
    "country": "United States"
  },
  {
    "id": 451245,
    "name": "Rigel Medical    ( Peterlee  England )",
    "country": "UK"
  },
  {
    "id": 286585,
    "name": "Riggers Medizintechnik Thalheim GmbH    ( Thalheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 453570,
    "name": "Right Medical Products    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 465207,
    "name": "Right! Systems Inc.    ( Lacey 26 USA )",
    "country": "United States"
  },
  {
    "id": 471764,
    "name": "Rigol USA    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 148993,
    "name": "Riken Keiki Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455976,
    "name": "Rikutoh Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 367310,
    "name": "Riley Medical Europe    ( Orvin  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 174970,
    "name": "Riley Medical Inc    ( Auburn ME USA )",
    "country": "United States"
  },
  {
    "id": 999865,
    "name": "RIM    (   USA )",
    "country": "United States"
  },
  {
    "id": 452403,
    "name": "Rimage Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452497,
    "name": "Rimage Europe    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 452498,
    "name": "Rimage Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 306763,
    "name": "Rimec srl    ( Rioveggio (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 157038,
    "name": "Rimed Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 455653,
    "name": "Rimsa P Longoni srl    ( Seregno (MB)  Italy )",
    "country": "Italy"
  },
  {
    "id": 283163,
    "name": "Rincon Chirurgische Instrumente    ( Immendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452611,
    "name": "Ring Communications Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 285702,
    "name": "Rinol AG Fussbodentechnik    ( Renningen  Germany )",
    "country": "Germany"
  },
  {
    "id": 291954,
    "name": "Rinz-L-O Pillow Co    ( Ferndale MI USA )",
    "country": "United States"
  },
  {
    "id": 456112,
    "name": "Rio Flexon Technology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 184073,
    "name": "Rios Rocha SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 451829,
    "name": "Rioux Vision Mobile Technology Solutions    ( Elgin SC USA )",
    "country": "United States"
  },
  {
    "id": 281287,
    "name": "Risa-Dental GmbH    ( Engen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462855,
    "name": "RISARC Management Systems    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 282552,
    "name": "Rische & Herfurth GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456178,
    "name": "Rising Medical Equipment Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 460913,
    "name": "RiskQual Technologies Inc    ( Pembroke Pines FL USA )",
    "country": "United States"
  },
  {
    "id": 358434,
    "name": "Rita Medical Systems Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 440383,
    "name": "Rite-Dent Latin America    ( Guatemala City  Guatemala )",
    "country": "Guatemala"
  },
  {
    "id": 296808,
    "name": "Rite-Dent Mfg Corp    ( Hialeah FL USA )",
    "country": "United States"
  },
  {
    "id": 999667,
    "name": "Rite-Hete    ( Minneapolis MN USA )",
    "country": "USA"
  },
  {
    "id": 457906,
    "name": "Rite-Temp Mfg    ( Rogers AR USA )",
    "country": "United States"
  },
  {
    "id": 459473,
    "name": "RITM America LLC    ( Henderson NV USA )",
    "country": "United States"
  },
  {
    "id": 307450,
    "name": "Roynhardt Pty Ltd    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 471691,
    "name": "Ritter Concept GmbH    ( Zwoenitz  Germany )",
    "country": "Germany"
  },
  {
    "id": 331382,
    "name": "Ritter IBW Dentalsysteme GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 104310,
    "name": "Ritter-Tycos Div Sybron Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 287570,
    "name": "Rittershaus GmbH & Co KG    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 999914,
    "name": "Rival Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 450808,
    "name": "Riverain Medical    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 456861,
    "name": "Riverain Technologies    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 467330,
    "name": "Riverbed Technology    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 458129,
    "name": "Riverpoint Medical    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 456024,
    "name": "Riverstone Resources (Wuxi) Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456025,
    "name": "Riverstone Resources Pte Ltd    ( Gilbert AZ USA )",
    "country": "United States"
  },
  {
    "id": 456023,
    "name": "Riverstone Resources Sdn Bhd    ( Burkit Berunting  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 462596,
    "name": "Rivertek Medical Systems Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 357982,
    "name": "RJ & VK Bird Pty Ltd    ( Middle Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 154209,
    "name": "RJ HARVEY INSTRUMENT CORP    (   USA )",
    "country": "United States"
  },
  {
    "id": 161428,
    "name": "RJ Mobility Ltd    ( Halifax  England )",
    "country": "UK"
  },
  {
    "id": 416536,
    "name": "RJ-Laser Reimers & Janssen GmbH    ( Winden  Germany )",
    "country": "Germany"
  },
  {
    "id": 105623,
    "name": "RJL Systems Inc    ( Clinton Township MI USA )",
    "country": "United States"
  },
  {
    "id": 286848,
    "name": "RK Instrumente Rainer Kroenauer    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455124,
    "name": "RKI Instruments Inc    ( Union City CA USA )",
    "country": "United States"
  },
  {
    "id": 458997,
    "name": "rL Solutions    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 466569,
    "name": "RLD Medical    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 226947,
    "name": "RMC Medical    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 462572,
    "name": "RMG Networks Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 171250,
    "name": "RMO Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 455595,
    "name": "RMS Medical Products    ( Chester NY USA )",
    "country": "United States"
  },
  {
    "id": 461269,
    "name": "RMS Omega Technologies    ( Bluffton SC USA )",
    "country": "United States"
  },
  {
    "id": 356699,
    "name": "RMT Aquatics (RehaMed International)    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 282246,
    "name": "RMT Rego Maschinentechnologie GmbH    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 280822,
    "name": "RMT Rehamed Technology GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 409119,
    "name": "RMXC Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 458462,
    "name": "RNA Holdings LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457376,
    "name": "RNK Products Inc    ( Viera FL USA )",
    "country": "United States"
  },
  {
    "id": 102051,
    "name": "Road Rescue Inc    ( Marion SC USA )",
    "country": "United States"
  },
  {
    "id": 999704,
    "name": "Roadmaster USA    (   USA )",
    "country": "United States"
  },
  {
    "id": 183748,
    "name": "Roadrunner Oxygen & Medical Supply Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 101648,
    "name": "Robbins Instruments Inc    ( Chatham NJ USA )",
    "country": "United States"
  },
  {
    "id": 332195,
    "name": "Robeck Co    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 460916,
    "name": "Robelan Display Inc    ( Hempstead NY USA )",
    "country": "United States"
  },
  {
    "id": 280164,
    "name": "Robert Helwig GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 283950,
    "name": "Robert Hoening Spezialfahrzeuge GmbH    ( Leonberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283496,
    "name": "Robert Kombert Industriebedarf GmbH & Co KG    ( Koblenz  Germany )",
    "country": "Germany"
  },
  {
    "id": 280166,
    "name": "Robert Riele GmbH & Co KG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 403761,
    "name": "Roberts Medical Refurb LLC    ( Canton OH USA )",
    "country": "United States"
  },
  {
    "id": 101649,
    "name": "Roberts Mfg Co Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 461271,
    "name": "Robin Medical Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 272730,
    "name": "Robinson Healthcare Ltd    ( Worksop  England )",
    "country": "UK"
  },
  {
    "id": 461288,
    "name": "Robomedica Inc    ( Mission Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 459267,
    "name": "Robot Coupe USA Inc    ( Ridgeland MS USA )",
    "country": "United States"
  },
  {
    "id": 451846,
    "name": "Roboz Surgical Instrument Co Inc    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 282757,
    "name": "Robu Glasfilter-Geraete GmbH    ( Hattert  Germany )",
    "country": "Germany"
  },
  {
    "id": 401593,
    "name": "Roche (Hellas) SA    ( Athinia (Marousi)  Greece )",
    "country": "Greece"
  },
  {
    "id": 455245,
    "name": "Roche Diabetes Care AG    ( Burgdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391902,
    "name": "Roche Diagnostics (Austria) GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 351509,
    "name": "Roche Diagnostics (Canada)    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 401588,
    "name": "Roche Diagnostics (Denmark)    ( Hvidovre  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 401592,
    "name": "Roche Diagnostics (Deutschland) GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 401591,
    "name": "Roche Diagnostics (France)    ( Meylan Cedex  France )",
    "country": "France"
  },
  {
    "id": 401585,
    "name": "Roche Diagnostics (Hong Kong) Ltd    ( WanChai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401599,
    "name": "Roche Diagnostics (M) Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 391900,
    "name": "Roche Diagnostics (Schweiz) AG    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 401615,
    "name": "Roche Diagnostics (Thailand) Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 401580,
    "name": "Roche Diagnostics Argentina    ( Capital Federal  USA )",
    "country": "United States"
  },
  {
    "id": 391901,
    "name": "Roche Diagnostics Asia Pacific Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 354557,
    "name": "Roche Diagnostics Australia Pty Ltd    ( Castle Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 401581,
    "name": "Roche Diagnostics Belgium NV/SA    ( Vilvoorde  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 391899,
    "name": "Roche Diagnostics Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 456912,
    "name": "Roche Diagnostics GmbH (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 401597,
    "name": "Roche Diagnostics KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 401598,
    "name": "Roche Diagnostics Korea Co Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 401614,
    "name": "Roche Diagnostics Ltd (Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 401617,
    "name": "Roche Diagnostics Ltd (UK)    ( Burgess Hill  England )",
    "country": "UK"
  },
  {
    "id": 401601,
    "name": "Roche Diagnostics Nederland bv    ( Almere  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 401602,
    "name": "Roche Diagnostics NZ Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 401613,
    "name": "Roche Diagnostics Scandinavia AB    ( Bromma  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401616,
    "name": "Roche Diagnostics Sistemleri Ticaret AS    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 401612,
    "name": "Roche Diagnostics SL    ( San Cugat del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 401596,
    "name": "Roche Diagnostics SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 401587,
    "name": "Roche Diagnostics sro    ( Praha 7  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 401589,
    "name": "Roche Ecuador SA    ( Quito  Ecuador )",
    "country": "Ecuador"
  },
  {
    "id": 455244,
    "name": "Roche Insulin Delivery Systems Inc    ( Fishers IN USA )",
    "country": "United States"
  },
  {
    "id": 401618,
    "name": "Roche International Ltd (Uruguay)    ( Montevideo  Uruguay )",
    "country": "Uruguay"
  },
  {
    "id": 401594,
    "name": "Roche Magyarorszag Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 401609,
    "name": "Roche Moskva Ltd    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 455816,
    "name": "Roche NimbleGen Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 401603,
    "name": "Roche Norge AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 401590,
    "name": "Roche Oy    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 401604,
    "name": "Roche Pakistan Ltd    ( Karachi  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 456911,
    "name": "Roche Peru    ( Lima  Peru )",
    "country": "Peru"
  },
  {
    "id": 401606,
    "name": "Roche Philippines Inc    ( Makati City  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 401607,
    "name": "Roche Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 401611,
    "name": "Roche Products (Pty) Ltd South Africa    ( Randburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 456531,
    "name": "Roche PVT GmbH    ( Waiblingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 401608,
    "name": "Roche Sistemas de Diagnosticos Lda    ( Amadora  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 401610,
    "name": "Roche Slovakia sro    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 340201,
    "name": "Rochester Cassette Repair Center Inc    ( Hilton NY USA )",
    "country": "United States"
  },
  {
    "id": 103369,
    "name": "Rochester Electro-Medical Inc    ( Lutz FL USA )",
    "country": "United States"
  },
  {
    "id": 232333,
    "name": "Rochester Medical Corp    ( Stewartville MN USA )",
    "country": "United States"
  },
  {
    "id": 454092,
    "name": "Rochester Medical Ltd    ( Lancing  England )",
    "country": "UK"
  },
  {
    "id": 461102,
    "name": "Rochester Midland Corp    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 458178,
    "name": "Rochester/Finger Lakes Eye & Tissue Bank    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 163504,
    "name": "Rocialle Medical Ltd    ( Sawston  England )",
    "country": "UK"
  },
  {
    "id": 462847,
    "name": "Rock'N Crab Aquatics    ( Windham NH USA )",
    "country": "United States"
  },
  {
    "id": 450779,
    "name": "Rocket Medical (US)    ( Hingham MA USA )",
    "country": "United States"
  },
  {
    "id": 450778,
    "name": "Rocket Medical plc    ( Washington  England )",
    "country": "UK"
  },
  {
    "id": 460734,
    "name": "Rocket Software Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 102156,
    "name": "Rockford Medical & Safety Co    ( Rockford IL USA )",
    "country": "United States"
  },
  {
    "id": 451849,
    "name": "Rockline Industries Inc    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 107811,
    "name": "Rockwell Laser Industries    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 452782,
    "name": "Rockwell Medical Technologies Inc    ( Wixom MI USA )",
    "country": "United States"
  },
  {
    "id": 461292,
    "name": "Rockwood Surgical Inc    ( Winter Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 456410,
    "name": "Rocky Mountain Lions Eye Bank    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 146720,
    "name": "Rocky Mountain Microscope Corp    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 186321,
    "name": "Rocky Mountain Radiographics    ( Parker CO USA )",
    "country": "United States"
  },
  {
    "id": 183096,
    "name": "Rodby Innovation AB    ( Hagby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 465226,
    "name": "RODE Microphones    ( Silverwater, NSW 10 USA )",
    "country": "United States"
  },
  {
    "id": 458782,
    "name": "Rodin4d    ( Pessac  France )",
    "country": "France"
  },
  {
    "id": 358088,
    "name": "Rodwell Scientific Instruments    ( Basildon  England )",
    "country": "UK"
  },
  {
    "id": 294364,
    "name": "Roehm GmbH    ( Darmstadt  USA )",
    "country": "United States"
  },
  {
    "id": 283788,
    "name": "ROEKO GmbH & Co KG    ( Langenau/Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 170912,
    "name": "Roentgen-Service AG    ( Emmen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452737,
    "name": "Roesner-Mautby Meditrade GmbH    ( Kiefersfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 287286,
    "name": "Roessel-Messtechnik GmbH & Co    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 307513,
    "name": "Roeth Medical Components (Pty) Ltd    ( Pinelands  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 462952,
    "name": "ROFIN-SINAR Laser GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 467079,
    "name": "Roftek Ltd    (   England )",
    "country": "UK"
  },
  {
    "id": 416247,
    "name": "Rogan-Delft bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452519,
    "name": "Rogan-Delft bv (Asia Office)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 186324,
    "name": "Rogers Ultrasound Imaging Inc    ( Frankfort KY USA )",
    "country": "United States"
  },
  {
    "id": 281151,
    "name": "Rogg-Verbandstoffe KG    ( Eching  Germany )",
    "country": "Germany"
  },
  {
    "id": 457534,
    "name": "Rogue Research Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 463025,
    "name": "Rohde & Schwarz USA Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 133974,
    "name": "Rohm & Haas Co    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 286233,
    "name": "Rohre-Ketterer GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160802,
    "name": "Roland Arzneimittel GmbH    ( Hamburg 73  Germany )",
    "country": "Germany"
  },
  {
    "id": 456981,
    "name": "Roland DG Corp    ( Hamamatsu  Japan )",
    "country": "Japan"
  },
  {
    "id": 456982,
    "name": "Roland DGA Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 416527,
    "name": "Roland Pty Ltd    ( Girraween  Australia )",
    "country": "Australia"
  },
  {
    "id": 161309,
    "name": "Rolco Srl    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 102915,
    "name": "Roldan Products Corp    ( Ballwin MO USA )",
    "country": "United States"
  },
  {
    "id": 452547,
    "name": "Rolence Enterprise Inc    ( Chungli  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454277,
    "name": "Rolf Greiner Biochemica    ( Flacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 281295,
    "name": "Rolf Ritter KG    ( Enger  Germany )",
    "country": "Germany"
  },
  {
    "id": 454837,
    "name": "Rolko Kahlgrueber GmbH    ( Borgholzhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 325242,
    "name": "Roll-A-Bout Corp    ( Frederica DE USA )",
    "country": "United States"
  },
  {
    "id": 175941,
    "name": "Rolland Specialty Vehicles & Products    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 280471,
    "name": "Rollei Fototechnic GmbH    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 306170,
    "name": "Roller Chair Pty Ltd    ( Wingfield  Australia )",
    "country": "Australia"
  },
  {
    "id": 284520,
    "name": "Rollgliss GmbH    ( Murnau  Germany )",
    "country": "Germany"
  },
  {
    "id": 397114,
    "name": "Rolli-Moden Designs    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 105262,
    "name": "RoLoKe Co    ( Inglewood CA USA )",
    "country": "United States"
  },
  {
    "id": 328141,
    "name": "Rolyn Optics Co    ( Covina CA USA )",
    "country": "United States"
  },
  {
    "id": 282556,
    "name": "ROM (Rud Otto Meyer)    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 452434,
    "name": "Roma Medical Aids Ltd    ( Bridgend  England )",
    "country": "UK"
  },
  {
    "id": 465179,
    "name": "ROMA Medical Supplies, Inc    ( Humble 86 USA )",
    "country": "United States"
  },
  {
    "id": 170914,
    "name": "Romabau AG    ( Weinfelden  USA )",
    "country": "United States"
  },
  {
    "id": 461299,
    "name": "Romack Inc    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 101654,
    "name": "Roman Research Inc    ( Hanson MA USA )",
    "country": "United States"
  },
  {
    "id": 104731,
    "name": "Romark Diagnostics    ( Hillsdale NJ USA )",
    "country": "United States"
  },
  {
    "id": 455089,
    "name": "RoMedic AB    ( Jarfalla  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453876,
    "name": "RoMedic Inc    ( Ephrata PA USA )",
    "country": "United States"
  },
  {
    "id": 462820,
    "name": "Rompa    ( Chesterfield  England )",
    "country": "UK"
  },
  {
    "id": 455945,
    "name": "Ronco    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 440679,
    "name": "Ronco Communications and Electronics Inc    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 106430,
    "name": "Rondex Products Inc    ( Rockford IL USA )",
    "country": "United States"
  },
  {
    "id": 281545,
    "name": "RONVIG Dental Mfg A/S    ( Daugaard  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 999542,
    "name": "Roper Appliances    (   USA )",
    "country": "United States"
  },
  {
    "id": 999536,
    "name": "Roper Home Appliances    (   USA )",
    "country": "United States"
  },
  {
    "id": 399190,
    "name": "Roper Scientific GmbH    ( Ottobrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 285281,
    "name": "Ropimex R Opel GmbH    ( Neunkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160780,
    "name": "Ropox A/S    ( Naestved  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451537,
    "name": "Ropox A/S    ( Naestved  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 283851,
    "name": "Roque    ( Leimen  Germany )",
    "country": "Germany"
  },
  {
    "id": 371485,
    "name": "Rosalind Kaska & Assoc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 462912,
    "name": "Rosco Laboratories Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 457851,
    "name": "Roscoe Medical Inc    ( Strongsville OH USA )",
    "country": "United States"
  },
  {
    "id": 160803,
    "name": "ROSE GmbH    ( Trier-Zewen  Germany )",
    "country": "Germany"
  },
  {
    "id": 106330,
    "name": "Rose Medical Inc    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 461120,
    "name": "Rosenbaum Fine Art    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 471706,
    "name": "Rosetta Genomics Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 359985,
    "name": "Roshel Marketing Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 462780,
    "name": "Roshi Corp    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 282979,
    "name": "Rosina Medizintechnik    ( Hiddenhausen  USA )",
    "country": "United States"
  },
  {
    "id": 354746,
    "name": "Ross Medical Equipment AB    ( Sollentuna  USA )",
    "country": "United States"
  },
  {
    "id": 426791,
    "name": "Ross Products International Inc    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 334009,
    "name": "Ross Systems Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 357184,
    "name": "Rossmax International Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 229006,
    "name": "Rosys Instruments AG    ( Hombrechtikon  USA )",
    "country": "United States"
  },
  {
    "id": 174972,
    "name": "Rota Systems Inc    ( Lawrence KS USA )",
    "country": "United States"
  },
  {
    "id": 152448,
    "name": "Rotal Hospitalar Industria e Comercio Ltda    ( Aparecida de Goiania-GO  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 372112,
    "name": "Rotec Medizintechnik GmbH    ( Weisendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 439754,
    "name": "Rotem GmbH    ( Leipzig  Germany )",
    "country": "Germany"
  },
  {
    "id": 439752,
    "name": "Rotem Inc    ( Topsfield MA USA )",
    "country": "United States"
  },
  {
    "id": 163004,
    "name": "Rotem Industries Ltd    ( Arava  Israel )",
    "country": "Israel"
  },
  {
    "id": 107529,
    "name": "Rotex Silver Recovery Co    ( Springfield OH USA )",
    "country": "United States"
  },
  {
    "id": 107458,
    "name": "Roth International Ltd    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 474855,
    "name": "Rothballer elektronic systems    ( Weiden  Germany )",
    "country": "Germany"
  },
  {
    "id": 471609,
    "name": "ROTTAPHARM MADAUS    ( Dublin 15  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 280168,
    "name": "Rotter GmbH und Co KG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 465223,
    "name": "Rotunda Scientific Technologies LLC    ( Stow 46 USA )",
    "country": "United States"
  },
  {
    "id": 139403,
    "name": "Rovers Medical Devices bv    ( Oss  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 462777,
    "name": "Rowny Systems Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 398832,
    "name": "Roxspur Measurement & Control Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 458676,
    "name": "Roy Matheson and Assoc Inc    ( Keene NH USA )",
    "country": "United States"
  },
  {
    "id": 462923,
    "name": "Roy Matheson and Assoc Inc    ( Keene NH USA )",
    "country": "United States"
  },
  {
    "id": 459241,
    "name": "Royal Dental    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 456194,
    "name": "Royal Fornia Medical Equipment Co Ltd    ( Zhuhai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 157039,
    "name": "Royal Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 403671,
    "name": "Royal Numico NV    ( Zoetermeer  USA )",
    "country": "United States"
  },
  {
    "id": 363932,
    "name": "Royal Philips Electronics NV    (   The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455217,
    "name": "Royal Spa Corp    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 457491,
    "name": "Royalty Pharma    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 171252,
    "name": "Royce Medical Products    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 329748,
    "name": "Royce Rolls Ringer Co    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 106903,
    "name": "Rozinn Electronics Inc    ( Glendale NY USA )",
    "country": "United States"
  },
  {
    "id": 360383,
    "name": "Rozinn.de Medizintechnik GmbH    ( Herten  Germany )",
    "country": "Germany"
  },
  {
    "id": 456853,
    "name": "RP Medical BV    ( Amersfoort  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 459494,
    "name": "RP Sign Systems    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 454467,
    "name": "RR Mechatronics USA LLC    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 454398,
    "name": "RS Medical Inc    ( Vancouver WA USA )",
    "country": "United States"
  },
  {
    "id": 192832,
    "name": "RS Trans Technology    ( Muskva  USA )",
    "country": "United States"
  },
  {
    "id": 460772,
    "name": "RSA Aveksa    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 461250,
    "name": "RSA Biomedical    ( Umea  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 461252,
    "name": "Rsam GRC Solutions    ( Secaucus NJ USA )",
    "country": "United States"
  },
  {
    "id": 285142,
    "name": "RSB-Dentalgeraete GmbH    ( Oberndorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 276788,
    "name": "RSI Video Conferencing    (   USA )",
    "country": "United States"
  },
  {
    "id": 381698,
    "name": "RSLSteeper    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 284933,
    "name": "RSM Mikromed Fachhandel und Service fuer    ( Neuruppin  Germany )",
    "country": "Germany"
  },
  {
    "id": 474860,
    "name": "RSscan International NV    ( Paal  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 138686,
    "name": "RSTI (Radiological Service Training Institute)    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 454352,
    "name": "RSW International Ltd    ( Rochdale  England )",
    "country": "UK"
  },
  {
    "id": 459416,
    "name": "RT Cotter and Assoc Inc    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 466629,
    "name": "RT London    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 999595,
    "name": "RTF Manufacturing    ( Hudson NY USA )",
    "country": "USA"
  },
  {
    "id": 459011,
    "name": "RTF Mfg    ( Hudson NY USA )",
    "country": "United States"
  },
  {
    "id": 454312,
    "name": "RTI Biologies    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 172285,
    "name": "RTI Electronics AB    ( Moelndal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451844,
    "name": "RTI Electronics Inc    ( Towaco NJ USA )",
    "country": "United States"
  },
  {
    "id": 452097,
    "name": "RTI Electronics Inc    ( Towaco NJ USA )",
    "country": "United States"
  },
  {
    "id": 170668,
    "name": "RTI Medical Systems Inc    ( Dothan AL USA )",
    "country": "United States"
  },
  {
    "id": 160806,
    "name": "Rubarth Apparate GmbH    ( Laatzen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456027,
    "name": "Rubberex Malaysia Sdn Bhd    ( Ipoh  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 454988,
    "name": "Rubbermaid Medical Solutions    ( Huntersville NC USA )",
    "country": "United States"
  },
  {
    "id": 285302,
    "name": "Ruberg-Mischtechnik KG    ( Paderborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 160805,
    "name": "Rudischhauser GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282248,
    "name": "Rudolf B Scholz    ( Haan  Germany )",
    "country": "Germany"
  },
  {
    "id": 283469,
    "name": "Rudolf Frei Praxis-Bedarf fuer Physikalische    ( Kirchzarten  Germany )",
    "country": "Germany"
  },
  {
    "id": 281811,
    "name": "Rudolf GmbH    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283195,
    "name": "Rudolf Hoppe GmbH    ( Isernhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 158827,
    "name": "Rudolf Kueffner    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 284506,
    "name": "Rudolf Reha Systeme    ( Moessingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283257,
    "name": "Rudolf Riester GmbH & Co KG    ( Jungingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287107,
    "name": "Rudolf Schiffel    ( Moerfelden  USA )",
    "country": "United States"
  },
  {
    "id": 278632,
    "name": "Rudolf Storz GmbH    ( Emmingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293885,
    "name": "Rudolf von Nordheim Inh W Reiss Laborbedarf    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 107459,
    "name": "Rudolph Instruments Inc    ( Denville NJ USA )",
    "country": "United States"
  },
  {
    "id": 459496,
    "name": "Rudolph Research Analytical    ( Hackettstown NJ USA )",
    "country": "United States"
  },
  {
    "id": 280222,
    "name": "Ruediger Anatomie-Verlag GmbH    ( Berlin/Spandau  Germany )",
    "country": "Germany"
  },
  {
    "id": 286490,
    "name": "Rueger GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 333709,
    "name": "Rueger SA    ( Crissier 1  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 286202,
    "name": "Ruettgers Surgicals Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 282164,
    "name": "Ruf Medizintechnik Med -Techn Geraetebau -    ( Griesheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 456793,
    "name": "Rugged Technologies    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 223020,
    "name": "Ruhof Corp    ( Mineola NY USA )",
    "country": "United States"
  },
  {
    "id": 284484,
    "name": "Ruhrtal Labor Technik    ( Moehnesee/Delecke  Germany )",
    "country": "Germany"
  },
  {
    "id": 435452,
    "name": "Rultract Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 456836,
    "name": "Rumex International Co    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 461254,
    "name": "RURO Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 101657,
    "name": "Rusch Inc A Teleflex Medical Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 158509,
    "name": "Rusch UK    ( High Wycombe  USA )",
    "country": "United States"
  },
  {
    "id": 177912,
    "name": "Rusche & Assoc    ( Greenwood SC USA )",
    "country": "United States"
  },
  {
    "id": 104940,
    "name": "Rush/berivon Inc    ( Meridian MS USA )",
    "country": "United States"
  },
  {
    "id": 416001,
    "name": "Rushabh Instruments LLC    ( Warrington PA USA )",
    "country": "United States"
  },
  {
    "id": 459302,
    "name": "Ruskinn Technology    ( Pencoed  Wales )",
    "country": "Wales"
  },
  {
    "id": 459009,
    "name": "Russelectric Inc    ( Hingham MA USA )",
    "country": "United States"
  },
  {
    "id": 451574,
    "name": "RV Industries Inc    ( Honey Brook PA USA )",
    "country": "United States"
  },
  {
    "id": 455502,
    "name": "RWC Testing & Lab Supplies    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 462658,
    "name": "RX Clinical Consulting Constortium Ltd    ( Miami Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 104315,
    "name": "RX Count Corp    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 104532,
    "name": "Rx Honing Machine Corp    ( Mishawaka IN USA )",
    "country": "United States"
  },
  {
    "id": 342422,
    "name": "Rx Limitada    ( Santa Fe de Bogota  USA )",
    "country": "United States"
  },
  {
    "id": 108303,
    "name": "Rx Rocker Corp    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 335241,
    "name": "Rx Systems Inc    ( St Charles MO USA )",
    "country": "United States"
  },
  {
    "id": 391202,
    "name": "Rx Textiles Inc    ( Monroe NC USA )",
    "country": "United States"
  },
  {
    "id": 459417,
    "name": "RXinnovate Consulting LLC    ( Bethany CT USA )",
    "country": "United States"
  },
  {
    "id": 459084,
    "name": "RxScan    ( Powell OH USA )",
    "country": "United States"
  },
  {
    "id": 454204,
    "name": "Ryazan State Instrument-Making Enterprise    ( Ryazan  Russia )",
    "country": "Russia"
  },
  {
    "id": 450561,
    "name": "Rycor Medical Inc    ( North Port FL USA )",
    "country": "United States"
  },
  {
    "id": 175748,
    "name": "Rye Pharmaceuticals LLC    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 339023,
    "name": "Rye Pharmaceuticals Pty Ltd    ( Roseville  Australia )",
    "country": "Australia"
  },
  {
    "id": 451890,
    "name": "Rymed Technologies Inc    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 331215,
    "name": "Rynel Inc    ( Wiscasset ME USA )",
    "country": "United States"
  },
  {
    "id": 451343,
    "name": "Ryzex Group - Australia    ( Canning Vale  Australia )",
    "country": "Australia"
  },
  {
    "id": 451341,
    "name": "Ryzex Group - Canada    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 451342,
    "name": "Ryzex Group - Canada    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451329,
    "name": "Ryzex Group - Europe    ( Chippenham  England )",
    "country": "UK"
  },
  {
    "id": 451344,
    "name": "Ryzex Group - France    ( Ecully  France )",
    "country": "France"
  },
  {
    "id": 356764,
    "name": "Ryzex Group - USA    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 283837,
    "name": "S & F Datentechnik GmbH & Co KG    ( Leer  Germany )",
    "country": "Germany"
  },
  {
    "id": 103546,
    "name": "S & G Enterprises Inc    ( Germantown WI USA )",
    "country": "United States"
  },
  {
    "id": 280826,
    "name": "S & G Implants GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 174973,
    "name": "S & L Optics    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 282630,
    "name": "S & N Richards Medizintechnik GmbH    ( Hamburg-Schenefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 392161,
    "name": "S & S Medcart    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 372844,
    "name": "S & S Technology    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 101689,
    "name": "S & S X-Ray Products Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 103882,
    "name": "S & W by Hausmann Industries    ( Northvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 161033,
    "name": "S & W Elektromedizin GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 348929,
    "name": "S & W Healthcare    ( Brooksville FL USA )",
    "country": "United States"
  },
  {
    "id": 332073,
    "name": "S & W Vickers Ltd Sp z o o    ( Bialystok  Poland )",
    "country": "Poland"
  },
  {
    "id": 340222,
    "name": "S C Foster    ( New Castle DE USA )",
    "country": "United States"
  },
  {
    "id": 287254,
    "name": "S E Nuendel Kunststoff-Technik GmbH    ( Wendelstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 101380,
    "name": "S Jackson Inc    ( Alexandria VA USA )",
    "country": "United States"
  },
  {
    "id": 152416,
    "name": "S Kimura Medical Instrument Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452128,
    "name": "S R Data Processing    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 105498,
    "name": "S S White Burs Inc    ( Lakewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 281886,
    "name": "S Siedle & Soehne Stiftung & Co Telefon und    ( Furtwangen  USA )",
    "country": "United States"
  },
  {
    "id": 173959,
    "name": "S Teasdale Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 999626,
    "name": "S. S. White Dental Mfg. Co.    (   USA )",
    "country": "United States"
  },
  {
    "id": 461257,
    "name": "S&P Robotics Inc    ( North York  Canada )",
    "country": "Canada"
  },
  {
    "id": 466246,
    "name": "S3 Integration    ( Baltimore 15 USA )",
    "country": "United States"
  },
  {
    "id": 457890,
    "name": "S4OPTIK    ( Virginia Beach VA USA )",
    "country": "United States"
  },
  {
    "id": 457575,
    "name": "S4S (UK) Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 461118,
    "name": "SA Ignite    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 307455,
    "name": "SA Incinerator Co (pty) Ltd    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 399758,
    "name": "SA Scale Co (Pty) Ltd    ( Benoni  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 337788,
    "name": "SA Scientific Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 402705,
    "name": "SA Vygon NV    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 306345,
    "name": "Saalmann GmbH    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 338538,
    "name": "SAAT Ltd    ( Petach Tikva  USA )",
    "country": "United States"
  },
  {
    "id": 284093,
    "name": "Sabema Schuhhandel GmbH    ( Ludwigswinkel  Germany )",
    "country": "Germany"
  },
  {
    "id": 456395,
    "name": "Sabra Dental Products    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 999996,
    "name": "Sabra Dental Products    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 150968,
    "name": "Sabre Medical-Scott International Ltd    ( Skelmersdale  England )",
    "country": "UK"
  },
  {
    "id": 455527,
    "name": "Sacace Biotechnologies srl    ( Como (CO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 287213,
    "name": "Sachsenfango GmbH    ( Weinboehla  Germany )",
    "country": "Germany"
  },
  {
    "id": 226622,
    "name": "Sacon AG    ( Affoltern aA  USA )",
    "country": "United States"
  },
  {
    "id": 287414,
    "name": "Sacon Deutschland GmbH    ( Wiesloch  Germany )",
    "country": "Germany"
  },
  {
    "id": 299814,
    "name": "Sacor Inc    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 242656,
    "name": "Sacre Enterprises Pty    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 451806,
    "name": "SAD Lightbox Co    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 459500,
    "name": "Saebo Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 999421,
    "name": "Saebo, Inc.    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 457203,
    "name": "Saehanul Biotech Corp    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 174132,
    "name": "Saeki Seisakusho    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999743,
    "name": "Saeshin    (   USA )",
    "country": "United States"
  },
  {
    "id": 173376,
    "name": "Saf-T-Pak Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 461416,
    "name": "Safari Books Online LLC    ( Sebastopol CA USA )",
    "country": "United States"
  },
  {
    "id": 337037,
    "name": "Safco Dental Supply Co    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 431598,
    "name": "Safco Products    ( New Hope MN USA )",
    "country": "United States"
  },
  {
    "id": 455253,
    "name": "Safe Home Products    ( Iowa City IA USA )",
    "country": "United States"
  },
  {
    "id": 452233,
    "name": "Safe Path Products    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 363524,
    "name": "Safecross First Aid Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 398883,
    "name": "Safeguard Medical Technologies LLC    ( Berlin Center OH USA )",
    "country": "United States"
  },
  {
    "id": 458108,
    "name": "safeHands Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 151011,
    "name": "Safelab Systems    ( Weston-super-Mare  England )",
    "country": "UK"
  },
  {
    "id": 160818,
    "name": "Safelab Systems Deutschland    ( Reutlingen 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 466744,
    "name": "Safepole LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 462599,
    "name": "Safer Sleep LLC    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 175944,
    "name": "Safetec of America Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 186329,
    "name": "Safety Anesthesia Equipment Service    ( Floral Park NY USA )",
    "country": "United States"
  },
  {
    "id": 451426,
    "name": "Safety Bath Inc    ( Ituna SK Canada )",
    "country": "Canada"
  },
  {
    "id": 278313,
    "name": "Safety Co Ltd    ( Saitama  USA )",
    "country": "United States"
  },
  {
    "id": 409903,
    "name": "Safety Equipment America Inc    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 409901,
    "name": "Safety Equipment Australia Pty Ltd    ( Warriewood  Australia )",
    "country": "Australia"
  },
  {
    "id": 456984,
    "name": "Safety Glasses USA Inc    ( Three Rivers MI USA )",
    "country": "United States"
  },
  {
    "id": 467372,
    "name": "Safety Restraint Chair Inc    ( Johnston IN USA )",
    "country": "United States"
  },
  {
    "id": 333602,
    "name": "Safety Syringes Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 441009,
    "name": "Safety Tech International Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 295730,
    "name": "Safety-Kleen Corp    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 452606,
    "name": "Safety-Med Products Inc    ( Burlington WI USA )",
    "country": "United States"
  },
  {
    "id": 431521,
    "name": "SafetyPlus Products Inc    ( McFarland WI USA )",
    "country": "United States"
  },
  {
    "id": 293372,
    "name": "Safident SA    ( Gland  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 281639,
    "name": "Safident Vertrieb Deutschland    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 307466,
    "name": "Safmed Pty Ltd    ( Edenvale  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 278314,
    "name": "Sagami Rubber Industries Co Ltd    ( Atsugi  Japan )",
    "country": "Japan"
  },
  {
    "id": 262103,
    "name": "Sagatech Electronics Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 461353,
    "name": "Sage    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 453462,
    "name": "Sage Healthcare Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 454605,
    "name": "Sage Japan Inc    ( Yakohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 306091,
    "name": "Sage Medical Pty Ltd    ( Rowville  USA )",
    "country": "United States"
  },
  {
    "id": 291958,
    "name": "Sage Pharmaceuticals Inc    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 103635,
    "name": "Sage Products Inc    ( Cary IL USA )",
    "country": "United States"
  },
  {
    "id": 460729,
    "name": "Sage Science Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 457649,
    "name": "Sage Services Group    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 103072,
    "name": "Sage Systems    ( Travelers Rest SC USA )",
    "country": "United States"
  },
  {
    "id": 466586,
    "name": "Sage Technology Solutions Inc    ( Mount Joy PA USA )",
    "country": "United States"
  },
  {
    "id": 263810,
    "name": "Saime SA    ( Savigny-le-Temple  France )",
    "country": "France"
  },
  {
    "id": 456072,
    "name": "Saint Come Chirurgie    ( Marseille  France )",
    "country": "France"
  },
  {
    "id": 466292,
    "name": "Saint Paul Health Care PT Inc    ( Garner NC USA )",
    "country": "United States"
  },
  {
    "id": 376043,
    "name": "Saint-Gobain Performance Plastics    ( Portage WI USA )",
    "country": "United States"
  },
  {
    "id": 283999,
    "name": "Seitz & Haag GmbH    ( Linden  Germany )",
    "country": "Germany"
  },
  {
    "id": 337869,
    "name": "Saint-Gobain Performance Plastics Gessil    ( Saint-Quentin-Fallavier  France )",
    "country": "France"
  },
  {
    "id": 457960,
    "name": "SaIRAN Medical Equipment    ( Isfahan  Iran )",
    "country": "Iran"
  },
  {
    "id": 454404,
    "name": "SAJ Distributors    ( Pine Bluff AR USA )",
    "country": "United States"
  },
  {
    "id": 461260,
    "name": "SAJE Technology Inc    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 158004,
    "name": "Sakai Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452843,
    "name": "Sakar International Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 170947,
    "name": "Sakuma Seisakusho Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 157040,
    "name": "Sakura Finetechnical Japan Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 172160,
    "name": "Sakura Finetek Europe bv    ( Alphen aan den Rijn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 156404,
    "name": "Sakura Finetek USA Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 455984,
    "name": "Sakura International Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 399233,
    "name": "SAL Pharma    ( Grantham  USA )",
    "country": "United States"
  },
  {
    "id": 460655,
    "name": "Salam International Inc    ( Laguna Niguel CA USA )",
    "country": "United States"
  },
  {
    "id": 460987,
    "name": "Salar Inc    ( Timonium MD USA )",
    "country": "United States"
  },
  {
    "id": 454272,
    "name": "Salary.com    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 453070,
    "name": "Salient Surgical Technologies Inc    ( Portsmouth NH USA )",
    "country": "United States"
  },
  {
    "id": 456077,
    "name": "Salim Group Industries    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 416255,
    "name": "Saliva Diagnostic Systems Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 999507,
    "name": "Salon Tech    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 105792,
    "name": "Salsbury Industries    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 999885,
    "name": "Salter Brecknell    ( Fairmont MA USA )",
    "country": "United States"
  },
  {
    "id": 455545,
    "name": "Salter Brecknell Canada    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455544,
    "name": "Salter Brecknell USA    ( Fairmont MN USA )",
    "country": "United States"
  },
  {
    "id": 103178,
    "name": "Salter Labs    ( Arvin CA USA )",
    "country": "United States"
  },
  {
    "id": 372980,
    "name": "Saltillo Corp    ( Millersburg OH USA )",
    "country": "United States"
  },
  {
    "id": 393135,
    "name": "Salts Healthcare    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 462583,
    "name": "Salumatics    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108824,
    "name": "Salvage Masters Inc    ( Upperville VA USA )",
    "country": "United States"
  },
  {
    "id": 451788,
    "name": "Salvin Dental Specialties Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 454097,
    "name": "Salyer Biomedical LLC    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 325590,
    "name": "Salzgitter Morex Enterprises Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 233275,
    "name": "Salzmann AG    ( St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451616,
    "name": "SAM Medical Products    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 293735,
    "name": "Samarit Medizintechnik AG    ( Zumikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 170670,
    "name": "Samaritan Medical Equipment Services    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 294369,
    "name": "Samco Silicone Products Ltd    ( Nuneaton  England )",
    "country": "UK"
  },
  {
    "id": 187040,
    "name": "Samed Elettromedicali srl    ( Merlino (LO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 299680,
    "name": "Samhall AB    (   USA )",
    "country": "United States"
  },
  {
    "id": 139720,
    "name": "Samhall Rehab AB    ( Malmo  USA )",
    "country": "United States"
  },
  {
    "id": 428598,
    "name": "Sammons Preston Rolyan    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 428599,
    "name": "Sammons Preston Rolyan Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454529,
    "name": "Samson Technologies Corp    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 999971,
    "name": "SAMSUNG    (   USA )",
    "country": "United States"
  },
  {
    "id": 456325,
    "name": "SAMSUNG Electronics America    ( Ridgefield NJ USA )",
    "country": "United States"
  },
  {
    "id": 457654,
    "name": "Samsung Electronics America Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 456116,
    "name": "Samsung Medical Rubber Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456000,
    "name": "Samsung Medison America Inc    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 457693,
    "name": "Samsung Medison Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457653,
    "name": "Samsung Medison Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457692,
    "name": "Samsung Medison India Pvt Ltd    ( Dehli  India )",
    "country": "India"
  },
  {
    "id": 174419,
    "name": "Samtronic Industria e Comercio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 176950,
    "name": "Samwell Testing Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456115,
    "name": "Samyang Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454158,
    "name": "San Antonio Eye Bank    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 456787,
    "name": "San Diego Eye Bank    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 459012,
    "name": "San Diego Instruments Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 394996,
    "name": "San Up SA    ( San Martin  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 458341,
    "name": "San-I-Pak Inc    ( Tracy CA USA )",
    "country": "United States"
  },
  {
    "id": 426895,
    "name": "Sanarus Medical Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 287858,
    "name": "Sanasol Kft    ( Zalaegerszeg  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 291960,
    "name": "Sanax Protective Products Inc    ( Newton Highlands MA USA )",
    "country": "United States"
  },
  {
    "id": 456114,
    "name": "Sancare Health Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999720,
    "name": "Sand-Rite Manufacturing Co.    ( Chicago IL USA )",
    "country": "USA"
  },
  {
    "id": 108820,
    "name": "Sandata Technologies Inc    ( Port Washington NY USA )",
    "country": "United States"
  },
  {
    "id": 452604,
    "name": "Sandel Medical Industries LLC    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 283457,
    "name": "Sander & Co Handels GmbH    ( Kirchheim unter Teck  Germany )",
    "country": "Germany"
  },
  {
    "id": 461262,
    "name": "Sanders Medical Products Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 454173,
    "name": "Sandhill Scientific - UK Ltd    ( Whitney  England )",
    "country": "UK"
  },
  {
    "id": 104327,
    "name": "Sandhill Scientific Inc    ( Highlands Ranch CO USA )",
    "country": "United States"
  },
  {
    "id": 453463,
    "name": "Sandoz Canada Inc    ( Boucherville PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 458633,
    "name": "Sandoz Inc    ( Princeton NJ USA )",
    "country": "United States"
  },
  {
    "id": 174727,
    "name": "Sandstrom Trade & Technology Inc    ( Welland ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459003,
    "name": "Sandusky Lee Corp    ( Sandusky OH USA )",
    "country": "United States"
  },
  {
    "id": 337404,
    "name": "Saner Software Inc    ( St Charles IL USA )",
    "country": "United States"
  },
  {
    "id": 461266,
    "name": "Sanexas International GmbH    ( Blaustein  Germany )",
    "country": "Germany"
  },
  {
    "id": 353391,
    "name": "Sanguin International Inc    ( Hamden CT USA )",
    "country": "United States"
  },
  {
    "id": 354214,
    "name": "Sanguin International Ltd    ( Barton-under-Needwood  England )",
    "country": "UK"
  },
  {
    "id": 102942,
    "name": "Sani-Med    ( Columbus MS USA )",
    "country": "United States"
  },
  {
    "id": 416606,
    "name": "Sanico srl    ( Cusago MI  USA )",
    "country": "United States"
  },
  {
    "id": 160820,
    "name": "Sanipharm    ( Saint-Jean-de-Braye  France )",
    "country": "France"
  },
  {
    "id": 108129,
    "name": "SaniServe    (   USA )",
    "country": "United States"
  },
  {
    "id": 160821,
    "name": "Sanitaetshaus Flentje Inh Ilse Flentje    ( Lingen/Ems  USA )",
    "country": "United States"
  },
  {
    "id": 282945,
    "name": "Sanitaetshaus Rudolf Laufer GmbH    ( Herne  Germany )",
    "country": "Germany"
  },
  {
    "id": 281970,
    "name": "Sanitaetshaus Selzer GmbH    ( Gelsenkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 177707,
    "name": "Sanitaria Scaligera Spa    ( Verona (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457751,
    "name": "Sanitech Australia    ( Camberwell  Australia )",
    "country": "Australia"
  },
  {
    "id": 152423,
    "name": "Sankei Co Ltd    ( Ichihara  Japan )",
    "country": "Japan"
  },
  {
    "id": 174133,
    "name": "Sanko X-Ray Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 174134,
    "name": "Sankyo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450862,
    "name": "Sanmina SCI Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 451563,
    "name": "Sanofi-aventis US LLC    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 441121,
    "name": "Sanofi-Synthelabo Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 282053,
    "name": "Sanpack-Gesellschaft Lohss Co (GmbH & Co )    ( Glinde  Germany )",
    "country": "Germany"
  },
  {
    "id": 150002,
    "name": "Sans Pareil Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 293152,
    "name": "Sanseg Trading AG    ( Abtwil St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999468,
    "name": "Santa Medical    ( Tustin CA USA )",
    "country": "USA"
  },
  {
    "id": 457274,
    "name": "Santec Medicalprodukte GmbH    ( Grosswallstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 384272,
    "name": "Santech Holdings (Pty) Ltd    ( Steenberg  USA )",
    "country": "United States"
  },
  {
    "id": 174976,
    "name": "Santinelli International Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 458692,
    "name": "Sanuwave Health Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 187788,
    "name": "Sanwa Kagaku Kenkyusho Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 456489,
    "name": "Sanwe Medical Equipment Co Ltd    ( Xuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451283,
    "name": "SANYO E&E Europe bv    ( Etten-Leur  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451282,
    "name": "SANYO E&E Europe bv (United Kingdom)    ( Loughborough  England )",
    "country": "UK"
  },
  {
    "id": 450858,
    "name": "SANYO Electric Co Ltd    (   USA )",
    "country": "United States"
  },
  {
    "id": 105748,
    "name": "Sanyo Fisher Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 399136,
    "name": "SANYO Sales & Marketing Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 171737,
    "name": "SANYO Scientific North America    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 461453,
    "name": "SAP AG    ( Walldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 461458,
    "name": "SAP America Inc    ( Newton Square PA USA )",
    "country": "United States"
  },
  {
    "id": 454646,
    "name": "Sapheneia Global LLC (Sweden)    ( Linkoping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 454644,
    "name": "Sapheneia Global LLC (USA)    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 391300,
    "name": "Saphirwerk Industrieprodukte AG    ( Bruegg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457390,
    "name": "Saphymo GmbH    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 454850,
    "name": "Sapi Med SpA    ( Alessandria (AL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 175904,
    "name": "Saratoga Medical Div OvaMed Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 103073,
    "name": "Sargent Co Inc    ( Cedar Rapids IA USA )",
    "country": "United States"
  },
  {
    "id": 453758,
    "name": "Saringer Life Science Technologies Inc    ( Stouffville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458110,
    "name": "Sarken Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 457370,
    "name": "Sarossy Labortechnik    ( Reutlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293589,
    "name": "Sarstedt AG    ( Sevelen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 150027,
    "name": "Sarstedt AG & Co    ( Nuembrecht  Germany )",
    "country": "Germany"
  },
  {
    "id": 306940,
    "name": "Sarstedt Australia Pty Ltd    ( Ingle Farm  Australia )",
    "country": "Australia"
  },
  {
    "id": 101949,
    "name": "Sarstedt Inc    ( Newton NC USA )",
    "country": "United States"
  },
  {
    "id": 403421,
    "name": "Sartorius (Malaysia) Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 140787,
    "name": "Sartorius AG    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 403420,
    "name": "Sartorius AG Beijing    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 439960,
    "name": "Sartorius BBI Systems GmbH    ( Melsungen  USA )",
    "country": "United States"
  },
  {
    "id": 439959,
    "name": "Sartorius BBI Systems Inc    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 107660,
    "name": "Sartorius Corp    ( Edgewood NY USA )",
    "country": "United States"
  },
  {
    "id": 452359,
    "name": "Sartorius Stedim Biotech GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452358,
    "name": "Sartorius Stedim Biotech SA    ( Aubagne Cedex  France )",
    "country": "France"
  },
  {
    "id": 452357,
    "name": "Sartorius Stedim SUS USA    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 458423,
    "name": "SATO America Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 449838,
    "name": "Saturn Biomedical Systems Inc    ( Burnaby BC USA )",
    "country": "United States"
  },
  {
    "id": 282144,
    "name": "Saunalux GmbH Products & Co KG    ( Grebenhain  Germany )",
    "country": "Germany"
  },
  {
    "id": 186709,
    "name": "Sauter AG    ( Vaduz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 283623,
    "name": "Sauter Moller GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 456559,
    "name": "Savage Laboratories    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 273276,
    "name": "Savant Medical Supply    ( Forest Park IL USA )",
    "country": "United States"
  },
  {
    "id": 451399,
    "name": "Savaria Concord    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454348,
    "name": "Savaria Concord Lifts Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 172841,
    "name": "Save 33 Electronique Medicale    ( Bruay sur Escaut  France )",
    "country": "France"
  },
  {
    "id": 441360,
    "name": "Savient Pharmaceuticals Inc    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 466430,
    "name": "Saving Sight    ( St Ann MO USA )",
    "country": "United States"
  },
  {
    "id": 163006,
    "name": "Savion Industries 1987 Ltd    ( Ashdod  Israel )",
    "country": "Israel"
  },
  {
    "id": 322460,
    "name": "Savyon Diagnostics Ltd    ( Ashdod  Israel )",
    "country": "Israel"
  },
  {
    "id": 372017,
    "name": "Sawbones Europe AB    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 354508,
    "name": "SBL Technologies Pty Ltd    ( Hornsby  USA )",
    "country": "United States"
  },
  {
    "id": 457539,
    "name": "SBM Science & BioMaterials    ( Lourdes  France )",
    "country": "France"
  },
  {
    "id": 457325,
    "name": "SC-Etiketten GmbH    ( Metjendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 285237,
    "name": "SC-System-Etiketten GmbH    ( Oldenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 401053,
    "name": "SCA Hygiene Products GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458965,
    "name": "Scala Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 101694,
    "name": "Scale-Tronix Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 459471,
    "name": "Scan Am Co    ( Algonquin IL USA )",
    "country": "United States"
  },
  {
    "id": 153256,
    "name": "Scan Medical Ltd    ( Twickenham  USA )",
    "country": "United States"
  },
  {
    "id": 455252,
    "name": "Scan Mobility Ltd    ( Banks  England )",
    "country": "UK"
  },
  {
    "id": 306224,
    "name": "Scan Optics Pty Ltd    ( Adelaide  Australia )",
    "country": "Australia"
  },
  {
    "id": 458805,
    "name": "Scan Sound Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 332045,
    "name": "Scanatron AG    ( Affoltern aA  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 306208,
    "name": "Scanco Medical AG    ( Bruettisellen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 460528,
    "name": "ScandiDos Inc    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 330947,
    "name": "ScandiMed AB    ( Sjobo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 183979,
    "name": "Scanditronix SA    ( Meyrin-Geneve  USA )",
    "country": "United States"
  },
  {
    "id": 406409,
    "name": "Scanditronix Wellhoefer    ( Schwarzenbruck  Germany )",
    "country": "Germany"
  },
  {
    "id": 140107,
    "name": "Scanditronix Wellhofer AB    ( Uppsala  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 395354,
    "name": "Scanditronix Wellhofer North America    ( Bartlett TN USA )",
    "country": "United States"
  },
  {
    "id": 452235,
    "name": "Scandius BioMedical Inc    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 232378,
    "name": "Scandmed AB    ( Djursholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455616,
    "name": "Scanflex Ltd    ( Bromborough  England )",
    "country": "UK"
  },
  {
    "id": 229196,
    "name": "Scanflex Medical AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 332206,
    "name": "SCANIS Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 339531,
    "name": "Scanlan Group bv    ( Schiphol-Triport  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103140,
    "name": "Scanlan International Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 340206,
    "name": "ScanMed of Medic Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 375033,
    "name": "Scantek Medical Inc    ( Cedar Knolls NJ USA )",
    "country": "United States"
  },
  {
    "id": 453444,
    "name": "Scantron Corp    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 282729,
    "name": "Scanvest Ring GmbH    ( Langenhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457241,
    "name": "Scanworks Medical Inc    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 457849,
    "name": "Scapa Group plc    ( Ashton-Under-Lyne  England )",
    "country": "UK"
  },
  {
    "id": 457850,
    "name": "Scapa North America    ( Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 394473,
    "name": "SCC Soft Computer    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 451515,
    "name": "ScerIS Inc    ( Sudbury MA USA )",
    "country": "United States"
  },
  {
    "id": 455989,
    "name": "SCETI KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 237817,
    "name": "SCG Ultraviolet Supplies    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 202158,
    "name": "Schad Medical Instruments GmbH    ( Neuhausen ob Eck  Germany )",
    "country": "Germany"
  },
  {
    "id": 306601,
    "name": "Schaefer Ausstattungs-Systeme GmbH    ( Betzdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 391440,
    "name": "Schaefer Micomed GmbH    ( Schorndorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 391315,
    "name": "Schaefer Water Centers    ( Herculaneum MO USA )",
    "country": "United States"
  },
  {
    "id": 441511,
    "name": "Schaerer Mayfield (Czech Republic)    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 441510,
    "name": "Schaerer Mayfield (Russia)    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 441509,
    "name": "Schaerer Mayfield France SA    ( Venissieux  France )",
    "country": "France"
  },
  {
    "id": 441508,
    "name": "Schaerer Mayfield Schweiz AG    ( Muensingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 441507,
    "name": "Schaerer Mayfield USA Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 457417,
    "name": "Schaerer Medical AG    ( Muensingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 284563,
    "name": "Schaftstall Warenhandelsges mbH    ( Muelheim am Ruhr  Germany )",
    "country": "Germany"
  },
  {
    "id": 279678,
    "name": "Schambeck SFD GmbH    ( Bad Honnef  Germany )",
    "country": "Germany"
  },
  {
    "id": 293897,
    "name": "Scheco Holding GmbH    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285686,
    "name": "Schein Orthopaedie Service KG    ( Remscheid  Germany )",
    "country": "Germany"
  },
  {
    "id": 460765,
    "name": "Schellers Fitness and Cycling    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 399742,
    "name": "Schember Berkel Ges mbH    ( Wiener Neudorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 287117,
    "name": "Schenk-Filterbau GmbH    ( Waldstetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 150264,
    "name": "Schering AG    ( Berlin 65  USA )",
    "country": "United States"
  },
  {
    "id": 452281,
    "name": "Schering Bayer Pharma AG    ( Berlin 65  Germany )",
    "country": "Germany"
  },
  {
    "id": 435615,
    "name": "Schering Oy    ( Turku  USA )",
    "country": "United States"
  },
  {
    "id": 105514,
    "name": "Schering-Plough Corp    ( Kenilworth NJ USA )",
    "country": "United States"
  },
  {
    "id": 108509,
    "name": "Schering-Plough Healthcare Products    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 283189,
    "name": "Scheu-Dental    ( Iserlohn  Germany )",
    "country": "Germany"
  },
  {
    "id": 328759,
    "name": "Schick Technologies Inc    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 293889,
    "name": "Schico GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280988,
    "name": "Schilder Maeurers GmbH    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451402,
    "name": "Schiller Africa    ( Broederstroom  USA )",
    "country": "United States"
  },
  {
    "id": 162079,
    "name": "Schiller AG    ( Baar  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439633,
    "name": "Schiller AG    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 108674,
    "name": "SCHILLER America Inc    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 330130,
    "name": "Schiller Asia Pacific    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 451404,
    "name": "Schiller Asia Pacific    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451403,
    "name": "Schiller Australia Pty Ltd    ( Belmont  Australia )",
    "country": "Australia"
  },
  {
    "id": 439632,
    "name": "Schiller Espana SA    ( Las Rozas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 171773,
    "name": "SCHILLER Handelsgesellschaft mbH    ( Linz  Austria )",
    "country": "Austria"
  },
  {
    "id": 234404,
    "name": "SCHILLER Healthcare India Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 451405,
    "name": "Schiller Japan Ltd    ( Hiroshima  Japan )",
    "country": "Japan"
  },
  {
    "id": 401027,
    "name": "Schiller Medical SAS    ( Wissembourg  France )",
    "country": "France"
  },
  {
    "id": 160957,
    "name": "SCHILLER Medizintechnik GmbH    ( Ottobrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 439631,
    "name": "SCHILLER Turkiye    ( Okmeydani-Siskli-Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 439886,
    "name": "Schiller-Reomed AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 285712,
    "name": "Schilling Medizintechnik GmbH    ( Renquishausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459270,
    "name": "Schindler Elevator Corp    ( Morristown NJ USA )",
    "country": "United States"
  },
  {
    "id": 171768,
    "name": "Schinko-Neuroth GmbH    ( Wolfsberg  Austria )",
    "country": "Austria"
  },
  {
    "id": 160958,
    "name": "Schippers-Medizintechnik    ( Puchheim bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282045,
    "name": "Schiwa GmbH    ( Glandorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 457353,
    "name": "Schlegel bvba    ( Gistel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 284338,
    "name": "Schlosser & Co GmbH    ( Markt Indersdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 457270,
    "name": "SCHMIDT BioMedTech    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 336058,
    "name": "Schmidt Scientific    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 295732,
    "name": "Schmidt Scientific Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 283038,
    "name": "Schmidt-Lola-GmbH    ( Hohenlockstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 458137,
    "name": "Schmitz (Guangzhou) Trading Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 293422,
    "name": "Schmitz AG    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 336386,
    "name": "Schmitz France SAS    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 457730,
    "name": "Schmitz Italia srl    ( Ovada (AL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458136,
    "name": "Schmitz und Soehne Dubai    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 160960,
    "name": "Schmitz und Soehne GmbH & Co KG    ( Wickede (Ruhr)  Germany )",
    "country": "Germany"
  },
  {
    "id": 293723,
    "name": "Schmizo AG Labor- und Wassertechnik    ( Zofingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 159379,
    "name": "Schneider & Piecha GmbH    ( Offenbach am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 453356,
    "name": "Schneider Electric SA    ( Rueil-Malmaison  France )",
    "country": "France"
  },
  {
    "id": 453355,
    "name": "Schneider Electric United States    ( Palatine IL USA )",
    "country": "United States"
  },
  {
    "id": 281497,
    "name": "Schneiderfilz Schneider GmbH & Co KG    ( Ettlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 132471,
    "name": "Schoch Electronics AG    ( Regensdorf/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 280788,
    "name": "Schoelly Fiberoptic GmbH    ( Denzlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283524,
    "name": "Schoene & Cie Nachf    ( Konstanz  USA )",
    "country": "United States"
  },
  {
    "id": 293284,
    "name": "Schoettli AG    ( Diessenhofen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 282567,
    "name": "Scholz Mechanik GmbH    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 293446,
    "name": "Scholzen Microbiology Systems AG    ( Beromuenster  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454174,
    "name": "School Kids Healthcare    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 462849,
    "name": "School Outfitters    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 283030,
    "name": "Schott AG    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 150176,
    "name": "Schott Glass    ( Mainz 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 103547,
    "name": "Schott North America Inc    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 371976,
    "name": "Schott North America Inc    ( Auburn NY USA )",
    "country": "United States"
  },
  {
    "id": 279858,
    "name": "Schott-Rohrglas GmbH    ( Bayreuth  Germany )",
    "country": "Germany"
  },
  {
    "id": 457894,
    "name": "Schouten Dental    ( Mijnsheerenland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 309643,
    "name": "Schouten Group bv    ( Mijnsheerenland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457895,
    "name": "Schouten SynTec    ( Mijnsheerenland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 999773,
    "name": "Schrader Electronics Ltd    ( Antrim Ireland  USA )",
    "country": "United States"
  },
  {
    "id": 282997,
    "name": "Schramm Rollstuhlboy GmbH    ( Hildesheim  USA )",
    "country": "United States"
  },
  {
    "id": 159380,
    "name": "Schreiber GmbH    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451848,
    "name": "Schreiber Instrumentos    ( Rosario  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 101698,
    "name": "Schuco Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 285017,
    "name": "Schuelke & Mayr GmbH    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284815,
    "name": "Schuer Hygienesystematik    ( Nettetal  USA )",
    "country": "United States"
  },
  {
    "id": 306606,
    "name": "Schuerr Schuhvertrieb GmbH    ( Schwarzenbach an der Saale  Germany )",
    "country": "Germany"
  },
  {
    "id": 416019,
    "name": "Schuetz-Dental GmbH    ( Rosbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 286015,
    "name": "Schuhfabrik Erich Rohde KG    ( Schwalmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 287211,
    "name": "Schuhfabrik Fritz & Albert Brumm GmbH & Co KG    ( Weilmuenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 283415,
    "name": "Schuhfabrik Theodor Bergmann GmbH    ( Kevelaer  Germany )",
    "country": "Germany"
  },
  {
    "id": 286935,
    "name": "Schuler-Dental Dentalfabrikation    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 284201,
    "name": "Schulte-Derne GmbH    ( Magdeburg  USA )",
    "country": "United States"
  },
  {
    "id": 157042,
    "name": "Schulte-Elektronik GmbH    ( Olsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282959,
    "name": "Schultes Medacta GmbH & Co Lehrmodelle KG    ( Herten  Germany )",
    "country": "Germany"
  },
  {
    "id": 306404,
    "name": "Schulz Lufttechnik GmbH    ( Sprockhoevel  Germany )",
    "country": "Germany"
  },
  {
    "id": 284129,
    "name": "Schulz OHG    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 279384,
    "name": "Schulz-Dental Spezialprodukte    ( Aalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 280756,
    "name": "Schumacher & Partner GmbH    ( Dausenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 283702,
    "name": "Schumacher GmbH & Co KG    ( Krefeld  USA )",
    "country": "United States"
  },
  {
    "id": 160799,
    "name": "Schupp GmbH & Co    ( Freudenstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 459185,
    "name": "SchureMed    ( Abington MA USA )",
    "country": "United States"
  },
  {
    "id": 999405,
    "name": "SchureMed    ( Abington ma USA )",
    "country": "United States"
  },
  {
    "id": 456962,
    "name": "SCHUTZ Dental Group    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 243403,
    "name": "Schuyler House    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 281694,
    "name": "Schwa-Medico GmbH    ( Ehringshausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285146,
    "name": "Schwarz Medizintechnik Homecare Service Ing    ( Oberpframmern  Germany )",
    "country": "Germany"
  },
  {
    "id": 284721,
    "name": "Schwarzer GmbH    ( Heilbronn  Germany )",
    "country": "Germany"
  },
  {
    "id": 454215,
    "name": "Schwarzer Neurology    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161499,
    "name": "Schwarzhaupt Medizintechnik GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 281848,
    "name": "Schwendler & Co KG    ( Friedrichsthal  Germany )",
    "country": "Germany"
  },
  {
    "id": 382785,
    "name": "SCHWIND eye-tech-solutions GmbH & Co KG    ( Kleinostheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 150879,
    "name": "SCHWINN FITNESS INTERNATIONAL    (   USA )",
    "country": "United States"
  },
  {
    "id": 256473,
    "name": "Schwinn Fitness International SA    ( Givisiez  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 279642,
    "name": "Schyns Medizintechnik GmbH    ( Bad Ems  Germany )",
    "country": "Germany"
  },
  {
    "id": 450822,
    "name": "SCI International Inc    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 460625,
    "name": "SCI Solutions Inc    ( Campbell CA USA )",
    "country": "United States"
  },
  {
    "id": 456579,
    "name": "Sci-Cool Inc    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 174730,
    "name": "SciCan    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456001,
    "name": "SciCan GmbH    ( Leutkirch im Allgaeu  Germany )",
    "country": "Germany"
  },
  {
    "id": 188433,
    "name": "SciCan Inc    ( Canonsburg PA USA )",
    "country": "United States"
  },
  {
    "id": 442181,
    "name": "SciCan Meded AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453571,
    "name": "Sciele Pharma Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 462586,
    "name": "Science Innovative Aesthetics    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 103550,
    "name": "Science/Electronics    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 456117,
    "name": "Scienco Technology Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456093,
    "name": "Scient_x-Alphatec Spine    ( Voisins le Bretonneux  France )",
    "country": "France"
  },
  {
    "id": 283294,
    "name": "Scient'x    ( Guyancourt  France )",
    "country": "France"
  },
  {
    "id": 454423,
    "name": "Scient'x USA    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 440373,
    "name": "Scienta International Inc    ( Agoura Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 102649,
    "name": "Scientech Inc    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 106523,
    "name": "Scientek Technology Corp    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 102650,
    "name": "Scientemp Corp    ( Adrian MI USA )",
    "country": "United States"
  },
  {
    "id": 107576,
    "name": "Scientific Adsorbents Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 454519,
    "name": "Scientific Asset Management LLC    ( Basking Ridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 451165,
    "name": "Scientific Biopsy Ltd    ( Even Yehuda  Israel )",
    "country": "Israel"
  },
  {
    "id": 155103,
    "name": "Scientific Device Laboratory Inc    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 452880,
    "name": "Scientific Electronics LLC    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 186338,
    "name": "Scientific Equipment Exchange Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 186339,
    "name": "Scientific Equipment Liquidators Inc    ( Big Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 101703,
    "name": "Scientific Industries Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 461514,
    "name": "Scientific Instruments and Applications Inc    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 462587,
    "name": "Scientific Materials Corp    ( Bozeman MT USA )",
    "country": "United States"
  },
  {
    "id": 103884,
    "name": "Scientific Pharmaceuticals Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 453372,
    "name": "Scientific Protein Laboratories LLC    ( Waunakee WI USA )",
    "country": "United States"
  },
  {
    "id": 465157,
    "name": "Scientific Resources Southwest Inc    ( Stafford CA USA )",
    "country": "United States"
  },
  {
    "id": 459224,
    "name": "Scientific Software Solutions Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 999577,
    "name": "Scientific Solutions for Fitness    ( Tulsa OK USA )",
    "country": "USA"
  },
  {
    "id": 358364,
    "name": "Scientific Supplies and Technology    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 170673,
    "name": "Scientific Vision Systems    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 458361,
    "name": "Scientifica Ltd    ( Uckfield  England )",
    "country": "UK"
  },
  {
    "id": 394503,
    "name": "SCIFIT    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 454497,
    "name": "SciGene Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 453592,
    "name": "SciLabware Ltd    ( Stone  England )",
    "country": "UK"
  },
  {
    "id": 169859,
    "name": "SciLog Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 471651,
    "name": "SCILOGEX LLC    ( Rocky Hill CT USA )",
    "country": "USA"
  },
  {
    "id": 370973,
    "name": "ScImage Inc    ( Los Altos CA USA )",
    "country": "United States"
  },
  {
    "id": 461283,
    "name": "SciMedia USA Ltd    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 107464,
    "name": "SCIMEDX Corp    ( Denville NJ USA )",
    "country": "United States"
  },
  {
    "id": 459052,
    "name": "Scinomix Inc    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 321009,
    "name": "Scion International Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 387711,
    "name": "Scion Valley    (   USA )",
    "country": "United States"
  },
  {
    "id": 461286,
    "name": "SciPro    ( Sanborn NY USA )",
    "country": "United States"
  },
  {
    "id": 462613,
    "name": "SciQuest Inc    ( Cary NC USA )",
    "country": "United States"
  },
  {
    "id": 459007,
    "name": "SCIREQ Scientific Respiratory Equipment Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 376503,
    "name": "Sciton Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 157043,
    "name": "Sclavo Diagnostics International SpA    ( Sovicille (SI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 464982,
    "name": "SCM True Air Technologies Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 107465,
    "name": "Scodenco    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 461289,
    "name": "Scope Communications UK Ltd    ( Totnes  England )",
    "country": "UK"
  },
  {
    "id": 103885,
    "name": "Scope Inc    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 465110,
    "name": "Scorpion Instruments Dentaires    ( Romagnat  France )",
    "country": "France"
  },
  {
    "id": 384270,
    "name": "Scotmed Ltd    ( Paisley  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 104322,
    "name": "Scotsman Ice Systems    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 467326,
    "name": "Scott Distribution LLC    ( Grapevine TX USA )",
    "country": "United States"
  },
  {
    "id": 441576,
    "name": "Scott Health and Safety    ( Monroe NC USA )",
    "country": "United States"
  },
  {
    "id": 454547,
    "name": "Scott Health and Safety    ( Skelmersdale  England )",
    "country": "UK"
  },
  {
    "id": 101278,
    "name": "Scott Medical Products    ( Plumsteadville PA USA )",
    "country": "United States"
  },
  {
    "id": 105590,
    "name": "Scott Orthotic Labs Inc    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 101704,
    "name": "Scott Specialties Inc    ( Belleville KS USA )",
    "country": "United States"
  },
  {
    "id": 146734,
    "name": "ScottCare Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 461301,
    "name": "ScottTech Integrated Solutions    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 442178,
    "name": "SCOTTY Group AG    ( Grambach  Austria )",
    "country": "Austria"
  },
  {
    "id": 456367,
    "name": "SCOTTY Group Austria GmbH    ( Grambach  Austria )",
    "country": "Austria"
  },
  {
    "id": 442179,
    "name": "SCOTTY Group plc Co    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 450853,
    "name": "SCOTTY Technologies of Americas Inc    ( Wilmington NC USA )",
    "country": "United States"
  },
  {
    "id": 456368,
    "name": "SCOTTY Tele-Transport Corp of the Americas Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 461293,
    "name": "Scribe Healthcare Technologies Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 146735,
    "name": "Scrip Inc    ( Peoria IL USA )",
    "country": "United States"
  },
  {
    "id": 366857,
    "name": "ScriptPro    ( Mission KS USA )",
    "country": "United States"
  },
  {
    "id": 461295,
    "name": "ScriptRx Inc    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 456195,
    "name": "SCW Medicath Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454475,
    "name": "ScyTek Laboratories Inc    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 468420,
    "name": "SD Biosensor Inc    ( Suwon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 454242,
    "name": "SD Healthcare    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 459375,
    "name": "SDFI    ( Boulder City NV USA )",
    "country": "United States"
  },
  {
    "id": 455246,
    "name": "SDI (North America) Inc    ( Bensenville IL USA )",
    "country": "United States"
  },
  {
    "id": 344584,
    "name": "SDI Diagnostics Inc    ( Easton MA USA )",
    "country": "United States"
  },
  {
    "id": 455247,
    "name": "SDI Ltd    ( Bayswater  Australia )",
    "country": "Australia"
  },
  {
    "id": 415998,
    "name": "SDR Clinical Technology    ( Middle Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 457892,
    "name": "SDR Scientific    ( Chatswood  Australia )",
    "country": "Australia"
  },
  {
    "id": 146729,
    "name": "SE International Inc    ( Summertown TN USA )",
    "country": "United States"
  },
  {
    "id": 332260,
    "name": "SE Medical Systems LLC    ( Stockbridge GA USA )",
    "country": "United States"
  },
  {
    "id": 453989,
    "name": "Sea-Long Medical Systems Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 105112,
    "name": "Seabrook Medical Systems Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 199693,
    "name": "SEAC srl    ( Calenzano (FL)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451857,
    "name": "Seacoast Medical    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 459055,
    "name": "Seahorse Bioscience    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 457588,
    "name": "Seaka Products Inc    ( Squamish BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454108,
    "name": "SEAL Analytical GmbH    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 454106,
    "name": "SEAL Analytical Inc    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 454107,
    "name": "SEAL Analytical Ltd    ( Fareham  England )",
    "country": "UK"
  },
  {
    "id": 106399,
    "name": "Seal-Seat Co    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 460586,
    "name": "Sealed Air Corp    ( Elmwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 102831,
    "name": "SeaMed Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 104015,
    "name": "Searle Laboratories Div Searle Pharmaceuticals Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 155971,
    "name": "Sears Health Care Products    (   USA )",
    "country": "United States"
  },
  {
    "id": 459071,
    "name": "Sears Holdings Corp    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 451659,
    "name": "SeaSpine Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 177155,
    "name": "Seatcase Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 177858,
    "name": "Seattle Surgical Repair    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 408226,
    "name": "Seattle Systems Inc    ( Poulsbo WA USA )",
    "country": "United States"
  },
  {
    "id": 452666,
    "name": "Sebac SA    ( Patin Cedex  France )",
    "country": "France"
  },
  {
    "id": 333592,
    "name": "Sebia Electrophoresis    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 174628,
    "name": "Sebia Electrophoresis (France)    ( Evry Cedex  France )",
    "country": "France"
  },
  {
    "id": 281878,
    "name": "Sebia GmbH    ( Fulda  Germany )",
    "country": "Germany"
  },
  {
    "id": 339565,
    "name": "Sebra    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 103689,
    "name": "seca    ( Hanover MD USA )",
    "country": "United States"
  },
  {
    "id": 168124,
    "name": "seca AG    ( Reinach/Basle 1  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 361809,
    "name": "seca France    ( Semur-en-Auxois  France )",
    "country": "France"
  },
  {
    "id": 168121,
    "name": "seca Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 370616,
    "name": "seca Vogel & Halke GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 173255,
    "name": "Sechrist Industries GmbH    ( Steinen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 101707,
    "name": "Sechrist Industries Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 462953,
    "name": "Secma    ( Kaevinge  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 152683,
    "name": "SECOMAM    ( Ales Cedex  France )",
    "country": "France"
  },
  {
    "id": 106202,
    "name": "Second Exposure Inc    ( Madisonville LA USA )",
    "country": "United States"
  },
  {
    "id": 458009,
    "name": "Second Sight Medical Products (Switzerland) Sarl    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 458008,
    "name": "Second Sight Medical Products Inc    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 451978,
    "name": "Second Skin Rubber Products    ( Mont Royal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 248761,
    "name": "Sectra Imtec AB    ( Linkoping  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 398532,
    "name": "Sectra North America Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 431513,
    "name": "Securall Cabinets Inc    ( La Porte IN USA )",
    "country": "United States"
  },
  {
    "id": 345204,
    "name": "Secure Care Products Inc    ( Concord NH USA )",
    "country": "United States"
  },
  {
    "id": 457721,
    "name": "Securetec Detektions Systeme AG    ( Brunnthal/Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 374048,
    "name": "Securitron Canada    ( Markham ON USA )",
    "country": "United States"
  },
  {
    "id": 366889,
    "name": "Securitron Magnalock Corp    ( Sparks NV USA )",
    "country": "United States"
  },
  {
    "id": 102986,
    "name": "Security Medical Products    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 451632,
    "name": "Sedana Medical    ( Sundbyberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 401035,
    "name": "SEDAT    ( Irigny  France )",
    "country": "France"
  },
  {
    "id": 457375,
    "name": "Sedation Resource Inc    ( Lone Oak TX USA )",
    "country": "United States"
  },
  {
    "id": 417398,
    "name": "Sedecal SA    ( Algete (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 374385,
    "name": "Sedecal USA Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 458602,
    "name": "Sedere    ( Alfortville Cedex  France )",
    "country": "France"
  },
  {
    "id": 451738,
    "name": "Sedona Lab Products LLC    ( Sedona AZ USA )",
    "country": "United States"
  },
  {
    "id": 101708,
    "name": "Seecor Inc    ( Mesquite TX USA )",
    "country": "United States"
  },
  {
    "id": 286068,
    "name": "Seefelder Messtechnik GmbH & Co Vertrieb KG    ( Seefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 456373,
    "name": "Seegene Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458994,
    "name": "SEEKER Communication LLC    ( La Place LA USA )",
    "country": "United States"
  },
  {
    "id": 452421,
    "name": "SeePoint LLC    ( Redondo Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 457983,
    "name": "SEERS Medical Ltd    ( Debenham  England )",
    "country": "UK"
  },
  {
    "id": 293809,
    "name": "Sefar AG Gewebe & Technologie    ( Rueschlikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 322461,
    "name": "Sefi Medical Instruments    ( Haifa  Israel )",
    "country": "Israel"
  },
  {
    "id": 451301,
    "name": "Segami (France)    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 451300,
    "name": "Segami Corp    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 454157,
    "name": "Segami Medical Canada Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 272824,
    "name": "Segar Alatan Sains (M) Sdn Bhd    ( Subang Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 393040,
    "name": "Segufix Bandagen GmbH & Co KG    ( Jesteburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283241,
    "name": "Segufix Neuheiten Center GmbH    ( Jesteburg  USA )",
    "country": "United States"
  },
  {
    "id": 105116,
    "name": "Segufix Systems Ltd    ( Canterbury NB Canada )",
    "country": "Canada"
  },
  {
    "id": 457986,
    "name": "Segway Orthopaedics Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 280576,
    "name": "Seidel Medizin Technik GmbH    ( Buchendorf bie Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 174868,
    "name": "Seikagaku America Inc    ( East Falmouth MA USA )",
    "country": "United States"
  },
  {
    "id": 178362,
    "name": "Seikagaku Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 458375,
    "name": "Seiko Epson Corp    ( Suwa  Japan )",
    "country": "Japan"
  },
  {
    "id": 363562,
    "name": "Seiko Instruments Inc (Japan)    ( Chiba  Japan )",
    "country": "Japan"
  },
  {
    "id": 177847,
    "name": "Seiko Instruments USA Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 278413,
    "name": "Seiko Takane Co Ltd    ( Kitakyushu  Japan )",
    "country": "Japan"
  },
  {
    "id": 174136,
    "name": "Seikorika Medical Electric Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 174137,
    "name": "Seikosha Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 105203,
    "name": "Seiler Instrument Co    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 460526,
    "name": "SEIMITSU Business Technology Solutions    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 172840,
    "name": "Sein Electronics Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 347678,
    "name": "Seinex Electronics Ltd    ( New Malden  England )",
    "country": "UK"
  },
  {
    "id": 321034,
    "name": "SEIRIN-America Inc    ( Weymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 284833,
    "name": "SEIRIN-Kasei & Co Deutschland GmbH    ( Somerset  Germany )",
    "country": "Germany"
  },
  {
    "id": 108078,
    "name": "Seitz Technical Products Inc    ( Avondale PA USA )",
    "country": "United States"
  },
  {
    "id": 456224,
    "name": "Sekisui Diagnostics LLC    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 455990,
    "name": "Sekisui Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456472,
    "name": "Sekisui Virotech GmbH    ( Ruesselsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 108505,
    "name": "Selco Products Co    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 431558,
    "name": "Select Appliance    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 462735,
    "name": "Select Comfort    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452724,
    "name": "Select Healthcare Ltd    ( Rushden  England )",
    "country": "UK"
  },
  {
    "id": 194771,
    "name": "Select Medical Systems Inc    ( Williston VT USA )",
    "country": "United States"
  },
  {
    "id": 403765,
    "name": "Select Surgical Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 281757,
    "name": "Select-Sutter Medizintechnik GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458996,
    "name": "Select-Tech Inc    ( Shelbyville TN USA )",
    "country": "United States"
  },
  {
    "id": 417197,
    "name": "Selective Med Components Inc    ( Mount Vernon OH USA )",
    "country": "United States"
  },
  {
    "id": 293811,
    "name": "Selfix AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 440683,
    "name": "SeliCor Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 284296,
    "name": "Sellotape Klebetechnik GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 287084,
    "name": "Selzer GmbH    ( Waghaeusel  Germany )",
    "country": "Germany"
  },
  {
    "id": 454444,
    "name": "Semadeni (Germany) AG    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 170954,
    "name": "Semadeni AG    ( Ostermundigen 1  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454446,
    "name": "Semadeni sro    ( Chropyne  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 441723,
    "name": "Semalex sprl    ( Ciplet  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 454445,
    "name": "Semandeni (Austria) AG    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 372014,
    "name": "Semler Technologies Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 107687,
    "name": "Sempco X-Ray    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 416506,
    "name": "Semperit Technische Produkte GmbH & Co KG    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 451742,
    "name": "Sempermed USA Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 170682,
    "name": "Sencore Electronics    (   USA )",
    "country": "United States"
  },
  {
    "id": 451757,
    "name": "Sendal SA    ( Martorell (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 267077,
    "name": "SenDx Medical Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 139943,
    "name": "Seneca Medical Inc    ( Tiffin OH USA )",
    "country": "United States"
  },
  {
    "id": 374102,
    "name": "Senecare Enterprises Inc    ( Holbrook NY USA )",
    "country": "United States"
  },
  {
    "id": 451698,
    "name": "Senectovia Schmillin AG    ( Urdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452845,
    "name": "Sengewald Klinikprodukte GmbH    ( Rohrdorf-Thansau  Germany )",
    "country": "Germany"
  },
  {
    "id": 285810,
    "name": "Sengewald Klinikprodukte GmbH & Co KG    ( Rohrdorf-Thansau  Germany )",
    "country": "Germany"
  },
  {
    "id": 186619,
    "name": "Sengewald Verpackungen GmbH & Co KG    ( Halle/Westfalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 291964,
    "name": "Senior Technologies Inc    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 184661,
    "name": "Senko Medical Instrument Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 158006,
    "name": "Senko Medical Trading Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 287168,
    "name": "Sennheiser electronic GmbH & Co KG    ( Wedemark  Germany )",
    "country": "Germany"
  },
  {
    "id": 439933,
    "name": "SenoRx Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 466249,
    "name": "Sensa Core Inc    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 457420,
    "name": "SensaCalm    ( Chattanooga TN USA )",
    "country": "United States"
  },
  {
    "id": 458970,
    "name": "Sensaphone    ( Aston PA USA )",
    "country": "United States"
  },
  {
    "id": 312344,
    "name": "SensArray Infrared Corp    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 455110,
    "name": "Sensatronics Environmental Sensing    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 441050,
    "name": "Sense Technology Inc    ( Export PA USA )",
    "country": "United States"
  },
  {
    "id": 463033,
    "name": "Senseonics    ( Germantown MD USA )",
    "country": "United States"
  },
  {
    "id": 382733,
    "name": "Sensidyne Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 456465,
    "name": "Sensimed AG    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459072,
    "name": "SensiQ Technologies Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 999808,
    "name": "SENSIT Technologies    ( Valparaiso IN USA )",
    "country": "United States"
  },
  {
    "id": 458999,
    "name": "Sensofar-Tech SL    ( Terrassa  Spain )",
    "country": "Spain"
  },
  {
    "id": 286575,
    "name": "SensoMotoric Instruments GmbH    ( Teltow/Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 382743,
    "name": "SensoMotoric Instruments Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 452602,
    "name": "Sensor Electronics Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452555,
    "name": "Sensor Health Products Inc    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452601,
    "name": "Sensor Products Inc    ( Madison NJ USA )",
    "country": "United States"
  },
  {
    "id": 999652,
    "name": "Sensor Safe    (   USA )",
    "country": "United States"
  },
  {
    "id": 294373,
    "name": "Sensor Scientific Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 452535,
    "name": "SensorCare Systems    ( Oldham  England )",
    "country": "UK"
  },
  {
    "id": 390932,
    "name": "Sensormatic Electronics Corp    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 457408,
    "name": "SensorMed    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 104423,
    "name": "SensorMedics Corp    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 345472,
    "name": "SensorMedics GmbH Nfg KG (Austria)    ( Purkersdorf  USA )",
    "country": "United States"
  },
  {
    "id": 345473,
    "name": "SensorMedics Hong Kong Ltd    ( New Territories  USA )",
    "country": "United States"
  },
  {
    "id": 345474,
    "name": "Sensormedics Italia srl    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 345471,
    "name": "SensorMedics s r o    ( Praha 5  USA )",
    "country": "United States"
  },
  {
    "id": 107776,
    "name": "SensorPhysics    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 339598,
    "name": "Sensortechnics GmbH    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457422,
    "name": "Sensory Goods    ( Surprise AZ USA )",
    "country": "United States"
  },
  {
    "id": 460689,
    "name": "Sensory Management Services LLC    ( Towson MD USA )",
    "country": "United States"
  },
  {
    "id": 392267,
    "name": "Sensory Testing Systems Inc    ( Baton Rouge LA USA )",
    "country": "United States"
  },
  {
    "id": 455727,
    "name": "SensoScientific    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 459509,
    "name": "Sensus Healthcare    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 454632,
    "name": "SenTec AG    ( Therwil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 322725,
    "name": "SenTech Medical Systems Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 453932,
    "name": "Sentinel Diagnostics SpA    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454026,
    "name": "Sentinel Imaging Group Inc    ( Clarks Summit PA USA )",
    "country": "United States"
  },
  {
    "id": 454923,
    "name": "Sentinelle Medical Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 341459,
    "name": "Sentron AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459078,
    "name": "Sentry Air Systems Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 459022,
    "name": "Sentry Data Systems Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 366910,
    "name": "Sentry Group    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 371754,
    "name": "Sentry Technology Corp    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 459268,
    "name": "Sepal Reproductive International    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 108079,
    "name": "Separation Technology Inc    ( Sanford FL USA )",
    "country": "United States"
  },
  {
    "id": 139384,
    "name": "Sepha Ltd    ( Belfast  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 226600,
    "name": "Sepragen Corp    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 306610,
    "name": "SeptiCont Hygienelogistik GmbH    ( Duelmen  Germany )",
    "country": "Germany"
  },
  {
    "id": 108875,
    "name": "Septodont Inc    ( New Castle DE USA )",
    "country": "United States"
  },
  {
    "id": 226274,
    "name": "SeQual Technologies Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457313,
    "name": "Sequana Medical AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457314,
    "name": "Sequana Medical Inc    ( Saratoga CA USA )",
    "country": "United States"
  },
  {
    "id": 453899,
    "name": "Sequel Special Products LLC    ( Waterbury CT USA )",
    "country": "United States"
  },
  {
    "id": 454608,
    "name": "Sequel Systems Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 458698,
    "name": "Sequenom Biosciences    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458697,
    "name": "Sequenom Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456118,
    "name": "SEQUOIA HEALTHCARE Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452203,
    "name": "SeraCare Life Sciences    (   USA )",
    "country": "United States"
  },
  {
    "id": 106459,
    "name": "Seradyn Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 284777,
    "name": "Serag-Wiessner GmbH & Co KG    ( Naila/Bayern  Germany )",
    "country": "Germany"
  },
  {
    "id": 375989,
    "name": "Serco GmbH & Co KG    ( Bonn  Germany )",
    "country": "Germany"
  },
  {
    "id": 450515,
    "name": "SERCOM    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 283424,
    "name": "Serf    ( Decines Cedex  France )",
    "country": "France"
  },
  {
    "id": 308356,
    "name": "Serim Research Corp    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 297643,
    "name": "Serion Immundiagnostica GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 107031,
    "name": "Seritex Inc    ( East Rutherford NJ USA )",
    "country": "United States"
  },
  {
    "id": 455480,
    "name": "Sero AS    ( Billingstad  Norway )",
    "country": "Norway"
  },
  {
    "id": 195330,
    "name": "Sero-Immuno Diagnostics Inc    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 325602,
    "name": "Serola Biomechanics    ( Loves Park IL USA )",
    "country": "United States"
  },
  {
    "id": 469512,
    "name": "Serological Research Institute (SERI)    ( Richmond CA USA )",
    "country": "United States"
  },
  {
    "id": 416576,
    "name": "Serologicals Corp    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 416578,
    "name": "Serologicals Ltd    ( Oxford  USA )",
    "country": "United States"
  },
  {
    "id": 466449,
    "name": "Serosep Ltd    ( Annacotty  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 294374,
    "name": "Serotec Ltd    ( Kidlington  England )",
    "country": "UK"
  },
  {
    "id": 348453,
    "name": "Serum International Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 280232,
    "name": "Serum-Werk Bernburg AG    ( Bernburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 186348,
    "name": "Service Net Inc    ( Albany OH USA )",
    "country": "United States"
  },
  {
    "id": 375403,
    "name": "ServiceCentral Technologies Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 460770,
    "name": "ServiceNow    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 441194,
    "name": "Services Industrials Savaria Inc    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 184671,
    "name": "Servicor    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 285221,
    "name": "Servo Lift GmbH    ( Offenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282265,
    "name": "Servo-Dental    ( Hagen-Halden  Germany )",
    "country": "Germany"
  },
  {
    "id": 401842,
    "name": "Servo-Dental do Brasil    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 363805,
    "name": "Servo-Dental USA Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 456078,
    "name": "SERVOMED    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 454853,
    "name": "Servomex (US)    ( Sugar Land TX USA )",
    "country": "United States"
  },
  {
    "id": 454854,
    "name": "Servomex Group Ltd    ( Crowborough  England )",
    "country": "UK"
  },
  {
    "id": 455501,
    "name": "Servona AG    ( Troisdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 287316,
    "name": "Servoprax GmbH    ( Wesel  Germany )",
    "country": "Germany"
  },
  {
    "id": 416508,
    "name": "Servox AG    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 283964,
    "name": "Serwo GmbH    ( Leverkusen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461122,
    "name": "Sesolinc Group Inc    ( Stuart FL USA )",
    "country": "United States"
  },
  {
    "id": 458961,
    "name": "Sessa Sheet Metal Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 280912,
    "name": "Setaram Technisches Buero    ( Frieberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 346062,
    "name": "Seton Identification Products    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 105118,
    "name": "Setra Systems Inc    ( Boxborough MA USA )",
    "country": "United States"
  },
  {
    "id": 463062,
    "name": "Seuster Labels GmbH    ( Plettenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455991,
    "name": "Seventh Dimensions Design Inc    ( Kobe  Japan )",
    "country": "Japan"
  },
  {
    "id": 441042,
    "name": "Severn River Thermics    ( Hayes VA USA )",
    "country": "United States"
  },
  {
    "id": 457292,
    "name": "Seward International Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457293,
    "name": "Seward Laboratory Systems Inc    ( Port Saint Lucie FL USA )",
    "country": "United States"
  },
  {
    "id": 178349,
    "name": "Seward Ltd    ( Worthing  England )",
    "country": "UK"
  },
  {
    "id": 157878,
    "name": "Seward Medical Systems Ltd    ( Newport  Wales )",
    "country": "Wales"
  },
  {
    "id": 451504,
    "name": "Seward Thackray    ( Rhymney  Wales )",
    "country": "Wales"
  },
  {
    "id": 409563,
    "name": "Sewoon Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 186353,
    "name": "SFR Funding Inc    ( Ormond Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 384414,
    "name": "SFRI Laboratoire    ( Saint Jean d'Illac  France )",
    "country": "France"
  },
  {
    "id": 457074,
    "name": "SFRI Sarl    ( Saint-Jean-d'Illac  France )",
    "country": "France"
  },
  {
    "id": 339915,
    "name": "SG Water USA LLC    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 279840,
    "name": "SG-Wasseraufbereitung und Regenerierstation GmbH    ( Barsbuettel  Germany )",
    "country": "Germany"
  },
  {
    "id": 161609,
    "name": "Sgarlato Labs Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 140819,
    "name": "SGE (Scientific Glass Engineering) International P    ( Ringwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 108695,
    "name": "SGE Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 287244,
    "name": "SGE Scientific Glass Engineering GmbH    ( Weiterstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 999689,
    "name": "SGM Biotech, Inc.    ( Bozeman MT USA )",
    "country": "USA"
  },
  {
    "id": 279070,
    "name": "SGM Codim    ( Saint-Etienne Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 331713,
    "name": "SGS Export International    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 402213,
    "name": "SH Medical Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 286090,
    "name": "SH-Medizintechnik GmbH    ( Seitingen  USA )",
    "country": "United States"
  },
  {
    "id": 464097,
    "name": "Shaanxi Ansen Medical Technology Development Co Lt    ( Xi'an  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458955,
    "name": "Shade Construction Co Inc    ( Parkville MD USA )",
    "country": "United States"
  },
  {
    "id": 454659,
    "name": "Shafer Enterprises    ( Stockbridge GA USA )",
    "country": "United States"
  },
  {
    "id": 358225,
    "name": "Shahak Diamond Tools    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 292649,
    "name": "Shahal Medical Services Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 108325,
    "name": "Shamrock Medical Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 323458,
    "name": "Shamrock Mfg Co Inc    ( Chino CA USA )",
    "country": "United States"
  },
  {
    "id": 105998,
    "name": "Shamrock Scientific Specialty Systems    ( Bellwood IL USA )",
    "country": "United States"
  },
  {
    "id": 108461,
    "name": "Shandon Lipshaw    (   USA )",
    "country": "United States"
  },
  {
    "id": 456230,
    "name": "Shandong Blue Sail Plastic & Rubber Co Ltd    ( Zibo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452784,
    "name": "Sientra Inc    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 456237,
    "name": "Shandong Dermcosy Medical Co Ltd    ( Zibo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456238,
    "name": "Shandong Freda Medical Device Co Ltd    ( Jinan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456240,
    "name": "Shandong Qiaopai Group Co Ltd    ( Zibo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456671,
    "name": "Shandong Weifang Eagle Medical Appliances Co Ltd    ( Weifang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291655,
    "name": "Shandong Xinhua Medical Instrument Co Ltd    ( Zibo City  USA )",
    "country": "United States"
  },
  {
    "id": 291738,
    "name": "Shanghai Anting Scientific Instrument Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456241,
    "name": "Shanghai AoHua Photoelectricity Endoscope Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453627,
    "name": "Shanghai Bio-Tech Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291744,
    "name": "Shanghai Centrifuge Institute    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456245,
    "name": "Shanghai Channelmed Import & Export Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456227,
    "name": "Shanghai Chemtron Biotech Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456248,
    "name": "Shanghai Chuangshi Industry Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 463074,
    "name": "Shanghai Clinx Science Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291746,
    "name": "Shanghai Dental Materials Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291753,
    "name": "Shanghai Electric Meter Works Omni Tech Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291754,
    "name": "Shanghai Electrons Optic Technical Institute    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456673,
    "name": "Shanghai Everpure Medical Plastic Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456674,
    "name": "Shanghai Fangtai Medical Equipment Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452649,
    "name": "Shanghai Fosun Biolog Biotech Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456260,
    "name": "Shanghai Guardian Medical Instrument Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455900,
    "name": "Shanghai Hongshi Medical Technology Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456675,
    "name": "Shanghai Hongze Enterprise Development Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456265,
    "name": "Shanghai Huazhe International Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 418528,
    "name": "Shanghai Intco Plastic and Rubber Products Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456268,
    "name": "Shanghai K & G International Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455714,
    "name": "Shanghai Kaile Infusion Sets Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456676,
    "name": "Shanghai Kesheng Prostheses Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291764,
    "name": "Shanghai Latex Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 305763,
    "name": "Shanghai Medical & Health Import & Export Corp    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456279,
    "name": "Shanghai Medical Equipment Works Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291773,
    "name": "Shanghai Medical Instrument Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 439528,
    "name": "Shanghai Medical Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 371200,
    "name": "Shanghai Medison Medical Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416159,
    "name": "Shanghai Optima Optical Glass Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456285,
    "name": "Shanghai SA Medical & Plastic Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291811,
    "name": "Shanghai Sanhe Medical Instrument Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456685,
    "name": "Shanghai Sanshen Medical Instrument Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456686,
    "name": "ShangHai Shengding Industry Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456330,
    "name": "Shanghai Smedent Medical Instrument Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456290,
    "name": "Shanghai Songhang Industry Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456291,
    "name": "Shanghai Sumiec Imp & Exp Corp    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291817,
    "name": "Shanghai Surgical Instruments Factory    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457733,
    "name": "Shanghai Technologies Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 416180,
    "name": "Shanghai Thales Electron Devices Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455693,
    "name": "Shanghai Transfusion Technology Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457409,
    "name": "Shanghai Winner Engineering Equipment Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456292,
    "name": "Shanghai Xuerui Imp & Exp Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373067,
    "name": "Shanghai Yamato Scale Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456298,
    "name": "Shanghai Yanshun Scope Parts & Accessories Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456702,
    "name": "Shanghai YIKANG Medical Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456299,
    "name": "Shanghai Yinjing Medical Supplies Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 103337,
    "name": "Shannon Group    (   USA )",
    "country": "United States"
  },
  {
    "id": 298131,
    "name": "Shantou Institute of Ultrasonic Instruments    ( Shantou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454992,
    "name": "Shantou Institute of Ultrasonic Instruments (HK) C    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454991,
    "name": "Shantou Institute of Ultrasonic Instruments (Unite    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 291683,
    "name": "Shanxi Changzhi Dental Materials Factory    ( Changzhi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456307,
    "name": "Shaoxing Fuqing Health Products Co Ltd    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456315,
    "name": "Shaoxing Gangfeng Hospital Products Co Ltd    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456319,
    "name": "Shaoxing Yibon Medical Co Ltd    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458669,
    "name": "Shape Medical Systems Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 332240,
    "name": "Shared Imaging    ( Streamwood IL USA )",
    "country": "United States"
  },
  {
    "id": 428611,
    "name": "Shared Medical Services Inc    ( Cottage Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 104690,
    "name": "Sharn Anesthesia Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 999889,
    "name": "Sharn Veterinary Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 188110,
    "name": "Sharp Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 105435,
    "name": "Sharp Electronics Corp    ( Mahwah NJ USA )",
    "country": "United States"
  },
  {
    "id": 104835,
    "name": "Sharplan Lasers Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 410019,
    "name": "Sharps Compliance Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 464790,
    "name": "Shasta Technologies LLC    ( Calistoga CA USA )",
    "country": "United States"
  },
  {
    "id": 108826,
    "name": "Shattuck & Assoc Auctioneers Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 171394,
    "name": "Shavrin Levatap Co Ltd    ( Kings Langley  England )",
    "country": "UK"
  },
  {
    "id": 105591,
    "name": "Sheepskin Ranch Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 452543,
    "name": "SheerVision Inc    ( Rolling Hills Estates CA USA )",
    "country": "United States"
  },
  {
    "id": 467244,
    "name": "Sheffield Medical Products Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 455408,
    "name": "Sheffmed    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 161066,
    "name": "Shekel Electronic Scales    ( Beit Keshet  Israel )",
    "country": "Israel"
  },
  {
    "id": 103557,
    "name": "Shelcon Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 340213,
    "name": "Sheldon Enterprises Inc    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 108582,
    "name": "Sheldon Mfg Inc    ( Cornelius OR USA )",
    "country": "United States"
  },
  {
    "id": 458993,
    "name": "ShelfPlus Automated Storage Concepts    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 332751,
    "name": "Shelhigh Inc    ( Union NJ USA )",
    "country": "United States"
  },
  {
    "id": 461360,
    "name": "SHELLEY Medical Imaging Technologies    ( Toronto  Canada )",
    "country": "Canada"
  },
  {
    "id": 148425,
    "name": "Shelton Scientific - IBI    ( Peosta IA USA )",
    "country": "United States"
  },
  {
    "id": 452600,
    "name": "Shenghai Winner Medical Instruments Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456662,
    "name": "Shengzhou Xingwang Dental Material Factory    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 467399,
    "name": "Shenyang RMS Medical Tech Co Ltd    ( Shenyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452483,
    "name": "Shenyang Shenda Endoscope Co Ltd    ( Shenyang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453646,
    "name": "Shenzen Mindray Bio-Medical Electronics Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 426657,
    "name": "Shenzh Goldway Industrial Inc    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456326,
    "name": "Shenzhen Anke High-tech Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451889,
    "name": "Shenzhen Ant Medical Devices Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456328,
    "name": "Shenzhen BASDA Medical Apparatus Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456329,
    "name": "Shenzhen Bestman Instrument Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456198,
    "name": "Shenzhen Biocare Biotech Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456342,
    "name": "Shenzhen Chenwei Electronic Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456343,
    "name": "Shenzhen Comen Medical Instruments Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456344,
    "name": "Shenzhen Creative Industry Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456345,
    "name": "Shenzhen Delicate Electronics Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456347,
    "name": "Shenzhen Dongdixin Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456354,
    "name": "Shenzhen Emperor Electronic Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456401,
    "name": "Shenzhen Everbest Machinery Industry Co Ltd    ( Xili  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456405,
    "name": "Shenzhen Hyde Medical Equipment Co Ltd    ( Qingshuihe  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456411,
    "name": "Shenzhen Jumper Medical Equipment Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291059,
    "name": "Shenzhen Keda Advanced Electrical Technology Co Lt    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456412,
    "name": "Shenzhen Kingyield Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456427,
    "name": "Shenzhen Maxcom Electronic Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291060,
    "name": "Shenzhen Mindray Bio-Medical Electronics Co Ltd    ( Shenzhen  USA )",
    "country": "United States"
  },
  {
    "id": 456429,
    "name": "Shenzhen Raycome Health Technology Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291064,
    "name": "Shenzhen Xianghe Science & Technology Development    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 156131,
    "name": "Shepard Medical Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 107472,
    "name": "Sher Co Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 107469,
    "name": "Sheriff Assoc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 454963,
    "name": "Sherman Oaks Medical Supplies    ( Sherman Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 104330,
    "name": "Sherman Supersonic Industries Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 455686,
    "name": "Shermond    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 451163,
    "name": "Sherwood    ( Niagara Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 999990,
    "name": "Sherwood Medical Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 466409,
    "name": "SHI International Corp    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 393607,
    "name": "Shi Qiang Optical    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 101713,
    "name": "Shielding International    ( Madras OR USA )",
    "country": "United States"
  },
  {
    "id": 460639,
    "name": "ShiftHound Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456466,
    "name": "ShiJiaZhuang AoFeiTe Import & Export Co Ltd    ( Shijiazhuang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 334022,
    "name": "Shilac Enterprises Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 403265,
    "name": "Shilac Japan    ( Izumi-shi  Japan )",
    "country": "Japan"
  },
  {
    "id": 451021,
    "name": "Shilog Medical Supply    ( McAlester OK USA )",
    "country": "United States"
  },
  {
    "id": 172209,
    "name": "Shimadzu (Asia Pacific) PTE Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 138089,
    "name": "Shimadzu Corp    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 153971,
    "name": "Shimadzu Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 161064,
    "name": "Shimadzu Deutschland GmbH    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 408259,
    "name": "Shimadzu Medical Systems Oceania Pty Ltd    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 106973,
    "name": "Shimadzu Precision Instruments Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 103338,
    "name": "Shimadzu Scientific Instruments Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 999856,
    "name": "ShimpoInstruments    ( Cedarhurst NY USA )",
    "country": "United States"
  },
  {
    "id": 452750,
    "name": "Shims Bargain Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 158010,
    "name": "Shin-ei Industries Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 158019,
    "name": "Shin-Nippon Commerce Inc    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 330920,
    "name": "Shinemound Enterprise Inc    ( North Billerica MA USA )",
    "country": "United States"
  },
  {
    "id": 456119,
    "name": "Shining World Health Care Co Ltd    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 158011,
    "name": "Shinko Denshi Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452207,
    "name": "Shinva Medical Instruments Co    ( Zibo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452209,
    "name": "Shinva Medical Instruments Co    ( Zibo City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455992,
    "name": "Shioda Dental Co    ( Nasukarasuyama  Japan )",
    "country": "Japan"
  },
  {
    "id": 457715,
    "name": "Shionogi Inc    ( Florham Park NJ USA )",
    "country": "United States"
  },
  {
    "id": 184209,
    "name": "Shippert Medical Technologies Corp    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 278653,
    "name": "Shiraimatsu & Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 384433,
    "name": "Shire Design Electronics Ltd    ( Hatton  England )",
    "country": "UK"
  },
  {
    "id": 464989,
    "name": "Shire Medical Solutions Inc    ( Skaneateles NY USA )",
    "country": "United States"
  },
  {
    "id": 398106,
    "name": "Shire Pharmaceuticals    ( St Laurent PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 370352,
    "name": "Shivani Scientific Industries (P) Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 354298,
    "name": "SHL Telemedicine International Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 103889,
    "name": "Shofu Dental Corp    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 285553,
    "name": "Shofu Dental GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 415927,
    "name": "Shofu Dental Products Ltd    ( Tonbridge  England )",
    "country": "UK"
  },
  {
    "id": 415906,
    "name": "Shofu Dental Taiwan Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 289560,
    "name": "Shofu Inc    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 415925,
    "name": "Shofu Inc (Shanghai)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 415924,
    "name": "Shofu Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 415255,
    "name": "Shoprider Mobility Products Inc    ( Carson CA USA )",
    "country": "United States"
  },
  {
    "id": 458817,
    "name": "Shor-Line    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 461364,
    "name": "ShoreTel    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 282769,
    "name": "Shortes Germany GmbH    ( Hatzfeld Eder  Germany )",
    "country": "Germany"
  },
  {
    "id": 456161,
    "name": "Shortridge Instruments Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 463050,
    "name": "Showa Best Glove    ( Menlo GA USA )",
    "country": "United States"
  },
  {
    "id": 322804,
    "name": "Showa Boeki Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 325605,
    "name": "Shower Aide Inc    ( Pelzer SC USA )",
    "country": "United States"
  },
  {
    "id": 441043,
    "name": "Shower Solutions USA LLC    ( Altamonte Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 464964,
    "name": "Showerbuddy Global Ltd    ( Waikanae  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 306226,
    "name": "SHP International Pty Ltd    ( Fullarton  Australia )",
    "country": "Australia"
  },
  {
    "id": 249842,
    "name": "Shree Pacetronix Ltd    ( Pithampur  India )",
    "country": "India"
  },
  {
    "id": 186354,
    "name": "Shreveport X-Ray Co Inc    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 456663,
    "name": "Shuangge Group Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 366520,
    "name": "Shuenn Bao Shing Corp    ( Changhua  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 458502,
    "name": "Shukla Medical    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 999705,
    "name": "Shuttle Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 458455,
    "name": "Shuttle Systems Inc    ( Glacier WA USA )",
    "country": "United States"
  },
  {
    "id": 410539,
    "name": "Shvadent-Schein    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 434203,
    "name": "SI Photonics    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 337485,
    "name": "SIA Oriola Riga    ( Riga  Latvia )",
    "country": "Latvia"
  },
  {
    "id": 456338,
    "name": "Siam Intermagnate Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 152520,
    "name": "Siare Engineering International Group Srl    ( Crespellano (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 377692,
    "name": "Sias AG    ( Hombrechtikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 177705,
    "name": "SIAS SpA    ( Cadriano di Granarolo (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 177708,
    "name": "Sibel SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 456703,
    "name": "SiChuan HangYu Electronic Medical Equipment Co Ltd    ( Chengdu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 162292,
    "name": "Sidhil Ltd    ( Homefield Halifax  England )",
    "country": "UK"
  },
  {
    "id": 999558,
    "name": "Siegel Performance Systems    ( Huntington NY USA )",
    "country": "United States"
  },
  {
    "id": 287588,
    "name": "Siegfried Martin GmbH    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279719,
    "name": "Sieker Apothekeneinrichtungen    ( Bad Oeynhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 323949,
    "name": "Siel Imaging Ltd    ( Aldermaston  USA )",
    "country": "United States"
  },
  {
    "id": 461298,
    "name": "Sielox LLC    ( Runnemede NJ USA )",
    "country": "United States"
  },
  {
    "id": 401833,
    "name": "Siemens Advanced Engineering Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 258246,
    "name": "Siemens AG    (   USA )",
    "country": "United States"
  },
  {
    "id": 401832,
    "name": "Siemens AG    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 283343,
    "name": "Siemens Aktiengesellschaft Lasertechnik    ( Karlstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 431316,
    "name": "Siemens Audiologie SAS (France)    ( Saint Denis Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 183376,
    "name": "Siemens Audiologische Technik GmbH    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 174735,
    "name": "Siemens Canada Ltd    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452753,
    "name": "Siemens Healthcare Diagnostics (France)    ( Paris la Defense  France )",
    "country": "France"
  },
  {
    "id": 452771,
    "name": "Siemens Healthcare Diagnostics (Germany)    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 452770,
    "name": "Siemens Healthcare Diagnostics (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452769,
    "name": "Siemens Healthcare Diagnostics (Spain)    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 453773,
    "name": "Siemens Healthcare Diagnostics Ltd Uk    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 452772,
    "name": "Siemens Healthcare Diagnostics Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 101715,
    "name": "Siemens Hearing Instruments Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 458059,
    "name": "Siemens Ltd    ( Midrand  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 431315,
    "name": "Siemens Medical Instruments Pte Lte (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 307478,
    "name": "Siemens Medical Solutions (South Africa)    ( Halfway House  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 187435,
    "name": "Siemens Medical Solutions AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 451971,
    "name": "Siemens Medical Solutions Diagnostics    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 452752,
    "name": "Siemens Medical Solutions Diagnostics UK    ( Llanberis  Wales )",
    "country": "Wales"
  },
  {
    "id": 399199,
    "name": "Siemens Medical Solutions USA Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 399200,
    "name": "Siemens Medical Solutions USA Inc    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 399202,
    "name": "Siemens Medical Solutions USA Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 399203,
    "name": "Siemens Medical Solutions USA Inc    ( Martinez CA USA )",
    "country": "United States"
  },
  {
    "id": 458058,
    "name": "Siemens Medical Solutions USA Inc    ( Hoffman Estates IL USA )",
    "country": "United States"
  },
  {
    "id": 451594,
    "name": "Siemens Molecular Imaging Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 452080,
    "name": "Siemens Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 339105,
    "name": "Siemens SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 453007,
    "name": "Siemens Water Technologies    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 178463,
    "name": "Siemens-Asahi Medical Technologies Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 102734,
    "name": "Sienco Inc    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 106862,
    "name": "Sienco Instrument Service Co    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 466320,
    "name": "Spectra Medical Devices Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 282981,
    "name": "Sieper-Werke GmbH    ( Hilchenbach-Muesen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452378,
    "name": "Sierra Asia Instruments Co    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 402450,
    "name": "Sierra BioSearch Inc    ( Bishop CA USA )",
    "country": "United States"
  },
  {
    "id": 452377,
    "name": "Sierra Instruments bv    ( Egmond ann den Hoef  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 103558,
    "name": "Sierra Instruments Inc    ( Monterey CA USA )",
    "country": "United States"
  },
  {
    "id": 105593,
    "name": "Sierra Monitor Corp    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 452590,
    "name": "Sierra Scientific Instruments Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 456548,
    "name": "Sierra Scientific Instruments Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 468397,
    "name": "SiestaMed Techonologies LLC    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 280174,
    "name": "Sifin Institut fuer Immunpraeparate und Naehrmedie    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 344611,
    "name": "SIG Pack Services Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 416011,
    "name": "SIGEMA Medical GmbH    ( Dottikon  USA )",
    "country": "United States"
  },
  {
    "id": 458518,
    "name": "Sight Society of NENY Inc (Lions Eye Bank at Alban    (   USA )",
    "country": "United States"
  },
  {
    "id": 454297,
    "name": "SightLife    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 466226,
    "name": "Sightpath Medical    ( Bloomington x USA )",
    "country": "United States"
  },
  {
    "id": 225961,
    "name": "Sigma Aldrich Co Ltd    ( Gillingham  England )",
    "country": "UK"
  },
  {
    "id": 454479,
    "name": "Sigma Digital X-Ray Inc    ( Buffalo Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 186360,
    "name": "Sigma Imaging Technologies Inc    ( Trenton NJ USA )",
    "country": "United States"
  },
  {
    "id": 152355,
    "name": "Sigma International LLC    ( Medina NY USA )",
    "country": "United States"
  },
  {
    "id": 160990,
    "name": "Sigma Laborzentrifugen GmbH    ( Osterode am Harz  Germany )",
    "country": "Germany"
  },
  {
    "id": 307317,
    "name": "Sigma Laborzentrifugen GmbH    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457635,
    "name": "Sigma Medical    ( Apeldoorn  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 392732,
    "name": "Sigma Medical Supplies Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 332035,
    "name": "Sigma Medizin-Technik GmbH    ( Gelenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 456607,
    "name": "Sigma Pharmaceuticals LLC    ( Monticello IA USA )",
    "country": "United States"
  },
  {
    "id": 453394,
    "name": "Sigma Pharmaceuticals Ltd    ( Rowville  Australia )",
    "country": "Australia"
  },
  {
    "id": 991654,
    "name": "SIGMA SYSTEMS    (   USA )",
    "country": "United States"
  },
  {
    "id": 455194,
    "name": "Sigma Systems Corp    ( Sharon MA USA )",
    "country": "United States"
  },
  {
    "id": 188102,
    "name": "Sigma-Aldrich Chemie GmbH    ( Steinheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 188108,
    "name": "Sigma-Aldrich Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 324517,
    "name": "Sigmacon (UK) Ltd    ( Stanmore  England )",
    "country": "UK"
  },
  {
    "id": 184261,
    "name": "Sigmedics Inc    ( Fairborn OH USA )",
    "country": "United States"
  },
  {
    "id": 465000,
    "name": "Signal Engineering & Supply Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 333605,
    "name": "Signal Medical Corp    ( Marysville MI USA )",
    "country": "United States"
  },
  {
    "id": 458156,
    "name": "Signal Systems Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 103004,
    "name": "Signalcom Systems Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 459081,
    "name": "Signature Genomic Laboratories LLC    ( Spokane WA USA )",
    "country": "United States"
  },
  {
    "id": 457237,
    "name": "Signature MRI Inc    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 234516,
    "name": "Signature Software Systems Inc    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 403734,
    "name": "Signet Armorlite Europe bv    ( Hoofddorf  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 359774,
    "name": "Signet Armorlite Europe Ltd    ( Quedgeley  England )",
    "country": "UK"
  },
  {
    "id": 104925,
    "name": "Signet Armorlite Inc    ( San Marcos CA USA )",
    "country": "United States"
  },
  {
    "id": 403736,
    "name": "Signet Armorlite Inc (Canada)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 169586,
    "name": "Signet Laboratories Inc    ( Dedham MA USA )",
    "country": "United States"
  },
  {
    "id": 454955,
    "name": "SIGNUS LLC    ( Chanhassen MN USA )",
    "country": "United States"
  },
  {
    "id": 454954,
    "name": "SIGNUS Medizintechnik GmbH    ( Alzenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 462997,
    "name": "SIGVARIS AG    ( St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 355528,
    "name": "Sigvaris Inc    ( Peachtree City GA USA )",
    "country": "United States"
  },
  {
    "id": 455504,
    "name": "SIKA USA Inc    ( Sturtevant WI USA )",
    "country": "United States"
  },
  {
    "id": 163007,
    "name": "Silberman Gas Systems Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 999903,
    "name": "Silentaire Technology    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 457129,
    "name": "Silentia AB    ( Falkenberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 192836,
    "name": "Silfradent    ( San Sofia (FO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457718,
    "name": "Silhorko-Eurowater A/S    ( Skanderborg  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 462989,
    "name": "Silhouet-Tone Corp    ( Laval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 462991,
    "name": "Silhouet-Tone Europe    ( Mies  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 462990,
    "name": "Silhouet-Tone USA    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 460794,
    "name": "Silicon Graphics Inc    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 294378,
    "name": "Silicone Altimex Ltd    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 342784,
    "name": "Silimed Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 108327,
    "name": "Silipos Inc    ( Niagara Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 454609,
    "name": "Silk Information Systems Inc    ( Watkinsville GA USA )",
    "country": "United States"
  },
  {
    "id": 461297,
    "name": "SilkRoad Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 287252,
    "name": "Sill-Optics GmbH & Co KG    ( Wendelstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 295734,
    "name": "Siltech Corp    ( Downey CA USA )",
    "country": "United States"
  },
  {
    "id": 295733,
    "name": "Siltech Corp Pty Ltd    ( Coburg  Australia )",
    "country": "Australia"
  },
  {
    "id": 467315,
    "name": "Silver Star Brands    ( Oshkosh WI USA )",
    "country": "United States"
  },
  {
    "id": 451277,
    "name": "Silvestrin Fabis Srl    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 328165,
    "name": "SIM Italia srl    ( San Pietro in Casale (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 263613,
    "name": "Sim Net Inc    ( Glen Allen VA USA )",
    "country": "United States"
  },
  {
    "id": 459272,
    "name": "Simbionix USA Corp    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 102312,
    "name": "Simco Industrial Static Control    ( Hatfield PA USA )",
    "country": "United States"
  },
  {
    "id": 187971,
    "name": "SIMCO International Marketing    ( San Salvador  USA )",
    "country": "United States"
  },
  {
    "id": 286370,
    "name": "Simco-Folien GmbH    ( Stockelsdorf Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 307319,
    "name": "Sime Darby Marketing    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 267014,
    "name": "Sime Latex Products Sdn Bhd    ( Seremban  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 185492,
    "name": "Simecoop Co Ltd    ( Lomianki  Poland )",
    "country": "Poland"
  },
  {
    "id": 192837,
    "name": "Simed srl    ( Baranzate di Bollate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462574,
    "name": "Simendo Bv    ( Rotterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 457217,
    "name": "SIMEON Medical GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457835,
    "name": "Similasan USA    ( Highlands Ranch CO USA )",
    "country": "United States"
  },
  {
    "id": 308422,
    "name": "Simione Central Holdings Inc    ( Atlanta GA USA )",
    "country": "USA"
  },
  {
    "id": 103141,
    "name": "SIMKAR Corp    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 418656,
    "name": "Simmler Inc    ( High Ridge MO USA )",
    "country": "United States"
  },
  {
    "id": 103224,
    "name": "Simmons Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 293252,
    "name": "Simon Keller AG    ( Burgdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 106001,
    "name": "Simonds Mfg Corp    ( Auburndale FL USA )",
    "country": "United States"
  },
  {
    "id": 285034,
    "name": "Simons Druck & Vertrieb GmbH    ( Nottuln  Germany )",
    "country": "Germany"
  },
  {
    "id": 146745,
    "name": "Simons X-Ray Corp    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 325591,
    "name": "Simpex Medical Inc    ( Des Plaines IL USA )",
    "country": "United States"
  },
  {
    "id": 287088,
    "name": "Simpex-Objekt    ( Wahlstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 458106,
    "name": "Simple Diagnostics    ( Williston Park NY USA )",
    "country": "United States"
  },
  {
    "id": 441519,
    "name": "SimpleChoice    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 175955,
    "name": "Simpler Life Emergency Provision    ( Redlands CA USA )",
    "country": "United States"
  },
  {
    "id": 454989,
    "name": "Simplex Medical Inc    ( Mount Prospect IL USA )",
    "country": "United States"
  },
  {
    "id": 457919,
    "name": "Simplex Mfg Co    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 358365,
    "name": "SimplexGrinnell LP    ( Westminster MA USA )",
    "country": "United States"
  },
  {
    "id": 453559,
    "name": "Simplicity for Children    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 452221,
    "name": "Simplo Technology Co Ltd    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456626,
    "name": "Simply Birth    ( Quinn Rocks  Australia )",
    "country": "Australia"
  },
  {
    "id": 457771,
    "name": "SimplyThick LLC    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 103892,
    "name": "Simport Plastics Ltd    ( Beloeil PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 102313,
    "name": "Simpson Electric Co    ( Lac du Flambeau WI USA )",
    "country": "United States"
  },
  {
    "id": 184663,
    "name": "SIMS Deltec Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 187763,
    "name": "SIMS Pte Ltd/SIMS Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452374,
    "name": "Simulab Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 102314,
    "name": "Simulaids Inc    ( Saugerties NY USA )",
    "country": "United States"
  },
  {
    "id": 459511,
    "name": "Simulated Surgical Solutions LLC    ( Williamsville NY USA )",
    "country": "United States"
  },
  {
    "id": 460529,
    "name": "Simulator Systems Inc    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 461338,
    "name": "Simulution    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 462573,
    "name": "Simuplan SL    ( Valencia  Spain )",
    "country": "Spain"
  },
  {
    "id": 439091,
    "name": "SIN Sistema de Implante Nacional Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 330134,
    "name": "Sinarapi Sdn Bhd    ( Klang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 284370,
    "name": "SinfoMed GmbH    ( Meckenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 459089,
    "name": "Singer Instrument Co Ltd    ( Watchet  England )",
    "country": "UK"
  },
  {
    "id": 452698,
    "name": "Single Use Surgical Ltd    ( Barnsley  England )",
    "country": "UK"
  },
  {
    "id": 455035,
    "name": "Sinitec AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 303638,
    "name": "SINMED Technologies Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 456704,
    "name": "SINNOWA Medical Science & Technology Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291116,
    "name": "Sino-American Joint Venture Henan Universe    ( Zhengzhou  USA )",
    "country": "United States"
  },
  {
    "id": 456664,
    "name": "Sino-US Wenzhou Huali Medical Instrument Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456310,
    "name": "Sinomech Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452956,
    "name": "Sintea Biotech Inc    ( Miami Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 452955,
    "name": "Sintea Biotech SpA    ( Baranzate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 358254,
    "name": "Sion Texo Medic Ltd    ( Upper Galilee  Israel )",
    "country": "Israel"
  },
  {
    "id": 287755,
    "name": "Siora Exports    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 291375,
    "name": "Siping Medical Instrument and Apparatus Factory    ( Siping  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 294380,
    "name": "Sippex    ( Courzieu  France )",
    "country": "France"
  },
  {
    "id": 450548,
    "name": "Sippex Kejie    ( Xian  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456595,
    "name": "Sirchie Fingerprint Laboratories    ( Youngsville NC USA )",
    "country": "United States"
  },
  {
    "id": 464985,
    "name": "Sirius Computer Solutions    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 461127,
    "name": "SiriusXM Holdings Inc    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 467229,
    "name": "Sirona Complete Care    ( Youngstown OH USA )",
    "country": "United States"
  },
  {
    "id": 360011,
    "name": "Sirona Dental Systems    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 354569,
    "name": "Sirona Dental Systems GmbH    ( Bensheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 457227,
    "name": "SironaHealth Inc    ( South Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 412986,
    "name": "SIRTeX Medical Ltd    ( North Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 999400,
    "name": "Siruis XM    (   USA )",
    "country": "United States"
  },
  {
    "id": 384300,
    "name": "SIS-Licht Gebr Lang GmbH & Co KG    ( Schweinfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 453745,
    "name": "SISK Healthcare    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 152450,
    "name": "Sismatec Ind Com Equips Hospitalares Ltda    ( Curitiba-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 398061,
    "name": "Sistemas Medicos Comercio e Industria Ltda    ( Belo Horizonte-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 466480,
    "name": "SIT Sordina IORT Technologies USA Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 452178,
    "name": "Site of Care Systems    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 457092,
    "name": "Sitec Medical Co Ltd    ( Gimpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 174420,
    "name": "Sitec S/A Industria e Comercio    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 462813,
    "name": "Sitecore    ( Copenhagen  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 459455,
    "name": "SiteFM    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 473825,
    "name": "Sividon Diagnostics GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 455505,
    "name": "SixLog Corp    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 322737,
    "name": "SIZEWise Rentals LLC    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 456920,
    "name": "SJT Medical Ltd    ( Histon  England )",
    "country": "UK"
  },
  {
    "id": 461363,
    "name": "SK Fitness Services    ( Wake Forest NC USA )",
    "country": "United States"
  },
  {
    "id": 457146,
    "name": "SKC Inc    ( Eighty Four PA USA )",
    "country": "United States"
  },
  {
    "id": 279576,
    "name": "SKE Haftetiketten GmbH    ( Asperg  Germany )",
    "country": "Germany"
  },
  {
    "id": 175957,
    "name": "Skedco Inc    ( Tualatin OR USA )",
    "country": "United States"
  },
  {
    "id": 469503,
    "name": "Skeletal Dynamics LLC    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 324852,
    "name": "SKF Services Ltd    ( Billinghurst  England )",
    "country": "UK"
  },
  {
    "id": 452707,
    "name": "SKF USA Inc    ( Kulpsville PA USA )",
    "country": "United States"
  },
  {
    "id": 455122,
    "name": "Skil Environmental Ltd    ( Skelmersdale  England )",
    "country": "UK"
  },
  {
    "id": 107471,
    "name": "Skil-Care Corp    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 465142,
    "name": "SkillSurvey, Inc.    ( Wayne 56 USA )",
    "country": "United States"
  },
  {
    "id": 461382,
    "name": "SkinPrint    ( Goshen NY USA )",
    "country": "United States"
  },
  {
    "id": 150503,
    "name": "Sklar Corp    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 293583,
    "name": "SKS Rehab AG    ( Schwanden  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461132,
    "name": "SKY Enterprises Inc    ( Kinston NC USA )",
    "country": "United States"
  },
  {
    "id": 322411,
    "name": "Sky Lab Co Ltd    ( Bangkok  USA )",
    "country": "United States"
  },
  {
    "id": 453726,
    "name": "SkyeTek    ( Westminster CO USA )",
    "country": "United States"
  },
  {
    "id": 152424,
    "name": "Skylark Device & Systems Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452417,
    "name": "Skylight Healthcare Systems Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 402212,
    "name": "Skyline Medical Inc    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 102186,
    "name": "Skytron    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 155431,
    "name": "Skyway Machine Inc    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 452336,
    "name": "SL Power Electronics (China)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451571,
    "name": "SL Power Electronics Corp    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 452335,
    "name": "SL Power Electronics Ltd    ( South Molton  England )",
    "country": "UK"
  },
  {
    "id": 186587,
    "name": "Slamed Ing GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 150965,
    "name": "SLE Ltd    ( South Croydon  England )",
    "country": "UK"
  },
  {
    "id": 160993,
    "name": "SLEE-Medical GmbH    ( Mainz  Germany )",
    "country": "Germany"
  },
  {
    "id": 458088,
    "name": "Sleep Group Solutions Inc    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 455543,
    "name": "Sleep Modus Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 392938,
    "name": "Sleepmate Technologies    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 373259,
    "name": "SleepMed Inc    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 271509,
    "name": "SleepNet Corp    ( Hampton NH USA )",
    "country": "United States"
  },
  {
    "id": 441502,
    "name": "SleepNet Corp UK    ( Abingdon  USA )",
    "country": "United States"
  },
  {
    "id": 467006,
    "name": "Sloan    ( Franklin Park IL USA )",
    "country": "United States"
  },
  {
    "id": 401028,
    "name": "Sloan Medical    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 458703,
    "name": "SLP Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 462567,
    "name": "SMAART Medical Systems Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 370593,
    "name": "Small Beginnings Inc    ( Hesperia CA USA )",
    "country": "United States"
  },
  {
    "id": 455512,
    "name": "Small Bone Innovations Asia Pacific Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 455511,
    "name": "Small Bone Innovations Deutschland GmbH    ( Donaueschingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455509,
    "name": "Small Bone Innovations Inc    ( Morrisville PA USA )",
    "country": "United States"
  },
  {
    "id": 455510,
    "name": "Small Bone Innovations International    ( Peronnas  France )",
    "country": "France"
  },
  {
    "id": 450983,
    "name": "Small Bone Innovations LLC    ( Morrisville PA USA )",
    "country": "United States"
  },
  {
    "id": 177065,
    "name": "SMAM srl    ( Muggio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 441103,
    "name": "Smart Caregiver Corp    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 458947,
    "name": "Smart Data Solutions    ( Mendota Heights MN USA )",
    "country": "United States"
  },
  {
    "id": 464983,
    "name": "Smart Diagnostic Devices    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 456026,
    "name": "Smart Glove Corp Sdn Bhd    ( Klang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 453634,
    "name": "Smart Medical SL    ( Cordovilla (Navarra)  Spain )",
    "country": "Spain"
  },
  {
    "id": 457090,
    "name": "SMART Medical Systems Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 464402,
    "name": "Smart Medical Technology Inc    ( Darien IL USA )",
    "country": "United States"
  },
  {
    "id": 223798,
    "name": "Smart Nine Corp    ( Kawasaki  Japan )",
    "country": "Japan"
  },
  {
    "id": 461510,
    "name": "Smart Solutions Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 325607,
    "name": "Smart Support Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 292652,
    "name": "SMART Technologies Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 462560,
    "name": "Smartfit Co Ltd    ( Yangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 461144,
    "name": "SmartGene Inc    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 461141,
    "name": "SmartGene Service SARL    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454914,
    "name": "SmartInfuser    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 250072,
    "name": "SmartLight Inc    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 344925,
    "name": "SmartLight Ltd    ( Yokne'am Elit  USA )",
    "country": "United States"
  },
  {
    "id": 399261,
    "name": "SmartLink International Pty Ltd    ( Brighton  Australia )",
    "country": "Australia"
  },
  {
    "id": 149568,
    "name": "SmartPractice    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 459013,
    "name": "SmartReceipt Inc    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 393589,
    "name": "SmartSound Ultrasound Inc    ( Claremont FL USA )",
    "country": "United States"
  },
  {
    "id": 999488,
    "name": "SMC    ( Chai Wan  USA )",
    "country": "United States"
  },
  {
    "id": 347677,
    "name": "SMH Products Ltd    ( South Shields  England )",
    "country": "UK"
  },
  {
    "id": 458017,
    "name": "SMI-LabHut Inc    ( Churcham  England )",
    "country": "UK"
  },
  {
    "id": 458691,
    "name": "Smile Line USA Inc    ( Wheat Ridge CO USA )",
    "country": "United States"
  },
  {
    "id": 451750,
    "name": "SmileMakers    ( Spartanburg SC USA )",
    "country": "United States"
  },
  {
    "id": 454449,
    "name": "Smission-Cartledge Biomedical LLC    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 458362,
    "name": "Smisson-Cartledge Biomedical LLC (SCB)    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 151072,
    "name": "Smit Mobile Equipment bv    ( Oud-Beijerland  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 229004,
    "name": "Smit Roentgen    ( Eindhoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454890,
    "name": "SmitCare    ( Camberly  England )",
    "country": "UK"
  },
  {
    "id": 307320,
    "name": "Smitech (Asia) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 466331,
    "name": "Smith & Greene Co    ( Kent WA USA )",
    "country": "United States"
  },
  {
    "id": 344552,
    "name": "Smith & Nephew Endoscopy (UK)    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 397892,
    "name": "Smith & Nephew Inc (Canada) Orthopaedic    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 270765,
    "name": "Smith & Nephew Inc Endoscopy Div Smith & Nephew pl    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 266941,
    "name": "Smith & Nephew Inc Orthopaedic    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 312175,
    "name": "Smith & Nephew Inc Wound Management    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 371726,
    "name": "Smith & Nephew Kinetec SA    ( Tournes  France )",
    "country": "France"
  },
  {
    "id": 289996,
    "name": "Smith & Nephew Ltd (Hong Kong)    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 160779,
    "name": "Smith & Nephew Medical Ltd    ( Hull  England )",
    "country": "UK"
  },
  {
    "id": 139107,
    "name": "Smith & Nephew plc    (   USA )",
    "country": "United States"
  },
  {
    "id": 307321,
    "name": "Smith & Nephew Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306264,
    "name": "Smith & Nephew srl    ( Agrate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 154154,
    "name": "Smith River Biologicals    ( Ferrum VA USA )",
    "country": "United States"
  },
  {
    "id": 186365,
    "name": "Smithers Medical Products Inc    ( North Canton OH USA )",
    "country": "United States"
  },
  {
    "id": 410014,
    "name": "Smiths Detection Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 451690,
    "name": "Smiths Detection UK    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 273755,
    "name": "Smiths Group plc    (   USA )",
    "country": "United States"
  },
  {
    "id": 418056,
    "name": "Smiths Medical (South Africa) Pty Ltd    ( Croydon  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 418055,
    "name": "Smiths Medical (Southeast Asia) Ltd    ( Kwun Tong  USA )",
    "country": "United States"
  },
  {
    "id": 440773,
    "name": "Smiths Medical ASD Inc    ( Norwell MA USA )",
    "country": "United States"
  },
  {
    "id": 455334,
    "name": "Smiths Medical ASD Inc    ( Norwell MA USA )",
    "country": "United States"
  },
  {
    "id": 418062,
    "name": "Smiths Medical Australasia Pty Ltd    ( Eight Mile Plains  Australia )",
    "country": "Australia"
  },
  {
    "id": 456872,
    "name": "Smiths Medical Belgium NV    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 418061,
    "name": "Smiths Medical Benelux NV    ( Turnhout  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 418059,
    "name": "Smiths Medical Canada Ltd    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 418058,
    "name": "Smiths Medical Deutschland GmbH    ( Grasbrunn  Germany )",
    "country": "Germany"
  },
  {
    "id": 418063,
    "name": "Smiths Medical France SA    ( Rungis Cedex  France )",
    "country": "France"
  },
  {
    "id": 454922,
    "name": "Smiths Medical Instrument (Zhejiang) Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 450285,
    "name": "Smiths Medical International Ltd    ( Hythe  USA )",
    "country": "United States"
  },
  {
    "id": 405719,
    "name": "Smiths Medical Japan Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 139097,
    "name": "Smiths Medical Ltd    ( Hythe  England )",
    "country": "UK"
  },
  {
    "id": 440772,
    "name": "Smiths Medical MD Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 418064,
    "name": "Smiths Medical Nederland bv    ( Rosmalen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 418060,
    "name": "Smiths Medical Osterreich GmbH    ( Brunn am Gebirge  Austria )",
    "country": "Austria"
  },
  {
    "id": 440771,
    "name": "Smiths Medical PM Inc    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 418660,
    "name": "Smiths Medical Russia    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 450479,
    "name": "Smiths Medical Schweiz AG    ( Duebendorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418057,
    "name": "Smiths Medical Sverige AB    ( Kista  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457084,
    "name": "Smooth Fitness    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 226159,
    "name": "SMR Industries    (   USA )",
    "country": "United States"
  },
  {
    "id": 102656,
    "name": "SMS INCORPORATED    (   USA )",
    "country": "United States"
  },
  {
    "id": 282731,
    "name": "SMS Medizintechnik    ( Hannover  USA )",
    "country": "United States"
  },
  {
    "id": 455241,
    "name": "SMS Technologies    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 403001,
    "name": "SMT Health Systems Inc    ( Detroit Lakes MN USA )",
    "country": "United States"
  },
  {
    "id": 452628,
    "name": "Snaa Industries    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 271543,
    "name": "SNAP Diagnostsics LLC    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 465227,
    "name": "SnapComms    ( Santa Barbara 53 USA )",
    "country": "United States"
  },
  {
    "id": 101722,
    "name": "Snowden-Pencer Inc    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 999659,
    "name": "SNS Care Co., Ltd.    ( Goyang-si Gyeonggi-do  Korea )",
    "country": "Korea"
  },
  {
    "id": 176283,
    "name": "Snug Seat Inc    ( Matthews NC USA )",
    "country": "United States"
  },
  {
    "id": 103970,
    "name": "Snugfit Eye Patch Co    ( Green Bay WI USA )",
    "country": "United States"
  },
  {
    "id": 103341,
    "name": "So-Low Environmental Equipment Co Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 453887,
    "name": "SOAPware Inc    ( Fayetteville AR USA )",
    "country": "United States"
  },
  {
    "id": 456081,
    "name": "Sobytek Instruments    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 174425,
    "name": "Socibra Soc Coml Imp e Indl Bras Ltda    ( Rio de Janeiro-RJ  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 157750,
    "name": "Societe Franco-Neerlandaise    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 392653,
    "name": "Socochim SA    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 346999,
    "name": "Socofar    ( Central Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 171283,
    "name": "Socorex Isba SA    ( Ecublens/Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 170956,
    "name": "Sodem Diffusion SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 451558,
    "name": "Sodexho Clinical Technology Management    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 457777,
    "name": "Soehnle Professional GmbH & Co KG    ( Backnang  Germany )",
    "country": "Germany"
  },
  {
    "id": 202170,
    "name": "Soehnle-Waagen GmbH & Co KG    ( Murrhardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 151045,
    "name": "Soen NV    ( Ieper  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 152723,
    "name": "Soering GmbH Medizintechnik    ( Quickborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 369666,
    "name": "Soering Inc    ( North Richland Hill TX USA )",
    "country": "United States"
  },
  {
    "id": 293813,
    "name": "Sofaco AG Watte- und Wegwerfartikel    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 460803,
    "name": "Sofradir Inc    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 104332,
    "name": "Soft Sert Inc    ( Rybrook NY USA )",
    "country": "United States"
  },
  {
    "id": 177164,
    "name": "Softcare Corp    ( Brooks GA USA )",
    "country": "United States"
  },
  {
    "id": 461153,
    "name": "Softek Solutions Inc    ( Prairie Village KS USA )",
    "country": "United States"
  },
  {
    "id": 462778,
    "name": "Softerra USA    ( Cupertino CA USA )",
    "country": "United States"
  },
  {
    "id": 458693,
    "name": "SoftGenetics LLC    ( State College PA USA )",
    "country": "United States"
  },
  {
    "id": 458443,
    "name": "SoftLink International Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 465126,
    "name": "Softmart, Inc.    ( Downingtown 45 USA )",
    "country": "United States"
  },
  {
    "id": 461515,
    "name": "Softmedical Inc    ( St Laurent  Canada )",
    "country": "Canada"
  },
  {
    "id": 462901,
    "name": "SoftTech Health    (   USA )",
    "country": "United States"
  },
  {
    "id": 325608,
    "name": "Softub Inc    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 459005,
    "name": "Software Testing Solutions    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 466503,
    "name": "SoftWriters Inc    ( Allison Park PA USA )",
    "country": "United States"
  },
  {
    "id": 453591,
    "name": "Sohniks Endoscopy Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 149175,
    "name": "Soken Products Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 999977,
    "name": "SOLAHD    (   USA )",
    "country": "United States"
  },
  {
    "id": 459097,
    "name": "Solaire Medical    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 457612,
    "name": "Solana Surgical    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 456113,
    "name": "Solar Korea Co Ltd    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 417544,
    "name": "Solarc Systems Inc    ( Barrie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457411,
    "name": "Solaris Medical Technology Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 460797,
    "name": "SolarWinds    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 356897,
    "name": "SolCom    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 456142,
    "name": "SOLE Fitness    ( Jonesboro AR USA )",
    "country": "United States"
  },
  {
    "id": 466272,
    "name": "Soleran Inc    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 108093,
    "name": "Solid State Sonics & Electronics Inc    ( Topeka KS USA )",
    "country": "United States"
  },
  {
    "id": 459456,
    "name": "Solid-Look Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 108094,
    "name": "Solidstate Controls Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 458501,
    "name": "Solo-Step    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 101724,
    "name": "Solon Mfg Co    ( Skowhegan ME USA )",
    "country": "United States"
  },
  {
    "id": 107917,
    "name": "Solos Endoscopy Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 439913,
    "name": "Solotop Eesti OU    ( Tallinn  Estonia )",
    "country": "Estonia"
  },
  {
    "id": 325041,
    "name": "Solotop Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 458109,
    "name": "Solstice Corp    ( Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 455539,
    "name": "Solta Medical    ( Hayward CA USA )",
    "country": "USA"
  },
  {
    "id": 102657,
    "name": "Soltec Corp    ( San Fernando CA USA )",
    "country": "United States"
  },
  {
    "id": 461281,
    "name": "Soltrix Technology Solutions Inc    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 457652,
    "name": "Soluble Systems LLC    ( Newport News VA USA )",
    "country": "United States"
  },
  {
    "id": 452179,
    "name": "Solucient    ( Evanston IL USA )",
    "country": "United States"
  },
  {
    "id": 454011,
    "name": "Soluscope GmbH    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454010,
    "name": "Soluscope International Trading (Shanghai) Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 398830,
    "name": "Soluscope SAS    ( Aubagne  France )",
    "country": "France"
  },
  {
    "id": 104389,
    "name": "Solutek Corp    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 339593,
    "name": "Solvay Draka bv Solmed Sales Dept    ( Enkhuizen  USA )",
    "country": "United States"
  },
  {
    "id": 452122,
    "name": "Solventus    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 160995,
    "name": "SOM Biofeedback    ( Murrhardt  Germany )",
    "country": "Germany"
  },
  {
    "id": 464076,
    "name": "Soma Access Systems LLC    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 155937,
    "name": "Soma Technology Inc    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 359571,
    "name": "Somagen Diagnostic Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 108503,
    "name": "Somanetics Corp    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 462551,
    "name": "Somat Company    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 104776,
    "name": "Somatics LLC    ( Lake Bluff IL USA )",
    "country": "United States"
  },
  {
    "id": 323715,
    "name": "Sometec    ( Soresnes Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 456372,
    "name": "Sometech Inc    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459103,
    "name": "SomnoComplete LLC    ( Newnan GA USA )",
    "country": "United States"
  },
  {
    "id": 999750,
    "name": "SonaCare Medical    ( Charlotte NC USA )",
    "country": "USA"
  },
  {
    "id": 465073,
    "name": "SonaCare Medical, LLC    ( Charlotte 80 USA )",
    "country": "United States"
  },
  {
    "id": 399231,
    "name": "Sonage Inc    ( Chatsworth CA USA )",
    "country": "United States"
  },
  {
    "id": 362122,
    "name": "SonaMed Corp    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 460918,
    "name": "Sonation GmbH    ( Biberach  Germany )",
    "country": "Germany"
  },
  {
    "id": 293701,
    "name": "Sonderegger AG    ( Bronschhofen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 465011,
    "name": "Sonexis Technology Inc    ( Monroeville PA USA )",
    "country": "United States"
  },
  {
    "id": 462809,
    "name": "Sonic Foundry    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 338628,
    "name": "SONIC Innovations    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 102331,
    "name": "Sonic Systems Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 280738,
    "name": "Sonic Ultraschallanlagen GmbH    ( Darmstadt  USA )",
    "country": "United States"
  },
  {
    "id": 101725,
    "name": "Sonicaid Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 174427,
    "name": "Soniclear Ind Com Imp Exp Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 101726,
    "name": "Sonicor Instrument Corp    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 104172,
    "name": "Sonics & Materials Inc    ( Newtown CT USA )",
    "country": "United States"
  },
  {
    "id": 459354,
    "name": "SonicWise Ultrasonics    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 456859,
    "name": "SONICWORLD Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 465172,
    "name": "SONIFI Healthcare, Inc.    ( Sioux Falls, 39 USA )",
    "country": "United States"
  },
  {
    "id": 451452,
    "name": "Sonitor Technologies AS    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 451451,
    "name": "Sonitor Technologies Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 456811,
    "name": "Sonitus Medical Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 170831,
    "name": "Sonntek Inc    ( Upper Saddle River NJ USA )",
    "country": "United States"
  },
  {
    "id": 106502,
    "name": "Sono-Tek Corp    ( Milton NY USA )",
    "country": "United States"
  },
  {
    "id": 458442,
    "name": "Sonocine Inc    ( Reno NV USA )",
    "country": "United States"
  },
  {
    "id": 108767,
    "name": "SonoDepot    ( St Cloud FL USA )",
    "country": "United States"
  },
  {
    "id": 174987,
    "name": "Sonogage    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 279636,
    "name": "Sonogel Vertriebs GmbH    ( Bad Camberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 454275,
    "name": "Sonoid Massage Products    ( Silver Creek NY USA )",
    "country": "United States"
  },
  {
    "id": 459444,
    "name": "Sonoma Orthopedic Products Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 172392,
    "name": "Sonomark Kft    ( Esztergom  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 293368,
    "name": "Sonomed Arzt- Spital- und Laborbedarf    ( Geroldswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 104453,
    "name": "Sonomed Inc    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 332256,
    "name": "Sonora Medical Systems Inc    ( Longmont CO USA )",
    "country": "United States"
  },
  {
    "id": 457462,
    "name": "Sonoring Deutschland    ( Holzwickede  Germany )",
    "country": "Germany"
  },
  {
    "id": 103343,
    "name": "Sonoscan Inc    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 453794,
    "name": "Sonoscanner Sarl    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 453877,
    "name": "Sonoscanner Sarl    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 451499,
    "name": "SonoScape Co Ltd    ( Shenzhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451500,
    "name": "SonoScape USA    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 465107,
    "name": "SonoSim Inc    ( Santa Monica CA USA )",
    "country": "United States"
  },
  {
    "id": 407190,
    "name": "SonoSite China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 371916,
    "name": "SonoSite China Ltd (Hong Kong)    ( Wanchai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 407191,
    "name": "SonoSite do Brasil    ( Brasilia-DF  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 407187,
    "name": "SonoSite France    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 407188,
    "name": "SonoSite GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 407189,
    "name": "SonoSite Iberica SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 340440,
    "name": "SonoSite Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 407185,
    "name": "SonoSite Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 108822,
    "name": "Sonotech Inc    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 284340,
    "name": "Sonotechnik GmbH    ( Markt Schwaben  Germany )",
    "country": "Germany"
  },
  {
    "id": 462575,
    "name": "Sonotrax    ( Delta BC Canada )",
    "country": "Canada"
  },
  {
    "id": 156025,
    "name": "Sonotron Holding AG    ( Zug 2  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 260777,
    "name": "Sonotron Ultrasound Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 426211,
    "name": "Sonovation Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 453817,
    "name": "Sonovation Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 174988,
    "name": "Sontec Instruments Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 452599,
    "name": "Sontec Instruments Inc    ( Centennial CO USA )",
    "country": "United States"
  },
  {
    "id": 332257,
    "name": "Sonultra Corp    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 351468,
    "name": "SONUS Pharmaceuticals Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 458546,
    "name": "Sony Biotechnology Inc    ( Champaign IL USA )",
    "country": "United States"
  },
  {
    "id": 104968,
    "name": "Sony Corp of America    ( New York NY USA )",
    "country": "USA"
  },
  {
    "id": 283629,
    "name": "Sony Deutschland GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 182624,
    "name": "Sony Electronics Inc    ( Park Ridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 451755,
    "name": "Sony Energy Devices Corp    ( Koriyama  Japan )",
    "country": "Japan"
  },
  {
    "id": 307322,
    "name": "Sony Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 450861,
    "name": "SOOIL Development Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452710,
    "name": "SOOIL USA    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 272830,
    "name": "SophisView Technologies Ltd    ( Jerusalem  Israel )",
    "country": "Israel"
  },
  {
    "id": 453844,
    "name": "Sophrona Solutions Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 284033,
    "name": "Sophysa    ( Orsay Cedex  France )",
    "country": "France"
  },
  {
    "id": 399024,
    "name": "SOPRO    ( La Ciotat Cedex  France )",
    "country": "France"
  },
  {
    "id": 452355,
    "name": "SOPRO-COMEG    ( Mount Laurel NJ USA )",
    "country": "United States"
  },
  {
    "id": 457805,
    "name": "SOPRO-COMEG (Germany)    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 150940,
    "name": "Sopur GmbH    ( Malsch/Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 401959,
    "name": "Sorba Medical Systems Inc    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 156432,
    "name": "SOREDEX Finland    ( Tuusula  Finland )",
    "country": "Finland"
  },
  {
    "id": 451357,
    "name": "SOREDEX GmbH    ( Schutterwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 156431,
    "name": "SOREDEX USA    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 455335,
    "name": "Sorenson BioScience Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 232609,
    "name": "Sorenson Laboratories Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 340060,
    "name": "Sorenson Medical    ( West Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 160998,
    "name": "Sorg Rollstuhltechnik GmbH & Co KG    ( Oberhausen-Rheinhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 431603,
    "name": "Sorice Inc    ( Perkasie PA USA )",
    "country": "United States"
  },
  {
    "id": 417263,
    "name": "Sorin Biomedica Belgium    ( Bruxelles  USA )",
    "country": "United States"
  },
  {
    "id": 150865,
    "name": "Sorin Biomedica Canada Inc    ( Toronto ON USA )",
    "country": "United States"
  },
  {
    "id": 149026,
    "name": "Sorin Biomedica Cardio SpA    ( Saluggia (VC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 417265,
    "name": "Sorin Biomedica Cardio SpA France    ( Guyancourt  USA )",
    "country": "United States"
  },
  {
    "id": 188103,
    "name": "Sorin Biomedica Cardio SpA UK    ( Gloucester  England )",
    "country": "UK"
  },
  {
    "id": 417266,
    "name": "Sorin Biomedica Espana SA    ( Sant Cugat del Valles  Spain )",
    "country": "Spain"
  },
  {
    "id": 417264,
    "name": "Sorin Biomedica Finland Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 417261,
    "name": "Sorin Biomedica Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 417267,
    "name": "Sorin Biomedica Nederland NV    ( Nieuwegein  USA )",
    "country": "United States"
  },
  {
    "id": 454957,
    "name": "Sorin CRM SAS    ( Clamart  France )",
    "country": "France"
  },
  {
    "id": 453105,
    "name": "Sorin Group Australia Pty    ( Milton  Australia )",
    "country": "Australia"
  },
  {
    "id": 450936,
    "name": "Sorin Group Canada    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 453107,
    "name": "Sorin Group Canada Inc    ( Markhan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457945,
    "name": "Sorin Group CRM USA Inc    ( Arvada CO USA )",
    "country": "United States"
  },
  {
    "id": 453102,
    "name": "Sorin Group Deutschland    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453106,
    "name": "Sorin Group International AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 440690,
    "name": "Sorin Group Italia srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453103,
    "name": "Sorin Group Nederland bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453108,
    "name": "Sorin Group Scandinavia    ( Sollentuna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 440691,
    "name": "Sorin SpA    (   Italy )",
    "country": "Italy"
  },
  {
    "id": 451729,
    "name": "Sorisa    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 452401,
    "name": "Sorna Corp    ( Eagan MN USA )",
    "country": "United States"
  },
  {
    "id": 457788,
    "name": "Sort Life Out Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 289635,
    "name": "Sorvall Products LP    (   USA )",
    "country": "United States"
  },
  {
    "id": 324225,
    "name": "SOS Products (Manchester) Ltd    ( Urmston  England )",
    "country": "UK"
  },
  {
    "id": 105736,
    "name": "SOS Technologies Inc    ( Sharpsburg GA USA )",
    "country": "United States"
  },
  {
    "id": 466308,
    "name": "Sotcher Measurement Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 456569,
    "name": "Sotera Wireless    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458340,
    "name": "SOTI Inc North America    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 287622,
    "name": "Soubeko GmbH    ( Zahsow  Germany )",
    "country": "Germany"
  },
  {
    "id": 292675,
    "name": "Sound Control Technologies Inc    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 458500,
    "name": "Sound Ergonomics LLC    ( Kenmore WA USA )",
    "country": "United States"
  },
  {
    "id": 344604,
    "name": "Sound Imaging Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 367463,
    "name": "Sound Surgical Technologies LLC    ( Louisville CO USA )",
    "country": "United States"
  },
  {
    "id": 462910,
    "name": "Sound-Eklin    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 462995,
    "name": "Source BioScience plc    ( Nottingham  England )",
    "country": "UK"
  },
  {
    "id": 453778,
    "name": "Source Distributors Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 234525,
    "name": "Source Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 428374,
    "name": "Source Medical    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 346728,
    "name": "Source Medical Corp    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 454490,
    "name": "Source Production & Equipment Co Inc    ( St Rose LA USA )",
    "country": "United States"
  },
  {
    "id": 193379,
    "name": "Source Scientific LLC    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 332258,
    "name": "Source Technologies Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 999937,
    "name": "Source-Ray    (   USA )",
    "country": "United States"
  },
  {
    "id": 459376,
    "name": "Source-Ray Inc    ( Ronkonkoma NY USA )",
    "country": "United States"
  },
  {
    "id": 460920,
    "name": "Sourcefire Inc    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 459388,
    "name": "SourceHOV LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 457939,
    "name": "Sourcemark LLC    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 431350,
    "name": "SourceOne Healthcare Technologies    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 458028,
    "name": "South Coast Enterprises    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 453489,
    "name": "South Dakota Lions Eye Bank    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 193497,
    "name": "South East Instruments Corp    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 106866,
    "name": "South Plains Biomedical Services Inc    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 177017,
    "name": "South Shore Fire & Safety Equipment Distributors I    ( East Meadow NY USA )",
    "country": "United States"
  },
  {
    "id": 462904,
    "name": "Southbend    ( Fuquay Varina NC USA )",
    "country": "United States"
  },
  {
    "id": 472811,
    "name": "Southeast Pathology Instrument Services    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 458146,
    "name": "Southeastern Medical Supply Inc    ( Dania Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 462842,
    "name": "Southeastern Network and Printing Systems Inc    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 462752,
    "name": "Southeastern Scientific    ( Winter Garden FL USA )",
    "country": "United States"
  },
  {
    "id": 451778,
    "name": "Southeastern Technology Inc    ( Murfreesboro TN USA )",
    "country": "United States"
  },
  {
    "id": 451856,
    "name": "Southern Anesthesia & Surgical    ( West Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 465041,
    "name": "Southern California Scientific Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 455377,
    "name": "Southern Care Systems Ltd    ( Ringwood  England )",
    "country": "UK"
  },
  {
    "id": 348515,
    "name": "Southern Dental Industries GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 333609,
    "name": "Southern Dental Industries Inc    ( Bensenville IL USA )",
    "country": "United States"
  },
  {
    "id": 348512,
    "name": "Southern Dental Industries Ltd    ( Bayswater  Australia )",
    "country": "Australia"
  },
  {
    "id": 348514,
    "name": "Southern Dental Industries Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 458737,
    "name": "Southern Implants Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 106867,
    "name": "Southern Medical Services    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 106109,
    "name": "Southern Medical Services Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 108305,
    "name": "Southern Prosthetic Supply Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 375288,
    "name": "Southern Respiratory Inc    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 455520,
    "name": "Southern Spine LLC    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 460676,
    "name": "Southern Vision Systems Inc    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 434191,
    "name": "Southern Welding Supply Inc    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 184651,
    "name": "Southmedic Inc    ( Barrie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108330,
    "name": "Southpaw Enterprises Inc    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 456086,
    "name": "SouthPointe Surgical Supply Inc    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 146764,
    "name": "Southwest Dental Works    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 363905,
    "name": "Southwest Imaging    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 455609,
    "name": "Southwest Medical    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 371556,
    "name": "Southwest Medical Corp    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 457125,
    "name": "SouthWest Medical Resources    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 458943,
    "name": "Southwest Sign Group    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 458159,
    "name": "Southwest Solutions Group    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 106558,
    "name": "Southwest Technologies Inc    ( North Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 186744,
    "name": "Southwest X-Ray Co    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 108096,
    "name": "Southwestern Biomedical Electronics Inc    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 465115,
    "name": "Sovereign Medical, Inc.    ( Hillsborough 62 USA )",
    "country": "United States"
  },
  {
    "id": 451450,
    "name": "Sovereign Tracking Systems LLC    ( Brick NJ USA )",
    "country": "United States"
  },
  {
    "id": 305762,
    "name": "Soveta Baltica Vanda    ( Vilnius  Lithuania )",
    "country": "Lithuania"
  },
  {
    "id": 289520,
    "name": "Soveta srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454640,
    "name": "SOWSIA Healthcare Solutions Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 172843,
    "name": "Soxil SpA    ( Segrate MI  USA )",
    "country": "United States"
  },
  {
    "id": 347006,
    "name": "Soyee Products Inc    ( Thompson CT USA )",
    "country": "United States"
  },
  {
    "id": 454245,
    "name": "Soyee Products Inc    ( Thompson CT USA )",
    "country": "United States"
  },
  {
    "id": 457609,
    "name": "Sozius Industrie- und Prozesstechnik GmbH    ( Langelsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 283772,
    "name": "Sozius-Messgeraete Helmut Schaening    ( Langelsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 178300,
    "name": "SP Industries Inc    ( Warminster PA USA )",
    "country": "United States"
  },
  {
    "id": 462608,
    "name": "Space Saving Solutions    ( Lexington SC USA )",
    "country": "United States"
  },
  {
    "id": 162905,
    "name": "Spacelabs (Singapore) Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 453660,
    "name": "Spacelabs Healthcare (Canada) Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453653,
    "name": "Spacelabs Healthcare (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452748,
    "name": "Spacelabs Healthcare GmbH    ( Feucht  Germany )",
    "country": "Germany"
  },
  {
    "id": 453659,
    "name": "Spacelabs Healthcare Ltd (Greece)    ( Athinia (Eliniko)  Greece )",
    "country": "Greece"
  },
  {
    "id": 453658,
    "name": "Spacelabs Healthcare Ltd (Italy)    ( S Giovanni Lupatoto (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453654,
    "name": "Spacelabs Healthcare Pte Ltd (Beijing Representati    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 453657,
    "name": "Spacelabs Healthcare SAS (France)    ( Creteil Cedex  France )",
    "country": "France"
  },
  {
    "id": 453656,
    "name": "Spacelabs Healthcare Solutions Pvt Ltd (India)    ( Hyderabad  India )",
    "country": "India"
  },
  {
    "id": 272810,
    "name": "Spacelabs Medical BV (Belgium)    ( Diegem  USA )",
    "country": "United States"
  },
  {
    "id": 283268,
    "name": "Spacelabs Medical GmbH    ( Meerbuscher  USA )",
    "country": "United States"
  },
  {
    "id": 101758,
    "name": "Spacelabs Medical Inc    ( Issaquah WA USA )",
    "country": "United States"
  },
  {
    "id": 393750,
    "name": "SpaceLabs Medical Inc (India)    ( New Delhi  USA )",
    "country": "United States"
  },
  {
    "id": 343476,
    "name": "Spacelabs Medical Instruments (Tianjin) Co Ltd    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 360588,
    "name": "Spacelabs Medical Ltd (Hong Kong)    ( Kowloon  USA )",
    "country": "United States"
  },
  {
    "id": 360061,
    "name": "Spacelabs Medical Ltd (UK)    ( Crawley  USA )",
    "country": "United States"
  },
  {
    "id": 185699,
    "name": "Spacelabs Medical Ltd-Taiwan    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 236390,
    "name": "Spacelabs Medical Products GmbH (Austria)    ( Wien  USA )",
    "country": "United States"
  },
  {
    "id": 277971,
    "name": "Spacelabs Medical SAS    ( Limonest  USA )",
    "country": "United States"
  },
  {
    "id": 354518,
    "name": "SpaceLabs Medical srl    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 340227,
    "name": "SpaceNow! Corp    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 102659,
    "name": "Spacesaver Corp    ( Fort Atkinson WI USA )",
    "country": "United States"
  },
  {
    "id": 174738,
    "name": "Spadina Industries Inc    ( Saskatoon SK Canada )",
    "country": "Canada"
  },
  {
    "id": 282671,
    "name": "Spahn Reha GmbH    ( Hanau  Germany )",
    "country": "Germany"
  },
  {
    "id": 103345,
    "name": "Span-America Medical Systems Inc    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 282008,
    "name": "Spannbeton Hellweg    ( Geseke  Germany )",
    "country": "Germany"
  },
  {
    "id": 452598,
    "name": "Sparco Inc    ( Mission TX USA )",
    "country": "United States"
  },
  {
    "id": 452112,
    "name": "Spark & Cannon Australasia Pty Ltd    ( Perth  Australia )",
    "country": "Australia"
  },
  {
    "id": 457957,
    "name": "Spark Holland BV    ( Emmen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 174991,
    "name": "SPARTA Composite Products Operation    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 466229,
    "name": "Spartan Bioscience Inc    ( Ottawa 6 USA )",
    "country": "United States"
  },
  {
    "id": 452597,
    "name": "Spartan Chemical Co Inc    ( Maumee OH USA )",
    "country": "United States"
  },
  {
    "id": 454156,
    "name": "SPBS Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 238937,
    "name": "Spead Doppler-Systeme Vertriebs GmbH    ( Kehl am Rhein  Germany )",
    "country": "Germany"
  },
  {
    "id": 366539,
    "name": "Speble Indentification International Pty Ltd    ( Midrand  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 305597,
    "name": "Specaway Pty Ltd    ( St Pauls  Australia )",
    "country": "Australia"
  },
  {
    "id": 457365,
    "name": "Special Products Ltd    ( Weybridge  England )",
    "country": "UK"
  },
  {
    "id": 356976,
    "name": "Special Protectors Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452416,
    "name": "Specialised Orthotic Services    ( Tutbury  England )",
    "country": "UK"
  },
  {
    "id": 462606,
    "name": "Specialists On Call    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 426494,
    "name": "Specialized Health Products Inc    ( Bountiful UT USA )",
    "country": "United States"
  },
  {
    "id": 235303,
    "name": "Specialized Medical Devices    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 451811,
    "name": "Specialized Printed Forms    ( Caledonia NY USA )",
    "country": "United States"
  },
  {
    "id": 332263,
    "name": "Specialized Products Co    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 450629,
    "name": "Specialized Technologies    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 104953,
    "name": "Specialties Electronic Co    ( Mount Holly NJ USA )",
    "country": "United States"
  },
  {
    "id": 454459,
    "name": "Specialty Bottle    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 391895,
    "name": "Specialty Carts Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 186746,
    "name": "Specialty Electronic Repair Inc    ( Lenoir City TN USA )",
    "country": "United States"
  },
  {
    "id": 451963,
    "name": "Specialty Gases of America    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 175669,
    "name": "Specialty Hearse & Ambulance Sales Corp    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 451314,
    "name": "Specialty Laboratories    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 456636,
    "name": "Specialty Medical Systems    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 105819,
    "name": "Specialty Surgical Instrumentation Inc    ( Antioch TN USA )",
    "country": "United States"
  },
  {
    "id": 332753,
    "name": "Specialty Surgical Products    ( Victor MT USA )",
    "country": "United States"
  },
  {
    "id": 456804,
    "name": "Specialty Therapy Equipment Inc    ( Towson MD USA )",
    "country": "United States"
  },
  {
    "id": 458621,
    "name": "SpecialtyCare    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 360632,
    "name": "Speclinc    ( Dyersburg TN USA )",
    "country": "United States"
  },
  {
    "id": 454977,
    "name": "Spectra Cryogenic Systems Pvt Ltd    ( Rajasthan  India )",
    "country": "India"
  },
  {
    "id": 999943,
    "name": "Spectra Gases, Inc    ( Branchburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 107475,
    "name": "Spectra Industries Corp a Div of IEM    ( Glenolden PA USA )",
    "country": "United States"
  },
  {
    "id": 458779,
    "name": "Spectra Laboratories    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 416004,
    "name": "Spectra Medical Pty Ltd    ( Ringwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 466279,
    "name": "Spectra Services    ( Ontario NY USA )",
    "country": "United States"
  },
  {
    "id": 161000,
    "name": "Spectra-Physics GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 397913,
    "name": "Spectra-Physics Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 292676,
    "name": "Spectracom Corp    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 461135,
    "name": "SpectraCorp Technologies Group Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 391869,
    "name": "Spectral Instruments    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 173432,
    "name": "SpectraLink    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 376094,
    "name": "SpectraLink International Corp    ( Bracknell  USA )",
    "country": "United States"
  },
  {
    "id": 457487,
    "name": "Spectramed Inc    ( Mount Vernon OH USA )",
    "country": "United States"
  },
  {
    "id": 106868,
    "name": "Spectranetics Corp    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 454094,
    "name": "Spectranetics International bv    ( Leusden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 234528,
    "name": "Spectrasoft Inc    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 458483,
    "name": "Spectrecology    ( Jasper GA USA )",
    "country": "United States"
  },
  {
    "id": 103893,
    "name": "Spectrex Corp    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 394704,
    "name": "Spectris plc    ( Egham  England )",
    "country": "UK"
  },
  {
    "id": 366856,
    "name": "Spectrolab Ltd    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 461473,
    "name": "SPECTROM Clinical Systems    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 106334,
    "name": "Spectron Corp    ( Stanwood WA USA )",
    "country": "United States"
  },
  {
    "id": 285186,
    "name": "Spectron Optik GmbH    ( Oestrich-Winkel  Germany )",
    "country": "Germany"
  },
  {
    "id": 101729,
    "name": "Spectronics Corp    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 453795,
    "name": "Spectros Corp    ( Portola Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 458440,
    "name": "Spectros Corp    ( Portola CA USA )",
    "country": "United States"
  },
  {
    "id": 175965,
    "name": "Spectrum AeroMed    (   USA )",
    "country": "United States"
  },
  {
    "id": 452254,
    "name": "Spectrum Dallas    ( Irving TX USA )",
    "country": "United States"
  },
  {
    "id": 452415,
    "name": "Spectrum Designs Medical Inc    ( Carpinteria CA USA )",
    "country": "United States"
  },
  {
    "id": 451975,
    "name": "Spectrum Dynamics (Israel) Ltd    ( Caesarea  Israel )",
    "country": "Israel"
  },
  {
    "id": 451974,
    "name": "Spectrum Dynamics (USA) Inc    ( Danville CA USA )",
    "country": "United States"
  },
  {
    "id": 457530,
    "name": "Spectrum Instruments Inc    ( San Dimas CA USA )",
    "country": "United States"
  },
  {
    "id": 171216,
    "name": "Spectrum Lasers    (   USA )",
    "country": "United States"
  },
  {
    "id": 454314,
    "name": "Spectrum Medical Inc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 455894,
    "name": "Spectrum Medical Ltd    ( Gloucester  England )",
    "country": "UK"
  },
  {
    "id": 462607,
    "name": "Spectrum Ophthalmics Inc    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 177167,
    "name": "Spectrum Products LLC    ( Missoula MT USA )",
    "country": "United States"
  },
  {
    "id": 466295,
    "name": "Spectrum Scientifics    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 451847,
    "name": "Spectrum Surgical Instruments Corp    ( Stow OH USA )",
    "country": "United States"
  },
  {
    "id": 108085,
    "name": "Spectrum Technologies Inc    ( Elysburg PA USA )",
    "country": "United States"
  },
  {
    "id": 457729,
    "name": "Spectrum UK    ( Macclesfield  England )",
    "country": "UK"
  },
  {
    "id": 303864,
    "name": "SpectRx Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 415813,
    "name": "Speech Machines plc    ( Malvern  England )",
    "country": "UK"
  },
  {
    "id": 104326,
    "name": "Speed Orthodontic Supplies    ( Cambridge ON Canada )",
    "country": "Canada"
  },
  {
    "id": 287757,
    "name": "Speedway Surgical Co    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 460695,
    "name": "ST Imaging    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 462604,
    "name": "Spellbound Development Group In    ( Newport Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 139277,
    "name": "Spembly Medical Ltd    ( Andover  England )",
    "country": "UK"
  },
  {
    "id": 172514,
    "name": "Spencer Italia Life Support srl    ( Collecchio (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 440639,
    "name": "Spencer Technologies Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 324860,
    "name": "Spenco Healthcare International Ltd    ( Horsham  England )",
    "country": "UK"
  },
  {
    "id": 101731,
    "name": "Spenco Medical Corp    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 151032,
    "name": "Spengler SAS (VM Tech group)    ( Cachan  France )",
    "country": "France"
  },
  {
    "id": 456037,
    "name": "Spenser Glove Mfg Berhad    ( Klang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 999482,
    "name": "Sper Scientific    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 452480,
    "name": "Sperian Protection (Australia)    ( Dingley  Australia )",
    "country": "Australia"
  },
  {
    "id": 452477,
    "name": "Sperian Protection (France)    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 452478,
    "name": "Sperian Protection (USA)    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 452479,
    "name": "Sperian Protection GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 103564,
    "name": "Sperzel of Lexington    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 458092,
    "name": "SPEware Corp    ( Baldwin Park CA USA )",
    "country": "United States"
  },
  {
    "id": 457355,
    "name": "Spezialdruckerei Mathias Lueck    ( Heinsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 281399,
    "name": "Spezialfabrik fuer Krankengehstoecke Ernst Herzog    ( Eschwege  Germany )",
    "country": "Germany"
  },
  {
    "id": 461157,
    "name": "SPG International LLC    ( Convington GA USA )",
    "country": "United States"
  },
  {
    "id": 417788,
    "name": "Sphere Health Systems    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 452309,
    "name": "Spheris    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 416523,
    "name": "Spherotech Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 428180,
    "name": "SPI Supplies    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 176920,
    "name": "Spiegelberg GmbH & Co KG    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457156,
    "name": "Spierings Medische Techniek    ( Nijmegen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453984,
    "name": "Spiggle & Theis Medizintechnik GmbH    ( Overath  Germany )",
    "country": "Germany"
  },
  {
    "id": 451720,
    "name": "Spin Life    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 459447,
    "name": "Spinal Elements Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 465159,
    "name": "Spinal Modulation Inc    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 457827,
    "name": "Spinal Solutions LLC    ( Murrieta CA USA )",
    "country": "United States"
  },
  {
    "id": 457701,
    "name": "Spinal USA    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 451145,
    "name": "SpinalGraft Technologies LLC    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 455516,
    "name": "Spine Wave Inc    ( Shelton CT USA )",
    "country": "United States"
  },
  {
    "id": 358138,
    "name": "Spine-issimus Ltd    ( Saffron Walden  England )",
    "country": "UK"
  },
  {
    "id": 457512,
    "name": "SpineFrontier Inc    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 455221,
    "name": "SpineMatrix Inc    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 458107,
    "name": "SpineNet LLC    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 382745,
    "name": "Spineology Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 457746,
    "name": "SpineSmith    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 459433,
    "name": "SpineSouce Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 456875,
    "name": "Spinetronics    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 459435,
    "name": "SpineVision SA    ( Antony Cedex  France )",
    "country": "France"
  },
  {
    "id": 457443,
    "name": "Spira Footwear Inc    ( El Paso TX USA )",
    "country": "United States"
  },
  {
    "id": 415433,
    "name": "Spira Oy Hengityshoitokeskus    ( Hameenlinna  Finland )",
    "country": "Finland"
  },
  {
    "id": 360429,
    "name": "Spiral Biotech Inc    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 459065,
    "name": "Spiral Software Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 999711,
    "name": "Spiral Systems Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 458895,
    "name": "Spirax Sarco Inc    ( Blythewood SC USA )",
    "country": "United States"
  },
  {
    "id": 451747,
    "name": "Spire Biomedical Inc    ( Bedford MA USA )",
    "country": "United States"
  },
  {
    "id": 452596,
    "name": "Spirit Creations Inc    ( Murrysville PA USA )",
    "country": "United States"
  },
  {
    "id": 457942,
    "name": "Spirit Fitness    ( Jonesboro AR USA )",
    "country": "United States"
  },
  {
    "id": 456126,
    "name": "Spirit Medical Co Ltd    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 178563,
    "name": "Spirometrics Europe    ( Meopham  England )",
    "country": "UK"
  },
  {
    "id": 104522,
    "name": "Spirometrics Medical Equipment Co    ( Gray ME USA )",
    "country": "United States"
  },
  {
    "id": 103684,
    "name": "Spirotech Corp    ( Snellville GA USA )",
    "country": "United States"
  },
  {
    "id": 363401,
    "name": "SPL Elektronik GmbH    ( Brieselang  Germany )",
    "country": "Germany"
  },
  {
    "id": 148523,
    "name": "Splash Shield    ( Uniontown PA USA )",
    "country": "United States"
  },
  {
    "id": 465098,
    "name": "Splunk Inc.    ( San Francisco 25 USA )",
    "country": "United States"
  },
  {
    "id": 457159,
    "name": "SPO Medical Systems    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 451104,
    "name": "SPO2.COM    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 185568,
    "name": "Spoina Spoldzielnia Pracy Sprzetu Medycznego    ( Lublin  Poland )",
    "country": "Poland"
  },
  {
    "id": 466709,
    "name": "Spok Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 103143,
    "name": "Sporicidin International    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 298689,
    "name": "Sporlastic GmbH    ( Nuertingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 323959,
    "name": "Sport Engineering    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 306764,
    "name": "Sport-Thieme GmbH    ( Grasleben  Germany )",
    "country": "Germany"
  },
  {
    "id": 454966,
    "name": "Sportaid    ( Loganville GA USA )",
    "country": "United States"
  },
  {
    "id": 319221,
    "name": "Sportime    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 458461,
    "name": "SportKAT LLC    ( Fallbrook CA USA )",
    "country": "United States"
  },
  {
    "id": 999771,
    "name": "SportKAT, LLC    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 284470,
    "name": "Sporto-Med GmbH    ( Floersheim-Dalsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 999441,
    "name": "Sports Art Fitness    ( Tainan City Taiwan Republic of China China )",
    "country": "China"
  },
  {
    "id": 468409,
    "name": "Sports Motion Inc    ( Cardiff CA USA )",
    "country": "United States"
  },
  {
    "id": 374307,
    "name": "Sports Resource Group Inc    ( Hawthorne NY USA )",
    "country": "United States"
  },
  {
    "id": 435447,
    "name": "SportsArt America    ( Woodinville WA USA )",
    "country": "United States"
  },
  {
    "id": 435450,
    "name": "SportsArt Worldwide    ( Tainan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456335,
    "name": "Spot Imaging Solutions    ( Sterling Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 106871,
    "name": "Sprague Magnetics Inc    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 279386,
    "name": "Spranz GmbH    ( Aalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462956,
    "name": "Spring Health Products Inc    ( Norristown PA USA )",
    "country": "United States"
  },
  {
    "id": 454611,
    "name": "Spring Medical Systems    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 457446,
    "name": "SpringFlex    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 347258,
    "name": "Springs Industries Inc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 461463,
    "name": "Springs Window Fashions LLC    ( Montgomery PA USA )",
    "country": "United States"
  },
  {
    "id": 107678,
    "name": "SPS Medical Supply Corp    ( Rush NY USA )",
    "country": "United States"
  },
  {
    "id": 337417,
    "name": "SPSS Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 457174,
    "name": "Spycor Construction Systems LLC    ( Mount Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 175968,
    "name": "Spyderco Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 441573,
    "name": "Spymaster    ( London  England )",
    "country": "UK"
  },
  {
    "id": 363402,
    "name": "Square D Group/Schneider Electric    ( Palatine IL USA )",
    "country": "United States"
  },
  {
    "id": 392253,
    "name": "Squire-Cogswell/Aeros Instruments Inc    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 454848,
    "name": "SR Equipment GmbH    ( Paderborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 101735,
    "name": "SR Instruments Inc    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 458666,
    "name": "SR Research Ltd    ( Kanata ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456837,
    "name": "SR Smith LLC    ( Canby OR USA )",
    "country": "United States"
  },
  {
    "id": 467166,
    "name": "SRCL    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 467160,
    "name": "SRCL (Ireland)    ( Dublin 12  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 467165,
    "name": "SRCL Consenur    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 177060,
    "name": "SRD Medical Ltd    ( Misgav  USA )",
    "country": "United States"
  },
  {
    "id": 461472,
    "name": "SREE Medical Systems    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 459377,
    "name": "SRI Instruments    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 465078,
    "name": "SRI Instruments Europe GmbH    ( Bad Honnef  Germany )",
    "country": "Germany"
  },
  {
    "id": 457315,
    "name": "SRI Surgical    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 393224,
    "name": "SRI/Surigal Express Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 107889,
    "name": "Sroufe Healthcare Products Inc    ( Ligonier IN USA )",
    "country": "United States"
  },
  {
    "id": 441647,
    "name": "SRR Surgical Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 364332,
    "name": "SRS Medical Corp    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 461469,
    "name": "SRSsoft    ( Montvale NJ USA )",
    "country": "United States"
  },
  {
    "id": 104072,
    "name": "SSCOR Inc    ( Sun Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 455054,
    "name": "SSI Laser Engineering    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 473834,
    "name": "SSI Technologies Inc    ( Janesville WI USA )",
    "country": "United States"
  },
  {
    "id": 999560,
    "name": "SSI Technologies, Inc.    ( Janesville WI USA )",
    "country": "USA"
  },
  {
    "id": 398946,
    "name": "SSL Australia Pty Ltd    ( Mordialloc  USA )",
    "country": "United States"
  },
  {
    "id": 398948,
    "name": "SSL Canada Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 398960,
    "name": "SSL Healthcare Brands SA    ( Barcelona  USA )",
    "country": "United States"
  },
  {
    "id": 398949,
    "name": "SSL Healthcare Danmark A/S    ( Soborg  USA )",
    "country": "United States"
  },
  {
    "id": 398943,
    "name": "SSL Healthcare Deutschland GmbH & Co KG    ( Maintal  USA )",
    "country": "United States"
  },
  {
    "id": 398950,
    "name": "SSL Healthcare France SA    ( Levallois-Perret Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 398952,
    "name": "SSL Healthcare Japan Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 398953,
    "name": "SSL Healthcare Nederland NV    ( `s Hertogenbosch  USA )",
    "country": "United States"
  },
  {
    "id": 398954,
    "name": "SSL Healthcare Norge A/S    ( Oslo  USA )",
    "country": "United States"
  },
  {
    "id": 398957,
    "name": "SSL Healthcare Schweiz AG    ( Reinach  USA )",
    "country": "United States"
  },
  {
    "id": 372105,
    "name": "SSL International plc    ( London  USA )",
    "country": "United States"
  },
  {
    "id": 398958,
    "name": "SSL New Zealand Ltd    ( Auckland  USA )",
    "country": "United States"
  },
  {
    "id": 398955,
    "name": "SSL Sverige A/B    ( Solna  USA )",
    "country": "United States"
  },
  {
    "id": 415990,
    "name": "SSS Australia    ( Tingalpa  Australia )",
    "country": "Australia"
  },
  {
    "id": 458438,
    "name": "SST Group Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 108084,
    "name": "St Charles Mfg Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 332271,
    "name": "St Croix Systems Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 226621,
    "name": "ST Electromedicina SA    ( Rubi (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 357189,
    "name": "St Francis Medical Equipment Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454701,
    "name": "St Johns First Aid Kits Pvt Ltd    ( Urapakkam  India )",
    "country": "India"
  },
  {
    "id": 453983,
    "name": "St Jude Medical    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 382499,
    "name": "St Jude Medical (Australia) Pty Ltd    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 456942,
    "name": "St Jude Medical (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 156450,
    "name": "St Jude Medical (Belgium)    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 320746,
    "name": "St Jude Medical (Hong Kong) Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 382502,
    "name": "St Jude Medical (Hong Kong) Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 382505,
    "name": "St Jude Medical (Hong Kong) Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 382508,
    "name": "St Jude Medical (Hong Kong) Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 382525,
    "name": "St Jude Medical (Hong Kong) Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456946,
    "name": "St Jude Medical (Schweiz) AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 382524,
    "name": "St Jude Medical AG    ( Basle  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 382501,
    "name": "St Jude Medical Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 407269,
    "name": "St Jude Medical Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 382503,
    "name": "St Jude Medical Denmark A/S    ( Glostrup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 407270,
    "name": "St Jude Medical Espana SA    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 382504,
    "name": "St Jude Medical Finland Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 332075,
    "name": "St Jude Medical France SA    ( Boulogne-Billancort Cedex  France )",
    "country": "France"
  },
  {
    "id": 311748,
    "name": "St Jude Medical GmbH    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 101736,
    "name": "St Jude Medical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 310299,
    "name": "St Jude Medical Inc    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 452237,
    "name": "St Jude Medical Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 456944,
    "name": "St Jude Medical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 456945,
    "name": "St Jude Medical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 457987,
    "name": "St Jude Medical Inc    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 457988,
    "name": "St Jude Medical Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 150083,
    "name": "St Jude Medical International    (   USA )",
    "country": "United States"
  },
  {
    "id": 382506,
    "name": "St Jude Medical Italia SpA    ( Agrate Brianza (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 382507,
    "name": "St Jude Medical Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 382500,
    "name": "St Jude Medical Medizintechnik GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 382509,
    "name": "St Jude Medical Nederland bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 382510,
    "name": "St Jude Medical Portugal    ( Lisboa  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 407271,
    "name": "St Jude Medical Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 382523,
    "name": "St Jude Medical Sweden    ( Jarfalla  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 301608,
    "name": "St Jude Medical UK Ltd    ( Stratford upon Avon  England )",
    "country": "UK"
  },
  {
    "id": 325614,
    "name": "St Louis Medical Distributors    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 466532,
    "name": "St Timothy Chair Co    ( Hickory NC USA )",
    "country": "United States"
  },
  {
    "id": 397843,
    "name": "STAAR Surgical A/S    ( Drammen  Norway )",
    "country": "Norway"
  },
  {
    "id": 397847,
    "name": "STAAR Surgical AB    ( Sundbyberg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 186588,
    "name": "STAAR Surgical AG    ( Nidau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 107095,
    "name": "STAAR Surgical Co    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 457332,
    "name": "STAAR Surgical Co AG    ( Nidau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 397841,
    "name": "STAAR Surgical France Sarl    ( Corbevoie  France )",
    "country": "France"
  },
  {
    "id": 397845,
    "name": "STAAR Surgical South Africa    ( Centurion  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 280322,
    "name": "Stabakon GmbH Blankenburg    ( Blankenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 101738,
    "name": "Stackhouse Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 108097,
    "name": "Staco Energy Product Co    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 466314,
    "name": "Stacy Systems Inc    ( Newark TX USA )",
    "country": "United States"
  },
  {
    "id": 286237,
    "name": "Stader & Bollengraben GmbH & Co KG    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 428453,
    "name": "Stadler Elektronik AG    ( Littau/Luzern  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 466431,
    "name": "Stafford-Smith Inc    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 459390,
    "name": "Stage Front Presentation Systems    ( Savannah GA USA )",
    "country": "United States"
  },
  {
    "id": 460692,
    "name": "Staging Concepts    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 287465,
    "name": "Stahl GmbH    ( Winterbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 416013,
    "name": "Stahl Waeschereimaschinenbau GmbH    ( Sindelfingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 102054,
    "name": "Stahl/Scott Fetzer Co    ( Wooster OH USA )",
    "country": "United States"
  },
  {
    "id": 451879,
    "name": "Stallion Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 338629,
    "name": "Stallion Technologies Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 453365,
    "name": "Stamina - DJO Surgical    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 107745,
    "name": "Stanbio Laboratory    ( Boerne TX USA )",
    "country": "United States"
  },
  {
    "id": 452920,
    "name": "Stanbio Life Sciences    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 176932,
    "name": "Stanbridge Ltd    ( Orpington  England )",
    "country": "UK"
  },
  {
    "id": 103198,
    "name": "Stand-Aid of Iowa Inc    ( Sheldon IA USA )",
    "country": "United States"
  },
  {
    "id": 456267,
    "name": "Standard Diagnostics Inc    ( Yongin  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 186750,
    "name": "Standard Imaging Inc    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 106872,
    "name": "Standard Medical Imaging Inc    ( Spokane WA USA )",
    "country": "United States"
  },
  {
    "id": 456371,
    "name": "Standard Plus Medical Co Ltd    ( Gimje  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 363903,
    "name": "Standard Register    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 465060,
    "name": "Standard Restaurant Supply    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 105165,
    "name": "Standard Textile Co Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 458734,
    "name": "Standing Stone Inc    ( Westport CT USA )",
    "country": "United States"
  },
  {
    "id": 232711,
    "name": "Stanford Photonics Electronic Imaging Technologies    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 101739,
    "name": "Stanford Professional Products Corp    ( Pennsauken NJ USA )",
    "country": "United States"
  },
  {
    "id": 289550,
    "name": "Stanford Research Systems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 461146,
    "name": "Stanley Access Technologies LLC    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 454149,
    "name": "Stanley Healthcare Solutions    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 454045,
    "name": "Stanley InnerSpace    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 454120,
    "name": "Stanley Security Operations Ltd    ( Marsh Barton  England )",
    "country": "UK"
  },
  {
    "id": 454118,
    "name": "Stanley Security Solutions Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 451568,
    "name": "Stanley Supply and Services    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 223691,
    "name": "Stanley Vidmar Storage Technologies    ( Allentown PA USA )",
    "country": "United States"
  },
  {
    "id": 453788,
    "name": "Stanmore Implants Worldwide (France)    ( Lyon  France )",
    "country": "France"
  },
  {
    "id": 384285,
    "name": "Stanmore Implants Worldwide Ltd    ( Elstree  England )",
    "country": "UK"
  },
  {
    "id": 108563,
    "name": "Staodyn Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 452312,
    "name": "Stapleline Medizintechnik GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 460690,
    "name": "Staples    ( Framingham MA USA )",
    "country": "United States"
  },
  {
    "id": 102924,
    "name": "Staplex Co    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 452212,
    "name": "Star Medical Inc    ( Floral Park NY USA )",
    "country": "United States"
  },
  {
    "id": 323443,
    "name": "Star Medical Systems    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 431605,
    "name": "Star Mfg International Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 459436,
    "name": "Star Micronics America Inc    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 999636,
    "name": "Star Micronics America, Inc.    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 459434,
    "name": "Star Micronics Co Ltd    ( Shizuoka  Japan )",
    "country": "Japan"
  },
  {
    "id": 174992,
    "name": "Star Ophthalmic Instruments Inc    ( Willowbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 426493,
    "name": "Star Sprinkler    ( Oak Creek WI USA )",
    "country": "United States"
  },
  {
    "id": 183776,
    "name": "Star Trac by Unisen Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 107476,
    "name": "Star X-Ray Co Inc    ( Amityville NY USA )",
    "country": "United States"
  },
  {
    "id": 456557,
    "name": "STARC medical GmbH    ( Isernhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282856,
    "name": "Starflex Niessen Schuhfabrik GmbH    ( Heinsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452574,
    "name": "Starion Instruments    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 409738,
    "name": "Stark & Assoc    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 418605,
    "name": "Starkey Australia Pty Ltd    ( Camperdown  Australia )",
    "country": "Australia"
  },
  {
    "id": 418606,
    "name": "Starkey Brazil Ltda    ( Campinas-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 418607,
    "name": "Starkey Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 418608,
    "name": "Starkey China Hearing Aid (Suzhou) Co Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 418609,
    "name": "Starkey Colombia    ( Santa Fe de Bogota  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 418615,
    "name": "Starkey de Mexico SA de CV    ( Colonia Cuauhtemoc  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 418610,
    "name": "Starkey France Sarl    ( Creteil  France )",
    "country": "France"
  },
  {
    "id": 418611,
    "name": "Starkey Hungary Laboratorium Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 418612,
    "name": "Starkey Italy srl    ( Cernusco sul Naivglio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418613,
    "name": "Starkey Japan Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 418614,
    "name": "Starkey Korea Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457257,
    "name": "Starkey Laboratories Australia Pty Ltd    ( Alexandria  Australia )",
    "country": "Australia"
  },
  {
    "id": 285021,
    "name": "Starkey Laboratories GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 107526,
    "name": "Starkey Laboratories Inc    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 418621,
    "name": "Starkey Laboratories Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 457265,
    "name": "Starkey Laboratories Poland Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 418616,
    "name": "Starkey New Zealand    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 418617,
    "name": "Starkey Norway A/S    ( Stavanger  Norway )",
    "country": "Norway"
  },
  {
    "id": 418618,
    "name": "Starkey Poland Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 418620,
    "name": "Starkey SA    ( Marin-NE  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 418619,
    "name": "Starkey Sweden AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 453665,
    "name": "Starkstrom    ( Eastcote Ruislip  England )",
    "country": "UK"
  },
  {
    "id": 469511,
    "name": "Starlight Children's Foundation    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 451853,
    "name": "StarMedTec GmbH    ( Starnberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 104320,
    "name": "Starplex Scientific Inc    ( Etobicoke ON Canada )",
    "country": "Canada"
  },
  {
    "id": 441539,
    "name": "Stat Biomedical Sales and Rentals Inc    ( Lubbock TX USA )",
    "country": "United States"
  },
  {
    "id": 186754,
    "name": "Stat Biomedical Technicians Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 101740,
    "name": "Stat Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 999775,
    "name": "STAT IM    (   USA )",
    "country": "United States"
  },
  {
    "id": 454426,
    "name": "Stat Medical Devices Inc    ( North Miami Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 186755,
    "name": "Stat Medical Equipment Service    ( Greer SC USA )",
    "country": "United States"
  },
  {
    "id": 465063,
    "name": "Stat Surgical, LLC    ( Anaheim 12 USA )",
    "country": "United States"
  },
  {
    "id": 461461,
    "name": "STAT System    ( San Rafael  USA )",
    "country": "United States"
  },
  {
    "id": 104435,
    "name": "Stat-Chek Co    ( Bend OR USA )",
    "country": "United States"
  },
  {
    "id": 183963,
    "name": "Statcorp Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 456628,
    "name": "Statcorp Medical    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 106873,
    "name": "State Ambulance Builders Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 465158,
    "name": "State of The Art Medical Products, Inc.    ( Cedar Grove 41 USA )",
    "country": "United States"
  },
  {
    "id": 332274,
    "name": "State Technology Inc    ( Bridgeport NJ USA )",
    "country": "United States"
  },
  {
    "id": 148994,
    "name": "Statebourne Cryogenics    ( Newcastle-upon-Tyne  England )",
    "country": "UK"
  },
  {
    "id": 358140,
    "name": "Static Systems Group plc    ( Wombourne  England )",
    "country": "UK"
  },
  {
    "id": 451748,
    "name": "Statlab Medical Products Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 453866,
    "name": "StatRad    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 319410,
    "name": "StatSpin International InTec    ( Bangor  USA )",
    "country": "United States"
  },
  {
    "id": 282002,
    "name": "Staude und Partner Orthopaedie Geraete - Mecc San    ( Gersthofen  USA )",
    "country": "United States"
  },
  {
    "id": 285922,
    "name": "Staudenmayer GmbH    ( Salach  Germany )",
    "country": "Germany"
  },
  {
    "id": 185569,
    "name": "Stawi Zaklad Odziezowy    ( Poznan  Poland )",
    "country": "Poland"
  },
  {
    "id": 457549,
    "name": "STAXI Corp Ltd    ( Kleinburg ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460543,
    "name": "Stayhealthy Inc    ( Monrovia CA USA )",
    "country": "United States"
  },
  {
    "id": 285881,
    "name": "Stayl-Med Techn GmbH    ( Roesrath  Germany )",
    "country": "Germany"
  },
  {
    "id": 458885,
    "name": "StayWell Health Management LLC    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 285403,
    "name": "Steag-Laminarflow-Prozesstechnik GmbH    ( Pliezhausen  USA )",
    "country": "United States"
  },
  {
    "id": 456731,
    "name": "Stealth Surgical LLC    ( Gordonsville VA USA )",
    "country": "United States"
  },
  {
    "id": 451435,
    "name": "Stedim Inc    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 451438,
    "name": "Stedim SA    ( Aubagne  USA )",
    "country": "United States"
  },
  {
    "id": 458953,
    "name": "Steelcase Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 455343,
    "name": "Steelco Asia    ( Puchong  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 455342,
    "name": "Steelco Hungary Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 455340,
    "name": "Steelco SpA    ( Riese Pio X (TV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455341,
    "name": "Steelco USA    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 101741,
    "name": "Steelcraft Inc    ( Millbury MA USA )",
    "country": "United States"
  },
  {
    "id": 106000,
    "name": "Steele Canvas Basket Corp    ( Chelsea MA USA )",
    "country": "United States"
  },
  {
    "id": 270719,
    "name": "Steele Supply Co    ( St Joseph MI USA )",
    "country": "United States"
  },
  {
    "id": 461454,
    "name": "SteelSentry    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 452674,
    "name": "Steering Developments Group Ltd    ( Hemel Hempstead  England )",
    "country": "UK"
  },
  {
    "id": 279487,
    "name": "Stefan Lowisch Rehabilitationshilfen Rollstuehle    ( Altenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 283631,
    "name": "Steiner (Deutschland) GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 161007,
    "name": "Steiner Otto GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 457339,
    "name": "Steiner Pinzetten GmbH    ( Engen  Germany )",
    "country": "Germany"
  },
  {
    "id": 146776,
    "name": "Steinman Services Inc    ( Edwardsville IL USA )",
    "country": "United States"
  },
  {
    "id": 280473,
    "name": "Steinmeier GmbH & Co KG    ( Bielefeld (Altenhagen)  Germany )",
    "country": "Germany"
  },
  {
    "id": 263306,
    "name": "Stelkast Co    ( McMurray PA USA )",
    "country": "United States"
  },
  {
    "id": 449887,
    "name": "stella-med    ( Fuerth  USA )",
    "country": "United States"
  },
  {
    "id": 451359,
    "name": "Stellar Pharmaceuticals Inc    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 234545,
    "name": "StellarNet Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 392407,
    "name": "Stellate Systems Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 453942,
    "name": "Stemcell Technologies Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 466557,
    "name": "Stemcup Medical Products AG    ( Zurich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456470,
    "name": "StemSoft Software Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 450942,
    "name": "STEN Corp    ( Ham Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 441253,
    "name": "Sten+Barr Medical Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 456972,
    "name": "Stens Biofeedback    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 458484,
    "name": "Stens Corp    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 177929,
    "name": "Stensland Verksted Larvik    ( Tjodalyng  Norway )",
    "country": "Norway"
  },
  {
    "id": 345669,
    "name": "Stento Communication Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 435258,
    "name": "Stentofon Electronic GmbH    ( Frechen-Konigsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 398526,
    "name": "Stentor Inc    ( Brisbane CA USA )",
    "country": "United States"
  },
  {
    "id": 285412,
    "name": "STEP Sensortechnik und Elektronik Pockau GmbH    ( Pockau  Germany )",
    "country": "Germany"
  },
  {
    "id": 428587,
    "name": "Stephan Polska Sp z o o    ( Swarzedz  Poland )",
    "country": "Poland"
  },
  {
    "id": 286861,
    "name": "Stephan Schilling GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 401705,
    "name": "Stephanix    ( La Ricamarie  France )",
    "country": "France"
  },
  {
    "id": 455613,
    "name": "Stephens Equipment Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 184355,
    "name": "Steraloids Inc    ( Newport RI USA )",
    "country": "United States"
  },
  {
    "id": 105469,
    "name": "Stereo Optical Co Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 408161,
    "name": "Stereotaxis Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 441721,
    "name": "Steri-Systems Corp    ( Auburn GA USA )",
    "country": "United States"
  },
  {
    "id": 293296,
    "name": "Sterico AG    ( Wangen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 452462,
    "name": "Stericom Ltd    ( Chesham  England )",
    "country": "UK"
  },
  {
    "id": 286863,
    "name": "Stericon GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 175376,
    "name": "Stericon Inc    ( Broadview IL USA )",
    "country": "United States"
  },
  {
    "id": 332276,
    "name": "Stericorp Inc    ( Port Huron MI USA )",
    "country": "United States"
  },
  {
    "id": 467155,
    "name": "Stericycle (Brasil)    ( Recife-PE  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 467162,
    "name": "Stericycle (Mexico)    ( Ciudad de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 467164,
    "name": "Stericycle (Romania)    ( Jilava  Romania )",
    "country": "Romania"
  },
  {
    "id": 467158,
    "name": "Stericycle Chile    ( Quilicura  Chile )",
    "country": "Chile"
  },
  {
    "id": 451557,
    "name": "Stericycle Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 467156,
    "name": "Stericycle Inc (Canada)    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 467161,
    "name": "Stericycle Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454425,
    "name": "Steridium Pty Ltd    ( Q Super Center  Australia )",
    "country": "Australia"
  },
  {
    "id": 294433,
    "name": "SteriGenics International Inc    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 311752,
    "name": "Steril-Aire Inc    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 451979,
    "name": "Sterilab Services    ( Harrogate  England )",
    "country": "UK"
  },
  {
    "id": 451242,
    "name": "sterilAir AG (Deutschland)    ( Kuerten  Germany )",
    "country": "Germany"
  },
  {
    "id": 293330,
    "name": "sterilAir AG (Switzerland)    ( Weinfelden  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 465037,
    "name": "Sterilco Inc    ( Husser LA USA )",
    "country": "United States"
  },
  {
    "id": 294426,
    "name": "Sterile Systems    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 466575,
    "name": "Sterilelink Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 999745,
    "name": "Sterilizer USA    (   USA )",
    "country": "United States"
  },
  {
    "id": 146777,
    "name": "Sterilizing Systems    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 399290,
    "name": "SterilMed Inc    ( Maple Grove MN USA )",
    "country": "United States"
  },
  {
    "id": 455359,
    "name": "Sterilserve Inc    ( Cartersville GA USA )",
    "country": "United States"
  },
  {
    "id": 467262,
    "name": "Sterilucent Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 151014,
    "name": "Sterimatic Ltd    ( Stroud  USA )",
    "country": "United States"
  },
  {
    "id": 451667,
    "name": "Sterimed Inc    ( Cartersville GA USA )",
    "country": "United States"
  },
  {
    "id": 455267,
    "name": "Sterimedix    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 451316,
    "name": "STERIS AG    ( Pieterlen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 107114,
    "name": "STERIS Corp    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 451310,
    "name": "STERIS Deutschland GmbH    ( Cologne  Germany )",
    "country": "Germany"
  },
  {
    "id": 309761,
    "name": "STERIS Finn-Aqua    ( Tuusula  Finland )",
    "country": "Finland"
  },
  {
    "id": 355810,
    "name": "STERIS Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 451317,
    "name": "STERIS SA    ( Nante  France )",
    "country": "France"
  },
  {
    "id": 320749,
    "name": "STERIS Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 227265,
    "name": "STERIS sprl/bvra    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 466230,
    "name": "Sterisil    ( Palmer Lake CO USA )",
    "country": "United States"
  },
  {
    "id": 455045,
    "name": "Steritec Products Mfg Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 105347,
    "name": "Sterling Diagnostics Inc    ( Sterling Heights MI USA )",
    "country": "United States"
  },
  {
    "id": 345093,
    "name": "Sterling do Brasil Imagens Para Diagnostic Ltd    ( Butanta-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 312341,
    "name": "Sterling Fluid Systems (USA)    ( Grand Island NY USA )",
    "country": "United States"
  },
  {
    "id": 403763,
    "name": "Sterling Medical    ( Goldsboro NC USA )",
    "country": "United States"
  },
  {
    "id": 108419,
    "name": "Sterling Medical Products    ( Prophetstown IL USA )",
    "country": "United States"
  },
  {
    "id": 325616,
    "name": "Sterling Medical Technologies Inc    ( McKinney TX USA )",
    "country": "United States"
  },
  {
    "id": 103007,
    "name": "Sterling Rubber Co    ( Fergus ON USA )",
    "country": "United States"
  },
  {
    "id": 455097,
    "name": "Sterling Stairlifts    ( Mount Poccono PA USA )",
    "country": "United States"
  },
  {
    "id": 999648,
    "name": "Stern Pinball    ( Melrose Park IL USA )",
    "country": "United States"
  },
  {
    "id": 174740,
    "name": "Sterne Medical Equipment    ( Mansfield ON Canada )",
    "country": "Canada"
  },
  {
    "id": 273271,
    "name": "Sterngold ImplaMed    ( Attleboro MA USA )",
    "country": "United States"
  },
  {
    "id": 461448,
    "name": "Stero    ( Petaluma CA USA )",
    "country": "United States"
  },
  {
    "id": 451367,
    "name": "Steroplast LLC    ( Manchester  England )",
    "country": "UK"
  },
  {
    "id": 151546,
    "name": "Sterylab SpA    ( Rho (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452728,
    "name": "Sterylab USA LLC    ( Franklin IN USA )",
    "country": "United States"
  },
  {
    "id": 452573,
    "name": "Stethoscope.com    ( Wayland MA USA )",
    "country": "United States"
  },
  {
    "id": 283081,
    "name": "Steuler-Industriewerke GmbH    ( Hoehr-Grenzhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 465064,
    "name": "Stevens-Moon    ( Dansville 27 USA )",
    "country": "United States"
  },
  {
    "id": 440712,
    "name": "STI Computer Services Inc    ( Eagleville PA USA )",
    "country": "United States"
  },
  {
    "id": 459485,
    "name": "STi Healthcare    ( New Albany OH USA )",
    "country": "United States"
  },
  {
    "id": 461267,
    "name": "STI Medical Systems    ( Honolulu HI USA )",
    "country": "United States"
  },
  {
    "id": 360755,
    "name": "STI-Signal Transduction Products    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 279421,
    "name": "Stiema Arbeitsschutz GmbH    ( Ahlen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161022,
    "name": "Stihler Electronic GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 187041,
    "name": "Stille Surgical AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450741,
    "name": "Stille-Sonesta AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450742,
    "name": "Stille-Sonesta Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 103611,
    "name": "Stille-Werner USA    (   USA )",
    "country": "United States"
  },
  {
    "id": 161024,
    "name": "Stimotron Medizinische Geraete    ( Wendelstein  USA )",
    "country": "United States"
  },
  {
    "id": 158850,
    "name": "Stimtech Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 451933,
    "name": "Stinger Medical    ( Murfreesboro TN USA )",
    "country": "United States"
  },
  {
    "id": 457224,
    "name": "Stingray Surgical Products LLC    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 329786,
    "name": "STL International Inc    ( Puyallup WA USA )",
    "country": "United States"
  },
  {
    "id": 391388,
    "name": "STM Healthcare    ( Alfreton  England )",
    "country": "UK"
  },
  {
    "id": 332279,
    "name": "Stockell Healthcare Systems    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 457379,
    "name": "Stockert GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 356888,
    "name": "Stockert Instrumente    (   USA )",
    "country": "United States"
  },
  {
    "id": 103897,
    "name": "StockerYale Inc    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 284392,
    "name": "Stockfabrik Heine    ( Meinhard  Germany )",
    "country": "Germany"
  },
  {
    "id": 324703,
    "name": "Stocksigns Ltd    ( Redhill  England )",
    "country": "UK"
  },
  {
    "id": 169870,
    "name": "Stockwell Scientific Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 161025,
    "name": "Stoeckert Instrumente GmbH    ( Muenchen  USA )",
    "country": "United States"
  },
  {
    "id": 102123,
    "name": "Stoelting Co    ( Wood Dale IL USA )",
    "country": "United States"
  },
  {
    "id": 104329,
    "name": "Stokes Div Pennwalt Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 452689,
    "name": "Stokke LLC    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 283633,
    "name": "Stollenwerk Hans Stollenwerk & Cie GmbH & Co    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 457527,
    "name": "Stomabags    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 461436,
    "name": "Stonehaven Medical Inc    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 454032,
    "name": "Storage Products Group LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 466689,
    "name": "Storage Systems Unlimited    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 458979,
    "name": "StorageTek Inc    ( Hummelstown PA USA )",
    "country": "United States"
  },
  {
    "id": 267435,
    "name": "StorCOMM Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 346564,
    "name": "StorCOMM Technologies Ltd    ( Basildon  USA )",
    "country": "United States"
  },
  {
    "id": 161027,
    "name": "Storz am Mark Tuttlingen GmbH    ( Emmingen Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 172845,
    "name": "Storz Medical AG    ( Taegerwilen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 408753,
    "name": "Storz Medical Italia srl    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 408752,
    "name": "Storz Medical UK Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 325615,
    "name": "Stow Away Inc    ( Chelsea OK USA )",
    "country": "United States"
  },
  {
    "id": 427958,
    "name": "Stradis HEALTHCARE LLC    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 459457,
    "name": "Strata Decision Technology    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 202212,
    "name": "Stratagene    ( La Jolla CA USA )",
    "country": "United States"
  },
  {
    "id": 460553,
    "name": "Stratasys Ltd    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 460556,
    "name": "Stratasys Ltd (Israel)    ( Rehovot  Israel )",
    "country": "Israel"
  },
  {
    "id": 452047,
    "name": "STRATEC Biomedical Systems AG    ( Birkenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 157047,
    "name": "Stratec Electronik GmbH    ( Birkenfeld-Graefenhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 305950,
    "name": "Stratec Medical    ( Oberdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 272077,
    "name": "STRATEC Medical Ltd    ( Welwyn Garden City  USA )",
    "country": "United States"
  },
  {
    "id": 156439,
    "name": "Stratec Medizintechnik GmbH    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 454606,
    "name": "Stratedigm Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 459048,
    "name": "Strategic Applications Inc    ( Lake Villa IL USA )",
    "country": "United States"
  },
  {
    "id": 450898,
    "name": "Strategic Health Care Technology Assocs    ( Saratoga Springs NY USA )",
    "country": "United States"
  },
  {
    "id": 462600,
    "name": "Strategic Healthcare Programs LLC    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 462598,
    "name": "Strategic Management Services LLC    ( Alexandria VA USA )",
    "country": "United States"
  },
  {
    "id": 463022,
    "name": "Strategic Medical Sales LLC    ( West Field NY USA )",
    "country": "United States"
  },
  {
    "id": 456266,
    "name": "StraTek Co Ltd    ( Anyang  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 234563,
    "name": "Stratford Software Inc    ( San Mateo CA USA )",
    "country": "United States"
  },
  {
    "id": 454216,
    "name": "Straub Medical AG    ( Wangs  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 232384,
    "name": "Straumann USA LLC    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 172322,
    "name": "Strauss & Co Industrial Diamonds Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 451625,
    "name": "Streamline Health Solutions Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 454613,
    "name": "StreamlineMD LLC    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 462594,
    "name": "Streater LLC    ( Albert Lea MN USA )",
    "country": "United States"
  },
  {
    "id": 105652,
    "name": "Streck Laboratories Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 455556,
    "name": "Streifeneder ortho.production GmbH    ( Emmering  Germany )",
    "country": "Germany"
  },
  {
    "id": 161030,
    "name": "Streppel Glasfaser-Optik    ( Wermelskirchen/Tente  Germany )",
    "country": "Germany"
  },
  {
    "id": 375497,
    "name": "Stretchair Patient Transfer Systems Inc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 465155,
    "name": "Stricker & Associates    ( Downers Grove 90 USA )",
    "country": "United States"
  },
  {
    "id": 171145,
    "name": "Striegler Medical Equipment    ( Pearland TX USA )",
    "country": "United States"
  },
  {
    "id": 451348,
    "name": "Strike First Corp Canada    ( Scarborough ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451349,
    "name": "Strike First Corp of America    ( Front Royal VA USA )",
    "country": "United States"
  },
  {
    "id": 282827,
    "name": "Strohm & Maier GmbH    ( Heidenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 371676,
    "name": "Stronglite Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 418092,
    "name": "StructuRad LLC    ( Encino CA USA )",
    "country": "United States"
  },
  {
    "id": 460658,
    "name": "Structural Concepts Corp    ( Muskegon MI USA )",
    "country": "United States"
  },
  {
    "id": 307324,
    "name": "Stryker (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 428430,
    "name": "Stryker Bertec    ( L'Islet PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 183623,
    "name": "Stryker Canada    ( Hamilton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460521,
    "name": "Stryker China    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452594,
    "name": "Stryker Communications    ( Flower Mound TX USA )",
    "country": "United States"
  },
  {
    "id": 150184,
    "name": "Stryker Corp    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 271227,
    "name": "Stryker Corp    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 454887,
    "name": "Stryker Craniomaxillofacial    ( Portage MI USA )",
    "country": "United States"
  },
  {
    "id": 107536,
    "name": "Stryker Endoscopy    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 185116,
    "name": "Stryker Europe SA    ( Montreux  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 454717,
    "name": "Stryker France SAS    ( Pusignan  France )",
    "country": "France"
  },
  {
    "id": 101991,
    "name": "Stryker Instruments    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 440017,
    "name": "Stryker Japan    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 343433,
    "name": "Stryker Latin America    ( Miramar FL USA )",
    "country": "United States"
  },
  {
    "id": 399227,
    "name": "Stryker Leibinger GmbH & Co KG    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 441867,
    "name": "Stryker Navigation    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 466891,
    "name": "Stryker Neurovascular    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 458074,
    "name": "Stryker Orthobiologics    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 441474,
    "name": "Stryker Orthopaedics    ( Mahwah NJ USA )",
    "country": "United States"
  },
  {
    "id": 454716,
    "name": "Stryker Osteosynthesis (Switzerland)    ( Geneve 15  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 184863,
    "name": "Stryker Pacific    ( Central  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 441885,
    "name": "Stryker Spine    ( Allendale NJ USA )",
    "country": "United States"
  },
  {
    "id": 441993,
    "name": "Stryker Spine SAS    ( Cestas  France )",
    "country": "France"
  },
  {
    "id": 457736,
    "name": "Stryker Sustainability Solutions    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 451223,
    "name": "Stryker Trauma    ( Selzach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 439927,
    "name": "Stryker UK Ltd    ( Newbury  England )",
    "country": "UK"
  },
  {
    "id": 451379,
    "name": "STS Div Ethox Corp    ( Rush NY USA )",
    "country": "United States"
  },
  {
    "id": 287616,
    "name": "Stuemed GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 163239,
    "name": "Sturdy Industrial Co Ltd    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 431569,
    "name": "Stylecraft Interiors Inc    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 336116,
    "name": "Stylesetter International Co Pty Ltd    ( Artarmon  Australia )",
    "country": "Australia"
  },
  {
    "id": 472809,
    "name": "Sub-Zero and Wolf    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 999498,
    "name": "Sub-Zero Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 391375,
    "name": "Subiton Laboratories SA    ( San Fernando  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 152104,
    "name": "Subtil Crepieux    ( Chassieu  France )",
    "country": "France"
  },
  {
    "id": 105596,
    "name": "Suburban Surgical Co Inc    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 462589,
    "name": "SuccessEHS    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 460754,
    "name": "SuccessFactors    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 286937,
    "name": "Sudhoff Technik GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 282217,
    "name": "Suesse GmbH Labortechnik    ( Gudensberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452489,
    "name": "SugiCount Medical    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 227940,
    "name": "Sugiyama Kogyo Co Ltd    ( Tsubame  Japan )",
    "country": "Japan"
  },
  {
    "id": 452637,
    "name": "Suinsa Medical Systems    ( Torrejon de Ardoz (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 454614,
    "name": "SuiteMed LLC    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 170434,
    "name": "Sullivan-Schein Dental    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 184356,
    "name": "Sultan Chemists Inc    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 382527,
    "name": "Sulzer Medica MST    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 185571,
    "name": "Sumi Zaklad Tworzyw Sztucznych    ( Sulejowek  Poland )",
    "country": "Poland"
  },
  {
    "id": 278665,
    "name": "Sumitomo Bakelite Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 375104,
    "name": "Sumitomo Electric Hightechs Co Ltd    ( Osaka  USA )",
    "country": "United States"
  },
  {
    "id": 158014,
    "name": "Sumitomo Electric Industries Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 464095,
    "name": "Sumitomo Heavy Industries Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 376112,
    "name": "Sumitomo Seika chemicals Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 461445,
    "name": "SynDaver Labs    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 458767,
    "name": "Summation Technology LLC    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 999994,
    "name": "Summit Appliances Division    (   USA )",
    "country": "United States"
  },
  {
    "id": 307325,
    "name": "Summit Co (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 462576,
    "name": "Summit Dental Systems Inc    ( Pompano Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 452467,
    "name": "Summit Doppler Systems Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 467180,
    "name": "Summit Glove Inc    ( Minerva OH USA )",
    "country": "United States"
  },
  {
    "id": 460830,
    "name": "Summit Healthcare Services Inc    ( Braintree MA USA )",
    "country": "United States"
  },
  {
    "id": 452347,
    "name": "Summit Imaging Inc    ( Lee's Summit MO USA )",
    "country": "United States"
  },
  {
    "id": 999766,
    "name": "Summit Measurement    (   USA )",
    "country": "United States"
  },
  {
    "id": 289264,
    "name": "Summit Medical Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 272070,
    "name": "Summit Medical Ltd    ( Cheltenham  England )",
    "country": "UK"
  },
  {
    "id": 455024,
    "name": "Summit Medical Products Inc    ( Sandy UT USA )",
    "country": "United States"
  },
  {
    "id": 129431,
    "name": "Summit Technologies Inc    ( Burlington ON USA )",
    "country": "United States"
  },
  {
    "id": 452431,
    "name": "SumTotal Systems Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 330924,
    "name": "Sun Biomedical Laboratories Inc    ( Blackwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 278666,
    "name": "Sun Contact Lens Co Ltd    ( Kyoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 394472,
    "name": "Sun Ergoline Inc    ( Jonesboro AR USA )",
    "country": "United States"
  },
  {
    "id": 106496,
    "name": "Sun Medical Inc    ( Grand Prairie TX USA )",
    "country": "United States"
  },
  {
    "id": 466964,
    "name": "Sun Medical Solutions Inc    ( Fuquay Varina NC USA )",
    "country": "United States"
  },
  {
    "id": 999442,
    "name": "Sun Microsystems    (   USA )",
    "country": "United States"
  },
  {
    "id": 186762,
    "name": "Sun Nuclear Corp    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 106564,
    "name": "SUN SRi    ( Rockwood TN USA )",
    "country": "United States"
  },
  {
    "id": 459384,
    "name": "Sun Telecom International    ( Johns Creek GA USA )",
    "country": "United States"
  },
  {
    "id": 106650,
    "name": "Sun-Med    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 451560,
    "name": "Sunbeam/Jarden Consumer Solutions    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 108830,
    "name": "Sunbelt Medical Corp    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 461161,
    "name": "Suncoast Solutions    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 458100,
    "name": "Sundance Enterprises Inc    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 467182,
    "name": "Sundstrom Safety AB    ( Lidingo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 467179,
    "name": "Sundstrom Safety Inc    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 467183,
    "name": "Sundstrom Safety Services Ltd    ( Cottingham  England )",
    "country": "UK"
  },
  {
    "id": 361260,
    "name": "Sunflower Medical    ( Ellis KS USA )",
    "country": "United States"
  },
  {
    "id": 312380,
    "name": "SunGard Recovery Services Inc    ( Wayne PA USA )",
    "country": "United States"
  },
  {
    "id": 456264,
    "name": "SUNGWON MEDICAL Co Ltd    ( Cheongju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455006,
    "name": "Suni Medical Imaging Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 452923,
    "name": "Sunlight Clinical Solutions    ( Shrewsbury  England )",
    "country": "UK"
  },
  {
    "id": 371747,
    "name": "Sunlight Medical (Deutschland) GmbH    ( Ravensburg  USA )",
    "country": "United States"
  },
  {
    "id": 291377,
    "name": "Sunlight Medical Asia Ltd    ( Shanghai  USA )",
    "country": "United States"
  },
  {
    "id": 392500,
    "name": "Sunlight Medical Inc    ( Pasadena MD USA )",
    "country": "United States"
  },
  {
    "id": 392499,
    "name": "Sunlight Medical Ltd    ( Tel Aviv  USA )",
    "country": "United States"
  },
  {
    "id": 456187,
    "name": "SunMax Biotechnology Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 384774,
    "name": "SunMed    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 373944,
    "name": "SunMedica    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 456263,
    "name": "SunMedix Co Ltd    ( Namyangju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 335669,
    "name": "Sunnex Equipment AB    ( Sunne  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 102322,
    "name": "Sunnex Inc    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 452332,
    "name": "Sunnex Inc    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 371494,
    "name": "Sunnico International Inc    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 398206,
    "name": "Sunnydale Goss    ( Centralia MO USA )",
    "country": "United States"
  },
  {
    "id": 336135,
    "name": "Sunnyfield Industries    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 440671,
    "name": "Sunoptic Technologies    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 450885,
    "name": "Sunoptics Surgical UK    ( Mersham  England )",
    "country": "UK"
  },
  {
    "id": 999438,
    "name": "SUNPENTOWN INTERNATIONAL INC.    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 456188,
    "name": "Sunpex Technology Co Ltd    ( Chia Yi  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452518,
    "name": "Sunquest Information Systems (India) Pvt Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 452517,
    "name": "Sunquest Information Systems (International) Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 452515,
    "name": "Sunquest Information Systems Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 413324,
    "name": "Sunray Medical Apparatus Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 454028,
    "name": "Sunrise Electronics and Medical Parts    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 356130,
    "name": "Sunrise Industries Inc    ( Guntersville AL USA )",
    "country": "United States"
  },
  {
    "id": 183268,
    "name": "Sunrise Machine & Tool Inc    ( Detroit Lakes MN USA )",
    "country": "United States"
  },
  {
    "id": 392821,
    "name": "Sunrise Medical (Norway)    ( Nesoddtangen  Norway )",
    "country": "Norway"
  },
  {
    "id": 392818,
    "name": "Sunrise Medical AB    ( Vastra Frolunda  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 392826,
    "name": "Sunrise Medical Australia    ( Castle Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 392822,
    "name": "Sunrise Medical Benelux    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 232611,
    "name": "Sunrise Medical Canada Inc    ( Concord ON Canada )",
    "country": "Canada"
  },
  {
    "id": 348662,
    "name": "Sunrise Medical Continuing Care    ( Stevens Point WI USA )",
    "country": "United States"
  },
  {
    "id": 452088,
    "name": "Sunrise Medical European Headquarters    ( Stourbridge  England )",
    "country": "UK"
  },
  {
    "id": 392796,
    "name": "Sunrise Medical GmbH    ( Malsch/Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 150065,
    "name": "Sunrise Medical Inc    ( Longmont CO USA )",
    "country": "United States"
  },
  {
    "id": 307326,
    "name": "Sunrise Medical Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 392825,
    "name": "Sunrise Medical Italy    ( Montale (PC)  Italy )",
    "country": "Italy"
  },
  {
    "id": 455606,
    "name": "Sunrise Medical Laboratories    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 345685,
    "name": "Sunrise Medical Ltd    ( Wollaston  England )",
    "country": "UK"
  },
  {
    "id": 357764,
    "name": "Sunrise Medical Respiratory Products    ( Somerset PA USA )",
    "country": "United States"
  },
  {
    "id": 392743,
    "name": "Sunrise Medical SA    ( Rochecorbon  France )",
    "country": "France"
  },
  {
    "id": 392777,
    "name": "Sunrise Medical SL    ( Arrankudiaga (Vizcaya)  Spain )",
    "country": "Spain"
  },
  {
    "id": 392793,
    "name": "Sunrise Medical Switzerland    ( Muri bei Berne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 392794,
    "name": "Sunrise Medical Technologies SA de CV    ( Tijuana  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 439944,
    "name": "Sunrise Medical Technology Inc    ( Waxahachie TX USA )",
    "country": "United States"
  },
  {
    "id": 454692,
    "name": "Sunshine Medical Equipment Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 452364,
    "name": "Sunstar Americas Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 234567,
    "name": "Sunstar Co Inc    ( Inglewood CA USA )",
    "country": "United States"
  },
  {
    "id": 140354,
    "name": "Sunstar Inc    ( Takatsuki  Japan )",
    "country": "Japan"
  },
  {
    "id": 106877,
    "name": "SunTech Medical Inc    ( Morrisville NC USA )",
    "country": "United States"
  },
  {
    "id": 139824,
    "name": "Sunwa Ltd    ( Sayama  USA )",
    "country": "United States"
  },
  {
    "id": 306372,
    "name": "SUPA Medical Devices    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 456084,
    "name": "Super Dental Co    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 467027,
    "name": "Super Ultrasonic Co Ltd    ( New Taipei City  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451924,
    "name": "superDimension Inc    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 107481,
    "name": "Superior Dental & Surgical Mfg Co Inc    ( Port St Lucie FL USA )",
    "country": "United States"
  },
  {
    "id": 104755,
    "name": "Superior Electric    ( Farmington CA USA )",
    "country": "USA"
  },
  {
    "id": 174743,
    "name": "Superior Medical Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452798,
    "name": "Superior Products Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 170791,
    "name": "Superior Scientific Inc    ( Alhambra CA USA )",
    "country": "United States"
  },
  {
    "id": 107482,
    "name": "Superior Surgical Supply    ( Lansdowne PA USA )",
    "country": "United States"
  },
  {
    "id": 362081,
    "name": "Superior Uniform Group Inc    ( Seminole FL USA )",
    "country": "United States"
  },
  {
    "id": 456043,
    "name": "Supermax Canada Inc    ( Pointe-Claire PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 456041,
    "name": "Supermax Deutschland GmbH    ( Schewim  Germany )",
    "country": "Germany"
  },
  {
    "id": 276669,
    "name": "Supermax Glove Mfg Sdn Bhd    ( Sungai Buloh  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 349087,
    "name": "Superquad LLC    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 456460,
    "name": "SuperSonic Imagine (France)    ( Aix-en-Provence  France )",
    "country": "France"
  },
  {
    "id": 456461,
    "name": "SuperSonic Imagine (United States)    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 155082,
    "name": "Superstat Corp    ( Rancho Dominguez CA USA )",
    "country": "United States"
  },
  {
    "id": 105597,
    "name": "Supertech Inc    ( Elkhart IN USA )",
    "country": "United States"
  },
  {
    "id": 149566,
    "name": "Supex Products Corp    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 306714,
    "name": "Suplimedic SA    ( Panama City  Republic of Panama )",
    "country": "Panama"
  },
  {
    "id": 377704,
    "name": "Suppleyes Inc    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 458363,
    "name": "Supply Plus Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 347660,
    "name": "Supplyline    ( Chesapeake VA USA )",
    "country": "United States"
  },
  {
    "id": 451606,
    "name": "SupplyScape    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 155973,
    "name": "Support Plus    ( Hudson OH USA )",
    "country": "United States"
  },
  {
    "id": 452572,
    "name": "Supreme Enterprises (Regd)    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 462951,
    "name": "Suprima GmbH    ( Bad Berneck  Germany )",
    "country": "Germany"
  },
  {
    "id": 176801,
    "name": "Surace SpA    ( Moncalieri (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 449813,
    "name": "Sure-Call Co UK    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 312185,
    "name": "SureHands Lift & Care Systems    ( Pine Island NY USA )",
    "country": "United States"
  },
  {
    "id": 407394,
    "name": "SureScreen Diagnostics Ltd    ( Derby  England )",
    "country": "UK"
  },
  {
    "id": 459423,
    "name": "Surescripts    ( Arlington VA USA )",
    "country": "United States"
  },
  {
    "id": 467391,
    "name": "Suretech Medical Inc    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 465116,
    "name": "Surfacide    ( Naperville IL USA )",
    "country": "United States"
  },
  {
    "id": 306400,
    "name": "Surg-Equip Ltd    ( Drogheda  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 457223,
    "name": "Surge Cardiovascular    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 451058,
    "name": "Surge Medical Solutions LLC    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 456730,
    "name": "Surgi Equips    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 457124,
    "name": "Surgi-Source LLC    ( Keller TX USA )",
    "country": "United States"
  },
  {
    "id": 415794,
    "name": "Surgical & Medical Supplies Pty Ltd    ( Rose Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 361640,
    "name": "Surgical Acuity    ( Middleton WI USA )",
    "country": "United States"
  },
  {
    "id": 466227,
    "name": "Surgical Advantage    ( Tulsa P. USA )",
    "country": "United States"
  },
  {
    "id": 106351,
    "name": "Surgical Appliance Industries    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 456087,
    "name": "Surgical Appliances    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 457509,
    "name": "Surgical Biologics LLC    ( Kennesaw GA USA )",
    "country": "United States"
  },
  {
    "id": 101766,
    "name": "Surgical Design Corp    ( Armonk NY USA )",
    "country": "United States"
  },
  {
    "id": 456019,
    "name": "Surgical Design Inc    ( Lorton VA USA )",
    "country": "United States"
  },
  {
    "id": 461158,
    "name": "Surgical Devices Inc    ( Athens GA USA )",
    "country": "United States"
  },
  {
    "id": 260013,
    "name": "Surgical Digital Video    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 459170,
    "name": "Surgical Direct    ( Deland FL USA )",
    "country": "United States"
  },
  {
    "id": 456737,
    "name": "Surgical Holdings    ( Southend  England )",
    "country": "UK"
  },
  {
    "id": 289926,
    "name": "Surgical Information Systems Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 298504,
    "name": "Surgical Innovations Group plc    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 173906,
    "name": "Surgical Instrument Group Holdings Ltd    ( Croydon  England )",
    "country": "UK"
  },
  {
    "id": 105288,
    "name": "Surgical Laser Technologies    (   USA )",
    "country": "United States"
  },
  {
    "id": 339656,
    "name": "Surgical Optics LLC    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 466441,
    "name": "Surgical Power Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 466499,
    "name": "Surgical Principals Inc (SPI)    ( Tacoma WA USA )",
    "country": "United States"
  },
  {
    "id": 453590,
    "name": "Surgical Repair Technologies    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 463035,
    "name": "Surgical Science Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 459203,
    "name": "Surgical Science Sweden AB    ( Goeteborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 459214,
    "name": "Surgical Solutions LLC    ( Henderson KY USA )",
    "country": "United States"
  },
  {
    "id": 466513,
    "name": "Surgical Specialties    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 431191,
    "name": "Surgical Specialties (Puerto Rico) Inc    ( Rincon PR USA )",
    "country": "United States"
  },
  {
    "id": 108968,
    "name": "Surgical Specialties Corp    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 457589,
    "name": "Surgical Supply Service    ( Tonawanda NY USA )",
    "country": "United States"
  },
  {
    "id": 999892,
    "name": "Surgical Table Services Company    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 458499,
    "name": "Surgical Tables Inc    ( Middleton MA USA )",
    "country": "United States"
  },
  {
    "id": 999817,
    "name": "Surgical Tables Inc.    ( Middleton MA USA )",
    "country": "USA"
  },
  {
    "id": 104000,
    "name": "Surgical Technology Laboratories Inc    ( Columbia SC USA )",
    "country": "United States"
  },
  {
    "id": 456720,
    "name": "Surgical Tools Inc    ( Bedford VA USA )",
    "country": "United States"
  },
  {
    "id": 465114,
    "name": "Surgical West Inc    ( Redondo Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 372418,
    "name": "surgicaltools.com    ( Bedford VA USA )",
    "country": "United States"
  },
  {
    "id": 161279,
    "name": "Surgicare Pty Ltd    ( Braeside  Australia )",
    "country": "Australia"
  },
  {
    "id": 409559,
    "name": "Surgicoin    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 456088,
    "name": "Surgicon (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 324902,
    "name": "Surgicon Ltd    ( Bradford  USA )",
    "country": "United States"
  },
  {
    "id": 101794,
    "name": "Surgicot Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 161995,
    "name": "Surgicraft Ltd    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 456089,
    "name": "Surgicrafts    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 456090,
    "name": "Surgikare    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 104831,
    "name": "Surgilase Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 131931,
    "name": "Surgimark Inc    ( Yakima WA USA )",
    "country": "United States"
  },
  {
    "id": 461163,
    "name": "SurgiMate    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 460753,
    "name": "Surgimedics    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 160826,
    "name": "Surgimedics/Surgical Laser Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 450730,
    "name": "Surgimedik    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 452607,
    "name": "Surgimedik Healthcare India Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 104501,
    "name": "Surgin Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 101774,
    "name": "Surgipath Medical Industries Inc    ( Richmond IL USA )",
    "country": "United States"
  },
  {
    "id": 456810,
    "name": "SurgiQuest Inc    ( Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 146787,
    "name": "Surgiquip Solutions Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 455652,
    "name": "Surgiris    ( Croix  France )",
    "country": "France"
  },
  {
    "id": 462810,
    "name": "Surgitech Surgical Services    ( Bingham Farms MI USA )",
    "country": "United States"
  },
  {
    "id": 452571,
    "name": "SurgiTel    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 392548,
    "name": "SurgMed Medical Cos    ( Dorval PQ USA )",
    "country": "United States"
  },
  {
    "id": 458186,
    "name": "Surgo Surgical Supply    ( Keswick ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461527,
    "name": "Surgpro LLC    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 450979,
    "name": "SurgRx Inc    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 330795,
    "name": "Suros Surgical Systems Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 456727,
    "name": "Surtex Instruments Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 175985,
    "name": "Survivair    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 999893,
    "name": "SurVivalink Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 460688,
    "name": "Susan Wolfe & Assoc LLC    ( Cedar Hill TX USA )",
    "country": "United States"
  },
  {
    "id": 465071,
    "name": "Susquehanna Micro Inc.    ( Windsor P. USA )",
    "country": "United States"
  },
  {
    "id": 409960,
    "name": "Susquehanna Ventures Inc    ( Williamsport PA USA )",
    "country": "United States"
  },
  {
    "id": 999449,
    "name": "Sussman Electric Boilers    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 460643,
    "name": "Sustainable Water Solutions LLC    ( Menlo Park CA USA )",
    "country": "United States"
  },
  {
    "id": 366538,
    "name": "Sutherland Medical Pty Ltd    ( Oakleigh  Australia )",
    "country": "Australia"
  },
  {
    "id": 344068,
    "name": "Sutter Instrument Co    ( Novato CA USA )",
    "country": "United States"
  },
  {
    "id": 457356,
    "name": "Sutter Medizintechnik GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 340068,
    "name": "Sutton Landis    (   USA )",
    "country": "United States"
  },
  {
    "id": 456700,
    "name": "Suture Express    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 407221,
    "name": "Sutures India Pvt Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 184061,
    "name": "Sutures Ltd    ( Wrexham  Wales )",
    "country": "Wales"
  },
  {
    "id": 173815,
    "name": "Sutures Research Laboratories (Pty) Ltd    ( Isando  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 460699,
    "name": "SuturTek Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 416080,
    "name": "Suzhou 3B Scientific Co Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455674,
    "name": "Suzhou Huatuo Medical Instruments Co    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 399031,
    "name": "Suzhou HuQiu Imaging Equipment Co Ltd    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291483,
    "name": "Suzhou Kangdi Electronic Co Ltd    ( Wujiang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291484,
    "name": "Suzhou Latex Factory    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291486,
    "name": "Suzhou Medical Instrument General Factory    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 158015,
    "name": "Suzuken Co Ltd    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 461379,
    "name": "SVA Healthcare Services LLC    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 161554,
    "name": "Svenska Dental Instrument AB    ( Upplands Vasby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 157657,
    "name": "Svenska Dentorama AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457570,
    "name": "SVS Vision    ( Mt Clemens MI USA )",
    "country": "United States"
  },
  {
    "id": 461242,
    "name": "SW Med Source    ( Southlake TX USA )",
    "country": "United States"
  },
  {
    "id": 330078,
    "name": "Swank Motion Pictures    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 272081,
    "name": "Swann-Morton Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 149011,
    "name": "SWB Elbow Brace Ltd    ( La Quinta CA USA )",
    "country": "United States"
  },
  {
    "id": 155974,
    "name": "Swearingen Software Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 170957,
    "name": "Sweco SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 157658,
    "name": "Swede Rescue AB    ( Trollhattan  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 348316,
    "name": "Swemac Medical Appliances AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 455322,
    "name": "Swereco Rehab AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 151479,
    "name": "Swift First Aid    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 103901,
    "name": "Swift Optics    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 456219,
    "name": "SwiftTech    ( Melksham  England )",
    "country": "UK"
  },
  {
    "id": 107483,
    "name": "SwimEx Systems    ( Fall River MA USA )",
    "country": "United States"
  },
  {
    "id": 460799,
    "name": "Swinson Medical LLC    ( Penhook VA USA )",
    "country": "United States"
  },
  {
    "id": 367469,
    "name": "Swiss American Products Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 455294,
    "name": "Swissimplant AG    ( Solothum  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 372422,
    "name": "Swisslog    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 354504,
    "name": "Swissray America Inc    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 280564,
    "name": "Swissray GmbH Digital X-ray Technology    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 339300,
    "name": "Swissray International Inc    ( East Brunswick NJ USA )",
    "country": "United States"
  },
  {
    "id": 177064,
    "name": "Swissray Medical AG    ( Hochdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 384321,
    "name": "Swissray Medical sro    ( Brno/Lesna  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 378613,
    "name": "Swissray Romania srl    ( Bucuresti Sector 5 & 6  Romania )",
    "country": "Romania"
  },
  {
    "id": 467093,
    "name": "SWN Communications Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 459340,
    "name": "SWT Therapy SRL    ( Sosua  Dominican Republic )",
    "country": "Dominican Republic"
  },
  {
    "id": 458879,
    "name": "SXC Health Solutions Corp    ( Lisle IL USA )",
    "country": "United States"
  },
  {
    "id": 272611,
    "name": "SY-LAB Geraete GmbH    ( Neupurkersdorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 399083,
    "name": "Sybaritic Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 461456,
    "name": "Sybase    ( Dublin CA USA )",
    "country": "United States"
  },
  {
    "id": 364520,
    "name": "Sybron Dental Specialties Inc    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 453577,
    "name": "Sybron Implant Solutions (US)    ( Orange CA USA )",
    "country": "United States"
  },
  {
    "id": 453576,
    "name": "Sybron Implant Solutions GmbH    ( Bramen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453579,
    "name": "Sybron Implant Solutions Pty    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 465163,
    "name": "SybronEndo Corp    ( Orange CA USA )",
    "country": "USA"
  },
  {
    "id": 106413,
    "name": "Sylvan Corp    ( Irwin PA USA )",
    "country": "United States"
  },
  {
    "id": 999646,
    "name": "Sylvania Television    (   USA )",
    "country": "United States"
  },
  {
    "id": 161041,
    "name": "Symbio Corp    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 457766,
    "name": "Symbios Medical Products LLC    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 458486,
    "name": "Symbiote Inc    ( Zeeland MI USA )",
    "country": "United States"
  },
  {
    "id": 398918,
    "name": "Symbol Technologies AB    ( Solna  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 398916,
    "name": "Symbol Technologies Africa Inc    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 398906,
    "name": "Symbol Technologies Asia Inc (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 398913,
    "name": "Symbol Technologies Asia Inc (Korea)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 398900,
    "name": "Symbol Technologies Asia Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 398903,
    "name": "Symbol Technologies Benelux    ( Bruxelles  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 398901,
    "name": "Symbol Technologies Ges mbh    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 398910,
    "name": "Symbol Technologies GmbH    ( Dietzenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 398911,
    "name": "Symbol Technologies Hong Kong Ltd    ( Causeway Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107930,
    "name": "Symbol Technologies Inc    ( Holtsville NY USA )",
    "country": "United States"
  },
  {
    "id": 294456,
    "name": "Symbol Technologies Ltd    ( Winnersh Triangle  England )",
    "country": "UK"
  },
  {
    "id": 398919,
    "name": "Symbol Technologies ME    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 398914,
    "name": "Symbol Technologies Mexico Ltd    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 398915,
    "name": "Symbol Technologies Nederland bv    ( Varsseveld  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 398908,
    "name": "Symbol Technologies Oy    ( Helsinki  Finland )",
    "country": "Finland"
  },
  {
    "id": 398902,
    "name": "Symbol Technologies Pty Ltd    ( Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 398909,
    "name": "Symbol Technologies SA (France)    ( Antony Cedex  France )",
    "country": "France"
  },
  {
    "id": 398917,
    "name": "Symbol Technologies SA (Spain)    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 398912,
    "name": "Symbol Technologies srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458065,
    "name": "Symetis SA    ( Ecublens VD  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 270540,
    "name": "Symmetry Medical Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 409716,
    "name": "Symmetry Medical Inc    ( Manchester NH USA )",
    "country": "United States"
  },
  {
    "id": 462972,
    "name": "Symmetry Surgical    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 426244,
    "name": "Symphony Medical Inc    ( Miami Lakes FL USA )",
    "country": "United States"
  },
  {
    "id": 270562,
    "name": "SymTech Communications Services    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 452126,
    "name": "SynaMed    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 466293,
    "name": "Synapse Medical LLC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 999643,
    "name": "Synapsys Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 459478,
    "name": "SynaptiCore    ( Katy TX USA )",
    "country": "United States"
  },
  {
    "id": 466501,
    "name": "Synaptive Medical Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461197,
    "name": "Synbiosis    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 451870,
    "name": "Synca Marketing    ( Le Gardeur PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 441446,
    "name": "SynCardia Systems Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 459476,
    "name": "Syncro Medical Innovations    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 324491,
    "name": "Synectics Medical Ltd    ( Enfield  USA )",
    "country": "United States"
  },
  {
    "id": 457858,
    "name": "Synedra Information Technologies GmbH    ( Innsbruck  Austria )",
    "country": "Austria"
  },
  {
    "id": 103155,
    "name": "Synemed Inc    ( Benicia CA USA )",
    "country": "United States"
  },
  {
    "id": 440152,
    "name": "Synergetics Inc    ( St Charles MO USA )",
    "country": "United States"
  },
  {
    "id": 460957,
    "name": "Synergic Solutions Inc    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 460719,
    "name": "Synergy Broadcast Systems    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 454736,
    "name": "Synergy Health plc    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 457354,
    "name": "Synergy Health Radeberg GmbH    ( Radeberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 408132,
    "name": "Synermed Diagnostic Corp    ( Westfield IN USA )",
    "country": "United States"
  },
  {
    "id": 455386,
    "name": "Synermed do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 304193,
    "name": "Synermed Europe Ltd    ( Burgess Hill  England )",
    "country": "UK"
  },
  {
    "id": 186616,
    "name": "Synermed International Inc    ( Westfield IN USA )",
    "country": "United States"
  },
  {
    "id": 458809,
    "name": "Syneron Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 999873,
    "name": "Syneron Inc.    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 456889,
    "name": "Syneron Medical Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 453453,
    "name": "Syngene    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 416037,
    "name": "Synlab Diagnostic GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 409723,
    "name": "Synovis Life Technologies Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 409724,
    "name": "Synovis Micro Companies Alliance Inc    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 409722,
    "name": "Synovis Surgical Innovations    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 107868,
    "name": "Synrad Inc    ( Mukilteo WA USA )",
    "country": "United States"
  },
  {
    "id": 461201,
    "name": "Syntermed Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 150210,
    "name": "Syntex Industries    ( Humboldt IA USA )",
    "country": "United States"
  },
  {
    "id": 451187,
    "name": "Synthes (Canada) Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 170958,
    "name": "Synthes AG Chur    ( Chur  USA )",
    "country": "United States"
  },
  {
    "id": 454976,
    "name": "Synthes Asia Pacific Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 451496,
    "name": "Synthes GmbH    ( Solothurn  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 101776,
    "name": "Synthes Inc    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 453945,
    "name": "Synthes Ltd    ( Welwyn Garden City  England )",
    "country": "UK"
  },
  {
    "id": 263229,
    "name": "Synton AG    ( Lyss  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 161035,
    "name": "Syntramed Juergen Horn    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452550,
    "name": "Syntron Bioresearch Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 330928,
    "name": "Synvasive Technology Inc    ( El Dorado Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 431353,
    "name": "Sypris Test & Measurement Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 154148,
    "name": "Syracuse Medical Devices Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 186771,
    "name": "Syracuse Medical Equipment Repair Inc    ( North Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 461438,
    "name": "Syris Scientific LLC    ( Gray ME USA )",
    "country": "United States"
  },
  {
    "id": 392525,
    "name": "Sysmex (Malaysia) Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 392527,
    "name": "Sysmex (Thailand) Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 455357,
    "name": "Sysmex Canada Inc    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 349743,
    "name": "Sysmex Corp    ( Kobe  Japan )",
    "country": "Japan"
  },
  {
    "id": 183919,
    "name": "Sysmex Corp of America    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 392522,
    "name": "Sysmex do Brasil Industria e Comercio Ltda    ( Sao Jose dos Pinhais-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 349744,
    "name": "Sysmex Europe GmbH    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 392521,
    "name": "Sysmex France Sarl    ( Roissy-Charles-de-Gaulle Cedex  France )",
    "country": "France"
  },
  {
    "id": 392524,
    "name": "Sysmex Hong Kong Ltd    ( Queensway  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392529,
    "name": "Sysmex Molis SA    ( Blegny/Barchon  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457658,
    "name": "Sysmex New Zealand Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 391829,
    "name": "Sysmex Reagents America Inc    ( Mundelein IL USA )",
    "country": "United States"
  },
  {
    "id": 392528,
    "name": "Sysmex San Tung Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455356,
    "name": "Sysmex Shanghai Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 349745,
    "name": "Sysmex Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 392526,
    "name": "Sysmex Transasia Biomedicals Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 225965,
    "name": "Sysmex UK Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 454735,
    "name": "Systagenix Wound Management    ( Quincy MA USA )",
    "country": "United States"
  },
  {
    "id": 172270,
    "name": "Systam Assistance Medical SA    ( Le Ledat  France )",
    "country": "France"
  },
  {
    "id": 185158,
    "name": "SysTec Computer Assoc Inc    ( Mount Sinai NY USA )",
    "country": "United States"
  },
  {
    "id": 306401,
    "name": "Systec GmbH Labor-Systemtechnik    ( Wettenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285436,
    "name": "systemform MediaCard GmbH & Co KG    ( Prien am Chiemsee  Germany )",
    "country": "Germany"
  },
  {
    "id": 186772,
    "name": "Systemic Junctures Corp    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 459008,
    "name": "SystemLink Inc    ( Sterling VA USA )",
    "country": "United States"
  },
  {
    "id": 461204,
    "name": "Systems Design & Integration Inc    ( West Roxbury MA USA )",
    "country": "United States"
  },
  {
    "id": 454263,
    "name": "Systems Technologies    ( Hayden ID USA )",
    "country": "United States"
  },
  {
    "id": 461435,
    "name": "Systems Technologies    ( Merill WI USA )",
    "country": "United States"
  },
  {
    "id": 460750,
    "name": "Systems Technologies Inc    ( West Long Branch NJ USA )",
    "country": "United States"
  },
  {
    "id": 999548,
    "name": "Systems Technologies, Inc.    ( Hayden IO USA )",
    "country": "USA"
  },
  {
    "id": 466610,
    "name": "Systems Technology Inc    ( Hawthorne CA USA )",
    "country": "United States"
  },
  {
    "id": 460711,
    "name": "SystemsOne LLC    ( Stuart FL USA )",
    "country": "United States"
  },
  {
    "id": 999658,
    "name": "Systium Technologies, LLC    ( New Hope MN USA )",
    "country": "United States"
  },
  {
    "id": 271295,
    "name": "Sysware Information Systems Inc    ( Southfield MI USA )",
    "country": "United States"
  },
  {
    "id": 462792,
    "name": "Sywest Medical Technologies Inc    ( East Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 455468,
    "name": "T & K Silicate Europe GmbH    ( Weyhe bei Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456262,
    "name": "T & L Co Ltd    ( Yongin  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 151514,
    "name": "T-Medical Systems Inc    ( Watauga TX USA )",
    "country": "United States"
  },
  {
    "id": 453704,
    "name": "T-System Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 461206,
    "name": "T&D Corp    ( Matsumoto  Japan )",
    "country": "Japan"
  },
  {
    "id": 461386,
    "name": "T3 Motion    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 457516,
    "name": "TA Instruments    ( New Castle DE USA )",
    "country": "United States"
  },
  {
    "id": 172326,
    "name": "Tab Cosmetics/Meditab Ltd    ( Tel Mond  Israel )",
    "country": "Israel"
  },
  {
    "id": 467205,
    "name": "TAB Products Co LLC    ( Mayville WI USA )",
    "country": "United States"
  },
  {
    "id": 158017,
    "name": "TABAI Espec Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 461167,
    "name": "Tableau Software    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 183053,
    "name": "Tabufile    ( St John NB Canada )",
    "country": "Canada"
  },
  {
    "id": 146789,
    "name": "Tacoma Biomedical Inc    ( Gig Harbor WA USA )",
    "country": "United States"
  },
  {
    "id": 455202,
    "name": "Tactical Medical Solutions Inc    ( Anderson SC USA )",
    "country": "United States"
  },
  {
    "id": 453760,
    "name": "Tactile Systems Technology Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 151544,
    "name": "Taema    ( Antony Cedex  France )",
    "country": "France"
  },
  {
    "id": 999827,
    "name": "Tafco    ( Hyde PA USA )",
    "country": "United States"
  },
  {
    "id": 456509,
    "name": "Tafford Uniforms    ( North Wales PA USA )",
    "country": "United States"
  },
  {
    "id": 459381,
    "name": "Tag Fitness    ( Aurora IL USA )",
    "country": "United States"
  },
  {
    "id": 227315,
    "name": "TAG Medical Products Ltd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 140161,
    "name": "Tagarno Innovision A/S    ( Horsens  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 106960,
    "name": "Tagg Industries    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 465006,
    "name": "Taico Incentive Services Inc    ( Patterson NY USA )",
    "country": "United States"
  },
  {
    "id": 456959,
    "name": "TaiDoc    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456189,
    "name": "TaiDoc Technology Corp    ( New Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 459412,
    "name": "Tait Ltd    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 453605,
    "name": "Taiwan Stanch Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999686,
    "name": "Taiwan Video System,.LTD (TVS)    (   USA )",
    "country": "United States"
  },
  {
    "id": 158018,
    "name": "Taiyu Medical Industry Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 461430,
    "name": "TAJ Flooring Inc    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 183933,
    "name": "Takagi Seiko Co Ltd    ( Nakano  Japan )",
    "country": "Japan"
  },
  {
    "id": 451233,
    "name": "Takaneh    ( Tehran  Iran )",
    "country": "Iran"
  },
  {
    "id": 138244,
    "name": "Takara Belmont Corp    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 182843,
    "name": "Takara Co Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 289615,
    "name": "Takasago Medical Industry Co    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 466577,
    "name": "Takei Scientific Instruments Co Ltd    ( Niigata City  Japan )",
    "country": "Japan"
  },
  {
    "id": 174140,
    "name": "Takeuchi Kenkyusho Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 332299,
    "name": "TAL Technologies Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 103567,
    "name": "Talk-A-Phone Co    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 149093,
    "name": "Talley Group Ltd    ( Romsey  England )",
    "country": "UK"
  },
  {
    "id": 439395,
    "name": "Talley Medical (United States)    ( Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 999789,
    "name": "TallyGenicom    ( Irvine CA USA )",
    "country": "USA"
  },
  {
    "id": 451298,
    "name": "Talyst Corp    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 293817,
    "name": "Tamagni AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 353255,
    "name": "Tampa Hyperbaric Enterprise    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 158022,
    "name": "Tamron Co Ltd    ( Saitama  Japan )",
    "country": "Japan"
  },
  {
    "id": 172327,
    "name": "Tana Industries Ltd    ( Haela  Israel )",
    "country": "Israel"
  },
  {
    "id": 344911,
    "name": "Tandberg (Canada) Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 373072,
    "name": "Tandberg (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 373071,
    "name": "Tandberg (UK)    ( Egham  England )",
    "country": "UK"
  },
  {
    "id": 270560,
    "name": "Tandberg ASA    ( Lysaker  Norway )",
    "country": "Norway"
  },
  {
    "id": 298372,
    "name": "Tandberg Inc    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 460614,
    "name": "Tandem Diabetes Care    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 399033,
    "name": "Tandem Medical Inc    ( Encinitas CA USA )",
    "country": "United States"
  },
  {
    "id": 459093,
    "name": "Tangent Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 999517,
    "name": "Tangent Inc.    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 457221,
    "name": "Tango3 LLC    ( Simpsonville KY USA )",
    "country": "United States"
  },
  {
    "id": 172241,
    "name": "Tanita Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 172239,
    "name": "Tanita Corp of America Inc    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 172240,
    "name": "Tanita Europe GmbH    ( Sindelfingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 357472,
    "name": "Tanita Health Equipment Hong Kong Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 357473,
    "name": "Tanita UK Ltd    ( Yiewsley  England )",
    "country": "UK"
  },
  {
    "id": 292689,
    "name": "Tanji Consultants    ( Issaquah WA USA )",
    "country": "United States"
  },
  {
    "id": 457087,
    "name": "Tankar Medical    ( Highlands Ranch CO USA )",
    "country": "United States"
  },
  {
    "id": 466962,
    "name": "tantum AG    ( Neumuenster  Germany )",
    "country": "Germany"
  },
  {
    "id": 461421,
    "name": "TAP Biosystems    ( Royston  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 353280,
    "name": "TAPCO Medical Inc    ( Calabasas CA USA )",
    "country": "United States"
  },
  {
    "id": 452570,
    "name": "Tapeless Wound Care Products LLC    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 455827,
    "name": "Tapuz Medical Ltd    ( Gat Rimon  Israel )",
    "country": "Israel"
  },
  {
    "id": 330142,
    "name": "TaraMedic Corp Sdn Bhd    ( Kuala Lumpur  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 457577,
    "name": "Targus HQ    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 451284,
    "name": "Tarkett Commercial    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 281571,
    "name": "Tarkett Holding GmbH Bereich Objekt    ( Frankenthal  Germany )",
    "country": "Germany"
  },
  {
    "id": 466796,
    "name": "Tarrison Products Ltd    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 108921,
    "name": "Tarry Mfg Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 335451,
    "name": "Tartan Group    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 103906,
    "name": "Tartan Orthopedics Ltd    ( Northglenn CO USA )",
    "country": "United States"
  },
  {
    "id": 457528,
    "name": "TASC Systems Inc    ( Langley BC Canada )",
    "country": "Canada"
  },
  {
    "id": 465208,
    "name": "Taser International, Inc.    ( x x USA )",
    "country": "United States"
  },
  {
    "id": 146792,
    "name": "Task Force Solutions    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 151924,
    "name": "Tasserit SA    ( Sens Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 457969,
    "name": "TatraMed Spol SRO    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 999629,
    "name": "Tatung    ( Taipei City 104  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 398858,
    "name": "Taugagreining hf    ( Reykjavik  USA )",
    "country": "United States"
  },
  {
    "id": 457109,
    "name": "Tauro-Implant GmbH    ( Winsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 271592,
    "name": "Taut Inc    ( Geneva IL USA )",
    "country": "United States"
  },
  {
    "id": 144417,
    "name": "Tavco Services Inc    ( Pflugerville TX USA )",
    "country": "United States"
  },
  {
    "id": 192840,
    "name": "Tavom SpA    ( Due Carrare (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 461211,
    "name": "Tayco Panelink Ltd    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 279064,
    "name": "Taylor Bryant Pty Ltd    ( St Leonards  USA )",
    "country": "United States"
  },
  {
    "id": 416420,
    "name": "Taylor Bryant Pty Ltd (New Zealand)    ( Auckland  USA )",
    "country": "United States"
  },
  {
    "id": 105299,
    "name": "Taylor Co    ( Rockton IL USA )",
    "country": "United States"
  },
  {
    "id": 457793,
    "name": "Taylor Healthcare Products Inc    ( Spring TX USA )",
    "country": "United States"
  },
  {
    "id": 108861,
    "name": "Taylor Made Ambulances    ( Newport AR USA )",
    "country": "United States"
  },
  {
    "id": 999692,
    "name": "Taylor Precision Products    ( Las Cruce NM USA )",
    "country": "USA"
  },
  {
    "id": 163030,
    "name": "Taylor Scientific Equipment Inc    ( Bethlehem CT USA )",
    "country": "United States"
  },
  {
    "id": 186777,
    "name": "Taylor-Wharton    ( Theodore AL USA )",
    "country": "United States"
  },
  {
    "id": 416016,
    "name": "Taylor-Wharton Harsco GmbH    ( Husum  Germany )",
    "country": "Germany"
  },
  {
    "id": 182849,
    "name": "TBJ Inc    ( Chambersburg PA USA )",
    "country": "United States"
  },
  {
    "id": 461399,
    "name": "TCE Technologies Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456197,
    "name": "Tcoag Ireland Ltd    ( Bray  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 456619,
    "name": "TCS Biosciences Ltd    ( Buckingham  England )",
    "country": "UK"
  },
  {
    "id": 312181,
    "name": "TCS Scientific Corp    ( New Hope PA USA )",
    "country": "United States"
  },
  {
    "id": 441586,
    "name": "TDC Filter Mfg Inc    ( Bolingbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 434164,
    "name": "TDH Medical    ( Cape Town  USA )",
    "country": "United States"
  },
  {
    "id": 461401,
    "name": "TDI Technologies Inc    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 999870,
    "name": "TDP    (   USA )",
    "country": "United States"
  },
  {
    "id": 461214,
    "name": "TE Connectivity Ltd    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 371965,
    "name": "Te Me Na SAS    ( Carrieres sur Seine  France )",
    "country": "France"
  },
  {
    "id": 455196,
    "name": "TE Technology Inc    ( Traverse City MI USA )",
    "country": "United States"
  },
  {
    "id": 106747,
    "name": "TEAC America Inc    ( Montebello CA USA )",
    "country": "United States"
  },
  {
    "id": 452968,
    "name": "TEAC Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452969,
    "name": "TEAC Deutschland GmbH    ( Wiesbaden-Erbenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 452970,
    "name": "TEAC Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 324323,
    "name": "TEAC UK Ltd    ( Watford  England )",
    "country": "UK"
  },
  {
    "id": 452560,
    "name": "Teal Electronics Corp    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 281347,
    "name": "Team 77 Wetzel & Franke GmbH    ( Erkrath-Hochdahl  Germany )",
    "country": "Germany"
  },
  {
    "id": 454961,
    "name": "Team Hybrid Ltd    ( Knowle  England )",
    "country": "UK"
  },
  {
    "id": 346550,
    "name": "Team-Best Inc (M-E Taiwan)    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456190,
    "name": "Teamchang International Co Ltd    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 400991,
    "name": "Teamedics Mfg Co Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451813,
    "name": "TeamHealth    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 461420,
    "name": "TeamQuest Corp    ( Clear Lake IA USA )",
    "country": "United States"
  },
  {
    "id": 465045,
    "name": "TeamViewer Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 458007,
    "name": "TearLab    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456881,
    "name": "Teasdale Hospital Equipment Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 174429,
    "name": "TEB (Tecnologia Eletronica Brasileira Ltda)    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457505,
    "name": "TECA Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 149105,
    "name": "TECAN AG    ( Maennedorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391124,
    "name": "TECAN Austria GmbH    ( Groedig  Austria )",
    "country": "Austria"
  },
  {
    "id": 384295,
    "name": "TECAN Deutschland GmbH    ( Crailsheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 452683,
    "name": "Tecan Schweiz AG    ( Mannedorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 441631,
    "name": "TECAN Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 452684,
    "name": "Tecan US    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 241810,
    "name": "TECAN US Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 456610,
    "name": "Tecfen Corp    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 410018,
    "name": "Tech + Med Industries LP    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 358047,
    "name": "Tech Air    ( White Plains NY USA )",
    "country": "United States"
  },
  {
    "id": 464986,
    "name": "Tech Systems Inc    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 459196,
    "name": "Tech Works    ( Yorba Linda CA USA )",
    "country": "United States"
  },
  {
    "id": 452551,
    "name": "Tech-Lab Mfg Sdn Bhd    ( Selangor  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 471598,
    "name": "Tech-Med Services    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 999875,
    "name": "Tech-Med Services Inc.    ( Smithtown NY USA )",
    "country": "United States"
  },
  {
    "id": 234588,
    "name": "Tech-Time Inc    ( Billings MT USA )",
    "country": "United States"
  },
  {
    "id": 458898,
    "name": "TechEn Inc    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 184607,
    "name": "TECHLAB Inc    ( Blacksburg VA USA )",
    "country": "United States"
  },
  {
    "id": 458269,
    "name": "Techlem Medical Corp    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461397,
    "name": "Techline USA LLC    ( Waunakee WI USA )",
    "country": "United States"
  },
  {
    "id": 185572,
    "name": "Techma-Robot Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 340718,
    "name": "Techman International Corp    ( Charlton City MA USA )",
    "country": "United States"
  },
  {
    "id": 289626,
    "name": "Techna Electronics Ind Co Ltd    ( Chofu  Japan )",
    "country": "Japan"
  },
  {
    "id": 103082,
    "name": "Techne Inc    ( Burlington NJ USA )",
    "country": "United States"
  },
  {
    "id": 140225,
    "name": "Techne Ltd    ( Stone  England )",
    "country": "UK"
  },
  {
    "id": 187806,
    "name": "Techni-Tool Inc    ( Worcester PA USA )",
    "country": "United States"
  },
  {
    "id": 102666,
    "name": "Technical Assoc    ( Canoga Park CA USA )",
    "country": "United States"
  },
  {
    "id": 187807,
    "name": "Technical Devices & Services    ( Seekonk MA USA )",
    "country": "United States"
  },
  {
    "id": 106883,
    "name": "Technical Dynamics Inc    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 187809,
    "name": "Technical Instrument San Francisco    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 456971,
    "name": "Technical Manufacturing Corp    ( Peabody MA USA )",
    "country": "United States"
  },
  {
    "id": 187810,
    "name": "Technical Options Inc    ( Newark OH USA )",
    "country": "United States"
  },
  {
    "id": 333520,
    "name": "Technical Products Inc    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 371529,
    "name": "Technical Prospects    ( Greenville WI USA )",
    "country": "United States"
  },
  {
    "id": 453353,
    "name": "Technical Service Consultants Ltd    ( Heywood  England )",
    "country": "UK"
  },
  {
    "id": 431622,
    "name": "Technical Services Group Inc    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 106884,
    "name": "Technical Services Program    ( Burlington VT USA )",
    "country": "United States"
  },
  {
    "id": 440737,
    "name": "Technical Solutions Australia Pty Ltd    ( Silvan  Australia )",
    "country": "Australia"
  },
  {
    "id": 193354,
    "name": "Technicas Medicas Mab SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 452564,
    "name": "Technicuff Corp    ( Leesburg FL USA )",
    "country": "United States"
  },
  {
    "id": 452721,
    "name": "Technidata America LLC    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 457415,
    "name": "Technidata America Medical Software LLC    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 452722,
    "name": "Technidata Asia-Pacific    ( Makati  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 452720,
    "name": "Technidata SAS    ( Montbonnot  France )",
    "country": "France"
  },
  {
    "id": 454980,
    "name": "Technifab Products Inc    ( Brazil IN USA )",
    "country": "United States"
  },
  {
    "id": 306792,
    "name": "Technik fuer Menschen GmbH    ( Hamm  USA )",
    "country": "United States"
  },
  {
    "id": 280475,
    "name": "Technika Kiessling GmbH    ( Braunschweig  Germany )",
    "country": "Germany"
  },
  {
    "id": 332061,
    "name": "Technima    ( Ramat Gan  Israel )",
    "country": "Israel"
  },
  {
    "id": 473850,
    "name": "Techniplast    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 999744,
    "name": "Techniquip    (   USA )",
    "country": "United States"
  },
  {
    "id": 283153,
    "name": "Technische Glaswerke Ilmenau GmbH    ( Ilmenau  Germany )",
    "country": "Germany"
  },
  {
    "id": 281647,
    "name": "Technische Orthopaedie Habermann GmbH    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 227244,
    "name": "Technix SpA    ( Grassobbio (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458131,
    "name": "Techno Concept    ( Mane  France )",
    "country": "France"
  },
  {
    "id": 457854,
    "name": "Techno Inc    ( New Hyde Park NY USA )",
    "country": "United States"
  },
  {
    "id": 332036,
    "name": "Techno Medica Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 415974,
    "name": "Techno Plas Pty Ltd    ( St Marys  Australia )",
    "country": "Australia"
  },
  {
    "id": 286134,
    "name": "Techno Strack GmbH    ( Siegen  Germany )",
    "country": "Germany"
  },
  {
    "id": 392415,
    "name": "Techno-Aide Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 294463,
    "name": "Technoflex    ( Bidart  France )",
    "country": "France"
  },
  {
    "id": 458273,
    "name": "Technogym USA Corp    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 457167,
    "name": "Technolas Perfect Vision GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457169,
    "name": "Technolas Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457168,
    "name": "Technolas US LLC    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 174078,
    "name": "Technologie Avanzate TA srl    ( Torino (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 241959,
    "name": "Technologie Medicale SA    ( Noisy-le-Sec Cedex  France )",
    "country": "France"
  },
  {
    "id": 323469,
    "name": "Technologies Machines Speciales (TMS)    ( Champs-sur-Yonne  France )",
    "country": "France"
  },
  {
    "id": 461512,
    "name": "Technology Connection    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 146800,
    "name": "Technology Imaging Services    ( Youngstown OH USA )",
    "country": "United States"
  },
  {
    "id": 107491,
    "name": "Technology in Medicine Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 192841,
    "name": "Technomedica    ( Athinai  Greece )",
    "country": "Greece"
  },
  {
    "id": 999913,
    "name": "Technoneb    (   USA )",
    "country": "United States"
  },
  {
    "id": 281978,
    "name": "Technosanat Innovative Medizintechnik Joerg Trempe    ( Gera  Germany )",
    "country": "Germany"
  },
  {
    "id": 451669,
    "name": "Technovent Ltd    ( Rogerstone  Wales )",
    "country": "Wales"
  },
  {
    "id": 474835,
    "name": "TECHNOWIPE Inc    ( Larchmont NY USA )",
    "country": "United States"
  },
  {
    "id": 187813,
    "name": "Technowood America Corp    ( Costa Mesa CA USA )",
    "country": "United States"
  },
  {
    "id": 455542,
    "name": "Technowood International Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 434251,
    "name": "Technuity Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 461221,
    "name": "TechRite Sales & Manufacturing Inc    ( San Roman CA USA )",
    "country": "United States"
  },
  {
    "id": 349095,
    "name": "TechSource Products Inc    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 455492,
    "name": "TECIL SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 458504,
    "name": "Tecmag Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 226196,
    "name": "Tecme SA    ( Cordoba  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 103569,
    "name": "Tecni-Quip    ( Seguin TX USA )",
    "country": "United States"
  },
  {
    "id": 184100,
    "name": "Tecnica Cientifica SA    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 174430,
    "name": "Tecnica Permatron Industria e Comercio Ltda    ( Joinville-SC  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 453482,
    "name": "Tecnimed srl    ( Vedano Olona (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460742,
    "name": "Tecniplast SpA    ( Buguggiate (VA)  Italy )",
    "country": "Italy"
  },
  {
    "id": 460746,
    "name": "Tecniplast USA Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 289394,
    "name": "Tecno Suma Internacional    ( Habana  Cuba )",
    "country": "Cuba"
  },
  {
    "id": 152517,
    "name": "TECNO-GAZ SpA    ( Sala Baganza (PR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 332069,
    "name": "Tecnobio Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 281527,
    "name": "Tecnomara Deutschland GmbH    ( Fernwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 104386,
    "name": "Tecnomed USA    ( Bay Shore NY USA )",
    "country": "United States"
  },
  {
    "id": 330596,
    "name": "Teco Diagnostics    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 154421,
    "name": "TECO GmbH    ( Neufahrn  Germany )",
    "country": "Germany"
  },
  {
    "id": 461225,
    "name": "Tecogen Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 456463,
    "name": "Tecres SpA    ( Verona (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462950,
    "name": "Tectara GmbH    ( Altnau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 999938,
    "name": "Tectrix    (   USA )",
    "country": "United States"
  },
  {
    "id": 454844,
    "name": "Ted Pella Inc    ( Redding CA USA )",
    "country": "United States"
  },
  {
    "id": 456459,
    "name": "TemPure Scientific    ( Gloucester NJ USA )",
    "country": "United States"
  },
  {
    "id": 457373,
    "name": "TeDan Surgical Innovations LLC    ( Sugar Land TX USA )",
    "country": "United States"
  },
  {
    "id": 106207,
    "name": "Tedro Enterprises    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 455521,
    "name": "Tee-Zed Products Pty Ltd    ( Bondi Junction  Australia )",
    "country": "Australia"
  },
  {
    "id": 331719,
    "name": "Teftec Corp    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 454359,
    "name": "Tegra Medical    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 280484,
    "name": "Tegro Runge GmbH & Co KG    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 343154,
    "name": "TEI Biosciences Inc    ( South Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 186603,
    "name": "Teisan KK    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 457173,
    "name": "Tek-Air Systems Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 461229,
    "name": "TekCel CNC Solutions    ( Perth  Australia )",
    "country": "Australia"
  },
  {
    "id": 451810,
    "name": "Tekia Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 344859,
    "name": "Tekmed Instruments SpA    ( Rozzano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 458163,
    "name": "TekMed Pty Ltd    ( Preston  Australia )",
    "country": "Australia"
  },
  {
    "id": 435604,
    "name": "Tekni-Plex Europe NV    ( Erembodegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 435605,
    "name": "Tekni-Plex Inc    ( Somerville NJ USA )",
    "country": "United States"
  },
  {
    "id": 451987,
    "name": "Teknimed SA    ( Vic en Bigorre  France )",
    "country": "France"
  },
  {
    "id": 139170,
    "name": "Tekno-Medical Optik Chirurgie GmbH & Co KG    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 294464,
    "name": "Teknor Apex Co    ( Pawtucket RI USA )",
    "country": "United States"
  },
  {
    "id": 452562,
    "name": "Tekscan Inc    ( South Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 356810,
    "name": "TekTone Sound and Signal Mfg Inc    ( Franklin NC USA )",
    "country": "United States"
  },
  {
    "id": 999415,
    "name": "TEKTRAN, INC.    ( Edmonds WA USA )",
    "country": "United States"
  },
  {
    "id": 104341,
    "name": "Tektronix Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 330005,
    "name": "TELCOR Inc    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 281117,
    "name": "Tele-Components Klaus H Mueller GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 292694,
    "name": "Tele-Images Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 456038,
    "name": "Tele-Paper (M) Sdn Bhd    ( Shah Alam  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 451821,
    "name": "Tele-Tracking Technologies Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 103142,
    "name": "TeleDiagnostic Systems    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 461393,
    "name": "Teledynamics LLC    ( Towaco NJ USA )",
    "country": "United States"
  },
  {
    "id": 101785,
    "name": "Teledyne Analytical Instruments    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 452368,
    "name": "Teledyne Isco (Belgium)    ( Wierde  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 452366,
    "name": "Teledyne Isco Inc    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 451567,
    "name": "Teledyne Leeman Labs Inc    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 428446,
    "name": "Teledyne Tekmar    ( Mason OH USA )",
    "country": "United States"
  },
  {
    "id": 465169,
    "name": "TeleEMG, LLC    ( Woburn 65 USA )",
    "country": "United States"
  },
  {
    "id": 450682,
    "name": "Teleflex Medical    ( Research Triangle Park NC USA )",
    "country": "United States"
  },
  {
    "id": 453396,
    "name": "Teleflex Medical    ( Reading PA USA )",
    "country": "United States"
  },
  {
    "id": 454906,
    "name": "Teleflex Medical (Pty) Ltd    ( Kelvin  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454908,
    "name": "Teleflex Medical AG    ( Belp  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 450683,
    "name": "Teleflex Medical Asia/Pacific    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 454811,
    "name": "Teleflex Medical Europe Ltd    ( Athlone  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 454895,
    "name": "Teleflex Medical GmbH    ( Kernen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454907,
    "name": "Teleflex Medical Iberia SA    ( Alcala de Henares (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 454336,
    "name": "Teleflex Medical LP    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451657,
    "name": "Teleflex Medical sa    ( Le Faget  France )",
    "country": "France"
  },
  {
    "id": 454909,
    "name": "Teleflex Medical Srl    ( Veredo (MB)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453924,
    "name": "Teleflex Medical UK    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 222523,
    "name": "Telegenix Inc    ( Rancocas NJ USA )",
    "country": "United States"
  },
  {
    "id": 454868,
    "name": "TeleHealth Services    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 285456,
    "name": "Telelift GmbH    ( Puchheim bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461504,
    "name": "TeleManager Technologies Inc    ( Newark NJ USA )",
    "country": "United States"
  },
  {
    "id": 108623,
    "name": "Telemed Systems Inc    ( Hudson MA USA )",
    "country": "United States"
  },
  {
    "id": 399141,
    "name": "TeleMedic Systems Ltd    ( Taunton  England )",
    "country": "UK"
  },
  {
    "id": 234610,
    "name": "Telemedicine Systems Corp    ( Coral Gables FL USA )",
    "country": "United States"
  },
  {
    "id": 283805,
    "name": "Telemetrie Elektronik Datatel GmbH    ( Langenhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461388,
    "name": "TelePresence Tech    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 460651,
    "name": "TeleResults Corp    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 101787,
    "name": "Telesensory Corp    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 409570,
    "name": "Telesensory France    ( Paris  USA )",
    "country": "United States"
  },
  {
    "id": 453913,
    "name": "Telesensory Mfg Sdn Bhd    ( Kulaijaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 409569,
    "name": "Telesensory UK    ( Calne  England )",
    "country": "UK"
  },
  {
    "id": 151074,
    "name": "Teletechnicom bv    ( Raamsdonksveer  USA )",
    "country": "United States"
  },
  {
    "id": 462993,
    "name": "TeleTracking Technologies Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 151046,
    "name": "Televic NV    ( Izegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 340322,
    "name": "TeleVox Software Inc    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 103909,
    "name": "Telex Communications Inc    ( Burnsville MN USA )",
    "country": "United States"
  },
  {
    "id": 461417,
    "name": "Telexy Healthcare    ( Maple Grove  Canada )",
    "country": "Canada"
  },
  {
    "id": 306409,
    "name": "Telic SA    ( Bigues (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 283098,
    "name": "Telos Arzt- und Krankenhausbedarf GmbH    ( Hungen/Obbornhofen  Germany )",
    "country": "Germany"
  },
  {
    "id": 227313,
    "name": "TELOS GmbH    ( Marburg/Lahn  Germany )",
    "country": "Germany"
  },
  {
    "id": 466231,
    "name": "Telrepco, Inc.    ( Wallingford 10 USA )",
    "country": "United States"
  },
  {
    "id": 108099,
    "name": "Telsar Laboratories Inc    ( Alton IL USA )",
    "country": "United States"
  },
  {
    "id": 465018,
    "name": "Telserv LLC    ( Cromwell CT USA )",
    "country": "United States"
  },
  {
    "id": 463073,
    "name": "Telstar Laboratory Equipment BV    ( Woerden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 105125,
    "name": "Teltron Technologies Inc    ( Birdsboro PA USA )",
    "country": "United States"
  },
  {
    "id": 457822,
    "name": "Tem International GmbH    ( Munich  Germany )",
    "country": "Germany"
  },
  {
    "id": 458437,
    "name": "TEMEC Instruments BV    ( Kerkade  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 285979,
    "name": "Temedia GmbH    ( Schoenbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 457393,
    "name": "TEMENA Group    ( Carrieres-sur-Seine  France )",
    "country": "France"
  },
  {
    "id": 282829,
    "name": "Tempex GmbH    ( Heidenheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 103910,
    "name": "Tempil Inc    ( South Plainfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 107492,
    "name": "Tempshield Inc    ( Mt Desert ME USA )",
    "country": "United States"
  },
  {
    "id": 458276,
    "name": "Tempsys    ( Emeryville CA USA )",
    "country": "United States"
  },
  {
    "id": 162105,
    "name": "Tempur Pedic Medical Division    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 999799,
    "name": "TemPure Scientific    (   USA )",
    "country": "United States"
  },
  {
    "id": 289289,
    "name": "Tempus Software Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 101375,
    "name": "Temrex Corp    ( Freeport NY USA )",
    "country": "United States"
  },
  {
    "id": 146804,
    "name": "Temtech Inc    ( Hurdle Mills NC USA )",
    "country": "United States"
  },
  {
    "id": 456191,
    "name": "Ten Comfort Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 461501,
    "name": "Tenable Network Security    ( Columbia MD USA )",
    "country": "United States"
  },
  {
    "id": 403607,
    "name": "Tenacore Holdings Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 457867,
    "name": "Tenacore Holdings Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 457596,
    "name": "Tender Corp    ( Littleton NH USA )",
    "country": "United States"
  },
  {
    "id": 452778,
    "name": "TENET Medical Engineering Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 456849,
    "name": "Tenex Health    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 999858,
    "name": "Tenma    ( Springboro OH USA )",
    "country": "United States"
  },
  {
    "id": 458473,
    "name": "Tennant Co    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 171311,
    "name": "Tenney Environmental    ( White Deer PA USA )",
    "country": "United States"
  },
  {
    "id": 431562,
    "name": "Tennsco Corp    ( Dickson TN USA )",
    "country": "United States"
  },
  {
    "id": 398063,
    "name": "TENOVIS GmbH & Co KG    ( Salzgitter  USA )",
    "country": "United States"
  },
  {
    "id": 407204,
    "name": "TENS Plus Industrial Co    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999515,
    "name": "Tens System    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 455098,
    "name": "TensioMed Kft    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 292700,
    "name": "TensorTech Corp    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 413004,
    "name": "Tensys Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 287284,
    "name": "Tente-Rollen GmbH & Co    ( Wermelskirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 399036,
    "name": "Tepa Inc    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 452987,
    "name": "Tepnel Molecular Diagnositcs    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 105988,
    "name": "Tepromark International Inc    ( Valley Stream NY USA )",
    "country": "United States"
  },
  {
    "id": 328182,
    "name": "Terahertz Technologies Inc    ( Oriskany NY USA )",
    "country": "United States"
  },
  {
    "id": 451550,
    "name": "Terang Nusa Sdn Bhd    ( Kota Bharu  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 371002,
    "name": "TeraRecon Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 371003,
    "name": "Terason    ( Burlington MA USA )",
    "country": "United States"
  },
  {
    "id": 465178,
    "name": "Terex Corporation    ( - - USA )",
    "country": "United States"
  },
  {
    "id": 177930,
    "name": "Termaks A/S    ( Bergen  Norway )",
    "country": "Norway"
  },
  {
    "id": 451414,
    "name": "Terra Universal Inc    ( Fullerton CA USA )",
    "country": "United States"
  },
  {
    "id": 172328,
    "name": "Terraflex    (   USA )",
    "country": "United States"
  },
  {
    "id": 452353,
    "name": "Terray Corp    ( Arnprior ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461378,
    "name": "Terry Miller Service Co    ( Columbus GA USA )",
    "country": "United States"
  },
  {
    "id": 461350,
    "name": "Terso Solutions Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 457402,
    "name": "Terumo BCT (Hong Kong) Ltd    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457399,
    "name": "Terumo BCT Europe NV    ( Garching  Germany )",
    "country": "Germany"
  },
  {
    "id": 457397,
    "name": "Terumo BCT Europe NV Belgium    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457400,
    "name": "Terumo BCT Europe NV Filiale Italiana    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 457401,
    "name": "Terumo BCT Europe NV Spain    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 457396,
    "name": "Terumo BCT Inc    ( Lakewood CO USA )",
    "country": "United States"
  },
  {
    "id": 457398,
    "name": "Terumo BCT Ltd    ( Larne  Northern Ireland )",
    "country": "Northern Ireland"
  },
  {
    "id": 371199,
    "name": "Terumo Cardiovascular Systems Corp    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 139721,
    "name": "Terumo Corp (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 307327,
    "name": "Terumo Corp (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 135223,
    "name": "Terumo Europe NV    ( Leuven  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457812,
    "name": "Terumo Europe NV    ( Eschborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 101790,
    "name": "Terumo Medical Corp    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 457403,
    "name": "Terumo Sweden AB    ( Vaestra Froelunda  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 999661,
    "name": "TES Electronic Corp.    (   USA )",
    "country": "United States"
  },
  {
    "id": 451990,
    "name": "Tescor Inc    ( Warminster PA USA )",
    "country": "United States"
  },
  {
    "id": 457238,
    "name": "Tesseract UK    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 457240,
    "name": "Tesseract USA    ( Reston VA USA )",
    "country": "United States"
  },
  {
    "id": 999969,
    "name": "Testrite Visual    (   USA )",
    "country": "United States"
  },
  {
    "id": 400977,
    "name": "Tetenal Espana SL    ( Manresa  Spain )",
    "country": "Spain"
  },
  {
    "id": 400975,
    "name": "Tetenal Ltd    ( Leicester  England )",
    "country": "UK"
  },
  {
    "id": 400978,
    "name": "Tetenal Polska Sp z o o    ( Katowice  Poland )",
    "country": "Poland"
  },
  {
    "id": 400976,
    "name": "Tetenal Sarl    ( Moneteau  France )",
    "country": "France"
  },
  {
    "id": 285025,
    "name": "Tetenal Vertriebsgesellschaft mbh    ( Norderstedt  Germany )",
    "country": "Germany"
  },
  {
    "id": 415971,
    "name": "Tetra Australia Pty Ltd    ( Mandalong  Australia )",
    "country": "Australia"
  },
  {
    "id": 162043,
    "name": "TETRA Medical Supply Corp    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 431618,
    "name": "Tetracore Inc    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 184682,
    "name": "Tetrad Corp    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 286561,
    "name": "Tetronik Kommunikationstechnik GmbH    ( Taunusstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 286867,
    "name": "Teufel & Gerlach Fabrik fuer Chirurgische Instrume    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282267,
    "name": "Teutotechnik Med Teutotechnik    ( Bad Iburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 358286,
    "name": "Teva Pharmaceutical Industries Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 467047,
    "name": "TEW Electric Heating Equipment Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999897,
    "name": "TEW Electric Heating Equipment CO., LTD    (   USA )",
    "country": "United States"
  },
  {
    "id": 281949,
    "name": "Tex-A-Med GmbH    ( Gefrees  Germany )",
    "country": "Germany"
  },
  {
    "id": 227453,
    "name": "Texas Computer Resources Inc    ( Hurst TX USA )",
    "country": "United States"
  },
  {
    "id": 105224,
    "name": "Texas Instruments Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 461230,
    "name": "Texas Medical Industries    ( Royse City TX USA )",
    "country": "United States"
  },
  {
    "id": 356812,
    "name": "Texas State Tech College    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 395033,
    "name": "TFI Healthcare    ( Petersburg VA USA )",
    "country": "United States"
  },
  {
    "id": 184489,
    "name": "TG Medical USA Inc    ( Azusa CA USA )",
    "country": "United States"
  },
  {
    "id": 457792,
    "name": "TGA Mobility    ( Sudbury  England )",
    "country": "UK"
  },
  {
    "id": 458509,
    "name": "TgK Scientific Ltd    ( Bradford on Avon  England )",
    "country": "UK"
  },
  {
    "id": 460525,
    "name": "TGM2    ( Clearwater FL USA )",
    "country": "United States"
  },
  {
    "id": 306121,
    "name": "TGS Electronics Pty Ltd    ( New Gisborne  Australia )",
    "country": "Australia"
  },
  {
    "id": 459380,
    "name": "TGX Medical Systems    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 286265,
    "name": "Th Viehmeyer KG    ( Spangenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 440837,
    "name": "Thaemert Orthopaedische Hilfsmittel GmbH & Co KG    ( Burguedel/Grossburgwedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 456337,
    "name": "Thai Centri (1995) Co Ltd    ( Samutsakorn  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 461403,
    "name": "The Gunlocke Company    ( Wayland NY USA )",
    "country": "United States"
  },
  {
    "id": 182651,
    "name": "Thai Fuji Latex Co Ltd    ( Phathumthanee  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 162720,
    "name": "Thai Kawasumi Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 416235,
    "name": "Thales Componentes SA    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 416233,
    "name": "Thales Componenti SpA    ( Roma  Italy )",
    "country": "Italy"
  },
  {
    "id": 416236,
    "name": "Thales Components AB    ( Stockholm  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 416237,
    "name": "Thales Components AG    ( Klingnau  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 416230,
    "name": "Thales Components Corp    ( Totowa NJ USA )",
    "country": "United States"
  },
  {
    "id": 416181,
    "name": "Thales Electron Devices (India)    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 416184,
    "name": "Thales Electron Devices (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 416179,
    "name": "Thales Electron Devices GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 398831,
    "name": "Thales Electron Devices SA    ( Velizy Cedex  France )",
    "country": "France"
  },
  {
    "id": 416231,
    "name": "Thales Electron Devices UK    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 416234,
    "name": "Thales International Japan    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 416182,
    "name": "Thales-Lamina Electro Devices (Poland)    ( Piaseczno/Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 161010,
    "name": "Thalheimer Kuehlung GmbH & Co    ( Ellwangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 440713,
    "name": "Thamert (UK) Ltd    ( Banbury  England )",
    "country": "UK"
  },
  {
    "id": 325623,
    "name": "Thamert USA Inc    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 186590,
    "name": "Thames Medical Ltd    ( Worthing  England )",
    "country": "UK"
  },
  {
    "id": 340324,
    "name": "Thatcher Pharmaceutical Co    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 371381,
    "name": "Thayer Medical Corp    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 453827,
    "name": "THD America Inc    ( Ankeny IA USA )",
    "country": "United States"
  },
  {
    "id": 453830,
    "name": "THD International    ( Correggio (RE)  Italy )",
    "country": "Italy"
  },
  {
    "id": 466918,
    "name": "The 3CPM Co    ( Towson MD USA )",
    "country": "United States"
  },
  {
    "id": 371759,
    "name": "THE ACME GROUP    (   USA )",
    "country": "United States"
  },
  {
    "id": 322783,
    "name": "The Adaptive Design Shop    ( Fairfax VA USA )",
    "country": "United States"
  },
  {
    "id": 104088,
    "name": "The Advanced Technology Corp    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 458905,
    "name": "The Advisory Board Co    ( Washington DC USA )",
    "country": "United States"
  },
  {
    "id": 317156,
    "name": "The Aftermarket Group    ( North Ridgeville OH USA )",
    "country": "United States"
  },
  {
    "id": 454020,
    "name": "The Alabama Eye Bank    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 461490,
    "name": "The ALARA Group    ( Feasterville PA USA )",
    "country": "United States"
  },
  {
    "id": 455404,
    "name": "The Alkalol Co    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 105283,
    "name": "The Allen Products Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 458278,
    "name": "The Ansar Group Inc    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 105236,
    "name": "The Anspach Effort Inc    ( Palm Beach Gardens FL USA )",
    "country": "United States"
  },
  {
    "id": 394818,
    "name": "The Argen Corp Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458959,
    "name": "The Armamentarium    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 355253,
    "name": "The Aztech Group    (   USA )",
    "country": "United States"
  },
  {
    "id": 452785,
    "name": "The Binding Site    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 392508,
    "name": "The Binding Site (France)    ( Saint-Egreve Cedex  France )",
    "country": "France"
  },
  {
    "id": 451306,
    "name": "TNCO Inc    ( Whitman MA USA )",
    "country": "United States"
  },
  {
    "id": 392507,
    "name": "The Binding Site GmbH    ( Schwetzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 155401,
    "name": "The Binding Site Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 182858,
    "name": "The Binding Site Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 107974,
    "name": "The Biomedical Engineering Center    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 464088,
    "name": "The Blankenship Center    ( Macon GA USA )",
    "country": "United States"
  },
  {
    "id": 451146,
    "name": "The Blood and Tissue Center of Central Texas    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 457451,
    "name": "The Brace Shop    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 232381,
    "name": "The Branford Labs    ( Summerville SC USA )",
    "country": "United States"
  },
  {
    "id": 105530,
    "name": "The Braun Corp    ( Winamac IN USA )",
    "country": "United States"
  },
  {
    "id": 451489,
    "name": "The Bremer Group Co    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 101956,
    "name": "The Brewer Co    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 104275,
    "name": "The Brinkmann Corp    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 106743,
    "name": "The Burrows Co    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 458496,
    "name": "The CBORD Group Inc    ( Ithaca NY USA )",
    "country": "United States"
  },
  {
    "id": 431488,
    "name": "The CENTECH GROUP Inc    ( Arlington VA USA )",
    "country": "United States"
  },
  {
    "id": 461487,
    "name": "The Challenge Machinery Company    ( Norton Shores MI USA )",
    "country": "United States"
  },
  {
    "id": 461405,
    "name": "The Chamberlain Group Inc    ( Elmhurst IL USA )",
    "country": "United States"
  },
  {
    "id": 457887,
    "name": "The Chudy Group LLC (TCGRX)    ( Powers Lake WI USA )",
    "country": "United States"
  },
  {
    "id": 103158,
    "name": "The Cin-Made Packaging Group    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 105311,
    "name": "The Citmed Corp    ( Citronelle AL USA )",
    "country": "United States"
  },
  {
    "id": 442174,
    "name": "The Contact Lens Store Inc    ( Danvers MA USA )",
    "country": "United States"
  },
  {
    "id": 171212,
    "name": "The Cooper Companies Inc    ( Pleasanton CA USA )",
    "country": "USA"
  },
  {
    "id": 322912,
    "name": "The Corridor Group Inc    ( Overland Park KS USA )",
    "country": "United States"
  },
  {
    "id": 458717,
    "name": "The Delfield Co    ( Mt Pleasant MI USA )",
    "country": "United States"
  },
  {
    "id": 461239,
    "name": "The Delta Group    ( London  England )",
    "country": "UK"
  },
  {
    "id": 104834,
    "name": "The Denominator Co Inc    ( Woodbury CT USA )",
    "country": "United States"
  },
  {
    "id": 452244,
    "name": "The Dental Box Co Inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 103035,
    "name": "The Drucker Co    ( Port Matilda PA USA )",
    "country": "United States"
  },
  {
    "id": 428427,
    "name": "The Durham Mfg Co    ( Durham CT USA )",
    "country": "United States"
  },
  {
    "id": 428428,
    "name": "The Durham Mfg Co    ( Durham CT USA )",
    "country": "United States"
  },
  {
    "id": 233638,
    "name": "The Echo Goup    ( Conway NH USA )",
    "country": "United States"
  },
  {
    "id": 104163,
    "name": "The Ednalite Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 324969,
    "name": "The Electrode Co Ltd    ( Usk  Wales )",
    "country": "Wales"
  },
  {
    "id": 104866,
    "name": "The Electrode Store    ( Enumclaw WA USA )",
    "country": "United States"
  },
  {
    "id": 999473,
    "name": "The Exact Weight Scale Co.    (   USA )",
    "country": "United States"
  },
  {
    "id": 453027,
    "name": "The Eye Bank of North Carolina    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 456783,
    "name": "The Eye-Bank For Sight Restoration Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 451321,
    "name": "The First Years    ( Stoughton MA USA )",
    "country": "United States"
  },
  {
    "id": 103762,
    "name": "The Flinchbaugh Co Inc    ( York PA USA )",
    "country": "United States"
  },
  {
    "id": 331252,
    "name": "The Force Medical Imaging Co Inc    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 101121,
    "name": "The Foredom Electric Co    ( Bethel CT USA )",
    "country": "United States"
  },
  {
    "id": 174795,
    "name": "The Fresnel Prism & Lens Co LLC    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 455139,
    "name": "The Furniss Corp Ltd    ( Grove City OH USA )",
    "country": "United States"
  },
  {
    "id": 106042,
    "name": "The Gollman Group Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 394819,
    "name": "The Grieve Corp    ( Round Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 102538,
    "name": "The Harloff Co Inc    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 277945,
    "name": "The Hatakeyama Dental Mfg Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 455051,
    "name": "The Helping Hand Co    ( Ledbury  England )",
    "country": "UK"
  },
  {
    "id": 431565,
    "name": "The HON Co    ( Muscatine IA USA )",
    "country": "United States"
  },
  {
    "id": 458152,
    "name": "The HookLady    ( Lindenwold NJ USA )",
    "country": "United States"
  },
  {
    "id": 101334,
    "name": "The Hygenic Corp    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 291886,
    "name": "The Hymed Group    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 999390,
    "name": "THE INDUSTRY SOURCE    ( New Hudson MI USA )",
    "country": "United States"
  },
  {
    "id": 357558,
    "name": "The Institute for Radiological Image Sciences Inc    ( Myersville MD USA )",
    "country": "United States"
  },
  {
    "id": 999523,
    "name": "The InterScholastic Trading Company, LLC    ( San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 454975,
    "name": "The Jacobs Mfg Co    ( Dundee  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 466720,
    "name": "The JJ Elemer Corp    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 101979,
    "name": "The Kendall Co    ( Mansfield MA USA )",
    "country": "United States"
  },
  {
    "id": 173965,
    "name": "The King Domain Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451003,
    "name": "The Lab Depot Inc    ( Dawsonville GA USA )",
    "country": "United States"
  },
  {
    "id": 457410,
    "name": "The Learning Clinic    ( London  England )",
    "country": "UK"
  },
  {
    "id": 370419,
    "name": "The LifeStyle Co Inc    ( Wall Township NJ USA )",
    "country": "United States"
  },
  {
    "id": 399264,
    "name": "The LiftCare Bed Co    ( Frankston  Australia )",
    "country": "Australia"
  },
  {
    "id": 101753,
    "name": "The Lumiscope Co Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 331404,
    "name": "The Machinery Works    ( Brookline MA USA )",
    "country": "United States"
  },
  {
    "id": 461168,
    "name": "The Macro Group Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 174621,
    "name": "The Magstim Co Ltd    ( Whitland  Wales )",
    "country": "Wales"
  },
  {
    "id": 461398,
    "name": "The Mark-Costello Co    ( Paramount CA USA )",
    "country": "United States"
  },
  {
    "id": 183757,
    "name": "The Medi-Kid Co    ( Hemet CA USA )",
    "country": "United States"
  },
  {
    "id": 288611,
    "name": "The Medibase Group Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 174744,
    "name": "THE Medical    ( Barrie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456204,
    "name": "THE Medical    ( Owings Mills MD USA )",
    "country": "United States"
  },
  {
    "id": 151054,
    "name": "The Medical Export Group bv    ( Gorinchem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454067,
    "name": "The Medipattern Corp    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459437,
    "name": "The Menon Group Inc    ( Bellevue WA USA )",
    "country": "United States"
  },
  {
    "id": 451674,
    "name": "The Mentholatum Co Inc    ( Orchard Park NY USA )",
    "country": "United States"
  },
  {
    "id": 175969,
    "name": "The Metrix Co    ( Dubuque IA USA )",
    "country": "United States"
  },
  {
    "id": 999603,
    "name": "The Microscope Store, L.L.C.    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 431617,
    "name": "The Mistral Group    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 322816,
    "name": "The Mobility Aids Centre    ( Peterborough  England )",
    "country": "UK"
  },
  {
    "id": 466623,
    "name": "The Morel Co    ( Batesville IN USA )",
    "country": "United States"
  },
  {
    "id": 107420,
    "name": "The National Wheel-O-Vator Co Inc    ( Roanoke IL USA )",
    "country": "United States"
  },
  {
    "id": 457876,
    "name": "The North Carolina Eye Bank    ( Winston-Salem NC USA )",
    "country": "United States"
  },
  {
    "id": 337087,
    "name": "The Oak Group    ( Wayland MA USA )",
    "country": "United States"
  },
  {
    "id": 457999,
    "name": "The Original Bean Blanket Co    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 431345,
    "name": "The Peddle Master    ( Johnstown CO USA )",
    "country": "United States"
  },
  {
    "id": 101592,
    "name": "The Pelton & Crane Co    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 466255,
    "name": "The Phantom Laboratory    ( Salem NY USA )",
    "country": "United States"
  },
  {
    "id": 283124,
    "name": "TNI Nadel GmbH    ( Ichtershausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 183873,
    "name": "The Phantom Laboratory Inc    ( Salem NY USA )",
    "country": "United States"
  },
  {
    "id": 356893,
    "name": "The Physician's Computer Co    ( Winooski VT USA )",
    "country": "United States"
  },
  {
    "id": 108806,
    "name": "The Pillow Place    ( Smyrna GA USA )",
    "country": "United States"
  },
  {
    "id": 999927,
    "name": "The Precision Scientific Company    (   USA )",
    "country": "United States"
  },
  {
    "id": 233893,
    "name": "The Program Workshop Inc    ( St Augustine FL USA )",
    "country": "United States"
  },
  {
    "id": 175971,
    "name": "The Prometheus Group    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 999887,
    "name": "The Pure Water Clean Air Group    ( Bermuda Dunes CA USA )",
    "country": "United States"
  },
  {
    "id": 106613,
    "name": "The Radix Corp    ( Pembina ND USA )",
    "country": "United States"
  },
  {
    "id": 409905,
    "name": "The Ralston/CanSafe Group    ( Fergus ON Canada )",
    "country": "Canada"
  },
  {
    "id": 441514,
    "name": "The Respiratory Group    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 104682,
    "name": "The Ridge Tool Co    ( Elyria OH USA )",
    "country": "United States"
  },
  {
    "id": 104684,
    "name": "The ROHO Group    ( Belleville IL USA )",
    "country": "United States"
  },
  {
    "id": 363218,
    "name": "The Ryzex Group    ( Bellingham WA USA )",
    "country": "United States"
  },
  {
    "id": 453461,
    "name": "The Sage Group Inc    ( Branchburg NJ USA )",
    "country": "United States"
  },
  {
    "id": 104686,
    "name": "The Salk Co Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 325596,
    "name": "The Saunders Group Inc    ( Chaska MN USA )",
    "country": "United States"
  },
  {
    "id": 999751,
    "name": "The Scope Exchange Inc.    ( Greensboro NC USA )",
    "country": "USA"
  },
  {
    "id": 146736,
    "name": "The Second Source    ( Claremont CA USA )",
    "country": "United States"
  },
  {
    "id": 263086,
    "name": "The Shams Group Inc    ( Coppell TX USA )",
    "country": "United States"
  },
  {
    "id": 450637,
    "name": "The Sharpening Co    ( South Bend IN USA )",
    "country": "United States"
  },
  {
    "id": 452382,
    "name": "The Sisus Corp    ( Santa Cruz CA USA )",
    "country": "United States"
  },
  {
    "id": 439954,
    "name": "The SmartPill Corp    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 175958,
    "name": "The Smithworks Co    ( Clarkston WA USA )",
    "country": "United States"
  },
  {
    "id": 332251,
    "name": "The Snyder Group Inc    ( Longwood FL USA )",
    "country": "United States"
  },
  {
    "id": 460523,
    "name": "The Society of Thoracic Surgeons    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 135441,
    "name": "The Specialty Bulb Co Inc    ( Bohemia NY USA )",
    "country": "United States"
  },
  {
    "id": 462786,
    "name": "The Speech Bin    ( Vero Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 332270,
    "name": "The SSI Group Inc    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 453849,
    "name": "The SSI Group Inc    ( Mobile AL USA )",
    "country": "United States"
  },
  {
    "id": 105588,
    "name": "The St John Co    ( Santa Clarita CA USA )",
    "country": "United States"
  },
  {
    "id": 462973,
    "name": "The Standing Co Inc    ( Saginaw MI USA )",
    "country": "United States"
  },
  {
    "id": 174648,
    "name": "The Stevens Co Ltd    ( Brampton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 294452,
    "name": "The Strain Gauging Co Ltd    ( Four Marks  England )",
    "country": "UK"
  },
  {
    "id": 329790,
    "name": "The SunBox Co    ( Gaithersburg MD USA )",
    "country": "United States"
  },
  {
    "id": 452663,
    "name": "The Surgical Co bv    ( Amersfoort  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453752,
    "name": "The Surgical Co International (TSCI) bv    ( Amersfoort  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455348,
    "name": "The Surgical Equipment People    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 152817,
    "name": "The Synaptic Corp    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 461476,
    "name": "The Systems House Inc    ( Clifton NJ USA )",
    "country": "United States"
  },
  {
    "id": 409528,
    "name": "The TriZetto Group Inc    ( Greenwood Village CO USA )",
    "country": "United States"
  },
  {
    "id": 460524,
    "name": "The United States Pharmocopeia Convention    ( Rockville MD USA )",
    "country": "United States"
  },
  {
    "id": 455449,
    "name": "The Utopia Group LLC    ( Loveland OH USA )",
    "country": "United States"
  },
  {
    "id": 174760,
    "name": "The Vernacare Co    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 416238,
    "name": "The Vibratome Co    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 451600,
    "name": "The Visiting Nurse Equipment and Supply    ( Massillon OH USA )",
    "country": "United States"
  },
  {
    "id": 457622,
    "name": "The Visual-Tech Connection    ( Westerville OH USA )",
    "country": "United States"
  },
  {
    "id": 999607,
    "name": "The Vollrath Co., L.L.C.    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 459441,
    "name": "The Wellness Network    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 461478,
    "name": "The Wheelchair Man Company Inc    ( Clementon NJ USA )",
    "country": "United States"
  },
  {
    "id": 460589,
    "name": "The White Stone Group Inc    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 461500,
    "name": "The Work Institute LLC    ( Brentwood TN USA )",
    "country": "United States"
  },
  {
    "id": 456999,
    "name": "The Wright Stuff Inc    ( Crystal Springs MS USA )",
    "country": "United States"
  },
  {
    "id": 106214,
    "name": "The X-Ray Sales & Service Co    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 139274,
    "name": "The Yoshida Dental Mfg Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451199,
    "name": "Theken Spine LLC    ( Akron OH USA )",
    "country": "United States"
  },
  {
    "id": 435425,
    "name": "Thematics    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 285638,
    "name": "Theodor Kohl KG    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 175248,
    "name": "Thera-Med Inc    ( Waco TX USA )",
    "country": "United States"
  },
  {
    "id": 108337,
    "name": "Thera-P-Cushion Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 334742,
    "name": "TherAdapt Products Inc    ( Ludington MI USA )",
    "country": "United States"
  },
  {
    "id": 452393,
    "name": "TheraDoc Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 101792,
    "name": "Theradyne Healthcare Div Kurt Mfg    (   USA )",
    "country": "United States"
  },
  {
    "id": 107584,
    "name": "Therafin Corp    ( Mokena IL USA )",
    "country": "United States"
  },
  {
    "id": 403319,
    "name": "Therafirm Ltd    ( Kansas City KS USA )",
    "country": "United States"
  },
  {
    "id": 106886,
    "name": "Theragenics Corp    ( Buford GA USA )",
    "country": "United States"
  },
  {
    "id": 105838,
    "name": "Therakos Inc    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 391159,
    "name": "Theranostic Medizintechnik GmbH    ( Solingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 362391,
    "name": "Therapeutic Alliances Inc    ( Fairborn OH USA )",
    "country": "United States"
  },
  {
    "id": 461516,
    "name": "Therapeutic Industries    ( Palm Desert CA USA )",
    "country": "United States"
  },
  {
    "id": 999444,
    "name": "Therapeutic Industries    ( Palm Desert CA USA )",
    "country": "United States"
  },
  {
    "id": 453072,
    "name": "Therapeutic Silicone Technologies Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 105919,
    "name": "Therapeutic Systems Inc    ( Doylestown PA USA )",
    "country": "United States"
  },
  {
    "id": 450264,
    "name": "TherapieTechnik Rostock GmbH    ( Tostock Warnemuende  Germany )",
    "country": "Germany"
  },
  {
    "id": 454967,
    "name": "Theraplay Ltd    ( Glasgow  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 453494,
    "name": "Theraposture Ltd    ( Westbury  England )",
    "country": "UK"
  },
  {
    "id": 324385,
    "name": "Therapy Equipment Ltd    ( Potters Bar  England )",
    "country": "UK"
  },
  {
    "id": 450770,
    "name": "Therapy Remarketing Group    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 458000,
    "name": "Therapy Shoppe Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 462875,
    "name": "TherapyCare Resources Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 461481,
    "name": "TheraSuit LLC    ( Keego Harbor MI USA )",
    "country": "United States"
  },
  {
    "id": 155097,
    "name": "TheraTest Labs    ( Lombard IL USA )",
    "country": "United States"
  },
  {
    "id": 426432,
    "name": "Thermage Inc    ( Hayward CA USA )",
    "country": "United States"
  },
  {
    "id": 455308,
    "name": "Thermal Product Solutions A SPX Corp Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 105990,
    "name": "Thermascan Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 455878,
    "name": "ThermaSolutions    ( White Bear Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 999535,
    "name": "Thermco Products Inc    ( Lafayette NJ USA )",
    "country": "USA"
  },
  {
    "id": 459320,
    "name": "Thermedx LLC    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 454517,
    "name": "Trig Medical Inc    ( Ridgefield NJ USA )",
    "country": "United States"
  },
  {
    "id": 461492,
    "name": "Thermmax Scientific Products    ( Colmar PA USA )",
    "country": "United States"
  },
  {
    "id": 397705,
    "name": "Thermo ARL    ( Ecublens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 391821,
    "name": "Thermo Elemental (UK)    ( Winsford  USA )",
    "country": "United States"
  },
  {
    "id": 398893,
    "name": "Thermo Finnigan China    ( Beijing  USA )",
    "country": "United States"
  },
  {
    "id": 392136,
    "name": "Thermo Finnigan GmbH (Germany)    ( Engelbach CA USA )",
    "country": "United States"
  },
  {
    "id": 451927,
    "name": "Thermo Fisher Diagnostics    ( Middletown VA USA )",
    "country": "United States"
  },
  {
    "id": 451981,
    "name": "Thermo Fisher Scientific    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 453042,
    "name": "Thermo Fisher Scientific    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 455453,
    "name": "Thermo Fisher Scientific    ( Tewksbury MA USA )",
    "country": "United States"
  },
  {
    "id": 451980,
    "name": "Thermo Fisher Scientific (Finland)    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 456874,
    "name": "Thermo Fisher Scientific Australia Pty Ltd    ( Scoresby  Australia )",
    "country": "Australia"
  },
  {
    "id": 451902,
    "name": "Thermo Fisher Scientific Inc    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 452271,
    "name": "Thermo Fisher Scientific Inc    ( Hudson NH USA )",
    "country": "United States"
  },
  {
    "id": 454209,
    "name": "Thermo Fisher Scientific Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 456857,
    "name": "Thermo Fisher Scientific Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 454056,
    "name": "Thermo Fisher Scientific Inc - Clinical Diagnostic    ( East Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 456914,
    "name": "Thermo Fisher Scientific India Pvt Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 451895,
    "name": "THERMO FISHER SCIENTIFIC-LIPSHAW    (   USA )",
    "country": "United States"
  },
  {
    "id": 457026,
    "name": "Thermo Fisher Softron GmbH    ( Germering  Germany )",
    "country": "Germany"
  },
  {
    "id": 457028,
    "name": "Thermo Fisher UK Ltd    ( Hemel Hempstead  England )",
    "country": "UK"
  },
  {
    "id": 392951,
    "name": "Thermo Haake GmbH    ( Karlsruhe  Germany )",
    "country": "Germany"
  },
  {
    "id": 454401,
    "name": "Thermo Hamilton Laboratory    ( Two Rivers WI USA )",
    "country": "United States"
  },
  {
    "id": 392138,
    "name": "Thermo IEC    (   USA )",
    "country": "United States"
  },
  {
    "id": 398891,
    "name": "Thermo LabSystems China    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392050,
    "name": "Thermo LabSystems GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 397706,
    "name": "Thermo LabSystems UK    ( Altrincham  England )",
    "country": "UK"
  },
  {
    "id": 456913,
    "name": "Thermo Life Sciences Ltd    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 391389,
    "name": "Thermo Optek bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 371194,
    "name": "Thermo Respiratory Group    ( Bilthoven  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451918,
    "name": "Thermo Scientific (Austria)    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 451897,
    "name": "Thermo Scientific Air Quality    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 455548,
    "name": "Thermo Scientific Air Quality Monitoring Systems    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 454696,
    "name": "Thermo Scientific bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 451911,
    "name": "Thermo Scientific Chromatography and Mass Spectrom    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 451904,
    "name": "Thermo Scientific Clinical Chemistry    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 451905,
    "name": "Thermo Scientific Clinical Chemistry (Australia)    ( Noble Park  Australia )",
    "country": "Australia"
  },
  {
    "id": 451901,
    "name": "Thermo Scientific Clinical Consumables    ( Beverly MA USA )",
    "country": "United States"
  },
  {
    "id": 451907,
    "name": "Thermo Scientific Controlled Environment    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 451908,
    "name": "Thermo Scientific Controlled Technologies    ( Marietta OH USA )",
    "country": "United States"
  },
  {
    "id": 451917,
    "name": "Thermo Scientific Deutschland    ( Langenselbold  Germany )",
    "country": "Germany"
  },
  {
    "id": 451910,
    "name": "Thermo Scientific Elemental Analysis    ( Dearborn MI USA )",
    "country": "United States"
  },
  {
    "id": 451909,
    "name": "Thermo Scientific Industrial Hygiene    ( Franklin MA USA )",
    "country": "United States"
  },
  {
    "id": 451919,
    "name": "Thermo Scientific Informatics    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 451922,
    "name": "Thermo Scientific Laboratory Automation and Integr    ( Burlington ON Canada )",
    "country": "Canada"
  },
  {
    "id": 451916,
    "name": "Thermo Scientific Laboratory Products    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 451913,
    "name": "Thermo Scientific Laboratory Sample Preparation    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 451915,
    "name": "Thermo Scientific Life & Lab Sciences/Bioscience T    ( Basingstoke  England )",
    "country": "UK"
  },
  {
    "id": 451914,
    "name": "Thermo Scientific Material Characterization    ( Tewksbury MA USA )",
    "country": "United States"
  },
  {
    "id": 451906,
    "name": "Thermo Scientific Molecular Biology    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 451912,
    "name": "Thermo Scientific Molecular Spectroscopy    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 454083,
    "name": "Thermo Scientific Owl Separation Systems    ( Asheville NC USA )",
    "country": "United States"
  },
  {
    "id": 457215,
    "name": "Thermo Scientific Pathology Equipment and Supplies    ( Kalamazoo MI USA )",
    "country": "United States"
  },
  {
    "id": 451898,
    "name": "Thermo Scientific Radiation Measurement & Protecti    ( Oakwood Village OH USA )",
    "country": "United States"
  },
  {
    "id": 451899,
    "name": "Thermo Scientific Radiation Measurement & Protecti    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 451900,
    "name": "Thermo Scientific Radiation Measurement & Protecti    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457213,
    "name": "Thermo Scientific Specialty Products    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 451903,
    "name": "Thermo Scientific Water Analysis    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 391863,
    "name": "Thermo Spectronic (UK)    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 105416,
    "name": "Thermo-Electric Co    ( Imperial PA USA )",
    "country": "United States"
  },
  {
    "id": 105127,
    "name": "Thermo-Genesis International Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 458892,
    "name": "Thermo-Kool    ( Laurel MS USA )",
    "country": "United States"
  },
  {
    "id": 455193,
    "name": "ThermoElectric Cooling America Corp    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 199551,
    "name": "ThermoGenesis Corp    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 457902,
    "name": "Thermomedic Thermal Therapy Garments    ( Wahroonga  Australia )",
    "country": "Australia"
  },
  {
    "id": 463000,
    "name": "Thermomedics Inc    ( Miami FL USA )",
    "country": "USA"
  },
  {
    "id": 103572,
    "name": "Thermometrics    ( Edison NJ USA )",
    "country": "United States"
  },
  {
    "id": 459517,
    "name": "Thermopatch Corp    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 102671,
    "name": "Thermoplastic Processes Inc    ( Stirling NJ USA )",
    "country": "United States"
  },
  {
    "id": 399198,
    "name": "Thermopol Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 441255,
    "name": "ThermoSafe Brands    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 108424,
    "name": "THERMOSCAN    (   USA )",
    "country": "United States"
  },
  {
    "id": 347442,
    "name": "Thermotech    (   USA )",
    "country": "United States"
  },
  {
    "id": 300773,
    "name": "Thermotek Inc    ( Flower Mound TX USA )",
    "country": "United States"
  },
  {
    "id": 459169,
    "name": "Thermotron    ( Holland MI USA )",
    "country": "United States"
  },
  {
    "id": 105470,
    "name": "Thermovac    (   USA )",
    "country": "United States"
  },
  {
    "id": 461080,
    "name": "Theronyx    ( Thousand Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 276660,
    "name": "THG-Glock Instruments    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 464085,
    "name": "THI Total Healthcare Innovation GmbH    ( Feistritz im Rosental  Austria )",
    "country": "Austria"
  },
  {
    "id": 459490,
    "name": "Thierry Corp    ( Royal Oak MI USA )",
    "country": "United States"
  },
  {
    "id": 450695,
    "name": "Thinking Systems Corp    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 461348,
    "name": "Thinkmate    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 451431,
    "name": "Third Wave Technologies    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 459510,
    "name": "Thomas & Betts Corp    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 284779,
    "name": "Thomas A Teichmann GmbH & Co KG    ( Nastaetten  Germany )",
    "country": "Germany"
  },
  {
    "id": 460678,
    "name": "Thomas Cuisine Management    ( Meridian IN USA )",
    "country": "United States"
  },
  {
    "id": 461524,
    "name": "Thomas EMS    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 108012,
    "name": "Thomas Fetterman Inc    ( Southampton PA USA )",
    "country": "United States"
  },
  {
    "id": 353237,
    "name": "Thomas Medical Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 363863,
    "name": "Thomas Medical Products Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 456791,
    "name": "Thomas Medical Products Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 376552,
    "name": "Thomas Products    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 465185,
    "name": "Thomas RECORDING GmbH    ( Giessen Germany Wi USA )",
    "country": "United States"
  },
  {
    "id": 102672,
    "name": "Thomas Scientific    ( Swedesboro NJ USA )",
    "country": "United States"
  },
  {
    "id": 392045,
    "name": "Thomas Systeme GmbH    ( Vlotho  Germany )",
    "country": "Germany"
  },
  {
    "id": 160976,
    "name": "Thome Medizinelektronik GmbH    ( Linsengericht  Germany )",
    "country": "Germany"
  },
  {
    "id": 452333,
    "name": "Thommen Medical AG    ( Waldenburg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 108338,
    "name": "Thompson Medical Inc    ( St Louis Park MN USA )",
    "country": "United States"
  },
  {
    "id": 101798,
    "name": "Thompson Surgical Instruments Inc    ( Traverse City MI USA )",
    "country": "United States"
  },
  {
    "id": 183932,
    "name": "Thomson & Nielsen Electronics    ( Nepean ON USA )",
    "country": "United States"
  },
  {
    "id": 454437,
    "name": "Thomson Canopus Co Ltd    ( Kobe  Japan )",
    "country": "Japan"
  },
  {
    "id": 394474,
    "name": "Thomson Inc    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 441263,
    "name": "Thomson MicroMedex    ( Greenwood Village CO USA )",
    "country": "United States"
  },
  {
    "id": 453124,
    "name": "Thomson-Reuters    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 323846,
    "name": "THOR International Ltd    ( Chesham  England )",
    "country": "UK"
  },
  {
    "id": 465224,
    "name": "Thor Medical    ( Franklin 40 USA )",
    "country": "United States"
  },
  {
    "id": 185611,
    "name": "THOR-LO Inc    ( Statesville NC USA )",
    "country": "United States"
  },
  {
    "id": 459285,
    "name": "Thoramet Surgical Products Inc    ( Rutherford NJ USA )",
    "country": "United States"
  },
  {
    "id": 456749,
    "name": "THORASYS Thoracic Medical Systems Inc    ( Halifax NS Canada )",
    "country": "Canada"
  },
  {
    "id": 391897,
    "name": "Thoratec Corp    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 346091,
    "name": "Thoratec Europe Ltd    ( Huntingdon  England )",
    "country": "UK"
  },
  {
    "id": 458435,
    "name": "Thorlabs Inc    ( Newton NJ USA )",
    "country": "United States"
  },
  {
    "id": 999687,
    "name": "Thorley Industries    ( Pittsburgh PA USA )",
    "country": "USA"
  },
  {
    "id": 279544,
    "name": "Thorn Licht GmbH    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 471700,
    "name": "Thorn Smith Laboratories    ( Beulah MI USA )",
    "country": "United States"
  },
  {
    "id": 184882,
    "name": "Thornberry Ltd    ( Lancaster PA USA )",
    "country": "United States"
  },
  {
    "id": 324261,
    "name": "Thorne Electrim    ( Stockbridge  USA )",
    "country": "United States"
  },
  {
    "id": 467034,
    "name": "Thornhill Research Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457801,
    "name": "Thornton & Ross Ltd    ( Huddersfield  England )",
    "country": "UK"
  },
  {
    "id": 183967,
    "name": "Thortex Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 103013,
    "name": "Thought Technology Ltd (Canada)    ( Montreal West PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 335447,
    "name": "Thought Technology Ltd (US)    ( West Chazy NY USA )",
    "country": "United States"
  },
  {
    "id": 452563,
    "name": "Thousands Impex    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 107493,
    "name": "Three Rivers Optical Co    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 282201,
    "name": "THS-Rollstuehle    ( Groembach  Germany )",
    "country": "Germany"
  },
  {
    "id": 418646,
    "name": "Thuasne (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 416170,
    "name": "Thuasne Belgilux    ( Zaventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 416171,
    "name": "Thuasne CR sro    ( Praha 5  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 364516,
    "name": "Thuasne Deutschland GmbH & Co KG    ( Burgwedel  Germany )",
    "country": "Germany"
  },
  {
    "id": 416168,
    "name": "Thuasne Espana SL    ( Madrid  Spain )",
    "country": "Spain"
  },
  {
    "id": 305112,
    "name": "Thuasne France    ( Levallois-Perret Cedex  France )",
    "country": "France"
  },
  {
    "id": 416169,
    "name": "Thuasne Italia    ( Desio (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 416131,
    "name": "Thuasne North America    ( Bainbridge Island WA USA )",
    "country": "United States"
  },
  {
    "id": 454386,
    "name": "Thuasne North America    ( Bainbridge Island WA USA )",
    "country": "United States"
  },
  {
    "id": 416167,
    "name": "Thuasne Vihome Nederland    ( Nijkerk  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 284875,
    "name": "Thueringer Pharmaglas GmbH    ( Neuhaus am Rennweg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999806,
    "name": "Thumper Massager Inc.    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 362009,
    "name": "Thune Products A/S    ( Hamar  Norway )",
    "country": "Norway"
  },
  {
    "id": 102786,
    "name": "Thymer Industries    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 452048,
    "name": "ThyroTEc LLC    ( Honey Brook PA USA )",
    "country": "United States"
  },
  {
    "id": 284886,
    "name": "Thyssen Aufzuege GmbH    ( Neuhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 450985,
    "name": "ThyssenKrupp Access    ( Grandview MO USA )",
    "country": "United States"
  },
  {
    "id": 373440,
    "name": "ThyssenKrupp Elevator    ( Frisco TX USA )",
    "country": "United States"
  },
  {
    "id": 371005,
    "name": "TI-BA Enterprises Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 455971,
    "name": "Tian Ao Medical Technology Co Ltd    ( Nanjing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456683,
    "name": "Tianjin Hanaco Medical Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291743,
    "name": "Tianjin Hospital Equipment Works    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456693,
    "name": "Tianjin Jing Gong Medical Equipment & Technology C    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456616,
    "name": "Tianjin JingMing New Technological Development Co    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456694,
    "name": "Tianjin MD Pacific Technology Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456695,
    "name": "Tianjin Shi Sanji Kegongmao Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456698,
    "name": "Tianjin Welcome Medical Equipment Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456504,
    "name": "Tianrong Medical & Communication Equipment Co Ltd    (   People's Republic of China )",
    "country": "China"
  },
  {
    "id": 377715,
    "name": "Tiara Medical Systems Inc    ( Lakewood OH USA )",
    "country": "United States"
  },
  {
    "id": 457447,
    "name": "TIB MOLBIOL GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 459516,
    "name": "Tiba Medical Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 462568,
    "name": "TIBCO Software Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 398060,
    "name": "Tibset    ( Istanbul  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 461065,
    "name": "Ticomix Inc    ( Rockford IL USA )",
    "country": "United States"
  },
  {
    "id": 226276,
    "name": "TIDI Products LLC    ( Neenah WI USA )",
    "country": "United States"
  },
  {
    "id": 455309,
    "name": "TIF A SPX Corp Co    ( Owatonna MN USA )",
    "country": "USA"
  },
  {
    "id": 364573,
    "name": "Tiger Medical Instruments Co Ltd    ( Yao  Japan )",
    "country": "Japan"
  },
  {
    "id": 467132,
    "name": "Tikom Tsg    ( Camillus NY USA )",
    "country": "United States"
  },
  {
    "id": 456474,
    "name": "TiLite    ( Pasco WA USA )",
    "country": "United States"
  },
  {
    "id": 163340,
    "name": "Tillotson Healthcare Corp    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 461464,
    "name": "Timeless Medical Systems    ( Charlottetown PE Canada )",
    "country": "Canada"
  },
  {
    "id": 106551,
    "name": "TimeMed Labeling Systems Inc    ( Burr Ridge IL USA )",
    "country": "United States"
  },
  {
    "id": 323844,
    "name": "Timesco of London    ( London  England )",
    "country": "UK"
  },
  {
    "id": 452418,
    "name": "TimeTrak Systems Inc    ( Port Huron MI USA )",
    "country": "United States"
  },
  {
    "id": 471654,
    "name": "TIMS Medical    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 340328,
    "name": "Tingle X-ray Products Inc    ( Cottondale AL USA )",
    "country": "United States"
  },
  {
    "id": 175250,
    "name": "Tingue Brown & Co    ( Peachtree City GA USA )",
    "country": "United States"
  },
  {
    "id": 324691,
    "name": "Tinsley Precision Instruments    ( Red Hill  England )",
    "country": "UK"
  },
  {
    "id": 453767,
    "name": "Tinsonax NSW Pty Ltd    ( Revesby  Australia )",
    "country": "Australia"
  },
  {
    "id": 187448,
    "name": "Tipal Instruments Ltd    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 356162,
    "name": "TISCOR    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 377916,
    "name": "Tissue Bank International    ( San Rafael CA USA )",
    "country": "United States"
  },
  {
    "id": 226194,
    "name": "Tissue Banks International    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 457600,
    "name": "Tissue Regenix Ltd    ( York  England )",
    "country": "UK"
  },
  {
    "id": 384284,
    "name": "Tissue Science Laboratories plc    ( Aldershot  USA )",
    "country": "United States"
  },
  {
    "id": 458791,
    "name": "TissueGnostics GmbH    ( Vienna  Austria )",
    "country": "Austria"
  },
  {
    "id": 442335,
    "name": "TissueLink Europe srl    ( Asti  USA )",
    "country": "United States"
  },
  {
    "id": 442334,
    "name": "TissueLink Medical Inc    ( Dover NH USA )",
    "country": "United States"
  },
  {
    "id": 451677,
    "name": "TissueNet    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 458689,
    "name": "Titamed bvba    ( Kontich  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 457559,
    "name": "Titan Spine    ( Mequon WI USA )",
    "country": "United States"
  },
  {
    "id": 457560,
    "name": "Titan Spine Europe GmbH    ( Laichingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 357394,
    "name": "Titertek Instruments Inc    ( Huntsville AL USA )",
    "country": "United States"
  },
  {
    "id": 104345,
    "name": "Titmus Optical Inc    ( Chester VA USA )",
    "country": "United States"
  },
  {
    "id": 461462,
    "name": "TKO Sports Group USA Ltd    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 356849,
    "name": "TLC Medical Equipment Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 452638,
    "name": "TLK Industries Inc    ( Dundee IL USA )",
    "country": "United States"
  },
  {
    "id": 332337,
    "name": "TMA Systems LLC    ( Tulsa OK USA )",
    "country": "United States"
  },
  {
    "id": 346086,
    "name": "TMED Inc    ( Columbia TN USA )",
    "country": "United States"
  },
  {
    "id": 171723,
    "name": "TMI (Timely Medical Innovations Ltd)    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 158016,
    "name": "TMI Co Ltd    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 358415,
    "name": "TMJ Concepts    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 173149,
    "name": "TMJ Implants Inc    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 452917,
    "name": "TMP Tueshaus Medical Products GmbH    ( Velen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455828,
    "name": "TMS Medical Supply Ltd    ( South Natanya  Israel )",
    "country": "Israel"
  },
  {
    "id": 452339,
    "name": "TMX Engineering & Mfg Inc    ( Santa Ana CA USA )",
    "country": "United States"
  },
  {
    "id": 465054,
    "name": "TNAassociates Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 330245,
    "name": "TNC Everlight Phils Inc    ( Manila  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 109545,
    "name": "TOA Electronics Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 458607,
    "name": "TOA Electronics Inc    ( Burlingame CA USA )",
    "country": "United States"
  },
  {
    "id": 278687,
    "name": "TOBI Corp    ( Abiko  Japan )",
    "country": "Japan"
  },
  {
    "id": 104390,
    "name": "Tobias Assoc Inc    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 456145,
    "name": "Tobii Technology AB    ( Danderyd  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 177709,
    "name": "Todd Research Ltd    ( Papworth Evarard  England )",
    "country": "UK"
  },
  {
    "id": 451528,
    "name": "TOESCO -Toei Engineering Co Ltd    ( Kawasaki-shi  Japan )",
    "country": "Japan"
  },
  {
    "id": 184521,
    "name": "Toho Kagaku Kenkyusho Co Ltd    ( Toyko  USA )",
    "country": "United States"
  },
  {
    "id": 139325,
    "name": "Toitu Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 182607,
    "name": "Tokai Rika Co Ltd    ( Niwa-gun  Japan )",
    "country": "Japan"
  },
  {
    "id": 455996,
    "name": "Tokyo Boeki Machinery Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455649,
    "name": "Tokyo Boeki Medical System Ltd    ( Akiruno  Japan )",
    "country": "Japan"
  },
  {
    "id": 174143,
    "name": "Tokyo Emix Corp    ( Misato  Japan )",
    "country": "Japan"
  },
  {
    "id": 138251,
    "name": "Tokyo Photoelectric Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 450831,
    "name": "Tolas Health Care Packaging Inc    ( Feasterville PA USA )",
    "country": "United States"
  },
  {
    "id": 177931,
    "name": "Toledo Norge A/S    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 457510,
    "name": "Tollos Inc    ( Barrie ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457511,
    "name": "Tollos Inc (United States)    ( Owings Mills MD USA )",
    "country": "United States"
  },
  {
    "id": 439730,
    "name": "Tollot (UK)    ( Southampton  USA )",
    "country": "United States"
  },
  {
    "id": 305694,
    "name": "Tollot Pty Ltd    ( Seven Hills  USA )",
    "country": "United States"
  },
  {
    "id": 458777,
    "name": "Tom-Cat Solutions    ( Ivyland PA USA )",
    "country": "United States"
  },
  {
    "id": 456261,
    "name": "Tomato M & C Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461455,
    "name": "Tomco Technologies    ( Stepney  Australia )",
    "country": "Australia"
  },
  {
    "id": 183969,
    "name": "TOMEY Corp    ( Nagoya  Japan )",
    "country": "Japan"
  },
  {
    "id": 192842,
    "name": "Tomey GmbH    ( Erlangen/Tennenlohe  Germany )",
    "country": "Germany"
  },
  {
    "id": 457022,
    "name": "TOMEY USA    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 458267,
    "name": "TOMI Environmental Solutions    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 454116,
    "name": "TomoTherapy Europe GmbH    ( Diegem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 439939,
    "name": "TomoTherapy Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 452734,
    "name": "TOMOVATION GmbH    ( Castrop/Rauxel  Germany )",
    "country": "Germany"
  },
  {
    "id": 461451,
    "name": "TomoVision    ( Magog PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 353244,
    "name": "TomTec Imaging Systems (China)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 353242,
    "name": "TomTec Imaging Systems (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 235679,
    "name": "TomTec Imaging Systems (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 353241,
    "name": "TomTec Imaging Systems (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 353169,
    "name": "TomTec Imaging Systems GmbH    ( Unterschleissheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 183930,
    "name": "TomTec Imaging Systems Inc    ( Erie CO USA )",
    "country": "United States"
  },
  {
    "id": 104770,
    "name": "Tomtec Inc    ( Hamden CT USA )",
    "country": "United States"
  },
  {
    "id": 457235,
    "name": "TOMY Corp    ( Oak Brook IL USA )",
    "country": "United States"
  },
  {
    "id": 399232,
    "name": "Tomy Digital Biology Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 161286,
    "name": "Tomy Tech USA Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 456699,
    "name": "Tongye Technologies Development Co Ltd    ( Tianjin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 289679,
    "name": "Tonokura Ika Kogyo Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454661,
    "name": "TONTARRA Medizintechnik GmbH    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103351,
    "name": "Toolmex Corp    ( Natick MA USA )",
    "country": "United States"
  },
  {
    "id": 158025,
    "name": "TOP Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455997,
    "name": "Top Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 184490,
    "name": "Top Glove Sdn Bhd    ( Klang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 456192,
    "name": "Top-Bound Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 192844,
    "name": "Topcon Australia Pty Ltd    ( Galdesvelle  Australia )",
    "country": "Australia"
  },
  {
    "id": 139215,
    "name": "Topcon Corp (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 192845,
    "name": "Topcon Deutschland GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 192846,
    "name": "Topcon Europe bv    ( Capelle a/d IJssel  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 150883,
    "name": "Topcon Medical Systems Inc    ( Oakland NJ USA )",
    "country": "United States"
  },
  {
    "id": 458264,
    "name": "Topera Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 371525,
    "name": "Topline Medical Inc    ( Long Lake MN USA )",
    "country": "United States"
  },
  {
    "id": 457389,
    "name": "TOPRO    ( Gjovik  Norway )",
    "country": "Norway"
  },
  {
    "id": 457537,
    "name": "Torax Medical Inc    ( Shoreview MN USA )",
    "country": "United States"
  },
  {
    "id": 106889,
    "name": "Toray Industries (America) Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 418638,
    "name": "Toray Industries (HK) Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 418642,
    "name": "Toray Industries (Singapore) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 137952,
    "name": "Toray Industries Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 418637,
    "name": "Toray Industries Inc (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 418643,
    "name": "Toray Industries Inc (Europe)    ( London  England )",
    "country": "UK"
  },
  {
    "id": 418639,
    "name": "Toray Industries Inc (Seoul)    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452114,
    "name": "Toray International America Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 105511,
    "name": "Torbot Group Inc    ( Cranston RI USA )",
    "country": "United States"
  },
  {
    "id": 174144,
    "name": "Toreck Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 285594,
    "name": "Tornier SA    ( Saint-Ismier Cedex  France )",
    "country": "France"
  },
  {
    "id": 454256,
    "name": "Tornier US    ( Bloomington MN USA )",
    "country": "United States"
  },
  {
    "id": 382455,
    "name": "Toroid Corp of Maryland    ( Salisbury MD USA )",
    "country": "United States"
  },
  {
    "id": 415935,
    "name": "Toroid do Brasil    ( San Jose dos Pinhais-PR  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 459514,
    "name": "Torrey Pines Scientific Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 999991,
    "name": "TORSION BALANCE    (   USA )",
    "country": "United States"
  },
  {
    "id": 407050,
    "name": "Toshiba America Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 101894,
    "name": "Toshiba America Medical Systems Inc    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 140664,
    "name": "Toshiba Corp    ( Otawara-shi  Japan )",
    "country": "Japan"
  },
  {
    "id": 158026,
    "name": "Toshiba Glass Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 373230,
    "name": "Toshiba Medical (Australia) Pty Ltd    ( North Ryde  Australia )",
    "country": "Australia"
  },
  {
    "id": 160817,
    "name": "Toshiba Medical Systems Europe bv    ( Zoetermeer  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 284954,
    "name": "Toshiba Medical Systems GmbH Deutschland    ( Neuss  Germany )",
    "country": "Germany"
  },
  {
    "id": 454392,
    "name": "Toshiba Medical Visualization Systems Europe Ltd    ( Edinburgh  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 107788,
    "name": "Tosoh Bioscience Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 451552,
    "name": "Tosoh Bioscience NV    ( Tessenderlo  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 226589,
    "name": "Tosoh Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 457170,
    "name": "Tosoh Europe NV    ( Tessenderlo  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 456045,
    "name": "Total Glove Co Sdn Bhd    ( Kamunting  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 458421,
    "name": "Total Gym    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 999668,
    "name": "Total Gym    ( San Diego CA USA )",
    "country": "USA"
  },
  {
    "id": 454615,
    "name": "Total OutSource Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 454616,
    "name": "Total OutSource Inc (India)    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 464107,
    "name": "Total Pharmacy Supply    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 462714,
    "name": "Total Renal Laboratories Inc    ( DeLand FL USA )",
    "country": "United States"
  },
  {
    "id": 454288,
    "name": "Total Repair Express LLC    ( Hillsborough NJ USA )",
    "country": "United States"
  },
  {
    "id": 364519,
    "name": "Total Resources International Inc    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 373218,
    "name": "Total Source Mfg    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 459508,
    "name": "Total Video Products Inc    ( Mickleton NJ USA )",
    "country": "United States"
  },
  {
    "id": 457135,
    "name": "Total Water Treatment Systems Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 470564,
    "name": "TotalKare of America Inc    ( Syracuse NY USA )",
    "country": "United States"
  },
  {
    "id": 347681,
    "name": "TotalMed    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 454071,
    "name": "Totax Plastic A/S    ( Vedbaek  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 162657,
    "name": "Totenprodukter A/S    ( Gjovik  Norway )",
    "country": "Norway"
  },
  {
    "id": 471690,
    "name": "TOTH Ltda    ( Santiago  Chile )",
    "country": "Chile"
  },
  {
    "id": 457582,
    "name": "TOTOKU Electric Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999547,
    "name": "Touch America    ( Hillsborough NC USA )",
    "country": "USA"
  },
  {
    "id": 461450,
    "name": "Touch of Life Technologies Inc    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 454870,
    "name": "TouchPoll Inc    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 450929,
    "name": "ToughStuff Fitness Equipment Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 457242,
    "name": "Tower Medical Systems    ( Holbrook NY USA )",
    "country": "United States"
  },
  {
    "id": 999934,
    "name": "Tower Medical Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 461446,
    "name": "TowerMetriX Inc    ( Wexford PA USA )",
    "country": "United States"
  },
  {
    "id": 461449,
    "name": "Towers Watson    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 461408,
    "name": "Town Food Service Equipment Co    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 393128,
    "name": "Towne Brothers (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 456091,
    "name": "Towne Brothers (Pvt) Ltd    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 184005,
    "name": "Townsend Design    ( Bakersfield CA USA )",
    "country": "United States"
  },
  {
    "id": 305958,
    "name": "Townson & Mercer Ltd    ( Altrincham  USA )",
    "country": "United States"
  },
  {
    "id": 289687,
    "name": "Toyo Chemical Laboratories Inc    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 289688,
    "name": "Toyo Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 278781,
    "name": "Toyo Medic Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 150932,
    "name": "Toyobo Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 336561,
    "name": "TP (Australia) Pty Ltd    ( Parkville  Australia )",
    "country": "Australia"
  },
  {
    "id": 336427,
    "name": "TP Orthodontics Brasil    ( Tupa-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 105305,
    "name": "TP Orthodontics Inc    ( La Porte IN USA )",
    "country": "United States"
  },
  {
    "id": 384355,
    "name": "TP Orthodontics Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 336428,
    "name": "TP Orthodontics Mexico SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 402855,
    "name": "TP Orthodontics South Africa (Pty) Ltd    ( Fourways North  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 407192,
    "name": "TP Orthodontics UK    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 999555,
    "name": "TPC Advanced Technology, Inc.    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 373151,
    "name": "TR Care bv    ( Ochten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 320857,
    "name": "TR Equipment AB    ( Tranas  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 373152,
    "name": "TR Equipment GmbH    ( Paderborn  Germany )",
    "country": "Germany"
  },
  {
    "id": 320705,
    "name": "TR Group Inc    ( Glenview IL USA )",
    "country": "United States"
  },
  {
    "id": 461395,
    "name": "TR Medical Systems    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 416562,
    "name": "Traatek Inc    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 391376,
    "name": "TracAbout Inc    ( Newton KS USA )",
    "country": "United States"
  },
  {
    "id": 465102,
    "name": "TRACE Analytics GmbH    ( Braunschweig x USA )",
    "country": "United States"
  },
  {
    "id": 459387,
    "name": "Trace Medical Equipment Inc    ( Lisle IL USA )",
    "country": "United States"
  },
  {
    "id": 457128,
    "name": "Trace Services Inc    ( Roselle IL USA )",
    "country": "United States"
  },
  {
    "id": 455669,
    "name": "TraceLink    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 474834,
    "name": "Tracelogix    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 428010,
    "name": "Tracewell Inc    ( Westerville OH USA )",
    "country": "United States"
  },
  {
    "id": 465069,
    "name": "Tracey Technologies    ( Houston 16 USA )",
    "country": "United States"
  },
  {
    "id": 286013,
    "name": "Tracheomed GmbH    ( Schwalbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 467021,
    "name": "Traco Manufacturing Inc    ( Orem UT USA )",
    "country": "United States"
  },
  {
    "id": 999730,
    "name": "Traco Manufacturing Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 106259,
    "name": "Traco Medical Inc    ( Sioux Falls SD USA )",
    "country": "United States"
  },
  {
    "id": 306282,
    "name": "Tracoe GmbH    ( Frankfurt am Main  Germany )",
    "country": "Germany"
  },
  {
    "id": 103372,
    "name": "Trademark Medical    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 431580,
    "name": "Tradeways Ltd    ( Annapolis MD USA )",
    "country": "United States"
  },
  {
    "id": 325629,
    "name": "Tradex International Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 468424,
    "name": "Trailmate    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 454060,
    "name": "TRALE Inc    ( Daleville IN USA )",
    "country": "United States"
  },
  {
    "id": 162688,
    "name": "Tranas Rostfria AB    ( Tranas  USA )",
    "country": "United States"
  },
  {
    "id": 461248,
    "name": "Trane Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 335462,
    "name": "Trans American Medical Instrument Corp    ( Bridgeview IL USA )",
    "country": "United States"
  },
  {
    "id": 461075,
    "name": "Transaction Innovation Corp    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 459519,
    "name": "TransAmerican Medical Imaging Inc    ( Lindon UT USA )",
    "country": "United States"
  },
  {
    "id": 332076,
    "name": "Transasia Biomedicals Ltd    ( Mumbai  India )",
    "country": "India"
  },
  {
    "id": 348933,
    "name": "Transcat Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 454461,
    "name": "Transcat Inc    ( Rochester NY USA )",
    "country": "United States"
  },
  {
    "id": 289368,
    "name": "Transcend Services Inc    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 459396,
    "name": "TransChart LLC    ( Dublin OH USA )",
    "country": "United States"
  },
  {
    "id": 390973,
    "name": "Transcon GmbH    ( Nettetal  Germany )",
    "country": "Germany"
  },
  {
    "id": 332356,
    "name": "Transducer Techniques Inc    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 455345,
    "name": "TransEnterix Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 325632,
    "name": "Transfer Master Products Inc    ( Postville IA USA )",
    "country": "United States"
  },
  {
    "id": 415934,
    "name": "Transformer Technology AS    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 354297,
    "name": "Transgenomic Inc    ( Omaha NE USA )",
    "country": "United States"
  },
  {
    "id": 102674,
    "name": "Transidyne General Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 456844,
    "name": "TransLite LLC    ( Sugar Land TX USA )",
    "country": "United States"
  },
  {
    "id": 464098,
    "name": "Translumina GmbH    ( Hechingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 174432,
    "name": "Transmai Equips Medicos Hosp Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 453695,
    "name": "Transmed Medizintechnik GmbH & Co KG    ( Nordrhein-Westfalen  Germany )",
    "country": "Germany"
  },
  {
    "id": 352808,
    "name": "Transmedic Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457133,
    "name": "TransMedics Inc    ( Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 440714,
    "name": "TransMotion Medical Inc    ( Sharon Center OH USA )",
    "country": "United States"
  },
  {
    "id": 452110,
    "name": "Transonic Systems Asia Inc    ( Dayuan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 344827,
    "name": "Transonic Systems Europe bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 106570,
    "name": "Transonic Systems Inc    ( Ithaca NY USA )",
    "country": "United States"
  },
  {
    "id": 344825,
    "name": "Transonic Systems Japan    ( Dayuan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 452996,
    "name": "Transplant Services Center of UT Southwestern Medi    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 454847,
    "name": "TRanspoRehaMed GmbH    ( Leichlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 108341,
    "name": "Transportation Concepts of Michigan Ltd    ( Lansing MI USA )",
    "country": "United States"
  },
  {
    "id": 461347,
    "name": "Transquip International    ( Webster TX USA )",
    "country": "United States"
  },
  {
    "id": 461392,
    "name": "TransTech Medical Solutions    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 107720,
    "name": "Transtector Systems Inc    ( Hayden ID USA )",
    "country": "United States"
  },
  {
    "id": 105719,
    "name": "Transtracheal Systems Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 459273,
    "name": "TransUnion LLC    ( Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 461390,
    "name": "TransVac Solutions    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 456193,
    "name": "Transverse Industries Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457082,
    "name": "Transysteme JMT Implants SA    ( Nimes Cedex 1  France )",
    "country": "France"
  },
  {
    "id": 461517,
    "name": "Trapeze Software LLC    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 999960,
    "name": "Traulsen Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 281237,
    "name": "Trautwein GmbH    ( Emmendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285620,
    "name": "Trautwein GmbH & Co    ( Recklinghausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 325633,
    "name": "Travel Ramp Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 410023,
    "name": "Traverse Rescue    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 466885,
    "name": "Travis Cleanair Inc    ( Loveland CO USA )",
    "country": "United States"
  },
  {
    "id": 456794,
    "name": "Traxsys Input Products    ( London  England )",
    "country": "UK"
  },
  {
    "id": 454144,
    "name": "TRB Chemedica AG    ( Haar bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454287,
    "name": "TRE Endoscopy One    ( Hunt Valley MD USA )",
    "country": "United States"
  },
  {
    "id": 452977,
    "name": "Trecore LLC    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 458860,
    "name": "Tree Star Inc    ( Ashland OR USA )",
    "country": "United States"
  },
  {
    "id": 332358,
    "name": "TreeAge Software Inc    ( Williamstown MA USA )",
    "country": "United States"
  },
  {
    "id": 186684,
    "name": "Treff AG    ( Degersheim  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 293278,
    "name": "Treff AG    ( Degersheim  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 361522,
    "name": "TREK Diagnostic Systems Inc    ( Cleveland OH USA )",
    "country": "United States"
  },
  {
    "id": 467131,
    "name": "TRITECH Associates Inc    ( Manasquan NJ USA )",
    "country": "United States"
  },
  {
    "id": 361534,
    "name": "TREK Diagnostic Systems Ltd    ( East Grinstead  England )",
    "country": "UK"
  },
  {
    "id": 104707,
    "name": "Trek Inc    ( Medina NY USA )",
    "country": "United States"
  },
  {
    "id": 452878,
    "name": "TREK Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 431926,
    "name": "Treksafe    ( Bonville  Australia )",
    "country": "Australia"
  },
  {
    "id": 453985,
    "name": "Tremblay Harrison Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 453639,
    "name": "Tremetrics    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 287328,
    "name": "Tremmel & Schaum Ohg    ( Wettenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286871,
    "name": "Treu-Instrumente GmbH    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461520,
    "name": "Trevi Technology Inc    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 306062,
    "name": "Trewavis Surgical    ( Boronia  Australia )",
    "country": "Australia"
  },
  {
    "id": 306031,
    "name": "Tri Delta Diagnostics    ( Morris Plains NJ USA )",
    "country": "United States"
  },
  {
    "id": 409076,
    "name": "Tri Star Optical    ( Westlake Village CA USA )",
    "country": "United States"
  },
  {
    "id": 451324,
    "name": "Tri State Medical Supply    ( Wauseon OH USA )",
    "country": "United States"
  },
  {
    "id": 101896,
    "name": "Tri W-G Group    ( Valley City ND USA )",
    "country": "United States"
  },
  {
    "id": 450855,
    "name": "Tri-anim Health Services Inc    ( Sylmar CA USA )",
    "country": "United States"
  },
  {
    "id": 454054,
    "name": "Tri-DD LLC    ( Boonton Township NJ USA )",
    "country": "United States"
  },
  {
    "id": 182619,
    "name": "Tri-Dim Filter Corp    ( Louisa VA USA )",
    "country": "United States"
  },
  {
    "id": 325635,
    "name": "Tri-Med Inc    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 104354,
    "name": "Tri-Star Industries Ltd    ( Yarmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 461459,
    "name": "Tri-State Digital Imaging    ( Rossville TN USA )",
    "country": "United States"
  },
  {
    "id": 457900,
    "name": "Tri-State Filing Systems Inc    ( Shreveport LA USA )",
    "country": "United States"
  },
  {
    "id": 103919,
    "name": "Tri-State Hospital Supply Corp    ( Howell MI USA )",
    "country": "United States"
  },
  {
    "id": 187834,
    "name": "Tri-State Medical    ( Gretna LA USA )",
    "country": "United States"
  },
  {
    "id": 457759,
    "name": "Tri-State Surgical Supply & Equipment    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 106209,
    "name": "Tri-Tec Medical    ( Germantown TN USA )",
    "country": "United States"
  },
  {
    "id": 332975,
    "name": "Tri-Tech Inc    ( Southport NC USA )",
    "country": "United States"
  },
  {
    "id": 458258,
    "name": "Tri-Tech Medical Inc    ( Avon OH USA )",
    "country": "United States"
  },
  {
    "id": 394340,
    "name": "Triad Disposables    ( Hartland WI USA )",
    "country": "United States"
  },
  {
    "id": 170931,
    "name": "Triad Health Care Ltd    ( Maryville IL USA )",
    "country": "United States"
  },
  {
    "id": 466910,
    "name": "Triage Logic LLC    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 108836,
    "name": "Triangle Biomedical Sciences Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 461381,
    "name": "TRIATEK    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 454354,
    "name": "Tribe Medical Group    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 185575,
    "name": "Tribo-Med Przeds Wielobranzowe    ( Nowa Wies Wielka  Poland )",
    "country": "Poland"
  },
  {
    "id": 185576,
    "name": "Tricomed SA    ( Lodz  Poland )",
    "country": "Poland"
  },
  {
    "id": 406262,
    "name": "TriContinent (UK)    ( Sudbury  England )",
    "country": "UK"
  },
  {
    "id": 169861,
    "name": "TriContinent Inc    ( Grass Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 102675,
    "name": "Tridak    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 185577,
    "name": "Trident Med    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 372168,
    "name": "Trident Medical Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454065,
    "name": "Trident Medical Inc    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 175014,
    "name": "Trident Medical Products Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 455009,
    "name": "Tridien Medical    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 454516,
    "name": "Trig Medical Ltd    ( Yokne'am Ilit  Israel )",
    "country": "Israel"
  },
  {
    "id": 332034,
    "name": "Trigate Pty Ltd    ( Randburg  USA )",
    "country": "United States"
  },
  {
    "id": 455036,
    "name": "Trilliant Surgical Ltd    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 409576,
    "name": "Trilux AG    ( Dietikon  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 409575,
    "name": "Trilux BVBA    ( Mechelen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 409577,
    "name": "Trilux Ceska republika sro    ( Praha 7  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 409583,
    "name": "Trilux CV    ( Amersfoort  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 409579,
    "name": "Trilux France SAS    ( Molsheim Cedex  France )",
    "country": "France"
  },
  {
    "id": 409582,
    "name": "Trilux Italia srl    ( Bergamo (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 409581,
    "name": "Trilux Kepviselet    ( Budapest  Hungary )",
    "country": "Hungary"
  },
  {
    "id": 409572,
    "name": "Trilux Leuchten GesmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 409585,
    "name": "Trilux Lighting Inc    ( Cavite  Philippines )",
    "country": "Philippines"
  },
  {
    "id": 409580,
    "name": "Trilux Lighting Ltd    ( Winsford Way  England )",
    "country": "UK"
  },
  {
    "id": 456585,
    "name": "TRILUX Medical GmbH & Co KG    ( Arnsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 456591,
    "name": "TRILUX Medical GmbH & Co KG (Jordan)    ( Amman  Jordan )",
    "country": "Jordan"
  },
  {
    "id": 456592,
    "name": "TRILUX Medical GmbH & Co KG (Spain)    ( Alcobendas (Madrid)  Spain )",
    "country": "Spain"
  },
  {
    "id": 456586,
    "name": "TRILUX MEDICAL ITALIA srl    ( Anzola Dell'Emilia (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 409584,
    "name": "Trilux Slovakia sro    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 279546,
    "name": "Trilux-Lenze GmbH & Co KG    ( Arnsberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 462747,
    "name": "TriMark Strategic    ( Coppell TX USA )",
    "country": "United States"
  },
  {
    "id": 455553,
    "name": "TriMedCo Inc    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 457243,
    "name": "TriMedx    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 105706,
    "name": "Trimedyne Inc    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 303389,
    "name": "Trimline Medical Products Corp    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 305454,
    "name": "Trinity Biotech plc    ( Bray  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 344612,
    "name": "Trinity Biotech USA    ( Berkeley Heights NJ USA )",
    "country": "United States"
  },
  {
    "id": 374391,
    "name": "Trinity Sterile Inc    ( Salisbury MD USA )",
    "country": "United States"
  },
  {
    "id": 348952,
    "name": "Trinity Technologies    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 466558,
    "name": "Trinity Video Communications Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 461370,
    "name": "Trintech Inc    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 462918,
    "name": "Trio Medical Supply LLC    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 282586,
    "name": "Trio Plus GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 103921,
    "name": "Trion Inc    ( Sanford NC USA )",
    "country": "United States"
  },
  {
    "id": 361276,
    "name": "Trion Ltd    ( Winchester  England )",
    "country": "UK"
  },
  {
    "id": 370462,
    "name": "TriPath Imaging Inc    ( Burlington NC USA )",
    "country": "United States"
  },
  {
    "id": 999664,
    "name": "Triplett Test Equipment & Tools    ( Manchester NH USA )",
    "country": "USA"
  },
  {
    "id": 106890,
    "name": "Tripp Lite    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 416241,
    "name": "Trismed Co Ltd    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 461362,
    "name": "Tristan Technologies    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457244,
    "name": "Tristate Biomedical Solutions LLC    ( Florence KY USA )",
    "country": "United States"
  },
  {
    "id": 161070,
    "name": "Tritec    ( Hannover 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 170806,
    "name": "Tritech Field Engineering Inc    ( Edgewater MD USA )",
    "country": "United States"
  },
  {
    "id": 454082,
    "name": "Tritech Forensics    ( Southport NC USA )",
    "country": "United States"
  },
  {
    "id": 457580,
    "name": "Tritech Research Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 282588,
    "name": "Triton Belco GmbH    ( Lehrte  USA )",
    "country": "United States"
  },
  {
    "id": 456437,
    "name": "Trium Analysis Online GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 461361,
    "name": "Triumvirate Environmental Inc    ( Somerville MA USA )",
    "country": "United States"
  },
  {
    "id": 457551,
    "name": "TriVascular Inc    ( Santa Rosa CA USA )",
    "country": "United States"
  },
  {
    "id": 173961,
    "name": "Trnka Ges mbH Co KG    ( Wien 1  USA )",
    "country": "United States"
  },
  {
    "id": 186831,
    "name": "Trnka Muskva    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 186832,
    "name": "Trnka Spol s r o    ( Brno  USA )",
    "country": "United States"
  },
  {
    "id": 187839,
    "name": "Troff Medical Services    ( Hendersonville NC USA )",
    "country": "United States"
  },
  {
    "id": 282591,
    "name": "Troge Medical GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 358437,
    "name": "Trokamed GmbH    ( Geisingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 452381,
    "name": "Trolli King (Holdings) Ltd    ( ShenZhen  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 325636,
    "name": "Tronex Healthcare Industries    ( Parsippany NJ USA )",
    "country": "United States"
  },
  {
    "id": 456878,
    "name": "Tronex International Inc    ( Mt Olive NJ USA )",
    "country": "United States"
  },
  {
    "id": 351185,
    "name": "Trophy Espana SL    ( Madrid  USA )",
    "country": "United States"
  },
  {
    "id": 283386,
    "name": "Trophy GmbH    ( Kehl am Rhein  USA )",
    "country": "United States"
  },
  {
    "id": 235328,
    "name": "Trophy Radiologie Ltd    ( Rochester  USA )",
    "country": "United States"
  },
  {
    "id": 151034,
    "name": "Trophy Radiology SA    ( Marne la Vallee Cedex 2  USA )",
    "country": "United States"
  },
  {
    "id": 329908,
    "name": "Trovi Rajawali Indonesia    ( Bekasi  USA )",
    "country": "United States"
  },
  {
    "id": 174433,
    "name": "Trox do Brasil Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 461357,
    "name": "TROY Barbell & Fitness    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 105245,
    "name": "Troy Biologicals Inc    ( Troy MI USA )",
    "country": "United States"
  },
  {
    "id": 465219,
    "name": "TROY Group, Inc.    ( Costa Mesa 94 USA )",
    "country": "United States"
  },
  {
    "id": 451420,
    "name": "Troy Innovative Instruments Inc    ( Middlefield OH USA )",
    "country": "United States"
  },
  {
    "id": 458245,
    "name": "Tru-Care Health Systems Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 471588,
    "name": "TRU-D SmartUVC LLC    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 101818,
    "name": "Tru-Trac Therapy Products Inc (Sub Henley Int Inc)    (   USA )",
    "country": "United States"
  },
  {
    "id": 461342,
    "name": "TruCorp Ltd    ( Belfast  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 105779,
    "name": "Trudell Medical Marketing Ltd    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 406796,
    "name": "Trudell Medical Marketing Ltd    ( London ON Canada )",
    "country": "Canada"
  },
  {
    "id": 333744,
    "name": "True Fitness    ( O'Fallon MO USA )",
    "country": "United States"
  },
  {
    "id": 459411,
    "name": "True Food International Inc    ( O'Fallon MO USA )",
    "country": "United States"
  },
  {
    "id": 999992,
    "name": "TRUE MANUFACTURING    (   USA )",
    "country": "United States"
  },
  {
    "id": 458365,
    "name": "TrueVision Systems Inc    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 394557,
    "name": "Truform-OTC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 452371,
    "name": "Trulife (Canada)    ( Trenton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452045,
    "name": "Trulife (USA)    ( Jackson MI USA )",
    "country": "United States"
  },
  {
    "id": 344591,
    "name": "Trulife Group Ltd    ( Dublin 24  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 455763,
    "name": "Truly Instrument Ltd    ( New Territories  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 391353,
    "name": "TRUMPF Kreuzer Medizin Systeme GmbH & Co KG    ( Puchheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 441254,
    "name": "TRUMPF Medical Systems Inc    ( Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 407177,
    "name": "TRUMPF Medizin Systeme GmbH    ( Saalfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 454456,
    "name": "TRUMPF Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 163008,
    "name": "Truphatek International Ltd    ( Netanya  Israel )",
    "country": "Israel"
  },
  {
    "id": 455829,
    "name": "Truphatek International Ltd    ( Netanya  Israel )",
    "country": "Israel"
  },
  {
    "id": 460673,
    "name": "TrustHCS    ( Springfield MO USA )",
    "country": "United States"
  },
  {
    "id": 464997,
    "name": "Trustwave Holdings Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 458608,
    "name": "Truven Health Analytics Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 451638,
    "name": "Trycom Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 453716,
    "name": "Tryton Medical Inc    ( Durham NC USA )",
    "country": "United States"
  },
  {
    "id": 106205,
    "name": "TS Medical Resource Systems Inc    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 456858,
    "name": "TS Meditech Co Ltd    ( Wonju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 459506,
    "name": "TSAChoice Inc    ( Candler NC USA )",
    "country": "United States"
  },
  {
    "id": 236960,
    "name": "TSE spol s r o    ( Ceske Budejovice  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 458660,
    "name": "TSE Systems Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 451227,
    "name": "TSI Inc    ( Shoreview MN USA )",
    "country": "United States"
  },
  {
    "id": 999619,
    "name": "TSi Power Corporation    ( Antigo WI USA )",
    "country": "United States"
  },
  {
    "id": 461341,
    "name": "TSIG Consulting    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 372466,
    "name": "TSK Laboratory International Inc    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 278783,
    "name": "TSK Laboratory Japan    ( Tochigi  Japan )",
    "country": "Japan"
  },
  {
    "id": 471616,
    "name": "TSK Products    ( Eatontown NJ USA )",
    "country": "United States"
  },
  {
    "id": 455041,
    "name": "TSO`3 Inc    ( Quebec PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 158040,
    "name": "Tsubasa Industry Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 459497,
    "name": "TTE Laboratories Inc    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 108102,
    "name": "TTI Medical    ( San Ramon CA USA )",
    "country": "United States"
  },
  {
    "id": 461369,
    "name": "TTP Labtech Ltd    ( Melbourn  United Kingdom )",
    "country": "United Kingdom"
  },
  {
    "id": 279762,
    "name": "TTS Product & Service GmbH    ( Bad Sulza  Germany )",
    "country": "Germany"
  },
  {
    "id": 161071,
    "name": "TTW Industrie- und Medizin-Messtechnik GmbH    ( Waldkirch  Germany )",
    "country": "Germany"
  },
  {
    "id": 999537,
    "name": "TTX Computer Products Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 306501,
    "name": "Tubular Mobility Equipment    ( Tauranga  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 103576,
    "name": "Tubular Specialties Mfg Inc    ( Los Angeles CA USA )",
    "country": "United States"
  },
  {
    "id": 458284,
    "name": "Tucker-Davis Technologies Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 161072,
    "name": "Tuenkers Maschinenbau GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 108343,
    "name": "Tuffcare Inc    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 362830,
    "name": "Tulip Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 286873,
    "name": "Tumed Surgical Instruments & Hospital Supplies Gmb    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 286569,
    "name": "Tunstall Comsystem GmbH    ( Telgte  Germany )",
    "country": "Germany"
  },
  {
    "id": 105772,
    "name": "TUNTURI    (   USA )",
    "country": "United States"
  },
  {
    "id": 139032,
    "name": "Tunturi Oy    ( Turku  Finland )",
    "country": "Finland"
  },
  {
    "id": 458859,
    "name": "Turbo Air Inc    ( Long Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 337629,
    "name": "Turbo-Doc Medical Record Systems Inc    ( Paradise CA USA )",
    "country": "United States"
  },
  {
    "id": 459153,
    "name": "TurboChef Technologies Inc    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 151393,
    "name": "Turn-Key Technologies Inc    ( Sayreville NJ USA )",
    "country": "United States"
  },
  {
    "id": 104507,
    "name": "Turner Designs Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 459187,
    "name": "Turning Technologies LLC    ( Youngstown OH USA )",
    "country": "United States"
  },
  {
    "id": 283918,
    "name": "Turtenwald Zahntechnik GmbH    ( Lengenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 232651,
    "name": "Tuta Healthcare Pty Ltd    ( Lane Cove  USA )",
    "country": "United States"
  },
  {
    "id": 395334,
    "name": "Tuthill Coupling Group    ( Berea OH USA )",
    "country": "United States"
  },
  {
    "id": 361922,
    "name": "Tutogen Medical US Inc    ( Alachua FL USA )",
    "country": "United States"
  },
  {
    "id": 183737,
    "name": "Tuttnauer Europe bv    ( Breda  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455256,
    "name": "Tuttnauer India Ltd    ( Mumbia  India )",
    "country": "India"
  },
  {
    "id": 418600,
    "name": "Tuttnauer Russia    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 108837,
    "name": "Tuttnauer USA Co Ltd    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 431499,
    "name": "TVI Corp    ( Glenn Dale MD USA )",
    "country": "United States"
  },
  {
    "id": 335215,
    "name": "TVR Communications    ( Woodside NY USA )",
    "country": "United States"
  },
  {
    "id": 227246,
    "name": "Twente Medical Systems    ( Oldenzaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 353319,
    "name": "TWF Services    ( Goliad TX USA )",
    "country": "United States"
  },
  {
    "id": 457982,
    "name": "Twin Star ECS    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 454035,
    "name": "Twin Supply Inc    ( Staten Island NY USA )",
    "country": "United States"
  },
  {
    "id": 999900,
    "name": "Tyco Electronics    (   USA )",
    "country": "United States"
  },
  {
    "id": 455212,
    "name": "Tyco Electronics Corp    ( Berwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 455214,
    "name": "Tyco Electronics Ltd    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453433,
    "name": "Tyco Fire Suppression & Building Products    ( Lansdale PA USA )",
    "country": "United States"
  },
  {
    "id": 442012,
    "name": "Tyco Healthcare Belgium SA NV    ( Mechelen  USA )",
    "country": "United States"
  },
  {
    "id": 440359,
    "name": "Tyco Healthcare Canada    ( Pointe-Claire PQ USA )",
    "country": "United States"
  },
  {
    "id": 403674,
    "name": "Tyco Healthcare Deutschland GmbH    ( Neustradt/Donau  USA )",
    "country": "United States"
  },
  {
    "id": 442014,
    "name": "Tyco Healthcare France    ( Elancourt  USA )",
    "country": "United States"
  },
  {
    "id": 442010,
    "name": "Tyco Healthcare Japan Inc    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 440904,
    "name": "Tyco Healthcare Ludlow    ( Chicopee MA USA )",
    "country": "United States"
  },
  {
    "id": 399028,
    "name": "Tyco Healthcare Pte Ltd    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 442011,
    "name": "Tyco Healthcare Pty Ltd    ( Lane Cove  USA )",
    "country": "United States"
  },
  {
    "id": 402330,
    "name": "Tyco Healthcare Retail Group Inc    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 442013,
    "name": "Tyco Healthcare Switzerland Ltd    ( Wallerau  USA )",
    "country": "United States"
  },
  {
    "id": 398199,
    "name": "Tyco Healthcare UK Ltd    ( Gosport  USA )",
    "country": "United States"
  },
  {
    "id": 450910,
    "name": "Tyco Healthcare Uni-Patch    ( Wabasha MN USA )",
    "country": "United States"
  },
  {
    "id": 452680,
    "name": "TYLA Medical Systems Ltd    ( Lod  Israel )",
    "country": "Israel"
  },
  {
    "id": 444442,
    "name": "Tympany    ( The Woodlands TX USA )",
    "country": "United States"
  },
  {
    "id": 457450,
    "name": "Tynor Orthopedics Ltd    ( Mohali  India )",
    "country": "India"
  },
  {
    "id": 293254,
    "name": "Typon    ( Burgdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461339,
    "name": "Tyromotion GmbH    ( Graz  Austria )",
    "country": "Austria"
  },
  {
    "id": 456215,
    "name": "Tyson Bioresearch Inc    ( Chunan  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 415439,
    "name": "Tytex A/S    ( Ikast  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 451745,
    "name": "Tytex GmbH    ( Wiehl  Germany )",
    "country": "Germany"
  },
  {
    "id": 335877,
    "name": "Tytex Inc    ( Lincoln RI USA )",
    "country": "United States"
  },
  {
    "id": 416174,
    "name": "Tytex Ireland Ltd    ( Youghal  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 451746,
    "name": "Tytex Slovakia sro    ( Humenne  Germany )",
    "country": "Germany"
  },
  {
    "id": 363108,
    "name": "TZ Medical Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 358812,
    "name": "Tzora Active Systems Ltd    ( Doar Na Shimshon  Israel )",
    "country": "Israel"
  },
  {
    "id": 999781,
    "name": "U-Line Corporation    ( Milwaukee WI USA )",
    "country": "United States"
  },
  {
    "id": 456259,
    "name": "U-Medical Co Ltd    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451033,
    "name": "U-Systems Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 107812,
    "name": "U.S. Laser Corp    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 403739,
    "name": "UA Medical Products Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 337486,
    "name": "UAB Oriola Vilnius    ( Vilnius  Lithuania )",
    "country": "Lithuania"
  },
  {
    "id": 453878,
    "name": "UAB Viltechmeda    ( Vilnius  Lithuania )",
    "country": "Lithuania"
  },
  {
    "id": 457423,
    "name": "UBCare Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 283360,
    "name": "Ubert GmbH & Co KG    ( Lohfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 453411,
    "name": "UBS Aesthetics    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 453436,
    "name": "UBS Aesthetics    ( Bethlehem PA USA )",
    "country": "United States"
  },
  {
    "id": 402849,
    "name": "UBS Instruments Corp    ( Canoga Park CA USA )",
    "country": "United States"
  },
  {
    "id": 458426,
    "name": "UCapIt    ( Des Moines IA USA )",
    "country": "United States"
  },
  {
    "id": 344441,
    "name": "UCO International    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 158028,
    "name": "Udono Medical Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 184225,
    "name": "UDT    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 107932,
    "name": "UDT Instruments    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 102676,
    "name": "UE Systems Inc    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 287300,
    "name": "Uebe GmbH    ( Wertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 158029,
    "name": "Ueda Electronic Works Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 101895,
    "name": "UFI Corp    ( Morro Bay CA USA )",
    "country": "United States"
  },
  {
    "id": 458964,
    "name": "UFSK International OSYS GmbH    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 285640,
    "name": "UFSK-International Hermann Scherrieble Unternehmen    ( Regensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452565,
    "name": "UG Healthcare (USA) Inc    ( Torrance CA USA )",
    "country": "United States"
  },
  {
    "id": 452345,
    "name": "UGO Ltd    ( Newton Abbot  England )",
    "country": "UK"
  },
  {
    "id": 462532,
    "name": "UHC    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 458822,
    "name": "UHS Surgical Services Inc    ( Sun Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 104871,
    "name": "UIC Inc    ( Joliet IL USA )",
    "country": "United States"
  },
  {
    "id": 455646,
    "name": "UK Medical Ltd    ( Sheffield  England )",
    "country": "UK"
  },
  {
    "id": 454265,
    "name": "UK Surgical Ltd    ( Halifax  England )",
    "country": "UK"
  },
  {
    "id": 161076,
    "name": "UKS-Robert Fischer GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459018,
    "name": "UL LLC    ( Franklin TN USA )",
    "country": "United States"
  },
  {
    "id": 157051,
    "name": "Ulco Engineering Pty Ltd    ( Marrickville  Australia )",
    "country": "Australia"
  },
  {
    "id": 454037,
    "name": "Uline Shipping Supplies    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 103085,
    "name": "Ulmer Pharmacal Co    ( Park Rapids MN USA )",
    "country": "United States"
  },
  {
    "id": 170959,
    "name": "Ulrich AG    ( St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 279451,
    "name": "Ulrich Alber GmbH & Co KG    ( Albstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 177198,
    "name": "Ulrich GmbH & Co KG    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 452756,
    "name": "ulrich Medical USA    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 284803,
    "name": "Ulrich Mueller    ( Nehren  Germany )",
    "country": "Germany"
  },
  {
    "id": 457330,
    "name": "UlrichSwiss    ( St Gallen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 103578,
    "name": "Ulster Scientific Inc    ( New Paltz NY USA )",
    "country": "United States"
  },
  {
    "id": 458425,
    "name": "Ulthera Inc    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 999425,
    "name": "Ulthera, Inc.    ( Mesa AZ USA )",
    "country": "United States"
  },
  {
    "id": 273746,
    "name": "Ulti Med Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 454340,
    "name": "Ultimate Dental    ( Memphis TN USA )",
    "country": "United States"
  },
  {
    "id": 455388,
    "name": "Ultimate Healthcare    ( Barnsley  England )",
    "country": "UK"
  },
  {
    "id": 455183,
    "name": "Ultimate Medical    ( Richmond  Australia )",
    "country": "Australia"
  },
  {
    "id": 426337,
    "name": "Ultimate Safety Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 461505,
    "name": "Ultimate Software    ( Weston FL USA )",
    "country": "United States"
  },
  {
    "id": 359602,
    "name": "Ultimed Trading Co SA    ( Yverdon-les-Bains  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 450998,
    "name": "Ultra Clean Systems Inc    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 455719,
    "name": "Ultra for Medical Products Co (Egypt)    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 457113,
    "name": "Ultra Solutions    ( Ontario CA USA )",
    "country": "United States"
  },
  {
    "id": 178578,
    "name": "Ultra Violet Products Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 107497,
    "name": "Ultra-Lum Inc    ( Claremont CA USA )",
    "country": "United States"
  },
  {
    "id": 459379,
    "name": "Ultra-Scan Corp    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 333613,
    "name": "Ultracell Medical Technologies Inc    ( North Stonington CT USA )",
    "country": "United States"
  },
  {
    "id": 178224,
    "name": "UltraCision Inc.    (   USA )",
    "country": "United States"
  },
  {
    "id": 284731,
    "name": "Ultradent    ( Brunnthal  Germany )",
    "country": "Germany"
  },
  {
    "id": 107498,
    "name": "Ultradent Products Inc    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 457438,
    "name": "Ultraflex Systems Inc    ( Pottstown PA USA )",
    "country": "United States"
  },
  {
    "id": 427846,
    "name": "UltraImaging Systems Inc    ( Manteca CA USA )",
    "country": "United States"
  },
  {
    "id": 451543,
    "name": "Ultralife Batteries (UK) Ltd    ( Abingdon  England )",
    "country": "UK"
  },
  {
    "id": 187844,
    "name": "Ultralife Batteries Inc    ( Newark NY USA )",
    "country": "United States"
  },
  {
    "id": 451542,
    "name": "Ultralife Batteries Inc    ( Newark NY USA )",
    "country": "United States"
  },
  {
    "id": 451210,
    "name": "Ultralite Enterprises Inc    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 108840,
    "name": "Ultramed Inc    ( Grosse Pointe Farms MI USA )",
    "country": "United States"
  },
  {
    "id": 324477,
    "name": "Ultramedic Ltd    ( Liverpool  England )",
    "country": "UK"
  },
  {
    "id": 458419,
    "name": "UltraRAD Corp    ( West Berlin NJ USA )",
    "country": "United States"
  },
  {
    "id": 173827,
    "name": "Ultrarad Electro Medical (Pty) Ltd    ( Auckland Park  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 332379,
    "name": "Ultrascan Inc    ( Goodlettsville TN USA )",
    "country": "United States"
  },
  {
    "id": 391912,
    "name": "UltraScope    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 307528,
    "name": "Ultrasonic Mfg    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 459378,
    "name": "Ultrasonic Power Corp    ( Freeport IL USA )",
    "country": "United States"
  },
  {
    "id": 451556,
    "name": "Ultrasonix Medical Corp    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 451992,
    "name": "Ultrasonix Medical Corp (Canada)    ( Richmond BC Canada )",
    "country": "Canada"
  },
  {
    "id": 454370,
    "name": "Ultrasonix Medical Ltd Europe    ( High Wycombe  England )",
    "country": "UK"
  },
  {
    "id": 439941,
    "name": "Ultrasound Parts Inc    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 366254,
    "name": "Ultrasound Technologies Ltd    ( Caldicot  Wales )",
    "country": "Wales"
  },
  {
    "id": 452267,
    "name": "Ultrasound Technologies Ltd (Deutschland)    ( Monchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 459503,
    "name": "UltraSPECT Inc    ( Auburndale MA USA )",
    "country": "United States"
  },
  {
    "id": 175999,
    "name": "Ultratec Inc    ( Madison WI USA )",
    "country": "United States"
  },
  {
    "id": 363235,
    "name": "UltraViolet Devices Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 306152,
    "name": "UltraVision International (Australia) Pty Ltd    ( Springwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 357616,
    "name": "UltraVision International Ltd    ( Leighton Buzzard  England )",
    "country": "UK"
  },
  {
    "id": 457813,
    "name": "Ultroid Technologies Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 999776,
    "name": "ULTRONICS OPTICAL    (   USA )",
    "country": "United States"
  },
  {
    "id": 101897,
    "name": "UMA Inc    ( Dayton VA USA )",
    "country": "United States"
  },
  {
    "id": 295948,
    "name": "Umedic GmbH    ( Moenchengladbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 463028,
    "name": "UMG/Del Medical    ( Harrison NY USA )",
    "country": "USA"
  },
  {
    "id": 108838,
    "name": "UMI International    ( East Rutherford NJ USA )",
    "country": "United States"
  },
  {
    "id": 170960,
    "name": "UMM Unites Medicales Mobiles SA    ( Collex/Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 416098,
    "name": "UMS-Neuromed AG    ( Castrop/Rauxel  USA )",
    "country": "United States"
  },
  {
    "id": 306794,
    "name": "Umschau Zeitschriftenverlag    ( Sulzbach im Taunus  Germany )",
    "country": "Germany"
  },
  {
    "id": 454756,
    "name": "Umsinsi Health Care (Pty) Ltd    ( Midrand  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 161074,
    "name": "Unbescheiden GmbH    ( Baden-Baden  Germany )",
    "country": "Germany"
  },
  {
    "id": 989656,
    "name": "UNDERWRITER LABORATORIES    (   USA )",
    "country": "United States"
  },
  {
    "id": 154140,
    "name": "Unetixs Inc    ( North Kingstown RI USA )",
    "country": "United States"
  },
  {
    "id": 176917,
    "name": "Unfors Instruments AB    ( Billdal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 439884,
    "name": "Unfors Instruments GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 361950,
    "name": "Unfors Instruments Inc    ( New Milford CT USA )",
    "country": "United States"
  },
  {
    "id": 453910,
    "name": "Unfors Instruments Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 453962,
    "name": "Unfors Instruments Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 457016,
    "name": "Unfors RaySafe AB    ( Billdal  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 457017,
    "name": "Unfors RaySafe GmbH    ( Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 457018,
    "name": "Unfors RaySafe Inc    ( Hopkinton MA USA )",
    "country": "United States"
  },
  {
    "id": 457019,
    "name": "Unfors RaySafe Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 306102,
    "name": "Uni-Ject Australia Pty Ltd    ( Seaford  Australia )",
    "country": "Australia"
  },
  {
    "id": 161075,
    "name": "Uni-Quatro Fotodokumentationssysteme GmbH    ( Tuerkenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 454617,
    "name": "UNI/CARE Systems Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 452567,
    "name": "Unibased Systems Architecture Inc    ( Chesterfield MO USA )",
    "country": "United States"
  },
  {
    "id": 456477,
    "name": "Unicable Inc    ( Bowling Green KY USA )",
    "country": "United States"
  },
  {
    "id": 283362,
    "name": "Unicam Analytische Systeme GmbH    ( Kassel  Germany )",
    "country": "Germany"
  },
  {
    "id": 460767,
    "name": "Unicam Systems    ( Chard  England )",
    "country": "UK"
  },
  {
    "id": 108839,
    "name": "UNICARE HEALTH AND FITNESS    (   USA )",
    "country": "United States"
  },
  {
    "id": 188122,
    "name": "Unicare Medical Services Ltd    ( Harlow  England )",
    "country": "UK"
  },
  {
    "id": 460661,
    "name": "Unication Inc    ( Arlington TX USA )",
    "country": "United States"
  },
  {
    "id": 459499,
    "name": "Unicell Inc    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 450508,
    "name": "UniChem plc    ( Chessington  England )",
    "country": "UK"
  },
  {
    "id": 399032,
    "name": "UNICO United Products and Instruments Inc    ( Dayton NJ USA )",
    "country": "United States"
  },
  {
    "id": 234634,
    "name": "Unicomp Corp of America    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 293264,
    "name": "Unident SA    ( Chene-Bourg  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456369,
    "name": "UNIDUS Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 284348,
    "name": "Uniequip Laborgeraetebau & Vertriebs GmbH    ( Martinsried  Germany )",
    "country": "Germany"
  },
  {
    "id": 460531,
    "name": "Unified Brands    ( Jackson MS USA )",
    "country": "United States"
  },
  {
    "id": 107662,
    "name": "Uniflex Inc    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 340003,
    "name": "UniFold Shelters Ltd    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 456506,
    "name": "Uniform Advantage    ( Pantation FL USA )",
    "country": "United States"
  },
  {
    "id": 329812,
    "name": "Uniform Data System for Medical Rehabilitation    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 460698,
    "name": "Unify GmbH & Co KG    ( Munchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454085,
    "name": "Unihoist Ltd    ( Eynsham  England )",
    "country": "UK"
  },
  {
    "id": 456216,
    "name": "Unik Surgical Sutures Mfg Co    ( Taipei Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 101821,
    "name": "Unilab Inc    ( Hillside NJ USA )",
    "country": "United States"
  },
  {
    "id": 175018,
    "name": "Unilens Corp USA    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 146824,
    "name": "Uniline Equipment Co    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 185578,
    "name": "Unimasz Zaklad Urzadzen Technologicznych    ( Olsztyn  Poland )",
    "country": "Poland"
  },
  {
    "id": 456281,
    "name": "Unimax Medical Systems Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 293466,
    "name": "Unimed SA    ( Lausanne  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 457484,
    "name": "unimed Surgical Products Inc    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 458861,
    "name": "Union Biometrica    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 104711,
    "name": "Union Carbide Corp Cryogenic Equipment Div    (   USA )",
    "country": "United States"
  },
  {
    "id": 456258,
    "name": "UNION MEDICAL Co Ltd    ( Uijeonbu  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 157054,
    "name": "Union Medical Engineering Co Ltd    ( Kyunggi-do  USA )",
    "country": "United States"
  },
  {
    "id": 382831,
    "name": "Union Medical USA    ( Orange FL USA )",
    "country": "United States"
  },
  {
    "id": 452871,
    "name": "Unipath Diagnostics GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 150378,
    "name": "Unipath Ltd    ( Bedford  England )",
    "country": "UK"
  },
  {
    "id": 452872,
    "name": "Unipath Scandinavia AB    ( Lund  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 172963,
    "name": "Unipower Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 456282,
    "name": "Unique Safety Equipment Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 175019,
    "name": "Unique Sports Products Inc    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 371794,
    "name": "UniQuest Inc    ( Joliet IL USA )",
    "country": "United States"
  },
  {
    "id": 461419,
    "name": "Unisense A/S    ( Aarhus  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 458963,
    "name": "Unisense Fertilitech A/S    ( Aarhus  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 278789,
    "name": "UNISIS Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 455998,
    "name": "Unisis Corp Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 278790,
    "name": "Unisol Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 102931,
    "name": "Unisplint Wall Medical    ( Buford GA USA )",
    "country": "United States"
  },
  {
    "id": 470543,
    "name": "Unistrip Technologies LLC    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 454212,
    "name": "UNISURGE International Ltd    ( Cambridge  England )",
    "country": "UK"
  },
  {
    "id": 454765,
    "name": "Unisyn Medical Technologies    ( Golden CO USA )",
    "country": "United States"
  },
  {
    "id": 461346,
    "name": "Unitech Electronics Co Ltd    ( New Taipei City  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457212,
    "name": "Unitech Medical    ( Hwaseong  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 105602,
    "name": "United Ad Label Co    ( Amherst NY USA )",
    "country": "United States"
  },
  {
    "id": 103583,
    "name": "United Air Specialists Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 106892,
    "name": "United Bio-Medical Inc    ( Andover KS USA )",
    "country": "United States"
  },
  {
    "id": 170876,
    "name": "United Biomedical Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 457869,
    "name": "United Biomedical Services    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 333614,
    "name": "United Biotech Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 391358,
    "name": "United Chair    ( St Pie PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 462673,
    "name": "United Chemical Technologies (UTC) Inc    ( Bristol PA USA )",
    "country": "United States"
  },
  {
    "id": 465007,
    "name": "United Data Technologies    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 363522,
    "name": "United Diagnostics Industry (UDI)    ( Riyadh  Saudi Arabia )",
    "country": "Saudi Arabia"
  },
  {
    "id": 102678,
    "name": "United Electric Controls Co    ( Watertown MA USA )",
    "country": "United States"
  },
  {
    "id": 402214,
    "name": "United Endoscopy    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 458611,
    "name": "United Hayek Medical    ( London  England )",
    "country": "UK"
  },
  {
    "id": 466262,
    "name": "USNewSound LLC    ( Frederick MD USA )",
    "country": "United States"
  },
  {
    "id": 102932,
    "name": "United Hospital Supply Corp    ( Burlington NJ USA )",
    "country": "United States"
  },
  {
    "id": 462618,
    "name": "United Industries    ( Beloit WI USA )",
    "country": "United States"
  },
  {
    "id": 108528,
    "name": "United Learning (Altschul Group Corp)    ( Evanston IL USA )",
    "country": "United States"
  },
  {
    "id": 398058,
    "name": "United Medical Equipment Corp    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 439938,
    "name": "United Medical Instruments (UMI)    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 471753,
    "name": "United Medical Products Inc    ( Murrysville PA USA )",
    "country": "United States"
  },
  {
    "id": 287706,
    "name": "United Medical Surgical Suppliers    ( Aleppo  Syria )",
    "country": "Syria"
  },
  {
    "id": 332382,
    "name": "United Medical Technologies Corp    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 104060,
    "name": "United Metal Fabricators Inc    ( Johnstown PA USA )",
    "country": "United States"
  },
  {
    "id": 418189,
    "name": "United Radiology Systems Inc    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 300130,
    "name": "United Receptacle    ( Pottsville PA USA )",
    "country": "United States"
  },
  {
    "id": 457708,
    "name": "United Scopes LLC (AmScope)    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 340338,
    "name": "United States Dosimetry Technology Inc    ( Richland WA USA )",
    "country": "United States"
  },
  {
    "id": 102189,
    "name": "United States Surgical    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 440378,
    "name": "United States Surgical/Syneture    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 452924,
    "name": "United States Welding Inc    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 177271,
    "name": "United Udyog    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 451194,
    "name": "UnitedPharma    ( Duluth GA USA )",
    "country": "United States"
  },
  {
    "id": 460669,
    "name": "Unitex Textile Rental Services Inc    ( Mt Vernon NY USA )",
    "country": "United States"
  },
  {
    "id": 185579,
    "name": "Unitra-Unima Zaklady Maszyn i Urzadzen Technologic    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 403317,
    "name": "Unitron Hearing GmbH    ( Fellbach-Oeffingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 104356,
    "name": "Unitron Hearing International    ( Kitchener ON Canada )",
    "country": "Canada"
  },
  {
    "id": 442184,
    "name": "Unitron Hearing Netherlands    ( Nieuwegein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 442185,
    "name": "Unitron Hearing UK    ( Warrington  England )",
    "country": "UK"
  },
  {
    "id": 402923,
    "name": "Unitron Hearing USA    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 103148,
    "name": "Unitron Ltd    ( Commack NY USA )",
    "country": "United States"
  },
  {
    "id": 332342,
    "name": "UNITRONIC    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 458482,
    "name": "Unity Scientific    ( Brookfield CT USA )",
    "country": "United States"
  },
  {
    "id": 358503,
    "name": "Univec Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 441579,
    "name": "Universal Air Filter Co    ( Sauget IL USA )",
    "country": "United States"
  },
  {
    "id": 462620,
    "name": "Universal Companies Inc    ( Abingdon VA USA )",
    "country": "United States"
  },
  {
    "id": 454618,
    "name": "Universal EMR Solutions    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 999961,
    "name": "Universal Enterprises, Inc.    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 356843,
    "name": "Universal Eye Care Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 106341,
    "name": "Universal Hospital Services Inc    ( Edina MN USA )",
    "country": "United States"
  },
  {
    "id": 310391,
    "name": "Universal Hospital Services Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 441549,
    "name": "Universal Hospital Supplies Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 456361,
    "name": "Universal Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455615,
    "name": "Universal Industrial Gases Inc    ( Easton PA USA )",
    "country": "United States"
  },
  {
    "id": 460664,
    "name": "Universal Linen Service    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 457755,
    "name": "Universal Medical Inc    ( Norwood MA USA )",
    "country": "United States"
  },
  {
    "id": 440733,
    "name": "Universal Medical Products    ( Lincoln NE USA )",
    "country": "United States"
  },
  {
    "id": 332392,
    "name": "Universal Medical Resources Inc    ( Washington MO USA )",
    "country": "United States"
  },
  {
    "id": 108105,
    "name": "Universal Medical Services Inc    ( Beaver Falls PA USA )",
    "country": "United States"
  },
  {
    "id": 106342,
    "name": "Universal Medical Systems Inc    ( Bedford Hills NY USA )",
    "country": "United States"
  },
  {
    "id": 346997,
    "name": "Universal Medical Technologies Inc    ( Scottsdale AZ USA )",
    "country": "United States"
  },
  {
    "id": 459513,
    "name": "Universal Ophthalmic Instruments Inc    ( Spring TX USA )",
    "country": "United States"
  },
  {
    "id": 298475,
    "name": "Universal Productora SA de CV    ( Cd de Mexico  Mexico )",
    "country": "Mexico"
  },
  {
    "id": 340339,
    "name": "Universal Shielding Corp    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 454619,
    "name": "Universal Software Solutions Inc    ( Davison MI USA )",
    "country": "United States"
  },
  {
    "id": 146846,
    "name": "Universal Systems Inc    ( Solon OH USA )",
    "country": "United States"
  },
  {
    "id": 105471,
    "name": "Universal Voltronics    ( Brookfield CT USA )",
    "country": "United States"
  },
  {
    "id": 174756,
    "name": "Universal X-Ray Co of Canada Ltd    ( Dorval PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 462892,
    "name": "University of Illinois    ( Champaign IL USA )",
    "country": "United States"
  },
  {
    "id": 456870,
    "name": "University of Miami Tissue Bank    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 322495,
    "name": "Universo Plastique SA    ( La Chaux-de-Fonds  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 459512,
    "name": "Uniweb Inc    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 458165,
    "name": "UnoClean    ( Hampshire IL USA )",
    "country": "United States"
  },
  {
    "id": 431355,
    "name": "Unomedical A/S    ( Lynge  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 452423,
    "name": "Unomedical A/S    ( Birkerod  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 431354,
    "name": "Unomedical Inc    ( McAllen TX USA )",
    "country": "United States"
  },
  {
    "id": 450273,
    "name": "Unomedical Ltd    ( Redditch  England )",
    "country": "UK"
  },
  {
    "id": 450276,
    "name": "Unomedical Pty Ltd    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 452424,
    "name": "Unomedical Sdn Bhd    ( Sungai Petani  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 456513,
    "name": "Unomedical Sdn Bhd    ( Sungai Petani  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 232379,
    "name": "UnoPlast A/S    ( Hundested  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 337997,
    "name": "Unor AG    ( Schlieren/Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 441052,
    "name": "UO Equipment Co    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 999112,
    "name": "Up & Up (Target)    (   USA )",
    "country": "United States"
  },
  {
    "id": 372416,
    "name": "Uplift Technologies Inc    ( Dartmouth NS Canada )",
    "country": "Canada"
  },
  {
    "id": 452921,
    "name": "Upstate New York Transplant Services Inc    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 457605,
    "name": "UptakeMedical    ( Tustin CA USA )",
    "country": "United States"
  },
  {
    "id": 451827,
    "name": "Uptime Business Products    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 460609,
    "name": "UpToDate Inc    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 466620,
    "name": "Uptronix Inc    ( Woodstock GA USA )",
    "country": "United States"
  },
  {
    "id": 398062,
    "name": "Upwards Biosystmes Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 999440,
    "name": "Urawa Corporation    ( Kuki, Saitama  Japan )",
    "country": "Japan"
  },
  {
    "id": 108551,
    "name": "UreSil LP    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 455835,
    "name": "Uriel-Meditex Ltd    ( Nesher  Israel )",
    "country": "Israel"
  },
  {
    "id": 454637,
    "name": "URIT Medical Electronic Group Co Ltd    ( Guilin  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 398205,
    "name": "Uro Power LLC    ( Naples FL USA )",
    "country": "United States"
  },
  {
    "id": 456046,
    "name": "Uro Technology Sdn Bhd    ( Pontian  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 401427,
    "name": "Uroan 21 SL    ( Palma de Mallorca  Spain )",
    "country": "Spain"
  },
  {
    "id": 103616,
    "name": "Urocare Products Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 289385,
    "name": "Urologix Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 417692,
    "name": "UROMED Kurt Drews GmbH    ( Oststeinbek  Germany )",
    "country": "Germany"
  },
  {
    "id": 456740,
    "name": "Uroplasty Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 460865,
    "name": "Urosource Mobile Med Solutions    ( Pampa TX USA )",
    "country": "United States"
  },
  {
    "id": 385358,
    "name": "Urosurge Medical (Pty) Ltd    ( Johannesburg  USA )",
    "country": "United States"
  },
  {
    "id": 452568,
    "name": "Urotech Devices    ( Mysore  India )",
    "country": "India"
  },
  {
    "id": 333455,
    "name": "UROTECH Medizinische Technologie GmbH    ( Achenmuehle  Germany )",
    "country": "Germany"
  },
  {
    "id": 457828,
    "name": "uroVision GmbH    ( Achenmuehle  Germany )",
    "country": "Germany"
  },
  {
    "id": 325643,
    "name": "US Abilities Inc    ( Philo IL USA )",
    "country": "United States"
  },
  {
    "id": 346085,
    "name": "US Bag    ( Talladega AL USA )",
    "country": "United States"
  },
  {
    "id": 107499,
    "name": "US Coach Co    ( Buffalo Gap TX USA )",
    "country": "United States"
  },
  {
    "id": 428588,
    "name": "US Cotton LLC    ( Rio Rancho NM USA )",
    "country": "United States"
  },
  {
    "id": 457196,
    "name": "US Cryotherapy    ( Roseville CA USA )",
    "country": "United States"
  },
  {
    "id": 358378,
    "name": "US Endo Inc    ( Addison IL USA )",
    "country": "United States"
  },
  {
    "id": 332760,
    "name": "US Endoscopy    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 450809,
    "name": "US Filter Water Process Inc    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 464996,
    "name": "US Foods, Inc    ( Rosemont 93 USA )",
    "country": "United States"
  },
  {
    "id": 474863,
    "name": "US Healthcare Inc    ( Ankara  Turkey )",
    "country": "Turkey"
  },
  {
    "id": 457245,
    "name": "US Imaging Inc    ( Marietta GA USA )",
    "country": "United States"
  },
  {
    "id": 106113,
    "name": "US IMAGING TABLES    (   USA )",
    "country": "United States"
  },
  {
    "id": 136748,
    "name": "US Instruments Inc    ( Lanoka Harbor NJ USA )",
    "country": "United States"
  },
  {
    "id": 376042,
    "name": "US IOL Inc    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 390937,
    "name": "US Medical    ( Raleigh NC USA )",
    "country": "United States"
  },
  {
    "id": 162423,
    "name": "US Medical Inc    ( Hingham MA USA )",
    "country": "United States"
  },
  {
    "id": 458418,
    "name": "US Medical Innovations LLC    ( Takoma Park MD USA )",
    "country": "United States"
  },
  {
    "id": 452206,
    "name": "US Medical Instruments Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 183949,
    "name": "US Medical Resources Corp    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 455233,
    "name": "US Medical Systems    ( Fort Mill SC USA )",
    "country": "United States"
  },
  {
    "id": 101364,
    "name": "US Optical Frame Co    ( Coral Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 151385,
    "name": "US Orthotics Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 153019,
    "name": "US Safety    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 329778,
    "name": "US Summit Corp    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 292783,
    "name": "US Technologies Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 462702,
    "name": "US Tele-Medicine    ( Beverly Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 451675,
    "name": "US Tissue and Cell (USTC)    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 439934,
    "name": "US X-Ray Sales    ( Punta Gorda FL USA )",
    "country": "United States"
  },
  {
    "id": 330937,
    "name": "USA Instruments Inc    ( Aurora OH USA )",
    "country": "United States"
  },
  {
    "id": 375035,
    "name": "USA Medical Inc    ( Colwyn PA USA )",
    "country": "United States"
  },
  {
    "id": 332397,
    "name": "USA Medical International Inc    ( Poulsbo WA USA )",
    "country": "United States"
  },
  {
    "id": 461485,
    "name": "USA Mobility    ( Springfield VA USA )",
    "country": "United States"
  },
  {
    "id": 169863,
    "name": "USA Scientific Inc    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 454344,
    "name": "Uscom    ( Sydney  Australia )",
    "country": "Australia"
  },
  {
    "id": 107829,
    "name": "USECO    ( Smyrna TN USA )",
    "country": "United States"
  },
  {
    "id": 394974,
    "name": "USF Deutschland GmbH    ( Ransbach/Baumbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 178880,
    "name": "USFilter    ( Warrendale PA USA )",
    "country": "United States"
  },
  {
    "id": 462637,
    "name": "USGI Medical    ( San Clemente CA USA )",
    "country": "United States"
  },
  {
    "id": 107496,
    "name": "USI Environmental Reclamation Ltd    ( West Bend WI USA )",
    "country": "United States"
  },
  {
    "id": 461443,
    "name": "USMed-Equip    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 161078,
    "name": "Ustomed Instrumente    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103373,
    "name": "Utah Medical Products Inc    ( Midvale UT USA )",
    "country": "United States"
  },
  {
    "id": 328000,
    "name": "Utah Medical Products Ltd    ( Athlone  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 466949,
    "name": "UTC Building & Industrial Systems    ( Farmington CT USA )",
    "country": "United States"
  },
  {
    "id": 462565,
    "name": "UTC Fire & Security UK Ltd    ( Milton Keynes  England )",
    "country": "UK"
  },
  {
    "id": 105230,
    "name": "Uti-Tect Inc    ( Waukegan IL USA )",
    "country": "United States"
  },
  {
    "id": 456496,
    "name": "UV-Vis Metrolab SA    ( Buenos Aires  Argentina )",
    "country": "Argentina"
  },
  {
    "id": 158772,
    "name": "Uvatec Inc    ( Sherman Oaks CA USA )",
    "country": "United States"
  },
  {
    "id": 441049,
    "name": "UVBioTek    ( North Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 177461,
    "name": "Uvex Arbeitsschutz GmbH    ( Fuerth  Germany )",
    "country": "Germany"
  },
  {
    "id": 281903,
    "name": "UVEX Arbeitsschutz GmbH    ( Fuerth  Germany )",
    "country": "Germany"
  },
  {
    "id": 291041,
    "name": "Uvex Austria    ( Wels  Austria )",
    "country": "Austria"
  },
  {
    "id": 106511,
    "name": "UVEX Safety    ( Smithfield RI USA )",
    "country": "United States"
  },
  {
    "id": 102677,
    "name": "UVP Inc    ( Upland CA USA )",
    "country": "United States"
  },
  {
    "id": 999905,
    "name": "UWE    (   USA )",
    "country": "United States"
  },
  {
    "id": 280958,
    "name": "Uwe Beck Rollstuhlcenter    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 458542,
    "name": "V & P Scientific Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 175642,
    "name": "V E Ralph & Son Inc    ( Kearny NJ USA )",
    "country": "United States"
  },
  {
    "id": 440718,
    "name": "V Guldmann A/S    ( Aarhus N  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 440725,
    "name": "V Guldmann A/S Italy    ( Castelvecchio RB (SV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 440903,
    "name": "V Med Supply    ( Freeport NY USA )",
    "country": "United States"
  },
  {
    "id": 108952,
    "name": "V-Tech Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 272616,
    "name": "V.M.K Ltd    ( Praha 9  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 452793,
    "name": "VacSax Ltd    ( Plymouth  England )",
    "country": "UK"
  },
  {
    "id": 103928,
    "name": "VacuMed    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 248764,
    "name": "VacuTec Messtechnik GmbH    ( Dresden  Germany )",
    "country": "Germany"
  },
  {
    "id": 456362,
    "name": "Vadi Medical Technology Co Ltd    ( Taoyuan Hsien  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 454675,
    "name": "VAHY RADWAG    ( Sumperk  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 292213,
    "name": "Vaisala GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 107500,
    "name": "Vaisala Inc    ( Woburn MA USA )",
    "country": "United States"
  },
  {
    "id": 186307,
    "name": "Vaisala KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 337829,
    "name": "Vaisala Ltd    ( Birmingham  England )",
    "country": "UK"
  },
  {
    "id": 137966,
    "name": "Vaisala Oy    ( Vantaa  Finland )",
    "country": "Finland"
  },
  {
    "id": 356269,
    "name": "Vaisala Oyj (Beijing)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 292128,
    "name": "Vaisala Oyj (Sweden)    ( Malmo  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 332228,
    "name": "Vaisala Pty Ltd    ( Hawthorn  Australia )",
    "country": "Australia"
  },
  {
    "id": 363786,
    "name": "Vaisala SA    ( St-Quentin-en-Yvelines Cedex  France )",
    "country": "France"
  },
  {
    "id": 457913,
    "name": "Val Jean Medical Inc    ( Chandler AZ USA )",
    "country": "United States"
  },
  {
    "id": 162373,
    "name": "Val Med Corp    ( New Orleans LA USA )",
    "country": "United States"
  },
  {
    "id": 461042,
    "name": "Valco Data Systems Inc    ( Menomonee Falls WI USA )",
    "country": "United States"
  },
  {
    "id": 461491,
    "name": "Valcom Inc    ( Roanoke VA USA )",
    "country": "United States"
  },
  {
    "id": 450514,
    "name": "Vale Care Shop    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 451428,
    "name": "Valeant Pharmaceuticals International    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 146849,
    "name": "Valencia Technical Services Inc    ( Las Vegas NV USA )",
    "country": "United States"
  },
  {
    "id": 306373,
    "name": "Valentine International Ltd/Dyna-Life    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456363,
    "name": "Valeo Corp    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 175673,
    "name": "Valeo Inc    ( Brookfield WI USA )",
    "country": "United States"
  },
  {
    "id": 463004,
    "name": "Valeritas Inc    ( Bridgewater NJ USA )",
    "country": "United States"
  },
  {
    "id": 106425,
    "name": "Valhalla Scientific    ( Poway CA USA )",
    "country": "United States"
  },
  {
    "id": 103929,
    "name": "Validyne Engineering Corp    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 292891,
    "name": "Valley Biomedical Services    ( Knoxville TN USA )",
    "country": "United States"
  },
  {
    "id": 103355,
    "name": "Valley Forge Scientific Corp    ( Oaks PA USA )",
    "country": "United States"
  },
  {
    "id": 107501,
    "name": "Valley Industrial Products    ( Huntington NY USA )",
    "country": "United States"
  },
  {
    "id": 376694,
    "name": "Valley National Gases Inc    ( Wheeling WV USA )",
    "country": "United States"
  },
  {
    "id": 101829,
    "name": "Valleylab    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 461014,
    "name": "Valpar International Corp    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 462629,
    "name": "Value Line Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 999757,
    "name": "Vammalan Konepaja    (   USA )",
    "country": "United States"
  },
  {
    "id": 459286,
    "name": "Van der Stahl Scientific Inc    ( Idyllwild CA USA )",
    "country": "United States"
  },
  {
    "id": 176041,
    "name": "Van Duerr Industries    ( Chico CA USA )",
    "country": "United States"
  },
  {
    "id": 456391,
    "name": "Van Note Associates    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 457723,
    "name": "van Raam    ( Varsseveld  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 329994,
    "name": "VanArsdale Innovative Products Inc    ( Pensacola FL USA )",
    "country": "United States"
  },
  {
    "id": 335301,
    "name": "VANCARE Inc    ( Aurora NE USA )",
    "country": "United States"
  },
  {
    "id": 105428,
    "name": "Vanderbilt Systems International    (   USA )",
    "country": "United States"
  },
  {
    "id": 461374,
    "name": "Vanderweil Engineers    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 452886,
    "name": "Vangard Voice Systems Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 152298,
    "name": "Vanguard Biomedical Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 104712,
    "name": "Vanguard Instrument Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 302698,
    "name": "Vanguard Medical Concepts Inc    ( Lakeland FL USA )",
    "country": "United States"
  },
  {
    "id": 462571,
    "name": "Vaniman Manufacturing Co    ( Fallbrook CA USA )",
    "country": "United States"
  },
  {
    "id": 456524,
    "name": "Vantage Endoscopy    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 340081,
    "name": "VantageMed Corp    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 999859,
    "name": "VAPORPRESS SCRUBEXCHANGE    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 382731,
    "name": "Vapotherm Inc    ( Stevensville MD USA )",
    "country": "United States"
  },
  {
    "id": 152773,
    "name": "Varian AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 360635,
    "name": "Varian Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 152772,
    "name": "Varian Medical Systems Australasia Pty Ltd    ( Frenchs Forest  Australia )",
    "country": "Australia"
  },
  {
    "id": 455439,
    "name": "Varian Medical Systems Canada Inc    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 161079,
    "name": "Varian Medical Systems Deutschland GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 363156,
    "name": "Varian Medical Systems Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 363973,
    "name": "Varian Medical Systems Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 405960,
    "name": "Varian Medical Systems Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 452238,
    "name": "Varian Medical Systems Inc    ( Charlottesville VA USA )",
    "country": "United States"
  },
  {
    "id": 182623,
    "name": "Varian Medical Systems Interay    ( North Charleston SC USA )",
    "country": "United States"
  },
  {
    "id": 363212,
    "name": "Varian Medical Systems International AG    ( Zug  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 435532,
    "name": "Varian Medical Systems UK Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 151416,
    "name": "Varian X-Ray Products    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 407330,
    "name": "Varian X-Ray Products Europe    ( Houten  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 407331,
    "name": "Varian X-Ray Products Far East    ( Toyko  Japan )",
    "country": "Japan"
  },
  {
    "id": 155353,
    "name": "Variomag USA    ( Daytona Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 170961,
    "name": "Variosonic AG    ( Rotkreuz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 284418,
    "name": "Varitec GmbH    ( Dillingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462657,
    "name": "VariTech Services    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 107899,
    "name": "Varitronics    ( Broomall PA USA )",
    "country": "United States"
  },
  {
    "id": 177932,
    "name": "Varodd Industrier A/S    ( Kristiansand  Norway )",
    "country": "Norway"
  },
  {
    "id": 452834,
    "name": "Varolab GmbH    ( Giesen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457357,
    "name": "Varolux GmbH & Co KG    ( Meitzendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 280328,
    "name": "Varolux Technik Geraetebau GmbH    ( Meitzendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 465015,
    "name": "Varrow Inc    ( Greensboro NC USA )",
    "country": "United States"
  },
  {
    "id": 449834,
    "name": "Vasamed    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 462675,
    "name": "Vasc-Alert    ( Lafayette IN USA )",
    "country": "United States"
  },
  {
    "id": 401523,
    "name": "Vasca Europe    ( Erkrath/Unterfeldhaus  USA )",
    "country": "United States"
  },
  {
    "id": 328604,
    "name": "Vasca Inc    ( Tewksbury MA USA )",
    "country": "United States"
  },
  {
    "id": 230812,
    "name": "Vascomed GmbH    ( Binzen  Germany )",
    "country": "Germany"
  },
  {
    "id": 273950,
    "name": "Vascor Medical Corp    ( Tarpon Springs FL USA )",
    "country": "United States"
  },
  {
    "id": 372648,
    "name": "Vascular Aquisition Corp    ( Lowell MA USA )",
    "country": "United States"
  },
  {
    "id": 392829,
    "name": "Vascular Architects Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 402556,
    "name": "Vascular Control Systems Inc    ( San Juan Capistrano CA USA )",
    "country": "United States"
  },
  {
    "id": 378090,
    "name": "Vascular Solutions Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 104916,
    "name": "Vascular Technology Inc (VTI)    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 470586,
    "name": "Vascular Transplant Services    ( Schaumburg IL USA )",
    "country": "United States"
  },
  {
    "id": 344576,
    "name": "Vascumed    ( Gent  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 418186,
    "name": "Vascutek Ltd    ( Inchinnan  Scotland )",
    "country": "Scotland"
  },
  {
    "id": 418187,
    "name": "Vascutek USA Inc    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 457799,
    "name": "Vaseline    ( Leatherhead  England )",
    "country": "UK"
  },
  {
    "id": 451624,
    "name": "VASI    ( Pickering ON Canada )",
    "country": "Canada"
  },
  {
    "id": 321871,
    "name": "Vasomedical Inc    ( Westbury NY USA )",
    "country": "United States"
  },
  {
    "id": 458057,
    "name": "Vasorum Ltd    ( Dublin 24  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 152533,
    "name": "Vassilli srl    ( Saonara (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457756,
    "name": "VasSol Inc    ( River Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 455007,
    "name": "Vatech America Inc    ( Secaucus NJ USA )",
    "country": "United States"
  },
  {
    "id": 455476,
    "name": "Vaudaux-Eppendorf AG    ( Schoenenbuch/Basel  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 280432,
    "name": "Vauth-Sagel GmbH & Co    ( Brakel-Erkeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 320838,
    "name": "VAX-D Medical Technologies LLC    ( Oldsmar FL USA )",
    "country": "United States"
  },
  {
    "id": 366526,
    "name": "VayTek Inc    ( Fairfield IA USA )",
    "country": "United States"
  },
  {
    "id": 393192,
    "name": "VBM France    ( Alleins  France )",
    "country": "France"
  },
  {
    "id": 393193,
    "name": "VBM Lekaoska technika spol sro    ( Kurim  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 392887,
    "name": "VBM Medical Inc    ( Noblesville IN USA )",
    "country": "United States"
  },
  {
    "id": 172386,
    "name": "VBM Medizintechnik GmbH    ( Sulz am Neckar  Germany )",
    "country": "Germany"
  },
  {
    "id": 461493,
    "name": "VBrick Systems Inc    ( Herndon VA USA )",
    "country": "United States"
  },
  {
    "id": 454169,
    "name": "VDW GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 471638,
    "name": "VE Ralph & Son Inc    ( Kearny NJ USA )",
    "country": "United States"
  },
  {
    "id": 455526,
    "name": "Veatch Ophthalmic Instruments    ( Tempe AZ USA )",
    "country": "United States"
  },
  {
    "id": 178275,
    "name": "Veba MediTemp bv    ( Uden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 461343,
    "name": "Vecna Technologies Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 174434,
    "name": "Veco Do Brasil Industria e Comercio de Equipamento    ( Campinas-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 103149,
    "name": "Vector Electronics & Technology Inc    ( North Hollywood CA USA )",
    "country": "United States"
  },
  {
    "id": 175028,
    "name": "VectorVision Inc    ( Greenville OH USA )",
    "country": "United States"
  },
  {
    "id": 103930,
    "name": "Vectra International Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 474901,
    "name": "VEDALAB    ( Cerise  France )",
    "country": "France"
  },
  {
    "id": 461333,
    "name": "Vedant Inc    ( Newburyport MA USA )",
    "country": "United States"
  },
  {
    "id": 401179,
    "name": "VEE GEE Scientific Inc    ( Kirkland WA USA )",
    "country": "United States"
  },
  {
    "id": 462916,
    "name": "Veeco    ( Plainview NY USA )",
    "country": "United States"
  },
  {
    "id": 151075,
    "name": "Veenstra Instrumenten bv    ( Joure  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 450482,
    "name": "Veenstra Instruments Germany    ( Neustadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 431331,
    "name": "Veetronix Inc    ( Lexington NE USA )",
    "country": "United States"
  },
  {
    "id": 456384,
    "name": "Vega Technologies Inc    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457361,
    "name": "Veigel GmbH & Co KG    ( Kuenzelsau  Germany )",
    "country": "Germany"
  },
  {
    "id": 999995,
    "name": "Veinlite    ( Sugarland TX USA )",
    "country": "United States"
  },
  {
    "id": 461030,
    "name": "Veinwave Ltd    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 466496,
    "name": "Vela Diagnostics    ( Fairfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 105850,
    "name": "Velcro USA Inc    ( Manchester NH USA )",
    "country": "United States"
  },
  {
    "id": 151293,
    "name": "Veldink Rolstoeltechniek bv    ( Nieuw Buinen  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 456735,
    "name": "VELMED Inc    ( Gibsonia PA USA )",
    "country": "United States"
  },
  {
    "id": 459321,
    "name": "Velocity Medical Solutions    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 458736,
    "name": "Velos Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 271364,
    "name": "Velp Scientifica srl    ( Usmate (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 457826,
    "name": "Veltek Assoc Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 107503,
    "name": "Venco/Venturo Mfg Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 453818,
    "name": "Vencon Technologies    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 337505,
    "name": "Venetec International Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 458138,
    "name": "Venner Medical (Deutschland) GmbH    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 455658,
    "name": "Venner Medical (S) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 453612,
    "name": "Venner Medical (Switzerland)    ( Ecublens  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 453611,
    "name": "Venner Medical GmbH    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 466859,
    "name": "Venni Instruments Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 105727,
    "name": "Venodyne    (   USA )",
    "country": "United States"
  },
  {
    "id": 320684,
    "name": "Venosan North America Inc    ( Asheboro NC USA )",
    "country": "United States"
  },
  {
    "id": 402804,
    "name": "Venoscope LLC    ( Lafayette LA USA )",
    "country": "United States"
  },
  {
    "id": 416208,
    "name": "Ventana Japan KK    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 151620,
    "name": "Ventana Medical Systems Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 416212,
    "name": "Ventana Medical Systems Pty Ltd    ( Montmorency  Australia )",
    "country": "Australia"
  },
  {
    "id": 466302,
    "name": "Veritas Medical Solutions LLC    ( Harleysville PA USA )",
    "country": "United States"
  },
  {
    "id": 440167,
    "name": "Ventana Medical Systems SA    ( Illkirch Cedex  France )",
    "country": "France"
  },
  {
    "id": 335409,
    "name": "Ventex Fabrics    ( Great Falls VA USA )",
    "country": "United States"
  },
  {
    "id": 399139,
    "name": "Ventlab Corp    ( Mocksville NC USA )",
    "country": "United States"
  },
  {
    "id": 453804,
    "name": "Ventracor bv    ( Heesch  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 453800,
    "name": "Ventracor Inc    ( Foster City CA USA )",
    "country": "United States"
  },
  {
    "id": 415926,
    "name": "Ventracor Ltd    ( Chatswood  Australia )",
    "country": "Australia"
  },
  {
    "id": 450630,
    "name": "Venture Medical ReQuip    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 465138,
    "name": "Venture Technologies    ( Ridgeland 86 USA )",
    "country": "United States"
  },
  {
    "id": 455337,
    "name": "Ventus Medical Inc    ( Belmont CA USA )",
    "country": "United States"
  },
  {
    "id": 999334,
    "name": "Venus Concept    ( Toronto  USA )",
    "country": "United States"
  },
  {
    "id": 456617,
    "name": "Venus Vision Care Pvt Ltd    ( Ahmedabad  India )",
    "country": "India"
  },
  {
    "id": 462531,
    "name": "Veolia Environmental Solutions    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 450728,
    "name": "Vepro (SEA) Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 199545,
    "name": "Vepro AG    ( Pfungstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 450739,
    "name": "Vepro Benelux    ( Valbonne  France )",
    "country": "France"
  },
  {
    "id": 450740,
    "name": "Vepro Italy    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 462539,
    "name": "Ver-tech Inc    ( Rockford MN USA )",
    "country": "United States"
  },
  {
    "id": 458228,
    "name": "Veracity Group Inc    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 471749,
    "name": "Veracyte    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 454641,
    "name": "Veran Medical Technologies Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 462617,
    "name": "Verasoft    ( Detroit MI USA )",
    "country": "United States"
  },
  {
    "id": 451928,
    "name": "Verathon Inc    ( Bothell WA USA )",
    "country": "United States"
  },
  {
    "id": 452026,
    "name": "Verathon Medical (Canada) ULC    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 452028,
    "name": "Verathon Medical (Europe) bv    ( IJsselstein  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 452029,
    "name": "Verathon Medical (France) Sarl    ( Strasbourg Cedex  France )",
    "country": "France"
  },
  {
    "id": 452027,
    "name": "Verathon Medical (Japan) KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 452025,
    "name": "Verathon Medical (United Kingdom) Ltd    ( Aylesbury  England )",
    "country": "UK"
  },
  {
    "id": 280482,
    "name": "Verbandwattefabrik Breitenau GmbH    ( Oederan  Germany )",
    "country": "Germany"
  },
  {
    "id": 459432,
    "name": "Verdiem Corp    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 337871,
    "name": "Vereinigte Papierwarenfabriken GmbH    ( Feuchtwangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 459450,
    "name": "Verge Solutions LLC    ( Mt Pleasant SC USA )",
    "country": "United States"
  },
  {
    "id": 333525,
    "name": "Verichem Laboratories Inc    ( Providence RI USA )",
    "country": "United States"
  },
  {
    "id": 450937,
    "name": "VeriChip Corp Canada    ( Richmond BC USA )",
    "country": "United States"
  },
  {
    "id": 450938,
    "name": "VeriChip Corp USA    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 461441,
    "name": "Vericom Corp    ( Roswell GA USA )",
    "country": "United States"
  },
  {
    "id": 452331,
    "name": "Veridex LLC    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 462615,
    "name": "Veridiam    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 999338,
    "name": "Veridian Healthcare    ( Gurnee IL USA )",
    "country": "United States"
  },
  {
    "id": 182632,
    "name": "Veridien Corp    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 101835,
    "name": "Veriflo Corp    ( Richmond CA USA )",
    "country": "United States"
  },
  {
    "id": 106601,
    "name": "Verimed International Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 459041,
    "name": "Verint Systems Inc    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 462614,
    "name": "Verisys Corp    ( South Jordan UT USA )",
    "country": "United States"
  },
  {
    "id": 458656,
    "name": "VeriTeQ    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 456152,
    "name": "Verity Medical Ltd    ( Romsey  England )",
    "country": "UK"
  },
  {
    "id": 999867,
    "name": "Verizon    (   USA )",
    "country": "United States"
  },
  {
    "id": 466284,
    "name": "Verizon Terremark    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 409769,
    "name": "Vermed Inc    ( Bellows Falls VT USA )",
    "country": "United States"
  },
  {
    "id": 416161,
    "name": "Vermeiren Deutschland GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 416162,
    "name": "Vermeiren France SA    ( Avelin  France )",
    "country": "France"
  },
  {
    "id": 151047,
    "name": "Vermeiren NV    ( Kalmthout  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 416163,
    "name": "Vermeiren Suisse SA    ( Belp  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 177678,
    "name": "Vermeulen BVBA    ( Broechem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 321023,
    "name": "Vermont Composites Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 451668,
    "name": "Vermont Photonics Technologies Corp    ( Bellows Falls VT USA )",
    "country": "United States"
  },
  {
    "id": 161080,
    "name": "VERMOP Salmon GmbH    ( Gilching  Germany )",
    "country": "Germany"
  },
  {
    "id": 457463,
    "name": "Vernacare Canada Inc [CLONED]    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 156521,
    "name": "Vernacare Div Verna LTD    ( Bolton  England )",
    "country": "UK"
  },
  {
    "id": 999466,
    "name": "Vernitron    (   USA )",
    "country": "United States"
  },
  {
    "id": 158877,
    "name": "Vernon Carus (UK) Ltd    ( Preston  England )",
    "country": "UK"
  },
  {
    "id": 306046,
    "name": "Vernon Carus Australia Pty Ltd    ( Burwood  Australia )",
    "country": "Australia"
  },
  {
    "id": 382529,
    "name": "Vernon-Carus (Malta) Ltd    ( Kappara  Malta )",
    "country": "Malta"
  },
  {
    "id": 152458,
    "name": "Verre Et Quartz    ( Bussy-Saint-Georges  France )",
    "country": "France"
  },
  {
    "id": 340346,
    "name": "VersaForm Systems Corp    ( Campbell CA USA )",
    "country": "United States"
  },
  {
    "id": 462683,
    "name": "VersaIMAGE Software Corp    ( Brighton MI USA )",
    "country": "United States"
  },
  {
    "id": 449826,
    "name": "VersaMed Inc    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 378254,
    "name": "VersaMed Medical Systems Inc    ( Pearl River NY USA )",
    "country": "United States"
  },
  {
    "id": 449828,
    "name": "VersaMed Medical Systems Inc (Brazil)    ( Sao Paulo-SP  USA )",
    "country": "United States"
  },
  {
    "id": 449825,
    "name": "VersaMed Medical Systems Inc (China)    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 449827,
    "name": "VersaMed Medical Systems Inc (Egypt)    ( Cairo  Egypt )",
    "country": "Egypt"
  },
  {
    "id": 449830,
    "name": "VersaMed Medical Systems Inc (Peru)    ( Lima 17  Peru )",
    "country": "Peru"
  },
  {
    "id": 449824,
    "name": "VersaMed Medical Systems Inc (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 449829,
    "name": "VersaMed Medical Systems Inc (Venezuela)    ( Caracas  Venezuela )",
    "country": "Venezuela"
  },
  {
    "id": 465040,
    "name": "Versatile Information Products, Inc.    ( Riverside, 36 USA )",
    "country": "United States"
  },
  {
    "id": 460561,
    "name": "Versitec    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 332408,
    "name": "Versus Technology Inc    ( Traverse City MI USA )",
    "country": "United States"
  },
  {
    "id": 462680,
    "name": "VERT Fitness USA Inc    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 173400,
    "name": "Vertec Scientific Ltd    ( Reading  England )",
    "country": "UK"
  },
  {
    "id": 456566,
    "name": "Vertec Scientific SA (Pty) Ltd    ( Randburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 999917,
    "name": "Vertex Diagnostics    ( Baldwin NY USA )",
    "country": "United States"
  },
  {
    "id": 104572,
    "name": "Vertex Industries Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 336954,
    "name": "Vertex-Dental bv    ( Zeist  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 356823,
    "name": "Vertical Peripherals Inc    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 455373,
    "name": "Vertos Medical Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 454726,
    "name": "WetKeys LLC    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 462585,
    "name": "Vertual    ( Hull  England )",
    "country": "UK"
  },
  {
    "id": 453395,
    "name": "Verus Pharmaceuticals Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 456716,
    "name": "Veskimo Personal Cooling Systems    ( San Juan Capistrano CA USA )",
    "country": "United States"
  },
  {
    "id": 461332,
    "name": "Vess Chairs Inc    ( West Allis WI USA )",
    "country": "United States"
  },
  {
    "id": 162294,
    "name": "Vessa Ltd    ( Alton  England )",
    "country": "UK"
  },
  {
    "id": 170935,
    "name": "Vesta Inc    ( Franklin WI USA )",
    "country": "United States"
  },
  {
    "id": 464927,
    "name": "Vestil Manufacturing Corp    ( Angola IN USA )",
    "country": "United States"
  },
  {
    "id": 458657,
    "name": "VetEquip Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 999923,
    "name": "VetEquip, Inc.    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 999461,
    "name": "Vetland Medical Sales & Services LLC    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 293563,
    "name": "Vetter AG    ( Schaffhausen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455262,
    "name": "Vexim SAS    ( Balma  France )",
    "country": "France"
  },
  {
    "id": 462622,
    "name": "VFA Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 459284,
    "name": "VG Innovations LLC    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 454693,
    "name": "VG Medical Equipment Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 178839,
    "name": "VGA Ingenierie    ( Rivesaltes  France )",
    "country": "France"
  },
  {
    "id": 999647,
    "name": "VGo Communications, Inc.    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 177710,
    "name": "VI Instruments    ( Johannesburg  USA )",
    "country": "United States"
  },
  {
    "id": 262916,
    "name": "Via Medical    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 454531,
    "name": "Viable Med Services Inc    ( Santa Clarita CA USA )",
    "country": "United States"
  },
  {
    "id": 450593,
    "name": "ViaCell Inc    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 153295,
    "name": "Viamed Ltd    ( Keighley  England )",
    "country": "UK"
  },
  {
    "id": 451771,
    "name": "Vianeta Communications    ( Milpitas CA USA )",
    "country": "United States"
  },
  {
    "id": 462977,
    "name": "Viasonix    ( Yonkers NY USA )",
    "country": "United States"
  },
  {
    "id": 460773,
    "name": "Viasonix Ltd    ( Ra'anana  Israel )",
    "country": "Israel"
  },
  {
    "id": 451179,
    "name": "VIASYS Healthcare GmbH    ( Hoechberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 444110,
    "name": "VIASYS Healthcare Inc    ( Palm Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 417787,
    "name": "VIASYS MedSystems    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 451527,
    "name": "VIASYS/Pulmonetic Systems Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 408749,
    "name": "Viatronix Inc    ( Stony Brook NY USA )",
    "country": "United States"
  },
  {
    "id": 107504,
    "name": "Vibra-Whirl Ltd    ( Panhandle TX USA )",
    "country": "United States"
  },
  {
    "id": 453626,
    "name": "VIBRANT MED-EL Hearing Technology    ( Sophia-Antipolis Cedex  France )",
    "country": "France"
  },
  {
    "id": 428727,
    "name": "VIBRANT MED-EL Hearing Technology GmbH    ( Innsbruck  USA )",
    "country": "United States"
  },
  {
    "id": 456856,
    "name": "VibraTrim LLC    ( Gig Harbor WA USA )",
    "country": "United States"
  },
  {
    "id": 292715,
    "name": "VIC Hi-Tech Corp    ( Gardena CA USA )",
    "country": "United States"
  },
  {
    "id": 452631,
    "name": "Vica Medica SL    ( Paterna (Valencia)  Spain )",
    "country": "Spain"
  },
  {
    "id": 403460,
    "name": "VICI Metronic Inc    ( Poulsbo WA USA )",
    "country": "United States"
  },
  {
    "id": 105982,
    "name": "Vicon Industries Inc    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 451065,
    "name": "Vicon Peak Asia    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 451064,
    "name": "Vicon Peak UK    ( Oxford  England )",
    "country": "UK"
  },
  {
    "id": 451063,
    "name": "Vicon Peak US    ( Lake Forest CA USA )",
    "country": "United States"
  },
  {
    "id": 102417,
    "name": "Victor Dessberg    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 101993,
    "name": "Victor Medical Products    ( Denton TX USA )",
    "country": "United States"
  },
  {
    "id": 356825,
    "name": "Victory Medical Sales Inc    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 458854,
    "name": "Victory Refrigeration    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 999826,
    "name": "Victory Refrigeration, Inc.    ( Cherry Hill NJ USA )",
    "country": "United States"
  },
  {
    "id": 452511,
    "name": "Victus Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 179168,
    "name": "Vida Elettronica srl    ( Forli (FO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 452284,
    "name": "Vidacare Corp    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 150366,
    "name": "Vidar Systems Corp    ( Herndon VA USA )",
    "country": "United States"
  },
  {
    "id": 104714,
    "name": "Vidco Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 297994,
    "name": "Vident    ( Brea CA USA )",
    "country": "United States"
  },
  {
    "id": 106260,
    "name": "Video Display Corp    ( Tucker GA USA )",
    "country": "United States"
  },
  {
    "id": 332413,
    "name": "Video Instruments    ( Xenia OH USA )",
    "country": "United States"
  },
  {
    "id": 323857,
    "name": "Video South Ltd    ( Bath  England )",
    "country": "UK"
  },
  {
    "id": 458262,
    "name": "Video-Tech-Tronics    ( Comstock Park MI USA )",
    "country": "United States"
  },
  {
    "id": 461048,
    "name": "VideoLink    ( West Newton MA USA )",
    "country": "United States"
  },
  {
    "id": 465039,
    "name": "Videosonic Systems Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 234639,
    "name": "Videx Inc    ( Corvallis OR USA )",
    "country": "United States"
  },
  {
    "id": 458416,
    "name": "VidiStar LLC    ( Greenville SC USA )",
    "country": "United States"
  },
  {
    "id": 461396,
    "name": "Vidyo Inc    ( Hackensack NJ USA )",
    "country": "United States"
  },
  {
    "id": 283022,
    "name": "Viessmann Kaeltetechnik AG    ( Hof  Germany )",
    "country": "Germany"
  },
  {
    "id": 467059,
    "name": "ViewEra    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 999660,
    "name": "ViewEra    ( Walnut CA USA )",
    "country": "USA"
  },
  {
    "id": 457047,
    "name": "Vieworks Canada    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 457046,
    "name": "Vieworks Co Ltd    ( Seongnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 457956,
    "name": "ViewRay Inc    ( Oakwood Village OH USA )",
    "country": "United States"
  },
  {
    "id": 451167,
    "name": "ViewSend Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 999333,
    "name": "VIEWSONIC    (   USA )",
    "country": "United States"
  },
  {
    "id": 454261,
    "name": "ViewSonic Corp    ( Walnut CA USA )",
    "country": "United States"
  },
  {
    "id": 441634,
    "name": "Vigil Health Solutions Inc    ( Victoria BC Canada )",
    "country": "Canada"
  },
  {
    "id": 459425,
    "name": "VigiLanz Corp    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452664,
    "name": "Viglia srl    ( Torino (TO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456729,
    "name": "Viking Importers Ltd    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 322217,
    "name": "Vilex Inc    ( McMinnville TN USA )",
    "country": "United States"
  },
  {
    "id": 331981,
    "name": "Vilgo SA    ( Bergerac  France )",
    "country": "France"
  },
  {
    "id": 458132,
    "name": "Villa Radiology Systems LLC    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 453697,
    "name": "Villa Sistemi Medicali    ( Buccinasco (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 156442,
    "name": "Villa Sistemi Medicali SpA    ( Buccinasco (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 151035,
    "name": "Villard    ( Coulaines  France )",
    "country": "France"
  },
  {
    "id": 259207,
    "name": "Viltechmeda Ltd    ( Vilnius  USA )",
    "country": "United States"
  },
  {
    "id": 455584,
    "name": "VIMS SAS    ( Saint-Jean  France )",
    "country": "France"
  },
  {
    "id": 339152,
    "name": "Vincent Medical Mfg Co Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 281813,
    "name": "Vinzenz Sattler Chirurgische Instrumente    ( Fridingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 324129,
    "name": "Viomedex Ltd    ( Eastbourne  England )",
    "country": "UK"
  },
  {
    "id": 453062,
    "name": "ViOptix Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 460559,
    "name": "Viora Inc    ( Jersey City NJ USA )",
    "country": "United States"
  },
  {
    "id": 152453,
    "name": "Viotti Associados Industria Eletronica Ltda    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 456802,
    "name": "Viotti Equipamentos Medicos    ( Sao Paulo-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 457246,
    "name": "VIP Medical Inc    ( Alexandria VA USA )",
    "country": "United States"
  },
  {
    "id": 454620,
    "name": "VIP Medicine LLC    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 366886,
    "name": "VIP Systems Inc    ( Telford PA USA )",
    "country": "United States"
  },
  {
    "id": 451270,
    "name": "VIQ Solutions    ( Markham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 459092,
    "name": "Viracor-IBT Laboratories    ( Lee's Summit MO USA )",
    "country": "United States"
  },
  {
    "id": 459322,
    "name": "Virginia Water Systems Inc    ( Midlothian VA USA )",
    "country": "United States"
  },
  {
    "id": 186712,
    "name": "ViroStat Inc    ( Portland ME USA )",
    "country": "United States"
  },
  {
    "id": 455987,
    "name": "Virox Technologies Inc    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 462569,
    "name": "VirtaMed Inc    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 103936,
    "name": "VirTis    ( Gardiner NY USA )",
    "country": "United States"
  },
  {
    "id": 460657,
    "name": "Virtual I/O Solutions LLC    ( Alpharetta GA USA )",
    "country": "United States"
  },
  {
    "id": 441543,
    "name": "Virtual Imaging Inc    ( Deerfield Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 462552,
    "name": "Virtual Instruments Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 453868,
    "name": "Virtual Radiologic Corp    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 454458,
    "name": "VIRTUAL SimTECH    ( Galveston TX USA )",
    "country": "United States"
  },
  {
    "id": 334294,
    "name": "Virtual Software Systems    ( Bethel Park PA USA )",
    "country": "United States"
  },
  {
    "id": 450997,
    "name": "VirtuRad    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 413204,
    "name": "Virtus Inc    ( Batesville IN USA )",
    "country": "United States"
  },
  {
    "id": 413203,
    "name": "Virtus Ltd    ( Lucan  USA )",
    "country": "United States"
  },
  {
    "id": 458480,
    "name": "Vis-A-Vis Inc    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 105827,
    "name": "VisAcom Corp    ( Buchanan NY USA )",
    "country": "United States"
  },
  {
    "id": 452727,
    "name": "Visage Imaging    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 452725,
    "name": "Visage Imaging GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 325663,
    "name": "Viscolas Inc    ( Belleville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 103087,
    "name": "Viscot Medical LLC    ( East Hanover NJ USA )",
    "country": "United States"
  },
  {
    "id": 402683,
    "name": "Viseon Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 402697,
    "name": "Viseon Video Conferencing Asia Pacific    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 402696,
    "name": "Viseon Video Conferencing Europe    ( Henley-on-Thames  USA )",
    "country": "United States"
  },
  {
    "id": 456385,
    "name": "Visgeneer Inc    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 346052,
    "name": "Vishay Dale    ( Columbus NE USA )",
    "country": "United States"
  },
  {
    "id": 428612,
    "name": "Vishay Intertechnology Inc    ( Malvern PA USA )",
    "country": "United States"
  },
  {
    "id": 453562,
    "name": "VISICU Inc    ( Baltimore MD USA )",
    "country": "United States"
  },
  {
    "id": 457540,
    "name": "Visiomed SAS France    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 394587,
    "name": "Vision BioSystems (Europe) Ltd    ( Newcastle upon Tyne  USA )",
    "country": "United States"
  },
  {
    "id": 394586,
    "name": "Vision Biosystems Inc    ( Norwell MA USA )",
    "country": "United States"
  },
  {
    "id": 394585,
    "name": "Vision BioSystems Ltd    ( Mount Waverley  USA )",
    "country": "United States"
  },
  {
    "id": 458414,
    "name": "Vision Chips Inc    ( Laguna Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 465129,
    "name": "Vision Financial Group, Inc.    ( Pittsburgh 61 USA )",
    "country": "United States"
  },
  {
    "id": 451629,
    "name": "Vision Fitness    ( Cottage Grove WI USA )",
    "country": "United States"
  },
  {
    "id": 454241,
    "name": "Vision Light Tech    ( Uden  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 455368,
    "name": "Vision RT Inc    ( Atlantic Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 455369,
    "name": "Vision RT Ltd    ( London  England )",
    "country": "UK"
  },
  {
    "id": 155536,
    "name": "Vision Sciences Inc    ( Orangeburg NY USA )",
    "country": "United States"
  },
  {
    "id": 452665,
    "name": "Vitaid Ltd (Canada)    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 456370,
    "name": "Vision Scientific Co Ltd    ( Bucheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 462626,
    "name": "Vision Software Technologies Inc    ( Goodlettsville TN USA )",
    "country": "United States"
  },
  {
    "id": 175033,
    "name": "Vision Systems Inc    ( Westborough MA USA )",
    "country": "United States"
  },
  {
    "id": 457618,
    "name": "Vision Technology    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 107749,
    "name": "Vision Ten Inc    ( Carlstadt NJ USA )",
    "country": "United States"
  },
  {
    "id": 377484,
    "name": "Vision.net    ( Washington  USA )",
    "country": "United States"
  },
  {
    "id": 457624,
    "name": "VisionAid Technologies    ( Spalding  England )",
    "country": "UK"
  },
  {
    "id": 454623,
    "name": "Visionary Medical Systems Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 462546,
    "name": "Visioneer Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 455907,
    "name": "Visionsense Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 999810,
    "name": "Visionsense Corp    ( Oraneburg NY USA )",
    "country": "United States"
  },
  {
    "id": 455906,
    "name": "Visionsense Ltd    ( Petach Tikva  Israel )",
    "country": "Israel"
  },
  {
    "id": 465099,
    "name": "VisionTree Software Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457568,
    "name": "Visionworks of America Inc    ( San Antonio TX USA )",
    "country": "United States"
  },
  {
    "id": 356828,
    "name": "Visiplex Inc    ( Vernon Hills IL USA )",
    "country": "United States"
  },
  {
    "id": 178248,
    "name": "Visiplex Medical Systems (Europe) GmbH    ( Geilenkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458658,
    "name": "Visitech Systems Inc    ( Apex NC USA )",
    "country": "United States"
  },
  {
    "id": 461311,
    "name": "Visix Inc    ( Norcross GA USA )",
    "country": "United States"
  },
  {
    "id": 286543,
    "name": "Viso Medical GmbH    ( Sulzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 451470,
    "name": "Visonic Sicherheitstechnik GmbH    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 451472,
    "name": "Visonic Technologies Americas    ( Bloomfield CT USA )",
    "country": "United States"
  },
  {
    "id": 451471,
    "name": "Visonic Technologies LTd    ( Tel Aviv  Israel )",
    "country": "Israel"
  },
  {
    "id": 451469,
    "name": "Visonic Technologies UK    ( Beckenham  England )",
    "country": "UK"
  },
  {
    "id": 455393,
    "name": "Vista Dental    ( Racine WI USA )",
    "country": "United States"
  },
  {
    "id": 466967,
    "name": "Vista LifeSciences    ( Parker CO USA )",
    "country": "United States"
  },
  {
    "id": 102788,
    "name": "Vista Lighting Corp    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 460558,
    "name": "Vista Medical Ltd    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 999420,
    "name": "Vista Medical Ltd.    ( Winnipeg  Canada )",
    "country": "Canada"
  },
  {
    "id": 358165,
    "name": "Vista Optics Ltd    ( Widnes  England )",
    "country": "UK"
  },
  {
    "id": 108241,
    "name": "Vista Technology Inc    ( Edmonton AB Canada )",
    "country": "Canada"
  },
  {
    "id": 373915,
    "name": "VistaLab Technologies Inc    ( Brewster NY USA )",
    "country": "United States"
  },
  {
    "id": 461331,
    "name": "Visual Health Information    ( Tacoma WA USA )",
    "country": "United States"
  },
  {
    "id": 462563,
    "name": "Visual MED Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 458413,
    "name": "Visualase Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 250773,
    "name": "Visualization Technology Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 454684,
    "name": "VisualSonics bv    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 454683,
    "name": "VisualSonics Inc    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452853,
    "name": "Visus Technology Transfer GmbH    ( Bochum  Germany )",
    "country": "Germany"
  },
  {
    "id": 101840,
    "name": "Vita Needle Co Inc    ( Needham MA USA )",
    "country": "United States"
  },
  {
    "id": 279747,
    "name": "Vita Zahnfabrik H Rauter GmbH & Co KG    ( Bad Saeckingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457930,
    "name": "Vitabalans Oy    ( Haemeenlinna  Finland )",
    "country": "Finland"
  },
  {
    "id": 266180,
    "name": "Vitacare Inc    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 464451,
    "name": "Vitacon US LLC    ( Maple Plain MN USA )",
    "country": "United States"
  },
  {
    "id": 107922,
    "name": "Vitaid Ltd    ( Williamsville NY USA )",
    "country": "United States"
  },
  {
    "id": 101843,
    "name": "Vitaire Corp    ( Elmhurst Annex NY USA )",
    "country": "United States"
  },
  {
    "id": 450527,
    "name": "Vital Care Products Inc    ( Richfield OH USA )",
    "country": "United States"
  },
  {
    "id": 457775,
    "name": "Vital Care Reps Inc    ( Tinley Park IL USA )",
    "country": "United States"
  },
  {
    "id": 344907,
    "name": "Vital Concepts Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 183042,
    "name": "Vital Connections Inc    ( Tipp City OH USA )",
    "country": "United States"
  },
  {
    "id": 453997,
    "name": "Vital Diagnostics    ( Lincoln RI USA )",
    "country": "United States"
  },
  {
    "id": 331069,
    "name": "Vital Images Inc    ( Minnetonka MN USA )",
    "country": "United States"
  },
  {
    "id": 162920,
    "name": "Vital Scientific NV    ( Spankeren/Dieren  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 440716,
    "name": "Vital Signs (UK) Ltd    ( Littlehampton  England )",
    "country": "UK"
  },
  {
    "id": 101841,
    "name": "Vital Signs Inc    ( Totowa NJ USA )",
    "country": "United States"
  },
  {
    "id": 461479,
    "name": "Vital Skills International    ( Rochester Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 465052,
    "name": "Vital Visual Media    ( unknown un USA )",
    "country": "United States"
  },
  {
    "id": 391380,
    "name": "VitalAire GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999398,
    "name": "VitalCare Technology    ( Hixton TN USA )",
    "country": "United States"
  },
  {
    "id": 104041,
    "name": "Vitalcor Inc    ( Westmont IL USA )",
    "country": "United States"
  },
  {
    "id": 461329,
    "name": "VitalGo Systems    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 192848,
    "name": "Vitali srl    ( Castel Maggiore (BO)  Italy )",
    "country": "Italy"
  },
  {
    "id": 451721,
    "name": "Vitalino    ( Fanzeres (GDM)  USA )",
    "country": "United States"
  },
  {
    "id": 458366,
    "name": "Vitalitec International Inc    ( Plymouth MA USA )",
    "country": "United States"
  },
  {
    "id": 455599,
    "name": "Vitality Medical    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 282602,
    "name": "Vitalograph GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 101994,
    "name": "Vitalograph Inc    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 151100,
    "name": "Vitalograph Ltd    ( Buckingham  England )",
    "country": "UK"
  },
  {
    "id": 456386,
    "name": "Vitaltec Corp    ( Taichung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 287618,
    "name": "Vitalux Medizinische Einrichtungen GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452091,
    "name": "Vitalwear Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 455132,
    "name": "Vitaphone GmbH    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 284971,
    "name": "Vitaroll Behinderten-Hilfsmittel GmbH    ( Nieder-Olm  Germany )",
    "country": "Germany"
  },
  {
    "id": 460646,
    "name": "Vitas Healthcare Corp    ( Miami FL USA )",
    "country": "United States"
  },
  {
    "id": 456800,
    "name": "Vitatron Holding bv    ( Maastricht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 156805,
    "name": "Vitatron Nederland bv    ( Arnhem  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 256136,
    "name": "Vitatron USA    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 277933,
    "name": "Vitech Scientific Ltd    ( Partridge Green  England )",
    "country": "UK"
  },
  {
    "id": 335217,
    "name": "ViTel Net (Visual Telecommunications Network Inc)    ( McLean VA USA )",
    "country": "United States"
  },
  {
    "id": 461442,
    "name": "Vitera Healthcare Solutions LLC    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 458659,
    "name": "Viterion TeleHealthcare    ( Elmsford NY USA )",
    "country": "United States"
  },
  {
    "id": 453603,
    "name": "Vitrex Medical A/S    ( Herlev  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 456383,
    "name": "Vitrolife    ( Goeteborg  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 456382,
    "name": "Vitrolife Inc    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 287399,
    "name": "VITRONIC Dr-Ing Stein Bildverarbeitungssysteme Gmb    ( Wiesbaden  Germany )",
    "country": "Germany"
  },
  {
    "id": 435618,
    "name": "VITRONIC Machine Vision Ltd    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 306407,
    "name": "VIVA Diagnostika GmbH    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 462788,
    "name": "Walman Instrument Group    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 332624,
    "name": "Vivax Medical Corp    ( Torrington CT USA )",
    "country": "United States"
  },
  {
    "id": 451540,
    "name": "Vivek Malhotra    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 394975,
    "name": "Vivendi Water Systems    ( Saint-Maurice Cedex  France )",
    "country": "France"
  },
  {
    "id": 457695,
    "name": "Vivipar GmbH    ( Wuerzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 407194,
    "name": "VivoMetrics Inc    ( Ventura CA USA )",
    "country": "United States"
  },
  {
    "id": 461394,
    "name": "Vivonet Acquisition Ltd    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 407311,
    "name": "Vivosonic    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 474833,
    "name": "Vivostat A/S    ( Alleroed  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 104715,
    "name": "Viz Test Equipment    (   USA )",
    "country": "United States"
  },
  {
    "id": 999501,
    "name": "Vizio    (   USA )",
    "country": "United States"
  },
  {
    "id": 457641,
    "name": "Viztek LLC    ( Garner NC USA )",
    "country": "United States"
  },
  {
    "id": 999682,
    "name": "Viztek LLC.    ( Garner NC USA )",
    "country": "USA"
  },
  {
    "id": 293386,
    "name": "Vlesia AG    ( Goldach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 184689,
    "name": "VLV Associates Inc    ( East Hanover NJ USA )",
    "country": "United States"
  },
  {
    "id": 416108,
    "name": "Vmed Technology Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 440379,
    "name": "VMG Medical    ( Staunton VA USA )",
    "country": "United States"
  },
  {
    "id": 174435,
    "name": "VMI Industria e Comercio Ltda    ( Lagoa Santa-MG  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 461326,
    "name": "VMware Inc    ( Palo Alto CA USA )",
    "country": "United States"
  },
  {
    "id": 302751,
    "name": "VNUS Medical Technologies Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 459454,
    "name": "Voalte Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 451474,
    "name": "Vocera Communications Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 225066,
    "name": "Voco GmbH    ( Cuxhaven  Germany )",
    "country": "Germany"
  },
  {
    "id": 452957,
    "name": "Voelker AG    ( Witten  Germany )",
    "country": "Germany"
  },
  {
    "id": 283277,
    "name": "Voelker GmbH    ( Kaltenkirchen  USA )",
    "country": "United States"
  },
  {
    "id": 452954,
    "name": "Voelker Healthcare GB Ltd    ( Plympton  England )",
    "country": "UK"
  },
  {
    "id": 283726,
    "name": "Voemel Chirurg Nahtmaterial    ( Kronberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 467113,
    "name": "Vogel GmbH & Co KG    ( Fernwald  Germany )",
    "country": "Germany"
  },
  {
    "id": 281776,
    "name": "Vogelsberger Quarzglastechnik K D Kindl GmbH    ( Sonnen  Germany )",
    "country": "Germany"
  },
  {
    "id": 281197,
    "name": "Vogtland-Konfektion GmbH    ( Ellefeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 462631,
    "name": "Voice Print International (VPI) Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 466745,
    "name": "Voice Systems Inc    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 452885,
    "name": "Voicebrook Inc    ( Lake Success NY USA )",
    "country": "United States"
  },
  {
    "id": 456409,
    "name": "VoiceFactor/Physicians Computer Services Inc    ( Homosassa FL USA )",
    "country": "United States"
  },
  {
    "id": 460862,
    "name": "voiceTech    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 460791,
    "name": "Voicewave Technology Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 452385,
    "name": "Volcano Corp (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 440157,
    "name": "Volcano Therapeutics BV    ( Zeventem  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 440156,
    "name": "Volcano Therapeutics Inc    ( Rancho Cordova CA USA )",
    "country": "United States"
  },
  {
    "id": 107505,
    "name": "Volk Corp    ( Farmington Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 418530,
    "name": "Volk Optical Inc    ( Mentor OH USA )",
    "country": "United States"
  },
  {
    "id": 999786,
    "name": "Volker Healthcare (GB) Ltd    ( Plympton  UK )",
    "country": "United Kingdom"
  },
  {
    "id": 461467,
    "name": "VOLO LLC    ( Ormond Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 102685,
    "name": "Volu-Sol Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 452070,
    "name": "Volume Interactions    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 417212,
    "name": "von Berg-Medizingeraete GmbH    ( Zwoenitz  Germany )",
    "country": "Germany"
  },
  {
    "id": 281563,
    "name": "Von Birckhahn International Trading GmbH    ( Forst bei Bruchsal  Germany )",
    "country": "Germany"
  },
  {
    "id": 462632,
    "name": "Von Gahlen Nederland BV    ( Zevenaar  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 458555,
    "name": "Vonco Medical    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 454707,
    "name": "Vonex Medical Supplies Inc    ( Tottenham ON Canada )",
    "country": "Canada"
  },
  {
    "id": 461502,
    "name": "Vortex Industries Inc    ( South San Francisco CA USA )",
    "country": "United States"
  },
  {
    "id": 156127,
    "name": "Vortran Medical Technology 1 Inc    ( Sacramento CA USA )",
    "country": "United States"
  },
  {
    "id": 456257,
    "name": "VOTEM Co Ltd    ( Chuncheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452855,
    "name": "Voyager Imaging    ( East Hawthorn  Australia )",
    "country": "Australia"
  },
  {
    "id": 460933,
    "name": "Voyant Health    ( Petach Tiqwa  Israel )",
    "country": "Israel"
  },
  {
    "id": 107712,
    "name": "VPI    ( Spencer IN USA )",
    "country": "United States"
  },
  {
    "id": 103588,
    "name": "VPI LLC    ( Sheboygan WI USA )",
    "country": "United States"
  },
  {
    "id": 382734,
    "name": "VQ Co LLC    ( Cincinnati OH USA )",
    "country": "United States"
  },
  {
    "id": 417320,
    "name": "VQ OrthoCARE    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 999611,
    "name": "VRLOGIC GmbH    ( Dieburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 459060,
    "name": "VRmagic    ( Mannheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 999568,
    "name": "VRmagic Holding AG    ( Augustaanlage 32  Germany )",
    "country": "Germany"
  },
  {
    "id": 454962,
    "name": "Vross Wheelchairs Ltd    ( Liverpool  England )",
    "country": "UK"
  },
  {
    "id": 463012,
    "name": "VSee    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 374364,
    "name": "VSM MedTech Systems Inc    ( Coquitlam BC USA )",
    "country": "United States"
  },
  {
    "id": 999925,
    "name": "VSSI Inc.    ( Carthage MO USA )",
    "country": "United States"
  },
  {
    "id": 999465,
    "name": "VSSI, Inc.    ( Carthage MO USA )",
    "country": "United States"
  },
  {
    "id": 234648,
    "name": "VTEL Corp    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 264373,
    "name": "VTEL Europe Ltd    ( Reading  USA )",
    "country": "United States"
  },
  {
    "id": 454816,
    "name": "VTS Medical Systems (Europe) LLC    ( Bradford  England )",
    "country": "UK"
  },
  {
    "id": 454815,
    "name": "VTS Medical Systems LLC    ( Melville NY USA )",
    "country": "United States"
  },
  {
    "id": 464096,
    "name": "VuCOMP    ( Plano TX USA )",
    "country": "United States"
  },
  {
    "id": 460557,
    "name": "VueTek Scientific LLC    ( Gray ME USA )",
    "country": "United States"
  },
  {
    "id": 462660,
    "name": "Vulcan-Hart Co    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 292601,
    "name": "Vulcon Technologies    ( Grandview MO USA )",
    "country": "United States"
  },
  {
    "id": 456868,
    "name": "VULM SK sro    ( Bratislava  Slovakia )",
    "country": "Slovakia"
  },
  {
    "id": 462548,
    "name": "VUV Analytics Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 103927,
    "name": "VWR International    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 174758,
    "name": "VWR International (North America)    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 463068,
    "name": "VWR International GmbH    ( Darmstadt  Germany )",
    "country": "Germany"
  },
  {
    "id": 453746,
    "name": "VWR International Ltd    ( Lutterworth  England )",
    "country": "UK"
  },
  {
    "id": 456752,
    "name": "VWR International PBI srl    ( Milano (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 456500,
    "name": "VWR International Pty Ltd    ( Murarrie  Australia )",
    "country": "Australia"
  },
  {
    "id": 999811,
    "name": "VWR International, LLC    ( West Chester PA USA )",
    "country": "United States"
  },
  {
    "id": 457613,
    "name": "Vycor Medical Inc    ( Boca Raton FL USA )",
    "country": "United States"
  },
  {
    "id": 415918,
    "name": "Vygon Bulgaria Ltd    ( Plovdiv  Bulgaria )",
    "country": "Bulgaria"
  },
  {
    "id": 415916,
    "name": "Vygon Colombia SA    ( Barranquilla  Colombia )",
    "country": "Colombia"
  },
  {
    "id": 107004,
    "name": "Vygon Corp    ( Montgomeryville PA USA )",
    "country": "United States"
  },
  {
    "id": 161085,
    "name": "Vygon GmbH & Co KG    ( Aachen  Germany )",
    "country": "Germany"
  },
  {
    "id": 415912,
    "name": "Vygon India Pvt Ltd    ( Gurgaon  India )",
    "country": "India"
  },
  {
    "id": 415913,
    "name": "Vygon Ireland Ltd    ( Dublin 22  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 174481,
    "name": "Wescom Products Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 457290,
    "name": "Vygon Italia Srl    ( Padova (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 415911,
    "name": "Vygon Nederland bv    ( Valkenswaard  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 441788,
    "name": "Vygon Neuro    ( Valley Forge PA USA )",
    "country": "United States"
  },
  {
    "id": 403266,
    "name": "Vygon Portugal Lda    ( Maia  Portugal )",
    "country": "Portugal"
  },
  {
    "id": 162799,
    "name": "Vygon SA (France)    ( Ecouen  France )",
    "country": "France"
  },
  {
    "id": 415917,
    "name": "Vygon SA (Uruguay)    ( Montevideo  Uruguay )",
    "country": "Uruguay"
  },
  {
    "id": 415915,
    "name": "Vygon Spain SA    ( Paterna (Valencia)  Spain )",
    "country": "Spain"
  },
  {
    "id": 415910,
    "name": "Vygon Sweden AB    ( Skelleftea  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 139282,
    "name": "Vygon UK Ltd    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 372330,
    "name": "Vysis Inc    ( Downers Grove IL USA )",
    "country": "United States"
  },
  {
    "id": 457291,
    "name": "Vytech    ( Padova (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 453915,
    "name": "Vytech srl    ( Padova (PD)  Italy )",
    "country": "Italy"
  },
  {
    "id": 324337,
    "name": "W & H (UK) Ltd    ( St Albans  England )",
    "country": "UK"
  },
  {
    "id": 450497,
    "name": "W & H Bulgaria GmbH    ( Sofia  Bulgaria )",
    "country": "Bulgaria"
  },
  {
    "id": 332875,
    "name": "W & H Dentalwerk Buermoos GmbH    ( Buermoos  Austria )",
    "country": "Austria"
  },
  {
    "id": 450492,
    "name": "W & H Deutschland    ( Laufen/Obb  Germany )",
    "country": "Germany"
  },
  {
    "id": 450493,
    "name": "W & H France Sarl    ( Eckbolsheim  France )",
    "country": "France"
  },
  {
    "id": 451873,
    "name": "W & H Impex Inc    ( Windsor ON Canada )",
    "country": "Canada"
  },
  {
    "id": 450494,
    "name": "W & H Italy Srl    ( Brusaporto (BG)  Italy )",
    "country": "Italy"
  },
  {
    "id": 450496,
    "name": "W & H Nordic AB    ( Taby  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 450499,
    "name": "W & H Poland Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 450491,
    "name": "W & H Wehadent    ( Salzburg  Austria )",
    "country": "Austria"
  },
  {
    "id": 170975,
    "name": "W & W Electronic AG    ( Muenchenstein 3  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 105138,
    "name": "W & W Mfg Co    ( Hicksville NY USA )",
    "country": "United States"
  },
  {
    "id": 101135,
    "name": "W A Baum Co Inc    ( Copiague NY USA )",
    "country": "United States"
  },
  {
    "id": 279556,
    "name": "W Buchberger    ( Arzbach  USA )",
    "country": "United States"
  },
  {
    "id": 999803,
    "name": "W C Wood Co Inc    ( Ottawa OH USA )",
    "country": "United States"
  },
  {
    "id": 102214,
    "name": "W E Anderson    ( Michigan City IN USA )",
    "country": "United States"
  },
  {
    "id": 284565,
    "name": "W Funcke GmbH & Co KG    ( Muelheim an der Ruhr  Germany )",
    "country": "Germany"
  },
  {
    "id": 285871,
    "name": "W H Brady GmbH    ( Langen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287576,
    "name": "W Hensche GmbH & Co    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 286589,
    "name": "W Jacob GmbH    ( Thurm  Germany )",
    "country": "Germany"
  },
  {
    "id": 307330,
    "name": "W L Gore (Singapore)    ( Singapore  USA )",
    "country": "United States"
  },
  {
    "id": 101293,
    "name": "W L Gore & Assoc Inc    ( Flagstaff AZ USA )",
    "country": "United States"
  },
  {
    "id": 280889,
    "name": "W Lucka    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 306424,
    "name": "W M Bamford & Co Ltd    ( Lower Hutt  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 462636,
    "name": "W Murphy Enterprises Inc    ( Deep River ON Canada )",
    "country": "Canada"
  },
  {
    "id": 362078,
    "name": "W R Grace & Co    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 324451,
    "name": "W S Rothband & Co Ltd    ( Haslingden  England )",
    "country": "UK"
  },
  {
    "id": 282606,
    "name": "W Schleicher Sicherheitsschutz    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286563,
    "name": "W Soehngen GmbH    ( Taunusstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 161003,
    "name": "W Steenbeck & Co Digital System GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 287359,
    "name": "W Strikfeldt & Koch GmbH    ( Wiehl/Bomig  Germany )",
    "country": "Germany"
  },
  {
    "id": 105041,
    "name": "W T Farley Inc    ( Camarillo CA USA )",
    "country": "United States"
  },
  {
    "id": 363646,
    "name": "W W Grainger Inc    ( Lake Forest IL USA )",
    "country": "United States"
  },
  {
    "id": 999487,
    "name": "W.C. Redmon Company    ( Peru IN USA )",
    "country": "United States"
  },
  {
    "id": 309489,
    "name": "Waagen Schrenk    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 151294,
    "name": "Waaijenberg Mobiliteit bv    ( Veenendaal  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 278791,
    "name": "Waco Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 289761,
    "name": "Wada Precision Dental Laboratories    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 462639,
    "name": "WaferGen Biosystems Inc    ( Fremont CA USA )",
    "country": "United States"
  },
  {
    "id": 279692,
    "name": "Wagner & Kuehner GmbH & Co KG    ( Kreuznach  Germany )",
    "country": "Germany"
  },
  {
    "id": 287513,
    "name": "Wagner Analysen Technik GmbH    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 178076,
    "name": "Wagner GmbH    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455648,
    "name": "Wagner Medical    ( Middlebourne WV USA )",
    "country": "United States"
  },
  {
    "id": 999395,
    "name": "Wagner SprayTech Corp.    ( Plymouth MN USA )",
    "country": "United States"
  },
  {
    "id": 293821,
    "name": "Wagner Visuell AG    ( Bachenbuelach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 106395,
    "name": "Wahl Clipper Corp    ( Sterling IL USA )",
    "country": "United States"
  },
  {
    "id": 454755,
    "name": "Wahl GmbH    ( Unterkirnach  Germany )",
    "country": "Germany"
  },
  {
    "id": 462643,
    "name": "Wairever Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 315005,
    "name": "WaisMed Ltd    ( Yokne'am Elit  Israel )",
    "country": "Israel"
  },
  {
    "id": 400971,
    "name": "WaisMed Ltd    ( Lod  Israel )",
    "country": "Israel"
  },
  {
    "id": 305649,
    "name": "Waite & Co Pty Ltd    ( Dee Why  Australia )",
    "country": "Australia"
  },
  {
    "id": 454624,
    "name": "Waiting Room Solutions    ( Goshen NY USA )",
    "country": "United States"
  },
  {
    "id": 455195,
    "name": "Wakefield Thermal Solutions Inc    ( Pelham NH USA )",
    "country": "United States"
  },
  {
    "id": 104824,
    "name": "Wako Chemicals USA Inc    ( Richmond VA USA )",
    "country": "United States"
  },
  {
    "id": 139510,
    "name": "Wako Pure Chemical Industries Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 463013,
    "name": "Wal-Star Inc    ( Danville NY USA )",
    "country": "United States"
  },
  {
    "id": 183374,
    "name": "Waldemar Link GmbH & Co    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282771,
    "name": "Waldi Schuhfabrik GmbH    ( Hassfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 358372,
    "name": "Waldmann Lighting    ( Wheeling IL USA )",
    "country": "United States"
  },
  {
    "id": 161086,
    "name": "Waldner Laboreinrichtungen GmbH & Co    ( Wangen im Allgaeu  Germany )",
    "country": "Germany"
  },
  {
    "id": 286017,
    "name": "Waldnicler Leinenhaus    ( Schwalmtal  Germany )",
    "country": "Germany"
  },
  {
    "id": 329823,
    "name": "Walk Easy Inc    ( Delray Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 435640,
    "name": "Walker Filtration Inc    ( Erie PA USA )",
    "country": "United States"
  },
  {
    "id": 324806,
    "name": "Walker Filtration Ltd    ( Washington  England )",
    "country": "UK"
  },
  {
    "id": 338084,
    "name": "Walker Filtration Pty Ltd    ( Preston  Australia )",
    "country": "Australia"
  },
  {
    "id": 107006,
    "name": "Walker LDJ Scientific Inc    ( Worcester MA USA )",
    "country": "United States"
  },
  {
    "id": 174763,
    "name": "Walkerworks    ( Montreal PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 458147,
    "name": "Walking Cane World    ( Largo FL USA )",
    "country": "United States"
  },
  {
    "id": 452691,
    "name": "WalkMed Infusion LLC    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 456846,
    "name": "Wallace & Tiernan GmbH    ( Guenzburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 102163,
    "name": "Wallace & Tiernan Inc    ( Vineland NJ USA )",
    "country": "United States"
  },
  {
    "id": 161373,
    "name": "Wallace Ltd    ( Hythe  England )",
    "country": "UK"
  },
  {
    "id": 103681,
    "name": "Wallach Surgical Devices Inc    ( Trumbull CT USA )",
    "country": "United States"
  },
  {
    "id": 470541,
    "name": "Wallcur LLC    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 457483,
    "name": "Walter Fischer GmbH & Co    ( Stuttgart (Degerloch)  Germany )",
    "country": "Germany"
  },
  {
    "id": 279727,
    "name": "Walter Graphtek GmbH    ( Luebeck  Germany )",
    "country": "Germany"
  },
  {
    "id": 306612,
    "name": "Walter H Becker GmbH    ( Triftern  Germany )",
    "country": "Germany"
  },
  {
    "id": 393240,
    "name": "Walter Lorenz Surgical (Australia)    ( North Ryde  USA )",
    "country": "United States"
  },
  {
    "id": 393241,
    "name": "Walter Lorenz Surgical GmbH    ( Freiburg  USA )",
    "country": "United States"
  },
  {
    "id": 103492,
    "name": "Walter Lorenz Surgical Inc    ( Jacksonville FL USA )",
    "country": "United States"
  },
  {
    "id": 309264,
    "name": "Walter Moosburger KG    ( Hoerbranz  Austria )",
    "country": "Austria"
  },
  {
    "id": 282811,
    "name": "Walter Schmitt    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 185582,
    "name": "Wamed Wytwornia Aparatury Medycznej    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 106678,
    "name": "Wampole Laboratories A MedPoint Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 161087,
    "name": "WAN Industrievertretungen und Vertriebs GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 359984,
    "name": "Wandsworth Electrical Ltd    ( Woking  England )",
    "country": "UK"
  },
  {
    "id": 108110,
    "name": "Wang NMR Inc    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 308114,
    "name": "Ward International    ( Canvey Island  England )",
    "country": "UK"
  },
  {
    "id": 451876,
    "name": "Ward/Kraft Inc    ( Fort Scott KS USA )",
    "country": "United States"
  },
  {
    "id": 279240,
    "name": "Wardray Premise Ltd    ( Thames Ditton  England )",
    "country": "UK"
  },
  {
    "id": 195331,
    "name": "Waring Products    ( Torrington CT USA )",
    "country": "United States"
  },
  {
    "id": 178078,
    "name": "Warren-Knight Instrument Co    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 454968,
    "name": "Wasatch Ophthalmic Instruments    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 293823,
    "name": "Waschanstalt Zuerich AG    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 103590,
    "name": "Wascomat Laundry Equipment    ( Inwood NY USA )",
    "country": "United States"
  },
  {
    "id": 469470,
    "name": "Washer Solutions Inc    ( Victor NY USA )",
    "country": "United States"
  },
  {
    "id": 455038,
    "name": "Washington Eye Bank    ( Washington DC USA )",
    "country": "United States"
  },
  {
    "id": 453588,
    "name": "Washington Regional Transplant Community    ( Annandale VA USA )",
    "country": "United States"
  },
  {
    "id": 462930,
    "name": "Washington University School of Medical in St Loui    (   USA )",
    "country": "United States"
  },
  {
    "id": 454883,
    "name": "Wassenburg Medical Devices    ( Dodewaard  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 282610,
    "name": "Wassermann-Dental-Maschinen GmbH    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 464999,
    "name": "Wasserstrom Restaurant Supply    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 460555,
    "name": "Waste Management Inc    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 469454,
    "name": "WatchGuard Technologies Inc    ( Seattle WA USA )",
    "country": "United States"
  },
  {
    "id": 461483,
    "name": "Water Professionals Ultrapure Water LLC    ( Blountville TN USA )",
    "country": "United States"
  },
  {
    "id": 461488,
    "name": "Water Rower    ( Warren RI USA )",
    "country": "United States"
  },
  {
    "id": 348690,
    "name": "Water Structures    ( Seabrook NH USA )",
    "country": "United States"
  },
  {
    "id": 459430,
    "name": "Waterbirth International    ( Lighthouse Point FL USA )",
    "country": "United States"
  },
  {
    "id": 456621,
    "name": "Waterbirth Solutions Inc    ( Port Townsend WA USA )",
    "country": "United States"
  },
  {
    "id": 467004,
    "name": "Waterless Co Inc    ( Vista CA USA )",
    "country": "United States"
  },
  {
    "id": 458826,
    "name": "Waterlogic USA (West)    ( Concord CA USA )",
    "country": "United States"
  },
  {
    "id": 104276,
    "name": "Waterloo Bedding Co Ltd    ( Kitchener ON USA )",
    "country": "United States"
  },
  {
    "id": 101847,
    "name": "Waterloo Healthcare LLC    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 461300,
    "name": "Watermark Medical Inc    ( West Palm Beach FL USA )",
    "country": "United States"
  },
  {
    "id": 377679,
    "name": "WaterPik Technologies    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 186647,
    "name": "Waters Corp    ( Milford MA USA )",
    "country": "United States"
  },
  {
    "id": 101910,
    "name": "Waters Instruments Inc    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 452926,
    "name": "Waters Medical Systems    ( Rochester MN USA )",
    "country": "United States"
  },
  {
    "id": 455181,
    "name": "Waters SAS    ( Saint-Quentin-en-Yvelines  France )",
    "country": "France"
  },
  {
    "id": 999694,
    "name": "Waterwise Inc.    ( Leesburg FL USA )",
    "country": "USA"
  },
  {
    "id": 105631,
    "name": "Watlow Infrared Co    (   USA )",
    "country": "United States"
  },
  {
    "id": 434250,
    "name": "Watson Diagnostics    ( Corona CA USA )",
    "country": "United States"
  },
  {
    "id": 457897,
    "name": "Watson Label Products Inc    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 462646,
    "name": "Watson-Marlow Inc    ( Wilmington MA USA )",
    "country": "United States"
  },
  {
    "id": 466236,
    "name": "Watts    ( North Andover MA USA )",
    "country": "United States"
  },
  {
    "id": 999860,
    "name": "Watts Premier, Inc.    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 108121,
    "name": "Wave Form Systems Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 407273,
    "name": "Wave Three Software    ( Fort Lauderdale FL USA )",
    "country": "United States"
  },
  {
    "id": 344440,
    "name": "WaveLight AG    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 454236,
    "name": "WaveMark Inc    ( Littleton MA USA )",
    "country": "United States"
  },
  {
    "id": 402509,
    "name": "Waverley Glen Systems Ltd    ( Vaughan ON Canada )",
    "country": "Canada"
  },
  {
    "id": 458214,
    "name": "WaveTec Vision    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 458472,
    "name": "WaveTec Vision Inc    ( Aliso Viejo CA USA )",
    "country": "United States"
  },
  {
    "id": 999813,
    "name": "WaveWare Technologies, Inc.    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 450509,
    "name": "Waymade Healthcare plc    ( London  England )",
    "country": "UK"
  },
  {
    "id": 452961,
    "name": "Wayne Engineering    ( Skokie IL USA )",
    "country": "United States"
  },
  {
    "id": 465210,
    "name": "Wayne Inc.    ( Austin 38 USA )",
    "country": "United States"
  },
  {
    "id": 999413,
    "name": "Wayne Metal Products, Inc.    ( Sanford FL USA )",
    "country": "United States"
  },
  {
    "id": 170820,
    "name": "WCMS Inc    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 460634,
    "name": "WealthEngine Inc    ( Bethesda MD USA )",
    "country": "United States"
  },
  {
    "id": 451401,
    "name": "Wearwell    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 108919,
    "name": "Weaver & Co    ( Aurora CO USA )",
    "country": "United States"
  },
  {
    "id": 279807,
    "name": "Weba-Medizinmechanik GmbH & Co    ( Balgheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 183931,
    "name": "Webb Mfg Corp    ( Philadelphia PA USA )",
    "country": "United States"
  },
  {
    "id": 332425,
    "name": "Webber Electronics Inc    ( North Ridgeville OH USA )",
    "country": "United States"
  },
  {
    "id": 462647,
    "name": "WebbShade    ( El Cajon CA USA )",
    "country": "United States"
  },
  {
    "id": 161088,
    "name": "Webeco GmbH & Co KG    ( Selmsdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 393125,
    "name": "Weber & Weber    ( Rio Rancho NM USA )",
    "country": "United States"
  },
  {
    "id": 281269,
    "name": "Weber Instrumente GmbH & Co KG    ( Emmingen-Liptingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 398534,
    "name": "WebMD    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 451774,
    "name": "Webmedx inc    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 461304,
    "name": "Websense Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 282931,
    "name": "Wedeco AG    ( Herford  Germany )",
    "country": "Germany"
  },
  {
    "id": 441448,
    "name": "Wedgewood Innovative Sensors    ( Anaheim CA USA )",
    "country": "United States"
  },
  {
    "id": 407207,
    "name": "Wego Chemical and Mineral Corp    ( Great Neck NY USA )",
    "country": "United States"
  },
  {
    "id": 450498,
    "name": "Wehadent Iberica SL    ( Valencia  Spain )",
    "country": "Spain"
  },
  {
    "id": 183440,
    "name": "Wehr Engineering    ( Fairland IN USA )",
    "country": "United States"
  },
  {
    "id": 284437,
    "name": "Weiblen & Ruemmelin Lederfabrik GmbH & Co KG    ( Metzingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 161089,
    "name": "Weidemeyer & Co    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 999540,
    "name": "Weider    (   USA )",
    "country": "United States"
  },
  {
    "id": 282740,
    "name": "Weidner Laboreinrichtungs GmbH    ( Hardegsen  Germany )",
    "country": "Germany"
  },
  {
    "id": 455943,
    "name": "Weifang Kawa Medical Products Co Ltd    (   People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291664,
    "name": "Weihai Medical Polymer Products    ( Weihai  USA )",
    "country": "United States"
  },
  {
    "id": 161092,
    "name": "Weiner Medizintechnik    ( Friedberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 282612,
    "name": "Weinmann    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 450945,
    "name": "Weinmann (Asia-Pacific) Co Ltd    ( Bangkok  Thailand )",
    "country": "Thailand"
  },
  {
    "id": 161095,
    "name": "Weinmann Geraete fuer Medizin GmbH & Co KG    ( Hamburg 54  Germany )",
    "country": "Germany"
  },
  {
    "id": 454030,
    "name": "Weinmann Geraete fur Medizin GmbH & Co KG (China)    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 286100,
    "name": "Weinmann GmbH    ( Seitingen-Oberflacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 450944,
    "name": "Weinmann Sarl    ( Igny  France )",
    "country": "France"
  },
  {
    "id": 456886,
    "name": "Weinmann SPb GmbH    ( St Petersburg  Russia )",
    "country": "Russia"
  },
  {
    "id": 284091,
    "name": "Weiss & Hameier KG    ( Ludwigshafen  USA )",
    "country": "United States"
  },
  {
    "id": 403543,
    "name": "Weiss GWE GmbH    ( Hude  Germany )",
    "country": "Germany"
  },
  {
    "id": 161097,
    "name": "Weiss Klimatechnik GmbH    ( Reiskirchen-Lindenstruth  Germany )",
    "country": "Germany"
  },
  {
    "id": 407218,
    "name": "Weiss Klimatechnik Polska Sp z o o    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 407217,
    "name": "Weiss Klimatechnik Russia    ( Moskva  Russia )",
    "country": "Russia"
  },
  {
    "id": 322465,
    "name": "Weisselberg N & Sons Engineers Ltd    ( Ramat Gan  Israel )",
    "country": "Israel"
  },
  {
    "id": 959595,
    "name": "WELBILT    (   USA )",
    "country": "United States"
  },
  {
    "id": 397833,
    "name": "Welch Allyn    ( Skaneateles Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 456867,
    "name": "Welch Allyn AB    ( Danderyd  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 442327,
    "name": "Welch Allyn Australia Pty Ltd    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 456864,
    "name": "Welch Allyn BV    ( Delft  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 174765,
    "name": "Welch Allyn Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 444099,
    "name": "Welch Allyn Cardio Control bv    ( Delft  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 444097,
    "name": "Welch Allyn France    ( Mareuil Les Meaux  France )",
    "country": "France"
  },
  {
    "id": 178366,
    "name": "Welch Allyn GmbH & Co KG    ( Jungingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 101850,
    "name": "Welch Allyn Inc    ( Skaneateles Falls NY USA )",
    "country": "United States"
  },
  {
    "id": 378487,
    "name": "Welch Allyn Inc    ( Beaverton OR USA )",
    "country": "United States"
  },
  {
    "id": 444108,
    "name": "Welch Allyn Inc Australia    ( Rydalmere  USA )",
    "country": "United States"
  },
  {
    "id": 444107,
    "name": "Welch Allyn Inc South Africa    ( Johannesburg  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 319405,
    "name": "Welch Allyn International Venture Inc    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 442326,
    "name": "Welch Allyn Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456832,
    "name": "Welch Allyn Latinoamerica    ( Doral FL USA )",
    "country": "United States"
  },
  {
    "id": 441593,
    "name": "Welch Allyn Monitoring (New Zealand)    ( Christchurch  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 441592,
    "name": "Welch Allyn Monitoring GmbH    ( Grasbrunn/Technopark  Germany )",
    "country": "Germany"
  },
  {
    "id": 456865,
    "name": "Welch Allyn Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 444098,
    "name": "Welch Allyn Sverige    ( Hoganas  Sweden )",
    "country": "Sweden"
  },
  {
    "id": 319406,
    "name": "Welch Allyn UK Ltd    ( Aston Abbotts  England )",
    "country": "UK"
  },
  {
    "id": 103947,
    "name": "Welcon Inc    ( Fort Worth TX USA )",
    "country": "United States"
  },
  {
    "id": 999970,
    "name": "WELDEX    (   USA )",
    "country": "United States"
  },
  {
    "id": 178119,
    "name": "Welding and Therapy Service Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 306498,
    "name": "Weldon Medical    ( Waikato  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 456428,
    "name": "Well Lands Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 407234,
    "name": "Welland Medical Ltd    ( Crawley  England )",
    "country": "UK"
  },
  {
    "id": 465003,
    "name": "Wellborn Mechanical Services Inc.    ( Longview 24 USA )",
    "country": "United States"
  },
  {
    "id": 453843,
    "name": "Wellogic    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 107508,
    "name": "Wells Dental Inc    ( Comptche CA USA )",
    "country": "United States"
  },
  {
    "id": 101872,
    "name": "Wells Johnson Co    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 465122,
    "name": "Wellsense USA Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 467103,
    "name": "Wellsense USA Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 263090,
    "name": "Wellsoft Corp    ( Somerset NJ USA )",
    "country": "United States"
  },
  {
    "id": 454059,
    "name": "Wellsource Inc    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 457543,
    "name": "Wellspect HealthCare    ( Elz  Germany )",
    "country": "Germany"
  },
  {
    "id": 466602,
    "name": "WellSpring Pharmaceutical Corp    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 462654,
    "name": "Wellspring Worldwide Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 458192,
    "name": "Welmed Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 232664,
    "name": "Welsheng Corp    ( Taipei  USA )",
    "country": "United States"
  },
  {
    "id": 174437,
    "name": "WEM Equipamentos Eletronicos Ltda    ( Ribeirao Preto-SP  Brazil )",
    "country": "Brazil"
  },
  {
    "id": 352963,
    "name": "Wembley Rubber Products (M) Sdn Bhd    ( Sepang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 161101,
    "name": "Wenger-Geraete    ( Stuttgart 80  Germany )",
    "country": "Germany"
  },
  {
    "id": 282813,
    "name": "Wenzel    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 458043,
    "name": "Wenzel Spine Inc    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 291525,
    "name": "Wenzhon Huali Medical Instrument Co Ltd    ( Yueqing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456665,
    "name": "Wenzhou Beipu Science & Technology Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 457567,
    "name": "Wenzhou Eyemax Optical Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 464540,
    "name": "Wenzhou Redstar Optical Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456652,
    "name": "Wenzhou Sifang Pharmaceutical Equipment Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 283083,
    "name": "Wepa Apoteken Bedarf GmbH & Co    ( Hoehr-Grenzhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453901,
    "name": "Werfen Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 453906,
    "name": "Werfen Austria GmbH    ( Wien  Austria )",
    "country": "Austria"
  },
  {
    "id": 283650,
    "name": "Werheit Stahl- und Edelstahl GmbH & Co    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 287136,
    "name": "Werkmeister GmbH    ( Wanfried  Germany )",
    "country": "Germany"
  },
  {
    "id": 282060,
    "name": "Werner Christ GmbH    ( Gondershausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 282031,
    "name": "Werner Eidam Medizin-Technologie GmbH    ( Wettenberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 286877,
    "name": "Werner Freutel    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 158406,
    "name": "Werner Frueh    ( Tuttlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287590,
    "name": "Werner Harfmann Gbr Herst Chir Instrumente    ( Wurmlingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 279872,
    "name": "Werner Hermann GmbH Etikettiersysteme    ( Beerfelden  Germany )",
    "country": "Germany"
  },
  {
    "id": 280241,
    "name": "Werner Mann Praxis- und Labormoebel    ( Besigheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 284164,
    "name": "Werner Schulte-Derne GmbH    ( Luenen  Germany )",
    "country": "Germany"
  },
  {
    "id": 170971,
    "name": "Wernli AG Elastic Bandages    ( Rothrist-Schweiz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 161102,
    "name": "Wero-Medical GmbH & Co KG    ( Taunusstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 466757,
    "name": "WES Enterprises LP    ( Keller TX USA )",
    "country": "United States"
  },
  {
    "id": 464971,
    "name": "Wes Todd    ( Spokane Valley WA USA )",
    "country": "United States"
  },
  {
    "id": 151037,
    "name": "Wesa AG    ( Eupen  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 374361,
    "name": "Wesco Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 102333,
    "name": "Wescor Inc    ( Logan UT USA )",
    "country": "United States"
  },
  {
    "id": 376294,
    "name": "Wescott Medical Ltd    ( Chester-Le-Street  England )",
    "country": "UK"
  },
  {
    "id": 286551,
    "name": "Wesemann GmbH & Co    ( Syke  Germany )",
    "country": "Germany"
  },
  {
    "id": 323969,
    "name": "Wessex Electronics Ltd    ( Bristol  England )",
    "country": "UK"
  },
  {
    "id": 162295,
    "name": "Wessex Medical Equipment Co Ltd    ( Romsey  England )",
    "country": "UK"
  },
  {
    "id": 454843,
    "name": "Wessington Cryogenics    ( Houghton le Spring  England )",
    "country": "UK"
  },
  {
    "id": 999561,
    "name": "West Bend    (   USA )",
    "country": "United States"
  },
  {
    "id": 469526,
    "name": "West Branch Medical LLC    ( Brookhaven PA USA )",
    "country": "United States"
  },
  {
    "id": 168427,
    "name": "West Chester Holdings Inc    ( Monroe OH USA )",
    "country": "United States"
  },
  {
    "id": 452293,
    "name": "West Coast - Southern Medical Services Inc    ( Bradenton FL USA )",
    "country": "United States"
  },
  {
    "id": 360445,
    "name": "West Coast Bio-Medical    ( Ramona CA USA )",
    "country": "United States"
  },
  {
    "id": 108700,
    "name": "West Coast Brace & Limb    ( Tampa FL USA )",
    "country": "United States"
  },
  {
    "id": 431352,
    "name": "West Coast Imaging    ( Oakhurst CA USA )",
    "country": "United States"
  },
  {
    "id": 456784,
    "name": "West Medica Austria    ( Perchtoldsdorf  Austria )",
    "country": "Austria"
  },
  {
    "id": 456785,
    "name": "West Medica Canada    ( Toronto ON Canada )",
    "country": "Canada"
  },
  {
    "id": 462986,
    "name": "West Pharmaceutical Services Inc    ( Exton PA USA )",
    "country": "United States"
  },
  {
    "id": 461308,
    "name": "West Physics    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 177156,
    "name": "West-Com Nurse Call Systems Inc    ( Fairfield CA USA )",
    "country": "United States"
  },
  {
    "id": 462666,
    "name": "WestAir Gases and Equipment Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 104492,
    "name": "Westco Medical Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 453593,
    "name": "Westcoast Surgical and Medical Supplies    ( Canning Vale  Australia )",
    "country": "Australia"
  },
  {
    "id": 999199,
    "name": "WESTERN    (   USA )",
    "country": "United States"
  },
  {
    "id": 106114,
    "name": "Western Biomedical Enterprises Inc    ( San Bernardino CA USA )",
    "country": "United States"
  },
  {
    "id": 456973,
    "name": "Western Cape Direct LLC    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 170822,
    "name": "Western Diagnostics Inc    ( Evergreen CO USA )",
    "country": "United States"
  },
  {
    "id": 458831,
    "name": "Western Digital Technologies Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 184244,
    "name": "Western Medica    ( Westlake OH USA )",
    "country": "United States"
  },
  {
    "id": 322757,
    "name": "Western Medical Ltd    ( Tenafly NJ USA )",
    "country": "United States"
  },
  {
    "id": 999446,
    "name": "Western Ophthalmics Corporation    ( Lynnwood WA USA )",
    "country": "United States"
  },
  {
    "id": 102936,
    "name": "Western Scientific Co Inc    ( Valencia CA USA )",
    "country": "United States"
  },
  {
    "id": 371757,
    "name": "Western Shelter Systems    ( Eugene OR USA )",
    "country": "United States"
  },
  {
    "id": 999100,
    "name": "Western Systems Research, Inc.    ( Temecula CA USA )",
    "country": "United States"
  },
  {
    "id": 335266,
    "name": "Western Textile Healthcare    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 151994,
    "name": "Westmark Products Inc    ( Tacoma WA USA )",
    "country": "United States"
  },
  {
    "id": 341425,
    "name": "Westmed Inc    ( Tucson AZ USA )",
    "country": "United States"
  },
  {
    "id": 161105,
    "name": "Westmed Johnen-Lappe-Wolff Medizin-Technik    ( Duesseldorf 1  Germany )",
    "country": "Germany"
  },
  {
    "id": 104263,
    "name": "Westnofa of Canada Ltd    ( Winnipeg MB Canada )",
    "country": "Canada"
  },
  {
    "id": 358480,
    "name": "Westone Laboratories Inc    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 999618,
    "name": "Westover Scientific Inc.    ( Mill Creek WA USA )",
    "country": "United States"
  },
  {
    "id": 475971,
    "name": "WestPort Medical    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 999655,
    "name": "WestPort Medical, Inc    ( Sales OR USA )",
    "country": "United States"
  },
  {
    "id": 282614,
    "name": "Westra    ( Hamburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 287306,
    "name": "Westra Elektroakinstik GmbH    ( Binswangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 285873,
    "name": "Weta Elektrogeraete und Apparatebau GmbH    ( Roedermark  Germany )",
    "country": "Germany"
  },
  {
    "id": 161106,
    "name": "Wetrok-GmbH Reinigungstechnik    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 285849,
    "name": "Wex Filtertechnik GmbH    ( Rotenburg/Fulda  USA )",
    "country": "United States"
  },
  {
    "id": 458140,
    "name": "Wexford Labs    ( Kirkwood MO USA )",
    "country": "United States"
  },
  {
    "id": 458507,
    "name": "Wexler Surgical    ( Houston TX USA )",
    "country": "United States"
  },
  {
    "id": 183773,
    "name": "Weyer GmbH    ( Kuerten  Germany )",
    "country": "Germany"
  },
  {
    "id": 332435,
    "name": "WFR Corp    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 105971,
    "name": "WFR/Aquaplast Corp    ( Wyckoff NJ USA )",
    "country": "United States"
  },
  {
    "id": 466548,
    "name": "WG Healthcare Ltd    ( Letchworth  England )",
    "country": "UK"
  },
  {
    "id": 103088,
    "name": "Whatman Inc    ( Piscataway NJ USA )",
    "country": "United States"
  },
  {
    "id": 149030,
    "name": "Whatman plc    ( Maidstone  England )",
    "country": "UK"
  },
  {
    "id": 457435,
    "name": "Wheaton Brace Co    ( Carol Stream IL USA )",
    "country": "United States"
  },
  {
    "id": 101939,
    "name": "Wheaton Science Products    ( Millville NJ USA )",
    "country": "United States"
  },
  {
    "id": 453712,
    "name": "WheelchairMedical.com    ( Brooklyn NY USA )",
    "country": "United States"
  },
  {
    "id": 108850,
    "name": "Wheelchairs of Kansas    ( Ellis KS USA )",
    "country": "United States"
  },
  {
    "id": 104297,
    "name": "Wheeled Coach Industries    ( Winter Park FL USA )",
    "country": "United States"
  },
  {
    "id": 104920,
    "name": "Wheelit Inc    ( Toledo OH USA )",
    "country": "United States"
  },
  {
    "id": 454712,
    "name": "Whip Mix Corp    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 454713,
    "name": "Whip Mix Europe GmbH    ( Dortmund  Germany )",
    "country": "Germany"
  },
  {
    "id": 105744,
    "name": "Whip-Mix Corp    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 462668,
    "name": "Whirlpool Corp    ( Benton Harbor MI USA )",
    "country": "United States"
  },
  {
    "id": 999962,
    "name": "Whirlpool Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 451609,
    "name": "White Conveyors Inc    ( Kenilworth NJ USA )",
    "country": "United States"
  },
  {
    "id": 105606,
    "name": "White Mountain Imaging    ( Webster NH USA )",
    "country": "United States"
  },
  {
    "id": 453947,
    "name": "White Peaks Dental Systems GmbH & Co KG    ( Essen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458721,
    "name": "White Plume Technologies LLC    ( Birmingham AL USA )",
    "country": "United States"
  },
  {
    "id": 999831,
    "name": "White-Westinghouse    (   USA )",
    "country": "United States"
  },
  {
    "id": 136834,
    "name": "Whitecross Engineering Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 105319,
    "name": "Whitehall Mfg Div of Acorn    ( City of Industry CA USA )",
    "country": "United States"
  },
  {
    "id": 416528,
    "name": "Whitehouse Scientific Ltd    ( Chester  England )",
    "country": "UK"
  },
  {
    "id": 330941,
    "name": "Whiteside Biomechanics    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 348935,
    "name": "Whitestone Acquisition Corp    ( Bloomington IN USA )",
    "country": "United States"
  },
  {
    "id": 332647,
    "name": "Whitmyer Biomechanix Inc    ( Tallahassee FL USA )",
    "country": "United States"
  },
  {
    "id": 101856,
    "name": "Whitney Products Inc    ( Niles IL USA )",
    "country": "United States"
  },
  {
    "id": 109723,
    "name": "Whittaker General Medical    (   USA )",
    "country": "United States"
  },
  {
    "id": 106117,
    "name": "Whittemore Enterprises Inc    ( Rancho Cucamonga CA USA )",
    "country": "United States"
  },
  {
    "id": 436625,
    "name": "Whittlestone Inc    ( Antioch CA USA )",
    "country": "United States"
  },
  {
    "id": 279431,
    "name": "Wibu-Wirtschaftsbund Sozialer Einrichtungen    ( Ahrensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 455390,
    "name": "Wichita Eye Bank & Cornea Research Center    ( Wichita KS USA )",
    "country": "United States"
  },
  {
    "id": 440668,
    "name": "Wicker Biomedical Services LLC    ( Brigantine NJ USA )",
    "country": "United States"
  },
  {
    "id": 462708,
    "name": "WIDE Corporation    ( Giheung-Gu, Yonging-si  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 999443,
    "name": "Wide Corporation    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 374036,
    "name": "Widex A/S    ( Lynge  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453802,
    "name": "Widex A/S    ( Vaerloese  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 453803,
    "name": "Widex Hearing Aid Co Inc    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 332990,
    "name": "Widex Hearing Co Inc    ( Long Island City NY USA )",
    "country": "United States"
  },
  {
    "id": 453801,
    "name": "Widex Hoergeraete GmbH    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 999335,
    "name": "Widmer Time Recorder Company Inc    ( New York NY USA )",
    "country": "USA"
  },
  {
    "id": 170973,
    "name": "Wiegand AG Buelach    ( Buelach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 461324,
    "name": "Wieland    ( Grabill IN USA )",
    "country": "United States"
  },
  {
    "id": 285361,
    "name": "Wieland Dental & Technick GmbH & Co KG    ( Pforzheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 417399,
    "name": "Wieland Dental Systems Inc    ( Danbury CT USA )",
    "country": "United States"
  },
  {
    "id": 333669,
    "name": "Wiest International America    (   USA )",
    "country": "United States"
  },
  {
    "id": 333699,
    "name": "Wiest International America    (   USA )",
    "country": "United States"
  },
  {
    "id": 338708,
    "name": "Wiest International GmbH    ( Potsdam  Germany )",
    "country": "Germany"
  },
  {
    "id": 466695,
    "name": "WIKA Instrument LP    ( Lawrenceville GA USA )",
    "country": "United States"
  },
  {
    "id": 331210,
    "name": "Wil-Med Global Inc    ( Broken Arrow OK USA )",
    "country": "United States"
  },
  {
    "id": 458174,
    "name": "WILAmed GmbH    ( Kammerstein  Germany )",
    "country": "Germany"
  },
  {
    "id": 458049,
    "name": "Wilbrecht LEDCO Inc    ( St Paul MN USA )",
    "country": "United States"
  },
  {
    "id": 454007,
    "name": "Wilburn Medical    ( Kernersville NC USA )",
    "country": "United States"
  },
  {
    "id": 347667,
    "name": "Wilder Mobility    ( Waterloo ON Canada )",
    "country": "Canada"
  },
  {
    "id": 460632,
    "name": "Wildfire Interactive Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 462705,
    "name": "WildPackets Inc    ( Walnut Creek CA USA )",
    "country": "United States"
  },
  {
    "id": 280404,
    "name": "Wilh Wuelfing GmbH & Co KG    ( Borken  Germany )",
    "country": "Germany"
  },
  {
    "id": 285432,
    "name": "Wilhelm Fitzner    ( Preussisch-Oldendorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 158438,
    "name": "Wilhelm Haselmeier GmbH & Co    ( Stuttgart  Germany )",
    "country": "Germany"
  },
  {
    "id": 286506,
    "name": "Wilhelm Julius Teufel GmbH    ( Wangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 160089,
    "name": "Wilhelm Meyer GmbH & Co KG    ( Vlotho  Germany )",
    "country": "Germany"
  },
  {
    "id": 282616,
    "name": "Wilhelm Toedt Textilhandelsgesellschaft mbH    ( Hamburg  USA )",
    "country": "United States"
  },
  {
    "id": 161082,
    "name": "Wilhelm Vogel GmbH & Co KG    ( Giessen  Germany )",
    "country": "Germany"
  },
  {
    "id": 287578,
    "name": "Wilhelm Wandt    ( Wuppertal  Germany )",
    "country": "Germany"
  },
  {
    "id": 279911,
    "name": "Wilhelm Werner GmbH    ( Leverkusen  Germany )",
    "country": "Germany"
  },
  {
    "id": 462864,
    "name": "WiLine Networks Inc    ( San Carlos CA USA )",
    "country": "United States"
  },
  {
    "id": 335921,
    "name": "Wilkem Scientific    ( Pawtucket RI USA )",
    "country": "United States"
  },
  {
    "id": 283067,
    "name": "Wilkens GmbH & Co KG    ( Hoya Weser  Germany )",
    "country": "Germany"
  },
  {
    "id": 335922,
    "name": "Wilkens-Anderson Co    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 364324,
    "name": "Wilkinson Hi-Rise LLC    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 286136,
    "name": "Will & Hahnenstein GmbH    ( Siegen  Germany )",
    "country": "Germany"
  },
  {
    "id": 163493,
    "name": "William A Cook Australia Pty Ltd    ( Brisbane  Australia )",
    "country": "Australia"
  },
  {
    "id": 156480,
    "name": "William Cook Europe    ( Bjaerverskov  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 305672,
    "name": "William Green Pty Ltd    ( Rydalmere  Australia )",
    "country": "Australia"
  },
  {
    "id": 106468,
    "name": "Williams Healthcare Systems LLC    ( Elgin IL USA )",
    "country": "United States"
  },
  {
    "id": 451503,
    "name": "Williams Medical Supplies plc    ( Rhymney  Wales )",
    "country": "Wales"
  },
  {
    "id": 454904,
    "name": "Williams Sound Corp    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 462704,
    "name": "Williams Sound, LLC    ( Eden Prairie MN USA )",
    "country": "United States"
  },
  {
    "id": 106211,
    "name": "Willick Engineering Co Inc    ( Santa Fe Springs CA USA )",
    "country": "United States"
  },
  {
    "id": 293222,
    "name": "Willy A Bachofen AG    ( Muttenz  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 286102,
    "name": "Willy Fuchs    ( Seitingen-Oberflacht  Germany )",
    "country": "Germany"
  },
  {
    "id": 151452,
    "name": "Willy Ruesch AG    ( Kernen  Germany )",
    "country": "Germany"
  },
  {
    "id": 426532,
    "name": "WILMAD-LABGLASS    ( Buena NJ USA )",
    "country": "United States"
  },
  {
    "id": 104305,
    "name": "Wilson Greatbatch Technologies Ltd    ( Clarence NY USA )",
    "country": "United States"
  },
  {
    "id": 162041,
    "name": "Wilson Industries Inc    ( Pomona CA USA )",
    "country": "United States"
  },
  {
    "id": 286374,
    "name": "Wimmer GmbH    ( Stolberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 297511,
    "name": "Win International Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 462701,
    "name": "Win-Holt Equipment Group    ( Syosset NY USA )",
    "country": "United States"
  },
  {
    "id": 356830,
    "name": "WINCO Identification Corp    ( Nashua NH USA )",
    "country": "United States"
  },
  {
    "id": 102937,
    "name": "Winco Inc    ( Ocala FL USA )",
    "country": "United States"
  },
  {
    "id": 455720,
    "name": "Wincomm Corp    ( Hsinchu  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 441026,
    "name": "WinCup    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 401730,
    "name": "Windquest    ( Holland MI USA )",
    "country": "United States"
  },
  {
    "id": 281131,
    "name": "Windscheid & Wendel GmbH & Co KG    ( Duesseldorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 460566,
    "name": "Windsor    ( Englewood CO USA )",
    "country": "United States"
  },
  {
    "id": 426345,
    "name": "WindStone Medical Inc    ( Billings MT USA )",
    "country": "United States"
  },
  {
    "id": 415726,
    "name": "Winegard Co    ( Burlington IA USA )",
    "country": "United States"
  },
  {
    "id": 282815,
    "name": "Winkler GmbH    ( Heidelberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 284211,
    "name": "Winmed GmbH    ( Magdeburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 163046,
    "name": "Winmed Instruments Mfg Co    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 466437,
    "name": "Winola Lake Health IT    ( Pipersville PA USA )",
    "country": "United States"
  },
  {
    "id": 451769,
    "name": "Winscribe Inc    ( Chicago IL USA )",
    "country": "United States"
  },
  {
    "id": 457502,
    "name": "WiPath Communications LLC    ( Cumming GA USA )",
    "country": "United States"
  },
  {
    "id": 457504,
    "name": "WiPath Communications Ltd    ( Aukland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 457503,
    "name": "WiPath Communications Pty Ltd    ( Chester Hill  Australia )",
    "country": "Australia"
  },
  {
    "id": 293677,
    "name": "Wipf AG    ( Volketswil  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 416029,
    "name": "Wipf Austria GmbH    ( Marchtrenk  Austria )",
    "country": "Austria"
  },
  {
    "id": 460615,
    "name": "Wipro Ltd    ( Bangalore  India )",
    "country": "India"
  },
  {
    "id": 377702,
    "name": "Wire One Communications Inc    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 454187,
    "name": "Wired.MD Inc    ( Portland OR USA )",
    "country": "United States"
  },
  {
    "id": 465062,
    "name": "Wiring.com Inc.    ( Coral Springs 44 USA )",
    "country": "United States"
  },
  {
    "id": 293829,
    "name": "WISAG AG    ( Zuerich  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 139279,
    "name": "WISAP GmbH    ( Sauerlach bei Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 431607,
    "name": "Wisco Industries Inc    ( Oregon WI USA )",
    "country": "United States"
  },
  {
    "id": 101860,
    "name": "Wisconsin Aluminum Foundry Co Inc    ( Manitowoc WI USA )",
    "country": "United States"
  },
  {
    "id": 452530,
    "name": "Wisconsin Oven Corp    ( East Troy WI USA )",
    "country": "United States"
  },
  {
    "id": 455946,
    "name": "Wisconsin Pharmacal Co    ( Jackson WI USA )",
    "country": "United States"
  },
  {
    "id": 453709,
    "name": "WisdomKing.com Inc    ( Oceanside CA USA )",
    "country": "United States"
  },
  {
    "id": 179828,
    "name": "Wissenschaftliche Geraetebau Dr Ing Herbert Knauer    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 161110,
    "name": "Wissner-Bosserhoff GmbH    ( Wickede (Ruhr)  Germany )",
    "country": "Germany"
  },
  {
    "id": 280580,
    "name": "Witec Electronic GmbH    ( Buchholz  Germany )",
    "country": "Germany"
  },
  {
    "id": 287302,
    "name": "Witeg Labortechnik GmbH    ( Wertheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 416463,
    "name": "Witschi-Kissen AG    ( Lufingen  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 285612,
    "name": "Witschi-Kissen GmbH & Co KG    ( Ravensburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 452291,
    "name": "WITT BIOMEDICAL    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 152577,
    "name": "Witt Biomedical Corp    ( Melbourne FL USA )",
    "country": "United States"
  },
  {
    "id": 458013,
    "name": "Witt-Gasetechnik GmbH & Co KG    ( Witten  Germany )",
    "country": "Germany"
  },
  {
    "id": 161111,
    "name": "Witte & Sutor GmbH    ( Murrhardt/Wuertt  Germany )",
    "country": "Germany"
  },
  {
    "id": 284739,
    "name": "WKM Werkstatt fuer Koerperbehinderten Hilfsmittel    ( Muenchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 466297,
    "name": "WLD-Tec GmbH    ( Goettingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 103795,
    "name": "Wm B Johnson & Assoc Inc    ( Lewisburg WV USA )",
    "country": "United States"
  },
  {
    "id": 178325,
    "name": "Woehlk Contact Linsen GmbH    ( Schoenkirchen  Germany )",
    "country": "Germany"
  },
  {
    "id": 293585,
    "name": "Wohlgroth AG    ( Schwerzenbach  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 280536,
    "name": "Wolbert Hoevelmann Arbeitsschutz und Industriebeda    ( Bremen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456561,
    "name": "Wolf Medical Supplies    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 280204,
    "name": "Wolf Seiffert Systemeinrichtungen    ( Berlin  USA )",
    "country": "United States"
  },
  {
    "id": 103150,
    "name": "Wolf X-Ray Corp    ( Deer Park NY USA )",
    "country": "United States"
  },
  {
    "id": 286283,
    "name": "Wolf-Medizintechnik GmbH    ( St Gangloff  Germany )",
    "country": "Germany"
  },
  {
    "id": 329995,
    "name": "Wolfcraft Inc    ( Granada Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 175829,
    "name": "Wolfe Tory Medical Inc    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 280990,
    "name": "Wolfgang Zeh International    ( Duisburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 450926,
    "name": "Wolters Kluwer Health    ( St Louis MO USA )",
    "country": "United States"
  },
  {
    "id": 107513,
    "name": "Wolverine X-Ray Sales & Service    ( Southfield MI USA )",
    "country": "United States"
  },
  {
    "id": 172520,
    "name": "WOM World of Medicine AG    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 459217,
    "name": "WOM World of Medicine ASIA Ltd    ( Quarry Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 365948,
    "name": "WOM World of Medicine USA Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 456256,
    "name": "Won Industry Co    ( Siheung  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456255,
    "name": "Won Solution Co Ltd    ( Gwangju  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456254,
    "name": "WON Technology Co Ltd    ( Daejeon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455837,
    "name": "Wonderfeel Ltd (Hamamit Min Hateva Ltd)    ( Rishon LeZion  Israel )",
    "country": "Israel"
  },
  {
    "id": 402722,
    "name": "Woo Young Medical Co Ltd    (   Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 176026,
    "name": "Woodbridge Medical & Surgical Supply    ( Woodbridge NJ USA )",
    "country": "United States"
  },
  {
    "id": 457572,
    "name": "Wooden Specs Studio    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 372421,
    "name": "Woodhead LP    ( Deerfield IL USA )",
    "country": "United States"
  },
  {
    "id": 274417,
    "name": "Woodlyn Inc    ( Arlington Heights IL USA )",
    "country": "United States"
  },
  {
    "id": 999683,
    "name": "WoodsLamp.net    ( Broomall PA USA )",
    "country": "USA"
  },
  {
    "id": 152529,
    "name": "Woodway GmbH    ( Weil am Rhein  Germany )",
    "country": "Germany"
  },
  {
    "id": 178328,
    "name": "Woodway USA    ( Waukesha WI USA )",
    "country": "United States"
  },
  {
    "id": 456269,
    "name": "Woosam Medical Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458130,
    "name": "Work n Leisure (WNL) Products Inc    ( Holliston MA USA )",
    "country": "United States"
  },
  {
    "id": 462865,
    "name": "WorkCenters Plus Inc    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 459075,
    "name": "Workday    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 454625,
    "name": "Workflow.com LLC    ( Rocky River OH USA )",
    "country": "United States"
  },
  {
    "id": 452420,
    "name": "WorkForce Software    ( Livonia MI USA )",
    "country": "United States"
  },
  {
    "id": 464903,
    "name": "Workplace Resource Group (WRG)    ( Carrollton TX USA )",
    "country": "United States"
  },
  {
    "id": 462699,
    "name": "Workscapes    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 460613,
    "name": "WorkWell Inc    ( Duluth MN USA )",
    "country": "United States"
  },
  {
    "id": 266331,
    "name": "World Circuit Technology Inc    ( Simi Valley CA USA )",
    "country": "United States"
  },
  {
    "id": 394728,
    "name": "World Heart Inc    ( Oakland CA USA )",
    "country": "United States"
  },
  {
    "id": 170556,
    "name": "World Medical Equipment Inc    ( Marysville WA USA )",
    "country": "United States"
  },
  {
    "id": 459409,
    "name": "World of Anti Vibration Engineering (WAVE)    ( Hannoversch Muenden  Germany )",
    "country": "Germany"
  },
  {
    "id": 452525,
    "name": "World Precision Instruments GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 105460,
    "name": "World Precision Instruments Inc    ( Sarasota FL USA )",
    "country": "United States"
  },
  {
    "id": 452524,
    "name": "World Precision Instruments Ltd    ( Stevenage  England )",
    "country": "UK"
  },
  {
    "id": 338633,
    "name": "World Wide Medical Technologies    ( Oxford CT USA )",
    "country": "United States"
  },
  {
    "id": 321053,
    "name": "WorldCare Inc    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 465026,
    "name": "Worldcom Exchange Inc    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 317672,
    "name": "Worldwide Medical Inc    ( Charlotte NC USA )",
    "country": "United States"
  },
  {
    "id": 457010,
    "name": "Worthington Cylinder Corp    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 460547,
    "name": "Wound Care Innovations LLC    ( Addison TX USA )",
    "country": "United States"
  },
  {
    "id": 298413,
    "name": "WoundMatrix Inc    ( Chadds Ford PA USA )",
    "country": "United States"
  },
  {
    "id": 467040,
    "name": "WoundVision    ( Indianapolis IN USA )",
    "country": "United States"
  },
  {
    "id": 276662,
    "name": "WPA    ( Cambridge  USA )",
    "country": "United States"
  },
  {
    "id": 102935,
    "name": "WR Medical Electronics Co    ( Stillwater MN USA )",
    "country": "United States"
  },
  {
    "id": 467386,
    "name": "Wrangler Instruments    ( Sialkot  Pakistan )",
    "country": "Pakistan"
  },
  {
    "id": 471736,
    "name": "Wren Laboratories LLC    ( Branford CT USA )",
    "country": "United States"
  },
  {
    "id": 107759,
    "name": "Wright & Filippis Inc    ( Rochester Hills MI USA )",
    "country": "United States"
  },
  {
    "id": 418651,
    "name": "Wright Cremascoli Ortho Belgium    ( Ruisbroek  USA )",
    "country": "United States"
  },
  {
    "id": 418652,
    "name": "Wright Cremascoli Ortho Germany    ( Buxtehude  USA )",
    "country": "United States"
  },
  {
    "id": 418653,
    "name": "Wright Cremascoli Ortho SpA    ( Milano MI  USA )",
    "country": "United States"
  },
  {
    "id": 392044,
    "name": "Wright Cremascoli OrthoTechnique    ( Creteil Cedex  USA )",
    "country": "United States"
  },
  {
    "id": 418655,
    "name": "Wright Cremascoli UK    ( Woking  USA )",
    "country": "United States"
  },
  {
    "id": 105679,
    "name": "Wright Linear Pump Inc/Wright Therapy Products    ( Oakdale PA USA )",
    "country": "United States"
  },
  {
    "id": 453895,
    "name": "Wright Medical Belgium NV    ( Ruisbroek  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 453894,
    "name": "Wright Medical Deutschland GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 453897,
    "name": "Wright Medical France SAS    ( Paris  France )",
    "country": "France"
  },
  {
    "id": 453893,
    "name": "Wright Medical Italy srl    ( Peschiera Borromer (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 418654,
    "name": "Wright Medical Japan KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 233266,
    "name": "Wright Medical Technology Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 171707,
    "name": "Wright Medical Technology Inc    ( Arlington TN USA )",
    "country": "United States"
  },
  {
    "id": 402316,
    "name": "Wright Products Inc    ( Decatur IL USA )",
    "country": "United States"
  },
  {
    "id": 999974,
    "name": "Wright Respirometer    (   USA )",
    "country": "United States"
  },
  {
    "id": 453896,
    "name": "Wright UK Ltd    ( Chester  England )",
    "country": "UK"
  },
  {
    "id": 155977,
    "name": "Wright-Way Inc    ( Garland TX USA )",
    "country": "United States"
  },
  {
    "id": 430475,
    "name": "WRP Asia Pacific Sdn Bhd    ( Sepang  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 192849,
    "name": "WRT-Laborbau GmbH & Co KG    ( Stadtlohn  Germany )",
    "country": "Germany"
  },
  {
    "id": 397106,
    "name": "WS Holdings Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 161116,
    "name": "Wuertt Celluloid und Drahtwarenfabrik    ( Affalterbach  Germany )",
    "country": "Germany"
  },
  {
    "id": 455680,
    "name": "Wuhan Chutian Laser Group Co Ltd    ( Wuhan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455679,
    "name": "Wuhan Jianghan Medical Pharmacy Instrument Co Ltd    ( Wuhan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 286297,
    "name": "Wulff Werke Wiepenkathen GmbH & Co KG    ( Stade  Germany )",
    "country": "Germany"
  },
  {
    "id": 282238,
    "name": "Wulfhorst    ( Guetersloh  Germany )",
    "country": "Germany"
  },
  {
    "id": 456697,
    "name": "Wuxi Medical Instrument Factory    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456679,
    "name": "WuXi XINDA Medical Device Co Ltd    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 418566,
    "name": "WUXI/MSA Safety Equipment Co Ltd    ( Wuxi City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456666,
    "name": "Wuyi Ante Medical Devices Co Ltd    ( Jinhua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 170938,
    "name": "Wy'East Medical    ( Clackamas OR USA )",
    "country": "United States"
  },
  {
    "id": 104759,
    "name": "Wyatt Technology Corp    ( Santa Barbara CA USA )",
    "country": "United States"
  },
  {
    "id": 402699,
    "name": "Wyeth Consumer Healthcare    ( Madison NJ USA )",
    "country": "United States"
  },
  {
    "id": 431495,
    "name": "Wyeth Pharmaceuticals    ( Collegeville PA USA )",
    "country": "United States"
  },
  {
    "id": 402023,
    "name": "Wyndgate Technologies    ( El Dorado Hills CA USA )",
    "country": "United States"
  },
  {
    "id": 345600,
    "name": "Wyse Technology    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 185399,
    "name": "WZF Foton SA    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 462974,
    "name": "X-Bolt Orthopaedics    ( Dublin 9  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 106897,
    "name": "X-Cel X-Ray Corp    ( Crystal Lake IL USA )",
    "country": "United States"
  },
  {
    "id": 106898,
    "name": "X-Ray Marketing Assoc    ( Romeoville IL USA )",
    "country": "United States"
  },
  {
    "id": 106213,
    "name": "X-Ray Medical Electronics Inc    ( Nashville TN USA )",
    "country": "United States"
  },
  {
    "id": 305635,
    "name": "X-Ray Supplies Pty Ltd    ( Baulkham Hills  Australia )",
    "country": "Australia"
  },
  {
    "id": 435603,
    "name": "X-Ray Systems Ltd    ( Dalgety Bay  USA )",
    "country": "United States"
  },
  {
    "id": 346960,
    "name": "X-Rite Asia Pacific Ltd (Hong Kong)    ( Quarry Bay  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 401857,
    "name": "X-Rite Asia Pacific Ltd (Japan)    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 402201,
    "name": "X-Rite Asia Pacific Ltd (Singapore)    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 359553,
    "name": "X-Rite GmbH    ( Neu-Isenburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 401858,
    "name": "X-Rite GmbH (Czech Republic)    ( Vyskov  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 102741,
    "name": "X-Rite Inc    ( Grand Rapids MI USA )",
    "country": "United States"
  },
  {
    "id": 346966,
    "name": "X-Rite Ltd    ( Stockport  England )",
    "country": "UK"
  },
  {
    "id": 359554,
    "name": "X-Rite Mediterranee    ( Massy  France )",
    "country": "France"
  },
  {
    "id": 454145,
    "name": "X-spine Systems Inc    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 408772,
    "name": "Xanthus Life Sciences    ( Cambridge MA USA )",
    "country": "United States"
  },
  {
    "id": 407358,
    "name": "Xantrex Technology (Spain)    ( Sant Just Desvern (Barcelona)  Spain )",
    "country": "Spain"
  },
  {
    "id": 407357,
    "name": "Xantrex Technology Inc (Canada)    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 426344,
    "name": "Xantrex Technology Inc (US)    ( Arlington WA USA )",
    "country": "United States"
  },
  {
    "id": 457501,
    "name": "Xavant Technology Pty Ltd    ( Pretoria  South Africa )",
    "country": "South Africa"
  },
  {
    "id": 454066,
    "name": "Xcelens SA    ( Geneve  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 455905,
    "name": "Xenex Healthcare Services LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 415958,
    "name": "Xeniss Life Science Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458124,
    "name": "Xenosys    ( Incheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 372526,
    "name": "Xerox Corp    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 999597,
    "name": "Xerox Corporation    ( Norwalk CT USA )",
    "country": "United States"
  },
  {
    "id": 463014,
    "name": "Xhale Inc    ( Gainesville FL USA )",
    "country": "United States"
  },
  {
    "id": 466641,
    "name": "Xi'an iHelp Wearable Electronic Co Ltd    ( Xi'an  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456681,
    "name": "Xi'an Sancai Electronic Co Ltd    ( Xi'an  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456523,
    "name": "Xiantao Hongwei Nonwoven Products Co Ltd    ( Xiantao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456522,
    "name": "Xiantao Que-Yeh Protection Products Co Ltd    ( Xiantao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456520,
    "name": "Xiantao Sword Disposable Protective Products Co Lt    ( Xiantao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456519,
    "name": "Xiantao Yongli Medical Prouducts Co Ltd    ( Guangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 108651,
    "name": "Xillix Technologies Corp    ( Richmond BC USA )",
    "country": "United States"
  },
  {
    "id": 415954,
    "name": "Xin Huafeng Medical Instrument & Equipment Co Ltd    ( Nanhai City  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456517,
    "name": "Xinle Huabao Medical Products Co Ltd    ( Xinle  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 107054,
    "name": "Xintec Corp    (   USA )",
    "country": "United States"
  },
  {
    "id": 383552,
    "name": "XION GmbH    ( Berlin  Germany )",
    "country": "Germany"
  },
  {
    "id": 451694,
    "name": "Xiros plc    ( Leeds  England )",
    "country": "UK"
  },
  {
    "id": 354560,
    "name": "Xishan Medical Instrument Factory    ( Wuxi  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 163023,
    "name": "XiTec LLC    ( East Windsor CT USA )",
    "country": "United States"
  },
  {
    "id": 375502,
    "name": "XLTEK    ( Oakville ON Canada )",
    "country": "Canada"
  },
  {
    "id": 266253,
    "name": "XLTEK Korea Co Ltd    ( Seoul  USA )",
    "country": "United States"
  },
  {
    "id": 457821,
    "name": "Xlumena    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 365568,
    "name": "Xmark Corp    ( Ottawa ON Canada )",
    "country": "Canada"
  },
  {
    "id": 187872,
    "name": "XMS Corp    ( Rocklin CA USA )",
    "country": "United States"
  },
  {
    "id": 454408,
    "name": "XO Care A/S    ( Handvaerkersvinget 6  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 450505,
    "name": "Xodus Medical Inc    ( New Kensington PA USA )",
    "country": "United States"
  },
  {
    "id": 452654,
    "name": "Xoft Inc    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 454371,
    "name": "Xograph Healthcare Ltd    ( Tetbury  England )",
    "country": "UK"
  },
  {
    "id": 272619,
    "name": "Xograph Imaging Systems Ltd    ( Tetbury  USA )",
    "country": "United States"
  },
  {
    "id": 199565,
    "name": "Xomed Surgical Products Inc    (   USA )",
    "country": "United States"
  },
  {
    "id": 999675,
    "name": "Xonics Medical Systems    (   USA )",
    "country": "United States"
  },
  {
    "id": 440194,
    "name": "Xoran Technologies    ( Ann Arbor MI USA )",
    "country": "United States"
  },
  {
    "id": 462695,
    "name": "XOS Technologies Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 461497,
    "name": "Xpress Systems LLC    ( Columbus OH USA )",
    "country": "United States"
  },
  {
    "id": 452187,
    "name": "XR Imaging Network    ( Warwick RI USA )",
    "country": "United States"
  },
  {
    "id": 106081,
    "name": "Xrad Corp    ( Burbank CA USA )",
    "country": "United States"
  },
  {
    "id": 455372,
    "name": "XSENSOR Technology Corp    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 456431,
    "name": "Xstrahl Ltd    ( Camberley  England )",
    "country": "UK"
  },
  {
    "id": 187880,
    "name": "Xtend Communications Corp    ( New York NY USA )",
    "country": "United States"
  },
  {
    "id": 403605,
    "name": "Xtrak Medical Inc    ( Salem NH USA )",
    "country": "United States"
  },
  {
    "id": 459451,
    "name": "Xtreme Network Solutions LLC    ( Austin TX USA )",
    "country": "United States"
  },
  {
    "id": 456488,
    "name": "Xuzhou Kaixin Electronic Instrument Co Ltd    ( Xuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456486,
    "name": "XUZHOU LEO MEDICAL EQUIPMENTS Co Ltd    ( Xuzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 460994,
    "name": "Xybix Systems Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 457466,
    "name": "Xylem Inc    ( Rye Brook NY USA )",
    "country": "United States"
  },
  {
    "id": 450594,
    "name": "Y H Biotek Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 455721,
    "name": "Yaan Device Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 152425,
    "name": "Yamada Shadowless Lamp Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 460612,
    "name": "Yamaha Motor Corp    ( Cypress CA USA )",
    "country": "United States"
  },
  {
    "id": 337012,
    "name": "Yamahachi Dental Mfg Co    ( Gamagori  Japan )",
    "country": "Japan"
  },
  {
    "id": 267428,
    "name": "Yamamoto Kogaku Co Ltd    ( Higashiosaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 158030,
    "name": "Yamamoto Vinita Co Ltd    ( Osaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 157056,
    "name": "Yamaoka International Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 171746,
    "name": "Yamato Corp    ( Colorado Springs CO USA )",
    "country": "United States"
  },
  {
    "id": 174146,
    "name": "Yamato Electric Machine Co Ltd    ( Kasukabe  Japan )",
    "country": "Japan"
  },
  {
    "id": 289775,
    "name": "Yamato Sanki Co Ltd    ( Tokyo  USA )",
    "country": "United States"
  },
  {
    "id": 158035,
    "name": "Yamato Scale Co Ltd    ( Akashi  Japan )",
    "country": "Japan"
  },
  {
    "id": 298412,
    "name": "Yamato Scale GmbH    ( Willich  Germany )",
    "country": "Germany"
  },
  {
    "id": 104576,
    "name": "Yamato Scientific America Inc    ( Santa Clara CA USA )",
    "country": "United States"
  },
  {
    "id": 140353,
    "name": "Yamato Scientific Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 344834,
    "name": "Yamato Tech Corp    ( Surrey BC Canada )",
    "country": "Canada"
  },
  {
    "id": 456529,
    "name": "Yancheng Huida Medical Instruments Co Ltd    ( Yancheng  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456530,
    "name": "Yangzhou Medline Industry Co Ltd    ( Yangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 234658,
    "name": "Yardley Software    ( Northfield NJ USA )",
    "country": "United States"
  },
  {
    "id": 107591,
    "name": "Yarter-Tek Corp    ( Denver CO USA )",
    "country": "United States"
  },
  {
    "id": 459399,
    "name": "Yaskawa America Inc    ( Miamisburg OH USA )",
    "country": "United States"
  },
  {
    "id": 170826,
    "name": "Yates X-Ray Co Inc    ( Huntington Beach CA USA )",
    "country": "United States"
  },
  {
    "id": 185586,
    "name": "Yavit Zaklad Mechaniki Precyzyjnej Eksport-Import    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 177144,
    "name": "Yayoi Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 454719,
    "name": "YBUX s r o    ( Brno  Czech Republic )",
    "country": "Czech Republic"
  },
  {
    "id": 439883,
    "name": "YD BioTech Beijing Co Ltd    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 392049,
    "name": "YD Diagnostics    ( Yongin  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 452561,
    "name": "Yee Mau Industrial Co    ( Kwai Chung  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 459385,
    "name": "YEI Corp    ( Portsmouth OH USA )",
    "country": "United States"
  },
  {
    "id": 459310,
    "name": "Yellow Dog Software    ( Norfolk VA USA )",
    "country": "United States"
  },
  {
    "id": 182879,
    "name": "Yellow Springs Instrument Co Inc    ( Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 182877,
    "name": "Yellow Springs Instrument Co Ltd    ( Farnborough  England )",
    "country": "UK"
  },
  {
    "id": 281289,
    "name": "Yeti Dentalprodukte GmbH    ( Engen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451022,
    "name": "Yi Shing Co Ltd    ( Central Hong Kong  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 459492,
    "name": "Yield Engineering Systems Inc    ( Livermore CA USA )",
    "country": "United States"
  },
  {
    "id": 455585,
    "name": "Yiin Wang Industrila co Ltd    (   Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 457506,
    "name": "Yisailon Co Ltd    ( Shanghai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 286893,
    "name": "Zacharias Schwarz Inh E Betzler Chirurgische Instr    ( Tuttlingen/Nendingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456527,
    "name": "Yiwu Jiekang Medical Articles Co Ltd    ( Yiwu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 999394,
    "name": "YJL Corporation    (   USA )",
    "country": "United States"
  },
  {
    "id": 332447,
    "name": "Yokogawa Corp of America    ( Newnan GA USA )",
    "country": "United States"
  },
  {
    "id": 285557,
    "name": "Yokogawa Deutschland GmbH    ( Ratingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 158036,
    "name": "Yokogawa Electric Corp    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 451243,
    "name": "Yon Don Canada    ( Burnaby BC Canada )",
    "country": "Canada"
  },
  {
    "id": 163236,
    "name": "Yon Don Enterprise Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 451261,
    "name": "Yorco Sales Pvt Ltd    ( New Delhi  India )",
    "country": "India"
  },
  {
    "id": 106346,
    "name": "York Inc    ( Lyman SC USA )",
    "country": "United States"
  },
  {
    "id": 455455,
    "name": "Yoshida-Toei Engineering Co Ltd    ( Yokohama  Japan )",
    "country": "Japan"
  },
  {
    "id": 457923,
    "name": "YouMedical    ( Amsterdam  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 102690,
    "name": "Young Dental Mfg Co    ( Earth City MO USA )",
    "country": "United States"
  },
  {
    "id": 456404,
    "name": "YOUNG IL M Co Ltd    ( Sungnam  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 456280,
    "name": "Young Won Medical Co Ltd    ( Siheung  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 451944,
    "name": "Youngdo Ind Co Ltd    ( Busan  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 103954,
    "name": "Youngs Inc    ( Souderton PA USA )",
    "country": "United States"
  },
  {
    "id": 456487,
    "name": "Youzhou Super Union Medical Material Co Ltd    ( Yizheng  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 462988,
    "name": "Ypsomed AG    ( Burgdorf  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 285598,
    "name": "Ysebaert SA    ( Frepillon  France )",
    "country": "France"
  },
  {
    "id": 440731,
    "name": "YSI (Hong Kong) Ltd    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 440730,
    "name": "YSI (UK) Ltd    ( Fleet  England )",
    "country": "UK"
  },
  {
    "id": 321022,
    "name": "YSI Inc    ( Yellow Springs OH USA )",
    "country": "United States"
  },
  {
    "id": 374363,
    "name": "YSI Temperature    ( Dayton OH USA )",
    "country": "United States"
  },
  {
    "id": 279822,
    "name": "ystral GmbH    ( Ballrechten-Dottingen  Germany )",
    "country": "Germany"
  },
  {
    "id": 456047,
    "name": "YTY Industry Sdn Bhd    ( Petaling Jaya  Malaysia )",
    "country": "Malaysia"
  },
  {
    "id": 455722,
    "name": "Yueh Sheng Electronic Industrial Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 456667,
    "name": "Yueqing Kangfu Medical Equipment Factory    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456004,
    "name": "Yukor Co Ltd    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 456278,
    "name": "Yushin Medical Co Ltd    ( Bucheon  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 455999,
    "name": "Yuyama Co Ltd    ( Toyonaka  Japan )",
    "country": "Japan"
  },
  {
    "id": 457454,
    "name": "Yuyao Ruixin Medical Appliance Co Ltd    ( Yuyao  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 458148,
    "name": "Z & Z Medical Inc    ( Cedar Falls IA USA )",
    "country": "United States"
  },
  {
    "id": 330945,
    "name": "Z Technologies LLC    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 457444,
    "name": "Z-Coil Footwear Inc    ( Albuquerque NM USA )",
    "country": "United States"
  },
  {
    "id": 384435,
    "name": "Z-KAT Inc    ( Hollywood FL USA )",
    "country": "United States"
  },
  {
    "id": 452556,
    "name": "Z-Man Corp    ( Clinton MI USA )",
    "country": "United States"
  },
  {
    "id": 455203,
    "name": "Z-Medica Corp    ( Wallingford CT USA )",
    "country": "United States"
  },
  {
    "id": 451678,
    "name": "Z-Sciences Corp    ( Westmount PQ Canada )",
    "country": "Canada"
  },
  {
    "id": 452891,
    "name": "Z-Tec Mobility    ( Brierley Hill  England )",
    "country": "UK"
  },
  {
    "id": 457838,
    "name": "Zaber Technologies    ( Vancouver BC Canada )",
    "country": "Canada"
  },
  {
    "id": 285258,
    "name": "Zuern GmbH & Co KG    ( Osterburken  USA )",
    "country": "United States"
  },
  {
    "id": 279715,
    "name": "Zahnfabrik Bad Nauheim GmbH & Co Luxor KG    ( Bad Nauheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 284346,
    "name": "Zak Medizin-Technik    ( Marktheidenfeld  Germany )",
    "country": "Germany"
  },
  {
    "id": 331920,
    "name": "Zaklad Aparatury Elektromedycznej    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 391381,
    "name": "Zaklad Szlifierek Ponar-Lodz Sp z o o    ( Lodz  USA )",
    "country": "United States"
  },
  {
    "id": 185592,
    "name": "Zalmed Zaklad Aparatury    ( Warszawa  Poland )",
    "country": "Poland"
  },
  {
    "id": 399262,
    "name": "Zalom Communications Pty Ltd    ( Lane Cove  Australia )",
    "country": "Australia"
  },
  {
    "id": 409578,
    "name": "ZALUX SA    ( Utebo (Zaragoza)  Spain )",
    "country": "Spain"
  },
  {
    "id": 174439,
    "name": "Zammi Instrumental Ltda    ( Duque de Caxias-RJ  USA )",
    "country": "United States"
  },
  {
    "id": 287109,
    "name": "Zan Messgeraete GmbH    ( Oberthulba  USA )",
    "country": "United States"
  },
  {
    "id": 284445,
    "name": "ZAO Oriola SPb    ( St Petersburg  USA )",
    "country": "United States"
  },
  {
    "id": 454779,
    "name": "Zap Lasers    ( Pleasant Hill CA USA )",
    "country": "United States"
  },
  {
    "id": 285945,
    "name": "Zapf Instrumente    ( Sarstedt  USA )",
    "country": "United States"
  },
  {
    "id": 287209,
    "name": "Zarges Leichtbau GmbH    ( Weilheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458239,
    "name": "Zargis Medical Corp    ( Freehold NJ USA )",
    "country": "United States"
  },
  {
    "id": 284889,
    "name": "Zart GmbH Designmoebel    ( Neuhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 458891,
    "name": "Zasio Enterprises Inc    ( Boise ID USA )",
    "country": "United States"
  },
  {
    "id": 462965,
    "name": "Zavation    ( Brandon MS USA )",
    "country": "United States"
  },
  {
    "id": 284793,
    "name": "Zawatzky GmbH    ( Neckargemuend  Germany )",
    "country": "Germany"
  },
  {
    "id": 460611,
    "name": "Zayo Group LLC    ( Boulder CO USA )",
    "country": "United States"
  },
  {
    "id": 458937,
    "name": "Zcura LLC    ( Woodstock IL USA )",
    "country": "United States"
  },
  {
    "id": 153928,
    "name": "ZDL Inc    ( Lexington KY USA )",
    "country": "United States"
  },
  {
    "id": 108114,
    "name": "Zebra Technologies Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 407275,
    "name": "Zebra Technologies Europe Ltd (Denmark)    ( Ballerup  Denmark )",
    "country": "Denmark"
  },
  {
    "id": 407284,
    "name": "Zebra Technologies Europe Ltd (Dubai)    ( Dubai  United Arab Emirates )",
    "country": "United Arab Emirates"
  },
  {
    "id": 407274,
    "name": "Zebra Technologies Europe Ltd (United Kingdom)    ( Bourne End  England )",
    "country": "UK"
  },
  {
    "id": 407279,
    "name": "Zebra Technologies France    ( Charenton-le-Pont  France )",
    "country": "France"
  },
  {
    "id": 407280,
    "name": "Zebra Technologies Germany    ( Meerbusch  Germany )",
    "country": "Germany"
  },
  {
    "id": 407282,
    "name": "Zebra Technologies Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 407283,
    "name": "Zebra Technologies Korea    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 407281,
    "name": "Zebra Technologies Singapore    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 455838,
    "name": "zebris Medical GmbH    ( Isny  Germany )",
    "country": "Germany"
  },
  {
    "id": 281014,
    "name": "Zedin Instrumente Walter Zepf    ( Duerbheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 451834,
    "name": "Zedmed Medical Software Solutions    ( South Melbourne  Australia )",
    "country": "Australia"
  },
  {
    "id": 202305,
    "name": "Zee Medical Inc    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 321919,
    "name": "Zefon International Inc    ( St Petersburg FL USA )",
    "country": "United States"
  },
  {
    "id": 293833,
    "name": "Zehnder AG Apparatebau    ( Zuerich  USA )",
    "country": "United States"
  },
  {
    "id": 462857,
    "name": "Zeigler Enterprises Inc    ( Midland MI USA )",
    "country": "United States"
  },
  {
    "id": 456589,
    "name": "Zeisberg CNC-Systeme GmbH    ( Hofheim-Wallau  Germany )",
    "country": "Germany"
  },
  {
    "id": 286317,
    "name": "Zeisberg GmbH    ( Hofheim  Germany )",
    "country": "Germany"
  },
  {
    "id": 458873,
    "name": "Zelcore Inc    ( Hagerstown MD USA )",
    "country": "United States"
  },
  {
    "id": 104047,
    "name": "Zellweger Analytics Inc    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 346089,
    "name": "Zellweger Analytics Inc    ( Sunrise FL USA )",
    "country": "United States"
  },
  {
    "id": 458432,
    "name": "ZELTIQ Aesthetics Inc    ( Pleasanton CA USA )",
    "country": "United States"
  },
  {
    "id": 306533,
    "name": "Zeltner Bueroreform    ( Neukirchen/Vluyn  USA )",
    "country": "United States"
  },
  {
    "id": 305945,
    "name": "Zencor Pty Ltd    ( Carlton  Australia )",
    "country": "Australia"
  },
  {
    "id": 101871,
    "name": "Zenex Corp    ( Elk Grove Village IL USA )",
    "country": "United States"
  },
  {
    "id": 435255,
    "name": "Zenitel Belgium NV    ( Zellik  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 435256,
    "name": "Zenitel CSS France SA    ( Nanterre Cedex  France )",
    "country": "France"
  },
  {
    "id": 435254,
    "name": "Zenitel Norway ASA    ( Oslo  Norway )",
    "country": "Norway"
  },
  {
    "id": 435257,
    "name": "Zenitel Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 431334,
    "name": "Zenitel USA Inc    ( Kansas City MO USA )",
    "country": "United States"
  },
  {
    "id": 105474,
    "name": "Zenith Electronics Corp    ( Lincolnshire IL USA )",
    "country": "United States"
  },
  {
    "id": 271594,
    "name": "Zenith Medical Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 394321,
    "name": "Zenith Medical Systems Ltd    ( Manchester  USA )",
    "country": "United States"
  },
  {
    "id": 332452,
    "name": "Zenith Surgical Inc    ( Bombay  India )",
    "country": "India"
  },
  {
    "id": 305956,
    "name": "Zenith Ultrasonics Cleaning Systems Inc    ( Norwood NJ USA )",
    "country": "United States"
  },
  {
    "id": 332791,
    "name": "Zenith/DMG    ( Englewood NJ USA )",
    "country": "United States"
  },
  {
    "id": 454414,
    "name": "Zenni Optical    ( Novato CA USA )",
    "country": "United States"
  },
  {
    "id": 103593,
    "name": "Zep Mfg Co    ( Atlanta GA USA )",
    "country": "United States"
  },
  {
    "id": 464104,
    "name": "Zephyr Sleep Technologies Inc    ( Calgary AB Canada )",
    "country": "Canada"
  },
  {
    "id": 462593,
    "name": "Zephyr Technology Corp    ( Annapolis MD USA )",
    "country": "United States"
  },
  {
    "id": 229858,
    "name": "Zeppelin Chirurgische Instrumente GmbH    ( Pullach  Germany )",
    "country": "Germany"
  },
  {
    "id": 453537,
    "name": "ZeptoMetrix Corp    ( Buffalo NY USA )",
    "country": "United States"
  },
  {
    "id": 272819,
    "name": "Zerowet    ( Palos Verdes Peninsula CA USA )",
    "country": "United States"
  },
  {
    "id": 452081,
    "name": "Zerusa Ltd    ( Galway  Ireland )",
    "country": "Ireland"
  },
  {
    "id": 452142,
    "name": "Zerusa North America LLC    ( Boston MA USA )",
    "country": "United States"
  },
  {
    "id": 453813,
    "name": "Zest Anchore Inc    ( Escondido CA USA )",
    "country": "United States"
  },
  {
    "id": 456378,
    "name": "Zeta Corp    ( Gunpo  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 458933,
    "name": "Zeta Instruments    ( San Jose CA USA )",
    "country": "United States"
  },
  {
    "id": 176029,
    "name": "Zetron Inc    ( Redmond WA USA )",
    "country": "United States"
  },
  {
    "id": 453258,
    "name": "Zetta Medical Technologies LLC    ( Lake Zurich IL USA )",
    "country": "United States"
  },
  {
    "id": 104212,
    "name": "Zeus Industrial Products Inc    ( Orangeburg SC USA )",
    "country": "United States"
  },
  {
    "id": 107067,
    "name": "Zeus Scientific Inc    ( Raritan NJ USA )",
    "country": "United States"
  },
  {
    "id": 335430,
    "name": "Zevco Medical Products    ( Northbrook IL USA )",
    "country": "United States"
  },
  {
    "id": 177921,
    "name": "ZEVEX International    ( Salt Lake City UT USA )",
    "country": "United States"
  },
  {
    "id": 176924,
    "name": "Zewa AG    ( Laengenbold  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 456576,
    "name": "Zewa Inc    ( Fort Myers FL USA )",
    "country": "United States"
  },
  {
    "id": 457250,
    "name": "Zgrum Medical    ( Tuscon AZ USA )",
    "country": "United States"
  },
  {
    "id": 456494,
    "name": "Zhangjiagang All Fine Rubber & Plastic Products Co    ( Zhangjiagang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 467123,
    "name": "Zhangjiagang Bestran Technology Co Ltd    ( Zhangjiagang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291517,
    "name": "Zhangjiagang Rixin Medical Equipment Co Ltd    ( Zhangjiagang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456526,
    "name": "ZhangJiaGang RongChang Machinery Manufacture Co Lt    ( Zhangjigang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456493,
    "name": "Zhangjiagang Xiehe Medical Apparatus & Instruments    ( Zhangjiagang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456492,
    "name": "Zhanjiang Star Enterprise Co Ltd    ( Zhanjiang  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456651,
    "name": "Zhejiang Baite Medical Appliances Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456678,
    "name": "Zhejiang DA JI Medical Instruments Co Ltd    ( Shangyu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456491,
    "name": "Zhejiang FERT Medical Device Co Ltd    (   People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456490,
    "name": "Zhejiang Gongdong Medical Technology Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456684,
    "name": "Zhejiang Guangci Medical Appliance Co Ltd    ( Ningbo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455681,
    "name": "Zhejiang Haisheng Medical Device Co Ltd    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455684,
    "name": "Zhejiang Hongyu Medical Commodity Co Ltd    ( Yiwu  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456687,
    "name": "Zhejiang Huafu Medical Equipment Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456691,
    "name": "Zhejiang JingHuan Medical Articles Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456680,
    "name": "Zhejiang Kang Li Di Medical Articles Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456692,
    "name": "Zhejiang Kangdelai Medical Equipment Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455688,
    "name": "Zhejiang KangKang Medical-Device Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455685,
    "name": "Zhejiang Kanglidi Medical Articles Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455715,
    "name": "Zhejiang Lingyang Medical Apparatus Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455748,
    "name": "Zhejiang Orient Gene Biotech Co Ltd    ( Anji  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455705,
    "name": "Zhejiang Oujian Medical Apparatus Co Ltd    ( Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455761,
    "name": "Zhejiang Pengtian Medical Instrument Co Ltd    ( Zhuji  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 456682,
    "name": "Zhejiang Qiangsheng Medical Engineering Co Ltd    ( Fenghua  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291558,
    "name": "Zhejiang Saoxing Weixing Machinery Co Ltd    ( Shaoxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455749,
    "name": "Zhejiang Sujia Medical Device Co Ltd    ( Jiaxing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455754,
    "name": "Zhejiang U-REAL Medical Technology Co Ltd    ( Huangyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291513,
    "name": "Zhejiang University Medical Instrument Co Ltd    ( Hangzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455758,
    "name": "Zhejiang Wenzhou Packaging Import & Export Corp    ( West Wenzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291566,
    "name": "Zhejiang Xianju Medical Instrument Factory    ( Xianju  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455713,
    "name": "Zhejiang Yusheng Medical Instrument Co Ltd    ( Taizhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455759,
    "name": "Zhengzhou Dison Instrument and Meter Co Ltd    ( Zhengzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291076,
    "name": "Zhongshan Audiofono Hearing Aids Mfg Co    ( Zhongshan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 291078,
    "name": "Zhuhai R & DT Instrument & Equipment Co Ltd    ( Zhuhai  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 455762,
    "name": "Zibo Hengchang Plastic and Rubber Products Co Ltd    ( Zibo  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 283199,
    "name": "Ziegra-Eismaschinen GmbH    ( Isernhagen  Germany )",
    "country": "Germany"
  },
  {
    "id": 439942,
    "name": "Ziehm Imaging GmbH    ( Nuernberg  Germany )",
    "country": "Germany"
  },
  {
    "id": 439943,
    "name": "Ziehm Imaging Inc    ( Orlando FL USA )",
    "country": "United States"
  },
  {
    "id": 450899,
    "name": "Ziehm Imaging Singapore Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 460602,
    "name": "Ziemer Ophthalmic Systems AG    ( Port  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 460603,
    "name": "Ziemer USA Inc    ( Alton IL USA )",
    "country": "United States"
  },
  {
    "id": 459186,
    "name": "Zila Inc    ( Fort Collins CO USA )",
    "country": "United States"
  },
  {
    "id": 398384,
    "name": "ZIMCO Berufskleiderfabrik    ( Olten  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 440755,
    "name": "Zimmer (Finland)    ( Espoo  Finland )",
    "country": "Finland"
  },
  {
    "id": 440753,
    "name": "Zimmer Beijing Representative Office    ( Beijing  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 440748,
    "name": "Zimmer Chirurgie GmbH    ( Kiel  Germany )",
    "country": "Germany"
  },
  {
    "id": 440762,
    "name": "Zimmer CIS    ( Moskva  USA )",
    "country": "United States"
  },
  {
    "id": 440672,
    "name": "Zimmer Dental    ( Carlsbad CA USA )",
    "country": "United States"
  },
  {
    "id": 440707,
    "name": "Zimmer Dental GmbH    ( Freiburg  Germany )",
    "country": "Germany"
  },
  {
    "id": 453640,
    "name": "Zimmer Dental Italy    ( Vittorio Veneto (TV)  Italy )",
    "country": "Italy"
  },
  {
    "id": 440711,
    "name": "Zimmer Dental Ltd    ( Ramat Gan  Israel )",
    "country": "Israel"
  },
  {
    "id": 440710,
    "name": "Zimmer Dental Pty Ltd    ( Frenchs Forest  Australia )",
    "country": "Australia"
  },
  {
    "id": 440709,
    "name": "Zimmer Dental Sarl    ( Rungis Cedex  France )",
    "country": "France"
  },
  {
    "id": 454603,
    "name": "Zimmer GmbH    ( Winterthur  Switzerland )",
    "country": "Switzerland"
  },
  {
    "id": 102165,
    "name": "Zimmer Inc    ( Warsaw IN USA )",
    "country": "United States"
  },
  {
    "id": 440758,
    "name": "Zimmer KK    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 440759,
    "name": "Zimmer Korea Co Ltd    ( Seoul  Republic of Korea )",
    "country": "Korea"
  },
  {
    "id": 157058,
    "name": "Zimmer Ltd    ( Swindon  England )",
    "country": "UK"
  },
  {
    "id": 243015,
    "name": "Zimmer MedizinSystems    ( Irvine CA USA )",
    "country": "United States"
  },
  {
    "id": 232380,
    "name": "Zimmer MedizinSystems GmbH    ( Neu-Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 440760,
    "name": "Zimmer Netherlands bv    ( Utrecht  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 440761,
    "name": "Zimmer New Zealand Ltd    ( Auckland  New Zealand )",
    "country": "New Zealand"
  },
  {
    "id": 440752,
    "name": "Zimmer NV    ( Wemmel  Belgium )",
    "country": "Belgium"
  },
  {
    "id": 440675,
    "name": "Zimmer of Canada Ltd    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 409954,
    "name": "Zimmer Orthopaedic Surgical Products    ( Dover OH USA )",
    "country": "United States"
  },
  {
    "id": 440763,
    "name": "Zimmer Pte Ltd    ( Singapore  Republic of Singapore )",
    "country": "Singapore"
  },
  {
    "id": 440756,
    "name": "Zimmer Pte Ltd (Hong Kong)    ( Kowloon  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 440749,
    "name": "Zimmer Pty Ltd Australia    ( Frenchs Forest  Australia )",
    "country": "Australia"
  },
  {
    "id": 440764,
    "name": "Zimmer SA    ( Barcelona  Spain )",
    "country": "Spain"
  },
  {
    "id": 151036,
    "name": "Zimmer SAS    ( Courbevoie  France )",
    "country": "France"
  },
  {
    "id": 440673,
    "name": "Zimmer Spine    ( Minneapolis MN USA )",
    "country": "United States"
  },
  {
    "id": 452639,
    "name": "Zimmer Spine Europe    ( Bordeaux  France )",
    "country": "France"
  },
  {
    "id": 440757,
    "name": "Zimmer srl Italy    ( San Donato Milanese (MI)  Italy )",
    "country": "Italy"
  },
  {
    "id": 440765,
    "name": "Zimmer Taiwan Co Ltd    ( Taipei  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 281658,
    "name": "Zinsser Analytic GmbH    ( Frankfurt  Germany )",
    "country": "Germany"
  },
  {
    "id": 451573,
    "name": "Zinsser North America    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 457328,
    "name": "Zinsser North America Inc    ( Northridge CA USA )",
    "country": "United States"
  },
  {
    "id": 453920,
    "name": "Ziosoft Inc Japan    ( Tokyo  Japan )",
    "country": "Japan"
  },
  {
    "id": 453919,
    "name": "Ziosoft Inc USA    ( Redwood City CA USA )",
    "country": "United States"
  },
  {
    "id": 458872,
    "name": "Zip Pneumatics Inc    ( Raytown MT USA )",
    "country": "United States"
  },
  {
    "id": 474929,
    "name": "ZipLine Medical Inc    ( Campbell CA USA )",
    "country": "United States"
  },
  {
    "id": 458050,
    "name": "Zippswitch Products    ( Bay Shore NY USA )",
    "country": "United States"
  },
  {
    "id": 285266,
    "name": "Zirbus Apparate- und Maschinenbau GmbH    ( Bad Grund  Germany )",
    "country": "Germany"
  },
  {
    "id": 103956,
    "name": "Zirc Co    ( Buffalo MN USA )",
    "country": "United States"
  },
  {
    "id": 456983,
    "name": "Zirkonzahn Srl    ( Gais (BZ)  Italy )",
    "country": "Italy"
  },
  {
    "id": 459035,
    "name": "ZirMed Inc    ( Louisville KY USA )",
    "country": "United States"
  },
  {
    "id": 458871,
    "name": "ZIVELO LLC    ( Marion IN USA )",
    "country": "United States"
  },
  {
    "id": 460995,
    "name": "Zix Corp    ( Dallas TX USA )",
    "country": "United States"
  },
  {
    "id": 161123,
    "name": "ZK Hospital Bedarfs GmbH    ( Oer-Erkenschwick  Germany )",
    "country": "Germany"
  },
  {
    "id": 328284,
    "name": "ZLB Behring    ( King of Prussia PA USA )",
    "country": "United States"
  },
  {
    "id": 458042,
    "name": "ZMI Electronics    ( Kaohsiung  Taiwan )",
    "country": "Taiwan"
  },
  {
    "id": 356838,
    "name": "ZNC International Inc    ( Stamford CT USA )",
    "country": "United States"
  },
  {
    "id": 392109,
    "name": "Zoe Medical Inc    ( Topsfield MA USA )",
    "country": "United States"
  },
  {
    "id": 170827,
    "name": "Zoetek Medical Inc    ( Victor NY USA )",
    "country": "United States"
  },
  {
    "id": 407382,
    "name": "ZOLL Canada    ( Mississauga ON Canada )",
    "country": "Canada"
  },
  {
    "id": 453359,
    "name": "Zoll Circulation Inc    ( Sunnyvale CA USA )",
    "country": "United States"
  },
  {
    "id": 441258,
    "name": "ZOLL Data Systems    ( Broomfield CO USA )",
    "country": "United States"
  },
  {
    "id": 451480,
    "name": "ZOLL Lifecor Corp    ( Pittsburgh PA USA )",
    "country": "United States"
  },
  {
    "id": 406736,
    "name": "ZOLL Medical (France)    ( Coignieres  France )",
    "country": "France"
  },
  {
    "id": 406738,
    "name": "ZOLL Medical (Japan)    ( Yokohama City  Japan )",
    "country": "Japan"
  },
  {
    "id": 340577,
    "name": "ZOLL Medical Australia Pty Ltd    ( St Leonards  Australia )",
    "country": "Australia"
  },
  {
    "id": 150032,
    "name": "ZOLL Medical Corp    ( Chelmsford MA USA )",
    "country": "United States"
  },
  {
    "id": 272658,
    "name": "ZOLL Medical Corp (The Netherlands)    ( Dodewaard  The Netherlands )",
    "country": "Netherlands"
  },
  {
    "id": 406734,
    "name": "ZOLL Medical Deutschland    ( Koeln  Germany )",
    "country": "Germany"
  },
  {
    "id": 307944,
    "name": "ZOLL Medical East & Africa Region Office    ( Aman  Jordan )",
    "country": "Jordan"
  },
  {
    "id": 340608,
    "name": "ZOLL Medical UK Ltd    ( Runcorn  England )",
    "country": "UK"
  },
  {
    "id": 452938,
    "name": "Zonare Medical Canada ltd    ( Beaverton ON Canada )",
    "country": "Canada"
  },
  {
    "id": 452939,
    "name": "Zonare Medical Systems GmbH    ( Erlangen  Germany )",
    "country": "Germany"
  },
  {
    "id": 451034,
    "name": "Zonare Medical Systems Inc    ( Mountain View CA USA )",
    "country": "United States"
  },
  {
    "id": 452940,
    "name": "ZONARE Medical Systems UK Ltd    ( Maidenhead  England )",
    "country": "UK"
  },
  {
    "id": 285397,
    "name": "ZOOM Kommunikationssysteme GmbH    ( Plauen  Germany )",
    "country": "Germany"
  },
  {
    "id": 185593,
    "name": "Zootechnika Wytwornia Sprzetu Zootechnicznego    ( Krakow  Poland )",
    "country": "Poland"
  },
  {
    "id": 462603,
    "name": "Zotec Partners    ( Carmel IN USA )",
    "country": "United States"
  },
  {
    "id": 285410,
    "name": "Zotzmann & Stahl GmbH & Co KG    ( Pluederhausen  Germany )",
    "country": "Germany"
  },
  {
    "id": 457633,
    "name": "Zounds Hearing Inc    ( Phoenix AZ USA )",
    "country": "United States"
  },
  {
    "id": 291544,
    "name": "ZP-AS Technology Development General Co    ( Huangyan  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 284848,
    "name": "Zubler Geraetebau GmbH    ( Neu-Ulm  Germany )",
    "country": "Germany"
  },
  {
    "id": 463066,
    "name": "Zuccato HC srl    ( Verona (VR)  Italy )",
    "country": "Italy"
  },
  {
    "id": 454969,
    "name": "Zumax Medical    ( Suzhou  People's Republic of China )",
    "country": "China"
  },
  {
    "id": 176030,
    "name": "ZUMRO Inc    ( Hatboro PA USA )",
    "country": "United States"
  },
  {
    "id": 286981,
    "name": "Zumtobel Staff Deutschland Vertriebs GmbH    ( Lemgo  Germany )",
    "country": "Germany"
  },
  {
    "id": 458471,
    "name": "Zutron Medical    ( Lenexa KS USA )",
    "country": "United States"
  },
  {
    "id": 286635,
    "name": "ZVG Zellstoff-Vertriebs-GmbH    ( Troisdorf  Germany )",
    "country": "Germany"
  },
  {
    "id": 288744,
    "name": "Zybex Inc    ( San Diego CA USA )",
    "country": "United States"
  },
  {
    "id": 320756,
    "name": "ZyDoc Technologies LLC    ( Hauppauge NY USA )",
    "country": "United States"
  },
  {
    "id": 460717,
    "name": "Zygo Corp    ( Middlefield CT USA )",
    "country": "United States"
  },
  {
    "id": 332763,
    "name": "ZymeTx Inc    ( Oklahoma City OK USA )",
    "country": "United States"
  },
  {
    "id": 441475,
    "name": "Zynex Medical Inc    ( Littleton CO USA )",
    "country": "United States"
  },
  {
    "id": 454668,
    "name": "Zyno Medical LLC    ( Waltham MA USA )",
    "country": "United States"
  },
  {
    "id": 455617,
    "name": "Zynx Health Inc    ( Los Angeles CA USA )",
    "country": "United States"
  }
]
