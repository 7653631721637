import React from 'react';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
interface LogoProps {
  [key: string]: any;
}


const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `"Poppins" ,"Roboto", sans-serif`,
    fontSize: '1.4rem',
    color: 'black'
  },
  dark: {
    color: 'white'
  }
}));


const Logo: FC<LogoProps> = (props) => {
  const classes = useStyles();
  const { settings } = useSettings()

  return (
    <div title="Devicery Logo" className={clsx(classes.root, {
      [classes.dark]: (settings.theme === 'DARK')
    })}>Devicery&reg;</div>
  );
}

export default Logo;
