export const vendorsArray = [
  {
    id: 10486,
    name: 'J.W. MANNY, INC  SCARSDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 10504,
    name: 'MEDEXSUPPLY DISTRIBUTOR  Monsey NY (United States)',
    country: 'United States'
  },
  {
    id: 10532,
    name: 'LEMANS INDUSTRIES CORP  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 10535,
    name: 'CALIFORNIA ANALYTICAL  ORANGE CA (United States)',
    country: 'United States'
  },
  {
    id: 10536,
    name: 'NETECH  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 10548,
    name: 'MICROLAMP, INC.  FT LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 10549,
    name: 'S&S TECHNOLOGY  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 10552,
    name: 'HILTI, INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 10577,
    name: 'BOC GASES  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 10588,
    name: 'MILLIPORE CORP  Billerica MA (United States)',
    country: 'United States'
  },
  {
    id: 10699,
    name: 'BONDI IOVINO & FUSCO  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 10705,
    name: 'ZIMMER  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 10709,
    name: 'BURKE SUPPLY COMPANY, INC.  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 10719,
    name: 'WEAR GUARD  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 10739,
    name: 'LAURENCE FURNELL INC.  BAY SHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 10740,
    name: 'KENDALL/COVIDIEN  Mansfield MA (United States)',
    country: 'United States'
  },
  {
    id: 10748,
    name: 'BAY CORPORATION  WESTLAKE OH (United States)',
    country: 'United States'
  },
  {
    id: 10755,
    name: 'THERMO GENESIS CORP  RANCHO CORDOVA CA (United States)',
    country: 'United States'
  },
  {
    id: 10813,
    name: 'PRECISION MEDICAL SYSTEMS  NORTHAMPTON PA (United States)',
    country: 'United States'
  },
  {
    id: 10830,
    name: 'ABBOTT  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 10837,
    name: 'CARE FUSION/ ALARIS PRODUCTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 10842,
    name: 'BOEHRINGER LABORATORIES, INC.  PHOENIXVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 10936,
    name: 'PEACE MEDICAL  ORANGE NJ (United States)',
    country: 'United States'
  },
  {
    id: 10942,
    name: 'IMS, INC.  LEWISTON ME (United States)',
    country: 'United States'
  },
  {
    id: 10970,
    name: 'DALE TECHNOLOGY  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 10971,
    name: 'CENTURY FASTENERS  ELMHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 10992,
    name: 'PALMETTO, MOLLO & COMPANY  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 11012,
    name: 'VASCULAR TECHNOLOGY, INC.  NASHUA NH (United States)',
    country: 'United States'
  },
  {
    id: 11029,
    name: 'NYMCO ASSOCIATES, INC.  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 11049,
    name: 'HANOVER INSURANCE COMPANY  WOBURN MA (United States)',
    country: 'United States'
  },
  {
    id: 11051,
    name: 'AMERICAN IV PRODUCTS  HARMANS MD (United States)',
    country: 'United States'
  },
  {
    id: 11061,
    name: 'KELLY & HAYES ELECTRICAL  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 11091,
    name: 'ANESTHETIC VAPORIZER SERVICES  CLARENCE NY (United States)',
    country: 'United States'
  },
  {
    id: 11095,
    name: 'H. C. A. S., INCORPORATED  BELLMORE NY (United States)',
    country: 'United States'
  },
  {
    id: 11102,
    name: 'AIR & POWER TRANSMISSION INC  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 11110,
    name: 'UTICA NATIONAL INSURANCE GROUP  UTICA NY (United States)',
    country: 'United States'
  },
  {
    id: 11117,
    name: 'DRAGER MEDICAL, INC  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 11169,
    name: 'DELL COMMERCIAL CREDIT  DES MOINES IA (United States)',
    country: 'United States'
  },
  {
    id: 11216,
    name: 'DJO, LLC  VISTA CA (United States)',
    country: 'United States'
  },
  {
    id: 11217,
    name: 'SMITHS MEDICAL  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 11223,
    name: 'R&D BATTERIES, INC.  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 11228,
    name: 'KAMAN INDUSTRIAL  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 11247,
    name: 'QUAIL ELECTRONICS  LIVERMORE CA (United States)',
    country: 'United States'
  },
  {
    id: 11254,
    name: 'DIVERSIFIED MEDICAL/NCE  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 11257,
    name: 'ELECTRONIC CITY  NEW HYDE PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 11268,
    name: 'SIEMENS HEALTHCARE DIAGNOSTIC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 11282,
    name: 'OLYMPIC MEDICAL  SAN FRANCISCO CA (United States)',
    country: 'United States'
  },
  {
    id: 11297,
    name: 'STEPIC MEDICAL  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 11331,
    name: 'USE MCKESSON MED-SURG #0014292  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 11340,
    name: 'UNITED METAL FABRICATORS, INC.  JOHNSTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 11349,
    name: 'ALPHA SOURCE INC.  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 11398,
    name: 'SONTEK MEDICAL, INC.  Rochester NH (United States)',
    country: 'United States'
  },
  {
    id: 11425,
    name: 'BLUE GAVEL PRESS  ROCK ISLAND IL (United States)',
    country: 'United States'
  },
  {
    id: 11431,
    name: 'KINGS HARDWARE WEST  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 11451,
    name: 'THE STATE INSURANCE FUND  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 11477,
    name: 'GMAC PAYMENT PROCESSING CENTER  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 11485,
    name: 'TECH AIR  BRATTLEBORO VT (United States)',
    country: 'United States'
  },
  {
    id: 11490,
    name: 'MANAGED MEDICAL SPECIALTIES  HAINESPORT NJ (United States)',
    country: 'United States'
  },
  {
    id: 11503,
    name: 'DOLORES MURDOCK  WEST HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 11563,
    name: 'PSEG LI (FORMERLY LIPA)  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 11584,
    name: 'TELEPHONE BUSINESS SYSTEMS  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 11603,
    name: 'REID JORGENSEN  DUXBURY MA (United States)',
    country: 'United States'
  },
  {
    id: 11608,
    name: 'INTERNATIONAL BIOMEDICAL INC  AUSTIN TX (United States)',
    country: 'United States'
  },
  {
    id: 11630,
    name: 'WRAP-N-PACK  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 11636,
    name: 'CRITICARE SYSTEMS, INC  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 11641,
    name: 'THERMO/KENDRO LAB PRODUCTS  Asheville NC (United States)',
    country: 'United States'
  },
  {
    id: 11649,
    name: 'HEPA CORPORATION  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 11655,
    name: 'UPS SUPPLY CHAIN SOLUTIONS, INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 11662,
    name: 'AIRLINE HYDRAULICS CORPORATION  Philadelphia PA (United States)',
    country: 'United States'
  },
  {
    id: 11665,
    name: 'MCMASTER CARR  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 11675,
    name: 'ADR TECHNOLOGIES, INC.  SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 11676,
    name: 'TRI TECH  AVON OH (United States)',
    country: 'United States'
  },
  {
    id: 11691,
    name: 'BIO-MED DEVICES INC.  GUILDFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 11700,
    name: 'FLORIDA HEALTHCARE ENGINEERING  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 11710,
    name: 'MEDSERV, INC.  Minnetonka MN (United States)',
    country: 'United States'
  },
  {
    id: 11736,
    name: 'EXXONMOBIL  DES MOINES IA (United States)',
    country: 'United States'
  },
  {
    id: 11742,
    name: '3M HEALTHCARE SPECIALTIES DIVI  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 11786,
    name: 'MICRO WAREHOUSE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 11791,
    name: 'DIGI-KEY  Thief River Falls MN (United States)',
    country: 'United States'
  },
  {
    id: 11829,
    name: 'VALENTE LANDSCAPING INC  MEDFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 11848,
    name: 'SALTER LABS  ARVIN CA (United States)',
    country: 'United States'
  },
  {
    id: 11849,
    name: 'NSPIRE HEALTH  Detroit MI (United States)',
    country: 'United States'
  },
  {
    id: 11857,
    name: 'PREST-O-SALES  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 11861,
    name: 'IMPACT INSTRUMENTS, INC.  WEST CALDWELL NJ (United States)',
    country: 'United States'
  },
  {
    id: 11863,
    name: 'ALPINE MECHANICAL SYSTEMS INC  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 11900,
    name: 'MEDICAL CABLES INC/ CP HEALTH GROUP  MONROVIA CA (United States)',
    country: 'United States'
  },
  {
    id: 11904,
    name: 'GAST MANUFACTURING, INC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 11914,
    name: 'WESTERN ENTERPRISES  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 11921,
    name: 'WERFEN United States LLC  BEDFORD MA (United States)',
    country: 'United States'
  },
  {
    id: 11924,
    name: 'MEDICAL REPAIR LABORATORIES  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 11972,
    name: 'FABRICATION SPECIALTIES CORP.  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 11973,
    name: 'MINE SAFETY APPLIANCES COMPANY  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 11987,
    name: 'COMMONWEALTH OF MASS (DNU)  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 11999,
    name: 'SPACELABS  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 12003,
    name: 'SR INSTRUMENTS  TONAWANDA NY (United States)',
    country: 'United States'
  },
  {
    id: 12004,
    name: 'HAMILTON MEDICAL, INC.  RENO NV (United States)',
    country: 'United States'
  },
  {
    id: 12018,
    name: 'GLOBAL COMPUTER SUPPLIES  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 12034,
    name: 'ZOLL MEDICAL  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 12040,
    name: 'BULB DIRECT  PITTSFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 12052,
    name: 'NEURO TECHNOLOGY INC  KERRVILLE TX (United States)',
    country: 'United States'
  },
  {
    id: 12066,
    name: 'LASER COMPANY OF AMERICA  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 12072,
    name: 'MOORE WALLACE/UNITED AD LABEL  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 12078,
    name: 'SPECIALIZED PRODUCTS COMPANY  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 12102,
    name: 'MSC INDUSTRIAL SUPPLY CO  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 12148,
    name: 'ADVANCED CHEMICAL SENSOR  BOCA RATON FL (United States)',
    country: 'United States'
  },
  {
    id: 12168,
    name: 'ADVANCED MEDICAL SYSTEMS  HORSHAM PA (United States)',
    country: 'United States'
  },
  {
    id: 12193,
    name: 'HILL-ROM  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 12201,
    name: 'SIEMENS MEDICAL SOLUTIONS  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 12206,
    name: 'GAYMAR INDUSTRIES, INC  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 12211,
    name: 'CONSERVE ELECTRICAL SUPPLY CO  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 12212,
    name: 'ARJOHUNTLEIGH INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 12214,
    name: 'AUTOPRO AUTOMOTIVE SERVICES  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 12221,
    name: 'DIAMOND FASTENERS INC  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 12227,
    name: 'MECTA CORPORATION  TUALATIN OR (United States)',
    country: 'United States'
  },
  {
    id: 12236,
    name: 'AGILENT TECHNOLOGIES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 12237,
    name: 'MEDIQ ACS/INVACARE SUPPLY GRP  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 12238,
    name: 'CURBELL  ORCHARD PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 12258,
    name: 'PARKS MEDICAL ELECTRONICS, INC.  ALOHA OR (United States)',
    country: 'United States'
  },
  {
    id: 12260,
    name: 'CAREFUSION (VYASYS)  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 12277,
    name: 'ALLIED HEALTHCARE PRODUCTS,INC  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 12290,
    name: 'ASSOCIATION OF OPERATING ROOM  DENVER CO (United States)',
    country: 'United States'
  },
  {
    id: 12302,
    name: 'ATLANTIC ULTRAVIOLET  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 12311,
    name: 'CONMED CORPORATION  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 12353,
    name: 'AAMI  ARLINGTON VA (United States)',
    country: 'United States'
  },
  {
    id: 12396,
    name: 'POWER DRIVES INC.  ERIE PA (United States)',
    country: 'United States'
  },
  {
    id: 12430,
    name: 'BULBTRONICS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 12466,
    name: 'MALLINCKRODT  Pittsburgh PA (United States)',
    country: 'United States'
  },
  {
    id: 12488,
    name: 'REPLACEMENT PARTS INDS INC  CHATSWORTH CA (United States)',
    country: 'United States'
  },
  {
    id: 12489,
    name: 'MERCURY MEDICAL  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 12526,
    name: 'PMP SANDEN CONSULTING  SYOSSET NY (United States)',
    country: 'United States'
  },
  {
    id: 12603,
    name: 'LONG ISLAND BUSINESS NEWS  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 12612,
    name: 'G. NEIL  SUNRISE FL (United States)',
    country: 'United States'
  },
  {
    id: 12642,
    name: 'ST. ROSE OF LIMA  MASSAPEQUA NY (United States)',
    country: 'United States'
  },
  {
    id: 12655,
    name: 'OXFORD HEALTH PLANS, INC  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 12656,
    name: 'TRI AIR TESTING  AUSTIN TX (United States)',
    country: 'United States'
  },
  {
    id: 12669,
    name: 'AIRFILTRONIX CORPORATION  CLIFTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 12704,
    name: 'LAERDAL MEDICAL CORP  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 12714,
    name: 'B BRAUN MEDICAL  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 12730,
    name: 'SETON NAME PLATE COMPANY  NEW HAVEN CT (United States)',
    country: 'United States'
  },
  {
    id: 12731,
    name: 'HOWORTH AIR TECHNOLOGY LTD  BOLTON EN (United States)',
    country: 'United States'
  },
  {
    id: 12749,
    name: 'ARROW ELECTRONICS, INC.  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 12766,
    name: 'MEDI-COMP, INC.  PRINCETON MN (United States)',
    country: 'United States'
  },
  {
    id: 12776,
    name: 'SEAL-SEAT COMPANY  MONTEREY PARK CA (United States)',
    country: 'United States'
  },
  {
    id: 12803,
    name: 'TRI ANIM HEALTH SERVICES, INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 12833,
    name: 'SAKURA FINETEK United States, INC.  TORRANCE CA (United States)',
    country: 'United States'
  },
  {
    id: 12834,
    name: 'STANLEY SUPPLY & SERVICES  WOBURN MA (United States)',
    country: 'United States'
  },
  {
    id: 12840,
    name: 'SCALE-TRONIX  WHITE PLAINS NY (United States)',
    country: 'United States'
  },
  {
    id: 12843,
    name: 'GETINGE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 12898,
    name: 'MEDICAL ACCESSORIES INC  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 12899,
    name: 'AIRFLOW TECHNICAL PRODUCTS, IN  ANDOVER NJ (United States)',
    country: 'United States'
  },
  {
    id: 12911,
    name: 'NEWARK  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 12941,
    name: 'INVIVO CORPORATION  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 12991,
    name: 'CINCINNATI SUB ZERO PRODUCTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13008,
    name: 'REICHERT OPTHALMIC  DEPEW NY (United States)',
    country: 'United States'
  },
  {
    id: 13016,
    name: 'ARJOHUINTLEIGH INC.  ADDISON IL (United States)',
    country: 'United States'
  },
  {
    id: 13017,
    name: 'PRECISION ENVELOPE & PRINTING  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 13033,
    name: 'PASCALE, RAZZINO, ALEXANDERSON  STUART FL (United States)',
    country: 'United States'
  },
  {
    id: 13049,
    name: 'MEDTRONIC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13061,
    name: 'WILLIAM CHARLES PRINTING CO.  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 13070,
    name: 'PCI MEDICAL INC.  DEEP RIVER CT (United States)',
    country: 'United States'
  },
  {
    id: 13091,
    name: 'NIHON KODEN AMERICA, INC.  FOOTHILL RANCH CA (United States)',
    country: 'United States'
  },
  {
    id: 13092,
    name: 'AIRSEP CORP/ CHART INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13114,
    name: 'AIRWELD INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 13133,
    name: 'C.R. BARD INC  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 13248,
    name: 'MEDRECON INC  GARWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 13257,
    name: 'SOMA TECHNOLOGY, INC  BLOOMFIELD CT (United States)',
    country: 'United States'
  },
  {
    id: 13280,
    name: 'STERIS CORPORATION  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13303,
    name: 'SQUIRE-COGSWELL-AEROS  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 13343,
    name: 'DUN & BRADSTREET  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13366,
    name: 'NATIONALGRID  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 13402,
    name: 'MATRIX   CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13417,
    name: 'OPHIR SPRICON, INC.  NORTH LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 13423,
    name: 'ALCO SALES & SERVICE  BURR RIDGE IL (United States)',
    country: 'United States'
  },
  {
    id: 13424,
    name: 'AIR LIQUIDE HEALTHCARE(SCOTT MEDICAL )  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 13428,
    name: 'INTERTEK TESTING SERVICES  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 13432,
    name: 'SCHWING ELECTRICAL SUPPLY  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 13434,
    name: 'NATUS MEDICAL INC.  San Francisco CA (United States)',
    country: 'United States'
  },
  {
    id: 13441,
    name: 'BELAIR INSTRUMENTS CO., INC.  SPRINGFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 13451,
    name: 'BBC LIGHTING & SUPPLY  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 13454,
    name: 'QUALITY MONITOR SYSTEMS, INC.  COLORADO SPRINGS CO (United States)',
    country: 'United States'
  },
  {
    id: 13455,
    name: 'OZARK BIOMEDICAL  BEEBE AR (United States)',
    country: 'United States'
  },
  {
    id: 13457,
    name: 'WASTE MANAGEMENT OF NEW JERSEY  BALTIMORE MD (United States)',
    country: 'United States'
  },
  {
    id: 13458,
    name: 'CORPORATION SERVICE COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13459,
    name: 'MEDICAL WORLD COMMUNICATIONS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13462,
    name: 'PARALLAX TECHNOLOGY, INC.  CAMBRIDGE MA (United States)',
    country: 'United States'
  },
  {
    id: 13467,
    name: 'AMERICAN LASER PARTS & SERVICE  OBERLIN OH (United States)',
    country: 'United States'
  },
  {
    id: 13473,
    name: 'SENSORONICS, INC.  MURRIETA CA (United States)',
    country: 'United States'
  },
  {
    id: 13475,
    name: 'CHILD ABUSE PREVENTION SERVICE  ROSLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 13489,
    name: 'TRINITY TECHNOLOGIES LLC  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 13491,
    name: 'BECKMAN COULTER  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 13503,
    name: 'CITY OF PHILADELPHIA  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13505,
    name: 'NIDEK MEDICAL PRODUCTS, INC.  BIRMINGHAM AL (United States)',
    country: 'United States'
  },
  {
    id: 13508,
    name: 'ORMED  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13509,
    name: 'FRESENIUS  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 13511,
    name: 'SPECTRUM TECHNOLOGIES, INC.  PAXINOS PA (United States)',
    country: 'United States'
  },
  {
    id: 13513,
    name: 'FEDEX GROUND, INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13520,
    name: 'BIOLOGICAL CONTROLS INC  EATONTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 13522,
    name: 'CARDIAC SCIENCE  Waukesha WI (United States)',
    country: 'United States'
  },
  {
    id: 13523,
    name: 'HUNTINGTON COUNTRY INN  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 13525,
    name: 'MARCOR SERVICES  SKIPPACK PA (United States)',
    country: 'United States'
  },
  {
    id: 13527,
    name: 'ALLSTATES WORLD CARGO  FORKED RIVER NJ (United States)',
    country: 'United States'
  },
  {
    id: 13529,
    name: 'GLENN MEDICAL SYSTEMS, INC.  Cleveland OH (United States)',
    country: 'United States'
  },
  {
    id: 13532,
    name: 'CYCLE SERVICE  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 13543,
    name: 'COLONIAL LAKE PROPERTY  ADDISON IL (United States)',
    country: 'United States'
  },
  {
    id: 13551,
    name: 'THE SCOTT LAWSON GROUP LTD  CONCORD NH (United States)',
    country: 'United States'
  },
  {
    id: 13553,
    name: 'UNITED PARCEL SERVICE  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13558,
    name: 'AMETEK USG INC  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 13562,
    name: 'COMPUTER TRANSPORTATION SERVICES  BREDA IA (United States)',
    country: 'United States'
  },
  {
    id: 13567,
    name: 'OLYMPUS INDUSTRIAL MICROSCOPE  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13570,
    name: 'CARDIO MEDICAL PRODUCTS  HARTFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 13571,
    name: 'FISHER SCIENTIFIC  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 13576,
    name: 'PRECISION NAPCO  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 13577,
    name: 'GE MEDICAL SYSTEMS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13578,
    name: 'SOUTH JERSEY GAS  FOLSOM NJ (United States)',
    country: 'United States'
  },
  {
    id: 13591,
    name: 'BILL HUMPHRIES INDUSTRIAL SALE  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 13593,
    name: 'CYBEX INTERNATIONAL INC  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13596,
    name: 'ALLIED ELECTRONICS, INC  FORT WORTH TX (United States)',
    country: 'United States'
  },
  {
    id: 13601,
    name: 'DATEX OHMEDA  MADISON WI (United States)',
    country: 'United States'
  },
  {
    id: 13602,
    name: 'BIODEX MEDICAL SYSTEMS INC  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 13606,
    name: 'LOGAN TOWNSHIP  BRIDGEPORT NJ (United States)',
    country: 'United States'
  },
  {
    id: 13610,
    name: 'LASER SOLUTIONS INC  BASKING RIDGE NJ (United States)',
    country: 'United States'
  },
  {
    id: 13611,
    name: 'CARL ZEISS MEDITEC INC  PASADENA CA (United States)',
    country: 'United States'
  },
  {
    id: 13618,
    name: 'SOUTHWESTERN BIOMEDICAL  TULSA OK (United States)',
    country: 'United States'
  },
  {
    id: 13619,
    name: 'PEDIGO PRODUCTS INC.  PORTLAND OR (United States)',
    country: 'United States'
  },
  {
    id: 13624,
    name:
      'LONG ISLAND ASSOC. FOR CENTRAL  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 13626,
    name: 'COOPER SURGICAL  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 13628,
    name: 'CONECTIV  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 13629,
    name: 'OFFICE MAX  DES MOINES IA (United States)',
    country: 'United States'
  },
  {
    id: 13631,
    name: 'WORLD CLASS MOTOR SPECIALISTS  SANTA FE SPRINGS CA (United States)',
    country: 'United States'
  },
  {
    id: 13632,
    name: 'IRIDEX CORPORATION  MOUNTAIN VIES CA (United States)',
    country: 'United States'
  },
  {
    id: 13648,
    name: 'MEDICAL GAS EQUIPMENT  BRIDGEVIEW IL (United States)',
    country: 'United States'
  },
  {
    id: 13651,
    name: 'AIRTECH  ENGLEWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 13654,
    name: 'DANONE WATERS OF NORTH AMERICA  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 13663,
    name: 'BASIC MAINTENANCE SUPPLY  CHERRY HILL NJ (United States)',
    country: 'United States'
  },
  {
    id: 13664,
    name: 'SKILLPATH SEMINARS  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 13665,
    name: 'FUTUREMED  GRANADA HILLS CA (United States)',
    country: 'United States'
  },
  {
    id: 13667,
    name: 'CAREFUSION  YORBA LINDA CA (United States)',
    country: 'United States'
  },
  {
    id: 13668,
    name: "FIREMAN'S FUND INSURANCE  PALATINE IL (United States)",
    country: 'United States'
  },
  {
    id: 13670,
    name: 'INVACARE  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13700,
    name: 'AERO VAC  AMITYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13702,
    name: 'MEDELA  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13704,
    name: 'MIDWEST HEALTHCARE ENGINEERING  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 13713,
    name: 'WILLIAM F. RYAN COMMUNITY HEAL  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 13715,
    name: 'NEW YORK MICROSCOPE (MELSOBEL)  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13716,
    name: 'TYCO ELECTRONICS CORPORATION  HARRISBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13718,
    name: 'SUN LIFE & HEALTH INS COMPANY (US)  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 13720,
    name: 'MCS REALTY, LLC  MINEOLA NY (United States)',
    country: 'United States'
  },
  {
    id: 13723,
    name: 'JOSEPH G. EDWARDS  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 13728,
    name: 'HALLOWELL EMC  PITTSFIELD MA (United States)',
    country: 'United States'
  },
  {
    id: 13729,
    name: 'TECHNI-TOOL  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 13741,
    name: 'DIVERSIFIED INSTUMENT SERVICES  EVANSVILLE IN (United States)',
    country: 'United States'
  },
  {
    id: 13742,
    name: 'ASKUS, LLC  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13747,
    name: 'BUSINESS CREDIT United States  OMAHA NE (United States)',
    country: 'United States'
  },
  {
    id: 13749,
    name: 'VIRGINIA DEPT OF TAXATION  RICHMOND VA (United States)',
    country: 'United States'
  },
  {
    id: 13750,
    name: 'GLOBAL SURGICAL CORPORATION  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 13751,
    name: 'LEICA MICROSYSTEMS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13758,
    name: 'FERGUSON ENTERPRISES formerly D&WARSHOW  Boston MA (United States)',
    country: 'United States'
  },
  {
    id: 13764,
    name: 'ADVANCED INSTRUMENTS INC  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 13768,
    name: 'PRECISION MICRO INC  LEVITTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 13775,
    name: 'DECCAID SERVICES  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 13810,
    name: 'BENCO DENTAL COMPANY  Dallas TX (United States)',
    country: 'United States'
  },
  {
    id: 13820,
    name: 'BUXTON MEDICAL  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 13822,
    name: 'RICHARD ALLAN SCIENTIFIC CO.  KALAMAZOO MI (United States)',
    country: 'United States'
  },
  {
    id: 13823,
    name: 'SONY ELECTRONICS  NORCROSS GA (United States)',
    country: 'United States'
  },
  {
    id: 13825,
    name: 'SUFFOLK COUNTY LOCK & SECURITY  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 13826,
    name: 'ALESA CUNNINGHAM  FRANKFORT IN (United States)',
    country: 'United States'
  },
  {
    id: 13829,
    name: 'AUDI FINANCIAL SERVICES  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 13831,
    name: 'UNIVERSAL MEDICAL LASERS  PALM BAY FL (United States)',
    country: 'United States'
  },
  {
    id: 13832,
    name: 'AT&T  SAGINAW MI (United States)',
    country: 'United States'
  },
  {
    id: 13834,
    name: 'KENTEK CORPORATION  PITTSFIELD NH (United States)',
    country: 'United States'
  },
  {
    id: 13840,
    name: 'ALL ISLAND AUTO GLASS  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 13841,
    name: 'DIVERSIFIED DENTAL SERVICES OF  SETAUKET NY (United States)',
    country: 'United States'
  },
  {
    id: 13842,
    name: 'TRADE FAIR HARDWARE  AMITYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13852,
    name: 'RELAY SPECIALTIES, INC  OAKLAND NJ (United States)',
    country: 'United States'
  },
  {
    id: 13857,
    name: 'OSADA, INC  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 13859,
    name: 'N.I.T.C. - USE VENDOR#14411  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 13866,
    name: 'N&S ELECTRIC SUPPLY INC  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 13867,
    name: 'CONCORD ENDOSCOPY SERVICES, IN  SYOSSET NY (United States)',
    country: 'United States'
  },
  {
    id: 13870,
    name: 'AMERICAN INDUSTRIAL CLEANING C  GREAT NECK NY (United States)',
    country: 'United States'
  },
  {
    id: 13876,
    name: 'THERMO FISHER  KALAMAZOO MI (United States)',
    country: 'United States'
  },
  {
    id: 13878,
    name: 'ACDS (ASSOC FOR CHILDREN/ DOWN SYNDROME)    NY (United States)',
    country: 'United States'
  },
  {
    id: 13879,
    name: 'BROTHERS II  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 13883,
    name: 'MORRELL INSTRUMENT COMPANY, IN  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13894,
    name: 'ALL ISLAND COMPRESSOR SERVICE,  W. BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 13902,
    name: "MAGIC MOMENTS DJ'S  WEST ISLIP NY (United States)",
    country: 'United States'
  },
  {
    id: 13903,
    name: 'COMPLETE BUSINESS FORMS & PRIN  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13904,
    name: 'MED-E-QUIP LOCATORS, INC.  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 13906,
    name: 'MORGAN AND CURIS ASSOCIATES, I  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 13907,
    name: 'MEDIQUIP PARTS PLUS, INC.  High Ridge MO (United States)',
    country: 'United States'
  },
  {
    id: 13914,
    name: 'KR WILLIAMS GROUP, LLC  SOUTH LYME CT (United States)',
    country: 'United States'
  },
  {
    id: 13917,
    name: 'PANORAMA  COPIAGUE NY (United States)',
    country: 'United States'
  },
  {
    id: 13918,
    name: 'FP MAILING SOLUTIONS  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 13928,
    name: 'WELLS FARGO FINANCIAL LEASING  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 13929,
    name: 'CABLEVISION LIGHTPATH, INC  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13938,
    name: 'CINTAS CORPORATION #780  CENTRAL ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 13942,
    name: 'MAINTENANCE UNDERWRITERS GROUP  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13943,
    name: 'FORD CREDIT  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 13956,
    name: 'ALL SEASONS TENT RENTALS INC.  FLORAL PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 13961,
    name: 'CARDIOPULMONARY FITNESS  BELLE MEAD NJ (United States)',
    country: 'United States'
  },
  {
    id: 13963,
    name: 'FLUKE ELECTRONICS CORPORATION  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13967,
    name: 'GRAYBAR ELECTRIC COMPANY INC.  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 13968,
    name: 'AMERIWOOD MEDICAL INC  Elmhurst IL (United States)',
    country: 'United States'
  },
  {
    id: 13969,
    name: 'SOCIETY FOR HUMAN RESOURCE MAN  BALTIMORE MD (United States)',
    country: 'United States'
  },
  {
    id: 13970,
    name: 'THE OFFICE FURNITURE WAREHOUSE  ISLANDIA NY (United States)',
    country: 'United States'
  },
  {
    id: 13974,
    name: 'AMERICAN AIR FILTER LTD  Louisville KY (United States)',
    country: 'United States'
  },
  {
    id: 13976,
    name: 'NOVA BIOMEDICAL  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 13979,
    name: 'ENCORE TECHNICAL SERVICES, INC  HOLBROOK MA (United States)',
    country: 'United States'
  },
  {
    id: 13980,
    name: 'ECRI Institute  PLYMOUTH MEETING PA (United States)',
    country: 'United States'
  },
  {
    id: 13984,
    name: 'MS. SUE TERRY  McFARLAND WI (United States)',
    country: 'United States'
  },
  {
    id: 13985,
    name: "RONNIE''S TRUCK SERVICE, INC  FARMINGDALE NY (United States)",
    country: 'United States'
  },
  {
    id: 13987,
    name:
      'ELITE ACTION FIRE EXTINGUISHIN  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 13988,
    name: 'SMITH HAVEN CHRYSLER  ST. JAMES NY (United States)',
    country: 'United States'
  },
  {
    id: 13989,
    name: 'P&N MACHINE COMPANY  BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 13991,
    name: 'ARAMARK CTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 13993,
    name: 'ADVANTAGE MEDICAL ELECTRONICS,  CORAL SPRINGS FL (United States)',
    country: 'United States'
  },
  {
    id: 13997,
    name: 'HSBC BANK  BALTIMORE MD (United States)',
    country: 'United States'
  },
  {
    id: 14001,
    name: 'ISLAND SPRINKLERS RBR, INC.  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 14002,
    name: 'A&D DISTRIBUTORS  LIVINGSTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14003,
    name: 'DAVIS INSTRUMENTS LLC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14004,
    name: 'FREDERICK PFEIFER CORPORATION  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 14005,
    name: 'MISONIX  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14006,
    name: 'NEW ENGLAND FOAM PRODUCTS, LLC  WINDSOR CT (United States)',
    country: 'United States'
  },
  {
    id: 14009,
    name: 'MILES PETROLEUM COMPANY INC  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14010,
    name: 'SUBURBAN EATS CATERING  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 14013,
    name: 'KEY EQUIPMENT FINANCE  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 14015,
    name: 'THE WHEELCHAIR MAN COMPANY, IN  CLEMENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14017,
    name: 'GE MOBILE WATER, INC.  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 14020,
    name: 'ALCON LABORATORIES, INC  Fort Worth TX (United States)',
    country: 'United States'
  },
  {
    id: 14021,
    name: 'CARR BUSINESS SYSTEMS  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 14027,
    name: 'MCM ELECTRONICS  SPRINGBORO OH (United States)',
    country: 'United States'
  },
  {
    id: 14029,
    name: 'PARTS SOURCE, LLC  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 14030,
    name: 'EYETECH LTD  MORTON GROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 14032,
    name: 'PENTAX MEDICAL  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14035,
    name: 'KARL STORZ ENDOSCOPY-AMERICA,  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 14038,
    name: 'ARLEDGE ELECTRONICS, INC  PILESGROVE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14039,
    name: 'I.MILLER PRECISION OPTICAL INS  FEASTERVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 14041,
    name: 'TOTAL REPAIR EXPRESS, LLC  HILLSBOROUGH NJ (United States)',
    country: 'United States'
  },
  {
    id: 14042,
    name: 'TOTAL SCOPE, INC  BOOTHWYN PA (United States)',
    country: 'United States'
  },
  {
    id: 14044,
    name: 'WELLS JOHNSON COMPANY  TUCSON AZ (United States)',
    country: 'United States'
  },
  {
    id: 14045,
    name: 'THE FAX DOCTOR  COLLINGSWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 14055,
    name: 'MARK ARCIERI  HARTSDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14059,
    name: 'GCX CORP  SUISUN CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 14061,
    name: 'MACKIN MEDICAL INC.  BROOMALL PA (United States)',
    country: 'United States'
  },
  {
    id: 14068,
    name: 'LUMENIS, INC  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 14069,
    name: 'MOPEC INC.  OAK PARK MI (United States)',
    country: 'United States'
  },
  {
    id: 14071,
    name: 'GRAMS MEDICAL  COSTA MESA CA (United States)',
    country: 'United States'
  },
  {
    id: 14072,
    name: 'COLE-PARMER  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14076,
    name: 'SCIENTIFIC APPARATUS SERVICE  HAINESPORT NJ (United States)',
    country: 'United States'
  },
  {
    id: 14078,
    name: 'BILL FOX COMPANY  PORT JEFFERSON STA NY (United States)',
    country: 'United States'
  },
  {
    id: 14080,
    name: 'CFS IMAGING  GLEN ROCK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14083,
    name: 'MIDWEST SCIENCE & BIOCENTER, I  ALBERTVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 14086,
    name: 'ABOVE BOARD ELECTRONICS (ABE)  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 14088,
    name: 'FISHER & PAYKEL HEALTHCARE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14089,
    name: 'KIRWAN SURGICAL PRODUCTS, INC  MARSHFIELD MA (United States)',
    country: 'United States'
  },
  {
    id: 14090,
    name: 'MEDICAL GRAPHICS CORPORATION  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14091,
    name: 'OWENS & MINOR  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14093,
    name: 'APPLIED BIOMEDICAL  ARLINGTON TX (United States)',
    country: 'United States'
  },
  {
    id: 14094,
    name:
      'KONICA MINOLTA MEDICAL IMAGING United States, INC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14095,
    name: 'FIRST UNUM LIFE INSURANCE COMP  COLUMBIA SC (United States)',
    country: 'United States'
  },
  {
    id: 14096,
    name: 'GE FINANCIAL ASSURANCE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14097,
    name: 'CONTROLLED ACCESS/ASG  MAPLE SHADE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14098,
    name: 'UNIPOWER CORP  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14099,
    name: 'NEWBOLD  Rocky Mount VA (United States)',
    country: 'United States'
  },
  {
    id: 14100,
    name: 'RICHARD WOLF MEDICAL INSTRUMEN  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14101,
    name: 'AIRCLEAN SYSTEMS  RALEIGH NC (United States)',
    country: 'United States'
  },
  {
    id: 14102,
    name: 'FINE STAR IMAGING  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 14103,
    name: 'HAEMONETICS  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14104,
    name: 'APPLIED COMPUTER TECHNOLOGY  BAYSHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 14106,
    name: 'D-TAIL MEDICAL SALES  MAGNOLIA NJ (United States)',
    country: 'United States'
  },
  {
    id: 14107,
    name: 'BALLARD MEDICAL PRODUCTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14108,
    name: 'ZOLTAN STERSKY JR  SUNNYVALE CA (United States)',
    country: 'United States'
  },
  {
    id: 14109,
    name: 'METTLER TOLEDO  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14110,
    name:
      'COFFEE SOLUTIONS GROUP (FORMERLY FILTERFRESH)  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14111,
    name: 'C&H DISTRIBUTORS, INC  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 14112,
    name: 'ACMI CORPORATION  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14113,
    name: 'TEKTRONIX  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14117,
    name: 'INVENTORY SOLUTIONS, INC  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 14118,
    name: 'JEDMED  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 14122,
    name: 'RADIOMETER AMERICA INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14130,
    name: 'ALLSTATE LIFE INS CO OF NY  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14131,
    name: 'MARTAB MEDICAL  Allendale NJ (United States)',
    country: 'United States'
  },
  {
    id: 14135,
    name: 'DESIGNS FOR VISION INC  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 14137,
    name: 'QUEST INTERNATIONAL INC.  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 14139,
    name: 'MAXIM DIRECT DISTRIBUTION  SANFRANCISCO CA (United States)',
    country: 'United States'
  },
  {
    id: 14140,
    name: 'FIBEROPTICS TECHNOLOGY  POMFRET CT (United States)',
    country: 'United States'
  },
  {
    id: 14141,
    name: 'KENNEDY MANUFACTURING CO.  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 14142,
    name: 'KOVEN TECHNOLOGY INC.  SAINT LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 14143,
    name: 'PADGETT INSTRUMENTS  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 14145,
    name: 'NEW HORIZON  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 14146,
    name: 'STAR X-RAY CO., INC  AMITYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 14147,
    name: 'SCIFIT SYSTEMS, INC  TULSA OK (United States)',
    country: 'United States'
  },
  {
    id: 14148,
    name: 'UZ ENGINEERED PRODUCTS  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 14149,
    name: 'VISIPLEX TECHNOLOGIES, INC.  DEERFIELD IL (United States)',
    country: 'United States'
  },
  {
    id: 14151,
    name: 'APEX PACKING & RUBBER CO., INC  OLD BETHPAGE NY (United States)',
    country: 'United States'
  },
  {
    id: 14152,
    name: 'CARDIAC SCIENCE CORP.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14153,
    name: 'PRECISION ENDOSCOPY OF AMERICA  HUNT VALLEY MD (United States)',
    country: 'United States'
  },
  {
    id: 14155,
    name: 'DELAWARE SECRETARY OF STATE  BINGHAMTON NY (United States)',
    country: 'United States'
  },
  {
    id: 14157,
    name: 'VERATHON INC.  BOTHELL WA (United States)',
    country: 'United States'
  },
  {
    id: 14158,
    name: 'PROGRESSIVE DYNAMMICS MEDICAL  MARSHALL MI (United States)',
    country: 'United States'
  },
  {
    id: 14162,
    name: 'NCO FINANCIAL SYSTEMS, INC  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14163,
    name: 'NFPA INTERNATIONAL  MANCHESTER NH (United States)',
    country: 'United States'
  },
  {
    id: 14164,
    name: 'CONATIVE SYSTEMS, INC.  TEMECULA CA (United States)',
    country: 'United States'
  },
  {
    id: 14165,
    name: 'RPI PROFESSIONAL ALTERNATIVES,  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 14166,
    name: 'AESCULAP  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14167,
    name: 'NEWARK BETH ISRAEL MEDICAL CEN  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14169,
    name: 'ENDOFILTER  EDINA MN (United States)',
    country: 'United States'
  },
  {
    id: 14170,
    name: 'SAI SURGICAL INSTRUMENTS  RIDLEY PARK PA (United States)',
    country: 'United States'
  },
  {
    id: 14171,
    name: 'A&A BUSINESS SYSTEMS  JENKINTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 14172,
    name: 'CONNECTED OFFICE PRODUCTS  ABSECON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14175,
    name: 'COMPUTER SOURCE  NEW ORLEANS LA (United States)',
    country: 'United States'
  },
  {
    id: 14179,
    name: 'NEURO MEDICAL SYSTEMS  BENSALEM PA (United States)',
    country: 'United States'
  },
  {
    id: 14181,
    name: 'A & L SCIENTIFIC CORP  Glendale NY (United States)',
    country: 'United States'
  },
  {
    id: 14182,
    name: 'TERUMO BCT (FORMERLY CARIDIAN BCT)  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 14183,
    name: 'FETACESSORIES  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14184,
    name: 'RADCAL CORPORATION  MONROVIA C (United States)',
    country: 'United States'
  },
  {
    id: 14185,
    name: 'ORTHO CLINICAL DIAGNOSTICS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14187,
    name: 'SUPERIOR A/C & HEATING SYSTEMS  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 14189,
    name: 'CUSTOM ULTRASONIC INC  Ivyland PA (United States)',
    country: 'United States'
  },
  {
    id: 14192,
    name: 'CYBERTRONICS, INC.  NEW ORLEANS LA (United States)',
    country: 'United States'
  },
  {
    id: 14193,
    name: 'ONE SOURCE IMAGING  BROOMALL PA (United States)',
    country: 'United States'
  },
  {
    id: 14194,
    name: 'BELL OPHTHALMIC TECHNOLOGY  WESTVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14195,
    name: 'CREST ULTRASONICS  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14197,
    name: 'CRYSTAL SPRINGS WATER COMPANY  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14198,
    name: 'CONTROL DYNAMICS CORPORATION  WARMINSTER PA (United States)',
    country: 'United States'
  },
  {
    id: 14199,
    name: 'MAIN LINE PERSONNEL SERVICES,  BALA CYNWYD PA (United States)',
    country: 'United States'
  },
  {
    id: 14201,
    name: 'MEDICAL SUPPORT PRODUCTS, INC  LANCASTER PA (United States)',
    country: 'United States'
  },
  {
    id: 14202,
    name: 'BUDGET CONFERENCING, INC  CAMBRIDGE MA (United States)',
    country: 'United States'
  },
  {
    id: 14205,
    name: 'LASERMAN, INC  MAPLE GROVE MN (United States)',
    country: 'United States'
  },
  {
    id: 14207,
    name: 'SHELDON MANUFACTURING, INC  CORNELIUS OR (United States)',
    country: 'United States'
  },
  {
    id: 14208,
    name: 'ITC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14209,
    name: 'BOSTON SCIENTIFIC CORPORATION  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14210,
    name: 'DIVERSIFIED MEDICAL ALLIANCE  KENOVA VA (United States)',
    country: 'United States'
  },
  {
    id: 14211,
    name: 'CONSOLIDATED PLASTICS COMPANY,  STOW OH (United States)',
    country: 'United States'
  },
  {
    id: 14214,
    name: 'SMITH MED FORM LEVEL 1  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14217,
    name: "LIONVILLE-don''t use, see #0019751  SOUTHEASTERN PA (United States)",
    country: 'United States'
  },
  {
    id: 14218,
    name: 'UNIMED  GARDEN CITY PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 14220,
    name: 'JET MEDICAL ELECTRONICS INC.  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 14221,
    name: 'INFOLAB, INC.  CLARKSDALE MS (United States)',
    country: 'United States'
  },
  {
    id: 14225,
    name:
      'GYRUS ACMI (USE OLYMPUS AMERICA # 0019532)  MAPLE GROVE MN (United States)',
    country: 'United States'
  },
  {
    id: 14226,
    name: 'DOLBEY JAMISON OPTICAL COMPANY  POTTSTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 14227,
    name: 'DICTAPHONE  MELBOURNE FL (United States)',
    country: 'United States'
  },
  {
    id: 14228,
    name: 'TJS SALES ASSOCIATES, INC  DENVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14229,
    name: 'ULTRASONIC POWER CORPORATION  FREEPORT IL (United States)',
    country: 'United States'
  },
  {
    id: 14231,
    name: 'THE AUXILIARY OF ST. BARNABAS  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 14233,
    name: 'RC SERVICE  LYNBROOK NY (United States)',
    country: 'United States'
  },
  {
    id: 14237,
    name: 'AFC INTERNATIONAL INC.  DEMOTTE IN (United States)',
    country: 'United States'
  },
  {
    id: 14238,
    name: 'COMPUTER FIXER ON CAMPUS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14239,
    name: 'SOURCEONE HEALTHCARE TECHNOLOG  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14243,
    name: 'HITACHI HIGH TECHNOLOGIES AMER  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14244,
    name: 'HACH COMPANY  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14247,
    name: 'NOVA TECHNOLOGIES, INC  JULESBURG CO (United States)',
    country: 'United States'
  },
  {
    id: 14250,
    name: 'JOHNSTONE SUPPLY  HARAHAN LA (United States)',
    country: 'United States'
  },
  {
    id: 14251,
    name: 'SYNTHES  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14252,
    name: 'ORRCO, INC  ORRVILLE OH (United States)',
    country: 'United States'
  },
  {
    id: 14253,
    name: 'NATIONAL INSTRUMENT SERVICE CO  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14255,
    name: 'SO-LOW ENVIRONMENTAL EQPMT CO  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 14256,
    name: 'CBS WHITCOM TECHNOLOGIES CORP  ISLANDIA NY (United States)',
    country: 'United States'
  },
  {
    id: 14258,
    name: 'COLLINS MEDICAL INC  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14260,
    name: 'HACKER, INC  WINNSBOBO SC (United States)',
    country: 'United States'
  },
  {
    id: 14261,
    name: 'MDS NORDION  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14262,
    name: 'THERMO-ELECTRIC  IMPERIAL PA (United States)',
    country: 'United States'
  },
  {
    id: 14265,
    name: 'CARDINAL MEDICAL PRODUCTS  DUBLIN OH (United States)',
    country: 'United States'
  },
  {
    id: 14275,
    name: 'BioMediCon  MOORESTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 14277,
    name: 'BLACK BOX CORPORATION  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14278,
    name: 'BOCCONCINI  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14279,
    name: 'PELSTAR  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14282,
    name: 'HANS RUDOLPH, INC.  SHAWNEE KS (United States)',
    country: 'United States'
  },
  {
    id: 14283,
    name: 'INNOVATIVE PROD ACHVMENT  DULUTH GA (United States)',
    country: 'United States'
  },
  {
    id: 14284,
    name: 'EMERGIN  BOCA RATON FL (United States)',
    country: 'United States'
  },
  {
    id: 14285,
    name: 'DAKO NORTH AMERICA, INC.  CARPINTERIA CA (United States)',
    country: 'United States'
  },
  {
    id: 14286,
    name: 'INDEPENDENT BALANCING COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14288,
    name: 'FUJIFILM SONOSITE, INC  Bothell WA (United States)',
    country: 'United States'
  },
  {
    id: 14289,
    name: 'SEBRA  TUSCON AZ (United States)',
    country: 'United States'
  },
  {
    id: 14293,
    name: 'MULTIDATA SYSTEMS  ST LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 14297,
    name: 'U S FILTER / IONPURE, INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14298,
    name: 'BIOSENSE WEBSTER, INC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14299,
    name: 'CPATH SOLUTIONS, LLC  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 14300,
    name: 'EAST COAST LASER & MEDICAL INC  MULLICA HILL NJ (United States)',
    country: 'United States'
  },
  {
    id: 14301,
    name: 'PM PLUS  COVINGTON LA (United States)',
    country: 'United States'
  },
  {
    id: 14304,
    name: 'USE #0020614  Warrenville IL (United States)',
    country: 'United States'
  },
  {
    id: 14305,
    name: 'BOYD INDUSTRIES  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 14306,
    name: 'SANYO COMMERCIAL SOLUTIONS  WOOD DALE IL (United States)',
    country: 'United States'
  },
  {
    id: 14308,
    name: 'REMIN LABORATORIES INC  JOLIET IL (United States)',
    country: 'United States'
  },
  {
    id: 14310,
    name: 'PRECISION OPTICAL MACHINE  ROCKWELL PA (United States)',
    country: 'United States'
  },
  {
    id: 14313,
    name: 'AETNA DNU  CRANBURY NJ (United States)',
    country: 'United States'
  },
  {
    id: 14314,
    name: 'TRUE VALUE  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14315,
    name: 'AT&T  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 14316,
    name: 'COMMUNICATIONS SYS & SOLUTIONS  LANSDOWNE PA (United States)',
    country: 'United States'
  },
  {
    id: 14318,
    name: 'BIO-TECH MICROSCOPE SERVICE  PERKINSTON MS (United States)',
    country: 'United States'
  },
  {
    id: 14319,
    name: 'PNEUMATRON MEDICAL  ABITA SPRINGS LA (United States)',
    country: 'United States'
  },
  {
    id: 14320,
    name: 'SPRINT DNU  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14321,
    name: 'RADIO COMMUNICATIONS SERVICE  EDDYSTONE PA (United States)',
    country: 'United States'
  },
  {
    id: 14322,
    name: 'ASEPTICO  WOODINVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 14328,
    name: 'ALLTEL  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 14332,
    name: 'GURWIN JEWISH GERIATRIC FOUNDA  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 14333,
    name: 'DALE CARNEGIE CENTER OF EXCELL  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 14334,
    name: 'MEDIVATORS INC (formerly Minntech)  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14337,
    name: 'STRYKER ENDOSCOPY  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14338,
    name: 'ABIOMED, INC.  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14339,
    name: 'NATUS NEUROLOGY/ form. Care Fusion 209  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14342,
    name: 'LASER SERVICES OF AMERICA  KINGWOOD TX (United States)',
    country: 'United States'
  },
  {
    id: 14343,
    name: 'LABONCO CORPORATION  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 14346,
    name: 'RICHARD-ALLAN SCIENTIFIC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14348,
    name: 'BRINKMANN EPPENDORF SERVICES  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 14349,
    name: 'ADVANTAGE INDUSTRIAL SUPPLY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14350,
    name: 'ROCHE DIAGNOSTICS CORP  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14351,
    name: 'FOUR RIVERS SOFTWARE SYSTEMS  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14352,
    name: 'NATIONAL BUSINESS FURNITURE  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 14356,
    name: 'SERVO SYSTEMS  MONTVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14361,
    name: 'AIROYAL COMPANY  MAPLEWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 14362,
    name: 'MEDEQUIP BIOMEDICAL  DORAL FL (United States)',
    country: 'United States'
  },
  {
    id: 14363,
    name: 'SAMMONS PRESTON ROLYAN  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14366,
    name: 'TROPHIES BY JAY  MASSAPEQUA PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 14367,
    name:
      'ROSE E. WALSHIN - RENT ACCOUNT  ELK GROVE VILLAGE IL (United States)',
    country: 'United States'
  },
  {
    id: 14368,
    name: 'SOUTHERN ASSOCIATED SALES & SE  MEMPHIS TN (United States)',
    country: 'United States'
  },
  {
    id: 14369,
    name: 'INTEGRATED MEDICAL SYSTEMS INT  BIRMINGHAM AL (United States)',
    country: 'United States'
  },
  {
    id: 14370,
    name: 'GENERAL ANESTHETIC SERVICES  SOUTH PARK PA (United States)',
    country: 'United States'
  },
  {
    id: 14372,
    name: 'ARMALAB, LLC  BETHESDA MD (United States)',
    country: 'United States'
  },
  {
    id: 14375,
    name: 'SUNRISE MEDICAL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14376,
    name: 'INTERNATIONAL LIGHTING CORP  HAMMOND IN (United States)',
    country: 'United States'
  },
  {
    id: 14377,
    name: 'BIOMERIEUX INC  SAINT LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 14379,
    name: 'CONMED LINVATEC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14380,
    name: 'LIACS  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 14381,
    name: 'SKYTRON  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14382,
    name: 'TURN-KEY TECHNOLOGIES, INC  SAYREVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14385,
    name: 'AIRGAS EAST  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14389,
    name: 'NORTHWEST HOSPITAL PARKING  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 14392,
    name: 'ELECTRONIC DIAGNOSTIC & REPAIR  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 14395,
    name: 'PEAK TEK  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14396,
    name: 'HELENA LABORATORIES CORP.  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 14398,
    name: 'FIRST INSURANCE FUNDING CORPOR  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14400,
    name: 'QUASAR MEDICAL  BOLINGBROOK IL (United States)',
    country: 'United States'
  },
  {
    id: 14405,
    name: 'TECHNOTRADE INTERNATIONAL  MANCHESTER NH (United States)',
    country: 'United States'
  },
  {
    id: 14407,
    name: 'ELECTRO STEAM GENERATOR CORP  ALEXANDRIA VA (United States)',
    country: 'United States'
  },
  {
    id: 14409,
    name: 'THERMO CARDIOVASCULAR SYSTEMS  DETROIT MI (United States)',
    country: 'United States'
  },
  {
    id: 14415,
    name: 'QUIN TRON INSTRUMENT COMPANY  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 14418,
    name: 'INVISION  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 14420,
    name: 'WESTLAKE PHARMACEUTICAL SERV  COLLEGEVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 14422,
    name: 'ARROW INTERNATIONAL INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14423,
    name: 'DELTEC, INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14424,
    name: 'FISCHER MEDICAL LLC  Wheat Ridge CO (United States)',
    country: 'United States'
  },
  {
    id: 14425,
    name: 'SAFETY & SUPPLY CO.  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 14426,
    name: 'HOSPITAL OF THE UNIVERSITY OF  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14427,
    name: 'SMITH & NEPHEW, INC.  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 14434,
    name: 'EMPLOYEE SUPPORT NETWORK  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 14435,
    name: 'ENVIRONMENTAL MICROBIOLOGY LAB  SANTA ANA CA (United States)',
    country: 'United States'
  },
  {
    id: 14441,
    name: 'LA LABS INC  Kenner LA (United States)',
    country: 'United States'
  },
  {
    id: 14442,
    name: 'SURGICAL TABLE SERVICE COMPANY  AKRON OH (United States)',
    country: 'United States'
  },
  {
    id: 14444,
    name: 'BELL INC  WESTVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14446,
    name: 'PARTS NOW  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 14447,
    name: 'BBI SCIENTIFIC  Garden Grove CA (United States)',
    country: 'United States'
  },
  {
    id: 14448,
    name: 'AIR ROYAL  Mineola NY (United States)',
    country: 'United States'
  },
  {
    id: 14449,
    name: 'ALLEGIANCE HEALTHCARE/CARDINAL  Newark NJ (United States)',
    country: 'United States'
  },
  {
    id: 14450,
    name: 'ALLEGRO MEDICAL  Tempe AZ (United States)',
    country: 'United States'
  },
  {
    id: 14451,
    name: 'ALPHA OMEGA SHELVING  Carnegie PA (United States)',
    country: 'United States'
  },
  {
    id: 14452,
    name: 'AMERICAN DENTAL ACCESSORIES  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14456,
    name: 'BECKER PARKIN DENTAL & EQUIPME  HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 14457,
    name: 'BCC OF PHILADELPHIA COMPANY  LAUREL SPRINGS NJ (United States)',
    country: 'United States'
  },
  {
    id: 14458,
    name: 'BIO-TEK INSTRUMENTS, INC.  WINOOSKI VT (United States)',
    country: 'United States'
  },
  {
    id: 14460,
    name: 'BUFFALO DENTAL  SYOSSET NY (United States)',
    country: 'United States'
  },
  {
    id: 14461,
    name: 'KART-A-BAG  JOLIET IL (United States)',
    country: 'United States'
  },
  {
    id: 14462,
    name: 'CBG BIOTECH  OAKWOOD VILLAGE OH (United States)',
    country: 'United States'
  },
  {
    id: 14463,
    name: 'CLINICAL DATA INC.  LINCOLN RI (United States)',
    country: 'United States'
  },
  {
    id: 14464,
    name: 'CODMAN/J&J HEALTHCARE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14467,
    name: 'DELTA MEDICAL  VERNON CT (United States)',
    country: 'United States'
  },
  {
    id: 14468,
    name: 'CARDINAL/DETECTO  WEBB CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 14469,
    name: 'DeVIL BISS HEALTHCARE  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14470,
    name: 'ELECTRONIC DESIGN & SALES, INC  DANVILLE VA (United States)',
    country: 'United States'
  },
  {
    id: 14472,
    name: 'ENVIRCO CORPORATION  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 14474,
    name: 'EXELON POWER LABS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14476,
    name: 'GILWAY TECHNICAL LAMP  Woburn MA (United States)',
    country: 'United States'
  },
  {
    id: 14477,
    name: 'SIMPLEXGRINNELL LP  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 14478,
    name: 'MAGGARD PLUMBING  Rancho Cucamonga CA (United States)',
    country: 'United States'
  },
  {
    id: 14479,
    name: 'THE SCALE PEOPLE  COLUMBIA MD (United States)',
    country: 'United States'
  },
  {
    id: 14480,
    name: 'IMPACT WORLD WIDE LLC  Eudora KS (United States)',
    country: 'United States'
  },
  {
    id: 14481,
    name: 'INRAD INC  Northvale NJ (United States)',
    country: 'United States'
  },
  {
    id: 14482,
    name: 'INSIGHT  Stuart FL (United States)',
    country: 'United States'
  },
  {
    id: 14483,
    name: 'KEELER INSTRUMENTS  BROOMALL PA (United States)',
    country: 'United States'
  },
  {
    id: 14485,
    name: 'KREBS INSTRUMENTS  ENGELWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 14486,
    name: 'LONG ISLAND FORUM FOR TECH  BAY SHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 14487,
    name: 'MEDICAL DATA ELECTRONICS  Newark NJ (United States)',
    country: 'United States'
  },
  {
    id: 14489,
    name: 'MEDICAL LASER SERVICES  NOVI MI (United States)',
    country: 'United States'
  },
  {
    id: 14491,
    name: 'MEDIQ/PRN  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14493,
    name: 'BAYER formerly MEDRAD  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14498,
    name: 'MICROMEDICAL TECHNOLOGY  CHATHAM IL (United States)',
    country: 'United States'
  },
  {
    id: 14499,
    name: 'MKS-ASTEX  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14500,
    name: 'NBS CARD TECH  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14501,
    name: 'NEW YORK/NEW JERSEY SCIENTIFIC  MIDDLEBUSH NJ (United States)',
    country: 'United States'
  },
  {
    id: 14503,
    name: 'NONIN MEDICAL, INC.  PLYMOUTH MN (United States)',
    country: 'United States'
  },
  {
    id: 14504,
    name: 'NOVAMETRIX MEDICAL SYSTEMS, IN  WALLILNGFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 14506,
    name: 'OHAUS CORPORATION  PARSIPPANY NJ (United States)',
    country: 'United States'
  },
  {
    id: 14508,
    name: 'ON SITE GAS SYSTEMS  NEWINGTON CT (United States)',
    country: 'United States'
  },
  {
    id: 14509,
    name: 'ALLEN MEDICAL SYSTEMS, INC  ACTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14512,
    name: 'PARKER FILTRATION  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 14513,
    name: 'PARTSEARCH TECHNOLOGIES  New York NY (United States)',
    country: 'United States'
  },
  {
    id: 14514,
    name: 'PEERLESS ELECTRONICS, INC.  LYNBROOK NY (United States)',
    country: 'United States'
  },
  {
    id: 14515,
    name: 'PERKIN ELMER  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14516,
    name: 'PHILIPS HEALTHCARE  ANDOVER MA (United States)',
    country: 'United States'
  },
  {
    id: 14518,
    name: 'PHYSIOLOGIC REPS NC  GLENDALE CA (United States)',
    country: 'United States'
  },
  {
    id: 14519,
    name: 'PRESENTATIONS PLUS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14520,
    name: 'PRO SCIENTIFIC  OXFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 14521,
    name: 'PROTECH MEDICAL INC  Hazel Crest IL (United States)',
    country: 'United States'
  },
  {
    id: 14525,
    name: 'RETIF OIL & FUEL  New Orleans LA (United States)',
    country: 'United States'
  },
  {
    id: 14526,
    name: 'RIMA.COM  INDUSTRY CA (United States)',
    country: 'United States'
  },
  {
    id: 14527,
    name: 'SCORE INTERNATIONAL  SANFORD FL (United States)',
    country: 'United States'
  },
  {
    id: 14528,
    name: 'SERPAC  COVINA CA (United States)',
    country: 'United States'
  },
  {
    id: 14530,
    name: 'STAPLES ADVANTAGE  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14532,
    name: 'ADVANCED STERILIZATION (J&J)  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14534,
    name: 'HENRY SCHEIN, INC. (InSource)  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14535,
    name: 'SUNRISE TOOL  West Islip NY (United States)',
    country: 'United States'
  },
  {
    id: 14536,
    name: 'SUPPLIESUnited States  Jersey City NJ (United States)',
    country: 'United States'
  },
  {
    id: 14537,
    name: 'SV BIOMEDICAL LABS  EDMONDS WA (United States)',
    country: 'United States'
  },
  {
    id: 14538,
    name: 'T & M  Patchogue NY (United States)',
    country: 'United States'
  },
  {
    id: 14539,
    name: 'ENCORE MEDICAL GROUP INC.  WEST CHESTER PA (United States)',
    country: 'United States'
  },
  {
    id: 14540,
    name: 'THERA TEK United States  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 14541,
    name: "THERA'TRONICS  Mamaroneck NY (United States)",
    country: 'United States'
  },
  {
    id: 14542,
    name: 'THORATEC LABORATORIES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14544,
    name: 'TOPLINE MEDICAL  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14545,
    name: 'TRIMEDYNE  Irvine CA (United States)',
    country: 'United States'
  },
  {
    id: 14546,
    name: 'UNIVERSAL HOSP SERVICES, INC.  DONNERS DROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 14547,
    name: 'NOW NATUS NUEROLOGY- VENDOR # 0020932  GOLDEN CO (United States)',
    country: 'United States'
  },
  {
    id: 14549,
    name: 'WELCH ALLYN INC.  SKANEATELES FALLS NY (United States)',
    country: 'United States'
  },
  {
    id: 14550,
    name: 'WEST WORLD COMPUTERS  Albuquerque NM (United States)',
    country: 'United States'
  },
  {
    id: 14552,
    name: 'KEVIN T. CUNNINGHAM  FRANKFORT IN (United States)',
    country: 'United States'
  },
  {
    id: 14553,
    name: 'RED LION  NEWARK DE (United States)',
    country: 'United States'
  },
  {
    id: 14554,
    name: 'Becton, Dickinson and Company  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14555,
    name: 'K.D. CARPET & FLOORING, INC  SMITHTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 14556,
    name: 'BELIMED INFECTION CONTROL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14557,
    name: 'AIR COMPRESSOR EQUIPMENT INC  Wilson NC (United States)',
    country: 'United States'
  },
  {
    id: 14558,
    name: 'VILLAGE OFFICE SUPPLIES  SOMERSET NJ (United States)',
    country: 'United States'
  },
  {
    id: 14559,
    name: 'EMERGENCY MEDICAL ASSOCIATES  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14561,
    name: 'PRIME SOURCE SURGICAL  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 14562,
    name: 'FOX MEDICAL  Pennsauken NJ (United States)',
    country: 'United States'
  },
  {
    id: 14564,
    name: 'GE CAPITAL  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14565,
    name: 'LABTECH INC  Auburn WA (United States)',
    country: 'United States'
  },
  {
    id: 14566,
    name: 'A-VAC INDUSTRIES  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 14570,
    name: 'MOUSER ELECTRONICS  FORT WORTH TX (United States)',
    country: 'United States'
  },
  {
    id: 14572,
    name: 'BARNWELL HOUSE OF TIRES, INC  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 14574,
    name: 'BECTON DICKINSON  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 14576,
    name: 'ALL TEMP REFRDGE SERVICE INC  Madisonville LA (United States)',
    country: 'United States'
  },
  {
    id: 14577,
    name: 'RICHARD ALLAN SCIENTIFIC  Kalamazoo MI (United States)',
    country: 'United States'
  },
  {
    id: 14579,
    name: 'NEW JERSEY CORP TAX - CBT  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14581,
    name: 'SUBURBAN PEST MANAGEMENT  SMITHTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 14582,
    name: 'IMAGE DIAGNOSTICS INC  Sterling MA (United States)',
    country: 'United States'
  },
  {
    id: 14586,
    name: 'MIZUHO AMERICA  Beverly MA (United States)',
    country: 'United States'
  },
  {
    id: 14587,
    name: 'PREFERRED MEDICAL  Hallandale FL (United States)',
    country: 'United States'
  },
  {
    id: 14588,
    name: 'D&M MEDICAL  WAUCONDA IL (United States)',
    country: 'United States'
  },
  {
    id: 14589,
    name: 'ANESTHESIA EQUIPMENT SUPPLY IN  BLACK DIAMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 14590,
    name: 'BOEKEL SCIENTIFIC  Feasterville PA (United States)',
    country: 'United States'
  },
  {
    id: 14591,
    name: 'NORTHFIELD INSTRUMENT SERVICE  GLEN ALLEN VA (United States)',
    country: 'United States'
  },
  {
    id: 14592,
    name: 'MAJOR POWER  Champlain NY (United States)',
    country: 'United States'
  },
  {
    id: 14594,
    name:
      'A TISKET A TASKET ANYTHING IN A BASKET INC  HUNTINGTON NY (United States)',
    country: 'United States'
  },
  {
    id: 14595,
    name: 'ACME SCALE CO  SAN LEANDRO CA (United States)',
    country: 'United States'
  },
  {
    id: 14598,
    name: 'AT&T  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14603,
    name: 'OHMIC INSTRUMENTS CO  Easton MD (United States)',
    country: 'United States'
  },
  {
    id: 14604,
    name: 'NICE ELECTRONICS  Brooklyn NY (United States)',
    country: 'United States'
  },
  {
    id: 14605,
    name: 'GULF STATES ENGINEERING CO  Lafayette LA (United States)',
    country: 'United States'
  },
  {
    id: 14607,
    name: 'BYRON MEDICAL  TUCSON AZ (United States)',
    country: 'United States'
  },
  {
    id: 14608,
    name: 'R-V INDUSTRIES / BETA STAR  HONEY BROOK PA (United States)',
    country: 'United States'
  },
  {
    id: 14609,
    name: 'CAMBRIDGE APPLIED  Medford MA (United States)',
    country: 'United States'
  },
  {
    id: 14611,
    name: 'OMI SURGICAL PRODUCTS  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 14612,
    name: 'KAVA  Lake Zurich IL (United States)',
    country: 'United States'
  },
  {
    id: 14613,
    name: 'PHILIP ROSENAU CO INC  Warminster PA (United States)',
    country: 'United States'
  },
  {
    id: 14614,
    name: 'OFFICE TEAM  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14615,
    name: 'BACHARACH, INC.  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 14616,
    name: 'MEDASONICS-Use Cooper Surgical  Newark CA (United States)',
    country: 'United States'
  },
  {
    id: 14618,
    name: 'GENERAL PHYSIOTHERAPY  Earth City MO (United States)',
    country: 'United States'
  },
  {
    id: 14619,
    name: 'X-RITE INC  Grandville MI (United States)',
    country: 'United States'
  },
  {
    id: 14621,
    name: 'NIKON INSTRUMENTS INC.  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 14622,
    name: 'LFG RIGGING  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 14623,
    name: 'TOOL CRIB  Grand Forks ND (United States)',
    country: 'United States'
  },
  {
    id: 14624,
    name: 'BARTELS AND STOUT  BELLEVUE WA (United States)',
    country: 'United States'
  },
  {
    id: 14625,
    name: 'MJ RESEARCH INC  LAS VEGAS NV (United States)',
    country: 'United States'
  },
  {
    id: 14626,
    name: 'HUNTER WARFIELD  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 14629,
    name: 'M-PACT WORLDWIDE LLC  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 14631,
    name: 'JANDORF / ORRCO INC.  ORRVILLE OH (United States)',
    country: 'United States'
  },
  {
    id: 14632,
    name: 'CARE MEDICAL  Maumelle AR (United States)',
    country: 'United States'
  },
  {
    id: 14633,
    name: 'METAL CRAFT  MASON CITY IA (United States)',
    country: 'United States'
  },
  {
    id: 14635,
    name: 'SUPERIOR DIAGNOSTIC CORP  Tunkhannock PA (United States)',
    country: 'United States'
  },
  {
    id: 14637,
    name: 'ENVIRONMENTAL SYSTEMS REPAIR  Atco NJ (United States)',
    country: 'United States'
  },
  {
    id: 14638,
    name: 'SPECTRANETICS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14639,
    name: 'ENVIRONMENTAL TECTONICS CORP  Southampton PA (United States)',
    country: 'United States'
  },
  {
    id: 14640,
    name: 'MAINWAY AUTO BODY, INC.  BAY SHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 14643,
    name: 'BIOMEDICAL EQUIPMENT SERVICES  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 14645,
    name: 'TIGER AIR AND HEAT LLC  HARVEY LA (United States)',
    country: 'United States'
  },
  {
    id: 14646,
    name: 'KEM MEDICAL PRODUCTS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14648,
    name: 'COMMUTER BENEFIT PROGRAM  ENGLEWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 14649,
    name: 'EQUIPMENT ANCHORAGE, INC.  ARCADIA CA (United States)',
    country: 'United States'
  },
  {
    id: 14650,
    name: 'FRANK CALLAHAN COMPANY  COLMAR PA (United States)',
    country: 'United States'
  },
  {
    id: 14654,
    name: 'BODY MASTERS SPORTS  RAYNE LA (United States)',
    country: 'United States'
  },
  {
    id: 14655,
    name: 'TRIMLINE MEDICAL PRODUCTS CORP  RARITAN NJ (United States)',
    country: 'United States'
  },
  {
    id: 14657,
    name: 'MEDCARE PRODUCTS INC  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 14658,
    name: 'PHOTOVAC LASER CORP  GROVE CITY OH (United States)',
    country: 'United States'
  },
  {
    id: 14659,
    name: 'RESEARCH INSTRUMENT SERVICES  Arnold MD (United States)',
    country: 'United States'
  },
  {
    id: 14660,
    name: 'MESA LABORATORIES  LAKEWOOD CO (United States)',
    country: 'United States'
  },
  {
    id: 14661,
    name: 'MCV, INC  HAMMOND LA (United States)',
    country: 'United States'
  },
  {
    id: 14662,
    name: 'TERUMO CARDIOVASCULAR SYSTEMS  Atlanta GA (United States)',
    country: 'United States'
  },
  {
    id: 14663,
    name: 'MARILYN KENNEDY  WEST ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 14664,
    name: 'DEWALT FACTORY SERVICE  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 14669,
    name: 'MYRON CORPORATION  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14670,
    name: 'GENERAL SECURITY  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 14671,
    name: 'NETMIND CONSULTING SERVICES  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 14672,
    name: 'EZ WAY INC  Omaha NE (United States)',
    country: 'United States'
  },
  {
    id: 14674,
    name: 'GLOBAL CALIBRATION LABS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14678,
    name: 'USE 0021046 EDCO SALES  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14679,
    name: 'CONSOLIDATED PRODUCTS CO  LINWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 14681,
    name: 'PROSEC PROTECTION SYSTEMS CO  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 14682,
    name: 'GH ASSOCIATES LTD  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 14683,
    name: 'ARDUS MEDICAL  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 14685,
    name: 'KONIGSBERG INSTRUMENTS, INC  PASADENA CA (United States)',
    country: 'United States'
  },
  {
    id: 14686,
    name: 'ADVANCE MICRO POWER CORP  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 14687,
    name: 'SECHRIST INDUSTRIES  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 14688,
    name: 'BAXTER HEALTHCARE  Baltimore MD (United States)',
    country: 'United States'
  },
  {
    id: 14689,
    name: 'BIO-RAD LABORATORIES  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 14691,
    name: 'BELLAGIOS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 14692,
    name: 'SMITH, CARROAD, LEVY & FINKEL  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 14693,
    name: 'GLOBAL EQUIPMENT COMPANY  BUFORD GA (United States)',
    country: 'United States'
  },
  {
    id: 14694,
    name: 'KINETIC CONCEPTS INC  San Antonio TX (United States)',
    country: 'United States'
  },
  {
    id: 14695,
    name: 'J&J HEALTH CARE SYSTEMS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14696,
    name: 'GE OSMONIES, INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14698,
    name: 'EXPERT LASER SERVICES  MANTECA CA (United States)',
    country: 'United States'
  },
  {
    id: 14700,
    name: 'CONMED LINVATEC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14701,
    name: 'HEINE United States LIMITED  Dover NH (United States)',
    country: 'United States'
  },
  {
    id: 14703,
    name: "BJ'S WHOLESALE CLUB  RIVERHEAD NY (United States)",
    country: 'United States'
  },
  {
    id: 14706,
    name: 'DRUCKER COMPANY  Philipsburg PA (United States)',
    country: 'United States'
  },
  {
    id: 14707,
    name: 'INSTROMEDIX  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 14708,
    name: 'ENMET CORPORATION  Ann Arbor MI (United States)',
    country: 'United States'
  },
  {
    id: 14709,
    name: 'EAST MEADOW DRIVING SCHOOL  LEVITTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 14710,
    name: 'STATE OF THE ART MEDICAL PROD  CEDAR GROVE NJ (United States)',
    country: 'United States'
  },
  {
    id: 14711,
    name: 'BRYMILL CROGENICS SYSTEMS  Ellington CT (United States)',
    country: 'United States'
  },
  {
    id: 14715,
    name: 'SCHILLER AMERICA  DORAL FL (United States)',
    country: 'United States'
  },
  {
    id: 14716,
    name: 'MTI  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 14717,
    name: 'RITACCA LASER CENTER  Vernon Hills IL (United States)',
    country: 'United States'
  },
  {
    id: 14718,
    name: 'SCALES GALORE/ITIN SCALE CO.  Brooklyn NY (United States)',
    country: 'United States'
  },
  {
    id: 14719,
    name: 'JRM MEDICAL SERVICES INC  Cherry Hill NJ (United States)',
    country: 'United States'
  },
  {
    id: 14720,
    name: 'ITAC SYSTEMS INC  Garland TX (United States)',
    country: 'United States'
  },
  {
    id: 14721,
    name: 'WR MEDICAL  Stillwater MN (United States)',
    country: 'United States'
  },
  {
    id: 14722,
    name: 'BIOMEDICAL SOLUTIONS  New Orleans LA (United States)',
    country: 'United States'
  },
  {
    id: 14723,
    name: 'TLC ELECTRONICS  Lodi NJ (United States)',
    country: 'United States'
  },
  {
    id: 14725,
    name: 'BED TECHS INC  MILAN IN (United States)',
    country: 'United States'
  },
  {
    id: 14729,
    name: 'FULL VISION INC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 14730,
    name: 'BRIAN A FINN, SCALE COMPANY  WARRINGTON PA (United States)',
    country: 'United States'
  },
  {
    id: 14731,
    name: 'BULBWORKS  Succasunna NJ (United States)',
    country: 'United States'
  },
  {
    id: 14732,
    name: 'TECH TAG & LABEL  HATFIELD PA (United States)',
    country: 'United States'
  },
  {
    id: 14733,
    name: 'NOBEL BIOCARE  Yorba Linda CA (United States)',
    country: 'United States'
  },
  {
    id: 14734,
    name: 'AIR & PROCESS SYSTEMS INC  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 14735,
    name: 'SORIN GROUP United States, INC.  ARVADA CO (United States)',
    country: 'United States'
  },
  {
    id: 14737,
    name: 'CHEMSEARCH  HADDON TWP NJ (United States)',
    country: 'United States'
  },
  {
    id: 14738,
    name: 'THERAKOS INC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14739,
    name: 'HOPKINS MEDICAL  Pikesville MD (United States)',
    country: 'United States'
  },
  {
    id: 14740,
    name: 'CDW DIRECT, LLC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14741,
    name: 'TARRY MANUFACTURING  Danbury CT (United States)',
    country: 'United States'
  },
  {
    id: 14742,
    name: 'CRUCIAL TECHNOLOGY  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 14743,
    name: 'HELMER LABS  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 14744,
    name: 'STRATEGIC MEDICAL SALES  Bedford OH (United States)',
    country: 'United States'
  },
  {
    id: 14745,
    name: 'MED X CORPORATION  EAST BRUNSWCK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14747,
    name: 'QIAGEN INC  VALENCIA CA (United States)',
    country: 'United States'
  },
  {
    id: 14748,
    name: 'EDWARDS LIFESCIENCES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14749,
    name: 'SCOTT LAWSON GROUP  CONCORD NH (United States)',
    country: 'United States'
  },
  {
    id: 14750,
    name: 'ARTHREX  Naples FL (United States)',
    country: 'United States'
  },
  {
    id: 14751,
    name: 'DUBIN LUMBER  Philadelphia PA (United States)',
    country: 'United States'
  },
  {
    id: 14752,
    name: 'UNICOMP CORP  Lexington KY (United States)',
    country: 'United States'
  },
  {
    id: 14753,
    name: 'L&R MANUFACTURING CO  KEARNY NJ (United States)',
    country: 'United States'
  },
  {
    id: 14754,
    name: 'NEOPROBE CORP  DUBLIN OH (United States)',
    country: 'United States'
  },
  {
    id: 14755,
    name: 'OR GROUP INC  Acton MA (United States)',
    country: 'United States'
  },
  {
    id: 14756,
    name: 'ONSITE CALIBRATION SERVICE  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 14758,
    name: 'MIDLANTIC TECHNOLOGIES  Lansdale PA (United States)',
    country: 'United States'
  },
  {
    id: 14759,
    name: 'MIAMI MEDICAL  GLEN ALLEN VA (United States)',
    country: 'United States'
  },
  {
    id: 14761,
    name: 'OPTICS INC  Brunswick OH (United States)',
    country: 'United States'
  },
  {
    id: 14763,
    name: 'LAMONT MEDICAL EQUIPMENT  Camden NJ (United States)',
    country: 'United States'
  },
  {
    id: 14764,
    name: '4-STAR ELECTRONICS  San Clemente CA (United States)',
    country: 'United States'
  },
  {
    id: 14765,
    name: 'DECISION ONE CORPORATION  Minneapolis MN (United States)',
    country: 'United States'
  },
  {
    id: 14766,
    name: 'NUSTEP INC  ANN ARBOR MI (United States)',
    country: 'United States'
  },
  {
    id: 14767,
    name: 'POLAROID CORP  Hartford CT (United States)',
    country: 'United States'
  },
  {
    id: 14768,
    name: 'BROTHER INTERNATIONAL  Memphis TN (United States)',
    country: 'United States'
  },
  {
    id: 14769,
    name: 'PILLING WECK  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 14770,
    name: 'IMAGE STREAM MEDICAL  Littleton MA (United States)',
    country: 'United States'
  },
  {
    id: 14771,
    name: 'US SURGICAL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14773,
    name: 'CAVALIER COMPONENTS INC  MANALAPAN NJ (United States)',
    country: 'United States'
  },
  {
    id: 14774,
    name: 'A-VOX SYSTEMS INC  FAIR OAKS RANCH TX (United States)',
    country: 'United States'
  },
  {
    id: 14775,
    name: 'KRATOS MID-ATLANTIC  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 14776,
    name: 'SEATTLE SURGICAL REPAIR  Seattle WA (United States)',
    country: 'United States'
  },
  {
    id: 14777,
    name: 'LIFE SOURCE  Milpitas CA (United States)',
    country: 'United States'
  },
  {
    id: 14778,
    name: 'LABNET INTERNATIONAL INC  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14779,
    name: 'ABBOTT LABORATORIES  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14782,
    name: 'DATA CARD GROUP  ABESCON NJ (United States)',
    country: 'United States'
  },
  {
    id: 14783,
    name: 'STEREO OPTICAL CO INC  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 14784,
    name: 'REMEDPAR  NASHVILLE TN (United States)',
    country: 'United States'
  },
  {
    id: 14785,
    name: 'CURRENT THERAPEUTICS  Randolph NJ (United States)',
    country: 'United States'
  },
  {
    id: 14787,
    name: 'LIGHTING SPECIALTIES CO.  BUFFALO GROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 14788,
    name: 'RUHOF CORP  MINEOLA NY (United States)',
    country: 'United States'
  },
  {
    id: 14789,
    name: 'OBRIEN BUSINESS SYSTEMS  PLYMOUTH MEETING PA (United States)',
    country: 'United States'
  },
  {
    id: 14791,
    name: 'CANDELA CORP  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14794,
    name: 'K&S ASSOCIATES INC  NASHVILLE TN (United States)',
    country: 'United States'
  },
  {
    id: 14796,
    name: 'INTERLIGHT  HAMMOND IN (United States)',
    country: 'United States'
  },
  {
    id: 14797,
    name: 'ERBE United States  MARIETTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14798,
    name: 'PENN BIOMEDICAL SUPPORT INC  BLANDON PA (United States)',
    country: 'United States'
  },
  {
    id: 14799,
    name: 'SHAFER ENTERPRICES  Stockbridge GA (United States)',
    country: 'United States'
  },
  {
    id: 14800,
    name: 'ARMSTRONG MEDICAL INDIUSTRIES  Lincolnshire IL (United States)',
    country: 'United States'
  },
  {
    id: 14802,
    name: 'ASPECT MEDICAL  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 14803,
    name: 'MICRO-CLEAN  LEHIGH VALLEY PA (United States)',
    country: 'United States'
  },
  {
    id: 14805,
    name: 'KINEQUIP INC  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 14806,
    name: 'MODERN EQUIPMENT SALES & RENT  Bristol PA (United States)',
    country: 'United States'
  },
  {
    id: 14807,
    name: 'ELECTROCOM  LYNNWOOD WA (United States)',
    country: 'United States'
  },
  {
    id: 14809,
    name: 'NORTHWEST THERAPY SYSTEMS  Kirkland WA (United States)',
    country: 'United States'
  },
  {
    id: 14811,
    name: 'UNITED CAMERA REPAIR  Bensenville IL (United States)',
    country: 'United States'
  },
  {
    id: 14812,
    name: 'DANLEE MEDICAL PRODUCTS  Syracuse NY (United States)',
    country: 'United States'
  },
  {
    id: 14813,
    name: 'VERSAMED MEDICAL SYSTEMS (GE MEDICAL)  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 14814,
    name: 'MASIMO CORPORATION  Los Angeles CA (United States)',
    country: 'United States'
  },
  {
    id: 14815,
    name: 'WESTLAKE ELECTRONICS SUPPLY  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 14816,
    name: 'HERB FIELDS  SLIDELL LA (United States)',
    country: 'United States'
  },
  {
    id: 14817,
    name: 'GODFATHER SERVICE AND SUPPLY  SEA CLIFF NY (United States)',
    country: 'United States'
  },
  {
    id: 14818,
    name: 'GN OTOMETRICS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14819,
    name: 'BIOLOGIC SYSTEMS CORP  ELK GROVE VILLAGE IL (United States)',
    country: 'United States'
  },
  {
    id: 14820,
    name: 'RESMED  Los Angeles CA (United States)',
    country: 'United States'
  },
  {
    id: 14821,
    name: 'ITIN SCALE CO INC  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 14822,
    name: 'INTOXIMETERS INC  Saint Louis MO (United States)',
    country: 'United States'
  },
  {
    id: 14823,
    name: 'J & K MACHINING  SALFORDVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 14825,
    name: 'LOMBART INSTRUMENT  NORFORK VA (United States)',
    country: 'United States'
  },
  {
    id: 14826,
    name: 'CRYO ASSOCIATES  Rockville MD (United States)',
    country: 'United States'
  },
  {
    id: 14827,
    name: 'ULTRALIGHT ENTERPRISES INC  Lawrenceville GA (United States)',
    country: 'United States'
  },
  {
    id: 14828,
    name: 'SPECTRO SERVICE  Perkiomenville PA (United States)',
    country: 'United States'
  },
  {
    id: 14829,
    name: 'SOARING HEARTS  OLATHE KS (United States)',
    country: 'United States'
  },
  {
    id: 14831,
    name: 'MED-ELECTRONICS  COLLEGE PARK MD (United States)',
    country: 'United States'
  },
  {
    id: 14832,
    name: 'VENTANA MEDICAL SYSTEMS  AtTLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14833,
    name: 'ANIXTER  Pittsburgh PA (United States)',
    country: 'United States'
  },
  {
    id: 14834,
    name: 'A&J VACUUM SERVICES  Clifton NJ (United States)',
    country: 'United States'
  },
  {
    id: 14835,
    name: 'SALARE INC  HENDERSON NC (United States)',
    country: 'United States'
  },
  {
    id: 14836,
    name: 'United States SURGICAL  Norwalk CT (United States)',
    country: 'United States'
  },
  {
    id: 14837,
    name: 'B & B MICROSCOPES  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 14838,
    name: 'SEARS SUPPLY COMPANY  Durham NC (United States)',
    country: 'United States'
  },
  {
    id: 14839,
    name: 'CLINCAL TECHNOLOGY, INC.  BROADVIEW HEIGHTS OH (United States)',
    country: 'United States'
  },
  {
    id: 14840,
    name: 'NEWARK DENTAL  Springfield NJ (United States)',
    country: 'United States'
  },
  {
    id: 14842,
    name: 'OBTURA CORPORATION  Fenton MO (United States)',
    country: 'United States'
  },
  {
    id: 14843,
    name: 'PRECISION LASER  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 14844,
    name: 'ADVANCED THERAPUETICS CONCEPTS  Chattaroy WA (United States)',
    country: 'United States'
  },
  {
    id: 14845,
    name: 'M-STAR COMPANY  Wilmington OH (United States)',
    country: 'United States'
  },
  {
    id: 14846,
    name: 'GORDON STOWE & ASSOCIATES INC  WHEELING IL (United States)',
    country: 'United States'
  },
  {
    id: 14847,
    name: 'DIAGNOSTIC WORLD  Warwick NY (United States)',
    country: 'United States'
  },
  {
    id: 14848,
    name: 'SNOWDEN PENCER INC  Tucker GA (United States)',
    country: 'United States'
  },
  {
    id: 14849,
    name: 'ELECTRONIC RESOURCE INTL  Union IA (United States)',
    country: 'United States'
  },
  {
    id: 14850,
    name: 'ENTECH  TEMPE AZ (United States)',
    country: 'United States'
  },
  {
    id: 14852,
    name: 'RENNCO  Homer MI (United States)',
    country: 'United States'
  },
  {
    id: 14853,
    name: 'T.S.I. INC  Minneapolis MN (United States)',
    country: 'United States'
  },
  {
    id: 14854,
    name: 'ARTHROCARE  SUNNYVALE CA (United States)',
    country: 'United States'
  },
  {
    id: 14855,
    name: 'KENTEC MEDICAL INC  Irvine CA (United States)',
    country: 'United States'
  },
  {
    id: 14857,
    name: 'BELLCO  Vineland NJ (United States)',
    country: 'United States'
  },
  {
    id: 14858,
    name: 'INTEGRA OHIO  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14859,
    name: 'TRACKMASTER  Eastlake OH (United States)',
    country: 'United States'
  },
  {
    id: 14860,
    name: 'ETHICON ENDO SURGERY  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 14862,
    name: 'ALOKA  WALLINGFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 14863,
    name: 'ALLOMETRIC  Baton Rouge LA (United States)',
    country: 'United States'
  },
  {
    id: 14865,
    name: 'BC GROUP INTERNATIONAL  ST CHARLES MO (United States)',
    country: 'United States'
  },
  {
    id: 14866,
    name: 'CBS SCIENTIFIC INC  Solana Beach CA (United States)',
    country: 'United States'
  },
  {
    id: 14867,
    name: 'COMPUTER SERVICE AND INTEGRAT  MALVERN PA (United States)',
    country: 'United States'
  },
  {
    id: 14869,
    name: 'HOLLISTER, INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 14872,
    name: 'MEDICAL CONCEPTS  CHIPLEY FL (United States)',
    country: 'United States'
  },
  {
    id: 14874,
    name: 'CHAPMAN MANUFACTURING COMPANY  DURHAM CT (United States)',
    country: 'United States'
  },
  {
    id: 14875,
    name: 'CENORIN  KENT WA (United States)',
    country: 'United States'
  },
  {
    id: 14876,
    name: 'NEUROCOM INC  Clackamas OR (United States)',
    country: 'United States'
  },
  {
    id: 14877,
    name: 'FITNESS EQUIPMENT SERVICES  KENNER LA (United States)',
    country: 'United States'
  },
  {
    id: 14878,
    name: 'GEORGE PERCY MCGOWN  Brooklyn NY (United States)',
    country: 'United States'
  },
  {
    id: 14879,
    name: 'QUATRO SYSTEMS, INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 14880,
    name: 'DEN-PRO DENTAL SUPPLY INC  FEASTERVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 14881,
    name: 'NATIONAL SAFETY TECHNOLOGIES  TOLEDO OH (United States)',
    country: 'United States'
  },
  {
    id: 14883,
    name: 'IMMUCOR, INC.  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 14884,
    name: 'LIFELINE INSTRUMENTS  Redmond OR (United States)',
    country: 'United States'
  },
  {
    id: 14885,
    name: 'MICRO-OHM  Duarte CA (United States)',
    country: 'United States'
  },
  {
    id: 14886,
    name: 'DETEK COMPUTER SERVICES  Auburn WA (United States)',
    country: 'United States'
  },
  {
    id: 14887,
    name: 'CAST CUTTER SERVICE  Willow Grove PA (United States)',
    country: 'United States'
  },
  {
    id: 14888,
    name: 'BUSINESS INTEGRATED OFFICE SOL  METAIRIE LA (United States)',
    country: 'United States'
  },
  {
    id: 14889,
    name: 'GRAPHIC ENTERPRISE INC  Canton OH (United States)',
    country: 'United States'
  },
  {
    id: 14890,
    name: 'ALPHA OMEGA TECHNOLOGIES  Brielle NJ (United States)',
    country: 'United States'
  },
  {
    id: 14999,
    name: 'RAM ENGINEERING  Elmwood Park NJ (United States)',
    country: 'United States'
  },
  {
    id: 15442,
    name: 'ACORN INDUSTRIAL PRODUCTS  King Of Prussia PA (United States)',
    country: 'United States'
  },
  {
    id: 15443,
    name: 'BEACON MEDAES  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 15448,
    name: 'CRESTWOOD FARMS  BAYSHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 16157,
    name: 'G&L BAGELS, INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 16158,
    name: 'LASER PRODUCTS  Miami FL (United States)',
    country: 'United States'
  },
  {
    id: 16159,
    name: 'BAXA CORPORATION  DENVER CO (United States)',
    country: 'United States'
  },
  {
    id: 16160,
    name: "BIG JACK'S TOWING & PLOWING  WEST ROXBURY MA (United States)",
    country: 'United States'
  },
  {
    id: 16161,
    name: 'CIRCUIT CITY  Richmond VA (United States)',
    country: 'United States'
  },
  {
    id: 16162,
    name: 'MARTIN I. ORTIZ  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16167,
    name: 'KCM, INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 16168,
    name: 'RESPIRATORY SCIENCES INC.  ELMONT NY (United States)',
    country: 'United States'
  },
  {
    id: 16169,
    name: 'CHART INDUSTRIES  Cleveland OH (United States)',
    country: 'United States'
  },
  {
    id: 16170,
    name: 'NS ELECTRIC SUPPLY  Huntington Station NY (United States)',
    country: 'United States'
  },
  {
    id: 16171,
    name: 'FAI ELECTRONICS CORP.  Hauppauge NY (United States)',
    country: 'United States'
  },
  {
    id: 16174,
    name: 'AMPRONIX  Irvine CA (United States)',
    country: 'United States'
  },
  {
    id: 16175,
    name: 'VOLT SERVICES GROUP  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 16176,
    name: 'PRIMUS  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 16177,
    name: 'ARIZANT HEALTHCARE INC  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 16178,
    name: 'CLEANFULLY YOURS BUILDING MAIN  BAYSHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 16180,
    name: 'KNOTTS COMPANY  Berkeley Heights NJ (United States)',
    country: 'United States'
  },
  {
    id: 16182,
    name: 'PA SCDU  HARRISBURG PA (United States)',
    country: 'United States'
  },
  {
    id: 16183,
    name: 'TIGER MEDICAL  ROWLEY MA (United States)',
    country: 'United States'
  },
  {
    id: 16187,
    name: 'ALLIANCE MEDICAL LLC  BELCAMP MD (United States)',
    country: 'United States'
  },
  {
    id: 16188,
    name: 'CONTINENTAL EQUIPMENT CO  TONGANOXIE KS (United States)',
    country: 'United States'
  },
  {
    id: 16190,
    name: 'SECA CORPORATION  HANOVER MD (United States)',
    country: 'United States'
  },
  {
    id: 16192,
    name: 'VENODYNE  Columbus MS (United States)',
    country: 'United States'
  },
  {
    id: 16193,
    name: 'SECRETARY OF STATE WA  OLYMPIA WA (United States)',
    country: 'United States'
  },
  {
    id: 16198,
    name: 'MEDICAL GAS PROFESSIONAL  STILWELL KS (United States)',
    country: 'United States'
  },
  {
    id: 16199,
    name: 'EMERGENCY MEDICINE PHYS SERVIC  MEADOWBROOK PA (United States)',
    country: 'United States'
  },
  {
    id: 16200,
    name: 'YELLOW TRANSPORTATION, INC  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 16201,
    name: 'NEW ENGLAND MOTOR FREIGHT  ELIZABETH NJ (United States)',
    country: 'United States'
  },
  {
    id: 16202,
    name: 'HENRY TROEMNER LLC  THOROFARE NJ (United States)',
    country: 'United States'
  },
  {
    id: 16203,
    name: 'GREENE TRAINING INTERNATIONAL  LAKE FOREST IL (United States)',
    country: 'United States'
  },
  {
    id: 16204,
    name: 'OHIO DEPARTMENT OF JOB & FAMIL  COLUMBUS OH (United States)',
    country: 'United States'
  },
  {
    id: 16205,
    name: 'DIVISION OF UNEMPLOYMENT  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 16206,
    name: 'LOUISIANA DEPT. OF LABOR  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 16207,
    name: 'ARKANSAS EMPLOYMENT SECURITY  LITTLE ROCK AR (United States)',
    country: 'United States'
  },
  {
    id: 16208,
    name: 'BLICKMAN HEALTH SERVICES  Clifton NJ (United States)',
    country: 'United States'
  },
  {
    id: 16210,
    name: "ED''S CORNER SERVICE, LLC  EAST NORWALK CT (United States)",
    country: 'United States'
  },
  {
    id: 16211,
    name: 'STATEWIDE CORPORATION RESEARCH  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 16212,
    name: 'CHILDRESS & ZDEB, LTD.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16216,
    name: 'LORAD  Danbury CT (United States)',
    country: 'United States'
  },
  {
    id: 16218,
    name: 'HUGHES HITECH INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16219,
    name: 'MAQUET MEDICAL SYSTEMS  WAYNE NJ (United States)',
    country: 'United States'
  },
  {
    id: 16226,
    name: 'DISCOUNT COMPUTER PERIPHERALS  Melbourne FL (United States)',
    country: 'United States'
  },
  {
    id: 16229,
    name: 'TRANSAMERICA  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 16230,
    name: 'WESTERN BACKUP SOLUTIONS  Westminster CA (United States)',
    country: 'United States'
  },
  {
    id: 16232,
    name: 'SIMPLE TECHNOLOGIES  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 16233,
    name: 'BEE READY FISH BE IN H & D  MINEOLA NY (United States)',
    country: 'United States'
  },
  {
    id: 16235,
    name: 'HEALTH NET INSURANCE OF NY INC  HARTFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 16237,
    name: 'SECRETARY OF STATE- LA  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 16238,
    name: 'UNIV OF PA-ER  CHERRY HILL NJ (United States)',
    country: 'United States'
  },
  {
    id: 16240,
    name: 'INTERSCAN  Chatsworth CA (United States)',
    country: 'United States'
  },
  {
    id: 16243,
    name: 'YONKERS PARKING VIOLATIONS BUR  SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 16244,
    name: 'ENVIRONMENT OF CARE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16249,
    name: 'NYS DEPT. OF LABOR-UI DIVISION  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 16250,
    name: 'HOSPIRA WORLDWIDE, INC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16252,
    name: 'MEDICAL REPAIR SERVICE  BROOMALL PA (United States)',
    country: 'United States'
  },
  {
    id: 16256,
    name: 'AES/PHEAA  HARRISNBURG PA (United States)',
    country: 'United States'
  },
  {
    id: 16257,
    name: 'JULIEN E. MARX  MOBILE AL (United States)',
    country: 'United States'
  },
  {
    id: 16259,
    name: 'ACCESS MEDICAL LLC.  SANTA CLARITA CA (United States)',
    country: 'United States'
  },
  {
    id: 16260,
    name: 'AVOCENT GROUP, INC.  BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 16261,
    name: 'LOUISIANA DEPARTMENT OF REVENU  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 16263,
    name: 'BRIAN SHUBE CONSULTING  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 16268,
    name: 'CONSOLIDATED COMMUNICATIONS  DECATUR IL (United States)',
    country: 'United States'
  },
  {
    id: 16269,
    name: 'MASSACHUSETTS DIVISION OF EMPL  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 16270,
    name: 'UNIQUE AUTOMATION LLC  Newark NY (United States)',
    country: 'United States'
  },
  {
    id: 16271,
    name: 'SUPPLY NET  Valley Cottage NY (United States)',
    country: 'United States'
  },
  {
    id: 16272,
    name: 'TELEDYNE ANALYTICAL INST.  CITY OF INDUSTRY CA (United States)',
    country: 'United States'
  },
  {
    id: 16276,
    name: 'STEPHENS INTERNATIONAL  Lakeview AR (United States)',
    country: 'United States'
  },
  {
    id: 16277,
    name: 'HARRISON CONFERENCE AT GLEN  GLEN COVE NY (United States)',
    country: 'United States'
  },
  {
    id: 16278,
    name: 'DYNATRONICS  Salt Lake City UT (United States)',
    country: 'United States'
  },
  {
    id: 16279,
    name: 'CLINICAL DYNAMICS CORP  WALLINGFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 16281,
    name: 'CAPITAL PROCESS SERVERS, INC.  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 16282,
    name: 'ASC ACCOUNT 237201  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 16283,
    name: 'ROADWAY EXPRESS  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 16284,
    name: 'CONCENTRA MEDICAL CENTERS  MEMPHIS TN (United States)',
    country: 'United States'
  },
  {
    id: 16286,
    name: 'FASTENER INCORPORATED  Newington CT (United States)',
    country: 'United States'
  },
  {
    id: 16288,
    name: 'SPINK AND ASSOCIATES,LLC  WOODSTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 16289,
    name: 'AMERIFLEX, LLC  MOUNT LAUREL NJ (United States)',
    country: 'United States'
  },
  {
    id: 16290,
    name: 'FERRARIS MEDICAL  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 16293,
    name: 'INNOVATIVE LOGISTICS INC.  AMITYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 16296,
    name: 'SUFFOLK WEST BASEBALL  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 16297,
    name: 'THENERDS.COM  North Miami Beach FL (United States)',
    country: 'United States'
  },
  {
    id: 16298,
    name: 'INFOTEL  PLATTSBURGH NY (United States)',
    country: 'United States'
  },
  {
    id: 16299,
    name: "MARKS,O''NEILL,O''BRIEN & COURT  PHILADELPHIA PA (United States)",
    country: 'United States'
  },
  {
    id: 16300,
    name: 'WHITEHALL  CITY OF INDUSTRY CA (United States)',
    country: 'United States'
  },
  {
    id: 16303,
    name: 'PESCO, INC.  WESTWEGO LA (United States)',
    country: 'United States'
  },
  {
    id: 16306,
    name: 'LANDAUER, INC.  GLENWOOD IL (United States)',
    country: 'United States'
  },
  {
    id: 16309,
    name: 'STATE TECHNOLOGY  Bridgeport NJ (United States)',
    country: 'United States'
  },
  {
    id: 16310,
    name: 'COMPONENT SYSTEMS  Cleveland OH (United States)',
    country: 'United States'
  },
  {
    id: 16312,
    name: 'TRIPP ELECTRIC MOTOR  Belle Glade FL (United States)',
    country: 'United States'
  },
  {
    id: 16316,
    name: 'CATSKILL MEDICAL  EAST MEREDITH NY (United States)',
    country: 'United States'
  },
  {
    id: 16317,
    name: 'CERTIFIED SPECIALTIES INC  BAYSIDE NY (United States)',
    country: 'United States'
  },
  {
    id: 16319,
    name: 'REPRO-MED  Chester NY (United States)',
    country: 'United States'
  },
  {
    id: 16322,
    name: 'TOM HERNANDEZ  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 16323,
    name: 'WATKINS MOTOR LINES, INC.  LAKELAND FL (United States)',
    country: 'United States'
  },
  {
    id: 16324,
    name: 'HELLO DIRECT  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 16325,
    name: 'DR. DAVID J. ABRAMS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16327,
    name: 'MARYLAND UNEMPLOYMENT INS  BALTIMORE MD (United States)',
    country: 'United States'
  },
  {
    id: 16328,
    name: 'BIG BAST CHEVROLET  SEAFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 16330,
    name: 'STATE DISBURSEMENT UNIT  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 16331,
    name: 'PAETEC COMMUNICATIONS, INC.  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 16333,
    name: "BILLY DEE'S GLASS CAFE  HAUPPAUGE NY (United States)",
    country: 'United States'
  },
  {
    id: 16335,
    name: 'GARY W. MULLER, M.D.,P.C.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16336,
    name: 'MEDICAL PUBLISHING  ELMONT NY (United States)',
    country: 'United States'
  },
  {
    id: 16338,
    name: 'CAREERBUILDER, LLC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16339,
    name: 'MG GROUP  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 16342,
    name: 'COMMONWEALTH REPORTING COMPANY  CAMP HILL PA (United States)',
    country: 'United States'
  },
  {
    id: 16343,
    name: 'EVERGREEN HEALTHCARE  KIRKLAND WA (United States)',
    country: 'United States'
  },
  {
    id: 16346,
    name: 'KAPLAN, LEAMAN AND WOLFE  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16350,
    name: 'PORTNOY,MESSINGER,PEARL&ASSOC  SYOSSET NY (United States)',
    country: 'United States'
  },
  {
    id: 16351,
    name: 'GREY HOUSE PUBLISHING  MILLERTON NY (United States)',
    country: 'United States'
  },
  {
    id: 16352,
    name: 'INFINITI FINANCIAL SERVICES  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 16353,
    name: 'DEPT OF LABOR & INDUSTRIES  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 16354,
    name: 'UPS CAPITAL LEASING  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 16356,
    name: 'INC VILLAGE OF MINELOA  MINEOLA NY (United States)',
    country: 'United States'
  },
  {
    id: 16359,
    name: 'TRINITAS HEALTH FOUNDATION  ELIZABETH NJ (United States)',
    country: 'United States'
  },
  {
    id: 16362,
    name: 'NYS DEPARTMENT OF EDUCATION  ALBANY NY (United States)',
    country: 'United States'
  },
  {
    id: 16363,
    name: 'TEKNOWOLOGY, INC.  ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 16364,
    name: 'KEYSTONE HEALTH PLAN EAST  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16365,
    name: 'UNITED HEALTHCARE INSURANCE CO  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 16367,
    name: 'NEW HORIZONS PA  WILKES BARRE PA (United States)',
    country: 'United States'
  },
  {
    id: 16368,
    name: 'HAMPTON INN & SUITES  BEACHWOOD OH (United States)',
    country: 'United States'
  },
  {
    id: 16371,
    name: 'PERSONNEL CONCEPTS  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 16372,
    name: 'SOFTWARE SEARCH  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 16376,
    name: 'SIRIUS XM RADIO INC  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 16377,
    name: 'CINTAS CORPORATION #061  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16378,
    name: 'DEER PARK DIRECT  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 16384,
    name: 'LORMAN EDUCATION SERVICES  EAU CLAIRE WI (United States)',
    country: 'United States'
  },
  {
    id: 16385,
    name:
      'New EnglandSociety of Clinical Engineering  BEDFORD NH (United States)',
    country: 'United States'
  },
  {
    id: 16386,
    name: 'NYSSRC-SEC  BAYSIDE NY (United States)',
    country: 'United States'
  },
  {
    id: 16390,
    name: 'ARNOLD CHEVROLET  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 16397,
    name: 'PROPAGE, INC.  BRUNSWICK GA (United States)',
    country: 'United States'
  },
  {
    id: 16398,
    name: 'NEW ENGLAND FINANCIAL (FORMER TNE)  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 16399,
    name: 'THE MAT WEST COMPANY  VAN NUYS CA (United States)',
    country: 'United States'
  },
  {
    id: 16404,
    name: 'OMNI-PRESENTERS  HUNTINGTON NY (United States)',
    country: 'United States'
  },
  {
    id: 16405,
    name: 'FRED PRYOR SEMINARS  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 16407,
    name: 'ADELPHI UNIVERSITY  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 16409,
    name: 'SPRINT DNU  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 16410,
    name: 'BROWNS JEEP CHRYSLER  PATCGOGUE NY (United States)',
    country: 'United States'
  },
  {
    id: 16411,
    name: 'TOP ECHELON CONTRACTING, INC.  COLUMBUS OH (United States)',
    country: 'United States'
  },
  {
    id: 16417,
    name: 'TERESA PIAZZA  MASSAPEQUA NY (United States)',
    country: 'United States'
  },
  {
    id: 16418,
    name: "ST. BALDRICK'S FOUNDATION  PASADENA CA (United States)",
    country: 'United States'
  },
  {
    id: 16420,
    name: 'GVS-NY  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 16421,
    name: 'JOSEPH OESTERLE  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 16423,
    name: 'HESGNY  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 16426,
    name: 'AFLAC NEW YORK  COLUMBUS GA (United States)',
    country: 'United States'
  },
  {
    id: 16427,
    name: 'AARCO ENVIRONMENTAL SERVICES  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 16428,
    name: 'OFFICE FURNITURE DIRECT  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 16429,
    name: 'CARTRIDGE WORLD-BETHPAGE  BETHPAGE NY (United States)',
    country: 'United States'
  },
  {
    id: 16430,
    name: 'AMERICAN MANAGEMENT ASSOCIATIO  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 16433,
    name: 'SHERIFF OF SUFFOLK COUNTY  YAPHANK NY (United States)',
    country: 'United States'
  },
  {
    id: 16436,
    name: 'TEMPLE UNIVERSITY HOSPITAL PARKING  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 16439,
    name: 'ONE CALL COMMUNICATIONS, INC.  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 16442,
    name: "HANNIGAN'S TOWING  PORT CHESTER NY (United States)",
    country: 'United States'
  },
  {
    id: 16443,
    name: 'JOSE N. MORENO  BRADENTEN FL (United States)',
    country: 'United States'
  },
  {
    id: 16444,
    name: 'ANDREW B. HWANG  MONTEREY PARK CA (United States)',
    country: 'United States'
  },
  {
    id: 16448,
    name: 'ENVIRONMENT OF CARE LEADER  GAITHERSBURG MD (United States)',
    country: 'United States'
  },
  {
    id: 16449,
    name: 'ST. CHARLES HOSPITAL FOUNDATION  Port Jefferson NY (United States)',
    country: 'United States'
  },
  {
    id: 16453,
    name: 'HIGH POTENTIAL ELECTRIC, INC.  MASSAPEQUA PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 16759,
    name: 'BEST SOFTWARE  NORCROSS GA (United States)',
    country: 'United States'
  },
  {
    id: 18991,
    name: 'MAXIM INTERGRATED PRODUCTS  Dallas TX (United States)',
    country: 'United States'
  },
  {
    id: 18992,
    name: 'LINEAR TECHNOLOGY  Milpitas CA (United States)',
    country: 'United States'
  },
  {
    id: 18993,
    name: 'HAMILTON MEDICAL COMPANY  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 18995,
    name: 'BRENNER-FIEDLER  Cerritos CA (United States)',
    country: 'United States'
  },
  {
    id: 18997,
    name: 'MED SYSTEMS  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 18998,
    name: 'BURTON MEDICAL PRODUCTS  Chatsworth CA (United States)',
    country: 'United States'
  },
  {
    id: 19000,
    name: 'USE SPACELABS # 0011999  Bedford MA (United States)',
    country: 'United States'
  },
  {
    id: 19002,
    name: 'SCALE PEOPLE  COLUMBIA MD (United States)',
    country: 'United States'
  },
  {
    id: 19003,
    name: 'MCM SUPPLY  RIVERTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19004,
    name: 'SELECT MEDICAL PRODUCTS  Pinellas Park FL (United States)',
    country: 'United States'
  },
  {
    id: 19005,
    name: 'FARR TECHNICAL CENTER  Bowling Green KY (United States)',
    country: 'United States'
  },
  {
    id: 19006,
    name: 'DNTL WORKS EQUIPMENT CORP  Englewood CO (United States)',
    country: 'United States'
  },
  {
    id: 19009,
    name: 'CLEAN ROOM ENGINEERING  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 19010,
    name: 'DCI EQUIPMENT  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19014,
    name: 'FLANDERS/CSC  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19015,
    name: 'STATE TREASURER  OLYMPIA WA (United States)',
    country: 'United States'
  },
  {
    id: 19017,
    name: 'INTERMETRO INDUSTRIES CORP  Rancho Cucamonga CA (United States)',
    country: 'United States'
  },
  {
    id: 19018,
    name: 'TACOMA BIOMEDICAL INC  TACOMA WA (United States)',
    country: 'United States'
  },
  {
    id: 19019,
    name: 'PUBLIC BATTERY & SERVICE  Copiague NY (United States)',
    country: 'United States'
  },
  {
    id: 19020,
    name: 'BERKELEY MEDEVICES  RICHMOND CA (United States)',
    country: 'United States'
  },
  {
    id: 19022,
    name: 'MEDICAL EQUIPMENT SPECIALISTS  UNION LAKE MI (United States)',
    country: 'United States'
  },
  {
    id: 19023,
    name: 'TOBIAS ASSOCIATES  Warminster PA (United States)',
    country: 'United States'
  },
  {
    id: 19025,
    name: 'PARAGON ELECTRONICS  Fort Lauderdale FL (United States)',
    country: 'United States'
  },
  {
    id: 19026,
    name: 'CREDIT.NET  ST LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 19028,
    name: 'MAURITZON INC.  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 19032,
    name: 'OPTO SYSTEMS  NEWTON PA (United States)',
    country: 'United States'
  },
  {
    id: 19033,
    name: '2M TECHNOLOGIES  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 19034,
    name: 'FLUKE ELECTRONICS  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 19035,
    name: 'STERILQUIP INC.  SOUTH MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 19036,
    name: 'RISE / TWIN MEDICAL  EPHRATA PA (United States)',
    country: 'United States'
  },
  {
    id: 19038,
    name: 'Q SOURCE, INC.  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 19039,
    name: 'ENV SERVICES INC  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19042,
    name: 'PELTON AND CRANE  Charlotte NC (United States)',
    country: 'United States'
  },
  {
    id: 19043,
    name: 'VARIAN ANALYTICAL INSTRUMENTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19045,
    name: 'EMSAR  LACEY WA (United States)',
    country: 'United States'
  },
  {
    id: 19047,
    name: 'J.D. GOULD COMPANY  Indianapolis IN (United States)',
    country: 'United States'
  },
  {
    id: 19048,
    name: 'PRIMUS STERILIZER CO  OMAHA NE (United States)',
    country: 'United States'
  },
  {
    id: 19049,
    name: 'PARKMED  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19050,
    name: 'METTLER ELECTRONICS  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 19053,
    name: 'CROSS MEDICAL SPECIALTIES INC.  PITMAN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19054,
    name: 'AMIGO MOBILITY INTERNATIONAL  Bridgeport MI (United States)',
    country: 'United States'
  },
  {
    id: 19055,
    name: 'CADEX ELECTRONICS  RICHMOND BC (United States)',
    country: 'United States'
  },
  {
    id: 19056,
    name: 'R. C. SHARPE INC.  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 19058,
    name: 'TRUTECH LASER CORP  NORTH SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 19062,
    name: 'MORSE FLUID TECHNOLOGY  RAMSEY NJ (United States)',
    country: 'United States'
  },
  {
    id: 19063,
    name: 'PRECISION LASER SPECIALISTS  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 19064,
    name: 'DISCOUNT HYDRAULIC HOSE  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19065,
    name: 'AIR POWER TOOLS  ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 19066,
    name: 'MEDICAL RESOURCE United States  SAN ANTONIO TX (United States)',
    country: 'United States'
  },
  {
    id: 19067,
    name: 'LIFE FITNESS  SHILLER PARK IL (United States)',
    country: 'United States'
  },
  {
    id: 19068,
    name: 'RACINE MEDICAL ELECTRONICS  LAWRENCE MA (United States)',
    country: 'United States'
  },
  {
    id: 19070,
    name: 'POWERVAR  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 19071,
    name: 'STEAM SUPPLY , LLC  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 19072,
    name: 'SPARKS TECH  Batavia IL (United States)',
    country: 'United States'
  },
  {
    id: 19073,
    name: 'FOCUS United States MICROSCOPE  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 19075,
    name: 'MED ONE CAPITAL, INC.  SANDY UT (United States)',
    country: 'United States'
  },
  {
    id: 19076,
    name: 'MATRX  HARTFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 19077,
    name: 'EMSL ANALYTICAL LABORATORY  WESTMONT NJ (United States)',
    country: 'United States'
  },
  {
    id: 19078,
    name: "PRESCOTT''S, INC  MONUMENT CO (United States)",
    country: 'United States'
  },
  {
    id: 19079,
    name: 'McCARVILLE FORD  CENTEREACH NY (United States)',
    country: 'United States'
  },
  {
    id: 19095,
    name: 'ELMER SCHULTZ SERVICES  Philadelphia PA (United States)',
    country: 'United States'
  },
  {
    id: 19100,
    name: 'CARDINAL SCALE MANUFACTURING  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 19101,
    name: 'PARTSMASTER  Irving TX (United States)',
    country: 'United States'
  },
  {
    id: 19102,
    name: 'J.C. HAASE CONSULTING, LLC  WALDWICK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19103,
    name: 'EAST STAR MEDICAL, INC.  PEMBROKE PINES FL (United States)',
    country: 'United States'
  },
  {
    id: 19105,
    name: 'STAR MEDICAL EQUIPMENT, INC.  Conshohocken PA (United States)',
    country: 'United States'
  },
  {
    id: 19115,
    name: 'HUDSON RCI TELEFLEX  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19116,
    name: 'MEDEX REPAIR & SERVICE, INC  EAST BRUNSWICK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19117,
    name: 'STS NORTH AMERICA  CHAGRIN FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 19120,
    name: 'ASTRO- MED INC. [GRASS INSTRU]  West Warwick RI (United States)',
    country: 'United States'
  },
  {
    id: 19121,
    name: 'SONY  Kansas City MO (United States)',
    country: 'United States'
  },
  {
    id: 19122,
    name: 'United States SIENTIFIC  Ocala FL (United States)',
    country: 'United States'
  },
  {
    id: 19126,
    name: 'A.S.I. MEDICAL EQUIPMENT  Carrollton TX (United States)',
    country: 'United States'
  },
  {
    id: 19127,
    name: 'DOCKTERS X-RAY  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 19129,
    name: 'AMERICAN COLLEGIATE SCREEN  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19130,
    name: 'DICK BERG & ASSOCIATES  LIVINGSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 19132,
    name: 'NOVATEK MEDICAL  EFFINGHAM IL (United States)',
    country: 'United States'
  },
  {
    id: 19133,
    name: 'DEPENDABLE STERILIZER  WINTHROP HARBOR IL (United States)',
    country: 'United States'
  },
  {
    id: 19135,
    name: 'ENTERNET  Crofton MD (United States)',
    country: 'United States'
  },
  {
    id: 19142,
    name: 'SIGMA MEDICAL EQUIPMENT SVCS  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 19143,
    name: 'RACO INDUSTRIES  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 19144,
    name: 'TSI  SHOREVIEW MN (United States)',
    country: 'United States'
  },
  {
    id: 19145,
    name: 'MEDICAL SPECIALTIES  NEW ORLEANS LA (United States)',
    country: 'United States'
  },
  {
    id: 19146,
    name: 'LXU MEDICAL  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 19148,
    name: 'RESPITECH MEDICAL INC  LANCASTER PA (United States)',
    country: 'United States'
  },
  {
    id: 19150,
    name: 'MEDIVATORS (formerly MINNTECH CORP)  Minneapolis MN (United States)',
    country: 'United States'
  },
  {
    id: 19151,
    name: 'ALL SYSTEMS WIRELESS INC.  SOUTHAMPTON PA (United States)',
    country: 'United States'
  },
  {
    id: 19152,
    name: 'DAZTECH INCORPORATED  PERKASIE PA (United States)',
    country: 'United States'
  },
  {
    id: 19156,
    name: 'MALAND PRESENTATIONS  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 19157,
    name: "WARD'S INC  Rochester NY (United States)",
    country: 'United States'
  },
  {
    id: 19158,
    name: 'MED-ELECTRONIC  COLLEGE PARK MD (United States)',
    country: 'United States'
  },
  {
    id: 19159,
    name: 'R. W. TRUCK  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19168,
    name: 'UPGRADE SOURCE  Smithfield RI (United States)',
    country: 'United States'
  },
  {
    id: 19169,
    name: 'MICHAEL J WOLDOW AND COMPANY  CLAREMONT NH (United States)',
    country: 'United States'
  },
  {
    id: 19171,
    name: 'OPTHALMIC INSTRUMENTS  Stoughton MA (United States)',
    country: 'United States'
  },
  {
    id: 19173,
    name: 'NEW BRUNSWICK SCIENTIFIC  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19176,
    name: 'STERILIZER TECH SPECIALISTS  CHAGRIN FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 19179,
    name: 'BRANSON ULTRASONICS CORP  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19182,
    name: 'MOLECULAR DEVICES CORP  Union City CA (United States)',
    country: 'United States'
  },
  {
    id: 19183,
    name: 'SUBURBAN WATER TECHNOLGY  GILBERTSVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 19184,
    name: 'CAPINTEC SERVICE CENTER  Pittsburgh PA (United States)',
    country: 'United States'
  },
  {
    id: 19185,
    name: 'B&L PC SOLUTIONS, INC.  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 19186,
    name: 'OHIO MEDICAL CORP  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19188,
    name: 'INTERPOWER CORP.  OSKALOOSA IA (United States)',
    country: 'United States'
  },
  {
    id: 19189,
    name: 'INTUITIVE SURGICAL INC  SUNNYVALE CA (United States)',
    country: 'United States'
  },
  {
    id: 19191,
    name: 'CORRIGAN AND MANNING  FRAZER PA (United States)',
    country: 'United States'
  },
  {
    id: 19196,
    name: 'REDMOND CABLE  Redmond WA (United States)',
    country: 'United States'
  },
  {
    id: 19197,
    name: 'KANOMAX  ANDOVER NJ (United States)',
    country: 'United States'
  },
  {
    id: 19198,
    name: 'OLYMPUS SURGICA  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19199,
    name: 'HARD MFG. COMPANY  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 19202,
    name: 'NATIONAL DISPLAY SERVICES  MORGAN HILL CA (United States)',
    country: 'United States'
  },
  {
    id: 19210,
    name: 'GYNECARE ETHICON  Menlo Park CA (United States)',
    country: 'United States'
  },
  {
    id: 19211,
    name: 'SULLIVAN SCHEINE  Bastian VA (United States)',
    country: 'United States'
  },
  {
    id: 19212,
    name: 'SOMATICS  LAKE BLUFF IL (United States)',
    country: 'United States'
  },
  {
    id: 19213,
    name: 'SONAMED  WALTHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 19214,
    name: 'TOP BULB  EAST CHICAGO IN (United States)',
    country: 'United States'
  },
  {
    id: 19215,
    name: 'ADAMS GLOBAL COMMUNICATION  Shawnee Mission KS (United States)',
    country: 'United States'
  },
  {
    id: 19218,
    name: 'LUMINEX  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19219,
    name: 'CAMTEC ENTERPRISES INC  MOUNT LAUREL NJ (United States)',
    country: 'United States'
  },
  {
    id: 19220,
    name: 'GE CONSUMER & INDUSTRIAL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19221,
    name: 'ATLANTIC MEDICAL SPECIALTIES  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19222,
    name: 'PREMIER DENTAL PRODUCTS  PLYMOUTH MEETING PA (United States)',
    country: 'United States'
  },
  {
    id: 19223,
    name: 'TEAM MEDICAL  PLANO TX (United States)',
    country: 'United States'
  },
  {
    id: 19226,
    name: 'MODULARM CORPORATION  East Northport NY (United States)',
    country: 'United States'
  },
  {
    id: 19227,
    name: 'ART GORDON CASH REGISTERS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19228,
    name: 'INSOURCE  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19229,
    name: 'HALYARD HEALTH  ALPHARETTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19230,
    name: 'ALLIANCE BUSINESS SYSTEM  WEST PATERSON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19232,
    name: 'MEDICAL ELECTRONICS DIST.  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 19233,
    name: 'ARILEX BIOMEDICAL SERVICE  TULLYTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 19234,
    name: 'NATIONAL AIR FILTERS  Carlstadt NJ (United States)',
    country: 'United States'
  },
  {
    id: 19235,
    name: 'LATA TV AND VIDEO  Miami FL (United States)',
    country: 'United States'
  },
  {
    id: 19236,
    name: 'NQ INDUSTRIES INC.  ROCKY HILL CT (United States)',
    country: 'United States'
  },
  {
    id: 19237,
    name: 'MEDPRO  Port Washington NY (United States)',
    country: 'United States'
  },
  {
    id: 19238,
    name: 'BIOMEDICAL CONSULTING  SANFORD ME (United States)',
    country: 'United States'
  },
  {
    id: 19239,
    name: 'AIR FLOW INC  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 19241,
    name: 'QUEST TECHNOLOGIES  OCONOMOWOC WI (United States)',
    country: 'United States'
  },
  {
    id: 19242,
    name: 'POWERVAR  Waukegan IL (United States)',
    country: 'United States'
  },
  {
    id: 19243,
    name: 'ARDEN TECH SERVICE  ASHEVILLE NC (United States)',
    country: 'United States'
  },
  {
    id: 19244,
    name: 'AUSUS TECHNOLOGIES INC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19245,
    name: 'REMEL  Lenexa KS (United States)',
    country: 'United States'
  },
  {
    id: 19246,
    name: 'DIAGNOSTICA STAGO  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19247,
    name: 'SEPARATION TECHNOLOGY  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19249,
    name: 'AFI  WALPOLE MA (United States)',
    country: 'United States'
  },
  {
    id: 19251,
    name: 'CHART SEQUAL TECH  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 19253,
    name: 'LIKO, INC  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 19254,
    name: 'LAB LINE INSTRUMENTS  Dubuque IA (United States)',
    country: 'United States'
  },
  {
    id: 19255,
    name: 'ZUMA TECHNOLOGIES  MERRICK NY (United States)',
    country: 'United States'
  },
  {
    id: 19257,
    name: 'CASTERS & PARTS, INC  Conshohocken PA (United States)',
    country: 'United States'
  },
  {
    id: 19259,
    name: 'ALIMED, INC.  DEDHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 19260,
    name: 'GLOBAL FOCUS MARKETING & DISTR  Dallas TX (United States)',
    country: 'United States'
  },
  {
    id: 19261,
    name: 'LASER ENGINEERING INC.  NAVARRE BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 19262,
    name: 'CADWELL LABORATORIES  KENNEWICK WA (United States)',
    country: 'United States'
  },
  {
    id: 19263,
    name: 'CRYOSTAR  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 19264,
    name: 'BIOMEDICAL CONCEPTS  MANDEVILLE LA (United States)',
    country: 'United States'
  },
  {
    id: 19269,
    name: 'NAUTILUS PRODUCTS  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 19272,
    name: 'WORLD TECHNICAL SERVICES  PENSACOLA FL (United States)',
    country: 'United States'
  },
  {
    id: 19273,
    name: 'VIASYS HEALTHCARE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19274,
    name: 'SKYLINE DISPLAYS & GRAPHICS  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 19276,
    name: 'DYMAX CORP/BARD ACCESS SYSTEMS  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 19277,
    name: 'NATIONWIDE RESTORATION, LLC  METAIRIE LA (United States)',
    country: 'United States'
  },
  {
    id: 19278,
    name: 'MIZUHO OSI  UNION CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 19283,
    name: 'KANEKA PHARMA AMERICA  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19284,
    name: 'BULBMAN  RENO NV (United States)',
    country: 'United States'
  },
  {
    id: 19285,
    name: 'BRYANT AIR  N. BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 19287,
    name: 'COMPLETE MAINTENANCE SUPPLY  DEPTFORD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19288,
    name: 'MR. DIGITAL PRINTING  Farmingdale NY (United States)',
    country: 'United States'
  },
  {
    id: 19289,
    name: 'JAI  KINGSVILLE MD (United States)',
    country: 'United States'
  },
  {
    id: 19290,
    name: 'CULLIGAN WATER  Minneapolis MN (United States)',
    country: 'United States'
  },
  {
    id: 19291,
    name: 'RICH - MAR CORP  INOLA OK (United States)',
    country: 'United States'
  },
  {
    id: 19292,
    name: 'METROPOLITAN DATA SOLUTIONS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19293,
    name: 'DICKSON COMPANY  ADDISON IL (United States)',
    country: 'United States'
  },
  {
    id: 19294,
    name: 'CHIPNET  Arvada CO (United States)',
    country: 'United States'
  },
  {
    id: 19295,
    name: 'SURGICAL SPECIALTIES  ATANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19296,
    name: 'ATLANTIC RESPITORY SERVICE  ATKINSON NH (United States)',
    country: 'United States'
  },
  {
    id: 19297,
    name: "TOBY''S INSTRUMENT SHOP, INC  SALINE MI (United States)",
    country: 'United States'
  },
  {
    id: 19300,
    name: 'CLOCKWORK INNOVATIVE SOLUTIONS  Chattanooga TN (United States)',
    country: 'United States'
  },
  {
    id: 19301,
    name: 'HAYES GREATER LONG ISLAND  DENVER CO (United States)',
    country: 'United States'
  },
  {
    id: 19302,
    name: 'CARDIO PULMONARY TECHNOLOGIES  SUSSEX WI (United States)',
    country: 'United States'
  },
  {
    id: 19303,
    name: 'LASON SYSTEMS INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19304,
    name: 'MEDSERV PLUS  TWINSBURG OH (United States)',
    country: 'United States'
  },
  {
    id: 19306,
    name: 'FISHER CONSULTING SERVICE  DETROIT MI (United States)',
    country: 'United States'
  },
  {
    id: 19308,
    name: 'DORNIER MEDTECH  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 19309,
    name: "ONCOLOGY SERVICES INT'L.  RAMSEY NJ (United States)",
    country: 'United States'
  },
  {
    id: 19311,
    name: 'MACK INFORMATION SYSTEMS, INC.  WYNCOTE PA (United States)',
    country: 'United States'
  },
  {
    id: 19312,
    name: 'CIS-US, INC./PHARMALUCENCE  BEDFORD MA (United States)',
    country: 'United States'
  },
  {
    id: 19313,
    name: 'RJM SALES INC.  Scotch Plains NJ (United States)',
    country: 'United States'
  },
  {
    id: 19314,
    name: 'VORTECHS AUTOMATION, INC.  HUNTINGDON VALLEY PA (United States)',
    country: 'United States'
  },
  {
    id: 19315,
    name: 'IRIS SAMPLE PROCESSING  WESTWOOD MA (United States)',
    country: 'United States'
  },
  {
    id: 19316,
    name: 'CLEANAIR CERTIFICATIONS  SPRINGFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19318,
    name: 'CERIDIAN  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19319,
    name: 'SWK TECHNOLOGIES, INC.  LIVINGSTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19324,
    name: 'RHOADS METAL WORKS, INC.  PENNSAUKEN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19325,
    name: 'BANKDIRECT CAPITAL FINANCE LLC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19326,
    name: 'RUNNELLS SPECIALIZED  BERKELEY HEIGHTS NJ (United States)',
    country: 'United States'
  },
  {
    id: 19327,
    name: 'PEAK SCIENTIFIC  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 19329,
    name: 'AMERICAN TIME RECORDERS  VINELAND NJ (United States)',
    country: 'United States'
  },
  {
    id: 19334,
    name: 'EVOQUA WATER TECHNOLOGIES LLC  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19336,
    name: 'POSSIS  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 19337,
    name: 'SURGIMED-MLB, INC.  NEWTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 19340,
    name: 'ZMS  VERO BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 19341,
    name: 'UNITED BALANCE  Langhorne PA (United States)',
    country: 'United States'
  },
  {
    id: 19342,
    name: 'DYNAMIC SCALES INC.  TERRE HAUTE IN (United States)',
    country: 'United States'
  },
  {
    id: 19343,
    name: 'HALL RESEARCH TECHNOLOGIES  TUSTIN CA (United States)',
    country: 'United States'
  },
  {
    id: 19344,
    name: 'NEW YORK YANKEES  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19345,
    name: 'RICH-CO BUILDING ASSOCIATES  JERICHO NY (United States)',
    country: 'United States'
  },
  {
    id: 19346,
    name: 'GEORGIA DEPT OF REVENUE  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19347,
    name: 'NIKON INSTRUMENTS  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19349,
    name: 'ICC LOGISTICS SERVICES INC  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 19350,
    name: 'LABORATORY SAFETY SERVICES  BUTLER NJ (United States)',
    country: 'United States'
  },
  {
    id: 19351,
    name: 'MORTARA INSTRUMENT  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 19352,
    name: 'BD  SPARKS MD (United States)',
    country: 'United States'
  },
  {
    id: 19353,
    name: 'EMBROIDERY PARADISE, INC.  BRANSON MI (United States)',
    country: 'United States'
  },
  {
    id: 19354,
    name: 'U.S. INSTRUMENTS INC.  LANOKA HARBOR NJ (United States)',
    country: 'United States'
  },
  {
    id: 19357,
    name: 'INSTRUMENTATION ASSOCIATES  Phoenixville PA (United States)',
    country: 'United States'
  },
  {
    id: 19358,
    name: 'NEBS  Groton MA (United States)',
    country: 'United States'
  },
  {
    id: 19361,
    name: 'CBS TECHNOLOGIES  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19365,
    name: 'SCOTT SPECIALTY GASES/AIR LIQUIDE  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19366,
    name: 'BI EMERGENCY MEDICINE  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19370,
    name: 'ESSEX SURGICAL ARTS  BELLEVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 19372,
    name: 'MICROFORCE  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 19375,
    name: 'ELECTRO SURGICAL INSTRUMENT  ROCHESTER NY (United States)',
    country: 'United States'
  },
  {
    id: 19376,
    name: 'METRO IMAGING SERVICES INC  EAST HANOVER NJ (United States)',
    country: 'United States'
  },
  {
    id: 19382,
    name: 'Life Technologies Corp.  Carlsbad CA (United States)',
    country: 'United States'
  },
  {
    id: 19383,
    name: 'YOST MANUFACTURING CO.  HOLLAND MI (United States)',
    country: 'United States'
  },
  {
    id: 19384,
    name: 'PAUL H. GESSWEIN & CO., INC.  BRIDGEPORT CT (United States)',
    country: 'United States'
  },
  {
    id: 19387,
    name: 'AWISCO  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 19388,
    name: 'RIO GRANDE  ALBUQUERQUE NM (United States)',
    country: 'United States'
  },
  {
    id: 19390,
    name: 'HOSP - UNIV OF PENNSYLVANIA  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19391,
    name: 'CPUP-PFS -- use vendor#19765  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19393,
    name: 'GROBET FILE COMPANY  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19395,
    name: 'THE PLASTIC FORMING COMAPNY  WOODBRIDGE CT (United States)',
    country: 'United States'
  },
  {
    id: 19396,
    name: 'NATIONAL STORAGE & CONVEYOR  SETAUKET NY (United States)',
    country: 'United States'
  },
  {
    id: 19397,
    name: 'STATE DISBURSEMENT UNIT  WEST SACRAMENTO CA (United States)',
    country: 'United States'
  },
  {
    id: 19399,
    name: 'BIG ANT ELECTRIC, INC.  WEST HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 19400,
    name: 'ASHTEAD TECHNOLOGY  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 19401,
    name: 'J G WELLS SALES CO  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 19403,
    name: 'TRINCO  FRASER MI (United States)',
    country: 'United States'
  },
  {
    id: 19404,
    name: 'MAYTEK CONSULTING,INC.  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19405,
    name: 'SLEEP DISORDERS SERVICES  CHERRY HILL NJ (United States)',
    country: 'United States'
  },
  {
    id: 19406,
    name: 'DIAGNOSTIC PRODUCTS CORP  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 19407,
    name: 'CALLAHAN PARTNERS,LLC  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 19408,
    name: 'ALLEN MAXWELL & SILVER, INC.  ENGLEWOOD CLIFFS NJ (United States)',
    country: 'United States'
  },
  {
    id: 19409,
    name: 'UPS FREIGHT  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 19411,
    name: 'VITALOGRAPH INC  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 19413,
    name: 'United States SHRED  MASPETH NY (United States)',
    country: 'United States'
  },
  {
    id: 19415,
    name: 'EAGLE MICROSCOPE SERVICE  WEST CHESTER PA (United States)',
    country: 'United States'
  },
  {
    id: 19417,
    name: 'TRIPATH  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19418,
    name: 'ROCKLIN MANUFACTURING CO.  SIOUX CITY IA (United States)',
    country: 'United States'
  },
  {
    id: 19419,
    name: 'OXYGEN PLUS MEDICAL SYSTEMS  MONEATA VA (United States)',
    country: 'United States'
  },
  {
    id: 19423,
    name: 'ROSS LABORATORIES  COLUMBUS OH (United States)',
    country: 'United States'
  },
  {
    id: 19427,
    name: 'ANRON SERVICE CORP.  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 19428,
    name: 'PRUZAN MEDICAL EQUIPMENT  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 19429,
    name: 'OLYMPUS AMERICA INC.  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19430,
    name: 'TECH-MEDICAL SERVICES, INC.  NIXA MO (United States)',
    country: 'United States'
  },
  {
    id: 19431,
    name: 'SONORA MEDICAL SYSTEMS  LONGMONT CO (United States)',
    country: 'United States'
  },
  {
    id: 19433,
    name: 'NAPTECH TEST EQUIPMENT INC.  LOWER LAKES CA (United States)',
    country: 'United States'
  },
  {
    id: 19434,
    name: 'BUSINESS FORMS, INC.  GLENDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19437,
    name: 'NORTHEAST IMAGING ASSOC OF PHI  MOUNT LAUREL NJ (United States)',
    country: 'United States'
  },
  {
    id: 19438,
    name: 'PA HEART & VASCULAR GROUP  HUNTINGDON VALLEY PA (United States)',
    country: 'United States'
  },
  {
    id: 19440,
    name: 'SOSSNER STEEL STAMPS  ELIZABETHTON TN (United States)',
    country: 'United States'
  },
  {
    id: 19442,
    name: 'WINTERS  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 19444,
    name: 'TERUMO MEDICAL CORPORATION  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19446,
    name: 'EMERALD-ASSOCIATED  WANTAGH NY (United States)',
    country: 'United States'
  },
  {
    id: 19447,
    name: 'MEDICAL TECHNOLOGY ASSOCIATES  LARGO FL (United States)',
    country: 'United States'
  },
  {
    id: 19448,
    name: 'PREMEIR TEK, INC.  Lawrenceville GA (United States)',
    country: 'United States'
  },
  {
    id: 19449,
    name: 'iDOTpc INTERNATIONAL INC.  HAYWARD CA (United States)',
    country: 'United States'
  },
  {
    id: 19450,
    name: 'DATA LOGGERS, INC.  SANTA CLARA CA (United States)',
    country: 'United States'
  },
  {
    id: 19451,
    name: 'GUIDANT  Santa Clara CA (United States)',
    country: 'United States'
  },
  {
    id: 19452,
    name: 'ATLAS FITNESS SERVICES  CARLE PLACE NY (United States)',
    country: 'United States'
  },
  {
    id: 19453,
    name: 'NATIONAL BIOLOGICAL CORP.  BEACHWOOD OH (United States)',
    country: 'United States'
  },
  {
    id: 19454,
    name: 'TOPCON AMERICA CORP  Paramus NJ (United States)',
    country: 'United States'
  },
  {
    id: 19455,
    name: 'USHIO AMERICA, INC.  Cypress CA (United States)',
    country: 'United States'
  },
  {
    id: 19456,
    name: 'L. W. SCIENTIFIC  LAWRENCEVILLE GA (United States)',
    country: 'United States'
  },
  {
    id: 19459,
    name: 'BMC INSTRUMENT SERVICE INC.  BAYSHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 19460,
    name: 'THE MEDICAL CENTER, INC.  Columbus GA (United States)',
    country: 'United States'
  },
  {
    id: 19463,
    name: 'AORN OF SOUTH FLORIDA  HOLLYWOOD FL (United States)',
    country: 'United States'
  },
  {
    id: 19465,
    name: 'RAININ INSTRUMENTS, LLC  OAKLAND CA (United States)',
    country: 'United States'
  },
  {
    id: 19467,
    name: 'BIOMEDICS, INC.  LAGUNA HILLS CA (United States)',
    country: 'United States'
  },
  {
    id: 19468,
    name: 'DEPARTMENT OF SOCIAL SERVICES  BATON ROUGE LA (United States)',
    country: 'United States'
  },
  {
    id: 19469,
    name: 'HIGHER EDUCATION STUDENT ASSIS  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19470,
    name: 'CADMET  MALVERN PA (United States)',
    country: 'United States'
  },
  {
    id: 19471,
    name: 'VALLEY LAB/US SURGICAL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19473,
    name: 'JJ WHITE INC  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19474,
    name: 'ALDEN TECHNICAL SERVICES  FAIRVIEW NC (United States)',
    country: 'United States'
  },
  {
    id: 19475,
    name: 'ACUDERM INC.  Fort Lauderdale FL (United States)',
    country: 'United States'
  },
  {
    id: 19476,
    name: 'AMSTERDAM PRINTING & LITHO  AMSTERDAM NY (United States)',
    country: 'United States'
  },
  {
    id: 19478,
    name: 'SALYER BIOMEDICAL LLC  SAINT LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 19480,
    name: 'MCCORMICK MEDICAL  LYNNWOOD WA (United States)',
    country: 'United States'
  },
  {
    id: 19481,
    name: 'CAS MEDICAL SYSTEMS, INC.  Branford CT (United States)',
    country: 'United States'
  },
  {
    id: 19482,
    name: 'PERFECT HEALTH INSURANCE CO  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19483,
    name: 'V-TECH EQUIPMENT SERVICE INC.  LANGHORNE PA (United States)',
    country: 'United States'
  },
  {
    id: 19484,
    name: 'EMS TECHNOLOGIES, INC.  LEOLA PA (United States)',
    country: 'United States'
  },
  {
    id: 19485,
    name: 'THE PANGBURN COMPANY  NEW ROADS LA (United States)',
    country: 'United States'
  },
  {
    id: 19486,
    name: 'V-TECH EQUIPMENT SERVICE  Feasterville Trevose PA (United States)',
    country: 'United States'
  },
  {
    id: 19487,
    name: 'CODEMICRO  Vista CA (United States)',
    country: 'United States'
  },
  {
    id: 19488,
    name: 'KRAMER ELECTRONICS  Hampton NJ (United States)',
    country: 'United States'
  },
  {
    id: 19489,
    name: 'PRYOR PRODUCTS  Oceanside CA (United States)',
    country: 'United States'
  },
  {
    id: 19490,
    name: 'INNER CIRCLE DENTAL EQUIP  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19491,
    name: 'NICE SYSTEMS  UNIONDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19492,
    name: 'CABRINI ER PHYSICIAN SERVS  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19493,
    name: 'INPHYNET PRMRY CARE PHY  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19494,
    name: 'LAWRENCE ENVIRONMENTAL GROUP  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 19495,
    name: 'AIR PURIFICATION TECHNOLOGIES  Prior Lake MN (United States)',
    country: 'United States'
  },
  {
    id: 19498,
    name: 'RT PHOTO REPAIR  LODI NJ (United States)',
    country: 'United States'
  },
  {
    id: 19499,
    name: 'WLADMENN LIGHTING  Wheeling IL (United States)',
    country: 'United States'
  },
  {
    id: 19500,
    name: "TRUE FITNESS TECHNOLOGY  O'FALLON MO (United States)",
    country: 'United States'
  },
  {
    id: 19502,
    name: 'METROAMERICAN RADIOLOGY CONSUL  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19504,
    name: 'EARTHLINK/ONE COMMUNICATIONS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19505,
    name: 'FIRST HSA  READING PA (United States)',
    country: 'United States'
  },
  {
    id: 19507,
    name: 'FORTEC MEDICAL INC.  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 19508,
    name: 'VISION FITNESS  Lake Mills WI (United States)',
    country: 'United States'
  },
  {
    id: 19510,
    name: 'MK BATTERY  AHANEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 19512,
    name: 'EDMUND G. TODD  BEAUMONT CA (United States)',
    country: 'United States'
  },
  {
    id: 19513,
    name: 'TENACORE  SANTA ANA CA (United States)',
    country: 'United States'
  },
  {
    id: 19514,
    name: 'SYBRON ENDO  REDMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 19515,
    name: 'RESPERTECK  LANCASTER PA (United States)',
    country: 'United States'
  },
  {
    id: 19516,
    name: 'POPPER & SONS, INC.  NEW HYDE PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 19517,
    name: 'LUXTEC (INTEGRA)  WEST BOYLSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 19518,
    name: 'BIO THERM  GUYS TN (United States)',
    country: 'United States'
  },
  {
    id: 19519,
    name: 'NEWPORT MEDICAL INSTR, DBA COVIDIEN  COSTA MESA CA (United States)',
    country: 'United States'
  },
  {
    id: 19520,
    name: 'CREST HEALTHCARE  DASSEL MN (United States)',
    country: 'United States'
  },
  {
    id: 19521,
    name: 'SYLVAN CORP  IRWIN PA (United States)',
    country: 'United States'
  },
  {
    id: 19522,
    name: 'ACCU-SCOPE  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 19523,
    name: 'CMI  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19524,
    name: 'NJ SCIENTIFIC  SOMERSET NJ (United States)',
    country: 'United States'
  },
  {
    id: 19525,
    name: 'MED-TECH EQUIPMENT INC.  SWEDESBORO NJ (United States)',
    country: 'United States'
  },
  {
    id: 19526,
    name: 'GE WALKER  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 19527,
    name: 'SHELTON LAB  CORNELIUS OR (United States)',
    country: 'United States'
  },
  {
    id: 19528,
    name: 'VITAL SIGNS, INC.  TOTOWA NJ (United States)',
    country: 'United States'
  },
  {
    id: 19529,
    name: 'SPIRAX SARCO INC.  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19530,
    name: 'SOUTHERN MEDICAL SERVICES  SLIDELL LA (United States)',
    country: 'United States'
  },
  {
    id: 19531,
    name: 'NORTHEASTERN TECH GROUP, INC.  GLEN COVE NY (United States)',
    country: 'United States'
  },
  {
    id: 19532,
    name: 'OLYMPUS AMERICA  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19533,
    name: 'INVERNESS MEDICAL  PRINCETON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19534,
    name: 'ADVANCED BIO-MEDICAL  SLIDELL LA (United States)',
    country: 'United States'
  },
  {
    id: 19535,
    name: 'BIOTECH MICROSCOPE  PERKINSTON MS (United States)',
    country: 'United States'
  },
  {
    id: 19536,
    name: 'NEWPORT SCIENTIFIC, INC.  JESSUP MD (United States)',
    country: 'United States'
  },
  {
    id: 19537,
    name: 'RENAL TECHNOLOGY  SCOTCH PLAINS NJ (United States)',
    country: 'United States'
  },
  {
    id: 19538,
    name: 'MICRO-AIRE  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19539,
    name: 'LENOVO  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19540,
    name: 'NCE BATTERIES  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 19541,
    name: 'P.I.P.E  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 19542,
    name: 'SCOTTCARE CORP.  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 19543,
    name: 'SHOR-LINE  KANSAS CITY KS (United States)',
    country: 'United States'
  },
  {
    id: 19545,
    name: 'ZEISS  Thornwood NY (United States)',
    country: 'United States'
  },
  {
    id: 19546,
    name: 'BFW, INC.  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 19547,
    name: 'FITNESS EXPO  METARIE LA (United States)',
    country: 'United States'
  },
  {
    id: 19548,
    name: 'COMP United States  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19549,
    name: 'WATERS INSTRUMENTS  ROCHESTER MN (United States)',
    country: 'United States'
  },
  {
    id: 19550,
    name: 'TIME MOTION TOOLS  PASADENA CA (United States)',
    country: 'United States'
  },
  {
    id: 19551,
    name: 'RED BALL INC.  SHEVEPORT LA (United States)',
    country: 'United States'
  },
  {
    id: 19552,
    name: 'COMPUTER NETWORK SOLUTIONS  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 19553,
    name: 'LIFE MEDICAL NETWORKS  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19554,
    name: 'DIAMEDIX  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19555,
    name: 'GORDON CASH REGISTER  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19556,
    name: 'TOSOH BIOSCIENCE  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19557,
    name: 'SOUTHERN MICROSCOPE SERVICE  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19558,
    name: 'STRECK  OMAHA NE (United States)',
    country: 'United States'
  },
  {
    id: 19559,
    name: 'MEDLINE INDUSTRIES  Pittsburgh PA (United States)',
    country: 'United States'
  },
  {
    id: 19560,
    name: 'SPORTSMITH LLC  TULSA OK (United States)',
    country: 'United States'
  },
  {
    id: 19561,
    name: 'OSTEOMED  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19562,
    name: 'NORTHEAST MEDICAL EQUPMENT  MORRISTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19563,
    name: 'PC MALL  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 19564,
    name: 'I.V.R.I.  YORK PA (United States)',
    country: 'United States'
  },
  {
    id: 19565,
    name: 'McCORMICK MEDICAL DISTRIBUTION  LYNNWOOD WA (United States)',
    country: 'United States'
  },
  {
    id: 19566,
    name: 'AKBA  COPIAGUE NY (United States)',
    country: 'United States'
  },
  {
    id: 19567,
    name: 'INTERNATIONAL TECHNIDYNE CORP  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19568,
    name: 'CONE INSTRUMENTS  SOLON OH (United States)',
    country: 'United States'
  },
  {
    id: 19569,
    name: 'CABLES TO GO  DAYTON OH (United States)',
    country: 'United States'
  },
  {
    id: 19570,
    name: 'SAGE SOFTWARE  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 19571,
    name: 'MICROSCOPY United States  POTTSDOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 19572,
    name: 'VISTA IMAGING, LLC  EAST BRUNSWICK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19573,
    name: 'CYTYC CORP  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19574,
    name: 'FINE INDUSTRIAL SUPPLY  BALDWIN NY (United States)',
    country: 'United States'
  },
  {
    id: 19575,
    name: 'RTI ELECTRONICS  TOWACO NJ (United States)',
    country: 'United States'
  },
  {
    id: 19576,
    name: 'AMBCO ELECTRONICS  TUSTIN CA (United States)',
    country: 'United States'
  },
  {
    id: 19577,
    name: 'M KESSLER HARDWARE  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19578,
    name: 'JOHNSON PLASTICS & SUPPLY  EVANSVILE IN (United States)',
    country: 'United States'
  },
  {
    id: 19579,
    name: 'COLONIAL PRECISION MACHINE  FARMINDGALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19580,
    name: 'GESSWEIN  BRIDGEPORT CT (United States)',
    country: 'United States'
  },
  {
    id: 19581,
    name: 'GENERAL BIOMEDICAL SERVICES  KENNER LA (United States)',
    country: 'United States'
  },
  {
    id: 19582,
    name: 'FUNDAMENTALS OF PURCHASING  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19583,
    name: 'HOLY REDEEMER  JENKINTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 19584,
    name: 'CYMAR CORP  CARLINVILLE IL (United States)',
    country: 'United States'
  },
  {
    id: 19585,
    name: 'ROSLYN OPTICS COMPANY  COVINA CA (United States)',
    country: 'United States'
  },
  {
    id: 19586,
    name: 'TRINITY BIOTECH  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19587,
    name: 'SCALES INDUSTRIAL TECH  CARLE PLACE NY (United States)',
    country: 'United States'
  },
  {
    id: 19588,
    name: 'GARY PLASTIC PACKAGING CORP.  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 19589,
    name: 'HEARTLAND MEDICAL  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 19590,
    name: 'ABSOLUTE MEDICAL INC  SPRING VALLEY NY (United States)',
    country: 'United States'
  },
  {
    id: 19591,
    name: 'REDDING MEDICAL  REISTERSTOWN MD (United States)',
    country: 'United States'
  },
  {
    id: 19592,
    name: 'MAXTEC  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 19593,
    name: 'GN OTOMETRICS NORTH AMERICA  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19594,
    name: 'CONTINENTAL METAL PRODUCTS  WOBURN MA (United States)',
    country: 'United States'
  },
  {
    id: 19595,
    name: 'PURZAN MEDICAL EQUIPMENT SVC  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 19596,
    name: 'INTER-COUNTY LIGHTING INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19597,
    name: 'CONTROL SYSTEMS LABS  BUFFALO NY (United States)',
    country: 'United States'
  },
  {
    id: 19598,
    name: 'SUMMIT DOPPLER (NOW WALLACH SURGICAL)  DENVER CO (United States)',
    country: 'United States'
  },
  {
    id: 19599,
    name: 'LONG ISLAND TRUCK PARTS, INC.  MEDFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 19600,
    name: 'EMSE  FAIRFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19601,
    name: 'HACH ULTRA ANALYTICS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19603,
    name: 'DRE INC.  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 19604,
    name: 'ABRASIVE WEST LLC  ENGELWOOD CO (United States)',
    country: 'United States'
  },
  {
    id: 19605,
    name: 'PRODATA SYSTEMS  PELL CITY AL (United States)',
    country: 'United States'
  },
  {
    id: 19606,
    name: 'CIVCO MEDICAL SOLUTIONS  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19607,
    name: 'SCOTT EMERGENCY LIGHTING  BENSALEM PA (United States)',
    country: 'United States'
  },
  {
    id: 19608,
    name: 'GRAF & LEWENT ARCHITECTS LLP  ELMHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 19609,
    name: 'MODULAR SERVICES COMPANY  OKLAHOMA CITY OK (United States)',
    country: 'United States'
  },
  {
    id: 19610,
    name: 'CPM SALES AND SERVICES INC.  PEWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 19611,
    name: 'MCGRATH RESEARCH AND TECH.  GLEN ALLEN VA (United States)',
    country: 'United States'
  },
  {
    id: 19612,
    name: 'CONQUEST IMAGING  STOCKTON CA (United States)',
    country: 'United States'
  },
  {
    id: 19613,
    name: 'AMERICAN SELITE ENGRAVERS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19614,
    name: 'MOYER SERVICE COMPANY  OAKFORD PA (United States)',
    country: 'United States'
  },
  {
    id: 19615,
    name: 'OPTRONICS  GOLETA CA (United States)',
    country: 'United States'
  },
  {
    id: 19616,
    name: 'AEROFITNESS SERVICES  LEWISBERRY PA (United States)',
    country: 'United States'
  },
  {
    id: 19617,
    name: 'TIGER DIRECT  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 19618,
    name: 'PORTABLE POWER SYSEMS, INC.  DENVER CO (United States)',
    country: 'United States'
  },
  {
    id: 19619,
    name: 'MITCHELL INSTRUMENTS  SAN MARCOS CA (United States)',
    country: 'United States'
  },
  {
    id: 19620,
    name: 'WAVEWARE TECHNOLOGIES  Garland TX (United States)',
    country: 'United States'
  },
  {
    id: 19621,
    name: 'NICHE MEDICAL INC.  WARWICH RI (United States)',
    country: 'United States'
  },
  {
    id: 19623,
    name: 'AMGAS INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19624,
    name: 'CONVERGENT LASER  ALAMEDA CA (United States)',
    country: 'United States'
  },
  {
    id: 19625,
    name: 'SUROS SURGICAL SYSTEMS  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 19626,
    name: 'MESA INTERNATIONAL TECHNOLOGIES  SANTA ANA CA (United States)',
    country: 'United States'
  },
  {
    id: 19627,
    name: 'CEA INSTRUMENTS  EMERSON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19628,
    name: 'LATAM MEDICAL  BLOOMFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19629,
    name: 'FLO TEC  LARGO FL (United States)',
    country: 'United States'
  },
  {
    id: 19630,
    name: 'HALOGEN SOFTWARE  KANATA, ONTARIO C (United States)',
    country: 'United States'
  },
  {
    id: 19631,
    name: 'PERRY BAROMEDICAL CORP.  RIVIERA BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 19632,
    name: 'MEDI-NUCLEAR CORP.  NEW HUDSON MI (United States)',
    country: 'United States'
  },
  {
    id: 19633,
    name: 'MEDELCO MEDICAL  BOYNTON BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 19634,
    name: 'TECH INSTRUMENTATION  ELIZABETH CO (United States)',
    country: 'United States'
  },
  {
    id: 19636,
    name: 'NEW YORK SEMICONDUCTOR  Farmingdale NY (United States)',
    country: 'United States'
  },
  {
    id: 19637,
    name: 'LITTLEFUSE INC.  Des Plaines IL (United States)',
    country: 'United States'
  },
  {
    id: 19638,
    name: 'CARL ZEISS SURGICAL  PASADENA CA (United States)',
    country: 'United States'
  },
  {
    id: 19640,
    name: 'ADEL-LAWRENCE ASSOCIATES,INC.  ABERDEEN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19641,
    name: 'CISCO WEBEX LLC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19643,
    name: "ST. JOSEPH'S MEDICAL CENTER  YONKERS NY (United States)",
    country: 'United States'
  },
  {
    id: 19644,
    name: 'DELUXE BUSINESS CHECKS  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19645,
    name: 'PACIFIC MEDICAL  San Juan Capistrano CA (United States)',
    country: 'United States'
  },
  {
    id: 19646,
    name: 'PRECISION SURGICAL  Blue Bell PA (United States)',
    country: 'United States'
  },
  {
    id: 19647,
    name: 'MARSHALL INSTRUMENTS  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 19649,
    name: 'MAVIG, GMBH  ROCHESTER NY (United States)',
    country: 'United States'
  },
  {
    id: 19650,
    name: 'MEDICAL INTEGRATED SERVICES  CUYAHOGA FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 19651,
    name:
      'LIFE TECHNOLOGIES CORP. formerly THERMO FISHER SCN  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19652,
    name: 'DELL BUSINESS CREDIT  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19653,
    name: 'SOUTH SEMINOLE SHEET METAL  APOPKA FL (United States)',
    country: 'United States'
  },
  {
    id: 19654,
    name: 'BUNNELL INFANT CARE  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 19656,
    name: 'MAC MEDICAL  MILLSTADT IL (United States)',
    country: 'United States'
  },
  {
    id: 19657,
    name: 'CABRINI MEDICAL CENTER  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 19658,
    name: 'WLF COMPANY  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 19659,
    name: 'ENVIRONMENTAL PRODUCTS,INC.  LA PORTE IN (United States)',
    country: 'United States'
  },
  {
    id: 19661,
    name: 'VSSI  CARTHAGE MO (United States)',
    country: 'United States'
  },
  {
    id: 19663,
    name: 'NEW YORK STATE INSURANCE FUND  ALBANY NY (United States)',
    country: 'United States'
  },
  {
    id: 19666,
    name: 'MEDICATION TECHNOLOGIES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19667,
    name: 'MIDMARK CORP  VERSAILLES OH (United States)',
    country: 'United States'
  },
  {
    id: 19668,
    name: 'BMET SERVICE SOLUTIONS  WESTPORT MA (United States)',
    country: 'United States'
  },
  {
    id: 19669,
    name: 'FIBERTECH MEDICAL  TIMONIUM MD (United States)',
    country: 'United States'
  },
  {
    id: 19671,
    name: 'BERCHTOLD  CHARLESTON SC (United States)',
    country: 'United States'
  },
  {
    id: 19672,
    name: 'ALPHA OMEGA AUTO BODY  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 19673,
    name: 'RC MEDICAL  TOLLARD CT (United States)',
    country: 'United States'
  },
  {
    id: 19674,
    name: 'TRACELOGIX CORPORATION  MEMPHIS TN (United States)',
    country: 'United States'
  },
  {
    id: 19675,
    name: 'MULTI-TECH SYSTEMS  MOUNDSVIEW MN (United States)',
    country: 'United States'
  },
  {
    id: 19676,
    name: 'DYMO  STAMFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 19677,
    name: 'BIOMEDICAL EQUIP & ENGINEERING  QUINTON VA (United States)',
    country: 'United States'
  },
  {
    id: 19678,
    name: 'COOK MEDICAL  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19679,
    name: 'INSTRUMENTATION ASSOCIATES INC.  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19680,
    name: 'PROFESSIONAL MEDICAL GAS MGMT  LAKE RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 19682,
    name: 'THOUGHT TECHNOLOGY  PLATTSBURGH NY (United States)',
    country: 'United States'
  },
  {
    id: 19683,
    name: 'BUSCH INC  SOUTH PLAINFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19684,
    name: 'THERMO-FISHER SORVALL  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19686,
    name: 'MOOG MEDICAL  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 19687,
    name: 'CRAINEY TECHNICAL SERVICES LLC  TURNERSVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 19688,
    name: 'SCIENTIFIC EQUIPMENT LIQUIDATORS  BIG LAKE MN (United States)',
    country: 'United States'
  },
  {
    id: 19689,
    name: 'GAMMEX  MIDDLETON WI (United States)',
    country: 'United States'
  },
  {
    id: 19690,
    name: 'AMERICAN LAMP RECYCLING, LLC  WAPPINGERS FALLS NY (United States)',
    country: 'United States'
  },
  {
    id: 19691,
    name: 'NITELITE LASERS, PRODUCTS & SERVICES  MOORPARK CA (United States)',
    country: 'United States'
  },
  {
    id: 19692,
    name: 'GEXPRO  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 19693,
    name: 'TUV RHEINLAND OF NORTH AMERICA, INC.  HARTFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 19694,
    name: 'DENTSPLY INTERNATIONAL  YORK PA (United States)',
    country: 'United States'
  },
  {
    id: 19696,
    name: 'V. MUELLER NATIONAL REPAIR CENTER  Newark NJ (United States)',
    country: 'United States'
  },
  {
    id: 19697,
    name: 'MICRONIX SYSTEMS INC.  SPRINGFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19698,
    name: 'UNIVERSAL MOTOR DISTRIBUTORS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19699,
    name: 'TOWER FASTENERS CO., INC  HOLTSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 19700,
    name: 'SCOTTY PEELER COMPANY  Chico CA (United States)',
    country: 'United States'
  },
  {
    id: 19704,
    name: 'SUSQUEHANNA MICRO INC.  WINDSOR PA (United States)',
    country: 'United States'
  },
  {
    id: 19705,
    name: 'WOLFF INDUSTRIES  SPARTANBURG SC (United States)',
    country: 'United States'
  },
  {
    id: 19706,
    name: 'NORTHERN TOOL & EQUIPMENT  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 19707,
    name: 'PRO TOOLS DRICT  GILBERT AZ (United States)',
    country: 'United States'
  },
  {
    id: 19708,
    name: 'IPA  DULUTH GA (United States)',
    country: 'United States'
  },
  {
    id: 19709,
    name:
      'BURKE LAGUNA RIDGE BUSINESS CENTER LLC  COSTA MESA CA (United States)',
    country: 'United States'
  },
  {
    id: 19710,
    name: 'BASELINE CORPORATION  WHITE PLAINS NY (United States)',
    country: 'United States'
  },
  {
    id: 19713,
    name:
      'PENINSULA HOSP CTR DEPT OF EXTERNAL AFFAIRS & DEVE  FAR ROCKAWAY NY (United States)',
    country: 'United States'
  },
  {
    id: 19714,
    name: 'SOUTHERN CALIFORNIA EDISON  ROSEMEAD CA (United States)',
    country: 'United States'
  },
  {
    id: 19715,
    name: 'SOUTHSIDE HOSPITAL  BAY SHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 19716,
    name: 'PACIFIC DATA  NORCO CA (United States)',
    country: 'United States'
  },
  {
    id: 19717,
    name: 'SANSONE MICROSURGICAL CO.,INC.  BOCA RATON FL (United States)',
    country: 'United States'
  },
  {
    id: 19718,
    name: 'PROGRESSIVE COMPUTING,INC.  YONKERS NY (United States)',
    country: 'United States'
  },
  {
    id: 19719,
    name: 'GENDRON  TOLEDO OH (United States)',
    country: 'United States'
  },
  {
    id: 19721,
    name: 'GLASS DOCTOR  MEDFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 19722,
    name: 'BENJAMIN BIOMEDICAL INC.  ST. PETERSBERG FL (United States)',
    country: 'United States'
  },
  {
    id: 19723,
    name: 'VIASMART  HARRISON TOWNSHIP MI (United States)',
    country: 'United States'
  },
  {
    id: 19724,
    name:
      'HICKORY BATHING & HEALTHCARE SOLUTIONS, INC.  WHITEHOUSE STATION NJ (United States)',
    country: 'United States'
  },
  {
    id: 19725,
    name: 'FEASTERVILLE FAMILY PRACTICE  FEASTERVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 19726,
    name: 'INFINITY INFO SYSTEMS  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19727,
    name: 'MID ATLANTIC SCIENTIFIC  NEW CASTLE DE (United States)',
    country: 'United States'
  },
  {
    id: 19732,
    name: 'LABORIE MEDICAL TECH  WILLISTON VT (United States)',
    country: 'United States'
  },
  {
    id: 19733,
    name: 'NEW STAR LASERS  ROSEVILLE CA (United States)',
    country: 'United States'
  },
  {
    id: 19734,
    name: 'SPECTRO SERVICE  PERKIOMENVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 19735,
    name: 'CARDIAC SCIENCE CORP  WAUKESHA WI (United States)',
    country: 'United States'
  },
  {
    id: 19737,
    name: 'CVENT, INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19742,
    name: 'HIGHLAND MEDICAL EQUIPMENT  TEMECULA CA (United States)',
    country: 'United States'
  },
  {
    id: 19743,
    name: 'SORIN GROUP  ARVADA CO (United States)',
    country: 'United States'
  },
  {
    id: 19744,
    name: 'MONISERV, INC.  SAN FRANCISCO CA (United States)',
    country: 'United States'
  },
  {
    id: 19745,
    name: 'EMPI  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19746,
    name: 'UNICO  DAYTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19748,
    name: 'LAKE ERIE MEDICAL  TEMPERANCE MICH (United States)',
    country: 'United States'
  },
  {
    id: 19749,
    name: 'BONIKA SHEARS  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 19750,
    name: "DOME INT''L, INC  BROOKLYN NY (United States)",
    country: 'United States'
  },
  {
    id: 19751,
    name: 'INTERMETRO INDUSTRIES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19754,
    name:
      'SAFETY MANAGEMENT SERVICES INC  ARLINGTON HEIGHTS IL (United States)',
    country: 'United States'
  },
  {
    id: 19755,
    name: 'OSRAM SYLVANIA  DANVERS MA (United States)',
    country: 'United States'
  },
  {
    id: 19757,
    name: 'ORTHOSONICS LTD  CHATHAM NJ (United States)',
    country: 'United States'
  },
  {
    id: 19759,
    name: 'CREST ULTRASONICS CORP.  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19760,
    name: 'EXCALIBUR ENGINEERING  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 19761,
    name: 'SOUND DECISION FOR IMAGING  SAMMAMISH WA (United States)',
    country: 'United States'
  },
  {
    id: 19762,
    name: 'INTEGRA LIFESCIENCES  PLAINSBORO NJ (United States)',
    country: 'United States'
  },
  {
    id: 19763,
    name: 'ACSP  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19764,
    name: 'IV TECH  UPPERVILLE VA (United States)',
    country: 'United States'
  },
  {
    id: 19765,
    name: 'CPUP  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19767,
    name: 'NEW BOLD  ROCKY MOUNT VA (United States)',
    country: 'United States'
  },
  {
    id: 19768,
    name: 'LOUISIANA LAB SERVICE  KENNER LA (United States)',
    country: 'United States'
  },
  {
    id: 19771,
    name: 'DIVERSIFIED INSTRUMENTS  EVANSVILLE IN (United States)',
    country: 'United States'
  },
  {
    id: 19772,
    name: 'CARDIO DYNAMICS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19773,
    name: 'KSR PUBLISHING INC.  SARASOTA FLOR (United States)',
    country: 'United States'
  },
  {
    id: 19774,
    name: 'HOLOGIC  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 19776,
    name: 'LSS (LAB SAFETY SUPPLY)  JANESVILLE WI (United States)',
    country: 'United States'
  },
  {
    id: 19777,
    name: 'SPECTRUM SURGICAL INSTRUMENTS  STOW OH (United States)',
    country: 'United States'
  },
  {
    id: 19778,
    name: 'MICROSOFT CORPORATION  FARGO ND (United States)',
    country: 'United States'
  },
  {
    id: 19779,
    name: 'POSITIVE ELECTRO MECH. CORP  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 19781,
    name: 'ANACOM MEDTEK  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 19782,
    name: 'BIOMEDICAL EQUIP SERVICE CO  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 19783,
    name: 'GENERAL DATA CO  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 19784,
    name: 'BAL-TECH  ST. CHARLES IL (United States)',
    country: 'United States'
  },
  {
    id: 19785,
    name: 'OCEANSIDE CHAMBER OF COMMERCE  OCEANSIDE NY (United States)',
    country: 'United States'
  },
  {
    id: 19786,
    name: 'ADT SECURITY SERVICES  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 19787,
    name: 'COX COMMUNICATIONS  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 19789,
    name: 'SECURITY SERVICES & TECHNOLOGIES  NORRISTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 19790,
    name: 'MAXWELL PRODUCTS CORP.  NORTH WALES PA (United States)',
    country: 'United States'
  },
  {
    id: 19791,
    name: 'DELFI SPECIALTY  VANCOUVER BC (United States)',
    country: 'United States'
  },
  {
    id: 19792,
    name: 'CONDOR ELECTRONICS  SUNNYVALE CA (United States)',
    country: 'United States'
  },
  {
    id: 19793,
    name: 'PRINCETON CYRO  LAMBERTVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 19794,
    name: 'PRO DATA COMPUTER SERVICES L.L.C  ASTORIA NY (United States)',
    country: 'United States'
  },
  {
    id: 19795,
    name: 'ALLMED  RUSSELLVILLE MO (United States)',
    country: 'United States'
  },
  {
    id: 19796,
    name: 'BIOCON  SANFORD ME (United States)',
    country: 'United States'
  },
  {
    id: 19798,
    name: 'VCI  BERLIN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19799,
    name: '20th CENTURY REFUSE REMOVAL CO  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19800,
    name: 'A.D.A ELECTRICAL CONTRACTING  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 19801,
    name: 'FAXBACK  TIGARD OR (United States)',
    country: 'United States'
  },
  {
    id: 19802,
    name: 'LIEBEL-FLARSHEIM CO.  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19803,
    name: 'GALUXY MEDICAL  WYLIE TX (United States)',
    country: 'United States'
  },
  {
    id: 19804,
    name: 'EVERGREEN HEALTHCARE FOUNDATION  KIRKLAND WA (United States)',
    country: 'United States'
  },
  {
    id: 19805,
    name: 'DRAEGER SAFETY INC  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19806,
    name: 'TARGET SURGICAL  MAPLE GLEN PA (United States)',
    country: 'United States'
  },
  {
    id: 19807,
    name: 'OMRON COLIN MEDICAL CORP  REDMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 19808,
    name: 'SIGN TECH INTERNATIONAL  SCHAUMBURG IL (United States)',
    country: 'United States'
  },
  {
    id: 19809,
    name: 'SECURITY DODGE CHRYSLER  AMITYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 19810,
    name: 'FISHER MEDICAL TECH INC  Wheat Ridge CO (United States)',
    country: 'United States'
  },
  {
    id: 19813,
    name: 'ARTEL  WESTBROOK MAIN (United States)',
    country: 'United States'
  },
  {
    id: 19814,
    name: 'CCH  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 19816,
    name: 'RYDER TRANSPORTATION SERVICES  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19817,
    name: 'MADISON OFFICE MACHINES  MC FARLAND WI (United States)',
    country: 'United States'
  },
  {
    id: 19818,
    name: 'LIGHT SOURCE & VIDEO REPAIR  TARPON SPRINGS FL (United States)',
    country: 'United States'
  },
  {
    id: 19819,
    name: 'SHARN ANESTHESIA  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 19823,
    name: 'MICRO-OPTICS  FRESH MEADOWS NY (United States)',
    country: 'United States'
  },
  {
    id: 19824,
    name: 'HANDI-RAMP  LIBERTY IL (United States)',
    country: 'United States'
  },
  {
    id: 19826,
    name: 'COLCOM  CHESTER SPRINGS PA (United States)',
    country: 'United States'
  },
  {
    id: 19827,
    name: 'FIELDPOINT  OAKVILLE ONTA (United States)',
    country: 'United States'
  },
  {
    id: 19828,
    name: 'PIONEER GRAPHICS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19829,
    name: 'SIGN-A-RAMA  BELLMORE NY (United States)',
    country: 'United States'
  },
  {
    id: 19830,
    name: 'KENSINGTON SOUTH CDC  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19832,
    name: 'COMPREHENSIVE CABLE  SOUTH HACKENSACK NJ (United States)',
    country: 'United States'
  },
  {
    id: 19834,
    name: 'MACIA INSPECTION & TESTING  PIUGHKEEPSIE NY (United States)',
    country: 'United States'
  },
  {
    id: 19836,
    name: 'VALUE PLASTICS, INC  FORT COILLINS CO (United States)',
    country: 'United States'
  },
  {
    id: 19837,
    name: 'MEASUREMENT ASSURANCE TECH, LP  CARROLLTON TX (United States)',
    country: 'United States'
  },
  {
    id: 19838,
    name: 'FENWAL INC.  LAKE ZURICH IL (United States)',
    country: 'United States'
  },
  {
    id: 19840,
    name: 'GATEWAY SCIENTIFIC INC.  TURNERSVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 19841,
    name: 'PROGENY INC  LINCOLNSHIRE IL (United States)',
    country: 'United States'
  },
  {
    id: 19842,
    name: 'ULTRASOUND SOLUTIONS  CHAGRIN FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 19843,
    name: 'THOMAS LUNDBERG  PETERBOROUGH NE (United States)',
    country: 'United States'
  },
  {
    id: 19844,
    name: 'MELLES GRIOT  CARLS BAD CA (United States)',
    country: 'United States'
  },
  {
    id: 19845,
    name: 'LW SCIENTIFIC  LAWRENCEVILLE GA (United States)',
    country: 'United States'
  },
  {
    id: 19846,
    name: 'SOMA COMPUTER SUPPORT  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19874,
    name: 'ID WHOLESALER  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 19875,
    name: 'BEDFORD PRODUCTS  ROANOKE VA (United States)',
    country: 'United States'
  },
  {
    id: 19877,
    name: 'ACCESSIBLE VANS & MOBILITY  BETHPAGE NY (United States)',
    country: 'United States'
  },
  {
    id: 19878,
    name: 'AKF ENGINEERS  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 19880,
    name: 'BIO DATA CORP  HORSHAM PA (United States)',
    country: 'United States'
  },
  {
    id: 19881,
    name: 'BRADY ID  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 19882,
    name: 'STRYKER MEDICAL  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19883,
    name: 'MICRO-TOOLS  VACABILLE CA (United States)',
    country: 'United States'
  },
  {
    id: 19884,
    name: 'FASTENAL  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19885,
    name: 'MEDIFIX, INC.  MORTON GROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 19886,
    name: 'CINCINNATI SUB ZERO  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19887,
    name:
      'NORTHEASTERN HEALTHCARE TECHNOLOGY SYMPOSIUM  BEDFORD NH (United States)',
    country: 'United States'
  },
  {
    id: 19888,
    name: 'BATTERY PLEX  FORT LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 19889,
    name: 'HERCULES SEALING PRODUCTS  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 19890,
    name: 'DWYER  MICHIGAN CITY IN (United States)',
    country: 'United States'
  },
  {
    id: 19891,
    name: 'CYNMAR CORP  CARLINVILLE IL (United States)',
    country: 'United States'
  },
  {
    id: 19892,
    name: 'HEALTH O METER (PELSTAR)  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19894,
    name: 'AVENTRIC TECHNOLOGY  MADISON HEIGHTS MI (United States)',
    country: 'United States'
  },
  {
    id: 19895,
    name: 'RTI ELECTRONICS INC.  Towaco NJ (United States)',
    country: 'United States'
  },
  {
    id: 19896,
    name: 'ERGOTRON AMERICAS  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 19897,
    name: 'MICROSCOPE SOLUTIONS, INC.  PINCKNEY MI (United States)',
    country: 'United States'
  },
  {
    id: 19898,
    name: 'MACRO SOLUTIONS LLC  SCIO OR (United States)',
    country: 'United States'
  },
  {
    id: 19899,
    name: 'CYCLE SHOP  SLIDELL LA (United States)',
    country: 'United States'
  },
  {
    id: 19900,
    name: 'CORAL HOUSE  BALDWIN NY (United States)',
    country: 'United States'
  },
  {
    id: 19902,
    name: 'BEITLER MCKEE OPTICAL  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 19903,
    name: 'CANFIELD IMAGING SYSTEMS  FAIRFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19904,
    name: 'QUALITY MEDICAL GROUP  SOUTH PLAINFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 19905,
    name: 'IMAGE INDUSTRIES INC.  WOOD DALE IL (United States)',
    country: 'United States'
  },
  {
    id: 19906,
    name: 'L-COM CONNECTIVITY PRODUCTS  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 19907,
    name: 'A&B WIPER SUPPLY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19908,
    name: 'BIOMED DEVICES  GUILFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 19910,
    name: 'PHYSIO-CONTROL INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19912,
    name: 'SMALL PARTS  MIRAMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 19913,
    name: 'SONOMED  SOUTHEASTERN PA (United States)',
    country: 'United States'
  },
  {
    id: 19915,
    name: 'AMERICAN TRAINCO  ENGLEWOOD CA (United States)',
    country: 'United States'
  },
  {
    id: 19916,
    name: 'PLANMECA U.S.A. INC.  ROSELLE IL (United States)',
    country: 'United States'
  },
  {
    id: 19917,
    name: 'AOSS MEDICAL SUPPLY  MONROE LA (United States)',
    country: 'United States'
  },
  {
    id: 19918,
    name: 'GEM REFRIGERATOR COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 19920,
    name: 'HEWLETT PACKARD EXP TECH INC  TEMPE AZ (United States)',
    country: 'United States'
  },
  {
    id: 19921,
    name: 'SELECT POS & PERIPHERALS, LLC  EDINA MN (United States)',
    country: 'United States'
  },
  {
    id: 19922,
    name: 'ABINGTON EMERGENCY PHYSICIANS  WILMINGTON DE (United States)',
    country: 'United States'
  },
  {
    id: 19924,
    name: "FITNESS PLUS  O' SALLON MS (United States)",
    country: 'United States'
  },
  {
    id: 19925,
    name: 'STANLEY SECURITY SOLUTIONS  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 19926,
    name:
      'CENTURY INDUSTRIAL & BEARING SUPPLY CORP  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 19927,
    name: 'GENERAL WELDING SUPPLY CORP  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 19928,
    name: 'GOLD COAST CARPET CLEANING  HUNTINGTON NY (United States)',
    country: 'United States'
  },
  {
    id: 19929,
    name: 'FREEDOM MEDICAL  EXTON PA (United States)',
    country: 'United States'
  },
  {
    id: 19930,
    name: 'MOUNTAIN PRECISION MFG. LTD. CO.  BOISE ID (United States)',
    country: 'United States'
  },
  {
    id: 19933,
    name: 'INVERNESS MEDICAL  PRINCETON NJ (United States)',
    country: 'United States'
  },
  {
    id: 19934,
    name: 'NEW PENN MOTOR EXPRESS, INC  LEBANON PA (United States)',
    country: 'United States'
  },
  {
    id: 19935,
    name: 'E.D.S.  DANVILLE VA (United States)',
    country: 'United States'
  },
  {
    id: 19936,
    name: 'GC ELECTRONICS  ROCHFORD IL (United States)',
    country: 'United States'
  },
  {
    id: 19937,
    name:
      'INDUSTRIAL $ BEARING SUPPLY CORP.  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 19938,
    name: 'BIOSITE INCORPORATED  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 19939,
    name: 'GRABEL NEW ORLEANS MOVERS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19941,
    name: 'EAGLE NATIONWIDE MORTGAGE CO  MASSAPEQUA NY (United States)',
    country: 'United States'
  },
  {
    id: 19943,
    name: 'REID SUPPLY  MUSKEGON MI (United States)',
    country: 'United States'
  },
  {
    id: 19944,
    name: 'WIHA QUALITY TOOL  MONTICELLO MN (United States)',
    country: 'United States'
  },
  {
    id: 19945,
    name: 'MEDIAD  CERRITOS CA (United States)',
    country: 'United States'
  },
  {
    id: 19946,
    name: 'OMNIMED INC.  MOORESTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 19947,
    name: 'BERKLEY ASSOC. LLC  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 19948,
    name: 'EXCALIBUR  MINDEN NV (United States)',
    country: 'United States'
  },
  {
    id: 19949,
    name: 'ALPHA IMAGING  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 19950,
    name: 'WESTCORE  LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 19951,
    name: 'FUCHS WELDING SERVICE  STATEN ISLAND NY (United States)',
    country: 'United States'
  },
  {
    id: 19952,
    name: 'TECHNICARE BIOMEDICAL  PETERBOROUGH NH (United States)',
    country: 'United States'
  },
  {
    id: 19953,
    name: 'ELLEX INNOVATIVE IMAGING  SACRAMENTO CA (United States)',
    country: 'United States'
  },
  {
    id: 19955,
    name: 'ALLY  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 19956,
    name: 'ST. CATHERINE OF SIENA MEDICAL CENTER  SMITHTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 19957,
    name: 'STRAPWORKS.COM  EUGENE OR (United States)',
    country: 'United States'
  },
  {
    id: 19958,
    name: 'ULINE  WAUKEGAN IL (United States)',
    country: 'United States'
  },
  {
    id: 19959,
    name: 'INTEGRATED COMMUNICATIONS  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 19960,
    name: 'DAVIS CALIBRATION  Vernon Hills IL (United States)',
    country: 'United States'
  },
  {
    id: 19962,
    name: 'MICRO AUDIOMETRICS CORP  MURPHY NC (United States)',
    country: 'United States'
  },
  {
    id: 19963,
    name: 'MENTOR CORP  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 19964,
    name: 'PDG CONSULTANTS  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 19965,
    name:
      'CASTERS, WHEELS, INDUSTRIAL HANDLING  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19966,
    name: 'EUTEC-SILWELD  DOWNERS GROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 19967,
    name: 'BAST CHEVROLET  SEAFORD NY (United States)',
    country: 'United States'
  },
  {
    id: 19968,
    name: 'HETTICH  BEVERLY MA (United States)',
    country: 'United States'
  },
  {
    id: 19970,
    name: 'M.C. HEALTHCARE PRODUCTS  BEAMSVILLE ONTA (United States)',
    country: 'United States'
  },
  {
    id: 19971,
    name: 'PREMIER MEDICAL PRODUCTS  PLYMOUTH MEETING PA (United States)',
    country: 'United States'
  },
  {
    id: 19972,
    name: 'MAGUIRE ENTERPRISE INC.  FORT LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 19973,
    name: 'INSTANT VERTICALS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 19975,
    name: 'GERMFREE  ORMOND BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 19976,
    name: 'MARTRONICS CORPORATION  SALKUM WA (United States)',
    country: 'United States'
  },
  {
    id: 19977,
    name: 'NYS DEPT OF TRANSPORTATION  ALBANY NY (United States)',
    country: 'United States'
  },
  {
    id: 19978,
    name: 'STANDARD PARKING CORP  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19979,
    name: 'RG GROUP  YORK PA (United States)',
    country: 'United States'
  },
  {
    id: 19980,
    name: 'AM BICKFORD  WALES CENTER NY (United States)',
    country: 'United States'
  },
  {
    id: 19981,
    name: 'RX SYSTEMS, INC  ST CHARLES MO (United States)',
    country: 'United States'
  },
  {
    id: 19982,
    name: 'AJILON PROFESSIONAL STAFFING, LLC  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 19983,
    name: 'JOERNS MEDICAL  STEVENS POINT WI (United States)',
    country: 'United States'
  },
  {
    id: 19984,
    name: 'BIEN-AIR United States, INC  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 19985,
    name: 'MEDICANCE  LOUISVILLE CO (United States)',
    country: 'United States'
  },
  {
    id: 19986,
    name:
      'Aimes Medical Equip. Rental And Props DBA Dr. Prop  Bronx NY (United States)',
    country: 'United States'
  },
  {
    id: 19987,
    name: 'GFG INSTRUMENTATION  ANN ARBOR MICH (United States)',
    country: 'United States'
  },
  {
    id: 19988,
    name: 'BARD ACCESS SYSTEMS  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 19989,
    name: 'GMC BIOMEDICAL  ORANGE CA (United States)',
    country: 'United States'
  },
  {
    id: 19990,
    name: "TRAN-STAR EXECUTIVE INT'L TRANS  NORTH BABYLON NY (United States)",
    country: 'United States'
  },
  {
    id: 19991,
    name: 'GMC BIOMEDICAL  ORANGE CA (United States)',
    country: 'United States'
  },
  {
    id: 19993,
    name: 'CARIDIAN  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 19994,
    name: 'SIMPLEMICRO  CRANBURY NJ (United States)',
    country: 'United States'
  },
  {
    id: 19995,
    name: 'NASSAU CHROMIUM PLATING CO. INC  MINEOLA NY (United States)',
    country: 'United States'
  },
  {
    id: 19996,
    name: 'MINDRAY DS United States, INC.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 19998,
    name: 'TECHSTREET  ANN ARBOR MI (United States)',
    country: 'United States'
  },
  {
    id: 19999,
    name: 'SELECT POS  EDINA MN (United States)',
    country: 'United States'
  },
  {
    id: 20000,
    name: 'FACTORY EXPRESS  ALBUERQUE NM (United States)',
    country: 'United States'
  },
  {
    id: 20002,
    name: 'PRO-FORM  LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 20003,
    name: 'EVERGREEN EMERGENCY SERVICES,PS  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 20004,
    name: 'ICON HEALTH & FITNESS  LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 20005,
    name: 'H&C WEIGHING SYSTEMS  COLUMBIA MARY (United States)',
    country: 'United States'
  },
  {
    id: 20006,
    name: 'FILTER FRESH - DEER PARK  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 20007,
    name: 'WASTE OIL SOLUTIONS  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 20008,
    name: 'INTERCALL OF NEW YORK  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 20010,
    name: 'NANODROP TECHNOLOGIES, LLC  WILMINGTON DE (United States)',
    country: 'United States'
  },
  {
    id: 20011,
    name: 'A&E TECHNICAL SERVICES, INC  METAIRIE LA (United States)',
    country: 'United States'
  },
  {
    id: 20013,
    name: 'PENLON AMERICA  ROANOKE VA (United States)',
    country: 'United States'
  },
  {
    id: 20014,
    name: 'SERVERSUPPLY  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 20015,
    name: 'PATRIOT-SUPPLY  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 20016,
    name: 'CINTECH  LANOKA HARBOR NJ (United States)',
    country: 'United States'
  },
  {
    id: 20017,
    name: 'SCHAERER MAYFIELD  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 20018,
    name: 'ASPEN SURGICAL PRODUCTS  CALEDONIA MI (United States)',
    country: 'United States'
  },
  {
    id: 20019,
    name: 'MICROBARN  RALEIGH NC (United States)',
    country: 'United States'
  },
  {
    id: 20021,
    name: 'SOUTHERN HILLS COMPUTER  HILLSBORO OH (United States)',
    country: 'United States'
  },
  {
    id: 20022,
    name: 'ENV SERVICES  HATFIELD PA (United States)',
    country: 'United States'
  },
  {
    id: 20023,
    name: 'SOMANETIC  TROY MI (United States)',
    country: 'United States'
  },
  {
    id: 20024,
    name: 'DCI COMPUTING  SICKLERVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20025,
    name: 'TRIWG  VALLEY CITY ND (United States)',
    country: 'United States'
  },
  {
    id: 20026,
    name: 'UNITED MEDICAL INSTRUMENTS  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 20027,
    name: 'CPM SALES AND SERVICE  PEWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 20029,
    name: 'AMI (ASSET MANAGEMENT INTERNATIONAL  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 20030,
    name: 'W&H IMPEX INC US$  WINDSOR ON (United States)',
    country: 'United States'
  },
  {
    id: 20031,
    name:
      'COMPREHENSIVE ANESTHESIA SAFETY ENTERPRISES INC  ELKTON MD (United States)',
    country: 'United States'
  },
  {
    id: 20032,
    name:
      'CARDINAL HEALTH (FORMERLY VIASYS NEUROCARE)  MADISON WI (United States)',
    country: 'United States'
  },
  {
    id: 20035,
    name: 'EXTECH INSTRUMENTS  WALTHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 20036,
    name: 'DYNEX TECHNOLOGIES  CHANTILLY VA (United States)',
    country: 'United States'
  },
  {
    id: 20037,
    name: 'ABC ADVENCED COMPUTER  HAYWARD CA (United States)',
    country: 'United States'
  },
  {
    id: 20038,
    name: 'BIG KIDZ CUSTOMZ  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 20040,
    name: 'WT FARLEY  CAMARILLO CA (United States)',
    country: 'United States'
  },
  {
    id: 20041,
    name: 'COMSEARCH.COM  ASHBURN VA (United States)',
    country: 'United States'
  },
  {
    id: 20042,
    name: 'ASHE ORGANIZATION  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20044,
    name: 'ELECTRIFIED DISCOUNTERS, INC.  HAWDEN CT (United States)',
    country: 'United States'
  },
  {
    id: 20045,
    name: 'HIGH TECHNOLOGY INC.  WALPOLE MA (United States)',
    country: 'United States'
  },
  {
    id: 20047,
    name: 'FEDEX FREIGHT  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 20048,
    name: 'CAMOZZI  MCKINNEY TX (United States)',
    country: 'United States'
  },
  {
    id: 20049,
    name: 'TALLEY MEDICAL  LANSING MI (United States)',
    country: 'United States'
  },
  {
    id: 20050,
    name: 'PRODUCTION AUTOMATION CORP (PAC)  EDEN PRAIRIE MN (United States)',
    country: 'United States'
  },
  {
    id: 20052,
    name: 'RC TESTING SERVICE  LYNBROOK NY (United States)',
    country: 'United States'
  },
  {
    id: 20053,
    name: 'NS ELECTRONICS  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20054,
    name:
      'SIEMENS MEDICAL SOLUTIONS United States  MALVERNE PA (United States)',
    country: 'United States'
  },
  {
    id: 20056,
    name:
      'FLEET INFORMATION TECHNOLOGIES LLC  EAST STROUDSBURG PA (United States)',
    country: 'United States'
  },
  {
    id: 20057,
    name: 'ELECTRICAL GEODESICS, INC.  EUGENE OR (United States)',
    country: 'United States'
  },
  {
    id: 20058,
    name: 'F. GREEK BRISTOL PROPERTIES,L.P.  EAST BRUNSWICK NJ (United States)',
    country: 'United States'
  },
  {
    id: 20059,
    name: 'HOVER TECH INTL  BETHLEHEM PA (United States)',
    country: 'United States'
  },
  {
    id: 20060,
    name: 'RICHARD-ALLAN SCIENTIFIC CO.  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20061,
    name: 'MAG MEDICAL ENETRPRISES  MASSAPEQUA NEW (United States)',
    country: 'United States'
  },
  {
    id: 20062,
    name: 'FITSERVE  MADISONVILLE LA (United States)',
    country: 'United States'
  },
  {
    id: 20063,
    name: 'MBA MEDICAL  HARAHAN LA (United States)',
    country: 'United States'
  },
  {
    id: 20065,
    name: 'ABLE FIRE PREVENTION CORP  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20068,
    name: 'TRI W-G  VALLEY CITY ND (United States)',
    country: 'United States'
  },
  {
    id: 20069,
    name: 'NAPA AUTO PARTS  FOGELSVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 20070,
    name: 'MEDSTAR SURGIAL COMPANY  NORCO CA (United States)',
    country: 'United States'
  },
  {
    id: 20071,
    name: 'BATTDEPOT.COM  BLAINE WA (United States)',
    country: 'United States'
  },
  {
    id: 20072,
    name: 'BARNWELL HOUSE OF TIRE, INC.  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 20076,
    name: 'PARTS WAREHOUSE  LYNDEN WA (United States)',
    country: 'United States'
  },
  {
    id: 20077,
    name: 'NATIONAL BOND COLLECTION  WILKES BARRE PA (United States)',
    country: 'United States'
  },
  {
    id: 20078,
    name:
      'HEALTH CARE DISTRICT OF PALM BEACH COUNTY  WEST PALM BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 20079,
    name: 'GLOBAL INDUSTRIAL  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 20080,
    name: 'ARCOMA NORTH AMERICA  LAGUNA HILLS CA (United States)',
    country: 'United States'
  },
  {
    id: 20081,
    name: 'ENCORESTORE.COM (ENTERPRISE)  BAKERSFIELD CA (United States)',
    country: 'United States'
  },
  {
    id: 20082,
    name: 'ASCEND MEDIA  OVERLAND PARK KS (United States)',
    country: 'United States'
  },
  {
    id: 20083,
    name: 'A.F.I. INC.  WALPOLE MA (United States)',
    country: 'United States'
  },
  {
    id: 20084,
    name: 'PHOTO THERM  TRENTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 20085,
    name: 'SIZE WISE  WALPOLE MA (United States)',
    country: 'United States'
  },
  {
    id: 20087,
    name: 'EDMUND SCIENTIFIC  TONAWANDA NY (United States)',
    country: 'United States'
  },
  {
    id: 20090,
    name: 'PECO ENERGY COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20091,
    name: 'MIKE WOLDOW-USE #19169  CLAREMONT NH (United States)',
    country: 'United States'
  },
  {
    id: 20093,
    name: 'D&B  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20094,
    name: 'MAVIS DISCOUNT TIRE  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20095,
    name: 'SPECTRA SERVICES  CRANBERRY TWP. PA (United States)',
    country: 'United States'
  },
  {
    id: 20096,
    name: 'HEALTH EQUIPMENT SERVICE  INDIANA PA (United States)',
    country: 'United States'
  },
  {
    id: 20097,
    name: 'EPPENDORF NORTH AMERICA  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20098,
    name: 'ANESTHESIA SERVICES AND PRODUCTS, INC.  OXFORD PA (United States)',
    country: 'United States'
  },
  {
    id: 20100,
    name: 'ReNU POWDER COATING  W.BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 20101,
    name: 'EIKO LIGHT BULBS  MAPLE GROVE MN (United States)',
    country: 'United States'
  },
  {
    id: 20102,
    name: 'J & J BIOMEDICAL SERVICES  MIRAMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 20103,
    name: 'AIR QUALITY ENGINEERING  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 20104,
    name: 'RAIA INTERNATIONAL  VALLEY STREAM NY (United States)',
    country: 'United States'
  },
  {
    id: 20105,
    name: 'SAFETY-KLEEN SYSTEMS  FAIRLESS HILLS PA (United States)',
    country: 'United States'
  },
  {
    id: 20107,
    name: 'RUSKINN INC  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 20108,
    name: 'DAVID SCOTT COMPANY  FRAMINGHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 20109,
    name: 'CHROMATOGRAPHY RESEARCH SUPPLIES  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 20111,
    name: 'E.THOMAS HVAC  SANTA ANA CA (United States)',
    country: 'United States'
  },
  {
    id: 20112,
    name: 'GLENN ZECCOLA  BEDMINSTER NJ (United States)',
    country: 'United States'
  },
  {
    id: 20113,
    name: 'AIR LIQUIDE  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 20114,
    name: 'CS MEDICAL  Creedmoor NC (United States)',
    country: 'United States'
  },
  {
    id: 20115,
    name: 'LANDICE  RANDOLPH NJ (United States)',
    country: 'United States'
  },
  {
    id: 20117,
    name: 'SMD  WALLINGFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 20118,
    name: 'ALL HOURS PLUMBING & HEATING  MT. SINAI NY (United States)',
    country: 'United States'
  },
  {
    id: 20120,
    name: 'OMEGA ENGINEERING  STAMFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 20121,
    name: 'ECHOSERVE  GOLDEN CO (United States)',
    country: 'United States'
  },
  {
    id: 20122,
    name: 'CONCEPT2  MORRISVILLE VT (United States)',
    country: 'United States'
  },
  {
    id: 20124,
    name: 'QUALITY MEDICAL SUPPLIES  LAS VEGAS NV (United States)',
    country: 'United States'
  },
  {
    id: 20125,
    name: '3B TECH.NET  SOUTH BEND IN (United States)',
    country: 'United States'
  },
  {
    id: 20126,
    name: 'ACCOUNTABLE SOFTWARE  EXTON PA (United States)',
    country: 'United States'
  },
  {
    id: 20127,
    name: 'ID CARD GROUP  WEST SACRAMENTO CA (United States)',
    country: 'United States'
  },
  {
    id: 20128,
    name: 'INFINITE BARCODE CORP  GREENSBORO NC (United States)',
    country: 'United States'
  },
  {
    id: 20129,
    name: 'CSI MEDGAS INC.  BAYSIDE NY (United States)',
    country: 'United States'
  },
  {
    id: 20130,
    name: 'INSTRUMENTATION ASSOCIATES, INC  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20131,
    name: 'CLICKSTART  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20132,
    name: 'ALPHA COMMERCIAL  PALMYRA PA (United States)',
    country: 'United States'
  },
  {
    id: 20133,
    name: 'SKC INC.  EIGHTY FOUR PA (United States)',
    country: 'United States'
  },
  {
    id: 20134,
    name: 'GOLAPTOPS.COM  LAREDO TX (United States)',
    country: 'United States'
  },
  {
    id: 20135,
    name: 'B & G MEDICAL  SEWELL NJ (United States)',
    country: 'United States'
  },
  {
    id: 20136,
    name: 'H&R HEALTHCARE  LAKEWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20137,
    name: 'GEORGE LECK & SONS INC  IVYLAND PA (United States)',
    country: 'United States'
  },
  {
    id: 20139,
    name: 'DORNOCH MEDICAL SYSTEMS  RIVERSIDE MO (United States)',
    country: 'United States'
  },
  {
    id: 20140,
    name: 'METRO OPTHAMALIC INC.  SOUTH AMBOY NJ (United States)',
    country: 'United States'
  },
  {
    id: 20141,
    name: 'PHARMALUCENCE  BILLERICA MA (United States)',
    country: 'United States'
  },
  {
    id: 20142,
    name:
      'NEW YORK CUSTOM WELDING & METAL FABRICATION  WADING RIVER NY (United States)',
    country: 'United States'
  },
  {
    id: 20143,
    name: 'ERI INC  UNION IA (United States)',
    country: 'United States'
  },
  {
    id: 20144,
    name: 'RLD MEDICAL  SANTA CLARITA CA (United States)',
    country: 'United States'
  },
  {
    id: 20145,
    name:
      'ARJOHUNTLEIGH INC formerly KCI United States  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 20146,
    name: 'E. MCGRATH, INC  SALEM MA (United States)',
    country: 'United States'
  },
  {
    id: 20147,
    name: 'HASKRIS  ELK GROVE VILLAGE IL (United States)',
    country: 'United States'
  },
  {
    id: 20150,
    name: 'ACISCO  JERICHO NY (United States)',
    country: 'United States'
  },
  {
    id: 20151,
    name: 'ALPINE BIO MED MEDICAL DEVICES  FOUNTAIN VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20152,
    name: 'ACCURATE SCALES INC.  TERRE HAUTE IN (United States)',
    country: 'United States'
  },
  {
    id: 20153,
    name: 'MAICO  EDEN PRAIRIE MN (United States)',
    country: 'United States'
  },
  {
    id: 20154,
    name: 'SAMMONS PRESTON  BOWLINGBROOK IL (United States)',
    country: 'United States'
  },
  {
    id: 20155,
    name: 'SYNCARDIA  TUSON AZ (United States)',
    country: 'United States'
  },
  {
    id: 20156,
    name: 'CINTECH INC  LANOKA HARBOR NJ (United States)',
    country: 'United States'
  },
  {
    id: 20157,
    name: 'ZEUS, INC.  ORANGEBURG SC (United States)',
    country: 'United States'
  },
  {
    id: 20158,
    name: 'DAVIS MEDICAL ELECTRONICS  VISTA CA (United States)',
    country: 'United States'
  },
  {
    id: 20159,
    name: 'TRI-NAMICS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20160,
    name: 'INDEPENDENCE BLUE CROSS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20161,
    name: 'HEALTHPASS  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 20162,
    name: 'CREATIVE DISPLAY SOLUTIONS  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 20163,
    name: 'LEADERPROMOS.COM  COLUMBUS OH (United States)',
    country: 'United States'
  },
  {
    id: 20165,
    name: 'HALASZ ELECTRICAL CONTRACTORS, INC.  JAMESBURG NJ (United States)',
    country: 'United States'
  },
  {
    id: 20166,
    name: 'TWACOMM  HUNTINGTON BEACH CA (United States)',
    country: 'United States'
  },
  {
    id: 20168,
    name: 'FILTERFRESH ATLANTIC JERSEY  BRISTOL PA (United States)',
    country: 'United States'
  },
  {
    id: 20169,
    name: 'CARE FUSION 203  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 20171,
    name: 'MEDICAL PURCHASING RESOURCES  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20172,
    name: 'RADIOLOGICAL SERVICE TRAINING INSTITUTE  SOLON OH (United States)',
    country: 'United States'
  },
  {
    id: 20173,
    name: 'CGALEX DISTRIBUTORS INC.  CHAGRIN FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 20175,
    name: 'BEESCO BIOMEDICAL  QUINTON VA (United States)',
    country: 'United States'
  },
  {
    id: 20176,
    name: 'EXACT TOLERANCE LLC.  BRISTOL PA (United States)',
    country: 'United States'
  },
  {
    id: 20177,
    name: 'GULF MEDICAL FIBEROPTICS  OLDSMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 20178,
    name: 'JABRA (GN NETCOM INC.)  NASHUA NH (United States)',
    country: 'United States'
  },
  {
    id: 20179,
    name: 'INTERMEDEX  SAN CLEMENTE CA (United States)',
    country: 'United States'
  },
  {
    id: 20180,
    name: 'GQI GERMAN QUALITY INSTRUMENTS  MASSILLON OH (United States)',
    country: 'United States'
  },
  {
    id: 20182,
    name: 'STERILMED  GREENSBORO NC (United States)',
    country: 'United States'
  },
  {
    id: 20183,
    name: 'MCGAN TECHNOLOGY, LLC  BLACKSTONE MA (United States)',
    country: 'United States'
  },
  {
    id: 20184,
    name: 'SALESFORCE.COM, INC.  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 20186,
    name: "DUCKY'S RIGGING  ROSELLE NJ (United States)",
    country: 'United States'
  },
  {
    id: 20188,
    name: 'F & M PRECISE METALS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20189,
    name: 'DIRECTRON  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 20190,
    name: 'PROREPAIR (HENRY SCHEIN)  PLACENTIA CA (United States)',
    country: 'United States'
  },
  {
    id: 20191,
    name: 'PRUDENTIAL INSURANCE GROUP  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20192,
    name: 'QUANTUM BIOMEDICAL  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 20193,
    name: 'FERLISE PHOTOGRAPH STUDIOS  LEVITTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 20194,
    name: 'GERMAN QUALITY INSTRUMENTS  MASSILLON OH (United States)',
    country: 'United States'
  },
  {
    id: 20196,
    name: 'PNEUMATIC SERVICE INC.  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 20197,
    name: 'NUELL  WARSAW IN (United States)',
    country: 'United States'
  },
  {
    id: 20198,
    name: 'PRECISE BIOMEDICAL  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 20199,
    name: 'A&E MANUFACTORING CO. INC  LEVITTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 20200,
    name: 'OPTIMAL IMAGING  HOLBROOK NY (United States)',
    country: 'United States'
  },
  {
    id: 20201,
    name: 'GREEN MOUNTAIN NI  MORRISVILLE VT (United States)',
    country: 'United States'
  },
  {
    id: 20202,
    name: 'SIM NET  BEAVERDAM VA (United States)',
    country: 'United States'
  },
  {
    id: 20203,
    name: 'JT PACKARD  VERONA WI (United States)',
    country: 'United States'
  },
  {
    id: 20204,
    name: 'VIVID IMAGING  SOUTH PASADENA CA (United States)',
    country: 'United States'
  },
  {
    id: 20205,
    name: 'ACIST  EDEN PRAIRE MN (United States)',
    country: 'United States'
  },
  {
    id: 20206,
    name: 'AMERICAN OPTI SURGICAL INK  LAKE FOREST CA (United States)',
    country: 'United States'
  },
  {
    id: 20207,
    name: 'TRANSMOTION  Ocala FL (United States)',
    country: 'United States'
  },
  {
    id: 20208,
    name: 'EXTRA COFFEE SERVICE  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 20209,
    name: 'NETWORK LIQUIDATORS  OLDSMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 20211,
    name: 'PACKO INC  ROCKY MOUNT MO (United States)',
    country: 'United States'
  },
  {
    id: 20212,
    name: 'INTERVOX  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 20213,
    name: 'AMERICAN POWER CORPORATION  W. KINGSTON RI (United States)',
    country: 'United States'
  },
  {
    id: 20215,
    name: 'JACKSON SURGICAL SERVICES  LACOMBE LA (United States)',
    country: 'United States'
  },
  {
    id: 20216,
    name: 'DATAMAX  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 20217,
    name: 'FILTERFRESH DELAWARE VALLEY  CONSHOHOCKEN PA (United States)',
    country: 'United States'
  },
  {
    id: 20218,
    name: 'MOBILITY RESEARCH  TEMPE ARIZ (United States)',
    country: 'United States'
  },
  {
    id: 20219,
    name: 'CTI PRODUCTIONS  COPPELL TX (United States)',
    country: 'United States'
  },
  {
    id: 20220,
    name: 'SKAYCO EQUIP, INC  FAIRVIEW VILLAGE PA (United States)',
    country: 'United States'
  },
  {
    id: 20221,
    name: 'PRIORITY ONE  ROANOKE TX (United States)',
    country: 'United States'
  },
  {
    id: 20222,
    name: 'AMERICAN MESSAGING  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 20223,
    name: 'SCALEMART  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 20224,
    name: 'GREEN & CLEAN  EASTON PA (United States)',
    country: 'United States'
  },
  {
    id: 20225,
    name: 'TOOLSPLUS.COM  WATERBURY CT (United States)',
    country: 'United States'
  },
  {
    id: 20226,
    name: 'AELITHO  BEVERLY NJ (United States)',
    country: 'United States'
  },
  {
    id: 20228,
    name: 'DAMON CRAIN  SANTA ROSA CA (United States)',
    country: 'United States'
  },
  {
    id: 20229,
    name: 'DIRECT SUPPLY  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 20230,
    name: 'SAGE SERVICE GROUP  Charleston SC (United States)',
    country: 'United States'
  },
  {
    id: 20231,
    name: 'JASON T. VERBA  CHARGIN FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 20232,
    name: 'STANLEY CONVERGENT SECURITY SOLUTIONS  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 20233,
    name: 'MATTHEWS, PIERCE, & LLOYD, INC.  DOVER DE (United States)',
    country: 'United States'
  },
  {
    id: 20234,
    name: 'SMART VM  BEACHWOOD OH (United States)',
    country: 'United States'
  },
  {
    id: 20236,
    name: 'HEALTHCARE BUSINESS MEDIA, INC.  LEXINGTON KY (United States)',
    country: 'United States'
  },
  {
    id: 20237,
    name: 'JD MEDICAL  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 20238,
    name: 'SERVICE SPECIALISTS OF AMERICA, INC  NEW BOSTON MI (United States)',
    country: 'United States'
  },
  {
    id: 20239,
    name: 'MEDICAL MEASUREMENTS SYSTEMS  DOVER NH (United States)',
    country: 'United States'
  },
  {
    id: 20241,
    name: 'CHARLES SOLANA & SONS  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 20242,
    name: 'TECHNOLAS PERFECT VISION  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20243,
    name: 'BEST THERATRONICS  KANATA ONTA (United States)',
    country: 'United States'
  },
  {
    id: 20244,
    name: 'DUNHOLT MEDICAL LLC  AKRON OHIO (United States)',
    country: 'United States'
  },
  {
    id: 20245,
    name: 'SCALE PERFECT  ST. VISTA CA (United States)',
    country: 'United States'
  },
  {
    id: 20246,
    name: 'GLEN COVE MANSION  GLEN COVE NY (United States)',
    country: 'United States'
  },
  {
    id: 20247,
    name: 'CLAFIN MEDICAL EQUIPMENT  WARWICK RI (United States)',
    country: 'United States'
  },
  {
    id: 20248,
    name: 'LASER DRIVE INC.  GIBSONIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20250,
    name: 'PRI MEDICAL TECHNOLOGIES  SUN VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20251,
    name: 'RALPH W EARL  SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 20252,
    name: 'MED REPAIR LLC  PORTAGE MI (United States)',
    country: 'United States'
  },
  {
    id: 20253,
    name: 'IMPACT MEDICAL CO.  WEST CALDWELL NJ (United States)',
    country: 'United States'
  },
  {
    id: 20254,
    name: 'IMPACT COMPUTERS & ELECTRONICS  HOLLYWOOD FL (United States)',
    country: 'United States'
  },
  {
    id: 20255,
    name: 'C-ARMS United States  SHAKER HEIGHTS OH (United States)',
    country: 'United States'
  },
  {
    id: 20256,
    name: 'INDUSTRIAL SALES & SERVICE CORP.  WEST BABYLON NY (United States)',
    country: 'United States'
  },
  {
    id: 20257,
    name: 'HOSPITAL MAINTENANCE CONSULTANTS  CHETEK WI (United States)',
    country: 'United States'
  },
  {
    id: 20258,
    name: 'MEDMARC CASUALTY INSURANCE COMPANY  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20259,
    name: 'MHHC FOUNDATION  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 20260,
    name: 'METRO FIRE & SAFETY, INC.  SPRING VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20262,
    name: 'KEENZO.COM  JERICHO NY (United States)',
    country: 'United States'
  },
  {
    id: 20263,
    name: 'PRONK TECHNOLOGIES INC  SUN VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20265,
    name: 'PAUL CONTE CHEVROLET  FREEPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 20266,
    name: 'PROGRESS MACHINE & TOOL CORP  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 20267,
    name: 'ANALOGIC  PEABODY MA (United States)',
    country: 'United States'
  },
  {
    id: 20268,
    name: 'PELICAN-CASE  SAN ANTONIO TX (United States)',
    country: 'United States'
  },
  {
    id: 20269,
    name: 'APPLIED INDUSTRIAL TECHNOLOGIES  BRISTOL PA (United States)',
    country: 'United States'
  },
  {
    id: 20270,
    name: 'C-SCAN  LOS GATOS CA (United States)',
    country: 'United States'
  },
  {
    id: 20271,
    name: 'LAERIE INC.  BERTHOUD COLO (United States)',
    country: 'United States'
  },
  {
    id: 20272,
    name: 'INTOXIMETERS  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20273,
    name: 'IT PARTS DEPOT  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 20274,
    name: 'THE PRINTER PROS  TORRANCE CA (United States)',
    country: 'United States'
  },
  {
    id: 20275,
    name: 'EMPIRE ALARM CO., INC  YONKERS NY (United States)',
    country: 'United States'
  },
  {
    id: 20276,
    name: 'VERMONT OPTECHS, INC.  CHARLOTTE VT (United States)',
    country: 'United States'
  },
  {
    id: 20277,
    name: 'SONICOR INSTRUMENT CORP  WALLINGFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 20278,
    name: 'COBEX RECORDER INC  COCONUT CREEK FL (United States)',
    country: 'United States'
  },
  {
    id: 20279,
    name: 'CODE MICRO  OCEANSIDE CA (United States)',
    country: 'United States'
  },
  {
    id: 20280,
    name: 'IDEAL PRODUCTS  623 BROSELEY MISS (United States)',
    country: 'United States'
  },
  {
    id: 20282,
    name: 'LAERIE, INC.  BERTHOUD COLO (United States)',
    country: 'United States'
  },
  {
    id: 20283,
    name: 'LONG ISLAND MEDICAL FOUNDATION  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 20284,
    name: 'CERTIFIED MEDICAL TESTING  FRESNO CA (United States)',
    country: 'United States'
  },
  {
    id: 20285,
    name: 'WILKERSON CORP  RICHLAND MI (United States)',
    country: 'United States'
  },
  {
    id: 20286,
    name: 'VC TECHNOLOGY  BROOKSVILLE FL (United States)',
    country: 'United States'
  },
  {
    id: 20287,
    name: 'MARK S. FRANK  CLEVELAND OHIO (United States)',
    country: 'United States'
  },
  {
    id: 20288,
    name: 'MID CENTRAL MEDICAL  ST. VINCENT MN (United States)',
    country: 'United States'
  },
  {
    id: 20289,
    name: 'KEY SURGICAL  EDEN PRAIRIE MN (United States)',
    country: 'United States'
  },
  {
    id: 20290,
    name: 'CONVEYER & CASTER  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 20291,
    name: 'SCOPE SEATING TECH  ELKHART IN (United States)',
    country: 'United States'
  },
  {
    id: 20292,
    name: 'EXERGEN CORPORATION  WATERTOWN MA (United States)',
    country: 'United States'
  },
  {
    id: 20293,
    name: 'BPA AIR QUALITY SOLUTIONS LLC.  CHARLESTON SC (United States)',
    country: 'United States'
  },
  {
    id: 20294,
    name: 'PEARSON ELECTRONICS  PALO ALTO CA (United States)',
    country: 'United States'
  },
  {
    id: 20295,
    name: 'CAMINO NEUROCARE GROUP  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 20296,
    name: 'SHELLY ELECTRIC COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20298,
    name: 'HANDLER MANUFACTURING  WESTFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20299,
    name: 'INNOVATIVE PRODUCT ACHIEVEMENTS, INC  DULUTH GA (United States)',
    country: 'United States'
  },
  {
    id: 20301,
    name: 'ICU MEDICAL  SAN CLEMENTE CA (United States)',
    country: 'United States'
  },
  {
    id: 20302,
    name: 'BARD MEDICAL (formerly Medivance)  COVINGTON GA (United States)',
    country: 'United States'
  },
  {
    id: 20304,
    name: 'SERC PRODUCTS  CLARKSVILLE IN (United States)',
    country: 'United States'
  },
  {
    id: 20305,
    name: 'SANDHILL SCIENTIFIC INC  HIGHLAND RANCH CO (United States)',
    country: 'United States'
  },
  {
    id: 20306,
    name: 'QUOTE WERKS  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 20307,
    name: 'CARSTAN ACCU-DATA SCALE SYSTEMS  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 20309,
    name: 'DePuy Synthes Sales, Inc.  PALM BEACH GARDENS FL (United States)',
    country: 'United States'
  },
  {
    id: 20311,
    name: 'ARTROMICK INTERNATIONAL, INC  COLUMBUS OH (United States)',
    country: 'United States'
  },
  {
    id: 20312,
    name: "BEN'S PRECISION INSTRUMENTS  TACOMA WA (United States)",
    country: 'United States'
  },
  {
    id: 20314,
    name: 'MAJESTIC MEDICAL INC.  RAYNHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 20316,
    name: 'EDWIN BAYLON  OCEANSIDE CA (United States)',
    country: 'United States'
  },
  {
    id: 20317,
    name: 'GRATEFUL ELECTRIC, INC.  BALDWIN NY (United States)',
    country: 'United States'
  },
  {
    id: 20318,
    name: 'JOHN ASTA & COMPANY  LANGHORNE PA (United States)',
    country: 'United States'
  },
  {
    id: 20319,
    name: 'MTS POWER SYSTEMS  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20320,
    name: 'EAGLE BIOMEDICAL SERVICES  PENNSAUKEN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20321,
    name: 'PLATINUM MEDICAL PARTS  DEERFIELD FL (United States)',
    country: 'United States'
  },
  {
    id: 20322,
    name: 'SCIENTIFIC INDUSTRIES  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 20323,
    name: 'HP PARTS STORE  PALA ALTO CA (United States)',
    country: 'United States'
  },
  {
    id: 20324,
    name: 'MELFAST  FAIRFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20325,
    name: 'MOUNTAIN WEST MICROSURGICAL LLC  WOODLAND PARK COLO (United States)',
    country: 'United States'
  },
  {
    id: 20326,
    name: 'WEST COAST BIOMEDICAL  RAMONA CA (United States)',
    country: 'United States'
  },
  {
    id: 20327,
    name: 'BRISTOL TOWNSHIP  BRISTOL PA (United States)',
    country: 'United States'
  },
  {
    id: 20329,
    name: 'CNR REFRIGERATION  HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 20330,
    name: 'COLTENE WHALEDENT  CUYAHOGA FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 20331,
    name: 'MESCO  SUMMIT NJ (United States)',
    country: 'United States'
  },
  {
    id: 20332,
    name: 'ANGIO DYNAMICS  QUEENSBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20333,
    name: 'ARIBEX  OREM UTAH (United States)',
    country: 'United States'
  },
  {
    id: 20336,
    name: 'NESTLE WATERS NORTH AMERICA  GREENWICH CT (United States)',
    country: 'United States'
  },
  {
    id: 20337,
    name: 'M & M MEDICAL EQUIPMENT REPAIR  BEAVERDALE PA (United States)',
    country: 'United States'
  },
  {
    id: 20338,
    name: 'ZONARE MEDICAL  MOUNTAIN VIEW CA (United States)',
    country: 'United States'
  },
  {
    id: 20339,
    name: 'RUSHABH INSTRUMENTS  WARRINGTON PA (United States)',
    country: 'United States'
  },
  {
    id: 20340,
    name: 'EC NEWS ONLINE ONLY  DULUTH MN (United States)',
    country: 'United States'
  },
  {
    id: 20341,
    name: 'NYSACSP  BINGHAMTON NY (United States)',
    country: 'United States'
  },
  {
    id: 20344,
    name: 'HERITAGE MEDICAL  WEST PLAINS MO (United States)',
    country: 'United States'
  },
  {
    id: 20345,
    name: 'MEDBULB  WILLOW SPRINGS IL (United States)',
    country: 'United States'
  },
  {
    id: 20346,
    name: 'MILLER ENERGY INC.  SOUTH PLAINFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20347,
    name: 'CABLEVISION OPTIMUM  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 20348,
    name:
      'HEALTHCARE FACILITIES ACCREDITATION PROGRAM  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20349,
    name: 'ETYMOTIC  ELKROW VILLAGE IL (United States)',
    country: 'United States'
  },
  {
    id: 20351,
    name: 'ASHRAE  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20352,
    name: 'STEIN YOUR FLORIST CO.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20353,
    name: 'BIG BLUE PRODUCTS INC.  HUNTINGTON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 20354,
    name: 'HOME SCIENCE TOOLS  BILLINGS MT (United States)',
    country: 'United States'
  },
  {
    id: 20355,
    name: 'NUETERRA HEALTHCARE  LEAWOOD KS (United States)',
    country: 'United States'
  },
  {
    id: 20356,
    name: 'TOTALCOMP SCALES & COMPONENTS  FAIRLAWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20357,
    name: 'CHRONO-LOG CORP  HAVERTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 20358,
    name: 'WHITTEMORE ENTERPRISES  CUCAMONGA CA (United States)',
    country: 'United States'
  },
  {
    id: 20359,
    name: 'KOVEN  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20360,
    name: 'AMERICAN MEDICAL SYSTEMS  MINNETONKA MN (United States)',
    country: 'United States'
  },
  {
    id: 20362,
    name: 'SOVEREIGN MEDICAL  HILLSBOROUGH NC (United States)',
    country: 'United States'
  },
  {
    id: 20363,
    name: 'STILLE SURGICAL  LOMBARD IL (United States)',
    country: 'United States'
  },
  {
    id: 20365,
    name: 'DUNHILL MEDICAL  MEDFORD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20366,
    name: 'GOMADIC  HERNDON VA (United States)',
    country: 'United States'
  },
  {
    id: 20367,
    name: 'FIVE STAR SURGICAL  NEW BEDFORD MA (United States)',
    country: 'United States'
  },
  {
    id: 20368,
    name: 'MOBILE ASPECTS  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 20369,
    name: 'BEACH MEDICAL  NEWPORT BEACH CA (United States)',
    country: 'United States'
  },
  {
    id: 20370,
    name: 'UNISYN MEDICAL TECHNOLOGIES  GOLDEN CO (United States)',
    country: 'United States'
  },
  {
    id: 20371,
    name: 'HEALTH LIGHTING  MAMARONECK NY (United States)',
    country: 'United States'
  },
  {
    id: 20372,
    name: 'VENTILATORS PLUS  SAN DIMAS CA (United States)',
    country: 'United States'
  },
  {
    id: 20373,
    name:
      'SIHI PUMPS, INC./ VALLEY TECH, INC.  WHITEHOUSE STATION NJ (United States)',
    country: 'United States'
  },
  {
    id: 20374,
    name: 'WIREMOLD PRODUCTS  HUNTINGTON CA (United States)',
    country: 'United States'
  },
  {
    id: 20375,
    name: 'PIONEER MEDICAL DIV OF AGM  LEAWOOD KS (United States)',
    country: 'United States'
  },
  {
    id: 20376,
    name: 'RESPIRTECH  LANCASTER PA (United States)',
    country: 'United States'
  },
  {
    id: 20377,
    name: 'FRIGECO  ALHAMBRA CA (United States)',
    country: 'United States'
  },
  {
    id: 20378,
    name: "WY'EAST MEDICAL  CLACKAMAS OR (United States)",
    country: 'United States'
  },
  {
    id: 20379,
    name: 'SKILLSOFT CORPORATION  NASHUA NH (United States)',
    country: 'United States'
  },
  {
    id: 20380,
    name: 'RELIANCE METAL FABRICATORS  MIDDLESEX NJ (United States)',
    country: 'United States'
  },
  {
    id: 20381,
    name: 'CRYO-TECH, INC.  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 20382,
    name: 'VCOM  SOUTH HACKENSACK NJ (United States)',
    country: 'United States'
  },
  {
    id: 20383,
    name: 'ARLINGTON SCALE CO. INC  BLOOMFIELD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20384,
    name: 'ADVANTECH  MILPITAS CA (United States)',
    country: 'United States'
  },
  {
    id: 20387,
    name: 'ABETTA SAFE & LOCK  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20388,
    name: 'CONMED CORP  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20390,
    name: 'AUSTIN HARDWARE SUPPLY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20396,
    name: 'DEXTA  NAPA CA (United States)',
    country: 'United States'
  },
  {
    id: 20397,
    name: 'ABATEMENT TECHNOLOGIES  SUWANEE GA (United States)',
    country: 'United States'
  },
  {
    id: 20398,
    name: 'CLINICAL CARE ASSOCIATES  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20400,
    name: 'PAETEC  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 20401,
    name:
      'SIERRA SCIENTIFIC INSTRUMENTS/GIVEN IMAGING  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20402,
    name: 'SUNNY EXPRESS  HAYWARD CA (United States)',
    country: 'United States'
  },
  {
    id: 20404,
    name: 'LAPTOP BATTERY DEPOT  San Diego CA (United States)',
    country: 'United States'
  },
  {
    id: 20405,
    name: 'HEMCO CORP  INDEPENDENCE RD MO (United States)',
    country: 'United States'
  },
  {
    id: 20406,
    name: 'W. KRAMER ASSOCIATES, INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20407,
    name: 'MIDAN CO.  Chino CA (United States)',
    country: 'United States'
  },
  {
    id: 20408,
    name: 'MID-ATLANTIC MECHANICAL  MONROE TOWNSHIP NJ (United States)',
    country: 'United States'
  },
  {
    id: 20409,
    name: 'MIDWEST CONTROL PRODUCTS CORP  Bushnell IL (United States)',
    country: 'United States'
  },
  {
    id: 20411,
    name: 'PEX UNIVERSE  HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 20412,
    name: 'SOTA MEDICAL  CEDAR GROVE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20413,
    name: 'TREMETRICS  EDEN PRAIRIE MN (United States)',
    country: 'United States'
  },
  {
    id: 20415,
    name: 'THOMAS HUDON  ARLINGTON WA (United States)',
    country: 'United States'
  },
  {
    id: 20416,
    name: 'AB SCIEX  FOSTER CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 20417,
    name: 'WATERS MEDICAL  ROCHESTER MN (United States)',
    country: 'United States'
  },
  {
    id: 20418,
    name: 'L.A.B INSTRUMENTS, LTD  CARSON CITY NV (United States)',
    country: 'United States'
  },
  {
    id: 20420,
    name: 'CASTER SOLUTIONS, INC.  REDMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 20421,
    name: 'ACCU-TECH CORP  ROSWELL GA (United States)',
    country: 'United States'
  },
  {
    id: 20423,
    name: 'VELOPEX  SAINT CLOUD FLOR (United States)',
    country: 'United States'
  },
  {
    id: 20424,
    name: 'NEW YORK LABOR LAW POSTER SERVICE  ALBANY NY (United States)',
    country: 'United States'
  },
  {
    id: 20425,
    name: 'THE BAKER COMPANY  BOSTON MA (United States)',
    country: 'United States'
  },
  {
    id: 20426,
    name: 'INDUSTRIAL THREADED PRODUCTS CORP  BAYSHORE NY (United States)',
    country: 'United States'
  },
  {
    id: 20427,
    name: 'GRAHAMFIELD  Doraville GA (United States)',
    country: 'United States'
  },
  {
    id: 20428,
    name: 'GENDEX DENTAL SYSTEMS  DES PLAINES IL (United States)',
    country: 'United States'
  },
  {
    id: 20429,
    name: 'METROPOLITAN MEDICAL SERVICES  ASHEVILLE NC (United States)',
    country: 'United States'
  },
  {
    id: 20433,
    name: 'MOORE MEDICAL  FARMINGTON CT (United States)',
    country: 'United States'
  },
  {
    id: 20434,
    name: 'PRINT-TEK  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20435,
    name: 'INSTRUMENT CARE UNLIMITED  WINDER GA (United States)',
    country: 'United States'
  },
  {
    id: 20436,
    name: 'MARK MEDICAL MFG, INC.  DOWNINGTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 20437,
    name: 'ALLCARE SURGICAL INSTRUMENTS  MIRAMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 20438,
    name: 'TRITON SURGICAL TECHNOLOGIES  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 20439,
    name: 'STRENUMED, INC.  VENTURA CA (United States)',
    country: 'United States'
  },
  {
    id: 20440,
    name: 'SCOPE CARE  CONCORD ON L (United States)',
    country: 'United States'
  },
  {
    id: 20441,
    name: 'ENDOSURG MEDICAL  BELTSVILLE MD (United States)',
    country: 'United States'
  },
  {
    id: 20442,
    name: 'PMES  ABERDEEN MD (United States)',
    country: 'United States'
  },
  {
    id: 20443,
    name: 'DOUG GREEN  MISSION VIEJO CA (United States)',
    country: 'United States'
  },
  {
    id: 20444,
    name: 'COAST TO COAST MEDICAL, INC.  DAVIE FL (United States)',
    country: 'United States'
  },
  {
    id: 20446,
    name: 'MEDICAL CLIENT CONSULTING  LOVES PARK IL (United States)',
    country: 'United States'
  },
  {
    id: 20447,
    name: 'GOLDMAN EVANS & TRAMMELL  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 20448,
    name: 'ECO-CARE  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20449,
    name: 'S&R SCALES  TONAWANDA NY (United States)',
    country: 'United States'
  },
  {
    id: 20451,
    name: 'MIDWEST IMAGING  ST. CHARLES MO (United States)',
    country: 'United States'
  },
  {
    id: 20452,
    name: 'DIAGNOSTIC SYSTEMS  WARWICK RI (United States)',
    country: 'United States'
  },
  {
    id: 20453,
    name: 'HDTV SUPPLY  WEST LAKE VILLAGE CA (United States)',
    country: 'United States'
  },
  {
    id: 20458,
    name: 'SPECTROLINE  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20462,
    name: 'RENOTECH INTERIORS  TAPPAN NY (United States)',
    country: 'United States'
  },
  {
    id: 20463,
    name: 'ARROW MEDICAL SUPPLY  LIBERTYVILLE IL (United States)',
    country: 'United States'
  },
  {
    id: 20464,
    name: 'PACEMASTER  WEST CALDWELL NJ (United States)',
    country: 'United States'
  },
  {
    id: 20465,
    name:
      'VANGUARD CLEANING SYSTEMS OF PA  HUNTINGDON VALLEY PA (United States)',
    country: 'United States'
  },
  {
    id: 20467,
    name: 'GLASS EXPRESS - COMMACK  COMMACK NY (United States)',
    country: 'United States'
  },
  {
    id: 20468,
    name: 'VENDORMATE, INC.  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20469,
    name: 'REITER AMERICA, LLC  SPARTANBURG SC (United States)',
    country: 'United States'
  },
  {
    id: 20470,
    name: 'INTEGRA NEUROSCIENCES  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 20473,
    name: 'ALPRO SERVICE COMPANY  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 20474,
    name: 'CONTINUITY CENTERS  WOODBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20475,
    name: 'WORLD MEDICAL EQUIPMENT INC.  MARYSVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20476,
    name: 'BOYERTOWN LABEL  Sinking Spring PA (United States)',
    country: 'United States'
  },
  {
    id: 20477,
    name: 'CODE INTEGRITY SOLUTIONS LLC  SAN RAFAEL CA (United States)',
    country: 'United States'
  },
  {
    id: 20478,
    name: 'GREAT PRINTER DEALS.COM  WILLOWBROOK IL (United States)',
    country: 'United States'
  },
  {
    id: 20479,
    name: 'INVERTER SOLUTIONS  JOHNSTON RI (United States)',
    country: 'United States'
  },
  {
    id: 20481,
    name: 'ZACK ELECTRONICS  DUARTE CA (United States)',
    country: 'United States'
  },
  {
    id: 20482,
    name: "HEALTHCARE INT'L  LANGLEY WA (United States)",
    country: 'United States'
  },
  {
    id: 20483,
    name: 'METRO SERVICE COMPANY  LANGHORNE PA (United States)',
    country: 'United States'
  },
  {
    id: 20484,
    name: 'KELE  Bartlett TN (United States)',
    country: 'United States'
  },
  {
    id: 20485,
    name: 'ALL SPEC  WILMINGTON NC (United States)',
    country: 'United States'
  },
  {
    id: 20486,
    name: 'TRIDIEN MEDICAL  CORAL SPRINGS FL (United States)',
    country: 'United States'
  },
  {
    id: 20488,
    name: 'LIGHTHOUSE WORLDWIDE SOLUTIONS  FREMONT CA (United States)',
    country: 'United States'
  },
  {
    id: 20489,
    name: 'HULL ANESTHESIA, INC.  HUNTINGTON BEACH CA (United States)',
    country: 'United States'
  },
  {
    id: 20490,
    name: 'RSTI  SOLON OH (United States)',
    country: 'United States'
  },
  {
    id: 20491,
    name: 'TAGQ EQUIPMENT MGT CORP  CUTTER BAY FL (United States)',
    country: 'United States'
  },
  {
    id: 20492,
    name: 'ALAN COMPUTECH INTERNATIONAL  UNION CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 20493,
    name: 'GALIL MEDICAL  ARDEN HILLS MN (United States)',
    country: 'United States'
  },
  {
    id: 20494,
    name: 'ELFIN ENGINEERING  Munford TN (United States)',
    country: 'United States'
  },
  {
    id: 20495,
    name: 'HOYA CONBIO CORPORATION  FREEMONT CA (United States)',
    country: 'United States'
  },
  {
    id: 20497,
    name: 'PERFORMANCE 1  MENTOR OH (United States)',
    country: 'United States'
  },
  {
    id: 20498,
    name: 'FUTURE HEALTH CONCEPT  SANFORD FL (United States)',
    country: 'United States'
  },
  {
    id: 20499,
    name: 'The Dick Munns Co.  LOS ALAMITOS CA (United States)',
    country: 'United States'
  },
  {
    id: 20500,
    name: 'FOREST DENTAL PRODUCTS  HILLSBORO OR (United States)',
    country: 'United States'
  },
  {
    id: 20501,
    name: 'FINE SURGICAL INSTRUMENTS  HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 20502,
    name: 'FIBEROPTIC OPTIC TECHNOLOGY  POMFRET CT (United States)',
    country: 'United States'
  },
  {
    id: 20503,
    name: 'NETWORK SOLUTIONS TECHNOLOGY  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 20504,
    name: 'ELECTRIC MOTOR WHOLESALE.COM  Clayton DE (United States)',
    country: 'United States'
  },
  {
    id: 20506,
    name: 'MCCAULEY MEDICAL  CONCORD NH (United States)',
    country: 'United States'
  },
  {
    id: 20508,
    name: 'CUSTOM LANYARDS PLUS  WINTER SPRINGS FL (United States)',
    country: 'United States'
  },
  {
    id: 20509,
    name: 'ELECTRO-MEDICAL  MARIETTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20510,
    name: 'BC TECHNICAL  Jeffersonville IN (United States)',
    country: 'United States'
  },
  {
    id: 20511,
    name: 'COOPER ATKINS  MIDDLEFIELD CT (United States)',
    country: 'United States'
  },
  {
    id: 20512,
    name: 'BISCOTOOLSUPPLY.COM  FORT PIERCE FL (United States)',
    country: 'United States'
  },
  {
    id: 20515,
    name:
      'ATLANTIC REFRIGERATION EQUIPMENT COMPANY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20517,
    name: 'INSTRUMENT SPECIALISTS, INC.  BOERNE TX (United States)',
    country: 'United States'
  },
  {
    id: 20518,
    name: 'COMPRESSION THERAPY CONCEPTS  EATONTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20519,
    name: 'ARON, SADOWSKY & MARKS, INC.  ENGLEWOOD CLIFFS NJ (United States)',
    country: 'United States'
  },
  {
    id: 20520,
    name: 'JACKSON LEWIS LLP  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20521,
    name: 'HNM MEDICAL-United States  MIAMI FL (United States)',
    country: 'United States'
  },
  {
    id: 20523,
    name: 'DENTAL HANDPIECES REPAIR, INC.  NEWBURY PARK CA (United States)',
    country: 'United States'
  },
  {
    id: 20524,
    name: 'THERMO ELECTRON NORTH AMERICA LLC  MADISON WI (United States)',
    country: 'United States'
  },
  {
    id: 20525,
    name: 'RENOVO SOLUTIONS  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 20526,
    name: 'TELE-VERSE COMMUNICATIONS INC  HOLTSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 20528,
    name: 'WALT WHITMAN FENCE COMPANY  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 20530,
    name: 'NETWORK SOLUTIONS & TRAINING  EAST NORTHPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 20532,
    name: 'BAUSCH & LOMB  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20533,
    name: 'BK ENGINEERING  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 20534,
    name:
      'MOBILE INSTRUMENT SERVICE AND REPAIR  BELLEFONTAINE OH (United States)',
    country: 'United States'
  },
  {
    id: 20535,
    name:
      'MIDWEST CALIBRATION LABORATORY, INC  STE. GENEVIEVE MO (United States)',
    country: 'United States'
  },
  {
    id: 20536,
    name: 'AMANO CINCINNATI INC  ROSELAND NJ (United States)',
    country: 'United States'
  },
  {
    id: 20537,
    name: 'A WALSH IMAGING  POMPTON LAKES NJ (United States)',
    country: 'United States'
  },
  {
    id: 20538,
    name: 'MEDGRAPHICS CARDIORESPIRATORY  ST. PAUL MN (United States)',
    country: 'United States'
  },
  {
    id: 20539,
    name: 'ITABO ELECTRIC COMPANY  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 20540,
    name: 'COMEDICAL  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20541,
    name: 'IKON FINANCIAL/RICOH United States  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20542,
    name: 'A.C. ELECTRIC  MONROVIA CA (United States)',
    country: 'United States'
  },
  {
    id: 20544,
    name: 'GULF COAST BIOMEDICAL INC  Delray Beach FL (United States)',
    country: 'United States'
  },
  {
    id: 20545,
    name: 'LASER LABS INC.  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 20546,
    name: 'RANDSTAT  CAROL STREAM IL (United States)',
    country: 'United States'
  },
  {
    id: 20547,
    name: 'BLUE BELL BIO MEDICAL  VAN WERT OH (United States)',
    country: 'United States'
  },
  {
    id: 20548,
    name: 'FRIESEN ELECTRIC  SUMAS WA (United States)',
    country: 'United States'
  },
  {
    id: 20550,
    name: 'EMBLEM HEALTH SERVICES CO LLC  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20551,
    name: 'HEALTH INS PLAN OF GREATER NY  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20552,
    name: 'EMPIRE HEALTH CHOICE, INC.  NEWARK NJ (United States)',
    country: 'United States'
  },
  {
    id: 20553,
    name: 'AIR PRODUCTS AND CHEMICALS, INC  ALLENTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 20556,
    name: 'PRA RECOVERY  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 20558,
    name: "B&H THE PROFESSIONAL'S SOURCE  NEW YORK NY (United States)",
    country: 'United States'
  },
  {
    id: 20559,
    name: 'STANLEY INNERSPACE  GRAND RAPIDS MI (United States)',
    country: 'United States'
  },
  {
    id: 20561,
    name: 'TRUMPF MEDICAL INC.  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 20563,
    name: 'CORPAK MED SYSTEMS  WHEELING IL (United States)',
    country: 'United States'
  },
  {
    id: 20564,
    name: 'MARTRONICS  SALKUM WA (United States)',
    country: 'United States'
  },
  {
    id: 20565,
    name: 'STONE SERVICES CORP  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 20566,
    name: "GALVAN'S SERVICE & REPAIR  LOS ANGELES CA (United States)",
    country: 'United States'
  },
  {
    id: 20568,
    name: 'ON SITE FITNESS, LLC.  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20570,
    name: 'S.J. BEAULIER, JR., TRUSTEE  MEMPHIS TN (United States)',
    country: 'United States'
  },
  {
    id: 20572,
    name: 'POST ALARM SYSTEMS  ARCADIA CA (United States)',
    country: 'United States'
  },
  {
    id: 20573,
    name: 'COMPUTER TRANSPORTATION SERVICES LTD  BREDA IA (United States)',
    country: 'United States'
  },
  {
    id: 20574,
    name: 'RMDS  WANTAGH NY (United States)',
    country: 'United States'
  },
  {
    id: 20575,
    name: 'FINE SCIENCE TOOLS  FOSTER CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 20576,
    name: 'RADSOURCE IMAGING TECHNOLOGIES  PARKVILLE MO (United States)',
    country: 'United States'
  },
  {
    id: 20577,
    name: 'ANESTHESIA SERVICE INC.  OKLAHOMA CITY OK (United States)',
    country: 'United States'
  },
  {
    id: 20578,
    name: 'BSN MED  RUTHERFORD COLLEGE NC (United States)',
    country: 'United States'
  },
  {
    id: 20579,
    name: 'TKO SURGICAL, INC.  BEAVERTON OR (United States)',
    country: 'United States'
  },
  {
    id: 20580,
    name: 'STERLING & STERLING, INC.  WOODBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20581,
    name: "MORAN'S RESTAURANT  NEW YORK NY (United States)",
    country: 'United States'
  },
  {
    id: 20582,
    name: 'PARKER HANNIFIN CORP  HAVERHILL MA (United States)',
    country: 'United States'
  },
  {
    id: 20584,
    name: 'SSCOR  SUN VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20585,
    name: 'SUNTECH MEDICAL  MORRISVILLE NC (United States)',
    country: 'United States'
  },
  {
    id: 20586,
    name: 'BLOCK SCIENTIFIC  BELLPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 20587,
    name: 'A-CHEM  EXCELSIOUR SPRINGS MO (United States)',
    country: 'United States'
  },
  {
    id: 20588,
    name: 'Applied Biosystems  South San Francisco CA (United States)',
    country: 'United States'
  },
  {
    id: 20589,
    name: 'TOSHIBA AMERICA MEDICAL SYSTEMS INC.  GLEN MILLS PA (United States)',
    country: 'United States'
  },
  {
    id: 20591,
    name: 'MEDICAL EQUIP INSP SERVICE  VICTORIA CA (United States)',
    country: 'United States'
  },
  {
    id: 20592,
    name: 'CNA INSURANCE  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20594,
    name: 'NUVO, INC  San Fernando CA (United States)',
    country: 'United States'
  },
  {
    id: 20598,
    name: 'COLISEUM CATERERS  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 20599,
    name: 'DEER HILLS DELICATESSEN  DEER PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 20600,
    name: 'SIMON WANG IMAGE SYSTEMS  WEST ORANGE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20601,
    name: 'LANGEVIN LEARNING SERVICES  ODENSBURG NY (United States)',
    country: 'United States'
  },
  {
    id: 20602,
    name: 'DCI DENTAL  NEWBERG OR (United States)',
    country: 'United States'
  },
  {
    id: 20605,
    name: 'KEATON MEDICAL LLC  PASADINA MD (United States)',
    country: 'United States'
  },
  {
    id: 20607,
    name: 'SPENCER TECHNOLOGIES, INC.  REDMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 20608,
    name: 'BROADWAY PREMIUM FUNDING  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20611,
    name: 'GENERAL DEVELOPMENT CORP  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 20613,
    name: 'SEILER INST & MANUFACTURING INC.  ST. LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20614,
    name: 'PATTERSON DENTAL SUPPLY INC  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20615,
    name: 'ADVANTAGE MEDICAL CABLES  CORAL SPRINGS FL (United States)',
    country: 'United States'
  },
  {
    id: 20616,
    name: 'MULTIVIEW, INC.  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20617,
    name: 'EMPLOYMENT DEVELOPMENT DEPT  WEST SACRAMENTO CA (United States)',
    country: 'United States'
  },
  {
    id: 20618,
    name: 'PROMED SOLUTIONS  LOS ALAMITOS CA (United States)',
    country: 'United States'
  },
  {
    id: 20619,
    name: 'FALCON ELECTRIC INC  IRWINDALE CA (United States)',
    country: 'United States'
  },
  {
    id: 20621,
    name: 'THE HARTFORD  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20623,
    name: 'LINKEDIN  MOUNTAIN VIEW CA (United States)',
    country: 'United States'
  },
  {
    id: 20624,
    name: 'GLOBAL MEDICAL SALES AND REPAIR  LONG BEACH CA (United States)',
    country: 'United States'
  },
  {
    id: 20625,
    name: 'ESCO ELECTRIC SUPPLY  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20626,
    name: 'TN DEPT OF LABOR & WORKFORCE DEV  NASHVILLE TN (United States)',
    country: 'United States'
  },
  {
    id: 20627,
    name: 'ATLAS INTERNATIONAL  RANCHO CA (United States)',
    country: 'United States'
  },
  {
    id: 20628,
    name: 'WAGNER INSTRUMENTS  GREENWICH CT (United States)',
    country: 'United States'
  },
  {
    id: 20631,
    name: 'PENN PRESBYTERIAN MEDICAL CENTER  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20632,
    name: 'INNOVATIVE ENDOSCOPY COMPONENTS  FORT LAUFERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 20633,
    name: 'ENDOSCOPY MD  OXFORD PA (United States)',
    country: 'United States'
  },
  {
    id: 20636,
    name: 'NITC  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 20637,
    name: 'LUXAR CARE  WOODINVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20638,
    name: 'M-B ELECTRONICS  BETHEL PARK PA (United States)',
    country: 'United States'
  },
  {
    id: 20639,
    name: 'THE MICRSCOPE STORE  ROANOKE VA (United States)',
    country: 'United States'
  },
  {
    id: 20640,
    name: 'FUKUDA DENSHI  REDMOND WA (United States)',
    country: 'United States'
  },
  {
    id: 20642,
    name: 'NUAIRE LAB EQUIPMENT SUPPLY  PLYMOUTH MN (United States)',
    country: 'United States'
  },
  {
    id: 20644,
    name: 'NEWARK ELECTRONICS  PALATINE IL (United States)',
    country: 'United States'
  },
  {
    id: 20646,
    name: 'COVIDIEN  PLAINFIELD IN (United States)',
    country: 'United States'
  },
  {
    id: 20647,
    name: 'ST. JUDE MEDICAL S.C., INC  ST. PAUL MN (United States)',
    country: 'United States'
  },
  {
    id: 20648,
    name: 'KASON  LEWIS CENTER OH (United States)',
    country: 'United States'
  },
  {
    id: 20649,
    name: 'PHOTOMEDEX, INC.  MONTGOMERYVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 20650,
    name: 'PPC SYSTEMS  MILLFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 20651,
    name: 'RONCO SPECIALIZED SYSTEMS  ROCHESTER NY (United States)',
    country: 'United States'
  },
  {
    id: 20652,
    name: 'ELECTRO MEDICAL SYSTEMS  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20653,
    name: 'INTERSTATE BATTERIES  DES MOINES IA (United States)',
    country: 'United States'
  },
  {
    id: 20656,
    name: 'CANGRO INDUSTRIES  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20657,
    name: 'AEROSCOUT INC  REDWOOD CITY CA (United States)',
    country: 'United States'
  },
  {
    id: 20658,
    name: 'SYNRAD  MUKILETO WA (United States)',
    country: 'United States'
  },
  {
    id: 20661,
    name: 'AMERICAN FLOOR MATS  ROCKVILLE MD (United States)',
    country: 'United States'
  },
  {
    id: 20662,
    name: 'ABC VACUUM WAREHOUSE  AUSTIN TEXA (United States)',
    country: 'United States'
  },
  {
    id: 20663,
    name: 'WALSH LANDSCAPING & DESIGN  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20667,
    name: 'STAR TRAC  Irvine CA (United States)',
    country: 'United States'
  },
  {
    id: 20669,
    name: 'GEDDIS, INC.  DUNEDIN FL (United States)',
    country: 'United States'
  },
  {
    id: 20670,
    name: 'THE ENDORPHIN CORP.  PINELLAS PARK FL (United States)',
    country: 'United States'
  },
  {
    id: 20671,
    name: 'HUMEDCO CORP  CHERRY HILL NJ (United States)',
    country: 'United States'
  },
  {
    id: 20672,
    name: 'ZIEHM IMAGING  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 20673,
    name: 'SYNOVIS  BIRMINGHAM AL (United States)',
    country: 'United States'
  },
  {
    id: 20680,
    name: 'GIVEN IMAGING INC  Atlanta GA (United States)',
    country: 'United States'
  },
  {
    id: 20681,
    name: 'MEDICAL POSITIONING INC.  KANSAS CITY MO (United States)',
    country: 'United States'
  },
  {
    id: 20682,
    name: 'OEC Medical Systems Inc.  Chicago IL (United States)',
    country: 'United States'
  },
  {
    id: 20683,
    name: 'GYRUS ACMI-ENT  BARTLETT TN (United States)',
    country: 'United States'
  },
  {
    id: 20686,
    name: 'MEDISAFE AMERICA LLC  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 20688,
    name: 'WATERLOO HEALTHCARE LLC  PHOENIX AZ (United States)',
    country: 'United States'
  },
  {
    id: 20689,
    name: 'HEALTHMARK INDUSTRIES  FRASER MI (United States)',
    country: 'United States'
  },
  {
    id: 20690,
    name: 'UNITED AD LABEL/ RR Donnelley Company  GETZVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 20691,
    name: 'CASWELL PLATING  LYONS NY (United States)',
    country: 'United States'
  },
  {
    id: 20694,
    name: 'TELEFLEX MEDICAL  EVERETT MA (United States)',
    country: 'United States'
  },
  {
    id: 20695,
    name: 'NASCO  FORT ATKINSON WI (United States)',
    country: 'United States'
  },
  {
    id: 20696,
    name: 'JAMIE & ASSOCIATES  Omaha NE (United States)',
    country: 'United States'
  },
  {
    id: 20697,
    name: 'J & M Trading  Goodlettsville Tenn (United States)',
    country: 'United States'
  },
  {
    id: 20698,
    name: 'SOLAR WINDS  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20699,
    name: 'ARAMARK REFRESHMENT SERVICES  WEST DEPTFORD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20703,
    name: 'DICK MUNNS COMPANY  LOS ALAMITOS CA (United States)',
    country: 'United States'
  },
  {
    id: 20704,
    name: 'APPLIED INDUSTRIAL TECHNOLOGY  BRISTOL PA (United States)',
    country: 'United States'
  },
  {
    id: 20705,
    name: 'SUNNEX MOUNTS  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 20706,
    name: 'D&D ELECTRIC MOTORS  LINDENHURST NY (United States)',
    country: 'United States'
  },
  {
    id: 20707,
    name: 'JEOL United States, INC.  PEABODY MA (United States)',
    country: 'United States'
  },
  {
    id: 20708,
    name: 'GLOB TEC INC United States  NORTHVALE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20709,
    name: 'P1 GROUP, INC.  LENEXA KS (United States)',
    country: 'United States'
  },
  {
    id: 20711,
    name: 'GRANT FACILITY MANAGEMENT  TURNERSVILLE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20712,
    name: 'AMX SOLUTIONS, INC  Augusta GA (United States)',
    country: 'United States'
  },
  {
    id: 20713,
    name: 'HVACR TOOLS  PAULSBORO NJ (United States)',
    country: 'United States'
  },
  {
    id: 20714,
    name: 'CLASSIC DIAGNOSTIC IMAGING  MACEDONIA OH (United States)',
    country: 'United States'
  },
  {
    id: 20715,
    name: 'BOVIE MEDICAL  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 20716,
    name:
      'SIGMA MEDICAL EQUIPMENT SERVICES, INC.  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 20717,
    name:
      'DIAMEDICAL United States EQUIPMENT LLC  WEST BLOOMFIELD MI (United States)',
    country: 'United States'
  },
  {
    id: 20718,
    name: 'TROFF MEDICAL  HENDERSONVILLE NC (United States)',
    country: 'United States'
  },
  {
    id: 20721,
    name: 'TECHNICAL PROSPECTS  APPLETON WI (United States)',
    country: 'United States'
  },
  {
    id: 20722,
    name: 'DIAGNOSTIC-SOLUTIONS  GARRETTSVILLE OH (United States)',
    country: 'United States'
  },
  {
    id: 20723,
    name: 'ULTRALITE  LAWRENCEVILLE GA (United States)',
    country: 'United States'
  },
  {
    id: 20724,
    name: 'THOMAS MAYESKI  LEVITTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 20725,
    name: 'MARINER MEDICAL LABS, LLC  NEW SMYRNA BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 20726,
    name: 'MEDICAL EQUIPMENT CORPORATION  VIRGINIA BEACH VIRG (United States)',
    country: 'United States'
  },
  {
    id: 20727,
    name: 'PRECISION LASER  WEST BERLIN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20729,
    name: 'AMERICORD  LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 20730,
    name: 'MED PARTS PLUS  AUBURN AL (United States)',
    country: 'United States'
  },
  {
    id: 20732,
    name: 'QUEST DIAGNOSTICS INCORPORATED  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 20733,
    name: 'NAUTILUS, INC.  VANCOUVER WA (United States)',
    country: 'United States'
  },
  {
    id: 20735,
    name: 'PMC CONSULTING GROUP INC  WEST ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 20737,
    name: 'NATIONAL CAR RENTAL  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20738,
    name: 'MAP2 ENTERPRISES  BEAVER FALLS PA (United States)',
    country: 'United States'
  },
  {
    id: 20739,
    name: 'UNIVERSAL HOSPITAL SERVICES  ANAHEIM CA (United States)',
    country: 'United States'
  },
  {
    id: 20740,
    name: 'US ELECTRONICS  MINNEAPOLIS MN (United States)',
    country: 'United States'
  },
  {
    id: 20741,
    name: 'O.R. SOLUTIONS, INC.  CHANTILLY VA (United States)',
    country: 'United States'
  },
  {
    id: 20742,
    name: 'ULTRAFLEX  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 20743,
    name: 'VISION BUSINESS PRODUCTS  CARNEGIE PA (United States)',
    country: 'United States'
  },
  {
    id: 20744,
    name: 'US MARKETING OFFICE SUPPLY CO  LONG ISLAND CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 20745,
    name: 'AIRON CORP  MELBOURNE FL (United States)',
    country: 'United States'
  },
  {
    id: 20747,
    name: 'HEALTHCARE BUSINESS MEDIA, INC.  LEXINGTON NY (United States)',
    country: 'United States'
  },
  {
    id: 20749,
    name: 'NEURO THERM  WILMINGTON MA (United States)',
    country: 'United States'
  },
  {
    id: 20750,
    name: 'NUTRONICS IMAGING INC.  WOODBURY NEW (United States)',
    country: 'United States'
  },
  {
    id: 20751,
    name: 'L.A. TESTING  GARDEN GROVE CA (United States)',
    country: 'United States'
  },
  {
    id: 20752,
    name: 'PhiGEM PARTS  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 20753,
    name: 'UNIVERSITY MEDICAL CENTER  LAS VEGAS NV (United States)',
    country: 'United States'
  },
  {
    id: 20756,
    name: 'ONSITE GAS SYSTEMS, INC.  NEWINGTON CT (United States)',
    country: 'United States'
  },
  {
    id: 20759,
    name: 'ADEL-LAWRENCE ASSOCIATES, INC.  ABERDEEN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20760,
    name: 'BEACON SURGICAL, LLC  CHURUBUSCO IN (United States)',
    country: 'United States'
  },
  {
    id: 20761,
    name: 'A+ MEDICAL COMPANY, INC.  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 20762,
    name: 'COMPREHENSIVE BENEFITS  MELVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 20764,
    name: 'ROCKWELL LASER INDUSTRIES  CINCINNATI OH (United States)',
    country: 'United States'
  },
  {
    id: 20765,
    name: 'MASTERCARE PATIENT EQUIPMENT  COLUMBUS NE (United States)',
    country: 'United States'
  },
  {
    id: 20766,
    name: 'FOGG SYSTEM COMPANY  AURORA CO (United States)',
    country: 'United States'
  },
  {
    id: 20767,
    name: 'COAST TO COAST COMPUTER PRODUCTS  SIMI VALLEY CA (United States)',
    country: 'United States'
  },
  {
    id: 20768,
    name: 'STRYKER INSTRUMENTS  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 20769,
    name: 'J B BICYCLE REPAIR  KIRKLAND WA (United States)',
    country: 'United States'
  },
  {
    id: 20770,
    name: 'ELITE BIOMEDICAL SOLUTIONS, LLC  Cincinnati OH (United States)',
    country: 'United States'
  },
  {
    id: 20771,
    name: 'CMX MEDICAL IMAGING  SEATTLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20774,
    name: 'COAST TO COAST MEDICAL,INC  FALL RIVER MA (United States)',
    country: 'United States'
  },
  {
    id: 20775,
    name: 'MRISS Inc dba Flying Machine  New York New (United States)',
    country: 'United States'
  },
  {
    id: 20776,
    name: 'TRANSAMERICAN MEDICAL IMAGING  LINDON UT (United States)',
    country: 'United States'
  },
  {
    id: 20778,
    name: 'PUGET SOUND SURGICAL  TACOMA WA (United States)',
    country: 'United States'
  },
  {
    id: 20779,
    name: 'TAYLOR PERFORMANCE SOLUTIONS, Inc  Farmingdale NY (United States)',
    country: 'United States'
  },
  {
    id: 20780,
    name: 'STEMCELL TECHNOLOGIES  VANCOUVER BC (United States)',
    country: 'United States'
  },
  {
    id: 20781,
    name: 'STERLING NORTH AMERICA INC  DEER PARK NEW (United States)',
    country: 'United States'
  },
  {
    id: 20783,
    name: 'MEMORY TEN, INC.  SANTA CLARA CA (United States)',
    country: 'United States'
  },
  {
    id: 20784,
    name: 'JC MEDICAL EQUIPMENT CO., Inc.  Moriches NY (United States)',
    country: 'United States'
  },
  {
    id: 20785,
    name: 'MEDRECON  GARWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20786,
    name: 'KAYPENTAX  MONTVALE NJ (United States)',
    country: 'United States'
  },
  {
    id: 20790,
    name: 'CCI Systems  Brooklyn NY (United States)',
    country: 'United States'
  },
  {
    id: 20791,
    name: 'FORESIGHT IMAGING  CHELMSFORD MA (United States)',
    country: 'United States'
  },
  {
    id: 20793,
    name: 'STANFORD DOSIMETRY LLC  BELLINGHAM WA (United States)',
    country: 'United States'
  },
  {
    id: 20794,
    name: 'HAMSHIRE CONTROLS  Dover NH (United States)',
    country: 'United States'
  },
  {
    id: 20795,
    name: 'LUND CERTIFIED VALVE TESTING  RIDGE NY (United States)',
    country: 'United States'
  },
  {
    id: 20796,
    name: 'SONOGAGE  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 20797,
    name: 'WILLISTON PARK TV & RADIO  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20798,
    name: 'BIOCHROM US  HOLLISTON MA (United States)',
    country: 'United States'
  },
  {
    id: 20801,
    name: 'FLIR COMMERCIAL SYSTEMS INC  Nashua NH (United States)',
    country: 'United States'
  },
  {
    id: 20802,
    name: 'STRATEGIC MEDICAL SALES  Bedford Heights OH (United States)',
    country: 'United States'
  },
  {
    id: 20804,
    name: 'ENGINEERING SERVICES  TWINSBURG OH (United States)',
    country: 'United States'
  },
  {
    id: 20805,
    name: 'LBR SCIENTIFIC INC  EAST RUTHERFORD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20806,
    name: 'PROVIDER ENTERPRISE  HENDERSONVILLE TN (United States)',
    country: 'United States'
  },
  {
    id: 20807,
    name: 'SCALES INDUSTRIAL TECH, INC.  CARLE PLACE NY (United States)',
    country: 'United States'
  },
  {
    id: 20808,
    name: 'OLD TOWN SIGN COMPANY  IRWINDALE CA (United States)',
    country: 'United States'
  },
  {
    id: 20809,
    name: 'Go Carpentry Inc  Bay Shore NY (United States)',
    country: 'United States'
  },
  {
    id: 20812,
    name: 'LIFE SCIENCES TECHNOLOGY, INC.  ROCK HILL SC (United States)',
    country: 'United States'
  },
  {
    id: 20813,
    name: 'VASOMEDICAL  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 20814,
    name: 'USOC BIO-MEDICAL SERVICES  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 20815,
    name: 'KDM MEDICAL EQUIPMENT INC  MT SINAI NY (United States)',
    country: 'United States'
  },
  {
    id: 20816,
    name: 'BODYGUARD FITNESS  ST. GEORGES QC (United States)',
    country: 'United States'
  },
  {
    id: 20818,
    name: 'SOLBERG MANUFACTURING  ITASCA IL (United States)',
    country: 'United States'
  },
  {
    id: 20819,
    name: 'Parker Laboratories Inc.  Fairfield NJ (United States)',
    country: 'United States'
  },
  {
    id: 20825,
    name: 'VENDOR CREDENTIALING SERVICE  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 20827,
    name: 'AIRCOND CORP  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20830,
    name: 'LAIRD PLASTICS  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 20833,
    name: 'FILTRINE MANUFACTURING COMPANY  KEENE NH (United States)',
    country: 'United States'
  },
  {
    id: 20835,
    name: 'SARANT CADILLAC CORP  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20837,
    name: 'ANAEROBE SYSTEMS  MORGAN HILL CA (United States)',
    country: 'United States'
  },
  {
    id: 20838,
    name: 'UNETIXS VASCULAR DIAGNOSTICS  Waukesha WI (United States)',
    country: 'United States'
  },
  {
    id: 20839,
    name: 'IN THE MOOD DJ PRODUCTIONS INC.  SMITHTOWN NY (United States)',
    country: 'United States'
  },
  {
    id: 20842,
    name: 'MERCURY FITNESS REPAIR, INC.  KENMORE WA (United States)',
    country: 'United States'
  },
  {
    id: 20843,
    name: 'BAYSIDE BIOMEDICAL SERVICES  CLEARWATER FL (United States)',
    country: 'United States'
  },
  {
    id: 20844,
    name: 'BOSUN SUPPLIES, INC.  ARNOLD MD (United States)',
    country: 'United States'
  },
  {
    id: 20845,
    name: 'CORPORATE HIRING SOLUTIONS INC  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 20846,
    name: 'VETERINARY HOSPITAL SERVICES,INC  STILLWATER MN (United States)',
    country: 'United States'
  },
  {
    id: 20847,
    name: 'WESTPRIME SYSTEMS INC  CHINO CA (United States)',
    country: 'United States'
  },
  {
    id: 20848,
    name: 'ELECTRI-CORD MFG  WESTFIELD PA (United States)',
    country: 'United States'
  },
  {
    id: 20849,
    name: 'JOHN IACONO, INC.  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 20850,
    name: 'COMPLIANCE SERVICES  TALLAHASSEE FL (United States)',
    country: 'United States'
  },
  {
    id: 20851,
    name: 'NORTH SHORE CARE SUPPLY  NORTHBROOK IL (United States)',
    country: 'United States'
  },
  {
    id: 20855,
    name: 'ADEPTO MEDICAL  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 20860,
    name: 'BLOCK IMAGING  Holt MI (United States)',
    country: 'United States'
  },
  {
    id: 20861,
    name: 'IVY BIOMEDICAL  BRANDORD CT (United States)',
    country: 'United States'
  },
  {
    id: 20862,
    name: 'STAR MEDICAL EQUIPMENT. LLC  PLYMOUTH MEETING PA (United States)',
    country: 'United States'
  },
  {
    id: 20863,
    name: 'BRONCHTECH, INC.  BOOTHWYN PA (United States)',
    country: 'United States'
  },
  {
    id: 20864,
    name: 'CARESTREAM HEALTH INC DIV ONEX CORP  ROCHESTER NY (United States)',
    country: 'United States'
  },
  {
    id: 20865,
    name: 'SUN LASERS  PORTLAND OR (United States)',
    country: 'United States'
  },
  {
    id: 20866,
    name: 'WESCOR, INC.  LOGAN UT (United States)',
    country: 'United States'
  },
  {
    id: 20867,
    name: 'ATRICURE  WEST CHESTER OH (United States)',
    country: 'United States'
  },
  {
    id: 20869,
    name: 'HEALTH TECH TALENT MGT. LLC  VIRGINIA BEACH VIRG (United States)',
    country: 'United States'
  },
  {
    id: 20870,
    name: 'TEKYARD, LLC  BURNSVILLE MN (United States)',
    country: 'United States'
  },
  {
    id: 20871,
    name: 'VITAL REGILUS  Tamarac FL (United States)',
    country: 'United States'
  },
  {
    id: 20872,
    name: 'MEK & ASSOCIATES  GRAYSON GA (United States)',
    country: 'United States'
  },
  {
    id: 20875,
    name: 'PEERLESS PAPER SPECIALITY INC  WILLOW GROVE PA (United States)',
    country: 'United States'
  },
  {
    id: 20877,
    name: 'TEK-NET INC.  LAKEWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 20878,
    name: 'READING CRANE AND ENGINEERING COMPANY  READING PA (United States)',
    country: 'United States'
  },
  {
    id: 20879,
    name: 'PORTER INSTRUMENTS  HATFIELD PA (United States)',
    country: 'United States'
  },
  {
    id: 20880,
    name: 'STRYKER COMMUNICATIONS  FLOWER MOUND TX (United States)',
    country: 'United States'
  },
  {
    id: 20881,
    name: 'PROTECH OPTHTALMICS  BRENTWOOD CA (United States)',
    country: 'United States'
  },
  {
    id: 20882,
    name: 'ZETTA MEDICAL TECHNOLOGIES, LLC  LAKE ZURICH IL (United States)',
    country: 'United States'
  },
  {
    id: 20883,
    name: 'PC CONNECTION SALES CORP  MERRIMACK NH (United States)',
    country: 'United States'
  },
  {
    id: 20884,
    name: 'COVIDIEN MEDICAL DEVICES  SUNNYVALE CA (United States)',
    country: 'United States'
  },
  {
    id: 20886,
    name: 'MIKRON INC  WAREHAM MA (United States)',
    country: 'United States'
  },
  {
    id: 20888,
    name: 'THE PROMETHEUS GROUP  Dover NH (United States)',
    country: 'United States'
  },
  {
    id: 20889,
    name: 'LEGACY CHILLER SYSTEMS  DIAMOND SPRINGS CA (United States)',
    country: 'United States'
  },
  {
    id: 20890,
    name: 'QAL-TEK ASSOCIATES  IDAHO FALLS ID (United States)',
    country: 'United States'
  },
  {
    id: 20891,
    name: 'MEDICAL DEVICE RESOURCE CORP.  LIVERMORE CA (United States)',
    country: 'United States'
  },
  {
    id: 20892,
    name: 'GE HEALTHCARE INSTITUTE  WAUKESHA WI (United States)',
    country: 'United States'
  },
  {
    id: 20893,
    name: 'LUXAIRE H.V.A.C. SERVICES  BALDWIN NY (United States)',
    country: 'United States'
  },
  {
    id: 20894,
    name: 'MURPHY & SON LOGISTICS  CENTRAL ISLIP NY (United States)',
    country: 'United States'
  },
  {
    id: 20895,
    name: 'MEDICAL DELIVERY SERVICES, INC.  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 20896,
    name: 'GAME READY  CONCORD CA (United States)',
    country: 'United States'
  },
  {
    id: 20897,
    name: 'SALVATORE CAMPISI & SONS  RIDGEWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 20899,
    name: 'TAYLOR PERFORMANCE SOLUTIONS  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 20900,
    name:
      'WUNSCH CERTIFIED VALVE INSPECTION  PORT JEFFERSON STATION NY (United States)',
    country: 'United States'
  },
  {
    id: 20901,
    name: 'ENVIRONMENTAL SAFETY PROFESSIONAL  KNIGHTDALE NC (United States)',
    country: 'United States'
  },
  {
    id: 20903,
    name: 'SAFETY SERVICES, INC  KALAMAZOO MI (United States)',
    country: 'United States'
  },
  {
    id: 20904,
    name: 'CARDIAC EQUIPMENT  EAST MEADOW NY (United States)',
    country: 'United States'
  },
  {
    id: 20905,
    name: 'PRICE SCIENTIFIC  DURHAM NC (United States)',
    country: 'United States'
  },
  {
    id: 20907,
    name: "BACH'S HOME HEALTH CARE SUPPLY  Hackettstown NJ (United States)",
    country: 'United States'
  },
  {
    id: 20908,
    name: 'MGC DIAGNOSTICS  ST PAUL MN (United States)',
    country: 'United States'
  },
  {
    id: 20909,
    name: 'MICROFARADS  TORRANCE CA (United States)',
    country: 'United States'
  },
  {
    id: 20910,
    name: 'MEDSPEC  MOUNT HOLLY NJ (United States)',
    country: 'United States'
  },
  {
    id: 20913,
    name: 'CASTLE & COOPER United States LLC  REDWOOD FALLS MN (United States)',
    country: 'United States'
  },
  {
    id: 20915,
    name: 'TRANSACT TECHNOLOGIES INC  HAMDEN CT (United States)',
    country: 'United States'
  },
  {
    id: 20916,
    name: 'GF HEALTH PRODUCTS,INC  FOND DU LAC WI (United States)',
    country: 'United States'
  },
  {
    id: 20917,
    name: 'A. Duie Pyle, Inc.  West Chester PA (United States)',
    country: 'United States'
  },
  {
    id: 20918,
    name: 'HAYDEN MEDICAL INC  Santa Clarita CA (United States)',
    country: 'United States'
  },
  {
    id: 20920,
    name: "JOHN BECK'S AUTO BODY CO  LEVITTOWN PA (United States)",
    country: 'United States'
  },
  {
    id: 20921,
    name: 'ARTEL  WESTBROOK MAIN (United States)',
    country: 'United States'
  },
  {
    id: 20923,
    name: 'SPECTROFUGE CORP OF NC, INC.  DURHAM NC (United States)',
    country: 'United States'
  },
  {
    id: 20926,
    name: 'MICROSCOPE SOLUTIONS,INC.  RALEIGH NC (United States)',
    country: 'United States'
  },
  {
    id: 20927,
    name: 'STEREOTAXIS, INC.  SAINT LOUIS MO (United States)',
    country: 'United States'
  },
  {
    id: 20929,
    name: 'CLINICAL DELIVERY SYSTEMS, INC.  Farmingdale NY (United States)',
    country: 'United States'
  },
  {
    id: 20931,
    name: 'BAKER COMPANY  SANFORD ME (United States)',
    country: 'United States'
  },
  {
    id: 20932,
    name: 'NATUS NEUROLOGY INC  MIDDLETON WI (United States)',
    country: 'United States'
  },
  {
    id: 20933,
    name: 'CALLCARE  LANCASTER PA (United States)',
    country: 'United States'
  },
  {
    id: 20935,
    name: "CHEAP CHARLIE''S PLUMBING SERVICE, INC.  SUMMIT NJ (United States)",
    country: 'United States'
  },
  {
    id: 20936,
    name: 'SOUTHEASTERN BIOMEDICAL ASSOC INC  GRANITE FALLS NC (United States)',
    country: 'United States'
  },
  {
    id: 20937,
    name: 'CYNOSURE, INC.  WESTFORD MA (United States)',
    country: 'United States'
  },
  {
    id: 20938,
    name: 'ACTRON MANUFACTURING  CORONA CA (United States)',
    country: 'United States'
  },
  {
    id: 20939,
    name: 'ALPINE ELECTRONICS  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 20942,
    name: 'SCIENCELAB.COM  HOUSTON TX (United States)',
    country: 'United States'
  },
  {
    id: 20943,
    name: 'BERMAN BEDDING  Erie PA (United States)',
    country: 'United States'
  },
  {
    id: 20944,
    name: 'MEDSERVICE REPAIR INC.  Mundelein Illi (United States)',
    country: 'United States'
  },
  {
    id: 20945,
    name: 'Progressive Medical Corp.  Kent WA (United States)',
    country: 'United States'
  },
  {
    id: 20946,
    name: 'STEWMAC.COM  ATHENS OH (United States)',
    country: 'United States'
  },
  {
    id: 20947,
    name: 'LET US ENTERTAIN YOU  MASSAPEQUA NY (United States)',
    country: 'United States'
  },
  {
    id: 20949,
    name: 'HOGGAN SCIENTIFIC  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 20950,
    name: 'SENTINEL IMAGING GROUP INC  CLARKS SUMMIT PA (United States)',
    country: 'United States'
  },
  {
    id: 20951,
    name: 'NORTHWEST BIOMEDICAL ASSOCIATES, INC.  EVERETT WA (United States)',
    country: 'United States'
  },
  {
    id: 20952,
    name: 'WESTERN MEDICA  Westlake OHIO (United States)',
    country: 'United States'
  },
  {
    id: 20953,
    name: 'SYMMETRY SURGICAL  Nashville TN (United States)',
    country: 'United States'
  },
  {
    id: 20955,
    name: 'CENTURY HLM, LLC  MESA AZ (United States)',
    country: 'United States'
  },
  {
    id: 20956,
    name: 'WESTERN INNOVATOR  WESTLAKE OH (United States)',
    country: 'United States'
  },
  {
    id: 20959,
    name: 'PREMIUM ASSIGNMENT CORPORATION  TALLAHASSEE FL (United States)',
    country: 'United States'
  },
  {
    id: 20962,
    name: 'INTEGRATED SERVICE SOLUTION  LANSDALE PA (United States)',
    country: 'United States'
  },
  {
    id: 20963,
    name: 'WES ENTERPRISES  KELLER TX (United States)',
    country: 'United States'
  },
  {
    id: 20964,
    name: 'POKORNEY MANUFACTURING  ADDISON IL (United States)',
    country: 'United States'
  },
  {
    id: 20965,
    name: 'US PLASTIC CORP  LIMA OH (United States)',
    country: 'United States'
  },
  {
    id: 20966,
    name:
      'SHAMROCK SCIENTIFIC SPECIALTY SYSTEMS, INC.  BELLWOOD IL (United States)',
    country: 'United States'
  },
  {
    id: 20967,
    name: 'ILLUMINA, INC.  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 20969,
    name: 'EMERALD RESOURCES  NORTH SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 20970,
    name: 'Hill Laboratories  Frazer PA (United States)',
    country: 'United States'
  },
  {
    id: 20971,
    name: 'LAND AND WHEELS  Milwaukee Wisc (United States)',
    country: 'United States'
  },
  {
    id: 20972,
    name: 'HAUN WELDING SUPPLY  SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 20973,
    name: 'PARALLAX METALS, INC.  BARRINGTON IL (United States)',
    country: 'United States'
  },
  {
    id: 20974,
    name: 'AADCO MEDICAL, INC.  RANDOLPH VT (United States)',
    country: 'United States'
  },
  {
    id: 20976,
    name: 'INSITE ELECTRONIC SERVICES  SPARTANBURG SC (United States)',
    country: 'United States'
  },
  {
    id: 20977,
    name: 'HERTZ SUPPLY  SCHNECKSVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 20978,
    name: 'SHOCKWATCH  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 20981,
    name: 'SPORTS ART AMERICA  WOODINVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 20982,
    name: 'MORGAN MEDESIGN INC.  SANTA ROSA CA (United States)',
    country: 'United States'
  },
  {
    id: 20984,
    name: 'FORMASPACE  AUSTIN TX (United States)',
    country: 'United States'
  },
  {
    id: 20985,
    name: 'AO AMERICAN ORTHODONTICS  MILWAUKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 20986,
    name: 'MOTION SYSTEMS CORPORATION  EATONTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 20987,
    name: 'PANASONIC  WOOD DALE IL (United States)',
    country: 'United States'
  },
  {
    id: 20990,
    name: 'UVP, LLC  UPLAND CA (United States)',
    country: 'United States'
  },
  {
    id: 20991,
    name: 'GENERAL BOILER  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 20992,
    name: 'WATERMARK MEDICAL  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 20998,
    name:
      'ATLAS ELECTROSTATIC REFINISHING INC  NEWTOWN SQUARE PA (United States)',
    country: 'United States'
  },
  {
    id: 20999,
    name: 'GENESIS BPS  RAMSEY NJ (United States)',
    country: 'United States'
  },
  {
    id: 21002,
    name: 'BIONESS  VALENCIA CA (United States)',
    country: 'United States'
  },
  {
    id: 21003,
    name: 'LUMINANCE INTEGRATED TECHNOLOGIES  PLANO TX (United States)',
    country: 'United States'
  },
  {
    id: 21004,
    name: 'PREMIER, INC.  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 21005,
    name: 'COFFEE DISTRIBUTING CORP.  GARDEN CITY PARK NY (United States)',
    country: 'United States'
  },
  {
    id: 21006,
    name: 'SPERIAN PROTECTION OPTICAL  CHESTER VA (United States)',
    country: 'United States'
  },
  {
    id: 21007,
    name: 'ALLPARTS MEDICAL  NASHVILLE TN (United States)',
    country: 'United States'
  },
  {
    id: 21009,
    name: 'SONESTA MEDICAL INC  Lombard IL (United States)',
    country: 'United States'
  },
  {
    id: 21011,
    name: 'CARDIOVASCULAR INSTRUMENTS CORP  WAKEFIELD MA (United States)',
    country: 'United States'
  },
  {
    id: 21012,
    name: 'JG-CONSTRUCTION  BELLMORE NY (United States)',
    country: 'United States'
  },
  {
    id: 21013,
    name: 'AMERICAN INTERNATIONAL MEDICAL  SYLMAR CA (United States)',
    country: 'United States'
  },
  {
    id: 21014,
    name: 'ASHCROFT INC.  STRATFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 21016,
    name: 'MEDICAL DATA INFORMATION SERVICES  HORSHAM PA (United States)',
    country: 'United States'
  },
  {
    id: 21018,
    name: 'VISTALAB TECHNOLOGIES, INC  BREWSTER NY (United States)',
    country: 'United States'
  },
  {
    id: 21019,
    name: 'LUDLUM MEASUREMENTS INC  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 21020,
    name: 'UPTIME RADIOLOGY SERVICES LLC  WEST MILFORD CT (United States)',
    country: 'United States'
  },
  {
    id: 21021,
    name: 'PC TECH SERVICES  TRINTON FALLS NJ (United States)',
    country: 'United States'
  },
  {
    id: 21022,
    name: 'SOUTHERN TIRE & AUTO  CLINTON NC (United States)',
    country: 'United States'
  },
  {
    id: 21024,
    name: 'ABSOLUTE MEDICAL EQUIPMENT  GAINESVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 21025,
    name: 'GREATAMERICA FINANCIAL SERVICES  DALLAS TX (United States)',
    country: 'United States'
  },
  {
    id: 21027,
    name: 'FOREST MEDICAL  EAST SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 21028,
    name: 'The Riviera at Massapequa  Massapequa NY (United States)',
    country: 'United States'
  },
  {
    id: 21029,
    name: 'HENRY SCHEIN PROREPAIR  PLAINVIEW NY (United States)',
    country: 'United States'
  },
  {
    id: 21031,
    name: 'ANALOX  HUNTINGTON BEACH CA (United States)',
    country: 'United States'
  },
  {
    id: 21032,
    name: 'SYSCONE INC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 21035,
    name: 'MILLENNIUM SURGICAL CORP  NARBERTH PA (United States)',
    country: 'United States'
  },
  {
    id: 21036,
    name: 'WALLACH SURGICAL INC.  Trumbull CT (United States)',
    country: 'United States'
  },
  {
    id: 21038,
    name: 'RF SURGICAL SYSTEMS, INC.  Carlsbad CA (United States)',
    country: 'United States'
  },
  {
    id: 21039,
    name: 'ATS LABORATORIES, INC  BRIDGEPORT CT (United States)',
    country: 'United States'
  },
  {
    id: 21040,
    name: 'WALDMANN LIGHTING CO  WHEELING IL (United States)',
    country: 'United States'
  },
  {
    id: 21041,
    name: 'MAGIC MIKE INSTALLATIONS, INC  BOHEMIA NY (United States)',
    country: 'United States'
  },
  {
    id: 21042,
    name: 'Agfa HealthCare Corporation  Philadelphia PA (United States)',
    country: 'United States'
  },
  {
    id: 21043,
    name: 'ATLANTIC SCALE  NUTLEY NJ (United States)',
    country: 'United States'
  },
  {
    id: 21044,
    name: 'PROFICIENT SURGICAL  GREAT NECK NY (United States)',
    country: 'United States'
  },
  {
    id: 21045,
    name: 'AMS SALES CORP  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21046,
    name: 'CAMFIL United States, INC.  SYRACUSE NY (United States)',
    country: 'United States'
  },
  {
    id: 21047,
    name:
      'BK FIRE SUPPRESSION & SECURITY SYSTEMS  BRENTWOOD NY (United States)',
    country: 'United States'
  },
  {
    id: 21048,
    name: 'OAKWORKS  New Freedom PA (United States)',
    country: 'United States'
  },
  {
    id: 21049,
    name: 'ADVANCED SCALE CO  LINDENWOLD NJ (United States)',
    country: 'United States'
  },
  {
    id: 21053,
    name: 'VECTRA FITNESS  KENT WA (United States)',
    country: 'United States'
  },
  {
    id: 21055,
    name: '3M HEALTH CARE SERVICE CENTER  OAKDALE MN (United States)',
    country: 'United States'
  },
  {
    id: 21057,
    name: 'SUN MEDICAL SOLUTIONS, INC.  FUQUAY VARINA NC (United States)',
    country: 'United States'
  },
  {
    id: 21058,
    name: 'VENOSCOPE.COM  LAFAYETTE LA (United States)',
    country: 'United States'
  },
  {
    id: 21060,
    name: 'ATLANTA BIOMEDICAL CORPORATION (ABC)  SUWANEE GA (United States)',
    country: 'United States'
  },
  {
    id: 21061,
    name: 'MEMTEC  Wellsville PA (United States)',
    country: 'United States'
  },
  {
    id: 21062,
    name: 'LASER SYSTEMS  HILLTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 21063,
    name:
      'William C. Gerakaris Plumbing & Heating Corp.  Long Island City NY (United States)',
    country: 'United States'
  },
  {
    id: 21064,
    name: 'CHARLES MARK VAN DER LAAN  HIALEAH FL (United States)',
    country: 'United States'
  },
  {
    id: 21065,
    name: 'ARENT FOX LLP  WASHINGTON DC (United States)',
    country: 'United States'
  },
  {
    id: 21067,
    name: 'CERTIFIED AIR SOLUTIONS  EAGLEVILLE PA (United States)',
    country: 'United States'
  },
  {
    id: 21069,
    name: 'BEAGLE PATCH U.S.A.LLC  KERVILLE TX (United States)',
    country: 'United States'
  },
  {
    id: 21070,
    name: 'CONNEX INTERNATIONAL  NEW YORK NY (United States)',
    country: 'United States'
  },
  {
    id: 21071,
    name: 'COMBINE REFRIGERATION INC  ST. ALBANS NY (United States)',
    country: 'United States'
  },
  {
    id: 21072,
    name: 'SUMMIT INDUSTRIES, LLC  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 21073,
    name: 'BMESCO  LOUISVILLE KY (United States)',
    country: 'United States'
  },
  {
    id: 21074,
    name: 'ALPHA PRODUCTIONS, INC.  WESTBURY NY (United States)',
    country: 'United States'
  },
  {
    id: 21075,
    name: 'LED MEDICAL DIAGNOSTICS INC  ATLANTA GA (United States)',
    country: 'United States'
  },
  {
    id: 21076,
    name: 'WARREN-KNIGHT INSTRUMENT CO  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21078,
    name: 'MAULL BIOMEDICAL TRAINING, LLC  AURORA OH (United States)',
    country: 'United States'
  },
  {
    id: 21079,
    name: 'SIRONA United States  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 21082,
    name: 'ADAM MEDICAL SALES INC  BROOKLYN NY (United States)',
    country: 'United States'
  },
  {
    id: 21083,
    name: 'ADVANCED MICROSCOPY TECHNIQUES CORP  WOBURN MA (United States)',
    country: 'United States'
  },
  {
    id: 21084,
    name: 'PD BLOWERS  GAINESVILLE GA (United States)',
    country: 'United States'
  },
  {
    id: 21085,
    name: 'KARL STORZ  GOLETA CA (United States)',
    country: 'United States'
  },
  {
    id: 21086,
    name: 'MET ONE INSTRUMENTS, INC.  GRANTS PASS OR (United States)',
    country: 'United States'
  },
  {
    id: 21089,
    name: 'J & R ENTERPRISES  MURRAY KY (United States)',
    country: 'United States'
  },
  {
    id: 21090,
    name: 'CNA SCIENTIFIC  Manassas VA (United States)',
    country: 'United States'
  },
  {
    id: 21091,
    name: 'IMAGING SERVICES NW  NINE MILE FALLS WA (United States)',
    country: 'United States'
  },
  {
    id: 21092,
    name: 'DORAN SCALES  BATAVIA IL (United States)',
    country: 'United States'
  },
  {
    id: 21093,
    name: 'AIREDALE ASSOCIATES, LTD.  MERRICK NY (United States)',
    country: 'United States'
  },
  {
    id: 21096,
    name: 'IMAGING DYNAMICS COMPANY  NE CALGARY ALBE (United States)',
    country: 'United States'
  },
  {
    id: 21097,
    name: 'LUO 3 TECHNOLOGY INC  Staten Island NY (United States)',
    country: 'United States'
  },
  {
    id: 21098,
    name: 'B & L ENGINEERING  SANTA ANA CA (United States)',
    country: 'United States'
  },
  {
    id: 21099,
    name: 'INJECTOR SUPPORT & SERVICE, LLC  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 21100,
    name: 'HemaTechnologies  LEBANON NJ (United States)',
    country: 'United States'
  },
  {
    id: 21104,
    name: 'THE BAGEL FACTORY  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 21106,
    name: 'A&D ENGINEERING, INC.  SAN JOSE CA (United States)',
    country: 'United States'
  },
  {
    id: 21107,
    name: 'BRIEN MEDICAL  ROCKVILLE CENTRE NY (United States)',
    country: 'United States'
  },
  {
    id: 21108,
    name: 'L.I. AUTOMATIC DOORS  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 21109,
    name: 'NCTPVA  HEMPSTEAD NY (United States)',
    country: 'United States'
  },
  {
    id: 21112,
    name: 'BAKER PRINTING  MEDIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21113,
    name: 'SYSTEM ID WAREHOUSE  PLANO TX (United States)',
    country: 'United States'
  },
  {
    id: 21115,
    name: 'ADROIT MEDICAL SYSTEMS  LONDON TN (United States)',
    country: 'United States'
  },
  {
    id: 21116,
    name: 'County Surgical Supply, Inc.  Red Bank NJ (United States)',
    country: 'United States'
  },
  {
    id: 21120,
    name:
      'AMBULATORY SURGERY CENTER ASSOCIATION  WASHINGTON DC (United States)',
    country: 'United States'
  },
  {
    id: 21122,
    name: 'THORATEC CORPORATION  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 21123,
    name: 'NEON, INC.  JOHNSTON RI (United States)',
    country: 'United States'
  },
  {
    id: 21126,
    name: 'PREMIER PURCHASING PARTNERS, LP  LOS ANGELES CA (United States)',
    country: 'United States'
  },
  {
    id: 21127,
    name: 'GORDON STOWE ASSOCIATES  WHEELING IL (United States)',
    country: 'United States'
  },
  {
    id: 21129,
    name: 'CAMPOLO, MIDDLETON & MCCORMICK, LLP  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 21132,
    name: 'TAP MEDICAL PRODUCTS, INC.  SAMMAMISH WA (United States)',
    country: 'United States'
  },
  {
    id: 21133,
    name: 'TRISONICS  Highspire PA (United States)',
    country: 'United States'
  },
  {
    id: 21134,
    name: 'STANDISH X-RAY CORP  DOVER NJ (United States)',
    country: 'United States'
  },
  {
    id: 21136,
    name: 'MULTI RADIANCE MEDICAL  SOLON OH (United States)',
    country: 'United States'
  },
  {
    id: 21137,
    name: 'MULTI-IMAGER SERVICE, INC.  ONTARIO CA (United States)',
    country: 'United States'
  },
  {
    id: 21138,
    name: 'GUARDIAN  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21139,
    name: 'J.A FACCIBENE & ASSOC., INC.  ROCKVILLE CENTRE NY (United States)',
    country: 'United States'
  },
  {
    id: 21143,
    name: 'SYRIS SCIENTIFIC  Gray ME (United States)',
    country: 'United States'
  },
  {
    id: 21145,
    name: 'UNITED MEDICAL COMMUNICATIONS  GARDEN CITY NY (United States)',
    country: 'United States'
  },
  {
    id: 21150,
    name: 'QBC DIAGNOSTICS, INC.  PORT MATILDA PA (United States)',
    country: 'United States'
  },
  {
    id: 21151,
    name: 'THE OLYMPIC GLOVE & SAFETY COMPANY  ELMWOOD NJ (United States)',
    country: 'United States'
  },
  {
    id: 21153,
    name: 'HEALTH CONNECT PARTNERS, INC.  LEBANON TN (United States)',
    country: 'United States'
  },
  {
    id: 21155,
    name: 'BRAEMAR MANUFACTURING,LLC  EAGAN MN (United States)',
    country: 'United States'
  },
  {
    id: 21156,
    name: 'ACCUVEIN INC  COLD SPRING HARBOR NY (United States)',
    country: 'United States'
  },
  {
    id: 21157,
    name: 'SHOX SURGICAL INC.  MEDIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21158,
    name: 'INTELLIGENT COMPUTER SOLUTIONS  CHATSWORTH CA (United States)',
    country: 'United States'
  },
  {
    id: 21159,
    name: 'MORIGON TECHNOLOGIES, LLC  NORTH HOLLYWOOD CA (United States)',
    country: 'United States'
  },
  {
    id: 21160,
    name: 'NICO CORPORATION  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 21161,
    name: 'A HELPING HAND MEDICAL SUPPLY  HARRISBURG PA (United States)',
    country: 'United States'
  },
  {
    id: 21162,
    name: 'BRANTON VET ANESTHESIA SERVICE  Fort Worth TX (United States)',
    country: 'United States'
  },
  {
    id: 21164,
    name: 'ELECTRONIC TRANSFORMER   FLOR (United States)',
    country: 'United States'
  },
  {
    id: 21165,
    name: 'THE FAMILY LANDSCAPING  FREEPORT NY (United States)',
    country: 'United States'
  },
  {
    id: 21167,
    name: 'PROTEGRITY ADVISORS, LLC  RONKONKOMA NY (United States)',
    country: 'United States'
  },
  {
    id: 21168,
    name: 'ADC - AMERICAN DIAGNOSTIC CORP  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 21169,
    name: 'CLAYMOUNT AMERICAS CORP  DOWNERS GROVE IL (United States)',
    country: 'United States'
  },
  {
    id: 21170,
    name: 'SMARTSOURCE RENTALS  LAUREL NY (United States)',
    country: 'United States'
  },
  {
    id: 21171,
    name:
      'B.E.S.T. Biomedical Electronics Svc & Tech  LOMBARD IL (United States)',
    country: 'United States'
  },
  {
    id: 21174,
    name: 'SERVICE CASTER CORPORATION  WEST READING PA (United States)',
    country: 'United States'
  },
  {
    id: 21176,
    name: 'EDMUND OPTICS AMERICA  BARRINGTON NJ (United States)',
    country: 'United States'
  },
  {
    id: 21177,
    name: 'FLUORAMICS  Mahwah NJ (United States)',
    country: 'United States'
  },
  {
    id: 21178,
    name: 'NY IMAGING SERVICE, INC  Newburgh NY (United States)',
    country: 'United States'
  },
  {
    id: 21180,
    name: 'OMNIGUIDE INC  Lexington MA (United States)',
    country: 'United States'
  },
  {
    id: 21181,
    name: 'SUMMIT IMAGING  WOODINVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 21182,
    name: 'BMC BIOMEDICAL  BEDFORD TX (United States)',
    country: 'United States'
  },
  {
    id: 21185,
    name: 'RGD MARKETING, LLC  BELLMORE NY (United States)',
    country: 'United States'
  },
  {
    id: 21188,
    name:
      'ALPINE SOLUTIONS INC./ASI DIGITAL IMAGING  CAMARILLO CA (United States)',
    country: 'United States'
  },
  {
    id: 21189,
    name: 'POWERTECH CONTROLS CO.,INC  HAUPPAUGE NY (United States)',
    country: 'United States'
  },
  {
    id: 21190,
    name: 'SUMMIT APPLIANCE DIVISION  BRONX NY (United States)',
    country: 'United States'
  },
  {
    id: 21191,
    name: 'ANDRESEN MEDICAL  MONROE WA (United States)',
    country: 'United States'
  },
  {
    id: 21192,
    name: 'KORR MEDICAL TECHNOLOGIES, INC.  SALT LAKE CITY UT (United States)',
    country: 'United States'
  },
  {
    id: 21193,
    name: 'C&C NW AESTHETIC LASERS LLC  VANCOUVER WA (United States)',
    country: 'United States'
  },
  {
    id: 21194,
    name: 'Micro-Med, Inc.  Louisville KY (United States)',
    country: 'United States'
  },
  {
    id: 21195,
    name: 'CLARE INSTRUMENTS US, INC.  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 21196,
    name: 'LONG ISLAND PROCESSOR INC  OCEANSIDE NY (United States)',
    country: 'United States'
  },
  {
    id: 21197,
    name: 'ALL PARTS MEDICAL  Nashville TN (United States)',
    country: 'United States'
  },
  {
    id: 21198,
    name: 'INTERMED  ALACHUA FL (United States)',
    country: 'United States'
  },
  {
    id: 21200,
    name: 'AQUA MASSAGE INTERNATIONAL (AMI)  MYSTIC CT (United States)',
    country: 'United States'
  },
  {
    id: 21201,
    name: 'NORTHWEST MEDICAL SUPPLY, INC. (NWS)  SWQUIM WA (United States)',
    country: 'United States'
  },
  {
    id: 21206,
    name:
      'MIR-MEDICAL INTERNATIONAL RESEARCH United States, INC.  WAUKESHA WI (United States)',
    country: 'United States'
  },
  {
    id: 21209,
    name: 'RADIOLOGY SOLUTIONS  GIBBSBORO NJ (United States)',
    country: 'United States'
  },
  {
    id: 21210,
    name: 'THE DOORBRIM  FT LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 21211,
    name: 'THE HARTFORD STEAM BOILER  KING OF PRUSSIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21212,
    name: 'FIELDTEX PRODUCTS INC.  Rochester NY (United States)',
    country: 'United States'
  },
  {
    id: 21213,
    name: 'ATLANTIC BIOMEDICAL  BALTIMORE MD (United States)',
    country: 'United States'
  },
  {
    id: 21214,
    name: 'JAKEN MEDICAL INC  CHINO CA (United States)',
    country: 'United States'
  },
  {
    id: 21215,
    name: 'BIOMEDIX  ST PAUL MN (United States)',
    country: 'United States'
  },
  {
    id: 21216,
    name: 'MED ALLIANCE GROUP INC  ST CHARLES IL (United States)',
    country: 'United States'
  },
  {
    id: 21218,
    name: 'BEMES INC  FENTON MO (United States)',
    country: 'United States'
  },
  {
    id: 21220,
    name: 'CLINICAL ENGINEERING TECHNOLOGY  JACKSONVILLE FL (United States)',
    country: 'United States'
  },
  {
    id: 21221,
    name: 'MAYFIELD MEDICAL  BETHALTO IL (United States)',
    country: 'United States'
  },
  {
    id: 21222,
    name: 'ATOM MEDICAL United States  WEXFORD PA (United States)',
    country: 'United States'
  },
  {
    id: 21223,
    name: 'RECREATION SUPPLY INC  LEWIS CENTER OH (United States)',
    country: 'United States'
  },
  {
    id: 21225,
    name:
      'HEALTHCARE FINANCIAL MANAGEMENT ASSOCIATION  WESTCHESTER IL (United States)',
    country: 'United States'
  },
  {
    id: 21226,
    name: 'PAUSCH LLC  TINTON FALLS NJ (United States)',
    country: 'United States'
  },
  {
    id: 21228,
    name: 'TORMACH  WAUNAKEE WI (United States)',
    country: 'United States'
  },
  {
    id: 21229,
    name: 'ADVANCEWEB  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21230,
    name: 'MD PUBLISHING  PEACHTREE CITY GA (United States)',
    country: 'United States'
  },
  {
    id: 21231,
    name: 'REACH MARKETING LLC  PEARL RIVER NY (United States)',
    country: 'United States'
  },
  {
    id: 21232,
    name: 'PREMIER, INC.  ROSEVILLE CA (United States)',
    country: 'United States'
  },
  {
    id: 21233,
    name: 'WAGNER MEDICAL  MIDDLEBOURNE WV (United States)',
    country: 'United States'
  },
  {
    id: 21234,
    name: 'DIALYSIS TECHNICAL CONSULTING LLC  BLAIRSTOWN NJ (United States)',
    country: 'United States'
  },
  {
    id: 21235,
    name: 'WOODMAN COMMERICAL FITNESS WCF  OCEAN CITY WA (United States)',
    country: 'United States'
  },
  {
    id: 21236,
    name: 'AUTOMAT CUSTOMIZING & RESTORATION  HICKSVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 21237,
    name: 'INNOVATIVE ENDOSCOPY SOLUTIONS, INC.  HURST TX (United States)',
    country: 'United States'
  },
  {
    id: 21238,
    name: 'ELECTRONIC CONTROL CONCEPTS  SAUGERTIES NY (United States)',
    country: 'United States'
  },
  {
    id: 21239,
    name: 'LOUROE ELECTRONICS  VAN NUYS CA (United States)',
    country: 'United States'
  },
  {
    id: 21240,
    name: 'FITNESS PLUS EQUIPMENT SERVICES INC  WENTZVILLE MO (United States)',
    country: 'United States'
  },
  {
    id: 21241,
    name: 'PRECOR  WOODINVILLE WA (United States)',
    country: 'United States'
  },
  {
    id: 21242,
    name: 'INTENSA  HIGH POINT NC (United States)',
    country: 'United States'
  },
  {
    id: 21243,
    name: 'QUANTEL United States  BOZEMAN MT (United States)',
    country: 'United States'
  },
  {
    id: 21245,
    name: 'INTERNATIONAL CONFIGURATIONS INC  Enfield CT (United States)',
    country: 'United States'
  },
  {
    id: 21246,
    name: 'CRAWFORD TOOL  PEACHTREE CITY GA (United States)',
    country: 'United States'
  },
  {
    id: 21247,
    name: 'COLDER PRODUCTS CO.  ST. PAUL MN (United States)',
    country: 'United States'
  },
  {
    id: 21248,
    name: 'THE FITNESS RESOURCE  Plainview NY (United States)',
    country: 'United States'
  },
  {
    id: 21250,
    name: 'SUMMIT MEDICAL SPECIALTIES  JACKSONVILLE FL (United States)',
    country: 'United States'
  },
  {
    id: 21251,
    name: 'EMS MEDICAL  MANCHESTER MD (United States)',
    country: 'United States'
  },
  {
    id: 21252,
    name: 'NDD MEDICAL TECHNOLOGIES  ANDOVER MA (United States)',
    country: 'United States'
  },
  {
    id: 21256,
    name: 'ILLUMINEX TECHNOLOGIES  BAYVILLE NY (United States)',
    country: 'United States'
  },
  {
    id: 21258,
    name: 'VAPOTHERM, INC.  Detroit MI (United States)',
    country: 'United States'
  },
  {
    id: 21259,
    name: 'GULDMANN CO.  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 21260,
    name: 'MARMED INC.  CLEVELAND OH (United States)',
    country: 'United States'
  },
  {
    id: 21261,
    name: 'DURABUILT/GLOBAL STAR MEDICAL  TEXARKANA AR (United States)',
    country: 'United States'
  },
  {
    id: 21262,
    name: 'EGPRO LLC.  HEWLETT NY (United States)',
    country: 'United States'
  },
  {
    id: 21265,
    name: 'BECKER PUMPS CORP.  CUYAHOGA FALLS OH (United States)',
    country: 'United States'
  },
  {
    id: 21266,
    name: 'HEALTHCARE DATA SOLUTIONS  FOOTHILL RANCH CA (United States)',
    country: 'United States'
  },
  {
    id: 21267,
    name: 'THE BREWER COMPANY  MENOMONEE FALLS WI (United States)',
    country: 'United States'
  },
  {
    id: 21268,
    name: 'MERION MATTERS  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21271,
    name: 'BROWARD A&C  DEERFIELD BEACH FL (United States)',
    country: 'United States'
  },
  {
    id: 21272,
    name: 'KOWA  TORRANCE CA (United States)',
    country: 'United States'
  },
  {
    id: 21273,
    name: 'SONODEPOT  ST. CLOUD FL (United States)',
    country: 'United States'
  },
  {
    id: 21274,
    name: 'MICRO LAMP  FT. LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 21276,
    name: 'Ultra Clean Systems  OLDSMAR FL (United States)',
    country: 'United States'
  },
  {
    id: 21277,
    name: 'AVENTURA MEDICAL  MCDONOUGH GA (United States)',
    country: 'United States'
  },
  {
    id: 21278,
    name: 'Market Lab  CALEDONIA MI (United States)',
    country: 'United States'
  },
  {
    id: 21280,
    name: 'IET LABS, INC.  ROSLYN HEIGHTS NY (United States)',
    country: 'United States'
  },
  {
    id: 21281,
    name: 'JOHNSON HEALTH TECH  COTTAGE GROVE WI (United States)',
    country: 'United States'
  },
  {
    id: 21284,
    name: 'MVIX  STERLING VA (United States)',
    country: 'United States'
  },
  {
    id: 21285,
    name: 'IKA LABORATORY TECHNOLOGY  WILMINGTON NC (United States)',
    country: 'United States'
  },
  {
    id: 21286,
    name: 'FREEMAN  ORLANDO FL (United States)',
    country: 'United States'
  },
  {
    id: 21287,
    name: 'NIKOMED  HATBORO PA (United States)',
    country: 'United States'
  },
  {
    id: 21288,
    name: 'BLUE CHIP MEDICAL PRODUCTS  SUFFERN NY (United States)',
    country: 'United States'
  },
  {
    id: 21291,
    name: 'GE MEDICAL  JUPITER FL (United States)',
    country: 'United States'
  },
  {
    id: 21292,
    name:
      'CAMPBELL TECHNOLOGICAL RESOURCES, INC.  CRESTLINE OH (United States)',
    country: 'United States'
  },
  {
    id: 21293,
    name: 'CHECK POINT AUTOMOTIVE INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 21294,
    name: 'SOUTHEAST LASER SYSTEMS, INC.  CHAROLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 21295,
    name: '1ST CALL BIOMEDICAL  COLUMBIA MD (United States)',
    country: 'United States'
  },
  {
    id: 21296,
    name: 'WAYTEK  VOORHEES NJ (United States)',
    country: 'United States'
  },
  {
    id: 21298,
    name: 'INNOVATION MEDICAL  IRVINE CA (United States)',
    country: 'United States'
  },
  {
    id: 21299,
    name: 'MONICA HEALTHCARE  KENNESAW GA (United States)',
    country: 'United States'
  },
  {
    id: 21301,
    name: 'VICOM COMPUTER SERVICES, INC.  FARMINGDALE NY (United States)',
    country: 'United States'
  },
  {
    id: 21303,
    name: 'SOLUTIONS X 2  HATFIELD PA (United States)',
    country: 'United States'
  },
  {
    id: 21304,
    name: 'ROBERT C. KOVALSKY  MERRICK NY (United States)',
    country: 'United States'
  },
  {
    id: 21308,
    name: 'TECHMOTION FITNESS REPAIR  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21309,
    name: 'POERSCH METAL MANUFACTURING  CHICAGO IL (United States)',
    country: 'United States'
  },
  {
    id: 21311,
    name: 'SYSTEMS ELECTRONICS, INC.  KNIGHTDALE NC (United States)',
    country: 'United States'
  },
  {
    id: 21312,
    name: 'COGENTIX MEDICAL  ORANGEBURG NY (United States)',
    country: 'United States'
  },
  {
    id: 21313,
    name: 'IMAGE QUEST, INC. D/B/A REPAIRMED  NAPLES FL (United States)',
    country: 'United States'
  },
  {
    id: 21314,
    name: 'FERGUSON (INDIANA) ENTERPRISES  INDIANAPOLIS IN (United States)',
    country: 'United States'
  },
  {
    id: 21315,
    name: 'CUSTOM BIOMEDICAL  QUAKERTOWN PA (United States)',
    country: 'United States'
  },
  {
    id: 21316,
    name: "ROBINSON'S OXYGEN AND MEDICAL GASES  HOLBROOK NY (United States)",
    country: 'United States'
  },
  {
    id: 21317,
    name: 'ICU  WINDER GA (United States)',
    country: 'United States'
  },
  {
    id: 21318,
    name: 'AIR AND VACUUM SUPPLY  PRARIE VILLAGE KS (United States)',
    country: 'United States'
  },
  {
    id: 21319,
    name:
      'ANESTHESIA CARE EQUIPMENT SERVICES, INC.  ENGLEWOOD CLIFFS NJ (United States)',
    country: 'United States'
  },
  {
    id: 21320,
    name: 'METAL STOCK INC.  PHILADELPHIA PA (United States)',
    country: 'United States'
  },
  {
    id: 21321,
    name: 'TECHNICAL AIR SYSTEMS  NEW VERNON NJ (United States)',
    country: 'United States'
  },
  {
    id: 21322,
    name: 'CHUBB & SON  PITTSBURGH PA (United States)',
    country: 'United States'
  },
  {
    id: 21323,
    name: 'PATTERSON MEDICAL  WARRENVILLE IL (United States)',
    country: 'United States'
  },
  {
    id: 21324,
    name: 'RI, LLC.  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 21325,
    name: 'QED MEDICAL  LEXINGTON KY (United States)',
    country: 'United States'
  },
  {
    id: 21326,
    name: 'MEDICAL DEVICE DEPOT, INC.  ELLICOT CITY MD (United States)',
    country: 'United States'
  },
  {
    id: 21328,
    name: 'NOVAMED  TRUMBULL CT (United States)',
    country: 'United States'
  },
  {
    id: 21329,
    name: 'ACCRIVA DIAGNOSTICS  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 21330,
    name: 'THE BINDING SITE INC.  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 21331,
    name: 'MODUFLEX ANESTHESIA EQUIPMENT INC.  SAN DIEGO CA (United States)',
    country: 'United States'
  },
  {
    id: 21332,
    name: 'TANITA  ARLINGTON HEIGHTS IL (United States)',
    country: 'United States'
  },
  {
    id: 21333,
    name: 'MAUNA KEA TECHNOLOGIES INC.  SUWANEE GA (United States)',
    country: 'United States'
  },
  {
    id: 21334,
    name: 'USHIO AMERICA INC.  CYPRESS CA (United States)',
    country: 'United States'
  },
  {
    id: 21335,
    name: 'REPAIRMED  MEMPHIS TN (United States)',
    country: 'United States'
  },
  {
    id: 21336,
    name: 'TROPITRONICS INC.  FORT LAUDERDALE FL (United States)',
    country: 'United States'
  },
  {
    id: 21337,
    name: 'APEXLAMPS  ALHAMBRA CA (United States)',
    country: 'United States'
  },
  {
    id: 21338,
    name: 'CTC  DAVIE FL (United States)',
    country: 'United States'
  },
  {
    id: 21339,
    name: 'RADON MEDICAL IMAGING  ONA WV (United States)',
    country: 'United States'
  },
  {
    id: 21341,
    name: 'RRC POWER SOLUTIONS INC.  YORBA LINDA CA (United States)',
    country: 'United States'
  },
  {
    id: 21343,
    name: 'MICROTEK MEDICAL  COLUMBUS MS (United States)',
    country: 'United States'
  },
  {
    id: 21345,
    name: 'MS AIR, INC.  MOUNT SINAI NY (United States)',
    country: 'United States'
  },
  {
    id: 21346,
    name: 'ENDOCHOICE, INC.  ALPHARETTA GA (United States)',
    country: 'United States'
  },
  {
    id: 21347,
    name: 'OPTIX INCORPORATED  HILLSBOROUGH NJ (United States)',
    country: 'United States'
  },
  {
    id: 21349,
    name: 'CME AMERICA  GOLDEN CO (United States)',
    country: 'United States'
  },
  {
    id: 21350,
    name: 'CARDIO COMMAND INC  TAMPA FL (United States)',
    country: 'United States'
  },
  {
    id: 21351,
    name: 'CSI HEALTHCARE IT  JACKSONVILLE FL (United States)',
    country: 'United States'
  },
  {
    id: 21352,
    name: 'D & A SOLUTION AND X-RAY, INC.  ELMONT NY (United States)',
    country: 'United States'
  },
  {
    id: 21353,
    name: 'AJ ABRAMS  WESTPORT CT (United States)',
    country: 'United States'
  },
  {
    id: 21354,
    name: 'HAUSMAN INDUSTRIES INC  NORTHVALE NJ (United States)',
    country: 'United States'
  },
  {
    id: 21355,
    name:
      'DIVERSIFIED MONITORING SYSTEMS, INC.  VIRGINIA BEACH VA (United States)',
    country: 'United States'
  },
  {
    id: 21357,
    name: 'GLOBAL MEDICAL IMAGING (GMI)  CHARLOTTE NC (United States)',
    country: 'United States'
  },
  {
    id: 21358,
    name: 'BAYLIS MEDICAL  BURLINGTON MA (United States)',
    country: 'United States'
  },
  {
    id: 21378,
    name: 'VIEW SONIC CORPORATION  BREA CA (United States)',
    country: 'United States'
  },
  {
    id: 21379,
    name: 'BK ULTRASOUND  State College PA (United States)',
    country: 'United States'
  },
  {
    id: 21383,
    name: 'ELITech Biomedical Systems  Logan UT (United States)',
    country: 'United States'
  },
  {
    id: 21384,
    name: 'Medical Imaging/IMCO  Essington PA (United States)',
    country: 'United States'
  },
  {
    id: 21385,
    name: 'Invotec International, Inc.  Jacksonville FL (United States)',
    country: 'United States'
  },
  {
    id: 21386,
    name: 'McLan Electronics, Inc.  Raymond MS (United States)',
    country: 'United States'
  },
  {
    id: 21387,
    name: 'Ramm Inc  Conshohocken PA (United States)',
    country: 'United States'
  },
  {
    id: 21388,
    name: 'BEACON MEDAES  Rock Hill SC (United States)',
    country: 'United States'
  },
  {
    id: 21389,
    name: 'On Site Gas Systems, Inc.  Newington CT (United States)',
    country: 'United States'
  },
  {
    id: 21390,
    name: 'Victor Equipment  Denton TX (United States)',
    country: 'United States'
  },
  {
    id: 21391,
    name: 'Butler Schein  Dublin OH (United States)',
    country: 'United States'
  },
  {
    id: 21392,
    name: 'Covita  Haddonfield NJ (United States)',
    country: 'United States'
  },
  {
    id: 204453,
    name: 'JEDLANEK ASSOCIATES  Lady Lake FL (United States)',
    country: 'United States'
  }
];
